<template>
  <resizable-panel :observer="[isOpenPolicyVisible, accessRightsLabel, errorMessage]">
    <div class="input-label" v-t="'settings.settingsCompanyInfo.transparencyLevel'"></div>
    <p class="no-margin-top" v-html="accessRightsLabel"></p>
    <button
      v-if="!isOpenPolicyVisible"
      class="small-button"
      v-t="'common.update'"
      @click="isOpenPolicyVisible = true" />
    <form @submit.prevent="update" v-if="isOpenPolicyVisible">
      <checkbox
        class="red"
        v-model="companyModel.hasOpenPolicy">
        {{$t('settings.settingsCompanyAccessRights.openPolicyCheckbox')}}
      </checkbox>
      <div>
        <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
        <p v-if="isUpdateSuccess" class="alert fade-in" v-t="'settings.updateSuccess'"></p>
        <loading-button
          v-else
          class="primary settings-button"
          :loading="isLoading">
          <span v-t="'settings.update'"></span>
        </loading-button>
      </div>
    </form>
  </resizable-panel>
</template>

<script>
import Checkbox from '@components/commons/Checkbox.vue'
import ResizablePanel from '@components/commons/ResizablePanel.vue'

export default {
  components: {
    Checkbox,
    ResizablePanel
  },
  data() {
    return {
      companyModel: {
        hasOpenPolicy: false
      },
      errorMessage: null,
      isLoading: false,
      isOpenPolicyVisible: false,
      isUpdateSuccess: false
    }
  },
  computed: {
    isSettingsCompanyAccessRightsView() {
      return this.$route.name === 'settingsCompanyAccessRights'
    },
    hasOpenPolicy() {
      return this.$store.getters['account/getCompany'].hasOpenPolicy
    },
    accessRightsLabel() {
      const accessRightsKey = this.companyModel.hasOpenPolicy ? 'employees' : 'administrators'
      return this.$t(`settings.settingsCompanyAccessRights.${accessRightsKey}Description`)
    }
  },
  methods: {
    update() {
      this.isUpdateSuccess = false
      this.errorMessage = null
      this.isLoading = true

      this.$store.dispatch('company/update', this.companyModel)
        .then(response => {
          this.isLoading = false
          this.isUpdateSuccess = true
          setTimeout(() => {
            this.isOpenPolicyVisible = false
            this.isUpdateSuccess = false
          }, 1400)
        })
        .catch(error => {
          this.isLoading = false
          this.errorMessage = error
        })
    }
  },
  created() {
    this.companyModel.hasOpenPolicy = this.hasOpenPolicy
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/settings.scss";
</style>
