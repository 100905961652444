<template>
  <div class="loading">
    <svg width="30px" height="30px" viewBox="0 0 796 710" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="logo" fill="#48B6AC">
        <polygon id="top" fill-rule="nonzero" points="203 0 769 0 637 118">
          <animate attributeName="fill" values="#48B6AC; #B6E2DE; #48B6AC; #48B6AC; #48B6AC; #48B6AC; #48B6AC; #48B6AC; #48B6AC" keyTimes="0; 0.14; 0.28; 0.42; 0.56; 0.7; 0.84; 0.98; 1" repeatCount="indefinite" dur="0.75s"/>
        </polygon>
        <polygon id="right" points="796 26 735 618 665 146">
          <animate attributeName="fill" values="#48B6AC; #48B6AC; #B6E2DE; #48B6AC; #48B6AC; #48B6AC; #48B6AC; #48B6AC; #48B6AC" keyTimes="0; 0.14; 0.28; 0.42; 0.56; 0.7; 0.84; 0.98; 1" repeatCount="indefinite" dur="0.75s"/>
        </polygon>
        <polygon id="big-bottom" points="628 179 702 687 204 559">
          <animate attributeName="fill" values="#48B6AC; #48B6AC; #48B6AC; #B6E2DE; #48B6AC; #48B6AC; #48B6AC; #48B6AC; #48B6AC" keyTimes="0; 0.14; 0.28; 0.42; 0.56; 0.7; 0.84; 0.98; 1" repeatCount="indefinite" dur="0.75s"/>
        </polygon>
        <polygon id="bottom" points="34 710 166 592 630 710">
          <animate attributeName="fill" values="#48B6AC; #48B6AC; #48B6AC; #48B6AC; #B6E2DE; #48B6AC; #48B6AC; #48B6AC; #48B6AC" keyTimes="0; 0.14; 0.28; 0.42; 0.56; 0.7; 0.84; 0.98; 1" repeatCount="indefinite" dur="0.75s"/>
        </polygon>
        <polygon id="left" points="70 99 136 565 0 685">
          <animate attributeName="fill" values="#48B6AC; #48B6AC; #48B6AC; #48B6AC; #48B6AC; #B6E2DE; #48B6AC; #48B6AC; #48B6AC" keyTimes="0; 0.14; 0.28; 0.42; 0.56; 0.7; 0.84; 0.98; 1" repeatCount="indefinite" dur="0.75s"/>
        </polygon>
        <polygon id="big-top" points="102 14 599 149 173 533">
          <animate attributeName="fill" values="#48B6AC; #48B6AC; #48B6AC; #48B6AC; #48B6AC; #48B6AC; #B6E2DE; #48B6AC; #48B6AC" keyTimes="0; 0.14; 0.28; 0.42; 0.56; 0.7; 0.84; 0.98; 1" repeatCount="indefinite" dur="0.75s"/>
        </polygon>
      </g>
    </svg>
    <transition name="slide-fade" appear>
      <div class="loading-message" v-if="loadingMessage">{{loadingMessage}}</div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    animationDelay: {
      type: Number,
      default: 5000
    },
    messages: {
      type: Array
    }
  },
  data() {
    return {
      loadingMessage: null,
      loadingMessageIndex: 0,
      animationTimeout: null
    }
  },
  methods: {
    switchLoadingMessage() {
      if (!this.messages || !this.messages[this.loadingMessageIndex]) {
        return
      }
      if (!this.loadingMessage) {
        this.loadingMessage = this.messages[this.loadingMessageIndex]
        this.animationTimeout = setTimeout(this.switchLoadingMessage, this.animationDelay)
      }
      else {
        this.loadingMessage = null
        this.loadingMessageIndex = (this.loadingMessageIndex + 1) % this.messages.length
        this.animationTimeout = setTimeout(this.switchLoadingMessage, 600)
      }
    }
  },
  beforeDestroy() {
    if (this.animationTimeout) {
      clearTimeout(this.animationTimeout)
    }
  },
  mounted() {
    this.switchLoadingMessage()
  }
}
</script>

<style lang="scss" scoped>
.loading {
  width: 100%;
  text-align: center;
  padding: 50px 0;
}

.loading-message {
  color: $light-text-color;
  padding: 1.5em 0;
  opacity: 0.8;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 600ms ease;
}

.slide-fade-enter {
  transform: translateY(1em);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateY(-0.75em);
  opacity: 0;
}
</style>
