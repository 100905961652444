<template>
  <div class="grid-exclusion-button" v-if="$$isAdmin">
    <button
      @click="isGridExclusionVisible = true"
      class="secondary"
      v-t="'grid.editor.exclusion.action'"></button>
    <modal :visible="isGridExclusionVisible" @close="isGridExclusionVisible = false">
      <div class="grid-exclusion-modal">
        <h1 v-t="'grid.editor.exclusion.title'"></h1>
        <div v-t="'grid.editor.exclusion.intro'"></div>
        <EmployeesPicker
          v-model="includedEmployees"
          :fieldOnly="false"
          @select="selectEmployees" />
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Modal from '@components/commons/Modal.vue'
import EmployeesPicker from '@components/commons/EmployeesPicker.vue'

export default {
  components: {
    Modal,
    EmployeesPicker
  },
  data() {
    return {
      isGridExclusionVisible: false
    }
  },
  computed: {
    ...mapGetters({
      employees: 'employees/getEmployees'
    }),
    includedEmployees() {
      return this.employees.filter(e => !e.isExcluded)
    }
  },
  methods: {
    selectEmployees(selections) {
      const employees = selections.map(({ id, isSelected }) => ({ id, isExcluded: !isSelected }))
      this.$store.dispatch('employees/updateEmployees', employees)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";

.grid-exclusion-button {
  display: inline-block;
}

.grid-exclusion-modal {
  width: 650px;
  padding: 20px;
}
</style>
