<template>
  <div class="container">
    <resizable-panel :observer="isIntro">
      <div v-if="isIntro">
        <div class="introduction" v-html="$t('grid.onboarding.intro.onboardingIntroduction')"></div>
        <menu class="hero">
          <p v-t="'grid.onboarding.intro.pleaseImport'"></p>
          <router-link
            tag="button"
            class="secondary"
            :to="{name: 'importEmployees'}"
            v-t="'grid.onboarding.intro.importEmployees'">
          </router-link>
          <button class="primary" @click="submitIntro" v-t="'grid.onboarding.common.start'"></button>
        </menu>
      </div>
    </resizable-panel>
    <resizable-panel :observer="[isIntro, isGridStructureEditorVisible].join()">
      <div v-if="!isIntro && !isGridStructureEditorVisible">
        <menu class="float-right no-margin-top no-margin-bottom">
          <grid-exclusion-button />
        </menu>
        <h1 v-t="'grid.onboarding.intro.chooseTitle'"></h1>
        <p v-t="'grid.onboarding.intro.intro'"></p>
        <p class="quote" v-t="'grid.onboarding.intro.introQuote'"></p>
        <onboarding-component-selector v-model="selectedComponentsModel"></onboarding-component-selector>
        <p v-html="$t('grid.onboarding.intro.footer')"></p>
        <p class="alert" v-html="$t('grid.onboarding.intro.components.footer')"></p>
        <menu class="hero" v-if="!isCompleted">
          <p v-t="'grid.onboarding.intro.shallWeProceed'"></p>
          <loading-button
            class="primary"
            @click="submitComponents"
            :loading="isLoading">
            <span v-t="'common.continue'"></span>
          </loading-button>
        </menu>
        <menu class="hero" v-else>
          <p v-t="'grid.onboarding.intro.shallWeResetGrid'"></p>
          <button
            @click="toggleIsGridStructureEditorVisible"
            class="secondary"
            v-t="'common.cancel'" />
          <loading-button
            class="primary"
            @click="submitComponents"
            :loading="isLoading">
            <span v-t="'grid.onboarding.intro.resetGrid'"></span>
          </loading-button>
        </menu>
      </div>
    </resizable-panel>
    <div v-if="!isIntro && isGridStructureEditorVisible">
      <menu class="float-right no-margin-top no-margin-bottom">
        <button
          @click="toggleIsGridStructureEditorVisible($event)"
          class="secondary"
          v-t="'grid.onboarding.intro.reset'" />
        <grid-preview-button :grid="onboardingGrid" />
      </menu>
      <h1 v-t="'grid.onboarding.intro.title'"></h1>
      <p v-t="'grid.onboarding.levels.intro'"></p>
      <grid-structure-editor />
      <onboarding-continue-banner
        @submit="submitStructure"
        :label="$t('grid.onboarding.intro.shallWeProceed2')" />
    </div>
  </div>
</template>

<script>
import cloneDeepWith from 'lodash.clonedeepwith'
import ResizablePanel from '@components/commons/ResizablePanel.vue'
import OnboardingComponentSelector from '@components/grid/onboarding/OnboardingComponentSelector.vue'
import GridExclusionButton from '@components/grid/qualification/GridExclusionButton'
import GridPreviewButton from '@components/grid/viewer/GridPreviewButton'
import GridStructureEditor from '@components/grid/structure/GridStructureEditor'
import OnboardingContinueBanner from '@components/grid/onboarding/OnboardingContinueBanner.vue'

import animateScrollTo from 'animated-scroll-to'
import { mapGetters } from 'vuex'

export default {
  components: {
    OnboardingComponentSelector,
    OnboardingContinueBanner,
    ResizablePanel,
    GridExclusionButton,
    GridStructureEditor,
    GridPreviewButton
  },
  data() {
    return {
      isLoading: false,
      isGridStructureEditorVisible: false,
      selectedComponentsModel: cloneDeepWith(this.$store.getters['onboarding/getSelectedComponents'])
    }
  },
  computed: {
    ...mapGetters({
      isIntro: 'onboarding/isIntro',
      onboardingGrid: 'sandbox/onboardingGrid',
      onboardingProgress: 'onboarding/getProgressIndex'
    }),
    isCompleted() {
      return this.onboardingProgress >= 1
    }
  },
  methods: {
    submitIntro() {
      this.$store.dispatch('onboarding/submitIntro')
        .then(() => {
          animateScrollTo(0)
        })
    },
    submitComponents() {
      let shouldProceed = true
      if (this.onboardingProgress > 0) {
        shouldProceed = window.confirm(this.$t('grid.onboarding.intro.continueWarning'))
      }
      if (shouldProceed) {
        this.isLoading = true
        this.$store.dispatch('onboarding/submitComponents', this.selectedComponentsModel)
          .then(() => {
            this.isLoading = false
            this.toggleIsGridStructureEditorVisible()
          })
      }
    },
    submitStructure() {
      this.$router.push('/grid/onboarding/skills')
    },
    toggleIsGridStructureEditorVisible(event = null) {
      if (!event || !event.altKey) {
        this.isGridStructureEditorVisible = !this.isGridStructureEditorVisible
        animateScrollTo(0)
      }
      else {
        this.$store.dispatch('sandbox/toggleIsSandboxDebug')
      }
    }
  },
  created() {
    if (this.isCompleted) {
      this.toggleIsGridStructureEditorVisible()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/alert.scss";
@import "./src/styles/link.scss";
@import "./src/styles/button.scss";

.container {
  @include tab-container;
}

.introduction {
  &::v-deep .numeric-list {
    list-style-type: decimal;
  }

  &::v-deep li {
    line-height: 1.8em;
  }
}

.quote {
  @include font-large-size;
  border-left: 5px solid $graph-blue-color;
  padding: 0.5em 1em;
}
</style>
