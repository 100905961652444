<template>
  <div class="container">
    <h1 v-t="'variable.form.title'"></h1>
    <p v-t="'variable.intro'" class="preserve-lines"></p>
    <form @submit.prevent="submit" class="reveal">
      <div class="form-section form-three-panes">
        <div>
          <div class="input-label big" v-t="'variable.kinds.title'"></div>
          <RadioInputs
            :items="kinds"
            localePrefix="variable.kinds"
            v-model="variablePlanModel.kind"
            @input="onKindChange" />
        </div>
        <div>
          <div class="input-label big" v-t="'variable.recurrences.title'"></div>
          <RadioInputs
            :items="recurrences"
            localePrefix="variable.recurrences"
            :description="false"
            v-model="variablePlanModel.recurrence" />
        </div>
        <div>
          <div class="input-label big" v-t="'variable.form.name'"></div>
          <input
            ref="name"
            type="text"
            class="form-section"
            v-model="variablePlanModel.name"
            :placeholder="namePlaceholder">
          <div class="input-label big" v-t="'variable.form.description'"></div>
          <MarkdownTextEditor
            v-model="variablePlanModel.description"
            :rows="3"
            :showOKButton="false"
            :placeholder="$t('variable.form.descriptionPlaceholder')" />
        </div>
      </div>
      <p class="error-message fade-in" v-if="errorMessage">{{ errorMessage }}</p>
      <menu>
        <button
          type="button"
          class="secondary"
          @click="$router.go(-1)"
          v-t="'common.cancel'">
        </button>
        <loading-button
          class="primary"
          :disabled="isLoading"
          :loading="isLoading">
          <span v-t="'common.create'"></span>
        </loading-button>
      </menu>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { buildGroupKey, computePeriod } from '@/utils/variable'
import RadioInputs from '@components/commons/RadioInputs'
import MarkdownTextEditor from '@components/commons/MarkdownTextEditor'

export default {
  components: {
    MarkdownTextEditor,
    RadioInputs
  },
  data() {
    return {
      isLoading: false,
      errorMessage: null,
      variablePlanModel: {}
    }
  },
  computed: {
    ...mapGetters({
      getVariablePlanModel: 'variablePlans/getVariablePlanModel',
      kinds: 'variablePlans/getKinds',
      recurrences: 'variablePlans/getRecurrences'
    }),
    namePlaceholder() {
      return this.$t(`variable.kinds.${this.variablePlanModel.kind}.title`)
    }
  },
  methods: {
    initModel() {
      const groupKey = buildGroupKey()
      this.variablePlanModel = { ...this.getVariablePlanModel, groupKey }
      this.onKindChange()
    },
    onKindChange() {
      switch (this.variablePlanModel.kind) {
        case 'profit_sharing':
        case 'participation':
          this.variablePlanModel.recurrence = 'yearly'
          break
        case 'bonus':
          this.variablePlanModel.recurrence = 'none'
          break
        default:
          this.variablePlanModel.recurrence = 'monthly'
          break
      }
    },
    async submit() {
      this.errorMessage = null
      this.isLoading = true
      try {
        if (!this.variablePlanModel.name) {
          this.variablePlanModel.name = this.namePlaceholder
        }
        const period = computePeriod(this.variablePlanModel.recurrence)
        const model = { ...this.variablePlanModel, ...period }
        const { id } = await this.$store.dispatch('variablePlans/createVariablePlan', model)
        this.$router.push({ name: 'variablePlan', params: { id, highlightId: id, highlightClass: 'flash' } })
      }
      catch (e) {
        this.errorMessage = e
        this.isLoading = false
      }
    }
  },
  created() {
    this.initModel()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";

.container {
  @include container;
}

.form-three-panes {
  display: grid;
  grid-template-columns: 1fr 0.6fr 1fr;
  gap: 20px;
}
</style>
