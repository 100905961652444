<template>
  <div>
    <div v-if="currentReport">
      <component :is="currentReport.component" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BeeswarmGraph from '@components/graph/BeeswarmGraph.vue'
import ComboGraph from '@components/graph/ComboGraph.vue'
import DeviationGraph from '@components/graph/DeviationGraph.vue'
import DistributionGraph from '@components/graph/DistributionGraph.vue'
import EvolutionGraph from '@components/graph/EvolutionGraph.vue'
import GenderEqualityIndexGraph from '@components/graph/GenderEqualityIndexGraph.vue'
import GridGraph from '@components/graph/GridGraph.vue'
import LinearRegressionGraph from '@components/graph/LinearRegressionGraph.vue'
import PayrollGraph from '@components/graph/PayrollGraph.vue'
import TableGraph from '@components/graph/TableGraph.vue'

export default {
  components: {
    BeeswarmGraph,
    ComboGraph,
    DeviationGraph,
    DistributionGraph,
    EvolutionGraph,
    GenderEqualityIndexGraph,
    GridGraph,
    LinearRegressionGraph,
    PayrollGraph,
    TableGraph
  },
  computed: {
    ...mapGetters({
      currentReport: 'statistics/getCurrentReport'
    })
  },
  methods: {
    loadCurrentReport(id) {
      this.$store.dispatch('statistics/setCurrentReport', id)
    }
  },
  created() {
    this.loadCurrentReport(this.$route.params.id)
  },
  beforeRouteUpdate(to, _from, next) {
    this.loadCurrentReport(to.params.id)
    next()
  }
}
</script>
