<template>
  <div class="form">
    <h3 v-t="'settings.settingsCompanySynchronization.longTitle'"></h3>
    <p v-t="{path: 'settings.settingsCompanySynchronization.intro', args: {serviceNames: enabledIdentityProviderNames}}"></p>
    <div v-if="isSynchronizationEnabled">
      <h4 v-t="'settings.settingsCompanySynchronization.changes.changesTitle'"></h4>
      <SynchronizationChanges />
      <br>
      <h4 v-t="'settings.settingsCompanySynchronization.changes.changesHistoryTitle'"></h4>
      <SynchronizationChangesHistory />
    </div>
    <div v-else>
      <hr>
      <p v-html="$t('settings.settingsCompanySynchronization.tutorial')"></p>
      <router-link
        tag="button"
        class="primary settings-button"
        :to="{name: 'settingsCompanyIntegrations'}">
        <span v-t="'settings.settingsCompanyIntegrations.configure'"></span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SynchronizationChanges from '@components/settings/SynchronizationChanges.vue'
import SynchronizationChangesHistory from '@components/settings/SynchronizationChangesHistory.vue'

export default {
  components: {
    SynchronizationChanges,
    SynchronizationChangesHistory
  },
  computed: {
    ...mapGetters({
      isSynchronizationEnabled: 'synchronization/isSynchronizationEnabled',
      enabledIdentityProviderNames: 'synchronization/enabledIdentityProviderNames'
    })
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/settings.scss";

h4 {
  margin: 1em 0 0.4em;
}
</style>
