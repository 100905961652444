<template>
  <div
    class="employee-row">
    <name-thumbnail
      :employee="user"
      :inline="true"
      class="blue"
      @click.native="$router.push({name: 'employee', params: {id: user.id}})" />
    <div>
      <router-link
        :to="{name: 'employee', params: { id: user.id}}"
        class="colored-link">
        {{user.firstName}} {{user.lastName}}
      </router-link>
      <div class="email faded" :title="userEmail">{{userEmail}}</div>
    </div>
    <div
      class="faded updated-at"
      :class="{green: isUserUpdatedToday}">{{userUpdatedAt}}</div>
    <div>
      <!-- Admin dropdown -->
      <dropdown
        class="subtle-dropdown"
        :items="statusDropdownItems"
        @change="setStatus">
        <span
          v-t="isAdminLoading ? 'common.ellipsis' : `settings.settingsCompanyAccessRights.statuses.${user.status}`"
          :class="{green: user.status !== 'employee'}"></span>
        <span class="down" :class="{green: user.status !== 'employee'}">▾</span>
      </dropdown>
    </div>
    <div>
      <button
        v-for="thirdPartyUser in user.thirdPartyUsers"
        :key="thirdPartyUser.id"
        class="small-button subtle-button green"
        v-t="`settings.settingsCompanySynchronization.identityProviders.${thirdPartyUser.identityProvider}`"
        @click="$$openUrl(thirdPartyUser.profileUrl)"></button>
      <button
        v-if="!user.thirdPartyUsers.length"
        class="small-button subtle-button"
        @click="$router.push({name: 'settingsCompanySynchronization'})"
        v-t="'settings.settingsCompanyAccessRights.inactive'"></button>
    </div>
    <div class="menu" v-if="!isOneself">
      <!-- Revoke button -->
      <loading-button
        v-if="user.hasAccount"
        class="small-button red"
        :loading="isInvitationLoading"
        @click="revokeAccount">
        <span v-t="'settings.settingsCompanyAccessRights.revokeAccount'"></span>
      </loading-button>
      <div
        v-else-if="user.invitationToken"
        class="invited">
        <!-- Expired invitation button -->
        <button
          v-if="isInvitationExpired"
          class="small-button orange"
          v-t="'settings.settingsCompanyAccessRights.invitationExpired'">
        </button>
        <!-- Sent invitation button -->
        <button
          v-else
          class="small-button green"
          v-t="'settings.settingsCompanyAccessRights.invitationSent'">
        </button>
        <user-invitation-status
          :user="user"
          :isExpired="isInvitationExpired"
          @sendInvitation="sendInvitation" />
      </div>
      <!-- Invite button -->
      <loading-button
        v-else
        class="small-button green"
        :loading="isInvitationLoading"
        @click="sendInvitation">
        <span v-t="'settings.settingsCompanyAccessRights.invite'"></span>
      </loading-button>
      <!-- Sign-in As button -->
      <signin-as-button :employee="user" />
    </div>
    <div class="right faded" v-else v-t="'settings.settingsCompanyAccessRights.itsYou'"></div>
  </div>
</template>

<script>
import { dateDiff, parseDateUTC } from '@/utils/date'
import UserInvitationStatus from '@components/settings/UserInvitationStatus'
import SigninAsButton from '@components/settings/SigninAsButton'
import Dropdown from '@components/commons/Dropdown'
import NameThumbnail from '@components/commons/NameThumbnail'
import { mapGetters } from 'vuex'

export default {
  components: {
    Dropdown,
    SigninAsButton,
    NameThumbnail,
    UserInvitationStatus
  },
  props: ['user'],
  data() {
    return {
      isAdminLoading: false,
      isInvitationLoading: false
    }
  },
  computed: {
    ...mapGetters({
      userStatuses: 'account/userStatuses'
    }),
    isOneself() {
      return this.user.id === this.$store.getters['account/getUser'].id
    },
    isInvitationExpired() {
      return this.user.invitationDate &&
        dateDiff(this.user.invitationDate, new Date(), 'weeks') >= 1
    },
    statusDropdownItems() {
      return [{
        name: this.$t('settings.settingsCompanyAccessRights.status'),
        items: this.userStatuses.map(status => ({
          name: this.$t(`settings.settingsCompanyAccessRights.statuses.${status}`),
          value: status,
          selected: status === this.user.status
        }))
      }]
    },
    userEmail() {
      return this.user.thirdPartyUsers.length ? this.user.thirdPartyUsers[0].email : this.user.email
    },
    userUpdatedAt() {
      return parseDateUTC(this.user.updatedAt, false).local().format('DD/MM HH:mm')
    },
    isUserUpdatedToday() {
      return this.userUpdatedAt.includes(this.$options.filters.formatDate(new Date(), 'DD/MM'))
    }
  },
  methods: {
    async sendInvitation() {
      this.confirmEmail().then(async() => {
        if (confirm(this.$t('settings.settingsCompanyAccessRights.inviteConfirmation', { name: this.user.firstName }))) {
          this.isInvitationLoading = true

          try {
            await this.$store.dispatch('company/inviteUser', this.user)
          }
          catch (error) {
            error && alert(error)
          }
          finally {
            this.isInvitationLoading = false
          }
        }
      })
    },
    async setStatus(_, status) {
      const user = this.user
      const statusLabel = this.$t(`settings.settingsCompanyAccessRights.statuses.${status}`)

      if (confirm(this.$t('settings.settingsCompanyAccessRights.setStatus', { name: user.firstName, statusLabel }))) {
        this.isAdminLoading = true
        try {
          await this.$store.dispatch('company/setUserStatus', {
            user: this.user,
            status
          })
        }
        catch (error) {
          error && alert(error)
        }
        finally {
          this.isAdminLoading = false
        }
      }
    },
    async revokeAccount() {
      if (confirm(this.$t('settings.settingsCompanyAccessRights.revokeConfirmation', { name: this.user.firstName }))) {
        this.isInvitationLoading = true
        await this.$store.dispatch('company/revokeAccount', this.user)
        this.isInvitationLoading = false
      }
    },
    confirmEmail() {
      return new Promise((resolve) => {
        if (this.user.email) {
          resolve()
        }
        else {
          const email = window.prompt(this.$t('settings.settingsCompanyAccessRights.promptEmail', { name: this.user.firstName }), this.userEmail || '')
          if (email) {
            const employeeModel = { ...this.$store.getters['employees/getEmployee'](this.user.id), email }
            this.$store.dispatch('employees/updateEmployee', employeeModel)
              .then(() => {
                // Display user email in the list
                this.$store.dispatch('company/getUsers')
                resolve()
              })
              .catch(() => {
                alert(this.$t('settings.settingsCompanyAccessRights.promptEmailError'))
                this.$router.push({ name: 'updateEmployee', params: { id: this.user.id } })
              })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
@import "./src/styles/link.scss";

.email {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.menu {
  text-align: right;
}

.faded {
  @include font-small-size;
  color: $light-text-color;
}

.updated-at {
  @include line-regular-height;
}

.green {
  @include font-semibold;
  color: darken($clearteal-color, 5);
}

.invited {
  display: inline-block;
  position: relative;

  &:hover > .invitation-status {
    display: block;
  }
}

.subtle-button {
  border-color: transparent;
}

.subtle-dropdown::v-deep .dropdown-button {
  color: $light-text-color;
}
</style>
