<template>
  <div class="container">
    <div class="settings-menu">
      <div class="settings-menu-section">
        <h3>{{ getUser.firstName }}</h3>
        <ul>
          <router-link
            v-for="section in userSections"
            :key="section"
            tag="li"
            :class="section"
            active-class="selected"
            :to="{ name: section}">
            {{$t(`settings.${section}.title`)}}
          </router-link>
        </ul>
      </div>
      <template v-if="$$isAdmin">
        <hr>
        <div class="settings-menu-section">
          <h3>{{ $$companyName }}</h3>
          <ul>
            <router-link
              v-for="section in companySections"
              :key="section"
              tag="li"
              :class="section"
              active-class="selected"
              :to="{ name: section}">
              {{$t(`settings.${section}.title`)}}
            </router-link>
          </ul>
        </div>
      </template>
    </div>
    <div class="settings-container" :class="$route.name">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    const userSections = [
      'settingsUserInfo',
      'settingsUserEmail',
      'settingsUserPassword'
    ]
    const companySections = [
      'settingsCompanyInfo',
      'settingsCompanyBilling',
      'settingsCompanyAccessRights',
      'settingsCompanyCustomFields',
      'settingsCompanySynchronization',
      'settingsCompanyIntegrations'
    ]
    return { userSections, companySections }
  },
  computed: {
    ...mapGetters({
      getUser: 'account/getUser'
    })
  },
  beforeDestroy() {
    if (this.$$isAtLeastManager) {
      this.$store.dispatch('employees/getEmployees')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/settings.scss";

.container {
  @include container;
  padding: 0;
  display: grid;
  grid-template-columns: 350px 1fr;
}

.settings-menu {
  padding: $settings-margin * 2 $settings-margin * 2.5;
  border-right: $border;
}

.settings-container {
  padding: $settings-margin * 2 $settings-margin * 2.5;
  width: 100%;
  margin: 0 auto;

  &:not(.settingsCompanyAccessRights):not(.settingsCompanySynchronization) {
    background: url(~@/assets/settings-background.svg) no-repeat right $settings-margin * 1.5 bottom $settings-margin * 1.5;
    background-size: 150px;
  }

  &::v-deep {
    h3 {
      margin-bottom: 32px;
    }
  }
}

.settings-menu-section {
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li {
    padding-left: 30px;
    line-height: 50px;
    cursor: pointer;
    background: transparent none no-repeat left center;

    &:hover {
      color: darken($clearteal-color, 3);
    }

    &.selected {
      color: darken($clearteal-color, 3);
      @include font-semibold;
    }

    &.settingsUserInfo {
      background-image: url(~@/assets/settings-user.svg);

      &:hover,
      &.selected {
        background-image: url(~@/assets/settings-user-active.svg)
      }
    }

    &.settingsUserEmail {
      background-image: url(~@/assets/settings-email.svg);

      &:hover,
      &.selected {
        background-image: url(~@/assets/settings-email-active.svg)
      }
    }

    &.settingsUserPassword {
      background-image: url(~@/assets/settings-password.svg);

      &:hover,
      &.selected {
        background-image: url(~@/assets/settings-password-active.svg)
      }
    }

    &.settingsCompanyInfo {
      background-image: url(~@/assets/settings-company.svg);

      &:hover,
      &.selected {
        background-image: url(~@/assets/settings-company-active.svg)
      }
    }

    &.settingsCompanyBilling {
      background-image: url(~@/assets/settings-company-billing.svg);

      &:hover,
      &.selected {
        background-image: url(~@/assets/settings-company-billing-active.svg)
      }
    }

    &.settingsCompanyAccessRights {
      background-image: url(~@/assets/settings-access-rights.svg);

      &:hover,
      &.selected {
        background-image: url(~@/assets/settings-access-rights-active.svg)
      }
    }

    &.settingsCompanyTeams {
      background-image: url(~@/assets/settings-teams.svg);

      &:hover,
      &.selected {
        background-image: url(~@/assets/settings-teams-active.svg)
      }
    }

    &.settingsCompanyCustomFields {
      background-image: url(~@/assets/settings-custom-fields.svg);

      &:hover,
      &.selected {
        background-image: url(~@/assets/settings-custom-fields-active.svg)
      }
    }

    &.settingsCompanySynchronization {
      background-image: url(~@/assets/settings-synchronization.svg);

      &:hover,
      &.selected {
        background-image: url(~@/assets/settings-synchronization-active.svg)
      }
    }

    &.settingsCompanyIntegrations {
      background-image: url(~@/assets/settings-integrations.svg);

      &:hover,
      &.selected {
        background-image: url(~@/assets/settings-integrations-active.svg)
      }
    }
  }
}

hr {
  display: block;
  margin: 20px 0 33px 0;
  border: 0;
  border-top: $border;
  height: 1px;
}
</style>
