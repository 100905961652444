<template>
  <div>
    <div class="container" v-if="reviewModel">
      <menu class="float-right no-margin-top no-margin-bottom">
        <button
          v-if="isCreator && reviewModel.status === 'draft'"
          class="destructive"
          v-t="'common.remove'"
          @click="removeReview">
        </button>
        <button
          class="secondary"
          v-t="'common.back'"
          @click="$router.push({name: 'reviews', params: {highlightId: $route.params.highlightId, highlightClass: $route.params.highlightClass}})">
        </button>
        <template v-if="$$isAdmin">
          <Dropdown
            class="review-statuses-dropdown large description"
            :right="true"
            :items="statusesDropdownItems"
            @change="(_, status) => setStatus(status)">
            <span class="review-status" :class="reviewModel.status">
              <span v-t="`reviews.statuses.${reviewModel.status}.title`"></span>
              <span class="down">▾</span>
            </span>
          </Dropdown>
          <LoadingButton
            v-if="reviewModel.status != 'draft'"
            :loading="isCreatingWagePlan"
            :class="reviewModel.status === 'closed' ? 'primary' : 'secondary'"
            @click="createWagePlan">
            Créer un plan d’augmentation
          </LoadingButton>
          <button
            v-if="reviewModel.status === 'draft'"
            class="primary"
            @click="setStatus('ongoing')">
            Démarrer
          </button>
          <button
            v-else-if="reviewModel.status === 'ongoing'"
            class="primary"
            @click="setStatus('closed')">
            Terminer
          </button>
        </template>
      </menu>
      <h1>
        <InlineEditor
          v-model="reviewModel.name"
          :placeholder="reviewModel.name"
          :disabled="isSettingsDisabled"
          @submit="submit()" />
      </h1>
      <MarkdownTextEditor
        class="blue"
        v-model="reviewModel.description"
        :placeholder="reviewModel.description"
        :showInlineEditButton="true"
        :showAddButton="true"
        :autoDismiss="true"
        :showToolbar="true"
        :disabled="isSettingsDisabled"
        @submit="submit()" />
      <div>
        <TabBar
          v-if="tabs.length > 1"
          class="tab-bar-wide"
          :tabs="tabs.map(t => t.name)"
          :activeTab="selectedTab.name"
          @tabSelected="selectTab">
        </TabBar>
        <div v-if="selectedTab.id == 'settings'">
          <p v-if="isReviewClosed || isSettingsDisabled" class="alert no-margin-bottom">La campagne est terminée, les paramètres ne sont plus modifiables.</p>
          <ReviewSteps :review="reviewModel" />
          <ReviewEmployees :review="reviewModel" @change="initModel" />
        </div>
        <div v-else-if="selectedTab.id == 'reviews'">
          <p v-if="isReviewClosed" class="alert no-margin-bottom">La campagne est terminée, les évaluations ne sont plus modifiables.</p>
          <ReviewSteps :isSummary="true" :review="reviewModel" />
          <ReviewWages v-if="reviewModel.employees.length" :review="reviewModel" />
          <p
            v-else-if="!$$isAdmin"
            class="alert slow-fade-in">
            <strong>😴 &nbsp;Vous n’avez rien à remplir&nbsp;!</strong><br><br>
            Cette campagne d’évaluation n’est ouverte qu’à une partie des salariés, vous n’avez rien à remplir cette fois-ci.
          </p>
          <menu v-else class="hero placeholder no-margin-bottom">
            <p class="no-margin-top">Ajoutez des salariés à la campagne pour pouvoir suivre leurs évaluations.</p>
            <button
              class="primary" @click="selectTab(tabs[0].name)">Commencer</button>
          </menu>
        </div>
      </div>
    </div>
    <LoadingView v-else />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { setAppTitle } from '@/utils/route'
import InlineEditor from '@components/commons/InlineEditor.vue'
import LoadingButton from '@/components/commons/LoadingButton.vue'
import LoadingView from '@components/commons/LoadingView.vue'
import MarkdownTextEditor from '@components/commons/MarkdownTextEditor.vue'
import Dropdown from '@components/commons/Dropdown.vue'
import TabBar from '@components/commons/TabBar.vue'
import ReviewEmployees from '@components/reviews/ReviewEmployees.vue'
import ReviewWages from '@components/reviews/ReviewWages.vue'
import ReviewSteps from '@components/reviews/ReviewSteps.vue'
import { isReviewClosed, isReviewSettingsDisabled, REVIEW_STATUSES } from '@/utils/reviews'

export default {
  components: {
    InlineEditor,
    LoadingButton,
    LoadingView,
    MarkdownTextEditor,
    Dropdown,
    ReviewEmployees,
    ReviewWages,
    ReviewSteps,
    TabBar
  },
  data() {
    return {
      isLoading: false,
      isCreatingWagePlan: false,
      reviewModel: null,
      updatedAt: null,
      selectedTab: {}
    }
  },
  computed: {
    ...mapGetters({
    }),
    isReviewView() {
      return this.$route.name === 'review'
    },
    isCreator() {
      return this.$$isCreator(this.reviewModel)
    },
    statusesDropdownItems() {
      return [{
        name: this.$t('reviews.statuses.title'),
        items: REVIEW_STATUSES.map(status => ({
          name: this.$t(`reviews.statuses.${status}.title`),
          label: this.$t(`reviews.statuses.${status}.description`),
          value: status,
          selected: this.reviewModel.status === status
        }))
      }]
    },
    tabs() {
      const tabs = [
        {
          name: 'Paramètres',
          id: 'settings',
          hidden: !this.$$isAdmin
        },
        {
          name: 'Évaluations',
          id: 'reviews'
        }
      ]
      return tabs.filter(tab => !tab.hidden)
    },
    isSettingsDisabled() {
      return isReviewSettingsDisabled(this.reviewModel)
    },
    isReviewClosed() {
      return isReviewClosed(this.reviewModel)
    }
  },
  methods: {
    async initModel() {
      this.reviewModel = await this.$store.dispatch('reviews/getReview', this.$route.params.id)
      this.$store.commit('reviews/setCurrentReview', this.reviewModel)
    },
    initTab() {
      const hashTab = this.tabs.find(t => t.id === location.hash.substr(5))
      this.selectedTab = hashTab || this.tabs[this.isSettingsDisabled ? 1 : 0] || this.tabs[0]
    },
    setStatus(status) {
      if ((this.reviewModel.status !== status) && window.confirm(this.$t(`reviews.statuses.${status}.confirm`))) {
        this.reviewModel.status = status
        this.submit()
      }
    },
    selectTab(name) {
      this.selectedTab = this.tabs.find(t => t.name === name)
      history.replaceState(null, null, '#tab-' + this.selectedTab.id)
    },
    async submit() {
      this.$v.$touch()
      if (this.$v.$error || this.isLoading) {
        return
      }
      this.isLoading = true
      try {
        await this.$store.dispatch('reviews/updateReview', this.reviewModel)
        this.updatedAt = this.$options.filters.formatCalendar(new Date())
      }
      catch (error) {
        error && alert(error)
      }
      finally {
        this.isLoading = false
      }
    },
    async removeReview() {
      if (window.confirm('La suppression de la campagne est irréversible. Souhaitez-vous continuer ?')) {
        await this.$store.dispatch('reviews/removeReview', this.reviewModel)
        this.$router.push({ name: 'reviews' })
      }
    },
    async createWagePlan() {
      if (window.confirm('Un plan d’augmentation sera créé à partir des évaluations saisies dans cette campagne. Souhaitez-vous continuer ?')) {
        this.isCreatingWagePlan = true
        const { id } = await this.$store.dispatch('reviews/createWagePlan', this.reviewModel)
        this.isCreatingWagePlan = false
        this.$router.push({ name: 'wagePlan', params: { id } })
      }
    }
  },
  async created() {
    try {
      await this.initModel()
      this.initTab()
      setAppTitle(this.$route)
    }
    catch (e) {
      this.$router.push({ name: 'reviews' })
    }
  },
  validations() {
    return {
      reviewModel: {
        name: { required }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/alert.scss";
@import "./src/styles/animation.scss";
@import "./src/styles/badge.scss";
@import "./src/styles/button.scss";

.container {
  @include container;
}

h1 {
  display: flex;
  gap: 1em;
  align-items: center;
}

.review-status {
  @include font-medium;

  &.draft {
    color: $orange-color;

    &.bg {
      background: $lightorange-color;
    }
  }

  &.ongoing {
    color: lighten($graph-darkblue-color, 10);

    &.bg {
      background: darken($graph-lightblue-color, 2);
    }
  }

  &.closed {
    color: darken($clearteal-color, 5);

    &.bg {
      background: $lightteal-color;
    }
  }
}

.review-statuses-dropdown::v-deep {
  .dropdown-content {
    width: 500px;
  }

  .dropdown-button {
    box-shadow: inset 0px 0px 0px 1px $clearteal-color;
    border: none;

    span {
      @include font-smaller-size;
      text-transform: uppercase;
    }
  }

  .dropdown-item:nth-of-type(2) {
    @extend .review-status, .draft;
  }

  .dropdown-item:nth-of-type(3) {
    @extend .review-status, .ongoing;
  }

  .dropdown-item:nth-of-type(4) {
    @extend .review-status, .closed;
  }

  .dropdown-item .right-label {
    color: $text-color;
  }
}

.tab-bar-wide::v-deep {
  margin-top: 1.5em;

  .header {
    background: transparent;
  }

  .border {
    margin: 0 -20px;
  }
}

.hero.placeholder {
  @extend .badge-block;
  display: block;
  margin: 20px 0;
  padding: 1em 1em;
}
</style>
