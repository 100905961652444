import { create, all } from 'mathjs'
import sortBy from 'lodash.sortby'

const PERCENT_SYMBOL = 'PERCENT'
const OF_SYMBOL_REGEX = /^(des|de|du|of)_/ig

const config = {}
const math = create(all, config)

// Allow single quotes (', ’) in Symbols (side-effect: no more `'` operator)
const isAlphaOriginal = math.parse.isAlpha
math.parse.isAlpha = function(c, cPrev, cNext) {
  return isAlphaOriginal(c, cPrev, cNext) || ['\'', '’'].includes(c)
}

function isImplicitMultiplyNode(node) {
  return node.isOperatorNode && node.implicit && node.fn === 'multiply'
}

function isPercentNode(node) {
  return isImplicitMultiplyNode(node) &&
    node.args[0].isConstantNode &&
    node.args[1].isSymbolNode &&
    node.args[1].name === PERCENT_SYMBOL
}

function isIndicatorNode(node, path, _parent) {
  return node.isSymbolNode &&
    path !== 'fn'
}

function transformFormula(formula) {
  return formula.transform((node, path, parent) => {
    let [a, b] = node.args || []
    // Replace `A + 10 %` → A + A * 10%
    if (node.isOperatorNode && node.fn === 'add' && isPercentNode(b)) {
      a = transformFormula(a)
      b = new math.OperatorNode('*', 'multiply', [a, new math.ConstantNode(b.args[0].value * 0.01)])
      return new math.OperatorNode('+', 'add', [a, b])
    }
    // Replace `10 %` → 0.01
    else if (isPercentNode(node)) {
      return new math.ConstantNode(a.value * 0.01)
    }
    // Replace `of A` → A
    else if (node.isSymbolNode && node.name.match(OF_SYMBOL_REGEX)) {
      node.name = node.name.replace(OF_SYMBOL_REGEX, '')
    }
    // Use title case if lowercase
    if (isIndicatorNode(node, path, parent) && node.name.toLowerCase() === node.name) {
      node.name = node.name.charAt(0).toUpperCase() + node.name.slice(1)
    }
    return node
  })
}

export function parseFormula(expr) {
  if (!expr) {
    return null
  }

  const cleanExpr = expr
    .replace(/\s+/g, ' ') // Handle multiple whitespace
    .replace(/^\s$/g, '') // Clean space-only formula
    .replace(/[€£$]/g, '') // Strip currency symbols
    .replace(/,([0-9])/g, '.$1') // Replace french decimal delimiter `,` by `.`
    .replace(/([0-9]) ([0-9])/g, '$1$2') // Replace french thousand delimiter `1 000` by `1000`
    .replace(/(\s|\d)x(\s|\d)/g, '$1 * $2') // Replace single `x` by `*`
    .replace(/([a-zA-Z\u00C0-\u024F]) ([0-9]+)/gu, '$1_$2') // Allow number suffix in symbols
    .replace(/([a-zA-Z\u00C0-\u024F]) (?=[a-zA-Z\u00C0-\u024F])/gu, '$1_') // Replace spaces in symbols by `_` (see https://stackoverflow.com/a/280762 & https://stackoverflow.com/a/39134560)
    .replace(/(\s|\d)%/g, `$1 ${PERCENT_SYMBOL}`) // Replace `%` by `PERCENT` symbol
  const formula = math.parse(cleanExpr)
  return cleanExpr ? transformFormula(formula) : null
}

export function extractFormulaSymbols(formula) {
  const formulaSymbols = []
  if (formula && formula.traverse) {
    formula.traverse((node, path, parent) => {
      if (isIndicatorNode(node, path, parent) && !formulaSymbols.includes(node.name) && node.name !== PERCENT_SYMBOL) {
        formulaSymbols.push(node.name.replace(/_/g, ' '))
      }
    })
  }
  return formulaSymbols
}

export function explainFormula(parsedFormula, scope) {
  if (parsedFormula && parsedFormula.traverse) {
    let explainedFormula = parsedFormula.toString({ implicit: 'show', parenthesis: 'all', notation: 'fixed' })
    // Sort by largest indicators to avoid issues when indicator name is included in another one
    sortBy(Object.keys(scope), i => -i.length).forEach(indicator => {
      // Replace all occurrences using split + join
      explainedFormula = explainedFormula.split(indicator).join(scope[indicator])
    })
    return explainedFormula
  }
}

// Takes complete list of indicators and returns a formula scope
export function buildFormulaScope(indicators) {
  return indicators.reduce((memo, { name, value }) => {
    memo[name.replace(/ /g, '_')] = value
    return memo
  }, {})
}

// Takes a compiled formula and a scope
// Returns a result (Number or Boolean)
// Raises if anything is wrong
export function evaluateFormula(compiledFormula, scope) {
  if (compiledFormula && compiledFormula.evaluate) {
    return compiledFormula.evaluate(scope)
  }
}
