<template>
  <div v-if="$$isAdmin" class="access-rights-button-container">
    <button
      class="access-rights-button secondary"
      @click="isModalVisible = true">
      {{$t('employees.employee.accessRights.title')}}
      <span class="down"></span>
    </button>

    <modal class="overflow-visible" :visible="isModalVisible" @close="close">
      <div class="access-rights-modal">
        <h1 v-t="'employees.employee.accessRights.title'"></h1>
        <div v-if="!employee.isAlumni">
          <p>{{$t('employees.employee.accessRights.intro', {firstName: employee.firstName})}}</p>
          <UserAccessRightsTable :user="employee" />
        </div>
        <p class="alert" v-else v-t="'employees.employee.accessRights.alumniNotice'"></p>
        <p class="preserve-lines no-margin-bottom" v-html="$t('employees.employee.accessRights.footer')"></p>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '@components/commons/Modal.vue'
import UserAccessRightsTable from '@components/settings/UserAccessRightsTable'

export default {
  components: {
    Modal,
    UserAccessRightsTable
  },
  props: {
    employee: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      isModalVisible: false
    }
  },
  methods: {
    close() {
      this.isModalVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/alert.scss";
@import "./src/styles/button.scss";

.access-rights-button-container {
  display: inline-block;
}

.access-rights-button {
  .down {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: transparent url(~@/assets/icon-lock-green.svg) no-repeat
      center center;
    background-size: contain;
    vertical-align: -1px;
    margin-left: 3px;
  }
}

.access-rights-modal {
  width: 650px;
  padding: 20px;

  h1 {
    background: transparent url(~@/assets/icon-lock.svg) no-repeat
      left center;
    padding-left: 30px;
  }
}
</style>
