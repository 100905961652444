<template>
  <div>
    <div class="form settings-form small">
      <h3 v-t="'settings.passwordChange'"></h3>
      <form @submit.prevent="update">
        <div class="form-section">
          <div class="input-label" v-t="'settings.oldPassword'"></div>
          <input
            type="password"
            v-model="passwordChangeModel.currentPassword"
            :placeholder="$t('account.passwordPlaceholder')"
            :class="{'input-error' : $v.passwordChangeModel.currentPassword.$error}">
        </div>
        <div class="form-section">
          <div class="input-label" v-t="'settings.newPassword'"></div>
          <input
            type="password"
            v-model="passwordChangeModel.newPassword"
            :placeholder="$t('account.passwordPlaceholder')"
            :class="{'input-error' : $v.passwordChangeModel.newPassword.$error}">
        </div>
        <div>
          <p v-if="updateSuccess" class="alert" v-t="'settings.passwordChangeSuccess'"></p>
          <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
          <loading-button
            class="primary settings-button"
            :loading="isLoading">
            <span v-t="'settings.update'"></span>
          </loading-button>

        </div>
      </form>
    </div>
    <br><br>
    <div class="left" v-t="'settings.sessionDestroy'"></div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      passwordChangeModel: {
        currentPassword: null,
        newPassword: null
      },
      errorMessage: null,
      updateSuccess: false,
      isLoading: false
    }
  },
  computed: {
    user() {
      return this.$store.getters['account/getUser']
    }
  },
  methods: {
    update() {
      this.$v.$touch()

      if (!this.$v.$error) {
        this.updateSuccess = false
        this.errorMessage = null
        this.isLoading = true

        this.$store.dispatch('account/updateAccountPassword', this.passwordChangeModel)
          .then(response => {
            this.isLoading = false
            this.updateSuccess = true

            this.$v.$reset()
            this.passwordChangeModel.currentPassword = null
            this.passwordChangeModel.newPassword = null
          })
          .catch(error => {
            this.isLoading = false
            this.errorMessage = error
          })
      }
    }
  },
  validations() {
    return {
      passwordChangeModel: {
        currentPassword: { required },
        newPassword: { required }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/settings.scss";
</style>
