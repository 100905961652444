<template>
  <div class="mini-interval-container">
    <div class="cursor" :class="{warning}" :style="style"></div>
    <div class="tick"></div>
    <div class="mini-interval"></div>
  </div>
</template>

<script>
export default {
  props: {
    employee: Object,
    // Pass true to show current salary instead of post grid one
    useCurrentSalary: Boolean
  },
  computed: {
    interval() {
      return this.employee.postGridWageDetails.summary.salary.interval
    },
    percent() {
      if (this.interval) {
        let { percent, min, max, med } = this.interval
        if (this.useCurrentSalary) {
          const currentSalary = this.employee.currentSalary
          percent =
              currentSalary >= med
                ? 0.5 + 0.5 * (currentSalary - med) /
                (max - med)
                : 0.5 * (currentSalary - min) /
              (med - min)
        }
        return Math.max(Math.min(percent, 1.05), -0.05)
      }
      else {
        return 0
      }
    },
    warning() {
      return this.percent < 0 || this.percent > 1
    },
    style() {
      const width = 55 - 2
      const position = Math.round(this.percent * width)
      return `left: ${position}px`
    }
  }
}
</script>

<style lang="scss" scoped>
.mini-interval-container {
  width: 55px;
  height: 9px;
  position: relative;
  padding: 0;
  box-sizing: content-box;
  margin: 1px auto 0;
}

.cursor {
  width: 3px;
  height: 11px;
  top: -1px;
  border-radius: 5px;
  background: lighten($graph-purple-color, 3);
  position: absolute;
  z-index: 2;
  box-shadow: 0 1px 0 lighten($graph-purple-color, 32);
  transition: all 300ms;

  &.warning {
    background: darken($red-color, 7);
    box-shadow: 0 1px 0 lighten($red-color, 25);
  }
}

.tick {
  width: 2px;
  height: 9px;
  top: 0;
  left: 27px;
  border-radius: 6px;
  background: lighten($graph-purple-color, 20);
  position: absolute;
  z-index: 1;
}

.mini-interval {
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background: lighten($graph-purple-color, 30);
  box-shadow: 0 1px 0 lighten($graph-purple-color, 20);
}
</style>
