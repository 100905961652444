<template>
  <div class="synchronization-changes" v-if="isSynchronizationEnabled">
    <resizable-panel :observer="[successMessage, errorMessage]">
      <p class="alert fade-in" v-if="successMessage">{{successMessage}}</p>
      <p class="error-message" v-if="errorMessage">{{errorMessage}}</p>
    </resizable-panel>
    <div v-if="changes">
      <div v-if="changes.length" class="changes-table">
        <synchronization-change-row
          v-for="(change, i) in changes"
          :showDate="true"
          :key="i"
          :change="change"
          :header="formatHeader(changes, i)" />
          <div
            v-if="showLoadMore"
            class="load-more"
            :class="{disabled: isLoading}"
            v-t="'common.showMore'"
            @click="loadChanges(true)"></div>
      </div>
      <p v-else class="light-text" v-t="'settings.settingsCompanySynchronization.changes.noChange'"></p>
    </div>
    <loading-view
      v-else-if="isLoading"
      :messages="[1, 2, 3].map(i => $t(`settings.settingsCompanySynchronization.loading.message${i}`))" />
  </div>
</template>

<script>
import animateScrollTo from 'animated-scroll-to'
import { mapGetters } from 'vuex'
import LoadingView from '@components/commons/LoadingView'
import SynchronizationChangeRow from '@components/settings/SynchronizationChangeRow'
import ResizablePanel from '@components/commons/ResizablePanel'

export default {
  components: {
    LoadingView,
    ResizablePanel,
    SynchronizationChangeRow
  },
  data() {
    return {
      errorMessage: null,
      successMessage: null
    }
  },
  computed: {
    ...mapGetters({
      changes: 'synchronization/changesHistory',
      changesHistoryTotal: 'synchronization/changesHistoryTotal',
      showLoadMore: 'synchronization/showChangesHistoryLoadMore',
      isLoading: 'synchronization/isChangesHistoryLoading',
      isSynchronizationEnabled: 'synchronization/isSynchronizationEnabled'
    })
  },
  methods: {
    formatHeader(changes, i) {
      if (i === 0) {
        const count = changes.length
        const total = this.changesHistoryTotal

        return [
          this.$tc('settings.settingsCompanySynchronization.changes.changes', count, { count }),
          total
        ].filter(t => t).join(' / ')
      }
    },
    handleError(error) {
      animateScrollTo(0).then(() => {
        this.errorMessage = error || this.$t('settings.settingsCompanySynchronization.changes.genericError')
      })
    },
    async loadChanges(loadMore) {
      try {
        this.errorMessage = null
        await this.$store.dispatch('synchronization/getChangesHistory', loadMore)
      }
      catch (error) {
        this.handleError(error)
      }
    }
  },
  created() {
    this.loadChanges()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/alert.scss";
@import "./src/styles/settings.scss";

.load-more {
  text-align: center;
  text-transform: uppercase;
  @include font-bold;
  @include font-smaller-size;
  color: $clearteal-color;
  line-height: 3em;
  cursor: pointer;

  &:not(.disabled):hover {
    background: $lightteal-color;
  }

  &.disabled {
    cursor: default;
    color: $light-text-color;
  }
}
</style>
