<template>
  <div>
    <menu class="float-right no-margin-top">
      <dropdown :items="sortDropdownItems" @change="setSort">
        <span :class="'arrow-' + sortOrder" v-t="`variable.form.${sortKey}`"></span>
      </dropdown>
      <input
        ref="searchQuery"
        type="search"
        spellcheck="false"
        :placeholder="$t('employees.search')"
        @input="onSearchQueryChange"
        @keydown="onSearchKeyDown($event)" />
      <div class="button-group">
        <router-link
          tag="button"
          class="secondary"
          :to="{name: 'addVariablePlan'}"
          v-t="'variable.placeholder.action'">
        </router-link>
      </div>
    </menu>
    <h1 v-t="'variable.longTitle'"></h1>
    <div class="variables-plans" v-if="groupedVariablePlans.length">
      <VariablePlan
        v-for="groupedVariablePlan in sortedGroupedVariablePlans"
        :key="groupedVariablePlan.id"
        :groupedVariablePlan="groupedVariablePlan" />
      <p v-if="!sortedGroupedVariablePlans.length" v-t="'variable.placeholder.notFound'"></p>
      <div class="variable-plan-add">
        <p
          class="no-margin-top no-margin-bottom preserve-lines"
          v-t="'variable.placeholder.footer'"></p>
        <router-link
          tag="button"
          class="primary"
          :to="{name: 'addVariablePlan'}"
          v-t="'variable.placeholder.action'">
        </router-link>
      </div>
    </div>
    <div class="create-variable-plan" v-else-if="!isLoading">
      <p v-html="$t('variable.intro')" class="preserve-lines"></p>
      <menu class="hero">
        <router-link
          tag="button"
          class="primary"
          :to="{name: 'addVariablePlan'}"
          v-t="'variable.placeholder.action'">
        </router-link>
      </menu>
    </div>
    <LoadingView v-else />
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import { mapGetters } from 'vuex'
import { filterByQuery, sortByKeys } from '@/utils/string'
import Dropdown from '@components/commons/Dropdown'
import LoadingView from '@components/commons/LoadingView'
import VariablePlan from '@components/variable/VariablePlan'

const CW_VARIABLE_SORT_KEY = 'CW_VARIABLE_SORT_KEY'
const CW_VARIABLE_SORT_ORDER = 'CW_VARIABLE_SORT_ORDER'
const CW_VARIABLE_SEARCH_QUERY = 'CW_VARIABLE_SEARCH_QUERY'

export default {
  components: {
    Dropdown,
    LoadingView,
    VariablePlan
  },
  data() {
    const defaults = {
      isLoading: false,
      searchQuery: null,

      sortItems: ['name', 'startDate', 'endDate', 'variableWagesCount'],
      sortKey: 'name',
      sortOrder: 1
    }

    if (defaults.sortItems.includes(localStorage[CW_VARIABLE_SORT_KEY])) {
      defaults.sortKey = localStorage[CW_VARIABLE_SORT_KEY]
    }
    if (['-1', '1'].includes(localStorage[CW_VARIABLE_SORT_ORDER])) {
      defaults.sortOrder = +localStorage[CW_VARIABLE_SORT_ORDER]
    }
    if (sessionStorage[CW_VARIABLE_SEARCH_QUERY]) {
      defaults.searchQuery = sessionStorage[CW_VARIABLE_SEARCH_QUERY]
    }

    return defaults
  },
  computed: {
    ...mapGetters({
      groupedVariablePlans: 'variablePlans/getGroupedVariablePlans'
    }),
    filteredGroupedVariablePlans() {
      return filterByQuery(this.groupedVariablePlans, this.searchQuery, '_searchKey')
    },
    sortedGroupedVariablePlans() {
      return sortByKeys(this.filteredGroupedVariablePlans, this.sortKey, this.sortOrder)
    },
    sortDropdownItems() {
      return [{
        name: this.$t('employees.sort.property'),
        value: 'sortKey',
        items: this.sortItems.map(item => ({
          name: this.$t(`variable.form.${item}`),
          value: item,
          selected: this.sortKey === item
        }))
      }, {
        name: this.$t('employees.sort.order'),
        value: 'sortOrder',
        items: [{
          name: this.$t('employees.sort.ascending'),
          value: 1,
          selected: this.sortOrder === 1
        },
        {
          name: this.$t('employees.sort.descending'),
          value: -1,
          selected: this.sortOrder === -1
        }]
      }]
    }
  },
  methods: {
    async loadVariablePlans() {
      this.isLoading = true
      try {
        await this.$store.dispatch('variablePlans/getVariablePlans')
      }
      catch (_) {}
      this.isLoading = false
    },
    onSearchQueryChange: debounce(function(event) {
      this.searchQuery = event.target.value
    }, 300),
    onSearchKeyDown(event) {
      if (event.key === 'Enter' && this.sortedGroupedVariablePlans.length) {
        this.$router.push({ name: 'variablePlan', params: { id: this.sortedGroupedVariablePlans[0].id } })
      }
    },
    setSort(key, value) {
      switch (key) {
        case 'sortKey':
          localStorage[CW_VARIABLE_SORT_KEY] = value
          this.sortKey = value
          break
        case 'sortOrder':
          localStorage[CW_VARIABLE_SORT_ORDER] = value
          this.sortOrder = value
          break
      }
    }
  },
  beforeDestroy() {
    if (this.searchQuery) {
      sessionStorage[CW_VARIABLE_SEARCH_QUERY] = this.searchQuery
    }
    else {
      delete sessionStorage[CW_VARIABLE_SEARCH_QUERY]
    }
  },
  mounted() {
    this.loadVariablePlans()

    if (this.$route.params.query) {
      this.searchQuery = this.$route.params.query
    }
    if (this.searchQuery) {
      this.$refs.searchQuery.value = this.searchQuery
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";

h1 {
  margin-bottom: 20px;
}

.variable-plan-add {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  border-top: 1px solid $graph-inner-border-color;
  padding-top: 1em;
}

input[type="search"] {
  display: inline-block;
  width: 190px;
}

.arrow-1::after,
.arrow--1::after {
  content: " ";
  text-align: left;
  display: inline-block;
  vertical-align: sub;
  width: 16px;
  height: 16px;
  margin-left: 0.25em;
  background: transparent url(~@/assets/arrow-down.svg) no-repeat center center;
  opacity: 0.8;
}

.arrow-1::after {
  transform: scaleY(-1);
}

.dropdown::v-deep .dropdown-button:hover .arrow-1::after,
.dropdown::v-deep .dropdown-button:focus .arrow-1::after,
.dropdown::v-deep .dropdown-button:hover .arrow--1::after,
.dropdown::v-deep .dropdown-button:focus .arrow--1::after {
  background-image: url(~@/assets/arrow-down-active.svg);
}
</style>
