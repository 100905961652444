import Vue from 'vue'
import i18n from '@/i18n'
import api from '@/store/api'
import cloneDeepWith from 'lodash.clonedeepwith'
import flatten from 'lodash.flatten'
import sortBy from 'lodash.sortby'
import uniq from 'lodash.uniq'
import moment from 'moment'
import {
  availableComponentsForLinkedComponent,
  flattenedComponents,
  getWageDetails,
  getWagesDetailsSummary,
  gridFormulaForRemunerationType,
  isSandboxGridValid,
  isSandboxWagesValid,
  isSandboxWageValid,
  isWagesSimilar,
  reusableComponentsForRemunerationType,
  transferWage
} from '@/utils/grid'

import {
  buildDomainId,
  extractDomainId,
  normalizedGrid,
  denormalizedGrid,
  normalizedComponent,
  normalizedLevel,
  normalizedSandboxWages
} from '@/store/schema'
import { sequentialDispatch } from '@/utils/store'
import { buildSearchTerms, sortByKeys } from '@/utils/string'
import { getFlatComponent, getReachedLevel, findSkills } from '@/utils/skills'
const ONBOARDING_GRID_PREFIX = '$$build_'
const SANDBOX_GRID_PREFIX = '$$sandbox_'

const state = {
  gridId: '',
  onboardingGridId: '',
  grids: {}, // {1: { name: 'A grid', components: [1, …] } }
  components: {}, // { 1: { name: 'A component', levels: [1, …], linkedComponents: [2, …] }, 2: {…}, … }
  levels: {}, // { 1: { name: 'A level', … }, 2: {…}, … }
  domains: {},
  skills: {},
  employeeWages: {}, // { 1: {level_ids: [1, 2, …]}, 2: {…}, …}
  jobOfferWages: {}, // { 1: {level_ids: [1, 2, …]}, 2: {…}, …}
  name: '', // Breadcrumb helper
  levelModel: { // Level model helper for forms
    name: null,
    description: null,
    salaryValue: null,
    equityValue: null,
    bonusValue: null
  },
  componentModel: {
    name: null,
    description: null,
    salaryOperation: null,
    equityOperation: null,
    bonusOperation: null,
    hasRareEvolution: false
  },
  defaultOperations: {
    salary: 'addition',
    equity: 'addition',
    bonus: 'percentage'
  },
  gridOperations: {},
  wagesLoaded: false,
  isSandboxDebug: false
}

const getters = {
  grid(state) {
    return denormalizedGrid(state)
  },
  onboardingGrid(state, getters) {
    if (state.name.includes(ONBOARDING_GRID_PREFIX)) {
      return getters.grid
    }
  },
  enrichedEmployee(state, getters, _, rootGetters) {
    return (employee, simulationLevels, simulationWages) => {
      let wage = getters.employeeWage(employee.id)
      if (simulationWages && simulationWages.length) {
        const simulationWage = simulationWages.find(w => w.id === wage.id)
        if (simulationWage) {
          wage = simulationWage
        }
      }
      const currentDetails = rootGetters['employees/getCurrentWageDetails'](employee)
      const currentSalary = currentDetails.summary.salary.value
      const postGridWageDetails = getWageDetails(getters.grid, wage, { simulationLevels })
      const postGridSalary = postGridWageDetails.summary.salary && postGridWageDetails.summary.salary.value
      const postGridSalaryFixed = Math.max(currentSalary, postGridSalary)
      const postGridWageValid = isSandboxWageValid(wage, getters.grid)
      const postGridSalaryRisePercent = currentSalary ? (postGridSalary - currentSalary) / currentSalary * 100 : 0

      return Object.assign({
        fullName: [employee.firstName, employee.lastName].join(' '),
        currentSalary: currentSalary,
        postGridSalary: postGridSalary,
        postGridSalaryFixed: postGridSalaryFixed,
        postGridSalaryRise: postGridSalary - currentSalary,
        postGridSalaryRisePercent: postGridSalaryRisePercent,
        postGridSalaryFixedRise: postGridSalaryFixed - currentSalary,
        postGridWageDetails: postGridWageDetails,
        postGridWageValid,
        sandboxWage: wage,
        _searchKey: buildSearchTerms(
          employee.firstName,
          employee.lastName,
          ...postGridWageDetails.selectedComponents.map(c => c.selectedLevel.name)
        )
      }, employee)
    }
  },
  enrichedEmployees(_, { hasEmployeeWage, employeeWage, enrichedEmployee }, __, rootGetters) {
    // Pass simulationLevels or simulationWages to get simulated wages
    // Pass shouldFilter to only include employees with simulated wages
    return (simulationLevels, simulationWages, shouldFilter) => {
      const selectedLevelIdsMap = shouldFilter && simulationLevels && simulationLevels.length
        ? simulationLevels.reduce((memo, level) => {
          memo[level.id] = true
          return memo
        }, {})
        : {}
      const employees = rootGetters['employees/getGridEmployees']
        .filter(employee => {
          if (shouldFilter) {
            let wage = employeeWage(employee.id)
            wage = (simulationWages || []).find(w => w.id === wage.id) || wage
            return (wage.levelIds || []).find(levelId => selectedLevelIdsMap[levelId])
          }
          else {
            return hasEmployeeWage(employee.id)
          }
        })
      const enrichedEmployees = employees.map(employee => enrichedEmployee(employee, simulationLevels, simulationWages))
      return sortByKeys(enrichedEmployees, 'fullName')
    }
  },
  employeeWages(state, { hasEmployeeWage }) {
    return state.employeeWages ? Object.values(state.employeeWages).filter(w => hasEmployeeWage(w.employeeId)) : []
  },
  employeeWage(state) {
    return (employeeId) => state.employeeWages[employeeId]
      ? state.employeeWages[employeeId]
      : { employeeId, hasLevels: true, levelIds: [], skillIds: [], qualification: [] }
  },
  hasEmployeeWage(state, _, __, rootGetters) {
    return (employeeId) => {
      const employee = rootGetters['employees/getEmployee'](employeeId)
      return !!state.employeeWages[employeeId] && employee && !employee.isExcluded && !employee.isExternal
    }
  },
  jobOfferWages(state) {
    return state.jobOfferWages ? Object.values(state.jobOfferWages) : []
  },
  jobOfferWage(state) {
    return (jobOfferId) => state.jobOfferWages[jobOfferId]
      ? state.jobOfferWages[jobOfferId]
      : { jobOfferId, hasLevels: true, levelIds: [], skillIds: [] }
  },
  name(state) {
    return state.name
  },
  allComponents(state, { grid }) {
    return grid ? flattenedComponents(grid.components) : []
  },
  reusableComponents(state, { grid }) {
    return (remunerationType) => reusableComponentsForRemunerationType(grid.components, remunerationType)
  },
  availableComponents(state, { grid }) {
    return (remunerationType) => availableComponentsForLinkedComponent(grid.components, remunerationType)
  },
  component(state) {
    return (componentId) => state.components[componentId]
  },
  domain(state) {
    return (domainId) => state.domains[domainId]
  },
  level(state) {
    return (levelId) => state.levels[levelId]
  },
  domains(state) {
    return (componentId, linkedLevelId) => {
      const component = state.components[componentId]
      const levelId = component.levels.length && component.levels.find(levelId =>
        (!linkedLevelId || state.levels[levelId].linkedLevelId === linkedLevelId))
      const level = state.levels[levelId]
      const domains = level && level.domains && level.domains.map(domainId => state.domains[domainId])
      return domains || []
    }
  },
  isGridValid(_, getters) {
    return isSandboxGridValid(getters.grid)
  },
  isWagesValid(_, { isEmployeeWagesValid, isJobOfferWagesValid }) {
    return isEmployeeWagesValid && isJobOfferWagesValid
  },
  isEmployeeWagesValid(_, { employeeWages, grid }, __, rootGetters) {
    const isSandboxGridValid = isSandboxWagesValid(employeeWages, grid)
    const isEmployeesComplete = rootGetters['employees/getGridEmployees']
      .every(({ id }) => employeeWages.find(({ employeeId }) => employeeId === id))
    return isSandboxGridValid && isEmployeesComplete
  },
  isJobOfferWagesValid(state, getters, _, rootGetters) {
    const wages = state.jobOfferWages ? Object.values(state.jobOfferWages) : null
    return isSandboxWagesValid(wages, getters.grid)
  },
  isValid(_, getters) {
    return getters.isGridValid && getters.isWagesValid
  },
  gridOperations(state) {
    return state.gridOperations
  },
  employeeWagesSummary(state, getters, rootState, rootGetters) {
    if (getters.grid) {
      const summaries = rootGetters['employees/getGridEmployees'].map(employee => {
        return getWageDetails(getters.grid, getters.employeeWage(employee.id), {
          salary: employee.initialSalaryValue,
          equity: employee.initialBonusValue,
          bonus: employee.initialBonusValue
        }).summary
      })

      return getWagesDetailsSummary(summaries)
    }
  },
  jobOfferWagesSummary(state, getters, rootState, rootGetters) {
    if (getters.grid) {
      const summaries = rootGetters['candidates/getJobOffers'].map(jobOffer => {
        return getWageDetails(getters.grid, getters.jobOfferWage(jobOffer.id)).summary
      })

      return getWagesDetailsSummary(summaries)
    }
  },
  totalWagesSummary(state, getters, rootState, rootGetters) {
    if (getters.grid) {
      const summaries = rootGetters['employees/getGridEmployees'].map(employee => {
        return getWageDetails(getters.grid, getters.employeeWage(employee.id), {
          salary: employee.initialSalaryValue,
          equity: employee.initialBonusValue,
          bonus: employee.initialBonusValue
        }).summary
      })
        .concat(rootGetters['candidates/getJobOffers'].map(jobOffer => {
          return getWageDetails(getters.grid, getters.jobOfferWage(jobOffer.id)).summary
        }))

      return getWagesDetailsSummary(summaries)
    }
  },
  wagesLoaded(state) {
    return state.wagesLoaded
  },
  isSandboxDebug(state) {
    return state.isSandboxDebug
  }
}

const actions = {
  reset(context) {
    context.commit('reset')
  },
  get(context, gridId) {
    return api.get('/grid/' + gridId)
      .then(response => {
        context.commit('setGrid', response.data)
        return context.dispatch('getWages')
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  getOnboardingGrid(context) {
    return this.dispatch('sandboxList/get').then(grids => {
      const onboardingGrids = grids
        .filter(g => g.name.includes(ONBOARDING_GRID_PREFIX))
      if (onboardingGrids.length) {
        return context.dispatch('get', onboardingGrids[onboardingGrids.length - 1].id)
          .catch(error => {
            return context.dispatch('handleAPIError', error, { root: true })
          })
      }
      else {
        return context.dispatch('reset')
      }
    }).catch(error => {
      return context.dispatch('handleAPIError', error, { root: true })
    })
  },
  getLatestSandboxGrid(context) {
    return this.dispatch('sandboxList/get').then(grids => {
      const sandboxGrids = grids
        .filter(g => g.name.includes(SANDBOX_GRID_PREFIX))
      if (sandboxGrids.length) {
        return context.dispatch('get', sandboxGrids[sandboxGrids.length - 1].id)
          .catch(error => {
            return context.dispatch('handleAPIError', error, { root: true })
          })
      }
    }).catch(error => {
      return context.dispatch('handleAPIError', error, { root: true })
    })
  },
  createSandboxGrid(context) {
    const currentGrid = context.rootGetters['currentGrid/getCurrentGrid']
    if (!currentGrid) {
      return Promise.reject('Unable to create sandbox grid without published grid.')
    }
    const gridModel = {
      gridId: currentGrid.id,
      name: `${SANDBOX_GRID_PREFIX}_${moment.utc().format('YYYYMMDDTHHmmss\\Z')}`
    }
    return api.post('/grid', gridModel).then(({ data }) => {
      context.commit('setGrid', data)
      return context.dispatch('getWages')
    }).catch(error => {
      return context.dispatch('handleAPIError', error, { root: true })
    })
  },
  initSandboxGrid(context) {
    if (context.state.name.includes(SANDBOX_GRID_PREFIX)) {
      return Promise.resolve()
    }
    return context.dispatch('getLatestSandboxGrid').then(isLoaded => {
      if (!isLoaded) {
        if (context.rootGetters['account/isAdmin']) {
          return context.dispatch('createSandboxGrid')
        }
        else {
          return Promise.reject(i18n.t('grid.editor.noSandboxGrid'))
        }
      }
    })
  },
  updateGridProperties(context, grid) {
    return api.patch('/grid/' + grid.id, grid)
      .then(({ data }) => {
        context.commit('updateGridProperties', data)
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  updateGrid(context, grid) {
    context.commit('setGrid', grid)
  },
  getWages(context) {
    return api.get('/grid/' + context.state.gridId + '/wages')
      .then(response => {
        context.commit('setWages', response.data)
        return true
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  updateWages(context, wages) {
    context.commit('setWages', wages)
  },
  createComponent(context, { gridId, component }) {
    // Prepare rank and orderedComponentIds to insert in place
    const grid = { id: gridId }
    const components = context.getters.grid.components || []
    let orderedComponentIds = [...state.grids[grid.id].components]
    const firstMultiplierComponentIndex = orderedComponentIds.findIndex(id => state.components[id].salaryOperation === 'multiplier')
    if (component.salaryOperation === 'addition' && firstMultiplierComponentIndex > -1) {
      // If addition, insert before the first multiplier (linkedComponents are excluded)
      component.rank = firstMultiplierComponentIndex
      orderedComponentIds.splice(firstMultiplierComponentIndex, 0, '_placeholder')
    }
    else {
      // If multiplier, push at the end of the list
      component.rank = components.length
      orderedComponentIds = null
    }

    return api.post('/grid/' + gridId + '/component', component)
      .then(({ data }) => {
        context.commit('addComponent', data)

        // Reorder if needed
        if (orderedComponentIds) {
          orderedComponentIds = orderedComponentIds.map(id => id.replace('_placeholder', data.id))
          return context.dispatch('reorderComponent', { grid, orderedComponentIds })
            .then(_ => data)
        }
        else {
          return data
        }
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  createLinkedComponent(context, { componentId, linkedComponent }) {
    const component = context.state.components[componentId]
    linkedComponent.rank = component.linkedComponents ? component.linkedComponents.length - 1 : 0

    return api.post('/component/' + componentId, linkedComponent)
      .then(response => {
        context.commit('addLinkedComponent', {
          componentId,
          linkedComponent: response.data
        })
        return response.data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  updateComponent(context, component) {
    return api.patch('/component/' + component.id, component)
      .then(response => {
        context.commit('updateComponent', response.data)
        return response.data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  updateComponentProperties(context, { id, name, description }) {
    // Optimized version of updateComponent if you don't want to refresh all levels, skills…
    return api.patch('/component/' + id, { name, description })
      .then(({ data }) => {
        context.commit('updateComponentProperties', data)
        return data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  setComponent(context, component) {
    context.commit('updateComponent', component)
  },
  removeComponent(context, component) {
    return api.delete('/component/' + component.id)
      .then(_ => {
        context.dispatch('getWages')
        context.commit('removeComponent', component)

        // Reorder components
        const grid = { id: component.gridId }
        const orderedComponentIds = state.grids[grid.id].components
        return context.dispatch('reorderComponent', { grid, orderedComponentIds })
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  addRemuneration(context, { component, remunerationType }) {
    return context.dispatch('updateComponent', {
      id: component.id,
      [remunerationType + 'Operation']: context.state.gridOperations[remunerationType]
    })
  },
  removeRemuneration(context, { component, remunerationType }) {
    return api.delete(`/component/${component.id}/remuneration/${remunerationType}`)
      .then(response => {
        context.dispatch('getWages')
        context.commit('updateComponent', response.data)
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  updateGridOperation(context, { remunerationType, operation }) {
    context.commit('setGridOperation', { remunerationType, operation })
    context.getters.grid.components.forEach(component => {
      if (component[remunerationType + 'Operation']) {
        context.dispatch('updateComponent', {
          id: component.id,
          [remunerationType + 'Operation']: operation
        })
      }
    })
  },
  createLevel(context, { component, level }) {
    return api.post('/component/' + component.id + '/level', level)
      .then(({ data }) => {
        context.commit('addLevel', {
          componentId: component.id,
          level: data
        })

        // Create experience linked levels
        if (component.linkedComponents && component.linkedComponents.length && component.linkedComponents[0].ref === 'experience') {
          const linkedComponent = component.linkedComponents[0]
          return context.dispatch('createLinkedLevels', { component: linkedComponent, linkedLevel: data })
            .then(_ => data)
        }
        else {
          return data
        }
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  async createLevels(context, { component, levels }) {
    for (const level of levels) {
      await api.post('/component/' + component.id + '/level', level)
    }
    // Reloading whole grid is faster than addLevels mutation
    await context.dispatch('getLatestSandboxGrid')
  },
  createLinkedLevels(context, { component, linkedLevel }) {
    return api.post('/component/' + component.id + '/linked_levels', { linkedLevelId: linkedLevel.id })
      .then(({ data }) => {
        context.commit('updateComponent', data)
        return data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  createTemplateLevel(context, { component, templateLevel, level }) {
    return api.post('/component/' + component.id + '/template_level', { templateLevelId: templateLevel.id, level })
      .then(({ data }) => {
        context.commit('updateComponent', data)
        return data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  createDomain(context, { component, domain }) {
    return api.post('/component/' + component.id + '/domain', domain)
      .then(({ data }) => {
        context.commit('updateComponent', data)

        // Return new domain
        return context.getters.domains(component.id, domain.linkedLevelId).find(d => d.name === domain.name)
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  async updateLevels(context, levels) {
    const promises = levels.map(level => api.patch('/level/' + level.id, level))
    const responses = await Promise.all(promises)
    context.commit('updateLevels', responses.map(r => r.data))
    context.commit('resetWagesSalaryValue')
  },
  async updateTooManyLevels(context, levels) {
    for (const level of levels) {
      try {
        await api.patch('/level/' + level.id, level)
      }
      catch (e) {
        // Poor man's retry handling
        await api.patch('/level/' + level.id, level)
      }
    }
    // Reloading whole grid is faster than updateLevels mutation
    await context.dispatch('getLatestSandboxGrid')
  },
  updateLevel(context, level) {
    return api.patch('/level/' + level.id, level)
      .then(response => {
        context.commit('updateLevel', response.data)
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  synchronizeLevels(context, { component, levels }) {
    if (!(component = context.getters.allComponents.find(c => c.id === component.id)) || !levels.length) {
      return Promise.resolve()
    }

    const existingLevels = component.levels.filter(l => l.linkedLevelId === levels[0].linkedLevelId)
    const actions = []

    levels.forEach((level, i) => {
      if (existingLevels[i]) {
        // Update existing level
        actions.push(['updateLevel', { ...existingLevels[i], name: level.name, description: '' }])
      }
      else {
        // Create level
        actions.push(['createLevel', { component, level: level }])
      }
    })
    existingLevels.forEach((existingLevel, i) => {
      // Delete obsolete level
      if (!levels[i]) {
        actions.push(['removeLevel', existingLevel])
      }
    })

    return sequentialDispatch(context, actions)
  },
  synchronizeDomains(context, { component, domains }) {
    if (!(component = context.getters.allComponents.find(c => c.id === component.id)) || !domains.length) {
      return Promise.resolve()
    }

    const existingDomains = context.getters.domains(component.id, domains[0].linkedLevelId)
    const actions = []

    domains.forEach((domain, i) => {
      if (existingDomains[i]) {
        // Update existing domain
        actions.push(['updateDomain', { ...existingDomains[i], name: domain.name }])

        // Clear existing domain descriptions
        component.levels
          .filter(l => l.linkedLevelId === domains[0].linkedLevelId)
          .forEach(level => {
            actions.push(['updateDomainDescription', { domainId: existingDomains[i].id, levelId: level.id, description: null }])
          })
      }
      else {
        // Create domain
        actions.push(['createDomain', { component, domain: domain }])
      }
    })
    existingDomains.forEach((existingDomain, i) => {
      // Delete obsolete domain
      if (!domains[i]) {
        actions.push(['removeDomain', existingDomain])
      }
    })

    return sequentialDispatch(context, actions)
  },
  synchronizeSkills(context, { component, levels }) {
    if (!(component = context.getters.allComponents.find(c => c.id === component.id)) || !levels.length) {
      return Promise.resolve()
    }

    const actions = []
    levels.forEach((level, i) => {
      const existingLevel = component.levels
        .find(l => l.linkedLevelId === levels[0].linkedLevelId && l.rank === i)

      level.domains.forEach((domain, j) => {
        const existingDomain = existingLevel.domains.find(d => d.index === j)
        const existingSkills = findSkills(component, existingLevel.id, existingDomain.id)
        const skills = domain.skills

        skills.forEach((skill, i) => {
          skill.domainId = existingDomain.id

          if (existingSkills[i]) {
            // Update existing skill
            actions.push(['updateSkill', { ...existingSkills[i], name: skill.name }])
          }
          else {
            // Create skill
            actions.push(['createSkill', { levelId: existingLevel.id, skill: skill }])
          }
        })
        existingSkills.forEach((existingSkill, i) => {
          // Delete obsolete skill
          if (!skills[i]) {
            actions.push(['removeSkill', { levelId: existingLevel.id, skillId: existingSkill.id }])
          }
        })
      })
    })

    return sequentialDispatch(context, actions)
  },
  updateDomain(context, domain) {
    return api.patch('/domain/' + domain.id, domain)
      .then(response => {
        context.commit('updateDomain', response.data)
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  updateDomainDescriptions(context, domainDescriptions) {
    const promises = domainDescriptions.map(d => context.dispatch('updateDomainDescription', d))
    return Promise.all(promises)
  },
  updateDomainDescription(context, { domainId, levelId, description }) {
    return api.put('/level/' + levelId + '/domain/' + domainId, { description })
      .then(_ => {
        context.commit('updateDomainDescription', { domainId, levelId, description })
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  removeLevel(context, level) {
    return api.delete('/level/' + level.id)
      .then(_ => {
        context.dispatch('getWages')
        context.commit('removeLevel', level)

        // Reorder sibling levels
        const component = context.state.components[level.componentId]
        let orderedLevelIds = component.levels
        if (level.linkedLevelId) {
          orderedLevelIds = component.levels.filter(levelId => {
            return context.state.levels[levelId].linkedLevelId === level.linkedLevelId
          })
        }
        return context.dispatch('reorderLevel', { component, orderedLevelIds })
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  removeDomain(context, domain) {
    return api.delete('/domain/' + domain.id)
      .then(({ data }) => {
        context.commit('updateComponent', data)

        // Reorder domains
        const component = data
        const orderedDomainIds = context.getters.domains(component.id, domain.linkedLevelId).map(d => d.id)
        return context.dispatch('reorderDomain', { component, orderedDomainIds })
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  reorderComponent(context, { grid, orderedComponentIds }) {
    const currentOrderedComponentIds = context.state.grids[grid.id].components
    context.commit('updateComponentOrder', { grid, orderedComponentIds })

    api.put(`/grid/${grid.id}/component/order`, { orderedComponentIds })
      .catch(_ => {
        context.commit('updateComponentOrder', { grid, orderedComponentIds: currentOrderedComponentIds })
      })
  },
  reorderLevel(context, { component, orderedLevelIds }) {
    context.commit('updateLevelOrder', { component, orderedLevelIds })

    return api.put(`/component/${component.id}/level/order`, { orderedLevelIds })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  reorderDomain(context, { component, orderedDomainIds }) {
    context.commit('updateDomainOrder', { component, orderedDomainIds })

    return api.put(`/component/${component.id}/domain/order`, { orderedDomainIds })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  updateLevelsGroup(context, levels) {
    context.commit('updateLevelsGroup', levels)

    return context.dispatch('updateLevels', levels)
  },
  createSkill(context, { levelId, skill }) {
    return api.post(`/level/${levelId}/skill`, skill)
      .then(({ data }) => {
        context.commit('addSkill', { levelId, skill: data })
        return data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  updateSkill(context, skill) {
    return api.patch(`/skill/${skill.id}`, skill)
      .then(({ data }) => {
        context.commit('updateSkill', data)
        return data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  removeSkill(context, { levelId, skillId }) {
    return api.delete(`/skill/${skillId}`)
      .then(() => {
        context.commit('removeSkill', { levelId, skillId })
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  ensureSandboxWageSkillsConsistency(context) {
    // This method will add missing skillIds based on levelIds on all sandbox wages.
    // It will not remove obsolete skillIds, since WageCalculator already does this
    // when saving a wage and also because obsolete skills are not displayed in the UI.
    const enrichedEmployees = context.getters.enrichedEmployees()
    enrichedEmployees.forEach(employee => {
      const wage = employee.sandboxWage
      const employeeId = employee.id
      const removedSkills = []
      const addedSkills = []

      employee.postGridWageDetails.selectedComponents.forEach(component => {
        const flatComponent = getFlatComponent(component)
        // flatComponent will only be returned only if the component has skills:
        if (flatComponent && !(flatComponent.config && flatComponent.config.hasManualLevels)) {
          const selectedLevel = component.selectedLevel
          const reachedLevel = getReachedLevel(flatComponent, wage.skillIds)
          if (selectedLevel.id !== reachedLevel.id) {
            console.log('Sandbox wage level ≠ reached level:',
              employee.firstName,
              employee.lastName,
              'is',
              component.name, selectedLevel.name,
              'but skills say', reachedLevel.name)

            if (selectedLevel.rank > 0) {
              console.log('Fixing', employee.firstName, 'skills…')
              for (let i = 0; i < flatComponent.levels.length && i <= selectedLevel.rank; i++) {
                const flatLevel = flatComponent.levels[i]
                flatLevel.skills.forEach(skill => {
                  if (!wage.skillIds.includes(skill.id)) {
                    console.log('Selecting skill', flatLevel.name, skill.name)
                    addedSkills.push(skill.id)
                  }
                })
              }
            }
          }
        }
      })

      if (addedSkills.length) {
        context.dispatch('updateSandboxWageSkills', { employeeId, addedSkills, removedSkills })
      }
    })
  },
  updateSimilarSandboxWage(context, { employee, wage, referenceWage }) {
    return context.dispatch('getLatestSandboxGrid').then(isLoaded => {
      if (isLoaded) {
        const sandboxGrid = context.getters.grid
        const currentGrid = context.rootGetters['currentGrid/getCurrentGrid']
        if (context.getters.hasEmployeeWage(employee.id)) {
          // Update existing wage
          const sandboxWage = context.getters.employeeWage(employee.id)
          if (isWagesSimilar(referenceWage, currentGrid, sandboxWage, sandboxGrid)) {
            const updatedSandboxWage = transferWage(wage, currentGrid, sandboxWage, sandboxGrid)
            if (isSandboxWageValid(updatedSandboxWage, sandboxGrid)) {
              return context.dispatch('updateSandboxWage', updatedSandboxWage)
            }
          }
        }
        else {
          // Create new wage
          const sandboxWage = {
            hasLevels: true,
            employeeId: employee.id,
            levelIds: []
          }
          const updatedSandboxWage = transferWage(wage, currentGrid, sandboxWage, sandboxGrid)
          if (isSandboxWageValid(updatedSandboxWage, sandboxGrid)) {
            return context.dispatch('updateSandboxWage', updatedSandboxWage)
          }
        }
      }
    })
  },
  updateSandboxWage(context, wage) {
    const path = wage.employeeId ? 'employee' : 'job_offer'
    return api.put(`/grid/${context.state.gridId}/wage/${path}`, wage)
      .then(({ data }) => {
        context.commit('setSandboxWage', data)
        return data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  updateSandboxWages(context, wages) {
    const actions = wages.map(wage => {
      return ['updateSandboxWage', wage]
    })
    return sequentialDispatch(context, actions)
  },
  updateSandboxWageSkills(context, { employeeId, addedSkills, removedSkills }) {
    const wage = cloneDeepWith(context.getters.employeeWage(employeeId))
    if (wage) {
      wage.skillIds = wage.skillIds.concat(addedSkills)
      wage.skillIds = wage.skillIds.filter(id => !removedSkills.includes(id))
      wage.skillIds = uniq(wage.skillIds)
      context.dispatch('updateSandboxWage', wage)
    }
  },
  updateSandboxWageLevel(context, { wage, level }) {
    if (wage.levelIds.includes(level.id)) {
      return Promise.resolve({ data: wage })
    }
    else {
      wage = {
        ...wage,
        levelIds: wage.levelIds
          .map(id => context.state.levels[id])
          .filter(l => l && l.componentId !== level.componentId)
          .concat([level])
          .map(l => l.id)
      }

      return context.dispatch('updateSandboxWage', wage)
    }
  },
  updateSandboxWagesLevel(context, { wages, level }) {
    const actions = wages.map(wage => {
      return ['updateSandboxWageLevel', { wage, level }]
    })
    return sequentialDispatch(context, actions)
  },
  adjustSandboxWage(context, { employeeId, overridenSalaryValue }) {
    const wage = cloneDeepWith(context.getters.employeeWage(employeeId))
    if (wage) {
      wage.overridenSalaryValue = overridenSalaryValue
    }
    return context.dispatch('updateSandboxWage', wage)
  },
  delete(context) {
    return api.delete('/grid')
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  toggleIsSandboxDebug(context) {
    context.commit('setIsSandboxDebug', !context.getters.isSandboxDebug)
  },
  publish(context) {
    return api.put('/grid/current/' + context.state.gridId)
      .then(response => {
        context.commit('currentGrid/setCurrentGrid', response.data.currentGrid, { root: true })
        context.commit('currentGrid/setDisplayedGrid', response.data.currentGrid, { root: true })
        context.commit('currentGrid/setCompanyGrids', response.data.companyGrids, { root: true })
        context.dispatch('employees/getEmployees', {}, { root: true })
        context.dispatch('wagePlans/init', {}, { root: true })
        context.dispatch('candidates/init', {}, { root: true })
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  }
}

const mutations = {
  reset(state) {
    state.gridId = ''
    state.grids = {}
    state.components = {}
    state.levels = {}
    state.name = ''
    state.employeeWages = {}
    state.jobOfferWages = {}
    state.gridOperations = {}
    state.wagesLoaded = false
  },
  setName(state, name) {
    state.name = name
  },
  setGrid(state, grid) {
    const n = normalizedGrid(grid)
    state.name = grid.name
    state.gridId = n.result
    state.grids = n.entities.grids
    state.components = n.entities.components || {}
    state.levels = n.entities.levels || {}
    state.domains = n.entities.domains || {}
    state.skills = n.entities.skills || {}

    if (!grid.components) {
      state.gridOperations = state.defaultOperations
    }

    state.gridOperations = {
      salary: gridFormulaForRemunerationType(grid, 'salary').operation || 'addition',
      equity: gridFormulaForRemunerationType(grid, 'equity').operation || 'addition',
      bonus: gridFormulaForRemunerationType(grid, 'bonus').operation || 'percentage'
    }
  },
  updateGridProperties(state, grid) {
    state.grids[grid.id] = {
      ...state.grids[grid.id],
      title: grid.title,
      description: grid.description
    }
  },
  setGridOperation(state, { remunerationType, operation }) {
    state.gridOperations[remunerationType] = operation
  },
  setWages(state, wages) {
    const n = normalizedSandboxWages(wages)
    state.employeeWages = n.entities.employeeWages || {}
    state.jobOfferWages = n.entities.jobOfferWages || {}
    state.wagesLoaded = true
  },
  setSandboxWage(state, wage) {
    if (wage.employeeId) {
      Vue.set(state.employeeWages, wage.employeeId, wage)
    }
    else {
      Vue.set(state.jobOfferWages, wage.jobOfferId, wage)
    }
  },
  resetWagesSalaryValue(state) {
    Object.values(state.employeeWages).forEach(wage => {
      Vue.set(wage, 'salaryValue', null)
    })
  },
  addComponent(state, component) {
    const n = normalizedComponent(component)
    state.components = { ...state.components, ...n.entities.components }
    state.levels = { ...state.levels, ...n.entities.levels }
    state.domains = { ...state.domains, ...n.entities.domains }
    state.skills = { ...state.skills, ...n.entities.skills }

    const grid = state.grids[state.gridId]
    if (!grid.components) {
      grid.components = [component.id]
    }
    else {
      grid.components.push(component.id)
    }
  },
  addLinkedComponent(state, { componentId, linkedComponent }) {
    const n = normalizedComponent(linkedComponent)
    state.components = { ...state.components, ...n.entities.components }
    state.levels = { ...state.levels, ...n.entities.levels }
    state.domains = { ...state.domains, ...n.entities.domains }
    state.skills = { ...state.skills, ...n.entities.skills }

    const component = state.components[componentId]
    if (!component.linkedComponents) {
      component.linkedComponents = [linkedComponent.id]
    }
    else {
      component.linkedComponents.push(linkedComponent.id)
    }
  },
  updateComponent(state, component) {
    const n = normalizedComponent(component)
    state.components = Object.assign({}, state.components, n.entities.components)
    state.levels = { ...state.levels, ...n.entities.levels }
    state.domains = { ...state.domains, ...n.entities.domains }
    state.skills = { ...state.skills, ...n.entities.skills }
  },
  updateComponentProperties(state, component) {
    state.components[component.id] = {
      ...state.components[component.id],
      name: component.name,
      description: component.description
    }
  },
  removeComponent(state, component) {
    let components = null

    if (component.parentComponentId) {
      components = state.components[component.parentComponentId].linkedComponents
    }
    else {
      components = state.grids[state.gridId].components
    }

    components.splice(components.indexOf(component.id), 1)
  },
  updateComponentOrder(state, { grid, orderedComponentIds }) {
    // orderedComponentIds need to be a full list.
    state.grids[grid.id].components = orderedComponentIds
    orderedComponentIds.forEach((componentId, i) => {
      state.components[componentId].rank = i
    })
  },
  addLevel(state, { componentId, level }) {
    const n = normalizedLevel(level)
    state.levels = { ...state.levels, ...n.entities.levels }
    state.domains = { ...state.domains, ...n.entities.domains }
    state.skills = { ...state.skills, ...n.entities.skills }

    const component = state.components[componentId]
    if (!component.levels) {
      component.levels = [level.id]
    }
    else {
      component.levels.push(level.id)
    }
  },
  updateLevels(state, levels) {
    levels.forEach(level => {
      const n = normalizedLevel(level)
      state.levels = { ...state.levels, ...n.entities.levels }
      state.domains = { ...state.domains, ...n.entities.domains }
      state.skills = { ...state.skills, ...n.entities.skills }
    })
  },
  updateLevel(state, level) {
    const n = normalizedLevel(level)
    state.levels = { ...state.levels, ...n.entities.levels }
    state.domains = { ...state.domains, ...n.entities.domains }
    state.skills = { ...state.skills, ...n.entities.skills }
  },
  removeLevel(state, level) {
    const levels = state.components[level.componentId].levels
    levels.splice(levels.indexOf(level.id), 1)

    // Delete linked levels (replicate backend behavior):
    const linkedLevels = Object.values(state.levels)
      .filter(linkedLevel => linkedLevel.linkedLevelId === level.id)
    linkedLevels.forEach(linkedLevel => {
      const levels = state.components[linkedLevel.componentId].levels
      levels.splice(levels.indexOf(linkedLevel.id), 1)
    })
  },
  updateLevelOrder(state, { component, orderedLevelIds }) {
    // orderedLevelIds might be a partial list so we concat it and preserve its order:
    state.components[component.id].levels = uniq(orderedLevelIds.concat(state.components[component.id].levels))
    orderedLevelIds.forEach((levelId, i) => {
      state.levels[levelId].rank = i
    })
  },
  updateLevelsGroup(state, levels) {
    levels.forEach(level => {
      console.log('Updating', level.name, 'to', level.group)
      state.levels[level.id].group = level.group
    })
  },
  updateDomain(state, domain) {
    // Only the name can be updated here.
    Object.keys(state.domains)
      .filter(composedDomainId => extractDomainId(composedDomainId) === domain.id)
      .forEach(composedDomainId => {
        state.domains[composedDomainId].name = domain.name
      })
  },
  updateDomainDescription(state, { domainId, levelId, description }) {
    const domain = state.domains[buildDomainId(domainId, levelId)]
    if (domain) {
      domain.description = description
    }
  },
  updateDomainOrder(state, { component, orderedDomainIds }) {
    // orderedLevelIds might be a partial list
    orderedDomainIds.forEach((domainId, i) => {
      Object.keys(state.domains)
        .filter(composedDomainId => extractDomainId(composedDomainId) === domainId)
        .forEach(composedDomainId => {
          state.domains[composedDomainId].index = i
        })
    })

    state.components[component.id].levels.forEach(levelId => {
      state.levels[levelId].domains = sortBy(state.levels[levelId].domains, domainId => state.domains[domainId].index)
    })
  },
  addSkill(state, { levelId, skill }) {
    const domain = state.domains[buildDomainId(skill.domainId, levelId)]
    if (!domain.skills) {
      domain.skills = [skill.id]
    }
    else {
      domain.skills.push(skill.id)
    }
    Vue.set(state.skills, skill.id, skill)

    // Sort skills by index
    domain.skills = sortBy(domain.skills, skillId => state.skills[skillId].index)
  },
  updateSkill(state, skill) {
    // Beware: if skill.index changes, domain.skills order will not match
    Vue.set(state.skills, skill.id, skill)
  },
  removeSkill(state, { levelId, skillId }) {
    const skill = state.skills[skillId]
    const skills = state.domains[buildDomainId(skill.domainId, levelId)].skills
    skills.splice(skills.indexOf(skillId), 1)
  },
  setIsSandboxDebug(state, isSandboxDebug) {
    state.isSandboxDebug = isSandboxDebug
  },
  filterGridByEmployeeWages({ employeeWages, components }) {
    // Filter role levels to only include those present in employee wages
    const employeeWagesLevelIds = flatten(Object.values(employeeWages).map(w => w.levelIds))
    const roleComponent = Object.values(components).find(c => c.ref === 'role')
    if (roleComponent) {
      roleComponent.levels = roleComponent.levels.filter(l => employeeWagesLevelIds.includes(l))
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
