<template>
  <div>
    <div class="components">
      <div class="component block" v-for="component in groupedComponents" :key="component.id">
        <div class="preview">
          <grid-component
            v-for="c in component.components"
            :key="c.id"
            :component="c"
            :title="$t('grid.onboarding.intro.components.valuesIntro')"></grid-component>
        </div>
        <checkbox
          :value="component.id"
          :key="component.id"
          v-model="selectedComponents"
          :disabled="component.disabled"
          @change="onChange">{{$t(`templates.clearwage.components.${component.title || component.id}.title`)}}</checkbox>
        <p class="no-margin-bottom preserve-lines" v-html="$t(`templates.clearwage.components.${component.id}.description`)"></p>
      </div>
    </div>
    <p class="preserve-lines" v-t="'grid.onboarding.intro.components.formulaIntro'"></p>
    <grid-formula
      :formula="computedFormula"
      layout="horizontal"></grid-formula>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Checkbox from '@components/commons/Checkbox.vue'
import GridFormula from '@components/grid/viewer/GridFormula.vue'
import GridComponent from '@components/grid/valuation/GridComponent.vue'
import { gridFormulaForRemunerationType } from '@/utils/grid'

export default {
  props: ['value'],
  data() {
    return {
      selectedComponents: this.value
    }
  },
  computed: {
    ...mapGetters({
      getEmployeesValues: 'onboarding/getEmployeesValues'
    }),
    groupedComponents() {
      return [
        {
          id: 'role',
          title: 'roleExperience',
          disabled: true,
          components: [
            this.generateComponent('role'),
            this.generateComponent('experience')
          ]
        },
        {
          id: 'seniority',
          components: [this.generateComponent('seniority')]
        },
        {
          id: 'management',
          components: [this.generateComponent('management')]
        },
        {
          id: 'city',
          components: [this.generateComponent('city')]
        }
      ]
    },
    allComponents() {
      return [
        {
          id: 'role',
          salaryOperation: 'addition',
          name: this.$t('templates.clearwage.components.role.title')
        },
        {
          id: 'experience',
          salaryOperation: 'multiplier',
          name: this.$t('templates.clearwage.components.experience.title')
        },
        {
          id: 'seniority',
          salaryOperation: 'addition',
          name: this.$t('templates.clearwage.components.seniority.title')
        },
        {
          id: 'management',
          salaryOperation: 'addition',
          name: this.$t('templates.clearwage.components.management.title')
        },
        {
          id: 'city',
          salaryOperation: 'multiplier',
          name: this.$t('templates.clearwage.components.city.title')
        }
      ]
    },
    computedFormula() {
      const components = this.allComponents.filter(
        c => ['role', 'experience'].concat(this.selectedComponents).includes(c.id)
      )
      return gridFormulaForRemunerationType({ components }, 'salary')
    }
  },
  methods: {
    generateComponent(ref) {
      const component = this.allComponents.find(c => c.id === ref)
      let values
      if (['experience', 'seniority'].includes(ref)) {
        values = 'ABCDEF'.split('').map(letter => (
          `${this.$t(`templates.clearwage.components.${ref}.level`)} ${letter}`
        ))
      }
      else {
        values = this.getEmployeesValues(ref)
      }
      const levels = values.map((value, i) => ({
        id: value,
        name: value,
        salaryValue: component.salaryOperation === 'addition' ? 0 : 1,
        rank: i
      }))

      return { ...component, levels }
    },
    updateValue() {
      this.selectedComponents = this.value
    },
    onChange() {
      this.$emit('input', this.selectedComponents)
    }
  },
  watch: {
    value: 'updateValue'
  },
  components: {
    Checkbox,
    GridComponent,
    GridFormula
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/alert.scss";
@import "./src/styles/block.scss";

.component {
  overflow: hidden;
  min-height: 5em;
  margin-bottom: 1em
}

.checkbox::v-deep label {
  @include font-big-size;
  @include font-semibold;
  cursor: pointer;
  color:  $graph-darkblue-color;

  &:before {
    vertical-align: bottom !important;
  }
}

.preview {
  background: white;
  border-radius: $border-radius;
  padding: 0.75em;
  border: $border;
  float: right;
  margin: -0.25em;
  margin-left: 2em;
  min-width: 24%;
  display: flex;
  justify-content: center;

  &::v-deep .level-value {
    color: $light-text-color;
    cursor: help;
  }
}
</style>
