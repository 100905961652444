<template>
  <div class="wrapper">
    <div class="svg-container">
      <svg :height="viewport.height" ref="svgNode"></svg>
      <GraphLegend id="ranges" />
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
import { mapGetters } from 'vuex'
import i18n from '@/i18n'
import debounce from 'lodash.debounce'
import sortBy from 'lodash.sortby'
import groupBy from 'lodash.groupby'
import {
  generateAbscissaLabels,
  generateOrdinateLabels,
  generateStackedBars,
  getMaxValue
} from '@/utils/graph'
import GraphLegend from '@components/graph/GraphLegend.vue'

function initGraph(svg, viewport) {
  const node = {}

  node.svg = d3.select(svg)
  node.viewport = node.svg.append('g')
    .attr('class', 'viewport')
  node.background = node.viewport.append('rect')
    .attr('class', 'background')
  node.bars = node.viewport.append('g')
    .attr('class', 'bars')
  node.circles = node.viewport.append('g')
    .attr('class', 'circles')
  node.abscissa = node.viewport.append('g')
    .attr('class', 'axis abscissa')
  node.ordinate = node.viewport.append('g')
    .attr('class', 'axis ordinate')
  node.x = d3.scaleBand()
  node.y = d3.scaleLinear()

  return {
    node: node,
    viewport: viewport,
    data: []
  }
}

function fixGapsInDomain(domain) {
  const maxValue = +domain[domain.length - 1].split(',')[1]
  const minValue = +domain[0].split(',')[0]
  const minMaxValue = +domain[0].split(',')[1]
  const interval = minMaxValue - minValue
  const newDomain = []

  for (let i = minValue; i < maxValue; i += interval) {
    newDomain.push([i, i + interval].join(','))
  }
  return newDomain
}

function generateDistributionGraphModel(graph, employees) {
  // Generate data
  const genderDomain = ['male', 'female', 'other']
  const total = employees.length
  const employeesBySalaryRange = groupBy(employees, e => e.salaryRange)
  const domain = fixGapsInDomain(sortBy(Object.keys(employeesBySalaryRange), Number.parseInt))

  graph.data = domain.map(range => {
    const rangeParts = range.split(',')
    const x = `${rangeParts[0]}\u00A0k${i18n.currencySymbol}`
    const employeesByGender = groupBy(employeesBySalaryRange[range], e => e.gender)
    const values = []
    let previousY = 0

    genderDomain.forEach(gender => {
      const employees = employeesByGender[gender]
      if (employees && employees.length) {
        const count = employees.length

        const y = count / total + previousY

        values.push({
          x: x,
          y0: previousY,
          y1: y,
          class: gender
        })
        previousY = y
      }
    })

    if (!values.length) {
      values.push({
        x: x,
        y0: 0,
        y1: 0
      })
    }

    return {
      x: x,
      values: values
    }
  })
  return graph
}

function renderGraph(graph) {
  if (!graph.node.svg.node()) {
    return
  }
  const domain = graph.data.map(d => d.x)
  const viewport = graph.viewport
  const node = graph.node

  // Viewport
  viewport.width = graph.node.svg.node().getBoundingClientRect().width
  viewport.innerWidth = viewport.width - viewport.padding.left - viewport.padding.right
  viewport.innerHeight = viewport.height - viewport.padding.top - viewport.padding.bottom

  // Graph Axes
  graph.axes = {
    abscissa: {
      type: 'discrete',
      labels: 'belowAxis',
      axis: node.x
    },
    ordinate: {
      type: 'continuous',
      labels: 'aboveMax',
      axis: node.y,
      format: '.1%'
    }
  }
  node.x.domain(domain)
    .rangeRound([0, viewport.innerWidth])
    .padding([0.15])
  if (node.x.bandwidth() > 300) {
    node.x.padding([0.50])
  }
  else if (node.x.bandwidth() > 150) {
    node.x.padding([0.40])
  }
  node.y.domain([d3.max(graph.data, getMaxValue), 0])
    .rangeRound([0, viewport.innerHeight])

  // Viewport background
  node.viewport.attr('transform',
    `translate(${viewport.padding.left},${viewport.padding.top})`)
  node.background.attr('width', viewport.innerWidth)
    .attr('height', viewport.innerHeight)

  // Axes Labels
  const abscissaLabelsData = generateAbscissaLabels(graph)
  const abscissaLabels = node.abscissa.selectAll('.label')
    .data(abscissaLabelsData, d => d.label)
  abscissaLabels
    .enter().append('text')
    .attr('class', 'label wrap')
    .merge(abscissaLabels)
    .attr('x', d => d.x)
    .attr('y', d => d.y)
    .text(d => d.label)
  abscissaLabels.exit()
    .remove()

  const ordinateLabelsData = generateOrdinateLabels(graph)
  const ordinateLabels = node.ordinate.selectAll('.label')
    .data(ordinateLabelsData, d => d.id)
  ordinateLabels
    .enter().append('text')
    .attr('class', 'label')
    .attr('x', d => d.x)
    .attr('y', d => d.y)
    .merge(ordinateLabels)
    .text(d => d.label)
    .transition()
    .attr('x', d => d.x)
    .attr('y', d => d.y)
  ordinateLabels.exit()
    .remove()

  // Bars
  const salariesBarsData = generateStackedBars(graph)
  const salariesBars = node.bars.selectAll('.bar')
    .data(salariesBarsData, d => d.id)
  salariesBars
    .enter().append('rect')
    .attr('class', d => 'bar ' + d.class)
    .attr('x', d => d.x)
    .attr('y', d => d.y + d.height)
    .attr('rx', 2)
    .attr('ry', 2)
    .attr('width', d => d.width)
    .attr('height', 0)
    .merge(salariesBars)
    .transition()
    .attr('x', d => d.x)
    .attr('y', d => d.y)
    .attr('width', d => d.width)
    .attr('height', d => d.height)
  salariesBars.exit()
    .remove()
}

export default {
  components: {
    GraphLegend
  },
  name: 'combo-graph',
  data() {
    return {
      // Options
      viewport: {
        width: 0, // Computed
        height: 350,
        innerWidth: 0, // Computed
        innerHeight: 0, // Computed
        padding: {
          left: 0,
          right: 0,
          top: 35,
          bottom: 40
        }
      },
      // State
      graph: null,
      resizeFn: null
    }
  },
  computed: {
    ...mapGetters({
      employees: 'statistics/getFilteredEmployees'
    })
  },

  mounted() {
    this.init()
    setTimeout(this.render, 400)
    this.resizeFn = debounce(this.render, 100)
    window.addEventListener('resize', this.resizeFn)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeFn)
  },
  watch: {
    employees: 'render'
  },
  methods: {
    init() {
      this.graph = initGraph(this.$refs.svgNode, this.viewport)
    },
    render() {
      if (this.employees) {
        generateDistributionGraphModel(this.graph, this.employees)
        renderGraph(this.graph)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/graph.scss";

$distribution-blue-color: #b5cedf;
$distribution-purple-color: #dbb2dc;

svg::v-deep .ordinate .label {
  @include font-semibold;
  fill: $graph-text-color;
  paint-order: stroke;
  stroke: white;
  stroke-width: 4px;
  stroke-linecap: butt;
  stroke-linejoin: miter;
}

svg::v-deep .bar {
  fill: $distribution-blue-color;

  &.female {
    fill: $distribution-purple-color;
  }
}

.wrapper::v-deep .legend {
  li::before {
    border-radius: 2px;
    background: $distribution-blue-color;
  }

  li.female::before {
    background: $distribution-purple-color;
  }
}
</style>
