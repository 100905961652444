<template>
  <div class="components-table" v-if="gridConfig">
    <table class="blue readonly">
      <tr
        v-for="config in gridConfig"
        :key="config.id"
        class="row">
        <!-- Role configuration -->
        <template v-if="config.ref === 'role'">
          <td>
            <div class="component">{{getComponentName(config.id)}}</div>
            <div>{{getComponentLevelsSummary(config.id)}}</div>
          </td>
          <td>
            <div class="sep"></div>
          </td>
          <td>
            <div class="role-config split-config">
              <div class="split-header" v-if="isHasAutoLevelsAvailable(config)">
                <checkbox
                  v-model="config.hasAutoLevels"
                  @change="onChange">
                  {{getComponentAutoCheckboxLabel(config.id)}}
                </checkbox>
              </div>
              <resizable-panel :observer="[config.values.length, config.hasAutoLevels]">
                <div
                  class="split-item"
                  :class="{borderless: (value === config.values[0]) && !isHasAutoLevelsAvailable(config)}"
                  v-for="value in !config.hasAutoLevels ? config.values : []"
                  :key="value.name">
                  <operation-input
                    operation="addition"
                    :min="100"
                    :step="100"
                    :disabled="config.hasAutoLevels"
                    v-model="value.value"
                    @change="onChange" />
                  <label>{{value.name}}</label>
                </div>
              </resizable-panel>
            </div>
          </td>
        </template>
        <!-- Experience configuration -->
        <template v-else-if="config.ref === 'experience'">
          <td>
            <div class="component">{{getComponentName(config.id)}}</div>
            <div v-if="!config.forceHasSplitLevels">{{getComponentLevelsSummary(config.id)}}</div>
          </td>
          <td>
            <div class="sep"></div>
          </td>
          <td>
            <div class="experience-config split-config">
              <div class="split-header" :class="{split: !config.hasAutoLevels}">
                <p class="no-margin-top">
                  <Dropdown
                    class="large description blue"
                    :items="intervalItems"
                    @change="selectInterval">
                    <span v-t="`grid.onboarding.generate.config.interval.dropdown.${experienceConfig.hasIntervals}.title`"></span>
                    <span class="down">▾</span>
                  </Dropdown>
                  <!--
                    TODO: Add interval options:
                    - Minimum interval step
                    - Included salaries percent
                  -->
                </p>
                <checkbox
                  v-if="isHasAutoLevelsAvailable(config)"
                  v-model="config.hasAutoLevels"
                  @change="onChange">
                  {{getComponentAutoCheckboxLabel(config.id)}}
                </checkbox>
                <checkbox
                  v-if="!config.forceHasSplitLevels"
                  v-model="experienceMergedLevels"
                  @change="updateExperienceConfig">
                  <span v-t="'grid.onboarding.generate.config.experienceMergedLevels'"></span>
                </checkbox>
              </div>
              <resizable-panel :observer="[config.curves.length, config.hasAutoLevels]">
                <div class="flex split-item"
                  v-for="curve in !config.hasAutoLevels ? config.curves : []"
                  :key="curve.linkedLevelId">
                  <div class="evolution-name" v-if="curve.name">
                    <div class="component">{{curve.name}}</div>
                    <div>{{getComponentLevelsSummary(config.id, curve.linkedLevelId)}}</div>
                  </div>
                  <div>
                    <div class="evolution-selector">
                      <dropdown class="blue" :items="getExperienceCurves(config, curve)" @change="selectExperienceCurve(curve.linkedLevelId, ...arguments)">
                        <span v-t="`grid.onboarding.generate.config.curve.${curve.curve}`"></span>
                        <span class="down">▾</span>
                      </dropdown>
                    </div>
                    <div>
                      {{$t('grid.onboarding.generate.config.evolutionFrom')}}
                      <OperationInput
                        operation="multiplier"
                        :min="0.1"
                        :max="100"
                        :step="0.1"
                        v-model="curve.range[0]"
                        @change="onChange" />
                      {{$t('common.and')}}
                      <OperationInput
                        operation="multiplier"
                        :min="0.1"
                        :max="100"
                        :step="0.1"
                        v-model="curve.range[1]"
                        @change="onChange" />
                    </div>
                  </div>
                  <div class="evolution-graph">
                    <mini-evolution-graph
                      class="shadows"
                      :value="getExperienceGraphValues(config, curve)"
                      :height="70"
                      :padding="8" />
                  </div>
                </div>
              </resizable-panel>
            </div>
          </td>
        </template>
        <!-- Linear (Increment) configuration -->
        <template v-else-if="config.type === 'linear'">
          <td>
            <div class="component">{{getComponentName(config.id)}}</div>
            <div>{{getComponentLevelsSummary(config.id)}}</div>
          </td>
          <td>
            <div class="sep"></div>
          </td>
          <td>
            <div class="linear-config split-config">
              <div class="split-header">
                <checkbox
                  v-model="config.hasAutoLevels"
                  @change="onChange">
                  {{getComponentAutoCheckboxLabel(config.id)}}
                </checkbox>
              </div>
              <resizable-panel :observer="config.hasAutoLevels">
                <div class="split-item" v-if="!config.hasAutoLevels">
                  {{$t('grid.onboarding.generate.config.valueFromTo', {min: config.range[0]})}}
                  <operation-input
                    operation="addition"
                    :min="100"
                    :step="500"
                    :disabled="config.hasAutoLevels"
                    v-model="config.range[1]"
                    @change="onChange" />
                </div>
              </resizable-panel>
            </div>
          </td>
        </template>
        <!-- Fixed (Coefficient) configuration -->
        <template v-else-if="config.type === 'fixed'">
          <td>
            <div class="component">{{getComponentName(config.id)}}</div>
            <div>{{getComponentLevelsSummary(config.id)}}</div>
          </td>
          <td>
            <div class="sep"></div>
          </td>
          <td>
            <div class="fixed-config split-config">
              <div class="split-header" v-if="isHasAutoLevelsAvailable(config)">
                <checkbox
                  v-model="config.hasAutoLevels"
                  @change="onChange">
                  {{getComponentAutoCheckboxLabel(config.id)}}
                </checkbox>
              </div>
              <resizable-panel :observer="[config.values.length, config.hasAutoLevels]">
                <div
                  class="split-item"
                  :class="{borderless: (value === config.values[0]) && !isHasAutoLevelsAvailable(config)}"
                  v-for="value in !config.hasAutoLevels ? config.values : []"
                  :key="value.name">
                  <operation-input
                    operation="multiplier"
                    :max="5"
                    :step="0.1"
                    :disabled="config.hasAutoLevels"
                    v-model="value.value"
                    @change="onChange" />
                  <label>{{value.name}}</label>
                </div>
              </resizable-panel>
            </div>
          </td>
        </template>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getLevelName } from '@/utils/grid'
import { generateCurveValues } from '@/utils/graph'
import cloneDeepWith from 'lodash.clonedeepwith'
import Checkbox from '@components/commons/Checkbox.vue'
import Dropdown from '@components/commons/Dropdown.vue'
import OperationInput from '@components/commons/OperationInput.vue'
import ResizablePanel from '@components/commons/ResizablePanel.vue'
import MiniEvolutionGraph from '@components/graph/MiniEvolutionGraph.vue'

export default {
  components: {
    Checkbox,
    Dropdown,
    MiniEvolutionGraph,
    OperationInput,
    ResizablePanel
  },
  data() {
    return {
      gridConfig: null
    }
  },
  methods: {
    initModel() {
      this.gridConfig = cloneDeepWith(this.$store.getters['onboarding/getGridConfig'])
    },
    updateExperienceConfig() {
      const experienceConfig = this.experienceConfig
      const refCurve = experienceConfig.curves[0]
      if (experienceConfig.hasSplitLevels) {
        const roleComponent = this.gridComponents.find(c => c.ref === 'role')
        experienceConfig.curves = roleComponent.levels.map(l => ({
          ...cloneDeepWith(refCurve),
          linkedLevelId: l.id,
          name: l.name
        }))
      }
      else {
        experienceConfig.curves = [{
          ...cloneDeepWith(refCurve),
          linkedLevelId: null,
          name: null
        }]
      }
      this.onChange()
    },
    onChange() {
      this.$store.dispatch('onboarding/setGridConfig', this.gridConfig)
      setTimeout(() => {
        this.$parent.$emit('resize')
      }, 400)
    },
    selectExperienceCurve(linkedLevelId, _, value) {
      const experienceConfig = this.experienceConfig
      const curveConfig = experienceConfig.curves.find(c => c.linkedLevelId === linkedLevelId)
      curveConfig.curve = value
      this.onChange()
    },
    selectInterval(_, value) {
      this.experienceConfig.hasIntervals = value
      this.onChange()
    },
    getComponent(id) {
      return this.gridComponents.find(c => c.id === id)
    },
    getComponentName(id) {
      return this.getComponent(id).name
    },
    getComponentLevels(id, linkedLevelId) {
      const component = this.getComponent(id)
      if (component) {
        const uniqLevels = component.levels.filter(l =>
          !l.linkedLevelId || l.linkedLevelId === (linkedLevelId || component.levels[0].linkedLevelId))
        return uniqLevels.map(l => l.name)
      }
      else {
        return []
      }
    },
    getComponentLevelsSummary(id, linkedLevelId) {
      const component = this.getComponent(id)
      const count = this.getComponentLevels(id, linkedLevelId).length
      return getLevelName(component.ref, count)
    },
    getComponentAutoCheckboxLabel(id) {
      const component = this.getComponent(id)
      const operation = this.$t('grid.onboarding.generate.config.' + (component.ref === 'role' ? 'salaryBase' : ('operation.' + component.salaryOperation)))
      const levels = getLevelName(component.ref, 0).replace('0 ', '')
      return this.$t('grid.onboarding.generate.config.hasAutoLevelsLabel', { operation, levels })
    },
    getExperienceGraphValues(config, curve) {
      const count = this.getComponentLevels(config.id, curve.linkedLevelId).length
      return generateCurveValues(count, curve.curve, curve.range[0], curve.range[1])
    },
    getExperienceCurves(config, curve) {
      return [{
        name: this.$t('grid.onboarding.generate.config.curve.title'),
        value: 'curve',
        items: config.availableCurves.map(item => ({
          name: this.$t(`grid.onboarding.generate.config.curve.${item}`),
          value: item,
          selected: curve.curve === item
        }))
      }]
    },
    isHasAutoLevelsAvailable(config) {
      const component = this.getComponent(config.id)
      const hasLinkedLevels = component && component.levels.find(l => l.linkedLevelId)
      return config.type !== 'fixed' || (config.ref === 'experience' && hasLinkedLevels)
    }
  },
  computed: {
    ...mapGetters({
      gridComponents: 'sandbox/allComponents'
    }),
    intervalItems() {
      return [{
        name: this.$t('grid.onboarding.generate.config.interval.dropdown.title'),
        value: 'interval',
        items: [false, true].map(type => ({
          name: this.$t(`grid.onboarding.generate.config.interval.dropdown.${type}.title`),
          label: this.$t(`grid.onboarding.generate.config.interval.dropdown.${type}.description`),
          value: type,
          selected: this.experienceConfig.hasIntervals === type
        }))
      }]
    },
    experienceConfig() {
      return this.gridConfig.find(c => c.ref === 'experience') || {}
    },
    experienceMergedLevels: {
      get() {
        return !this.experienceConfig.hasSplitLevels
      },
      set(value) {
        this.experienceConfig.hasSplitLevels = !value
      }
    }
  },
  created() {
    this.initModel()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/form.scss";
@import "./src/styles/table.scss";

.components-table {
  border: 1px solid lighten($graph-darkblue-color, 30);
  border-radius: $border-radius;

  .row {
    cursor: inherit;
  }

  td {
    line-height: 1.4em;
    border-bottom: 1px solid lighten($graph-darkblue-color, 40);
    padding: 0.75em 1em;
    vertical-align: middle;
  }

  td:first-of-type {
    white-space: nowrap;
    vertical-align: top;
  }

  td:nth-of-type(2) {
    vertical-align: top;
    padding: 0;
    position: relative;

    .sep {
      position: absolute;
      margin: 0;
      height: 100%;
      width: 1px;
      padding: 10px 0;

      &:before {
        content: " ";
        display: block;
        height: 100%;
        width: 100%;
        background: lighten($graph-darkblue-color, 40);
      }
    }
  }

  td:nth-of-type(3) {
    width: 100%;
    padding-left: 2em;
  }
}

.component {
  @include font-large-size;
  @include font-semibold;
  display: inline-block;
  color: $graph-darkblue-color;
  margin: 0 1rem 0.25rem 0;
}

.operation-input {
  &:not(:first-child) {
    margin-left: 0.25em;
  }

  & + label {
    margin-left: 0.75em;
  }
}

.checkbox + .checkbox {
  display: inline-block;
  margin-top: 9px;
}

.split-config {
  margin-left: -2em;

  .split-header,
  .split-item {
    margin: 0.75em 0;
    padding-left: 2em;
  }

  .split-header {
    margin: 0.25em 0;
  }

  .split-item {
    &:not(.borderless) {
      padding-top: 0.75em;
      border-top: 1px solid lighten($graph-darkblue-color, 45);
    }

    &.borderless:first-of-type {
      margin-top: 0.25em;
    }

    &:last-of-type {
      margin-bottom: 0.25em;
    }

    &.flex {
      display: flex;
      align-items: center;
    }
  }
}

.experience-config {
  .evolution-name {
    width: 150px;
  }

  .evolution-selector {
    width: 245px;
    margin-bottom: 9px;
  }

  .evolution-graph {
    border: 1px solid $graph-outer-border-color;
    border-radius: $border-radius;
    width: 150px;
    font-size: 0;
    line-height: 0;
  }
}
</style>
