<template>
  <div>
    <h1 v-t="'benefits.group.create'" />
    <form @submit.prevent="submit">
      <div class="form-section">
        <div class="input-label" v-t="'benefits.group.form.name'" />
        <input
          ref="benefitsPlanName"
          type="text"
          v-model="benefitsPlanModel.name"
          :placeholder="$t('benefits.group.form.namePlaceholder')"
          :class="{'input-error' : $v.benefitsPlanModel.name.$error}"
          :disabled="isLoading">
      </div>
      <div class="form-section">
        <div class="input-label" v-t="'benefits.group.form.description'" />
        <MarkdownTextEditor
          style="margin: 0"
          :showOKButton="false"
          :rows="2"
          :placeholder="$t('benefits.group.form.descriptionPlaceholder')"
          v-model="benefitsPlanModel.description" />
      </div>
      <menu>
        <LoadingButton type="submit" class="primary" :loading="isLoading">
          <span v-t="'benefits.group.form.create'" />
        </LoadingButton>
        <button type="button" class="secondary" v-t="'benefits.group.form.cancel'" @click="$router.go(-1)" />
      </menu>
    </form>
    <div v-if="error" class="error-message">
      {{error}}
    </div>
  </div>
</template>

<script>
import LoadingButton from '@components/commons/LoadingButton'
import MarkdownTextEditor from '@components/commons/MarkdownTextEditor'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    MarkdownTextEditor,
    LoadingButton
  },
  data() {
    return {
      isLoading: false,
      error: null,
      benefitsPlanModel: {
        name: null,
        description: null
      }
    }
  },
  methods: {
    async submit() {
      this.$v.$touch()

      if (!this.$v.$error) {
        try {
          this.isLoading = true
          this.error = null

          const { id } = await this.$store.dispatch('benefits/createBenefitsPlan', this.benefitsPlanModel)
          this.$router.push({ name: 'benefitsPlan', params: { id } })
        }
        catch (error) {
          this.isLoading = false
          this.error = error
        }
      }
    }
  },
  mounted() {
    this.$refs.benefitsPlanName.focus()
  },
  validations: {
    benefitsPlanModel: { name: { required } }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/form.scss";
@import "./src/styles/button.scss";

input {
  max-width: 300px;
}
</style>
