<template>
  <div>
    <p
      v-if="successMessage"
      class="success-banner alert cool-bump fade-in"
      v-html="successMessage"></p>
    <EmployeesTableContainer
      class="review-employees-container"
      name="reviewWages"
      :employees="filteredEmployees"
      :limit="10"
      :preservedProps="['managerId', 'manager', 'reviewWages']">
      <template v-slot:title>
        <span>Évaluations</span>
        <span class="badge small-badge border-badge">
          {{filteredEmployees.length}}
        </span>
      </template>
      <template v-slot:company>
        <Checkbox
          class="review-filter-checkbox"
          v-if="isFilterVisible"
          v-model="isFiltered">
          Afficher uniquement mes évaluations
        </Checkbox>
      </template>
      <template v-slot:group="slotProps">
        <div class="employee-action employee-action-header">
          <div class="">Manager</div>
          <div
            class="step"
            v-for="(step, i) in getVisibleSteps(review.steps)"
            :key="step.id">
            <span class="light-text">{{i + 1}}.</span>&nbsp;
            {{step.shortName}}
            &nbsp;<span class="light-text">{{getEmployeesProgress(slotProps.employees, step)}}</span>
          </div>
        </div>
      </template>
      <template v-slot:action="slotProps">
        <div class="employee-action" v-if="!isLoading">
          <div class="review-manager">
            <span v-if="slotProps.employee.manager">
              {{slotProps.employee.manager.firstName}}
              {{slotProps.employee.manager.lastName}}
            </span>
          </div>
          <div
            class="step"
            v-for="step in getVisibleSteps(review.steps)"
            :key="step.id">
            <ReviewWageButton
              :employee="slotProps.employee"
              :step="step"
              :wage="slotProps.employee.reviewWages.find(w => w && (w.reviewStepId === step.id))"
              :review="review"
              @open="openEmployeeModal(slotProps.employee, step)" />
          </div>
        </div>
      </template>
    </EmployeesTableContainer>
    <Modal class="review-wage-editor-container" :visible="!!modalPayload" @close="closeModal()">
      <ReviewWageEditor
        ref="modal"
        :payload="modalPayload"
        @close="closeModal"
        @change="onWageChange"
        @delete="onWageDelete" />
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getFieldById, getField } from '@/utils/employee'
import EmployeesTableContainer from '@components/employees/EmployeesTableContainer.vue'
import ReviewWageEditor from '@components/reviews/ReviewWageEditor.vue'
import ReviewWageButton from '@components/reviews/ReviewWageButton.vue'
import Checkbox from '@components/commons/Checkbox.vue'
import Modal from '@components/commons/Modal.vue'
import { getItem, setItem } from '@/utils/storage'
import { getReferenceStepsKinds, getVisibleSteps, isReviewClosed, isWageReviewDone } from '@/utils/reviews'

const CW_REVIEW_WAGES_FILTERED = 'CW_REVIEW_WAGES_FILTERED'

export default {
  components: {
    EmployeesTableContainer,
    Modal,
    ReviewWageButton,
    ReviewWageEditor,
    Checkbox
  },
  props: {
    review: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      wages: [],
      isLoading: false,
      isFiltered: getItem(CW_REVIEW_WAGES_FILTERED, true),
      modalPayload: null,
      successMessage: null,
      wagesReloadInterval: null
    }
  },
  computed: {
    ...mapGetters({
      getEmployee: 'employees/getEmployee',
      getUser: 'account/getUser'
    }),
    enrichedEmployees() {
      const { steps, employees } = this.review
      const wages = this.wages
      return employees
        .map(employee => {
          return {
            ...employee,
            reviewWages: steps.map(step => wages.find(w => (w.employeeId === employee.id) && (w.reviewStepId === step.id)))
          }
        })
    },
    filteredEmployees() {
      return this.isFiltered ? this.enrichedEmployees.filter(e => e.id === this.getUser.id || e.managerId === this.getUser.id) : this.enrichedEmployees
    },
    isFilterVisible() {
      return this.enrichedEmployees.filter(e => e.id === this.getUser.id || e.managerId === this.getUser.id).length !== this.enrichedEmployees.length
    }
  },
  methods: {
    getField,
    getFieldById,
    getVisibleSteps,
    async loadWages(isLoadingIndicator = true) {
      this.isLoading = isLoadingIndicator
      this.wages = await this.$store.dispatch('reviews/getReviewWages', this.review.id)
      this.isLoading = false
    },
    openEmployeeModal(employee, step) {
      const review = this.review
      const wage = employee.reviewWages.find(w => w.reviewStepId === step.id)
      const referenceStepsKinds = getReferenceStepsKinds(step)
      const referenceSteps = review.steps.filter(s => referenceStepsKinds.includes(s.kind))
      const referenceWages = referenceSteps.map(s => employee.reviewWages.find(w => w.reviewStepId === s.id))
      this.modalPayload = { employee, step, review, wage, referenceSteps, referenceWages }
      this.hideBeacon()
    },
    closeModal(successMessage) {
      if (this.$refs.modal) {
        this.$refs.modal.flushSubmitDebounced()
      }
      this.modalPayload = null
      if (successMessage) {
        setTimeout(() => {
          this.successMessage = successMessage
        }, 400)
      }
      else {
        this.successMessage = null
      }
      this.showBeacon()
    },
    showBeacon() {
      const beacon = document.getElementById('beacon-container')
      if (beacon) {
        beacon.style.display = 'block'
      }
    },
    hideBeacon() {
      const beacon = document.getElementById('beacon-container')
      if (beacon) {
        beacon.style.display = 'none'
      }
    },
    onWageChange(wage) {
      this.wages = this.wages.filter(w => w.id !== wage.id).concat([wage])
    },
    onWageDelete({ oldWage, newWage }) {
      this.wages = this.wages.filter(w => w.id !== oldWage.id).concat([newWage])
      this.modalPayload.wage = newWage
    },
    getEmployeesProgress(employees, step) {
      if (!this.wages.length || employees.length === 1) {
        return
      }
      return employees.filter(e => e.reviewWages.find(w => w && w.reviewStepId === step.id && isWageReviewDone(w.review))).length +
        '/' +
        employees.length
    }
  },
  watch: {
    isFiltered() {
      setItem(CW_REVIEW_WAGES_FILTERED, this.isFiltered)
    }
  },
  beforeDestroy() {
    clearInterval(this.wagesReloadInterval)
  },
  created() {
    this.loadWages()
    this.wagesReloadInterval = setInterval(() => {
      if (!this.modalPayload && !isReviewClosed(this.review) && document.visibilityState === 'visible') {
        console.log('Reload wages!')
        this.loadWages(false)
      }
    }, 30000)
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/alert.scss";
@import "./src/styles/animation.scss";
@import "./src/styles/badge.scss";
@import "./src/styles/button.scss";

.badge {
  margin-left: 0.8em;
}

.hero.placeholder {
  @extend .badge-block;
  display: block;
  margin: 20px 0;
  padding: 1em 1em;
}

.employees-picker-modal {
  width: 650px;
  padding: 2em 1em 1em 1em;
}

.review-employees-container::v-deep {
  margin-top: 20px;

  .column-action-slot {
    white-space: normal;
    padding-left: 0;
    text-align: left;
  }

  &.resizable-container,
  .resizable-content {
    overflow: visible;
  }
}

.review-filter-checkbox {
  @include font-normal-size;
  @include line-regular-height;
  @include font-regular;
}

.review-wage-editor-container::v-deep .close-button-container {
  padding: 7px 15px 0 0;
  z-index: 3;
}

.employee-action {
  width: 780px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 2em;
  align-items: center;

  &.employee-action-header {
    white-space: nowrap;
  }

  .review-manager {
    @include font-small-size;
  }

  .step {
    &.synthesis {
      @include font-small-size;
      @include line-regular-height;
      margin: -3px 0;
    }
  }
}

.success-banner {
  white-space: pre;
  margin-bottom: -10px;
}
</style>
