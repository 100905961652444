<template>
  <div class="variable-plan-employees" v-if="enrichedConfig">
    <EmployeesTableContainer
      :hideWhenEmpty="true"
      :employees="includedEmployees"
      name="variable-plan-employees"
      :preservedProps="['isReadOnly', 'arrivalDate', 'departureDate', 'customFields']">
      <template v-slot:title>
        <span>{{$tc('variable.editor.includedEmployees', includedEmployees.length, {count: includedEmployees.length})}}</span>
        <span class="variable-plan-total badge border-badge large-badge">{{computedCents / 100 | formattedCurrency(true)}}</span>
      </template>
      <template v-slot:company>
        <menu>
          <button
            class="secondary"
            @click="exportVariableWages"
            v-t="'common.export'"></button>
          <button
            class="primary"
            v-if="!isSharedIndicatorsDisabled"
            @click="isEmployeesPickerVisible = true"
            v-t="'common.update'"></button>
        </menu>
      </template>
      <template v-slot:intro v-if="sharedIndicatorsCount">
        <div class="shared-indicators">
          <div class="column-name-thumbnail">
            <div class="thumbnail"></div>
          </div>
          <div class="column-name">
            <h2>{{$tc('variable.editor.indicator.sharedIndicators', sharedIndicatorsCount)}}</h2>
            <h3 class="job-title">{{$tc('variable.editor.indicator.sharedIndicatorsDescription', sharedIndicatorsCount)}}</h3>
          </div>
          <IndicatorsEditor
            :mode="'collective'"
            :variablePlan="variablePlan"
            :enrichedConfig="enrichedConfig"
            :disabled="isSharedIndicatorsDisabled"
            v-model="sharedIndicatorsModel"
            @input="onSharedIndicatorsChange" />
        </div>
      </template>
      <template v-slot:action="slotProps">
        <IndicatorsEditor
          :mode="'individual'"
          :variablePlan="variablePlan"
          :enrichedConfig="enrichedConfig"
          :sharedIndicators="sharedIndicatorsModel"
          :employee="slotProps.employee"
          :disabled="isIndicatorsDisabled || !$$hasUpdatePermission(slotProps.employee)"
          v-model="getVariableWage(slotProps.employee).indicators"
          @input="onVariableWageChange(getVariableWage(slotProps.employee))" />
      </template>
    </EmployeesTableContainer>
    <div class="placeholder" v-if="!includedEmployees.length">
      <menu class="hero">
        <p v-t="'variable.employees.intro'"></p>
        <button
          class="primary"
          @click="isEmployeesPickerVisible = true"
          v-t="'variable.employees.add'"></button>
      </menu>
    </div>
    <modal :visible="isEmployeesPickerVisible" @close="isEmployeesPickerVisible = false">
      <div class="employees-picker-modal">
        <h1 v-t="'variable.employees.title'"></h1>
        <div v-t="'variable.employees.intro'"></div>
        <EmployeesPicker
          v-model="includedEmployees"
          @select="selectEmployees" />
      </div>
    </modal>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import { mapGetters } from 'vuex'
import partition from 'lodash.partition'
import { slugify } from '@/utils/string'
import { normalizeDate } from '@/utils/date'
import { computeVariableWagesCents, exportVariableWages, filterIndicators } from '@/utils/variable'
import EmployeesTableContainer from '@components/employees/EmployeesTableContainer.vue'
import Modal from '@components/commons/Modal.vue'
import EmployeesPicker from '@components/commons/EmployeesPicker.vue'
import IndicatorsEditor from '@components/variable/IndicatorsEditor.vue'

export default {
  components: {
    EmployeesPicker,
    EmployeesTableContainer,
    IndicatorsEditor,
    Modal
  },
  props: {
    disableSharedIndicators: Boolean,
    enrichedConfig: Object,
    variablePlan: Object,
    // Array of variable wages
    value: Array
  },
  data() {
    return {
      isEmployeesPickerVisible: false,
      variableWagesModel: [],
      sharedIndicatorsModel: [],
      computedCents: 0
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'account/getUser',
      getEmployeesByIds: 'employees/getEmployeesByIds',
      isSynchronizationEnabled: 'synchronization/isSynchronizationEnabled'
    }),
    includedEmployees() {
      return this.getEmployeesByIds(this.variableWagesModel.map(w => w.userId))
    },
    sharedIndicatorsCount() {
      return this.enrichedConfig.indicators.filter(i => i.source === 'collective' && i.kind !== 'constant').length
    },
    isSharedIndicatorsDisabled() {
      return this.disableSharedIndicators || this.isIndicatorsDisabled || (!this.$$isAdmin && this.variablePlan.creatorId !== this.getUser.id)
    },
    isIndicatorsDisabled() {
      return this.variablePlan.status === 'closed'
    }
  },
  methods: {
    initModel() {
      this.variableWagesModel = [...this.value]
      this.sharedIndicatorsModel = [...this.variablePlan.sharedIndicators]
      this.computeCentsDebounced()
    },
    getVariableWage(employee) {
      return this.variableWagesModel.find(w => w.userId === employee.id)
    },
    async selectEmployees(selections) {
      const variablePlan = this.variablePlan
      const [addedEmployees, removedEmployees] = partition(selections, s => s.isSelected)
      const [addedEmployeeIds, removedEmployeeIds] = [addedEmployees.map(e => e.id), removedEmployees.map(e => e.id)]

      try {
        const updatedVariablePlan = await this.$store.dispatch('variablePlans/assignVariableWages', { variablePlan, addedEmployeeIds, removedEmployeeIds })
        this.variableWagesModel = [...updatedVariablePlan.variableWages]
        this.computeCentsDebounced()
        this.onChange()
      }
      catch (error) {
        error && alert(error)
      }
    },
    onVariableWageChange(variableWage) {
      variableWage.updatedAt = new Date()
      this.$store.dispatch('variablePlans/updateVariableWageDebounced', variableWage)
        .catch(error => error && alert(error))
      this.computeCentsDebounced()
      this.onChange()
    },
    cleanSharedIndicatorsModel() {
      // IndicatorsEditor is normally responsible to filterIndicators() but since this component
      // is hidden when collective indicators disappear, we need to handle this here.
      const previousSharedIndicators = this.sharedIndicatorsModel
      const evaluationOptions = { mode: 'collective' }
      this.sharedIndicatorsModel = filterIndicators(this.enrichedConfig, this.sharedIndicatorsModel, evaluationOptions)
      if (this.sharedIndicatorsModel.length !== previousSharedIndicators.length) {
        this.emitSharedIndicatorsDebounced()
      }
      this.computeCentsDebounced()
    },
    onSharedIndicatorsChange() {
      this.emitSharedIndicatorsDebounced()
      this.computeCentsDebounced()
    },
    emitSharedIndicatorsDebounced: debounce(function() {
      this.$emit('sharedIndicators', this.sharedIndicatorsModel)
    }, 1000),
    onChange() {
      this.$emit('input', this.variableWagesModel)
    },
    computeCentsDebounced: debounce(function() {
      if (this.enrichedConfig) {
        this.computedCents = computeVariableWagesCents(this.variablePlan, this.enrichedConfig, this.sharedIndicatorsModel, this.variableWagesModel)
        this.$emit('computedCents', this.computedCents)
      }
    }, 100),
    updateVariableWagesCents() {
      this.variableWagesModel.forEach(variableWage => {
        variableWage.updatedAt = new Date()
        variableWage.indicators = filterIndicators(this.enrichedConfig, variableWage.indicators, { mode: 'individual' })
        const options = { freezeIndicators: this.isIndicatorsDisabled }
        variableWage.computedCents = computeVariableWagesCents(this.variablePlan, this.enrichedConfig, this.sharedIndicatorsModel, this.variableWagesModel, variableWage.id, options)
        this.$store.dispatch('variablePlans/updateVariableWageDebounced', variableWage).catch(_ => {})
      })
    },
    exportVariableWages() {
      const rows = exportVariableWages(this.variablePlan, this.enrichedConfig, this.sharedIndicatorsModel, this.variableWagesModel)
      const filename = this.$t('variable.form.export.xlsxFilename', { name: slugify(this.variablePlan.name), date: normalizeDate(new Date()) })
      this.$router.push({ name: 'export', params: { rows, filename } })
    }
  },
  watch: {
    'enrichedConfig.indicators': 'cleanSharedIndicatorsModel',
    'enrichedConfig.formulas': 'computeCentsDebounced'
  },
  created() {
    this.initModel()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/badge.scss";
@import "./src/styles/button.scss";

.placeholder {
  @extend .badge-block;
  display: block;
  margin-top: 2em;

  menu.hero {
    margin: 1em 0;
  }
}

.employees-picker-modal {
  width: 650px;
  padding: 20px;
}

.variable-plan-total {
  margin-left: 0.75rem;
  vertical-align: -1px;
}

.shared-indicators {
  display: flex;
  height: 100%;
  align-items: center;
  border-radius: $border-radius;
  padding: 6px 12px;

  // Based on EmployeesTable
  .column-name-thumbnail {
    width: 46px;

    .thumbnail {
      display: inline-block;
      margin: 0 auto;
      width: 34px;
      height: 34px;
      border-radius: 50%;
      vertical-align: middle;
      background: #D1E4FE none no-repeat center center;
      background-image: url(~@/assets/variable-collective.svg);
    }
  }

  .column-name {
    flex-grow: 1;

    h2 {
      @include font-normal-size;
      margin-bottom: 0;
    }

    h3 {
      @include font-small-size;
      @include font-regular;
      margin-bottom: 0;
    }

    .job-title {
      color: $light-text-color;
      display: inline-block;
      margin-right: 0.5em;
    }
  }
}
</style>
