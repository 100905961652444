<template>
  <div class="form">
    <h3 v-t="'settings.settingsCompanyInfo.title'"></h3>
    <form @submit.prevent="update">
      <div class="form-section">
        <div class="input-label" v-t="'settings.settingsCompanyInfo.name'"></div>
        <div class="company-name">
          <input
            type="text"
            v-model="companyModel.name"
            :class="{'input-error' : $v.companyModel.name.$error}">
        </div>
      </div>
      <div class="form-section">
        <div class="input-label" v-t="'settings.settingsCompanyInfo.locale.title'"></div>
        <dropdown
          class="language-dropdown large"
          :items="locales"
          @change="setLocale">
          <div class="current-language">
            <img :src="require(`@/assets/${companyModel.locale}.svg`)">
            {{ $t(`settings.settingsCompanyInfo.locale.${this.companyModel.locale}`) }}
          </div>
        </dropdown>
      </div>
      <div class="form-section">
        <div class="input-label" v-t="'settings.settingsCompanyInfo.currency.title'"></div>
        <dropdown
          class="language-dropdown large"
          :items="currencies"
          @change="setCurrency">
          <div class="current-currency" :class="{long: getCurrencySymbol(this.companyModel.currency).length > 1}">
            <span>{{getCurrencySymbol(this.companyModel.currency)}}</span>
            {{ $t(`settings.settingsCompanyInfo.currency.${this.companyModel.currency}`) }}
          </div>
        </dropdown>
      </div>
      <div>
        <p v-if="updateSuccess" class="alert" v-t="'settings.updateSuccess'"></p>
        <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
        <loading-button
          class="primary settings-button"
          :loading="isLoading">
          <span v-t="'settings.update'"></span>
        </loading-button>
      </div>
    </form>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import i18n from '@/i18n'
import Dropdown from '@components/commons/Dropdown.vue'
import { AVAILABLE_CURRENCIES, getCurrencySymbol } from '@/utils/currency'

export default {
  data() {
    return {
      companyModel: {
        name: null,
        hasOpenPolicy: false,
        locale: i18n.locale,
        currency: i18n.currency
      },
      errorMessage: null,
      updateSuccess: false,
      isLoading: false
    }
  },
  computed: {
    company() {
      return this.$store.getters['account/getCompany']
    },
    locales() {
      return [{
        name: this.$t('settings.settingsCompanyInfo.locale.title'),
        items: i18n.availableLocales.map(availableLocale => ({
          name: this.$t(`settings.settingsCompanyInfo.locale.${availableLocale}`),
          value: availableLocale,
          selected: this.companyModel.locale === availableLocale
        }))
      }]
    },
    currencies() {
      return [{
        name: this.$t('settings.settingsCompanyInfo.currency.title'),
        items: AVAILABLE_CURRENCIES.map(currency => ({
          name: this.$t(`settings.settingsCompanyInfo.currency.${currency}`),
          value: currency,
          selected: this.companyModel.currency === currency
        }))
      }]
    }
  },
  methods: {
    getCurrencySymbol,
    update() {
      this.$v.$touch()

      if (!this.$v.$error) {
        this.updateSuccess = false
        this.errorMessage = null
        this.isLoading = true

        this.$store.dispatch('company/update', this.companyModel)
          .then(response => {
            this.isLoading = false
            this.updateSuccess = true
          })
          .catch(error => {
            this.isLoading = false
            this.errorMessage = error
          })
      }
    },
    setLocale(_, value) {
      this.companyModel.locale = value
    },
    setCurrency(_, value) {
      this.companyModel.currency = value
    }
  },
  mounted() {
    this.companyModel.name = this.company.name
    this.companyModel.hasOpenPolicy = this.company.hasOpenPolicy
    this.companyModel.locale = this.company.locale
    this.companyModel.currency = this.company.currency
  },
  validations() {
    return {
      companyModel: {
        name: { required }
      }
    }
  },
  components: {
    Dropdown
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/settings.scss";

input[type="text"] {
  width: 300px;
}

.current-language {
  display: flex;
  min-width: 85px;
  align-items: center;

  img {
    margin-right: 7px;
  }
}

.current-currency {
  display: flex;
  min-width: 85px;
  align-items: center;

  span {
    @include font-bold;
    width: 22px;
    text-align: center;
    margin-right: 7px;
  }

  &.long span {
    @include font-smaller-size;
  }
}
</style>
