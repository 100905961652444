<template>
  <div>
    <div class="links" v-if="links">
      <div
        class="link"
        v-for="(link, index) in links"
        :key="`${link.name}-${link.url}`">
        <div>
          <p>{{link.name}}</p>
          <a :href="link.url" target="_blank">{{link.url}}</a>
        </div>
        <div @click="removeLink(index)" class="close"><span>×</span></div>
      </div>
    </div>
    <ResizablePanel :observer="formDisplayed">
      <button
        class="small-button"
        v-if="!formDisplayed"
        @click="showForm" v-t="'benefits.form.addExternalLink'" />
      <div class="link-form" v-if="formDisplayed">
        <form @submit.prevent="addLink">
          <div class="input-label" v-t="'benefits.form.linkTitle'" />
          <input
            type="text"
            ref="linkName"
            :placeholder="$t('benefits.form.linkNamePlaceholder')"
            v-model="linkModel.name"
            :class="{'input-error' : $v.linkModel.name.$error}">
          <div class="input-label" v-t="'benefits.form.link'" />
          <input
            type="text"
            :placeholder="$t('benefits.form.linkPlaceholder')"
            v-model="linkModel.url"
            :class="{'input-error' : $v.linkModel.url.$error}">
          <button type="button" class="cancel small-button" v-t="'benefits.form.cancel'" @click="closeForm" />
          <button type="submit" class="small-button" v-t="'benefits.form.add'" />
        </form>
      </div>
    </ResizablePanel>
  </div>
</template>

<script>
import ResizablePanel from '@components/commons/ResizablePanel'
import { required, url } from 'vuelidate/lib/validators'

export default {
  components: {
    ResizablePanel
  },
  props: ['value'],
  watch: {
    value: {
      immediate: true,
      handler: function(newValue, _oldValue) {
        this.benefit = newValue
      }
    }
  },
  data() {
    return {
      benefit: null,
      formDisplayed: false,
      linkModel: {}
    }
  },
  computed: {
    links() {
      return this.benefit && this.benefit.details.links
    }
  },
  methods: {
    showForm() {
      this.formDisplayed = true
      this.$nextTick(() => this.$refs.linkName.focus())
    },
    closeForm() {
      this.formDisplayed = false
      this.linkModel = {}
      this.$v.$reset()
    },
    addLink() {
      this.$v.$touch()

      if (!this.$v.$error) {
        this.benefit.details.links.push(this.linkModel)
        this.linkModel = {}
        this.formDisplayed = false
        this.$emit('input', this.benefit)
        this.$v.$reset()
      }
    },
    removeLink(index) {
      this.benefit.details.links.splice(index, 1)
      this.$emit('input', this.benefit)
    }
  },
  validations: {
    linkModel: {
      name: { required },
      url: { required, url }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";

.link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: $border;
  border-radius: $border-radius;
  padding: 0.5em 0.5em;
  margin-bottom: 1em;

  &:first-child {
    margin-top: 0;
  }

  p {
    @include font-semibold;
    margin: 0;
  }

  a {
    @include font-small-size;
    color: $light-text-color;
  }

  .close {
    @include font-semibold;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5em;
    width: 30px;
    line-height: 30px;
    border-radius: 100px;
    cursor: pointer;

    &:hover {
      background: $background-color;
    }
  }
}

.link-form {
  padding: 0.8em 1em;
  background: $graph-background-color;
  border-radius: $border-radius;
  text-align: right;

  input {
    margin-bottom: 0.8em;
  }

  .cancel {
    border: 0;
    background: 0;
    margin-right: 0.5em;
  }
}
</style>
