<template>
  <div class="grid-editor-actions">
    <div class="grid-editor-actions-menu">
      <div class="input-label" v-t="'grid.editor.actionsMenu'"></div>
      <div class="grid-editor-actions-buttons">
        <grid-exclusion-button />
        <grid-export-button :grid="grid" />
        <grid-preview-button :grid="grid" />
      </div>
    </div>
  </div>
</template>

<script>
import GridExclusionButton from '@components/grid/qualification/GridExclusionButton'
import GridExportButton from '@components/grid/editor/GridExportButton'
import GridPreviewButton from '@components/grid/viewer/GridPreviewButton'

export default {
  props: {
    grid: Object
  },
  components: {
    GridExclusionButton,
    GridExportButton,
    GridPreviewButton
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/block.scss";
@import "./src/styles/form.scss";

.grid-editor-actions {
  text-align: right;
}

.grid-editor-actions-menu {
  @extend .block;
    border-bottom: none;
    display: inline-block;
    text-align: left;
    padding: 0.8em;
    margin: 20px 0 0;
}

.input-label {
  margin: 0 0 0.6rem 0;
}

.grid-editor-actions-buttons *:not(:last-child) {
  margin-right: 0.6rem;
}
</style>
