<template>
  <div class="wrapper">
    <div class="svg-container grid-container" v-if="isValidGrid">
      <div class="grid-component-table">
        <table class="blue no-bg">
          <!-- Header -->
          <tr>
            <th class="level-name">
              Métier
            </th>
            <th
              v-for="groupedLevel in experienceGroupedLevels"
              :key="groupedLevel.rank">{{groupedLevel.name}}</th>
          </tr>
          <template v-for="(level, i) in enrichedLevels">
            <tr
              v-if="hasLevelGroups && level.group && (i === 0 || enrichedLevels[i - 1].group !== level.group)"
              :key="[level.id, 'group'].join()"
              class="row group">
              <td
                class="level-name"
                :colspan="experienceGroupedLevels.length + 1">{{level.group}}</td>
            </tr>
            <tr
              :key="level.id"
              class="row">
              <td class="level-name">
                {{ level.name }}
              </td>
              <td
                v-for="(linkedLevel, i) in level.linkedLevels"
                :key="i"
                class="linked-level"
                :class="{dimmed: !linkedLevel || linkedLevel.isHidden}">
                <EmployeesBadges
                  v-if="linkedLevel"
                  class="small"
                  :employees="linkedLevel.employees"
                  :limit="hasManyLevels ? 2 : 3"
                  :clickable="true" />
              </td>
            </tr>
          </template>
        </table>
      </div>
      <GraphLegend id="grid" />
    </div>
    <p class="alert placeholder" v-else v-html="$t('dashboard.graphs.grid.noGrid')"></p>
  </div>
</template>

<script>
import { flattenedComponents, getLinkedLevels, getVisibleLevels } from '@/utils/grid'
import EmployeesBadges from '@components/employees/EmployeesBadges'
import { mapGetters } from 'vuex'
import GraphLegend from '@components/graph/GraphLegend.vue'
import groupBy from 'lodash.groupby'
import sortBy from 'lodash.sortby'

export default {
  components: {
    EmployeesBadges,
    GraphLegend
  },
  name: 'grid-graph',
  computed: {
    ...mapGetters({
      employees: 'statistics/getFilteredEmployees',
      grid: 'currentGrid/getReferenceGrid',
      getEmployee: 'employees/getEmployee'
    }),
    isValidGrid() {
      return this.grid && this.roleComponent && this.experienceComponent
    },
    gridComponents() {
      return this.grid ? flattenedComponents(this.grid.components) : []
    },
    roleComponent() {
      return this.gridComponents.find(c => c.ref === 'role')
    },
    roleLevels() {
      return this.roleComponent && getVisibleLevels(this.roleComponent.levels)
    },
    experienceComponent() {
      return this.gridComponents.find(c => c.ref === 'experience')
    },
    experienceGroupedLevels() {
      const levels = getVisibleLevels(this.experienceComponent.levels)
        .filter(l => !l.linkedLevelId || this.roleLevels.find(ll => ll.id === l.linkedLevelId))
      const groupedLevels = groupBy(levels, 'rank')
      return Object.keys(groupedLevels).map(rank => {
        const groupedRankLevels = groupBy(groupedLevels[rank].map(l => l.name))
        const mostCommonLevelName = sortBy(Object.keys(groupedRankLevels), v => -groupedRankLevels[v].length)[0]
        return {
          rank: +rank,
          name: mostCommonLevelName
        }
      })
    },
    hasManyLevels() {
      return this.experienceGroupedLevels >= 10
    },
    hasLevelGroups() {
      return !!this.enrichedLevels.find(({ group }) => group)
    },
    enrichedLevels() {
      return this.roleLevels.map(level => {
        let hasEmployees = false
        const linkedLevels = getLinkedLevels(this.experienceComponent.levels, level)
        const orderedLinkedLevels = this.experienceGroupedLevels.map(e => {
          const linkedLevel = linkedLevels.find(l => l.rank === e.rank)
          if (linkedLevel) {
            const employees = this.employees.reduce((memo, employee) => {
              const wage = employee.scopeValuesWage
              if (wage && wage.hasLevels && wage.levelIds && wage.levelIds.includes(linkedLevel.id)) {
                memo.push(employee)
                hasEmployees = true
              }
              return memo
            }, [])
            return {
              ...linkedLevel,
              employees
            }
          }
        })
        return {
          ...level,
          linkedLevels: orderedLinkedLevels,
          hasEmployees
        }
      })
    }
  },
  methods: {
  }
}

</script>

<style lang="scss" scoped>
@import "./src/styles/alert.scss";
@import "./src/styles/button.scss";
@import "./src/styles/graph.scss";
@import "./src/styles/table.scss";

.grid-container {
  margin-top: 5px;
}

.grid-component-table {
  border: 1px solid $graph-outer-border-color;
  border-radius: $border-radius;
  margin-bottom: 20px;

  table {
    table-layout: fixed;
  }

  th,
  td {
    @include line-regular-height;
    padding: 0.4em;
    white-space: normal;
    border-bottom: 1px solid $graph-outer-border-color;
    border-right: 1px solid $graph-inner-border-color;
    overflow: hidden;
    text-overflow: ellipsis;

    &:first-of-type {
      padding-left: 10px;
    }
  }

  th,
  .row.group {
    @include font-smaller-size;
    font-weight: 550;
    text-transform: uppercase;
  }

  .row:not(.group) td {
    height: 37px;
  }

  .level-name {
    width: 18%;
  }

  .linked-level.dimmed {
    background-color: lighten($graph-lightblue-color, 1) !important;

    * {
      opacity: 0.75;
    }
  }

  .row.group {
    background-color: lighten($graph-lightblue-color, 1) !important;
  }
}

.wrapper::v-deep .legend-container .legend {
  display: none;
}
</style>
