<template>
  <div id="app" :data-route="$route.name">
    <template v-if="$route.meta.layout === false">
      <unsupporter-browser-banner />
      <router-view />
    </template>
    <template v-else-if="$$hasSession">
      <template v-if="isLoaded">
        <div class="app-container" @click="appClicked">
          <admin-banner v-if="isAdminSession" />
          <demo-banner v-else-if="$$isDemoSession" />
          <unsupporter-browser-banner />
          <user-banner v-if="userBanner" :banner="userBanner" />
          <div class="content" :class="{expired: isExpired}">
            <top-bar />
            <div :class="{'root-container': !$route.meta.rootless}">
              <router-view />
            </div>
          </div>
          <modal
            :visible="isReadOnlyAlertDisplayed"
            @close="closeReadOnlyAlert">
            <ReadOnlyAlert />
          </modal>
        </div>
      </template>
      <template v-else>
        <loading-view />
      </template>
    </template>
    <template v-else>
      <div class="visitor-container">
        <unsupporter-browser-banner />
        <top-bar></top-bar>
        <div class="content">
          <div class="root-container">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AdminBanner from '@components/layout/AdminBanner'
import ReadOnlyAlert from '@components/layout/ReadOnlyAlert'
import DemoBanner from '@components/layout/DemoBanner'
import LoadingView from '@components/commons/LoadingView'
import Modal from '@/components/commons/Modal'
import TopBar from '@components/layout/TopBar'
import UnsupporterBrowserBanner from '@components/layout/UnsupporterBrowserBanner'
import UserBanner from '@components/layout/UserBanner'
import VersionChecker from '@/mixins/version-checker'

export default {
  mixins: [
    VersionChecker
  ],
  components: {
    AdminBanner,
    ReadOnlyAlert,
    DemoBanner,
    LoadingView,
    Modal,
    UnsupporterBrowserBanner,
    UserBanner,
    TopBar
  },
  computed: {
    ...mapGetters([
      'isAppLoaded',
      'isAdminSession',
      'isAccountMenuDisplayed',
      'isReadOnlyAlertDisplayed',
      'isExpiredSession',
      'isTrialExpired'

    ]),
    ...mapGetters({
      userBanner: 'account/getUserBanner'
    }),
    isLoaded() {
      return this.isAppLoaded && this.isVersionLoaded
    },
    isExpired() {
      return (this.isExpiredSession || this.isTrialExpired) && this.$route.name !== 'settingsCompanyBilling'
    }
  },
  methods: {
    appClicked(event) {
      const isAccountMenuDisplayed = (event.target.id === 'account-menu-button') ? !this.isAccountMenuDisplayed : false
      if (this.isAccountMenuDisplayed !== isAccountMenuDisplayed) {
        this.$store.commit('setAccountMenuDisplayed', isAccountMenuDisplayed)
      }
    },
    closeReadOnlyAlert() {
      this.$store.commit('setIsReadOnlyAlertDisplayed', false)
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "@/styles/_fonts.scss";
@import "@/styles/_print.scss";

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  margin: 0;
  padding: 0;
  @include font-normal-size;
}

#app {
  background-color: $background-color;
  color: $text-color;
  font-family: $fallback-font;
  @include font-regular;
  min-height: 100vh;
}

@supports (font-variation-settings: normal) {
  #app {
    font-family: $font;
  }
}

h1 {
  margin: 0 0 0.7em 0;
  @include font-title-size;
  @include font-semibold;
}

h2 {
  margin: 0 0 1em 0;
  @include font-bigger-size;
  @include font-semibold;
}

h3 {
  margin: 0 0 1em 0;
  @include font-big-size;
  @include font-semibold;
}

h4 {
  margin: 0 0 1em 0;
  @include font-large-size;
  @include font-semibold;
}

strong,
b,
th {
  @include font-semibold;
}

.small-size {
  @include font-small-size;
}

small {
  @include font-smaller-size;
}

a {
  color: inherit;
}

p {
  line-height: 1.4em;
}

.content {
  display: grid;
  grid-template-rows: auto 1fr;
  background: $background-color;
  transition: filter 600ms ease-out;
}

.visitor-container .content {
  grid-template-rows: 1fr;
  padding: 40px 0 20px 0;
}

.root-container {
  margin: 0 20px 20px 20px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-margin-left {
  margin-left: 0 !important;
}

.no-margin-right {
  margin-right: 0 !important;
}

.nowrap {
  white-space: nowrap;
}

.preserve-lines {
  white-space: pre-line;
}

.light-text {
  color: $light-text-color;
}

.dark-light-text {
  color: $dark-light-text-color;
}

.expired {
  filter: grayscale(1);
}

@media screen and (min-width: $content-max-width + 40px) {
  // Fix jumping scrollbar https://aykevl.nl/2014/09/fix-jumping-scrollbar
  html {
    width: 100vw;
    overflow-x: hidden;
  }

  .app-container .root-container,
  .visitor-container .root-container {
    margin: 0 auto 20px auto;
    width: $content-max-width;
  }
}
</style>
