import { render, staticRenderFns } from "./WageCalculator.vue?vue&type=template&id=88a7ad20&scoped=true&"
import script from "./WageCalculator.vue?vue&type=script&lang=js&"
export * from "./WageCalculator.vue?vue&type=script&lang=js&"
import style0 from "./WageCalculator.vue?vue&type=style&index=0&id=88a7ad20&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88a7ad20",
  null
  
)

export default component.exports