<template>
  <div class="benchmark-wrapper">
    <div class="benchmark-container">
      <h1 v-t="'benchmark.jobs.searchTitle'"></h1>
      <p>
        <input
          ref="search"
          class="search"
          type="search"
          v-model="searchQuery"
          @keydown="searchKeyDown($event)"
          @blur="searchQuery = null"
          :placeholder="$t('benchmark.jobs.searchPlaceholder')">
        <span class="search-label" v-if="searchQuery">
          {{$tc('benchmark.jobs.found', filteredJobs.length, {count: filteredJobs.length})}}
        </span>
        <span class="search-label" v-if="!$route.params.id && !jobsCount" v-t="'benchmark.jobs.loading'"></span>
      </p>
      <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>
      <div class="jobs" v-if="filteredJobs.length">
        <template
          v-for="(job, i) in filteredJobs"
          v-if="i < 50">
          <bdi
            class="job-header"
            :key="'header' + job.id"
            v-if="!i || filteredJobs[i - 1].group != job.group ">
            {{job.group}}
          </bdi>
          <div
            class="job header-link"
            :key="job.id"
            @mousedown="openJob(job.id)">
            {{job.job}}
          </div>
        </template>
      </div>
      <template v-if="!$route.params.id && jobsCount">
        <p v-html="$t('benchmark.jobs.intro', {jobs: $tc('benchmark.jobs.profiles', jobsCount, {count: jobsCount}) })"></p>
        <div>
          <span v-t="'benchmark.jobs.suggestedJobs'"></span>
          <p>
            <span
              class="small-button suggested-job"
              v-for="job in suggestedJobs"
              :key="job.id"
              @click="$router.push('/tools/jobs/' + job.id)">{{job.job}}</span>
          </p>
        </div>
        <div>
          <span v-t="'benchmark.jobs.randomJobs'"></span>
          <p>
            <span
              class="small-button suggested-job"
              v-for="job in randomJobs"
              :key="job.id"
              @click="$router.push('/tools/jobs/' + job.id)">{{job.job}}</span>
          </p>
        </div>
        <p v-html="$t('benchmark.job.sourcesList')"></p>
        <p v-html="$t('benchmark.jobs.footer')"></p>
      </template>
      <div class="fading" :class="{faded: searchQuery}">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      searchFocused: false,
      searchQuery: null,
      errorMessage: null
    }
  },
  methods: {
    openJob(jobId) {
      this.searchQuery = null
      this.$router.push('/tools/jobs/' + jobId)
    },
    searchKeyDown(event) {
      if (event.key === 'Enter') {
        if (this.filteredJobs.length) {
          this.openJob(this.filteredJobs[0].id)
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      jobsCount: 'jobs/getJobsCount',
      suggestedJobs: 'jobs/getSuggestedJobs'
    }),
    filteredJobs() {
      return this.$store.getters['jobs/searchJobs'](this.searchQuery)
    },
    randomJobs() {
      return this.$store.getters['jobs/getRandomJobs'](5)
    }
  },
  mounted() {
    this.$refs.search.focus()
    if (!this.jobsCount) {
      this.$store.dispatch('jobs/getJobs')
        .catch(error => {
          this.errorMessage = error
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";
@import "./src/styles/link.scss";

.benchmark-wrapper {
  @include container;
  min-height: 510px;
}

input.search {
  display: inline-block;
  max-width: 40%;
  margin-bottom: 8px;
}

.search-label {
  margin-left: 1em;
  color: $light-text-color;
}

.jobs {
  line-height: 2em;
  max-height: 18em;
  overflow: auto;
  border: 1px $border-color solid;
  border-radius: $border-radius;
  padding-bottom: 10px;
  position: absolute;
  z-index: 2;
  background: white;
  max-width: 585px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.job,
.job-header {
  padding: 0 10px;
}

.job + .job {
  border-top: 1px solid lighten($border-color, 5);
}

.job-header {
  display: inline-block;
  @include font-smaller-size;
  text-transform: uppercase;
  @include font-bold;
  color: $light-text-color;
}

.info {
  display: inline-block;
  width: 20px;
  height: 20px;
  text-decoration: none;
  background: $light-text-color;
  color: white;
  @include font-bold;
  font-family: serif;
  border-radius: 10px;
  margin-left: 0.25em;
  text-align: center;
  vertical-align: text-bottom;
  line-height: 20px;
  opacity: 0.5;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);

  &:hover {
    opacity: 1;
  }
}

.jobs:not(:hover) .job:first-of-type {
  background: $lightteal-color;
}

.fading {
  transition-property: opacity;
  transition-duration: 400ms;
}

.faded {
  opacity: 0.5;
}

.suggested-job {
  display: inline-block;
  color: inherit;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  white-space: nowrap;
  max-width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
