// Compute percent between a and b
// Round the result to the `precision` number of decimals
// Ex: getPercent(5, 100, 2) → 5.00
export function getPercent(a, b, precision = 2) {
  a = a || 0
  b = b || 0
  let percent = b
    ? (a - b) / b * 100
    : 100
  percent = Math.round(percent * Math.pow(10, precision)) / Math.pow(10, precision)
  return percent
}

// Apply a percent to a value
// Round the result to the `precision` number of decimals
// Ex: applyPercent(5, 1000, 0) → 50
export function applyPercent(percent, a, precision = 0) {
  a = a || 0
  percent = percent || 0
  let value = a + (a * percent / 100)
  value = Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision)
  return value
}
