const state = {
  gridWorker: new Worker(new URL('@/workers/grid.js', import.meta.url)),
  evolutionWorker: new Worker(new URL('@/workers/evolution.js', import.meta.url)),
  payrollWorker: new Worker(new URL('@/workers/payroll.js', import.meta.url)),
  tableWorker: new Worker(new URL('@/workers/table.js', import.meta.url))
}

const getters = {}

const actions = {
  generateGridChangelog({ state: { gridWorker } }, params) {
    return new Promise((resolve, reject) => {
      gridWorker.onmessage = ({ data }) => {
        resolve(data)
      }
      gridWorker.onerror = (error) => {
        reject(error)
      }
      gridWorker.postMessage(params)
    })
  },
  generateEvolutionModel({ state: { evolutionWorker } }, params) {
    return new Promise((resolve, reject) => {
      evolutionWorker.onmessage = ({ data }) => {
        resolve(data)
      }
      evolutionWorker.onerror = (error) => {
        reject(error)
      }
      evolutionWorker.postMessage(params)
    })
  },
  generatePayrollModel({ state: { payrollWorker } }, params) {
    return new Promise((resolve, reject) => {
      payrollWorker.onmessage = ({ data }) => {
        resolve(data)
      }
      payrollWorker.onerror = (error) => {
        reject(error)
      }
      payrollWorker.postMessage(params)
    })
  },
  generateTableModel({ state: { tableWorker } }, params) {
    return new Promise((resolve, reject) => {
      tableWorker.onmessage = ({ data }) => {
        resolve(data)
      }
      tableWorker.onerror = (error) => {
        reject(error)
      }
      tableWorker.postMessage(params)
    })
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
