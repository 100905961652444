<template>
<div>
  <div v-if="header" class="change-header">
    <div>{{header}}</div>
  </div>
  <div class="change-row">
    <name-thumbnail
      :employee="user"
      :inline="true"
      class="blue" />
    <router-link
      class="colored-link"
      :to="{ name: 'employees', params: { query: [user.firstName, user.lastName].join(' ') } }">
      <div>{{user.firstName}} {{user.lastName}}</div>
      <div class="email light-text" :title="user.email">{{user.email}}</div>
    </router-link>
    <div>
      <div class="change-kind" :class="change.kind">
        {{$t(`settings.settingsCompanySynchronization.kind.${change.kind}`)}}
        <tooltip
          :class="`left blue-bg ${change.kind === 'wage_outdated' ? 'orange-icon' : 'green-icon'}`">
          <table class="change-diff-table">
            <tr
              v-for="row in diffTable"
              :key="row.key">
              <td>{{row.key}}</td>
              <td v-if="row.hasDiff">
                <span class="remove">{{row.oldValue}}</span>
                →
                <span class="add">{{row.newValue}}</span></td>
              <td v-else>{{row.newValue}}</td>
            </tr>
          </table>
        </tooltip>
      </div>
    </div>
    <div v-if="showDate" class="change-date">
      {{changeCreatedAt}}
    </div>
    <div v-else>
      <loading-button
        v-if="!change.applied"
        class="small-button green"
        :loading="change.applying"
        @click="applyChange">
        <span v-t="'settings.settingsCompanySynchronization.actions.sync'"></span>
      </loading-button>
      <span v-else class="change-applied" v-t="'settings.settingsCompanySynchronization.changes.success'"></span>
    </div>
  </div>
</div>
</template>

<script>
import NameThumbnail from '@components/commons/NameThumbnail'
import Tooltip from '@components/commons/Tooltip'
import { formatSynchronizationChangeDiff } from '@/utils/diff'
import { parseDateUTC } from '@/utils/date'

export default {
  components: {
    NameThumbnail,
    Tooltip
  },
  props: {
    change: Object,
    header: String,
    showDate: Boolean
  },
  computed: {
    thirdPartyUser() {
      return this.change.thirdPartyUser
    },
    user() {
      return {
        ...this.thirdPartyUser,
        firstName: this.thirdPartyUser.givenName,
        lastName: this.thirdPartyUser.familyName
      }
    },
    diffTable() {
      return formatSynchronizationChangeDiff(this.change.diff)
    },
    changeCreatedAt() {
      return parseDateUTC(this.change.createdAt, false).local().format('DD/MM HH:mm')
    }
  },
  methods: {
    applyChange() {
      this.$emit('submit', this.change)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
@import "./src/styles/link.scss";

.change-row,
.change-header {
  display: grid;
  grid-template-columns: 30px 210px 1fr auto;
  grid-gap: 10px;
  align-items: center;
  align-content: center;
  padding: 6px 0;
  border-bottom: $border;

  & > div:last-of-type {
    text-align: right;
  }
}

.change-header {
  @include font-tiny-size;
  @include font-semibold;
  text-transform: uppercase;
  white-space: nowrap;
  padding: 0.5rem 0;
}

.email {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  @include font-small-size;
}

.change-kind {
  @include font-small-size;
  color: darken($clearteal-color, 10);

  &::before {
    content: ' ';
    display: inline-block;
    width: 14px;
    height: 14px;
    background: transparent url(~@/assets/trend-up.svg) no-repeat center center;
    background-size: contain;
    margin-right: 1px;
    vertical-align: -2px;
    transform: scaleY(-1);
  }

  &.wage_outdated {
    color: $orange-color;

    &::before {
      background-image: url(~@/assets/trend-down.svg);
    }
  }
}

.change-date {
  @include font-small-size;
  color: $light-text-color;
}

.change-applied {
  @include font-small-size;
  color: darken($clearteal-color, 7);
}

.change-diff-table {
  border-collapse: collapse;
  border-style: hidden;
  width: 100%;
  margin: -0.5em 0;

  td {
    padding: 0.4em 0;
  }

  tr:not(:last-of-type) td {
    border-bottom: $border;
  }

  td:first-of-type {
    @include font-semibold;
    padding-right: 0.5em;
  }

  .add {
    color: darken($clearteal-color, 5);
  }

  .remove {
    color: $orange-color;
  }
}
</style>
