<template>
  <div>
    <div class="toolbar">
      <div class="publish-message" v-if="isValid">
        🚀 <span v-t="'sandbox.readyToPublish'"></span>
      </div>
      <div class="publish-message" v-else>
        ⛔ <span v-t="'sandbox.notReadyToPublish'"></span>
        <ul v-if="validationMessages.length > 1">
          <li v-for="message in validationMessages" :key="message">
            {{ message }}
          </li>
        </ul>
        <div v-else>{{ validationMessages[0] }}</div>
      </div>
      <loading-button
        class="btn primary"
        :disabled="!isValid || isLoading"
        :loading="isLoading"
        @click="publishGrid">
        <span v-t="'sandbox.publish'"></span>
      </loading-button>
    </div>
    <div v-if="isGridValid" class="summaries">
      <div class="container" v-if="employeeSandboxWages.length > 0">
        <h2 v-t="'sandbox.summary.team'"></h2>
        <wage-summary
          :wage-summary="employeeSandboxWagesSummary"
          :reference-wage-summary="employeeWagesSummary"
          :show-delta="true">
        </wage-summary>
      </div>
      <div class="container" v-if="jobOfferSandboxWages.length > 0">
        <h2 v-t="'sandbox.summary.jobOffers'"></h2>
        <wage-summary
          :wage-summary="jobOfferSandboxWagesSummary"
          :reference-wage-summary="jobOfferWagesSummary"
          :show-delta="true">
        </wage-summary>
      </div>
      <div class="container" v-if="employeeSandboxWages.length > 0 && jobOfferSandboxWages.length > 0">
        <h2 v-t="'sandbox.summary.total'"></h2>
        <wage-summary
          :wage-summary="totalSandboxWagesSummary"
          :reference-wage-summary="totalWagesSummary"
          :show-delta="true">
        </wage-summary>
      </div>
    </div>
    <div class="container" v-else v-t="'sandbox.summary.unavailable'"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import WageSummary from '@components/wage/WageSummary.vue'

export default {
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      employeeSandboxWages: 'sandbox/employeeWages',
      jobOfferSandboxWages: 'sandbox/jobOfferWages',
      isValid: 'sandbox/isValid',
      isGridValid: 'sandbox/isGridValid',
      isWagesValid: 'sandbox/isWagesValid',
      employeeSandboxWagesSummary: 'sandbox/employeeWagesSummary',
      jobOfferSandboxWagesSummary: 'sandbox/jobOfferWagesSummary',
      totalSandboxWagesSummary: 'sandbox/totalWagesSummary',
      employeeWagesSummary: 'currentGrid/getEmployeeWagesSummary',
      jobOfferWagesSummary: 'currentGrid/getJobOfferWagesSummary',
      totalWagesSummary: 'currentGrid/getTotalWagesSummary'
    }),
    validationMessages() {
      const messages = []

      if (!this.isGridValid) {
        messages.push(this.$t('sandbox.incompleteComponents'))
      }

      if (!this.isWagesValid) {
        messages.push(this.$t('sandbox.incompleteWages'))
      }

      return messages
    }
  },
  methods: {
    publishGrid() {
      if (confirm(this.$t('sandbox.publishConfirmation'))) {
        this.isLoading = true
        this.$store.dispatch('sandbox/publish')
          .then((response) => {
            this.$router.push({ name: 'grid' })
          })
          .catch(e => {
            this.isLoading = false
          })
      }
    }
  },
  components: {
    WageSummary
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";

h2 {
  margin: 0;
  margin-bottom: 20px;
  color: $clearteal-color;
  @include font-semibold;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  border: $border;
  border-radius: $border-radius;
}

.publish-message {
  padding-right: 20px;

  ul {
    margin-bottom: 0;
  }
}

.disabled {
  background-color: $gray-color;

  &:hover {
    background: $gray-color;
  }
}

.summaries {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: flex-start;
  grid-gap: 20px;
}

.container {
  @include container;
}
</style>
