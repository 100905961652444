<template>
  <resizable-panel :observer="visible">
    <div v-if="visible" class="org-chart-onboarding-overlay">
      <div class="close-button-container">
        <div class="close-button" @click="$emit('dismiss')">×</div>
      </div>
      <menu class="hero placeholder no-margin-top no-margin-bottom">
        <h3 v-html="$t('orgChart.onboarding.title')"></h3>
        <p
          class="no-margin-top preserve-lines"
          v-html="$t('orgChart.onboarding.intro', {companyName: $$companyName})"></p>
        <p v-if="$$isAdmin" class="preserve-lines" v-t="'orgChart.onboarding.introAdmin'"></p>
        <button
          class="secondary"
          v-t="'common.ok'"
          @click="$emit('dismiss')"></button>
        <router-link
          v-if="$$isAdmin"
          tag="button"
          class="primary"
          :to="{name: 'updateTeams'}"
          v-t="'teams.form.action'"></router-link>
      </menu>
    </div>
  </resizable-panel>
</template>

<script>
import ResizablePanel from '@components/commons/ResizablePanel.vue'

export default {
  components: {
    ResizablePanel
  },
  props: {
    visible: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";

.org-chart-onboarding-overlay {
  background: rgba(white, 0.8);
  padding: 1em;
  text-align: center;
  margin: 2em auto 1em;
  max-width: $content-max-width * 0.65;
  border-radius: 1em;
  border-bottom: $border;
  position: relative;
}

.close-button-container {
  position: absolute;
  top: 0.8em;
  right: 0;
  text-align: right;
  padding: 0 1em 0 0;
  z-index: 1;

  .close-button {
    display: inline-block;
    @include font-big-size;
    @include font-bold;
    cursor: pointer;
    width: 30px;
    line-height: 30px;
    text-align: center;

    &:hover {
      background: $graph-lightblue-color;
      border-radius: 15px;
    }

    &:active {
      background: darken($graph-lightblue-color, 5);
    }
  }
}
</style>
