import { diffWords } from 'diff'
import { formatCurrency, getCurrencySymbol } from './currency'
import { formatList } from '@/utils/string'
import { formattedDate } from './date'
import camelCase from 'lodash.camelcase'
import groupBy from 'lodash.groupby'
import i18n from '@/i18n'
import orderBy from 'lodash.orderby'
import sortBy from 'lodash.sortby'
import { flattenedComponents, getWageDetails, isSandboxWageValid } from './grid'

// Hack to avoid infinite changelog
const CHANGELOG_LEVELS_PERFORMANCE_LIMIT = 10000

// Compute a list of added, updated and removed items between the two grids and wages.
// Supported items: Components, Levels, Domains, Skills, Wages
// Please note that this is only a heuristics, we cannot know for sure if a new item matches an old one (but we can try!).
// This is a CPU expensive function, use with debounce().
export function generateChangelog(params) {
  const { oldGrid, newGrid, oldWages, newWages, employees, company } = params
  const cache = []
  let changelog = []
  console.time('Generate changelog')

  // Init i18n
  i18n.locale = company.locale
  i18n.currency = company.currency
  i18n.currencySymbol = getCurrencySymbol(company.currency)

  // Updated grid
  const diffGrid = diffGridItems('grid', oldGrid, newGrid)
  if (diffGrid.length) {
    changelog.push({
      operation: 'update',
      kind: 'grid',
      component: { name: '$$grid' },
      name: [{ name: newGrid.title }],
      diff: diffGrid
    })
  }

  const oldComponents = flattenedComponents(oldGrid && oldGrid.components)
  const newComponents = flattenedComponents(newGrid && newGrid.components)

  // Added & updated components
  newComponents.forEach(newComponent => {
    const oldComponent = oldComponents.find(o => isGridItemsSimilar('component', o, newComponent))
    if (!oldComponent) {
      changelog.push({
        operation: 'add',
        kind: 'component',
        component: newComponent,
        name: [newComponent]
      })
    }
    else {
      const diffComponent = diffGridItems('component', oldComponent, newComponent)
      if (diffComponent.length) {
        changelog.push({
          operation: 'update',
          kind: 'component',
          component: newComponent,
          name: [newComponent],
          diff: diffComponent
        })
      }
    }
    // Added & updated levels
    const newParentComponent = newComponent.parentComponentId && newComponents.find(c => c.id === newComponent.parentComponentId)
    const oldParentComponent = oldComponent && oldComponent.parentComponentId && oldComponents.find(c => c.id === oldComponent.parentComponentId)
    const newLevels = newComponent.levels || []
    const oldLevels = (oldComponent && oldComponent.levels) || []
    if (newLevels.length > CHANGELOG_LEVELS_PERFORMANCE_LIMIT) {
      console.log('Component', newComponent.name, 'has too many levels', newLevels.length, 'to be diffed')
      return
    }
    console.time(`Diff updated levels (${newComponent.name})`)
    newLevels.forEach((newLevel, levelIndex) => {
      const newLinkedLevel = findCachedLevel(cache, newParentComponent, newLevel.linkedLevelId)
      const oldLevel = findCachedLevel(cache, oldComponent, newLevel.previousLevelId) || oldLevels.find(oldLevel => {
        const oldLinkedLevel = findCachedLevel(cache, oldParentComponent, oldLevel.linkedLevelId)
        return isGridItemsSimilar('level', oldLevel, newLevel, oldLinkedLevel, newLinkedLevel)
      })
      if (!oldLevel) {
        changelog.push({
          operation: 'add',
          kind: 'level',
          component: newComponent,
          name: [newLinkedLevel, newLevel]
        })
      }
      else {
        const diffLevel = diffGridItems('level', oldLevel, newLevel)
        if (diffLevel.length) {
          changelog.push({
            operation: 'update',
            kind: 'level',
            component: newComponent,
            name: [newLinkedLevel, newLevel],
            diff: diffLevel
          })
        }
      }
      // Added & updated domains
      if (newComponent.hasSkills) {
        const newDomains = newLevel.domains || []
        const oldDomains = (oldLevel && oldLevel.domains) || []
        newDomains.forEach(newDomain => {
          const newDomainLinkedLevel = findCachedLevel(cache, newParentComponent, newDomain.linkedLevelId)
          const oldDomain = oldDomains.find(oldDomain => {
            const oldDomainLinkedLevel = findCachedLevel(cache, oldParentComponent, oldDomain.linkedLevelId)
            return isGridItemsSimilar('domain', oldDomain, newDomain, oldDomainLinkedLevel, newDomainLinkedLevel)
          })
          if (!oldDomain) {
            // Domains are serialized on every level
            if (levelIndex === 0) {
              changelog.push({
                operation: 'add',
                kind: 'domain',
                component: newComponent,
                name: [newDomain]
              })
            }
          }
          else {
            let diffDomain = []
            if (levelIndex === 0) {
              diffDomain = diffGridItems('domain', oldDomain, newDomain)
            }
            const diffDomainDescription = diffGridItems('domainDescription', oldDomain, newDomain)
            if (diffDomainDescription.length) {
              diffDomainDescription.forEach(diff => (diff.key += ' ' + newLevel.name))
            }
            // For domain descriptions, group them inside the previous udpate of a domain
            // This avoids creating a dedicated line for every description
            if (diffDomain.length || diffDomainDescription.length) {
              const newDomainChange = changelog.find(c => c.operation === 'update' && c.kind === 'domain' && c.name[0].name === newDomain.name)
              if (newDomainChange) {
                newDomainChange.diff = newDomainChange.diff.concat(diffDomain.concat(diffDomainDescription))
              }
              else {
                changelog.push({
                  operation: 'update',
                  kind: 'domain',
                  component: newComponent,
                  name: [newDomain],
                  diff: diffDomain.concat(diffDomainDescription)
                })
              }
            }
          }
          // Added & updated skills
          const newSkills = newDomain.skills || []
          const oldSkills = (oldDomain && oldDomain.skills) || []
          newSkills.forEach((newSkill, skillIndex) => {
            const newSkillName = { name: newDomain.name + ' ' + (skillIndex + 1) + '/' + newSkills.length }
            const oldSkill = oldSkills.find(s => isGridItemsSimilar('skill', s, newSkill))
            if (!oldSkill) {
              changelog.push({
                operation: 'add',
                kind: 'skill',
                component: newComponent,
                name: [newLinkedLevel, newLevel, newSkillName],
                diff: [{ newValue: newSkill.name }]
              })
            }
            else {
              const diffSkill = diffGridItems('skill', oldSkill, newSkill)
              if (diffSkill.length) {
                changelog.push({
                  operation: 'update',
                  kind: 'skill',
                  component: newComponent,
                  name: [newLinkedLevel, newLevel, newSkillName],
                  diff: diffSkill
                })
              }
            }
          })
        })
      }
    })
    console.timeEnd(`Diff updated levels (${newComponent.name})`)
  })

  // Removed components
  oldComponents.forEach(oldComponent => {
    const newComponent = newComponents.find(o => isGridItemsSimilar('component', oldComponent, o))
    if (!newComponent) {
      changelog.push({
        operation: 'remove',
        kind: 'component',
        component: oldComponent,
        name: [oldComponent]
      })
    }
    // Removed levels
    const oldParentComponent = oldComponent.parentComponentId &&
      oldComponents.find(c => c.id === oldComponent.parentComponentId)
    const newParentComponent = newComponent && newComponent.parentComponentId &&
      newComponents.find(c => c.id === newComponent.parentComponentId)
    const oldLevels = oldComponent.levels || []
    if (oldLevels.length > CHANGELOG_LEVELS_PERFORMANCE_LIMIT) {
      console.log('Component', newComponent.name, 'has too many levels', oldLevels.length, 'to be diffed')
      return
    }
    console.time(`Diff removed levels (${oldComponent.name})`)
    oldLevels.forEach((oldLevel, levelIndex) => {
      const newLevel = findCachedLevel(cache, newComponent, oldLevel.id, true)
      if (!newLevel) {
        const oldLinkedLevel = findCachedLevel(cache, oldParentComponent, oldLevel.linkedLevelId)
        changelog.push({
          operation: 'remove',
          kind: 'level',
          component: oldComponent,
          name: [oldLinkedLevel, oldLevel]
        })
      }
      // Removed domains
      if (oldComponent.hasSkills) {
        const oldLinkedLevel = findCachedLevel(cache, oldParentComponent, oldLevel.linkedLevelId)
        const oldDomains = oldLevel.domains || []
        const newDomains = (newLevel && newLevel.domains) || []
        oldDomains.forEach(oldDomain => {
          const oldDomainLinkedLevel = findCachedLevel(cache, oldParentComponent, oldDomain.linkedLevelId)
          const newDomain = newDomains.find(newDomain => {
            const newDomainLinkedLevel = findCachedLevel(cache, newParentComponent, newDomain.linkedLevelId)
            return isGridItemsSimilar('domain', oldDomain, newDomain, oldDomainLinkedLevel, newDomainLinkedLevel)
          })
          if (!newDomain) {
            // Domains are serialized on every level
            if (levelIndex === 0) {
              changelog.push({
                operation: 'remove',
                kind: 'domain',
                component: oldComponent,
                name: [oldDomain]
              })
            }
          }
          // Removed skills
          const oldSkills = oldDomain.skills || []
          const newSkills = (newDomain && newDomain.skills) || []
          oldSkills.forEach((oldSkill, skillIndex) => {
            const oldSkillName = { name: oldDomain.name + ' ' + (skillIndex + 1) + '/' + oldSkills.length }
            const newSkill = newSkills.find(s => isGridItemsSimilar('skill', s, oldSkill))
            if (!newSkill) {
              changelog.push({
                operation: 'remove',
                kind: 'skill',
                component: oldComponent,
                name: [oldLinkedLevel, oldLevel, oldSkillName],
                diff: [{ newValue: oldSkill.name }]
              })
            }
          })
        })
      }
    })
    console.timeEnd(`Diff removed levels (${oldComponent.name})`)
  })

  // Updated wages
  // We do not compute added/removed because it should be the consequence of aforementioned changes.
  console.time('Diff wages')
  const newEnrichedWages = newWages.map(wage => ({ ...wage, salary: getWageDetails(newGrid, wage, { salaryOnly: true }).summary.salary.value }))
  const oldEnrichedWages = oldWages.map(wage => ({ ...wage, salary: getWageDetails(oldGrid, wage, { salaryOnly: true }).summary.salary.value }))
  newEnrichedWages.forEach(newWage => {
    const oldWage = oldEnrichedWages.find(w => w.employeeId === newWage.employeeId)
    const employee = employees.find(e => e.id === newWage.employeeId)
    if (oldWage && employee) {
      if (!isGridItemsSimilar('wage', oldWage, newWage)) {
        // Check if the new wage is valid (oldWage should always be ok)
        newWage.salary = isSandboxWageValid(newWage, newGrid) ? newWage.salary : null
        const name = [employee.firstName, employee.lastName].join(' ')
        const diff = diffGridItems('wage', oldWage, newWage)
        changelog.push({
          operation: 'update',
          kind: 'wage',
          component: {},
          name: [{ name }],
          diff
        })
      }
    }
  })
  console.timeEnd('Diff wages')

  // Sort changelog per component, operation, kind then name
  const OPERATION_INDEX = ['update', 'add', 'remove']
  const KIND_INDEX = ['grid', 'component', 'level', 'domain', 'skill', 'wage']
  changelog.forEach(change => {
    change.componentIndex = change.component.rank
    change.operationIndex = OPERATION_INDEX.indexOf(change.operation)
    change.kindIndex = KIND_INDEX.indexOf(change.kind)
    change.component = change.component.name
    change.name = formatDiffName(change.name)
  })
  changelog = sortBy(changelog, ['componentIndex', 'operationIndex', 'kindIndex', 'name'])

  console.timeEnd('Generate changelog')
  // Group changelog per component and compute summary
  return Object.entries(groupBy(changelog, c => c.component)).map(([component, changes]) => {
    const name = component !== 'undefined' ? (component === '$$grid' ? i18n.t('grid.diff.kind.grid') : [i18n.t('grid.diff.kind.component'), component].join(' ')) : i18n.t('grid.diff.kind.wages')
    const summary = formatList(Object.entries(groupBy(changes, c => [c.operation, c.kind].join('|'))).map(([operationKind, changes2]) => {
      const [operation] = operationKind.split('|')
      const operationKey = camelCase(operationKind.split('|'))
      const count = changes2.length
      return `<span class="${operation}">` + i18n.tc(`grid.diff.operation.${operationKey}`, count, { count }) + '</span>'
    })) + '.'
    return {
      name,
      summary,
      changes
    }
  })
}

// Fast level directionary lookup
function findCachedLevel(cache, component, id, isPreviousLevelId = false) {
  if (component && component.levels && id) {
    if (!cache[component.id]) {
      cache[component.id] = {}
      // Cache level id
      cache[component.id][0] = component.levels.reduce((memo, level) => {
        memo[level.id] = level
        return memo
      }, {})
      // Cache previous level id
      cache[component.id][1] = component.levels.reduce((memo, level) => {
        if (level.previousLevelId) {
          memo[level.previousLevelId] = level
        }
        return memo
      }, {})
    }
    return cache[component.id][isPreviousLevelId ? 1 : 0][id]
  }
}

// Check if two items of the grid looks similar depending on their kind
// We should update this method as soon as we add meaningful attributes to these objects.
export function isGridItemsSimilar(kind, oldItem, newItem, oldLinkedItem, newLinkedItem) {
  if (!oldItem || !newItem) {
    return false
  }
  switch (kind) {
    case 'component':
      if (oldItem.name === newItem.name) {
        return true
      }
      if (oldItem.ref && oldItem.ref === newItem.ref) {
        return true
      }
      if (oldItem.rank === newItem.rank && oldItem.description && oldItem.description === newItem.description) {
        return true
      }
      return false
    case 'level':
      if (newItem.previousLevelId && newItem.previousLevelId === oldItem.id) {
        return true
      }
      if (oldLinkedItem && newLinkedItem && oldLinkedItem.name !== newLinkedItem.name) {
        return false
      }
      if (oldItem.name === newItem.name) {
        return true
      }
      if (oldItem.rank === newItem.rank && oldItem.description && oldItem.description === newItem.description) {
        return true
      }
      return false
    case 'domain':
      if (oldLinkedItem && newLinkedItem && oldLinkedItem.name !== newLinkedItem.name) {
        return false
      }
      if (oldItem.name === newItem.name) {
        return true
      }
      return false
    case 'skill':
      return oldItem.index === newItem.index
    case 'wage':
      return oldItem.salary === newItem.salary
  }
}

// Compute the list of differences between two items of the grid depending on their kind
// We should update this method as soon as we add meaningful attributes to these objects.
function diffGridItems(kind, oldItem, newItem) {
  if (!oldItem || !newItem) {
    return []
  }
  let keys = []
  switch (kind) {
    case 'grid':
      keys = ['title', 'description']
      break
    case 'component':
      keys = ['name', 'rank', 'description', 'hasSkills', 'hasSplitDomains', 'hasSkillValues']
      break
    case 'level':
      keys = ['name', 'rank', 'isHidden', 'group', 'description', 'minimumValue', 'salaryValue', 'maximumValue']
      break
    case 'domain':
      keys = ['name', 'index']
      break
    case 'domainDescription':
      keys = ['description']
      break
    case 'skill':
      keys = ['name', 'index']
      break
    case 'wage':
      keys = ['salary']
  }
  const diff = []
  keys.forEach(key => {
    let oldValue = oldItem[key]
    let newValue = newItem[key]
    switch (key) {
      case 'name':
        // Do not display 'name' since it's already displayed above
        key = undefined
        break
      case 'rank':
      case 'index':
        oldValue = (oldValue || 0) + 1
        newValue = (newValue || 0) + 1
        break
      case 'isHidden':
      case 'hasSkills':
      case 'hasSkillValues':
        oldValue = i18n.t(oldValue ? 'common.yes' : 'common.no')
        newValue = i18n.t(newValue ? 'common.yes' : 'common.no')
        break
      case 'salary':
        key = undefined
        oldValue = oldValue ? formatCurrency(oldValue) : `(${i18n.t('grid.editor.toBeDefined')})`
        newValue = newValue ? formatCurrency(newValue) : `(${i18n.t('grid.editor.toBeDefined')})`
        break
    }
    if (key) {
      key = i18n.t(`grid.diff.key.${key}`)
    }
    if (oldValue !== newValue) {
      let parts = [
        { removed: true, value: oldValue || i18n.t('grid.diff.value.empty') },
        { value: ' → ' },
        { added: true, value: newValue || i18n.t('grid.diff.value.empty') }
      ]
      // Only compute diff when it makes sense
      const isDescrition = key === 'description'
      const isSkill = kind === 'skill'
      const isBothString = isNaN(oldValue) && isNaN(newValue)
      if (isBothString && (isDescrition || isSkill)) {
        const diffParts = diffWords(oldValue, newValue)
        // If it gets too long, do not use it, because it quickly gets unreadable
        // Note that for one change, we get at least 2 parts
        if (diffParts.length <= 10) {
          parts = diffParts
        }
      }
      diff.push({ key, newValue, parts })
    }
  })
  return diff
}

// Format a list of grid items as a path like Component › Level › Domain › Skill
function formatDiffName(diffItems) {
  return diffItems.filter(di => di).map(di => di.name).join(' › ')
}

// Inspired by grid.js' diffGridItems()
export function formatSynchronizationChangeDiff(diff) {
  if (diff) {
    return orderBy(diff.map(({ key, label, oldValue, newValue }) => {
      let sortKey = label || key
      const hasDiff = typeof oldValue !== 'undefined'
      switch (key) {
        case 'first_name':
        case 'last_name':
          // Move names to top
          sortKey = '_' + sortKey
          break
        case 'salary_start_date':
          oldValue = oldValue ? formattedDate(oldValue) : i18n.t('grid.diff.value.empty')
          newValue = newValue ? formattedDate(newValue) : i18n.t('grid.diff.value.empty')
          break
        case 'salary_value':
          oldValue = oldValue ? formatCurrency(oldValue) : i18n.t('grid.diff.value.empty')
          newValue = newValue ? formatCurrency(newValue) : i18n.t('grid.diff.value.empty')
          break
        case 'gender':
          oldValue = oldValue ? i18n.t(`employees.fields.gender.value.${oldValue}`) : i18n.t('grid.diff.value.empty')
          newValue = newValue ? i18n.t(`employees.fields.gender.value.${newValue}`) : i18n.t('grid.diff.value.empty')
          break
        case 'is_alumni':
        case 'is_external':
          oldValue = i18n.t(oldValue ? 'common.yes' : 'common.no')
          newValue = i18n.t(newValue ? 'common.yes' : 'common.no')
          break
        case 'departure_date':
          oldValue = oldValue ? formattedDate(oldValue) : i18n.t('grid.diff.value.empty')
          newValue = newValue ? formattedDate(newValue) : i18n.t('grid.diff.value.empty')
          break
        default:
          oldValue = oldValue || i18n.t('grid.diff.value.empty')
          newValue = newValue || i18n.t('grid.diff.value.empty')
      }
      key = label || i18n.t(`employees.fields.${camelCase(key)}.name`)
      return {
        key,
        sortKey,
        hasDiff,
        oldValue,
        newValue
      }
    }), ['sortKey'])
  }
}
