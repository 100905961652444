<template>
  <div>
    <div class="container">
      <menu class="float-right no-margin-top">
        <dropdown :items="sortDropdownItems" @change="setSort">
          <span :class="'arrow-' + sortOrder" v-t="`employees.employee.form.${sortKey}`"></span>
        </dropdown>
        <dropdown :items="displayDropdownItems" @change="setDisplayMode">
          {{$t(`employees.display.${displayMode}`)}}
          <span class="down">▾</span>
        </dropdown>
        <input
          ref="searchQuery"
          type="search"
          spellcheck="false"
          :placeholder="$t('employees.search')"
          @input="onSearchQueryChange"
          @keydown="onSearchKeyDown($event)" />
        <div class="button-group" v-if="$$isAdmin || $$hasPermission('employeesExport')">
          <button
            v-if="$$isAdmin"
            class="secondary"
            v-t="'common.add'"
            @click="$router.push({name: 'addEmployee'})">
          </button>
          <button
            v-if="$$isAdmin"
            class="secondary"
            v-t="'employees.import.action'"
            @click="$router.push({name: 'importEmployees'})">
          </button>
          <button
            v-if="$$hasPermission('employeesExport')"
            class="secondary"
            v-t="'common.export'"
            @click="$router.push({name: 'exportEmployees'})">
          </button>
          <synchronization-button />
        </div>
      </menu>
      <h1 v-t="'employees.title'"></h1>
      <div
        v-if="$route.params.isWagePlanPublishSuccess"
        class="alert slow-fade-in success"
        v-t="'wagePlans.publishSuccess'"></div>
      <p v-if="!searchQuery">
        {{introLabel}}
        {{ $t('employees.summary.contains') }}
        <strong>
          {{ $tc(`employees.${displayMode}.count`, searchQueryCount, { count: searchQueryCount }) }}.
        </strong>
      </p>
      <p v-else>
        <strong>
          {{ $tc(`employees.${displayMode}.count`, searchQueryCount, { count: searchQueryCount }) }}
        </strong>
        {{ $tc('employees.summary.found', searchQueryCount)}}
        <button
          v-if="!searchQueryCount && !isGlobalSearch"
          class="small-button"
          @click="setGlobalSearch"
          v-t="'employees.display.globalSearch'"></button>
      </p>
      <EmployeesTable
        ref="employeesTable"
        :employees="displayedEmployees"
        :groupKey="groupKey"
        :infoSlotTitle="$t('employees.summary.remuneration')"
        :limit="20"
        name="employees"
        :preservedProps="['email', 'benefitsCount', 'benefitsPlansSummary', 'variablePlansSummary']"
        :searchQuery="searchQuery"
        :sortKey="sortKey"
        :sortOrder="sortOrder"
        :showSeniority="true"
        :showStatusBadge="true"
        @count="count => searchQueryCount = count">
        <template v-slot:info="slotProps">
          <EmployeeRemunerationSummarty :employee="slotProps.employee" />
        </template>
      </EmployeesTable>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import { titleize } from '@/utils/string'
import Dropdown from '@components/commons/Dropdown'
import EmployeesTable from '@components/employees/EmployeesTable'
import SynchronizationButton from '@components/settings/SynchronizationButton'
import EmployeeRemunerationSummarty from '@components/employees/EmployeeRemunerationSummary'
import { getSessionItem, setSessionItemClean } from '@/utils/storage'
import { getSetting, setSetting } from '@/utils/settings'

const CW_TEAM_GROUP_KEY = 'CW_TEAM_GROUP_KEY'
const CW_TEAM_SORT_KEY = 'CW_TEAM_SORT_KEY'
const CW_TEAM_SORT_ORDER = 'CW_TEAM_SORT_ORDER'
const CW_TEAM_SEARCH_QUERY = 'CW_TEAM_SEARCH_QUERY'
const CW_TEAM_DISPLAY_MODE = 'CW_TEAM_DISPLAY_MODE'

export default {
  components: {
    Dropdown,
    EmployeeRemunerationSummarty,
    EmployeesTable,
    SynchronizationButton
  },
  data() {
    const defaults = {
      displayItems: ['employees', 'alumnis', 'externals'],
      displayMode: 'employees',

      groupItems: ['none', 'team'],
      groupKey: 'team',

      searchQuery: null,
      searchQueryCount: this.$store.getters['employees/getEmployees'].length,
      isGlobalSearch: false,

      sortItems: ['firstName', 'fullName', 'jobType', 'seniority', 'salary'],
      sortKey: 'firstName',
      sortOrderItems: ['-1', '1'],
      sortOrder: 1
    }

    const displayMode = getSessionItem(CW_TEAM_DISPLAY_MODE)
    if (defaults.displayItems.includes(displayMode)) {
      defaults.displayMode = displayMode
    }
    const groupKey = getSetting(CW_TEAM_GROUP_KEY)
    if (defaults.groupItems.includes(groupKey)) {
      defaults.groupKey = groupKey
    }
    const sortKey = getSetting(CW_TEAM_SORT_KEY)
    if (defaults.sortItems.includes(sortKey)) {
      defaults.sortKey = sortKey
    }
    const sortOrder = getSetting(CW_TEAM_SORT_ORDER)
    if (defaults.sortOrderItems.includes(sortOrder)) {
      defaults.sortOrder = +sortOrder
    }
    const searchQuery = getSessionItem(CW_TEAM_SEARCH_QUERY)
    if (searchQuery) {
      defaults.searchQuery = searchQuery
      defaults.isGlobalSearch = searchQuery.includes(',')
    }

    return defaults
  },
  computed: {
    displayedEmployees() {
      if (this.isGlobalSearch) {
        return this.$store.getters['employees/getAllEmployees']
      }
      else {
        return this.$store.getters[`employees/get${titleize(this.displayMode)}`]
      }
    },
    sortDropdownItems() {
      return [{
        name: this.$t('employees.sort.property'),
        value: 'sortKey',
        items: this.sortItems.map(item => ({
          name: this.$t(`employees.employee.form.${item}`),
          value: item,
          selected: this.sortKey === item
        }))
      }, {
        name: this.$t('employees.sort.order'),
        value: 'sortOrder',
        items: [{
          name: this.$t('employees.sort.ascending'),
          value: 1,
          selected: this.sortOrder === 1
        },
        {
          name: this.$t('employees.sort.descending'),
          value: -1,
          selected: this.sortOrder === -1
        }]
      }, {
        name: this.$t('employees.group.title'),
        value: 'groupKey',
        items: this.groupItems.map(item => ({
          name: this.$t(`employees.group.${item}`),
          value: item,
          selected: this.groupKey === item
        }))
      }]
    },
    displayDropdownItems() {
      return [{
        name: this.$t('employees.display.title'),
        items: this.displayItems.map(item => ({
          name: this.$t(`employees.display.${item}`),
          value: item,
          selected: this.displayMode === item
        }))
      }]
    },
    introLabel() {
      return this.$$isManager ? this.$t('employees.summary.yourTeam') : this.$$companyName
    }
  },
  methods: {
    setSearchQuery(searchQuery) {
      this.isGlobalSearch = searchQuery.includes(',')
      this.searchQuery = searchQuery
      setSessionItemClean(CW_TEAM_SEARCH_QUERY, searchQuery)
    },
    onSearchQueryChange: debounce(function(event) {
      this.setSearchQuery(event.target.value)
    }, 300),
    onSearchKeyDown(event) {
      if (event.key === 'Enter') {
        this.$refs.employeesTable.openFirst()
      }
    },
    setSort(key, value) {
      switch (key) {
        case 'groupKey':
          setSetting(CW_TEAM_GROUP_KEY, value)
          this.groupKey = value
          break
        case 'sortKey':
          setSetting(CW_TEAM_SORT_KEY, value)
          this.sortKey = value
          break
        case 'sortOrder':
          setSetting(CW_TEAM_SORT_ORDER, value)
          this.sortOrder = value
          break
      }
    },
    setDisplayMode(_, value) {
      setSessionItemClean(CW_TEAM_DISPLAY_MODE, value)
      this.displayMode = value
    },
    setGlobalSearch() {
      this.isGlobalSearch = true
    }
  },
  mounted() {
    this.$store.dispatch('employees/getEmployeesIfNeeded')

    if (this.$route.params.query) {
      this.setSearchQuery(this.$route.params.query)
    }
    if (this.searchQuery) {
      this.$refs.searchQuery.value = this.searchQuery
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/alert.scss";
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";
@import "./src/styles/link.scss";

.container {
  @include container;
  overflow: hidden;
}

input[type="search"] {
  display: inline-block;
  width: 230px;
}

menu .secondary {
  vertical-align: 1px;
}

.arrow-1::after,
.arrow--1::after {
  content: " ";
  text-align: left;
  display: inline-block;
  vertical-align: sub;
  width: 16px;
  height: 16px;
  margin-left: 0.25em;
  background: transparent url(~@/assets/arrow-down.svg) no-repeat center center;
  opacity: 0.8;
}

.arrow-1::after {
  transform: scaleY(-1);
}

.dropdown::v-deep .dropdown-button:hover .arrow-1::after,
.dropdown::v-deep .dropdown-button:focus .arrow-1::after,
.dropdown::v-deep .dropdown-button:hover .arrow--1::after,
.dropdown::v-deep .dropdown-button:focus .arrow--1::after {
  background-image: url(~@/assets/arrow-down-active.svg);
}

.dropdown:not(:hover) .down {
  color: $light-text-color;
}

.employees-table::v-deep {
  .column-name {
    width: 220px;
  }

  .employee.clickable:hover .remuneration-part {
    background: darken($graph-lightblue-color, 5);
  }
}
</style>
