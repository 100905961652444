<template>
  <div>
    <h3 class="title">
      {{$t('grid.editor.component.createTitle')}}
    </h3>
    <p v-t="'grid.editor.component.createIntro'">
    </p>
    <form @submit.prevent="submit()">
      <div class="input-label" v-t="'common.name'"></div>
      <input
        type="text"
        v-model="componentModel.name"
        ref="name"
        :placeholder="$t('grid.editor.componentName')"
        :class="{'input-error' : $v.componentModel.name.$error}">
      <div>
        <div class="input-label" v-t="'common.description'"></div>
        <MarkdownTextEditor
          v-model="componentModel.description"
          :placeholder="$t('grid.editor.componentDescription')"
          :showAddButton="true"
          :showInlineEditButton="true"
          :showToolbar="true"
          :autoDismiss="true" />
      </div>
      <div class="input-label" v-t="'grid.editor.component.type'"></div>
      <radioInputs
        :items="availableOperations"
        localePrefix="grid.editor.component.operation"
        v-model="componentModel.salaryOperation" />
    </form>
    <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>
    <menu>
      <button
        class="secondary"
        @click="close"
        v-t="'common.cancel'"></button>
      <loading-button
        class="primary"
        :disabled="isLoading"
        @click="submit"
        :loading="isLoading">
        <span v-t="'common.add'"></span>
      </loading-button>
    </menu>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import RadioInputs from '@components/commons/RadioInputs'
import MarkdownTextEditor from '@components/commons/MarkdownTextEditor.vue'
import { remunerationOperationSymbol } from '@/utils/grid'
import { mapGetters } from 'vuex'

export default {
  components: {
    RadioInputs,
    MarkdownTextEditor
  },
  name: 'gridComponentCreateEditor',
  props: {},
  data() {
    return {
      componentModel: null,
      errorMessage: null,
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      grid: 'sandbox/grid'
    }),
    availableOperations() {
      return ['addition', 'multiplier']
    }
  },
  methods: {
    remunerationOperationSymbol,
    initModel() {
      this.componentModel = {
        name: null,
        salaryOperation: 'addition'
      }
    },
    close() {
      this.$emit('close')
    },
    async submit() {
      this.$v.$touch()

      if (this.$v.$error || this.isLoading) {
        return
      }
      this.isLoading = true

      try {
        const gridId = this.grid.id
        let component = this.componentModel
        component = await this.$store.dispatch('sandbox/createComponent', { gridId, component })
        this.$emit('close', component)
      }
      catch (error) {
        this.errorMessage = error
      }
      finally {
        this.isLoading = false
      }
    }
  },
  created() {
    this.initModel()
  },
  mounted() {
    if (this.$refs.name) {
      this.$refs.name.focus()
    }
  },
  validations() {
    const validations = {
      componentModel: {
        name: { required }
      }
    }
    return validations
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";

input[type="text"] {
  width: 37%;
  margin-bottom: 1rem;
}

.radio-inputs::v-deep .radio-input {
  &::after {
    position: absolute;
    right: 12px;
    content: '+';
    font-size: 3em;
    @include font-bold;
    @include font-tabular-numbers;
    color: darken($graph-lightblue-color, 17);
    opacity: 0.8;
  }

  &.multiplier::after {
    content: '×'
  }
}
</style>
