<template>
  <div class="goal goals-editor" :class="{'cool-bump': isPlaceholder}" v-if="goalModel">
    <div class="goal-header" v-if="header">
      <div class="goal-title">
        <InlineEditor
          class="inline blue"
          v-model="goalModel.name"
          :placeholder="defaultPlaceholder"
          :autoDismiss="true"
          :disabled="disabled"
          @submit="onGoalsChange" />
      </div>
      <div class="goal-settings">
        <div class="indicator-dropdown">
          <Dropdown
            class="blue modern description"
            :items="indicatorDropdownItems"
            :disabled="disabled"
            @change="setIndicator">
            <span>{{indicatorTitle}}</span>
            <span class="down">▾</span>
          </Dropdown>
        </div>
        <span class="versus">vs</span>
        <div class="indicator-dropdown">
          <Dropdown
            class="blue modern description"
            :items="targetDropdownItems"
            :disabled="disabled"
            @change="setTarget">
            <span>{{targetTitle}}</span>
            <span class="down">▾</span>
          </Dropdown>
        </div>
        <div v-if="!disabled" class="remove-button" @click="removeGoal()">×</div>
      </div>
    </div>
    <div class="wrapper">
      <div class="svg-container" :class="{placeholder: isPlaceholder}">
        <div class="svg-placeholder preserve-lines" v-t="'variable.goal.addGoalPlaceholder'"></div>
        <svg :height="viewport.height" ref="svgNode"></svg>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import cloneDeepWith from 'lodash.clonedeepwith'
import InlineEditor from '@components/commons/InlineEditor'
import Dropdown from '@components/commons/Dropdown'
import debounce from 'lodash.debounce'
import * as d3 from 'd3'
import { buildGroupedAvailableReferences, getReferenceIndicatorValue, evaluateIndicators, splitReferenceId } from '@/utils/variable'
import flatten from 'lodash.flatten'
import { generateAbscissaLabels, generateOrdinateLabels, formatLabel } from '@/utils/graph'

function initGraph(svg, viewport) {
  const node = {}

  node.svg = d3.select(svg)
  node.svg.selectAll('*').remove()
  node.viewport = node.svg.append('g')
    .attr('class', 'viewport')
  node.background = node.viewport.append('rect')
    .attr('class', 'background')
  node.abscissa = node.viewport.append('g')
    .attr('class', 'axis abscissa')
  node.ordinate = node.viewport.append('g')
    .attr('class', 'axis ordinate')
  node.paths = node.viewport.append('g')
    .attr('class', 'paths')
  node.lines = node.viewport.append('g')
    .attr('class', 'lines')
  node.target = node.viewport.append('g')
    .attr('class', 'targets')
  node.indicator = node.viewport.append('g')
    .attr('class', 'indicators')
  node.x = d3.scaleLinear()
  node.y = d3.scaleLinear()

  return {
    node: node,
    viewport: viewport,
    data: []
  }
}

function generatePath(points, x, y, options) {
  options = options || {}
  const path = d3.path()
  const firstPoint = points[0]
  const zeroY = y.range()[1]
  path.moveTo(x(firstPoint[0]), options.zero ? zeroY : y(firstPoint[1]))
  for (const point of points) {
    if (point !== firstPoint) {
      path.lineTo(x(point[0]), options.zero ? zeroY : y(point[1]))
    }
  }
  if (options.close) {
    const lastPoint = points[points.length - 1]
    path.lineTo(x(lastPoint[0]), zeroY)
    path.lineTo(x(firstPoint[0]), zeroY)
    path.closePath()
  }
  return path
}

function generateAbscissaPoints(component, max = Number.MAX_VALUE) {
  const indicatorValue = getIndicatorValue(component)
  const targetValue = getTargetValue(component)
  const target = Math.min(Math.max(indicatorValue, targetValue) * 1.3, max)
  const length = 200
  return Array
    .from({ length }, (_, i) => i).map(i => Math.round(target * (i / (length - 1))))
    .map(x => generatePoint(component, x))
}

function generatePoint(component, value) {
  const bonusValue = getBonusValue(component, value)
  return [value, bonusValue]
}

function getIndicatorValue(component) {
  const { enrichedConfig, evaluationOptions, goalModel, indicators } = component
  return getReferenceIndicatorValue(enrichedConfig, indicators, goalModel.indicator, evaluationOptions)
}

function getTargetValue(component) {
  const { enrichedConfig, evaluationOptions, goalModel, indicators } = component
  return getReferenceIndicatorValue(enrichedConfig, indicators, goalModel.target, evaluationOptions)
}

function setIndicatorValue(indicators, goalModel, indicatorValue) {
  indicators = cloneDeepWith(indicators)
  const indicatorId = splitReferenceId(goalModel.indicator.reference)[2]
  const indicator = indicators.find(i => i.id === indicatorId)
  if (indicator) {
    indicator.value = indicatorValue
    indicator.inputValue = indicator.value.toString()
    indicator.computedValue = indicatorValue // For reference indicators
  }
  return indicators
}

function getBonusValue(component, indicatorValue) {
  const { enrichedConfig, evaluationOptions, indicators, goalModel } = component
  return evaluateIndicators(
    enrichedConfig,
    [],
    setIndicatorValue(indicators, goalModel, indicatorValue),
    evaluationOptions)
}

const placeholderData = [{
  id: 1,
  points: [[0, 0], [5000, 5000]]
}]
function generateGoalGraphModel(graph, component, isPlaceholder) {
  // Ensure goalModel is valid (reference still accessible)
  if (isPlaceholder) {
    graph.data = placeholderData
    graph.rawData = graph.data
    graph.isPlaceholder = true
  }
  else {
    graph.data = [{
      id: 1,
      class: 'trend',
      points: generateAbscissaPoints(component)
    }, {
      id: 2,
      class: 'indicator',
      points: generateAbscissaPoints(component, getIndicatorValue(component))
    }]
    graph.target = generatePoint(component, getTargetValue(component))
    graph.indicator = generatePoint(component, getIndicatorValue(component))

    const indicatorValue = graph.indicator[0]
    const targetValue = graph.target[0]
    const targetPercent = targetValue ? indicatorValue / targetValue : 0
    const nameLabel = component.goalModel.name || component.defaultPlaceholder
    graph.indicatorLabel = `${nameLabel} ${formatLabel('.0%', targetPercent)}${targetPercent >= 1 ? ' 🎉' : ''}`
    graph.rawData = graph.data
    graph.isPlaceholder = false
  }

  return graph
}

function renderGraph(graph) {
  const viewport = graph.viewport
  const node = graph.node

  // Viewport
  viewport.width = graph.node.svg.node().getBoundingClientRect().width
  viewport.innerWidth = viewport.width - viewport.padding.left - viewport.padding.right
  viewport.innerHeight = viewport.height - viewport.padding.top - viewport.padding.bottom

  // Graph Axes
  graph.axes = {
    abscissa: {
      type: 'continuous',
      labels: 'belowAxis',
      axis: node.x
    },
    ordinate: {
      type: 'continuous',
      labels: 'beforeAxis',
      axis: node.y,
      format: '$.0f'
    }
  }
  const points = graph.data[0].points
  node.x.domain([0, d3.max(points, d => d[0])])
    .rangeRound([25, viewport.innerWidth])
  node.y.domain([d3.max(points, d => d[1]), d3.min(points, d => d[1])])
    .rangeRound([0, viewport.innerHeight])

  // Viewport background
  node.viewport.attr('transform',
    `translate(${viewport.padding.left},${viewport.padding.top})`)
  node.background.attr('width', viewport.innerWidth)
    .attr('height', viewport.innerHeight)

  // Axes Labels
  const abscissaLabelsData = generateAbscissaLabels(graph)
  const abscissaLabels = node.abscissa.selectAll('.label')
    .data(abscissaLabelsData, d => d.label)
  abscissaLabels
    .enter().append('text')
    .attr('class', 'label wrap')
    .attr('x', d => d.x)
    .attr('y', d => d.y)
    .merge(abscissaLabels)
    .transition()
    .attr('x', d => d.x)
    .attr('y', d => d.y)
    .text(d => d.label)
  abscissaLabels.exit()
    .remove()

  const abscissaTicks = node.abscissa.selectAll('.tick')
    .data(abscissaLabelsData, d => d.label)
  abscissaTicks
    .enter().append('line')
    .attr('class', 'tick')
    .attr('x1', d => d.x)
    .attr('y1', _d => node.y.range()[1])
    .attr('x2', d => d.x)
    .attr('y2', _d => node.y.range()[1])
    .merge(abscissaTicks)
    .transition()
    .attr('x1', d => d.x)
    .attr('y1', _d => node.y.range()[0])
    .attr('x2', d => d.x)
    .attr('y2', _d => node.y.range()[1])
  abscissaTicks.exit()
    .remove()

  const ordinateLabelsData = generateOrdinateLabels(graph)
  const ordinateLabels = node.ordinate.selectAll('.label')
    .data(ordinateLabelsData, d => d.id)
  ordinateLabels
    .enter().append('text')
    .attr('class', 'label')
    .attr('x', d => d.x)
    .attr('y', d => d.y)
    .merge(ordinateLabels)
    .text(d => d.label)
    .attr('x', d => d.x)
    .attr('y', d => d.y)
  ordinateLabels.exit()
    .remove()

  const ordinateTicks = node.ordinate.selectAll('.tick')
    .data(ordinateLabelsData, d => d.y)
  ordinateTicks
    .enter().append('line')
    .attr('class', 'tick')
    .merge(ordinateTicks)
    .attr('x1', _d => node.x.range()[0])
    .attr('y1', d => d.y)
    .attr('x2', _d => node.x.range()[1])
    .attr('y2', d => d.y)
  ordinateTicks.exit()
    .remove()

  // Trend & path lines
  const linesData = graph.isPlaceholder ? [] : graph.data
  const lines = node.lines.selectAll('.line')
    .data(linesData, d => d.id)
  lines
    .enter().append('path')
    .attr('class', d => 'line ' + d.class)
    .attr('d', d => generatePath(d.points, node.x, node.y, { zero: true }))
    .merge(lines)
    .transition()
    .duration(500)
    .attr('d', d => generatePath(d.points, node.x, node.y))
  lines.exit()
    .remove()

  const paths = node.paths.selectAll('.path')
    .data(linesData, d => d.id)
  paths
    .enter().append('path')
    .attr('class', d => 'path ' + d.class)
    .attr('d', d => generatePath(d.points, node.x, node.y, { zero: true, close: true }))
    .merge(paths)
    .transition()
    .duration(500)
    .attr('d', d => generatePath(d.points, node.x, node.y, { close: true }))
  paths.exit()
    .remove()

  // Target
  const targetsData = !graph.target
    ? []
    : [{
        id: 1,
        points: [[graph.target[0], node.y.domain()[1]], [graph.target[0], graph.target[1]]]
      }, {
        id: 2,
        points: [[node.x.domain()[0], graph.target[1]], [graph.target[0], graph.target[1]]]
      }]
  const targets = node.target.selectAll('.target')
    .data(targetsData, d => d.id)
  targets
    .enter().append('path')
    .attr('class', 'target')
    .attr('d', d => generatePath(d.points, node.x, node.y, { zero: true }))
    .merge(targets)
    .transition()
    .duration(500)
    .attr('d', d => generatePath(d.points, node.x, node.y))
  targets.exit()
    .remove()

  const targetsCirclesData = !graph.target ? [] : [graph.target, graph.target]
  const targetsCircles = node.target.selectAll('.circle')
    .data(targetsCirclesData)
  targetsCircles
    .enter().append('circle')
    .attr('class', 'circle')
    .attr('r', 0)
    .attr('cx', d => node.x(d[0]))
    .attr('cy', _d => node.y.range()[1])
    .merge(targetsCircles)
    .transition()
    .duration(500)
    .attr('r', (_, i) => i ? 6 : 16)
    .attr('opacity', (_, i) => i ? 1 : 0.15)
    .attr('cx', d => node.x(d[0]))
    .attr('cy', d => node.y(d[1]))
  targetsCircles.exit()
    .remove()

  const targetsLabelData = !graph.targetLabel ? [] : [graph.target]
  const targetsLabel = node.target.selectAll('.label')
    .data(targetsLabelData, d => d.label)
  targetsLabel
    .enter().append('text')
    .attr('class', 'label')
    .attr('x', _d => node.x.range()[0])
    .attr('y', _d => node.y.range()[1])
    .merge(targetsLabel)
    .transition()
    .attr('x', _d => node.x.range()[0])
    .attr('y', d => node.y(d[1]))
    .text(_d => graph.targetLabel)
  targetsLabel.exit()
    .remove()

  // Indicator
  const indicatorsData = !graph.indicator
    ? []
    : [{
        id: 1,
        points: [[graph.indicator[0], node.y.domain()[1]], [graph.indicator[0], graph.indicator[1]]]
      }, {
        id: 2,
        points: [[node.x.domain()[0], graph.indicator[1]], [graph.indicator[0], graph.indicator[1]]]
      }]
  const indicators = node.indicator.selectAll('.indicator')
    .data(indicatorsData, d => d.id)
  indicators
    .enter().append('path')
    .attr('class', 'indicator')
    .attr('d', d => generatePath(d.points, node.x, node.y, { zero: true }))
    .merge(indicators)
    .transition()
    .duration(500)
    .attr('d', d => generatePath(d.points, node.x, node.y))
  indicators.exit()
    .remove()

  const indicatorsCircles = node.indicator.selectAll('.circle')
    .data(!graph.indicator ? [] : [graph.indicator, graph.indicator])
  indicatorsCircles
    .enter().append('circle')
    .attr('class', 'circle')
    .attr('r', 0)
    .attr('cx', d => node.x(d[0]))
    .attr('cy', d => node.y.range()[1])
    .merge(indicatorsCircles)
    .transition()
    .duration(500)
    .attr('r', (_, i) => i ? 6 : 13)
    .attr('opacity', (_, i) => i ? 1 : 0.15)
    .attr('cx', d => node.x(d[0]))
    .attr('cy', d => node.y(d[1]))
  indicatorsCircles.exit()
    .remove()

  const indicatorsLabelData = !graph.indicator ? [] : [graph.indicator]
  const indicatorsLabel = node.indicator.selectAll('.label')
    .data(indicatorsLabelData, (_d, i) => i)
  indicatorsLabel
    .enter().append('text')
    .attr('class', 'label')
    .attr('x', _d => node.x.range()[0])
    .attr('y', _d => node.y.range()[1])
    .merge(indicatorsLabel)
    .transition()
    .duration(500)
    .attr('x', _d => node.x.range()[0])
    .attr('y', d => node.y(d[1]))
    .text(_d => graph.indicatorLabel)
  indicatorsLabel.exit()
    .remove()

  const indicatorsOrdinateLabelData = !graph.indicator ? [] : [graph.indicator]
  const indicatorsOrdinateLabel = node.indicator.selectAll('.label2')
    .data(indicatorsOrdinateLabelData, (_d, i) => i)
  indicatorsOrdinateLabel
    .enter().append('text')
    .attr('class', 'label2')
    .attr('x', _d => node.x.range()[0])
    .attr('y', _d => node.y.range()[1])
    .merge(indicatorsOrdinateLabel)
    .transition()
    .duration(500)
    .attr('x', _d => node.x.range()[0])
    .attr('y', d => node.y(d[1]))
    .text(d => formatLabel('$.0f', d[1]))
  indicatorsOrdinateLabel.exit()
    .remove()

  const indicatorsAbcsissaLabelData = !graph.indicator ? [] : [graph.indicator]
  const indicatorsAbcsissaLabel = node.indicator.selectAll('.label3')
    .data(indicatorsAbcsissaLabelData, (_d, i) => i)
  indicatorsAbcsissaLabel
    .enter().append('text')
    .attr('class', 'label3')
    .attr('x', _d => node.x.range()[0])
    .attr('y', _d => node.y.range()[1])
    .merge(indicatorsAbcsissaLabel)
    .transition()
    .duration(500)
    .attr('x', d => node.x(d[0]))
    .attr('y', _d => node.y.range()[1])
    .text(d => formatLabel('$.0f', d[0]))
  indicatorsAbcsissaLabel.exit()
    .remove()
}

export default {
  components: {
    Dropdown,
    InlineEditor
  },
  props: {
    variablePlan: {
      type: Object
    },
    enrichedConfig: {
      type: Object
    },
    // Either simultation.indicators or flatten([indicators, sharedIndicators])
    indicators: {
      type: Array
    },
    // Goals must be provided and contain one goal
    value: {
      type: Array
    },
    // Mode (evaluation option. `simulation`, `individual` or `collective`)
    mode: {
      type: String,
      default: 'simulation'
    },
    // Employee (evaluation option)
    employee: {
      type: Object
    },
    // Disable state
    disabled: {
      type: Boolean
    },
    // Show name & indicators
    header: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      goalModel: null,
      // Options
      viewport: {
        width: 0, // Computed
        height: 250,
        innerWidth: 0, // Computed
        innerHeight: 0, // Computed
        padding: {
          left: 60,
          right: 25,
          top: 32,
          bottom: 30
        }
      },
      // State
      graph: null,
      resizeFn: null
    }
  },
  computed: {
    ...mapGetters({
      getEvaluationOptions: 'variablePlans/getEvaluationOptions'
    }),
    isPlaceholder() {
      return this.goalModel && !(this.goalModel.indicator.reference && this.goalModel.target.reference && (this.goalModel.indicator.reference !== this.goalModel.target.reference))
    },
    evaluationOptions() {
      return this.getEvaluationOptions(this.variablePlan, this.mode, this.employee)
    },
    defaultPlaceholder() {
      return 'Objectif de vente'
    },
    groupedAvailableReferences() {
      const mode = 'goal'
      const customFields = this.$store.getters['company/getCustomFields']
      return buildGroupedAvailableReferences(this.enrichedConfig.indicators, this.variablePlan.dataFeeds, this.goalModel, customFields, { mode })
    },
    availableReferences() {
      return flatten(this.groupedAvailableReferences)
    },
    indicatorTitle() {
      const selectedReference = this.availableReferences.find(i => i.id === this.goalModel.indicator.reference)
      return selectedReference ? selectedReference.title : 'Indicateur à suivre…'
    },
    indicatorDropdownItems() {
      return this.groupedAvailableReferences.map((group, i) => ({
        name: this.$t('variable.editor.indicator.references.indicators' + i),
        value: i,
        items: group.map(reference => ({
          name: reference.title,
          label: reference.label,
          value: reference.id,
          selected: this.goalModel.indicator.reference === reference.id
        }))
      })).filter(({ items }) => items.length)
    },
    targetTitle() {
      const selectedReference = this.availableReferences.find(i => i.id === this.goalModel.target.reference)
      return selectedReference ? selectedReference.title : 'Objectif…'
    },
    targetDropdownItems() {
      return this.groupedAvailableReferences.map((group, i) => ({
        name: this.$t('variable.editor.indicator.references.indicators' + i),
        value: i,
        items: group.map(reference => ({
          name: reference.title,
          label: reference.label,
          value: reference.id,
          selected: this.goalModel.target.reference === reference.id
        }))
      })).filter(({ items }) => items.length)
    }
  },
  methods: {
    init() {
      // Only one goal is supported for now
      if (this.value.length) {
        this.goalModel = cloneDeepWith(this.value)[0]
        this.$nextTick(() => {
          this.graph = initGraph(this.$refs.svgNode, this.viewport)
          this.render()
        })
      }
      else {
        this.goalModel = null
      }
    },
    setIndicator(_, value) {
      this.goalModel.indicator.reference = value
      this.onGoalsChange()
    },
    setTarget(_, value) {
      this.goalModel.target.reference = value
      this.onGoalsChange()
    },
    removeGoal() {
      this.$emit('input', [])
    },
    onGoalsChange() {
      if (!this.disabled) {
        this.$emit('input', [this.goalModel])
        this.render()
      }
    },
    render() {
      if (!this.goalModel || !this.graph) {
        return
      }
      generateGoalGraphModel(this.graph, this, this.isPlaceholder)
      renderGraph(this.graph)
    },
    renderDebounced: debounce(function() {
      this.render()
    }, 50)
  },
  watch: {
    value: 'init',
    indicators: 'render',
    'enrichedConfig.formulas': 'render',
    'enrichedConfig.indicators': 'render'
  },
  mounted() {
    this.init()
    this.resizeFn = debounce(this.render, 100)
    window.addEventListener('resize', this.resizeFn)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeFn)
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/graph.scss";

.goal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.7rem;

  .goal-title {
    @include font-small-size;
    @include font-semibold;
    width: 50%;
  }

  .goal-settings {
    $goal-settings-height: 27px;
    display: flex;

    // Dropdown skin: right aligned with description
    .indicator-dropdown::v-deep {
      position: relative;

      .dropdown:not(:first-child) {
        margin-left: 5px;
      }

      .dropdown-button {
        padding: 5px;
        max-width: 170px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:not(:hover):not(:focus),
        &[disabled] {
          color: $light-text-color;
        }
      }

      .dropdown-content {
        width: 304px;
        right: 0;
        margin-top: 5px;
      }
    }

    .versus {
      @include font-small-size;
      @include font-semibold;
      line-height: $goal-settings-height;
      width: 2em;
      text-align: center;
    }

    .remove-button {
      display: inline-block;
      @include font-big-size;
      @include font-bold;
      cursor: pointer;
      color: lighten($text-color, 25);
      min-width: 0;
      width: 40px;
      margin-right: -10px;
      line-height: $goal-settings-height;
      text-align: center;
      user-select: none;

      &:hover {
        color: $red-color;
      }
    }
  }
}

.svg-container {
  &.placeholder {
    svg {
      opacity: 0.3;
    }

    .svg-placeholder {
      display: flex;
    }
  }

  .svg-placeholder {
    @include line-regular-height;
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: $graph-text-color;
    display: none;
    text-align: center;
  }

  svg {
    transition: opacity 600ms;
  }

  svg::v-deep {
    .label {
      fill: $light-text-color;
    }

    .ordinate .label {
      text-anchor: end;
      alignment-baseline: middle;
    }

    .abscissa .tick {
      shape-rendering: crispedges;
      stroke: $graph-inner-border-color;
    }

    .ordinate .tick {
      shape-rendering: crispedges;
      stroke: $graph-inner-border-color;
      stroke-dasharray: 3 3;
      opacity: 0.8;
    }

    .line {
      fill: transparent;
      opacity: 0.8;
      stroke: $graph-darkblue-color;

      &.indicator {
        opacity: 1;
        stroke-width: 4px;
        stroke: lighten($red-color, 5);
      }
    }

    .paths path {
      fill: $graph-blue-color;
      opacity: 0.4;

      &.indicator {
        fill: $red-color;
      }
    }

    .targets {
      .target {
        stroke: $graph-darkblue-color;
        stroke-width: 1px;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 4 4;
        opacity: 1;
      }

      .label {
        @include font-bigger-size;
        text-anchor: start;
        transform: translate(0, -5px);
      }
    }

    .indicators {
      .indicator {
        stroke: $red-color;
        stroke-width: 1px;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 4 4;
        opacity: 1;
      }

      .circle {
        fill: $red-color;
      }

      .label {
        @include font-bigger-size;
        @include font-medium;
        fill: darken($red-color, 5);
        text-anchor: start;
        transform: translate(0, -12px);
      }

      .label2,
      .label3 {
        text-anchor: end;
        @include font-large-size;
        @include font-semibold ;
        fill: $red-color;
        transform: translate(-10px, 4px);
        font-smoothing: antialiased;
        // Text stroke of 4px:
        text-shadow: white 4px 0px 0px, white 3.87565px 0.989616px 0px,
          white 3.51033px 1.9177px 0px, white 2.92676px 2.72656px 0px,
          white 2.16121px 3.36588px 0px, white 1.26129px 3.79594px 0px,
          white 0.282949px 3.98998px 0px, white -0.712984px 3.93594px 0px,
          white -1.66459px 3.63719px 0px, white -2.51269px 3.11229px 0px,
          white -3.20457px 2.39389px 0px, white -3.69721px 1.52664px 0px,
          white -3.95997px 0.56448px 0px, white -3.97652px -0.432781px 0px,
          white -3.74583px -1.40313px 0px, white -3.28224px -2.28625px 0px,
          white -2.61457px -3.02721px 0px, white -1.78435px -3.57996px 0px,
          white -0.843183px -3.91012px 0px, white 0.150409px -3.99717px 0px,
          white 1.13465px -3.8357px 0px, white 2.04834px -3.43574px 0px,
          white 2.83468px -2.82216px 0px, white 3.44477px -2.03312px 0px,
          white 3.84068px -1.11766px 0px, white 3.9978px -0.132717px 0px;
      }

      .label3 {
        text-anchor: middle;
        transform: translate(0, 21px);
      }
    }
  }
}
</style>
