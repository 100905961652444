<template>
  <div>
    <div class="teams-menu-wrapper print-hidden">
      <div class="teams-menu">
        <div class="button-group">
          <PermissionButton permission="teamsOrgChart" />
        </div>
        <div class="button-group">
          <button
            class="secondary"
            @click="zoom"
            v-t="'orgChart.actions.zoom'" />
          <button
            class="secondary"
            @click="openOptionsModal"
            v-t="'orgChart.actions.options'" />
          <button
            v-if="$$isAdmin"
            class="secondary"
            @click="$router.push({name: 'updateTeams'})"
            v-t="'common.update'" />
          <button
            class="secondary"
            v-t="'common.export'"
            @click="download" />
        </div>
      </div>
    </div>
    <OrgChartOnboarding :visible="!isOnboardingDone" @dismiss="dismissOnboarding" />
    <OrgChartEditor ref="orgChart" />
    <OrgChartOptions :visible="isOptionsModalVisible" @close="closeOptionsModal" />
  </div>
</template>

<script>

import PermissionButton from '@components/settings/PermissionButton.vue'
import OrgChartEditor from '@components/teams/orgChart/OrgChartEditor.vue'
import OrgChartOptions from '@components/teams/orgChart/OrgChartOptions.vue'
import OrgChartOnboarding from '@/components/teams/orgChart/OrgChartOnboarding.vue'
import { downloadPDF } from '@/utils/pdf'
import { getSetting, setSetting } from '@/utils/settings'

const CW_ORG_CHART_ONBOARDING_DONE = 'CW_ORG_CHART_ONBOARDING_DONE'

export default {
  components: {
    OrgChartEditor,
    OrgChartOptions,
    OrgChartOnboarding,
    PermissionButton
  },
  data() {
    return {
      isOnboardingDone: false,
      isOptionsModalVisible: false
    }
  },
  methods: {
    initModel() {
      this.isOnboardingDone = !this.$$isAdmin || !!getSetting(CW_ORG_CHART_ONBOARDING_DONE)
    },
    dismissOnboarding() {
      this.isOnboardingDone = true
      setSetting(CW_ORG_CHART_ONBOARDING_DONE, true)
      setTimeout(() => {
        if (this.$refs.orgChart) {
          this.$refs.orgChart.render()
        }
      }, 600)
    },
    openOptionsModal() {
      this.isOptionsModalVisible = true
    },
    closeOptionsModal() {
      this.isOptionsModalVisible = false
    },
    zoom() {
      this.$refs.orgChart.zoom()
    },
    download() {
      downloadPDF()
    }
  },
  created() {
    this.initModel()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/teams.scss";
</style>
