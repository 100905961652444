<template>
  <resizable-panel :observer="[isOpened, authDomainLabel, errorMessage]">
    <div class="input-label" v-t="'settings.settingsCompanyAccessRights.authDomain.title'"></div>
    <p class="no-margin-top" v-html="authDomainLabel"></p>
    <button
      v-if="!isOpened"
      class="small-button"
      v-t="'common.update'"
      @click="isOpened = true" />
    <form @submit.prevent="update" v-if="isOpened">
      <Checkbox
        v-model="hasAuthDomain">
        {{$t('settings.settingsCompanyAccessRights.authDomain.checkboxLabel')}}
      </Checkbox>
      <input
        type="text"
        class="auth-domain"
        :placeholder="$t('settings.settingsCompanyAccessRights.authDomain.inputPlaceholder')"
        v-model="authDomain">
      <div>
        <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
        <p v-if="isUpdateSuccess" class="alert fade-in" v-t="'settings.updateSuccess'"></p>
        <loading-button
          v-else
          class="primary settings-button"
          :loading="isLoading">
          <span v-t="'settings.update'"></span>
        </loading-button>
      </div>
    </form>
  </resizable-panel>
</template>

<script>
import Checkbox from '@components/commons/Checkbox.vue'
import ResizablePanel from '@components/commons/ResizablePanel.vue'

export default {
  components: {
    Checkbox,
    ResizablePanel
  },
  data() {
    return {
      hasAuthDomain: false,
      authDomain: null,
      errorMessage: null,
      isLoading: false,
      isOpened: false,
      isUpdateSuccess: false
    }
  },
  computed: {
    authDomainLabel() {
      const authDomain = this.storedAuthDomain
      return this.$t(`settings.settingsCompanyAccessRights.authDomain.${authDomain ? 'enabled' : 'disabled'}Description`, { authDomain })
    },
    storedAuthDomain() {
      return this.$store.getters['account/getCompany'].authDomain
    }
  },
  methods: {
    initModel() {
      this.authDomain = this.storedAuthDomain
      this.hasAuthDomain = !!this.authDomain
    },
    update() {
      this.isUpdateSuccess = false
      this.errorMessage = null
      this.isLoading = true

      if (!this.authDomain) {
        this.hasAuthDomain = false
      }
      if (!this.hasAuthDomain) {
        this.authDomain = null
      }
      const authDomain = this.authDomain
      this.$store.dispatch('company/update', { authDomain })
        .then(() => {
          this.isLoading = false
          this.isUpdateSuccess = true
          setTimeout(() => {
            this.isOpened = false
            this.isUpdateSuccess = false
          }, 1400)
        })
        .catch(error => {
          this.isLoading = false
          this.errorMessage = error
        })
    }
  },
  created() {
    this.initModel()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/settings.scss";

.auth-domain {
  max-width: 300px;
  margin-top: 10px;
}
</style>
