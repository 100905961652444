<template>
  <div class="wage-sharing-container">
    <h1>{{companyName}}</h1>
    <div
      class="wage-sharing">
      <h3>
        <InlineEditor
          v-model="sharingConfigModel.title"
          :placeholder="$t('wageSharing.templates.generic.title')"
          :disabled="!isEditable"
          @submit="submit" />
      </h3>
      <MarkdownTextEditor
        v-model="sharingConfigModel.description"
        :disabled="!isEditable"
        :placeholder="$t('wageSharing.description')"
        :showAddButton="true"
        :showToolbar="true"
        :autoDismiss="true"
        @submit="submit" />
      <hr>
      <div>
        <div>
          <div class="input-label" v-t="'candidates.jobOfferName'"></div>
          <div class="job-title">
            <inline-editor
              v-model="sharingConfigModel.jobTitle"
              :placeholder="$t('candidates.jobOfferName')"
              :disabled="!isEditable"
              @submit="submit" />
          </div>
        </div>
      </div>
      <div class="three-columns">
        <div class="badge-container">
          <div class="input-label" v-t="'common.grossAnnualSalary'"></div>
          <span class="badge border-badge large-badge">
            {{ wageDetails.summary.salary.value | formattedCurrency }}
          </span>
        </div>
        <template v-if="wageDetails.summary.salary.variableValue">
          <div class="badge-container">
            <div class="input-label" v-t="'wageSharing.fixedSalary'"></div>
            <span class="badge border-badge large-badge">
              {{ wageDetails.summary.salary.fixedValue | formattedCurrency }}
            </span>
          </div>
          <div class="badge-container">
            <div class="input-label" v-t="'wageSharing.variableSalary'"></div>
            <span class="badge border-badge large-badge">
              {{ wageDetails.summary.salary.variableValue | formattedCurrency }}
            </span>
          </div>
        </template>
      </div>
      <div class="badge-container">
        <div class="input-label" v-t="'wageSharing.estimatedNetMonthlySalary'"></div>
        <div class="net-brut">
          <span class="badge border-badge large-badge">
            {{ netSalary | formattedCurrency }}
          </span>
          <span
            :class="{editable: isEditable}"
            @click="editSocialContribution"
            v-t="{
                path: 'wageSharing.socialContributionLabel',
                args: {socialContribution: sharingConfigModel.socialContribution}
              }">
          </span>
        </div>
      </div>
      <template v-if="wage.hasLevels">
        <div class="input-label" v-t="'employees.employee.form.placementOnGrid'"></div>
        <div class="wage-details" @click="isModalVisible = true">
          <inline-wage
            :wageDetails="wageDetails"
            :showSalary="false" />
          <div class="learn-more" v-t="'wageSharing.learnMore'"></div>
        </div>
      </template>
    </div>
    <footer>
      <a class="unstyled-link" :href="$t('urls.landing')" target="_blank">
        {{$t('wageSharing.footer')}}
        <img class="logo" src="~@/assets/logo-menu.svg" alt="Clearwage">
      </a>
    </footer>
    <modal :visible="isModalVisible" @close="isModalVisible = false">
      <wage-viewer :wage="wage" :showVariable="true" />
    </modal>
  </div>
</template>

<script>

import WageViewer from '@components/wage/WageViewer.vue'
import InlineEditor from '@components/commons/InlineEditor.vue'
import Modal from '@components/commons/Modal.vue'
import MarkdownTextEditor from '@components/commons/MarkdownTextEditor.vue'
import InlineWage from '@components/wage/InlineWage.vue'
import { getEmbeddedWageDetails, getWageDetails } from '@/utils/grid'
import { mapGetters } from 'vuex'

export default {
  props: {
    wage: Object,
    isEditable: Boolean
  },
  data() {
    return {
      isModalVisible: false,
      sharingConfigModel: null
    }
  },
  methods: {
    submit() {
      if (this.isEditable) {
        const wage = this.wage
        const sharingConfig = this.sharingConfigModel
        this.$store.dispatch('wageSharing/createOrUpdate', { wage, sharingConfig })
          .catch(error => error && alert(error))
        this.$parent.$emit('resize')
      }
    },
    editSocialContribution() {
      if (this.isEditable) {
        const socialContribution = window.prompt(this.$t('wageSharing.socialContributionPrompt'), this.sharingConfigModel.socialContribution)
        if (socialContribution && !isNaN(Number.parseFloat(socialContribution))) {
          this.sharingConfigModel.socialContribution = Math.min(Math.max(0, Number.parseFloat(socialContribution)), 100)
          this.submit()
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      grid: 'currentGrid/getCurrentGrid'
    }),
    companyName() {
      return this.wage.company ? this.wage.company.name : this.$$companyName
    },
    wageDetails() {
      return this.wage.levels ? getEmbeddedWageDetails(this.wage, { includeVariable: true }) : getWageDetails(this.grid, this.wage, { includeVariable: true })
    },
    netSalary() {
      const brutSalary = this.wageDetails.summary.salary.value
      const socialContribution = this.sharingConfigModel.socialContribution || 25
      return brutSalary * (1 - socialContribution / 100) / 12
    }
  },
  created() {
    this.sharingConfigModel = { ...this.wage.sharingConfig }
  },
  components: {
    MarkdownTextEditor,
    InlineEditor,
    InlineWage,
    Modal,
    WageViewer
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/badge.scss";
@import "./src/styles/button.scss";
@import "./src/styles/link.scss";
@import "./src/styles/form.scss";

$content-max-width: 575px;
$padding: 20px;

.wage-sharing-container {
  width: $content-max-width;
  margin: 0 auto;
}

.wage-sharing {
  @include container;
}

h1 {
  text-align: center;
  padding: 1.7em 0 0.85em 0;
  margin: 0;
}

h3 {
  @include line-regular-height;
  margin-bottom: 0.5em;
}

hr {
  display: block;
  border: 0;
  border-top: $border;
  height: 1px;
  margin: $padding 0;
}

.three-columns {
  display: grid;
  grid-template-columns: auto auto auto 1fr;
  grid-gap: 2em;
  margin-top: $padding;
}

.badge-container {
  margin-bottom: $padding;
}

.net-brut {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1em;
  align-items: center;

  .editable {
    cursor: pointer;

    &::after {
      content: " ";
      display: inline-block;
      width: 0.8em;
      height: 0.8em;
      background: transparent url(~@/assets/edit.svg) no-repeat right center;
      background-size: contain;
      vertical-align: baseline;
      margin-left: 0.5em;
      opacity: 0.8;
    }
  }

  &:last-child {
    @include font-small-size;
    color: $light-text-color;
  }
}

.input-label {
  margin-bottom: 0.75em;
}

.wage-details {
  @extend .badge-block;
  @extend .clickable;
  display: block;
  padding: 0 0 0 0.75em;

  .components {
    padding: 0;
  }

  &::v-deep .component:not(:last-of-type) {
    margin-right: 1em;
  }

  .learn-more {
    @include font-small-size;
    padding: 0.25em 0 0.75em 0;
    line-height: 1em;
    opacity: 0.7;
  }
}

footer {
  @include font-small-size;
  text-align: center;
  padding: 5px 0 $padding;

  a {
    transition-property: opacity;
    transition-duration: 400ms;
    opacity: 0.6;
    display: inline-block;
    line-height: $padding * 2;

    &:hover {
      opacity: 0.9;
    }
  }

  .logo {
    display: inline-block;
    height: 15.5px;
    vertical-align: -3px;
    margin: 0 0.2em;
  }
}

@media screen and (max-width: $content-max-width + $padding * 2) {
  .wage-sharing-container {
    margin: 0 $padding;
    width: auto;
  }

  .three-columns {
    display: block;
  }

  .wage-details .components {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
</style>
