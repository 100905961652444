<template>
  <menu class="hero" :class="{completed}">
    <div class="banner">
      <p class="checked" v-html="continueLabel"></p>
      <loading-button
        v-if="!hideButton"
        class="primary"
        @click="submit"
        :loading="loading">
        <span v-t="'common.continue'"></span>
      </loading-button>
    </div>
  </menu>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    completed: Boolean,
    label: String,
    completedLabel: String,
    hideButton: Boolean
  },
  computed: {
    continueLabel() {
      return this.completed ? this.completedLabel : this.label
    }
  },
  methods: {
    submit() {
      this.$emit('submit')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/button.scss";

@keyframes slide-up {
  from {
    bottom: -10em;
  }
  to {
    bottom: 2em;
  }
}

.hero.completed {
  position: sticky;
  bottom: 2em;
  z-index: 1;
  animation: slide-up 600ms ease-out;
  animation-fill-mode: forwards;
  pointer-events: none;
  margin: 0;
  white-space: nowrap;

  .banner {
    background: white;
    padding: 0.25em 2em 0.25em 1.5em;
    display: inline-block;
    border: $border;
    border-top: 4px solid $clearteal-color;
    margin: 0;
    box-shadow: 2px 3px 0 rgba(0, 0, 0, 0.01), 2px 6px 20px rgba(0, 0, 0, 0.15);
    pointer-events: initial;
  }

  .checked {
    position: relative;
    line-height: 1.5em;
    text-align: left;

    &:before {
      content: '\00a0';
      float: left;
      width: 22px;
      line-height: 22px;
      border-radius: 14px;
      border: 1px solid lighten($clearteal-color, 5);
      margin-top: 10px;
      color: lighten($clearteal-color, 5);
      @include font-bold;
      margin-right: 1.5em;
      transform: scale(1.3);
    }

    &:after {
      @extend .fade-in;
      animation-delay: 700ms;
      animation-fill-mode: both;
      display: inline-block;
      content: '';
      position: absolute;
      left: 8px;
      top: 15px;
      background: transparent;
      width: 4px;
      height: 8px;
      border-bottom: 3px solid lighten($clearteal-color, 5);
      border-right: 3px solid lighten($clearteal-color, 5);
      border-radius: 1px;
      box-shadow: 1px 1px 0 rgba($clearteal-color, .1);
      transform: rotate(45deg) scale(1.3);
    }
  }

  .primary {
    margin-bottom: 1em;
  }
}
</style>
