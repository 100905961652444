<template>
  <div v-if="isSettingsCompanyAccessRightsView">
    <h3 v-t="'settings.settingsCompanyAccessRights.title'"></h3>
    <div class="input-label" v-t="'settings.settingsCompanyAccessRightsPermissions.title'"></div>
      <p class="no-margin-top" v-t="'settings.settingsCompanyAccessRightsPermissions.introShort'"></p>
      <router-link
        tag="button"
        class="small-button"
        :to="{name: 'settingsCompanyAccessRightsPermissions'}"
        v-t="'common.update'">
      </router-link>
    <hr>
    <p class="no-margin-top preserve-lines" v-t="'settings.settingsCompanyAccessRights.explanation'"></p>
    <user-access-rights-table />
  </div>
  <router-view v-else></router-view>
</template>

<script>
import UserAccessRightsTable from '@components/settings/UserAccessRightsTable'

export default {
  components: {
    UserAccessRightsTable
  },
  data() {
    return {
      companyModel: {
        hasOpenPolicy: false
      },
      errorMessage: null,
      isLoading: false,
      isOpenPolicyVisible: false,
      isUpdateSuccess: false
    }
  },
  computed: {
    isSettingsCompanyAccessRightsView() {
      return this.$route.name === 'settingsCompanyAccessRights'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/settings.scss";
</style>
