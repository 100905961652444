<template>
  <div class="form settings-form small">
    <h3 v-t="'settings.emailChange'"></h3>
    <form @submit.prevent="update">
      <div class="form-section">
        <div class="input-label" v-t="'account.newEmail'"></div>
        <input
          type="text"
          v-model="emailChangeModel.email"
          :placeholder="user.email"
          :class="{'input-error' : $v.emailChangeModel.email.$error}">
      </div>
      <div class="form-section">
        <div class="input-label" v-t="'account.password'"></div>
        <input
          type="password"
          v-model="emailChangeModel.password"
          :placeholder="$t('account.passwordPlaceholder')"
          :class="{'input-error' : $v.emailChangeModel.password.$error}">
      </div>
      <div>
        <p v-if="updateSuccess" class="alert" v-t="'settings.emailChangeSuccess'"></p>
        <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
        <loading-button
          class="primary settings-button"
          :loading="isLoading">
          <span v-t="'settings.update'"></span>
        </loading-button>
      </div>
    </form>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      emailChangeModel: {
        email: null,
        password: null
      },
      errorMessage: null,
      updateSuccess: false,
      isLoading: false
    }
  },
  computed: {
    user() {
      return this.$store.getters['account/getUser']
    }
  },
  methods: {
    update() {
      this.$v.$touch()

      if (!this.$v.$error) {
        this.updateSuccess = false
        this.errorMessage = null
        this.isLoading = true

        this.$store.dispatch('account/updateAccountEmail', this.emailChangeModel)
          .then(response => {
            this.isLoading = false
            this.updateSuccess = true

            this.$v.$reset()
            this.emailChangeModel.email = null
            this.emailChangeModel.password = null
          })
          .catch(error => {
            this.isLoading = false
            this.errorMessage = error
          })
      }
    }
  },
  validations() {
    return {
      emailChangeModel: {
        email: { required },
        password: { required }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/settings.scss";
</style>
