<template>
  <div>
    <h3>
      <router-link
        tag="a"
        class="colored-link"
        :to="{name: 'settingsCompanyIntegrations'}"
        :active-class="'selected'">
        {{$t('settings.settingsCompanyIntegrations.title')}}
      </router-link>
      &nbsp;&nbsp;›&nbsp;&nbsp;
      {{$t('settings.settingsCompanyIntegrations.okta.title')}}
    </h3>
    <p class="no-margin-top" v-html="$t('settings.settingsCompanyIntegrations.okta.intro')"></p>
    <div class="form-section">
      <checkbox v-model="companyModel.oktaActive" @change="toggle">
        {{$t('settings.settingsCompanyIntegrations.okta.toggle')}}
      </checkbox>
    </div>
    <resizable-panel :observer="[company.oktaActive, toggleSuccessMessage, successMessage, errorMessage]">
      <p v-if="toggleSuccessMessage" class="no-margin-top alert preserve-lines" v-html="toggleSuccessMessage"></p>
      <div>
        <div class="settings-block">
          <header><strong>Provisioning</strong></header>
          <p v-t="'settings.settingsCompanyIntegrations.okta.settingsToOktaSCIM'"></p>
          <div class="form-section">
            <div class="input-label">API Token</div>
            <copy-input v-model="companyModel.scimAuthToken" />
          </div>
        </div>
        <form @submit.prevent="update">
          <div class="settings-block">
            <header><strong>Single Sign-on</strong></header>
            <p v-t="'settings.settingsCompanyIntegrations.okta.settingsFromOkta'"></p>
            <div class="form-section">
              <div class="input-label">OKTA Client ID</div>
              <input
                type="text"
                v-model="companyModel.oktaClientId"
                placeholder="Ex : 0oaneswpzjoBmTSu80h6">
            </div>
            <div class="form-section">
              <div class="input-label">OKTA Domain</div>
              <input
                type="text"
                v-model="companyModel.oktaDomain"
                @change="formatDomain"
                placeholder="Ex : my-company.okta.com">
            </div>
          </div>
          <div>
            <p v-if="successMessage" class="alert preserve-lines" v-html="successMessage"></p>
            <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
            <loading-button
              class="primary settings-button"
              :loading="isLoading">
              {{$t('settings.settingsCompanyIntegrations.applyChanges')}}
            </loading-button>
          </div>
        </form>
      </div>
    </resizable-panel>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatDomain } from '@/utils/string'
import Checkbox from '@components/commons/Checkbox'
import CopyInput from '@components/commons/CopyInput'
import ResizablePanel from '@components/commons/ResizablePanel.vue'

export default {
  components: { Checkbox, CopyInput, ResizablePanel },
  data() {
    return {
      companyModel: {},
      errorMessage: null,
      successMessage: null,
      toggleSuccessMessage: null,
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      company: 'account/getCompany',
      getOpenIDConnectLoginRedirectURI: 'okta/getOpenIDConnectLoginRedirectURI',
      getOpenIDConnectInitiateLoginURI: 'okta/getOpenIDConnectInitiateLoginURI'
    })
  },
  methods: {
    formatDomain() {
      if (this.companyModel.oktaDomain) {
        this.companyModel.oktaDomain = formatDomain(this.companyModel.oktaDomain)
      }
    },
    toggle() {
      this.$store.dispatch('company/updateOktaSettings', this.companyModel)
        .then(({ oktaActive }) => {
          this.toggleSuccessMessage = this.$t('settings.settingsCompanyIntegrations.okta.toggleSuccess' + (oktaActive ? 'Active' : 'Inactive'))
        })
        .catch(error => error && alert(error))
    },
    update() {
      this.errorMessage = null
      this.successMessage = null
      this.toggleSuccessMessage = null
      this.isLoading = true

      this.$store.dispatch('company/updateOktaSettings', this.companyModel)
        .then(({ oktaActive }) => {
          this.isLoading = false
          this.successMessage = this.$t('settings.settingsCompanyIntegrations.okta.success' + (oktaActive ? 'Active' : 'Inactive'))
        })
        .catch(error => {
          this.isLoading = false
          this.errorMessage = error
        })
    }
  },
  created() {
    this.companyModel = { ...this.company }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/settings.scss";
</style>
