import store from '@/store'
import { camelCase, cloneDeepWith } from 'lodash'

// Get a JS object from user settings
export function getSetting(key, defaultValue = null) {
  key = getKey(key)
  const value = store.getters['account/getSetting'](key)
  return value !== undefined ? cloneDeepWith(value) : defaultValue
}

// Set a JS object from user settings
export async function setSetting(key, value) {
  key = getKey(key)
  value = cloneDeepWith(value)
  try {
    await store.dispatch('account/updateSetting', { key, value })
  }
  catch (_) {}
}

function getKey(key) {
  return camelCase(key.replace('CW_', ''))
}
