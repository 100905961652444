<template>
  <div>
    <div class="header">
      <div
        v-for="tab in tabs"
        :key="tab"
        @click="click(tab)"
        :class="{
          'colored-link': true,
          'tab': true,
          'active': activeTab == tab}">
        {{ tab }}
        <div class="fix-bottom"></div>
      </div>
    </div>
    <div class="border"></div>
  </div>
</template>

<script>
export default {
  props: ['tabs', 'activeTab'],
  methods: {
    click(tab) {
      this.$emit('tabSelected', tab)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/link.scss";

.header {
  background: $background-color;
  z-index: 0;
}

.border {
  position: relative;
  height: 4px;
  border-top: $border;
  border-left: $border;
  border-right: $border;
  background-color: white;
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: 0;
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: 0;
}

.tab {
  display: inline-block;
  position: relative;
  margin-top: -3px;
  top: 3px;
  width: 130px;
  padding: 12px 0;
  z-index: 0;
  text-align: center;
  background-color: white;
  border-top: $border;
  border-left: $border;
  border-right: $border;
  border-radius: $border-radius;
  background-image: linear-gradient(to top, lighten($background-color, 1.5), white);
  margin-left: 5px;

  &:first-child {
    margin-left: 0;
  }
}

.fix-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3px;
  background-color: white;
}

.active {
  z-index: 1;
  color: $clearteal-color;
  background: white;
  @include font-semibold;

  &:hover {
    color: $clearteal-color;
  }
}
</style>
