<template>
  <div>
    <div class="job-offer-container">
      <menu class="float-right no-margin-top no-margin-bottom" v-if="storedJobOffer">
        <button
          v-if="$$isAdmin"
          class="destructive"
          v-t="'common.remove'"
          @click="deleteConfirmationDisplayed = true">
        </button>
      </menu>
      <div
        v-if="storedJobOffer"
        class="job-offer-name"
        @click="jobOfferNameFormDisplayed = true">
        <div class="edit-action">
          <h1>
            <span
              v-for="(namePart, i) in jobOfferNameParts"
              :key="[namePart, i].join()">
              {{namePart}}
            </span>
          </h1>
          <img src="~@/assets/edit.svg" alt="edit">
          <div class="underline"></div>
        </div>
      </div>
      <h1 v-else v-t="isAddJobOffer ? 'candidates.addJobOffer' : 'hiringPlans.form.title'"></h1>

      <div class="reference-wage">
        <p class="no-margin-top" v-t="isAddJobOffer || storedJobOffer ? 'candidates.jobOfferForm.referenceWage' : 'hiringPlans.form.intro'"></p>
        <wage-calculator
          v-model="jobOfferModel.referenceWage"
          :grid="currentGrid"
          :showWageTypeSelector="false"
          :showVariable="true"
          :autoOpen="!storedJobOffer"
          @input="setJobOfferNameIfNeeded">
        </wage-calculator>

        <div v-if="!storedJobOffer">
          <div class="input-label" v-t="'candidates.jobOfferName'"></div>
          <input
            type="text"
            v-model="jobOfferModel.name"
            :placeholder="$t('candidates.jobOfferName')"
            :class="{'input-error' : $v.jobOfferModel.name.$error}">
        </div>

        <div>
          <div class="input-label" v-t="'candidates.jobOfferForm.quantity'"></div>
          <quantity-input
            class="green"
            v-model="jobOfferModel.quantity"
            :class="{'input-error' : $v.jobOfferModel.quantity.$error}" />
        </div>

        <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>

        <menu>
          <button
            :disabled="isLoading"
            class="secondary"
            @click="$router.go(-1)"
            v-t="'common.cancel'">
          </button>
          <loading-button
            class="primary"
            :loading="isLoading"
            @click="submit">
            <span v-t="storedJobOffer ? 'candidates.updateJobOffer' : (isAddJobOffer ? 'common.add' : 'common.create')"></span>
          </loading-button>
        </menu>
      </div>
      <modal
        :visible="deleteConfirmationDisplayed"
        @close="deleteConfirmationDisplayed = false">
        <job-offer-delete-confirmation
          :job-offer="jobOfferModel"
          @close="deleteConfirmationDisplayed = false" />
      </modal>
      <modal
        :visible="jobOfferNameFormDisplayed"
        @close="jobOfferNameFormDisplayed = false">
        <job-offer-name-form
          :job-offer="jobOfferModel"
          @close="jobOfferNameFormDisplayed = false" />
      </modal>
    </div>
    <div class="job-offer-container" v-if="storedJobOffer">
      <div>
        <h2 v-t="'candidates.candidates'"></h2>
        <p class="candidates-count">
          {{$tc('candidates.count', candidates.length, {count: candidates.length})}}
        </p>
        <candidates-table
          v-if="candidates.length"
          :candidates="candidates"
          @select="openCandidate" />
      </div>
      <div v-if="rejectedCandidates.length">
        <br>
        <h2 v-t="'candidates.rejectedCandidates'"></h2>
        <p class="candidates-count">
          {{$tc('candidates.count', rejectedCandidates.length, {count: rejectedCandidates.length})}}
        </p>
        <candidates-table
          :candidates="rejectedCandidates"
          @select="openCandidate" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { integer, minValue, required } from 'vuelidate/lib/validators'
import CandidatesTable from '@components/candidates/CandidatesTable.vue'
import JobOfferDeleteConfirmation from '@components/candidates/JobOfferDeleteConfirmation.vue'
import JobOfferNameForm from '@components/candidates/JobOfferNameForm.vue'
import Modal from '@components/commons/Modal.vue'
import QuantityInput from '@components/commons/QuantityInput'
import WageCalculator from '@components/wage/WageCalculator.vue'

export default {
  data() {
    return {
      jobOfferModel: null,
      errorMessage: null,
      isLoading: false,
      deleteConfirmationDisplayed: false,
      jobOfferNameFormDisplayed: false
    }
  },
  computed: {
    ...mapGetters({
      currentGrid: 'currentGrid/getCurrentGrid',
      getEnrichedCandidatesByJobOffer: 'candidates/getEnrichedCandidatesByJobOffer',
      getEnrichedRejectedByJobOffer: 'candidates/getEnrichedRejectedByJobOffer',
      getJobOfferModel: 'candidates/getJobOfferModel',
      getJobOfferNameFromWage: 'candidates/getJobOfferNameFromWage',
      isJobOfferUnique: 'candidates/isJobOfferUnique'
    }),
    isAddJobOffer() {
      return this.$route.name === 'addJobOffer'
    },
    storedJobOffer() {
      const jobOfferId = this.$route.params.id
      return jobOfferId ? this.$store.getters['candidates/getJobOffer'](jobOfferId) : null
    },
    jobOfferNameParts() {
      if (this.jobOfferModel) {
        return this.jobOfferModel.name.split('   ')
      }
    },
    candidates() {
      if (this.storedJobOffer) {
        return this.getEnrichedCandidatesByJobOffer(this.storedJobOffer.id)
      }
    },
    rejectedCandidates() {
      if (this.storedJobOffer) {
        return this.getEnrichedRejectedByJobOffer(this.storedJobOffer.id)
      }
    }
  },
  methods: {
    openCandidate({ id }) {
      this.$router.push({ name: 'candidate', params: { id } })
    },
    setJobOfferNameIfNeeded() {
      if (this.$$hasGrid && (!this.jobOfferModel.name || this.jobOfferModel.name.includes('   '))) {
        this.jobOfferModel.name = this.getJobOfferNameFromWage(this.jobOfferModel.referenceWage)
      }
    },
    submit() {
      this.$v.$touch()

      if (!this.$v.$error) {
        const jobOffer = this.jobOfferModel
        this.errorMessage = null
        this.isLoading = true

        if (!this.storedJobOffer) {
          const isJobOfferUnique = this.isJobOfferUnique(jobOffer) ||
            window.confirm(this.$t('candidates.jobOfferForm.similarJobOfferConfirmation'))
          if (isJobOfferUnique) {
            this.$store.dispatch('candidates/addJobOffer', jobOffer)
              .then(jobOffer => {
                this.isLoading = false
                this.$router.push({ name: 'hiringPlans', params: { highlightJobOffer: jobOffer.id } })
              })
              .catch(error => {
                this.isLoading = false
                this.errorMessage = error
              })
          }
          else {
            this.isLoading = false
          }
        }
        else {
          const referenceWage = this.jobOfferModel.referenceWage
          this.$store.dispatch('candidates/updateJobOffer', jobOffer)
            .then(() => {
              this.$store.dispatch('candidates/setReferenceWage', { jobOffer, referenceWage })
                .then(response => {
                  this.$router.push('/recruitment')
                })
                .catch(error => {
                  this.errorMessage = error
                  this.isLoading = false
                })
            }).catch(error => {
              this.errorMessage = error
              this.isLoading = false
            })
        }
      }
      else {
        window.scrollTo(0, 0)
      }
    }
  },
  watch: {
    'storedJobOffer.name'() {
      this.jobOfferModel.name = this.storedJobOffer.name
    }
  },
  validations() {
    return {
      jobOfferModel: {
        name: { required },
        quantity: {
          integer,
          minValue: minValue(1)
        }
      }
    }
  },
  created() {
    if (this.storedJobOffer) {
      this.jobOfferModel = { ...this.storedJobOffer }
      this.$store.commit('candidates/setCurrentJobOffer', this.jobOfferModel)
    }
    else {
      this.jobOfferModel = { ...this.getJobOfferModel }
      this.setJobOfferNameIfNeeded()
      const hiringPlan = this.$store.getters['candidates/getHiringPlan'](this.$route.params.hiringPlanId)
      if (hiringPlan) {
        this.jobOfferModel.hiringPlan = hiringPlan
      }
    }
  },
  components: {
    CandidatesTable,
    JobOfferDeleteConfirmation,
    JobOfferNameForm,
    Modal,
    QuantityInput,
    WageCalculator
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/alert.scss";
@import "./src/styles/button.scss";
@import "./src/styles/link.scss";
@import "./src/styles/form.scss";

.job-offer-container {
  @include container;
  margin-bottom: 1.5em;
}

h1 {
  margin: 0 0 20px 0;
  display: inline-block;
  position: relative;
  z-index: 2;

  span:last-of-type:not(:first-of-type) {
    @include font-medium;
    display: inline-block;
    color: $light-text-color;
    margin-left: 0.25em;
    margin-right: 0.25em;
  }
}

.job-offer-name {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .edit-action {
    position: relative;
    cursor: pointer;

    .underline {
      display: none;
      position: absolute;
      z-index: 1;
      bottom: 22px;
      width: 100%;
      height: 2px;
      background: lighten($light-text-color, 10);
    }

    &:hover > .underline {
      display: block;
    }

    img {
      height: 16px;
      margin-left: 5px;
    }
  }
}

input[type="text"] {
  display: inline-block;
  width: 200px;
  margin-bottom: 1.5em;
}

.candidates-count {
  margin-top: -0.5em;
}
</style>
