import Vue from 'vue'
import api from '@/store/api'
import { normalizedGrids } from '@/store/schema'

const initialState = () => {
  return {
    sandboxList: [], // [1, 2, …]
    grids: {}, // { 1: { name: 'A grid', …}, 2: {…}, … }
    gridModel: { // Helper for grid form
      name: null
    },
    templateGrids: [],
    gridsLoaded: false
  }
}

const state = initialState()

const getters = {
  grids(state) {
    return state.sandboxList.map(gridId => state.grids[gridId])
  },
  templateGrids(state) {
    return state.templateGrids
  },
  gridsLoaded(state) {
    return state.gridsLoaded
  }
}

const actions = {
  reset(context) {
    context.commit('reset')
  },
  get(context) {
    return api.get('/grid/sandbox')
      .then(response => {
        context.commit('setGrids', response.data)
        return response.data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  create(context, grid) {
    return api.post('/grid', grid)
      .then(response => {
        context.commit('addGrid', response.data)
        return response.data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  remove(context, grid) {
    return api.delete('/grid/' + grid.id)
      .then(_ => {
        context.commit('removeGrid', grid)
        this.dispatch('onboarding/init')
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  update(context, grid) {
    return api.patch('/grid/' + grid.id, grid)
      .then(response => {
        context.commit('updateGrid', response.data)
        return response.data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  getTemplateGrids(context) {
    return api.get('/grid/templates')
      .then(response => {
        context.commit('setTemplateGrids', response.data)
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  }
}

const mutations = {
  reset(state) {
    Object.assign(state, initialState())
  },
  setGrids(state, grids) {
    const n = normalizedGrids(grids)
    state.sandboxList = n.result || []
    state.grids = n.entities.grids || {}
    state.gridsLoaded = true
  },
  addGrid(state, grid) {
    Vue.set(state.grids, grid.id, grid)
    state.sandboxList.push(grid.id)
  },
  removeGrid(state, grid) {
    state.sandboxList.splice(state.sandboxList.indexOf(grid.id), 1)
  },
  updateGrid(state, grid) {
    state.grids[grid.id] = grid
  },
  setTemplateGrids(state, templateGrids) {
    state.templateGrids = templateGrids
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
