import flattenDeep from 'lodash.flattendeep'
import uniq from 'lodash.uniq'

export const SKILL_VALUE_NONE = 0
export const SKILL_VALUE_UNKNOWN = 1
export const SKILL_VALUE_ONGOING = 2
export const SKILL_VALUE_ACQUIRED = 10
export const SKILL_VALUES = [
  SKILL_VALUE_NONE,
  SKILL_VALUE_UNKNOWN,
  SKILL_VALUE_ONGOING,
  SKILL_VALUE_ACQUIRED
]

export function componentHasSkills(component, recursive = false) {
  return hasSkills(component) ||
        (recursive &&
          component.linkedComponents &&
          component.linkedComponents.find(hasSkills))
}

export function hasSkills(component) {
  const serverHasSkills = component && component.hasSkills
  // Still needed because getFlatComponent accepts jobSkills structure.
  const legacyHasSkills = component && component.levels && component.levels.length && !!component.levels[0].domains
  return serverHasSkills || legacyHasSkills
}

// Returns a flat component with valid skills on levels
export function getFlatComponent(component) {
  // Empty skills are ignored
  if (hasSkills(component)) {
    return {
      id: component.id,
      name: component.name,
      ref: component.ref,
      salaryOperation: component.salaryOperation,
      config: component.config,
      levels: component.levels.map(level => ({
        id: level.id,
        name: level.name,
        isHidden: level.isHidden,
        description: level.description,
        salaryValue: level.salaryValue,
        minimumValue: level.minimumValue,
        maximumValue: level.maximumValue,
        rank: level.rank,
        skills: flattenDeep(
          level.domains.map(domain =>
            domain.skills
              .filter(s => !!s.name)
              .map(s => ({ ...s, domain: { name: domain.name, description: domain.description } }))
          )
        )
      }))
    }
  }
}

export function getSkillsProgress(component) {
  const skills = getFlatComponent(component)
  if (skills) {
    const levelsCount = skills.levels.length
    const count = skills.levels.reduce((memo, level) => {
      if (level.skills.length) {
        memo++
      }
      return memo
    }, 0)
    return {
      count: count,
      total: levelsCount,
      percent: Math.round(count / levelsCount * 100)
    }
  }
  else {
    return { count: 0, total: 0, percent: 0 }
  }
}

export function getSkillsFromLevels(levels) {
  return flattenDeep(
    levels.map(level => (
      level.domains && level.domains.map(domain => (
        domain.skills.map(skill => skill)
      ))
    ))
  )
    .filter(s => !!s)
}

export function getSkillIdsFromLevels(levels) {
  return getSkillsFromLevels(levels)
    .map(s => s.id)
}

export function findSkills(component, levelId, domainId) {
  return component.levels
    .find(l => l.id === levelId)
    .domains.find(d => d.id === domainId)
    .skills
}

export function findSkill(component, levelId, domainId, skillId) {
  return findSkills(component, levelId, domainId)
    .find(s => s.id === skillId)
}

// Takes a flat component with skills on levels
// Returns true if the level and all the previous ones are selected
export function isLevelReached(flatComponent, level, selectedSkills) {
  // level starts from 0
  let hasSkills = true
  const visibleLevels = flatComponent.levels.filter(l => !l.isHidden)
  for (let i = 0; i < visibleLevels.length; i++) {
    const currentLevel = visibleLevels[i]
    if (!currentLevel.skills || !selectedSkills) {
      return false
    }
    hasSkills = hasSkills && isLevelSelected(currentLevel, selectedSkills)
    if (currentLevel.id === level.id) {
      if (i === 0) {
        return true
      }
      else {
        return hasSkills
      }
    }
  }
}

// Takes a level with skills
// Returns true if all skills from level are selected
export function isLevelSelected(flatLevel, selectedSkills) {
  if (selectedSkills) {
    return flatLevel.skills.length &&
      flatLevel.skills.every(s => selectedSkills.includes(s.id))
  }
}

// Takes a flat component with skills on levels
// Optionally pass originalLevels to return the corresponding non-flat level
// Returns the highest level reached
export function getReachedLevel(flatComponent, selectedSkills, originalLevels = null) {
  let reachedIndex = 0
  const visibleLevels = flatComponent.levels.filter(l => !l.isHidden)
  for (let i = 0; i < visibleLevels.length; i++) {
    const currentLevel = visibleLevels[i]
    if (isLevelReached(flatComponent, currentLevel, selectedSkills)) {
      reachedIndex = i
    }
    else {
      break
    }
  }
  const reachedLevel = visibleLevels[reachedIndex]
  return originalLevels ? originalLevels.find(l => l.id === reachedLevel.id) : reachedLevel
}

// Check/Uncheck all skills for this level
// Returns an updated selectedSkills array
export function toggleLevelSelectedSkills(level, selectedSkills) {
  const skillIds = level.skills.map(s => s.id)
  const hasUnselectedSkill = skillIds.find(id => !selectedSkills.includes(id))
  if (hasUnselectedSkill) {
    return uniq(selectedSkills.concat(skillIds))
  }
  else {
    return selectedSkills.filter(id => !skillIds.includes(id))
  }
}

// Update skill values based on selected skills
export function applySelectedSkills(selectedSkillValues, selectedSkills) {
  const skillIds = uniq(Object.keys(selectedSkillValues).concat(selectedSkills))
  skillIds.forEach(skillId => {
    if (isSkillChecked(selectedSkills, skillId)) {
      selectedSkillValues[skillId] = SKILL_VALUE_ACQUIRED
    }
    else {
      selectedSkillValues[skillId] = SKILL_VALUE_NONE
    }
  })
  return selectedSkillValues
}

// Check/Unchell all skills so this level becomes the selected one
// If the level isn't selected, will be the new selection
// If the level is selected, select the previous one instead
// Returns the list of selected skills
export function selectLevelSkills(flatComponent, selectedSkills, selectedLevel, level) {
  if (level.id !== selectedLevel.id || (level.rank === 0 && !selectedSkills.length)) {
    return flattenDeep(
      flatComponent.levels
        .filter(l => l.rank <= level.rank)
        .map(l => l.skills)
    ).map(s => s.id)
  }
  else {
    return flattenDeep(
      flatComponent.levels
        .filter(l => l.rank < level.rank)
        .map(l => l.skills)
    ).map(s => s.id)
  }
}

// Returns the list of skill labels, extracted from the skill name
// Usage: `Motivation | Highly-skilled | Soft skill`
export function getSkillLabels(skill) {
  if (skill) {
    return skill.name.split('|').slice(1).map(p => p.trim())
  }
  else {
    return []
  }
}

// Parse and return the list of skill names from a multiline string
// and remove junk (whitespaces, bullet points, …).
// Use it to extract skills from the clipboard
const SKILL_LABEL_SEP = ' | '
export function parseSkillNamesFromMultilineString(text) {
  if (text) {
    const names = text.split('\n')
    return names
      .map(name =>
        // Clean name, extract and format skill labels (with ` | `)
        name
          .split('\t')
          .map(
            namePart =>
              namePart
                .trim()
                .replace(/^"|"$/g, '') // Remove external quotes
                .replace(/\r/g, '') // Remove Windows newlines
                .replace(/^[-—*•]/g, '') // Remove bullet points
                .trim()
          )
          .filter(namePart => !!namePart)
          .join(SKILL_LABEL_SEP)
      )
      .filter(name => !!name)
  }
  else {
    return []
  }
}

// Generates a list of skills holders (roles, components withs skills)
// Takes flat components, an optional progress function and if components without skills should be included
// Returns a list with id you can pass to function getSkillsHolderLevels()
export function getSkillsHolders(components, progressFunction = () => {}, includeWithoutSkills = false) {
  const roleComponent = components.find(c => c.ref === 'role')
  const experienceComponent = components.find(c => c.ref === 'experience')
  const roleLevels = roleComponent && experienceComponent && (experienceComponent.hasSkills || includeWithoutSkills)
    ? roleComponent.levels.map(level => ({
      id: level.name,
      name: level.name,
      progress: progressFunction(level.name),
      isRole: true
    }))
    : []
  const componentsWithSkills = components.filter(c => (c.hasSkills || includeWithoutSkills) && !['role', 'experience'].includes(c.ref) && c.scope !== 'intervalSteps')
    .map(component => ({
      id: '_' + component.id,
      name: component.name,
      progress: progressFunction('_' + component.id)
    }))
  return roleLevels.concat(componentsWithSkills)
}

// Fetches the list of levels with skills for a specific skills holder.
// Take flats components
// Returns a list of levels
export function getSkillsHolderLevels(components, skillHolderId) {
  let component
  let levels = []

  if (skillHolderId && skillHolderId.length > 1 && skillHolderId[0] === '_') {
    component = components.find(c => c.id === skillHolderId.substr(1))
    levels = component ? component.levels : []
  }
  else {
    const roleComponent = components.find(c => c.ref === 'role')
    if (roleComponent) {
      const roleLevel = roleComponent.levels.find(l => l.name === skillHolderId)
      if (roleLevel) {
        component = components.find(c => c.ref === 'experience')
        if (component) {
          levels = component.levels.filter(l => l.linkedLevelId === roleLevel.id)
        }
      }
    }
  }
  return levels
}

export function isSkillChecked(skillIds, skillId) {
  return skillIds && skillIds.includes(skillId)
}

export function getSkillValue(skillIds, skillValues, skillId) {
  if (skillValues && skillValues[skillId] !== undefined) {
    return skillValues[skillId]
  }
  else {
    return isSkillChecked(skillIds, skillId) ? SKILL_VALUE_ACQUIRED : SKILL_VALUE_NONE
  }
}
