<template>
  <div>
    <menu class="float-right">
      <loading-button
        class="secondary"
        @click="$router.push({name: 'hiringPlans'})">
        <span v-t="'hiringPlans.action'"></span>
      </loading-button>
    </menu>
    <h3 v-t="'hiringPlans.hiringPlans'"></h3>
    <div class="plans">
      <div
        class="plan"
        v-for="hiringPlan in hiringPlans"
        :key="hiringPlan.id"
        @click="openHiringPlan($event, hiringPlan)">
        <checkbox :checked="isHiringPlanSelected(hiringPlan)">
          {{hiringPlan.name}}
        </checkbox>
        <div class="employees-badge">
          <div class="employees" :class="{empty: !hiringPlan.jobOffers}">
            {{$tc('hiringPlans.positions', hiringPlan.jobOffers, {count: hiringPlan.jobOffers})}}
          </div>
          <div class="badge-container">
            <div
              v-if="hiringPlan.postPayrollRise !== 0"
              class="badge border-badge delta-badge higher">
              {{ hiringPlan.postPayrollRise | formattedCurrency(false, {signDisplay: "always"}) }}
            </div>
            <div v-else class="badge border-badge delta-badge" v-t="'common.unchanged'"></div>
          </div>
        </div>
      </div>
      <div
        v-if="!hiringPlans.length"
        class="alert placeholder"
        v-t="'hiringPlans.placeholder'">
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Checkbox from '@components/commons/Checkbox.vue'

export default {
  computed: {
    ...mapGetters({
      hiringPlans: 'candidates/getEnrichedHiringPlans',
      isHiringPlanSelected: 'candidates/isHiringPlanSelected'
    })
  },
  methods: {
    openHiringPlan({ target }, { id }) {
      switch (true) {
        case target.nodeName === 'LABEL':
          return
        case target.nodeName === 'INPUT':
          this.$store.dispatch('candidates/toggleHiringPlanSelection', { id })
          return
        default:
          this.$router.push({ name: 'hiringPlans', params: { highlightHiringPlan: id } })
      }
    }
  },
  components: {
    Checkbox
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/plans.scss";
</style>
