<template>
  <div class="data-feed">
    <header>
      <div class="data-feed-title">{{enrichedDataFeed.name}}</div>
      <Dropdown
        class="data-feed-filters blue modern"
        :items="filtersDropdownItems"
        @change="setFilters">
        <span v-t="'common.show'"></span>
        <span class="down">▾</span>
      </Dropdown>
      <search-input
        class="data-feed-search blue"
        v-model="searchQuery"
        :searchCount="null" />
      <div
        class="data-feed-updated-at light-text"
        :class="{loading: isLoading}"
        @click="refresh">
        {{currentTime | formatDate('HH:mm')}}
        <span class="icon"></span>
      </div>
    </header>
    <div class="data-feed-items" v-if="truncatedItems.length">
      <table class="blue inverted">
        <tr>
          <th v-if="isColumnVisible('date')" class="arrow--1" v-t="'variable.dataFeed.dataFeedItem.key.date.title'"></th>
          <th v-if="isColumnVisible('invoice')" v-t="'variable.dataFeed.dataFeedItem.key.invoice.title'"></th>
          <th v-if="isColumnVisible('title')" v-t="'variable.dataFeed.dataFeedItem.key.title.title'"></th>
          <th v-if="isColumnVisible('employee')" v-t="'variable.dataFeed.dataFeedItem.key.employee.title'"></th>
          <th v-if="isColumnVisible('value')" v-t="'variable.dataFeed.dataFeedItem.key.value.title'"></th>
          <th
            v-for="columnKey in dataFeedColumnKeys"
            :key="columnKey">{{columnKey}}</th>
          <th v-if="!isDropdownDisabled && isColumnVisible('actions')"></th>
        </tr>
        <tbody>
          <tr v-for="item in truncatedItems"
            :key="item.id"
            class="row"
            :class="{'light-text': !item.isOngoingPeriod}"
            :title="!item.isOngoingPeriod && $t('variable.dataFeed.dataFeedItem.key.date.notOngoing')">
            <td v-if="isColumnVisible('date')" :class="{error: item.errors.date}" :title="item.errors.date">{{item.formattedDate}}</td>
            <td v-if="isColumnVisible('invoice')" :class="{error: item.errors.invoice}" :title="item.errors.invoice">{{item.invoice}}</td>
            <td v-if="isColumnVisible('title')" :class="{error: item.errors.title}" :title="item.errors.title">{{item.title}}</td>
            <td v-if="isColumnVisible('employee')" :class="{error: item.errors.employee}" :title="item.errors.employee">{{item.employee}}</td>
            <td v-if="isColumnVisible('value')" :class="{error: item.errors.value}" :title="item.errors.value">{{item.value | formattedCurrency}}</td>
            <td
              v-for="(columnKey, i) in dataFeedColumnKeys"
              :key="i">{{item.columns[i] > 2 ? $options.filters.formattedCurrency(item.columns[i]) : item.columns[i]}}</td>
            <td class="item-action" v-if="!isDropdownDisabled && isColumnVisible('actions')">
              <Dropdown
                class="blue"
                :items="dropdownItems"
                @change="(_, action) => onDropdownClick(item, action)">⋯</Dropdown>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <menu class="pagination" v-if="needsTruncation">
      <button
        class="small-button blue"
        :disabled="!canTruncatePrevious"
        @click="truncatePrevious()"
        v-t="'dashboard.navigation.previous'"></button>
      <span>{{ truncationLabel }}</span>
      <button
        class="small-button blue"
        :disabled="!canTruncateNext"
        @click="truncateNext()"
        v-t="'dashboard.navigation.next'"></button>
    </menu>
    <p class="light-text no-margin-bottom" v-if="!truncatedItems.length">
      {{emptyPlaceholder}}
    </p>
  </div>
</template>

<script>
import { formatSmartPeriod } from '@/utils/variable'
import { filterByQuery } from '@/utils/string'
import Dropdown from '@components/commons/Dropdown'
import SearchInput from '@components/commons/SearchInput.vue'
import debounce from 'lodash.debounce'

const REFRESH_DELAY = 120000

export default {
  components: {
    Dropdown,
    SearchInput
  },
  props: {
    enrichedDataFeed: Object,
    variablePlan: Object
  },
  data() {
    return {
      currentTime: new Date(),
      currentTimeInternal: null,
      refreshTimer: null,
      isLoading: false,
      isFiltered: false,
      offset: 0,
      limit: 20,
      searchQuery: null
    }
  },
  computed: {
    employees() {
      if (this.variablePlan.variableWages) {
        return this.variablePlan.variableWages
          ? this.variablePlan.variableWages.map(v => ({ id: v.userId }))
          : []
      }
    },
    dropdownItems() {
      return [{
        name: this.$t('variable.dataFeed.dataFeedItem.action.title'),
        value: 'action',
        items: [{
          name: this.$t('common.remove'),
          value: 'remove',
          disabled: true
        }]
      }]
    },
    filtersDropdownItems() {
      return [{
        name: this.$t('variable.dataFeed.filters.periods.title'),
        value: 'periods',
        items: [{
          name: this.$t('variable.dataFeed.filters.periods.false'),
          value: false,
          selected: !this.isFiltered
        },
        {
          name: this.$t('variable.dataFeed.filters.periods.true'),
          value: true,
          selected: this.isFiltered
        }]
      }, {
        name: 'Nombre de lignes',
        value: 'limit',
        items: [{
          name: 20,
          value: 20,
          selected: this.limit === 20
        },
        {
          name: 50,
          value: 50,
          selected: this.limit === 50
        },
        {
          name: 100,
          value: 100,
          selected: this.limit === 100
        }, {
          name: 500,
          value: 500,
          selected: this.limit === 500
        }]
      }]
    },
    isDropdownDisabled() {
      return this.$$isOneselfRoute || !this.$$isAdmin || this.variablePlan.status === 'closed'
    },
    items() {
      const userIds = this.employees.map(({ id }) => id)
      let items = this.enrichedDataFeed.dataFeedItems
      if (!this.isFiltered) {
        items = items.filter(i => i.isOngoingPeriod && (!i.userId || userIds.includes(i.userId)))
      }
      if (this.searchQuery) {
        items = filterByQuery(items, this.searchQuery, '_searchKey')
      }
      return items
    },
    needsTruncation() {
      return this.items.length > this.limit
    },
    truncatedItems() {
      if (this.needsTruncation) {
        return this.items.slice(this.offset, this.offset + this.limit)
      }
      else {
        return this.items
      }
    },
    truncationLabel() {
      const page = this.offset / this.limit + 1
      const total = Math.ceil(this.items.length / this.limit)
      return `${page} / ${total}`
    },
    canTruncatePrevious() {
      return this.offset > 0
    },
    canTruncateNext() {
      return this.offset + this.limit < this.items.length
    },
    emptyPlaceholder() {
      const ongoingPeriod = formatSmartPeriod(this.variablePlan, this.$t('common.to')).toLowerCase()
      if (!this.enrichedDataFeed.dataFeedItems.length) {
        return this.$t('variable.dataFeed.placeholder.empty')
      }
      else if (!this.items.length) {
        return this.$t('variable.dataFeed.placeholder.filteredEmpty', { ongoingPeriod })
      }
      else if (this.items.length !== this.enrichedDataFeed.dataFeedItems.length) {
        return this.$t('variable.dataFeed.placeholder.filtered', { ongoingPeriod })
      }
      else {
        return ''
      }
    },
    dataFeedColumnKeys() {
      return this.enrichedDataFeed.config.columnKeys || []
    }
  },
  methods: {
    setFilters(key, value) {
      switch (key) {
        case 'periods':
          this.isFiltered = value
          break
        case 'limit':
          this.offset = 0
          this.limit = value
          break
      }
    },
    onSearchQueryChange: debounce(function(value) {
      this.searchQuery = value
    }, 300),
    truncatePrevious() {
      if (this.canTruncatePrevious) {
        this.offset -= this.limit
      }
    },
    truncateNext() {
      if (this.canTruncateNext) {
        this.offset += this.limit
      }
    },
    setRefreshTimeer() {
      this.refreshTimer = setTimeout(this.refresh, REFRESH_DELAY)
    },
    async refresh() {
      clearTimeout(this.refreshTimer)
      this.isLoading = true
      try {
        const variablePlanId = this.variablePlan.id
        const { id } = this.enrichedDataFeed
        await this.$store.dispatch('variablePlans/getDataFeed', { variablePlanId, id })
        // TODO: Only trigger input if data has changed (etag?)
        this.$emit('input')
      }
      catch (e) {
      }
      this.isLoading = false
      this.setRefreshTimeer()
    },
    onVisibilityChange() {
      if (!document.hidden) {
        this.refresh()
      }
    },
    onDropdownClick(item, action) {
      switch (action) {
        case 'remove':
          return this.removeDataFeedItem(item)
      }
    },
    isColumnVisible(column) {
      const hiddenColumns = this.enrichedDataFeed.config.hiddenColumns || []
      return !hiddenColumns.includes(column)
    },
    async removeDataFeedItem(item) {
      if (confirm(this.$t('variable.dataFeed.dataFeedItem.action.removeConfirmation', item))) {
        try {
          const variablePlanId = this.variablePlan.id
          const dataFeedId = this.enrichedDataFeed.id
          const id = item.id
          await this.$store.dispatch('variablePlans/removeDataFeedItem', { variablePlanId, dataFeedId, id })
          this.refresh()
        }
        catch (error) {
          error && alert(error)
        }
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.onVisibilityChange)
    clearTimeout(this.refreshTimer)
    clearInterval(this.currentTimeInternal)
  },
  created() {
    document.addEventListener('visibilitychange', this.onVisibilityChange, false)
    this.setRefreshTimeer()
    this.currentTimeInternal = setInterval(() => {
      this.currentTime = new Date()
    }, 60000)
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/variable.scss";
@import "./src/styles/table.scss";
@import "./src/styles/link.scss";

@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.data-feed {
  @include font-small-size;
  padding-top: 7px !important;
}

header {
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  gap: 10px;
  align-items: center;

  .data-feed-title {
    @include font-semibold;
  }

  .data-feed-filters::v-deep {
    .dropdown-button {
      &:not(:hover):not(:focus),
      &[disabled] {
        color: $light-text-color;
      }
    }
  }

  .data-feed-search {
    margin-left: 0;

    &::v-deep input {
      font: inherit;
      line-height: 2em;
      height: auto;
      width: 125px;
    }
  }

  .data-feed-updated-at {
    @include font-tabular-numbers;
    cursor: pointer;
    transition: opacity 200ms linear;

    &.loading {
      opacity: 0.5;
    }

    .icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: no-repeat top left;
      background-image: url(~@/assets/synchronization-loading.svg);
      animation: loading 3s infinite linear;
      margin: -2px 0 -5px 3px;
      border-radius: 20px;
      text-align: center;
    }
  }
}

.data-feed-items {
  border: 1px solid $graph-outer-border-color;
  border-radius: $border-radius;
  box-shadow: 1px 1.5px 0 rgba(0, 0, 0, 0.03);
  margin: 0.5em 0 3px 0;
}

table {
  th,
  td {
    @include line-large-height;
    padding: 0 4px;
    border: 1px solid $graph-outer-border-color;
  }

  th {
    @include font-medium;
    white-space: inherit;
  }

  td:first-of-type {
    width: 1px;
  }

  th:nth-of-type(5),
  td:nth-of-type(5),
  th:nth-of-type(6),
  td:nth-of-type(6) {
    text-align: right;
  }

  .error {
    cursor: help;
    color: $light-text-color;
  }

  .item-action::v-deep {
    padding: 0;

    .dropdown {
      display: block;

      .dropdown-button {
        color: $text-color;
        padding: 5px;
        background: transparent;
        border: none;
        width: 100%;
        @include font-big-size;
        line-height: 1rem;
      }
    }
  }
}

menu.pagination {
  @include font-tabular-numbers;
  margin: 0.75em 0 0;
  color: $light-text-color;
}
</style>
