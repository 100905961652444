<template>
  <div class="inline-editor">
    <div
      v-if="!isEditing"
      @click="editDescription"
      :class="{editable: !disabled}">
      <span>{{description || placeholder}}</span>
    </div>
    <div class="inline-editor-input" v-else>
      <input
        type="text"
        ref="input"
        v-model="description"
        @keydown.enter="submit"
        @input="onChange"
        @blur="dismiss"
        :placeholder="placeholder"
        :size="inputSize" />
      <button
        class="small-button"
        @click="submit" v-t="'common.ok'"></button>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'

export default {
  props: {
    disabled: Boolean,
    placeholder: {
      type: String,
      required: true
    },
    autoDismiss: {
      type: Boolean,
      default: true
    },
    value: String
  },
  data() {
    return {
      description: this.value,
      isMenuVisible: false,
      isEditing: false
    }
  },
  computed: {
    inputSize() {
      return Math.max(this.description.length, this.placeholder.length)
    }
  },
  methods: {
    editDescription() {
      if (this.disabled) {
        return
      }
      this.isEditing = true
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    },
    dismiss() {
      if (this.autoDismiss) {
        this.submit()
      }
    },
    submit() {
      this.isEditing = false
      this.$emit('submit')
    },
    onChange() {
      this.$emit('input', this.description)
      this.emitChange()
    },
    emitChange: debounce(function() {
      this.$emit('change', this.value)
    }, 300)
  },
  watch: {
    value() {
      this.description = this.value
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";

.inline-editor {
  display: inline-block;
  white-space: nowrap;
}

.editable {
  cursor: pointer;

  &::after {
    content: " ";
    display: inline-block;
    width: 0.8em;
    height: 0.8em;
    background: transparent url(~@/assets/edit.svg) no-repeat right center;
    background-size: contain;
    vertical-align: baseline;
    margin-left: 0.5em;
  }
}

.inline-editor-input {
  display: flex;

  input[type="text"] {
    font: inherit;
    color: inherit;
    outline: none;
    box-sizing: border-box;
    border: $border;
    border-radius: $border-radius;
    padding: 3.5px 5px;
    max-width: 90%;

    &:focus {
      border-color: $clearteal-color;
    }
  }

  .small-button {
    margin-left: 0.75em;
  }
}

// Blue skin
.inline-editor.blue {
  input[type="text"]:focus {
    border-color: lighten($graph-darkblue-color, 20);
  }

  .small-button {
    @extend .blue
  }
}
</style>
