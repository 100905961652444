<template>
  <div class="tab-container">
    <menu
      v-if="$$isAtLeastManager && $$hasPermission('gridEditor') && isCurrentGrid && !isLoadingGrid"
      class="float-right no-margin-top">
      <button
        class="secondary"
        v-t="'grid.update'"
        @click="updateGrid">
      </button>
    </menu>
    <h1 :data-id="displayedGrid && displayedGrid.id">
      {{getGridTitle(displayedGrid, $$companyName)}}
      <template v-if="!isLoadingGrid && displayedGrid">
        <GridVersionPicker
          :title="$t('grid.current.history')"
          :selectedGrid="displayedGrid"
          @change="setDisplayedGrid" />
        <dropdown :items="displayDropdownItems" @change="setDisplayMode">
          <span v-t="'common.options'"></span>
        </dropdown>
      </template>
    </h1>
    <p v-if="isLoadingGrid" class="no-margin-top light-text" v-t="'grid.current.loading'"></p>
    <div v-if="!isLoadingGrid && displayedGrid">
      <MarkdownText v-model="displayedGrid.description" />
      <p
        v-if="$route.params.isPublishSuccess"
        class="congratulation alert slow-fade-in"
        v-html="$t('grid.current.publishSuccess')"></p>
      <GridNavigation
        :components="gridComponents"
        :activeComponentSlug="activeComponentSlug"
        @select="selectComponent" />
      <div>
        <GridComponentDetail
          v-for="component in gridComponents"
          :key="component.id"
          :grid="displayedGrid"
          :component="component"
          :activeComponentSlug="activeComponentSlug"
          :showEmployees="isCurrentGrid && showEmployees"
          :showDescriptions="showDescriptions"
          :showSkills="showSkills"
          :showSkillsProgress="showSkillsProgress"
          :showValues="showValues"
          @select="selectComponent" />
      </div>
      <div class="grid-formula">
        <h2 v-t="'grid.formula'"></h2>
        <p v-t="'grid.current.formulaIntro'"></p>
        <GridFormula
          layout="horizontal"
          :grid="displayedGrid"
          @select="selectComponent" />
      </div>
    </div>
    <loading-view v-else-if="isLoadingGrid"></loading-view>
    <p v-else>
      <span v-t="{path: 'grid.noCurrentGrid', args: {companyName: $$companyName}}"></span>
      <menu class="hero" v-if="$$isAdmin">
        <router-link
          tag="button"
          class="primary"
          to="/grid/onboarding"
          v-t="'grid.createFirstGrid'">
        </router-link>
        <br><br>
      </menu>
    </p>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import { mapGetters } from 'vuex'
import { componentsForRemunerationType, getGridTitle, getParentComponent } from '@/utils/grid'
import { slugify } from '@/utils/string'
import animateScrollTo from 'animated-scroll-to'
import GridFormula from '@components/grid/viewer/GridFormula.vue'
import GridComponentDetail from '@components/grid/viewer/GridComponentDetail.vue'
import GridNavigation from '@components/grid/viewer/GridNavigation.vue'
import GridVersionPicker from '@components/grid/viewer/GridVersionPicker.vue'
import Dropdown from '@components/commons/Dropdown.vue'
import LoadingView from '@components/commons/LoadingView.vue'
import MarkdownText from '@components/commons/MarkdownText.vue'
import { componentHasSkills } from '@/utils/skills'

const CW_GRID_SHOW_DESCRIPTIONS = 'CW_GRID_SHOW_DESCRIPTIONS'
const CW_GRID_SHOW_EMPLOYEES = 'CW_GRID_SHOW_EMPLOYEES'
const CW_GRID_SHOW_SKILLS = 'CW_GRID_SHOW_SKILLS'
const CW_GRID_SHOW_SKILLS_PROGRESS = 'CW_GRID_SHOW_SKILLS_PROGRESS'
const CW_GRID_SHOW_VALUES = 'CW_GRID_SHOW_VALUES'

export default {
  components: {
    Dropdown,
    MarkdownText,
    GridComponentDetail,
    GridFormula,
    GridNavigation,
    GridVersionPicker,
    LoadingView
  },
  data() {
    return {
      activeComponentSlug: null,
      showDescriptions: !localStorage[CW_GRID_SHOW_DESCRIPTIONS] ||
        localStorage[CW_GRID_SHOW_DESCRIPTIONS] === 'true',
      showEmployees: !localStorage[CW_GRID_SHOW_EMPLOYEES] ||
        localStorage[CW_GRID_SHOW_EMPLOYEES] === 'true',
      showSkills: !localStorage[CW_GRID_SHOW_SKILLS] ||
        localStorage[CW_GRID_SHOW_SKILLS] === 'true',
      // showSkillsProgress is off by default
      showSkillsProgress: localStorage[CW_GRID_SHOW_SKILLS_PROGRESS] === 'true',
      showValues: !localStorage[CW_GRID_SHOW_VALUES] ||
        localStorage[CW_GRID_SHOW_VALUES] === 'true'
    }
  },
  methods: {
    getGridTitle,
    getComponentSlug(component) {
      return slugify(component.name)
    },
    setDisplayMode(_, value) {
      switch (value) {
        case 'descriptions':
          this.showDescriptions = !this.showDescriptions
          localStorage[CW_GRID_SHOW_DESCRIPTIONS] = this.showDescriptions
          break
        case 'employees':
          this.showEmployees = !this.showEmployees
          localStorage[CW_GRID_SHOW_EMPLOYEES] = this.showEmployees
          break
        case 'skills':
          this.showSkills = !this.showSkills
          localStorage[CW_GRID_SHOW_SKILLS] = this.showSkills
          break
        case 'skillsProgress':
          this.showSkillsProgress = !this.showSkillsProgress
          localStorage[CW_GRID_SHOW_SKILLS_PROGRESS] = this.showSkillsProgress
          break
        case 'values':
          this.showValues = !this.showValues
          localStorage[CW_GRID_SHOW_VALUES] = this.showValues
          break
      }
    },
    setDisplayedGrid(gridId) {
      this.activeComponentSlug = null
      this.$store.dispatch('currentGrid/getDisplayedGrid', gridId)
    },
    selectComponent(component) {
      if (component) {
        const parentComponent = getParentComponent(component, this.displayedGrid)
        if (parentComponent) {
          const slug = this.getComponentSlug(parentComponent)
          const anchor = '#' + slug
          animateScrollTo(this.$el.querySelector(anchor), {
            minDuration: 0,
            maxDuration: 1000
          }).then(() => {
            setTimeout(() => {
              this.$router.replace(anchor).catch(_ => { })
              this.activeComponentSlug = slug
            }, 110)
          })
        }
      }
      else {
        animateScrollTo(0)
        history.replaceState(null, null, ' ')
      }
    },
    onScroll: debounce(function() {
      let lastVisible
      if (window.scrollY + window.innerHeight > document.body.scrollHeight) {
        lastVisible = this.componentAnchors[0]
      }
      else {
        lastVisible = this.componentAnchors.find(anchor => {
          const rect = anchor.node.getBoundingClientRect()
          return rect.top <= 5
        })
      }
      this.activeComponentSlug = lastVisible && lastVisible.anchor
    }, 100, { maxWait: 100, leading: true, trailing: true }),
    async updateGrid(event) {
      if (event.altKey) {
        await this.$store.dispatch('currentGrid/updateGrid')
      }
      this.$router.push({ name: 'gridEditor' })
    }
  },
  computed: {
    ...mapGetters({
      displayedGrid: 'currentGrid/getDisplayedGrid',
      currentGrid: 'currentGrid/getCurrentGrid',
      isLoadingGrid: 'currentGrid/isLoadingGrid',
      isOnboarding: 'onboarding/isActive'
    }),
    isCurrentGrid() {
      return this.currentGrid && this.displayedGrid.id === this.currentGrid.id
    },
    gridComponents() {
      return this.displayedGrid ? componentsForRemunerationType(this.displayedGrid.components, 'salary') : []
    },
    componentAnchors() {
      return this.gridComponents.map(component => {
        const anchor = this.getComponentSlug(component)
        const node = this.$el.querySelector('#' + anchor)
        return { anchor, node }
      }).reverse()
    },
    displayDropdownItems() {
      const hasSkills = this.gridComponents.find(c => componentHasSkills(c, true))
      return [{
        name: this.$t('grid.current.title'),
        items: [{
          name: this.$t('grid.current.showDescriptions'),
          value: 'descriptions',
          selected: this.showDescriptions
        }, {
          name: this.$t('grid.current.showSkills'),
          value: 'skills',
          selected: this.showSkills,
          hidden: !hasSkills
        }, {
          name: this.$t('grid.current.showSkillsProgress'),
          value: 'skillsProgress',
          selected: this.showSkillsProgress,
          hidden: !hasSkills || !this.$$hasOpenAccess
        }, {
          name: this.$t('grid.current.showEmployees'),
          value: 'employees',
          selected: this.showEmployees,
          hidden: !this.isCurrentGrid || !this.$$hasOpenAccess
        }, {
          name: this.$t('grid.current.showValues'),
          value: 'values',
          selected: this.showValues
        }]
      }]
    }
  },
  mounted() {
    if (this.displayDropdownItems[0].items.find(i => i.value === 'employees').hidden) {
      this.showEmployees = false
    }
    if (this.displayDropdownItems[0].items.find(i => i.value === 'skillsProgress').hidden) {
      this.showSkillsProgress = false
    }
    if (this.$route.hash) {
      window.location = this.$route.hash
    }
    window.addEventListener('scroll', this.onScroll)
    this.onScroll()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/alert.scss";
@import "./src/styles/animation.scss";
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";

.tab-container {
  @include tab-container;
  padding-bottom: 0;
}

h1 {
  display: inline-block;
  margin-bottom: 0.25em;
  height: 32px;
}

h2 {
  margin-bottom: 0;
}

.grid-formula {
  padding: 1.4em 0;
}

.dropdown {
  @include font-regular;
  color: $text-color;
  margin-left: 0.25em;
  vertical-align: 1.5px;
}
</style>
