<template>
  <div class="employees-table">
    <div v-if="$$isManager" class="filtered-employees" v-t="'employees.summary.customTeam'"></div>
    <div v-if="limitedEmployees.length">
      <slot name="intro" />
      <div class="employee employee-header" v-if="showSeniority || infoSlotTitle">
        <div class="columns">
          <div class="column-name-thumbnail"></div>
          <div class="column-name"></div>
          <div v-if="infoSlotTitle" class="column-info-slot">{{infoSlotTitle}}</div>
        </div>
      </div>
      <template
        v-for="(employee, i) in limitedEmployees">
        <div
          v-if="shouldDisplayGroups && (!i || employee.group !== limitedEmployees[i - 1].group)"
          :key="[employee.id, '-group'].join()"
          class="employee-group">
          <div class="employee-group-label" :class="{short: infoSlotTitle}">
            {{formatGroup(employee.group)}}
          </div>
          <div>
            <slot name="group" :employees="getGroupEmployees(employee.group)" />
          </div>
        </div>
        <div
          :key="employee.id"
          class="employee"
          :class="{
            clickable: !hasActionSlot,
            flash: debouncedHighlightedEmployeeId === employee.id
          }"
          :data-id="employee.id"
          @click="!hasActionSlot && openEmployee(employee)">
          <div class="columns">
            <div
              class="column-name-thumbnail"
              @click.stop="openEmployee(employee)">
              <NameThumbnail :employee="employee" inline=true />
            </div>
            <div
              class="column-name"
              @click.stop="openEmployee(employee)">
              <h2>{{employee.fullName}}</h2>
              <div v-if="employee.jobType" class="job-type light-text">
                {{employee.jobType}}
                <span
                  v-if="showStatusBadge && employee.status !== 'employee'"
                  class="status-badge"
                  v-t="`settings.settingsCompanyAccessRights.statuses.${employee.status}`"></span>
              </div>
              <div v-if="showSeniority" class="seniority light-text">
                <template v-if="employee.isAlumni">
                  <strong>{{ $t('employees.alumnis.name') }}</strong>
                </template>
                <template v-else-if="employee.isExternal">
                  <strong>{{ $t('employees.externals.name') }}</strong>
                </template>
                <template v-else-if="employee.seniority > 0">
                  {{employee.seniority | formatDiff(2)}}
                </template>
                <template v-else-if="employee.seniority < 0">
                  <span v-t="'employees.employee.joinIn'"></span>
                  {{ employee.seniority | formatDiff(1) }}
                </template>
              </div>
            </div>
            <div class="column-info-slot">
              <slot name="info" :employee="employee" />
            </div>
            <div class="column-action-slot" v-if="hasActionSlot" @click.stop>
              <slot name="action" :employee="employee" />
            </div>
          </div>
        </div>
      </template>
      <div class="employee clickable" v-if="shouldDisplayPagination" @click="showMore">
        <div class="columns">
          <div class="column-toggle-limit">
            <span v-if="currentLimit < sortedEmployees.length" v-t="'common.showMore'"></span>
            <multiselect
              class="multiselect--above"
              @click.native.stop.prevent=""
              :value="currentLimit"
              :options="pagination"
              :searchable="false"
              :allowEmpty="false"
              :showLabels="false"
              :customLabel="option => option === sortedEmployees.length ? $t('common.all') : option"
              @input="setCurrentLimit">
              <template slot="singleLabel" slot-scope="{option}">{{`${option} / ${sortedEmployees.length}`}}</template>
            </multiselect>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getField } from '@/utils/employee'
import NameThumbnail from '@components/commons/NameThumbnail'
import { fullDiffRaw } from '@/utils/date'
import { buildSearchTerms, filterByQuery, sortByKeys } from '@/utils/string'
import { getSessionItem, setSessionItem } from '@/utils/storage'
import snakeCase from 'lodash.snakecase'

function buildSessionKey(key) {
  return key ? snakeCase(`CW_${key}_LIMIT`).toUpperCase() : ''
}

export default {
  components: {
    NameThumbnail
  },
  props: {
    // When passing employees, notice that most properties will be removed from performance reasons.
    // To preserve specific properties, group them in one `props` property.
    // See enrichedEmployees below from more details.
    employees: {
      type: Array
    },
    fieldOnly: {
      type: Boolean
    },
    groupKey: {
      type: String,
      default: 'team'
    },
    highlightedEmployeeId: {
      type: String
    },
    infoSlotTitle: {
      type: String
    },
    limit: {
      type: Number,
      default: 5
    },
    name: {
      type: String
    },
    preservedProps: {
      type: Array
    },
    searchQuery: {
      type: String
    },
    showSeniority: {
      type: Boolean
    },
    sortKey: {
      type: String,
      default: 'firstName'
    },
    sortOrder: {
      type: Number,
      default: 1
    },
    showStatusBadge: {
      type: Boolean
    }
  },
  data() {
    return {
      debouncedHighlightedEmployeeId: null,
      currentLimit: getSessionItem(buildSessionKey(this.name), this.limit)
    }
  },
  computed: {
    hasGroupSlot() {
      return !!this.$scopedSlots.group
    },
    hasActionSlot() {
      return !!this.$scopedSlots.action
    },
    enrichedEmployees() {
      const { fieldOnly } = this
      return this.employees.map(employee => {
        const { firstName, id, isAlumni, isExternal, lastName, status, hasAvatar } = employee
        const seniority = fullDiffRaw(employee.arrivalDate, employee.departureDate)
        const wageDetails = employee.wageDetails || getField(employee, 'currentWageDetails', { store: this.$store, fieldOnly })
        const jobType = getField(employee, 'jobType', { store: this.$store, fieldOnly })
        const group = this.groupKey === 'team' ? getField(employee, 'team') : null
        const fullName = getField(employee, 'fullName', { inverted: this.sortKey === 'fullName', fieldOnly })
        const preservedProps = (this.preservedProps || []).reduce((memo, prop) => {
          memo[prop] = employee[prop]
          return memo
        }, {})

        return {
          firstName,
          fullName,
          group,
          hasAvatar,
          id,
          isAlumni,
          isExternal,
          jobType,
          lastName,
          seniority,
          status,
          wageDetails,
          ...preservedProps,
          _searchKey: buildSearchTerms(
            employee.firstName,
            employee.lastName,
            group,
            jobType
          )
        }
      })
    },
    filteredEmployees() {
      return filterByQuery(this.enrichedEmployees, this.searchQuery, '_searchKey')
    },
    sortedEmployees() {
      const sortedEmployees = sortByKeys(this.filteredEmployees, this.sortKey, this.sortOrder, 'group')
      this.$emit('count', sortedEmployees.length)
      return sortedEmployees
    },
    shouldDisplayGroups() {
      return !!this.sortedEmployees.find(({ group }) => group) || this.hasGroupSlot
    },
    limitedEmployees() {
      if (this.currentLimit) {
        return this.sortedEmployees.slice(0, this.currentLimit)
      }
      else {
        return this.sortedEmployees
      }
    },
    shouldDisplayPagination() {
      return this.limit && this.sortedEmployees.length > this.limit
    },
    pagination() {
      return [...new Set([+this.limit, 5, 10, 20, 50, 100, 200, 500, this.sortedEmployees.length])]
        .filter(i => i <= this.sortedEmployees.length)
        .sort((a, b) => (a - b))
    }
  },
  watch: {
    highlightedEmployeeId: 'setDebouncedHighlightedEmployeeId'
  },
  methods: {
    openFirst() {
      if (this.limitedEmployees.length === 1) {
        this.$router.push({ name: 'employee', params: { id: this.limitedEmployees[0].id } })
      }
    },
    openEmployee({ id }) {
      this.$router.push({ name: 'employee', params: { id } })
    },
    setDebouncedHighlightedEmployeeId() {
      if (this.highlightedEmployeeId) {
        setTimeout(() => {
          this.debouncedHighlightedEmployeeId = this.highlightedEmployeeId
        }, 300)
      }
    },
    formatGroup(group) {
      if (this.groupKey === 'team') {
        return group ? `${this.$t('employees.group.team')} ${group}` : this.$t('employees.group.noTeam')
      }
      else {
        return group
      }
    },
    getGroupEmployees(group) {
      return this.sortedEmployees.filter(e => e.group === group)
    },
    showMore() {
      this.setCurrentLimit(this.currentLimit + this.limit)
    },
    setCurrentLimit(value) {
      this.currentLimit = Math.min(value, this.sortedEmployees.length)
      this.$parent.$emit('resize')
      if (this.name) {
        setSessionItem(buildSessionKey(this.name), this.currentLimit)
      }
    }
  },
  mounted() {
    this.setDebouncedHighlightedEmployeeId()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/block.scss";
@import "./src/styles/table.scss";

.filtered-employees {
  @include font-small-size;
  color: $light-text-color;
  margin: 0.5em 1em 0.3em;
}

.employee {
  display: block;

  &:not(:first-of-type)::before {
    content: " ";
    display: block;
    border-bottom: 1px solid $graph-inner-border-color;
    border-image: linear-gradient(
        90deg,
        white 0%,
        $graph-inner-border-color 7%,
        $graph-inner-border-color 93%,
        white 100%
      )
      1;
    border-image-width: 0 0 1px;
    margin: 0 6px;
  }

  &.dimmed {
    color: $light-text-color;
  }

  &.alumni {
    color: $light-text-color;

    h2::after {
      content: "🚶";
    }

    .badge {
      background: $background-color;
      color: inherit;
    }
  }

  &.clickable {
    cursor: pointer;
  }

  &.clickable:hover {
    .columns {
      background: $graph-lightblue-color;
    }

    .status-badge {
      background: darken($graph-lightblue-color, 5);
    }

    .thumbnail::v-deep {
      filter: brightness(0.95);
    }

    .inline-wage::v-deep {
      .component {
        background: darken($graph-lightblue-color, 5);
      }

      .badge {
        background: darken($lightteal-color, 5);
      }
    }
  }
}

.employee-header {
  @include font-small-size;
  @include font-semibold;
  cursor: default;

  & + .employee-group {
    margin-top: -30px;
  }
}

.employee-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include font-small-size;
  @include font-semibold;
  margin: 8px 0 0;
  padding: 6px 12px;
  border-radius: $border-radius;
  cursor: default;

  &:first-of-type {
    margin-top: 0;
  }

  .employee-group-label.short {
    max-width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.columns {
  display: flex;
  height: 100%;
  align-items: center;
  border-radius: $border-radius;
  padding: 8px 12px;
}

.column-name-thumbnail {
  width: 48px;
  cursor: pointer;
}

.column-name {
  width: 180px;
  cursor: pointer;

  h2 {
    @include font-normal-size;
    margin-bottom: -2px;
  }

  .job-type,
  .seniority {
    @include font-small-size;
    margin-top: 3px;
  }

  .status-badge {
    @include font-smaller-size;
    color: $text-color;
    background: $graph-lightblue-color;
    padding: 2px 4px;
    border-radius: $border-radius;
    margin-left: 3px;
  }
}

.column-seniority {
  width: 130px;
  text-align: center;

  * {
    @include line-regular-height;
  }

  .small {
    @include font-smaller-size;
    color: $light-text-color;
  }
}

.column-info-slot {
  flex-grow: 2;
}

.column-action-slot {
  white-space: nowrap;
  padding-left: 12px;
  text-align: center;
  align-self: stretch;
  display: flex;
  align-items: center;
}

.column-toggle-limit {
  text-align: center;
  flex-grow: 1;
  margin: -4px;
  user-select: none;

  span {
    @include font-bold;
    @include font-smaller-size;
    color: $clearteal-color;
    text-transform: uppercase;
    line-height: 2em;
  }

  span + .multiselect {
    margin-right: -75px;
    margin-left: 0.5em;
  }

  .multiselect::v-deep {
    display: inline-block;
    width: auto;

    .multiselect__single {
      @include font-bold;
      font-size: 0.8rem !important;
      color: $gray-color;
    }

    .multiselect__select {
      height: 32px;
      right: 3px;

      &::before {
        border-color: $gray-color transparent transparent;
      }
    }

    .multiselect__tags {
      padding: 6px 30px 0 4px;
    }

    &:not(:hover):not(.multiselect--active) {
    .multiselect__single,
    .multiselect__tags {
      border-color: transparent !important;
      border-radius: 0 !important;
      background: transparent !important;
    }
  }
  }
}
</style>
