<template>
  <div class="steps">
    <div
      class="step"
      v-for="(step, i) in getVisibleSteps(review.steps)"
      :key="step.id">
      <h3>Étape {{i + 1}}</h3>
      <p>
        {{step.name}}
        <span v-if="isSummary">({{step.endDate | fullFormattedDate}})</span>
      </p>
      <div class="period" v-if="!isSummary">
        <div>
          <div
            class="input-label"
            v-t="'variable.periodForm.startDate'">
          </div>
          <DateInput
            v-model="step.startDate"
            @input="onStepChange(step)"
            :disabled="isDisabled"
            :placeholder="$t('common.datePlaceholder')" />
        </div>
        <div>
          <div
            class="input-label"
            v-t="'variable.periodForm.endDate'"></div>
          <DateInput
            v-model="step.endDate"
            @input="onStepChange(step)"
            :disabled="isDisabled"
            :placeholder="$t('common.datePlaceholder')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateInput from '@components/commons/DateInput.vue'
import { isReviewSettingsDisabled, getVisibleSteps } from '@/utils/reviews'

export default {
  components: {
    DateInput
  },
  data() {
    return {
    }
  },
  props: {
    review: {
      type: Object,
      default() {
        return {}
      }
    },
    isSummary: {
      type: Boolean
    }
  },
  computed: {
    isDisabled() {
      return isReviewSettingsDisabled(this.review)
    }
  },
  methods: {
    getVisibleSteps,
    onStepChange(step) {
      const reviewId = this.review.id
      this.$store.dispatch('reviews/updateStep', { reviewId, step })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/badge.scss";

.steps {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2em;
  align-items: flex-start;

  .step {
    @extend .badge-block;
    display: block;

    h3 {
      margin-top: 0.45em;
      margin-bottom: 0.1em;
    }

    p {
      padding: 0;
      margin: 0.5em 0;
    }

    .detail {
      @include font-small-size;
      margin-top: 0.5em;
      color: $light-text-color;
    }

    .period {
      padding: 5px 0;
      display: flex;
      gap: 1em;
    }
  }
}
</style>
