import { compile } from 'path-to-regexp'
import store from '@/store'
import i18n from '@/i18n'

function routeName(route) {
  const dynamicName = store.getters[route.meta.dynamicRouteGetter]
  return dynamicName || i18n.t(route.meta.routeName)
}

function getHomeRoute(route) {
  const company = store.getters['account/getCompany']
  return {
    name: company ? company.name : i18n.t('common.brand'),
    path: '/',
    isLogo: !company
  }
}

function getRouteName(route) {
  const routes = generateBreadcrumb(route)
  const lastRoutes = [routes[routes.length - 1].name]

  if (routes.length > 3) {
    lastRoutes.unshift(routes[routes.length - 2].name)
  }

  return lastRoutes.join('  ›  ')
}

export function setAppTitle(route) {
  const routeName = getRouteName(route)
  document.title = [i18n.t('common.brand'), routeName].join('  ›  ')
}

export function generateBreadcrumb(route) {
  return [getHomeRoute(route)].concat(
    route.matched
      .filter(matched => !matched.meta.hideInBreadcrumb)
      .map(matched => ({
        path: compile(matched.path)(route.params),
        name: routeName(matched)
      }))
  )
}

let redirectRoute = null
export function saveRedirectRoute(to) {
  redirectRoute = to
  return true
}

export function loadRedirectRouteOnce() {
  const to = redirectRoute
  redirectRoute = null
  return to
}

/*
  HashActions allows putting links in Locale app
  Usage:
    <a href="#contact"></a>   <--  Open the Beacon
    <a href="#employees"></a> <--  Navigate to the Employees page (route name defined in router)
*/
export function nextWithHashActions(router, callBeacon, to, from, next) {
  switch (to.hash) {
    case '':
      next()
      break
    case '#contact':
      if (to.params.prefill) {
        callBeacon('prefill', to.params.prefill)
      }
      callBeacon('open')
      callBeacon('navigate', '/ask/message/')
      next(false)
      break
    case '#dashboard':
      next('/dashboard')
      break
    case '#grid':
      next('/grid')
      break
    case '#recruitment':
      next('/recruitment')
      break
    case '#review':
      next({ name: 'reviewEmployees', params: { redirectTo: from.path } })
      break
    case '#help':
      callBeacon('open')
      next(false)
      break
    case '#variable':
      next('/variable')
      break
    case '#benefits':
      next('/benefits')
      break
    default:
      const name = to.hash.substr(1)
      const result = router.resolve({ name })
      if (name && result.resolved.matched.length > 0) {
        next({ name })
      }
      else if (to.hash.indexOf('#employee:') === 0) {
        const id = to.hash.replace('#employee:', '')
        next({ name: 'employee', params: { id } })
      }
      else if (to.hash.indexOf('#help:') === 0) {
        const articleId = to.hash.replace('#help:', '')
        callBeacon('article', articleId)
        next(false)
      }
      else {
        next()
      }
  }
}
