<template>
  <div>
    <template v-if="isTabsVisible">
      <progress-tab-bar
        v-if="isOnboardingVisible"
        :tabs="tabs.map(t => t.name)"
        :activeTab="activeTabName"
        :progressIndex="onboardingProgress"
        @tabSelected="tabSelected">
      </progress-tab-bar>
      <tab-bar
        v-else
        :tabs="tabs.map(t => t.name)"
        :activeTab="activeTabName"
        @tabSelected="tabSelected">
      </tab-bar>
    </template>
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProgressTabBar from '@components/commons/ProgressTabBar.vue'
import TabBar from '@components/commons/TabBar.vue'

export default {
  computed: {
    ...mapGetters({
      isOnboarding: 'onboarding/isActive',
      rawOnboardingProgress: 'onboarding/getProgressIndex'
    }),
    onboardingProgress() {
      return this.rawOnboardingProgress - (this.$$isManager ? 1 : 0)
    },
    isOnboardingVisible() {
      return this.isOnboarding && this.$$hasOpenAccess
    },
    isTabsVisible() {
      return this.activeTabName !== 'GridExport'
    },
    tabs() {
      let tabs = []

      if (!this.isOnboardingVisible) {
        tabs = [
          {
            name: this.$t('grid.current.title'),
            path: '/grid'
          },
          {
            name: this.$t('grid.simulation.title'),
            path: '/grid/simulation',
            hidden: !this.$$hasPermission('gridSimulation')
          },
          {
            name: this.$t('grid.editor.shortTitle'),
            path: '/grid/editor',
            hidden: !this.$$isAtLeastManager || !this.$$hasPermission('gridEditor')
          }
        ]
      }
      else {
        tabs = [
          {
            name: this.$t('grid.onboarding.intro.title'),
            path: '/grid/onboarding',
            hidden: !this.$$isAdmin
          },
          {
            name: this.$t('grid.onboarding.skills.title'),
            path: '/grid/onboarding/skills'
          },
          {
            name: this.$t('grid.onboarding.qualification.title'),
            path: '/grid/onboarding/qualification'
          },
          {
            name: this.$t('grid.onboarding.generate.title'),
            path: '/grid/onboarding/generate',
            hidden: !this.$$hasPermission('gridValuationEditor')
          }
        ]
      }
      return tabs.filter(tab => !tab.hidden)
    },
    activeTabName() {
      const activeTab = this.getActiveTab(this.$route.path)
      return activeTab && activeTab.name
    }
  },
  methods: {
    tabSelected(name) {
      const tab = this.tabs.find(t => t.name === name)
      this.$router.push(tab.path, () => {})
    },
    isMatchingTab(tab, path) {
      return [tab.path, tab.path + '/'].includes(path)
    },
    getActiveTab(path) {
      let tab = this.tabs.find(tab => this.isMatchingTab(tab, path))

      if (!tab) {
        if (path === '/grid/export') {
          tab = {
            name: 'GridExport',
            path: path
          }
        }
        else if (path.includes('/grid/sandbox')) {
          // Select sandbox tab for /grid/sandbox/*
          tab = {
            name: 'Sandbox',
            path: path
          }
        }
        else if (path.includes('/grid/editor')) {
          // Select editor tab for /grid/editor/*
          tab = {
            name: this.$t('grid.editor.shortTitle'),
            path: path
          }
        }
        else if (this.isOnboardingVisible) {
          // Select current onboarding tab
          tab = this.tabs[Math.min(this.onboardingProgress, this.tabs.length - 1)]
        }
        else {
          // Select sandbox tab
          tab = this.tabs[0]
        }
      }

      return tab
    }
  },
  created() {
    const path = this.$route.path
    // Select the right tab
    const activeTab = this.getActiveTab(path)
    if (activeTab && !this.isMatchingTab(activeTab, path)) {
      this.$router.replace(activeTab.path)
    }
  },
  beforeRouteUpdate(to, from, next) {
    const path = to.path
    const activeTab = this.getActiveTab(path)
    if (activeTab && !this.isMatchingTab(activeTab, path)) {
      next(activeTab.path)
    }
    else {
      next()
    }
  },
  components: {
    ProgressTabBar,
    TabBar
  }
}
</script>

<style lang="sass" scoped>
</style>
