<template>
  <div class="search-input">
    <input
      type="search"
      spellcheck="false"
      @input="onSearchQueryChange"
      :placeholder="$t('employees.search')" />
    <label v-if="searchQuery && searchCountLabel">
      {{searchCountLabel}}
    </label>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'

export default {
  props: {
    value: String,
    searchCount: Number
  },
  data() {
    return {
      searchQuery: this.value || ''
    }
  },
  computed: {
    searchCountLabel() {
      if (this.searchCount !== null) {
        return this.$tc('employees.employees.searchCount', this.searchCount, { count: this.searchCount })
      }
    }
  },
  methods: {
    onSearchQueryChange: debounce(function(event) {
      this.$emit('input', event.target.value)
    }, 300)
  },
  watch: {
    value() {
      if (this.searchQuery !== this.value) {
        this.searchQuery = this.value
      }
    }
  }

}
</script>

<style lang="scss" scoped>
@import "./src/styles/form.scss";

.search-input {
  display: inline-block;
  margin-left: 0.75rem;
}

input[type="search"] {
  @include font-small-size;
  display: inline-block;
  width: 110px;
  transition: width 200ms ease-out;

  &:focus {
    width: 150px;
  }
}

label {
  @include font-small-size;
  @include font-regular;
  margin-left: 0.5em;
  color: $light-text-color;
}

// Large skin
.search-input.large input[type="search"] {
  &,
  &:focus {
    width: 200px;
  }
}

/// Blue skin
.search-input.blue input[type="search"]:focus {
  border-color: lighten($graph-darkblue-color, 20);
}
</style>
