<template>
  <div class="variable-plan-viewer">
    <h1>{{variablePlan.name}}</h1>
    <MarkdownText v-model="variablePlan.description" />
    <p v-t="{path: 'employees.employee.variable.intro', args: {period: formatPeriod(variablePlan, false, this.$t('common.at')) }}"></p>
    <VariablePlanEmployee
      :enrichedConfig="enrichedConfig"
      :variablePlan="variablePlan"
      @input="onVariableWageChange" />
    <p>
      <span
        v-if="variablePlan.status !== 'closed'"
        v-t="'employees.employee.variable.results.' + ($$hasUpdatePermission(employee) ? 'ongoingManagerNotice' : 'ongoingNotice')"></span>
      &nbsp;
      <span
        v-html="$t('employees.employee.variable.results.updatedAt', {updatedAt})"></span>
    </p>
    <router-link
      v-if="$$hasUpdatePermission(employee)"
      tag="button"
      class="secondary edit-button"
      :to="{name: 'variablePlan', params: { id: variablePlan.id }}"
      v-t="'employees.employee.variable.updateVariablePlan'">
    </router-link>
    <h2 v-t="'employees.employee.variable.formulaIntro'"></h2>
    <VariablePlanEditor
      ref="variablePlanEditor"
      v-model="variablePlan"
      @enriched="onEnrichedConfig" />
  </div>
</template>

<script>
import MarkdownText from '@components/commons/MarkdownText'
import VariablePlanEditor from '@components/variable/VariablePlanEditor.vue'
import VariablePlanEmployee from '@components/variable/VariablePlanEmployee.vue'
import { formatPeriod } from '@/utils/variable'

export default {
  components: {
    MarkdownText,
    VariablePlanEditor,
    VariablePlanEmployee
  },
  data() {
    return {
      enrichedConfig: null
    }
  },
  props: {
    employee: {
      type: Object
    },
    variableWage: {
      type: Object
    }
  },
  computed: {
    variablePlan() {
      const variableWages = [this.variableWage]
      const variablePlan = this.variableWage.variablePlan
      const { sharedIndicators } = variablePlan
      const { indicators } = this.variableWage
      // Fill simulation indicators with employee's ones so (s)he can play with them!
      const simulations = [{ id: 42, indicators: [...indicators, ...sharedIndicators] }]
      return { ...variablePlan, variableWages, simulations }
    },
    updatedAt() {
      const updatedAt = this.variablePlan.status !== 'closed' && this.variablePlan.dataFeeds && this.variablePlan.dataFeeds.length
        ? new Date()
        : this.variableWage.updatedAt
      return this.$options.filters.formatCalendar(updatedAt)
    }
  },
  methods: {
    formatPeriod,
    onEnrichedConfig(enrichedConfig) {
      this.enrichedConfig = enrichedConfig
    },
    onVariableWageChange(variableWage) {
      Object.assign(this.variableWage, variableWage)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/alert.scss";
@import "./src/styles/button.scss";

.variable-plan-viewer {
  width: $content-max-width;
  padding: 20px;
}

.edit-button {
  float: right;
  margin-top: 10px;
}

h1 {
  margin-bottom: 0.5rem;
}

.description {
  margin-bottom: -5px;
}

h2 {
  margin-top: 2rem;
}
</style>
