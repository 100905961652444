<template>
  <div>
    <div class="employee-detail" v-if="storedEmployee">
      <EmployeeDetail :employee="storedEmployee" :isUpdate="true" />
    </div>
    <template v-if="storedEmployee && storedEmployee.thirdPartyUsers">
      <div
        v-for="thirdPartyUser in storedEmployee.thirdPartyUsers"
        :key="thirdPartyUser.id"
        class="form employee-third-party-user light-text">
        <div class="preserve-lines">{{$t('employees.employee.thirdPartyUser.thirdPartyProfile', {serviceName: $t(`settings.settingsCompanySynchronization.identityProviders.${thirdPartyUser.identityProvider}`)})}}</div>
        <button
          class="secondary"
          v-t="{
          path: 'employees.employee.thirdPartyUser.openIn',
          args: {serviceName: $t(`settings.settingsCompanySynchronization.identityProviders.${thirdPartyUser.identityProvider}`)}}"
          @click="$$openUrl(thirdPartyUser.profileUrl)"></button>
      </div>
    </template>
    <div class="form" :class="{reveal: storedEmployee}">
      <h1 v-if="!storedEmployee" v-t="'employees.employee.form.addEmployee'"></h1>
      <div class="two-columns">
        <div>
          <h3 class="icon-personal" v-t="'employees.employee.form.personalInformations'"></h3>

          <EmployeeField
            v-for="(field, i) in getFields('name')"
            :key="field.id"
            :field="field"
            v-model="employeeModel[field.id]"
            :autofocus="i === 0"
            :isRequired="true"
            :isError="isError(field.id)" />

          <EmployeeField
            v-for="field in getFields('personal')"
            :key="field.id"
            :field="field"
            v-model="employeeModel[field.id]"
            :isError="isError(field.id)" />

          <hr class="hr">

          <h3 class="icon-team" v-t="'employees.employee.form.teamInformations'"></h3>

          <EmployeeField
            v-for="field in getFields('team', {exclude: 'manager', hidden: true})"
            :key="field.id"
            :field="field"
            v-model="employeeModel[field.id]"
            :isError="isError(field.id)" />

          <div class="input-label">
            <span v-t="'employees.employee.form.visibility'"></span>
          </div>
          <checkbox
            v-model="employeeModel.isExternal"
            @change="validate">
            <span v-t="'employees.employee.form.isExternal'"></span>
          </checkbox>
        </div>

        <div>
          <hr class="vr">
        </div>

        <div>
          <h3 class="icon-company" v-t="'employees.employee.form.companyInformations'"></h3>

          <EmployeeField
            v-for="field in getFields('company', {exclude: ['salary', 'contractualVariable']})"
            :key="field.id"
            :field="field"
            v-model="employeeModel[field.id]"
            :isError="isError(field.id)" />

          <template v-if="getFields('custom', {store: $store}).length">
            <hr class="hr">

            <h3 class="icon-custom" v-t="'employees.employee.form.customInformations'"></h3>

            <EmployeeField
              v-for="field in getFields('custom', {store: $store})"
              :key="field.id"
              :field="field"
              v-model="employeeModel.customFields[field.id]"
              :isError="isError(field.id)" />
          </template>
        </div>
      </div>

      <template v-if="!storedEmployee">
        <br><br>
        <h3 v-t="'employees.employee.setWage'"></h3>
        <wage-calculator
          v-model="employeeModel.currentWage"
          :grid="currentGrid"
          :showVariable="true">
        </wage-calculator>
      </template>

      <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>

      <menu>
        <template v-if="storedEmployee">
          <loading-button
            :disabled="isLoading || isDeleting"
            :loading="isDeleting"
            class="destructive"
            @click="remove">
            <span v-t="'common.remove'"></span>
          </loading-button>
        </template>
        <button
          :disabled="isLoading || isDeleting"
          class="secondary"
          @click="$router.go(-1)"
          v-t="'employees.employee.form.cancel'">
        </button>
        <loading-button
          class="primary"
          :disabled="isLoading || isDeleting"
          :loading="isLoading"
          @click="submit">
          <span>{{ action }}</span>
        </loading-button>
      </menu>
    </div>
  </div>
</template>

<script>
import Checkbox from '@components/commons/Checkbox.vue'
import EmployeeDetail from '@components/employees/EmployeeDetail.vue'
import EmployeeField from '@components/employees/EmployeeField.vue'
import WageCalculator from '@components/wage/WageCalculator.vue'

import { mapGetters } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import { dateValidator } from '@/utils/date'
import { getFields } from '@/utils/employee'
import cloneDeepWith from 'lodash.clonedeepwith'

export default {
  components: {
    Checkbox,
    EmployeeDetail,
    EmployeeField,
    WageCalculator
  },
  data() {
    return {
      employeeModel: null,
      isLoading: false,
      isDeleting: false,
      isOktaUserFieldsVisible: false,
      errorMessage: null
    }
  },
  computed: {
    ...mapGetters({
      currentGrid: 'currentGrid/getCurrentGrid',
      customFields: 'company/getCustomFields'
    }),
    storedEmployee() {
      const employeeId = this.$route.params.id
      if (employeeId) {
        return this.$store.getters['employees/getEmployee'](employeeId)
      }
    },
    action() {
      return this.$t('employees.employee.form.' + (this.storedEmployee ? 'update' : 'add'))
    }
  },
  methods: {
    getFields,
    initModel() {
      if (this.storedEmployee) {
        this.employeeModel = cloneDeepWith(this.storedEmployee)
      }
      else {
        this.employeeModel = cloneDeepWith(this.$store.state.employees.employeeModel)
      }
    },
    validate() {
      this.$v.$touch()
    },
    isError(property) {
      return !!this.$v.employeeModel[property] && this.$v.employeeModel[property].$error
    },
    getErrorClass(property) {
      return { 'input-error': this.$v.employeeModel[property] && this.$v.employeeModel[property].$error }
    },
    submit() {
      this.validate()

      if (!this.$v.$error) {
        this.isLoading = true

        this.createOrUpdate()
          .then(employee => {
            this.$router.push({ name: 'employee', params: { id: employee.id } })
          })
          .catch(error => {
            this.isLoading = false
            this.errorMessage = error
          })
      }
      else {
        window.scrollTo(0, 0)
      }
    },
    remove() {
      if (confirm(this.$t('employees.employee.form.removeConfirmation', { employeeName: this.employeeModel.firstName, companyName: this.$$companyName }))) {
        this.isDeleting = true
        this.$store.dispatch('employees/removeEmployee', this.employeeModel)
          .then(_ => {
            this.$router.push({ name: 'employees' })
          })
          .catch(error => {
            this.isDeleting = false
            this.errorMessage = error
          })
      }
    },
    createOrUpdate() {
      if (this.storedEmployee) {
        return this.$store.dispatch('employees/updateEmployee', this.employeeModel)
      }
      else {
        return this.$store.dispatch('employees/addEmployee', this.employeeModel).then(employee => {
          this.$store.dispatch('sandbox/updateSimilarSandboxWage', { employee, wage: employee.currentWage })
          return employee
        })
      }
    }
  },
  validations() {
    return {
      employeeModel: {
        firstName: { required },
        lastName: { required },
        email: { email },
        arrivalDate: this.storedEmployee ? { dateValidator } : {}
      }
    }
  },
  created() {
    this.initModel()
  },
  mounted() {
    if (this.storedEmployee) {
      this.$store.commit('employees/setCurrentEmployee', this.employeeModel)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/alert.scss";
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";

input,
select,
.employee-field {
  margin-bottom: 1rem;
}

.employee-detail,
.form {
  margin-bottom: 1.5em;
}

.form {
  @include container;
}

.employee-third-party-user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;

  button {
    white-space: nowrap;
  }
}

.checkbox {
  display: inline-block;
  margin: 0.5em;
}

.two-columns {
  display: grid;
  grid-template-columns: 1fr 8% 1fr;
}

.vr {
  width: 1px;
  height: 98%;
  background: $border-color;
  border: none;
  padding: 0;
  margin: 0 auto;
}

.hr {
  height: 1px;
  background: $border-color;
  border: none;
  padding: 0;
  margin: 1.5em auto;
}

.icon-personal,
.icon-company,
.icon-team,
.icon-custom {
  background: transparent none no-repeat left 3px;
  background-size: 16px;
  padding-left: 26px;

  &.icon-personal {
    background-image: url(~@/assets/settings-user.svg);
  }

  &.icon-company {
    background-image: url(~@/assets/settings-company.svg);
  }

  &.icon-team {
    background-image: url(~@/assets/settings-teams.svg);
  }

  &.icon-custom {
    background-image: url(~@/assets/settings-custom-fields.svg);
  }
}
</style>
