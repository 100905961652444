import { normalizeDate, parseDate, formatDate } from '@/utils/date'
import i18n from '@/i18n'
import { sum } from 'd3'
import orderBy from 'lodash.orderby'
import { buildSearchTerms, parseStringFloat, slugify } from '@/utils/string'
import { getField } from './employee'

function parseItemDate(config, item, errors) {
  const content = item.content || {}
  const dateKey = config.dateKey || 'date'
  const date = content[dateKey]
  if (date) {
    const parsedDate = parseDate(date, false)
    if (parsedDate.isValid()) {
      return parsedDate
    }
    else {
      errors.date = i18n.t('variable.dataFeed.dataFeedItem.key.date.invalid', { date })
      return date
    }
  }
  else {
    return parseDate(item.createdAt, false)
  }
}

function parseItemInvoice(config, item, _errors) {
  const content = item.content || {}
  const invoiceKey = config.invoiceKey || 'invoice'
  return content[invoiceKey] || i18n.t('variable.dataFeed.dataFeedItem.key.invoice.placeholder')
}

function parseItemTitle(config, item, _errors) {
  const content = item.content || {}
  const titleKey = config.titleKey || 'title'
  return content[titleKey] || i18n.t('variable.dataFeed.dataFeedItem.key.title.placeholder')
}

function parseItemValue(config, item, errors) {
  const content = item.content || {}
  const valueKey = config.valueKey || 'value'
  const value = content[valueKey]
  const parsedValue = parseStringFloat(value)
  if (!isNaN(parsedValue)) {
    return Math.round(parsedValue * 100) / 100
  }
  else {
    errors.value = i18n.t('variable.dataFeed.dataFeedItem.key.value.invalid', { value })
    return value
  }
}

// Return values: userId, -1 for error and 0 for missing
function parseItemEmployee(config, item, errors, employees) {
  const content = item.content || {}
  const employeeKey = config.employeeKey || 'employee'
  const employee = content[employeeKey]
  let userId = 0
  if (employee) {
    const slug = slugify(employee || '')
    const user = employees.find(e =>
      (getField(e, 'slug') === slug) || (getField(e, 'slug', { inverted: true }) === slug))
    if (user) {
      userId = user.id
    }
    else {
      errors.employee = i18n.t('variable.dataFeed.dataFeedItem.key.employee.invalid', { employee })
      userId = -1
    }
  }
  return { employee, userId }
}

function parseItemColumns(config, item, _errors) {
  const content = item.content || {}
  const columnKeys = config.columnKeys || []
  return columnKeys.map((_, i) => {
    return content.columns[i] || 0
  })
}

// Note that we check the existence of the isValid method, not its return value
function computeIsOngoingPeriod(config, date, errors) {
  if (!errors.date) {
    const normalizedDate = normalizeDate(date)
    return normalizedDate >= config.startDate && normalizedDate <= config.endDate
  }
  else {
    return false
  }
}

export function parseDataFeedItems(dataFeed, employees) {
  const config = dataFeed.config
  const items = dataFeed.dataFeedItems || []

  return orderBy(items.map(item => {
    const errors = {}
    const id = item.id
    const date = parseItemDate(config, item, errors)
    const formattedDate = formatDate(date, 'DD/MM/YY')
    const invoice = parseItemInvoice(config, item, errors)
    const title = parseItemTitle(config, item, errors)
    const value = parseItemValue(config, item, errors)
    const { employee, userId } = parseItemEmployee(config, item, errors, employees)
    const columns = parseItemColumns(config, item, errors)
    const isOngoingPeriod = computeIsOngoingPeriod(config, date, errors)
    const isValid = !Object.keys(errors).length
    const _searchKey = buildSearchTerms(formattedDate, invoice, title, employee, value)

    return {
      isValid,
      isOngoingPeriod,
      errors,
      id,
      formattedDate,
      date,
      invoice,
      title,
      employee,
      userId,
      value,
      columns,
      _searchKey
    }
  }), ['date'], ['desc'])
}

export function enrichDataFeed(dataFeed, variablePlan, employees) {
  // Enrich config
  dataFeed = { ...dataFeed }
  const config = dataFeed.config || {}
  config.startDate = variablePlan.startDate
  config.endDate = variablePlan.endDate
  dataFeed.config = config
  // Parse data feed items
  dataFeed.dataFeedItems = parseDataFeedItems(dataFeed, employees)
  return dataFeed
}

export function enrichDataFeeds(dataFeeds, variablePlan, employees) {
  return (dataFeeds || []).map(dataFeed => enrichDataFeed(dataFeed, variablePlan, employees))
}

export function getDataFeedIndicatorValue(enrichedDataFeed, employee, index, kind) {
  const isSum = kind === 'sum'
  let filteredDataFeedItems = enrichedDataFeed.dataFeedItems
    .filter(i => i.isValid && i.isOngoingPeriod)
  if (!isSum) {
    filteredDataFeedItems = filteredDataFeedItems.filter(i => (!employee || !i.userId || i.userId === employee.id))
  }
  const total = sum(filteredDataFeedItems.map(i => index !== undefined ? i.columns[index] : i.value))
  return !isNaN(total) ? total : 0
}
