<template>
  <div class="wrapper">
    <div class="svg-container">
      <GraphTooltip :employees="employees" :tooltip="tooltip" />
      <svg :height="viewport.height" ref="svgNode"></svg>
      <GraphLegend
        id="salaries"
        :graph="graph"
        v-model="truncateOffset"
        @input="render" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import * as d3 from 'd3'
import debounce from 'lodash.debounce'
import flatten from 'lodash.flatten'
import { getGroupedEmployeesAndScopeValues } from '@/utils/statistics'
import {
  generateAbscissaLabels,
  generateOrdinateLabels,
  generateBeeswarmCircles,
  renderInteractiveLayer,
  truncateModel,
  wrapLabels,
  formatLabel,
  renderSelectionLayer
} from '@/utils/graph'
import GraphLegend from '@components/graph/GraphLegend.vue'
import GraphTooltip from '@components/graph/GraphTooltip.vue'

const RANDOM = d3.randomLcg(42)

function formatAbscissaLabel(d) {
  return `${d.count}\n${d.label}`
}

function initGraph(svg, viewport) {
  const node = {}

  node.svg = d3.select(svg)
  node.viewport = node.svg.append('g')
    .attr('class', 'viewport')
  node.background = node.viewport.append('rect')
    .attr('class', 'background')
  node.abscissa = node.viewport.append('g')
    .attr('class', 'axis abscissa')
  node.ordinate = node.viewport.append('g')
    .attr('class', 'axis ordinate')
  node.selection = node.viewport.append('g')
    .attr('class', 'selection')
  node.quantiles = node.viewport.append('g')
    .attr('class', 'quantiles')
  node.groups = node.viewport.append('g')
    .attr('class', 'groups')
  node.ordinateLabels = node.viewport.append('g')
    .attr('class', 'axis ordinate')
  node.quantilesLabels = node.viewport.append('g')
    .attr('class', 'quantiles-labels')
  node.x = d3.scaleBand()
  node.y = d3.scaleLinear()

  return {
    node: node,
    viewport: viewport,
    data: [],
    truncation: {
      total: 0
    }
  }
}

function generateComboGraphModel(graph, employees, scope) {
  const { groupedEmployees, scopeValues } = getGroupedEmployeesAndScopeValues(employees, scope)

  graph.data = scopeValues.map(x => ({
    x: x,
    values: groupedEmployees[x].map(e => ({
      id: e.id,
      x: x,
      xOffset: e[scope + 'Progress'] || 0,
      y: e.salary,
      r: 0.8,
      class: e.gender,
      route: e.route
    }))
  }))
  graph.truncation.total = graph.data.length

  return graph
}

function renderGraph(graph, component) {
  if (!graph.node.svg.node()) {
    return
  }
  const domain = graph.data.map(d => d.x)
  const viewport = graph.viewport
  const node = graph.node

  // Viewport
  viewport.width = graph.node.svg.node().getBoundingClientRect().width
  viewport.innerWidth = viewport.width - viewport.padding.left - viewport.padding.right
  viewport.innerHeight = viewport.height - viewport.padding.top - viewport.padding.bottom

  // Graph Axes
  graph.axes = {
    abscissa: {
      type: 'discrete',
      labels: 'belowAxis',
      axis: node.x
    },
    ordinate: {
      type: 'continuous',
      labels: 'beforeAxis',
      axis: node.y,
      format: '$~s'
    }
  }
  node.x.domain(domain)
    .rangeRound([0, viewport.innerWidth])
    .padding([0.15])
  if (node.x.bandwidth() > 300) {
    node.x.padding([0.50])
  }
  else if (node.x.bandwidth() > 150) {
    node.x.padding([0.40])
  }
  node.y.domain([
    d3.max(graph.data, d => d3.max(d.values, d => d.y) * 1.1),
    d3.min(graph.data, d => d3.min(d.values, d => d.y) * 0.8)
  ]).rangeRound([0, viewport.innerHeight])

  // Viewport background
  node.viewport.attr('transform',
    `translate(${viewport.padding.left},${viewport.padding.top})`)
  node.background.attr('width', viewport.innerWidth)
    .attr('height', viewport.innerHeight)

  // Axes Labels
  const abscissaLabelsData = generateAbscissaLabels(graph)
  const abscissaLabels = node.abscissa.selectAll('.label')
    .data(abscissaLabelsData, formatAbscissaLabel)
  abscissaLabels
    .enter().append('text')
    .attr('class', 'label wrap')
    .merge(abscissaLabels)
    .attr('x', d => d.x)
    .attr('y', d => d.y)
    .text(formatAbscissaLabel)
  abscissaLabels.exit()
    .remove()

  const abscissaTicks = node.abscissa.selectAll('.tick')
    .data(abscissaLabelsData, formatAbscissaLabel)
  abscissaTicks
    .enter().append('line')
    .attr('class', 'tick')
    .attr('x1', d => d.x)
    .attr('y1', d => 0)
    .attr('x2', d => d.x)
    .attr('y2', d => viewport.innerHeight)
    .merge(abscissaTicks)
    .transition()
    .attr('x1', d => d.x)
    .attr('y1', d => 0)
    .attr('x2', d => d.x)
    .attr('y2', d => viewport.innerHeight)
  abscissaTicks.exit()
    .remove()

  const ordinateLabelsData = generateOrdinateLabels(graph)
  const ordinateLabels = node.ordinateLabels.selectAll('.label')
    .data(ordinateLabelsData, d => d.id)
  ordinateLabels
    .enter().append('text')
    .attr('class', 'label')
    .attr('x', d => d.x)
    .attr('y', d => d.y)
    .merge(ordinateLabels)
    .text(d => d.label)
    .transition()
    .attr('x', d => d.x)
    .attr('y', d => d.y)
  ordinateLabels.exit()
    .remove()

  const ordinateTicks = node.ordinate.selectAll('.tick')
    .data(ordinateLabelsData, d => d.y)
  ordinateTicks
    .enter().append('line')
    .attr('class', 'tick')
    .merge(ordinateTicks)
    .attr('x1', d => node.x.range()[0])
    .attr('y1', d => d.y)
    .attr('x2', d => node.x.range()[1])
    .attr('y2', d => d.y)
  ordinateTicks.exit()
    .remove()

  Vue.nextTick(() => {
    wrapLabels(graph, graph.node.svg.node())
  })

  // Circles
  const employeesCirclesData = generateBeeswarmCircles(graph)
  const employeesGroups = node.groups.selectAll('g')
    .data(employeesCirclesData, d => d.id)
  employeesGroups.exit()
    .remove()
  employeesGroups
    .enter().append('g')
    .merge(employeesGroups)
    .each(function(group) {
      const self = this
      const data = group.values

      const simulation = d3.forceSimulation(data)
        .force('x', d3.forceX(d => d.x).strength(1))
        .force('y', d3.forceY(d => d.y).strength(3))
        .force('collide', d3.forceCollide(5))
        .stop()
      // Set random seed to avoid jumping circles
      simulation.randomSource(RANDOM)

      for (let i = 0; i < 120; ++i) simulation.tick()

      const circles = d3.select(self).selectAll('.circle')
        .data(data, d => d.id)
      circles.enter().append('circle')
        .attr('class', d => 'circle ' + d.class)
        .attr('cx', d => d.x)
        .attr('cy', d => d.y)
        .attr('r', 0)
        .style('opacity', 0.85)
        .merge(circles)
        .transition()
        .attr('cx', d => d.x)
        .attr('cy', d => d.y)
        .attr('r', d => d.r)
      circles.exit()
        .remove()
    })

  // Quantiles
  const quantileBarWidth = 0.6
  const quantileBarPadding = 5
  const quantilesBars = node.quantiles.selectAll('.quantile')
    .data(employeesCirclesData, d => d.id)
  quantilesBars
    .enter().append('rect')
    .attr('class', 'quantile bar')
    .style('opacity', 0.8)
    .attr('x', d => node.x(d.id) + node.x.bandwidth() * (1 - quantileBarWidth) * 0.5)
    .attr('y', d => node.y(d.quantiles[1]))
    .attr('rx', 3)
    .attr('ry', 3)
    .attr('width', node.x.bandwidth() * quantileBarWidth)
    .attr('height', 0)
    .merge(quantilesBars)
    .transition()
    .duration(300)
    .attr('x', d => node.x(d.id) + node.x.bandwidth() * (1 - quantileBarWidth) * 0.5)
    .attr('y', d => node.y(d.quantiles[2]) - quantileBarPadding)
    .attr('width', node.x.bandwidth() * quantileBarWidth)
    .attr('height', d => node.y(d.quantiles[0]) - node.y(d.quantiles[2]) + quantileBarPadding * 2)
  quantilesBars.exit()
    .remove()

  // Quantiles labels
  const quantilesLabelsMargin = 20
  const quantilesLabels = node.quantilesLabels.selectAll('g')
    .data(employeesCirclesData, d => d.id)
  quantilesLabels
    .enter().append('g')
    .merge(quantilesLabels)
    .each(function(group) {
      const self = this

      const labels = d3.select(self).selectAll('.label')
        .data(group.quantiles)
      labels.enter().append('text')
        .attr('class', 'label')
        .attr('x', node.x(group.id) + node.x.bandwidth() / 2)
        .attr('y', (d, i) => 2 + node.y(d) + (i === 0 ? quantilesLabelsMargin : (i === 2 ? -quantilesLabelsMargin : 0)))
        .merge(labels)
        .text((d, i) =>
          node.y(d) + quantilesLabelsMargin < node.y.range()[1] &&
        i !== 1 &&
        (i === 2 || (group.quantiles[i + 2] !== d))
            ? formatLabel('$~s', Math.round(d / 100) * 100)
            : '')
        .transition()
        .attr('x', node.x(group.id) + node.x.bandwidth() / 2)
        .attr('y', (d, i) => 2 + node.y(d) + (i === 0 ? quantilesLabelsMargin : (i === 2 ? -quantilesLabelsMargin : 0)))
      labels.exit()
        .remove()
    })
  quantilesLabels.exit()
    .remove()

  // Mouse hover
  const circlesData = flatten(employeesCirclesData.map(d => d.values))
  renderInteractiveLayer(
    node,
    circlesData,
    tooltip => {
      component.tooltip = tooltip
    },
    route => {
      component.$router.push(route)
    })

  renderSelectionLayer(
    graph,
    component.$store.getters['statistics/isSelectScopeValueAvailable'],
    scope => {
      component.$store.dispatch('statistics/selectScopeValue', scope)
    }
  )
}

export default {
  components: {
    GraphLegend,
    GraphTooltip
  },
  data() {
    return {
      // Options
      viewport: {
        width: 0, // Computed
        height: 400,
        innerWidth: 0, // Computed
        innerHeight: 0, // Computed
        padding: {
          left: 40,
          right: 0,
          top: 25,
          bottom: 77
        }
      },
      // State
      tooltip: null,
      truncateOffset: 0,
      graph: null,
      resizeFn: null
    }
  },
  computed: {
    ...mapGetters({
      selectedScope: 'statistics/getCurrentScope',
      selectedScopeName: 'statistics/getCurrentScopeNameLowerCase',
      employees: 'statistics/getFilteredEmployees'
    })
  },
  mounted() {
    this.init()
    this.render()
    this.resizeFn = debounce(this.render, 50)
    window.addEventListener('resize', this.resizeFn)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeFn)
  },
  watch: {
    employees: 'resetRender',
    selectedScope: 'resetRender'
  },
  methods: {
    init() {
      this.graph = initGraph(this.$refs.svgNode, this.viewport)
    },
    resetRender() {
      this.truncateOffset = 0
      this.resizeFn()
    },
    render() {
      if (this.employees) {
        console.time('renderBeeswarmGraph')
        generateComboGraphModel(this.graph, this.employees, this.selectedScope)
        truncateModel(this.graph, this.truncateOffset)
        renderGraph(this.graph, this)
        console.timeEnd('renderBeeswarmGraph')
      }
    }
  }
}

</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
@import "./src/styles/graph.scss";

svg::v-deep .ordinate .label {
  text-anchor: end;
  alignment-baseline: middle;
}

svg::v-deep .quantiles-labels .label {
  @include font-smaller-size;
  @include font-semibold;
  fill: $graph-text-color;
  text-anchor: middle;
  alignment-baseline: middle;

  // Text stroke of 2px
  text-shadow: rgb(255, 255, 255) 2px 0px 0px,
    rgb(255, 255, 255) 1.75517px 0.958851px 0px,
    rgb(255, 255, 255) 1.0806px 1.68294px 0px,
    rgb(255, 255, 255) 0.141474px 1.99499px 0px,
    rgb(255, 255, 255) -0.832294px 1.81859px 0px,
    rgb(255, 255, 255) -1.60229px 1.19694px 0px,
    rgb(255, 255, 255) -1.97998px 0.28224px 0px,
    rgb(255, 255, 255) -1.87291px -0.701566px 0px,
    rgb(255, 255, 255) -1.30729px -1.5136px 0px,
    rgb(255, 255, 255) -0.421592px -1.95506px 0px,
    rgb(255, 255, 255) 0.567324px -1.91785px 0px,
    rgb(255, 255, 255) 1.41734px -1.41108px 0px,
    rgb(255, 255, 255) 1.92034px -0.558831px 0px;
}

svg::v-deep .abscissa .tick {
  shape-rendering: crispedges;
  stroke: $graph-inner-border-color;
}

svg::v-deep .ordinate .tick {
  shape-rendering: crispedges;
  stroke: $graph-inner-border-color;
  stroke-dasharray: 3 3;
  opacity: 0.8;
}
</style>
