<template>
  <div>
    <div class="employee-detail" v-if="storedCandidate">
      <employee-detail :employee="storedCandidate" />
    </div>
    <div class="form" :class="{reveal: candidateModel.id}">
      <h1 v-if="!candidateModel.id" v-t="'candidates.addCandidate'"></h1>
      <div class="two-columns">
        <div class="form-section">
          <h3 v-t="'employees.employee.form.personalInformations'"></h3>

          <div class="input-label" v-t="'candidates.firstName'"></div>
          <input
            type="text"
            v-model="candidateModel.firstName"
            ref="firstNameInput"
            :placeholder="$t('candidates.firstNamePlaceholder')"
            :class="{'input-error' : $v.candidateModel.firstName.$error}">

          <div class="input-label" v-t="'candidates.lastName'"></div>
          <input
            type="text"
            v-model="candidateModel.lastName"
            :placeholder="$t('candidates.lastNamePlaceholder')"
            :class="{'input-error' : $v.candidateModel.lastName.$error}">

          <div class="job-offer-picker" v-if="storedCandidate">
            <job-offer-picker
              v-model="candidateModel.jobOffer"
              @set-reference-wage="useWageProposalAsReference = $event"
              @input="applyWageProposalFromJobOffer">
            </job-offer-picker>
          </div>
        </div>

        <div class="form-section"></div>
      </div>

      <div class="form-section" v-if="!storedCandidate">
        <br>
        <h3 v-t="'candidates.wageProposal'"></h3>
        <wage-calculator
          :key="candidateModel.wageProposal.id"
          v-model="candidateModel.wageProposal"
          :grid="currentGrid"
          :showVariable="true">
        </wage-calculator>
      </div>

      <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>

      <menu>
        <loading-button
          class="destructive"
          v-if="candidateModel.id"
          :disabled="isLoading || isDeleting"
          :loading="isDeleting"
          @click="remove">
          <span v-t="'common.remove'"></span>
        </loading-button>
        <button
          class="secondary"
          :disabled="isLoading || isDeleting"
          @click="$router.go(-1)" v-t="'candidates.cancel'">
        </button>
        <loading-button
          class="primary"
          :disabled="isLoading || isDeleting"
          :loading="isLoading"
          @click="submit">
          <span>{{ submitActionName }}</span>
        </loading-button>
      </menu>
    </div>
  </div>
</template>

<script>
import EmployeeDetail from '@components/employees/EmployeeDetail.vue'
import JobOfferPicker from '@components/candidates/JobOfferPicker.vue'
import WageCalculator from '@components/wage/WageCalculator.vue'

import { required } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      candidateModel: null,
      isLoading: false,
      isDeleting: false,
      errorMessage: null,
      useWageProposalAsReference: false
    }
  },
  computed: {
    storedCandidate() {
      const candidateId = this.$route.params.id
      if (candidateId) {
        return this.$store.getters['candidates/getCandidate'](candidateId)
      }
    },
    currentGrid() {
      return this.$store.getters['currentGrid/getCurrentGrid']
    },
    submitActionName() {
      return this.$t(this.candidateModel.id ? 'common.update' : 'common.add')
    }
  },
  methods: {
    submit() {
      this.$v.$touch()

      if (!this.$v.$error) {
        this.isLoading = true

        this.createOrUpdate()
          .then(candidate => {
            this.$router.push({ name: 'hiringPlans', params: { highlightCandidate: candidate.id } })
          })
          .catch(error => {
            this.isLoading = false
            this.errorMessage = error
          })
      }
      else {
        window.scrollTo(0, 0)
      }
    },
    createOrUpdate() {
      if (this.useWageProposalAsReference) {
        this.candidateModel.jobOffer.referenceWage = this.candidateModel.wageProposal
      }
      if (!this.candidateModel.jobOffer) {
        delete this.candidateModel.jobOffer
      }

      if (this.candidateModel.id) {
        return this.$store.dispatch('candidates/updateCandidate', this.candidateModel)
      }
      else {
        return this.$store.dispatch('candidates/addCandidate', this.candidateModel)
      }
    },
    remove() {
      if (confirm(this.$t('candidates.removeConfirmation', { candidateName: this.candidateModel.firstName }))) {
        this.isDeleting = true
        const candidate = { ...this.candidateModel }
        this.$router.push({ name: 'hiringPlans', params: { highlightCandidate: candidate.id, highlightClass: 'red-flash' } })
        setTimeout(() => {
          this.$store.dispatch('candidates/removeCandidate', this.candidateModel)
            .catch(error => error && alert(error))
        }, 1000)
      }
    },
    applyWageProposalFromJobOffer() {
      if (this.candidateModel.jobOffer && this.candidateModel.jobOffer.referenceWage) {
        this.candidateModel.wageProposal = this.candidateModel.jobOffer.referenceWage
      }
    }
  },
  validations() {
    return {
      candidateModel: {
        firstName: { required },
        lastName: { required }
      }
    }
  },
  created() {
    if (this.storedCandidate) {
      this.candidateModel = { ...this.storedCandidate }
      if (this.candidateModel.jobOffer) {
        this.candidateModel.jobOffer = this.$store.getters['candidates/getJobOffer'](this.candidateModel.jobOffer)
      }
    }
    else {
      this.candidateModel = { ...this.$store.getters['candidates/getCandidateModel'] }
      const jobOffer = this.$store.getters['candidates/getJobOffer'](this.$route.params.jobOfferId)
      if (jobOffer) {
        this.candidateModel.jobOffer = jobOffer
      }
    }
    this.applyWageProposalFromJobOffer()
  },
  mounted() {
    if (!this.storedCandidate) {
      if (!this.$route.params.jobOfferId) {
        this.$router.replace('/recruitment')
      }
      else {
        this.$refs.firstNameInput.focus()
      }
    }
    else {
      this.$store.commit('candidates/setCurrentCandidate', this.candidateModel)
    }
  },
  components: {
    EmployeeDetail,
    JobOfferPicker,
    WageCalculator
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";

.employee-detail {
  margin-bottom: 1.5em;
}

.form {
  @include container;
}

.two-columns {
  display: grid;
  grid-template-columns: 1fr 8% 1fr;
}

input {
  margin-bottom: 1rem;

  &:last-of-type {
    margin-bottom: 0;
  }
}

input {
  &:last-child {
    margin-bottom: 0;
  }
}

.job-offer-picker {
  margin-top: 20px;
}
</style>
