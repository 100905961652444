<template>
  <div>
    <div class="read-only-container">
      <img class="demo-image" src="~@/assets/demo.svg">
      <div class="demo-explanation" v-html="content"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const RO_KIND_DEMO = 'demo'
const RO_KIND_EXPIRED = 'expired'
const RO_KIND_TRIAL_EXPIRED = 'trialExpired'
const RO_KIND_SIGNIN_AS = 'signinAs'

export default {
  computed: {
    ...mapGetters({
      isDemoSession: 'isDemoSession',
      isExpiredSession: 'isExpiredSession',
      isTrialExpired: 'isTrialExpired'
    }),
    kind() {
      if (this.isDemoSession) {
        return RO_KIND_DEMO
      }
      else if (this.isTrialExpired) {
        return RO_KIND_TRIAL_EXPIRED
      }
      else if (this.isExpiredSession) {
        return RO_KIND_EXPIRED
      }
      else {
        return RO_KIND_SIGNIN_AS
      }
    },
    content() {
      return this.$t(`demo.alert.${this.kind}.content`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";

.read-only-container {
  display: grid;
  grid-template-columns: 170px 1fr;
  gap: 2rem;
  padding: 2rem;
  max-width: 640px;
}

.demo-image {
  width: 100%;
  margin-top: 7px;
}

.demo-explanation::v-deep {
  p {
    @include line-regular-height;
  }

  a.button-link {
    @extend button;
    @extend .primary;
    display: inline-block;
    text-decoration: inherit;
  }

  .emphasis {
    @include font-semibold;
    color: $clearteal-color;
  }
}
</style>
