<template>
  <div class="markdown-text-editor">
    <div v-if="showLabel" class="input-label">
      <span>{{label}}</span>
      <span class="light-text">{{optionalLabel}}</span>
    </div>
    <div v-if="!isEditingDescription">
      <template v-if="description && description.trim()">
        <markdown-text
          class="description"
          :class="{clickable: !disabled, editable: !disabled && showInlineEditButton}"
          v-model="description"
          @click.native="editDescription" />
        <button
          type="button"
          v-if="!disabled && !showInlineEditButton"
          class="small-button update-description"
          @click="editDescription" v-t="'common.update'"></button>
      </template>
      <button
        type="button"
        v-else-if="!disabled"
        class="small-button add-description"
        @click="editDescription"
        key="add">{{action || $t('common.addDescription')}}</button>
    </div>
    <div v-else>
      <div class="textarea-container">
        <textarea ref="textarea"></textarea>
      </div>
      <resizable-panel :observer="isMenuVisible">
        <div class="menu" v-if="isMenuVisible">
          <div
            class="markdown-friendly"
            v-html="$t('markdownEditor.friendly')"></div>
          <button
            v-if="showOKButton"
            class="small-button"
            @click="submit" v-t="'common.ok'"></button>
        </div>
      </resizable-panel>
    </div>
  </div>
</template>

<script>
import MarkdownText from '@components/commons/MarkdownText'
import ResizablePanel from '@components/commons/ResizablePanel'
import EasyMDE from 'easymde'
import debounce from 'lodash.debounce'

export default {
  components: {
    MarkdownText,
    ResizablePanel
  },
  props: {
    action: String,
    autoDismiss: Boolean,
    disabled: Boolean,
    label: String,
    placeholder: String,
    optionalLabel: String,
    showAddButton: Boolean,
    showInlineEditButton: Boolean,
    showOKButton: {
      type: Boolean,
      default: true
    },
    showToolbar: {
      type: Boolean,
      default: false
    },
    minHeight: {
      type: String,
      default: '50px'
    },
    value: String
  },
  data() {
    return {
      easyMDE: null,
      description: this.value,
      isMenuVisible: false,
      isEditingDescription: false
    }
  },
  computed: {
    showLabel() {
      return this.label && (!this.disabled || this.description)
    }
  },
  methods: {
    buildEaseMDE() {
      if (!this.easyMDE && this.$refs.textarea) {
        this.easyMDE = new EasyMDE({
          element: this.$refs.textarea,
          initialValue: this.description,
          spellChecker: false,
          minHeight: this.minHeight,
          status: false,
          placeholder: this.placeholder,
          toolbar: this.showToolbar
            ? [
                'bold',
                'italic',
                'heading',
                '|',
                'quote',
                'unordered-list',
                'ordered-list',
                'horizontal-rule',
                '|',
                'link']
            : false
        })
        this.easyMDE.codemirror.on('change', () => {
          this.onChange(this.easyMDE.value())
        })
        this.easyMDE.codemirror.on('blur', () => {
          // We need setTimeout because toolbar triggers blur
          setTimeout(() => {
            if (this.easyMDE && this.easyMDE.codemirror && !this.easyMDE.codemirror.hasFocus()) {
              this.dismiss()
            }
          }, 200)
        })
      }
    },
    destroyEasyMDE() {
      if (this.easyMDE) {
        this.easyMDE.toTextArea()
        this.easyMDE.cleanup()
        this.easyMDE = null
      }
    },
    toggleEditDescription(value) {
      this.isEditingDescription = value
      if (value) {
        this.$nextTick(() => {
          this.buildEaseMDE()
        })
      }
      else {
        this.destroyEasyMDE()
      }
    },
    editDescription() {
      if (this.disabled) {
        return
      }
      this.isMenuVisible = !!this.description
      this.toggleEditDescription(true)
      this.$nextTick(() => this.easyMDE.codemirror.focus())
    },
    dismiss() {
      if (this.autoDismiss) {
        this.submit()
      }
    },
    submit() {
      this.toggleEditDescription(false)
      this.$emit('submit', this.description)
    },
    onChange(value) {
      this.description = value
      this.isMenuVisible = true
      this.$emit('input', value)
      this.emitChange()
    },
    emitChange: debounce(function() {
      this.$emit('change', this.value)
    }, 300)
  },
  mounted() {
    this.toggleEditDescription(!this.value && !this.showAddButton && !this.disabled)
  },
  beforeDestroy() {
    this.destroyEasyMDE()
  },
  watch: {
    value() {
      if (this.description !== this.value) {
        this.toggleEditDescription(!this.value && !this.showAddButton)
        this.isMenuVisible = false
        this.description = this.value
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";
@import "~easymde/dist/easymde.min.css";

.markdown-text-editor {
  margin-bottom: 1em;
}

.add-description {
  margin-top: 0.25em;
}

.clickable {
  cursor: pointer;
}

.editable::v-deep p:last-child:after,
.editable::v-deep ul:last-child li:last-child:after {
  content: " ";
  display: inline-block;
  width: 0.8em;
  height: 0.8em;
  background: transparent url(~@/assets/edit.svg) no-repeat right center;
  background-size: contain;
  vertical-align: baseline;
  margin-left: 0.5em;
}

.menu {
  margin-top: 5px;

  .markdown-friendly {
    margin-top: 0.4em;
    float: right;
    @include font-small-size;
    color: $light-text-color;
  }
}

// Blue skin
.container.blue {
  textarea:focus {
    border-color: lighten($graph-darkblue-color, 20);
  }

  .small-button {
    @extend .blue;
    &:not([disabled]):not(:hover) {
      color: $light-text-color;
    }
  }
}

// EasyMDE skin
.textarea-container::v-deep .EasyMDEContainer {
  .editor-toolbar {
    border: $border;
    border-bottom: none;
    padding: 0;
  }

  .CodeMirror {
    border: $border;
    color: $text-color;
    padding: 0.5em;
    border-radius: $border-radius;
    // Workaround from https://github.com/codemirror/codemirror5/issues/6982#issuecomment-1239544853
    clip-path: inset(0px);
  }

  .editor-toolbar + .CodeMirror {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .CodeMirror-placeholder {
    opacity: 0.4;
  }
}
</style>
