<template>
  <div>
    <div class="employee-detail">
      <EmployeeDetail :employee="storedEmployee" />
    </div>
    <div>
      <h3 v-if="isGridGenerated" v-t="'grid.editor.setWage'" @click="debugWage"></h3>
      <h3 v-else v-t="'grid.editor.setQualification'"></h3>
      <WageCalculator
        v-if="!isDebug"
        ref="wage"
        v-model="wageModel"
        :grid="grid"
        :referenceWage="referenceWage"
        :referenceGrid="referenceGrid"
        :showDelta="isGridGenerated"
        :showReason="isGridGenerated"
        :showWageTypeSelector="isGridGenerated"
        :showSalary="isGridGenerated"
        :autoOpen="!isGridGenerated && 'smart'"
        @resize="onResize"
        @input="onChange" />
      <WageViewer
        v-else
        :wage="wageModel" />
    </div>
    <p v-if="currentGrid" class="light-text no-margin-top" v-html="$t('grid.editor.currentGridWageNotice', {employeeId: payload.employee.id })"></p>
    <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>
    <menu>
      <button
        class="secondary reset-button fade-in"
        v-if="isUpdated"
        @click="reset"
        v-t="'common.reset'"></button>
      <button
        v-if="isBackAvailable"
        class="secondary"
        @click="$emit('back')"
        v-t="'common.back'"></button>
      <button
        v-else
        class="secondary"
        @click="close"
        v-t="'common.cancel'"></button>
      <loading-button
        class="primary"
        :disabled="isLoading"
        @click="submit"
        :loading="isLoading">
        <span v-t="'common.update'"></span>
      </loading-button>
    </menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EmployeeDetail from '@components/employees/EmployeeDetail.vue'
import WageCalculator from '@components/wage/WageCalculator.vue'
import WageViewer from '@components/wage/WageViewer.vue'

export default {
  components: {
    EmployeeDetail,
    WageCalculator,
    WageViewer
  },
  name: 'gridEmployeeEditor',
  props: {
    payload: Object,
    isBackAvailable: Boolean,
    isGridGenerated: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      wageModel: {},
      errorMessage: null,
      isLoading: false,
      isUpdated: false,
      isDebug: false
    }
  },
  computed: {
    ...mapGetters({
      getEmployee: 'employees/getEmployee',
      getEmployeeWageModel: 'onboarding/getEmployeeWageModel',
      grid: 'sandbox/grid',
      currentGrid: 'currentGrid/getCurrentGrid'
    }),
    storedEmployee() {
      return this.getEmployee(this.payload.employee.id)
    },
    referenceWage() {
      return this.payload.employee.currentWage
    },
    referenceGrid() {
      return this.currentGrid
    }
  },
  methods: {
    initModel() {
      this.wageModel = this.getEmployeeWageModel(this.payload.employee.id)
    },
    close() {
      this.$emit('close')
    },
    closeOrBack() {
      if (this.isBackAvailable) {
        this.$emit('back')
      }
      else {
        this.close()
      }
    },
    onResize() {
      this.$emit('resize')
    },
    onChange() {
      this.isUpdated = true
      this.$emit('simulation', [], [this.wageModel])
    },
    reset() {
      this.$refs.wage.reset()
      this.isUpdated = false
    },
    debugWage(event) {
      if (event.altKey) {
        this.isDebug = !this.isDebug
      }
    },
    submit() {
      this.isLoading = true
      this.$store.dispatch('sandbox/updateSandboxWage', this.wageModel)
        .then(() => {
          this.closeOrBack()
        })
        .catch(error => {
          this.errorMessage = error
          this.isLoading = false
        })
    }
  },
  created() {
    this.initModel()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/button.scss";

.employee-detail::v-deep .infos {
  padding: 0 0 1.5em;
  border: none;
}

.wage-viewer {
  width: auto;
  padding: 0;
}

.reset-button {
  margin-right: 2em
}
</style>
