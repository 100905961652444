import Vue from 'vue'
import orderBy from 'lodash.orderby'
import i18n from '@/i18n'
import { buildNormalizedDate } from '@/utils/date'
import { getWageDetails } from '@/utils/grid'
import { getItem, setItem } from '@/utils/storage'
import { getWageWithStartDate } from '@/utils/evolution'
import {
  CW_GRAPH_SCOPE,
  CW_GRAPH_FILTERS,
  CW_GRAPH_OPTIONS,
  REPORTS,
  DEFAULT_OPTIONS,
  classifyGender,
  classifySalary,
  computeAge,
  computeSeniority,
  getScopes,
  getScopeValues,
  getScopesValues,
  isFiltered,
  getWageGrid
} from '@/utils/statistics'
import { getField } from '@/utils/employee'

const initialState = () => {
  const currentOptions = getItem(CW_GRAPH_OPTIONS, { ...DEFAULT_OPTIONS })
  Object.keys(DEFAULT_OPTIONS).forEach(key => {
    if (typeof currentOptions[key] === 'undefined') {
      currentOptions[key] = DEFAULT_OPTIONS[key]
    }
  })

  return {
    currentReport: null,
    currentScope: getItem(CW_GRAPH_SCOPE),
    currentFilters: getItem(CW_GRAPH_FILTERS, []),
    currentOptions,
    employeesNewsfeeds: {},
    resetScope: null
  }
}

const state = initialState()

const getters = {
  getEmployeesAndAlumnis(_, { getCurrentOptions }, _rootState, rootGetters) {
    console.time('getStatisticsEmployees')
    const employeesAndJobOfferProfiles = [
      ...rootGetters['candidates/getSelectedJobOffersProfiles'],
      ...rootGetters['employees/getEmployeesAndAlumnis']
    ]
    const referenceGrid = rootGetters['currentGrid/getReferenceGrid']
    const customFields = rootGetters['company/getCustomFields']
    const scopes = getScopes(referenceGrid, customFields)
    const wageDetailsOptions = {
      salaryOnly: true,
      includeVariable: getCurrentOptions.includeContractualVariable
    }
    const hasEquivalenceLevels = Object.values(getCurrentOptions.equivalenceLevels).find(l => l)
    const employees = employeesAndJobOfferProfiles.map(e => {
      const manager = getField(e, 'manager', { store: { getters: rootGetters } })
      const wagePlanEmployee = rootGetters['wagePlans/getHighestEmployeeWage'](e.id)
      const wagePlanWage = wagePlanEmployee ? wagePlanEmployee.wage : null
      const hiringPlanWage = e.jobOffer ? e.jobOffer.referenceWage : null
      const currentWage = e.currentWage
      const currentWageDetails = getWageDetails(null, currentWage, wageDetailsOptions)
      const currentSalary = currentWageDetails.summary.salary.value
      const onboardingWage = rootGetters['sandbox/onboardingGrid'] ? getWageWithStartDate(rootGetters['sandbox/employeeWage'](e.id)) : null
      const scopeValuesWage = wagePlanWage || hiringPlanWage || onboardingWage || currentWage
      const wage = wagePlanWage || hiringPlanWage || (getCurrentOptions.salary === 'sandbox' && onboardingWage) || currentWage
      let upcomingWages = []
      if (wagePlanEmployee && wagePlanEmployee.wages.length) {
        upcomingWages = wagePlanEmployee.wages
      }
      if (getCurrentOptions.salary === 'sandbox' && onboardingWage) {
        upcomingWages.push(onboardingWage)
      }

      const wageDetails = getWageDetails(null, wage, wageDetailsOptions)
      let salary = wageDetails.summary.salary.value
      let salaryDelta = wageDetails.summary.salary.delta

      if (hasEquivalenceLevels) {
        const equivalenceWageDetails = getWageDetails(getWageGrid(wage, { getters: rootGetters }), wage, {
          includeVariable: getCurrentOptions.includeContractualVariable,
          equivalenceLevels: getCurrentOptions.equivalenceLevels
        })
        salary = equivalenceWageDetails.summary.salary.value
        salaryDelta = equivalenceWageDetails.summary.salary.delta
      }

      const route = wagePlanEmployee
        ? { name: 'wagePlan', params: { id: wagePlanEmployee.wagePlan.id, highlightEmployee: e.id } }
        : (e.jobOffer
            ? { name: 'hiringPlans', params: { highlightJobOffer: e.jobOffer.id, highlightClass: 'bump' } }
            : { name: 'employee', params: { id: e.id } })

      return {
        id: e.id,
        firstName: e.firstName,
        lastName: e.lastName,
        fullName: [e.firstName, e.lastName].join(' '),
        initials: e.initials,
        hiringPlan: e.hiringPlan,
        jobOffer: e.jobOffer,
        isAlumni: e.isAlumni,
        wagePlan: wagePlanEmployee && wagePlanEmployee.wagePlan,
        route,
        gender: classifyGender(e.gender),
        age: computeAge(e.birthdate),
        arrivalDate: e.arrivalDate,
        departureDate: e.departureDate,
        seniorityDate: computeSeniority(e.arrivalDate),
        salaryRange: classifySalary(salary),
        ...getScopesValues(scopes, { ...e, manager }, scopeValuesWage),
        salary,
        salaryDelta,
        currentSalary,
        currentWage,
        scopeValuesWage,
        upcomingWages,
        wage
      }
    })
    console.timeEnd('getStatisticsEmployees')
    return employees
  },
  getEmployees(_, { getEmployeesAndAlumnis }) {
    return getEmployeesAndAlumnis.filter(e => !e.isAlumni)
  },
  getEmployee(_, getters) {
    return (id) => getters.getEmployees.find(e => e.id === id)
  },
  getSynthesis(_state, getters, _rootState, rootGetters) {
    const employees = getters.getEmployees
    const synthesis = { employees }

    // Payroll stats
    synthesis.payroll = employees.reduce((memo, employee) => memo + employee.currentSalary, 0)
    synthesis.postPayroll = employees.reduce((memo, employee) => memo + employee.salary, 0)
    synthesis.postPayrollRise = synthesis.postPayroll - synthesis.payroll
    synthesis.postPayrollRisePercent = synthesis.payroll ? Math.abs(synthesis.postPayrollRise / synthesis.payroll) * 100 : 100

    // Grid version
    const grid = rootGetters['currentGrid/getCurrentGrid']
    if (grid) {
      synthesis.grid = {
        published: true,
        version: grid.version
      }
    }
    else if (rootGetters['onboarding/getProgressIndex'] > 1) {
      synthesis.grid = {
        onboarding: true
      }
    }

    return synthesis
  },
  getReports() {
    return REPORTS.map(r => r.id)
  },
  getReport() {
    return (id) => REPORTS.find(r => r.id === id)
  },
  getCurrentReport(state, _getters, _rootState, rootGetters) {
    const welcomeReport = { id: 'welcome' }
    const isWelcomeReport = rootGetters['account/isAdmin'] && rootGetters['employees/getEmployees'].length <= 1
    const defaultReport = isWelcomeReport ? welcomeReport : REPORTS[0]
    return state.currentReport || defaultReport
  },
  getCurrentReportName(_state, getters) {
    return i18n.t(`dashboard.graphs.${getters.getCurrentReport.id}.title`)
  },
  getAvailableScopes(_state, getters, _, rootGetters) {
    const referenceGrid = rootGetters['currentGrid/getReferenceGrid']
    const customFields = rootGetters['company/getCustomFields']
    const scopes = getScopes(referenceGrid, customFields)
    let availableScopes = scopes.filter(scope => {
      const values = getters.getEmployees.map(e => e[scope.value])
      return values.length && !values.every(value => [null, undefined].includes(value))
    })
    if (!availableScopes.length) {
      availableScopes = [scopes[0]]
    }

    return availableScopes
  },
  getCurrentScope(state, getters) {
    const availableScopes = getters.getAvailableScopes.map(s => s.value)
    if (availableScopes.includes(state.currentScope)) {
      return state.currentScope
    }
    else {
      return availableScopes[0]
    }
  },
  getCurrentScopeName(_state, getters) {
    let currentScope = getters.getCurrentScope
    currentScope = getters.getAvailableScopes.find(s => s.value === currentScope)
    return currentScope && currentScope.title
  },
  getCurrentScopeNameLowerCase(_state, { getCurrentScopeName }) {
    return getCurrentScopeName && getCurrentScopeName.toLowerCase()
  },
  getScopeValues(_, { getEmployees }) {
    // Returns all values with default value at the bottom
    return (scope) => getScopeValues(getEmployees, scope)
  },
  getScopeDefaultValue(_, { getEmployees }) {
    // Returns the most frequent value
    return (scope) => getScopeValues(getEmployees, scope, true)[0]
  },
  getCurrentFilters(state) {
    return state.currentFilters
  },
  getUsableFilters(state) {
    // Returns current filters with null instead of translated default value
    return state.currentFilters.map(({ scope, values }) =>
      ({
        scope,
        values: values.map(value => value === i18n.t('dashboard.graphs.defaultValue') ? null : value)
      }))
  },
  getCurrentOptions(state) {
    return state.currentOptions
  },
  hasCurrentOptions(state) {
    return (keys) => {
      return keys.reduce((memo, key) => {
        const defaultValue = DEFAULT_OPTIONS[key]
        if (defaultValue) {
          return memo || (JSON.stringify(state.currentOptions[key]) !== JSON.stringify(defaultValue))
        }
        else {
          return memo
        }
      }, false)
    }
  },
  getFilteredEmployees(_, { getEmployees, getUsableFilters }) {
    if (getUsableFilters.length) {
      return getEmployees.filter(employee => isFiltered(getUsableFilters, employee))
    }
    else {
      return getEmployees
    }
  },
  getEmployeesNewsfeeds(state) {
    return state.employeesNewsfeeds
  },
  getOptions(state, { getCurrentReport, getCurrentScope, getAvailableScopes }) {
    // Options are only available for salaries report
    if (getCurrentReport.id === 'salaries') {
      const scope = getAvailableScopes.find(s => s.value === getCurrentScope) || getAvailableScopes[0]
      return scope.options.reduce((memo, option) => {
        memo[option] = state.currentOptions[option]
        return memo
      }, {})
    }
    else {
      return {}
    }
  },
  isSelectScopeValueAvailable(_, getters) {
    const availableScopes = getters.getAvailableScopes
    const scope = getters.getCurrentScope
    const scopeIndex = availableScopes.findIndex(s => s.value === scope)
    return scopeIndex < availableScopes.length - 1
  },
  getAnnotations(_, __, ___, rootGetters) {
    const wagePlans = rootGetters['wagePlans/getSelectedWagePlans']
    const hiringPlans = rootGetters['candidates/getSelectedHiringPlans']
    return orderBy([
      ...wagePlans.map(({ id, name, startDate }) => ({
        id,
        name,
        startDate: startDate || buildNormalizedDate()
      })),
      ...hiringPlans.map(({ id, name, startDate }) => ({
        id,
        name,
        startDate: startDate || buildNormalizedDate(1)
      }))
    ], ['startDate'])
  }
}

const actions = {
  reset(context) {
    context.commit('reset')
  },
  setCurrentReport(context, id) {
    const report = context.getters.getReport(id)
    context.commit('setCurrentReport', report)
  },
  setCurrentScope(context, scope) {
    context.commit('setCurrentScope', scope)
  },
  setCurrentFilters({ state, commit }, filters) {
    // Reset scope if needed
    const resetScope = state.resetScope
    if (!filters.length && resetScope) {
      commit('setCurrentScopeAndFilters', { scope: resetScope, filters, resetScope: null })
    }
    else {
      commit('setCurrentFilters', filters)
    }
  },
  async getEmployeesNewsfeeds({ getters, commit, dispatch }) {
    console.time('getEmployeesNewsfeeds')
    if (!Object.keys(getters.getEmployeesNewsfeeds).length) {
      try {
        const newsfeeds = await dispatch('employees/getNewsfeeds', null, { root: true })
        commit('setEmployeesNewsfeeds', newsfeeds)
      }
      catch (error) {
        // Error is processed in employees store
        return error
      }
    }
    console.timeEnd('getEmployeesNewsfeeds')
    return getters.getEmployeesNewsfeeds
  },
  setOption(context, { option, value }) {
    context.commit('setOption', { option, value })
  },
  selectScopeValue({ getters, commit }, value) {
    const availableScopes = getters.getAvailableScopes
    const scope = getters.getCurrentScope
    const scopeIndex = availableScopes.findIndex(s => s.value === scope)
    if (scopeIndex < availableScopes.length - 1) {
      const nextScope = availableScopes[scopeIndex + 1].value
      const filters = getters.getCurrentFilters.concat([{ scope, values: [value] }])
      commit('setCurrentScopeAndFilters', { scope: nextScope, filters, resetScope: scope })
    }
  }
}

const mutations = {
  reset(state) {
    Object.assign(state, initialState())
  },
  setCurrentReport(state, report) {
    state.currentReport = report
  },
  setCurrentScope(state, scope) {
    setItem(CW_GRAPH_SCOPE, scope)
    state.currentScope = scope
  },
  setCurrentFilters(state, filters) {
    setItem(CW_GRAPH_FILTERS, filters)
    state.currentFilters = filters
  },
  setCurrentScopeAndFilters(state, { scope, filters, resetScope }) {
    setItem(CW_GRAPH_SCOPE, scope)
    state.currentScope = scope
    setItem(CW_GRAPH_FILTERS, filters)
    state.currentFilters = filters
    if (!state.resetScope || !resetScope) {
      state.resetScope = resetScope
    }
  },
  setOption(state, { option, value }) {
    Vue.set(state.currentOptions, option, value)
    setItem(CW_GRAPH_OPTIONS, state.currentOptions)
  },
  setEmployeesNewsfeeds(state, employeesNewsfeeds) {
    state.employeesNewsfeeds = employeesNewsfeeds
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
