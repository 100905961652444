<template>
  <div>
    <GridStructureDescriptionEditor :grid="grid" />
    <GridStructureComponentEditor
      v-for="component in grid.components"
      :key="component.id"
      :ref="component.id"
      :component="component"
      :class="{'zoom-in': highlightComponent && highlightComponent.id === component.id}" />
    <p class="preserve-lines" v-t="'grid.onboarding.intro.components.formulaIntro'"></p>
    <div class="grid-formula-editor">
      <GridFormula
        layout="horizontal"
        :grid="grid"
        @select="openComponentModal" />
      <button class="button secondary" @click="openComponentCreateModal">Ajouter<br>un composant</button>
    </div>
    <modal :visible="isComponentCreateVisible" @close="closeModal">
      <div class="modal">
        <GridComponentCreateEditor
          @close="closeModal" />
      </div>
    </modal>
  </div>
</template>

<script>
import GridComponentCreateEditor from '@components/grid/structure/GridComponentCreateEditor.vue'
import GridFormula from '@components/grid/viewer/GridFormula.vue'
import GridStructureComponentEditor from '@components/grid/structure/GridStructureComponentEditor.vue'
import GridStructureDescriptionEditor from '@components/grid/structure/GridStructureDescriptionEditor.vue'
import Modal from '@components/commons/Modal.vue'
import { mapGetters } from 'vuex'
import { getParentComponent } from '@/utils/grid'

export default {
  components: {
    GridComponentCreateEditor,
    GridFormula,
    GridStructureComponentEditor,
    GridStructureDescriptionEditor,
    Modal
  },
  data() {
    return {
      isComponentCreateVisible: false,
      highlightComponent: null
    }
  },
  computed: {
    ...mapGetters({
      grid: 'sandbox/grid'
    })
  },
  methods: {
    openComponentModal(component) {
      component = getParentComponent(component, this.grid)
      if (this.$refs[component.id]) {
        this.$refs[component.id][0].openComponentModal()
      }
    },
    openComponentCreateModal() {
      this.isComponentCreateVisible = true
    },
    closeModal(component) {
      this.highlightComponent = component
      this.isComponentCreateVisible = false
    }
  },
  beforeDestroy() {
    // Clean cache
    this.$store.dispatch('onboarding/softReset')
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/button.scss";

.grid-formula-editor {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & > * {
    margin: 0 1.25rem 1rem 0;
  }

  .button {
    @include line-regular-height;
    padding: 6px 15px;
  }
}

.modal {
  padding: 20px;
  padding-bottom: 0;
  width: 625px;

  & > div {
    padding-bottom: 10px;
  }
}
</style>
