import VueGtag from 'vue-gtag'
import Vue from 'vue'
import cookies from 'js-cookie'
import camelCase from 'lodash.camelcase'
import mixpanel from 'mixpanel-browser'
import api from '@/store/api'
import router from '@/router'

const DEBUG = false

if (DEBUG) {
  console.warn('Analytics debug mode. Make sure DNT is off to test')
}

if (canCollectAnalytics()) {
  Vue.use(VueGtag, {
    config: {
      id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
      params: {
        send_page_view: false
      }
    }
  }, router)

  // Mixpanel
  mixpanel.init(process.env.VUE_APP_MIXPANEL_ID, {
    debug: DEBUG
  })

  router.afterEach(to => {
    mixpanel.track('ViewPage', { path: to.fullPath })
  })

  // API events
  api.interceptors.response.use(
    response => {
      handleAPIResponse(response)
      return response
    }, error => {
      handleAPIResponse(error.response, error)
      return Promise.reject(error)
    })

  // Vue mixin for custom events
  Vue.mixin({
    methods: {
      $$trackEvent(name, properties = null) {
        trackEvent(name, properties)
      }
    }
  })
}

export function trackEvent(name, properties = null) {
  if (canCollectAnalytics()) {
    mixpanel.track(name, properties)
  }
}

export function setAnalyticsFields(user) {
  if (canCollectAnalytics()) {
    Vue.$gtag.set({
      user_id: user.id
    })

    mixpanel.identify(user.id)
    mixpanel.people.set({
      $first_name: user.firstName,
      $last_name: user.lastName,
      $email: user.email,
      $last_login: new Date(),
      company: user.company.name,
      companyId: user.company.id
    })
  }
}

export function resetAnalyticsFields() {
  if (canCollectAnalytics()) {
    Vue.$gtag.set({
      user_id: null
    })

    mixpanel.reset()
  }
}

// https://help.mixpanel.com/hc/en-us/articles/115004497803
export function mergePreSignupAnalytics(userId) {
  if (canCollectAnalytics()) {
    mixpanel.alias(userId)
  }
}

function canCollectAnalytics() {
  return process.env.VUE_APP_MIXPANEL_ID && process.env.VUE_APP_GOOGLE_ANALYTICS_ID && !isAdminSession()
}

function isAdminSession() {
  // Same logic that in store/index.js
  return cookies.get('s') !== undefined
}

function handleAPIResponse(response, error = null) {
  try {
    const action = response && response.config && actionName(response.config.method)
    if (action) {
      const url = response.request.responseURL
      const object = objectName(url)
      const event = error ? `${action}${object}Failed` : `${action}${object}`

      mixpanel.track(event, {
        status: response.status,
        path: new URL(url).pathname
      })
    }
  }
  catch (e) {}
}

function actionName(method) {
  switch (method) {
    case 'post':
      return 'Create'
    case 'put':
      return 'Set'
    case 'patch':
      return 'Update'
    case 'delete':
      return 'Delete'
    default:
      return null
  }
}

function objectName(url) {
  return new URL(url).pathname
    .split('/')
    .slice(2)
    .filter(c => !isUUID(c))
    .map(c => c.charAt(0).toUpperCase() + camelCase(c.slice(1)))
    .join('')
}

function isUUID(string) {
  const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
  return regex.test(string)
}
