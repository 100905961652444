<template>
  <span class="quantity-input">
    <input
      ref="input"
      type="number"
      :min="min"
      :max="max"
      :step="step"
      :disabled="disabled"
      v-model="valueModel"
      @input="onInput">
    <button
      class="small-button"
      @click="decrement">-</button>
    <button
      class="small-button"
      @click="increment">+</button>
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: Number
    },
    min: {
      type: Number,
      default: 1
    },
    max: {
      type: Number,
      default: 100
    },
    step: {
      type: Number,
      default: 1
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      valueModel: this.value || 0
    }
  },
  computed: {
  },
  methods: {
    increment() {
      if (this.valueModel + this.step <= this.max) {
        this.valueModel += this.step
        this.onInput()
      }
    },
    decrement() {
      if (this.valueModel - this.step >= this.min) {
        this.valueModel -= this.step
        this.onInput()
      }
    },
    updateValue() {
      this.valueModel = this.value || 0
    },
    onInput() {
      this.$emit('input', +this.valueModel)
      this.$emit('change', +this.valueModel)
    },
    focus() {

    }
  },
  watch: {
    value: 'updateValue'
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";

.input-error {
  input[type="number"] {
    @extend .input-error
  }
}

input[type="number"] {
  display: inline-block;
  width: 4em;
  text-align: center;
  @include font-tabular-numbers;
  padding: 0 0 0 10px;

  &:focus {
    border-color: lighten($graph-darkblue-color, 20);
  }

  &.small {
    width: 80px;
  }
}

* {
  margin-right: 0.5em;
}

.green input:focus {
  border-color: $clearteal-color;
}
</style>
