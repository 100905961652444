<template>
  <div class="component-levels-flex" :class="{sticky: linkedLevel && !(modalPayload || isLevelFormVisible)}">
    <div class="component-levels">
      <div class="table-border">
        <table class="blue" :class="{'no-bg': hasLevelGroups}">
          <tr class="row">
            <th v-if="isLinkedLevelSelectionVisible"></th>
            <th :colspan="isLinkedLevelSelectionVisible ? 4 : 3">
              {{ getLevelName(component.ref, enrichedLevels.length) }}
            </th>
          </tr>
          <tr v-if="linkedLevel" class="row active">
            <td v-if="isLinkedLevelSelectionVisible"></td>
            <td :colspan="3 + (isEmployeesVisible ? 1 : 0)">
              {{ linkedLevel.name }}
            </td>
          </tr>
          <draggable
            v-model="enrichedLevelsAndGroups"
            tag="tbody"
            :disabled="!isReorderingLevels">
            <tr
              v-for="level in enrichedLevelsAndGroups"
              :key="level.id"
              class="row clickable"
              :class="{
                group: level.isGroup,
                active: isLinkedLevelSelectionVisible && selectedLinkedLevel && level.id == selectedLinkedLevel.id,
                'zoom-in': highlightLevel && highlightLevel.id === level.id,
                draggable: isReorderingLevels,
                hidden: level.isHidden
              }"
              @click="level.isGroup ? renameLevelGroup(level) : openLevelModal($event, level)">
              <template v-if="level.isGroup">
                <td v-if="isLinkedLevelSelectionVisible"></td>
                <td
                  class="level-name"
                  :class="{editable: !isReorderingLevels}"
                  :colspan="3 + (isEmployeesVisible ? 1 : 0)">{{level.name}}</td>
              </template>
              <template v-else>
                <td
                  class="level-selection"
                  v-if="isLinkedLevelSelectionVisible"
                  @click.stop.prevent="selectLinkedLevel(level)">
                  <checkbox :checked="selectedLinkedLevel && level.id == selectedLinkedLevel.id" />
                </td>
                <td class="level-name" :class="{editable: !isReorderingLevels}">{{ level.name}}<template v-if="isSandboxDebug"> ({{level.rank}})</template></td>
                <td class="level-employees" v-if="isEmployeesVisible">
                  <employees-badges
                    class="small"
                    :employees="level.employees"
                    :clickable="false" />
                </td>
                <td
                  class="level-is-hidden">
                  <span
                    class="icon-hide"
                    :title="$t('grid.structure.hideLevel')"
                    @click.stop.prevent="toggleLevelVisibility(level)" />
                </td>
                <td
                  class="level-arrow"
                  v-if="isLinkedLevelSelectionVisible"
                  @click.stop.prevent="selectLinkedLevel(level)">
                  <span v-if="selectedLinkedLevel && level.id == selectedLinkedLevel.id">›</span>
                </td>
              </template>
            </tr>
          </draggable>
          <tr class="row clickable" v-if="isEmployeesVisible && loneLevel" @click="alertLoneEmployee">
            <td v-if="isLinkedLevelSelectionVisible"></td>
            <td class="level-lone">
              {{ loneLevel.name }}
            </td>
            <td class="level-employees">
              <employees-badges
                class="small"
                :employees="loneLevel.employees"
                :clickable="false" />
            </td>
            <td></td>
            <td v-if="isLinkedLevelSelectionVisible"></td>
          </tr>
        </table>
      </div>
      <div class="table-actions" v-if="isReorderingLevels">
        <button class="light-button" @click="toggleLevelReordering" v-t="'common.validate'"></button>
      </div>
      <div class="table-actions" v-else key="default">
        <button class="light-button" @click="toggleLevelReordering" v-t="'grid.structure.reorder'"></button>
        <button v-if="isLevelGroupAvailable" class="light-button" @click="addLevelGroup" v-t="'grid.structure.group.addGroup'"></button>
        <button class="light-button" @click="addLevel">{{getAddLevelAction(component.ref)}}</button>
      </div>
    </div>
    <div class="component-domains" v-if="domains">
      <div class="table-border">
        <table class="blue">
          <tr class="row">
            <th>
              {{ $tc('grid.structure.domains', domains.length, {count: domains.length}) }}
            </th>
          </tr>
          <tr class="row active" v-if="component.hasSplitDomains && linkedLevel">
            <td>
              {{ linkedLevel.name }}
            </td>
          </tr>
          <draggable
            v-model="domainsModel"
            tag="tbody"
            :disabled="!isReorderingDomains">
            <tr
              v-for="domain in domains"
              :key="domain.id"
              class="row clickable"
              :class="{
                'zoom-in': highlightDomain && highlightDomain.id === domain.id,
                draggable: isReorderingDomains
              }"
              @click="openDomainModal($event, domain, enrichedLevels)">
              <td class="level-name" :class="{editable: !isReorderingDomains}">{{ domain.name}}<template v-if="isSandboxDebug"> ({{domain.index}})</template></td>
            </tr>
          </draggable>
        </table>
      </div>
      <div class="table-actions" v-if="isReorderingDomains">
        <button class="light-button" @click="toggleDomainReordering" v-t="'common.validate'"></button>
      </div>
      <div class="table-actions" v-else key="default">
        <button class="light-button" @click="toggleDomainReordering" v-t="'grid.structure.reorder'"></button>
        <button class="light-button" @click="addDomain" v-t="'grid.structure.addDomain'"></button>
      </div>
    </div>
    <div>
      <GridStructureComponentLevelsEditor
        v-for="linkedComponent in component.linkedComponents"
        :key="linkedComponent.id"
        :component="linkedComponent"
        :linkedLevel="selectedLinkedLevel" />
    </div>
    <modal :visible="!!modalPayload" @close="closeModal">
      <div class="modal">
        <component
          :is="modalComponent"
          :payload="modalPayload"
          :isGridGenerated="isGridGenerated"
          :isGridStructureEditor="true"
          @close="closeModal" />
      </div>
    </modal>
    <modal class="overflow-visible" :visible="isLevelFormVisible" @close="isLevelFormVisible = false">
      <GridLevelForm
        :component="component"
        :linkedLevel="linkedLevel"
        @close="isLevelFormVisible = false"
        @add="levelAdded" />
    </modal>
  </div>
</template>

<script>
import GridComponentEditor from '@components/grid/editor/GridComponentEditor'
import GridDomainEditor from '@components/grid/editor/GridDomainEditor'
import GridLevelEditor from '@components/grid/editor/GridLevelEditor'
import GridLevelForm from '@components/grid/editor/GridLevelForm'
import Modal from '@components/commons/Modal'
import Checkbox from '@components/commons/Checkbox'
import EmployeesBadges from '@components/employees/EmployeesBadges'
import Draggable from 'vuedraggable'
import { getLevelName, getLinkedLevels, getVisibleLevels } from '@/utils/grid'
import { componentHasSkills } from '@/utils/skills'
import { mapGetters } from 'vuex'

function buildGroup(level, name = null) {
  return {
    ...level,
    id: [level.id, 'group'].join(),
    isGroup: true,
    name: name || level.group
  }
}

export default {
  name: 'GridStructureComponentLevelsEditor',
  components: {
    Checkbox,
    Draggable,
    EmployeesBadges,
    GridComponentEditor,
    GridDomainEditor,
    GridLevelEditor,
    GridLevelForm,
    Modal
  },
  props: {
    component: Object,
    linkedLevel: Object
  },
  data() {
    return {
      isReorderingLevels: false,
      isReorderingDomains: false,
      isLevelFormVisible: false,
      highlightLevel: null,
      highlightDomain: null,
      selectedLinkedLevel: null,
      modalComponent: null,
      modalPayload: null
    }
  },
  computed: {
    ...mapGetters({
      isGridGenerated: 'onboarding/isGridGenerated',
      employees: 'employees/getGridEmployees',
      getEmployee: 'employees/getEmployee',
      employeeWages: 'sandbox/employeeWages',
      getDomains: 'sandbox/domains',
      isSandboxDebug: 'sandbox/isSandboxDebug'
    }),
    hasSkills() {
      return componentHasSkills(this.component)
    },
    isEmployeesVisible() {
      return !this.hasSkills && !this.linkedLevel
    },
    isLinkedLevelSelectionVisible() {
      return !!this.component.linkedComponents && this.component.linkedComponents.length
    },
    enrichedLevelsAndLoneLevel() {
      let loneEmployees = this.employees
      let loneLevel = null
      let enrichedLevels

      if (this.isEmployeesVisible) {
        enrichedLevels = this.component.levels.map(level => {
          const employees = this.employeeWages.reduce((memo, wage) => {
            if (!wage.hasLevels) {
              loneEmployees = loneEmployees.filter(e => e.id !== wage.employeeId)
            }
            else if (wage.levelIds && wage.levelIds.includes(level.id)) {
              const employee = this.getEmployee(wage.employeeId)
              if (employee) {
                memo.push(employee)
                loneEmployees = loneEmployees.filter(e => e.id !== employee.id)
              }
            }
            return memo
          }, [])
          return { ...level, employees }
        }).filter(l => !this.linkedLevel || !l.linkedLevelId || l.linkedLevelId === this.linkedLevel.id)

        if (loneEmployees.length) {
          loneLevel = {
            name: this.$t('grid.editor.toBePositioned'),
            employees: loneEmployees
          }
        }
      }
      else {
        enrichedLevels = getLinkedLevels(this.component.levels, this.linkedLevel)
      }

      return { enrichedLevels, loneLevel }
    },
    enrichedLevels() {
      return this.enrichedLevelsAndLoneLevel.enrichedLevels
    },
    loneLevel() {
      return this.enrichedLevelsAndLoneLevel.loneLevel
    },
    enrichedLevelsAndGroups: {
      get() {
        return this.enrichedLevels.reduce((memo, level, i) => {
          if (level.group && (i === 0 || this.enrichedLevels[i - 1].group !== level.group)) {
            memo.push(buildGroup(level))
          }
          memo.push(level)
          return memo
        }, [])
      },
      set(value) {
        const orderedLevelIds = value.filter(({ isGroup }) => !isGroup).map(({ id }) => id)
        const component = this.component
        const hasGroups = orderedLevelIds.length !== value.length
        if (hasGroups) {
          // Apply group by iterating on following levels
          let currentGroupName = null
          const levels = value.reduce((memo, levelOrGroup) => {
            if (levelOrGroup.isGroup) {
              currentGroupName = levelOrGroup.name
            }
            else {
              if (levelOrGroup.group !== currentGroupName) {
                memo.push({ id: levelOrGroup.id, name: levelOrGroup.name, group: currentGroupName })
              }
            }
            return memo
          }, [])
          this.$store.dispatch('sandbox/updateLevelsGroup', levels)
        }
        this.$store.dispatch('sandbox/reorderLevel', { component, orderedLevelIds })
      }
    },
    isLevelGroupAvailable() {
      return this.component.ref === 'role'
    },
    hasLevelGroups() {
      return !!this.enrichedLevelsAndGroups.find(({ isGroup }) => isGroup)
    },
    domains() {
      return this.hasSkills ? this.getDomains(this.component.id, this.linkedLevel && this.linkedLevel.id) : null
    },
    domainsModel: {
      get() {
        return this.domains.map(domain => domain.id)
      },
      set(orderedDomainIds) {
        const component = this.component
        this.$store.dispatch('sandbox/reorderDomain', { component, orderedDomainIds })
      }
    }
  },
  methods: {
    getLevelName,
    getAddLevelAction(ref) {
      return getLevelName(ref, 1).replace('1', this.$t('grid.structure.addA'))
    },
    initModel() {
      if (this.component.levels.length) {
        if (!this.selectedLinkedLevel || !this.enrichedLevels.find(l => l.id === this.selectedLinkedLevel.id)) {
          this.selectedLinkedLevel = getVisibleLevels(this.enrichedLevels)[0] || this.enrichedLevels[0]
        }
      }
    },
    resetHighlight() {
      this.highlightLevel = false
      this.highlightDomain = false
    },
    selectLinkedLevel(level) {
      this.resetHighlight()
      this.selectedLinkedLevel = level
    },
    toggleLevelReordering() {
      this.resetHighlight()
      this.isReorderingLevels = !this.isReorderingLevels
    },
    toggleDomainReordering() {
      this.resetHighlight()
      this.isReorderingDomains = !this.isReorderingDomains
    },
    toggleLevelVisibility(level) {
      if (!level.isHidden && level.employees && level.employees.length) {
        alert(this.$t('grid.structure.hiddenWarning'))
      }
      this.$store.dispatch('sandbox/updateLevel', { ...level, isHidden: !level.isHidden })
    },
    openComponentModal(_event) {
      const component = this.component
      this.modalComponent = 'gridComponentEditor'
      this.modalPayload = { component }
    },
    openLevelModal(_event, level) {
      if (!this.isReorderingLevels) {
        const component = this.component
        const levels = [level]
        this.modalComponent = 'gridLevelEditor'
        this.modalPayload = { component, levels }
      }
    },
    openDomainModal(_event, domain, levels) {
      if (!this.isReorderingDomains) {
        const component = this.component
        this.modalComponent = 'gridDomainEditor'
        this.modalPayload = { component, domain, levels }
      }
    },
    closeModal() {
      this.modalPayload = null
    },
    alertLoneEmployee() {
      alert(this.$t('grid.structure.loneExplanation'))
    },
    async addLevel(event) {
      if (!event.altKey) {
        this.isLevelFormVisible = true
      }
      else {
        // Legacy addLevel
        const name = window.prompt(this.$t('sandbox.addLevelConfirmation') + (event.altKey ? '\n\nLe palier sera également créé pour tous les parents.' : ''))
        if (!name) {
          return
        }
        const component = this.component
        const linkedLevelId = this.linkedLevel ? this.linkedLevel.id : undefined
        const rank = getLinkedLevels(component.levels, linkedLevelId).length
        const group = component.levels.length ? component.levels[component.levels.length - 1].group : null
        const level = { name, rank, group, linkedLevelId }
        this.isLoading = true
        this.highlightLevel = await this.$store.dispatch('sandbox/createLevel', { component, level })
        this.isLoading = false
      }
    },
    levelAdded(levelModel) {
      const level = this.enrichedLevelsAndGroups.find(l => l.name === levelModel.name)
      if (level) {
        this.highlightLevel = level
        if (this.isLinkedLevelSelectionVisible) {
          this.selectedLinkedLevel = level
        }
      }
    },
    async addDomain() {
      const name = window.prompt(this.$t('sandbox.addDomainConfirmation'))
      if (!name) {
        return
      }
      const component = this.component
      const index = this.domains.length
      const linkedLevelId = component.hasSplitDomains && this.linkedLevel ? this.linkedLevel.id : undefined
      const domain = { name, index, linkedLevelId }
      this.isLoading = true
      this.highlightDomain = await this.$store.dispatch('sandbox/createDomain', { component, domain })
      this.isLoading = false
    },
    async addLevelGroup() {
      const name = window.prompt(this.$t('grid.structure.group.addGroupConfirmation'))
      if (!name) {
        return
      }
      const levels = this.enrichedLevels
      let eligibleLevel
      for (let i = levels.length - 1; i >= 1; i--) {
        const level = levels[i]
        if (level.group === levels[i - 1].group) {
          eligibleLevel = level
          break
        }
      }
      if (eligibleLevel) {
        const enrichedLevelsAndGroups = [...this.enrichedLevelsAndGroups]
        const index = enrichedLevelsAndGroups.findIndex(({ id }) => id === eligibleLevel.id)
        const group = buildGroup(eligibleLevel, name)
        enrichedLevelsAndGroups.splice(index, 0, group)
        this.enrichedLevelsAndGroups = enrichedLevelsAndGroups
        this.toggleLevelReordering()
        this.highlightLevel = group
      }
      else {
        alert(this.$t('grid.structure.group.groupLimitError'))
        this.toggleLevelReordering()
      }
    },
    renameLevelGroup(group) {
      const name = window.prompt(this.$t('grid.structure.group.renameGroupConfirmation'), group.name)
      if (!name) {
        return
      }
      const levels = this.enrichedLevels.filter(l => l.group === group.name).map(l => ({
        id: l.id,
        name: l.name,
        group: name
      }))
      this.$store.dispatch('sandbox/updateLevelsGroup', levels)
    }
  },
  watch: {
    component: 'initModel',
    linkedLevel: 'initModel'
  },
  created() {
    this.initModel()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/button.scss";
@import "./src/styles/table.scss";

.component-levels-flex {
  display: flex;

  &.sticky {
    position: sticky;
    top: 0;
  }
}

.component-levels,
.component-domains {
  background: white;
  padding: 0.75em 1.25em 0.5em 0;

  .table-border {
    display: inline-block;
    border: 1px solid $graph-outer-border-color;
    border-radius: $border-radius;
    box-shadow: 1px 1.5px 0 rgba(0, 0, 0, 0.03);
    margin-bottom: 0.25em;
  }

  table {
    min-width: 115px;
    @include font-small-size;

    th,
    td {
      @include line-regular-height;
      padding: 0.5em;
      border-top: 1px solid $graph-outer-border-color;
    }
  }

  .row.group {
    background-color: lighten($graph-lightblue-color, 1) !important;

    .level-name {
      @include font-smaller-size;
      font-weight: 550;
      text-transform: uppercase;
    }
  }

  .row.draggable {
    cursor: ns-resize;
  }

  .row.active,
  .row.clickable:hover {
    &::v-deep .group-badge {
      background: darken($graph-blue-color, 2);
    }
  }

  .level-linked-level {
    color: $light-text-color;
    width: 150px;
  }

  .level-name,
  .level-linked-level {
    white-space: normal;
  }

  .level-arrow {
    @include font-bold;
    @include font-bigger-size;
    opacity: 0.8;
    line-height: 0;
    padding: 0.5rem 0.5rem 0.5rem 0;
  }

  .level-name {
    max-width: 50%;

    &::before {
      content: " ";
      display: inline-block;
      width: 0;
      height: 0.8em;
      background: transparent url(~@/assets/drag.svg) no-repeat right center;
      background-size: contain;
      vertical-align: baseline;
      margin-right: 0;
      transition: width 200ms linear, margin-right 200ms linear;
    }

    &::after {
      content: " ";
      display: inline-block;
      width: 0.8em;
      height: 0.8em;
      background: transparent url(~@/assets/edit.svg) no-repeat right center;
      background-size: contain;
      vertical-align: baseline;
      margin-left: 0.4em;
      transition: width 200ms linear, margin-left 200ms linear;
    }

    &:not(.editable) {
      &::before {
        width: 0.8em;
        margin-right: 0.4em;
      }

      &::after {
        width: 0;
        margin-left: 0;
      }
    }
  }

  .level-selection {
    text-align: center;
    padding-right: 0;

    .checkbox {
      position: relative;
      left: 3px;
    }
  }

  .level-lone {
    color: $light-text-color;
  }

  .level-employees {
    text-align: left;
    padding: 0 0.5em;
    color: $text-color;
  }

  .row .icon-hide {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: transparent url(~@/assets/icon-hide.svg) no-repeat center center;
    vertical-align: middle;
    opacity: 0;

    &:hover {
      opacity: 1;
    }
  }

  .row.hidden {
    .level-name,
    .level-employees {
      opacity: 0.6;
    }

    .icon-hide {
      opacity: 0.8;
    }
  }

  .row:hover:not(.hidden) .icon-hide:not(:hover) {
    opacity: 0.5;
  }

  .table-actions {
    min-width: 120px;
    min-height: 50px;
  }

  .table-actions .light-button {
    display: block;
    text-align: left;
    line-height: 1.9em;
    background: white;
    color: $light-text-color;
    text-transform: none;
    @include font-small-size;
    @include font-regular;
    padding: 0 0.5em;
    width: 100%;
    border-radius: $border-radius;

    &:hover {
      background: $graph-lightblue-color;
      color: $graph-darkblue-color;
    }
  }
}

.modal {
  padding: 20px;
  padding-bottom: 0;
  width: 750px;

  & > div {
    padding-bottom: 10px;
  }
}
</style>
