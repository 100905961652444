<template>
  <div v-if="flatComponent">
    <button
      class="collapse-toggle-button small-button blue"
      @click="toggleCollapsedLevels" v-t="collapsedLevelRanks.length ? 'common.expandAll' : 'common.collapseAll'"></button>
    <p class="no-margin-top">
      {{$t('wageCalculator.chooseSkills')}}
    </p>
    <div
      v-for="level in visibleLevels"
      :key="level.id"
      class="level-block">
      <div>
        <div class="level-header">
          <Checkbox
            class="level-name"
            :checked="isLevelReached(level)"
            :disabled="disabled"
            @click.native="selectLevel($event, level)">
            {{level.name}}
          </Checkbox>
          <div class="level-delta-labels">
            <span
              v-if="showDelta && referenceLevel && selectedLevelModel.name != referenceLevel.name && level.name === referenceLevel.name"
              v-t="'wageCalculator.currentLevelLong'">
            </span>
            <span
              v-if="showDelta && referenceLevel && selectedLevelModel.name != referenceLevel.name && level.name === selectedLevelModel.name"
              class="new"
              v-t="'wageCalculator.newLevelLong'">
            </span>
          </div>
          <div></div>
          <div class="level-progress">
            <WageLevelQualificationProgress
              :level="level"
              :selectedSkills="selectedSkillsModel"
              :reached="isLevelReached(level)"
              :next="isNextLevel(level)"
              :hasManualLevels="config.hasManualLevels"
              @click.native="toggleLevelSelectedSkills(level)" />
          </div>
          <div class="level-value">{{ getFormattedLevelValue(level) }}</div>
          <div
            class="level-collapse-toggle-button"
            :class="{collapsed: isLevelCollapsed(level)}"
            @click="toggleCollapsedLevel(level)"></div>
        </div>
        <div v-if="!isLevelCollapsed(level)">
          <MarkdownText class="level-description small" v-model="level.description" />
          <ul class="skills">
            <template v-for="(skill, i) in level.skills">
              <li class="domain"
                :key="skill.id + '-domain'" v-if="i <= 0 || level.skills[i - 1].domain.name != skill.domain.name">
                {{skill.domain.name}}
                <Tooltip class="gray-icon" :message="skill.domain.description" />
              </li>
              <li :key="skill.id" class="skill">
                <CheckableSkill
                  :skill="skill"
                  :selectedSkills="selectedSkillsModel"
                  :selectedSkillValues="selectedSkillValuesModel"
                  @selectIsChecked="selectIsChecked"
                  :disabled="disabled"
                  :hasSkillValues="hasSkillValues" />
              </li>
            </template>
          </ul>
          <p v-if="!level.skills.length" class="level-missing-skills" v-t="'wageCalculator.pleaseDefineSkills'"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getReachedLevel,
  selectLevelSkills,
  toggleLevelSelectedSkills,
  getFlatComponent,
  isLevelSelected,
  applySelectedSkills
} from '@/utils/skills'
import CheckableSkill from '@components/commons/CheckableSkill.vue'
import Checkbox from '@components/commons/Checkbox.vue'
import MarkdownText from '@components/commons/MarkdownText.vue'
import Tooltip from '@components/commons/Tooltip.vue'
import WageLevelQualificationProgress from '@components/wage/WageLevelQualificationProgress.vue'
import { getVisibleLevels } from '@/utils/grid'

export default {
  components: {
    CheckableSkill,
    Checkbox,
    MarkdownText,
    Tooltip,
    WageLevelQualificationProgress
  },
  props: {
    // A component generated by getWageDetails
    component: Object,
    // An optional parentComponent, generated by getWageDetails
    parentComponent: Object,
    referenceLevel: Object,
    disabled: Boolean,
    // Used in formattedLevelValue
    wageDetails: Object,
    showDelta: Boolean,
    showSalary: Boolean
  },
  data() {
    return {
      selectedSkillsModel: [],
      selectedSkillValuesModel: {},
      selectedLevelModel: null,
      collapsedLevelRanks: []
    }
  },
  watch: {
    'component.selectedSkills': 'initModel',
    'component.selectedLevel': 'initModel',
    'component.id': 'initCollapsedLevelRanks'
  },
  computed: {
    flatComponent() {
      return getFlatComponent(this.component)
    },
    config() {
      return this.flatComponent ? this.flatComponent.config : {}
    },
    visibleLevels() {
      return getVisibleLevels(this.flatComponent && this.flatComponent.levels, this.selectedLevelModel)
    },
    hasSkillValues() {
      return this.component.hasSkillValues
    }
  },
  methods: {
    initModel() {
      this.selectedSkillsModel = [...(this.component.selectedSkills || [])]
      this.selectedSkillValuesModel = { ...(this.component.selectedSkillValues || {}) }
      this.selectedLevelModel = this.component.selectedLevel || {}
    },
    initCollapsedLevelRanks() {
      this.collapsedLevelRanks = this.component.levels.reduce((memo, level) => {
        if (!this.isNextLevel(level)) {
          memo.push(level.rank)
        }
        return memo
      }, [])
    },
    isLevelReached(level) {
      return level.rank <= this.selectedLevelModel.rank
    },
    isNextLevel(level) {
      if (this.flatComponent.levels.length) {
        const isFirstLevelSelected = isLevelSelected(this.flatComponent.levels[0], this.selectedSkillsModel)

        if (isFirstLevelSelected) {
          return level.rank === this.selectedLevelModel.rank + 1
        }
        else {
          return level.rank === 0
        }
      }
      else {
        return false
      }
    },
    isLevelCollapsed(level) {
      return this.collapsedLevelRanks.includes(level.rank)
    },
    selectLevel(event, level) {
      event.preventDefault()
      if (this.disabled) {
        return
      }
      if (event.altKey) {
        return this.$emit('resetLevel')
      }
      if (this.config.hasManualLevels) {
        this.selectedLevelModel = this.component.levels.find(l => l.id === level.id)
      }
      else {
        this.selectedSkillsModel = selectLevelSkills(this.flatComponent, this.selectedSkillsModel, this.selectedLevelModel, level)
        this.selectedSkillValuesModel = applySelectedSkills(this.selectedSkillValuesModel, this.selectedSkillsModel)
        this.selectedLevelModel = getReachedLevel(this.flatComponent, this.selectedSkillsModel, this.component.levels)
      }
      this.onChange()
    },
    selectIsChecked({ id, isChecked, skillValue }) {
      if (isChecked && !this.selectedSkillsModel.includes(id)) {
        this.selectedSkillsModel.push(id)
      }
      else if (!isChecked && this.selectedSkillsModel.includes(id)) {
        this.selectedSkillsModel.splice(this.selectedSkillsModel.indexOf(id), 1)
      }
      if (skillValue !== undefined) {
        this.selectedSkillValuesModel[id] = skillValue
      }
      this.onSkillsChange()
    },
    toggleLevelSelectedSkills(level) {
      this.selectedSkillsModel = toggleLevelSelectedSkills(level, this.selectedSkillsModel)
      this.selectedSkillValuesModel = applySelectedSkills(this.selectedSkillValuesModel, this.selectedSkillsModel)
      this.onSkillsChange()
    },
    onSkillsChange() {
      if (!this.config.hasManualLevels) {
        this.selectedLevelModel = getReachedLevel(this.flatComponent, this.selectedSkillsModel, this.component.levels)
      }
      this.onChange()
    },
    onChange() {
      // Update skills on component directly
      this.component.selectedSkills = [...this.selectedSkillsModel]
      this.component.selectedSkillValues = { ...this.selectedSkillValuesModel }
      this.$emit('selectLevel', this.selectedLevelModel)
    },
    toggleCollapsedLevels() {
      const levels = this.flatComponent.levels.map(l => l.rank)
      if (this.collapsedLevelRanks.length) {
        this.collapsedLevelRanks = []
      }
      else {
        this.collapsedLevelRanks = levels
      }
      // Tell WageCalculator's ResizablePanel to resize
      this.$parent.$parent.$emit('resize')
    },
    toggleCollapsedLevel(level) {
      if (this.collapsedLevelRanks.includes(level.rank)) {
        this.collapsedLevelRanks.splice(this.collapsedLevelRanks.indexOf(level.rank), 1)
      }
      else {
        this.collapsedLevelRanks.push(level.rank)
      }
      // Tell WageCalculator's ResizablePanel to resize
      this.$parent.$parent.$emit('resize')
    },
    getFormattedLevelValue(level) {
      if (this.showSalary) {
        const { wageDetails } = this
        return this.$options.filters.formattedLevelValue(this.flatComponent, level, this.parentComponent, this.parentComponent && this.parentComponent.selectedLevel, 'qualification', { wageDetails })
      }
    }
  },
  created() {
    this.initModel()
    this.initCollapsedLevelRanks()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/block.scss";
@import "./src/styles/button.scss";

.level-block {
  @extend .progress-block;
}

.level-header {
  display: grid;
  grid-template-columns: auto auto 1fr auto auto auto;
  align-items: center;
}

.level-name {
  @include font-semibold;
  @include font-big-size;

  &::v-deep input[type="checkbox"]:checked + label {
    color: $graph-darkblue-color;
  }
}

.level-delta-labels {
  @include font-smaller-size;
  @include font-bold;
  text-transform: uppercase;
  padding-top: 4px;
  color: $light-text-color;
  margin-left: 1em;

  .new {
    color: lighten($graph-darkblue-color, 10);
  }

  span {
    margin-right: 0.5em;
  }
}
.level-progress {
  text-align: right;
}

.level-value:not(:empty) {
  @include font-semibold;
  @include font-tabular-numbers;
  color: $text-color;
  text-align: right;
  min-width: 75px;
  margin-left: 20px;
}

.level-collapse-toggle-button {
  cursor: pointer;
  display: inline-block;
  width: 55px;
  height: 22px;
  background: transparent url(~@/assets/triangle-down-active.svg) no-repeat top
    3px center;
  opacity: 0.7;
  background-size: 18px;
  margin-right: -16px;
  transition-property: transform;
  transition-duration: 300ms;

  &:hover {
    opacity: 1;
  }

  &.collapsed {
    transform: rotate(-90deg) translateX(-1px);
  }
}

.level-description {
  margin: 0.75em 0 0 30px !important;
}

ul.skills {
  list-style-type: none;
  padding: 0;
  margin: 0 0 0.25em;
}

.level-missing-skills {
  @include font-small-size;
  color: $light-text-color;
  background-color: white;
  border: 1px solid $graph-inner-border-color;
  border-radius: $border-radius;
  padding: 0.6em;
  margin-bottom: 0.3em;
}

.domain {
  @include font-small-size;
  @include font-semibold;
  @include line-regular-height;
  margin-top: 0.75em;
  margin-bottom: 0;
  margin-left: 30px;
}

.collapse-toggle-button {
  float: right;
  margin-top: -4px;
}
</style>
