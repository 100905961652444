import Vue from 'vue'
import sortBy from 'lodash.sortby'
import orderBy from 'lodash.orderby'
import api from '@/store/api'
import store from '@/store'
import i18n from '@/i18n'
import { getItem, setItem } from '@/utils/storage'
import { dateDiff, formattedMonthAndYear } from '@/utils/date'
import { getSequentialName } from '@/utils/string'
import { applyPercent } from '@/utils/math'
import { getVisibleLevels, getWageDetails, getWagesDetailsSummary } from '@/utils/grid'
import { debouncedAction, sequentialDispatch } from '@/utils/store'
import { getField } from '@/utils/employee'
import { getWageWithStartDate } from '@/utils/evolution'

const CW_WAGE_PLANS_SELECTION = 'CW_WAGE_PLANS_SELECTION'

function buildWageModel(context, wagePlanId, employeeId, simulationModel) {
  simulationModel = simulationModel || {}
  const employee = context.rootGetters['employees/getEmployee'](employeeId)
  let wage = context.getters.getEmployeeWage(wagePlanId, employeeId)
  const grid = context.rootGetters['currentGrid/getCurrentGrid']

  if (!wage || simulationModel.kind === 'reset') {
    if (employee.currentWage) {
      wage = { ...employee.currentWage, employeeId }
      wage.explanation = null
      delete wage.startDate
    }
    else {
      wage = { ...context.rootState.employees.employeeModel.currentWage, employeeId }
      wage.hasLevels = false
    }
  }
  else {
    wage = { ...wage, employeeId }
  }
  if (simulationModel.kind === 'salary_raise') {
    const wageDetails = getWageDetails(grid, employee.currentWage)
    const salaryRaisePercent = simulationModel.salaryRaisePercent || 0
    // Calculation does not include target variable value, so the final percent increase may be off
    const salary = wageDetails.summary.salary.fixedValue || 0
    const overridenSalaryValue = applyPercent(salaryRaisePercent, salary)

    wage.overridenSalaryValue = overridenSalaryValue
  }
  else if (simulationModel.kind === 'next_level' && employee.currentWage) {
    const wageDetails = getWageDetails(grid, wage, { includeComponents: true })
    const selectedComponent = wageDetails.selectedComponents.find(c => c.id === simulationModel.component)
    if (selectedComponent) {
      const selectedLevel = selectedComponent.selectedLevel
      const nextSelectedLevel = getVisibleLevels(selectedComponent.levels).find(l => l.rank === selectedLevel.rank + 1)
      if (nextSelectedLevel) {
        wage.levelIds = wage.levelIds.filter(l => l !== selectedLevel.id).concat(nextSelectedLevel.id)
        wage.overridenSalaryValue = null
      }
    }
  }
  return wage
}

const initialState = () => {
  return {
    currentWagePlanName: null,
    wagePlans: {},
    wagePlansSelection: getItem(CW_WAGE_PLANS_SELECTION, {}),
    wagePlanModel: {
      name: null,
      wages: [],
      ignoredEmployees: [],
      budgetValue: null
    }
  }
}

const state = initialState()

const getters = {
  getWagePlans(state) {
    return sortBy(Object.values(state.wagePlans), 'createdAt')
  },
  isWagePlanSelected(state) {
    return ({ id }) => !!state.wagePlansSelection[id]
  },
  getSelectedWagePlans(_, { getWagePlans, isWagePlanSelected }) {
    return getWagePlans.filter(isWagePlanSelected)
  },
  getWagePlan(state) {
    return id => state.wagePlans[id]
  },
  getEmployeeWage(_, { getWagePlan }) {
    return (id, employeeId) => {
      const wagePlan = getWagePlan(id)
      return wagePlan.wages.find(w => w.employeeId === employeeId)
    }
  },
  getHighestEmployeeWage(_, { getWagePlans, isWagePlanSelected }) {
    return (employeeId) => {
      const wagePlanEmployee = getWagePlans.reduce((memo, wagePlan) => {
        if (isWagePlanSelected(wagePlan)) {
          const wage = wagePlan.wages.find(wage => wage.employeeId === employeeId)
          if (wage) {
            memo = memo || { wagePlan, wage, wages: [] }
            if (wage.salaryValue > memo.wage.salaryValue || wage.contractualVariableValue > memo.wage.contractualVariableValue) {
              memo.wagePlan = wagePlan
              memo.wage = wage
            }
            memo.wages.push(getWageWithStartDate(wage, wagePlan))
          }
        }
        return memo
      }, null)
      if (wagePlanEmployee) {
        wagePlanEmployee.wages = orderBy(wagePlanEmployee.wages, ['startDate'], ['desc'])
      }
      return wagePlanEmployee
    }
  },
  getWagePlanDetail(_state, { getWagePlan }, _rootState, rootGetters) {
    return id => {
      const wagePlan = getWagePlan(id)
      if (wagePlan) {
        console.time('getWagePlanDetail')
        const payroll = rootGetters['currentGrid/getPayroll']
        const wageDetailsByEmployeeId = wagePlan.wages.reduce((memo, wage) => {
          memo[wage.employeeId] = getWageDetails(null, wage, { salaryOnly: true, includeVariable: true })
          return memo
        }, {})
        const wageSummaries = rootGetters['employees/getEmployees'].map(employee => {
          const includedEmployee = wageDetailsByEmployeeId[employee.id]
          return includedEmployee ? includedEmployee.summary : getWageDetails(null, employee.currentWage, { salaryOnly: true, includeVariable: true }).summary
        })
        const wageSummary = wageSummaries.length ? getWagesDetailsSummary(wageSummaries) : null
        const postPayroll = wageSummary ? wageSummary.salary.value : 0
        const postPayrollRise = postPayroll - payroll
        const postPayrollRisePercent = payroll ? Math.abs(postPayrollRise / payroll) * 100 : 100

        const wagePlanDetail = {
          payroll,
          postPayroll,
          postPayrollRise,
          postPayrollRisePercent
        }
        console.timeEnd('getWagePlanDetail')
        return wagePlanDetail
      }
    }
  },
  getWagePlanExport(_, { getWagePlan }, __, rootGetters) {
    return id => {
      const wagePlan = getWagePlan(id)
      const header = [
        'employees.fields.firstName.name',
        'employees.fields.lastName.name',
        'employees.fields.department.name',
        'employees.fields.jobType.name',
        'employees.fields.salary.name',
        'employees.fields.contractualVariable.name',
        'wagePlans.export.postSalary',
        'wagePlans.export.postContractualVariable'
      ].map(key => i18n.t(key))
      return [header, ...sortBy(wagePlan.wages.map(wage => {
        const employee = rootGetters['employees/getEmployee'](wage.employeeId)
        const team = getField(employee, 'team', { store })
        const jobType = getField(employee, 'jobType', { store })
        const currentWageDetails = getWageDetails(null, employee.currentWage, { salaryOnly: true })
        const currentSalary = currentWageDetails.summary.salary.fixedValue
        const currentVariable = currentWageDetails.summary.salary.variableValue
        const postWageDetails = getWageDetails(null, wage, { salaryOnly: true })
        const postSalary = postWageDetails.summary.salary.fixedValue
        const postVariable = postWageDetails.summary.salary.variableValue
        return [employee.firstName, employee.lastName, team, jobType, currentSalary, currentVariable, postSalary, postVariable]
      }), row => [row[2], row[3]].join())]
    }
  },
  getEnrichedWagePlans(state, getters) {
    return getters.getWagePlans.map(wagePlan => {
      const wagePlanDetail = getters.getWagePlanDetail(wagePlan.id)
      return {
        ...wagePlan,
        ...wagePlanDetail
      }
    })
  },
  getWagePlanModel(state, { getWagePlans }) {
    return ignoreName => {
      const existingNames = getWagePlans.map(w => w.name)
      const name = getSequentialName(formattedMonthAndYear(), existingNames, ignoreName)
      return {
        ...state.wagePlanModel,
        name: name
      }
    }
  },
  getCurrentWagePlanName(state) {
    return state.currentWagePlanName
  }
}

const actions = {
  reset(context) {
    context.commit('reset')
  },
  init(context) {
    if (context.rootGetters['account/isAtLeastManager']) {
      return context.dispatch('getWagePlans')
    }
    else {
      return Promise.resolve()
    }
  },
  getWagePlans(context) {
    return api.get('/wage_plans')
      .then(({ data }) => {
        context.commit('setWagePlans', data)
        return data
      })
  },
  createWagePlan(context) {
    const wagePlan = context.getters.getWagePlanModel()
    return api.post('/wage_plans', wagePlan)
      .then(({ data }) => {
        context.commit('setWagePlan', data)
        return data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  setWagePlan(context, wagePlan) {
    context.commit('setWagePlan', wagePlan)
  },
  toggleWagePlanSelection(context, wagePlan) {
    context.commit('setWagePlanSelection', {
      id: wagePlan.id,
      isSelected: !context.getters.isWagePlanSelected(wagePlan)
    })
  },
  updateWagePlan(context, { id, name, budgetValue, startDate }) {
    return api.patch('/wage_plan/' + id, { name, budgetValue, startDate })
      .then(({ data }) => {
        context.commit('setWagePlan', data)
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  debouncedUpdateWagePlan: debouncedAction((context, wagePlan) => {
    if (context.getters.getWagePlan(wagePlan.id)) {
      return context.dispatch('updateWagePlan', wagePlan)
    }
  }, null, 'id'),
  removeWagePlanIfUnchanged(context, wagePlan) {
    const storedWagePlan = context.getters.getWagePlan(wagePlan.id)
    const { name, budgetValue } = context.getters.getWagePlanModel(wagePlan.name)
    if (storedWagePlan &&
      dateDiff(storedWagePlan.createdAt, new Date(), 'seconds') < 30 &&
      storedWagePlan.name === name &&
      storedWagePlan.budgetValue === budgetValue &&
      storedWagePlan.wages.length === 0) {
      context.commit('removeWagePlan', wagePlan)
      return context.dispatch('removeWagePlan', wagePlan)
    }
  },
  removeWagePlan(context, wagePlan) {
    return api.delete('/wage_plan/' + wagePlan.id)
      .then(() => {
        context.commit('removeWagePlan', wagePlan)
        return true
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  publishWagePlan(context, wagePlan) {
    return api.put('/wage_plan/publish/' + wagePlan.id)
      .then(() => {
        context.dispatch('employees/needsRefresh', null, { root: true })
        context.commit('removeWagePlan', wagePlan)
        return true
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  setEmployeeWage(context, { employee, wage, wagePlanId }) {
    return api.put('/wage_plan/' + wagePlanId + '/employee/' + employee.id + '/wage', wage)
      .then(({ data }) => {
        context.commit('setEmployeeWage', { wagePlanId, wage: data })
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  async setWages({ commit, dispatch }, { wagePlanId, wages }) {
    try {
      const { data } = await api.put('/wage_plan/' + wagePlanId + '/wages', { wages })
      commit('setWages', { wagePlanId, wages: data })
    }
    catch (error) {
      dispatch('handleAPIError', error, { root: true })
    }
  },
  createEmployeeWage(context, { wagePlanId, employeeId, simulationModel }) {
    const wage = buildWageModel(context, wagePlanId, employeeId, simulationModel)
    return context.dispatch('setEmployeeWage', { employee: { id: employeeId }, wage, wagePlanId })
  },
  removeEmployeeWage(context, { wagePlanId, employeeId }) {
    return api.delete('/wage_plan/' + wagePlanId + '/employee/' + employeeId + '/wage')
      .then(() => {
        context.commit('removeEmployeeWage', { wagePlanId, employeeId })
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  async removeWages({ commit, dispatch }, { wagePlanId, wages }) {
    try {
      await api.delete('/wage_plan/' + wagePlanId + '/wages', { data: { wages } })
      commit('removeWages', { wagePlanId, wages })
    }
    catch (error) {
      dispatch('handleAPIError', error, { root: true })
    }
  },
  assignWages(context, { wagePlan, addedEmployeeIds, removedEmployeeIds, simulationModel }) {
    const wagePlanId = wagePlan.id
    const removedWages = (removedEmployeeIds || []).map(employeeId => ({ employeeId }))
    const addedWages = (addedEmployeeIds || []).map(employeeId => buildWageModel(context, wagePlanId, employeeId, simulationModel))
    const actions = [
      ['removeWages', { wagePlanId, wages: removedWages }],
      ['setWages', { wagePlanId, wages: addedWages }]
    ]
    return sequentialDispatch(context, actions)
  }
}

const mutations = {
  reset(state) {
    Object.assign(state, initialState())
  },
  setWagePlans(state, wagePlans) {
    // Skip if empty to avoid triggering observers
    if (!Object.values(state.wagePlans).length && !wagePlans.length) {
      return
    }
    state.wagePlans = wagePlans.reduce((memo, wagePlan) => {
      memo[wagePlan.id] = wagePlan
      return memo
    }, {})
  },
  setWagePlanSelection(state, { id, isSelected }) {
    if (isSelected) {
      Vue.set(state.wagePlansSelection, id, true)
    }
    else {
      Vue.delete(state.wagePlansSelection, id)
    }
    setItem(CW_WAGE_PLANS_SELECTION, state.wagePlansSelection)
  },
  setWagePlan(state, wagePlan) {
    Vue.set(state.wagePlans, wagePlan.id, wagePlan)
  },
  removeWagePlan(state, wagePlan) {
    Vue.delete(state.wagePlans, wagePlan.id)
  },
  setEmployeeWage(state, { wagePlanId, wage }) {
    const wagePlan = state.wagePlans[wagePlanId]
    const wageIndex = wagePlan.wages.findIndex(w => w.id === wage.id)
    if (wageIndex !== -1) {
      Vue.set(wagePlan.wages, wageIndex, wage)
    }
    else {
      wagePlan.wages.push(wage)
    }
    Vue.set(state.wagePlans, wagePlan.id, wagePlan)
  },
  setWages(state, { wagePlanId, wages }) {
    const wagePlan = state.wagePlans[wagePlanId]
    wages.forEach(wage => {
      const wageIndex = wagePlan.wages.findIndex(w => w.id === wage.id)
      if (wageIndex !== -1) {
        Vue.set(wagePlan.wages, wageIndex, wage)
      }
      else {
        wagePlan.wages.push(wage)
      }
    })
    Vue.set(state.wagePlans, wagePlan.id, wagePlan)
  },
  removeEmployeeWage(state, { wagePlanId, employeeId }) {
    const wagePlan = state.wagePlans[wagePlanId]
    const wageIndex = wagePlan.wages.findIndex(w => w.employeeId === employeeId)
    wagePlan.wages.splice(wageIndex, 1)
  },
  removeWages(state, { wagePlanId, wages }) {
    const wagePlan = state.wagePlans[wagePlanId]
    wages.forEach(({ employeeId }) => {
      const wageIndex = wagePlan.wages.findIndex(w => w.employeeId === employeeId)
      wagePlan.wages.splice(wageIndex, 1)
    })
  },
  setCurrentWagePlanName(state, name) {
    state.currentWagePlanName = name
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
