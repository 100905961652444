import { slugify } from '@/utils/string'
import { flattenedComponents } from '@/utils/grid'
import { getField } from '@/utils/employee'
import moment from 'moment'
import orderBy from 'lodash.orderby'
import groupBy from 'lodash.groupby'
import i18n from '@/i18n'

export const REPORTS = [
  {
    id: 'salaries',
    component: 'beeswarm-graph'
  },
  {
    id: 'workforce',
    component: 'combo-graph'
  },
  {
    id: 'payroll',
    component: 'payroll-graph'
  },
  {
    id: 'evolution',
    component: 'evolution-graph'
  },
  {
    id: 'grid',
    component: 'grid-graph'
  },
  {
    id: 'deviation',
    component: 'deviation-graph'
  },
  {
    id: 'table',
    component: 'table-graph'
  },
  {
    id: 'seniority',
    component: 'linear-regression-graph'
  },
  {
    id: 'ranges',
    component: 'distribution-graph'
  },
  {
    id: 'gender',
    component: 'gender-equality-index-graph'
  }
]

export const SCOPES = [
  // Fields
  'jobGroup',
  'jobType',
  'experience',
  'seniority',
  'managementLevel',
  'wageType',
  'city',
  'department',
  'fullDepartment',
  'manager',
  'contractType',
  'spc',
  'arrivalDateDuration'
  // Disabled until wage stats are generated:
  // 'salaryRaiseDuration'
]

export const DEFAULT_OPTIONS = {
  salary: 'current',
  includeContractualVariable: true,
  equivalenceLevels: {},
  recurrence: 'yearly',
  tableField1: 'jobType',
  tableField2: 'arrivalDateDuration'
}

export const SCOPE_NEXT_SELECTION = {
  jobGroup: 'jobType',
  jobType: 'experience'
}

const SCOPE_TO_REF = {
  jobType: 'role',
  managementLevel: 'management'
}

export const CW_GRAPH_SCOPE = 'CW_GRAPH_SCOPE'
export const CW_GRAPH_FILTERS = 'CW_GRAPH_FILTERS'
export const CW_GRAPH_OPTIONS = 'CW_GRAPH_OPTIONS'

const FEMALE_GENDER = ['femme', 'feminin', 'female', 'fem', 'f']
const MALE_GENDER = ['homme', 'masculin', 'male', 'mle', 'm', 'h']

export function classifyGender(gender) {
  switch (true) {
    case !gender: return 'male'
    case FEMALE_GENDER.includes(slugify(gender)): return 'female'
    case MALE_GENDER.includes(slugify(gender)): return 'male'
    default: return 'male'
  }
}

export function classifySalary(value) {
  const interval = 10000
  const precision = 1000
  const down = Math.floor(value / interval) * interval
  const up = down + interval

  return [down / precision, up / precision].join(',')
}

export function computeAge(birthdate) {
  if (birthdate) {
    return moment().diff(birthdate, 'years')
  }
  else {
    return 30
  }
}

export function computeSeniority(arrivalDate) {
  if (arrivalDate) {
    return moment().diff(arrivalDate, 'months')
  }
  else {
    return 12
  }
}

export function computeDuration(date, referenceDate = undefined) {
  const months = moment(referenceDate).diff(date, 'months')
  const years = Math.floor(months / 12)
  const isHalfYear = years < 3 ? (months / 12 - years) >= 0.5 : false
  let value, index
  if (years < 1 && !isHalfYear) {
    value = `-6 ${i18n.tc('date.duration.month', 6)}`
    index = 0
  }
  else if (years < 1 && isHalfYear) {
    value = `6 ${i18n.tc('date.duration.month', 6)}`
    index = 0.5
  }
  else {
    value = `${years} ${i18n.tc('date.duration.year', years)}${isHalfYear ? ' ½' : ''}`
    index = years
  }

  return {
    value,
    index
  }
}

function getComponentByScope(components, scope) {
  const ref = SCOPE_TO_REF[scope] || scope
  return components.find(c => c.ref === ref)
}

function buildScope(components, scope) {
  const component = getComponentByScope(components, scope)
  const title = component ? component.name : i18n.t(`dashboard.scopes.${scope}.title`)
  const options = []
  return { title, options, value: scope }
}

export function getScopes(referenceGrid, customFields) {
  const components = flattenedComponents(referenceGrid && referenceGrid.components)
  const scopes = SCOPES.map(scope => buildScope(components, scope))
    .concat(customFields
      .filter(field => field.scopes.includes('dashboard'))
      .map(field => ({
        title: field.name,
        options: [],
        value: field.id
      })))

  components.forEach((component, i) => {
    if (!scopes.find(scope => getComponentByScope([component], scope.value))) {
      scopes.splice(i + 1, 0, buildScope([component], component.ref))
    }
  })

  return scopes
}

// Employee can be employee or jobOfferProfile
export function getScopesValues(scopes, employee, wage) {
  return scopes.reduce((memo, { value: scope }) => {
    const { value, index } = getScopeValue(scope, employee, wage)
    memo[scope] = value
    memo[scope + 'Index'] = index
    return memo
  }, {})
}

export function getScopeValue(scope, employee, wage, options) {
  let value, index
  const ref = SCOPE_TO_REF[scope] || scope
  let level = wage && wage.qualification.find(q => q.ref === ref)
  if (scope === 'jobGroup') {
    level = wage && wage.qualification.find(q => q.ref === 'role')
    if (level) {
      level = { name: level.group || null, index: level.index }
    }
  }
  if (level) {
    value = level.name
    index = level.index
  }
  else if (scope === 'arrivalDateDuration') {
    ({ value, index } = computeDuration(getField(employee, 'arrivalDate'), (options || {}).referenceDate))
  }
  else if (scope === 'salaryRaiseDuration') {
    // TODO: Use employee stats
    if (employee.currentWage && employee.currentWage.startDate) {
      ({ value, index } = computeDuration(employee.currentWage.startDate, (options || {}).referenceDate))
    }
  }
  else if (scope === 'wageType') {
    if (wage && wage.hasLevels) {
      [value, index] = !wage.salaryDelta
        ? [i18n.t('wageCalculator.wageTypes.aligned.scopeValue'), 0]
        : [i18n.t('wageCalculator.wageTypes.adjusted.scopeValue'), 1]
    }
    else {
      [value, index] = [null, 2]
    }
  }
  else {
    value = getField(employee, scope, { fieldOnly: true, departmentOnly: true }) || null
  }
  return { value, index }
}

// Returns all values with default value at the bottom
export function getGroupedEmployeesAndScopeValues(employees, scope, sortByLength = false) {
  const defaultValue = i18n.t('dashboard.graphs.defaultValue')
  const groupedEmployees = groupBy(employees, e => e[scope] || defaultValue)
  const scopeValues = orderBy(
    Object.keys(groupedEmployees),
    sortByLength
      ? [v => groupedEmployees[v].length]
      : [v => v === defaultValue, v => groupedEmployees[v][0][scope + 'Index'], v => v],
    sortByLength
      ? ['desc']
      : ['asc', 'asc', 'asc']
  )
  return { groupedEmployees, scopeValues }
}

export function getScopeValues(employees, scope, sortByLength = false) {
  return getGroupedEmployeesAndScopeValues(employees, scope, sortByLength).scopeValues
}

export function isFiltered(filters, employee, simulationScope, simulationValue) {
  return filters.reduce((memo, { scope, values }) => {
    return memo && values.includes(scope === simulationScope ? simulationValue : employee[scope])
  }, true)
}

export function getWageGrid(wage, store) {
  if (!wage) {
    return
  }
  const onboardingGrid = store.getters['sandbox/onboardingGrid'] || {}
  const currentGrid = store.getters['currentGrid/getCurrentGrid'] || {}
  return wage.gridId === onboardingGrid.id ? onboardingGrid : currentGrid
}
