import { render, staticRenderFns } from "./PayrollGraph.vue?vue&type=template&id=57c2838c&scoped=true&"
import script from "./PayrollGraph.vue?vue&type=script&lang=js&"
export * from "./PayrollGraph.vue?vue&type=script&lang=js&"
import style0 from "./PayrollGraph.vue?vue&type=style&index=0&id=57c2838c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57c2838c",
  null
  
)

export default component.exports