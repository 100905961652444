<template>
  <div>
    <div class="teams-menu-wrapper print-hidden">
      <div class="teams-menu">
        <div class="button-group">
          <button
            class="secondary"
            v-t="'common.back'"
            @click="back" />
        </div>
      </div>
    </div>
    <div class="app-container">
      <div class="container root-container">
        <h1 v-t="'teams.form.title'"></h1>
        <p class="preserve-lines" v-t="'teams.form.intro'"></p>
        <p
          v-if="isSynchronizationEnabled"
          class="alert preserve-lines"
          v-html="$t('teams.form.synchronizedTeams', {serviceNames: enabledIdentityProviderNames})"></p>
        <div class="employees-editor">
          <EmployeesTableContainer
            :employees="allEmployees"
            :limit="10"
            name="teams-update"
            :preservedProps="['department', 'subDepartment', 'managerId', 'isExternal']"
            :title="$tc('employees.employees.count', allEmployees.length)">
            <template v-slot:company>
              <Checkbox
                class="hide-external-checkbox"
                v-model="hideExternals">
                {{ $t('teams.form.hideExternals') }}
              </Checkbox>
            </template>
            <template v-slot:group>
              <div class="employee-action-header">
                <div class="department">
                  <span v-t="'teams.form.columns.department.title'"></span>
                  <Tooltip class="gray-icon" :message="$t('teams.form.columns.department.description')" />
                </div>
                <div class="sub-department">
                  <span v-t="'teams.form.columns.subDepartment.title'"></span>
                  <span class="optional" v-t="'common.optional'"></span>
                  <Tooltip class="left gray-icon" :message="$t('teams.form.columns.subDepartment.description')" />
                </div>
                <div class="manager">
                  <span v-t="'teams.form.columns.manager.title'"></span>
                  <Tooltip class="left gray-icon" :message="$t('teams.form.columns.manager.description')" />
                </div>
                <div class="is-external">
                  <span v-t="'teams.form.columns.isExternal.title'"></span>
                  <Tooltip class="left gray-icon" :message="$t('teams.form.columns.isExternal.description')" />
                </div>
              </div>
            </template>
            <template v-slot:info="slotProps">
              <div class="employee-action">
                <div class="department">
                  <EmployeeField
                    :field="getFieldById('department', $store)"
                    :showLabel="false"
                    :placeholder="$t('common.select.placeholder')"
                    v-model="slotProps.employee.department"
                    @input="updateEmployee(slotProps.employee, 'department')" />
                </div>
                <div class="sub-department">
                  <EmployeeField
                    :field="getFieldById('subDepartment', $store)"
                    :showLabel="false"
                    :placeholder="$t('common.select.placeholder')"
                    v-model="slotProps.employee.subDepartment"
                    @input="updateEmployee(slotProps.employee, 'subDepartment')" />
                </div>
                <div class="manager">
                  <EmployeeField
                    :field="getFieldById('managerId', $store)"
                    :showLabel="false"
                    :placeholder="$t('common.select.placeholder')"
                    v-model="slotProps.employee.managerId"
                    @input="updateEmployee(slotProps.employee, 'managerId')" />
                </div>
                <div class="is-external">
                  <checkbox
                    v-model="slotProps.employee.isExternal"
                    @change="updateEmployee(slotProps.employee, 'isExternal')" />
                </div>
              </div>
            </template>
            <template v-slot:action>

            </template>
          </EmployeesTableContainer>
        </div>
        <p class="preserve-lines light-text no-margin-bottom" v-t="'teams.form.footer'" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getFieldById } from '@/utils/employee'
import Checkbox from '@components/commons/Checkbox.vue'
import Tooltip from '@components/commons/Tooltip.vue'
import EmployeeField from '@components/employees/EmployeeField.vue'
import EmployeesTableContainer from '@components/employees/EmployeesTableContainer.vue'

export default {
  components: {
    EmployeeField,
    EmployeesTableContainer,
    Checkbox,
    Tooltip
  },
  data() {
    return {
      hideExternals: true
    }
  },
  computed: {
    ...mapGetters({
      employees: 'employees/getEmployees',
      externals: 'employees/getExternals',
      isSynchronizationEnabled: 'synchronization/isSynchronizationEnabled',
      enabledIdentityProviderNames: 'synchronization/enabledIdentityProviderNames'
    }),
    allEmployees() {
      return [...this.employees, ...this.externals].filter(({ isExternal }) => !this.hideExternals || !isExternal)
    }
  },
  methods: {
    getFieldById,
    back() {
      this.$router.go(-1)
    },
    async refresh() {
      await this.$store.dispatch('orgChart/getEmployees')
      await this.$store.dispatch('employees/getEmployees')
    },
    async updateEmployee(employee, field) {
      const model = { id: employee.id, [field]: employee[field] }
      await this.$store.dispatch('employees/updateEmployee', model)
    }
  },
  beforeDestroy() {
    this.refresh()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/teams.scss";

.employees-editor {
  &::v-deep {
    .employees-table-container {
      margin-top: 0;
    }
    .column-action-slot {
      display: none;
    }
  }

  .hide-external-checkbox {
    @include font-normal-size;
    @include line-regular-height;
    @include font-regular;
  }

  .employee-action-header,
  .employee-action {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 45px;
  }

  .employee-action-header {
    width: 679px;
    @include font-tiny-size;
    @include font-bold;
    text-transform: uppercase;
    white-space: nowrap;

    .sub-department {
      padding-left: 5px;
    }

    .manager {
      padding-left: 10px;
    }

    .optional {
      display: inline-block;
      color: $light-text-color;
      margin-left: 0.5em;
    }

    // Tooltip theme
    .tooltip-container {
      @include font-small-size;
      margin-left: 3px;
      text-transform: none;

      &::v-deep.left .tooltip {
        background: white;
      }
    }
  }

  .is-external {
    text-align: center;
  }
}

// Vue-multiselect theme
.employee-field::v-deep {
  .multiselect:not(:hover):not(.multiselect--active) {
    .multiselect__single,
    .multiselect__tags {
      border-color: transparent !important;
      border-radius: 0 !important;
      background: transparent !important;
    }
  }
}
</style>
