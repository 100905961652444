<template>
  <div class="navigation-bar">
    <div :style="floorStyle" class="floor" />
    <template v-if="$$hasOpenAccess">
      <div class="navigation-section company-section">
        <h4>{{company.name}}</h4>
        <ul>
          <router-link
            to="/dashboard"
            tag="li"
            active-class="selected"
            v-t="'dashboard.title'" />
          <router-link
            to="/employees"
            tag="li"
            active-class="selected"
            v-t="'employees.title'" />
          <router-link
            v-if="$$hasPermission('teamsOrgChart') || $$hasPermission('teamsDirectory')"
            to="/teams"
            tag="li"
            active-class="selected"
            v-t="'teams.title'" />
          <router-link
            v-if="$$isManager"
            to="/profile"
            tag="li"
            active-class="selected"
            v-t="'employees.profile'" />
        </ul>
      </div>
      <div class="navigation-section remuneration-section">
        <h4 v-t="'remuneration.title'" />
        <ul>
          <router-link
            to="/grid"
            tag="li"
            class="grid"
            :class="{onboarding: onboardingProgress}"
            active-class="selected">
            {{$t('grid.title')}}
            <span class="progress" v-if="onboardingProgress">
              {{onboardingProgress}}
            </span>
          </router-link>
          <router-link
            v-if="$$isAtLeastManager && $$hasPermission('variablePlans')"
            to="/variable"
            tag="li"
            active-class="selected"
            v-t="'remuneration.variable.shortTitle'" />
          <router-link
            v-if="$$isAdmin"
            to="/benefits"
            tag="li"
            active-class="selected"
            v-t="'remuneration.benefits.shortTitle'" />
          <router-link
            v-if="$$hasPermission('reviews')"
            to="/reviews"
            tag="li"
            active-class="selected"
            v-t="'reviews.shortTitle'" />
        </ul>
      </div>
    </template>
    <template v-else>
      <div class="navigation-section company-section">
        <h4>{{company.name}}</h4>
        <ul>
          <router-link
            to="/profile"
            tag="li"
            active-class="selected"
            v-t="'employees.profile'">
          </router-link>
          <router-link
            v-if="$$hasPermission('teamsOrgChart') || $$hasPermission('teamsDirectory')"
            to="/teams"
            tag="li"
            active-class="selected"
            v-t="'teams.title'" />
        </ul>
      </div>
      <div v-if="currentGrid" class="navigation-section remuneration-section">
        <h4 v-t="'remuneration.title'" />
        <ul>
          <router-link
            to="/grid"
            tag="li"
            active-class="selected"
            v-t="'grid.title'" />
          <router-link
            v-if="$$hasPermission('reviews')"
            to="/reviews"
            tag="li"
            active-class="selected"
            v-t="'reviews.shortTitle'" />
        </ul>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      navigationElements: [],
      floorStyle: {}
    }
  },
  computed: {
    ...mapGetters({
      onboardingProgress: 'onboarding/getProgress',
      company: 'account/getCompany',
      currentGrid: 'currentGrid/getCurrentGrid'
    })
  },
  watch: {
    $route: {
      immediate: true,
      handler: function(newValue, _oldValue) {
        this.$nextTick(() => this.positionFloor())
      }
    }
  },
  mounted() {
    this.navigationElements = this.$children.map(c => c.$el)
  },
  methods: {
    positionFloor(animated = true) {
      setTimeout(() => {
        const selectedElement = this.navigationElements.find(el => el.classList.contains('selected'))

        if (selectedElement) {
          const navigationSectionElement = selectedElement.parentElement.parentElement
          const transitionType = this.floorStyle.opacity === 1 ? 'all' : 'opacity'

          this.floorStyle = {
            opacity: 1,
            transition: animated ? `${transitionType} 0.25s ease-out` : null,
            transform: `translateX(${selectedElement.offsetLeft + navigationSectionElement.offsetLeft}px)`,
            width: `${selectedElement.clientWidth}px`,
            background: getComputedStyle(navigationSectionElement.children[0]).color
          }
        }
        else {
          this.floorStyle = { ...this.floorStyle, opacity: 0 }
        }
      }, 90)
    }
  }
}
</script>

<style lang="scss" scoped>
.navigation-bar {
  position: relative;
  display: flex;
  background: white;
}

.floor {
  position: absolute;
  bottom: 0;
  height: 4px;
}

.navigation-section {
  position: relative;
  margin-right: 1em;
  min-width: 150px;

  h4 {
    pointer-events: none;
    position: absolute;
    margin: 0;
    width: 100%;
    @include font-tiny-size;
    @include font-bold;
    padding: 3px 10px;
    text-transform: uppercase;
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }
}

ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

li {
  @include font-smaller-size;
  @include font-bold;
  height: $header-height;
  letter-spacing: 1px;
  padding: 0 1em;
  padding-top: 32px;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
  font-feature-settings: "frac" 1;
  transition: background-color 0.25s ease;
}

.company-section {
  h4 {
    color: $graph-text-color;
    background: $graph-background-color;
  }

  li:hover:not(.selected) {
    background-color: $graph-background-color;
  }
}

.remuneration-section {
  h4 {
    color: $clearteal-color;
    background: $lightteal-color;
  }

  li:hover:not(.selected) {
    background-color: rgba($clearteal-color, 0.1);
  }
}

.onboarding {
  color: $clearteal-color;
  white-space: nowrap;

  .progress {
    @include font-small-size;
    margin-left: 0.25em;
  }
}
</style>
