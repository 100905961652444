import { render, staticRenderFns } from "./SettingsCompanyBillingView.vue?vue&type=template&id=2c7afd35&scoped=true&"
import script from "./SettingsCompanyBillingView.vue?vue&type=script&lang=js&"
export * from "./SettingsCompanyBillingView.vue?vue&type=script&lang=js&"
import style0 from "./SettingsCompanyBillingView.vue?vue&type=style&index=0&id=2c7afd35&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c7afd35",
  null
  
)

export default component.exports