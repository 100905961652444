import Vue from 'vue'
import api from '@/store/api'
import { sum } from 'd3'
import sortBy from 'lodash.sortby'
import { getSequentialName } from '@/utils/string'
import { getItem, setItem } from '@/utils/storage'
import {
  normalizedCandidate,
  normalizedCandidates,
  normalizedJobOffer
} from '@/store/schema'
import {
  getWageDetails,
  isWagesIdentical
} from '@/utils/grid'
import i18n from '@/i18n'
import { buildNormalizedDate } from '@/utils/date'

function generateJobOfferName(grid, wage) {
  const wageDetails = getWageDetails(grid, wage)
  const roleComponent = wageDetails.selectedComponents.find(({ ref }) => ref === 'role')
  const experienceComponent = wageDetails.selectedComponents.find(({ ref }) => ref === 'experience')
  if (roleComponent && experienceComponent) {
    return [
      roleComponent.selectedLevel.name,
      experienceComponent.selectedLevel.name
    ].join('   ')
  }
  else {
    return 'Poste à pourvoir   '
  }
}

function enrichCandidates(candidates, getCandidateDetail) {
  return candidates.map(candidate => {
    const candidateDetail = getCandidateDetail(candidate.id)
    return {
      ...candidate,
      ...candidateDetail
    }
  })
}

const CW_HIRING_PLANS_SELECTION = 'CW_HIRING_PLANS_SELECTION'

const initialState = () => {
  return {
    candidateList: [],
    hiringPlanList: [],
    jobOfferList: [],
    candidates: {},
    hiringPlans: {},
    jobOffers: {},
    hiringPlansSelection: getItem(CW_HIRING_PLANS_SELECTION, {}),
    currentCandidateName: '',
    currentJobOfferName: '',
    candidateModel: {
      firstName: '',
      lastName: '',
      birthdate: '',
      wageProposal: {
        hasLevels: true,
        explanation: '',
        levelIds: []
      }
    },
    jobOfferModel: {
      name: null,
      quantity: 1,
      hiringPlan: {
        name: 'Juillet 2019'
      },
      referenceWage: {
        hasLevels: true,
        explanation: '',
        levelIds: []
      }
    }
  }
}

const state = initialState()

const getters = {
  getCandidates(state) {
    return sortBy(state.candidateList
      .map(id => state.candidates[id])
      .filter(c => !c.rejected),
    c => [c.firstName, c.lastName].join())
  },
  getRejected(state) {
    return sortBy(state.candidateList
      .map(id => state.candidates[id])
      .filter(c => c.rejected),
    c => [c.firstName, c.lastName].join())
  },
  getCandidate(state) {
    return (id) => state.candidates[id]
  },
  getCurrentCandidateName(state) {
    return state.currentCandidateName
  },
  getCurrentJobOfferName(state) {
    return state.currentJobOfferName
  },
  getHiringPlans(state) {
    return sortBy(state.hiringPlanList.map(id => state.hiringPlans[id]), 'createdAt')
  },
  getHiringPlan(state) {
    return (id) => state.hiringPlans[id]
  },
  getSelectedHiringPlans(_state, { getHiringPlans, isHiringPlanSelected }) {
    return getHiringPlans.filter(isHiringPlanSelected)
  },
  getSelectedJobOffersProfiles(_state, { getJobOffersByHiringPlan, getSelectedHiringPlans }) {
    return getSelectedHiringPlans.reduce((memo, hiringPlan) => {
      const jobOffers = getJobOffersByHiringPlan(hiringPlan.id)
      jobOffers.forEach(jobOffer => {
        for (let i = 1; i <= jobOffer.quantity; i++) {
          memo.push({
            id: [jobOffer.id, i].join(),
            firstName: jobOffer.name,
            lastName: '#' + i,
            initials: jobOffer.name[0],
            arrivalDate: buildNormalizedDate(1),
            hiringPlan,
            jobOffer
          })
        }
      })
      return memo
    }, [])
  },
  getHiringPlanDetail(_state, { getHiringPlan, getJobOffersByHiringPlan }, _rootState, rootGetters) {
    return id => {
      const hiringPlan = getHiringPlan(id)
      if (hiringPlan) {
        const payroll = rootGetters['currentGrid/getPayroll']
        const grid = rootGetters['currentGrid/getCurrentGrid']
        const jobOffers = getJobOffersByHiringPlan(id)
        const salaries = sum(jobOffers.map(jobOffer => {
          const salary = getWageDetails(grid, jobOffer.referenceWage, { includeVariable: true }).summary.salary.value
          return jobOffer.quantity * salary
        }))
        const postPayroll = payroll + salaries
        const postPayrollRise = postPayroll - payroll
        const postPayrollRisePercent = payroll ? Math.abs(postPayrollRise / payroll) * 100 : 100

        return {
          jobOffers: sum(jobOffers, j => j.quantity),
          payroll,
          postPayroll,
          postPayrollRise,
          postPayrollRisePercent
        }
      }
    }
  },
  getEnrichedHiringPlans(state, { getHiringPlans, getHiringPlanDetail }) {
    return getHiringPlans.map(hiringPlan => {
      const hiringPlanDetail = getHiringPlanDetail(hiringPlan.id)
      return {
        ...hiringPlan,
        ...hiringPlanDetail
      }
    })
  },
  isHiringPlanSelected(state) {
    return ({ id }) => !!state.hiringPlansSelection[id]
  },
  getJobOffers(state) {
    return sortBy(state.jobOfferList.map(id => state.jobOffers[id]), 'name')
  },
  getJobOffer(state) {
    return (id) => state.jobOffers[id]
  },
  getJobOffersByHiringPlan(state, { getJobOffers }) {
    return (id) => getJobOffers.filter(j => j.hiringPlanId === id)
  },
  getEnrichedCandidatesByJobOffer(state, { getCandidates, getCandidateDetail }) {
    return (id) => enrichCandidates(getCandidates.filter(c => c.jobOffer === id), getCandidateDetail)
  },
  getEnrichedRejectedByJobOffer(state, { getRejected, getCandidateDetail }) {
    return (id) => enrichCandidates(getRejected.filter(c => c.jobOffer === id), getCandidateDetail)
  },
  getRemovableCandidatesCountByJobOffer(state, { getEnrichedCandidatesByJobOffer, getEnrichedRejectedByJobOffer }) {
    return (id) => getEnrichedCandidatesByJobOffer(id).length + getEnrichedRejectedByJobOffer(id).length
  },
  getJobOfferReferenceWages(state, { getJobOffers }) {
    return getJobOffers
      .map(jobOffer => jobOffer.referenceWage)
      .filter(w => w != null)
  },
  getCandidateDetail(state, { getCandidate }, rootState, rootGetters) {
    return id => {
      const candidate = getCandidate(id)
      if (candidate) {
        const grid = rootGetters['currentGrid/getCurrentGrid']
        const wageProposalDetails = getWageDetails(grid, candidate.wageProposal, { includeVariable: true })

        return {
          wageProposalDetails,
          proposedSalary: wageProposalDetails.summary.salary.value
        }
      }
    }
  },
  getCandidateModel(state) {
    return state.candidateModel
  },
  getJobOfferNameFromWage(_state, _getters, _rootState, rootGetters) {
    return (referenceWage) => {
      const grid = rootGetters['currentGrid/getCurrentGrid']
      return generateJobOfferName(grid, referenceWage)
    }
  },
  getJobOfferModel(state, { getHiringPlans }, _rootState, rootGetters) {
    const hiringPlanNames = getHiringPlans.map(h => h.name)
    const hiringPlanModel = state.jobOfferModel
    const name = getSequentialName(i18n.t('hiringPlans.hiringPlan'), hiringPlanNames, null, true)
    return { ...state.jobOfferModel, hiringPlan: { ...hiringPlanModel, name } }
  },
  isJobOfferUnique(state, { getJobOffersByHiringPlan }, _rootState, rootGetters) {
    return (jobOffer) => {
      const grid = rootGetters['currentGrid/getCurrentGrid']
      const jobOffers = getJobOffersByHiringPlan(jobOffer.hiringPlan.id)
      const identicalJobOffers = jobOffers.filter(j => isWagesIdentical(j.referenceWage, grid, jobOffer.referenceWage, grid))

      return !identicalJobOffers.length
    }
  }
}

const actions = {
  reset(context) {
    context.commit('reset')
  },
  init(context) {
    if (context.rootGetters['account/isAtLeastManager']) {
      return context.dispatch('getCandidates')
    }
    else {
      return Promise.resolve()
    }
  },
  getCandidates(context) {
    return api.get('/candidates')
      .then(response => {
        context.commit('setCandidates', response.data)
      })
      .catch(_ => {
        // Nothing
      })
  },
  addCandidate(context, candidate) {
    return api.post('/candidate', candidate)
      .then(({ data }) => {
        context.commit('setCandidate', data)
        return data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  updateCandidate(context, candidate) {
    return api.patch(`/candidate/${candidate.id}`, candidate)
      .then(({ data }) => {
        context.commit('setCandidate', data)
        return data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  rejectCandidate(context, candidate) {
    return context.dispatch('updateCandidateRejection', {
      candidate,
      rejected: true
    })
  },
  reconsiderCandidate(context, candidate) {
    return context.dispatch('updateCandidateRejection', {
      candidate,
      rejected: false
    })
  },
  updateCandidateRejection(context, { candidate, rejected }) {
    return api.patch(`/candidate/${candidate.id}`, { rejected })
      .then(({ data }) => {
        context.commit('setCandidate', data)
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  setWageProposal(context, { candidate, wageProposal }) {
    return api.put(`/candidate/${candidate.id}/wage/proposal`, wageProposal)
      .then(response => {
        context.commit('setCandidate', response.data)
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  updateWageProposal(context, { candidateId, wageProposal }) {
    const candidate = context.getters.getCandidate(candidateId)
    context.commit('setCandidate', { ...candidate, wageProposal })
  },
  setReferenceWage(context, { jobOffer, referenceWage }) {
    return api.put(`/job_offer/${jobOffer.id}/wage/reference`, referenceWage)
      .then(response => {
        context.commit('setJobOffer', response.data)
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  removeCandidate(context, candidate) {
    return api.delete(`/candidate/${candidate.id}`)
      .then(response => {
        context.commit('deleteCandidate', candidate)
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  acceptCandidate(context, { candidate, acceptParams }) {
    return api.put(`/candidate/${candidate.id}/accept`, acceptParams)
      .then(({ data }) => {
        context.commit('employees/addEmployee', data, { root: true })
        // Will reload everything on next load
        context.dispatch('init')
        return data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  addJobOffer(context, jobOffer) {
    return api.post('/job_offer', jobOffer)
      .then(({ data }) => {
        context.commit('setJobOffer', data)
        return data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  updateJobOffer(context, jobOffer) {
    return api.patch(`/job_offer/${jobOffer.id}`, jobOffer)
      .then(({ data }) => {
        context.commit('setJobOffer', data)
        return data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  removeJobOffer(context, { jobOffer, deleteCandidates }) {
    return api.delete(`/job_offer/${jobOffer.id}`, {
      data: { deleteCandidates }
    })
      .then(response => {
        context.commit('deleteJobOffer', { jobOffer, deleteCandidates })
        context.commit('removeHiringPlanIfNeeded', jobOffer.hiringPlanId)
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  updateHiringPlan(context, hiringPlan) {
    return api.patch(`/hiring_plan/${hiringPlan.id}`, hiringPlan)
      .then(({ data }) => {
        context.commit('setHiringPlan', data)
        return data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  toggleHiringPlanSelection(context, hiringPlan) {
    context.commit('setHiringPlanSelection', {
      id: hiringPlan.id,
      isSelected: !context.getters.isHiringPlanSelected(hiringPlan)
    })
  }
}

const mutations = {
  reset(state) {
    Object.assign(state, initialState())
  },
  setCandidates(state, candidates) {
    const n = normalizedCandidates(candidates)
    // Skip if empty to avoid triggering observers
    if (!state.candidateList.length && !n.result.candidates.length &&
      !state.jobOfferList.length && !n.result.jobOffers.length &&
      !state.hiringPlanList.length && !n.result.hiringPlans.length) {
      return
    }
    state.candidateList = n.result.candidates
    state.jobOfferList = n.result.jobOffers
    state.hiringPlanList = n.result.hiringPlans
    state.candidates = n.entities.candidates || {}
    state.jobOffers = n.entities.jobOffers || {}
    state.hiringPlans = n.entities.hiringPlans || {}
  },
  setCandidate(state, candidate) {
    const n = normalizedCandidate(candidate)
    for (const id in n.entities.candidates) {
      Vue.set(state.candidates, id, n.entities.candidates[id])
      if (!state.candidateList.includes(id)) {
        state.candidateList.push(id)
      }
    }

    // We do not set the jobOffer since it's not preloaded (missing referenceWage).
  },
  setCurrentCandidate(state, candidate) {
    state.currentCandidateName = candidate.firstName + ' ' + candidate.lastName
  },
  setCurrentJobOffer(state, jobOffer) {
    state.currentJobOfferName = jobOffer.name
  },
  setJobOffer(state, jobOffer) {
    const n = normalizedJobOffer(jobOffer)
    for (const id in n.entities.jobOffers) {
      Vue.set(state.jobOffers, id, n.entities.jobOffers[id])
      if (!state.jobOfferList.includes(id)) {
        state.jobOfferList.push(id)
      }
    }
    for (const id in n.entities.hiringPlans) {
      Vue.set(state.hiringPlans, id, n.entities.hiringPlans[id])
      if (!state.hiringPlanList.includes(id)) {
        state.hiringPlanList.push(id)
      }
    }
  },
  setHiringPlan(state, hiringPlan) {
    Vue.set(state.hiringPlans, hiringPlan.id, hiringPlan)
    if (!state.hiringPlanList.includes(hiringPlan.id)) {
      state.hiringPlanList.push(hiringPlan.id)
    }
  },
  deleteCandidate(state, candidate) {
    state.candidateList.splice(state.candidateList.indexOf(candidate.id), 1)
    Vue.delete(state.candidates, candidate.id)
  },
  deleteJobOffer(state, { jobOffer, deleteCandidates }) {
    state.jobOfferList.splice(state.jobOfferList.indexOf(jobOffer.id), 1)
    Vue.delete(state.jobOffers, jobOffer.id)
    Object.values(state.candidates).forEach(candidate => {
      if (candidate.jobOffer === jobOffer.id) {
        if (deleteCandidates) {
          state.candidateList.splice(state.candidateList.indexOf(candidate.id), 1)
        }
        else {
          Vue.set(candidate, 'jobOffer', null)
        }
      }
    })
  },
  removeHiringPlanIfNeeded(state, hiringPlanId) {
    const jobOffers = Object.values(state.jobOffers).filter(j => j.hiringPlanId === hiringPlanId)
    if (!jobOffers.length) {
      state.hiringPlanList.splice(state.hiringPlanList.indexOf(hiringPlanId), 1)
      Vue.delete(state.hiringPlans, hiringPlanId)
    }
  },
  setHiringPlanSelection(state, { id, isSelected }) {
    if (isSelected) {
      Vue.set(state.hiringPlansSelection, id, true)
    }
    else {
      Vue.delete(state.hiringPlansSelection, id)
    }
    setItem(CW_HIRING_PLANS_SELECTION, state.hiringPlansSelection)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
