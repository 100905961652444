/* eslint-disable no-irregular-whitespace */
import Vue from 'vue'
import i18n from '@/i18n'
import { isInterval, remunerationOperationSymbol, remunerationOperationUnit, valueFromOperation, getComponentScopeValue, isIntervalStepsScope } from '@/utils/grid'
import { capitalize } from '@/utils/string'
import { formatCurrency } from './utils/currency'

// Format level values dynamically based on component's config
// By default, experience level will look like this `× 1,10   33 000 €`
// With intervals, it'll look like this `78.5 – 103.0 k€`
// Context = `formula`, `grid` or `qualification`
// Options = `hideOperation: true`, `k: true`
function formattedLevelValue(component, level, linkedComponent, linkedLevel, context, options) {
  options = options || {}
  const formattedLevelValue = formattedRemuneration({ level, component }, 'salary', options)

  if (isInterval(component)) {
    // Interval grid mode
    if (component.ref === 'role') {
      return ''
    }
    else if (formattedLevelValue && linkedComponent && linkedLevel) {
      const wageDetails = options.wageDetails
      let minimumValue = level.minimumValue
      let maximumValue = level.maximumValue
      // Apply scoped component value
      if (wageDetails) {
        const wage = { hasLevels: wageDetails.components.length, levelIds: wageDetails.selectedComponents.map(c => c.selectedLevel && c.selectedLevel.id) }
        minimumValue = getComponentScopeValue(wageDetails.components, wage, minimumValue, 'intervalBounds')
        maximumValue = getComponentScopeValue(wageDetails.components, wage, maximumValue, 'intervalBounds')
      }
      // Round to k€
      minimumValue = Math.round(minimumValue / 100) / 10
      maximumValue = Math.round(maximumValue / 100) / 10

      const format = { minimumFractionDigits: 1 }
      if (minimumValue !== maximumValue) {
        return `${i18n.n(minimumValue, format)} – ${i18n.n(maximumValue, format)} k${i18n.currencySymbol}`
      }
      else {
        return `${i18n.n(minimumValue, format)} k${i18n.currencySymbol}`
      }
    }
    else {
      return formattedLevelValue
    }
  }
  else if (isIntervalStepsScope(component) && options.wageDetails) {
    let salaryValue = level.salaryValue
    const wageDetails = options.wageDetails
    const wage = { hasLevels: wageDetails.components.length, levelIds: wageDetails.selectedComponents.map(c => c.selectedLevel && c.selectedLevel.id) }
    salaryValue = getComponentScopeValue(wageDetails.components, wage, salaryValue, 'intervalBounds')
    return formattedRemuneration({ level: { ...level, salaryValue }, component }, 'salary', options)
  }
  else {
    // Default grid mode
    if (context !== 'formula' && formattedLevelValue && linkedComponent && linkedLevel) {
      const formattedSimulatedLevelValue = formattedRemuneration({
        level: {
          salaryValue: valueFromOperation(
            level.salaryValue,
            linkedLevel.salaryValue,
            component.salaryOperation
          )
        },
        component: linkedComponent
      }, 'salary', options)

      if (context === 'qualification') {
        return [formattedLevelValue, formattedSimulatedLevelValue].join('    ')
      }
      else {
        return formattedSimulatedLevelValue
      }
    }
    else {
      return formattedLevelValue
    }
  }
}

// Options = `hideOperation: true`
function formattedRemuneration({ level, component }, remunerationType, options) {
  options = options || {}
  const value = level && level[remunerationType + 'Value']
  let operation = component[remunerationType + 'Operation']
  let operationLabel = ''
  let precision

  if ([undefined, null].includes(value)) {
    return ''
  }

  if (operation === 'multiplier') {
    precision = 2
  }

  if (isIntervalStepsScope(component)) {
    options.hideOperation = true
    operation = 'addition'
    precision = 0
  }

  if (component.ref !== 'role' && !options.hideOperation) {
    operationLabel = remunerationOperationSymbol(operation) +
      ' '
  }

  return operationLabel +
         formattedNumber(value, precision) +
         ' ' +
         remunerationOperationUnit(operation)
};

function formattedRemunerationType(remunerationType, isTotal = false) {
  switch (remunerationType) {
    case 'salary':
      return i18n.t(`common.${isTotal ? 'payroll' : 'grossAnnualSalary'}`)
    case 'equity':
    case 'bonus' :
      return i18n.t(`common.${remunerationType}`)
  };

  return '???'
};

// Available options https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
// Ex: minimumFractionDigits, maximumFractionDigits, signDisplay...
// Also: selectedRecurrence [yearly, monthly]
function formattedCurrency(value, decimals = false, options = {}) {
  if (options && options.selectedRecurrence === 'monthly') {
    value /= 12
  }
  return formatCurrency(value, decimals, options)
}

function formattedNumber(value, precision, maxPrecision) {
  precision = precision === null ? 2 : precision
  return i18n.n(value, { minimumFractionDigits: precision, maximumFractionDigits: maxPrecision })
}

function formattedDeltaNumber(value, precision, maxPrecision) {
  const deltaPrefix = value >= 0 ? '+' : '-'
  const absValue = Math.abs(value)
  if (absValue > 0 && absValue < 1) {
    maxPrecision = absValue < 0.1 ? 2 : 1
  }
  return deltaPrefix + formattedNumber(absValue, precision, maxPrecision)
}

function formattedSentence(array) {
  if (array.length > 1) {
    const start = array.slice(0, -1).join(', ')

    const end = array.slice(-1)[0]
    return [start, end].join(i18n.t('common.and'))
  }
  else if (array.length) {
    return array[0]
  }
}

Vue.filter('formattedLevelValue', formattedLevelValue)
Vue.filter('formattedCurrency', formattedCurrency)
Vue.filter('formattedRemuneration', formattedRemuneration)
Vue.filter('formattedRemunerationType', formattedRemunerationType)
Vue.filter('formattedNumber', formattedNumber)
Vue.filter('formattedDeltaNumber', formattedDeltaNumber)
Vue.filter('formattedSentence', formattedSentence)
Vue.filter('capitalize', capitalize)
