<template>
  <span class="dropdown">
    <button
      ref="button"
      class="small-button dropdown-button"
      @click="$event.target.focus()"
      @focus="onFocus"
      @blur="onBlur"
      :disabled="disabled">
      <slot></slot>
    </button>
    <span class="dropdown-content dropdown-checkbox-content" :style="dropdownStyle" ref="dropdown">
      <ul>
        <template
          v-for="(group, index) in items">
          <li v-if="index > 0" class="dropdown-separator" :key="group.value + '-sep'"></li>
          <li class="dropdown-header-item" :key="group.value + '-header'">{{group.name}}</li>
          <li
            v-for="item in group.items"
            v-if="!item.hidden"
            :key="item.key"
            class="dropdown-item header-link"
            :class="{'dropdown-checked-item': item.selected}"
            @mousedown="$emit('change', group.value, item.value)">
            {{item.name}}
            <span
              v-if="item.label"
              class="right-label"
              @mousedown.stop="selectRightLabel(group.value, item.value)">{{item.label}}</span>
          </li>
        </template>
      </ul>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    items: {
      type: Array
    },
    disabled: {
      type: Boolean
    },
    right: {
      type: Boolean
    },
    preventBlur: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      shouldPreventBlur: false,
      dropdownStyle: null
    }
  },
  methods: {
    selectRightLabel(key, value) {
      this.$emit('change', key, value, true)
      this.shouldPreventBlur = this.preventBlur
    },
    onFocus() {
      if (this.right) {
        const { button, dropdown } = this.$refs
        if (button && dropdown) {
          const dropdownWidth = dropdown.getBoundingClientRect().width
          const buttonWidth = button.getBoundingClientRect().width
          const diff = dropdownWidth - buttonWidth
          this.dropdownStyle = diff > 0 ? `margin-left: -${diff}px` : null
        }
      }
    },
    onBlur() {
      if (this.shouldPreventBlur) {
        this.$refs.button.focus()
        this.shouldPreventBlur = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
@import "./src/styles/link.scss";

.dropdown {
  display: inline-block;
  margin-top: 0;
  text-align: left;
  user-select: none;

  .dropdown-button {
    color: $text-color;
    display: block;

    .down {
      font-size: 1.2em;
      line-height: 1em;
      margin-left: 0.3em;
      opacity: 0.8;
    }

    &[disabled] {
      .down {
        display: none;
      }
    }

    // Fix issue with button focus on Firefox
    * {
      pointer-events: none;
    }

    &:focus {
      pointer-events: none;
    }

    &:focus + .dropdown-content {
      display: inline-block;
    }
  }

  .dropdown-content {
    position: absolute;
    z-index: 2;
    display: none;
    margin-top: 0.5em;
    border: 1px solid $clearteal-color;
    border-radius: $border-radius;
    background-color: white;
    box-shadow: 2px 3px 0 rgba(0, 0, 0, 0.005), 2px 6px 20px rgba(0, 0, 0, 0.08);
    max-height: 56vh;
    overflow-y: scroll;

    ul {
      list-style: none;
      white-space: nowrap;
      padding: 0.25em 0;
      margin: 0;
    }

    .dropdown-item,
    .dropdown-header-item {
      padding: 5px 10px;
      @include font-small-size;
    }

    .dropdown-header-item {
      @include font-smaller-size;
      @include font-bold;
      color: $light-text-color;
      text-transform: uppercase;
      cursor: default;
    }

    .dropdown-separator {
      background: $border-color;
      height: 1px;
      margin: 5px 0;
    }

    .dropdown-checked-item {
      @include font-semibold;

      &::before {
        content: "✔";
        position: absolute;
        margin-left: -18px;
      }
    }

    &.dropdown-checkbox-content .dropdown-item,
    &.dropdown-checkbox-content .dropdown-header-item {
      padding-left: 28px;
      padding-right: 28px;
    }

    .right-label {
      float: right;
      margin-right: -1em;
      margin-left: 1em;
    }
  }
}

// Large skin {
.dropdown.large {
  .dropdown-item,
  .dropdown-header-item,
  .dropdown-button {
    @include line-regular-height;
  }

  .dropdown-header-item {
    @include font-small-size;
  }

  .dropdown-item,
  .dropdown-button {
    @include font-normal-size;
  }

  .dropdown-button {
    line-height: 32px;
    padding: 0 15px;
    border-radius: 15px;
  }

  .dropdown-content {
    border-radius: 15px;
  }
}

// Blue skin
.dropdown.blue {
  .dropdown-button {
    border: 1px solid $graph-outer-border-color;
    color: $graph-darkblue-color;

    &:hover,
    &:focus {
      border-color: lighten($graph-darkblue-color, 20);
    }
  }

  .dropdown-content {
    border-color: lighten($graph-darkblue-color, 20);
  }

  .dropdown-content.dropdown-checkbox-content .dropdown-item:hover {
    background: darken($graph-lightblue-color, 2);
  }
}

// Modern skin without borders (better on $graph-lightblue-color background)
.dropdown.modern {
  .dropdown-button {
    @extend .modern;
  }
}

// With description skin
.dropdown.description {
  ul {
    white-space: initial;
  }

  .dropdown-item {
    padding-right: 5px;
    @include font-semibold;

    .right-label {
      float: none;
      margin: 0;
      display: block;
      @include font-regular;
      padding-top: 3px;
    }
  }
}
</style>
