import api from '@/store/api'
import i18n from '@/i18n'

function getJobTitle(wage, rootGetters) {
  if (wage.candidateId) {
    const candidate = rootGetters['candidates/getCandidate'](wage.candidateId)
    const jobOffer = rootGetters['candidates/getJobOffer'](candidate && candidate.jobOffer)

    return jobOffer && jobOffer.name
  }
}

const state = {}

const getters = {}

const actions = {
  getWage(context, token) {
    return api.get('/wage/share/' + token)
      .then(({ data }) => {
        return data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  getGrid(context, token) {
    return api.get('/wage/share/' + token + '/grid')
      .then(({ data }) => {
        return data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  share(context, { wage, employee, template }) {
    if (wage.sharingToken) {
      return Promise.resolve(wage)
    }
    else {
      const name = [employee.firstName, employee.lastName].join(' ')
      // Only wageProposal supported yet:
      const sharingConfig = {
        title: i18n.t(`wageSharing.templates.${template}.title`, { name }),
        description: i18n.t(`wageSharing.templates.${template}.description`),
        jobTitle: getJobTitle(wage, context.rootGetters),
        socialContribution: 25 // Cotisation de cadre par défaut https://salaire.brut-net.info
      }
      return context.dispatch('createOrUpdate', { wage, sharingConfig })
    }
  },
  createOrUpdate(context, { wage, sharingConfig }) {
    return api.patch('/wage/share/' + wage.id, sharingConfig)
      .then(({ data }) => {
        context.dispatch('updateWageInStores', data)
        return data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  remove(context, wage) {
    return api.delete('/wage/share/' + wage.id)
      .then(({ data }) => {
        context.dispatch('updateWageInStores', data)
        return data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  updateWageInStores(context, wage) {
    // Only wageProposal supported yet:
    if (wage.candidateId) {
      const params = { candidateId: wage.candidateId, wageProposal: wage }
      context.dispatch('candidates/updateWageProposal', params, { root: true })
    }
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
