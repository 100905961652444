<template>
  <Dropdown
    v-if="companyGrids.length || canSelectNoGrid || disabled"
    :disabled="disabled"
    :items="versionsDropdownItems"
    @change="onChange">
    {{displayedGridLabel}}
    <span class="down">▾</span>
  </Dropdown>
</template>

<script>
import { mapGetters } from 'vuex'
import Dropdown from '@components/commons/Dropdown.vue'

export default {
  components: {
    Dropdown
  },
  props: {
    title: {
      type: String
    },
    canSelectNoGrid: {
      type: Boolean,
      default: false
    },
    selectedGrid: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      companyGrids: 'currentGrid/getCompanyGrids',
      currentGrid: 'currentGrid/getCurrentGrid'
    }),
    displayedGridLabel() {
      if (!this.selectedGrid) {
        return this.$t('grid.noGrid')
      }

      const version = this.selectedGrid.version || (this.currentGrid && this.currentGrid.version + 1) || 1

      return this.$t('grid.current.version', { version })
    },
    versionsDropdownItems() {
      const item = {
        items: this.companyGrids.map(grid => ({
          name: this.$t('grid.current.version', { version: grid.version }),
          label: this.$t('grid.current.updatedAt', { date: this.$options.filters.fullFormattedDate(grid.updatedAt) }),
          value: grid.id,
          selected: this.selectedGrid && this.selectedGrid.id === grid.id
        }))
      }

      this.title && (item.name = this.title)
      this.canSelectNoGrid && (item.items.push({
        name: this.$t('grid.noGrid'),
        value: null,
        selected: this.selectedGrid === null
      }))

      return [item]
    }
  },
  methods: {
    onChange(_, gridId) {
      this.$emit('change', gridId)
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown:not(:hover) .down {
  color: $light-text-color;
}
</style>
