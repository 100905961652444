<template>
  <div class="employee-field">
    <div v-if="showLabel" class="input-label">
      <span>{{field.name}}</span>
      <span v-if="isRequired" class="light-text" v-t="'common.required'"></span>
    </div>
    <template v-if="field.type === 'date'">
      <date-input
        v-model="model"
        :placeholder="placeholder || field.placeholder"
        :class="{ 'input-error': isError}"
        @input="onInput" />
    </template>
    <template v-else-if="field.autocomplete">
      <multiselect
        v-model="selectModel"
        :options="fieldOptions"
        trackBy="id"
        label="name"
        :selectLabel="$t('common.select.select')"
        :deselectLabel="$t('common.select.deselect')"
        selectedLabel=""
        :placeholder="selectModel ? selectModel.name : placeholder || field.placeholder"
        :tagPlaceholder="$t('common.select.tagPlaceholder')"
        tagPosition="bottom"
        :taggable="!field.fixed"
        @input="onSelectInput"
        @tag="onTagInput">
        <span slot="noResult" class="light-text" v-t="'common.select.noResult'"></span>
        <span slot="noOptions" class="light-text" v-t="'common.select.noOptions'"></span>
        <template slot="singleLabel" slot-scope="{ option }">{{option.name}}</template>
      </multiselect>
    </template>
    <template v-else>
      <input
        type="text"
        v-model="model"
        :autofocus="autofocus"
        :placeholder="placeholder || field.placeholder"
        :class="{ 'input-error': isError}"
        @input="onInput">
    </template>
  </div>
</template>

<script>
import { getFieldOptions } from '@/utils/employee'
import cloneDeepWith from 'lodash.clonedeepwith'
import DateInput from '@components/commons/DateInput'

export default {
  components: {
    DateInput
  },
  props: {
    employee: {},
    value: {},
    field: {
      type: Object,
      default() { }
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    isRequired: {
      type: Boolean
    },
    isError: {
      type: Boolean
    },
    autofocus: {
      type: Boolean
    },
    placeholder: {
      type: String
    }
  },
  data() {
    return {
      model: null,
      selectModel: null
    }
  },
  computed: {
    fieldOptions() {
      const options = {
        departmentOnly: true,
        model: this.selectModel,
        store: this.$store
      }
      return this.field.autocomplete ? getFieldOptions(this.field, options) : []
    }
  },
  methods: {
    initModel() {
      this.model = cloneDeepWith(this.value)
      if (this.field.autocomplete && !this.selectModel) {
        this.selectModel = this.fieldOptions.find(({ id }) => id === this.model)
      }
    },
    onTagInput(id) {
      this.selectModel = { id, name: id }
      this.onSelectInput()
    },
    onSelectInput() {
      this.model = this.selectModel ? this.selectModel.id : null
      this.onInput()
    },
    onInput() {
      this.$emit('input', this.model)
    }
  },
  watch: {
    value: 'initModel'
  },
  created() {
    this.initModel()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/form.scss";
</style>
