<template>

  <div
    v-if="isScopeSelectorVisible"
    :key="selectedScope"
    class="scope-selector-container"
    :class="{'cool-bump': isAnimated}">
    <div class="scope-selector">
      <dropdown
        :right="true"
        :items="scopes"
        @change="setSelectedScope">
        {{selectedScopeLabel}}
      </dropdown>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import Dropdown from '@components/commons/Dropdown.vue'

export default {
  components: {
    Dropdown
  },
  data() {
    return {
      isAnimated: false
    }
  },
  watch: {
    selectedScope() {
      this.isAnimated = true
    }
  },
  computed: {
    ...mapGetters({
      availableScopes: 'statistics/getAvailableScopes',
      selectedScope: 'statistics/getCurrentScope',
      currentReport: 'statistics/getCurrentReport'
    }),
    isScopeSelectorVisible() {
      return ['evolution', 'payroll', 'workforce', 'salaries', 'deviation'].includes(this.currentReport.id)
    },
    scopes() {
      return [{
        name: this.$t('dashboard.filters.show'),
        value: 'scope',
        items: this.availableScopes.map(item => ({
          name: item.title,
          value: item.value,
          selected: this.selectedScope === item.value
        }))
      }]
    },
    selectedScopeLabel() {
      const { title } = this.availableScopes.find(s => s.value === this.selectedScope) || {}
      return title || this.$t('dashboard.filters.title')
    }
  },
  methods: {
    setSelectedScope(_, selectedScope) {
      this.$store.dispatch('statistics/setCurrentScope', selectedScope)
      this.$nextTick(() => {
        this.isAnimated = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/link.scss";

$scope-selector-padding: 0.3em;

.scope-selector-container {
  display: inline-block;
}

.scope-selector {
  display: inline-block;
  background: white url(~@/assets/graph-scope.svg) no-repeat left 10px center;
  background-size: 24px;
  padding: $scope-selector-padding;
  border: $border;
  border-radius: $border-radius;
  padding-left: 40px;

  .dropdown::v-deep .dropdown-button {
    font: inherit;
    color: inherit;
    border: none;
    padding: 0 0.5em;

    &:hover,
    &:focus {
      color: inherit;
      background: $graph-lightblue-color;
    }

    &:focus {
      background: $graph-blue-color;
    }
  }

  .dropdown::v-deep {
    .dropdown-button {
      max-width: 570px;
      line-height: 1.75em;
    }

    .dropdown-content {
      border-color: $graph-blue-color !important;
      outline: none !important;
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1) !important;

      // Dropdown multi-select skin
      .dropdown-item {
        line-height: 1.5em;

        .right-label {
          @include font-bigger-size;
          width: 40px;
          height: 36px;
          line-height: 36px;
          margin: -5px -28px -5px 5px;
          text-align: center;
          color: lighten($graph-text-color, 10);

          &:hover {
            background: darken($graph-lightblue-color, 5);
            color: $graph-text-color;
          }
        }

        &:hover {
          background: $graph-lightblue-color !important;
        }
      }
    }
  }
}

.slide-fade-enter-active {
  transition: all 0.1s linear;
}
.slide-fade-leave-active {
  transition: all 0.1s linear;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: scale(0.96);
  opacity: 0.75;
}
</style>
