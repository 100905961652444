<template>
  <div>
    <div class="container">
      <h1 v-t="'employees.review.title'"></h1>
      <p v-t="'employees.review.body'" class="preserve-lines"></p>
      <employees-review
        v-model="reviewFields"
        @input="isUpdated = true">
      </employees-review>
      <div class="error-message" v-if="errorMessage">
        {{ errorMessage }}
      </div>
      <div v-if="isSuccess" class="success-banner fade-in">
        <menu>
          <button @click="next" class="primary" v-t="'common.continue'" />
        </menu>
        <p v-html="$t('employees.review.success')"></p>
      </div>
      <menu v-else>
        <button
          v-if="isUpdated"
          class="secondary margin-right"
          @click="reset"
          :disabled="isLoading"
          v-t="'common.reset'">
        </button>
        <button
          class="secondary"
          @click="next"
          :disabled="isLoading"
          v-t="'employees.review.skip'">
        </button>
        <loading-button
          class="primary"
          @click="submit"
          :loading="isLoading">
          <span v-t="'employees.review.submit'"></span>
        </loading-button>
      </menu>
    </div>
  </div>
</template>

<script>
import cloneDeepWith from 'lodash.clonedeepwith'
import EmployeesReview from '@components/employees/EmployeesReview.vue'

function getDefaultData() {
  return {
    error: null,
    errorMessage: null,
    isLoading: false,
    isUpdated: false,
    isSuccess: false,
    reviewFields: cloneDeepWith(this.$store.getters['employeesReview/getDefaultReviewFields']),
    successResponse: null
  }
}

export default {
  components: {
    EmployeesReview
  },
  data: getDefaultData,
  methods: {
    reset() {
      this.isUpdated = false
      Object.assign(this.$data, getDefaultData.bind(this)())
    },
    next() {
      const route = this.$route.params.redirectTo || '/dashboard'
      this.$router.push(route)
    },
    submit() {
      this.isLoading = true
      this.$store.dispatch('employeesReview/updateReviewFields', this.reviewFields)
        .then(updatedEmployees => {
          // If nothing was updated, just go to next step
          if (updatedEmployees && updatedEmployees.length) {
            this.isLoading = false
            this.errorMessage = null
            this.isSuccess = true
          }
          else {
            this.next()
          }
        })
        .catch(error => {
          this.isLoading = false
          this.errorMessage = error && error.error
        })
    }
  },
  created() {
    if (!this.reviewFields.length) {
      this.next()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/button.scss";

.container {
  @include container;
}

.margin-right {
  margin-right: 1em;
}

.success-banner {
  background: $lightteal-color;
  border: 1px solid darken($lightteal-color, 10);
  padding: 0 1em;
  border-radius: $border-radius;

  menu {
    margin-top: 9px;
    float: right;
  }
}
</style>
