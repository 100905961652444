<template>
  <div class="filters-container">
    <div class="filters" :class="{'filter-icon': isIconVisible}">
      <div
        class="filter"
        :class="{'cool-bump': highlightedFilterIndex === i, 'inline': (i === currentFilters.length - 1) }"
        v-for="(filter, i) in currentFilters.length ? currentFilters : [{}]"
        :key="i">
        <span v-if="i > 0" class="and">+</span>
        <dropdown
          :right="isRightDropdown"
          :items="getScopes(i)"
          @change="(_, value) => setScope(i, value)">
          <span class="filter-label">{{getSelectedScopeLabel(i)}}</span>
        </dropdown>
        <template v-if="getValues(i)">
          <span class="equal">=</span>
          <dropdown
            :right="isRightDropdown"
            :items="getValues(i)"
            :preventBlur="true"
            @change="(_, values, isRightButton) => setValues(i, values, isRightButton)">
            {{getSelectedValuesLabel(i)}}
          </dropdown>
        </template>
      </div>
      <button class="small-button blue reset add" v-if="currentFilters.length" @click="addFilter">+</button>
      <button class="small-button blue reset" v-if="currentFilters.length" @click="reset">X</button>
      <span class="employees-count" v-if="showEmployeesCount && employeesCount">{{employeesCount}}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Dropdown from '@components/commons/Dropdown.vue'
import cloneDeepWith from 'lodash.clonedeepwith'

export default {
  components: {
    Dropdown
  },
  props: {
    isRightDropdown: {
      type: Boolean,
      default: true
    },
    showEmployeesCount: {
      type: Boolean,
      default: true
    },
    showIcon: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      highlightedFilterIndex: -1
    }
  },
  computed: {
    ...mapGetters({
      currentReport: 'statistics/getCurrentReport',
      filteredEmployees: 'statistics/getFilteredEmployees',
      employees: 'statistics/getEmployees',
      currentFilters: 'statistics/getCurrentFilters',
      availableScopes: 'statistics/getAvailableScopes',
      getScopeValues: 'statistics/getScopeValues',
      getScopeDefaultValue: 'statistics/getScopeDefaultValue'
    }),
    isEmployeesCountVisible() {
      return this.$route.name !== 'dashboard' || !['evolution', 'payroll'].includes(this.currentReport.id)
    },
    employeesCount() {
      if (this.isEmployeesCountVisible && this.currentFilters.length) {
        const filteredEmployeesCount = this.filteredEmployees.length
        const employeesCount = this.employees.length

        return `${filteredEmployeesCount} / ${employeesCount}`
      }
    },
    isIconVisible() {
      return this.showIcon && !this.currentFilters.length
    }
  },
  methods: {
    getFilter(i) {
      return this.currentFilters[i] || { scope: null, values: [] }
    },
    getScopes(i) {
      const { scope } = this.getFilter(i)
      return [{
        name: this.$t('dashboard.filters.title'),
        value: 'scope',
        items: this.availableScopes.map(item => ({
          name: item.title,
          value: item.value,
          selected: scope === item.value
        }))
      }]
    },
    getValues(i) {
      const { scope, values } = this.getFilter(i)
      if (scope) {
        const scopeValues = this.getScopeValues(scope)
        return [{
          name: this.getSelectedScopeLabel(i),
          items: scopeValues.map(v => ({
            name: v,
            value: v,
            selected: values.includes(v),
            label: values.includes(v) ? '-' : '+'
          }))
        }]
      }
    },
    getSelectedScopeLabel(i) {
      const { scope } = this.getFilter(i)
      if (scope) {
        const { title } = this.availableScopes.find(s => s.value === scope) || {}
        return title
      }
      else {
        return this.$t('dashboard.filters.title')
      }
    },
    getSelectedValuesLabel(i) {
      const { values } = this.getFilter(i)
      const count = values.length
      return count < 2 ? values.join(', ') : this.$tc('dashboard.filters.elements', count, { count })
    },
    setScope(i, scope) {
      const values = [this.getScopeDefaultValue(scope)]
      const filters = cloneDeepWith(this.currentFilters)
      filters[i] = { scope, values }
      this.$store.dispatch('statistics/setCurrentFilters', filters)
    },
    setValues(i, value, isRightButton) {
      let { scope, values } = this.getFilter(i)
      if (isRightButton) {
        values = values.includes(value) ? values.filter(v => v !== value) : values.concat(value)
      }
      else {
        values = [value]
      }
      if (values.length) {
        const filters = cloneDeepWith(this.currentFilters)
        filters[i] = { scope, values }
        this.$store.dispatch('statistics/setCurrentFilters', filters)
      }
      else {
        const filters = cloneDeepWith(this.currentFilters)
        filters.splice(i, 1)
        this.$store.dispatch('statistics/setCurrentFilters', filters)
      }
    },
    addFilter() {
      const availableScopes = this.availableScopes.map(s => s.value)
      const selectedScopes = this.currentFilters.map(f => f.scope)
      const filteredScopes = availableScopes.filter(s => !selectedScopes.includes(s))
      const scope = filteredScopes[0] || availableScopes[0]
      const values = [this.getScopeDefaultValue(scope)]
      const filters = [...this.currentFilters, { scope, values }]
      this.$store.dispatch('statistics/setCurrentFilters', filters)
      this.highlightedFilterIndex = filters.length - 1
    },
    reset() {
      this.$store.dispatch('statistics/setCurrentFilters', [])
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/link.scss";
@import "./src/styles/button.scss";

$filters-padding: 0.3em;

.filters-container {
  display: inline-block;
}

.filters {
  display: inline-block;
  background: white url(~@/assets/graph-filters.svg) no-repeat left 10px top 5px;
  background-size: 24px;
  padding: $filters-padding;
  border: $border;
  border-radius: $border-radius;
  padding-left: 40px;
  max-width: 600px;

  &.filter-icon {
    background-image: none;
    padding-left: $filters-padding;

    .filter-label {
      background: transparent url(~@/assets/graph-filters.svg) no-repeat center center;
      background-size: 24px;
      width: 24px;
      color: transparent;
      overflow: hidden;
      display: inline-block;
      margin: -7px;
    }
  }
}

.filter {
  &.inline {
    display: inline-block;
  }
}

.dropdown::v-deep .dropdown-button {
  font: inherit;
  color: inherit;
  border: none;
  padding: 0 0.5em;

  &:hover,
  &:focus {
    color: inherit;
    background: $graph-lightblue-color;
  }

  &:focus {
    background: $graph-blue-color;
  }
}

.dropdown::v-deep {
  .dropdown-button {
    max-width: 400px;
      line-height: 1.75em;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .dropdown-content {
    border-color: $graph-blue-color !important;
    outline: none !important;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1) !important;

    // Dropdown multi-select skin
    .dropdown-item {
      line-height: 1.5em;

      .right-label {
        @include font-bigger-size;
        width: 40px;
        height: 30px;
        line-height: 30px;
        margin: -5px -28px -5px 5px;
        text-align: center;
        color: lighten($graph-text-color, 10);

        &:hover {
          background: darken($graph-lightblue-color, 5);
          color: $graph-text-color;
        }
      }

      &:hover {
        background: $graph-lightblue-color !important;
      }
    }
  }
}

.equal {
  display: inline-block;
  margin: 0 -2px;
  position: relative;
}

.reset {
  @include font-tabular-numbers;
  text-align: center;
  margin: -1px 0;
  padding: 0;
  vertical-align: top;
  line-height: 26px;
  width: 30px;
  @include font-semibold;

  &.add {
    font-weight: 300;
    font-size: 22px;
    margin: -1px 4.5px;
  }
}

.and {
  @include font-tabular-numbers;
  @include font-semibold;
  color: $graph-darkblue-color;
  text-transform: uppercase;
  vertical-align: -1px;
}

.employees-count {
  @include font-small-size;
  margin-left: 0.5em;
  color: $light-text-color;
}
</style>
