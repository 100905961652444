<template>
  <div>
    <div
      v-if="orgChartErrorMessage"
      class="error-message preserve-lines"
      v-html="orgChartErrorMessage"></div>
    <div class="org-chart-editor">
      <svg ref="svgNode"></svg>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
import debounce from 'lodash.debounce'
import short from 'short-uuid'
import { truncateLabel } from '@/utils/graph'
import { mapGetters } from 'vuex'
import { colorize } from '@/utils/color'
import partition from 'lodash.partition'
import orderBy from 'lodash.orderby'
import groupBy from 'lodash.groupby'
import { getItem, setItem } from '@/utils/storage'
import { isCurrency } from '@/utils/currency'

const CW_ORG_CHART_COLLAPSED_CARDS = 'CW_ORG_CHART_COLLAPSED_CARDS'
const AVATAR_SIZE = 36
const VIEWPORT_PADDING = 20
const CARD_WIDTH = 170
const CARD_HEIGHT = 64
const CARD_PADDING_X = 7
const CARD_PADDING_Y = 7
const CARD_MARGIN_Y = 1
const GROUP_MARGIN_X = 20
const GROUP_MARGIN_Y = 20
const TEAM_HEIGHT = 30
const TEAM_PADDING_Y = 10
const TEAM_MARGIN_Y = 10
const TEAM_BORDER_Y = 4
const ROLE_TRUNCATE = 20
const INFO_TRUNCATE = 3

function initGraph(svg, viewport) {
  const node = {}

  node.svg = d3.select(svg)
  node.groups = node.svg.append('g')
    .attr('class', 'groups')
  node.defs = node.svg.append('defs')
  node.clipPath = node.defs.append('clipPath')
    .attr('id', 'clip-circle')
    .append('circle')
    .attr('r', AVATAR_SIZE / 2)
    .attr('cy', AVATAR_SIZE / 2)
    .attr('cx', AVATAR_SIZE / 2)

  return {
    node: node,
    viewport: viewport,
    data: [],
    isDragging: false,
    animationDuration: 0,
    zoomScale: 1
  }
}

function generateModel(graph, orgChart, options) {
  graph.animationDuration = 0
  graph.data = orgChart
  graph.options = options
  return graph
}

const collapsedCards = getItem(CW_ORG_CHART_COLLAPSED_CARDS, {})

function toggleCollapsedCard(card) {
  if (!collapsedCards[card.id]) {
    collapsedCards[card.id] = true
  }
  else {
    delete collapsedCards[card.id]
  }
  setItem(CW_ORG_CHART_COLLAPSED_CARDS, collapsedCards)
}

function isCardExpanded(card) {
  return card.children && card.children.length && !collapsedCards[card.id]
}

function buildGroup(cards, children) {
  return {
    id: cards.length ? cards.map(c => c.id).join() : 1,
    cards: orderBy(cards, [c => c.children && c.children.length, 'role', 'name'], ['desc', 'asc', 'asc']),
    children,
    meta: {}
  }
}

function groupCards(cards) {
  const [expandedCards, collapsedCards] = partition(cards, isCardExpanded)
  const collapsedCardsGroups = Object.values(groupBy(collapsedCards, 'fullDepartment'))
  return orderBy([
    ...expandedCards.map(expandedCard => {
      const children = groupCards(expandedCard.children)
      return buildGroup([expandedCard], children)
    }),
    ...collapsedCardsGroups.map(collapsedCardsGroup => buildGroup(collapsedCardsGroup, []))
  ].filter(group => group.cards.length),
  [
    g => g.cards.length && g.cards[0].fullDepartment,
    g => g.cards.length && g.cards[0].role,
    g => g.cards.length && g.cards[0].name
  ],
  ['asc', 'asc', 'asc'])
}

function annotateGroups(group, parentGroup = null, memo = { col: 0 }) {
  const team = group.cards && group.cards.length && group.cards[0].fullDepartment
  const parentTeam = parentGroup && parentGroup.cards && parentGroup.cards.length && parentGroup.cards[0].fullDepartment
  const showTeam = team && (!parentTeam || parentTeam !== team)
  const offsetY = showTeam ? TEAM_HEIGHT + TEAM_MARGIN_Y : 0
  group.teams = showTeam
    ? [{
        id: group.id,
        name: team,
        meta: {
          x: 0,
          y: 0,
          width: CARD_WIDTH,
          height: TEAM_HEIGHT
        }
      }]
    : []
  group.meta.y = parentGroup ? parentGroup.meta.y + parentGroup.meta.height + GROUP_MARGIN_Y : 0
  group.meta.height = 0
  group.cards.forEach((card, i) => {
    card.meta.row = i
    card.meta.x = 0
    card.meta.y = offsetY + card.meta.row * CARD_HEIGHT + card.meta.row * CARD_MARGIN_Y
    card.meta.width = CARD_WIDTH
    card.meta.height = CARD_HEIGHT
    group.meta.height = card.meta.y + CARD_HEIGHT
  })
  group.children.forEach(groupChild => annotateGroups(groupChild, group, memo))
  if (group.children.length) {
    const minCol = group.children[0].meta.col
    const maxCol = group.children[group.children.length - 1].meta.col
    group.meta.col = (maxCol + minCol) / 2
  }
  else {
    group.meta.col = memo.col
    memo.col += 1
  }
  group.meta.x = group.meta.col * CARD_WIDTH + group.meta.col * GROUP_MARGIN_X
  group.meta.width = CARD_WIDTH
  group.meta.color = colorize(team)
}

function getGroupsData(data) {
  const groups = groupCards(data)
  const group = groups.length === 1 ? groups[0] : buildGroup([], groups)
  annotateGroups(group)
  const flattenGroups = (groups) => {
    return groups
      .reduce((memo, group) => {
        memo.push(group)
        if (group.children) {
          memo = memo.concat(flattenGroups(group.children))
        }
        return memo
      }, [])
  }
  return flattenGroups([group])
}

// https://observablehq.com/@bumbeishvili/curved-edges
function diagonal(s, t) {
  const x = s.x
  const y = s.y
  const ex = t.x
  const ey = t.y

  const xrvs = ex - x < 0 ? -1 : 1
  const yrvs = ey - y < 0 ? -1 : 1

  const rdef = 35
  let r = Math.abs(ex - x) / 2 < rdef ? Math.abs(ex - x) / 2 : rdef

  r = Math.abs(ey - y) / 2 < r ? Math.abs(ey - y) / 2 : r

  const h = Math.abs(ey - y) / 2 - r
  const w = Math.abs(ex - x) - r * 2

  const path = `
            M ${x} ${y}
            L ${x} ${y + h * yrvs}
            C  ${x} ${y + h * yrvs + r * yrvs} ${x} ${y + h * yrvs + r * yrvs} ${x + r * xrvs} ${y + h * yrvs + r * yrvs}
            L ${x + w * xrvs + r * xrvs} ${y + h * yrvs + r * yrvs}
            C  ${ex}  ${y + h * yrvs + r * yrvs} ${ex}  ${y + h * yrvs + r * yrvs} ${ex} ${ey - h * yrvs}
            L ${ex} ${ey}
 `
  return path
}

function getLinksData(group) {
  const start = {
    x: group.meta.width / 2,
    y: group.meta.height
  }

  return [
    ...group.teams.map(t => ({
      id: short.generate(),
      path: diagonal(
        {
          x: t.meta.width / 2,
          y: t.meta.height
        },
        {
          x: t.meta.width / 2,
          y: t.meta.height + TEAM_MARGIN_Y
        })
    })),
    ...group.children.map(c => ({
      id: short.generate(),
      path: diagonal(start, {
        x: c.meta.x - group.meta.x + c.meta.width / 2,
        y: c.meta.y - group.meta.y
      })
    }))
  ]
}

function zoomGraph(graph, force = false) {
  const { node, viewport, zoom } = graph
  const isInit = !graph.animationDuration
  const shouldZoom = force || isInit
  if (shouldZoom) {
    node.svg.call(zoom.transform, d3.zoomIdentity.scale(1))
    let groupsRect = node.groups.node().getBoundingClientRect()
    const zoomScale = Math.min(1, Math.max(
      0.75,
      viewport.innerWidth / (groupsRect.width + 2 * VIEWPORT_PADDING)))
    node.svg.call(zoom.transform, d3.zoomTransform(node.svg).scale(zoomScale))
    groupsRect = node.groups.node().getBoundingClientRect()
    const zoomX = viewport.innerWidth / 2 - groupsRect.width / 2
    node.svg.call(zoom.transform, d3.zoomIdentity.translate(zoomX, VIEWPORT_PADDING).scale(zoomScale))
  }
}

function renderGraph(graph, open) {
  const refresh = () => {
    renderGraph(graph, open)
    zoomGraph(graph, true)
  }
  const viewport = graph.viewport
  const node = graph.node

  // Viewport
  graph.node.svg.style('display', 'none')
  const parentNode = graph.node.svg.node().parentNode
  const parentRect = parentNode.getBoundingClientRect()
  viewport.width = parentRect.width
  viewport.height = window.innerHeight - parentRect.top
  viewport.innerWidth = viewport.width - viewport.padding.left - viewport.padding.right
  viewport.innerHeight = viewport.height - viewport.padding.top - viewport.padding.bottom
  graph.node.svg.style('display', null)

  node.svg
    .attr('width', viewport.width)
    .attr('height', viewport.height)

  // Draw groups
  const groupsData = getGroupsData(graph.data)
  const groups = node.groups.selectAll('.group')
    .data(groupsData, d => d.id)
  groups.exit()
    .remove()
  groups
    .enter().append('g')
    .attr('class', 'group')
    .merge(groups)
    .each(function(groupData) {
      // Group
      const group = d3.select(this)
        .attr('transform', `translate(${groupData.meta.x},${groupData.meta.y})`)

      // Group links
      const linksData = groupData.cards.length ? getLinksData(groupData) : []
      const links = group.selectAll('.link')
        .data(linksData, d => d.id)
      links.exit()
        .remove()
      links.enter().append('path')
        .attr('class', 'link')
        .attr('d', d => d.path)

      // Team
      const teamsData = groupData.teams
      const teams = group.selectAll('.team')
        .data(teamsData, d => d.id)
      teams.exit()
        .remove()
      teams
        .enter().append('g')
        .attr('class', 'team')
        .style('opacity', 0)
        .merge(teams)
        .transition()
        .duration(graph.animationDuration)
        .style('opacity', 1)
        .each(function(teamData) {
          // Team
          const team = d3.select(this)
            .attr('transform', 'translate(0, 0)')

          // Rect shadow
          const rectShadow = team.selectAll('.rect-shadow')
            .data([teamData], d => d.id)
          rectShadow.exit()
            .remove()
          rectShadow.enter().append('rect')
            .attr('class', 'rect-shadow')
            .merge(rectShadow)
            .attr('rx', 4)
            .attr('ry', 4)
            .attr('width', CARD_WIDTH)
            .attr('height', TEAM_HEIGHT)

          // Rect
          const rect = team.selectAll('.rect')
            .data([teamData], d => d.id)
          rect.exit()
            .remove()
          rect.enter().append('rect')
            .attr('class', 'rect')
            .merge(rect)
            .attr('rx', 4)
            .attr('ry', 4)
            .attr('width', CARD_WIDTH)
            .attr('height', TEAM_HEIGHT)

          // Border
          const border = team.selectAll('.border')
            .data([teamData], d => d.id)
          border.exit()
            .remove()
          border.enter().append('rect')
            .attr('class', 'border')
            .merge(border)
            .attr('width', CARD_WIDTH)
            .attr('height', TEAM_BORDER_Y)
            .attr('fill', groupData.meta.color)

          // Team label
          const teamLabel = team.selectAll('.team-label')
            .data([teamData], d => d.id)
          teamLabel.exit()
            .remove()
          teamLabel.enter().append('text')
            .attr('class', 'team-label')
            .merge(teamLabel)
            .attr('x', CARD_PADDING_X)
            .attr('y', TEAM_PADDING_Y)
            .text(d => truncateLabel(d.name, ROLE_TRUNCATE))
        })

      // Cards
      const cardsData = groupData.cards
      const cards = group.selectAll('.card')
        .data(cardsData, d => d.id)
      cards.exit()
        .remove()
      cards
        .enter().append('g')
        .attr('class', 'card')
        .style('opacity', 0)
        .merge(cards)
        .transition()
        .duration(graph.animationDuration)
        .style('opacity', 1)
        .each(function(cardData) {
          // Card
          const card = d3.select(this)
            .attr('transform', `translate(${cardData.meta.x},${cardData.meta.y})`)

          // Rect shadow
          const rectShadow = card.selectAll('.rect-shadow')
            .data([cardData], d => d.id)
          rectShadow.exit()
            .remove()
          rectShadow.enter().append('rect')
            .attr('class', 'rect-shadow')
            .merge(rectShadow)
            .attr('rx', 4)
            .attr('ry', 4)
            .attr('width', d => d.meta.width)
            .attr('height', d => d.meta.height)

          // Rect
          const rect = card.selectAll('.rect')
            .data([cardData], d => d.id)
          rect.exit()
            .remove()
          rect.enter().append('rect')
            .attr('class', 'rect')
            .merge(rect)
            .attr('rx', 4)
            .attr('ry', 4)
            .attr('width', d => d.meta.width)
            .attr('height', d => d.meta.height)

          // Role label
          const role = card.selectAll('.role')
            .data([cardData], d => d.id)
          role.exit()
            .remove()
          role.enter().append('text')
            .attr('class', 'role')
            .merge(role)
            .classed('green', d => isCurrency(d.role))
            .on('click', (_e, d) => {
              open(d.id)
            })
            .attr('x', CARD_PADDING_X)
            .attr('y', CARD_PADDING_Y)
            .text(d => truncateLabel(d.role, ROLE_TRUNCATE))

          // Name label
          const hasAvatarRect = cardData.avatarOption !== 'none'
          const avatarRectPaddingX = hasAvatarRect ? 38 : 0
          const avatarRectTruncate = hasAvatarRect ? 5 : 0
          const name = card.selectAll('.name')
            .data([cardData], d => d.id)
          name.exit()
            .remove()
          name.enter().append('text')
            .attr('class', 'name')
            .merge(name)
            .classed('green', d => isCurrency(d.name))
            .on('click', (_e, d) => {
              open(d.id)
            })
            .attr('x', CARD_PADDING_X + avatarRectPaddingX)
            .attr('y', CARD_PADDING_Y + 19)
            .text(d => truncateLabel(d.name, ROLE_TRUNCATE - avatarRectTruncate))

          // Info label
          const info = card.selectAll('.info')
            .data([cardData], d => d.id)
          info.exit()
            .remove()
          info.enter().append('text')
            .attr('class', 'info')
            .merge(info)
            .classed('green', d => isCurrency(d.info))
            .attr('x', CARD_PADDING_X + avatarRectPaddingX)
            .attr('y', CARD_PADDING_Y + 37)
            .text(d => truncateLabel(d.info, ROLE_TRUNCATE - INFO_TRUNCATE - avatarRectTruncate))

          // Avatar (if available)
          const avatarX = CARD_PADDING_X - 2
          const avatarY = CARD_PADDING_Y + 16
          const hasAvatar = hasAvatarRect && cardData.avatarOption === 'avatar' && cardData.avatar
          const avatar = card.selectAll('.avatar')
            .data(hasAvatar ? [cardData] : [], d => d.id)
          avatar.exit()
            .remove()
          avatar.enter().append('image')
            .attr('class', 'avatar')
            .merge(avatar)
            .attr('height', AVATAR_SIZE)
            .attr('width', AVATAR_SIZE)
            .attr('transform', `translate(${avatarX},${avatarY})`)
            .attr('xlink:href', d => d.avatar)
            .attr('clip-path', 'url(#clip-circle)')
            .attr('preserveAspectRatio', 'xMidYMid slice')

          // Avatar colored rect (fallback)
          const shouldColorize = false
          const avatarRect = card.selectAll('.avatar-rect')
            .data(hasAvatar || !hasAvatarRect ? [] : [cardData], d => d.id)
          avatarRect.exit()
            .remove()
          avatarRect.enter().append('rect')
            .attr('class', 'avatar-rect')
            .merge(avatarRect)
            .attr('rx', AVATAR_SIZE)
            .attr('ry', AVATAR_SIZE)
            .attr('height', AVATAR_SIZE)
            .attr('width', AVATAR_SIZE)
            .attr('transform', `translate(${avatarX},${avatarY})`)
            .attr('fill', d => shouldColorize ? colorize(d.name) : groupData.meta.color)

          // Avatar initials (fallback)
          const avatarLabel = card.selectAll('.avatar-label')
            .data(hasAvatar || !hasAvatarRect ? [] : [cardData], d => d.id)
          avatarLabel.exit()
            .remove()
          avatarLabel.enter().append('text')
            .attr('class', 'avatar-label')
            .merge(avatarLabel)
            .attr('transform', `translate(${avatarX + AVATAR_SIZE / 2},${avatarY + AVATAR_SIZE / 2 + 1})`)
            .text(d => d.initials)

          // Expand button
          const childrenCount = cardData.children ? cardData.children.length : 0
          const expand = card.selectAll('.expand')
            .data(childrenCount ? [cardData] : [], d => d.id)
          expand.exit()
            .remove()
          expand
            .enter().append('g')
            .attr('class', 'expand')
            .merge(expand)
            .on('click', (event, d) => {
              toggleCollapsedCard(d)
              refresh()
            })
            .each(function(d) {
              const EXPAND_WIDTH = 25
              const EXPAND_HEIGHT = 17

              const expand = d3.select(this)
                .attr('transform', `translate(${d.meta.width - EXPAND_WIDTH - 5},${d.meta.height - EXPAND_HEIGHT - 4})`)

              // Button
              const expandRect = expand.selectAll('.expand-rect')
                .data([d], d => d.id)
              expandRect.exit()
                .remove()
              expandRect.enter().append('rect')
                .attr('class', 'expand-rect')
                .merge(expandRect)
                .attr('rx', 3)
                .attr('ry', 3)
                .attr('width', EXPAND_WIDTH)
                .attr('height', EXPAND_HEIGHT)

              // Text
              const expandCount = expand.selectAll('.expand-count')
                .data([d], d => d.id)
              expandCount.exit()
                .remove()
              expandCount.enter().append('text')
                .attr('class', 'expand-count')
                .merge(expandCount)
                .attr('x', EXPAND_WIDTH - 3)
                .attr('y', EXPAND_HEIGHT / 2 + 1)
                .text(d => childrenCount + (isCardExpanded(d) ? '-' : '+'))
            })
        })
    })

  // Zoom viewport & center
  graph.zoom = d3.zoom()
    .extent([[0, 0], [viewport.innerWidth, viewport.innerHeight]])
    .scaleExtent([0.25, 4])
    .on('zoom', ({ transform }) => {
      node.groups.attr('transform', transform)
    })
    .on('start', function() {
      node.svg.attr('cursor', 'grabbing')
    })
    .on('end', function() {
      node.svg.attr('cursor', null)
    })
  node.svg.call(graph.zoom)
    .on('dblclick.zoom', null)

  zoomGraph(graph)
  // Enable animation only after first rendering
  graph.animationDuration = 500
}

export default {
  data() {
    return {
      // Options
      viewport: {
        width: 0, // Computed
        height: 0, // Computed
        innerWidth: 0, // Computed
        innerHeight: 0, // Computed
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }
      },
      // State
      graph: null,
      resizeFn: null
    }
  },
  computed: {
    ...mapGetters({
      orgChartError: 'orgChart/orgChartError',
      orgChart: 'orgChart/getOrgChart',
      options: 'orgChart/getOptions'
    }),
    orgChartErrorMessage() {
      if (this.orgChartError) {
        let users = this.orgChartError
        users = users.length ? users : ['Alice', 'Bob']
        users.push(users[0])
        users = users.join(this.$t('orgChart.errorJunction'))
        return this.$t('orgChart.error', { users })
      }
    }
  },
  watch: {
    orgChart: 'render',
    options: 'render'
  },
  mounted() {
    this.init()
    this.render()
    this.resizeFn = debounce(this.render, 100)
    window.addEventListener('resize', this.resizeFn)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeFn)
  },
  methods: {
    init() {
      this.graph = initGraph(this.$refs.svgNode, this.viewport)
    },
    zoom() {
      zoomGraph(this.graph, true)
    },
    open(employeeId) {
      if (this.$$hasOpenAccess) {
        this.$router.push({ name: 'employee', params: { id: employeeId } })
      }
    },
    render() {
      generateModel(this.graph, this.orgChart, this.options)
      renderGraph(this.graph, this.open)
    }
  }
}

</script>
<style lang="scss" scoped>
.org-chart-editor {
  font-size: 0;
  line-height: 0;
}

.error-message {
  @include line-regular-height;
  background-color: $lightred-color;
  border: 1px solid lighten($red-color, 25);
  color: $red-color;
  border-radius: $border-radius;
  padding: 0.6em;
  margin: 2em auto;
  max-height: 20em;
  max-width: $content-max-width;
  overflow: hidden;
}

svg::v-deep {
  user-select: none;

  .group {
    .link {
      fill: none;
      stroke-width: 2px;
      stroke: $graph-accentblue-color;
    }
  }

  .team {
    .rect-shadow {
      transform: translate(0.5px, 1.5px);
      fill: $border-color;
    }

    .rect {
      fill: white;
    }

    .team-label {
      @include font-small-size;
      @include font-bold;
      fill: $text-color;
      text-anchor: start;
      dominant-baseline: hanging;
    }
  }

  .card {
    .rect-shadow {
      transform: translate(0.5px, 1.5px);
      fill: $border-color;
    }

    .rect {
      fill: white;
    }

    .role {
      @include font-small-size;
      @include font-bold;
      cursor: pointer;
      fill: $text-color;
      text-anchor: start;
      dominant-baseline: hanging;
    }

    .name {
      @include font-small-size;
      @include font-bold;
      cursor: pointer;
      fill: $light-text-color;
      text-anchor: start;
      dominant-baseline: hanging;
    }

    .info {
      @include font-small-size;
      fill: $light-text-color;
      text-anchor: start;
      dominant-baseline: hanging;
    }

    .role, .name, .info {
      &.green {
        @include font-bold;
        fill: $clearteal-color;
      }
    }

    .avatar-label {
      @include font-normal-size;
      @include font-semibold;
      fill: $text-color;
      text-transform: uppercase;
      text-anchor: middle;
      dominant-baseline: middle;
    }

    .expand {
      cursor: pointer;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }

      .expand-rect {
        fill: $graph-background-color;
      }

      .expand-count {
        @include font-small-size;
        @include font-bold;
        fill: $graph-darkblue-color;
        text-anchor: end;
        dominant-baseline: middle;
      }

      .expand-arrow {
        transform-origin: 20px 8px;
        transform: rotate(-90deg);

        &.expanded {
          transform: rotate(0);
        }
      }
    }
  }
}
</style>
