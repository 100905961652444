<template>
  <div class="form">
    <!-- Header -->
    <h3 v-t="companyStatus === 'lead' ? 'settings.settingsCompanyBilling.form.title' : 'settings.settingsCompanyBilling.title'"></h3>
    <div v-if="isSuccess" class="success-banner">
      <p>
        <span class="bullet"><span>✔</span></span>
        <span v-html="$t('settings.settingsCompanyBilling.form.successMessage')"></span>
      </p>
    </div>
    <p class="error-message no-margin-top reveal" v-if="errorMessage" v-html="errorMessage"></p>
    <!-- Loading -->
    <template v-if="isLoading">
      <LoadingView
        :messages="[1, 2, 3].map(i => $t(`settings.settingsCompanyBilling.loading.message${i}`))" />
    </template>
    <!-- Lead -->
    <template v-else-if="companyStatus === 'lead'">
      <p v-html="$t('settings.settingsCompanyBilling.form.intro')"></p>
      <form class="subscription-form" @submit="subscribe">
        <div class="form-section">
          <div>
            <span class="input-label big" v-t="'settings.settingsCompanyBilling.form.billingName'"></span>
          </div>
          <input
            type="text"
            v-model="subscriptionModel.billingName"
            :class="{'input-error': $v.subscriptionModel.billingName.$error}">
        </div>
        <div class="form-section">
          <div>
            <span class="input-label big" v-t="'settings.settingsCompanyBilling.form.billingEmail'"></span>
          </div>
          <input
            type="text"
            v-model="subscriptionModel.billingEmail"
            :class="{'input-error': $v.subscriptionModel.billingEmail.$error}">
        </div>
        <div class="form-section">
          <div>
            <span class="input-label big" v-t="'settings.settingsCompanyBilling.form.billing'"></span>
            <tooltip
              class="gray-icon blue-bg"
              :message="$t('settings.settingsCompanyBilling.form.billingTooltip')" />
          </div>
          <radioInputs
            class="large-inline"
            :items="availableIntervals"
            localePrefix="settings.settingsCompanyBilling.interval"
            :description="true"
            :descriptionGetter="getIntervalDescription"
            v-model="subscriptionModel.interval" />
        </div>
        <div class="form-section">
          <div>
            <span class="input-label big" v-t="'settings.settingsCompanyBilling.form.quantity'"></span>
            <tooltip
              class="gray-icon blue-bg"
              :message="$t('settings.settingsCompanyBilling.form.quantityTooltip')" />
          </div>
          <router-link class="labeled-badge clickable" :to="{name: 'employees'}">
            <label v-t="'dashboard.synthesis.employees'"></label>
            <span class="badge border-badge">
              {{ employeeCount }}
            </span>
          </router-link>
        </div>
        <div class="form-section" v-if="previewSubscriptionCoupon">
          <div>
            <span class="input-label big" v-t="'settings.settingsCompanyBilling.form.coupon'"></span>
            <tooltip
              class="gray-icon blue-bg"
              :message="$t('settings.settingsCompanyBilling.form.couponTooltip')" />
          </div>
          <div class="labeled-badge">
            <label>{{previewSubscriptionCoupon.name}}</label>
            <span class="badge border-badge">
              {{ previewSubscriptionCoupon.price }}
            </span>
          </div>
        </div>
        <div class="form-section">
          <div class="input-label big" v-t="'settings.settingsCompanyBilling.form.summary'"></div>
          <transition name="slide-fade" mode="out-in">
            <div class="settings-block subscription subscription-preview" :key="subscriptionModel.interval">
              <div class="subscription-logo">
                <a href="https://www.clearwage.com/tarifs" target="_blank" class="hover-link">
                  <img src="~@/assets/logo-square.svg">
                </a>
              </div>
              <div class="subscription-description">
                <h4>{{ subscriptionName }}</h4>
                <ul>
                  <li class="price">{{ previewSubscriptionPricing }}</li>
                  <li class="extras" v-if="previewSubscriptionInvoiceItems">{{ previewSubscriptionInvoiceItems }}</li>
                  <li class="status" v-else>{{ previewSubscriptionPeriod }}</li>
                </ul>
              </div>
            </div>
          </transition>
        </div>
        <menu class="hero">
          <p class="no-margin-top">
            <Checkbox
              v-model="subscriptionModel.isTermsChecked"
              :class="{'input-error' : $v.subscriptionModel.isTermsChecked.$error}">
              <span v-html="$t('account.terms', {url: $t('urls.terms')})" />
            </Checkbox>
          </p>
          <LoadingButton
            type="button"
            :loading="isSubscribing"
            class="primary"
            @click="subscribe">
            {{$t('settings.settingsCompanyBilling.form.subscribe')}}
          </LoadingButton>
        </menu>
      </form>
      <hr>
      <iframe class="iframe-features" :src="pricingUrl"></iframe>
      <p class="light-text preserve-lines" v-html="$t('settings.settingsCompanyBilling.form.footer')"></p>
    </template>
    <!-- Customer, Churn, Free, Internal -->
    <template v-else>
      <div class="settings-block subscription">
        <div class="subscription-logo">
          <a href="https://www.clearwage.com/tarifs" target="_blank" class="hover-link">
            <img src="~@/assets/logo-square.svg">
          </a>
        </div>
        <div class="subscription-description">
          <h4>{{ subscriptionName }}</h4>
          <ul>
            <li class="price">{{ subscriptionPricing }}</li>
            <li class="status">{{ subscriptionPeriod }}</li>
            <template v-if="stripeSubscription">
              <li class="light-text" v-t="'settings.settingsCompanyBilling.stripePortalIntro'"></li>
              <menu class="menu">
                <LoadingButton
                  class="secondary"
                  :loading="isLoadingStripePortalSession"
                  @click="openStripePortal">
                  {{$t('settings.settingsCompanyBilling.openStripePortal')}}
                </LoadingButton>
              </menu>
            </template>
            <template v-else>
              <li class="light-text" v-t="'settings.settingsCompanyBilling.thanks'"></li>
            </template>
          </ul>
        </div>
      </div>
      <p v-html="$t('settings.settingsCompanyBilling.footer')"></p>
      <hr>
      <iframe class="iframe-features" :src="pricingUrl"></iframe>
    </template>
  </div>
</template>

<script>
import LoadingButton from '@/components/commons/LoadingButton.vue'
import { COMPANY_STATUSES } from '@/utils/company'
import Checkbox from '@components/commons/Checkbox'
import LoadingView from '@components/commons/LoadingView'
import RadioInputs from '@components/commons/RadioInputs.vue'
import Tooltip from '@components/commons/Tooltip.vue'
import animateScrollTo from 'animated-scroll-to'
import { email, required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
  components: {
    Checkbox,
    RadioInputs,
    Tooltip,
    LoadingButton,
    LoadingView
  },
  data() {
    return {
      errorMessage: null,
      subscriptionModel: {
        isTermsChecked: false,
        interval: 'month',
        billingName: null,
        billingEmail: null,
        successUrl: location.href.split('?')[0]
      },
      isSubscribing: false,
      isSuccess: false,
      isLoading: false,
      isLoadingStripePortalSession: false,
      stripeSubscription: null,
      stripeOffer: null
    }
  },
  computed: {
    ...mapGetters({
      employees: 'employees/getEmployees',
      user: 'account/getUser'
    }),
    company() {
      return this.$store.getters['account/getCompany'] || {}
    },
    companyStatuses() {
      return COMPANY_STATUSES
    },
    companyStatus() {
      return this.company.status
    },
    subscriptionName() {
      return this.$t(`settings.settingsCompanyBilling.subscription.${this.companyStatus === COMPANY_STATUSES.free ? 'starter' : 'pro'}.title`)
    },
    subscriptionPricing() {
      switch (this.companyStatus) {
        case COMPANY_STATUSES.free:
          return this.$t('settings.settingsCompanyBilling.subscription.starter.pricing')
        default:
          if (this.stripeSubscription) {
            let { price, interval } = this.stripeSubscription
            price = this.$n(price / 100)
            interval = this.$t(`settings.settingsCompanyBilling.interval.${interval}.noun`)
            return this.$t('settings.settingsCompanyBilling.subscription.pro.dynamicPricing', { price, interval })
          }
          else {
            return this.$t('settings.settingsCompanyBilling.subscription.pro.pricing')
          }
      }
    },
    subscriptionPeriod() {
      switch (this.companyStatus) {
        case COMPANY_STATUSES.free:
          return this.$t('settings.settingsCompanyBilling.subscription.starter.status')
        case COMPANY_STATUSES.churn:
          return this.$t('settings.settingsCompanyBilling.subscription.churn.status')
        default:
          if (this.stripeSubscription) {
            const { currentPeriodEnd } = this.stripeSubscription
            return this.$t('settings.settingsCompanyBilling.subscription.pro.subscriptionPeriod', { currentPeriodEnd: this.$options.filters.formatCalendar(new Date(currentPeriodEnd)) })
          }
          else {
            return this.$t('settings.settingsCompanyBilling.interval.year.billing')
          }
      }
    },
    availableIntervals() {
      return ['month', 'year']
    },
    employeeCount() {
      return this.employees.length
    },
    yearlyPrice() {
      return (this.stripeOffer && this.stripeOffer.yearlyPrice) || 18
    },
    monthlyPrice() {
      return (this.stripeOffer && this.stripeOffer.monthlyPrice) || 2
    },
    previewSubscriptionPricing() {
      const { employeeCount } = this
      const { interval } = this.subscriptionModel
      const coupon = this.previewSubscriptionCoupon && this.previewSubscriptionCoupon.coupon
      const promotion = coupon ? `(${this.previewSubscriptionCoupon.price})` : ''
      let price = (interval === 'year' ? this.yearlyPrice : this.monthlyPrice) * (coupon || 1)
      let total = price * employeeCount
      price = this.$n(price)
      total = this.$n(total)

      return this.$t('settings.settingsCompanyBilling.form.pricing', { price, employeeCount, total, promotion })
    },
    previewSubscriptionPeriod() {
      const { interval } = this.subscriptionModel
      return this.$t(`settings.settingsCompanyBilling.interval.${interval}.billing`)
    },
    previewSubscriptionInvoiceItems() {
      if (this.stripeOffer && this.stripeOffer.invoiceItems && this.stripeOffer.invoiceItems.length) {
        const invoiceItems = this.stripeOffer.invoiceItems
          .map(({ amount, description }) => this.$t('settings.settingsCompanyBilling.form.extraPricing', { extra: description, price: this.$n(amount / 100) }))
        return '+ ' + this.$options.filters.formattedSentence(invoiceItems)
      }
    },
    previewSubscriptionCoupon() {
      if (this.stripeOffer && this.stripeOffer.coupon && this.stripeOffer.coupon.percentOff) {
        return {
          name: this.stripeOffer.coupon.name,
          coupon: (100 - this.stripeOffer.coupon.percentOff) / 100,
          price: `-${this.stripeOffer.coupon.percentOff} %`
        }
      }
    },
    pricingUrl() {
      const pricingUrl = this.$t('settings.settingsCompanyBilling.pricingUrl')
      return location.hostname === 'localhost' ? pricingUrl.replace('https://www.clearwage.com', `${location.protocol}//${location.hostname}:4040`) : pricingUrl
    }
  },
  methods: {
    initModel() {
      const { company, user } = this
      this.subscriptionModel.billingName = company.billingName || company.name
      this.subscriptionModel.billingEmail = company.billingEmail || user.email
    },
    getIntervalDescription(interval) {
      // Monthly prices are displayed to simplify comparison
      const isYearly = interval === 'year'
      let price = isYearly ? Math.round(this.yearlyPrice) / 12 : this.monthlyPrice
      price = this.$n(price)
      const yearlyDiscount = Math.round((1 - this.monthlyPrice * 12 / this.yearlyPrice) * 100)
      const yearlyDiscountLabel = isYearly && yearlyDiscount ? ` (${yearlyDiscount}%)` : ''
      interval = this.$t('settings.settingsCompanyBilling.interval.month.noun')
      return this.$t('settings.settingsCompanyBilling.subscription.pro.dynamicPricing', { price, interval }) + yearlyDiscountLabel
    },
    setErrorMessage(errorMessage) {
      this.errorMessage = errorMessage
      animateScrollTo(0)
    },
    setSuccess() {
      this.isSuccess = true
      animateScrollTo(0)
    },
    async loadStripeSubscription() {
      this.isLoading = true
      try {
        this.stripeSubscription = await this.$store.dispatch('company/getStripeSubscription')
        this.stripeOffer = await this.$store.dispatch('company/getStripeOffer')
      }
      finally {
        this.isLoading = false
      }
    },
    contactUs() {
      const subject = this.$t('settings.settingsCompanyBilling.contactSubject')
      const text = ''
      const prefill = { subject, text }
      this.$router.push({ hash: '#contact', params: { prefill } })
    },
    async openStripePortal() {
      try {
        this.isLoadingStripePortalSession = true
        const params = { returnUrl: location.href.split('?')[0] }
        const url = await this.$store.dispatch('company/createStripePortalSession', params)
        location.href = url
      }
      catch (error) {
        this.setErrorMessage(error)
        this.isLoadingStripePortalSession = false
      }
    },
    async subscribe() {
      this.errorMessage = null
      this.$v.$touch()

      if (this.$v.$error) {
        return
      }
      if (this.employeeCount <= 5 && !this.$route.query.force) {
        return this.setErrorMessage(this.$t('settings.settingsCompanyBilling.form.minimumEmployeeCountMessage'))
      }
      this.isSubscribing = true
      const { subscriptionModel } = this
      subscriptionModel.quantity = this.employeeCount
      try {
        const url = await this.$store.dispatch('company/createStripeSubscription', subscriptionModel)
        location.href = url
      }
      catch (error) {
        this.errorMessage = error
        this.isSubscribing = false
      }
    }
  },
  validations: {
    subscriptionModel: {
      isTermsChecked: {
        required: (value) => value === true
      },
      billingName: { required },
      billingEmail: { required, email }
    }
  },
  created() {
    this.initModel()
    if (this.$route.query.cancel) {
      this.setErrorMessage(this.$t('settings.settingsCompanyBilling.form.cancelMessage'))
    }
    else if (this.$route.query.sessionId) {
      this.setSuccess()
    }
    this.loadStripeSubscription()
    this.$store.commit('setIsReadOnlyAlertDisplayed', false)
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/settings.scss";

@keyframes zoom {
  from {
    padding: 40px;
  }
  to {
    padding: 20px;
  }
}

.subscription {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: $settings-padding * 1.2;
  padding-bottom: $settings-padding;

  // Preview skin
  &.subscription-preview {
    align-items: center;

    .subscription-logo img {
      width: 80px;
      height: 80px;
      padding: 12px;
      animation: none;
    }
  }

  .subscription-logo {
    img {
      border: 1px solid lighten($graph-inner-border-color, 4);
      background: white;
      border-radius: $border-radius;
      width: 102px;
      height: 102px;
      padding: 20px;
      animation: zoom 600ms ease-in-out;
    }
  }

  .subscription-description {
    h4 {
      margin: 0;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        margin-top: 0.4em;
      }
    }

    .menu {
      margin: 0.75em 0 0;
    }
  }

  .subscription-menu {
    display: grid;
    grid-template-columns: auto auto;
    gap: 1em;
    text-align: left;
  }
}

.subscription-form {
  .input-label {
    display: inline-block;
    margin: 0 3px 10px 0;
  }

  .labeled-badge {
    @extend .badge-block;
    margin-right: 20px;
  }

  .checkbox.input-error {
    color: $red-color;
  }

  input[type="text"] {
    max-width: 55%;
  }
}

.slide-fade-enter-active {
  transition: all 0.2s linear;
}
.slide-fade-leave-active {
  transition: all 0.2s linear;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: scale(0.96);
  opacity: 0.75;
}

.success-banner {
  background: $lightteal-color;
  border: 1px solid darken($lightteal-color, 10);
  padding: 0 1em;
  margin-bottom: 1em;
  border-radius: $border-radius;

  .bullet {
    font-weight: bold;
    display: inline-block;
    background: white;
    width: 40px;
    text-align: center;
    border-radius: 1em;
    margin-right: 0.75em;
    color: $clearteal-color;
    font-size: 1.4rem;
    line-height: 38px;
    vertical-align: middle;
    padding-left: 4px;
    border: $border;
    box-shadow: inset 1px 2px rgba(0, 0, 0, 0.05);

    span {
      display: inline-block;
      animation: zoom-in 600ms ease-out;
      animation-delay: 10ms;
      animation-fill-mode: both;
    }
  }

  menu {
    float: right;
    margin: (-3.5em) 0 0;
  }
}

hr {
  margin: 3em 0 2.5em;
}

.iframe-features {
  border: none;
  width: 100%;
  height: 800px;
}
</style>
