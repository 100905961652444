<template>
  <div class="calculator">
    <menu class="float-right no-margin-top" v-if="grid">
      <button
        class="secondary"
        @click="addCurrentWageToSimulationWages"
        v-t="'grid.simulation.save'">
      </button>
    </menu>
    <h1 v-t="'grid.simulation.simulateSalary'"></h1>
    <div v-if="grid">
      <WageCalculator
        ref="wageCalculator"
        v-model="currentWage"
        :show-wage-type-selector="false"
        :grid="grid"
        @input="onSimulationChange" />
      <br>
      <h2 v-t="'grid.simulation.simulationWages'"></h2>
      <draggable v-model="simulationWages" @change="saveSimulationWages">
        <transition-group name="simulated-wages" tag="div">
          <div
            class="simulated-wage"
            v-for="wageDetails in simulationWageDetails"
            :key="wageDetails.id">
            <inline-wage :wageDetails="wageDetails" />
            <div class="remove" @click="removeSimulationWage(wageDetails)">×</div>
          </div>
        </transition-group>
      </draggable>
      <p class="alert" v-if="!simulationWages.length" v-html="$t('grid.simulation.help')"></p>
    </div>
    <div v-else>
      <span v-t="{path: 'grid.noCurrentGrid', args: {companyName: $$companyName}}"></span>
      <menu class="hero" v-if="$$isAdmin">
        <router-link
          tag="button"
          class="primary"
          to="/grid/onboarding"
          v-t="'grid.createFirstGrid'">
        </router-link>
      </menu>
    </div>
  </div>
</template>

<script>
import uuidv4 from 'uuid/v4'
import { mapGetters } from 'vuex'
import { getItem, setItem } from '@/utils/storage'
import { isSandboxWageValid, getWageDetails } from '@/utils/grid'
import InlineWage from '@components/wage/InlineWage.vue'
import WageCalculator from '@components/wage/WageCalculator.vue'
import Draggable from 'vuedraggable'

const CW_GRID_SIMULATED_WAGES = 'CW_GRID_SIMULATED_WAGES'

export default {
  components: {
    Draggable,
    InlineWage,
    WageCalculator
  },
  data() {
    return {
      currentWage: null,
      simulationWages: []
    }
  },
  computed: {
    ...mapGetters({
      grid: 'currentGrid/getCurrentGrid',
      simulationWage: 'currentGrid/getSimulationWage'
    }),
    simulationWageDetails() {
      return this.simulationWages.map(wage => {
        const wageDetails = getWageDetails(this.grid, wage)
        return { id: wage.id, ...wageDetails }
      })
    }
  },
  methods: {
    initModel() {
      this.currentWage = this.loadCurrentWage()
      this.simulationWages = this.loadSimulationWages()
    },
    loadCurrentWage() {
      const simulationWage = {
        gridId: this.simulationWage ? this.simulationWage.gridId : this.grid.id,
        hasLevels: true,
        levelIds: this.simulationWage ? [...this.simulationWage.levelIds] : [],
        skillIds: [],
        overridenSalaryValue: null
      }
      return simulationWage
    },
    loadSimulationWages() {
      try {
        return getItem(CW_GRID_SIMULATED_WAGES, []).filter(wage => isSandboxWageValid(wage, this.grid))
      }
      catch {
        return []
      }
    },
    onSimulationChange() {
      this.$store.dispatch('currentGrid/setSimulationWage', this.currentWage)
    },
    addCurrentWageToSimulationWages() {
      this.$refs.wageCalculator.closeLevelSelector().then(() => {
        const id = uuidv4()
        const wage = { ...this.currentWage, id }
        this.simulationWages.unshift(wage)
        this.saveSimulationWages()
      })
    },
    saveSimulationWages() {
      setItem(CW_GRID_SIMULATED_WAGES, this.simulationWages)
    },
    removeSimulationWage(wage) {
      this.simulationWages = this.simulationWages.filter(w => w.id !== wage.id)
      this.saveSimulationWages()
    }
  },
  created() {
    this.initModel()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/alert.scss";
@import "./src/styles/badge.scss";
@import "./src/styles/block.scss";
@import "./src/styles/button.scss";

.calculator {
  @include tab-container;
  background: white;
  padding: 20px;
}

h1 {
  // Align with GridEditorView's box
  margin-bottom: 21px;
}

.alert {
  margin: 0em 0 1em;
  padding-right: 2em;
}

.simulated-wage {
  @extend .block;
  border: 1px solid $graph-outer-border-color;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75em;
  overflow: hidden;
  transition: all 400ms;
  height: 61px;

  .components::v-deep .component {
    min-width: 90px;
  }

  .remove {
    display: inline-block;
    @include font-bigger-size;
    @include font-semibold;
    cursor: pointer;
    width: 34px;
    line-height: 34px;
    border-radius: 17px;
    text-align: center;
    margin-right: 0.75em;

    &:hover {
      color: $red-color;
      background: white;
    }

    &:active {
      background: darken($graph-lightblue-color, 5);
    }
  }
}

.simulated-wages-enter, .simulated-wages-leave-to {
  border-width: 0px;
  margin-bottom: 0;
  height: 0;
  opacity: 0;
}

.badge {
  margin-left: 0.5em;
}
</style>
