<template>
  <Modal class="sidebar" :visible="visible" @close="$emit('close')">
    <div class="options-modal">
      <h1 v-t="'orgChart.options.title'"></h1>
      <p v-t="'orgChart.options.intro'"></p>
      <div>
        <div class="input-label big" v-t="'orgChart.options.fields.role.title'"></div>
        <multiselect
          :value="optionsModel.role"
          :options="availableOptions.role"
          :searchable="false"
          :allowEmpty="false"
          :showLabels="false"
          :customLabel="formatOption"
          @input="value => setOption('role', value)"></multiselect>
      </div>
      <div>
        <div class="input-label big" v-t="'orgChart.options.fields.name.title'"></div>
        <multiselect
          :value="optionsModel.name"
          :options="availableOptions.name"
          :searchable="false"
          :allowEmpty="false"
          :showLabels="false"
          :customLabel="formatOption"
          @input="value => setOption('name', value)"></multiselect>
      </div>
      <div>
        <div class="input-label big" v-t="'orgChart.options.fields.info.title'"></div>
        <multiselect
          :value="optionsModel.info"
          :options="availableOptions.info"
          :searchable="false"
          :allowEmpty="false"
          :showLabels="false"
          :customLabel="formatOption"
          @input="value => setOption('info', value)"></multiselect>
      </div>
      <div>
        <div class="input-label big" v-t="'orgChart.options.fields.avatar.title'"></div>
        <multiselect
          :value="optionsModel.avatar"
          :options="availableOptions.avatar"
          :searchable="false"
          :allowEmpty="false"
          :showLabels="false"
          :customLabel="formatOption"
          @input="value => setOption('avatar', value)"></multiselect>
      </div>
      <div>
        <div class="input-label big" v-t="'orgChart.options.fields.group.title'"></div>
        <multiselect
          :value="optionsModel.group"
          :options="availableOptions.group"
          :searchable="false"
          :allowEmpty="false"
          :showLabels="false"
          :customLabel="formatOption"
          @input="value => setOption('group', value)"></multiselect>
      </div>
      <p class="no-margin-bottom" v-t="'orgChart.views.intro'"></p>
      <div class="views-container">
        <draggable class="views" v-model="viewsModel" @change="saveViews">
          <transition-group class="blue" name="views" tag="table">
            <tr
              class="row clickable"
              v-for="view in viewsModel"
              :key="view.id">
              <td class="view-name" @click="applyView(view)">
                {{view.name}}
              </td>
              <td class="view-action delete" @click="removeView(view)">×</td>
            </tr>
          </transition-group>
        </draggable>
      </div>
      <menu class="no-margin-top no-margin-bottom">
        <button class="small-button" @click="addCurrentViewToViews" v-t="'orgChart.views.add'"></button>
      </menu>
    </div>
  </modal>
</template>

<script>
import Draggable from 'vuedraggable'
import Modal from '@components/commons/Modal.vue'
import { getSetting, setSetting } from '@/utils/settings'
import { flattenedComponents } from '@/utils/grid'
import { mapGetters } from 'vuex'
import uuidv4 from 'uuid/v4'

const CW_ORG_CHART_OPTIONS = 'CW_ORG_CHART_OPTIONS'
const CW_ORG_CHART_VIEWS = 'CW_ORG_CHART_VIEWS'
const CW_ORG_CHART_OPTIONS_DEFAULT = {
  role: null,
  name: null,
  info: null,
  avatar: null,
  group: null
}

export default {
  components: {
    Draggable,
    Modal
  },
  props: {
    visible: {
      type: Boolean
    }
  },
  data() {
    return {
      optionsModel: CW_ORG_CHART_OPTIONS_DEFAULT,
      viewsModel: []
    }
  },
  computed: {
    ...mapGetters({
      referenceGrid: 'currentGrid/getReferenceGrid'
    }),
    commonOptions() {
      return this.injectGridOptions([
        'employee:jobTitle',
        'employee:fullName',
        'employee:arrivalDate',
        'employee:email',
        'none'
      ], true)
    },
    groupOptions() {
      return this.injectGridOptions([
        'employee:fullDepartment',
        'none'
      ])
    },
    avatarOptions() {
      return [
        'avatar:avatar',
        'avatar:initials',
        'avatar:none'
      ]
    },
    availableOptions() {
      return {
        role: this.commonOptions,
        name: this.commonOptions,
        info: this.commonOptions,
        avatar: this.avatarOptions,
        group: this.groupOptions
      }
    },
    defaultViews() {
      const defaultView = {
        id: uuidv4(),
        name: this.$t('orgChart.views.default.title'),
        options: {
          role: this.availableOptions.role[0],
          name: this.availableOptions.name[1],
          info: this.availableOptions.info[2],
          avatar: this.availableOptions.avatar[0],
          group: this.availableOptions.group[0]
        }
      }
      let gridView
      if (this.$$isAtLeastManager && this.referenceGridComponents.length) {
        gridView = {
          id: uuidv4(),
          name: this.$t('orgChart.views.default.title2'),
          options: {
            role: this.availableOptions.role.filter(c => c.includes('wage:'))[0],
            name: this.availableOptions.name.filter(c => c.includes('wage:'))[1],
            info: this.availableOptions.info[1],
            avatar: this.availableOptions.avatar[1],
            group: this.availableOptions.group[0]
          }
        }
      }
      let salaryView
      if (this.$$isAtLeastManager) {
        salaryView = {
          id: uuidv4(),
          name: this.$t('orgChart.views.default.title3'),
          options: {
            role: this.availableOptions.role[0],
            name: this.availableOptions.name[4],
            info: this.availableOptions.info[6],
            avatar: this.availableOptions.avatar[2],
            group: this.availableOptions.group[0]
          }
        }
      }
      return [defaultView, gridView, salaryView].filter(v => v)
    },
    referenceGridComponents() {
      const referenceGrid = this.referenceGrid
      return referenceGrid ? flattenedComponents(referenceGrid.components) : []
    }
  },
  methods: {
    initModel() {
      this.optionsModel = this.loadOptions()
      this.viewsModel = this.loadViews()
      this.$store.dispatch('orgChart/setOptions', this.optionsModel)
    },
    injectGridOptions(options, injectSalary) {
      // Add employee:salary option
      if (this.$$isAtLeastManager && injectSalary) {
        const salaryOption = 'employee:salary'
        options = [...options.slice(0, -1), salaryOption, ...options.slice(-1)]

        const contractualVariableOption = 'employee:contractualVariable'
        options = [...options.slice(0, -1), contractualVariableOption, ...options.slice(-1)]

        const latestSalaryRaise = 'employee:latestSalaryRaise'
        options = [...options.slice(0, -1), latestSalaryRaise, ...options.slice(-1)]
      }
      // Add wage: options
      if (this.$$isAtLeastManager && this.referenceGridComponents.length) {
        const gridOptions = this.referenceGridComponents.map(c => 'wage:' + c.id)
        options = [...options.slice(0, -1), ...gridOptions, ...options.slice(-1)]
      }
      return options
    },
    loadOptions() {
      const options = getSetting(CW_ORG_CHART_OPTIONS, CW_ORG_CHART_OPTIONS_DEFAULT)
      options.role = this.availableOptions.role.find(v => v === options.role)
        ? options.role
        : this.defaultViews[0].options.role
      options.name = this.availableOptions.name.find(v => v === options.name)
        ? options.name
        : this.defaultViews[0].options.name
      options.info = this.availableOptions.info.find(v => v === options.info)
        ? options.info
        : this.defaultViews[0].options.info
      options.avatar = this.availableOptions.avatar.find(v => v === options.avatar)
        ? options.avatar
        : this.defaultViews[0].options.avatar
      options.group = this.availableOptions.group.find(v => v === options.group)
        ? options.group
        : this.defaultViews[0].options.group
      return options
    },
    setOption(key, value) {
      // Switch option with other field
      if (value !== 'none') {
        ['role', 'name', 'info'].forEach(optionKey => {
          if (this.optionsModel[optionKey] === value) {
            this.$set(this.optionsModel, optionKey, this.optionsModel[key])
          }
        })
      }
      this.$set(this.optionsModel, key, value)
      this.saveOptions()
    },
    saveOptions() {
      this.$store.dispatch('orgChart/setOptions', this.optionsModel)
      setSetting(CW_ORG_CHART_OPTIONS, this.optionsModel)
    },
    formatOption(key) {
      const [scope, id] = key.split(':')
      switch (scope) {
        case 'employee':
          switch (id) {
            case 'latestSalaryRaise':
              return this.$t('orgChart.fields.latestSalaryRaise.name')
            default:
              return this.$t(`employees.employee.form.${id}`)
          }
        case 'avatar':
          switch (id) {
            case 'avatar':
              return this.$t('orgChart.fields.avatar.avatar')
            case 'initials':
              return this.$t('orgChart.fields.avatar.initials')
            case 'none':
            default:
              return this.$t('orgChart.fields.none')
          }
        case 'wage':
          const component = this.referenceGridComponents.find(c => c.id === id)
          return component ? this.$t('orgChart.fields.wage.name', { component: component.name }) : '—'
        case 'none':
        default:
          return this.$t('orgChart.fields.none')
      }
    },
    loadViews() {
      let views = getSetting(CW_ORG_CHART_VIEWS, [])
      if (!views.length) {
        views = [...this.defaultViews]
      }
      return views
    },
    addCurrentViewToViews() {
      const name = window.prompt(this.$t('orgChart.views.custom.prompt'), this.$t('orgChart.views.custom.title'))
      if (name) {
        const id = uuidv4()
        const view = { id, options: { ...this.optionsModel }, name }
        this.viewsModel.push(view)
        this.saveViews()
      }
    },
    saveViews() {
      setSetting(CW_ORG_CHART_VIEWS, this.viewsModel)
    },
    applyView(view) {
      this.optionsModel = { ...view.options }
      this.saveOptions()
    },
    removeView(view) {
      this.viewsModel = this.viewsModel.filter(w => w.id !== view.id)
      this.saveViews()
      // Restore the default view if removed:
      this.viewsModel = this.loadViews()
    }
  },
  created() {
    this.initModel()
  }

}
</script>

<style lang="scss" scoped>
@import "./src/styles/teams.scss";
@import "./src/styles/table.scss";

.options-modal {
  width: 385px;
  height: calc(100vh - 120px - 20px);
  padding: 15px 20px;

  h1 {
    background: transparent url(~@/assets/icon-org-chart.svg) no-repeat left
      center;
    background-size: 20px;
    padding-left: 30px;
  }

  .multiselect {
    margin-bottom: 1rem;
  }

  .views-container {
    border: 1px $graph-outer-border-color solid;
    border-radius: $border-radius;
    box-shadow: 1px 1.5px 0 rgba(0, 0, 0, 0.03);
    margin: 0.6rem 0;

    table {
      @include font-small-size;
      user-select: none;

      td {
        line-height: 2em;
        padding: 0.25em 0.5em;
        border: 1px solid $graph-outer-border-color;
      }

      .view-name {
        width: 100%;
      }

      .view-action {
        color: $light-text-color;
        padding: 0 1.5em;

        &.delete {
          @include font-bold;
          padding: 0 1em;
        }
      }

      .row.clickable:hover {
        .view-action {
          color: darken($clearteal-color, 10);

          &.delete {
            color: darken($red-color, 12);
          }
        }
      }
    }
  }
}
</style>
