<template>
  <div class="grid-diff-container">
    <menu v-if="changelog.length || isError" class="actions-menu fade-in">
      <slot />
    </menu>
    <div class="grid-title">
      <h3 v-t="'grid.diff.title'"></h3>
      <GridVersion :grid="newGrid" />
    </div>
    <resizable-panel :observer="[changelog, isDiffVisible]">
      <LoadingView v-if="isLoading" />
      <p class="alert warning no-margin-bottom" v-else-if="isError" v-html="$t('grid.diff.error')"></p>
      <div v-else-if="changelog.length" class="changelog">
        <div
          v-for="(group, i) in changelog"
          :key="[group.name, i].join()">
          <div class="change-group-name">
            <strong>{{group.name}}</strong>
            <div v-if="!isDiffVisible" class="change-group-summary" v-html="group.summary"></div>
          </div>
          <div v-if="isDiffVisible" class="change-items">
            <div
              class="change-item"
              v-for="(change, j) in getGroupChanges(group)"
              :key="[change.name, j].join()">
              <span class="change-badge" :class="change.operation" v-t="`grid.diff.kind.${change.kind}`"></span>
              <div>
                <div class="change-name">{{change.name}}</div>
                <div class="change-diff">
                  <div
                    v-for="(diff, k) in change.diff"
                    :key="[diff.key, k].join()">
                    <strong v-if="diff.key">{{diff.key}} : </strong>
                    <span v-if="!diff.parts">{{diff.newValue}}</span>
                    <span
                      v-else
                      v-for="(part, l) in diff.parts"
                      :key="[part.value, l].join()"
                      :class="{add: part.added, remove: part.removed}">{{part.value}}</span>
                  </div>
                </div>
              </div>
            </div>
            <button
              v-if="!isGroupExpanded(group)"
              class="collapsed-button small-button"
              @click="expandGroup(group)">{{$tc('grid.diff.remaining', getGroupRemainingChanges(group), {count: getGroupRemainingChanges(group)})}}</button>
          </div>
        </div>
        <button
          class="diff-visible-button small-button"
          @click="toggleDiff"
          v-t="isDiffVisible ?'grid.diff.hideDiff' : 'grid.diff.showDiff'"></button>
      </div>
      <div v-else key="identical" class="dark-light-text" v-t="'grid.diff.identical'"></div>
    </resizable-panel>
  </div>
</template>

<script>
import { captureException } from '@sentry/browser'
import animateScrollTo from 'animated-scroll-to'
import GridVersion from '@components/grid/viewer/GridVersion.vue'
import ResizablePanel from '@components/commons/ResizablePanel'
import LoadingView from '@components/commons/LoadingView'
import debounce from 'lodash.debounce'
import { mapGetters } from 'vuex'

const CHANGELOG_REFRESH_DELAY = 600
const CHANGELOG_CHANGES_LIMIT = 10

export default {
  components: {
    GridVersion,
    LoadingView,
    ResizablePanel
  },
  props: {
    oldGrid: Object,
    newGrid: Object,
    oldWages: Array,
    newWages: Array,
    employees: Array
  },
  data() {
    return {
      changelog: [],
      isDiffVisible: false,
      expandedGroups: {},
      isLoading: false,
      isError: false
    }
  },
  computed: {
    ...mapGetters({
      company: 'account/getCompany'
    })
  },
  methods: {
    toggleDiff() {
      this.isDiffVisible = !this.isDiffVisible
      if (!this.isDiffVisible) {
        const shouldScroll = this.$el.getBoundingClientRect().top < 0
        if (shouldScroll) {
          animateScrollTo(this.$el, { verticalOffset: -11, minDuration: 0, maxDuration: 600 })
        }
        this.expandedGroups = {}
      }
    },
    async generateChangelog() {
      this.isError = false
      try {
        const { oldGrid, newGrid, oldWages, newWages, employees, company } = this
        const params = { oldGrid, newGrid, oldWages, newWages, employees, company }
        this.changelog = await this.$store.dispatch('workers/generateGridChangelog', params)
        this.isLoading = false
      }
      catch (e) {
        this.isError = true
        this.isLoading = false
        captureException(e)
      }
    },
    generateChangelogDebounced: debounce(function() {
      this.generateChangelog()
    }, CHANGELOG_REFRESH_DELAY),
    expandGroup(group) {
      this.$set(this.expandedGroups, group.name, true)
    },
    isGroupExpanded(group) {
      return this.expandedGroups[group.name] || group.changes.length <= CHANGELOG_CHANGES_LIMIT
    },
    getGroupRemainingChanges(group) {
      return group.changes.length - CHANGELOG_CHANGES_LIMIT
    },
    getGroupChanges(group) {
      if (!this.isGroupExpanded(group)) {
        return group.changes.slice(0, CHANGELOG_CHANGES_LIMIT)
      }
      else {
        return group.changes
      }
    }
  },
  watch: {
    oldGrid: 'generateChangelogDebounced',
    newGrid: 'generateChangelogDebounced',
    oldWages: 'generateChangelogDebounced',
    newWages: 'generateChangelogDebounced'
  },
  mounted() {
    this.isLoading = true
    this.generateChangelogDebounced()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/alert.scss";
@import "./src/styles/button.scss";
@import "./src/styles/block.scss";

.grid-diff-container {
  @extend .block;
  border-bottom: none;
  margin-bottom: 1em;
  padding-bottom: 1.1em;
}

.grid-title {
  display: flex;
  align-items: center;
  margin-bottom: 0.6em;

  h3 {
    margin: 0;
  }
}

.actions-menu {
  margin: 0 0 -1em;
  float: right;
}

.changelog {
  @include line-regular-height;
}

.change-group-name {
  margin-bottom: 0.5em;
}

.change-group-summary::v-deep {
  span {
    @include font-medium;
  }

  .update {
    color: lighten($graph-darkblue-color, 10);
  }

  .add {
    color: darken($clearteal-color, 5);
  }

  .remove {
    color: $orange-color;
  }
}

.change-items {
  margin-top: 0.5em;
  &:not(:last-of-type) {
    margin-bottom: 1.5em;
  }
  @include font-small-size;
}

.change-item {
  margin-bottom: 0.5em;
  display: flex;
  align-items: flex-start;
}

.change-badge {
  @include font-medium;
  @include font-tabular-numbers;
  text-transform: lowercase;
  display: inline-block;
  padding: 0 0.4em;
  border-radius: $border-radius;
  border: 1px solid transparent;
  margin-right: 0.5em;
  white-space: nowrap;

  &::before {
    margin-right: 0.4em;
  }

  &.update {
    background: lighten($graph-darkblue-color, 55);
    color: lighten($graph-darkblue-color, 10);
    border-color: lighten($graph-darkblue-color, 35);
    background: url(~@/assets/update.svg) no-repeat 7px 6px;
    padding-left: 20px;
  }

  &.add {
    background: $lightteal-color;
    color: darken($clearteal-color, 5);
    border-color: darken($lightteal-color, 25);

    &::before {
      content: "+";
    }
  }

  &.remove {
    color: $orange-color;
    background: $lightorange-color;
    border-color: darken($lightorange-color, 25);

    &::before {
      content: "-";
    }
  }
}

.change-name {
  @include font-medium;
  padding-top: 1px;
}

.change-diff {
  strong {
    @include font-medium;
  }

  .add {
    color: darken($clearteal-color, 5);
  }

  .remove {
    color: $orange-color;
  }

  .remove + .add {
    margin-left: 0.25em;
  }
}

.collapsed-button {
  margin-top: 0.2em;
  margin-bottom: 1em;
}

.diff-visible-button {
  margin-top: 0.25em;
}

.loading {
  padding: 1em 0;
  opacity: 0.8;
}
</style>
