<template>
  <div
    class="progress"
    :class="{collapsed}"
    :title="$t(collapsed ? 'common.expand' : 'common.collapse')">
    <onboarding-progress-bar :percent="progress" :showCheckmark="false" />
  </div>
</template>

<script>
import OnboardingProgressBar from '@components/grid/onboarding/OnboardingProgressBar'
import partition from 'lodash.partition'

export default {
  components: { OnboardingProgressBar },
  props: {
    level: Object,
    selectedSkills: Array,
    collapsed: Boolean,
    reached: Boolean,
    next: Boolean,
    hasManualLevels: Boolean
  },
  computed: {
    progress() {
      const skillIds = this.level.skills.map(s => s.id)
      const [selected, unselected] = partition(skillIds, id => this.selectedSkills.includes(id))
      const count = selected.length
      const total = count + unselected.length
      let percent = Math.round(count / total * 100)

      if (percent === 100 && !this.next && !this.reached && !this.hasManualLevels) {
        percent -= 15
      }

      return {
        count,
        total,
        percent
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.progress {
  cursor: pointer;
  padding-top: 2px;

  .progress-bar::v-deep {
    label {
      @include font-normal-size;
      @include font-semibold;
    }
  }
}
</style>
