<template>
  <div class="variable-plan-periods-table">
    <table class="blue">
      <template
        class="variable-plan-period row clickable"
        v-for="(variablePlan, i) in limitedVariablePlans">
        <tr
          v-if="(!i || variablePlan.status !== variablePlans[i - 1].status)"
          :key="[variablePlan.id, 'status'].join('-')">
          <th
            class="variable-plan-status bg"
            :class="variablePlan.status"
            colspan="4"
            v-html="$tc(`variable.statuses.${variablePlan.status}.longTitle`, limitedVariablePlans.filter(v => v.status === variablePlan.status).length)"></th>
        </tr>
        <tr
          :key="variablePlan.id"
          :class="$route.params.highlightId === variablePlan.id ? $route.params.highlightClass : ''"
          @click="$router.push({name: 'variablePlan', params: {id: variablePlan.id}})">
          <td>
            <span v-html="formatSmartPeriod(variablePlan)"></span>
          </td>
          <td>
            {{$tc('employees.employees.count', variablePlan.variableWagesCount, {count: variablePlan.variableWagesCount})}}
          </td>
          <td></td>
          <td>
            <span v-if="variablePlan.status === 'closed'" class="badge border-badge">{{variablePlan.computedCents / 100 | formattedCurrency}}</span>
          </td>
        </tr>
      </template>
      <tr v-if="shouldDisplayLimitToggle" @click="toggleLimit">
        <td colspan="4">
          <div class="variable-plan-toggle-limit" v-t="isLimited ? 'common.showAll' : 'common.showLess'"></div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { formatSmartPeriod } from '@/utils/variable'

export default {
  props: {
    variablePlans: {
      type: Array
    },
    limit: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      isLimited: true
    }
  },
  computed: {
    limitedVariablePlans() {
      this.$parent.$emit('resize')
      if (this.limit && this.isLimited) {
        // Limit is only applied to closed periods since they're the only "cold" ones
        const variablePlansWitoutClosed = this.variablePlans.filter(v => v.status !== 'closed')
        const closedVariablePlans = this.variablePlans.filter(v => v.status === 'closed').slice(0, this.limit)
        return [...variablePlansWitoutClosed, ...closedVariablePlans]
      }
      else {
        return this.variablePlans
      }
    },
    shouldDisplayLimitToggle() {
      return this.limit && this.variablePlans.filter(v => v.status === 'closed').length > this.limit
    }
  },
  methods: {
    formatSmartPeriod,
    toggleLimit() {
      this.isLimited = !this.isLimited
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/variable.scss";

.variable-plan-periods-table {
  border: 1px solid $graph-outer-border-color;
  border-radius: $border-radius;
  margin: $variable-padding 0 0;
  overflow: hidden;

  table {
    border-collapse: collapse;
    border-style: hidden;
    width: 100%;
    background: white;
  }

  td,
  th {
    line-height: 2.5em;
    white-space: nowrap;
    text-align: left;
    padding: 0 10px;
    border-top: 1px solid $graph-inner-border-color;
  }

  td {
    cursor: pointer;
  }

  tr:hover {
    background: darken($graph-lightblue-color, 2);
  }

  th {
    @include font-small-size;
    line-height: 2em;
  }

  td {
    &::v-deep .arrow {
      @include font-normal-size;
      color: $light-text-color;
      vertical-align: 1px;
    }

    .badge {
      line-height: 1.1em;
    }
  }

  td:first-of-type {
    width: 30%;
  }

  td:nth-of-type(2) {
    color: $light-text-color;
  }

  td:last-of-type {
    text-align: right;
  }
}

.variable-plan-toggle-limit {
  text-align: center;
  text-transform: uppercase;
  @include font-bold;
  @include font-smaller-size;
  color: $clearteal-color;
}
</style>
