<template>
  <div>
    <grid-valuation-editor />
  </div>
</template>

<script>
import GridValuationEditor from '@components/grid/valuation/GridValuationEditor'

export default {
  components: {
    GridValuationEditor
  }
}
</script>
