<template>
  <div class="invitation-status">
    <div class="invitation-status-content">
      <p v-t="{
        path: `settings.settingsCompanyAccessRights.has${isExpired ? 'Expired' : 'ReceivedMail'}`,
        args: {
          name: user.firstName
        }
      }">
      </p>
      <p>
        <input
          type="text"
          ref="url"
          :value="invitationUrl">
      </p>
      <menu>
        <button
          class="small-button"
          @click="copyInvitationUrl">
          <span v-if="iSCopied" v-t="'settings.settingsCompanyAccessRights.copied'"></span>
          <span v-else v-t="'settings.settingsCompanyAccessRights.copy'"></span>
        </button>
        <button
          class="small-button green"
          v-t="'settings.settingsCompanyAccessRights.resendInvitation'"
          @click="$emit('sendInvitation')">
        </button>
      </menu>
    </div>
  </div>
</template>

<script>
import copy from 'copy-to-clipboard'

export default {
  props: {
    user: Object,
    isExpired: Boolean
  },
  data() {
    return {
      iSCopied: false
    }
  },
  computed: {
    invitationUrl() {
      return this.$t('urls.invitation', { token: this.user.invitationToken })
    }
  },
  methods: {
    copyInvitationUrl() {
      copy(this.invitationUrl)
      this.iSCopied = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";

.invitation-status {
  display: none;
  text-align: left;
  z-index: 10;
  position: absolute;
  width: 430px;
  right: -1px;

  .invitation-status-content {
    background: $graph-lightblue-color;
    margin-top: 10px;
    padding: 10px 20px;
    border: $border;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0px 2px 0 rgba(0, 0, 0, 0.1);

    p {
      margin: 0 0 20px 0;
    }
  }
}
</style>
