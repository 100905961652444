<template>
  <div class="grid-component-table" :class="{menu: selectedLevel, 'wage-details': wageDetails}">
    <table class="blue">
      <!-- Header -->
      <tr>
        <th
          class="name colored-link"
          :class="arrowClassName('rank')"
          @click="sortBy('rank')">
          {{ title || component.name }}
        </th>
        <th
          class="colored-link employees-count"
          :class="arrowClassName('employeesCount')"
          @click="showEmployees && sortBy('employeesCount')">
          <template v-if="showEmployees">
            {{$t('grid.current.employees')}}
          </template>
        </th>
        <th
          class="colored-link operation-name"
          :class="arrowClassName('salaryValue')"
          @click="sortBy('salaryValue')"
          v-t="isInterval ? 'grid.editor.interval' : 'grid.editor.amount'">
        </th>
      </tr>
      <tbody
        v-for="level in sortedLevels"
        :key="level.id"
        class="row"
        :class="{
          active: selectedLevel && level.id === selectedLevel.id,
          clickable: selectedLevel,
          reached: isLevelReached(level),
          selected: isLevelSelected(level)
        }"
        @click="$emit('select', level)">
        <!-- Level -->
        <tr>
          <td class="level-name-container">
            <template v-if="wageDetails">
              <checkbox :checked="isLevelReached(level)" />
              <span class="level-name"> {{level.name}} </span>
              <span v-if="hasSkills && isLevelSelected(level)" class="level-name-current" v-t="'wageCalculator.currentLevelLong'"></span>
            </template>
            <template v-else>
              <span class="level-name">{{level.name}}</span>
            </template>
            <markdown-text v-if="showDescriptions" class="description" v-model="level.description" />
          </td>
          <td class="employees-count">
            <employees-badges v-if="showEmployees" :employees="level.employees" />
          </td>
          <td class="level-value" :class="{'light-text': !getFormattedLevelValue(level)}">
            {{ getFormattedLevelValue(level) }}
          </td>
        </tr>
        <tr
          v-if="hasSkills"
          class="skills-row">
          <td :colspan="linkedLevel ? 4 : 3">
            <ul class="skills">
            <template v-for="(skill, i) in level.skills">
              <li class="domain"
                :key="skill.id + '-domain'" v-if="i <= 0 || level.skills[i - 1].domain.name != skill.domain.name">
                {{skill.domain.name}}
                <tooltip :message="skill.domain.description" />
              </li>
              <li :key="skill.id" class="skill">
                <CheckableSkill
                  :skill="skill"
                  :selectedSkills="isSkillChecked(skill)"
                  :disabled="true"
                  :progress="getSkillProgress(skill, level.employees)" />
              </li>
            </template>
          </ul>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { getFlatComponent } from '@/utils/skills'
import { getVisibleLinkedLevels, isInterval, remunerationTypesForComponent, valueFromOperation } from '@/utils/grid'
import { mapGetters } from 'vuex'
import CheckableSkill from '@components/commons/CheckableSkill.vue'
import Checkbox from '@components/commons/Checkbox.vue'
import MarkdownText from '@components/commons/MarkdownText.vue'
import Tooltip from '@components/commons/Tooltip.vue'
import EmployeesBadges from '@components/employees/EmployeesBadges.vue'
import flatten from 'lodash.flatten'

export default {
  components: {
    CheckableSkill,
    Checkbox,
    EmployeesBadges,
    MarkdownText,
    Tooltip
  },
  props: {
    component: Object,
    wageDetails: Object,
    // Used in formattedLevelValue
    simulatedWageDetails: Object,
    grid: Object,
    linkedComponent: Object,
    linkedLevel: Object,
    selectedLevel: Object,
    title: String,
    showDescriptions: Boolean,
    showEmployees: Boolean,
    showSkills: Boolean,
    showSkillsProgress: Boolean,
    showValues: Boolean
  },
  data() {
    return {
      sortKey: 'rank',
      sortOrder: 1
    }
  },
  computed: {
    ...mapGetters({
      employees: 'employees/getEmployees'
    }),
    employeesByLevels() {
      return this.employees.reduce((memo, employee) => {
        if (employee.currentWage && employee.currentWage.hasLevels) {
          if (this.simulatedWageDetails && this.simulatedWageDetails.selectedComponents && this.simulatedWageDetails.selectedComponents[0].selectedLevel && !employee.currentWage.levelIds.includes(this.simulatedWageDetails.selectedComponents[0].selectedLevel.id)) {
            return memo
          }
          employee.currentWage.levelIds.forEach(levelId => {
            if (!memo[levelId]) {
              memo[levelId] = []
            }
            memo[levelId].push(employee)
          })
        }
        return memo
      }, {})
    },
    enrichedLevels() {
      let component = this.component
      component = { ...component, levels: getVisibleLinkedLevels(this.component.levels, this.linkedLevel) }
      component = getFlatComponent(component) || component
      return component.levels
        .filter(l => !l.isHidden)
        .map(level => {
          const employeesForLevel = this.employeesByLevels[level.id]
          const employees = employeesForLevel || []
          const employeesCount = employees.length

          return {
            ...level,
            employeesCount,
            employees
          }
        })
    },
    enrichedLevelsEmployees() {
      return flatten(this.enrichedLevels.map(l => l.employees))
    },
    sortedLevels() {
      return this.enrichedLevels.slice().sort((a, b) => {
        a = a[this.sortKey]
        b = b[this.sortKey]
        return (a === b ? 0 : (a > b ? 1 : -1)) * this.sortOrder
      })
    },
    hasSkills() {
      return this.showSkills && this.sortedLevels.length && this.sortedLevels[0].skills
    },
    operationLabel() {
      return this.component.salaryOperation === 'multiplier' ? this.$t('grid.editor.coef') : this.$t('grid.editor.amount')
    },
    remunerationTypes() {
      return remunerationTypesForComponent(this.component)
    },
    arrowClassName() {
      return (key) => this.sortKey === key ? 'arrow-' + this.sortOrder : ''
    },
    isInterval() {
      return isInterval(this.component)
    }
  },
  methods: {
    valueFromOperation,
    getFormattedLevelValue(level, placeholder = true) {
      const wageDetails = this.wageDetails || this.simulatedWageDetails
      const formattedLevelValue = this.$options.filters.formattedLevelValue(this.component, level, this.linkedComponent, this.linkedLevel, 'grid', { wageDetails })
      return (this.showValues && formattedLevelValue) ||
        (placeholder && !formattedLevelValue ? this.$t('grid.editor.toBeDefined') : undefined)
    },
    sortBy(key) {
      if (this.sortKey !== key) {
        this.sortOrder = 1
      }
      else {
        this.sortOrder *= -1
      }
      this.sortKey = key
    },
    isLevelReached(level) {
      if (this.wageDetails) {
        const { selectedLevel } = this.wageDetails.selectedComponents.find(c => c.id === this.component.id) || {}
        if (selectedLevel) {
          if (this.hasSkills) {
            return (level.rank <= selectedLevel.rank) && (!this.linkedLevel || this.linkedLevel.id === selectedLevel.linkedLevelId)
          }
          else {
            return level.id === selectedLevel.id
          }
        }
      }
    },
    isLevelSelected(level) {
      if (this.wageDetails) {
        const { selectedLevel } = this.wageDetails.selectedComponents.find(c => c.id === this.component.id) || {}
        if (selectedLevel) {
          return level.id === selectedLevel.id
        }
      }
    },
    isSkillChecked(skill) {
      return this.wageDetails ? this.wageDetails.skillIds : [skill.id]
    },
    getSkillProgress(skill) {
      if (this.showSkillsProgress && this.hasSkills) {
        const employeesCount = this.enrichedLevelsEmployees.length
        if (employeesCount) {
          const skillCount = this.enrichedLevelsEmployees.reduce((memo, employee) => {
            if (employee.currentWage && employee.currentWage.skillIds && employee.currentWage.skillIds.find(id => skill.id === id)) {
              memo++
            }
            return memo
          }, 0)
          return skillCount / employeesCount
        }
        else {
          return 0
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/link.scss";
@import "./src/styles/table.scss";

.grid-component-table {
  border: 1px solid $graph-outer-border-color;
  border-radius: $border-radius;
  display: inline-block;
}

table {
  width: auto;
  @include font-large-size;

  th.arrow-1::after,
  th.arrow--1::after {
    vertical-align: -3px;
  }

  th,
  td {
    line-height: 2.75em;
    border-top: 1px solid $graph-outer-border-color;
  }

  .row {
    // Fix level-value position when level has a description
    vertical-align: top;
  }

  .row:nth-child(even) .checkable-skill::v-deep .skill-label {
    background-color: $graph-lightblue-color;
  }

  .skills-row td {
    border: none;
  }
}

.name {
  text-align: left;
}

.operation-name {
  text-align: right;
}

.level-name-container {
  @include font-semibold;
  width: 100%;
  text-align: left;

  .level-name-current {
    @include font-smaller-size;
    @include font-bold;
    text-transform: uppercase;
    padding-top: 4px;
    color: lighten($graph-darkblue-color, 10);
    margin-left: 1em;
  }

  .description {
    @include font-normal-size;
    margin: -0.25em 0 0.75em 0 !important;
  }

  .checkbox {
    pointer-events: none;

    &::v-deep label {
      vertical-align: initial;

      &::before {
        margin-top: -2px;
      }
    }
  }
}

.level-value {
  text-align: right;
  @include font-tabular-numbers;
  white-space: nowrap;
}

.employees-count {
  text-align: right;
}

.description {
  @include font-regular;
  margin: 0.75em 0 !important;
}

.domain {
  @include font-normal-size;
  @include font-semibold;
  @include line-regular-height;

  &:not(:first-child) {
    margin-top: 0.75em;
  }
}

ul.skills {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-bottom: 1em;
}

li.skill .checkable-skill {
  @include font-normal-size;
  padding: 0.2em 0 0;
}

// Menu skin
.grid-component-table.menu {
  position: sticky;
  top: 60px;
  max-height: 80vh;
  overflow: auto;

  .description {
    display: none;
  }

  .employees-count,
  .operation-name,
  .level-value {
    display: none;
  }
}

// Wage Details skin
.grid-component-table.wage-details {
  .row:nth-child(odd) {
    background-color: white;
  }

  .row:not(.selected) {
    .level-value {
      color: $light-text-color;
    }
  }

  .row.reached {
    background-color: lighten($graph-lightblue-color, 1);

    .level-name {
      color: $graph-darkblue-color;
    }
  }
}
</style>
