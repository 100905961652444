<template>
  <div v-if="employees.length">
    <div class="employees">
      <table class="blue">
        <tr class="row">
          <th
            class="employee-name colored-link"
            :class="arrowClassName('employee')"
            @click="sortBy('employee')">
            {{$tc('employees.employees.count', employees.length)}}
          </th>
          <th
            class="current-salary colored-link"
            :class="arrowClassName('currentSalary')"
            @click="sortBy('currentSalary')">
            {{$t('grid.editor.currentMale')}}
          </th>
          <th
            class="post-grid-salary colored-link"
            :class="arrowClassName('postGridSalary')"
            @click="sortBy('postGridSalary')">
            {{$t(isOnboarding ? 'grid.editor.postGrid' : 'grid.editor.newMale')}}
          </th>
          <th
            class="post-grid-salary-rise colored-link"
            :class="arrowClassName('postGridSalaryRise') + (isPercentMode ? ' percent' : '')"
            @click="sortBy('postGridSalaryRise')">
            {{$t('grid.editor.diff')}}
          </th>
        </tr>
        <template v-for="employee in employees">
          <tr
            class="row employee-tooltip-container"
            :class="{animating: employee.isAnimating, clickable: isClickable}"
            :key="employee.id"
            @click="select(employee)">
            <td class="employee-name">
              <name-thumbnail class="blue" :employee="employee" inline=true></name-thumbnail>
              {{ employee.fullName }}
              <div class="employee-info" v-if="$scopedSlots.info">
                <slot name="info" :employee="employee" />
              </div>
              <span class="role light-text">{{employee.description}}</span>
              <div class="employee-tooltip">
                <InlineWage
                  :wageDetails="employee.postGridWageDetails"
                  :showSalary="false"
                  :shouldHighlightHiddenLevel="true" />
                <div class="edit-employee" v-t="'grid.editor.clickToEdit'"></div>
              </div>
              <span
                v-if="employee.sandboxWage.hasLevels && employee.sandboxWage.overridenSalaryValue"
                class="employee-wage-label adjusted"
                v-t="'grid.editor.adjusted'"></span>
              <span
                v-else-if="!employee.sandboxWage.hasLevels"
                class="employee-wage-label adjusted"
                v-t="'grid.editor.offGrid'"></span>
            </td>
            <td class="employee-salary" :class="{interval: showIntervals}">
              <div>{{ employee.currentSalary | formattedCurrency }}</div>
              <WageMiniInterval
                v-if="showIntervals"
                :employee="employee"
                :useCurrentSalary="true" />
            </td>
            <td class="employee-salary" :class="{interval: showIntervals}">
              <div>{{ employee.postGridSalary | formattedCurrency }}</div>
              <WageMiniInterval
                v-if="showIntervals"
                :employee="employee"
                :useCurrentSalary="false" />
            </td>
            <td
              @click.stop="togglePercentMode"
              v-if="employee.postGridWageValid"
              :class="{error: employee.postGridSalaryRise < 0, percent: isPercentMode}"
              v-bind:style="{background: employee.background}">
              <template v-if="isPercentMode">
                {{ employee.postGridSalaryRisePercent | formattedDeltaNumber(0, 0) }}&nbsp;%
              </template>
              <template v-else>
                {{ employee.postGridSalaryRise | formattedCurrency(false, {signDisplay: "always"}) }}
              </template>
            </td>
            <td v-else>
              <div class="center wip" v-t="'grid.editor.toBeQualified'"></div>
            </td>
          </tr>
          <!-- The Salary Decrease Banner is very obtrusive and has been temporarily disabled -->
          <tr
            v-if="false && isClickable && employee.postGridWageValid && employee.postGridSalaryRise < 0"
            class="alert warning"
            :key="[employee.id, 'warning'].join()">
            <td colspan="4">
              {{$t('grid.editor.salaryDecreaseWarning', {employee: employee.firstName})}}
              <div class="float-right">
                <button
                  v-if="isAdjusting !== employee.id"
                  class="small-button orange adjust-button"
                  @click="adjustEmployeeWage(employee.id, employee.postGridSalaryFixed)"
                  v-t="'grid.editor.salaryDecreaseFix'"
                  :title="$t('grid.editor.salaryDecreaseFixExplanation')"></button>
                <label
                  v-else
                  class="adjust-label"
                  v-t="'grid.editor.salaryDecreaseFixOngoing'"></label>
              </div>
            </td>
          </tr>
        </template>
      </table>
    </div>
  </div>
</template>

<script>
import { getSetting, setSetting } from '@/utils/settings'
import debounce from 'lodash.debounce'
import anime from 'animejs/lib/anime.es.js'
import { mapGetters } from 'vuex'
import { max } from 'd3'
import { filterByQuery } from '@/utils/string'
import NameThumbnail from '@components/commons/NameThumbnail.vue'
import InlineWage from '@components/wage/InlineWage.vue'
import WageMiniInterval from '@components/wage/WageMiniInterval.vue'
import orderBy from 'lodash.orderby'

const CW_GRID_EMPLOYEES_PERCENT_MODE = 'CW_GRID_EMPLOYEES_PERCENT_MODE'
const CW_GRID_EMPLOYEES_SORT_KEY = 'CW_GRID_EMPLOYEES_SORT_KEY'
const CW_GRID_EMPLOYEES_SORT_ORDER = 'CW_GRID_EMPLOYEES_SORT_ORDER'

const ANIMATION_LIMIT = 100

export default {
  components: {
    InlineWage,
    NameThumbnail,
    WageMiniInterval
  },
  props: {
    searchQuery: String,
    simulationLevels: Array,
    simulationWages: Array,
    highlightedRef: {
      type: String,
      default: 'role'
    },
    showIntervals: Boolean
  },
  data() {
    return {
      sortKey: getSetting(CW_GRID_EMPLOYEES_SORT_KEY, 'employee'),
      sortOrder: getSetting(CW_GRID_EMPLOYEES_SORT_ORDER, 1),
      employees: null,
      isAdjusting: false,
      isPercentMode: getSetting(CW_GRID_EMPLOYEES_PERCENT_MODE, true),
      generateEmployeesDebounced: debounce(function() {
        this.generateEmployees()
      }, 600)
    }
  },
  computed: {
    ...mapGetters({
      enrichedEmployees: 'sandbox/enrichedEmployees',
      isOnboarding: 'onboarding/isActive',
      grid: 'sandbox/grid',
      wages: 'sandbox/employeeWages'
    }),
    isClickable() {
      return !!this.$listeners.select
    }
  },
  methods: {
    generateBackground(percent, isNegative, isAnimating) {
      const accentColor = isAnimating ? '#E8F1F8' : (isNegative ? '#FBEBEA' : '#ECF0F3')
      return `linear-gradient(\
        90deg,\
        ${accentColor} ${percent}%, \
        rgba(255,255,255,0.5) ${percent}%, \
        rgba(255,255,255,0.5) 100%)`
    },
    generateEmployees(options) {
      console.time('generateEmployees')
      const refKey = this.isPercentMode ? 'postGridSalaryRisePercent' : 'postGridSalaryRise'
      let updatedEmployees = filterByQuery(
        this.enrichedEmployees(this.simulationLevels, this.simulationWages, this.simulationLevels && this.simulationLevels.length),
        this.searchQuery,
        '_searchKey')
      const maxSalaryRaise = max(updatedEmployees, e => Math.abs(e[refKey]))
      updatedEmployees.forEach(employee => {
        const percent = maxSalaryRaise > 0
          ? Math.floor(Math.abs(employee[refKey]) / maxSalaryRaise * 100)
          : 0
        employee.percent = Math.min(98, Math.max(0, percent))
        employee.background = this.generateBackground(employee.percent, employee[refKey] < 0)
        employee.description = this.getValue(this.highlightedRef, employee.postGridWageDetails.selectedComponents)
      })
      if (!this.searchQuery) {
        const sortKey = this.sortKey === 'employee'
          ? ['description', 'fullName']
          : [this.sortKey === 'postGridSalaryRise' ? refKey : this.sortKey]
        const sortOrder = this.sortOrder > 0 ? 'asc' : 'desc'
        updatedEmployees = orderBy(updatedEmployees, sortKey, [sortOrder, sortOrder])
      }

      if (this.employees && this.employees.length === updatedEmployees.length && this.employees.length < ANIMATION_LIMIT && (!options || !options.immediate)) {
        this.employees.forEach((employee, i) => {
          const updatedEmployee = updatedEmployees[i]
          const isNegative = updatedEmployee.postGridSalaryRise < 0
          Object.assign(employee, updatedEmployee, {
            postGridSalary: employee.postGridSalary,
            postGridSalaryRise: employee.postGridSalaryRise,
            postGridSalaryRisePercent: employee.postGridSalaryRisePercent,
            percent: employee.percent,
            background: this.generateBackground(employee.percent, isNegative, true),
            isAnimating: updatedEmployee.postGridSalary !== employee.postGridSalary
          })
          anime({
            targets: employee,
            round: 0.1,
            delay: (employee.isAnimating && options && options.delay) || 0,
            duration: employee.isAnimating ? 400 : 0,
            easing: 'linear',
            postGridSalary: updatedEmployee.postGridSalary,
            postGridSalaryRise: updatedEmployee.postGridSalaryRise,
            postGridSalaryRisePercent: {
              value: updatedEmployee.postGridSalaryRisePercent,
              round: 10
            },
            percent: {
              value: updatedEmployee.percent,
              round: 1
            },
            update: () => {
              employee.background = this.generateBackground(employee.percent, isNegative, employee.isAnimating)
            },
            complete: () => {
              Object.assign(employee, {
                background: this.generateBackground(updatedEmployee.percent, isNegative),
                isAnimating: false,
                percent: updatedEmployee.percent,
                postGridSalary: updatedEmployee.postGridSalary,
                postGridSalaryRise: updatedEmployee.postGridSalaryRise,
                postGridSalaryRisePercent: updatedEmployee.postGridSalaryRisePercent
              })
            }
          })
        })
      }
      else {
        this.employees = updatedEmployees
      }

      this.$emit('count', updatedEmployees.length)
      this.$emit('employees', updatedEmployees)
      console.timeEnd('generateEmployees')
    },
    generateEmployeesWithDelay() {
      this.generateEmployeesDebounced()
    },
    select(employee) {
      this.$emit('select', employee)
    },
    getValue(ref, components) {
      if (components) {
        const roleComponent = components.find(c => c.ref === ref)
        if (roleComponent && roleComponent.selectedLevel) {
          return roleComponent.selectedLevel.name
        }
      }
    },
    adjustEmployeeWage(employeeId, overridenSalaryValue) {
      this.isAdjusting = employeeId
      this.$store.dispatch('sandbox/adjustSandboxWage', { employeeId, overridenSalaryValue }).then(() => {
        setTimeout(() => {
          this.isAdjusting = false
        }, 1000)
      })
    },
    togglePercentMode() {
      this.isPercentMode = !this.isPercentMode
      setSetting(CW_GRID_EMPLOYEES_PERCENT_MODE, this.isPercentMode)
      this.generateEmployees({ immediate: true })
    },
    arrowClassName(key) {
      return this.sortKey === key ? 'arrow-' + this.sortOrder : ''
    },
    sortBy(key) {
      if (this.sortKey !== key) {
        this.sortOrder = 1
      }
      else {
        this.sortOrder *= -1
      }
      this.sortKey = key
      setSetting(CW_GRID_EMPLOYEES_SORT_KEY, this.sortKey)
      setSetting(CW_GRID_EMPLOYEES_SORT_ORDER, this.sortOrder)
      this.generateEmployees({ immediate: true })
    }
  },
  watch: {
    searchQuery: 'generateEmployees',
    simulationLevels: 'generateEmployeesWithDelay',
    simulationWages: 'generateEmployeesWithDelay',
    grid: 'generateEmployeesWithDelay',
    wages: 'generateEmployeesWithDelay'
  },
  created() {
    this.generateEmployees()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/alert.scss";
@import "./src/styles/button.scss";
@import "./src/styles/link.scss";
@import "./src/styles/table.scss";

.employees {
  border: 1px $graph-outer-border-color solid;
  border-radius: $border-radius;
  box-shadow: 1px 1.5px 0 rgba(0, 0, 0, 0.03);
}

table {
  white-space: nowrap;
  @include font-small-size;

  td {
    line-height: 2.6em;
    padding: 0 0.5em;
  }

  th {
    @include font-medium;
    background: white;
    border: none;
    padding: 0 0.5em;
    line-height: 30px;
  }

  td,
  th {
    border: 1px solid $graph-outer-border-color;
  }
}

td:not(:first-of-type) {
  @include font-tabular-numbers;
  transition: background 500ms, color 500ms;
}

th:nth-of-type(2),
td:nth-of-type(2),
th:nth-of-type(3),
td:nth-of-type(3) {
  text-align: center;
  width: 85px;
}

th:nth-of-type(4),
td:nth-of-type(4) {
  cursor: pointer;
  text-align: right;
  width: 85px;

  &.percent {
    text-align: center;
  }
}

.row.animating {
  td:nth-of-type(3),
  td:nth-of-type(4) {
    background: lighten($graph-darkblue-color, 53);

    &:not(.error) {
      color: $graph-darkblue-color;
    }
  }
}

.employee-wage-label {
  float: right;
  background: darken($graph-blue-color, 15);
  color: white;
  text-transform: uppercase;
  @include font-extra-bold;
  @include font-tiny-size;
  line-height: 2.1em;
  padding: 0 1em;
  border-radius: $border-radius;
  margin-top: 0.55em;
  text-shadow: 0 1px 0 rgba($text-color, 0.25);
}

.error {
  @include font-semibold;
  color: $red-color;
}

.wip {
  color: darken($clearteal-color, 3);
}

.role {
  float: right;
  margin: 0 0.25em 0 0.5em;
  max-width: 40%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.employee-info {
  float: right;
}

.employee-salary {
  &.interval {
    vertical-align: initial;
    @include line-regular-height;
    padding-top: 1px;
  }
}

.row:not(:hover) .mini-interval-container {
  opacity: 0.7;
}

.edit-employee {
  @include font-smaller-size;
  padding: 0.25em 10px 0.75em 10px;
  line-height: 1em;
  font-style: italic;
  opacity: 0.7;
}

// Similar to Tooltip component without the icon
.employee-tooltip-container {
  position: relative;

  &:hover .employee-tooltip {
    display: block;
  }
}

.employee-tooltip {
  pointer-events: none;
  display: none;
  position: absolute;
  background: $graph-lightblue-color;
  border: 1px solid $graph-outer-border-color;
  border-radius: $border-radius;
  color: $text-color;
  margin-top: 5px;
  margin-left: 36px;
  box-shadow: 1px 2px 0 rgba(0, 0, 0, 0.03), 3px 4px 0 rgba(0, 0, 0, 0.015);
  z-index: 1000;

  &:after,
  &:before {
    top: -21px;
    left: 20px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 10px;
  }

  &:after {
    border-bottom-color: $graph-lightblue-color;
    margin-top: 1px;
  }
  &:before {
    border-bottom-color: $graph-outer-border-color;
  }
}
</style>
