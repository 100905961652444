<template>
  <div v-if="isSettingsCompanyAccessRightsPermissionsView">
    <h3>
      <router-link
        tag="a"
        class="colored-link"
        active-class="noop"
        :to="{name: 'settingsCompanyAccessRights'}"
        v-t="'settings.settingsCompanyAccessRights.title'">
      </router-link>
      <span class="step">›</span>
      <span v-t="'settings.settingsCompanyAccessRightsPermissions.title'"></span>
    </h3>
    <p v-t="'settings.settingsCompanyAccessRightsPermissions.intro'"></p>
    <ul class="permissions">
      <li class="permission permission-header">
        <div class="input-label no-margin-bottom" v-t="'settings.settingsCompanyAccessRightsPermissions.feature'"></div>
        <div class="input-label no-margin-bottom" v-t="'settings.settingsCompanyAccessRightsPermissions.requiredStatus'"></div>
      </li>
      <li
        class="permission"
        v-for="permission in sortedPermissions"
        :key="permission.id">
        <div class="permission-name">{{permission.name}}</div>
        <div class="permission-button">
          <PermissionButton
            :small="true"
            :permission="permission.id" />
        </div>
        </li>
    </ul>
    <p class="light-text" v-html="$t('settings.settingsCompanyAccessRightsPermissions.footer')"></p>
    <hr>
    <div class="input-label" v-t="'settings.settingsCompanyAccessRightsGroups.title'"></div>
    <p class="no-margin-top" v-html="$t('settings.settingsCompanyAccessRightsGroups.intro')"></p>
    <button class="small-button" @click="$router.push({name: 'settingsCompanyAccessRightsGroups'})" v-t="'common.update'"></button>
    <hr>
    <AuthDomainCheckbox />
    <hr>
    <OpenPolicyCheckbox />
  </div>
  <router-view v-else></router-view>
</template>

<script>
import { COMPANY_PERMISSIONS } from '@/utils/permissions'
import orderBy from 'lodash.orderby'
import AuthDomainCheckbox from '@/components/settings/AuthDomainCheckbox.vue'
import PermissionButton from '@/components/settings/PermissionButton.vue'
import OpenPolicyCheckbox from '@/components/settings/OpenPolicyCheckbox.vue'
import { slugify } from '@/utils/string'

export default {
  components: {
    PermissionButton,
    AuthDomainCheckbox,
    OpenPolicyCheckbox
  },
  computed: {
    sortedPermissions() {
      return orderBy(Object.keys(COMPANY_PERMISSIONS).map(permission => ({
        id: permission,
        name: this.$t(`settings.company.permissions.permissions.${permission}.title`)
      })), [({ name }) => slugify(name)])
    },
    isSettingsCompanyAccessRightsPermissionsView() {
      return this.$route.name === 'settingsCompanyAccessRightsPermissions'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/link.scss";
@import "./src/styles/settings.scss";

.step {
  display: inline-block;
  margin: 0 0.5em;
  color: $light-text-color;
}

.permissions {
  padding: 0;

  .permission {
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:not(:last-of-type) {
      border-bottom: $border;
    }
  }
}
</style>
