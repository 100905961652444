<template>
  <transition name="modal">
    <div class="mask">
      <div class="wrapper">
        <div class="container">
          <h1 v-t="'sandbox.nameForm.updateName'"></h1>
          <div class="grid-name">
            <input
              type="text"
              v-model="gridModel.name"
              @keyup.enter="submit()"
              ref="nameInput"
              :placeholder="$t('sandbox.name')"
              :class="{'input-error' : $v.gridModel.name.$error}">
          </div>
          <menu>
            <button class="secondary" @click="$emit('close')" :disabled="isLoading" v-t="'sandbox.nameForm.cancel'"></button>
            <loading-button
              class="primary"
              @click="submit()"
              :loading="isLoading">
              <span v-t="'sandbox.nameForm.update'"></span>
            </loading-button>
          </menu>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      gridModel: null,
      isLoading: false
    }
  },
  props: [
    'grid'
  ],
  methods: {
    submit() {
      this.$v.$touch()

      if (!this.$v.$error) {
        this.isLoading = true

        this.$store.dispatch('sandboxList/update', {
          id: this.gridModel.id,
          name: this.gridModel.name
        })
          .then(response => {
            this.$emit('close', response)
          })
          .catch(_ => {
            this.isLoading = false
          })
      }
    }
  },
  created() {
    this.gridModel = Object.assign({}, this.grid)
  },
  mounted() {
    this.$refs.nameInput.focus()
  },
  validations() {
    return { gridModel: { name: { required } } }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";

.mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: table;
  transition: opacity 0.3s ease;
}

.wrapper {
  display: table-cell;
  vertical-align: middle;
}

.container {
  @include container;
  border: none;
  width: 400px;
  margin: 0 auto;
  transition: all 0.3s ease;
}

h1 {
  @include font-bigger-size;
  margin: 0 0 10px 0;
}

.grid-name {
  margin-bottom: 20px;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
