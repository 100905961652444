<template>
  <div class="visitor-container password-reset-request" v-if="userDetails || errorMessage">
    <div class="center">
      <img
        class="visitor-logo"
        src="~@/assets/logo.svg"
        alt="Clearwage"
        @click="$router.push('/')">
    </div>
    <div class="visitor-form">
      <header class="visitor-form-header" v-html="headerLabel"></header>
      <div class="visitor-form-content">
        <form @submit.prevent="requestPasswordReset()" v-if="userDetails">
          <div class="input-label" v-t="'account.passwordReset.newPassword'"></div>
          <input
            type="password"
            v-model="password"
            :placeholder="$t('account.passwordPlaceholder')"
            :class="{'input-error' : $v.password.$error}">
          <div v-if="errorMessage">
            <div class="error-message">{{ errorMessage }}</div>
          </div>
          <button
            type="button"
            class="secondary"
            v-if="errorMessage"
            v-t="'account.passwordReset.tryAgain'"
            @click="$router.push({name: 'passwordRequest'})"></button>
          <button class="primary" :disabled="isLoading">{{ requestButtonText }}</button>
        </form>
        <div class="visitor-outro" v-if="userDetails">
          <span v-t="'account.passwordReset.thirdInstruction'"></span>
        </div>
        <div v-else-if="errorMessage">
          <div class="error-message no-margin-top">{{ errorMessage }}</div>
          <br>
          <button
            type="button"
            class="primary"
            v-t="'common.continue'"
            @click="$router.push({name: 'passwordRequest'})"></button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      password: '',
      isLoading: false,
      errorMessage: null,
      userDetails: null
    }
  },
  created() {
    this.getUserDetails()
  },
  methods: {
    getUserDetails() {
      const token = this.$route.params.token
      this.$store.dispatch('account/getPasswordResetRequest', { token })
        .then(userDetails => {
          this.userDetails = userDetails
        })
        .catch(error => {
          this.errorMessage = error
        })
    },
    requestPasswordReset() {
      this.$v.$touch()

      if (!this.$v.$error) {
        const token = this.$route.params.token
        const password = this.password

        this.errorMessage = null
        this.isLoading = true

        this.$store.dispatch('account/resetPassword', { token, password })
          .then(() => {
            this.$router.push('/')
          })
          .catch(error => {
            this.isLoading = false
            this.errorMessage = error
          })
      }
    }
  },
  computed: {
    requestButtonText() {
      return this.$t(this.isLoading ? 'account.passwordReset.requesting' : 'account.passwordReset.resetAction')
    },
    headerLabel() {
      if (this.errorMessage && !this.userDetails) {
        return this.$t('account.passwordReset.invalidLink')
      }
      else if (this.userDetails) {
        return this.$t('account.passwordReset.hello', {
          userName: this.userDetails.firstName
        })
      }
    }
  },
  validations: {
    password: { required }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/visitor.scss";
</style>
