<template>
  <div>
    <h3 class="title" v-t="'grid.editor.gridWagesEditor.title'"></h3>
    <div>
      <p v-t="'grid.editor.gridWagesEditor.intro'"></p>
      <div class="simulation-actions">
        <div>
          <div class="input-label big" v-t="'grid.editor.gridWagesEditor.adjusted.title'"></div>
          <p  v-t="'grid.editor.gridWagesEditor.adjusted.intro'"></p>
          <loading-button
            class="primary"
            @click="adjustWages()"
            :loading="isLoading">
            <span v-t="'grid.editor.gridWagesEditor.adjusted.action'"></span>
          </loading-button>
        </div>
        <div class="hr"></div>
        <div>
          <div class="input-label big" v-t="'grid.editor.gridWagesEditor.aligned.title'"></div>
          <p  v-t="'grid.editor.gridWagesEditor.aligned.intro'"></p>
          <loading-button
            class="primary"
            @click="alignWages()"
            :loading="isLoading">
            <span v-t="'grid.editor.gridWagesEditor.aligned.action'"></span>
          </loading-button>
        </div>
      </div>
    </div>
    <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>
  </div>
</template>

<script>
import cloneDeepWith from 'lodash.clonedeepwith'
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  name: 'gridWagesEditor',
  props: {
    payload: {
      type: Object
    }
  },
  data() {
    return {
      errorMessage: null,
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      currentGrid: 'currentGrid/getCurrentGrid',
      sandboxGrid: 'sandbox/grid',
      enrichedEmployees: 'sandbox/enrichedEmployees'
    })
  },
  methods: {
    close() {
      this.$emit('close')
    },
    // This logic has been cloned from WageToolView:
    async adjustWages() {
      this.isLoading = true
      const updatedWages = this.enrichedEmployees()
        .map(employee => {
          const updatedWage = cloneDeepWith(employee.sandboxWage)
          updatedWage.overridenSalaryValue = employee.currentSalary
          return updatedWage
        })
      await this.$store.dispatch('sandbox/updateSandboxWages', updatedWages)
      this.isLoading = false
      this.close()
    },
    async alignWages() {
      this.isLoading = true
      const updatedWages = this.enrichedEmployees()
        .filter(({ sandboxWage }) => sandboxWage.hasLevels)
        .map(employee => {
          const updatedWage = cloneDeepWith(employee.sandboxWage)
          updatedWage.overridenSalaryValue = null
          return updatedWage
        })
      await this.$store.dispatch('sandbox/updateSandboxWages', updatedWages)
      this.isLoading = false
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/button.scss";
@import "./src/styles/block.scss";
@import "./src/styles/form.scss";

h3.title {
  height: 32px;
  display: flex;
  align-items: center;
}

.simulation-actions {
  @extend .block;
  padding: 1em;
  margin-bottom: 1em;
  display: grid;
  width: 60%;
  grid-template-columns: 1fr auto 1fr auto;

  .hr {
    text-align: center;
    width: 30px;

    &::before {
      display: block;
      content: " ";
      width: 1px;
      height: 100%;
      background: $border-color;
      margin: auto;
    }
  }
}
</style>
