<template>
  <div class="components">
    <!-- Grid levels -->
    <span
      v-for="component in components"
      :key="component.id"
      class="component"
      :class="{highlighted: isHighlighted(component)}"
      :title="component.name + ' : ' + (component.selectedLevel && component.selectedLevel.name)">
      <label>{{ component.name }}</label>
      <span v-if="component.selectedLevel">{{ component.selectedLevel.name }}</span>
      <span v-else v-t="'grid.editor.toBeDefined'"></span>
    </span>
    <!-- Grid / Salary Placeholder -->
    <span v-if="!components.length && !salarySummary.fixedValue" class="component">
      <label v-t="'grid.editor.' + (!hasGrid ? 'fixedSalary' : 'placement')"></label>
      <span v-t="'grid.editor.toBeDefined'"></span>
    </span>
    <!-- Adjustment -->
    <span v-else-if="salarySummary.delta && $$hasPermission('employeesWageFormula') && (showSalaryBadges || showSalary)" class="component">
      <label v-t="'grid.editor.adjustment'"></label>
      {{ salarySummary.delta | formattedCurrency(false, {signDisplay: "always", selectedRecurrence}) }}
    </span>
    <!-- Salary -->
    <template v-if="salarySummary.fixedValue">
      <!-- Gray badges -->
      <template v-if="showSalaryBadges && salarySummary.fixedValue">
        <!-- Fixed salary -->
        <span class="component">
          <label v-t="'grid.editor.fixedSalary'"></label>
          <span>{{salarySummary.fixedValue | formattedCurrency(false, {selectedRecurrence}) }}</span>
        </span>
        <!-- Variable salary -->
        <span class="component" v-if="salarySummary.variableValue">
          <label v-t="'grid.editor.variableSalary'"></label>
          <span>{{salarySummary.variableValue | formattedCurrency(false, {selectedRecurrence}) }}</span>
        </span>
      </template>
      <!-- Green badge -->
      <template v-else-if="showSalary">
        <!-- Salary intro -->
        <span v-if="!components.length" class="component">
          <template v-if="$$hasGrid">
            <label v-t="'grid.editor.remuneration'"></label>
            <span v-t="'grid.editor.offGrid'"></span>
          </template>
          <template v-else>
            <label v-t="'grid.editor.remuneration'"></label>
            <span v-t="'grid.editor.fixedSalary'"></span>
          </template>
        </span>
        <!-- Fixed salary -->
        <span class="badge border-badge large-badge">
          {{ salarySummary.fixedValue  | formattedCurrency(false, {selectedRecurrence}) }}
        </span>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    wageDetails: Object,
    showSalary: {
      type: Boolean,
      default: true
    },
    showSalaryBadges: {
      type: Boolean,
      default: false
    },
    showRoleComponent: {
      type: Boolean,
      default: true
    },
    selectedRecurrence: {
      type: String,
      default: 'yearly'
    },
    shouldHighlightHiddenLevel: {
      type: Boolean
    }
  },
  computed: {
    components() {
      if (!this.wageDetails.selectedComponents.length) {
        return []
      }
      let components = this.wageDetails.components.length ? this.wageDetails.components : this.wageDetails.selectedComponents
      if (!this.showRoleComponent) {
        components = components.filter(c => c.ref !== 'role')
      }
      return components
    },
    salarySummary() {
      return this.wageDetails.summary.salary
    },
    hasGrid() {
      return this.$$hasGrid || this.$store.getters['sandbox/onboardingGrid']
    }
  },
  methods: {
    isHighlighted(component) {
      return !component.selectedLevel ||
      (this.shouldHighlightHiddenLevel && component.selectedLevel.isHidden) ||
        (!this.components.length && !this.salarySummary.fixedValue)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/badge.scss";

.components {
  display: inline-block;
  line-height: 1.5em;
  padding: 0.25em 0.75em;
}

.component {
  @include font-medium;
  display: inline-block;
  margin: 0.25em 0;

  &:not(:last-of-type) {
    margin-right: 1.75em;
  }

  label {
    @include font-small-size;
    @include font-regular;
    display: block;
    cursor: inherit;
  }
}

.components .component.highlighted {
  background: $lightteal-color;
  color: darken($clearteal-color, 7);
}

.badge {
  vertical-align: 9.5px;
}
</style>
