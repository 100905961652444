<template>
  <div class="variable-plan-employee-results">
    <VariablePlanEmployees
      :disableSharedIndicators="true"
      :enrichedConfig="enrichedConfig"
      :variablePlan="variablePlan"
      v-model="variablePlan.variableWages"
      @input="variableWages => $emit('input', variableWages[0])" />
  </div>
</template>

<script>
import VariablePlanEmployees from '@components/variable/VariablePlanEmployees.vue'
export default {
  components: {
    VariablePlanEmployees
  },
  props: {
    enrichedConfig: {
      type: Object
    },
    variablePlan: {
      type: Object
    }
  },
  computed: {
    variableWage() {
      return this.variablePlan.variableWages[0]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/alert.scss";
@import "./src/styles/badge.scss";

.variable-plan-employee-results {
  @extend .badge-block;
  display: block;
  padding: 5px;

  h3 {
    margin-bottom: 0.75rem;
  }

  .variable-plan-employees::v-deep {
    .employees-table-container {
      background: inherit;
      border: inherit;
      padding: inherit;
      margin-top: inherit;

      & > h3 {
        display: none;
      }

      .employees-table {
        margin-top: inherit;
      }
    }

    .resizable-container,
    .resizable-content {
      overflow: inherit; // Avoid cropped tooltip
    }

    .filtered-employees {
      display: none; // Avoid irrelevant filtered employees message
    }
  }

  .updated-at {
    @include line-regular-height;
    color: $light-text-color;
    text-align: right;
    padding: 5px 0 0;
  }
}
</style>
