<template>
  <form @submit.prevent="submit" class="custom-field reveal">
    <div class="custom-field-name">
      <div>
        <div class="input-label" v-t="'settings.settingsCompanyCustomFields.form.name'"></div>
        <input
          ref="name"
          type="text"
          v-model="model.name"
          :placeholder="$t('settings.settingsCompanyCustomFields.form.namePlaceholder')"
          :class="{'input-error' : $v.model.name.$error}">
      </div>
      <div class="custom-field-scopes">
        <div class="input-label" v-t="'settings.settingsCompanyCustomFields.form.kind'"></div>
        <multiselect
          v-model="model.kind"
          :options="availableKinds"
          :searchable="false"
          :allowEmpty="false"
          :showLabels="false"
          :customLabel="formatKind"
          @input="resetScope"
          :class="{'input-error' : $v.model.kind.$error}" />
      </div>
    </div>
    <div class="custom-field-description">
      <div>
        <div class="input-label" v-t="'settings.settingsCompanyCustomFields.form.description'"></div>
        <input
          type="text"
          v-model="model.description"
          :autofocus="true"
          :placeholder="$t('settings.settingsCompanyCustomFields.form.descriptionPlaceholder')">
      </div>
    </div>
    <div class="custom-field-scopes">
      <div class="input-label" v-t="'settings.settingsCompanyCustomFields.form.scope'"></div>
      <div
        v-for="scope in availableScopes"
        :key="scope"
        class="custom-field-scope">
        <checkbox
          :value="scope"
          v-model="model.scopes"
          :disabled="disabledScopes.includes(scope)">
          <div>
            <strong>{{formatScope(scope)}}</strong><br>
            {{formatScopeDescription(scope)}}
          </div>
        </checkbox>
      </div>
    </div>
    <div class="error-message" v-if="errorMessage">
      {{errorMessage}}
    </div>
    <menu>
      <button
        v-if="field"
        type="button"
        class="destructive"
        v-t="'common.remove'"
        @click="remove">
      </button>
      <button
        type="button"
        class="secondary"
        @click="close"
        v-t="'common.cancel'">
      </button>
      <loading-button
        type="submit"
        class="primary"
        :disabled="isLoading"
        :loading="isLoading">
        <span v-t="field ? 'common.update' : 'common.add'"></span>
      </loading-button>
    </menu>
  </form>
</template>

<script>
import Checkbox from '@components/commons/Checkbox'
import cloneDeepWith from 'lodash.clonedeepwith'
import { buildCustomField, CUSTOM_FIELD_KINDS, CUSTOM_FIELD_SCOPES } from '@/utils/employee'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
  components: {
    Checkbox
  },
  props: {
    field: {
      type: Object,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      model: {},
      errorMessage: null,
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      customFields: 'company/getCustomFields'
    }),
    availableKinds() {
      return CUSTOM_FIELD_KINDS
    },
    availableScopes() {
      return CUSTOM_FIELD_SCOPES
    },
    disabledScopes() {
      return this.model.kind === 'string' ? ['variable'] : ['dashboard']
    }
  },
  methods: {
    initModel() {
      this.model = this.field ? cloneDeepWith(this.field) : buildCustomField()
    },
    formatKind(kind) {
      return this.$t(`settings.settingsCompanyCustomFields.kinds.${kind}.name`)
    },
    formatScope(scope) {
      return this.$t(`settings.settingsCompanyCustomFields.scopes.${scope}.name`)
    },
    formatScopeDescription(scope) {
      return this.$t(`settings.settingsCompanyCustomFields.scopes.${scope}.description`)
    },
    resetScope() {
      this.model.scopes = []
    },
    close() {
      this.$emit('close')
    },
    async remove() {
      this.errorMessage = null
      if (confirm(this.$t('settings.settingsCompanyCustomFields.form.deleteConfirmation'))) {
        try {
          await this.$store.dispatch('company/removeCustomField', this.model)
        }
        catch (error) {
          this.errorMessage = error
        }
        this.close()
      }
    },
    submit() {
      this.errorMessage = null
      this.$v.$touch()

      if (!this.$v.$error) {
        const isSimilar = this.customFields.filter(({ id }) => id !== this.model.id).map(({ name }) => name).includes(this.model.name)
        if (isSimilar) {
          this.errorMessage = this.$t('settings.settingsCompanyCustomFields.form.errors.unique')
          return
        }

        this.isLoading = true
        if (this.field) {
          this.$store.dispatch('company/updateCustomField', this.model)
        }
        else {
          this.$store.dispatch('company/addCustomField', this.model)
        }
        this.isLoading = false
        this.close()
      }
    }
  },
  validations: {
    model: {
      name: { required },
      kind: { required }
    }
  },
  created() {
    this.initModel()
  },
  mounted() {
    this.$refs.name.focus()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/button.scss";
@import "./src/styles/badge.scss";
@import "./src/styles/form.scss";

.custom-field {
  @extend .badge-block;
  display: block;
  padding: 0.6em 0.7em 0.25em;
  margin-bottom: 1em;

  input[type="text"] {
    border-radius: 5px;
  }

  .custom-field-name {
    display: grid;
    grid-template-columns: 45% 25%;
    gap: 0.7em;
    margin-bottom: 1em;
  }

  .custom-field-description {
    display: grid;
    grid-template-columns: 72%;
    margin-bottom: 1em;
  }

  .custom-field-scopes {
    .custom-field-scope {
      margin: 0.5em 0;
    }
  }

  .checkbox::v-deep {
    input[type="checkbox"][disabled] + label {
      color: $light-text-color;
    }
  }

  menu {
    margin-top: 1.25em;
  }
}
</style>
