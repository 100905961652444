<template>
  <div class="employee-summary">
    <div class="camembert">
      <div class="svg-container">
        <svg :width="viewport.width" :height="viewport.height" ref="svgNode" />
      </div>
    </div>
    <div class="detail">
      <Dropdown
        class="blue recurrence-dropdown"
        :items="recurrenceDropdownItems"
        @change="selectRecurrence">
        <span v-t="`employees.employee.summary.recurrence.${selectedRecurrence}.title`"></span>
        <span class="down">▾</span>
      </Dropdown>
      <h2 v-t="'employees.employee.summary.globalRemuneration'"></h2>
      <strong class="zoom-in" v-if="remunerationSummary">{{ remunerationSummary.total | formattedCurrency(false, {selectedRecurrence}) }}</strong>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
import Dropdown from '@components/commons/Dropdown'

function initGraph(svg, viewport) {
  const node = {}

  node.svg = d3.select(svg)
  node.viewport = node.svg.append('g')
    .attr('class', 'viewport')
  node.arcs = node.viewport.append('g')
    .attr('class', 'arcs')

  return {
    node: node,
    viewport: viewport,
    data: []
  }
}

function generatePieModel(graph, remunerationSummary) {
  graph.data = []

  graph.data.push({ id: 'salary', value: remunerationSummary.salary || 1 })

  if (remunerationSummary.variable) {
    graph.data.push({ id: 'variable', value: remunerationSummary.variable })
  }
  if (remunerationSummary.benefits) {
    graph.data.push({ id: 'benefits', value: remunerationSummary.benefits })
  }

  return graph
}

function renderGraph(graph) {
  const viewport = graph.viewport
  const node = graph.node

  // Viewport
  viewport.innerWidth = viewport.width - viewport.padding.left - viewport.padding.right
  viewport.innerHeight = viewport.height - viewport.padding.top - viewport.padding.bottom
  node.svg.attr('viewBox', [-viewport.width / 2, -viewport.height / 2, viewport.width, viewport.height])

  node.viewport.attr('transform',
    `translate(${viewport.padding.left},${viewport.padding.top})`)

  node.pie = d3.pie().padAngle(1 / 20).sort(null).value(d => d.value)
  node.arc = d3.arc()
    .innerRadius(viewport.innerWidth * 0.5 * 0.81)
    .outerRadius(viewport.innerWidth * 0.5)

  const angleInterpolation = d3.interpolate(node.pie.startAngle()(), node.pie.endAngle()())

  // Arcs
  const arcs = node.arcs.selectAll('.arc')
    .data(node.pie(graph.data), d => d.data.id)
  arcs
    .enter().append('path')
    .attr('class', d => `arc ${d.data.id}`)
    .merge(arcs)
    .transition()
    .duration(600)
    .attrTween('d', d => {
      const originalEnd = d.endAngle
      return t => {
        const currentAngle = angleInterpolation(t)
        if (currentAngle < d.startAngle) {
          return ''
        }

        d.endAngle = Math.min(currentAngle, originalEnd)

        return node.arc(d)
      }
    })
  arcs.exit()
    .remove()
}

export default {
  components: {
    Dropdown
  },
  name: 'EmployeeSummary',
  props: {
    remunerationSummary: {
      type: Object,
      default() {
        return null
      }
    },
    selectedRecurrence: {
      type: String,
      default: 'yearly'
    }
  },
  data() {
    return {
      // Options
      viewport: {
        width: 90,
        height: 90,
        innerWidth: 0, // Computed
        innerHeight: 0, // Computed
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }
      }
    }
  },
  mounted() {
    this.init()
    this.render()
  },
  computed: {
    recurrenceDropdownItems() {
      return [{
        name: this.$t('employees.employee.summary.recurrence.title'),
        items: ['yearly', 'monthly'].map(recurrence => ({
          name: this.$t(`employees.employee.summary.recurrence.${recurrence}.title`),
          value: recurrence,
          selected: this.selectedRecurrence === recurrence
        }))
      }]
    }
  },
  watch: {
    remunerationSummary: 'render'
  },
  methods: {
    init() {
      this.graph = initGraph(this.$refs.svgNode, this.viewport)
    },
    render() {
      if (this.remunerationSummary && this.remunerationSummary.isLoaded) {
        generatePieModel(this.graph, this.remunerationSummary)
        renderGraph(this.graph, this)
      }
    },
    selectRecurrence(_, value) {
      this.$emit('selectRecurrence', value)
    }
  }

}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/button.scss";

$salary-color: #b5cedf;
$variable-color: #dbb2dc;
$benefits-color: #94d3ce;

.employee-summary {
  position: relative;
  background-color: $graph-lightblue-color;
  border: 1px solid $graph-blue-color;
  border-bottom: none;
  border-radius: $border-radius $border-radius 0 0;
  display: grid;
  grid-template-columns: 125px 1fr;
  border: $border;
  border-bottom: none;
  padding: 10px 20px;
  margin-top: 20px;
  gap: 20px;

  .camembert {
    text-align: center;
  }

  .detail {
    padding-top: 8px;

    h2 {
      @include font-alternate-digits;
      line-height: 1.3em;
      margin: 0;
      color: darken($salary-color, 30);
    }

    strong {
      @include font-bold;
      @include font-normal-size;
      @include font-alternate-digits;
      margin: 0;
      color: darken($salary-color, 30);
      position: absolute;
      left: 5px;
      top: 5px;
      left: 46px;
      top: 45px;
      width: 70px;
      text-align: center;
    }
  }
}

.svg-container {
  font-size: 0;

  svg::v-deep {
    .arc.salary {
      fill: $salary-color;
    }

    .arc.variable {
      fill: $variable-color;
    }

    .arc.benefits {
      fill: $benefits-color;
    }
  }
}

.recurrence-dropdown {
  position: relative;
  float: right;
  margin-top: 2px;

  // Dropdown skin: right aligned
  &::v-deep .dropdown-content {
    right: 0;
  }
}
</style>
