<template>
  <div class="radio-inputs">
    <label
      v-for="item in items"
      :for="[item, _uid].join()"
      :key="item"
      class="radio-input"
      :class="[model == item && 'active', item]">
      <input
        type="radio"
        :id="[item, _uid].join()"
        :value="item"
        v-model="model"
        @change="onChange">
      <checkbox :checked="model == item" />
      <div>
        <strong class="radio-input-title" v-t="`${localePrefix}.${item}.title`"></strong>
        <div v-if="description && descriptionGetter" class="radio-input-description" >{{ descriptionGetter(item) }}</div>
        <div v-else-if="description" class="radio-input-description" v-t="`${localePrefix}.${item}.description`"></div>
      </div>
    </label>
  </div>
</template>

<script>
import Checkbox from '@components/commons/Checkbox'

export default {
  components: {
    Checkbox
  },
  props: {
    localePrefix: String,
    value: String,
    items: Array,
    description: {
      type: Boolean,
      default: true
    },
    descriptionGetter: Function
  },
  data() {
    return {
      model: null
    }
  },
  methods: {
    initModel() {
      this.model = this.value
    },
    onChange() {
      this.$emit('input', this.model)
    }
  },
  watch: {
    value: 'initModel'
  },
  created() {
    this.initModel()
  }
}
</script>

<style lang="scss" scoped>
.radio-input {
  display: flex;
  align-items: center;
  margin: 5px 0 7px 0;
  padding: 7px 13px;
  border-radius: 7px;
  border: $border;
  cursor: pointer;
  position: relative;
  background: white;

  &:last-of-type {
    margin-bottom: 1em;
  }

  &.active,
  &:hover {
    background: $graph-lightblue-color;
  }

  input[type="radio"] {
    display: none;
  }

  .checkbox {
    pointer-events: none;
    margin: 0 4px 0 0;
  }

  .radio-input-description {
    @include font-small-size;
    @include line-regular-height;
    padding-top: 2px;
  }
}

// Dark skin
.radio-inputs.dark .radio-input {
  &.active,
  &:hover {
    background: darken($graph-lightblue-color, 5);
  }
}

// Large inline skin
.radio-inputs.large-inline {
  .radio-input {
    display: inline-flex;
    margin: 0 10px 0 0;

  }
}

// Inline skin (based on Scope Selector)
.radio-inputs.inline {
  display: block;
  padding: 3px;
  border: 1px solid $graph-outer-border-color;
  border-radius: $border-radius;
  background: white;

  .radio-input {
    @include font-small-size;
    line-height: 1.75em;
    border-radius: 2px;
    display: inline-block;
    margin: 0;
    padding: 0 0.4em;
    border: none;

    strong {
      @include font-regular;
      letter-spacing: -0.5px;
    }

    &:hover {
      background: $graph-lightblue-color;
    }

    &.active {
      background: $graph-blue-color;
    }
  }

  span.checkbox {
    display: none;
  }
}
</style>
