<template>
  <div class="team" :class="{manual: !team.isGenerated}">
    <h3 class="team-name">
      <InlineEditor
        class="blue"
        v-model="teamModel.name"
        :placeholder="$t('settings.settingsCompanyTeams.teamNamePlaceholder')"
        :disabled="disabled"
        @submit="updateTeam()" />
      <button v-if="!disabled" class="small-button red" @click="$emit('remove', team)" v-t="'common.remove'"></button>
    </h3>
    <p v-if="team.isGenerated" class="no-margin-top light-text" v-t="`settings.settingsCompanyTeams.team${team.isGenerated ? 'Generated' : 'Manual'}`"></p>
    <TeamEmployeePicker v-if="!disabled" :disabled="disabled" :team="team" :editAdmins="true" />
    <TeamEmployeePicker :team="team" :disabled="disabled" />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import InlineEditor from '@components/commons/InlineEditor.vue'
import TeamEmployeePicker from '@components/settings/teams/TeamEmployeePicker.vue'

export default {
  components: {
    InlineEditor,
    TeamEmployeePicker
  },
  props: {
    team: Object,
    disabled: {
      type: Boolean
    }
  },
  data() {
    return {
      teamModel: null
    }
  },
  methods: {
    initModel() {
      this.teamModel = { ...this.team }
    },
    updateTeam() {
      this.$v.$touch()
      if (this.$v.$error) {
        this.initModel()
        return
      }
      this.$store.dispatch('teams/updateTeam', this.teamModel)
    }
  },
  created() {
    this.initModel()
  },
  validations() {
    return {
      teamModel: {
        name: { required }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/badge.scss";
@import "./src/styles/button.scss";

.team {
  @extend .badge-block;
  display: block;
  padding: 0.6em 0.7em;
  margin-top: 20px;

  &.manual p {
    color: lighten($graph-purple-color, 20);
  }

  .team-name {
    height: 34px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .inline-editor {
      margin-top: -1px;
      max-width: 455px;
    }
  }
}

.employee-picker-container {
  margin-top: 0.5em;
  padding: 0.6em 0.7em;
  background: white;
  border-radius: $border-radius;
  border: 1px solid $graph-inner-border-color;
}
</style>
