<template>
  <div id="formula">
    <h2 v-t="'grid.formula'"></h2>
    <div v-for="remunerationType in remunerationTypes" :key="remunerationType">
      <h3>{{ remunerationType | formattedRemunerationType }}</h3>
      <select
        v-if="remunerationType != 'salary'"
        :name="remunerationType"
        :value="gridOperations[remunerationType]"
        @change="updateOperation">
        <option value="addition">{{$i18n.currencySymbol}}</option>
        <option value="percentage">%</option>
      </select>
      <hr>
      <grid-formula-detail
        v-if="hasRemuneration(remunerationType)"
        style="margin-right: 20px"
        :formula="gridFormula(remunerationType)"
        @select="goToComponent($event)">
      </grid-formula-detail>
      <component-picker
        :remunerationType="remunerationType"
        @createComponent="createComponent"
        @remunerationAdded="goToComponent">
      </component-picker>
    </div>
    <component-form
      v-if="componentFormDisplayed"
      :gridId="grid.id"
      :remunerationType="remunerationType"
      @close="componentFormClosed">
    </component-form>
  </div>
</template>

<script>
import GridFormulaDetail from '@components/grid/legacy/GridFormulaDetail.vue'
import ComponentPicker from '@components/grid/legacy/ComponentPicker.vue'
import ComponentForm from '@components/grid/legacy/ComponentForm.vue'
import { gridHasRemunerationType, gridFormulaForRemunerationType, REMUNERATION_TYPES } from '@/utils/grid'
import { slugify } from '@/utils/string'

export default {
  data() {
    return {
      componentFormDisplayed: false,
      remunerationType: null
    }
  },
  computed: {
    grid() {
      return this.$store.getters['sandbox/grid']
    },
    gridOperations() {
      return this.$store.getters['sandbox/gridOperations']
    },
    remunerationTypes() {
      return REMUNERATION_TYPES
    }
  },
  methods: {
    hasRemuneration(remunerationType) {
      return gridHasRemunerationType(this.grid.components, remunerationType)
    },
    gridFormula(remunerationType) {
      return gridFormulaForRemunerationType(this.grid, remunerationType)
    },
    createComponent(remunerationType) {
      this.remunerationType = remunerationType
      this.componentFormDisplayed = true
    },
    goToComponent(component) {
      window.location = '#' + slugify(component.name)
    },
    componentFormClosed(component) {
      this.componentFormDisplayed = false
      this.selectedComponent = null
      this.remunerationType = null

      if (component) {
        this.goToComponent(component)
      }
    },
    updateOperation(event) {
      this.$store.dispatch('sandbox/updateGridOperation', {
        remunerationType: event.target.name,
        operation: event.target.value
      })
    }
  },
  components: {
    GridFormulaDetail,
    ComponentPicker,
    ComponentForm
  }
}
</script>

<style lang="scss" scoped>
#formula {
  padding: 20px;
  border: $border;
  border-radius: $border-radius;
  margin-bottom: 20px;
}

h2 {
  margin: 0;
  color: $clearteal-color;
  @include font-semibold;
}

h3 {
  display: inline-block;
  margin: 20px 0 0 0;
}

hr {
  display: block;
  border: 0;
  border-top: $border;
  height: 1px;
  margin: 10px 0 0 0;
}

select {
  min-width: 45px;
  color: $text-color;
  @include font-small-size;
  cursor: pointer;
  background: white;
  border: $border;
  margin-left: 5px;
  @include font-semibold;
  padding: 2px 10px;

  &:hover {
    border-color: $clearteal-color;
  }

  &:focus {
    outline: none;
  }

  &::-ms-expand {
    display: none;
  }
}
</style>
