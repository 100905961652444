<template>
  <EmployeesTableContainer
    :employees="employeesAndConcernedAlumnis"
    :fieldOnly="fieldOnly"
    :progress="progress"
    :title="$t('employees.picker.employees')">
    <template v-slot:company>
      <div class="button-group toggle-group">
        <button
          class="secondary"
          @click="toggleEmployees(employeesAndConcernedAlumnis)"
          v-t="'employees.picker.' + (isEmployeesSelected(employeesAndConcernedAlumnis) ? 'removeAll' : 'addAll')"></button>
      </div>
    </template>
    <template v-slot:group="slotProps">
      <div class="button-group toggle-group">
        <button
          class="secondary"
          @click="toggleEmployees(slotProps.employees)"
          v-t="'employees.picker.' + (isEmployeesSelected(slotProps.employees) ? 'removeAll' : 'addAll')"></button>
      </div>
    </template>
    <template v-slot:action="slotProps">
      <button
        class="toggle-employee"
        :class="isEmployeeSelected(slotProps.employee) ? 'primary' : 'secondary'"
        v-t="'common.yes'"
        @click="updateEmployee(slotProps.employee, true)" />
      <button
        class="toggle-employee"
        :class="!isEmployeeSelected(slotProps.employee) ? 'primary' : 'secondary'"
        v-t="'common.no'"
        @click="updateEmployee(slotProps.employee, false)" />
    </template>
  </EmployeesTableContainer>
</template>

<script>
import uniq from 'lodash.uniq'
import debounce from 'lodash.debounce'
import { mapGetters } from 'vuex'
import EmployeesTableContainer from '@components/employees/EmployeesTableContainer'

export default {
  components: {
    EmployeesTableContainer
  },
  props: {
    fieldOnly: {
      type: Boolean,
      default: true
    },
    value: {
      type: Array
    }
  },
  data() {
    return {
      selectedEmployeeIds: {}
    }
  },
  computed: {
    ...mapGetters({
      employees: 'employees/getUpdateEmployees',
      getEmployeesByIds: 'employees/getEmployeesByIds'
    }),
    employeesAndConcernedAlumnis() {
      const ids = uniq([...this.employees.map(({ id }) => id), ...this.value.map(({ id }) => id)])
      return this.getEmployeesByIds(ids)
    },
    progress() {
      const count = Object.keys(this.selectedEmployeeIds).length
      const total = this.employeesAndConcernedAlumnis.length
      return {
        count: count,
        total: total,
        percent: Math.round(count / total * 100)
      }
    }
  },
  watch: {
    value: 'initModel'
  },
  methods: {
    initModel() {
      this.selectedEmployeeIds = this.value.reduce((memo, { id }) => {
        memo[id] = true
        return memo
      }, {})
    },
    isEmployeeSelected(employee) {
      return this.selectedEmployeeIds[employee.id]
    },
    isEmployeesSelected(employees) {
      return !employees.find(e => !this.isEmployeeSelected(e))
    },
    toggleEmployees(employees) {
      const isSelected = this.isEmployeesSelected(employees)
      employees.forEach(employee => {
        this.updateEmployee(employee, !isSelected)
      })
    },
    updateEmployee(employee, isSelected) {
      if (isSelected) {
        this.$set(this.selectedEmployeeIds, employee.id, true)
      }
      else {
        this.$delete(this.selectedEmployeeIds, employee.id)
      }
      this.emitDebouncedSelections()
    },
    emitSelections() {
      const originalIds = this.value.map(e => e.id)
      const selections = this.employeesAndConcernedAlumnis.reduce((memo, { id }) => {
        const wasSelected = originalIds.includes(id)
        const isSelected = !!this.selectedEmployeeIds[id]
        if (wasSelected !== isSelected) {
          memo.push({ id, isSelected })
        }
        return memo
      }, [])
      if (selections.length) {
        this.$emit('select', selections)
      }
    },
    emitDebouncedSelections: debounce(function(event) {
      this.emitSelections()
    }, 700)
  },
  created() {
    this.initModel()
  },
  beforeDestroy() {
    this.emitSelections()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";

.toggle-employee {
  min-width: 50px;
  margin: 0 3px;
}

.toggle-group button {
  padding: 0.5em 0.9em;
}
</style>
