import api from '@/store/api'
import i18n from '@/i18n'
import { normalizedUsers } from '@/store/schema'
import { cloneDeepWith } from 'lodash'

const initialState = () => {
  return {
    userList: [],
    users: {},
    usersLoaded: false
  }
}

const state = initialState()

const getters = {
  usersLoaded(state) {
    return state.usersLoaded
  },
  users(state) {
    return state.userList.map(id => state.users[id])
  },
  usersWithAccount(_state, { users }) {
    return users.filter(u => u.hasAccount)
  },
  pendingInvitations(_state, { users }) {
    return users.filter(u => u.invitationToken != null && u.hasAccount === false)
  },
  nonInvitedUsers(_state, { users }) {
    return users.filter(u => u.invitationToken == null && u.hasAccount === false)
  },
  getPermission(_, __, ___, rootGetters) {
    return (permission) => {
      const company = rootGetters['account/getCompany']
      return company &&
        company.permissions &&
        company.permissions[permission]
    }
  },
  getCustomFields(_, __, ___, rootGetters) {
    const company = rootGetters['account/getCompany']
    return (company &&
        company.customFields) || []
  }
}

const actions = {
  reset(context) {
    context.commit('reset')
  },
  update(context, companyParams) {
    return api.put('/company', companyParams)
      .then(response => {
        context.commit('account/setCompany', response.data, { root: true })
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  updateBambooHRSettings(context, params) {
    return api.put('/company/bamboo_hr', params)
      .then(({ data }) => {
        context.dispatch('synchronization/reset', null, { root: true })
        context.commit('account/setCompany', data, { root: true })
        return data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  updateLuccaSettings(context, params) {
    return api.put('/company/lucca', params)
      .then(({ data }) => {
        context.dispatch('synchronization/reset', null, { root: true })
        context.commit('account/setCompany', data, { root: true })
        return data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  getLuccaProperties(context) {
    return api.get('/company/lucca/properties')
      .then(({ data }) => {
        return data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  updateOktaSettings(context, params) {
    return api.put('/company/okta', params)
      .then(({ data }) => {
        context.commit('account/setCompany', data, { root: true })
        return data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  updatePermission({ commit, dispatch, rootGetters }, { permission, value }) {
    const company = rootGetters['account/getCompany']
    const companyPermissions = (company && company.permissions) || {}
    return api.put('/company/permissions', { ...companyPermissions, [permission]: value })
      .then(({ data }) => {
        commit('account/setCompany', data, { root: true })
        return data
      })
      .catch(error => dispatch('handleAPIError', error, { root: true }))
  },
  updateCustomFields({ commit, dispatch }, customFields) {
    commit('account/setCustomFields', cloneDeepWith(customFields), { root: true })
    return api.put('/company/custom_fields', { customFields })
      .then(({ data }) => commit('account/setCompany', data, { root: true }))
      .catch(error => dispatch('handleAPIError', error, { root: true }))
  },
  updateCustomField({ dispatch, getters }, customField) {
    const customFields = getters.getCustomFields
      .map(field => field.id === customField.id ? customField : field)
    return dispatch('updateCustomFields', customFields)
  },
  removeCustomField({ dispatch, getters }, customField) {
    const customFields = getters.getCustomFields.filter(({ id }) => id !== customField.id)
    return dispatch('updateCustomFields', customFields)
  },
  addCustomField({ dispatch, getters }, customField) {
    const customFields = [...getters.getCustomFields, customField]
    return dispatch('updateCustomFields', customFields)
  },
  getUsers(context) {
    return api.get('/company/users')
      .then(response => {
        context.commit('setUsers', response.data)
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  inviteUser(context, user) {
    return api.post('/account/invitation', {
      employeeId: user.id
    })
      .then(response => {
        context.commit('setUser', response.data)
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  inviteAll(context) {
    return api.post('/account/invitation/all')
      .then(response => {
        context.commit('setUsers', response.data)
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  setUserStatus(context, { user, status }) {
    if (context.rootGetters['account/getUser'].id === user.id) {
      return Promise.reject(i18n.t('settings.settingsCompanyAccessRights.oneselfStatusError'))
    }
    else {
      return api.put(`/account/${user.id}/status`, { status })
        .then(response => {
          context.dispatch('employees/needsRefresh', null, { root: true })
          context.commit('setUser', response.data)
        })
        .catch(error => context.dispatch('handleAPIError', error, { root: true }))
    }
  },
  revokeAccount(context, user) {
    return api.delete(`/account/${user.id}`)
      .then(response => {
        context.commit('setUser', response.data)
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  async createStripePortalSession({ dispatch }, params) {
    try {
      const { data } = await api.post('/company/stripe_portal_session', params)
      return data.url
    }
    catch (error) {
      throw await dispatch('handleAPIError', error, { root: true })
    }
  },
  async createStripeSubscription({ dispatch }, params) {
    try {
      const { data } = await api.post('/company/stripe_subscription', params)
      return data.url
    }
    catch (error) {
      throw await dispatch('handleAPIError', error, { root: true })
    }
  },
  async getStripeSubscription({ dispatch }) {
    try {
      const { data } = await api.get('/company/stripe_subscription')
      return data
    }
    catch (error) {
      throw await dispatch('handleAPIError', error, { root: true })
    }
  },
  async getStripeOffer({ dispatch }) {
    try {
      const { data } = await api.get('/company/stripe_offer')
      return data
    }
    catch (error) {
      throw await dispatch('handleAPIError', error, { root: true })
    }
  }
}

const mutations = {
  reset(state) {
    Object.assign(state, initialState())
  },
  setUsers(state, users) {
    const n = normalizedUsers(users)
    state.userList = n.result
    state.users = n.entities.users
    state.usersLoaded = new Date()
  },
  setUser(state, user) {
    // Preserve thirdPartyUsers since they're not serialized in every routes
    const thirdPartyUsers = state.users[user.id].thirdPartyUsers
    state.users[user.id] = user
    state.users[user.id].thirdPartyUsers = thirdPartyUsers
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
