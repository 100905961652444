<template>
  <div>
    <TeamsTopBar />
    <router-view />
  </div>
</template>

<script>
import TeamsTopBar from '@components/teams/TeamsTopBar.vue'

export default {
  components: {
    TeamsTopBar
  },
  mounted() {
    this.$store.dispatch('orgChart/getEmployees')
  }
}
</script>
