<template>
  <div class="data-feed-container form-section" v-if="enrichedDataFeeds.length">
    <h3 v-t="'variable.dataFeed.title'"></h3>
    <VariablePlanDataFeed
      v-for="enrichedDataFeed in enrichedDataFeeds"
      :key="enrichedDataFeed.id"
      :enrichedDataFeed="enrichedDataFeed"
      :variablePlan="variablePlan"
      @input="onDataFeedChange" />
  </div>
</template>

<script>
import VariablePlanDataFeed from '@components/variable/VariablePlanDataFeed'

export default {
  components: { VariablePlanDataFeed },
  props: {
    variablePlan: Object,
    enrichedDataFeeds: Array
  },
  methods: {
    onDataFeedChange() {
      this.$emit('input')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/variable.scss";
</style>
