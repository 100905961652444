import { buildNormalizedDate, normalizeDate } from '@/utils/date'
import * as d3 from 'd3'
import flatten from 'lodash.flatten'
import { getField } from './employee'
import { getScopeValue, isFiltered } from './statistics'

export function generateEvolutionModel(params, valueFn) {
  console.time('generateEvolutionModel')
  const xAxis = generateXAxis(params.offset)
  const domains = { x: xAxis.domain() }
  const employeesTimelines = generateEmployeesTimelines(params, domains, valueFn)
  const zAxis = generateZAxis(employeesTimelines)
  domains.z = zAxis.domain()
  const timeline = aggregateTimelines(domains, employeesTimelines)
  console.timeEnd('generateEvolutionModel')
  return { domains, timeline }
}

const TICKS = 5 * 12 // months

export function generateXAxis(offset) {
  const year = (new Date()).getFullYear()
  const xAxis = d3.scaleTime()
    .domain([new Date(year - 2 + offset, 0), new Date(year + 3 + offset, 5)])
    .rangeRound([0, TICKS - 1])
  const ticks = xAxis.ticks(TICKS).map(t => normalizeDate(t))
  return d3.scaleOrdinal()
    .domain(ticks)
    .range(Array.from(Array(ticks.length), (_, i) => i))
}

export function generateZAxis(timelines) {
  const series = [...new Set(flatten(Object.values(timelines)).map(([value]) => value))]
  return d3.scaleOrdinal()
    .domain(series)
    .range(Array.from(Array(series.length), (_, i) => i))
}

export function aggregateTimelines(domains, timelines) {
  return domains.x.map((x, i) => {
    const data = { x, y: {} }
    return domains.z.reduce((memo, z) => {
      memo.y[z] = Object.entries(timelines).reduce((memo2, [employeeId, timeline]) => {
        if (timeline[i] && timeline[i][0] === z && timeline[i][1]) {
          memo2.value += timeline[i][1]
          memo2.employeeIds.push(employeeId)
        }
        return memo2
      }, { value: 0, employeeIds: [] })
      return memo
    }, data)
  })
}

function generateEmployeesTimelines({ employees, employeesNewsfeeds, filters, options, scope }, domains, valueFn) {
  return employees.reduce((memo, employee) => {
    const newsfeed = employeesNewsfeeds[employee.id] || []
    memo[employee.id] = generateEmployeeTimeline(domains, employee, filters, newsfeed, options, scope, valueFn)
    return memo
  }, {})
}

// 2021-01-16 -> 2021-01
export function getMonth(normalizedDate) {
  return normalizedDate && normalizedDate.slice(0, 7)
}

export function getWageWithStartDate(wage, referenceModel) {
  return { ...wage, startDate: referenceModel && referenceModel.startDate ? referenceModel.startDate : buildNormalizedDate() }
}

function generateEmployeeTimeline(domains, employee, filters, newsfeed, options, scope, valueFn) {
  const arrivalDate = getField(employee, 'arrivalDate')
  const arrivalMonth = getMonth(arrivalDate)
  const departureMonth = getMonth(getField(employee, 'departureDate'))

  return domains.x.map(tick => {
    const tickMonth = getMonth(tick)
    let z = employee[scope]

    // Reject future employee (the months before their arrival)
    if (arrivalMonth && tickMonth < arrivalMonth) {
      return [z, 0]
    }
    // Reject departed employee (the months after their departure)
    else if (departureMonth && tickMonth > departureMonth) {
      return [z, 0]
    }
    else {
      let wages = newsfeed.slice()
      // Add upcoming wages
      wages = [...employee.upcomingWages, ...wages]
      // Add default wage if newsfeed is empty
      if (!wages.length && employee.wage) {
        wages.push(employee.wage)
      }
      // RefWage for the salary, RefQualificationWage for the qualification
      let refWage = wages.shift()
      let refQualificationWage = refWage

      // Find the right wage for current month by iterating from the most recent one
      while (wages.length && tickMonth < getMonth(refWage.startDate)) {
        refWage = wages.shift()
        if (refWage.qualification.length) {
          refQualificationWage = refWage
        }
      }
      if (refWage) {
        // If scope value isn't found in refQualificationWage qualification,
        // getScopeValue() will fallback on scopeValuesWage qualificaton,
        // which was set on employee in getEmployeesAndAlumnis()
        z = getScopeValue(scope, employee, refQualificationWage).value
        if (isFiltered(filters, employee, scope, z)) {
          return [z, valueFn(refWage, options)]
        }
        else {
          return [z, 0]
        }
      }
      else {
        return [z, valueFn(null, options)]
      }
    }
  })
}
