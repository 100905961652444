<template>
  <div
    class="wage-tab"
    :class="{active}">
    <div class="component-name">{{ component.name }}</div>
    <div v-if="selectedLevel && !selectedLevel.isHidden">
      <div class="selected-level">
        <span
          class="selected-level-delta fade-in"
          :class="{negative: selectedLevel.salaryValue < referenceLevel.salaryValue}"
          v-if="showDelta && referenceLevel && selectedLevel.salaryValue !== referenceLevel.salaryValue">
        </span>
        {{selectedLevel.name}}
      </div>
      <div class="selected-level-value" v-if="showSalary">
        {{ component | formattedLevelValue(selectedLevel, parentComponent, parentComponent && parentComponent.selectedLevel, 'formula', {wageDetails}) }}
      </div>
    </div>
    <div v-else-if="selectedLevel && selectedLevel.isHidden" class="selected-level highlighted" v-t="'grid.editor.hiddenLevel'"></div>
    <div v-else class="selected-level highlighted" v-t="'grid.editor.toBeDefined'"></div>
  </div>
</template>

<script>
export default {
  props: {
    active: Boolean,
    component: Object,
    parentComponent: Object,
    referenceComponent: Object,
    // Used in formattedLevelValue
    wageDetails: Object,
    showDelta: {
      type: Boolean,
      default: false
    },
    showSalary: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    selectedLevel() {
      return this.component && this.component.selectedLevel
    },
    referenceLevel() {
      return this.referenceComponent && this.referenceComponent.selectedLevel
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/alert.scss";
@import "./src/styles/animation.scss";

.wage-tab {
  background: transparent url(~@/assets/triangle-down.svg) no-repeat bottom 8px center;
  padding: 5px 2px 21px;
  text-align: center;
  cursor: pointer;
  @include line-regular-height;

  &:hover {
    user-select: none;
  }

  &:hover,
  &.active {
    background-color: lighten($graph-blue-color, 5);
  }

  &.active {
    background-image: url(~@/assets/triangle-down-active.svg);
    color: darken($graph-darkblue-color, 5);
  }
  &.active .component-name,
  &.active .selected-level-value,
  &.active .arrow {
    opacity: 0.8;
  }
}

.component-name,
.selected-level-value {
  opacity: 1;
}

.selected-level {
  @include font-semibold;
  font-size: 1.1em;
}

.highlighted {
  color: darken($clearteal-color, 5);
}

.selected-level-delta {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: transparent url(~@/assets/trend-up.svg) no-repeat center center;
  vertical-align: -2px;

  &.negative {
    background-image: url(~@/assets/trend-down.svg)
  }
}

.selected-level-value {
  @include font-tabular-numbers;
}

.arrow {
  @include font-small-size;
  opacity: 0.7;
  margin-top: 0.25em;
}

.alert.warning.light {
  padding: 2px;
  margin: 0 2px;
}

@media screen and (max-width: 575px) {
  .wage-tab,
  .selected-level {
    @include font-tiny-size;
  }
}
</style>
