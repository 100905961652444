<template>
  <div class="components">
    <span
      v-for="component in components"
      :key="component.id"
      class="component"
      :title="component.name + ' : ' + component.selectedLevel.name">
      <label>{{ component.name }}</label>
      <template v-if="component.referenceLevel">
        {{ component.referenceLevel.name }}
        &nbsp;→&nbsp;
      </template>
      {{ component.selectedLevel.name }}
    </span>
    <span v-if="salarySummary.delta !== referenceSummary.delta" class="component">
      <label v-t="'grid.editor.adjustment'"></label>
      <template v-if="referenceSummary.delta">
        {{ referenceSummary.delta | formattedCurrency(false, {signDisplay: "always"}) }}
        &nbsp;→&nbsp;
      </template>
      {{ salarySummary.delta | formattedCurrency(false, {signDisplay: "always"}) }}
    </span>
    <span class="component">
      <label v-t="'grid.editor.fixedSalary'"></label>
      <template v-if="salarySummary.fixedValue !== referenceSummary.fixedValue">
        {{referenceSummary.fixedValue | formattedCurrency }}
        &nbsp;→&nbsp;
      </template>
      {{salarySummary.fixedValue | formattedCurrency }}
    </span>
    <span v-if="salarySummary.variableValue || referenceSummary.variableValue" class="component">
      <label v-t="'grid.editor.variableSalary'"></label>
      <template v-if="salarySummary.variableValue !== referenceSummary.variableValue">
        {{referenceSummary.variableValue | formattedCurrency }}
        &nbsp;→&nbsp;
      </template>
      {{salarySummary.variableValue | formattedCurrency }}
    </span>
    <span
      v-if="delta !== 0"
      class="badge border-badge large-badge delta-badge"
      :class="{higher: delta > 0, lower: delta < 0}">
      {{ delta | formattedCurrency(false, {signDisplay: "always"}) }}
      <small>({{ deltaPercent | formattedNumber(1, 1) }}&nbsp;%)</small>
    </span>
    <span
      v-else
      key="unchanged"
      class="badge large-badge delta-badge"
      v-t="'common.unchanged'"></span>
  </div>
</template>

<script>
import { getPercent } from '@/utils/math'
import { isGridItemsSimilar } from '@/utils/diff'

export default {
  props: {
    wageDetails: Object,
    referenceWageDetails: Object
  },
  computed: {
    components() {
      const components = []
      this.wageDetails.selectedComponents.forEach(component => {
        const referenceComponent = this.referenceWageDetails.selectedComponents
          .find(c => isGridItemsSimilar('component', c, component))
        if (referenceComponent) {
          if (referenceComponent.selectedLevel.name !== component.selectedLevel.name) {
            components.push({
              ...component,
              referenceLevel: referenceComponent.selectedLevel
            })
          }
        }
        else {
          components.push(component)
        }
      })
      return components
    },
    salarySummary() {
      return this.wageDetails.summary.salary
    },
    referenceSummary() {
      return this.referenceWageDetails.summary.salary
    },
    delta() {
      return this.salarySummary.value - this.referenceSummary.value
    },
    deltaPercent() {
      return getPercent(this.salarySummary.value, this.referenceSummary.value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/badge.scss";

.components {
  display: inline-block;
  line-height: 1.5em;
  padding: 0.25em 0.75em;
  min-height: 39px;
}

.component {
  @include font-medium;
  display: inline-block;
  margin: 0.25em 0;

  &:not(:last-of-type) {
    margin-right: 1.75em;
  }

  label {
    @include font-small-size;
    @include font-regular;
    display: block;
  }
}

.badge {
  vertical-align: 9.5px;
}
</style>
