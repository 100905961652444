<template>
  <div class="sidebar-container fixed-size" ref="container">
    <aside>
      <ul class="sticky">
        <template v-if="partitionJobs.jobs.length">
          <li class="header">
            <h3 v-t="'grid.onboarding.skills.jobSkills'"></h3>
          </li>
          <li
            v-for="job in partitionJobs.jobs"
            :key="job.id"
            class="item"
            :class="{selected: job.id == selectedJob}"
            @click="selectJob(job.name)">
            <div class="item-name">{{job.name}}</div>
            <onboarding-progress-bar
              :minimal="true"
              :percent="job.progress" />
          </li>
        </template>
        <template v-if="partitionJobs.others.length">
          <li class="header">
            <h3 v-t="'grid.onboarding.skills.shortOtherSkills'"></h3>
          </li>
          <li
            v-for="job in partitionJobs.others"
            :key="job.id"
            class="item"
            :class="{selected: job.id == selectedJob}"
            @click="selectJob(job.id)">
            <div class="item-name">{{job.name}}</div>
            <onboarding-progress-bar
              :minimal="true"
              :percent="job.progress" />
          </li>
        </template>
      </ul>
    </aside>
    <main v-if="jobSkills">
      <header class="sticky template-header">
        <button
          v-if="!isHeaderVisible"
          class="float-right secondary"
          @click="toggleHeader"
          v-t="'grid.onboarding.common.options'"></button>
        <h3 @click="toggleHeader">
          <span>{{selectedJobName}}</span>
          <onboarding-progress-bar
            :percent="getJobSkillsProgress(selectedJob)"
            :key="selectedJob" />
        </h3>
        <resizable-panel :observer="[isHeaderVisible, skillsTemplates]">
          <div v-if="isHeaderVisible">
            <p class="no-margin-top" v-if="!jobSkills.hasSkills" v-t="'grid.onboarding.skills.optionsNew'"></p>
            <p class="no-margin-top" v-else v-t="'grid.onboarding.skills.optionsExisting'"></p>
            <div class="templates">
              <template v-for="template in skillsTemplates">
                <input
                  type="radio"
                  :value="template.ref"
                  :id="[template.ref, _uid].join('-')"
                  :key="template.ref"
                  v-model="selectedTemplate"
                  @change="selectTemplate">
                <label
                  :for="[template.ref, _uid].join('-')"
                  :key="[template.ref, 'label'].join()"
                  class="template">
                  {{ template.name }}
                </label>
              </template>
              <menu>
                <div>
                  <span class="info">i</span>
                </div>
                <div class="template-description">
                  {{selectedTemplateDescription}}
                  <dropdown
                    class="blue"
                    v-if="selectedTemplate === '_copy' && availableJobsToCopy"
                    :items="availableJobsToCopy"
                    @change="selectTemplateJob">
                    {{selectedTemplateJob}}
                    <span class="down">▾</span>
                  </dropdown>
                </div>
                <div class="nowrap">
                  <template v-if="jobSkills.hasSkills">
                    <button
                      class="secondary"
                      v-t="'common.cancel'"
                      @click="toggleHeader" />
                    <loading-button
                      class="primary"
                      @click="setJobSkills(selectedJob, selectedTemplate, selectedTemplateJob)"
                      :loading="isLoading">
                      <span v-t="'common.update'"></span>
                    </loading-button>
                  </template>
                  <loading-button
                    v-else
                    class="primary"
                    @click="setJobSkills(selectedJob, selectedTemplate, selectedTemplateJob)"
                    :loading="isLoading">
                    <span v-t="'grid.onboarding.common.start'"></span>
                  </loading-button>
                </div>
              </menu>
            </div>
          </div>
        </resizable-panel>
      </header>
      <div
        v-if="jobSkills"
        class="job-skills"
        ref="skills"
        :class="{disabled: jobSkills.disabled, 'fade-in-sections': isFadingIn}">
        <div
          v-for="level in jobSkills.levels"
          :key="level.id"
          class="level progress-block fade-in-section">
          <h3 class="level-title">{{level.name}}</h3>
          <markdown-text
            class="description small"
            v-model="level.description" />
          <div
            class="domain"
            v-for="domain in level.domains"
            :key="domain.id">
            <strong>{{domain.name}}</strong>
            <markdown-text
              class="description small"
              v-model="domain.description" />
            <ul class="skills">
              <li v-for="skill in domain.skills" :key="skill.id" class="skill">
                <autosize-textarea
                  type="text"
                  :value="skill.name"
                  :disabled="jobSkills.disabled"
                  :placeholder="$t('grid.onboarding.skills.skillPlaceholder')"
                  @input='value => onUpdate(selectedJob, level.id, domain.id, skill.id, value)'
                  @batch='values => onBatchAdd(selectedJob, level.id, domain.id, values)'
                  @enter='onEnter($event)'
                  @delete='onDelete($event, selectedJob, level.id, domain.id, skill.id)' />
                <div
                  v-if="!jobSkills.disabled && (skill.name || domain.skills.length > 1)"
                  class="remove-skill"
                  @click="onDelete($event, selectedJob, level.id, domain.id, skill.id)">×</div>
              </li>
              <li v-if="!domain.skills.length" class="skill">
                <autosize-textarea
                  type="text"
                  value=""
                  :disabled="jobSkills.disabled"
                  :placeholder="$t('grid.onboarding.skills.skillPlaceholder')"
                  @focus="onEnter($event)" />
              </li>
              <li class="add-skill" v-if="!jobSkills.disabled">
                <a href="#" @click="onAdd($event, selectedJob, level.id, domain.id)" v-t="'grid.onboarding.common.addButton'"></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import partition from 'lodash.partition'
import Vue from 'vue'
import animateScrollTo from 'animated-scroll-to'
import { mapGetters } from 'vuex'
import AutosizeTextarea from '@components/commons/AutosizeTextarea'
import MarkdownText from '@components/commons/MarkdownText'
import Dropdown from '@components/commons/Dropdown'
import ResizablePanel from '@components/commons/ResizablePanel'
import OnboardingProgressBar from '@components/grid/onboarding/OnboardingProgressBar'

export default {
  components: {
    AutosizeTextarea,
    MarkdownText,
    Dropdown,
    OnboardingProgressBar,
    ResizablePanel
  },
  data() {
    return {
      isAddingJobSkill: null,
      isFadingIn: false,
      isHeaderExpanded: false,
      isLoading: false,
      previewTemplate: null,
      previewTemplateCache: {},
      selectedTemplate: '',
      selectedTemplateJob: '',
      availableSkillsTemplates: {}
    }
  },
  computed: {
    ...mapGetters({
      jobs: 'onboarding/getJobs',
      selectedJob: 'onboarding/getSelectedJob',
      getJobSkills: 'onboarding/getJobSkills',
      getPreviewJobSkills: 'onboarding/getPreviewJobSkills',
      getJobSkillsProgress: 'onboarding/getJobSkillsProgress',
      gridComponents: 'sandbox/allComponents'
    }),
    partitionJobs() {
      const [jobs, others] = partition(this.jobs, ({ id }) => id[0] !== '_')
      return { jobs, others }
    },
    selectedJobName() {
      const job = this.jobs.find(job => job.id === this.selectedJob)
      return job && job.name
    },
    nonEmptyJobs() {
      return this.partitionJobs.jobs.filter(j => j.id !== this.selectedJob).map(j => j.id)
    },
    availableJobsToCopy() {
      if (this.selectedTemplate === '_copy' && this.nonEmptyJobs.length) {
        return [{
          name: this.$t('grid.onboarding.skills.roles'),
          value: 'job',
          items: this.nonEmptyJobs.map(job => ({
            name: job,
            value: job,
            selected: this.selectedTemplateJob === job
          }))
        }]
      }
    },
    isHeaderVisible() {
      return this.jobSkills.disabled || this.isHeaderExpanded
    },
    isSkillsTemplateAvailable() {
      return !this.jobSkills.hasCustomDomains && this.jobSkills.levels.length === 6
    },
    jobSkills() {
      if (this.previewTemplate) {
        return this.getPreviewJobSkills(this.selectedJob, this.previewTemplate)
      }
      else {
        return this.getJobSkills(this.selectedJob)
      }
    },
    skillsTemplates() {
      const isSeniority = this.gridComponents.find(c => c.ref === 'seniority' && c.id === this.selectedJob.substring(1))
      const key = isSeniority ? 'seniority' : 'experience'
      const availableSkillsTemplates = this.availableSkillsTemplates[key] || []
      let skillsTemplates = [{
        ref: '',
        name: this.$t('grid.onboarding.skills.emptyTemplate.title'),
        description: this.$t('grid.onboarding.skills.emptyTemplate.description')
      }]

      if (this.isSkillsTemplateAvailable) {
        skillsTemplates = skillsTemplates.concat(availableSkillsTemplates)
        if (key === 'experience' && this.nonEmptyJobs.length) {
          skillsTemplates.splice(1, 0, {
            ref: '_copy',
            name: this.$t('grid.onboarding.skills.copyTemplate.title'),
            description: this.$t('grid.onboarding.skills.copyTemplate.description')
          })
        }
        skillsTemplates.unshift()
      }

      return skillsTemplates
    },
    selectedTemplateDescription() {
      const selectedTemplate = this.skillsTemplates.find(t => t.ref === this.selectedTemplate)
      return selectedTemplate && selectedTemplate.description
    }
  },
  methods: {
    toggleHeader() {
      this.isHeaderExpanded = !this.isHeaderExpanded
      if (this.isHeaderExpanded) {
        this.selectTemplate()
      }
      else {
        this.previewTemplate = null
      }
    },
    selectTemplateJob(_, job) {
      this.selectedTemplateJob = job
    },
    selectTemplate() {
      const job = this.selectedJob
      const template = this.selectedTemplate
      const key = [job, template].join('-')
      if (template) {
        if (template === '_copy') {
          if (this.nonEmptyJobs.length) {
            this.selectedTemplateJob = this.nonEmptyJobs.find(j => j !== this.selectedJob) || this.nonEmptyJobs[0]
          }
          this.previewTemplate = true
        }
        else if (this.previewTemplateCache[key]) {
          this.previewTemplate = this.previewTemplateCache[key]
        }
        else {
          this.$store.dispatch('onboarding/loadSkillTemplate', { job, template }).then(response => {
            this.previewTemplate = response
            this.previewTemplateCache[key] = this.previewTemplate
          })
        }
      }
      else {
        this.previewTemplate = true
      }
    },
    selectJob(job) {
      this.selectedTemplate = ''
      this.selectedTemplateJob = ''
      this.previewTemplate = null
      this.isFadingIn = false
      this.isHeaderExpanded = false
      this.$store.dispatch('onboarding/setSelectedJob', job)
      this.$emit('resize')

      const shouldScroll = this.$refs.container.getBoundingClientRect().top < 0
      if (shouldScroll) {
        animateScrollTo(this.$refs.container, {
          minDuration: 0,
          maxDuration: 600
        })
      }
    },
    setJobSkills(job, template, copy) {
      const jobSkills = this.getJobSkills(this.selectedJob) || {}
      if (jobSkills.hasSkills) {
        if (window.confirm(this.$t('grid.onboarding.skills.overwriteWarning'))) {
          this.setJobSkillsConfirmed(job, template, copy)
        }
      }
      else {
        this.setJobSkillsConfirmed(job, template, copy)
      }
    },
    setJobSkillsConfirmed(job, template, copy) {
      this.isLoading = true
      this.$store.dispatch('onboarding/setJobSkills', { job, template, copy }).then(() => {
        this.isLoading = false
        this.isHeaderExpanded = false
        this.previewTemplate = false
        this.flashJobSkills()
        this.$emit('change')
      })
    },
    onEnter(event) {
      const nextElement = event.target.parentElement.nextElementSibling &&
        event.target.parentElement.nextElementSibling.firstElementChild
      if (nextElement) {
        if (nextElement.nodeName === 'A') {
          nextElement.click()
        }
        else {
          nextElement.focus()
        }
      }
      event.preventDefault()
    },
    onAdd(event, job, levelId, domainId) {
      event.preventDefault()

      // Avoid parallel calls to addJobSkill
      if (!this.isAddingJobSkill) {
        this.isAddingJobSkill = true
        this.$store.dispatch('onboarding/addJobSkill', { job, levelId, domainId }).then(() => {
          Vue.nextTick(() => {
            event.target.parentElement && event.target.parentElement.previousElementSibling.firstElementChild.focus()
            this.isAddingJobSkill = false
          })
        })
          .catch(() => {
            this.isAddingJobSkill = false
          })
        this.$emit('change')
      }
    },
    onBatchAdd(job, levelId, domainId, names) {
      this.$store.dispatch('onboarding/addJobSkills', { job, levelId, domainId, names })
    },
    onDelete(event, job, levelId, domainId, skillId) {
      event.preventDefault()

      const nextFocusedElement = event.target.parentElement.previousElementSibling || event.target.parentElement.nextElementSibling
      if (!nextFocusedElement.classList.contains('add-skill')) {
        nextFocusedElement.firstElementChild.focus()
      }
      this.$store.dispatch('onboarding/deleteJobSkill', { job, levelId, domainId, skillId })
      this.$emit('change')
    },
    onUpdate(job, levelId, domainId, skillId, name) {
      this.$store.dispatch('onboarding/updateJobSkill', { job, levelId, domainId, skillId, name })
        .catch(error => error && alert(error))
      this.$emit('change')
    },
    flashJobSkills() {
      Vue.nextTick(() => {
        const firstSkill = this.$refs.skills.querySelector('textarea')
        if (firstSkill && firstSkill.value) {
          firstSkill.focus()
        }
      })
      this.isFadingIn = true
      setTimeout(() => {
        this.isFadingIn = false
      }, 1500)
    }
  },
  created() {
    this.$store.dispatch('onboarding/loadSkillsTemplates').then(templates => {
      this.availableSkillsTemplates = templates
    })
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/block.scss";
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";
@import "./src/styles/link.scss";
@import "./src/styles/sidebar.scss";

.sidebar-container {
  grid-gap: 0;

  main {
    padding: 1.1em;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1.25em;
    padding: 0.8em 0.8em 0.8em 1em;
    border-bottom: 1px solid $graph-accentblue-color;

    .item-name {
      @include font-small-size;
      @include font-semibold;
    }
  }

  .header h3 {
    @include font-normal-size;
    line-height: 24px;
  }

  .header + .item {
    border-top: 1px solid $graph-accentblue-color;
    margin-top: -1px;
  }
}

.job-skills.disabled {
  .level-title,
  .description,
  strong {
    color: darken($light-text-color, 10);
  }
}

.level {
  padding-bottom: 1em;

  .level-title {
    margin-top: 0.25em;
    margin-bottom: 0.5em;
  }

  .domain:not(:last-of-type) {
    margin-bottom: 1.75em;
  }
}

strong {
  display: inline-block;
  margin-bottom: 0.5em;
}

ul.skills {
  list-style-type: none;
  padding: 0;
  margin: 0.25em 0;
  line-height: 2em;
  border: 1px solid $graph-inner-border-color;
  border-radius: $border-radius;
  overflow: hidden;
  box-shadow: 1px 2px 0 rgba(0, 0, 0, 0.015), 3px 4px 0 rgba(0, 0, 0, 0.005);

  .skill {
    display: flex;

    textarea {
      margin: 0;
      padding: 0.8em 0.65em;
      border: none;
      line-height: 1.4em;

      &:focus {
        background: lighten($graph-lightblue-color, 2) !important;
        box-shadow: inset 3px 0 0 lighten($graph-darkblue-color, 22) !important;

        & + .remove-skill {
          background: lighten($graph-lightblue-color, 2);
        }
      }
    }

    &:hover {
      textarea {
        background: lighten($graph-lightblue-color, 2);
        box-shadow: inset 3px 0 0 lighten($graph-darkblue-color, 50);
      }

      .remove-skill {
        background: lighten($graph-lightblue-color, 2);
      }
    }

    &:not(:last-of-type) textarea {
      border-bottom: $border;
    }
  }

  .remove-skill {
    @include font-big-size;
    @include font-bold;
    cursor: pointer;
    color: lighten($text-color, 25);
    background: white;
    border-bottom: $border;
    min-width: 0;
    width: 33px;
    line-height: 39px;
    text-align: center;
    user-select: none;

    &:hover {
      color: $red-color;
    }
  }

  li.add-skill a {
    @extend .unstyled-link;
    display: block;
    color: $graph-darkblue-color;
    @include font-small-size;
    padding: 0 0.5em;
    background: white;

    &:hover,
    &:focus {
      background: $graph-lightblue-color;
      color: $graph-darkblue-color;
      border-radius: 0 0 4px 4px;
      outline: none;
    }
  }
}

.description {
  @include font-small-size;
  margin: 0 0 1em;
}

.template-header {
  background: $graph-lightblue-color;
  border-bottom: 1px solid lighten($graph-blue-color, 5);
  padding: 1.1em 1.1em 0 1.1em;
  margin: -1.1em -1.1em 1.1em -1.1em;

  & > h3 {
    cursor: pointer;
    margin-bottom: 17px;
  }

  & > button {
    margin-top: -4px;
  }
}

.templates {
  font-size: 0;

  .template {
    background: white;
    border: 1px solid $graph-outer-border-color;
    border-radius: 1em;
    color: $graph-darkblue-color;
    line-height: 2em;
    display: inline-block;
    padding: 0 0.5em;
    margin-right: 0.5em;
    margin-bottom: 0.5em;
    cursor: pointer;
    @include font-small-size;

    &:hover {
      border-color: lighten($graph-darkblue-color, 20);
      background: rgba(lighten($graph-darkblue-color, 18), 0.05);
    }
  }

  input[type="radio"] {
    display: none;
  }

  input[type="radio"]:checked + .template {
    border-color: lighten($graph-darkblue-color, 20);
    background: rgba(lighten($graph-darkblue-color, 18), 0.2);
  }

  menu {
    @include font-normal-size;
    margin: 0 0 0.5em;
    text-align: left;
    display: grid;
    grid-template-columns: 25px 10fr 1fr;
    align-items: center;
    grid-column-gap: 0.5em;
    min-height: 50px;
    padding-right: 0.5em;

    & > * {
      margin-left: 0;
    }
  }

  button:not(:first-of-type) {
    margin-left: 6.5px;
  }

  .template-description {
    @include font-small-size;
    margin-top: 2px;
  }

  .info {
    display: inline-block;
    @include font-bold;
    border: 1px solid lighten($graph-darkblue-color, 20);
    color: lighten($graph-darkblue-color, 10);
    line-height: 18px;
    width: 20px;
    border-radius: 11px;
    text-align: center;
    margin-top: 1px;
  }
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 1;
  box-shadow: 0px 2px 0 rgba(0, 0, 0, 0.015);
}
</style>
