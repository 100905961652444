import * as d3 from 'd3'
import i18n from '@/i18n'
import uuidv4 from 'uuid/v4'
import gaussian from 'gaussian'
import groupBy from 'lodash.groupby'
import flatten from 'lodash.flatten'
import flattenDeep from 'lodash.flattendeep'
import sortBy from 'lodash.sortby'
import { linearRegressionLine, linearRegression } from 'simple-statistics'
import { formatCurrency } from './currency'

const LABELS_ALIGN_TOP = 12
const LABELS_MARGIN = 8

const AXIS_BAND_PADDING = 0.1 // space between bands

const BADGE_WIDTH = 40
const BADGE_HEIGHT = 40

const CIRCLE_SIZE = 5
const CIRCLE_MARGIN = CIRCLE_SIZE * 1.3

// Base by https://bl.ocks.org/mbostock/7555321
// Modified to handle line breaks & font size reduction on overflow
function wrap(text, width) {
  let isOverflowing = false
  text.each(function() {
    const text = d3.select(this)

    if (!text.attr('data-label')) {
      text.attr('data-label', text.text())
    }

    const words = text.attr('data-label').replace(/\n/g, ' \n').split(/[ ]+/).reverse()
    let word
    let line = []
    let lineNumber = 0
    const lineHeight = 1.25 // ems
    const x = text.attr('x')
    const dy = parseFloat(text.attr('dy') || 0)

    let tspan = text.text(null)
      .style('font-size', null)
      .append('tspan')
      .attr('x', x)
      .attr('dy', dy + 'em')

    // eslint-disable-next-line no-cond-assign
    while (word = words.pop()) {
      let shouldBreak = false
      if (word[0] === '\n') {
        word = word.substr(1)
        shouldBreak = true
      }
      line.push(word)
      tspan.text(line.join(' '))
      if (tspan.node().getComputedTextLength() > width || shouldBreak) {
        if (line.length > 1) {
          line.pop()
          tspan.text(line.join(' '))
          line = [word]
          tspan = text.append('tspan')
            .attr('x', x)
            .attr('dy', Math.min(++lineNumber, 1) * lineHeight + dy + 'em')
            .text(word)
        }
      }
      isOverflowing = isOverflowing || tspan.node().getComputedTextLength() > width

      // Truncate text of current label if too big
      if (tspan.node().getComputedTextLength() > width * 1.5) {
        tspan.text(tspan.text().substr(0, 3) + '…')
      }
    }
  })
  // Reduce font size of all labels if one is overflowing
  if (isOverflowing) {
    text.style('font-size', '0.8em')
  }
}

// Setup d3 locale
d3.formatDefaultLocale({
  decimal: ',',
  thousands: '\u00a0',
  grouping: [3],
  currency: ['', '\u00a0€'],
  percent: '\u202f%'
})

export function formatLabel(d3Format, text) {
  if (d3Format === '$,') {
    return formatCurrency(text)
  }
  else if (d3Format) {
    return d3.format(d3Format)(text)
      .replace('k ', 'k')
      .replace('€', i18n.currencySymbol)
  }
  else {
    return text
  }
}

const LABEL_SEP = ' · '
export function truncateLabel(label, length = 14) {
  label = (label || '').split(LABEL_SEP)[0]
  return label.length <= length ? label : label.substr(0, length) + '…'
}

export function getMinValue(data) {
  return typeof data.y !== 'undefined' ? data.y : d3.min(data.values, v => v.y0 || v.y || 0)
}

export function getMaxValue(data) {
  return typeof data.y !== 'undefined' ? data.y : d3.max(data.values, v => v.y1 || v.y || 0)
}

export function generateSalariesGraphModel(data, axes, viewport) {
  let manualAbscissaDomain
  const innerPadding = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }
  const graph = {
    data: [],
    axes: axes,
    viewport: viewport
  }

  // Detect and generate data based on content
  switch (true) {
    case !!data.job: // Job
      // 1st decile (10% have less),
      const d1 = data.job.minimumSalary
      // 9th and last decile (10% have more)
      const d9 = data.job.maximumSalary
      const mean = (d1 + d9) / 2
      // https://en.wikipedia.org/wiki/Standard_deviation#Rules_for_normally_distributed_data
      const confidence80p = 1.281552
      const confidence99p = 2.575829
      const stddev = (d9 - mean) / confidence80p
      const variance = Math.pow(stddev, 2)
      const normal = gaussian(mean, variance)
      const points = 50

      const scale = d3.scaleLinear()
        .range([mean - confidence99p * stddev, mean + confidence99p * stddev])
        .domain([0, points])

      // Majority of d9 of FR salaries are < 60k
      // however some aren't so we try to preserve the domain
      // unless it collides with the label
      let maxDomain = graph.axes.abscissa.maxDomain || 0
      const domainStep = 10000

      while (d9 + domainStep > maxDomain) {
        maxDomain += domainStep
      }
      manualAbscissaDomain = [0, maxDomain]
      graph.axes.abscissa.maxDomain = maxDomain

      graph.data = []
      for (let i = 0; i < points; i++) {
        graph.data.push({
          x: scale(i),
          inside: scale(i) >= d1 && scale(i) <= d9,
          values: [{
            y: normal.pdf(scale(i))
          }]
        })
      }

      break
  }

  // Generate inner viewport
  graph.viewport.innerWidth = graph.viewport.width - graph.viewport.padding.left - graph.viewport.padding.right
  graph.viewport.innerHeight = graph.viewport.height - graph.viewport.padding.top - graph.viewport.padding.bottom

  // Generate axes
  const abscissaDomain = manualAbscissaDomain || graph.data.map(d => d.x)
  const ordinateDomain = [d3.max(graph.data, getMaxValue), 0]
  graph.axes.abscissa.axis = generateAxis(graph.axes.abscissa, graph.viewport.innerWidth, abscissaDomain)
  graph.axes.ordinate.axis = generateAxis(graph.axes.ordinate, graph.viewport.innerHeight, ordinateDomain)

  // Adjust padding to let space for labels
  const abscissaRange = graph.axes.abscissa.axis.range()
  const ordinateRange = graph.axes.ordinate.axis.range()
  graph.axes.abscissa.axis.range([abscissaRange[0] + innerPadding.left, abscissaRange[1] + innerPadding.right])
  graph.axes.ordinate.axis.range([ordinateRange[0] + innerPadding.top, ordinateRange[1] + innerPadding.bottom])

  return graph
}

export function generateAxis(axis, rangeMax, domain) {
  switch (axis.type) {
    case 'discrete':
      return d3.scaleBand()
        .padding([AXIS_BAND_PADDING])
        .rangeRound([0, rangeMax])
        .domain(domain)
    case 'continuous':
      return d3.scaleLinear()
        .rangeRound([0, rangeMax])
        .domain(domain)
  }
}

export function generateAbscissaLabels(graph) {
  const abscissa = graph.axes.abscissa
  const ordinate = graph.axes.ordinate

  switch (abscissa.type) {
    case 'continuous':
      return abscissa.axis.ticks(7).filter(t => t).map(tick => ({
        x: abscissa.axis(tick),
        y: ordinate.axis.range()[1] + LABELS_ALIGN_TOP + LABELS_MARGIN,
        label: tick
      }))

    case 'discrete':
      switch (abscissa.labels) {
        case 'belowAxis':
          return graph.data.map(d => ({
            id: d.x,
            x: abscissa.axis(d.x) + abscissa.axis.bandwidth() / 2,
            y: ordinate.axis.range()[1] + LABELS_ALIGN_TOP + LABELS_MARGIN,
            count: d.values && d.values.length,
            label: d.x
          }))
        case 'belowMin':
          return graph.data.map(d => ({
            id: d.x,
            x: abscissa.axis(d.x) + abscissa.axis.bandwidth() / 2,
            y: ordinate.axis(getMinValue(d)) + LABELS_ALIGN_TOP + LABELS_MARGIN,
            count: d.values && d.values.length,
            label: d.x
          }))
      }
      break
  }
}

export function generateLinearRegressionAbscissaLabels(graph) {
  const abscissa = graph.axes.abscissa
  const ordinate = graph.axes.ordinate
  const maxMonth = abscissa.axis.domain()[1]
  const maxYears = Math.floor(maxMonth / 12)
  const desiredTicks = 7
  const isTooLow = maxYears < desiredTicks / 2
  const isTooHigh = maxYears > desiredTicks * 2
  const allTicks = [...Array(maxMonth)].map((_, i) => i + 1)
  const ticks = allTicks
    .filter(
      (isTooLow && (t => Math.floor(t / 6) === t / 6)) || // 6 months
      (!isTooHigh && (t => Math.floor(t / 12) === t / 12)) || // 1 year
      (isTooHigh && (t => Math.floor(t / (12 * 5)) === t / (12 * 5))) // 5 years
    )

  return ticks.map(tick => ({
    id: tick,
    x: abscissa.axis(tick),
    y: ordinate.axis(0) + LABELS_ALIGN_TOP + LABELS_MARGIN,
    label: tick
  }))
}

export function generateSalariesAbscissaLabels(graph, job) {
  const abscissa = graph.axes.abscissa
  const ordinate = graph.axes.ordinate
  const mean = (job.minimumSalary + job.maximumSalary) / 2
  const shortFormat = '~s'
  // eslint-disable-next-line no-irregular-whitespace
  const meanLabel = `
      ${formatLabel(shortFormat, job.minimumSalary)}
      \u00A0—\u00A0
      ${formatLabel(shortFormat, job.maximumSalary)}
    `
  const min = 0
  const max = abscissa.axis.domain()[1]
  const y = ordinate.axis(0) + LABELS_ALIGN_TOP + LABELS_MARGIN

  return [{
    x: abscissa.axis(0),
    y: y,
    label: formatLabel(shortFormat, min)
  }, {
    x: abscissa.axis(mean),
    y: y,
    label: meanLabel
  }, {
    x: abscissa.axis(max),
    y: y,
    label: formatLabel(abscissa.format, max)
  }]
}

export function generateSalariesOrdinateLabels(graph, job) {
  const abscissa = graph.axes.abscissa
  const mean = (job.minimumSalary + job.maximumSalary) / 2
  const meanLabel = '80 %'
  const y = graph.viewport.innerHeight / 2 + graph.viewport.padding.top

  return [{
    x: abscissa.axis(mean),
    y: y,
    label: meanLabel
  }]
}

export function generateOrdinateLabels(graph, ticks = 6) {
  const abscissa = graph.axes.abscissa
  const ordinate = graph.axes.ordinate

  switch (ordinate.labels) {
    case 'beforeAxis':
      return ordinate.axis.ticks(6)
        .filter(tick => Number.isInteger(tick))
        .map(tick => ({
          id: tick,
          x: 0,
          y: ordinate.axis(tick),
          label: formatLabel(ordinate.format, tick)
        }))
    case 'aboveMax':
      return graph.data.map(d => ({
        id: d.x,
        x: abscissa.axis(d.x) + abscissa.axis.bandwidth() / 2,
        y: ordinate.axis(getMaxValue(d)) - LABELS_MARGIN - 1,
        label: d.label || formatLabel(ordinate.format, getMaxValue(d))
      }))
    case 'belowMin':
      return graph.data.map(d => ({
        x: abscissa.axis(d.x) + abscissa.axis.bandwidth() / 2,
        y: ordinate.axis(getMinValue(d)) + LABELS_ALIGN_TOP + LABELS_MARGIN,
        label: formatLabel(ordinate.format, getMinValue(d))
      }))
  }
}

export function generateOrdinateTicks(graph) {
  const ordinate = graph.axes.ordinate

  return ordinate.axis.ticks().map(tick => ({
    y: ordinate.axis(tick),
    label: formatLabel(ordinate.format, tick)
  }))
}

export function generateBars(graph) {
  return graph.data.map(d => ({
    id: d.x,
    x: graph.axes.abscissa.axis(d.x),
    width: graph.axes.abscissa.axis.bandwidth(),
    y: graph.axes.ordinate.axis(getMinValue(d)),
    height: graph.axes.ordinate.axis.range()[1] - graph.axes.ordinate.axis(getMinValue(d))
  }))
}

export function generateStackedBars(graph, padding = 4) {
  return flattenDeep(graph.data.map(d => d.values)).map(d => ({
    id: [d.x, d.class].join(),
    x: graph.axes.abscissa.axis(d.x),
    width: graph.axes.abscissa.axis.bandwidth(),
    y: graph.axes.ordinate.axis(d.y1),
    height: Math.max(graph.axes.ordinate.axis(d.y0) - padding - graph.axes.ordinate.axis(d.y1), 0),
    class: d.class
  }))
}

export function generateCircles(graph) {
  return graph.data.map(e => ({
    id: e.id,
    x: graph.axes.abscissa.axis(e.x),
    y: graph.axes.ordinate.axis(e.y),
    r: CIRCLE_SIZE,
    class: e.class,
    route: e.route
  }))
}

export function generateBeeswarmCircles(graph) {
  return graph.data.map(d => {
    const quantiles = [
      d3.quantile(d.values.map(({ y }) => y), 0),
      d3.quantile(d.values.map(({ y }) => y), 0.5),
      d3.quantile(d.values.map(({ y }) => y), 1)
    ]
    return {
      id: d.x,
      quantiles: quantiles,
      values: d.values.map(v => ({
        id: v.id,
        x: graph.axes.abscissa.axis(v.x) + graph.axes.abscissa.axis.bandwidth() / 2 + v.xOffset * graph.axes.abscissa.axis.step(),
        y: graph.axes.ordinate.axis(v.y),
        v: v.y,
        r: (v.r || 0.8) * CIRCLE_SIZE,
        isDelta: v.isDelta,
        class: v.class,
        route: v.route
      }))
    }
  })
}

export function generateStackedCircles(graph) {
  const gap = CIRCLE_SIZE * 2 + CIRCLE_MARGIN

  return flattenDeep(graph.data.map(d => {
    const cols = Math.max(3, Math.floor(graph.axes.abscissa.axis.bandwidth() / gap) - 1)
    return sortBy(d.values, d => d.salary).map((v, i, all) => {
      const total = all.length
      const maxX = (Math.min(cols, total) - 1) * gap
      let x = (i % cols) * gap - maxX / 2
      const y = Math.floor(i / cols) * gap
      const isTopRow = Math.floor((total - 1) / cols) === Math.floor(i / cols)
      const isNotFirstRow = i > cols - 1
      const topRowColsToCenter = total - Math.floor(total / cols) * cols

      if (isTopRow && isNotFirstRow && topRowColsToCenter) {
        x += (cols - topRowColsToCenter) / 2 * gap
      }

      return {
        id: v.id,
        x: graph.axes.abscissa.axis(d.x) + graph.axes.abscissa.axis.bandwidth() / 2 + x,
        y: graph.axes.ordinate.axis(0) - y - CIRCLE_SIZE - CIRCLE_MARGIN,
        r: CIRCLE_SIZE,
        class: v.gender,
        route: v.route
      }
    })
  }).filter(d => d))
}

export function generateLinearRegressionLines(graph, points) {
  const pointsByGender = groupBy(points, d => d.class)
  const domain = Object.keys(pointsByGender)

  return domain.map(gender => {
    const employees = pointsByGender[gender]
    const mb = linearRegression(employees.map(e => [e.x, e.y]))
    const line = linearRegressionLine(mb)
    const isInsignificant = Math.abs(mb.m) > 0.5

    return {
      id: gender,
      x1: graph.axes.abscissa.axis.range()[0],
      y1: line(graph.axes.abscissa.axis.range()[0]),
      x2: graph.axes.abscissa.axis.range()[1],
      y2: line(graph.axes.abscissa.axis.range()[1]),
      class: gender + (isInsignificant ? ' insignificant' : '')
    }
  })
}

export function generatePaths(graph) {
  const lineFunction = d3.line()
    .x(d => graph.axes.abscissa.axis(d.x))
    .y(d => graph.axes.ordinate.axis(getMinValue(d)))

  const confidenceData = [].concat(graph.data.filter(d => d.inside))

  confidenceData.push({
    x: confidenceData[confidenceData.length - 1].x,
    values: [{ y: 0 }]
  })
  confidenceData.push({
    x: confidenceData[0].x,
    values: [{ y: 0 }]
  })

  const mainLine = {
    d: lineFunction.curve(d3.curveBasis)(graph.data),
    class: 'curve'
  }

  const confidenceArea = {
    d: lineFunction.curve(d3.curveLinearClosed)(confidenceData),
    class: 'area'
  }
  return [confidenceArea, mainLine]
}

function spreadBadges(bandwidth, badges) {
  let badge
  const spreadBadges = []
  const availableBadgeSpread = Math.trunc(bandwidth / BADGE_WIDTH)

  // eslint-disable-next-line no-cond-assign
  while (badge = badges.pop()) {
    if (!badge.items || badge.items.length > availableBadgeSpread) {
      spreadBadges.push(badge)
    }
    else {
      const spreadCount = Math.min(badge.items.length, availableBadgeSpread)

      const centeredBandwidth = spreadCount * BADGE_WIDTH * 1.15

      const spreadScale = d3.scaleLinear()
        .range([-centeredBandwidth / 2 + BADGE_WIDTH / 2, centeredBandwidth / 2 - BADGE_WIDTH / 2])
        .domain([0, spreadCount - 1])
      badge.items.forEach((b, i) => {
        b.x += spreadScale(i)
        b.rect.x = b.x - BADGE_WIDTH / 2
        spreadBadges.push(b)
      })
    }
  }

  return spreadBadges
}

function collideBadges(badges) {
  let badge
  const sortedBadges = sortBy(badges, [d => -d.x, d => -d.y])
  const groupedBadges = []

  // eslint-disable-next-line no-cond-assign
  while (badge = sortedBadges.pop()) {
    const previousBadge = groupedBadges.length && groupedBadges[groupedBadges.length - 1]
    if (!previousBadge ||
        badge.x !== previousBadge.x ||
        badge.rect.y > (previousBadge.rect.y + BADGE_HEIGHT)) {
      groupedBadges.push(badge)
    }
    else {
      if (!previousBadge.items) {
        previousBadge.items = [Object.assign({}, previousBadge)]
        previousBadge.uuid = uuidv4()
      }
      previousBadge.items.push(Object.assign({}, badge))
      previousBadge.class = 'badge-group'
      previousBadge.label = previousBadge.items.length
    }
  }

  return groupedBadges
}

export function generateBadges(graph) {
  const bandwidth = graph.axes.abscissa.axis.bandwidth()

  return spreadBadges(bandwidth, collideBadges(flatten(graph.data.map(role => {
    return role.values.map(d => {
      const x = graph.axes.abscissa.axis(role.x) + bandwidth / 2

      const y = graph.axes.ordinate.axis(d.y)

      return {
        x: x,
        y: y,
        class: 'badge',
        uuid: uuidv4(),
        rect: {
          x: x - BADGE_WIDTH / 2,
          y: y - BADGE_HEIGHT / 2 - 1,
          width: BADGE_WIDTH,
          height: BADGE_HEIGHT
        },
        label: d.label
      }
    })
  }))))
}

function niceRound(x, max) {
  if (max > 1.5) {
    x = x * 100
    x = Math.ceil(x / 5) * 5
    return x / 100
  }
  else {
    return x
  }
}

export function generateCurveValues(count, curve, min, max) {
  if (max < min) {
    max = min
  }
  switch (curve) {
    case 'log':
      const logScale = d3.scaleLog()
        .domain([1, count])
        .range([min, max])
      return [...Array(count)].map((_, i) => {
        return +niceRound(logScale(i + 1), max).toFixed(2)
      })
    case 'cubic':
      const powScale = d3.scalePow()
        .exponent(3)
        .domain([1, count])
        .range([min, max])
      return [...Array(count)].map((_, i) => {
        return +niceRound(powScale(i + 1), max).toFixed(2)
      })
    default: // Linear
      return [...Array(count)].map((_, i) => {
        const step = count > 1 ? (max - min) / (count - 1) : 0
        return +niceRound((min + step * i), max).toFixed(2)
      })
  }
}

export function wrapLabels(graph, element) {
  if (graph.axes.abscissa.axis.bandwidth) {
    d3.select(element)
      .selectAll('text.wrap')
      .call(wrap, graph.axes.abscissa.axis.bandwidth())
  }
}

let mouseOutTimeout
export function renderInteractiveLayer(node, circlesData, onOver, onClick) {
  function mouseOver() {
    clearTimeout(mouseOutTimeout)
    const node = d3.select(this)
    const data = node.data()[0]
    const { id, isDelta } = data

    const halo = layer.select('.halo')
      .style('visibility', 'visible')

    halo.select('.circle-halo')
      .attr('cx', node.attr('cx'))
      .attr('cy', node.attr('cy'))
      .attr('r', data.r * 2.5)

    halo.select('.circle')
      .attr('cx', node.attr('cx'))
      .attr('cy', node.attr('cy'))
      .attr('cy', node.attr('cy'))
      .attr('r', data.r * 1.3)
      .attr('class', 'circle ' + data.class)

    const rootRect = root.node().getBoundingClientRect()
    const position = data.x > rootRect.width / 2 ? 'left' : 'right'

    onOver({
      id,
      position,
      isDelta
    })
  }

  function mouseOut() {
    mouseOutTimeout = setTimeout(() => {
      onOver(null)
      layer.select('.halo').style('visibility', 'hidden')
    }, 200)
  }

  function mouseClick(event) {
    const node = d3.select(this)
    const { route } = node.data()[0]
    event.stopPropagation()

    onClick(route)
  }

  const root = node.svg
  let layer = node.viewport.select('.interactive')
  if (!layer.size()) {
    layer = node.viewport.append('g')
      .attr('class', 'interactive')
  }

  const interactiveCircles = layer.selectAll('.interactive-circle')
    .data(circlesData, d => d.id)
  interactiveCircles
    .enter().append('circle')
    .attr('class', 'interactive-circle')
    .style('fill', 'none')
    .style('pointer-events', 'all')
    .style('cursor', 'pointer')
    .merge(interactiveCircles)
    .on('mouseover', mouseOver)
    .on('click', mouseClick)
    .attr('cx', d => d.x)
    .attr('cy', d => d.y)
    .attr('r', d => d.r * 1.9)
  interactiveCircles.exit()
    .remove()

  if (!layer.on('mouseout')) {
    layer.on('mouseout', mouseOut)
  }

  if (!layer.select('.halo').size()) {
    const halo = layer.append('g')
      .attr('class', 'halo')
      .style('pointer-events', 'none')
    halo.append('circle')
      .attr('class', 'circle-halo')
      .style('fill', 'rgba(255, 255, 255, 0.5)')

    halo.append('circle')
      .attr('class', 'circle')
  }
}

export function renderSelectionLayer(graph, isAvailable, clickFn) {
  // Reset selection state
  graph.isSelectionAvailable = isAvailable
  graph.selectionLatestX = null
  graph.node.selection.selectAll('.bar').remove()

  if (!graph.node.viewport.on('mousemove')) {
    graph.node.viewport
      .on('mousemove', mouseMove)
      .on('mouseout', mouseOut)
      .on('click', mouseClick)
  }
  graph.node.viewport.style('cursor', graph.isSelectionAvailable ? 'pointer' : 'default')

  function mouseMove(event) {
    if (!graph.isSelectionAvailable) {
      return
    }
    const node = graph.node
    clearTimeout(graph.selectionMouseOutTimeout)
    const coords = d3.pointer(event, node.viewport.node())
    const domain = node.x.domain()

    /// https://observablehq.com/@d3/d3-scaleband#cell-402
    const leftPad = node.x.step() * node.x.paddingOuter() * node.x.align() * 2
    const index = Math.floor(((coords[0] - leftPad) / node.x.step()))
    const x = domain[Math.max(0, Math.min(index, domain.length - 1))]

    if (x && x !== graph.selectionLatestX) {
      const selectionData = [{ x }]
      const selection = node.selection.selectAll('.bar')
        .data(selectionData)
      selection
        .enter().append('rect')
        .attr('class', 'bar')
        .style('opacity', 0.2)
        .merge(selection)
        .attr('x', d => node.x(d.x) - node.x.paddingInner() * node.x.bandwidth() / 2)
        .attr('y', 0)
        .attr('width', node.x.bandwidth() + node.x.paddingInner() * node.x.bandwidth())
        .attr('height', graph.viewport.innerHeight)
      selection.exit()
        .remove()

      graph.selectionLatestX = x
    }
  }

  function mouseOut() {
    graph.selectionMouseOutTimeout = setTimeout(() => {
      graph.selectionLatestX = null
      graph.node.selection.selectAll('.bar').remove()
    }, 200)
  }

  function mouseClick() {
    if (graph.selectionLatestX) {
      clickFn(graph.selectionLatestX)
    }
  }
}

// Ensure the viewport won't be too big by filtering data to only show a window
const TRUNCATION_WINDOW_LENGTH = 14

export function needsTruncation(graph) {
  return graph && graph.truncation && (graph.truncation.infinite || graph.truncation.total > TRUNCATION_WINDOW_LENGTH)
}

export function canTruncatePrevious(graph, offset) {
  return graph && graph.truncation && (graph.truncation.infinite || offset > 0)
}

export function canTruncateNext(graph, offset) {
  return graph && graph.truncation && (graph.truncation.infinite || offset + TRUNCATION_WINDOW_LENGTH < graph.truncation.total)
}

export function truncateModel(graph, offset) {
  if (needsTruncation(graph)) {
    graph.data = graph.data.slice(offset, offset + TRUNCATION_WINDOW_LENGTH)
  }
}
