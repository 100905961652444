import i18n from '@/i18n'

// Sorted by french name
export const AVAILABLE_CURRENCIES = [
  'USD',
  'CAD',
  'EUR',
  'CHF',
  'HRK',
  'BGN',
  'RON',
  'GBP'
]

// Available options https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
// Ex: minimumFractionDigits, maximumFractionDigits, signDisplay...
export function formatCurrency(value, decimals = false, options = {}) {
  let result = i18n.n(value || 0, {
    style: 'currency',
    currencyDisplay: 'symbol',
    currency: i18n.currency,
    signDisplay: options.signDisplay || 'auto',
    minimumFractionDigits: options.minimumFractionDigits || 0,
    maximumFractionDigits: Math.max(
      options.minimumFractionDigits || 0,
      options.maximumFractionDigits || (decimals ? 2 : 0)
    )
  })
  // Workaround of missing narrowSymbol on Safari < 14 (https://caniuse.com/?search=currencyDisplay)
  if (i18n.locale === 'fr' && i18n.currency !== 'EUR') {
    result = result.replace('US', '').replace('GB', '').replace('CA', '')
  }
  return result
}

export function isCurrency(text) {
  return text && text.includes(i18n.currencySymbol)
}

export function getCurrencySymbol(currency) {
  return i18n.n(0, {
    style: 'currency',
    locale: 'en-US',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).split('0').map(t => t.trim()).join('')
}
