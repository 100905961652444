import api from '@/store/api'
import { flattenedComponents, getVisibleLevels, getWageDetails, getWagesDetailsSummary } from '@/utils/grid'
import cloneDeepWith from 'lodash.clonedeepwith'

const initialState = () => {
  return {
    currentGrid: null,
    displayedGrid: null,
    companyGrids: [],
    isLoadingGrid: false,
    simulationWage: null
  }
}

const state = initialState()

const getters = {
  isLoadingGrid(state) {
    return state.isLoadingGrid
  },
  getCurrentGrid(state) {
    return state.currentGrid
  },
  getReferenceGrid(_state, { getCurrentGrid }, _rootState, rootGetters) {
    return getCurrentGrid || rootGetters['sandbox/onboardingGrid']
  },
  hasGrid(state) {
    return !!state.currentGrid
  },
  getCompanyGrids(state) {
    return state.companyGrids
  },
  getDisplayedGrid(state) {
    return state.displayedGrid
  },
  getEmployeeWagesSummary(state, _getters, _rootState, rootGetters) {
    const summaries = rootGetters['employees/getEmployees']
      .filter(employee => employee.currentWage)
      .map(employee => (
        getWageDetails(state.currentGrid, employee.currentWage, { salaryOnly: true, includeVariable: true }).summary
      ))

    return getWagesDetailsSummary(summaries)
  },
  getPayroll(_state, { getEmployeeWagesSummary }) {
    if (getEmployeeWagesSummary) {
      return getEmployeeWagesSummary.salary.value
    }
  },
  getJobOfferWagesSummary(state, getters, rootState, rootGetters) {
    if (state.currentGrid) {
      const summaries = rootGetters['candidates/getJobOffers'].map(jobOffer => {
        return jobOffer.referenceWage ? getWageDetails(state.currentGrid, jobOffer.referenceWage).summary : null
      })
        .filter(s => s != null)

      return getWagesDetailsSummary(summaries)
    }
  },
  getTotalWagesSummary(state, getters, rootState, rootGetters) {
    if (state.currentGrid) {
      const summaries = rootGetters['employees/getEmployees'].map(employee => {
        if (employee.currentWage) {
          return getWageDetails(state.currentGrid, employee.currentWage, {
            salary: employee.initialSalaryValue,
            equity: employee.initialBonusValue,
            bonus: employee.initialBonusValue
          }).summary
        }

        return null
      })
        .concat(rootGetters['candidates/getJobOffers'].map(jobOffer => {
          return jobOffer.referenceWage ? getWageDetails(state.currentGrid, jobOffer.referenceWage).summary : null
        }))
        .filter(s => s != null)

      return getWagesDetailsSummary(summaries)
    }
  },
  allComponents({ currentGrid }) {
    return currentGrid ? flattenedComponents(currentGrid.components) : []
  },
  getComponent(state) {
    return (componentId) => {
      return state.displayedGrid.components.find(c => c.id === componentId)
    }
  },
  getSimulationWage(state, _getters, _rootState, rootGetters) {
    const { currentWage } = rootGetters['employees/getOneself']
    return state.simulationWage || currentWage
  }
}

const actions = {
  reset(context) {
    context.commit('reset')
  },
  getCurrentGrid(context) {
    return api.get('/grid/current')
      .then(response => {
        context.commit('setCurrentGrid', response.data.currentGrid)
        context.commit('setDisplayedGrid', response.data.currentGrid)
        context.commit('setCompanyGrids', response.data.companyGrids)
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  getDisplayedGrid(context, id) {
    context.commit('setIsLoadingGrid', true)

    return context.dispatch('getGrid', id)
      .then(grid => {
        context.commit('setDisplayedGrid', grid)
        context.commit('setIsLoadingGrid', false)
      })
      .catch(error => {
        context.commit('setIsLoadingGrid', false)
        return error
      })
  },
  getGrid(context, id) {
    if (state.currentGrid && state.currentGrid.id === id) {
      return state.currentGrid
    }
    else if (state.displayedGrid && state.displayedGrid.id === id) {
      return state.displayedGrid
    }
    return api.get('/grid/' + id)
      .then(({ data }) => {
        return data
      })
      .catch(error => {
        return context.dispatch('handleAPIError', error, { root: true })
      })
  },
  setSimulationWage({ commit, getters }, wage) {
    const grid = getters.getCurrentGrid
    if (grid && wage && wage.gridId === grid.id) {
      commit('setSimulationWage', wage)
    }
  },
  setSimulationWageLevel({ commit, getters }, level) {
    const grid = getters.getCurrentGrid
    const wage = getters.getSimulationWage
    // Replace wage levels by new level
    if (grid && wage && level) {
      const wageDetails = getWageDetails(grid, wage)
      const levelIds = wageDetails.selectedComponents.reduce((memo, component) => {
        let { selectedLevel } = component
        if (component.id === level.componentId) {
          memo.push(level.id)
        }
        else if (selectedLevel) {
          const originalComponent = flattenedComponents(grid.components).find(c => c.id === component.id)
          if (originalComponent && selectedLevel.linkedLevelId && !memo.includes(selectedLevel.linkedLevelId)) {
            selectedLevel = getVisibleLevels(originalComponent.levels)
              .find(l => memo.includes(l.linkedLevelId))
          }
          if (selectedLevel) {
            memo.push(selectedLevel.id)
          }
        }
        return memo
      }, [])
      commit('setSimulationWage', { ...wage, levelIds })
    }
  },
  updateGrid({ dispatch, getters }) {
    dispatch('sandbox/get', getters.getCurrentGrid.id, { root: true })
  }
}

const mutations = {
  reset(state) {
    Object.assign(state, initialState())
  },
  setCurrentGrid(state, grid) {
    state.currentGrid = grid
    state.simulationWage = null
  },
  setCompanyGrids(state, companyGrids) {
    state.companyGrids = companyGrids
  },
  setDisplayedGrid(state, grid) {
    state.displayedGrid = grid
  },
  setIsLoadingGrid(state, isLoadingGrid) {
    state.isLoadingGrid = isLoadingGrid
  },
  setSimulationWage(state, wage) {
    state.simulationWage = cloneDeepWith(wage)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
