import marked from 'marked'
import { sanitize, addHook } from 'dompurify'

// Add target="_blank" to links
const renderer = new marked.Renderer()
renderer.link = (href, title, text) => {
  return `<a target="_blank" href="${href}" title="${title}">${text}</a>`
}

marked.use({ renderer })

// Keep target during sanitize
// https://github.com/cure53/DOMPurify/issues/317
addHook('afterSanitizeAttributes', (node) => {
  if ('target' in node) {
    node.setAttribute('target', '_blank')
    node.setAttribute('rel', 'noopener')
  }
})

export default function(text) {
  return sanitize(marked(text, {
    renderer
  }))
};
