<template>
  <div class="job-wrapper" v-if="job">
    <div class="job-container">
      <header>
        <!-- eslint-disable-next-line no-irregular-whitespace -->
        <h1>👷‍♀️  {{job.job}}</h1>
        <aside>
          <label v-t="'benchmark.job.averageAnnualSalary'"></label>
          <br>
          <h2 v-if="averageSalary > 0">
            {{averageSalary / 1000 | formattedNumber}}<small>&nbsp;k{{$i18n.currencySymbol}}</small>
          </h2>
          <h2 v-else v-t="'benchmark.job.unavailable'">
          </h2>
          <dropdown :items="regionItems" @change="setRegion">
            {{getRegionName(selectedRegion)}}
          </dropdown>
        </aside>
        <div>
          <salaries-graph v-if="averageSalary > 0" :job="currentSalary"></salaries-graph>
        </div>
      </header>
      <main>
        <p v-html="job.description"></p>
        <p>
          <strong v-t="'benchmark.job.csp'"></strong>
          {{job.group}}.
        </p>
        <p>
          <strong v-t="'benchmark.job.profession'"></strong>
          {{job.profession}}
          (<a
            :href="`https://insee.fr/fr/metadonnees/pcsese2017/rubriqueRegroupee/${job.professionCode}`"
            target="_blank"
            class="hover-link">{{job.professionCode}}</a>).
        </p>
        <div v-if="relatedJobs.length">
          <strong v-t="'benchmark.job.relatedJobs'"></strong>
          <div class="multi-columns" v-if="displayAllRelatedJobs || relatedJobs.length <= 3">
            <div
              v-for="relatedJob in relatedJobs"
              :key="relatedJob.id"
              @click="$router.push('/tools/jobs/' + relatedJob.id)"
              class="hover-link"
              :class="{bold: job.id == relatedJob.id}">
              {{relatedJob.job}}
            </div>
            <a class="link" @click="toggleDisplayAllRelatedJobs" v-t="'benchmark.job.hide'"></a>
          </div>
          <template v-else>
            <span
              v-for="(relatedJob, i) in relatedJobs"
              :key="relatedJob.id"
              v-if="i < 3"
              class="related-job">{{relatedJob.job}}</span>…
            &nbsp;
            <a class="link" @click="toggleDisplayAllRelatedJobs" v-t="'common.showAll'"></a>
          </template>
        </div>
        <p>
          <strong v-t="'benchmark.job.sources'"></strong>
          <br>
          <label v-html="$t('benchmark.job.sourcesList')"></label>
        </p>
      </main>
    </div>
  </div>
  <loading-view v-else></loading-view>
</template>

<script>
import Dropdown from '@components/commons/Dropdown.vue'
import LoadingView from '@components/commons/LoadingView'
import SalariesGraph from '@components/graph/SalariesGraph.vue'
import { slugify } from '@/utils/string'
import camelCase from 'lodash.camelcase'

export default {
  data() {
    return {
      job: null,
      displayAllRelatedJobs: false,
      selectedRegion: this.$store.getters['jobs/getDefaultRegion']
    }
  },
  methods: {
    toggleDisplayAllRelatedJobs() {
      this.displayAllRelatedJobs = !this.displayAllRelatedJobs
    },
    setRegion(_, value) {
      this.selectedRegion = value
    },
    getRegionName(region) {
      return this.$t('benchmark.regions.' + camelCase(slugify(region)))
    },
    loadCurrentJob(jobId) {
      this.$store.commit('jobs/setCurrentJob', {})
      this.$store.dispatch('jobs/getJob', jobId)
        .then(job => {
          this.job = job
          this.$store.commit('jobs/setCurrentJob', job)
        })
        .catch(() => {
          this.$router.push('/tools/jobs')
        })
    }
  },
  computed: {
    relatedJobs() {
      return this.$store.getters['jobs/getRelatedJobs'](this.job.id)
    },
    regionItems() {
      return [{
        name: 'France',
        items: this.$store.getters['jobs/getRegions'].map(r => {
          let stats
          if (this.job) {
            const salary = this.job.salaries.find(s => s.region === r)
            if (salary && salary.minimumSalary > 0) {
              stats = this.$options.filters.formattedNumber(
                (salary.minimumSalary + salary.maximumSalary) / 2
              ) + ' k' + this.$i18n.currencySymbol
            }
            else {
              stats = '—'
            }
          }
          return {
            value: r,
            name: this.getRegionName(r),
            label: stats,
            selected: r === this.selectedRegion
          }
        })
      }]
    },
    currentSalary() {
      return this.job.salaries.find(s => s.region === this.selectedRegion)
    },
    averageSalary() {
      return (this.currentSalary.minimumSalary + this.currentSalary.maximumSalary) / 2
    }
  },
  mounted() {
    this.loadCurrentJob(this.$route.params.id)
  },
  beforeRouteUpdate(to, from, next) {
    this.loadCurrentJob(to.params.id)
    next()
  },
  components: {
    Dropdown,
    LoadingView,
    SalariesGraph
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/link.scss";
@import "./src/styles/form.scss";

$padding: 20px;
$gradient-start: rgba(39, 85, 122, 1);
$gradient-end: rgba(47, 105, 152, 1);

.job-container {
  border-radius: $border-radius;
  background: $background-color;
  overflow: hidden;
  margin-top: 1em;

  header {
    background: #22537d;
    background: linear-gradient(90deg, $gradient-start 0%, $gradient-end 100%);
    color: white;
    padding: $padding;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
    display: grid;
    grid-template-columns: 1fr 225px 225px;
    align-items: center;
    min-height: 140px;
  }

  aside {
    text-align: center;
    padding: 0 0.5em;
  }

  small {
    font-size: 0.8em;
  }

  main {
    border: $border;
    border-top: none;
    padding: $padding;
  }
}

h1,
h2 {
  margin-bottom: 0;
}

.multi-columns {
  column-count: 3;
}

.related-job:not(:last-of-type):after {
  content: ", ";
}

div::v-deep strong {
  margin-bottom: 0.25em;
  display: inline-block;
}

.bold {
  @include font-semibold;
}

.dropdown {
  text-shadow: none;
}

.dropdown::v-deep .dropdown-button {
  background: rgba(255, 255, 255, 0.05);
  height: 26px;
  line-height: 26px;
  color: white;
  padding: 0 10px;
  margin-top: 4px;

  &:hover,
  &:focus {
    background: rgba(255, 255, 255, 0.1);
    border-color: white;
    color: inherit !important;
  }
}

.dropdown::v-deep span.dropdown-content {
  border: 1px solid $border-color;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  outline: none;
}

.dropdown::v-deep .dropdown-item {
  color: $text-color;
}

.dropdown::v-deep .right-label {
  color: $gradient-end;
  @include font-semibold;
}
</style>
