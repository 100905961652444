<template>
  <div>
    <div class="header">
      <div
        v-for="(tab, i) in tabs"
        :key="tab"
        @click="click(tab)"
        :class="{
          'tab': true,
          'active': activeTab == tab,
          'completed': progressIndex > i}">
        <div class="inner">{{ tab }}</div>
      </div>
    </div>
    <div class="border"></div>
  </div>
</template>

<script>
export default {
  props: ['tabs', 'activeTab', 'progressIndex'],
  methods: {
    click(tab) {
      this.$emit('tabSelected', tab)
    }
  }
}
</script>

<style lang="scss" scoped>

.header {
  background: $background-color;
  counter-reset: tabs;
}

.tab {
  display: inline-block;
  position: relative;
  width: 22%;
  padding: 10px 0;
  text-align: center;
  background-color: white;
  border-top: $border;
  border-right: $border;
  border-bottom: $border;
  border-radius: 0;
  background-image: linear-gradient(
    to top,
    lighten($background-color, 1.5),
    white
  );
  counter-increment: tabs;

  &:hover {
    color: $clearteal-color;
  }

  &:not(.active) {
    cursor: pointer;
  }

  .inner {
    max-width: 160px;
    margin: auto;
    position: relative;

    &:before {
      content: counter(tabs);
      float: left;
      width: 22px;
      line-height: 22px;
      border-radius: 14px;
      border: 1px solid lighten($clearteal-color, 5);
      margin-top: 6px;
      color: lighten($clearteal-color, 5);
      @include font-bold;
      @include font-alternate-digits;
      margin-right: 1em;
    }
  }

  &.completed .inner {
    &:before {
      content: '\00a0';
    }

    &:after {
      display: inline-block;
      content: '';
      position: absolute;
      left: 8px;
      top: 11px;
      background: transparent;
      width: 4px;
      height: 8px;
      border-bottom: 3px solid lighten($clearteal-color, 5);
      border-right: 3px solid lighten($clearteal-color, 5);
      border-radius: 1px;
      box-shadow: 1px 1px 0 rgba($clearteal-color, .1);
      transform: rotate(45deg);
    }
  }

  &:not(.active):not(.completed) .inner {
    &:before,
    &:after {
      opacity: 0.7;
    }
  }

  &.active {
    color: $clearteal-color;
    background: white;
    @include font-semibold;
    border-bottom-color: white;
  }

  &:first-child {
    margin-left: 0;
    border-radius: $border-radius 0 0 0;
    border-left: $border;
  }

  &:last-of-type {
    border-radius: 0 $border-radius 0 0;
  }

  // Arrow
  &:not(:first-of-type) {
    &:after,
    &:before {
      top: 33%;
      left: 0;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-width: 10px;
    }

    &:after {
      border-left-color: lighten($background-color, 3);
      margin-left: -1px;
    }
    &:before {
      border-left-color: $border-color;
    }
  }
}

.border {
  margin-top: -1px;
  height: 4px;
  border-top: $border;
  border-left: $border;
  border-right: $border;
  background-color: white;
  border-radius: 0 $border-radius 0 0;
}

// Hide arrow on mobile layout
@media screen and (max-width: $content-max-width * 0.85) {
  .tab:before,
  .tab:after {
    display: none;
  }
}
</style>
