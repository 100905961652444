<template>
  <div class="wrapper">
    <svg :height="viewport.height">
      <g :style="{transform: `translate(${viewport.padding.left}px, ${viewport.padding.top}px)`}">
        <g class="axis abscissa">
          <line x1="0" :x2="`${viewport.innerWidth}px`"
            :y1="`${viewport.innerHeight}px`" :y2="`${viewport.innerHeight}px`" />
          <text
            v-for="label in abscissaLabels"
            :key="label.label"
            :data-label="label.label"
            :x="`${label.x}px`"
            :y="`${label.y}px`">{{label.label}}</text>
        </g>
        <g class="data paths" v-if="animatedPaths.length">
          <path
            v-for="path in animatedPaths"
            :key="path.class"
            :d="path.d"
            :class="path.class"
            fill="red"></path>
        </g>
        <g class="axis ordinate">
          <text
            class="ordinate-label"
            v-for="label in ordinateLabels"
            :key="label.label"
            :x="`${label.x}px`"
            :y="`${label.y}px`">{{label.label}}</text>
          <line
            v-for="tick in ordinateTicks"
            :key="tick.y"
            x1="0"
            :x2="`${viewport.innerWidth}px`"
            :y1="`${tick.y}px`"
            :y2="`${tick.y}px`" />
          <text
            class="tick-label"
            v-for="tick in ordinateTicks"
            :key="tick.label"
            x="0px"
            :y="`${tick.y}px`">{{tick.label}}</text>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
import { interpolatePath } from 'd3-interpolate-path'
import {
  generateSalariesGraphModel,
  generateSalariesAbscissaLabels,
  generateSalariesOrdinateLabels,
  generatePaths,
  wrapLabels
} from '@/utils/graph'

export default {
  name: 'data-explorer',
  props: ['job'],
  data() {
    return {
      // Options
      axes: {
        abscissa: {
          type: 'continuous',
          style: 'none',
          labels: 'belowAxis',
          format: '$~s'
        },
        ordinate: {
          type: 'continuous',
          style: 'none',
          labels: 'aboveMax',
          format: '$~s'
        }
      },
      viewport: {
        width: 0, // Computed
        height: 100,
        innerWidth: 0, // Computed
        innerHeight: 0, // Computed
        padding: {
          left: 20,
          right: 20,
          top: 12,
          bottom: 20
        }
      },
      // Data
      bars: [],
      badges: [],
      paths: [],
      animatedPaths: [],
      animatedInterval: null,
      abscissaLabels: [],
      ordinateLabels: [],
      ordinateTicks: []
    }
  },
  mounted() {
    window.addEventListener('resize', this.render)
    this.render()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.render)
  },
  watch: {
    job: 'render',
    index: 'render',

    paths: function() {
      if (this.paths.length && !this.animatedPaths.length) {
        this.animatedPaths = this.paths
      }
      else {
        clearInterval(this.animatedInterval)
        this.animatedPaths = this.paths.map((path, i) => {
          const oldPath = this.animatedPaths[i]

          return {
            class: path.class,
            d: oldPath.d,
            i: 0,
            interpolator: interpolatePath(oldPath.d, path.d)
          }
        })
        this.animatedInterval = setInterval(() => {
          this.animatedPaths.forEach(path => {
            if (path.i < 1) {
              path.d = path.interpolator(path.i += 0.05)
            }
            else {
              clearInterval(this.animatedInterval)
            }
          })
        }, 20)
      }
    }
  },
  methods: {
    render() {
      let graph
      this.viewport.width = this.$el.offsetWidth

      if (this.job) {
        graph = generateSalariesGraphModel({ job: this.job }, this.axes, this.viewport)
        this.abscissaLabels = generateSalariesAbscissaLabels(graph, this.job)
        this.ordinateLabels = generateSalariesOrdinateLabels(graph, this.job)
        this.paths = generatePaths(graph)
      }

      this.$nextTick(() => {
        wrapLabels(graph, this.$el)
      })
    }
  }
}

</script>

<style lang="scss" scoped>
.wrapper {
  font-size: 0;
}

svg {
  @include font-normal-size;
  border-radius: 3px;
  width: 100%;
}

text {
  @include font-small-size;
  fill: white;
  font-smoothing: antialiased;
}

.axis text {
  text-anchor: middle;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
  @include font-semibold;
}

.axis.ordinate .ordinate-label {
  fill: white;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  @include font-bold;
  transition: x 0.4s ease;
}

.axis.ordinate line {
  stroke: lighten($gray-color, 10);
  stroke-dasharray: 2 2;
}

.axis.abscissa text {
  opacity: 0.9;
}

.axis.abscissa line {
  stroke: rgba(255, 255, 255, 0.2);
}

.paths path {
  fill: transparent;
  stroke: white;
  stroke-width: 2px;
}

.paths path.area {
  fill: rgba(255, 255, 255, 0.2);
  stroke-width: 0;
}
</style>
