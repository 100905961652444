<template>
  <div class="summary" v-if="usersLoaded">
    <span
      class="badge-block clickable"
      :class="{active: currentFilter === 'users'}"
      @click="$emit('select', 'users')">
      <label v-t="'dashboard.synthesis.employees'"></label>
      <span class="badge border-badge">
        {{ users.length }}
      </span>
    </span>
    <span
      class="badge-block clickable"
      :class="{active: currentFilter === 'usersWithAccount'}"
      @click="$emit('select', 'usersWithAccount')">
      <label v-t="'settings.settingsCompanyAccessRights.usersWithAccount'"></label>
      <span class="badge border-badge">
        {{ usersWithAccount.length }}
      </span>
    </span>
    <span
      v-if="pendingInvitations.length"
      class="badge-block clickable"
      :class="{active: currentFilter === 'pendingInvitations'}"
      @click="$emit('select', 'pendingInvitations')">
      <label v-t="'settings.settingsCompanyAccessRights.pendingInvitations'"></label>
      <span class="badge border-badge">
        {{ pendingInvitations.length }}
      </span>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['currentFilter'],
  computed: {
    ...mapGetters('company', {
      usersLoaded: 'usersLoaded',
      pendingInvitations: 'pendingInvitations',
      users: 'users',
      usersWithAccount: 'usersWithAccount'
    })
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/badge.scss";

.summary {
  @include font-small-size;
  margin-bottom: 1em;
}

.badge-block {
  padding: 0.4em 0.4em 0.4em 0.8em;
  margin-right: 15px;
}
</style>
