<template>
  <div class="copy-input">
    <input
      type="text"
      :value="value"
      ref="url"
      :style="`width: ${width}px`"
      :readonly="true">
    <button
      type="button"
      class="primary"
      :class="isCopied && 'bump'"
      @click="copy"
      v-html="copyLabel"></button>
  </div>
</template>

<script>
import copy from 'copy-to-clipboard'

export default {
  props: {
    width: {
      type: Number,
      default: 320
    },
    value: {
      type: String
    }
  },
  data() {
    return {
      isCopied: false
    }
  },
  computed: {
    copyLabel() {
      return this.isCopied ? this.$t('common.copied') : this.$t('common.copy')
    }
  },
  methods: {
    copy() {
      copy(this.value)
      this.isCopied = true
      setTimeout(() => {
        this.isCopied = false
      }, 4000)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";

.copy-input {
  display: inline-block;
}

input[type="text"] {
  display: inline-block;
}

button {
  margin-left: 0.6rem;
}
</style>
