<template>
  <div class="visitor-container long signup">
    <div class="center">
      <img
        class="visitor-logo"
        src="~@/assets/logo.svg"
        alt="Clearwage"
        @click="$router.push('/')">
    </div>
    <div class="visitor-form">
      <header class="visitor-form-header" v-html="headerLabel" />
      <div class="visitor-form-content">
        <form @submit.prevent="signUp()">
          <div class="sides">
            <div>
              <div class="input-label" v-t="'account.firstName'"></div>
              <input
                type="text"
                v-model="signupParams.firstName"
                :placeholder="$t('account.firstNamePlaceholder')"
                :class="{'input-error' : $v.signupParams.firstName.$error}">
            </div>
            <div>
              <div class="input-label" v-t="'account.lastName'"></div>
              <input
                type="text"
                v-model="signupParams.lastName"
                :placeholder="$t('account.lastNamePlaceholder')"
                :class="{'input-error' : $v.signupParams.lastName.$error}">
            </div>
          </div>
          <hr>
          <div class="sides">
            <div>
              <div class="input-label" v-t="'account.companyName'"></div>
              <input
                type="text"
                v-model="signupParams.company.name"
                :placeholder="$t('account.companyNamePlaceholder')"
                :class="{'input-error' : $v.signupParams.company.name.$error}">
            </div>
            <div>
              <div class="input-label" v-t="'account.invitationCode'"></div>
              <input
                type="text"
                v-model="signupParams.invitationCode"
                :placeholder="$t('account.invitationCodePlaceholder')"
                :class="{'input-error' : $v.signupParams.invitationCode.$error}">
            </div>
          </div>
          <hr>
          <div class="sides">
            <div>
              <div class="input-label" v-t="'account.email'"></div>
              <input
                type="text"
                v-model="signupParams.email"
                :placeholder="$t('account.emailPlaceholder')"
                :class="{'input-error' : $v.signupParams.email.$error}">
            </div>
            <div>
              <div class="input-label" v-t="'account.password'"></div>
              <input
                type="password"
                v-model="signupParams.password"
                :placeholder="$t('account.passwordPlaceholder')"
                :class="{'input-error' : $v.signupParams.password.$error}">
            </div>
          </div>
          <div class="terms">
            <Checkbox
              v-model="termsChecked">
              <span v-html="$t('account.terms', {url: $t('urls.terms')})" :class="{'terms-error' : $v.termsChecked.$error}" />
            </Checkbox>
          </div>
          <button class="primary" :disabled="isLoading">{{ signupButtonText }}</button>
          <resizable-panel :observer="errorMessage">
            <div class="error-message left" v-if="errorMessage">{{ errorMessage }}</div>
          </resizable-panel>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from '@components/commons/Checkbox'
import ResizablePanel from '@components/commons/ResizablePanel.vue'
import { required, email } from 'vuelidate/lib/validators'

export default {
  components: {
    Checkbox,
    ResizablePanel
  },
  data() {
    return {
      signupParams: {
        firstName: '',
        lastName: '',
        company: {
          name: ''
        },
        email: '',
        password: '',
        invitationCode: ''
      },
      termsChecked: false,
      errorMessage: '',
      isLoading: false
    }
  },
  created() {
    this.signupParams.invitationCode = this.$route.query.code
  },
  methods: {
    signUp() {
      this.$v.$touch()

      if (!this.$v.$error) {
        this.errorMessage = null
        this.isLoading = true

        this.$store.dispatch('account/signUp', this.signupParams)
          .then(response => {
            this.$router.push('/')
          })
          .catch(error => {
            this.errorMessage = error || this.$t('account.wrongInvitationCode')
            this.isLoading = false
          })
      }
    }
  },
  computed: {
    headerLabel() {
      return this.$t('account.signup.pleaseEnterDetails')
    },
    signupButtonText() {
      return this.$t(this.isLoading ? 'account.signingUp' : 'account.signup.title')
    }
  },
  validations: {
    signupParams: {
      firstName: { required },
      lastName: { required },
      company: { name: { required } },
      invitationCode: { required },
      email: { required, email },
      password: { required }
    },
    termsChecked: {
      required: (value) => value === true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/visitor.scss";

.terms {
  text-align: left;
  @include font-small-size;
  margin-top: -10px;
  margin-bottom: 20px;
}

.terms-error {
  color: $red-color;
}

.sides {
  margin: 20px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  &:last-of-type {
    margin-bottom: 30px;
  }
}

hr {
  display: block;
  border: 0;
  border-top: $border;
  height: 1px;
  margin: 0 auto;
}
</style>
