<template>
  <div class="employees-badges">
    <template v-if="(employeesCount > 0 || !placeholder) && employeesCount <= limit">
      <transition-group name="badges" tag="div">
        <name-thumbnail
          class="blue"
          :class="{clickable}"
          v-for="employee in employees"
          :key="employee.id"
          :employee="employee"
          :inline="true"
          @click.native="openEmployees([employee])" />
      </transition-group>
    </template>
    <span
      class="group-badge"
      :class="{clickable}"
      v-else-if="employeesCount > 0"
      @click="openEmployees(employees)">
      {{ $tc(`grid.employees`, employeesCount, { count: employeesCount }) }}
    </span>
    <span
      class="group-badge"
      v-else-if="placeholder">
      {{ $tc(`grid.employees`, employeesCount, { count: employeesCount }) }}
    </span>
  </div>
</template>

<script>
import NameThumbnail from '@components/commons/NameThumbnail'

export default {
  components: {
    NameThumbnail
  },
  props: {
    employees: Array,
    limit: {
      type: Number,
      default: 3
    },
    placeholder: {
      type: Boolean,
      default: false
    },
    clickable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    employeesCount() {
      return this.employees.length
    }
  },
  methods: {
    openEmployees(employees) {
      if (this.clickable) {
        const query = employees.map(e => [e.firstName, e.lastName].join(' ')).join(', ')
        this.$router.push({ name: 'employees', params: { query } })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.employees-badges {
  white-space: nowrap;

  .thumbnail.inline {
    opacity: 0.9;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.05);
  }

  .thumbnail.inline:not(:last-of-type) {
    margin-right: 0.5em;
  }

  .group-badge {
    @include font-small-size;
    @include font-semibold;
    background: darken($graph-lightblue-color, 2);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.05);
    line-height: 2em;
    padding: 0 0.85em;
    border-radius: 2em;
    display: inline-block;
    opacity: 0.9;
  }

  .clickable {
    cursor: pointer;

    .thumbnail.inline:hover,
    .group-badge:hover {
      opacity: 1;
      background: darken($graph-blue-color, 2);
    }
  }
}

.badges-enter-active,
.badges-leave-active {
  transition: all 400ms;
}
.badges-enter,
.badges-leave-to {
  transform: scale(0);
  opacity: 0;
  margin-right: -24px !important;
}

// Small skins
.employees-badges.small {
  .group-badge {
    @include font-smaller-size;
  }

  .thumbnail.inline {
    @include font-smaller-size;
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
}
</style>
