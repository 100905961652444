<template>
  <span class="checkbox">
    <input
      type="checkbox"
      :id="_uid"
      :disabled="disabled"
      :class="{labeled: hasLabel}"
      v-model="checkedModel"
      :value="value"
      @change="onChange" />
    <label :for="_uid">
      <slot></slot>
    </label>
  </span>
</template>

<script>
export default {
  props: ['value', 'checked', 'disabled'],
  model: {
    prop: 'checked',
    event: 'change'
  },
  data() {
    return {
      checkedModel: this.checked
    }
  },
  computed: {
    hasLabel() {
      return this.$slots.default
    }
  },
  methods: {
    onChange() {
      this.$emit('change', this.checkedModel)
    }
  },
  watch: {
    checked() {
      this.checkedModel = this.checked
    }
  }
}
</script>

<style lang="scss" scoped>
$checkbox-color: lighten($graph-darkblue-color, 22);
$green-checkbox-color: $clearteal-color;

@keyframes check {
  from {
    transform: rotate(45deg) scale(1.5);
    opacity: 0.9;
  }
  to {
    transform: rotate(45deg) scale(1);
    opacity: 1;
  }
}

// Prevent label selection on double click
.checkbox:hover {
  user-select: none;
}

input[type="checkbox"] {
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    vertical-align: middle;
  }

  &.labeled + label {
    display: inline-grid;
    grid-template-columns: 30px 1fr;
  }

  // circle
  & + label:before {
    content: "";
    margin-right: 0.5em;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid $graph-blue-color;
    border-radius: 10px;
    background: white;
    box-sizing: border-box;
    vertical-align: middle;
  }

  // Box hover + focus
  &:hover + label:before,
  &:focus + label:before {
    box-shadow: 0 0 0 2px lighten($checkbox-color, 30);
    background: lighten($graph-lightblue-color, 2);
  }

  // Box hover + focus when checked
  &:not(:disabled):checked:hover + label:before,
  &:not(:disabled):checked:focus + label:before {
    box-shadow: 0 0 0 2px lighten($checkbox-color, 25);
  }

  // Box checked
  &:checked + label:before {
    border-color: transparent;
    background: $checkbox-color;
  }

  // Disabled state label
  &:disabled + label {
    cursor: auto;
  }

  // Disabled box
  &:disabled + label:before {
    box-shadow: none;
  }

  &:disabled:checked + label:before {
    background: lighten(desaturate($checkbox-color, 10), 20);
  }

  // Checkmark
  &:checked + label:after {
    display: inline-block;
    vertical-align: middle;
    content: "";
    position: absolute;
    left: 6.5px;
    top: 4px;
    background: transparent;
    width: 4px;
    height: 8px;
    border-bottom: 3px solid white;
    border-right: 3px solid white;
    border-radius: 1px;
    box-shadow: 1px 1px 0 rgba($graph-darkblue-color, 0.3);
    transform: rotate(45deg);
  }

  // Only animate when interacting with the input
  &:focus:checked + label:after {
    animation: check 200ms ease-out;
  }
}

// Green skin
.checkbox.green {
  input[type="checkbox"] {
    &:hover + label:before,
    &:focus + label:before {
      box-shadow: 0 0 0 2px lighten($green-checkbox-color, 40);
      background: lighten($green-checkbox-color, 60);
    }

    &:checked + label:before {
      background: $green-checkbox-color;
    }
  }
}

// Red skin
.checkbox.red {
  label {
    @include font-semibold;
    color: darken($red-color, 10);
  }

  input[type="checkbox"] {
    & + label:before {
      border: 1px solid lighten($red-color, 25);
    }

    &:hover + label:before,
    &:focus + label:before {
      box-shadow: 0 0 0 2px lighten($red-color, 40);
      background: lighten($red-color, 60);
    }

    &:checked + label:before {
      background: $red-color;
    }
  }
}
</style>
