import Vue from 'vue'
import api from '@/store/api'
import sortBy from 'lodash.sortby'
import { sequentialDispatch } from '@/utils/store'
import uniq from 'lodash.uniq'
import groupBy from 'lodash.groupby'

const initialState = () => {
  return {
    teams: []
  }
}

const state = initialState()

const getters = {
  getTeams(state) {
    return state.teams
  },
  getTeamMembers(_state, _getters, _rootState, rootGetters) {
    return team => {
      return team.memberIds.map(employeeId => {
        return rootGetters['employees/getEmployee'](employeeId)
      }).filter(e => !!e)
    }
  },
  getTeamAdmins(_state, _getters, _rootState, rootGetters) {
    return team => {
      return team.adminIds.map(employeeId => {
        return rootGetters['employees/getEmployee'](employeeId)
      }).filter(e => !!e)
    }
  },
  getSuggestedTeams(_state, getters, _rootState, rootGetters) {
    if (!getters.getTeams.length) {
      const employeesWithDepartment = rootGetters['employees/getEmployees'].filter(e => e.department)
      const departments = uniq(employeesWithDepartment.map(e => e.department)).sort()
      if (departments.length) {
        return departments
      }
    }
  }
}

const actions = {
  getTeams(context) {
    return api.get('/teams')
      .then(({ data }) => {
        context.commit('setTeams', data)
        return data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  createTeam(context, team) {
    return api.post('/team', team)
      .then(({ data }) => {
        context.commit('addTeam', data)
        return data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  createTeamWithMembres(context, params) {
    return context.dispatch('createTeam', params).then(team => {
      const employees = params.employees
      return context.dispatch('updateTeamMembers', { team, employees })
    })
  },
  createSuggestedTeams(context) {
    const employeesWithDepartment = context.rootGetters['employees/getEmployees'].filter(e => e.department)
    const groupedTeams = groupBy(employeesWithDepartment, e => e.department)
    const actions = Object.entries(groupedTeams).map(([name, employees]) =>
      (['createTeamWithMembres', { name, employees }]))
    return sequentialDispatch(context, actions)
  },
  updateTeam(context, team) {
    return api.patch('/team/' + team.id, team)
      .then(response => {
        context.commit('updateTeam', response.data)
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  removeTeam(context, team) {
    return api.delete('/team/' + team.id)
      .then(() => {
        context.commit('removeTeam', team)
        return true
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  updateTeamMembers(context, { team, employees }) {
    const employeeIds = employees.map(e => e.id)
    return api.put('/team/' + team.id + '/members', { employeeIds })
      .then(({ data }) => {
        context.commit('updateTeam', data)
        return data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  updateTeamAdmins(context, { team, employees }) {
    const employeeIds = employees.map(e => e.id)
    return api.put('/team/' + team.id + '/admins', { employeeIds })
      .then(({ data }) => {
        context.commit('updateTeam', data)
        return data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  generateTeams({ dispatch }, options = {}) {
    return api.post('/team/generate')
      .then(() => {
        return dispatch('getTeams')
      })
      .catch(error => dispatch('handleAPIError', error, { root: true }))
  }
}

const mutations = {
  setTeams(state, teams) {
    state.teams = teams
  },
  addTeam(state, team) {
    state.teams.push(team)
    state.teams = sortBy(state.teams, t => t.name.toLowerCase())
  },
  updateTeam(state, team) {
    const index = state.teams.findIndex(t => t.id === team.id)
    Vue.set(state.teams, index, team)
  },
  removeTeam(state, team) {
    state.teams.splice(state.teams.findIndex(t => t.id === team.id), 1)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
