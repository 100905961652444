<template>
  <user-banner v-if="isUnsupportedBrowser" :banner="banner" />
</template>

<script>
import UserBanner from '@components/layout/UserBanner'

export default {
  components: {
    UserBanner
  },
  computed: {
    isUnsupportedBrowser() {
      // Based on https://gist.github.com/DmitrySubj/ac554c6b84dd8551ccccacfb4e7afc25
      return /Trident\/|MSIE/.test(window.navigator.userAgent)
    },
    banner() {
      return {
        bannerMessage: this.$t('account.unsupporterBrowser'),
        bannerType: 'error'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.user-banner {
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.1);
}
</style>
