<template>
  <div
    v-if="banner"
    class="user-banner"
    :class="banner.bannerType">
    <div class="user-banner-content">
      <div class="user-banner-text">
        <div class="preserve-lines" v-html="banner.bannerMessage" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    banner: Object
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";

.user-banner {
  padding: 1rem;
  background-color: $lightteal-color;
  color: lighten($darkteal-color, 5);
  border-bottom: 1px solid darken($lightteal-color, 5);

  &.warning {
    background: $lightorange-color;
    color: $orange-color;
    border-bottom: 1px solid darken($lightorange-color, 5);
  }

  &.error {
    background: $lightred-color;
    color: $red-color;
    border-bottom: 1px solid darken($lightred-color, 5);
  }
}

.user-banner-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-banner-text {
  display: flex;
  align-items: center;
}

.user-banner-emoji {
  @include font-title-size;
  margin-right: 1rem;
}

.user-banner-content {
  @include font-semibold;
  @include font-small-size;
  @include line-regular-height;
}

@media screen and (min-width: $content-max-width + 40px) {
  .user-banner-content {
    margin: 0 auto;
    width: $content-max-width;
  }
}
</style>
