<template>
  <div :title="value.join(', ')">
    <svg :height="viewport.height" ref="svgNode">
    </svg>
  </div>
</template>

<script>
import * as d3 from 'd3'
import debounce from 'lodash.debounce'
import {
  formatLabel,
  generateBars,
  generateAbscissaLabels,
  generateOrdinateLabels,
  truncateLabel,
  generateStackedBars,
  getMaxValue,
  getMinValue
} from '@/utils/graph'
import i18n from '@/i18n'

function formatLevelLabel(value, showUnit) {
  const label = formatLabel('$~s', value)
  return showUnit ? label.replace('k' + i18n.currencySymbol, ' k' + i18n.currencySymbol) : label.replace('k' + i18n.currencySymbol, '')
}

function formatIntervalLabel(min, max) {
  if (min !== max) {
    return [formatLevelLabel(min), formatLevelLabel(max, true)].join(' – ')
  }
  else {
    return formatLevelLabel(min, true)
  }
}

function initGraph(svg, viewport) {
  const node = {}

  node.svg = d3.select(svg)
  node.viewport = node.svg.append('g')
    .attr('class', 'viewport')
  node.background = node.viewport.append('rect')
    .attr('class', 'background')
  node.shadows = node.viewport.append('g')
    .attr('class', 'shadows')
  node.bars = node.viewport.append('g')
    .attr('class', 'bars')
  node.abscissa = node.viewport.append('g')
    .attr('class', 'axis abscissa')
  node.ordinate = node.viewport.append('g')
    .attr('class', 'axis ordinate')
  node.x = d3.scaleBand()
  node.y = d3.scaleLinear()

  return {
    node: node,
    viewport: viewport,
    data: []
  }
}

function generateEvolutionModel(graph, values, labels) {
  const isInterval = values.length && typeof values[0] !== 'number'
  // Generate data
  graph.hasLabels = !!labels
  if (isInterval) {
    graph.isInterval = true
    graph.data = values.map((y, x) => ({
      x: labels ? labels[x] : x,
      label: formatIntervalLabel(y[0], y[1]),
      values: [{
        x: labels ? labels[x] : x,
        y0: y[0],
        y1: y[1] + 1000
      }]
    }))
  }
  else {
    graph.data = values.map((y, x) => ({
      label: formatLevelLabel(y, x === values.length - 1),
      x: labels ? labels[x] : x,
      y: y
    }))
  }
  return graph
}

function renderGraph(graph) {
  const domain = graph.data.map(d => d.x)
  const viewport = graph.viewport
  const node = graph.node

  // Viewport
  viewport.width = graph.node.svg.node().getBoundingClientRect().width
  viewport.innerWidth = viewport.width - viewport.padding.left - viewport.padding.right
  viewport.innerHeight = viewport.height - viewport.padding.top - viewport.padding.bottom

  // Graph Axes
  graph.axes = {
    abscissa: {
      type: 'discrete',
      labels: 'belowAxis',
      axis: node.x
    },
    ordinate: {
      type: 'continuous',
      labels: 'aboveMax',
      axis: node.y
    }
  }

  node.x.domain(domain)
    .rangeRound([0, viewport.innerWidth])
    .padding([0.20])
  node.y.domain([d3.max(graph.data, getMaxValue) * 1.05, d3.min(graph.data, getMinValue) * (graph.isInterval ? 1 : 0.9)])
    .range([0, viewport.innerHeight])

  // Viewport background
  node.viewport.attr('transform',
    `translate(${viewport.padding.left},${viewport.padding.top})`)
  node.background.attr('width', viewport.innerWidth)
    .attr('height', viewport.innerHeight)

  // Axes Labels
  if (graph.hasLabels) {
    const abscissaLabelsData = generateAbscissaLabels(graph)
    const abscissaLabels = node.abscissa.selectAll('.label')
      .data(abscissaLabelsData, d => d.id)
    abscissaLabels
      .enter().append('text')
      .attr('class', 'label wrap')
      .merge(abscissaLabels)
      .attr('x', d => d.x)
      .attr('y', d => d.y)
      .text(d => truncateLabel(d.label))
    abscissaLabels.exit()
      .remove()

    const ordinateLabelsData = generateOrdinateLabels(graph)
    const ordinateLabels = node.ordinate.selectAll('.label')
      .data(ordinateLabelsData, d => d.id)
    ordinateLabels
      .enter().append('text')
      .attr('class', 'label')
      .attr('x', d => d.x)
      .attr('y', d => d.y)
      .merge(ordinateLabels)
      .text(d => d.label)
      .transition()
      .attr('x', d => d.x)
      .attr('y', d => d.y)
    ordinateLabels.exit()
      .remove()
  }

  // Bars
  const barsData = graph.isInterval ? generateStackedBars(graph, 0) : generateBars(graph)

  // Bars Shadow
  const radius = 0
  const shadows = node.shadows.selectAll('.shadow')
    .data(barsData, d => d.id)
  shadows
    .enter().append('rect')
    .attr('class', 'shadow')
    .attr('rx', radius)
    .attr('ry', radius)
    .attr('x', d => d.x + 1)
    .attr('y', d => d.y + 1)
    .attr('width', d => d.width)
    .attr('height', d => d.height)
    .merge(shadows)
    .transition()
    .delay((_, i) => i * 25)
    .attr('x', d => d.x + 1)
    .attr('y', d => d.y + 1)
    .attr('width', d => d.width)
    .attr('height', d => d.height)
  shadows.exit()
    .remove()

  // Bars
  const bars = node.bars.selectAll('.bar')
    .data(barsData, d => d.id)
  bars
    .enter().append('rect')
    .attr('class', 'bar')
    .attr('rx', radius)
    .attr('ry', radius)
    .attr('x', d => d.x)
    .attr('y', d => d.y)
    .attr('width', d => d.width)
    .attr('height', d => d.height)
    .merge(bars)
    .transition()
    .delay((_, i) => i * 25)
    .attr('x', d => d.x)
    .attr('y', d => d.y)
    .attr('width', d => d.width)
    .attr('height', d => d.height)
  bars.exit()
    .remove()
}

export default {
  name: 'mini-evolution-graph',
  props: {
    value: Array,
    labels: Array,
    height: {
      type: Number,
      default: 30
    },
    padding: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      // Options
      viewport: {
        width: 0, // Computed
        height: this.height,
        innerWidth: 0, // Computed
        innerHeight: 0, // Computed
        padding: {
          left: this.padding,
          right: this.padding,
          top: this.padding + (this.labels ? 12 : 0),
          bottom: this.padding + (this.labels ? 20 : 0)
        }
      },
      // State
      graph: null,
      resizeFn: null
    }
  },
  mounted() {
    this.init()
    this.render()
    this.resizeFn = debounce(this.render, 100)
    window.addEventListener('resize', this.resizeFn)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeFn)
  },
  watch: {
    value: 'render',
    height: 'render',
    padding: 'render'
  },
  methods: {
    init() {
      this.graph = initGraph(this.$refs.svgNode, this.viewport)
    },
    render() {
      if (this.value) {
        generateEvolutionModel(this.graph, this.value, this.labels)
        renderGraph(this.graph, this.$router)
      }
    }
  }
}

</script>

<style lang="scss" scoped>
@import "./src/styles/graph.scss";

.shadows svg::v-deep .shadow {
  fill: lighten($graph-outer-border-color, 2);
}

svg::v-deep .shadow {
  fill: transparent;
}

svg::v-deep .bar {
  fill: darken($graph-lightblue-color, 3);
}
</style>
