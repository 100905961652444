<template>
  <span>
    <span v-if="selectedWagePlans.length">
      <span v-if="selectedHiringPlans.length">,</span>
      <span v-else v-t="'common.and'"></span>
      {{$tc('dashboard.graphs.footerSelectedWagePlans', selectedWagePlans.length)}}
      <span
        class="wage-plan"
        v-for="(wagePlan, i) in selectedWagePlans"
        :key="wagePlan.id">
        «&nbsp;<router-link :to="{name: 'wagePlan', params: {id: wagePlan.id}}">{{wagePlan.name}}</router-link>&nbsp;»
        <span v-if="i == selectedWagePlans.length - 2" v-t="'common.and'"></span>
        <span v-else-if="i !== selectedWagePlans.length - 1">, </span>
      </span>
    </span>
    <span v-if="selectedHiringPlans.length">
      <span v-t="'common.and'"></span>
      {{$tc('dashboard.graphs.footerSelectedHiringPlans', selectedHiringPlans.length)}}
      <span
        class="wage-plan"
        v-for="(hiringPlan, i) in selectedHiringPlans"
        :key="hiringPlan.id">
        «&nbsp;<router-link :to="{name: 'hiringPlans', params: {highlightHiringPlan: hiringPlan.id}}">{{hiringPlan.name}}</router-link>&nbsp;»
        <span v-if="i == selectedHiringPlans.length - 2" v-t="'common.and'"></span>
        <span v-else-if="i !== selectedHiringPlans.length - 1">, </span>
      </span>
    </span>
    <span
      v-if="!selectedWagePlans.length && !selectedHiringPlans.length && incentiveLocale"
      v-t="incentiveLocale"
      class="preserve-lines">
    </span>
  </span>
</template>

<script>
// When the need comes, refactor this
// into a generic SentenceWithLinks component.

import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      wagePlans: 'wagePlans/getWagePlans',
      selectedWagePlans: 'wagePlans/getSelectedWagePlans',
      hiringPlans: 'candidates/getHiringPlans',
      selectedHiringPlans: 'candidates/getSelectedHiringPlans'
    }),
    incentiveLocale() {
      if (this.wagePlans.length && this.hiringPlans.length) {
        return {
          path: 'dashboard.graphs.footerIncentivePlans'
        }
      }
      else if (this.wagePlans.length) {
        return {
          path: 'dashboard.graphs.footerIncentiveWagePlans'
        }
      }
      else if (this.hiringPlans.length) {
        return {
          path: 'dashboard.graphs.footerIncentiveHiringPlans'
        }
      }
    }
  }
}
</script>
