<template>
  <div
    class="thumbnail"
    :class="{active: employee.hasAvatar, directory: isDirectory}"
    :style="{backgroundColor, backgroundImage}">
    <label class="upload-button" :class="{disabled: !$$hasUpdatePermission(employee)}">
      <input
        v-if="$$hasUpdatePermission(employee)"
        type="file"
        @click="resetFile($event)"
        @change="uploadFile($event)">
    </label>
  </div>
</template>

<script>
import { colorize } from '@/utils/color'
import { getField } from '@/utils/employee'

export default {
  props: {
    employee: {
      type: Object,
      default() {
        return {}
      }
    },
    isDirectory: {
      type: Boolean
    }
  },
  data() {
    return {
      cacheBreak: 0
    }
  },
  computed: {
    backgroundColor() {
      return !this.isDirectory && colorize(this.employee.firstName)
    },
    backgroundImage() {
      const avatar = getField(this.employee, 'avatar', { store: this.$store })
      return avatar ? `url(${avatar})` : ''
    }
  },
  methods: {
    resetFile(event) {
      event.target.value = ''
    },
    async uploadFile(event) {
      const employee = this.employee
      const file = event.target.files[0]
      try {
        await this.$store.dispatch('employees/updateEmployeeAvatar', { employee, file })
      }
      catch (e) {
        alert(this.$t('employees.employee.form.avatar.uploadError'))
      }
      this.cacheBreak++
    }
  }
}
</script>

<style lang="scss" scoped>
.thumbnail {
  position: relative;
  margin: 0 auto;
  width: 100px;
  height: 100px;
  line-height: 100px;
  background: lighten($graph-darkblue-color, 50) none no-repeat center center;
  background-size: cover;
  border-radius: 50%;
  text-align: center;
  font-size: 45px;
  box-sizing: content-box;
  text-transform: uppercase;
  user-select: none;

  &.active .upload-button {
    opacity: 0;
  }

  &:not(.active) .upload-button {
    mix-blend-mode: color-burn;
  }

  .upload-button {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background: transparent url(~@/assets/icon-avatar.svg) no-repeat center
      center;
    opacity: 0.8;

    &:hover:not(.disabled) {
      opacity: 1;
      cursor: pointer;
    }
  }
}

// Directory skin
.thumbnail.directory {
  width: 72px;
  height: 80px;
  border-radius: 7px;
}
</style>
