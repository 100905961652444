import Vue from 'vue'
import * as Sentry from '@sentry/vue'

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    release: process.env.VUE_APP_COMMIT_SHA ? process.env.VUE_APP_COMMIT_SHA : 'unknown',
    tracesSampleRate: 0.1,
    autoSessionTracking: false
  })
}

export function setSentryUserContext(userId, companyName) {
  Sentry.setUser({
    id: userId,
    company: companyName
  })
}

export function resetSentryUserContext() {
  Sentry.setUser(null)
}
