<template>
  <div>
    <div
      v-for="(component, index) in formula.components"
      :key="component.id">
      <div
        :class="operationClass"
        v-if="index !== 0">
        {{ operationSymbol(component.operation) }}
      </div>
      <div
        class="component"
        :class="{invalid: !isSingleComponentValid(component)}"
        v-if="component.name"
        @click="select(component)">
        {{ component.name }}
      </div>
      <appGridFormulaDetail
        @select="select"
        class="group"
        :formula="component"
        :isInnerFormula="true"
        v-else>
      </appGridFormulaDetail>
    </div>
  </div>
</template>
<script>
import { remunerationOperationSymbol, isSingleComponentValid } from '@/utils/grid'

export default {
  name: 'appGridFormulaDetail',
  props: [
    'formula',
    'isInnerFormula'
  ],
  methods: {
    operationSymbol(operation) {
      return remunerationOperationSymbol(operation)
    },
    select(component) {
      this.$emit('select', component)
    },
    isSingleComponentValid(component) {
      return isSingleComponentValid(component)
    }
  },
  computed: {
    operationClass() {
      return this.isInnerFormula ? ['operation', 'recursive'] : 'operation'
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  display: inline-block;
}

.component {
  margin-top: 20px;
  background-color: $lightteal-color;
  color: $clearteal-color;
  @include font-big-size;
  border-radius: $border-radius;
  padding: 4px 10px;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 0px 1px $clearteal-color inset;
  }
}

.invalid {
  color: $orange-color;
}

.operation {
  @include font-big-size;
  @include font-tabular-numbers;
  padding: 5px 10px 5px 10px;
  color: $text-color;
}
</style>
