<template>
  <!-- Clone of InlineWage -->
  <div class="wage-details components">
    <span class="component">
      <label v-t="'grid.editor.remuneration'"></label>
      <span v-t="'wageCalculator.variable.title'"></span>
    </span>
    <span class="badge border-badge large-badge">
      {{ wageDetails.summary.salary.variableValue | formattedCurrency(false, {selectedRecurrence}) }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    wageDetails: Object,
    selectedRecurrence: String
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/badge.scss";

.wage-details {
  @extend .badge-block;
  padding: 0.25em 0.75em;

  .components {
    padding: 0;
  }

  &::v-deep .component {
    min-width: 75px;
  }
}

.components {
  display: inline-block;
  line-height: 1.5em;
  padding: 0.25em 0.75em;
}

.component {
  @include font-medium;
  display: inline-block;
  margin: 0.25em 0;

  &:not(:last-of-type) {
    margin-right: 1.75em;
  }

  label {
    @include font-small-size;
    @include font-regular;
    display: block;
    cursor: inherit;
  }
}

.components .component.highlighted {
  background: $lightteal-color;
  color: darken($clearteal-color, 7);
}

.badge {
  vertical-align: 9.5px;
}
</style>
