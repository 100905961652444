<template>
  <div>
    <template v-if="grid">
      <grid-formula-editor @addComponent="addComponent"></grid-formula-editor>
      <template v-if="allComponents.length">
        <component-editor
          v-for="component in allComponents"
          :key="component.id"
          :component="component"
          :parent-component="getParentComponent(component)"
          class="component-editor">
        </component-editor>
      </template>
    </template>
  </div>
</template>

<script>
import ComponentEditor from '@components/grid/legacy/ComponentEditor.vue'
import GridFormulaEditor from '@components/grid/legacy/GridFormulaEditor.vue'

import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('sandbox', {
      grid: 'grid',
      allComponents: 'allComponents'
    })
  },
  methods: {
    addComponent(remunerationType) {
      this.remunerationType = remunerationType
      this.componentFormDisplayed = true
    },
    getParentComponent(component) {
      if (component.parentComponentId) {
        return this.allComponents.find(c => c.id === component.parentComponentId)
      }
    }
  },
  components: {
    ComponentEditor,
    GridFormulaEditor
  }
}
</script>

<style lang="scss" scoped>
.component-editor {
  margin-bottom: 20px;

  &:last-child {
    margin: 0;
  }
}
</style>
