<template>
  <div>
    <onboarding-qualification-editor class="onboarding-qualification-editor" />
  </div>
</template>

<script>
import OnboardingQualificationEditor from '@components/grid/onboarding/OnboardingQualificationEditor'

export default {
  components: {
    OnboardingQualificationEditor
  }
}
</script>

<style lang="scss" scoped>
.onboarding-qualification-editor {
  margin: -1.8em 0;
}
</style>
