<template>
  <transition name="modal">
    <div class="mask">
      <div class="wrapper">
        <div class="container">
          <div>
            <h1 v-if="title">{{ title }}</h1>
            <h1 v-else v-t="'markdownEditor.description'"></h1>
            <p v-html="$t('markdownEditor.friendly')"></p>
          </div>
          <div class="editor">
            <div class="editor-tabs">
              <ul>
                <li
                  :class="{selected: !previewDisplayed}"
                  @click="showEditor">
                  <div style="display: flex; align-items: center; justify-content: space-between; padding: 0 8px">
                    <span v-t="'markdownEditor.editor'"></span>
                    <svg width="22" height="14" xmlns="http://www.w3.org/2000/svg">
                      <g fill-rule="nonzero" fill="#B3BDBB">
                        <path d="M3.525 10.394V3.525h2.02l2.02 2.526 2.02-2.526h2.021v6.869h-2.02v-3.94L7.566 8.98l-2.02-2.525v3.939h-2.02zm12.627 0l-3.03-3.333h2.02V3.525h2.02v3.536h2.02l-3.03 3.333z" />
                        <path d="M1.505 1.505v10.91h18.99V1.504H1.505zm20-1.01v12.93H.495V.494h21.01z" />
                      </g>
                    </svg>
                  </div>
                </li>
                <li
                  :class="{selected: previewDisplayed}"
                  @click="previewDisplayed = true"
                  v-t="'markdownEditor.preview'">
                </li>
              </ul>
            </div>
            <div v-if="!previewDisplayed">
              <textarea
                ref="textarea"
                v-model="descriptionModel"
                @keydown.enter='handleCmdEnter($event)'>
            </textarea>
            </div>
            <div
              class="preview"
              v-else>
              <markdown-text v-model="descriptionModel" />
            </div>
          </div>
          <menu>
            <button
              class="secondary"
              @click="$emit('close')"
              v-t="'markdownEditor.cancel'"
              :disabled="loading">
            </button>
            <loading-button
              class="primary"
              :loading="loading"
              @click="$emit('update', descriptionModel)">
              <span>{{ actionName }}</span>
            </loading-button>
          </menu>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Vue from 'vue'
import MarkdownText from '@components/commons/MarkdownText'

export default {
  components: {
    MarkdownText
  },
  props: [
    'description',
    'title',
    'loading'
  ],
  data() {
    return {
      descriptionModel: null,
      previewDisplayed: false
    }
  },
  computed: {
    actionName() {
      return this.$t('markdownEditor.' + (this.description ? 'update' : 'add'))
    }
  },
  methods: {
    handleCmdEnter(event) {
      if (event.metaKey || event.ctrlKey) {
        this.$emit('update', this.descriptionModel)
      }
    },
    showEditor() {
      this.previewDisplayed = false

      Vue.nextTick(() => {
        this.$refs.textarea.focus()
      })
    }
  },
  created() {
    if (this.description) {
      this.descriptionModel = this.description.slice(0)
    }
  },
  mounted() {
    this.$refs.textarea.focus()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";

.mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: table;
  transition: opacity 0.3s ease;
}

.wrapper {
  display: table-cell;
  vertical-align: middle;
}

.container {
  @include container;
  border: none;
  width: 70vw;
  height: 70vh;
  margin: 0 auto;
  transition: all 0.3s ease;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-row-gap: 20px;
}

.editor {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
}

.editor-tabs {
  ul {
    margin: 0;
    list-style-type: none;
    padding: 0;
  }

  li {
    margin: 0;
    display: inline;
    float: left;
    padding: 5px;
    text-align: center;
    min-width: 100px;
    cursor: pointer;
    @include font-semibold;
  }

  li:hover,
  .selected {
    border-bottom: 5px solid $clearteal-color;
  }
}

.preview {
  overflow: auto;
  padding: 0 10px;
  border-top: $border;
}

h1 {
  @include font-bigger-size;
  margin: 0;
}

textarea {
  height: 100%;
  margin: 0;
  resize: none;
}

p {
  color: $gray-color;
  margin: 0;
  font-style: oblique;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
