<template>
  <div class="block component" :class="{large}">
    <div class="component-info">
      <h3>
        <InlineEditor
          class="blue"
          v-model="componentModel.name"
          :placeholder="component.name"
          @submit="submit(componentModel)" />
        <template v-if="isSandboxDebug"> ({{componentModel.rank}})</template>
        <template v-if="linkedComponentModel">
          <div class="component-operation">
            {{ remunerationOperationSymbol(linkedComponentModel.salaryOperation) }}
          </div>
          <inline-editor
            class="blue"
            v-model="linkedComponentModel.name"
            :placeholder="linkedComponentModel.name"
            :autoDismiss="true"
            @submit="submit(linkedComponentModel)" />
          <template v-if="isSandboxDebug"> ({{linkedComponentModel.rank}})</template>
          <template v-if="linkedLinkedComponentModel">
            <div class="component-operation">
              {{ remunerationOperationSymbol(linkedLinkedComponentModel.salaryOperation) }}
            </div>
            <InlineEditor
              class="blue"
              v-model="linkedLinkedComponentModel.name"
              :placeholder="linkedLinkedComponentModel.name"
              :autoDismiss="true"
              @submit="submit(linkedLinkedComponentModel)" />
            <template v-if="isSandboxDebug"> ({{linkedLinkedComponentModel.rank}})</template>
          </template>
        </template>
      </h3>
      <MarkdownTextEditor
        class="description-editor blue"
        v-model="componentModel.description"
        :placeholder="component.description"
        :showInlineEditButton="true"
        :showAddButton="true"
        :showToolbar="true"
        :autoDismiss="true"
        @submit="submit(componentModel)" />
      <div class="component-settings">
        {{hasSkillsLabel}}
        <button
          class="small-button blue light"
          v-t="'account.settings'"
          @click="openComponentModal" />
      </div>
    </div>
    <div class="component-levels-container">
      <GridStructureComponentLevelsEditor
        ref="componentLevelsEditor"
        :component="component" />
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import GridStructureComponentLevelsEditor from '@components/grid/structure/GridStructureComponentLevelsEditor.vue'
import InlineEditor from '@components/commons/InlineEditor.vue'
import MarkdownTextEditor from '@components/commons/MarkdownTextEditor.vue'
import { remunerationOperationSymbol } from '@/utils/grid'
import { componentHasSkills } from '@/utils/skills'
import { mapGetters } from 'vuex'

export default {
  components: {
    GridStructureComponentLevelsEditor,
    InlineEditor,
    MarkdownTextEditor
  },
  props: {
    component: Object
  },
  data() {
    return {
      componentModel: null,
      linkedComponentModel: null,
      linkedLinkedComponentModel: null
    }
  },
  computed: {
    ...mapGetters({
      isSandboxDebug: 'sandbox/isSandboxDebug'
    }),
    large() {
      return !!this.linkedComponentModel
    },
    hasSkills() {
      return componentHasSkills(this.component, true)
    },
    hasSkillsLabel() {
      return this.$t('grid.structure.component' + (this.hasSkills ? 'HasSkills' : 'HasNoSkills'))
    }
  },
  methods: {
    remunerationOperationSymbol,
    initModel() {
      this.linkedComponentModel = null
      this.linkedLinkedComponentModel = null
      this.componentModel = { ...this.component }
      // linkedLinkedComponent
      if (this.component.linkedComponents && this.component.linkedComponents.length) {
        this.linkedComponentModel = { ...this.component.linkedComponents[0] }
        if (this.linkedComponentModel.linkedComponents && this.linkedComponentModel.linkedComponents.length) {
          this.linkedLinkedComponentModel = { ...this.linkedComponentModel.linkedComponents[0] }
        }
      }
    },
    openComponentModal() {
      this.$refs.componentLevelsEditor.openComponentModal()
    },
    async submit(componentModel) {
      this.$v.$touch()
      if (this.$v.$error || this.isLoading) {
        return
      }
      this.isLoading = true
      try {
        await this.$store.dispatch('sandbox/updateComponentProperties', componentModel)
      }
      finally {
        this.isLoading = false
      }
    }
  },
  watch: {
    component: 'initModel'
  },
  validations() {
    const validations = {
      componentModel: {
        name: { required }
      }
    }
    if (this.linkedComponentModel) {
      validations.linkedComponentModel = {
        name: { required }
      }
    }
    return validations
  },
  created() {
    this.initModel()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
@import "./src/styles/block.scss";

.component {
  display: grid;
  grid-gap: 1em;
  margin-bottom: 1em;

  &:not(.large) {
    grid-template-columns: minmax(250px, 1fr) auto;
  }
}

.component-info {
  h3 {
    color: $graph-darkblue-color;
    margin: 0;
  }

  .inline-editor {
    margin-bottom: 0.5em;
  }

  .description-editor {
    margin-bottom: 0;
  }
}

.component-levels-container {
  background: white;
  border-radius: $border-radius;
  border: $border;
  padding-left: 1.25em;
}

.component-operation {
  display: inline-block;
  @include font-big-size;
  @include font-tabular-numbers;
  padding: 5px 10px 5px 10px;
  color: $text-color;
}

.component-settings {
  margin-top: 0.5em;
  color: $light-text-color;

  .small-button {
    margin: 0.1em 0.5em;
  }
}
</style>
