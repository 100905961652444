<template>
  <div class="hiring-plan">
    <div class="hiring-plan-header">
      <h2
        class="hiring-plan-title colored-link"
        @click="hiringPlanNameFormDisplayed = true">
        {{hiringPlan.name}}
      </h2>
      <div
        class="badge">
        {{ hiringPlan.postPayrollRise | formattedCurrency }}
        <small>({{ hiringPlan.postPayrollRisePercent | formattedDeltaNumber(1, 1) }}&nbsp;%)</small>
      </div>
      <div></div>
      <router-link
        tag="button"
        class="secondary"
        :to="{name: 'addJobOffer', params: { hiringPlanId: hiringPlan.id }}"
        v-t="'candidates.addJobOffer'">
      </router-link>
    </div>
    <div class="job-offers">
      <div
        class="job-offer"
        v-for="{jobOffer, candidates} in jobOffersByHiringPlan(hiringPlan)"
        :key="jobOffer.id"
        :data-id="jobOffer.id">
        <div class="job-offer-header">
          <router-link
            tag="h3"
            class="job-offer-title colored-link"
            :to="{name: 'jobOffer', params: {id: jobOffer.id}}">
            <span
              v-for="(namePart, i) in getJobOfferNameParts(jobOffer)"
              :key="[namePart, i].join()">
              {{namePart}}
            </span>
          </router-link>
        </div>
        <div class="job-offer-subtitle">
          {{$tc('hiringPlans.positions', jobOffer.quantity, {count: jobOffer.quantity})}},
          {{$tc('hiringPlans.candidates', candidates.length, {count: candidates.length})}}
        </div>
        <candidates-table
          v-if="candidates.length"
          :compact="true"
          :candidates="candidates"
          @select="openCandidate" />
        <router-link
          tag="button"
          class="small-button green float-right"
          :to="{name: 'addCandidate', params: {jobOfferId: jobOffer.id}}"
          v-t="'candidates.addCandidate'">
        </router-link>
      </div>
      <p
        v-if="!jobOffersByHiringPlan(hiringPlan).length"
        class="no-margin-top"
        v-t="'candidates.noJobOffer'">
      </p>
    </div>
    <modal
      :visible="hiringPlanNameFormDisplayed"
      @close="hiringPlanNameFormDisplayed = false">
      <hiring-plan-name-form
        :hiring-plan="hiringPlan"
        @close="hiringPlanNameFormDisplayed = false" />
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import animateScrollTo from 'animated-scroll-to'
import CandidatesTable from '@components/candidates/CandidatesTable'
import HiringPlanNameForm from '@components/hiringPlans/HiringPlanNameForm'
import Modal from '@components/commons/Modal.vue'

export default {
  props: {
    hiringPlan: Object
  },
  data() {
    return {
      hiringPlanNameFormDisplayed: false
    }
  },
  computed: {
    ...mapGetters({
      getJobOffersByHiringPlan: 'candidates/getJobOffersByHiringPlan',
      getEnrichedCandidatesByJobOffer: 'candidates/getEnrichedCandidatesByJobOffer'
    })
  },
  methods: {
    openCandidate({ id }) {
      this.$router.push({ name: 'candidate', params: { id } })
    },
    getJobOfferNameParts(jobOffer) {
      return jobOffer.name.split('   ')
    },
    jobOffersByHiringPlan(hiringPlan) {
      return this.getJobOffersByHiringPlan(hiringPlan.id).map(jobOffer => ({
        jobOffer,
        candidates: this.getEnrichedCandidatesByJobOffer(jobOffer.id)
      }))
    },
    highlightHiringPlan() {
      const hiringPlanId = this.$route.params.highlightHiringPlan
      if (hiringPlanId === this.hiringPlan.id) {
        animateScrollTo(this.$el, {
          verticalOffset: -65,
          minDuration: 0,
          maxDuration: 0
        }).then(() => {
          this.$el.classList.add(this.$route.params.highlightClass || 'selected')
        })
      }
    },
    highlightJobOffer() {
      const jobOfferId = this.$route.params.highlightJobOffer
      if (jobOfferId) {
        const jobOffer = this.$el.querySelector(`.job-offer[data-id="${jobOfferId}"]`)
        if (jobOffer) {
          animateScrollTo(jobOffer, {
            verticalOffset: -65,
            minDuration: 0,
            maxDuration: 0
          }).then(() => {
            jobOffer.classList.add(this.$route.params.highlightClass || 'zoom-in')
          })
        }
      }
    }
  },
  mounted() {
    this.highlightHiringPlan()
    this.highlightJobOffer()
  },
  components: {
    CandidatesTable,
    HiringPlanNameForm,
    Modal
  }

}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/badge.scss";
@import "./src/styles/button.scss";
@import "./src/styles/link.scss";

$padding: 0.75em;

.hiring-plan:not(:first-of-type) {
  border-top: 1px solid $graph-inner-border-color;
  padding-top: 1em;
  margin-top: 1em;
}

.hiring-plan-title {
  @include font-large-size;
  margin: 0;
}

.hiring-plan-header {
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  column-gap: 1em;
  align-items: center;
}

.job-offers {
  display: flex;
  flex-wrap: wrap;
  padding: $padding 0;
}

.job-offer {
  @extend .badge-block;
  border: 1px solid $graph-outer-border-color;
  border-radius: $border-radius;
  padding: $padding;
  margin-right: 1.25em;
  margin-bottom: 1.25em;
  min-width: 26%;
}

.job-offer-header {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  margin-bottom: $padding;
}

.job-offer-title {
  margin: 0;

  span {
    @include font-large-size;
    margin-right: 0.3em;
  }

  span:not(:first-of-type) {
    @include font-regular;
    display: inline-block;
    color: $light-text-color;
  }
}

.job-offer-subtitle {
  color: $light-text-color;
  @include line-large-height;
  margin-top: -4px;
}

.candidates {
  margin-top: $padding;
}

.small-button {
  @include font-normal-size;
  margin-top: $padding;
  border-color: $graph-outer-border-color;
}
</style>
