<template>
  <button
    v-if="$$isAdmin && isSynchronizationEnabled"
    class="secondary synchronization-tooltip-container"
    @click="$router.push({name: 'settingsCompanySynchronization'})"
    @mouseover="isTooltipVisible = true"
    @mouseout="isTooltipVisible = false">
    <span v-t="'employees.synchronization.action'"></span>
    <span
      v-if="status === 'loading'"
      :class="{animated: isAnimated}"
      class="synchronization-icon loading">&nbsp;</span>
    <span
      v-if="status === 'applied'"
      :class="{animated: isAnimated}"
      class="synchronization-icon applied">&nbsp;</span>
    <span
      v-if="status === 'changed'"
      :class="{animated: isAnimated}"
      class="synchronization-icon changed">{{changesCount}}</span>
    <div v-if="tooltipLabel && isTooltipVisible" class="synchronization-tooltip">{{tooltipLabel}}</div>
  </button>
</template>

<script>
import { mapGetters } from 'vuex'

const CW_SYNCHRONIZATION_TOOLTIP_SEEN = 'CW_SYNCHRONIZATION_TOOLTIP_SEEN'

export default {
  data() {
    return {
      isAnimated: false,
      isTooltipVisible: false
    }
  },
  computed: {
    ...mapGetters({
      availableChanges: 'synchronization/availableChanges',
      isLoading: 'synchronization/isLoading',
      isSynchronizationEnabled: 'synchronization/isSynchronizationEnabled',
      isSynchronized: 'synchronization/isSynchronized'
    }),
    changesCount() {
      return this.availableChanges.length
    },
    status() {
      switch (true) {
        case !!this.isLoading:
          return 'loading'
        case !!this.isSynchronized:
          return 'applied'
        case this.changesCount > 0:
          return 'changed'
      }
    },
    tooltipLabel() {
      if (this.status === 'changed') {
        const count = this.changesCount
        return this.$tc('employees.synchronization.pendingChanges', count, { count })
      }
    }
  },
  methods: {
    animate() {
      this.isAnimated = true
      this.showTooltipIfNeeded()
    },
    showTooltipIfNeeded() {
      const hasSeenTooltip = !!sessionStorage.getItem(CW_SYNCHRONIZATION_TOOLTIP_SEEN)
      if (!hasSeenTooltip && this.changesCount > 0) {
        this.isTooltipVisible = true
        sessionStorage.setItem(CW_SYNCHRONIZATION_TOOLTIP_SEEN, true)
      }
    }
  },
  watch: {
    status: 'animate'
  },
  mounted() {
    this.showTooltipIfNeeded()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/button.scss";

@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

@keyframes applied {
  from {
    opacity: 0;
    transform: scale(1.5);
  }
  10% {
    opacity: 1;
    transform: scale(1);
  }
  90% {
    opacity: 1;
    transform: scale(1);
    width: 20px;
    padding: 2px 3px 4px 3px;
  }
  to {
    width: 0;
    padding: 0;
  }
}

@keyframes changed {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  40% {
    opacity: 1;
    transform: scale(1.2);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.synchronization-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat top left;
  padding: 2px 3px 4px 3px;
  margin: -3px -5px -3px 5px;
  border-radius: 20px;
  vertical-align: middle;
  text-align: center;

  &.applied:not(.animated) {
    display: none;
    animation: none !important;
  }

  &.loading {
    background-image: url(~@/assets/synchronization-loading.svg);
    animation: loading 3s infinite linear;
  }

  &.applied {
    background-image: url(~@/assets/synchronization-applied.svg);
    animation: applied 3s 1 linear;
    animation-fill-mode: both;
  }

  &.changed {
    @include font-smaller-size;
    color: $clearteal-color;
    background: $lightteal-color;
    animation: changed 500ms 1 linear;
  }
}

// Similar to Tooltip component without the icon
.synchronization-tooltip-container {
  position: relative;
}

.synchronization-tooltip {
  pointer-events: none;
  position: absolute;
  background: $graph-lightblue-color;
  border: $border;
  @include font-regular;
  @include font-small-size;
  color: $text-color;
  border-radius: $border-radius;

  margin-top: 16px;
  margin-left: -201px;
  box-shadow: 1px 2px 0 rgba(0, 0, 0, 0.015), 3px 4px 0 rgba(0, 0, 0, 0.007);
  z-index: 1000;
  padding: 0.5em 0.75em;
  text-transform: none;
  width: 340px;

  &:after,
  &:before {
    top: -21px;
    left: 300px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 10px;
  }

  &:after {
    border-bottom-color: $graph-lightblue-color;
    margin-top: 1px;
  }
  &:before {
    border-bottom-color: $graph-outer-border-color;
  }
}
</style>
