<template>
  <div v-if="wagesLoaded">
    <div v-if="employees.length > 0">
      <sandbox-wage-editor
        class="sandbox-wage-editor"
        v-for="employee in employees"
        :key="employee.id"
        :title="employee.firstName + ' ' + employee.lastName"
        :sandboxWage="$store.getters['sandbox/employeeWage'](employee.id)"
        :referenceWage="employee.currentWage">
          <template slot-scope="{ containerExpanded }">
            <div class="show-people-info" v-if="(employee.arrivalDate || employee.currentWage) && containerExpanded">?</div>
            <div class="people-info">
              <p v-if="employee.arrivalDate">
                💼 <span v-t="{
                  path: 'employees.employee.detail.status',
                  args: {
                    companyName: company.name,
                    arrivalDate: $options.filters.fullFormattedDate(employee.arrivalDate),
                    diff: $options.filters.fullDiffFromNow(employee.arrivalDate)
                  }
                }"></span>
              </p>
              <p v-if="employee.currentWage">
                📈 <span v-t="{
                  path: 'employees.employee.detail.wageEvolution',
                  args: {
                    date: $options.filters.fullFormattedDate(employee.currentWage.startDate),
                    diff: $options.filters.fullDiffFromNow(employee.currentWage.startDate)
                  }
                }"></span>
              </p>
            </div>
          </template>
      </sandbox-wage-editor>
    </div>
  </div>
  <loading-view v-else></loading-view>
</template>

<script>
import LoadingView from '@components/commons/LoadingView.vue'
import SandboxWageEditor from '@components/sandbox/SandboxWageEditor.vue'

export default {
  computed: {
    company() {
      return this.$store.getters['account/getCompany']
    },
    employees() {
      return this.$store.getters['employees/getGridEmployees']
    },
    wagesLoaded() {
      return this.$store.getters['sandbox/wagesLoaded']
    }
  },
  components: {
    LoadingView,
    SandboxWageEditor
  }
}
</script>

<style lang="scss" scoped>
.sandbox-wage-editor {
  margin-bottom: 20px;

  &:last-child {
    margin: 0;
  }
}

.show-people-info {
  margin-left: 10px;
  width: 18px;
  height: 18px;
  background: white;
  border: 1px solid $clearteal-color;
  color: $clearteal-color;
  @include font-bold;
  text-align: center;
  border-radius: 20px;
  @include font-smaller-size;
  user-select: none;
  cursor: pointer;

  &:hover {
    background: $clearteal-color;
    color: white;
  }

  &:hover + .people-info {
    display: block;
  }
}

.people-info {
  display: none;
  position: absolute;
  background: $lightteal-color;
  border: 1px solid $clearteal-color;
  border-radius: $border-radius;
  padding: 10px;
  top: 60px;
  border-bottom: 2px solid $clearteal-color;
}
</style>
