import { render, staticRenderFns } from "./AvatarEditor.vue?vue&type=template&id=5983e820&scoped=true&"
import script from "./AvatarEditor.vue?vue&type=script&lang=js&"
export * from "./AvatarEditor.vue?vue&type=script&lang=js&"
import style0 from "./AvatarEditor.vue?vue&type=style&index=0&id=5983e820&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5983e820",
  null
  
)

export default component.exports