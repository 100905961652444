<template>
  <div>
    <h3 class="title">
      {{payload.component.name}}
      &nbsp;&nbsp;›
      <InlineEditor
        v-model="domainModel.name"
        :placeholder="domainModel.name"
        :error="$v.domainModel.name.$error"
        @submit="onChange" />
    </h3>
    <p class="light-text">
      {{$t('grid.editor.domain.intro')}}
      <template v-if="shouldDisplayRoleNotice">{{$t('grid.editor.domain.roleNotice')}}</template>
    </p>
    <div class="domain-descriptions">
      <div
        v-for="domainDescription in domainDescriptionsModel"
        :key="domainDescription.levelId"
        class="input-box">
        <div class="input-label">{{domainDescription.levelName}}</div>
        <MarkdownTextEditor
          v-model="domainDescription.description"
          :showAddButton="true"
          :showInlineEditButton="true"
          :showToolbar="true"
          :autoDismiss="false"
          @change="onDomainDescriptionChange(domainDescription)" />
      </div>
    </div>
    <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>
    <menu>
      <button
        v-if="isUpdated"
        class="destructive fade-in"
        @click="initModel"
        v-t="'common.reset'"></button>
      <button
        class="destructive"
        @click="remove"
        :disabled="isLoading"
        v-t="'common.remove'"></button>
      <button
        class="secondary"
        @click="close"
        v-t="'common.cancel'"></button>
      <loading-button
        class="primary"
        :disabled="isLoading"
        @click="submit"
        :loading="isLoading">
        <span v-t="'common.update'"></span>
      </loading-button>
    </menu>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import InlineEditor from '@components/commons/InlineEditor'
import MarkdownTextEditor from '@components/commons/MarkdownTextEditor'

export default {
  components: {
    InlineEditor,
    MarkdownTextEditor
  },
  name: 'gridDomainEditor',
  props: {
    payload: Object
  },
  data() {
    return {
      errorMessage: null,
      isLoading: false,
      isUpdated: false,
      domainModel: null,
      domainDescriptionsModel: null
    }
  },
  computed: {
    shouldDisplayRoleNotice() {
      return this.payload.levels[0] && this.payload.levels[0].linkedLevelId
    }
  },
  methods: {
    onDomainDescriptionChange(domainDescription) {
      domainDescription.$isUpdated = true
      this.onChange()
    },
    onChange() {
      this.isUpdated = true
    },
    initModel() {
      this.domainModel = { ...this.payload.domain }
      const domainDescriptions = this.payload.levels
        .map(level => {
          const domain = level.domains && level.domains.find(domain => domain.id === this.domainModel.id)
          if (domain) {
            return {
              levelName: level.name,
              levelId: level.id,
              domainId: domain.id,
              description: domain.description
            }
          }
        })
        .filter(d => d)
      this.domainDescriptionsModel = [...domainDescriptions]
      this.isUpdated = false
    },
    close() {
      this.$emit('close')
    },
    remove() {
      if (confirm(this.$t('grid.editor.domain.deleteConfirmation', { name: this.payload.domain.name }))) {
        this.$store.dispatch('sandbox/removeDomain', this.payload.domain).then(() => {
          this.close()
        }).catch(error => {
          this.errorMessage = error
        })
      }
    },
    async submit() {
      this.$v.$touch()

      if (this.$v.$error) {
        return
      }
      this.isLoading = true

      try {
        const updatedDomainDescriptions = [...this.domainDescriptionsModel].filter(d => d.$isUpdated)
        await this.$store.dispatch('sandbox/updateDomainDescriptions', updatedDomainDescriptions)
        const response = await this.$store.dispatch('sandbox/updateDomain', this.domainModel)
        this.$emit('close', response)
      }
      catch (error) {
        this.errorMessage = error
        this.isLoading = false
      }
    }
  },
  created() {
    this.initModel()
  },
  watch: {
    'payload.component': 'initModel'
  },
  validations: {
    domainModel: {
      name: {
        required
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";
@import "./src/styles/link.scss";

h3.title {
  height: 32px;
  display: flex;
  align-items: center;

  .inline-editor {
    margin-left: 0.4em;
  }
}
</style>
