<template>
  <div>
    <div class="teams" v-if="!isLoading">
      <p
        v-if="isSynchronizationEnabled && isDebugTeams"
        class="alert"
        v-html="$t('settings.settingsCompanyTeams.synchronizedTeams', {serviceNames: enabledIdentityProviderNames})"></p>
      <div class="form-section">
        <Team
          :class="highlightedTeamId === team.id && highlightedClass"
          v-for="team in visibleTeams"
          :key="team.id"
          :team="team"
          :disabled="team.isGenerated"
          @remove="removeTeam" />
      </div>
      <form class="create-team-container" @submit.prevent="createTeam">
        <div class="form-section" v-if="suggestedTeams">
          <div class="input-label" v-t="'settings.settingsCompanyTeams.suggestedTeams'"></div>
          <div class="create-suggested-teams">
            {{$tc('settings.settingsCompanyTeams.teamsSuggestion', suggestedTeams.length, {teams: formatList(suggestedTeams)})}}
            <loading-button
              type="button"
              class="primary create-suggested-team-button"
              :loading="isCreatingSuggestedTeams"
              @click="createdSuggestedTeams">
              <span v-t="'common.create'"></span>
            </loading-button>
          </div>
        </div>
        <div class="form-section">
          <div class="input-label" v-t="'settings.settingsCompanyAccessRightsGroups.addGroup'"></div>
          <div class="create-team">
            <input
              type="text"
              v-model="teamName"
              :placeholder="$t('settings.settingsCompanyAccessRightsGroups.teamNamePlaceholder')"
              :class="{'input-error' : $v.teamName.$error}">
            <loading-button
              class="primary create-team-button"
              :loading="isCreating">
              <span v-t="'common.create'"></span>
            </loading-button>
          </div>
          <p class="error-message fade-in" v-if="errorMessage">{{ errorMessage }}</p>
        </div>
      </form>
      <footer class="preserve-lines light-text" v-html="$t('settings.settingsCompanyAccessRightsGroups.footer')"></footer>
    </div>
    <loading-view v-else />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import { formatList } from '@/utils/string'
import LoadingView from '@components/commons/LoadingView'
import Team from '@components/settings/teams/Team.vue'

export default {
  components: {
    LoadingView,
    Team
  },
  data() {
    return {
      errorMessage: null,
      highlightedClass: 'fade-in',
      highlightedTeamId: null,
      isCreating: false,
      isCreatingSuggestedTeams: false,
      isLoading: false,
      teamName: null,
      isDebugTeams: false
    }
  },
  computed: {
    ...mapGetters({
      teams: 'teams/getTeams',
      isSynchronizationEnabled: 'synchronization/isSynchronizationEnabled',
      enabledIdentityProviderNames: 'synchronization/enabledIdentityProviderNames'
    }),
    suggestedTeams() {
      // Suggested teams are deprecated
      return null
    },
    visibleTeams() {
      return this.teams.filter(t => !t.isGenerated || this.isDebugTeams)
    }
  },
  methods: {
    initDebug() {
      this.isDebugTeams = !!(this.$route.params.debug || this.$route.query.debug)
    },
    formatList,
    highlightTeam(team, className = 'zoom-in') {
      this.highlightedTeamId = team.id
      this.highlightedClass = className
    },
    async loadTeams() {
      this.isLoading = true
      await this.$store.dispatch('teams/getTeams')
      this.isLoading = false
    },
    async createTeam(event) {
      this.$v.$touch()
      if (this.$v.$error) {
        return
      }
      this.isCreating = true
      const name = this.teamName
      const team = await this.$store.dispatch('teams/createTeam', { name })
      this.highlightTeam(team)
      this.$v.$reset()
      this.teamName = null
      this.isCreating = false
    },
    async createdSuggestedTeams() {
      this.isCreatingSuggestedTeams = true
      await this.$store.dispatch('teams/createSuggestedTeams')
      this.isCreatingSuggestedTeams = false
    },
    removeTeam(team) {
      if (confirm(this.$t('settings.settingsCompanyTeams.removeConfirmation', { name: team.name }))) {
        this.highlightTeam(team, 'zoom-out')
        setTimeout(() => {
          this.$store.dispatch('teams/removeTeam', team)
            .catch(error => {
              this.errorMessage = error
            })
        }, 500)
      }
    },
    toggleShowGeneratedTeams() {
      this.showGeneratedTeams = !this.showGeneratedTeams
    }
  },
  watch: {
    $route: 'initDebug'
  },
  created() {
    this.loadTeams()
    this.initDebug()
  },
  validations() {
    return {
      teamName: { required }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/settings.scss";

.create-team {
  display: flex;

  input[type="text"] {
    display: inline-block;
    width: 250px;
  }

  .create-team-button {
    margin: 0 1em;
  }
}

.create-suggested-teams {
  display: flex;
  align-items: center;
  color: darken($clearteal-color, 10);

  .create-suggested-team-button {
    margin: 0.2em 0.5em 0;
  }
}
</style>
