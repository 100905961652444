import Vue from 'vue'
import api from '@/store/api'
import i18n from '@/i18n'
import { getCurrencySymbol } from '@/utils/currency'
import { setAnalyticsFields, mergePreSignupAnalytics } from '@/services/analytics'
import { setupBeacon } from '@/services/helpscout'
import { setSentryUserContext } from '@/services/sentry'
import { setupSnippet } from '@/services/snippet'
import { formatDate } from '@/utils/date'

const USER_STATUSES = {
  admin: 'admin',
  manager: 'manager',
  employee: 'employee'
}

const state = {
  settings: {},
  user: {}
}

const getters = {
  getSettings(state) {
    return state.settings
  },
  getSetting(state) {
    return key => state.settings[key]
  },
  getCompany(state) {
    return state.user != null ? state.user.company : null
  },
  getUser(state) {
    return state.user
  },
  getUserName(state) {
    if (state.user) {
      return [state.user.firstName, state.user.lastName].join(' ')
    }
  },
  getUserBanner(state, { getCompany, isAdmin }, __, { isLeadSession, isTrialExpired, isExpiredSession }) {
    if (state.user && state.user.bannerMessage) {
      const { bannerMessage, bannerType } = state.user
      return { bannerMessage, bannerType }
    }
    else if (isTrialExpired) {
      const company = getCompany || {}
      const expiresAt = formatDate(company.trialExpiresAt)
      const leadMessage = i18n.t('demo.banner.trialExpired.message', { expiresAt })
      const callToAction = isAdmin ? i18n.t('demo.banner.lead.callToAction') : ''
      return {
        bannerMessage: `${leadMessage} ${callToAction}`,
        bannerType: 'info'
      }
    }
    else if (isLeadSession) {
      const company = getCompany || {}
      const expiresAt = company.trialExpiresAt ? formatDate(company.trialExpiresAt) : null
      const leadMessage = company.trialExpiresAt ? i18n.t('demo.banner.lead.trialMessage', { expiresAt }) : i18n.t('demo.banner.lead.message')
      const callToAction = isAdmin ? i18n.t('demo.banner.lead.callToAction') : ''
      return {
        bannerMessage: `${leadMessage} ${callToAction}`,
        bannerType: 'info'
      }
    }
    else if (isExpiredSession) {
      return {
        bannerMessage: i18n.t('demo.banner.expired.message'),
        bannerType: 'info'
      }
    }
  },
  userStatuses() {
    return Object.values(USER_STATUSES)
  },
  isAdmin(state) {
    return state.user.status === USER_STATUSES.admin
  },
  isManager(state) {
    return state.user.status === USER_STATUSES.manager
  },
  isAtLeastManager(_, { isAdmin, isManager }) {
    return isAdmin || isManager
  },
  hasOpenAccess({ user }, { isAtLeastManager }) {
    return isAtLeastManager || (user.company && user.company.hasOpenPolicy)
  },
  isBambooHRActive(_, { getCompany }) {
    return !!(getCompany.bambooHrSettings && getCompany.bambooHrSettings.active)
  },
  isLuccaActive(_, { getCompany }) {
    return !!(getCompany.luccaSettings && getCompany.luccaSettings.active)
  },
  isLuccaWageQualificationActive(_, { getCompany }) {
    return !!(getCompany.luccaSettings && getCompany.luccaSettings.active && getCompany.luccaSettings.wageQualificationActive)
  },
  isOktaActive(_, { getCompany }) {
    return !!getCompany.oktaActive
  },
  hasPermission(_, { isAdmin, isAtLeastManager }, __, rootGetters) {
    return permission => {
      switch (rootGetters['company/getPermission'](permission)) {
        case 'employee':
          return true
        case 'manager':
          return isAtLeastManager
        case 'admin':
          return isAdmin
        default:
          return false
      }
    }
  }
}

const actions = {
  getSigninMethod(context, credentials) {
    return api.post('/account/signin/method', credentials)
      .then(({ data }) => {
        return data.signinMethod
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  signIn(context, credentials) {
    return api.post('/account/signin', credentials)
      .then(({ data }) => {
        this.commit('setSession', data.session)
        context.commit('setSettings', data.settings)
        context.commit('setUser', data.user)
        return context.dispatch('loadInitialData')
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  signInWithOpenIDConnect(context, credentials) {
    return api.post('/account/signin/openid_connect', credentials)
      .then(({ data }) => {
        this.commit('setSession', data.session)
        context.commit('setSettings', data.settings)
        context.commit('setUser', data.user)
        return context.dispatch('loadInitialData')
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  signInAsEmployee(context, id) {
    return api.put(`/account/${id}/signin`)
      .then(({ data }) => {
        this.commit('setAdminSession', { token: data.session.token })
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  signUp(context, params) {
    return api.post('/account/signup', params)
      .then(({ data }) => {
        this.commit('setSession', data.session)
        context.commit('setSettings', data.settings)
        context.commit('setUserSignedUp', data.user)
        context.commit('setUser', data.user)
        return context.dispatch('loadInitialData')
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  signOut(context) {
    return api.delete('/account/session')
      .then(_ => {
        context.dispatch('resetAll')
      })
      .catch(_ => {
        context.dispatch('resetAll')
      })
  },
  getAccount(context) {
    return api.get('/account')
      .then(({ data }) => {
        context.commit('setUser', data.user)
        context.commit('setSettings', data.settings)
        return context.dispatch('loadInitialData')
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  updateAccount(context, params) {
    return api.patch('/account', params)
      .then(response => {
        context.commit('setUser', response.data)
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  updateAccountEmail(context, params) {
    return api.put('/account/email', params)
      .then(response => {
        context.commit('setUser', response.data)
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  updateAccountPassword(context, params) {
    return api.put('/account/password', params)
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  async updateSetting({ getters, dispatch, commit }, { key, value }) {
    try {
      commit('setSetting', { key, value })
      await api.put('/account/settings', { settings: getters.getSettings })
    }
    catch (error) {
      return dispatch('handleAPIError', error, { root: true })
    }
  },
  requestPasswordReset(context, { email }) {
    return api.post('/account/password/reset', { email: email })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  getPasswordResetRequest(context, { token }) {
    return api.get(`/account/password/reset/${token}`)
      .then(({ data }) => data)
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  resetPassword(context, { token, password }) {
    return api.put(`/account/password/reset/${token}`, { password })
      .then(({ data }) => {
        this.commit('setSession', data.session)
        context.commit('setSettings', data.settings)
        context.commit('setUser', data.user)
        context.dispatch('loadInitialData')
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  getInvitation(context, { token }) {
    return api.get(`/account/invitation/${token}`)
      .then(({ data }) => data)
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  acceptInvitation(context, { token, password }) {
    return api.put(`/account/invitation/${token}`, { password })
      .then(({ data }) => {
        this.commit('setSession', data.session)
        context.commit('setSettings', data.settings)
        context.commit('setUser', data.user)
        return context.dispatch('loadInitialData')
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  loadInitialData(context) {
    return this.dispatch('currentGrid/getCurrentGrid')
      .then(r => {
        if (context.getters.hasOpenAccess) {
          return context.dispatch('loadOpenAccessData')
        }
        else {
          return context.dispatch('loadNonOpenAccessData')
        }
      })
  },
  loadOpenAccessData(context) {
    return this.dispatch('employees/getEmployees')
      .then(() => {
        return this.dispatch('onboarding/init').then(() => {
          return this.dispatch('wagePlans/init').then(() => {
            return this.dispatch('candidates/init').then(() => {
              return this.dispatch('employees/getOneself').then(() => {
                return this.dispatch('synchronization/init').then(() => {
                  this.dispatch('setIsAppLoaded')
                })
              })
            })
          })
        })
      })
  },
  loadNonOpenAccessData(context) {
    return this.dispatch('employees/getOneself')
      .then(r => {
        this.dispatch('setIsAppLoaded')
      })
  },
  resetAll(context) {
    context.commit('destroySettings')
    context.commit('destroyUser')
    this.commit('destroySession')
    this.dispatch('benefits/reset')
    this.dispatch('candidates/reset')
    this.dispatch('company/reset')
    this.dispatch('currentGrid/reset')
    this.dispatch('employees/reset')
    this.dispatch('employeesImporter/reset')
    this.dispatch('jobs/reset')
    this.dispatch('orgChart/reset')
    this.dispatch('onboarding/reset')
    this.dispatch('sandbox/reset')
    this.dispatch('sandboxList/reset')
    this.dispatch('synchronization/reset')
    this.dispatch('statistics/reset')
    this.dispatch('variablePlans/reset')
    this.dispatch('wagePlans/reset')
  }
}

const mutations = {
  setUserSignedUp(state, user) {
    mergePreSignupAnalytics(user.id)
  },
  setSettings(state, settings) {
    state.settings = settings
  },
  setSetting(state, { key, value }) {
    Vue.set(state.settings, key, value)
  },
  setUser(state, user) {
    state.user = user
    i18n.locale = user.company.locale
    i18n.currency = user.company.currency
    i18n.currencySymbol = getCurrencySymbol(user.company.currency)

    setAnalyticsFields(user)
    setupHelpScout(state.user)
    setSentryUserContext(user.id, user.company.name)
    setupSnippet(user)
  },
  destroySettings(state) {
    state.settings = {}
  },
  destroyUser(state) {
    state.user = {}
  },
  setCustomFields(state, customFields) {
    state.user.company.customFields = customFields
  },
  setCompany(state, company) {
    Vue.set(state.user, 'company', company)
    i18n.locale = company.locale
    i18n.currency = company.currency
    i18n.currencySymbol = getCurrencySymbol(company.currency)
    setupHelpScout(state.user)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

function setupHelpScout(user) {
  setupBeacon({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email || '',
    userId: user.id,
    companyName: user.company.name,
    signature: user.helpscoutSignature || ''
  })
}
