import { normalizedGrid } from '@/store/schema'
import i18n from '@/i18n'
import hashSum from 'hash-sum'
import { getSkillIdsFromLevels } from './skills'
import pick from 'lodash.pick'

// `equity` and `bonus` have been removed:
export const REMUNERATION_TYPES = [
  'salary'
]

export const COMPONENT_REFS = [
  'role',
  'experience',
  'seniority',
  'management',
  'city'
]

export function valueFromOperation(value1, value2, operation) {
  if (value1 == null || value2 == null || !operation) {
    return null
  }

  let newValue = value1
  if (operation === 'addition' || operation === 'percentage') {
    newValue += value2
  }

  if (operation === 'divider') {
    newValue /= value2
  }

  if (operation === 'multiplier') {
    newValue *= value2
  }

  return Math.round(newValue) // Avoid stupid JS float glitches
}

// Reverse from valueFromOperation
export function valueFromReverseOperation(value1, value2, operation) {
  if (value1 == null || value2 == null || !operation) {
    return null
  }

  let newValue = value1
  if (operation === 'addition' || operation === 'percentage') {
    newValue -= value2
  }

  if (operation === 'divider') {
    newValue *= value2
  }

  if (operation === 'multiplier') {
    newValue /= value2
  }

  return Math.round(newValue) // Avoid stupid JS float glitches
}

// Return wether a grid has component that has a specific remuneration type
export function gridHasRemunerationType(components, remunerationType) {
  if (!components) {
    return false
  }

  for (const component of components) {
    if (componentHasRemunerationType(component, remunerationType)) {
      return true
    }
  }

  return false
}

// Return wether a component has a specific remuneration type
export function componentHasRemunerationType(component, remunerationType) {
  return component[remunerationType + 'Operation'] != null
}

// Filter recursively components to only include those with specific remuneration type
export function componentsForRemunerationType(components, remunerationType) {
  return components.map(component => {
    let linkedComponents
    if (component.linkedComponents) {
      linkedComponents = componentsForRemunerationType(component.linkedComponents, remunerationType)
    }
    return { ...component, linkedComponents }
  }).filter(component => componentHasRemunerationType(component, remunerationType))
}

export function gridFormulaForRemunerationType(grid, remunerationType) {
  if (!grid.components) {
    return {}
  }

  return componentsFormulaForRemunerationType(grid.components, remunerationType)
}

export function componentsFormulaForRemunerationType(components, remunerationType) {
  let formula = []
  let formulaOperation = null
  const allComponents = components.find(c => c.parentComponentId) ? components : flattenedComponents(components)

  for (const component of allComponents) {
    const operation = component[remunerationType + 'Operation']
    const item = { operation, ...component }
    if (operation) {
      if (operation !== formulaOperation && formulaOperation && formula.length > 1) {
        formula = [{ components: [].concat(formula), operation }]
        formula.push(item)
      }
      else {
        formula.push(item)
      }

      formulaOperation = operation
    }
  }

  return { components: formula, operation: formulaOperation }
}

// Returns all components and their linked components at the same level
export function flattenedComponents(components) {
  let allComponents = []

  if (components) {
    components.forEach(component => {
      if (!component.salaryOperation) {
        // Ignore legacy components without salary operation
        return
      }
      allComponents.push(component)
      if (component.linkedComponents && component.linkedComponents.length) {
        allComponents = allComponents.concat(flattenedComponents(component.linkedComponents))
      }
    })
  }

  return allComponents
}

// Get parent component
export function getParentComponent(component, grid) {
  const components = flattenedComponents(grid && grid.components)
  while (component.parentComponentId) {
    const parentComponent = components.find(c => c.id === component.parentComponentId)
    if (parentComponent) {
      component = parentComponent
    }
    else {
      break
    }
  }
  return component
}

// Returns components where a specific remuneration type can be added
export function reusableComponentsForRemunerationType(components, remunerationType) {
  if (components) {
    return components.filter(component => {
      return !component[remunerationType + 'Operation']
    })
  }

  return []
}

// Returns components that could have a linked component for a specific remuneration type
export function availableComponentsForLinkedComponent(components, remunerationType) {
  if (!components) {
    return []
  }

  return components.filter(component => {
    if (component[remunerationType + 'Operation'] != null) {
      return availableComponentsForLinkedComponent(component.linkedComponents, remunerationType).length === 0
    }

    return false
  })
}

export function remunerationTypesForComponent(component) {
  const remunerationTypesForComponent = []
  REMUNERATION_TYPES.forEach((type) => {
    if (component[type + 'Operation'] != null) {
      remunerationTypesForComponent.push(type)
    }
  })

  return remunerationTypesForComponent
}

export function remunerationOperationSymbol(operation) {
  if (operation === 'addition' || operation === 'percentage') {
    return '+'
  }

  if (operation === 'multiplier') {
    return '×'
  }

  if (operation === 'divider') {
    return '÷'
  }

  return '?'
}

export function remunerationOperationUnit(operation) {
  if (operation === 'addition') {
    return i18n.currencySymbol
  }

  if (operation === 'percentage') {
    return '%'
  }

  return ''
}

export function isSandboxGridGenerated(grid) {
  return grid && grid.components && grid.components.find(component =>
    component.levels.find(l => typeof l.salaryValue === 'number'))
}

export function isSandboxGridValid(grid) {
  if (!grid || !grid.components || grid.components.length === 0) {
    return false
  }

  return grid.components.every(isComponentValid)
}

function isComponentValid(component) {
  const remunerationTypes = remunerationTypesForComponent(component)

  if (remunerationTypes.length === 0) {
    return false
  }

  let isLinkedComponentsValid = true
  if (component.linkedComponents && component.linkedComponents.length > 0) {
    isLinkedComponentsValid = component.linkedComponents.every(isComponentValid)
  }

  return isLinkedComponentsValid && isLevelsValid(component.levels, remunerationTypes)
}

export function isSingleComponentValid(component) {
  const remunerationTypes = remunerationTypesForComponent(component)
  return isLevelsValid(getVisibleLevels(component && component.levels), remunerationTypes)
}

function isLevelsValid(levels, remunerationTypes) {
  if (!levels || levels.length === 0) {
    return false
  }

  return levels
    .filter(({ isHidden }) => !isHidden)
    .every((level) => {
      return remunerationTypes.every((remunerationType) => {
        return level[remunerationType + 'Value'] != null
      })
    })
}

// Returns wether the given wages are valid for the given grid
// a wage is valid when each wage level id belongs exactly to one component
export function isSandboxWagesValid(wages, grid) {
  if (!isSandboxGridValid(grid) || !wages) {
    return false
  }

  const allComponents = flattenedComponents(grid.components)

  return wages.every(wage => isWageValid(wage, allComponents))
}

// Returns wether the given wage is valid for the given grid
// a wage is valid when each wage level id belongs exactly to one component
export function isSandboxWageValid(wage, grid) {
  return isWageValid(wage, flattenedComponents(grid.components))
}

// return wether the wage looks like an empty wage or not
export function isSandboxWageEmpty(wage) {
  return !wage || (wage.hasLevels && !wage.levelIds.length)
}

function isWageValid(wage, allComponents) {
  if (!wage) {
    return false
  }

  if (wage.hasLevels) {
    if (!wage.levelIds || wage.levelIds.length !== allComponents.length) {
      return false
    }

    return allComponents.every(component => {
      const level = component.levels.find(({ id }) => wage.levelIds.includes(id))
      return level && !level.isHidden
    })
  }
  else {
    return !!wage.overridenSalaryValue
  }
}

// Returns true if wage values are identical
export function isWagesSimilar(oldWage, oldGrid, newWage, newGrid) {
  const oldWageDetails = getWageDetails(oldGrid, oldWage)
  const newWageDetails = getWageDetails(newGrid, newWage)

  return oldWageDetails.summary.value === newWageDetails.summary.value
}

// Returns true if wage values and level names are identical
export function isWagesIdentical(oldWage, oldGrid, newWage, newGrid) {
  const oldWageDetails = getWageDetails(oldGrid, oldWage)
  const newWageDetails = getWageDetails(newGrid, newWage)

  return oldWageDetails.summary.value === newWageDetails.summary.value &&
    (oldWageDetails.selectedComponents.map(c => c.selectedLevel.name).join() ===
      newWageDetails.selectedComponents.map(c => c.selectedLevel.name).join())
}

// Transfer wage levels and skills from one wage to another
// Returns an updated newWage (check it with isSandboxWageValid before sending it to the server)
export function transferWage(oldWage, oldGrid, newWage, newGrid) {
  try {
    if (!oldGrid || !newGrid) {
      return null
    }
    const normalizedOldGrid = normalizedGrid(oldGrid)
    const normalizedNewGrid = normalizedGrid(newGrid)
    const oldToNewLevelIds = Object.values(normalizedNewGrid.entities.levels).reduce((memo, level) => {
      if (level.previousLevelId) {
        memo[level.previousLevelId] = level.id
      }
      return memo
    }, {})
    const updatedNewWage = {
      ...newWage,
      hasLevels: oldWage.hasLevels,
      overridenSalaryValue: oldWage.overridenSalaryValue,
      levelIds: oldWage.levelIds.map(id => oldToNewLevelIds[id]).filter(k => k),
      skillIds: oldWage.skillIds.map(oldSkillId => {
        const oldSkill = normalizedOldGrid.entities.skills[oldSkillId]
        const [oldDomainId, oldDomain] = Object.entries(normalizedOldGrid.entities.domains).find(([_, domain]) => domain.skills && domain.skills.includes(oldSkillId))
        const oldLevel = Object.values(normalizedOldGrid.entities.levels).find(l => l.domains && l.domains.includes(oldDomainId))
        const newLevelId = oldToNewLevelIds[oldLevel.id]
        const newLevel = normalizedNewGrid.entities.levels[newLevelId]
        const newDomainId = newLevel.domains.find(domainId => normalizedNewGrid.entities.domains[domainId].index === oldDomain.index)
        const newDomain = normalizedNewGrid.entities.domains[newDomainId]
        const newSkillId = newDomain.skills.find(skillId => normalizedNewGrid.entities.skills[skillId].index === oldSkill.index)
        return newSkillId
      }).filter(k => k)
    }
    return updatedNewWage
  }
  catch (e) {
    console.log('Unable to transfer old wage', oldWage, 'to new wage', newWage)
    console.log('This can happen if the structure of the new grid (levels, skills) has changed compared to the old one.')
    console.log('Error:', e)
  }
}

// Hash wage based on qualification level names and salary value
export function hashWage(wage) {
  return hashSum({
    overridenSalaryValue: wage.overridenSalaryValue,
    qualification: wage.qualification && wage.qualification.map(q => q.name)
  })
}

// Same getWageDetails but is able to read the embedded grid in the wage
export function getEmbeddedWageDetails(wage, options) {
  const embeddedGrid = { ...wage.grid }
  if (wage.hasLevels) {
    embeddedGrid.components = wage.levels
      .map(level => ({ ...level.component, levels: [level] }))
  }
  return getWageDetails(embeddedGrid, wage, options)
}

// Returns an object which contains for each component the selected level
// for the wage and a summary.
// Options can contain:
// [Performance]
// - salaryOnly: if true:
//     * salary will be read from wage instead of computing it
//     * components will not be returned
//     * Disabled if simulationLevels, nextLevelProgress or acquiredSkills is provided
// - includeComponents: if true, will clone component and filter levels
// [Options]
// - simulationLevels: if provided, these levels will replaces the selected ones
// - equivalenceLevels: if provided, these levels will apply these ones instead, even if salary is overriden
// - nextLevelProgress: if true, the percent of skills checked of next level will be computed
// - acquiredSkills: if true describe acquired skills
// - includeVariable: if true, the target variable value will be added to the total
// - includeSelectedSkills: if true, the selected skills will be added, next to selectedLevel
export function getWageDetails(grid, wage, options = {}) {
  const allComponents = flattenedComponents(grid ? grid.components : [])
  const components = []
  const selectedComponents = []
  const summary = {
    salary: { value: 0, unit: '€', fixedValue: 0, variableValue: 0 }
  }
  const salarySummary = summary.salary

  // Default value
  if (!wage) {
    return { selectedComponents, components, summary }
  }

  // Warn if grid looks strange
  if (wage.gridId && grid && wage.gridId !== grid.id) {
    console.warn(`Wage Grid mismatch for user ${wage.employeeId}`, wage, grid)
  }

  // Init options
  options = options || {}

  // Debug
  // console.time(`getWageDetails (${JSON.stringify(options)})`)

  // Option: salary only ⚡
  if (options.salaryOnly &&
    typeof wage.salaryValue === 'number' &&
    !options.simulationLevels &&
    !options.equivalenceLevels &&
    !options.nextLevelProgress &&
    !options.acquiredSkills) {
    salarySummary.value = wage.salaryValue
    salarySummary.delta = wage.salaryDelta
  }

  // Compute components, selected components & salary summary
  else if (wage.hasLevels) {
    allComponents.forEach(c => {
      let component = { id: c.id, name: c.name, ref: c.ref }
      let selectedLevel = findComponentLevel(c, wage.levelIds)

      // Option: include components ⚡
      if (options.includeComponents) {
        component = {
          ...c,
          levels: selectedLevel
          // Faster
            ? getLinkedLevels(c.levels, selectedLevel.linkedLevelId ? { id: selectedLevel.linkedLevelId } : null)
            // Slower
            : c.levels.filter(level => !level.linkedLevelId || wage.levelIds.includes(level.linkedLevelId))
        }

        components.push(component)
      }

      // Option: replace selected level by simulation levels
      if (options.simulationLevels && options.simulationLevels.length && selectedLevel) {
        const selectedSimulationLevel = options.simulationLevels.find(l => l.id === selectedLevel.id)
        if (selectedSimulationLevel) {
          selectedLevel = selectedSimulationLevel
        }
      }

      // Option: compute next level progress based on skills [0, 1[
      if (options.nextLevelProgress && c.hasSkills && selectedLevel) {
        const wageSkillIds = new Set(wage.skillIds)
        const nextLevel = c.levels.find(l => l.rank === selectedLevel.rank + 1)
        if (nextLevel) {
          let skillsCount = 0
          let wageSkillsCount = 0
          for (const domain of nextLevel.domains) {
            for (const skill of domain.skills) {
              skillsCount++
              if (wageSkillIds.has(skill.id)) {
                wageSkillsCount++
              }
            }
          }
          if (skillsCount) {
            component.nextLevelProgress = wageSkillsCount / skillsCount
          }
        }
      }

      // Option: describe acquired skills
      if (options.acquiredSkills && c.hasSkills && selectedLevel) {
        const wageSkillIds = new Set(wage.skillIds)
        component.acquiredSkills = c.levels.reduce((memo, level) => {
          memo += `${level.name}\r\n`
          for (const domain of level.domains) {
            memo += `${domain.name}\r\n`
            for (const skill of domain.skills) {
              memo += `[${wageSkillIds.has(skill.id) ? 'X' : ' '}] ${skill.name}\r\n`
            }
          }
          memo += '\r\n'
          return memo
        }, '')
      }

      // Option: includeSelectedSkills
      if (options.includeSelectedSkills) {
        if (c.hasSkills) {
          const componentSkillIds = getSkillIdsFromLevels(component.levels)
          component.selectedSkills = wage.skillIds.filter(id => componentSkillIds.includes(id))
          component.selectedSkillValues = component.hasSkillValues ? pick(wage.skillValues, componentSkillIds) : {}
        }
        else {
          component.selectedSkills = []
          component.selectedSkillValues = {}
        }
      }

      if (selectedLevel) {
        const operation = c.salaryOperation
        const salaryValue = selectedLevel.salaryValue

        // Compute salary
        salarySummary.value = valueFromOperation(
          salarySummary.value,
          salaryValue,
          operation)

        // Init interval
        if (isInterval(c, false)) {
          salarySummary.interval = {
            min: selectedLevel.minimumValue,
            med: selectedLevel.salaryValue,
            max: selectedLevel.maximumValue
          }

          salarySummary.value = getIntervalStartValue(c, selectedLevel)
        }

        // Update interval bounds
        if (isIntervalBoundsScope(c) && salarySummary.interval) {
          salarySummary.interval.min = valueFromOperation(
            salarySummary.interval.min,
            salaryValue,
            operation)
          salarySummary.interval.med = valueFromOperation(
            salarySummary.interval.med,
            salaryValue,
            operation)
          salarySummary.interval.max = valueFromOperation(
            salarySummary.interval.max,
            salaryValue,
            operation)
        }

        // Update interval steps
        if (isIntervalStepsScope(c)) {
          salarySummary.value = salaryValue
        }

        // Add to selected components
        component.selectedLevel = selectedLevel
        selectedComponents.push(component)
      }
    })

    // Compute overriden salary stats
    if (typeof wage.overridenSalaryValue === 'number') {
      salarySummary.delta = wage.overridenSalaryValue - salarySummary.value
      salarySummary.value = wage.overridenSalaryValue
    }

    // Compute interval stats
    if (salarySummary.interval) {
      salarySummary.interval.percent =
      salarySummary.value >= salarySummary.interval.med
        ? 0.5 + 0.5 * (salarySummary.value - salarySummary.interval.med) /
        (salarySummary.interval.max - salarySummary.interval.med)
        : 0.5 * (salarySummary.value - salarySummary.interval.min) /
        (salarySummary.interval.med - salarySummary.interval.min)
      salarySummary.interval.isOutside = salarySummary.interval.percent < 0 || salarySummary.interval.percent > 1
    }

    // Option: equivalence levels (map of ref -> level id)
    if (options.equivalenceLevels) {
      allComponents.slice().reverse().forEach(component => {
        const equivalenceLevelId = options.equivalenceLevels[component.ref]
        if (equivalenceLevelId) {
          const equivalenceLevel = findComponentLevel(component, [equivalenceLevelId])
          const selectedComponent = selectedComponents.find(c => c.id === component.id)
          const selectedLevel = selectedComponent && selectedComponent.selectedLevel
          if (equivalenceLevel && selectedLevel && equivalenceLevel.id !== selectedLevel.id) {
            salarySummary.value = valueFromOperation(
              valueFromReverseOperation(
                salarySummary.value,
                selectedLevel.salaryValue,
                component.salaryOperation),
              equivalenceLevel.salaryValue,
              component.salaryOperation)
          }
        }
        // Disable salary delta if equivalence levels are provided
        salarySummary.delta = 0
      })
    }
  }
  else if (typeof wage.overridenSalaryValue === 'number') {
    salarySummary.value = wage.overridenSalaryValue
  }

  // Check computed salary conformity
  if (wage.salaryValue && wage.salaryValue !== salarySummary.value && !options.simulationLevels && !options.equivalenceLevels) {
    console.warn(`Wage salary mismatch for user ${wage.employeeId}`, wage, salarySummary.value, wage.salaryValue)
  }

  // Compute fixed & variable value
  salarySummary.fixedValue = salarySummary.value
  salarySummary.variableValue = wage.contractualVariableValue || 0
  if (options.includeVariable && wage.contractualVariableValue) {
    salarySummary.value += wage.contractualVariableValue
  }

  // Debug
  // console.timeEnd(`getWageDetails (${JSON.stringify(options)})`)

  return { selectedComponents, components, summary }
}

// Returns total remuneration value of given wages
export function getWagesDetailsSummary(summaries) {
  return summaries.reduce((memo, summary) => {
    memo.salary.value += summary.salary.value
    memo.salary.delta += summary.salary.delta
    return memo
  }, {
    salary: {
      value: 0,
      unit: '€'
    }
  })
}

// Find component level quickly by caching level position in array
// If found level doesn't match, reset cache and find manually
// [componentId][levelId] = index in component.levels array
const componentLevelsCache = {}
function findComponentLevel(component, levelIds) {
  if (!component || !levelIds.length) {
    return
  }
  let selectedLevel
  if (component.levels.length > 1) {
    // Build cache if empty
    componentLevelsCache[component.id] = componentLevelsCache[component.id] || {}
    if (!Object.keys(componentLevelsCache[component.id]).length) {
      component.levels.forEach((level, i) => {
        componentLevelsCache[component.id][level.id] = i
      })
    }
    // Search level ids in cache
    for (const levelId of levelIds) {
      const selectedLevelIndex = componentLevelsCache[component.id][levelId]
      selectedLevel = component.levels[selectedLevelIndex]
      if (selectedLevel) {
        // If found, ensure level id matchs
        if (selectedLevel.id !== levelId) {
          // If not, empty cache and fallback on sequential search
          delete componentLevelsCache[component.id]
          selectedLevel = null
        }
        break
      }
    }
  }
  if (!selectedLevel) {
    selectedLevel = component.levels.find(level => levelIds.includes(level.id))
  }
  return selectedLevel
}

// Return true component with skills have proper skills
// Return true if no component has skills
export function gridHasSkills(grid) {
  return flattenedComponents(grid.components).reduce((memo, component) => {
    if (component.hasSkills) {
      component.levels.filter(({ isHidden }) => !isHidden).forEach(level => {
        memo = memo &&
          level.domains &&
          !!level.domains.find(domain => domain.skills.filter(s => !!s.name).length)
      })
    }
    return memo
  }, true)
}

export function getLevelName(componentRef, count, longLevel = false) {
  return i18n.tc(`templates.clearwage.components.${
    COMPONENT_REFS.includes(componentRef) ? componentRef : 'default'
  }.${
    longLevel ? 'longLevels' : 'levels'
  }`, count, { count })
}

// Returns the public name of the grid (stored in grid.title)
// Defaults to "Grille de salaire de Mon entreprise"
export function getGridTitle(grid, companyName) {
  return (grid && grid.title) || i18n.t('grid.current.longTitle', { company: companyName })
}

export function getGridVersion(grid, currentGrid) {
  if (currentGrid) {
    return grid.id === currentGrid.id ? currentGrid.version : currentGrid.version + 1
  }
  else {
    return 1
  }
}

// Returns interval component from wageDetails
export function getIntervalComponent(wageDetails) {
  return wageDetails && wageDetails.components.find(c => c.config && c.config.interval)
}

// Returns true if interval mode is enabled in one of the components' config
export function isGridInterval(components) {
  return components && components.length && !!components.find(component => isInterval(component))
}

// Returns true if interval mode is enabled in the component's config
export function isInterval(component, recursive = true) {
  return (component && component.config && component.config.interval) ||
    (recursive && component && component.linkedComponents && component.linkedComponents[0] &&
      component.linkedComponents[0].config && component.linkedComponents[0].config.interval)
}

// Returns true if the median of interval isn't the average
export function isIntervalAsymmetric(component) {
  return (component && component.config && component.config.intervalAsymmetric) ||
    (component && component.linkedComponents && component.linkedComponents[0] &&
      component.linkedComponents[0].config && component.linkedComponents[0].config.intervalAsymmetric)
}

// Returns true if the start position on the interval is the median instead of minimum
export function isIntervalStartPositionMedian(component) {
  return component && component.config && component.config.intervalStartPosition === 'median'
}

// Returns interval start value (min or median depending on config)
function getIntervalStartValue(component, level) {
  const isStartPositionMedian = isIntervalStartPositionMedian(component)
  return isStartPositionMedian ? level.salaryValue : level.minimumValue
}

// Apply scoped components to a value, based on wage levels
// Usage: compute interval start position, compute interval…
export function getComponentScopeValue(components, wage, value, scope) {
  if (!components || !wage || !wage.hasLevels) {
    return value
  }
  components
    .filter(c => c.scope === scope)
    .forEach(component => {
      const componentLevel = component.levels.find(l => wage.levelIds.includes(l.id))
      if (componentLevel) {
        value = valueFromOperation(value, componentLevel.salaryValue, component.salaryOperation)
      }
    })
  return value
}

// Returns true if component impacts interval bounds
export function isIntervalBoundsScope(component) {
  return component.scope === 'intervalBounds'
}

// Returns true if component resets salary value and define interval steps
export function isIntervalStepsScope(component) {
  return component.scope === 'intervalSteps'
}

// Returns the first linked component, if it exists
export function getLinkedComponent(component) {
  return component && component.linkedComponents && component.linkedComponents.length
    ? component.linkedComponents[0]
    : null
}

// Returns filtered list of levels based on linked level
export function getLinkedLevels(levels, linkedLevel) {
  return (levels || []).filter(l => !linkedLevel || !l.linkedLevelId || l.linkedLevelId === linkedLevel.id)
}

// Returns a list of visible levels, preserving the selected level if available
export function getVisibleLevels(levels, selectedLevel = null) {
  return (levels || []).filter(l => !l.isHidden || (selectedLevel && l.id === selectedLevel.id))
}

// The power of getLinkedLevels & getVisibleLevels!
export function getVisibleLinkedLevels(levels, linkedLevel) {
  return getLinkedLevels(getVisibleLevels(levels), linkedLevel)
}
