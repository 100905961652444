<template>
  <div class="wrapper">
    <div class="svg-container" :class="{loading: isLoading}">
      <LoadingView v-if="isLoading" />
      <svg :height="viewport.height" ref="svgNode"></svg>
      <GraphLegend
        id="payroll"
        :graph="graph"
        v-model="offset"
        @input="render" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as d3 from 'd3'
import debounce from 'lodash.debounce'
import GraphLegend from '@components/graph/GraphLegend.vue'
import LoadingView from '@components/commons/LoadingView'
import { truncateLabel } from '@/utils/graph'
import { formatDate, normalizeDate } from '@/utils/date'
import i18n from '@/i18n'
import { titleize } from '@/utils/string'
import orderBy from 'lodash.orderby'
import uniq from 'lodash.uniq'

function isYearTick(text) {
  return text.split('-')[1] === '01'
}

function formatAbscissaLabel(text) {
  const [year, month] = text.split('-')
  if (isYearTick(text)) {
    return year
  }
  else if (['04', '07', '10'].includes(month)) {
    return i18n.t('dashboard.graphs.evolution.q', { quarter: ['04', '07', '10'].indexOf(month) + 2 })
  }
}

function initGraph(svg, viewport) {
  const node = {}
  node.svg = d3.select(svg)
  node.viewport = node.svg.append('g')
    .attr('class', 'viewport')
  node.background = node.viewport.append('rect')
    .attr('class', 'background')
  node.abscissa = node.viewport.append('g')
    .attr('class', 'axis abscissa')
  node.ordinate = node.viewport.append('g')
    .attr('class', 'axis ordinate')
  node.annotations = node.viewport.append('g')
    .attr('class', 'annotations')
    .style('opacity', 0)
    .transition()
    .delay(1000)
    .style('opacity', 1)
    .selection()
  node.cursors = node.viewport.append('g')
    .attr('class', 'cursors')
    .style('opacity', 0)
    .transition()
    .delay(1000)
    .style('opacity', 1)
    .selection()
  node.bars = node.viewport.append('g')
    .attr('class', 'bars')
  node.tooltip = node.viewport.append('g')
    .attr('class', 'tooltip')

  return {
    node: node,
    viewport: viewport,
    data: [],
    truncation: {
      infinite: true
    }
  }
}

const LABELS_ALIGN_TOP = 20

function getCurrentMonth() {
  return normalizeDate(new Date((new Date()).getFullYear(), (new Date()).getMonth()))
}

function getMonth(normalizedDate) {
  return normalizedDate ? normalizedDate.slice(0, 7) + '-01' : getCurrentMonth()
}

function renderInteractiveLayer(graph, component) {
  // Modes:
  // - 0: stuck cursor
  // - 1: moving cursor
  // - 2: moving diff cursor
  // - 3: stuck diff cursor
  // Repeat!
  const node = graph.node
  let currentMode = 0
  const currentMonth = getCurrentMonth()
  let latestMonth, clickedMonth

  function moveCursor(month) {
    const monthIndex = node.x.domain().indexOf(month)
    if (monthIndex !== -1 && month !== latestMonth) {
      let cursorCirclesData, cursorX
      const cursorsData = uniq([month, clickedMonth].filter(m => m)).sort().map(month => ({
        x: month,
        y: graph.seriesData[graph.seriesData.length - 1][node.x.domain().indexOf(month)][1]
      }))

      // Mode highlights
      let highlights = node.tooltip.select('.highlights')
      if (!highlights.size()) {
        highlights = node.tooltip.append('g')
          .attr('class', 'highlights')
      }
      let highlightData = []
      let cursorDistance = 0
      if (currentMode > 1 && cursorsData.length > 1) {
        highlightData = [{ x0: node.x.domain()[0], x1: cursorsData[0].x }, { x0: cursorsData[1].x, x1: node.x.domain()[node.x.domain().length - 1] }]
        cursorDistance = node.x(highlightData[1].x0) - node.x(highlightData[0].x1)
      }
      const highlight = highlights.selectAll('.highlight')
        .data(highlightData)
      highlight
        .enter().append('rect')
        .attr('class', 'highlight')
        .merge(highlight)
        .attr('x', (d, i) => node.x(d.x0) + (i > 0 ? node.x.bandwidth() : 0))
        .attr('y', node.y.range()[0])
        .attr('width', (d, i) => node.x(d.x1) - node.x(d.x0))
        .attr('height', node.y.range()[1] - node.y.range()[0])
      highlight.exit()
        .remove()

      // Cursors
      const cursors = node.cursors.selectAll('.cursor')
        .data(cursorsData)
      cursors.exit()
        .remove()
      cursors
        .enter().append('g')
        .attr('class', 'cursor')
        .merge(cursors)
        .each(function(cursorData, i) {
          // Group
          const cursorIndex = node.x.domain().indexOf(cursorData.x)
          cursorX = node.x(cursorData.x) + node.x.bandwidth() / 2

          const cursor = d3.select(this)
            .attr('transform', `translate(${cursorX},0)`)

          // Cursor line
          if (!cursor.select('line').size()) {
            cursor.append('line')
          }
          cursor.select('line')
            .attr('x1', 0)
            .attr('x2', 0)
            .attr('y1', node.y.range()[0])
            .attr('y2', node.y(cursorData.y) - 5)

          // Cursor header
          if (!cursor.select('text').size()) {
            cursor.append('text')
              .attr('y', node.y.range()[0])
          }
          cursor.select('text')
            .text(
              cursorData.x === month && cursorDistance > 0 && cursorDistance < 60
                ? ''
                : (cursorData.x === currentMonth ? titleize(i18n.t('date.today')) : formatDate(cursorData.x, 'MM/YYYY')))
            .transition()
            .attr('y', node.y.range()[0])

          // Cursor circles
          cursorCirclesData = graph.seriesData.reduce((memo, serie) => {
            const point = serie[cursorIndex]
            const value = point[1] - point[0]
            const previousIndex = i > 0 ? node.x.domain().indexOf(cursorsData[i - 1].x) : cursorIndex - 1
            const previousPoint = previousIndex >= 0 ? serie[previousIndex] : point
            const previousValue = previousPoint[1] - previousPoint[0]
            const diff = value - previousValue
            const employeesCount = point.data.y[serie.key].employeeIds.length
            if (point && value) {
              memo.push({
                id: serie.key,
                y: node.y(point[1]),
                value,
                diff,
                isDiffing: i > 0,
                cursorIndex,
                previousIndex,
                label: ((i > 0) ? '' : employeesCount + ' ') + (serie.key || i18n.t('dashboard.graphs.defaultValue'))
              })
            }
            return memo
          }, [])
          cursorCirclesData.total = {
            value: d3.sum(cursorCirclesData, d => d.value),
            diff: d3.sum(cursorCirclesData, d => d.diff),
            isDiffing: i > 0
          }
        })

      // Legend
      const legendWith = 240
      let legend = node.tooltip.select('.legend')
      if (!legend.size()) {
        legend = node.tooltip.append('g')
          .attr('class', 'legend')
        legend.append('rect')
          .attr('class', 'legend-rect')
          .attr('fill', 'white')
          .style('opacity', 0.94)
          .attr('y', -20)
      }
      legend.select('.legend-rect')
        .attr('width', legendWith)
        .attr('height', node.y.range()[1] + 20)

      // Cursor legend rect
      const isRight = cursorX < node.x.range()[1] - (legendWith + 25)
      legend
        .attr('transform', `translate(${isRight ? node.x.range()[1] - legendWith : 0},0)`)

      // Legend title
      const legendTitle = legend.selectAll('.legend-title')
        .data([{ label: month }])
      legendTitle
        .enter().append('text')
        .attr('class', 'legend-title')
        .merge(legendTitle)
        .attr('x', 20)
        .attr('y', -10)
        .text(cursorsData.map(({ x }) => titleize(formatDate(x, 'MMMM YYYY'))).join(' → '))
      legendTitle.exit()
        .remove()

      // Legend footer
      const legendFooter = node.tooltip.selectAll('.legend-footer')
        .data([{}])
      legendFooter
        .enter().append('text')
        .attr('class', 'legend-footer')
        .merge(legendFooter)
        .attr('x', node.x.range()[1] - 5)
        .attr('y', node.y(0) + 16)
        .text([0, 3].includes(currentMode)
          ? i18n.t('dashboard.graphs.evolution.clickToNavigate')
          : ([1].includes(currentMode)
              ? i18n.t('dashboard.graphs.evolution.clickToCompare')
              : ''))
      legendFooter.exit()
        .remove()

      // Legend content
      const legendLimit = 12
      const legendData = cursorCirclesData.total.isDiffing
        ? orderBy(cursorCirclesData.filter(d => d.diff), ['diff'], ['desc']).slice(0, legendLimit)
        : orderBy(cursorCirclesData, ['value'], ['desc']).slice(0, legendLimit)

      legendData.unshift({
        id: node.color.domain()[0],
        value: cursorCirclesData.total.value,
        diff: cursorCirclesData.total.diff,
        isDiffing: cursorCirclesData.total.isDiffing,
        label: i18n.t('dashboard.graphs.payroll.salaries')
      })

      if (legendData.length === legendLimit + 1) {
        legendData.push({
          id: node.color.domain()[0],
          value: '',
          label: '…'
        })
      }

      const legendLineHeight = legendData.length > 10 ? 23 : 27
      const legendFigures = legend.selectAll('.legend-figure')
        .data(legendData, d => d.id)
      legendFigures.enter().append('text')
        .attr('class', 'legend-figure')
        .merge(legendFigures)
        .attr('x', 90)
        .attr('y', (_, i) => {
          return 12 + (i > 0 ? 3 : 0) + i * legendLineHeight
        })
        .attr('fill', d => node.color(d.id))
        .text(d => d.isDiffing
          ? component.$options.filters.formattedDeltaNumber(d.diff)
          : (d.value !== '' ? component.$options.filters.formattedCurrency(d.value) : ''))
      legendFigures.exit()
        .remove()

      // Legend text
      const legendText = legend.selectAll('.legend-text')
        .data(legendData, d => d.id)
      legendText.enter().append('text')
        .attr('class', (_, i) => 'legend-text' + (i === 0 ? ' legend-total' : ''))
        .on('click', function(event, d) {
          event.stopPropagation()
          const { cursorIndex, previousIndex } = d
          const serie = graph.seriesData.find(s => s.key === d.id)
          const point = serie[cursorIndex]
          const previousPoint = serie[previousIndex]
          if (!point || !previousPoint) {
            return
          }
          const employeeIds = point.data.y[serie.key].employeeIds
          const previousEmployeeIds = d.isDiffing ? previousPoint.data.y[serie.key].employeeIds : []
          const allEmployeeIds = uniq([...employeeIds, ...previousEmployeeIds])
          if (allEmployeeIds.length) {
            const query = component.$store.getters['employees/getEmployeesByIds'](allEmployeeIds)
              .map(({ firstName, lastName }) => [firstName, lastName].join(' ')).join(', ')
            component.$router.push({ name: 'employees', params: { query } })
          }
        })
        .merge(legendText)
        .attr('x', 100)
        .attr('y', (_, i) => {
          return 13 + (i > 0 ? 3 : 0) + i * legendLineHeight
        })
        .text(d => truncateLabel(d.label, 21))
      legendText.exit()
        .remove()

      // Save latest month to avoid recomputing on mouse move
      latestMonth = month
    }
  }

  function mouseMove(event) {
    if ([0, 3].includes(currentMode)) {
      return
    }
    const [x] = d3.pointer(event, node.viewport.node())
    const monthIndex = Math.max(Math.min(Math.round(((x - node.x.bandwidth() / 2) / node.x.step())), node.x.domain().length - 1), 0)
    const month = node.x.domain()[monthIndex]

    moveCursor(month)
  }

  function mouseClick(event) {
    switch (currentMode) {
      case 3:
        node.viewport.style('cursor', 'pointer')
        currentMode = 0
        clickedMonth = null
        initCursor()
        break
      case 2:
        node.viewport.style('cursor', 'default')
        currentMode++
        break
      case 1:
        node.viewport.style('cursor', 'col-resize')
        clickedMonth = latestMonth
        currentMode++
        break
      default:
        node.viewport.style('cursor', 'col-resize')
        currentMode++
        mouseMove(event)
    }
  }

  function initCursor() {
    let defaultMonth = currentMonth
    if (!node.x.domain().includes(defaultMonth)) {
      defaultMonth = node.x.domain()[12 * 3]
    }
    moveCursor(defaultMonth)
  }

  // Init events & cursor
  if (!latestMonth) {
    // Listen mouse events
    node.viewport
      .style('cursor', 'pointer')
      .on('mousemove', mouseMove)
      .on('click', mouseClick)

    initCursor()
  }
}

function renderGraph(graph, component) {
  if (!graph.node.svg.node()) {
    return
  }
  const viewport = graph.viewport
  const node = graph.node

  // Viewport
  viewport.width = graph.node.svg.node().getBoundingClientRect().width
  viewport.innerWidth = viewport.width - viewport.padding.left - viewport.padding.right
  viewport.innerHeight = viewport.height - viewport.padding.top - viewport.padding.bottom

  // Annotation padding
  const annotationHeight = 20
  const annotationPadding = graph.annotations.length * annotationHeight

  // Graph Axes
  node.series = d3.stack()
    .keys(graph.domains.z)
    .value((d, key) => d.y[key].value)
    .order(d3.stackOrderNone)
    .offset(d3.stackOffsetNone)
  graph.seriesData = node.series(graph.data)
  node.x = d3.scaleBand(graph.domains.x, [0, viewport.innerWidth])
    .padding([0.1])
  node.y = d3.scaleLinear([1.1 * (d3.max(graph.seriesData.flat(2)) || 1), 0], [annotationPadding, viewport.innerHeight])
  if (!node.color || graph.reset) {
    node.bars.selectAll('.series').remove()
    graph.reset = false
    graph.init = true
    node.color = d3.scaleOrdinal(graph.domains.z, d3.schemeTableau10)
  }

  graph.axes = {
    abscissa: {
      type: 'discrete',
      labels: 'belowAxis',
      axis: node.x
    },
    ordinate: {
      type: 'continuous',
      labels: 'beforeAxis',
      axis: node.y,
      format: 'd'
    }
  }

  // Viewport background
  node.viewport.attr('transform',
    `translate(${viewport.padding.left},${viewport.padding.top})`)
  node.background.attr('width', viewport.innerWidth)
    .attr('height', viewport.innerHeight)

  // Axes Labels
  const abscissaLabelsData = graph.data.filter(d => formatAbscissaLabel(d.x))
  const abscissaLabels = node.abscissa.selectAll('.label')
    .data(abscissaLabelsData.slice(0, -3), d => d.x)
  abscissaLabels
    .enter().append('text')
    .attr('class', d => 'label wrap text-' + (formatAbscissaLabel(d.x).length))
    .attr('x', d => node.x(d.x) - 5)
    .attr('y', _ => node.y(0) + LABELS_ALIGN_TOP)
    .style('opacity', 0)
    .merge(abscissaLabels)
    .transition()
    .attr('x', d => node.x(d.x) - 3)
    .attr('y', _ => node.y(0) + LABELS_ALIGN_TOP)
    .style('opacity', 1)
    .text(d => formatAbscissaLabel(d.x))
  abscissaLabels.exit()
    .transition()
    .style('opacity', 0)
    .remove()

  const abscissaTicks = node.abscissa.selectAll('.tick')
    .data(abscissaLabelsData, d => d.x)
  abscissaTicks
    .enter().append('line')
    .attr('x1', d => node.x(d.x) - 1)
    .attr('y1', _ => node.y(0))
    .attr('x2', d => node.x(d.x) - 1)
    .attr('y2', _ => node.y(0))
    .merge(abscissaTicks)
    .transition()
    .attr('class', d => 'tick ' + (isYearTick(d.x) ? 'year' : 'month'))
    .attr('x1', d => node.x(d.x) - 1)
    .attr('y1', _ => node.y.range()[0])
    .attr('x2', d => node.x(d.x) - 1)
    .attr('y2', _ => node.y(0))
  abscissaTicks.exit()
    .remove()

  // Stacked Bars
  const t = node.svg.transition().duration(graph.seriesData.length < 20 ? 300 : 0)
  node.bars.selectAll('.series')
    .data(graph.seriesData, d => d.key)
    .join(
      enter => enter.append('g')
        .attr('class', 'series')
        .style('opacity', 0.85)
        .attr('fill', ({ key }) => node.color(key)),
      update => update
        .attr('fill', ({ key }) => node.color(key)),
      exit => exit.call(exit => exit.selectAll('rect')
        .transition(t)
        .style('opacity', 0)
        .remove())
        .transition(t)
        .remove()
    )
    .selectAll('rect')
    .data(d => d)
    .join(
      enter => enter.append('rect')
        .attr('x', ({ data }) => node.x(data.x))
        .attr('y', ([y1, y2]) => graph.init ? node.y(0) : Math.min(node.y(y1), node.y(y2)) + Math.abs(node.y(y1) - node.y(y2)))
        .attr('height', 0)
        .attr('width', node.x.bandwidth())
        .call(update => update.transition(t)
          .delay((_, i) => i * 7)
          .attr('y', ([y1, y2]) => Math.min(node.y(y1), node.y(y2)))
          .attr('height', ([y1, y2]) => Math.abs(node.y(y1) - node.y(y2)))
        ),
      update => update.call(update => update.transition(t)
        .attr('y', ([y1, y2]) => Math.min(node.y(y1), node.y(y2)))
        .attr('height', ([y1, y2]) => Math.abs(node.y(y1) - node.y(y2)))
      ),
      exit => exit.call(exit => exit.transition(t)
        .style('opacity', 0)
        .remove()
      )
    )

  // Today annotation
  const currentMonth = getCurrentMonth()
  const todayData = node.x.domain().includes(currentMonth) ? [{ x: currentMonth }] : []
  const today = node.cursors.selectAll('.today')
    .data(todayData)
  today
    .enter().append('line')
    .attr('class', 'today')
    .merge(today)
    .attr('x1', d => node.x(d.x) + node.x.bandwidth() / 2)
    .attr('y1', _ => node.y.range()[0])
    .attr('x2', d => node.x(d.x) + node.x.bandwidth() / 2)
    .attr('y2', _ => node.y(0))
  today.exit()
    .remove()

  // Annotations
  const annotationsData = graph.annotations
    .map(d => ({ ...d, startDate: getMonth(d.startDate) }))
    .filter(d => node.x.domain().includes(d.startDate))
    .reverse()
  const annotations = node.annotations.selectAll('.annotation')
    .data(annotationsData, d => d.id)
  annotations.exit()
    .remove()
  annotations
    .enter().append('g')
    .attr('class', 'annotation cursor')
    .merge(annotations)
    .each(function(annotationData, i) {
      i = annotationsData.length - 1 - i
      const annotationMonth = annotationData.startDate
      const annotationX = node.x(annotationMonth) + node.x.bandwidth() / 2
      const annotationY = graph.seriesData[graph.seriesData.length - 1][node.x.domain().indexOf(annotationMonth)][1]

      const annotation = d3.select(this)
        .attr('transform', `translate(${annotationX},0)`)

      // Annotation line
      if (!annotation.select('line').size()) {
        annotation.append('line')
      }
      annotation.select('line')
        .attr('x1', 0)
        .attr('y1', node.y.range()[0] - (i + 1) * annotationHeight)
        .attr('x2', 0)
        .attr('y2', node.y(annotationY) - 5)

      // Annotation name
      if (!annotation.select('text').size()) {
        annotation.append('text')
      }
      annotation.select('text')
        .text(annotationData.name)
        .transition()
        .attr('y', node.y.range()[0] - (i + 1) * annotationHeight)
    })

  // Mouse hover
  renderInteractiveLayer(graph, component)

  // Disable init animations
  graph.init = false
}

export default {
  components: {
    GraphLegend,
    LoadingView
  },
  name: 'payroll-graph',
  data() {
    return {
      // Options
      viewport: {
        width: 0, // Computed
        height: 400,
        innerWidth: 0, // Computed
        innerHeight: 0, // Computed
        padding: {
          left: 24,
          right: 15,
          top: 35,
          bottom: 40
        }
      },
      // State
      graph: null,
      resizeFn: null,
      isLoading: false,
      loadingTimeout: null,
      offset: -1
    }
  },
  computed: {
    ...mapGetters({
      annotations: 'statistics/getAnnotations',
      employees: 'statistics/getEmployeesAndAlumnis',
      filters: 'statistics/getUsableFilters',
      options: 'statistics/getCurrentOptions',
      scope: 'statistics/getCurrentScope'
    })
  },
  mounted() {
    this.init()
    setTimeout(this.render, 400)
    this.resizeFn = debounce(this.render, 100)
    window.addEventListener('resize', this.resizeFn)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeFn)
  },
  watch: {
    employees: 'render',
    filters: 'render',
    scope: 'resetAndRender'
  },
  methods: {
    init() {
      this.graph = initGraph(this.$refs.svgNode, this.viewport)
    },
    resetAndRender() {
      this.graph.reset = true
      this.render()
    },
    async render() {
      if (this.employees) {
        await this.generatePayrollModel(this.graph, this.employees, this.offset)
        console.time('renderGraph')
        renderGraph(this.graph, this)
        console.timeEnd('renderGraph')
      }
    },
    async generatePayrollModel() {
      clearTimeout(this.loadingTimeout)
      this.loadingTimeout = setTimeout(() => {
        this.isLoading = true
      }, 300)
      try {
        const employeesNewsfeeds = await this.$store.dispatch('statistics/getEmployeesNewsfeeds')
        const { annotations, employees, filters, offset, options, scope } = this
        const { domains, timeline } = await this.$store.dispatch('workers/generatePayrollModel', { employees, employeesNewsfeeds, filters, offset, options, scope })
        this.graph.annotations = annotations
        this.graph.domains = domains
        this.graph.data = timeline
      }
      finally {
        clearTimeout(this.loadingTimeout)
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
@import "./src/styles/graph.scss";

.svg-container .loading {
  position: absolute;
  height: 400px;
  background: rgb(255 255 255 / 50%);
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

svg::v-deep .abscissa .label {
  text-anchor: start;

  &.text-2 {
    fill: $light-text-color;
  }

  &.text-4 {
    @include font-semibold;
    fill: $graph-text-color;
  }
}

svg::v-deep .ordinate .label {
  text-anchor: end;
  alignment-baseline: middle;
  fill: $light-text-color;
}

svg::v-deep .abscissa .tick {
  shape-rendering: crispedges;
  stroke: $graph-inner-border-color;
  opacity: 1;

  &.month {
    stroke-dasharray: 3 3;
  }
}

svg::v-deep .ordinate .tick {
  shape-rendering: crispedges;
  stroke: $graph-inner-border-color;
}

svg::v-deep .areas path {
  opacity: 0.3;
}

svg::v-deep .lines path {
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
}

svg::v-deep .cursor {
  line {
    stroke: $red-color;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  text {
    @include font-small-size;
    @include font-semibold;
    fill: $red-color;
    text-anchor: middle;
    transform: translate(0, -10px);
    // Text stroke of 4px
    text-shadow: rgb(255, 255, 255) 4px 0px 0px,
      rgb(255, 255, 255) 3.87565px 0.989616px 0px,
      rgb(255, 255, 255) 3.51033px 1.9177px 0px,
      rgb(255, 255, 255) 2.92676px 2.72656px 0px,
      rgb(255, 255, 255) 2.16121px 3.36588px 0px,
      rgb(255, 255, 255) 1.26129px 3.79594px 0px,
      rgb(255, 255, 255) 0.282949px 3.98998px 0px,
      rgb(255, 255, 255) -0.712984px 3.93594px 0px,
      rgb(255, 255, 255) -1.66459px 3.63719px 0px,
      rgb(255, 255, 255) -2.51269px 3.11229px 0px,
      rgb(255, 255, 255) -3.20457px 2.39389px 0px,
      rgb(255, 255, 255) -3.69721px 1.52664px 0px,
      rgb(255, 255, 255) -3.95997px 0.56448px 0px,
      rgb(255, 255, 255) -3.97652px -0.432781px 0px,
      rgb(255, 255, 255) -3.74583px -1.40313px 0px,
      rgb(255, 255, 255) -3.28224px -2.28625px 0px,
      rgb(255, 255, 255) -2.61457px -3.02721px 0px,
      rgb(255, 255, 255) -1.78435px -3.57996px 0px,
      rgb(255, 255, 255) -0.843183px -3.91012px 0px,
      rgb(255, 255, 255) 0.150409px -3.99717px 0px,
      rgb(255, 255, 255) 1.13465px -3.8357px 0px,
      rgb(255, 255, 255) 2.04834px -3.43574px 0px,
      rgb(255, 255, 255) 2.83468px -2.82216px 0px,
      rgb(255, 255, 255) 3.44477px -2.03312px 0px,
      rgb(255, 255, 255) 3.84068px -1.11766px 0px,
      rgb(255, 255, 255) 3.9978px -0.132717px 0px;
  }

  &.annotation {
    line {
      stroke: $graph-blue-color;
    }

    text {
      fill: $graph-darkblue-color;
    }
  }
}

svg::v-deep .highlight {
  fill: white;
  opacity: 0.5;
}

svg::v-deep .today {
  stroke: $red-color;
  stroke-width: 1px;
  shape-rendering: crispedges;
  stroke-dasharray: 3 3;
}

svg::v-deep .legend-title {
  @include font-small-size;
  @include font-semibold;
  fill: $graph-text-color;
}

svg::v-deep .legend-figure {
  // @include font-large-size;
  @include font-bold;
  @include font-alternate-digits;
  font-smoothing: antialiased;
  alignment-baseline: middle;
  text-anchor: end;
  // Text stroke of 2px
  text-shadow: rgb(255, 255, 255) 2px 0px 0px,
    rgb(255, 255, 255) 1.75517px 0.958851px 0px,
    rgb(255, 255, 255) 1.0806px 1.68294px 0px,
    rgb(255, 255, 255) 0.141474px 1.99499px 0px,
    rgb(255, 255, 255) -0.832294px 1.81859px 0px,
    rgb(255, 255, 255) -1.60229px 1.19694px 0px,
    rgb(255, 255, 255) -1.97998px 0.28224px 0px,
    rgb(255, 255, 255) -1.87291px -0.701566px 0px,
    rgb(255, 255, 255) -1.30729px -1.5136px 0px,
    rgb(255, 255, 255) -0.421592px -1.95506px 0px,
    rgb(255, 255, 255) 0.567324px -1.91785px 0px,
    rgb(255, 255, 255) 1.41734px -1.41108px 0px,
    rgb(255, 255, 255) 1.92034px -0.558831px 0px;
}

svg::v-deep .legend-text,
svg::v-deep .legend-footer {
  @include font-small-size;
  fill: lighten($text-color, 20);
  font-smoothing: antialiased;
  alignment-baseline: middle;

  &.legend-text:not(.legend-total):hover {
    text-decoration: underline;
    text-shadow: none;
  }

  &.legend-total {
    @include font-semibold;
    fill: $graph-text-color;
  }

  // Text stroke of 2px
  text-shadow: rgb(255, 255, 255) 2px 0px 0px,
    rgb(255, 255, 255) 1.75517px 0.958851px 0px,
    rgb(255, 255, 255) 1.0806px 1.68294px 0px,
    rgb(255, 255, 255) 0.141474px 1.99499px 0px,
    rgb(255, 255, 255) -0.832294px 1.81859px 0px,
    rgb(255, 255, 255) -1.60229px 1.19694px 0px,
    rgb(255, 255, 255) -1.97998px 0.28224px 0px,
    rgb(255, 255, 255) -1.87291px -0.701566px 0px,
    rgb(255, 255, 255) -1.30729px -1.5136px 0px,
    rgb(255, 255, 255) -0.421592px -1.95506px 0px,
    rgb(255, 255, 255) 0.567324px -1.91785px 0px,
    rgb(255, 255, 255) 1.41734px -1.41108px 0px,
    rgb(255, 255, 255) 1.92034px -0.558831px 0px;
}

svg::v-deep .legend-footer {
  @include font-small-size;
  fill: lighten($text-color, 20);
  font-smoothing: antialiased;
  alignment-baseline: middle;
  text-anchor: end;
}

.wrapper::v-deep .legend-container .legend {
  display: none;
}
</style>
