<template>
  <div>
    <h3>
      <router-link
        tag="a"
        class="colored-link"
        :to="{name: 'settingsCompanyIntegrations'}"
        :active-class="'selected'">
        {{$t('settings.settingsCompanyIntegrations.title')}}
      </router-link>
      &nbsp;&nbsp;›&nbsp;&nbsp;
      {{$t('settings.settingsCompanyIntegrations.bambooHR.title')}}
    </h3>
    <p class="no-margin-top" v-html="$t('settings.settingsCompanyIntegrations.bambooHR.intro')"></p>
    <div class="form-section">
      <checkbox v-model="settingsModel.active">
        {{$t('settings.settingsCompanyIntegrations.bambooHR.toggle')}}
      </checkbox>
    </div>
    <div>
      <form @submit.prevent="update">
        <div class="settings-block">
          <header><strong v-t="'settings.settingsCompanyIntegrations.rules.title'"></strong></header>
          <div class="form-section">
            <checkbox v-model="settingsModel.auto">
              {{ $t('settings.settingsCompanyIntegrations.rules.synchronization.auto') }}
            </checkbox>
          </div>
          <div class="form-section">
            <div class="input-label" v-t="'settings.settingsCompanyIntegrations.rules.user.title'"></div>
            <p class="preserve-lines" v-t="'settings.settingsCompanyIntegrations.bambooHR.rules.user.body'"></p>
          </div>
          <div class="form-section">
            <div class="input-label" v-t="'settings.settingsCompanyIntegrations.rules.wage.title'"></div>
            <p class="preserve-lines" v-t="'settings.settingsCompanyIntegrations.bambooHR.rules.wage.body'"></p>
          </div>
          <p v-html="$t('settings.settingsCompanyIntegrations.rules.customize')"></p>
        </div>
        <div class="settings-block">
          <header><strong v-t="'settings.settingsCompanyIntegrations.apiAccess'"></strong></header>
          <p class="preserve-lines" v-html="$t('settings.settingsCompanyIntegrations.bambooHR.apiAccessIntro')"></p>
          <div class="form-section">
            <div class="input-label" v-t="'settings.settingsCompanyIntegrations.bambooHR.apiKey'"></div>
            <input
              type="password"
              v-model="settingsModel.apiKey"
              placeholder="Ex : 98617bfc8db5a005fad5f9f35a0f661568cffcdd">
          </div>
          <div class="form-section">
            <div class="input-label" v-t="'settings.settingsCompanyIntegrations.bambooHR.domain'"></div>
            <input
              type="text"
              v-model="settingsModel.domain"
              placeholder="Ex : my-company">
          </div>
        </div>
        <div>
          <resizable-panel :observer="[successMessage, errorMessage]">
            <p v-if="successMessage" class="alert preserve-lines" v-html="successMessage"></p>
            <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
          </resizable-panel>
          <loading-button
            class="primary settings-button"
            :loading="isLoading">
            {{$t('settings.settingsCompanyIntegrations.applyChanges')}}
          </loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Checkbox from '@components/commons/Checkbox'
import ResizablePanel from '@components/commons/ResizablePanel.vue'

export default {
  components: { Checkbox, ResizablePanel },
  data() {
    return {
      settingsModel: {},
      errorMessage: null,
      successMessage: null,
      toggleSuccessMessage: null,
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      company: 'account/getCompany'
    })
  },
  methods: {
    update() {
      this.errorMessage = null
      this.successMessage = null
      this.toggleSuccessMessage = null
      this.isLoading = true

      this.$store.dispatch('company/updateBambooHRSettings', this.settingsModel)
        .then(({ bambooHrSettings }) => {
          this.isLoading = false
          this.successMessage = this.$t('settings.settingsCompanyIntegrations.bambooHR.success' + (bambooHrSettings.active ? 'Active' : 'Inactive'))
        })
        .catch(error => {
          this.isLoading = false
          this.errorMessage = error
        })
    }
  },
  created() {
    this.settingsModel = { ...this.company.bambooHrSettings }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/settings.scss";
</style>
