<template>
  <div class="container">
    <div class="menu-container">
      <h1 v-t="'grid.editor.title'"></h1>
      <grid-editor-menu v-if="isGridLoaded" />
    </div>
    <div v-if="isGridLoaded">
      <grid-diff-viewer
        v-if="!isPublishedGrid"
        :oldGrid="currentGrid"
        :newGrid="grid"
        :oldWages="currentWages"
        :newWages="wages"
        :employees="employees">
        <template v-if="$$isAdmin">
          <button
            @click="reset"
            class="secondary"
            v-t="'common.reset'"></button>
          <button
            class="primary"
            v-t="'grid.editor.applyChanges'"
            @click="toggleIsPublishVisible"></button>
        </template>
      </grid-diff-viewer>
      <div v-else class="alert">{{$t('grid.diff.publishedGridIntro', {version: grid.version})}}</div>
      <router-view @init="initSandboxGrid" />
      <grid-editor-actions :grid="grid" />
    </div>
    <LoadingView
      v-if="isLoading"
      :messages="[1, 2, 3, 4, 5].map(i => $t(`grid.editor.loading.message${i}`))" />
    <div
      class="alert warning preserve-lines"
      v-if="initErrorMessage"
      v-html="initErrorMessage"></div>
    <modal :visible="isPublishVisible" @close="toggleIsPublishVisible">
      <grid-publish />
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import GridDiffViewer from '@components/grid/viewer/GridDiffViewer'
import GridEditorActions from '@components/grid/editor/GridEditorActions'
import GridEditorMenu from '@components/grid/editor/GridEditorMenu'
import GridPublish from '@components/grid/editor/GridPublish'
import LoadingView from '@components/commons/LoadingView'
import Modal from '@components/commons/Modal'

export default {
  components: {
    GridDiffViewer,
    GridEditorActions,
    GridEditorMenu,
    GridPublish,
    LoadingView,
    Modal
  },
  data() {
    return {
      initErrorMessage: null,
      isLoading: true,
      isPublishVisible: false
    }
  },
  computed: {
    ...mapGetters({
      currentGrid: 'currentGrid/getCurrentGrid',
      grid: 'sandbox/grid',
      currentWages: 'employees/getCurrentWages',
      wages: 'sandbox/employeeWages',
      employees: 'employees/getGridEmployees'
    }),
    isGridLoaded() {
      return !this.isLoading && this.grid && !this.initErrorMessage
    },
    isPublishedGrid() {
      return this.grid && !this.grid.isSandbox
    }
  },
  methods: {
    initSandboxGrid() {
      this.isLoading = true
      this.$store.dispatch('sandbox/initSandboxGrid').then(() => {
        this.isLoading = false
      }).catch(message => {
        this.isLoading = false
        this.initErrorMessage = message || this.$t('grid.editor.initError')
      })
    },
    toggleIsPublishVisible() {
      this.isPublishVisible = !this.isPublishVisible
    },
    async reset() {
      if (window.confirm(this.$t('grid.editor.resetWarning'))) {
        this.isLoading = true
        await this.$store.dispatch('sandboxList/remove', this.grid)
        await this.$store.dispatch('sandbox/reset')
        await this.$store.dispatch('sandbox/createSandboxGrid')
        this.initSandboxGrid()
      }
    },
    getDefaultEditorRoute() {
      const name = this.$$hasPermission('gridValuationEditor') ? 'gridValuationEditor' : 'gridQualificationEditor'
      return { name }
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === 'gridEditor') {
      next(this.getDefaultEditorRoute())
    }
    else {
      next()
    }
  },
  created() {
    this.initSandboxGrid()
    if (this.$route.name === 'gridEditor') {
      this.$router.replace(this.getDefaultEditorRoute())
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/alert.scss";

.container {
  @include tab-container;
  min-height: 40em;
}

.menu-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -11px;
  margin-bottom: 7px;
  margin-bottom: 0.75em;
  height: 50px;

  h1 {
    margin: 0;
  }
}
</style>
