import { denormalize, normalize, schema } from 'normalizr'

const skillEntity = new schema.Entity('skills')
// The id of domains is not unique so we generate a new one based on parent level id.
// Please note that the domainId property of skill is untouched.
const domainEntity = new schema.Entity('domains', { skills: [skillEntity] }, {
  idAttribute: (domain, level) => buildDomainId(domain.id, level.id)
})
const levelEntity = new schema.Entity('levels', { domains: [domainEntity] })
const componentEntity = new schema.Entity('components', { levels: [levelEntity] })

const linkedComponentEntities = new schema.Array(componentEntity)
componentEntity.define({ linkedComponents: linkedComponentEntities })

const gridEntity = new schema.Entity('grids', { components: [componentEntity] })
const gridEntities = new schema.Array(gridEntity)

const userEntity = new schema.Entity('users')
const userEntities = new schema.Array(userEntity)

const employeesEntity = new schema.Object({
  employees: userEntities,
  alumnis: userEntities,
  externals: userEntities
})
const sandboxEmployeeWageEntity = new schema.Entity('employeeWages', {}, { idAttribute: 'employeeId' })
const sandboxJobOfferWageEntity = new schema.Entity('jobOfferWages', {}, { idAttribute: 'jobOfferId' })
const sandboxWagesEntity = new schema.Object({
  employees: new schema.Array(sandboxEmployeeWageEntity),
  jobOffers: new schema.Array(sandboxJobOfferWageEntity)
})

const hiringPlanEntity = new schema.Entity('hiringPlans')
const jobOfferEntity = new schema.Entity('jobOffers', { hiringPlan: hiringPlanEntity })
const candidateEntity = new schema.Entity('candidates', { jobOffer: jobOfferEntity })
const candidatesEntity = new schema.Object({
  candidates: new schema.Array(candidateEntity),
  jobOffers: new schema.Array(jobOfferEntity),
  hiringPlans: new schema.Array(hiringPlanEntity)
})

const thirdPartyUserEntity = new schema.Entity('thirdPartyUsers', {}, {
  idAttribute: (_thirdPartyUser, change) => change.id
})
const changeEntity = new schema.Entity('changes', { thirdPartyUser: thirdPartyUserEntity })
const changeEntities = new schema.Array(changeEntity)

const variableWageEntity = new schema.Entity('variableWages')
const variableWageEntities = new schema.Array(variableWageEntity)

const variablePlanEntity = new schema.Entity('variablePlans', { variableWages: variableWageEntities })
const variablePlanEntities = new schema.Array(variablePlanEntity)

const benefitEntity = new schema.Entity('benefit')
const benefitsEntity = new schema.Array(benefitEntity)
const benefitsPlanEntity = new schema.Entity('benefitsPlan', { benefits: benefitsEntity })
const benefitsPlansEntity = new schema.Array(benefitsPlanEntity)

export function normalizedEmployees(employees) {
  return normalize(employees, employeesEntity)
}

export function normalizedUsers(users) {
  return normalize(users, userEntities)
}

export function normalizedGrids(grids) {
  return normalize(grids, gridEntities)
}

export function normalizedGrid(grid) {
  return normalize(grid, gridEntity)
}

export function normalizedComponent(component) {
  return normalize(component, componentEntity)
}

export function normalizedLevel(level) {
  return normalize(level, levelEntity)
}

export function normalizedSandboxWages(sandboxWages) {
  return normalize(sandboxWages, sandboxWagesEntity)
}

export function normalizedCandidates(candidates) {
  return normalize(candidates, candidatesEntity)
}

export function normalizedCandidate(candidate) {
  return normalize(candidate, candidateEntity)
}

export function normalizedJobOffer(jobOffer) {
  return normalize(jobOffer, jobOfferEntity)
}

export function denormalizedGrid({ gridId, grids, components, levels, domains, skills }) {
  return denormalize(gridId, gridEntity, { grids, components, levels, domains, skills })
}

export function buildDomainId(domainId, levelId) {
  return [domainId, levelId].join('|')
}

export function extractDomainId(composedDomainId) {
  return composedDomainId.split('|')[0]
}

export function normalizedChanges(changes) {
  return normalize(changes, changeEntities)
}

export function denormalizedChanges({ changeIds, changes, thirdPartyUsers }) {
  return denormalize(changeIds, changeEntities, { changes, thirdPartyUsers })
}

export function normalizedVariablePlans(variablePlans) {
  return normalize(variablePlans, variablePlanEntities)
}

export function normalizedVariablePlan(variablePlan) {
  return normalize(variablePlan, variablePlanEntity)
}

export function denormalizedVariablePlans({ variablePlanIds, variablePlans, variableWages }) {
  return denormalize(variablePlanIds, variablePlanEntities, { variablePlans, variableWages })
}

export function denormalizedVariablePlan({ variablePlanId, variablePlans, variableWages }) {
  return denormalize(variablePlanId, variablePlanEntity, { variablePlans, variableWages })
}

export function normalizedBenefitsPlans(benefitsPlans) {
  return normalize(benefitsPlans, benefitsPlansEntity)
}
