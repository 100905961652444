import Vue from 'vue'
import { withScope, captureMessage } from '@sentry/browser'
import flattenDeep from 'lodash.flattendeep'
import short from 'short-uuid'
import sortBy from 'lodash.sortby'
import uniq from 'lodash.uniq'
import i18n from '@/i18n'
import store from '@/store'
import { capitalize, titleize, parseStringFloat } from '@/utils/string'
import { formatDate, fullDiffRaw, getRecurrenceDelta, normalizeDate, parseDate } from '@/utils/date'
import { parseFormula, extractFormulaSymbols, buildFormulaScope, evaluateFormula, explainFormula } from '@/utils/formula'
import { getDataFeedIndicatorValue } from '@/utils/dataFeed'
import { getField, CUSTOM_FIELD_URN } from '@/utils/employee'
import cloneDeepWith from 'lodash.clonedeepwith'

export const CONFIG_VERSION = 1.2
export const CONFIG_SUPPORTED_VERSIONS = [1.0, 1.1, 1.2]

export const INDICATOR_KINDS = [
  'value',
  'percentage',
  'reference',
  'tiers',
  'list',
  'checkbox',
  'constant'
]

export const INDICATOR_SOURCES = [
  'individual',
  'collective'
]

const TEMPLATES = [
  'bonus_1',
  'bonus_2',
  'collective_variable_1',
  'incentive_1',
  'individual_variable_1',
  'individual_variable_2',
  'profit_sharing_1'
].reduce((memo, template) => {
  memo = memo || {}
  memo[template] = require(`@/assets/variable/fr/${template}.json`)
  return memo
}, {})

const VARIABLE_MAX_VALUE = 100000

export function buildGroupKey() {
  return short.generate()
}

export function buildFormula() {
  return {
    condition: null,
    maximum: null,
    formula: ''
  }
}

export function buildConfig() {
  return {
    version: CONFIG_VERSION,
    indicators: [],
    formulas: [buildFormula()],
    goals: []
  }
}

export function buildSimulation() {
  return {
    id: short.generate(),
    indicators: []
  }
}

export function migrateConfig(configRaw, simulationsRaw) {
  let config, simulations
  if (configRaw && CONFIG_SUPPORTED_VERSIONS.includes(configRaw.version)) {
    config = cloneDeepWith(configRaw)
    // Migrate from 1.1 to 1.2
    if (config.version < 1.2) {
      config.goals = []
      config.version = CONFIG_VERSION
    }
  }
  else {
    config = buildConfig()
  }
  if (simulationsRaw && simulationsRaw.length) {
    simulations = cloneDeepWith(simulationsRaw)
  }
  else {
    simulations = [buildSimulation()]
  }
  return { config, simulations }
}

export function buildIndicator(name, options) {
  return {
    id: short.generate(),
    kind: 'value',
    source: options && options.isCollective ? 'collective' : 'individual',
    name
  }
}

export function buildIndicatorMappingItem(name, value, options = {}) {
  return {
    id: short.generate(),
    name,
    value,
    ...options
  }
}

export function buildGoal() {
  return {
    id: short.generate(),
    name: '',
    enabled: true,
    indicator: {
      kind: 'reference',
      reference: null
    },
    target: {
      kind: 'reference',
      reference: null
    }
  }
}

// Generate a list of available reference indicators, to be used in dropdown
// Accepts the following `options`:
// {mode: 'goal'} → Use value insteand of tier for indicators
export function buildGroupedAvailableReferences(indicators, dataFeeds, indicator, customFields, options) {
  options = options || {}

  // Reference indicators
  let availableIndicators, availableReferenceIndicators
  if (options.mode !== 'goal') {
    // Exclude references
    availableIndicators = sortBy(
      indicators.filter(i => i.kind !== 'reference' && i.id !== indicator.id),
      'name'
    )
    // Use tier type
    availableReferenceIndicators = availableIndicators.map(indicator => ({
      id: buildReferenceId('tiers', 'indicator', indicator.id),
      title: indicator.name,
      label: i18n.t('variable.editor.indicator.kinds.tiers.title')
    }))
  }
  else {
    // Keep references
    availableIndicators = sortBy(
      indicators.filter(i => i.kind !== 'constant' && i.id !== indicator.id),
      'name'
    )
    // Use value type instead of tier
    availableReferenceIndicators = availableIndicators.map(indicator => ({
      id: buildReferenceId('value', 'indicator', indicator.id),
      title: indicator.name,
      label: i18n.t(`variable.editor.indicator.kinds.${indicator.kind}.title`)
    }))
    // Return only this group of indicators
    return [availableReferenceIndicators]
  }

  // Other indicators
  let dynamicIndicators = [
    {
      id: buildReferenceId('value', 'employee', 'salary'),
      title: i18n.t('variable.editor.indicator.references.employeeSalary'),
      label: i18n.t('variable.editor.indicator.kinds.value.title')
    },
    {
      id: buildReferenceId('value', 'variableWages', 'count'),
      title: i18n.t('variable.editor.indicator.references.variableWagesCount'),
      label: i18n.t('variable.editor.indicator.kinds.value.title')
    },
    {
      id: buildReferenceId('value', 'employee', 'arrivalDateProrata'),
      title: i18n.t('variable.editor.indicator.references.employeeArrivalDateProrata'),
      label: i18n.t('variable.editor.indicator.kinds.percentage.title')
    },
    {
      id: buildReferenceId('value', 'employee', 'arrivalAndDepartureDateProrata'),
      title: i18n.t('variable.editor.indicator.references.employeeArrivalAndDepartureDateProrata'),
      label: i18n.t('variable.editor.indicator.kinds.percentage.title')
    }
  ]

  // Custom fields indicators
  const customFieldsIndicators = customFields
    .filter(field => field.kind === 'float' && field.scopes.includes('variable'))
    .map(field => ({
      id: buildReferenceId('value', 'employee', 'customField', field.id.replace(CUSTOM_FIELD_URN, '')),
      title: field.name,
      label: i18n.t('variable.editor.indicator.kinds.value.title')
    }))
  dynamicIndicators = [...customFieldsIndicators, ...dynamicIndicators]

  // Data feed indicators
  const availableDataFeeds = flattenDeep((dataFeeds || []).map(dataFeed => {
    // Data feed value
    const indicators = [{
      id: buildReferenceId('value', 'dataFeed', dataFeed.id),
      title: dataFeed.name,
      label: i18n.t('variable.dataFeed.kinds.value.title')
    }]
    // Data feed columns
    if (dataFeed.config.columnKeys) {
      dataFeed.config.columnKeys.forEach((columnKey, i) => {
        indicators.push({
          id: buildReferenceId('value', 'dataFeed', dataFeed.id, i),
          title: dataFeed.name + ` (${columnKey})`,
          label: i18n.t('variable.dataFeed.kinds.value.title')
        })
      })
    }
    return indicators
  }))
  const dataFeedIndicators = [
    ...availableDataFeeds
  ]
  return [
    availableReferenceIndicators,
    dynamicIndicators,
    dataFeedIndicators
  ]
}

export function buildReferenceId(kind, scope, property, index = null) {
  return index !== null
    ? [kind, scope, property, index].join(':')
    : [kind, scope, property].join(':')
}

export function splitReferenceId(reference) {
  return reference ? reference.split(':') : []
}

export function extractReferenceType(reference) {
  return splitReferenceId(reference)[0]
}

export function extractReferenceScope(reference) {
  return splitReferenceId(reference)[1]
}

export function buildIndicatorMapping(kind, reference) {
  if (reference) {
    kind = extractReferenceType(reference)
  }
  switch (kind) {
    case 'value':
      return
    case 'percentage':
      return
    case 'checkbox':
      return [
        buildIndicatorMappingItem(i18n.t('variable.editor.indicator.kinds.checkbox.namePlaceholder'), '1000 ' + i18n.currencySymbol, { single: true })
      ]
    case 'list':
      return [
        buildIndicatorMappingItem(i18n.t('variable.editor.indicator.kinds.list.namePlaceholder1'), '0 ' + i18n.currencySymbol),
        buildIndicatorMappingItem(i18n.t('variable.editor.indicator.kinds.list.namePlaceholder2'), '500 ' + i18n.currencySymbol),
        buildIndicatorMappingItem(i18n.t('variable.editor.indicator.kinds.list.namePlaceholder3'), '1000 ' + i18n.currencySymbol)
      ]
    case 'tiers':
      return [
        buildIndicatorMappingItem('0 ' + i18n.currencySymbol, '0 ' + i18n.currencySymbol, { nameType: 'number' }),
        buildIndicatorMappingItem('1000 ' + i18n.currencySymbol, '500 ' + i18n.currencySymbol, { nameType: 'number' }),
        buildIndicatorMappingItem('2000 ' + i18n.currencySymbol, '1000 ' + i18n.currencySymbol, { nameType: 'number' })
      ]
    case 'constant':
      return [
        buildIndicatorMappingItem('constant', '1000 ' + i18n.currencySymbol, { nameType: 'hidden' })
      ]
  }
}

export function buildSimulationIndicator(indicatorConfig, isSimulation) {
  return {
    id: indicatorConfig.id,
    name: indicatorConfig.name,
    value: isSimulation ? 1000 : getValueFromInputValue(indicatorConfig, '0'),
    inputValue: isSimulation ? '1000' : '0'
  }
}

export function buildNextVariablePlan(variablePlan) {
  return {
    ...variablePlan,
    ...computeNextPeriod(variablePlan),
    status: 'draft'
  }
}

// Takes a config and updates both config.indicators and enrichedConfig.
// The enriched config is a cloned version of the config with parsed formulas and other metadata.
// Options can contain { isCollective } to build new indicators with a collective source
// Its purpose is mainly about performance.
// Returns true if all formulas of the config are valid
export function enrichConfig(config, enrichedConfig, options) {
  enrichedConfig.isValid = true

  // Update enrichedConfig
  config.formulas.forEach(({ formula, condition, maximum }, i) => {
    const enrichedFormula = enrichedConfig.formulas[i] || {}
    if (!enrichedFormula.compiledFormula ||
        enrichedFormula.formula !== formula ||
        enrichedFormula.condition !== condition ||
        enrichedFormula.maximum !== maximum) {
      // Formula
      try {
        enrichedFormula.formula = formula
        enrichedFormula.parsedFormula = parseFormula(formula)
        enrichedFormula.compiledFormula = enrichedFormula.parsedFormula && enrichedFormula.parsedFormula.compile()
        enrichedFormula.formulaSymbols = extractFormulaSymbols(enrichedFormula.parsedFormula)
        enrichedFormula.formulaError = null
      }
      catch (err) {
        enrichedConfig.isValid = false
        enrichedFormula.formulaError = err.toString()
      }
      // Condition
      try {
        enrichedFormula.condition = condition
        enrichedFormula.parsedCondition = parseFormula(condition)
        enrichedFormula.compiledCondition = enrichedFormula.parsedCondition && enrichedFormula.parsedCondition.compile()
        enrichedFormula.conditionSymbols = extractFormulaSymbols(enrichedFormula.parsedCondition)
        enrichedFormula.conditionError = null
      }
      catch (err) {
        enrichedConfig.isValid = false
        enrichedFormula.conditionError = err.toString()
      }
      // Maximum
      try {
        enrichedFormula.maximum = maximum
        enrichedFormula.parsedMaximum = parseFormula(maximum)
        enrichedFormula.compiledMaximum = enrichedFormula.parsedMaximum && enrichedFormula.parsedMaximum.compile()
        enrichedFormula.maximumSymbols = extractFormulaSymbols(enrichedFormula.parsedMaximum)
        enrichedFormula.maximumError = null
      }
      catch (err) {
        enrichedConfig.isValid = false
        enrichedFormula.maximumError = err.toString()
      }
      Vue.set(enrichedConfig.formulas, i, enrichedFormula)
    }
  })

  // Update config.indicators
  const symbols = uniq(flattenDeep(enrichedConfig.formulas.map(f => [f.formulaSymbols, f.conditionSymbols, f.maximumSymbols]))).filter(s => s)
  const existingSymbols = config.indicators.map(i => i.name)
  const addedSymbols = symbols.filter(s => !existingSymbols.includes(s))
  const removedSymbols = existingSymbols.filter(i => !symbols.includes(i))
  const removedIndicators = {}

  if (removedSymbols.length) {
    removedSymbols.forEach(symbol => {
      const originalIndex = existingSymbols.indexOf(symbol)
      const index = config.indicators.findIndex(i => i.name === symbol)
      // Remove & backup
      removedIndicators[originalIndex] = config.indicators.splice(index, 1)[0]
    })
  }

  if (addedSymbols.length) {
    addedSymbols.forEach(symbol => {
      const index = symbols.indexOf(symbol)
      let indicator = buildIndicator(symbol, options)
      // Restore when possible
      if (removedIndicators[index]) {
        indicator = { ...removedIndicators[index], name: symbol }
      }
      config.indicators.splice(index, 0, indicator)
    })
  }
  enrichedConfig.indicators = config.indicators

  return enrichedConfig.isValid
}

export function getReferenceIndicatorValue(enrichedConfig, indicators, indicatorConfig, evaluationOptions) {
  const { employee, employees, variableWagesCount, period } = evaluationOptions || {}
  const { reference, mapping } = indicatorConfig
  const [kind, scope, property, index] = splitReferenceId(reference)
  const indicator = indicators.find(({ id }) => id === indicatorConfig.id)
  const referenceEmployee = employee || (employees.length ? employees[0] : null)

  // If simulation mode and computedValue is available, return it instead of computing it
  if (evaluationOptions.mode === 'simulation' && indicator && indicator.computedValue !== undefined) {
    return indicator.computedValue
  }
  // If frozen indicator is available, return it instead of computing it
  if (indicator && indicator.frozen) {
    return indicator.value
  }

  switch (scope) {
    case 'employee':
      if (referenceEmployee) {
        switch (property) {
          case 'customField':
            return parseStringFloat(getField(referenceEmployee, `urn:clw:cf:${index}`, { store }))
          case 'salary':
            return getField(referenceEmployee, 'salary', { store })
          case 'arrivalDateProrata':
            return computeProrata(period, referenceEmployee.arrivalDate)
          case 'arrivalAndDepartureDateProrata':
            return computeProrata(period, referenceEmployee.arrivalDate, referenceEmployee.departureDate)
        }
      }
      break
    case 'variableWages':
      switch (property) {
        case 'count':
          return variableWagesCount || 1
      }
      break
    case 'indicator':
      const refIndicatorConfig = enrichedConfig.indicators.find(i => i.id === property)
      const refIndicator = indicators.find(i => i.id === property)
      if (refIndicatorConfig && refIndicator) {
        if (extractReferenceScope(refIndicatorConfig.reference) === 'dataFeed') {
          return getReferenceIndicatorValue(enrichedConfig, indicators, { reference: refIndicatorConfig.reference }, evaluationOptions)
        }
        else {
          return getValueFromInputValue({ kind, mapping }, refIndicator.value)
        }
      }
      break
    case 'dataFeed':
      const dataFeed = enrichedConfig.dataFeeds && enrichedConfig.dataFeeds.find(d => d.id === property)
      if (dataFeed && referenceEmployee) {
        return getDataFeedIndicatorValue(dataFeed, referenceEmployee, index, kind)
      }
      break
  }
  return 0
}

// Since indicators might not be updated each time the formula is updated,
// try to generate new ones based on previous ones.
export function refreshIndicators(enrichedConfig, indicators, evaluationOptions) {
  evaluationOptions = evaluationOptions || {}
  return enrichedConfig.indicators.map(indicatorConfig => {
    const name = indicatorConfig.name
    const indicator = indicators.find(i => i.id === indicatorConfig.id) ||
      buildSimulationIndicator(indicatorConfig, evaluationOptions.mode === 'simulation')
    let value = indicator.value

    if (evaluationOptions.evaluateReferences) {
      if (indicatorConfig.kind === 'constant') {
        value = getValueFromInputValue(indicatorConfig)
      }
      if (indicatorConfig.kind === 'reference') {
        value = getReferenceIndicatorValue(enrichedConfig, indicators, indicatorConfig, evaluationOptions)
      }
    }

    return { ...indicator, name, value }
  })
}

// Takes an array of indicator and filter them based on evaluation mode
export function filterIndicators(enrichedConfig, indicators, evaluationOptions) {
  const { mode } = evaluationOptions || {}

  switch (mode) {
    case 'individual':
      return indicators.filter(indicator => {
        const indicatorConfig = enrichedConfig.indicators.find(i => i.id === indicator.id)
        return indicator.frozen || (indicatorConfig && indicatorConfig.source === 'individual')
      })
    case 'collective':
      return indicators.filter(indicator => {
        const indicatorConfig = enrichedConfig.indicators.find(i => i.id === indicator.id)
        return indicator.frozen || (indicatorConfig && indicatorConfig.source === 'collective')
      })
    default:
      return indicators
  }
}

export function evaluateEnrichedFormula(enrichedFormula, name, scope, evaluationOptions, bonusValue) {
  const result = evaluateFormula(enrichedFormula['compiled' + name], scope) || 0

  if (evaluationOptions.annotations) {
    const isFormula = name === 'Formula'
    const isInvalidCondition = name === 'Condition' && !result
    const isMaximumReached = name === 'Maximum' && bonusValue > result

    if (isFormula || isInvalidCondition || isMaximumReached) {
      let formula = explainFormula(enrichedFormula['parsed' + name], scope)
      let isError = false

      if (!formula) {
        formula = enrichedFormula[name.toLowerCase()]
        isError = true
      }

      evaluationOptions.annotations.push({
        name,
        isError,
        formula,
        result
      })
    }
  }

  return result
}

export function freezeIndicators(enrichedConfig, indicators, refreshedIndicators) {
  enrichedConfig.indicators
    .filter(({ kind }) => kind === 'reference')
    .forEach(({ id, name }) => {
      const refreshedIndicator = refreshedIndicators.find(i => i.id === id)
      const indicator = indicators.find(i => i.id === id)
      if (refreshedIndicator && (!indicator || !indicator.frozen)) {
        console.log('Indicator', name, 'has been frozen')
        const frozenIndicator = { ...refreshedIndicator, frozen: true }
        if (indicator) {
          indicators.splice(indicators.indexOf(indicator), 1)
        }
        indicators.push(frozenIndicator)
      }
    })
}

export function unfreezeIndicator(indicators) {
  for (let i = indicators.length - 1; i >= 0; i--) {
    if (indicators[i].frozen) {
      console.log('Indicator', indicators[i].id, 'has been unfrozen')
      indicators.splice(i, 1)
    }
  }
}

export function evaluateIndicators(enrichedConfig, sharedIndicators, indicators, evaluationOptions) {
  try {
    evaluationOptions = { ...(evaluationOptions || {}), evaluateReferences: true }

    // Unfreeze if requested
    if (evaluationOptions.freezeIndicators === false) {
      unfreezeIndicator(indicators)
    }

    // Ensure all indicators are properly defined
    let refreshedIndicators = [...(sharedIndicators || []), ...(indicators || [])]
    refreshedIndicators = refreshIndicators(enrichedConfig, refreshedIndicators, evaluationOptions)
    const scope = buildFormulaScope(refreshedIndicators)

    // Freeze if requested
    if (evaluationOptions.freezeIndicators === true) {
      freezeIndicators(enrichedConfig, indicators, refreshedIndicators)
    }

    // Evaluate all the things!
    let bonusValue = 0
    for (const enrichedFormula of enrichedConfig.formulas) {
      if (!enrichedFormula.compiledCondition || evaluateEnrichedFormula(enrichedFormula, 'Condition', scope, evaluationOptions)) {
        bonusValue = evaluateEnrichedFormula(enrichedFormula, 'Formula', scope, evaluationOptions)
        if (bonusValue !== undefined) {
          bonusValue = Number.parseFloat(bonusValue || 0)
          if (enrichedFormula.compiledMaximum) {
            const maximumValue = evaluateEnrichedFormula(enrichedFormula, 'Maximum', scope, evaluationOptions, bonusValue)
            if (bonusValue > maximumValue) {
              bonusValue = maximumValue
            }
          }
        }
        else {
          bonusValue = 0
        }
        break
      }
      else if (enrichedFormula.compiledFormula) {
        bonusValue = 0
      }
    }
    bonusValue = Math.max(-VARIABLE_MAX_VALUE, Math.min(bonusValue, VARIABLE_MAX_VALUE))
    return Math.round(bonusValue * 100) / 100
  }
  catch (error) {
    withScope(scope => {
      scope.setExtra('enrichedConfig', enrichedConfig)
      scope.setExtra('sharedIndicators', sharedIndicators)
      scope.setExtra('indicators', indicators)
      scope.setExtra('evaluationOptions', evaluationOptions)
      captureMessage(`${error} in evaluateIndicators()`)
    })
  }
}

export function computePeriod(recurrence, initialStartDate, offsetEndDate = true) {
  const { period, value } = getRecurrenceDelta(recurrence)
  let startDate = initialStartDate ? parseDate(initialStartDate) : parseDate(new Date()).add(1, period).startOf(period)
  let endDate = startDate.clone().add(value, period).subtract(offsetEndDate ? 1 : 0, 'day')
  startDate = normalizeDate(startDate)
  endDate = normalizeDate(endDate)
  return { startDate, endDate }
}

export function computeNextPeriod(variablePlan) {
  const startDate = parseDate(variablePlan.endDate).add(1, 'day')
  return computePeriod(variablePlan.recurrence, startDate)
}

// Return coef of remaining days / total days
export function computeProrata(period, startDate, endDate = null) {
  const periodStartDate = parseDate(period.startDate)
  const periodEndDate = parseDate(period.endDate).endOf('day')
  const periodDays = periodEndDate.diff(periodStartDate, 'days')
  const userStartDate = parseDate(startDate)
  let userStartDays = userStartDate.diff(periodStartDate, 'days')
  userStartDays = Math.min(periodDays, Math.max(0, userStartDays))
  const userEndDate = endDate ? parseDate(endDate).endOf('day') : null
  let userEndDays = userEndDate ? periodEndDate.diff(userEndDate, 'days') : 0
  userEndDays = Math.min(periodDays, Math.max(0, userEndDays))
  const missingDays = userStartDays + userEndDays
  let prorata = (periodDays - missingDays) / periodDays
  prorata = Math.min(1, Math.max(0, prorata))

  return Math.round(prorata * 100000) / 100000
}

export function formatSmartPeriod(variablePlan, sep = '<span class="arrow">→</span>') {
  const startDate = parseDate(variablePlan.startDate)
  const endDate = parseDate(variablePlan.endDate)
  if (startDate.isSame(endDate, 'day')) {
    return titleize(formatDate(endDate, 'MMMM YYYY'))
  }
  else {
    const startDateDayFormat = startDate.isSame(startDate.clone().startOf('month'), 'day') ? '' : 'D '
    const endDateDayFormat = endDate.isSame(endDate.clone().endOf('month'), 'day') ? '' : 'D '
    const startDateYearFormat = startDate.year() === endDate.year() ? ' ' : ' YYYY'
    const startDateFormat = `${startDateDayFormat}MMMM${startDateYearFormat}`
    const endDateFormat = `${endDateDayFormat}MMMM YYYY`
    if (!startDate.isSame(endDate, 'month')) {
      return titleize([startDate.format(startDateFormat), endDate.format(endDateFormat)].join(` ${sep} `))
    }
    else {
      return titleize(endDate.format(endDateFormat))
    }
  }
}

export function formatPeriod(variablePlan, concise = false, sep = '<span class="arrow">→</span>') {
  const startDate = parseDate(variablePlan.startDate)
  const endDate = parseDate(variablePlan.endDate)
  const month = 'MMM' + (concise ? '' : 'M')
  if (fullDiffRaw(startDate, endDate) === 0) {
    return capitalize(endDate.format(`D ${month} YYYY`))
  }
  else {
    const startDateLabel = capitalize(startDate.format(`D ${month} YYYY`))
    const endDateLabel = capitalize(endDate.format(`D ${month} YYYY`))
    return [startDateLabel, endDateLabel].join(` ${sep} `)
  }
}

export function getAnnualBonusValue(bonusValue, variablePlan) {
  if (['weekly'].includes(variablePlan.recurrence)) {
    return bonusValue * 52.143
  }
  else if (['monthly', 'quarterly', 'biannual'].includes(variablePlan.recurrence)) {
    const { startDate, endDate } = variablePlan
    // We round because 01/01 to 31/03 = 2.966 months
    const roundedMonths = Math.round(parseDate(endDate).diff(parseDate(startDate), 'months', true))
    return bonusValue / (roundedMonths / 12)
  }
  else {
    return null
  }
}

export function getInputValueFromValue(indicator, value, initialInputValue) {
  const mapping = indicator.mapping || []
  let inputValue
  switch (indicator.kind) {
    case 'value':
      if (value === null) {
        value = 1000
      }
      inputValue = value.toString()
      break
    case 'percentage':
      if (value === null || (value && value.toString() === initialInputValue)) {
        value = 1
      }
      inputValue = Math.round(value * 100).toString()
      break
    case 'checkbox':
      inputValue = typeof initialInputValue === 'boolean' ? !!initialInputValue : false
      break
    case 'list':
      const itemByName = mapping.find(i => i.name === initialInputValue)
      const itemByValue = mapping.find(i => i.value === value)
      if (itemByName) {
        inputValue = itemByName.name
      }
      else if (itemByValue) {
        inputValue = itemByValue.name
      }
      else if (mapping.length) {
        inputValue = mapping[0].name
      }
      break
    case 'tiers':
      inputValue = !isNaN(+initialInputValue) ? initialInputValue : 0
      break
    case 'reference':
      inputValue = 0
      break
    case 'constant':
      inputValue = mapping.length ? mapping[0].value : 0
      break
  }
  return inputValue
}

export function getValueFromInputValue(indicator, inputValue) {
  let mapping = indicator.mapping || []
  let value
  switch (indicator.kind) {
    case 'value':
      value = +inputValue
      value = Math.max(value, 0)
      break
    case 'percentage':
      value = +inputValue / 100
      value = Math.max(Math.min(value, 10), 0)
      break
    case 'checkbox':
      value = inputValue && mapping.length ? parseStringFloat(mapping[0].value) : 0
      break
    case 'list':
      const itemByName = mapping.find(i => i.name === inputValue)
      const defaultValue = mapping.length ? mapping[0].value : 0
      value = parseStringFloat(itemByName ? itemByName.value : defaultValue)
      break
    case 'tiers':
      mapping = sortBy(mapping, i => parseStringFloat(i.name))
      value = 0
      for (let i = mapping.length - 1; i >= 0; i--) {
        const item = mapping[i]
        if (+inputValue >= parseStringFloat(item.name)) {
          value = parseStringFloat(item.value)
          break
        }
      }
      break
    case 'reference':
      value = 0
      break
    case 'constant':
      value = mapping.length ? parseStringFloat(mapping[0].value) : 0
      break
  }
  return value
}

export function computeVariableWagesCents(variablePlan, enrichedConfig, sharedIndicators, variableWages, filteredVariableWageId = null, options = {}) {
  return Math.round(100 * variableWages.reduce((memo, variableWage) => {
    if (filteredVariableWageId && variableWage.id !== filteredVariableWageId) {
      return memo
    }
    const employee = store.getters['employees/getEmployee'](variableWage.userId)
    const evaluationOptions = { ...store.getters['variablePlans/getEvaluationOptions'](variablePlan, null, employee), ...options }
    const bonusValue = evaluateIndicators(enrichedConfig, sharedIndicators, variableWage.indicators, evaluationOptions)
    memo += bonusValue
    return memo
  }, 0))
}

export function exportVariableWages(variablePlan, enrichedConfig, sharedIndicators, variableWages) {
  const header = [
    'employees.fields.firstName.name',
    'employees.fields.lastName.name',
    'variable.common.value'
  ].map(key => i18n.t(key))
  return [header, ...sortBy(variableWages.map(variableWage => {
    const employee = store.getters['employees/getEmployee'](variableWage.userId)
    const evaluationOptions = store.getters['variablePlans/getEvaluationOptions'](variablePlan, null, employee)
    const bonusValue = evaluateIndicators(enrichedConfig, sharedIndicators, variableWage.indicators, evaluationOptions)
    return [employee.firstName, employee.lastName, bonusValue]
  }), row => row.join())]
}

export function isDemoVariablePlanAvailable(name, index) {
  return !!TEMPLATES[`${name}_${index}`]
}

export function getDemoVariablePlan(name, index) {
  const dataFeeds = []
  const variableWages = []
  const recurrence = 'none'
  const { config, description, simulations } = TEMPLATES[`${name}_${index}`]
  return { config, dataFeeds, description, recurrence, simulations, variableWages }
}
