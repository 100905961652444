<template>
  <div>
    <div class="navigation" :class="{'main-bar': $$hasSession}">
      <div class="wrapper" v-if="$$hasSession">
        <logo></logo>
        <navigation-bar></navigation-bar>
        <account-menu></account-menu>
      </div>
    </div>
    <div :class="$$hasSession ? 'secondary-bar' : 'navigation main-bar'">
      <div class="wrapper">
        <logo v-if="!$$hasSession"></logo>
        <breadcrumb v-if="!$route.meta.rootless"></breadcrumb>
      </div>
    </div>
  </div>
</template>

<script>
import AccountMenu from '@components/layout/AccountMenu'
import Breadcrumb from '@components/layout/Breadcrumb'
import Logo from '@components/layout/Logo'
import NavigationBar from '@components/layout/NavigationBar'

export default {
  components: {
    AccountMenu,
    Breadcrumb,
    Logo,
    NavigationBar
  }
}
</script>

<style lang="scss" scoped>
.navigation {
  .wrapper {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    justify-content: space-between;
  }
}

.main-bar {
  border-bottom: $border;
  background-color: white;
}

.secondary-bar {
  background-color: $background-color;
}

.wrapper {
  padding: 0 20px;
}

@media screen and (min-width: $content-max-width + 40px) {
  .wrapper {
    width: $content-max-width;
    margin: 0 auto;
    padding: 0;
  }
}
</style>
