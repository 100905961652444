<template>
  <div class="container" v-if="employee">
    <div
      class="alert success slow-fade-in"
      v-if="$route.params.candidateAccepted"
      v-t="{path: 'employees.employee.acceptationInfo', args: {companyName: $$companyName, employeeName: employee.firstName}}">
    </div>
    <EmployeeDetail :employee="employee">
      <template v-if="$$isAdmin">
        <button
          v-for="thirdPartyUser in employee.thirdPartyUsers"
          :key="thirdPartyUser.id"
          class="secondary"
          v-t="{
            path: 'employees.employee.thirdPartyUser.openIn',
            args: {serviceName: $t(`settings.settingsCompanySynchronization.identityProviders.${thirdPartyUser.identityProvider}`)}}"
          @click="$$openUrl(thirdPartyUser.profileUrl)"></button>
        <EmployeeAccessRightsButton :employee="employee" />
        <router-link
          :to="{name: 'updateEmployee', params: { id: employee.id}}"
          tag="button"
          class="secondary"
          v-t="'employees.employee.update'" />
      </template>
    </EmployeeDetail>
    <EmployeeSummary
      :remunerationSummary="remunerationSummary"
      :selectedRecurrence="selectedRecurrence"
      @selectRecurrence="selectRecurrence" />
    <EmployeeTabBar
      :tabs="tabs"
      :activeTab="selectedTab"
      @tabSelected="selectTab"
      :selectedRecurrence="selectedRecurrence" />
    <div class="tab" :data-tab="selectedTab && selectedTab.id">
      <keep-alive>
        <component
          v-bind:is="selectedTab.component"
          :variableWages="variableWages"
          :benefits="benefits"
          :selectedRecurrence="selectedRecurrence" />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import EmployeeAccessRightsButton from '@components/employees/EmployeeAccessRightsButton.vue'
import EmployeeDetail from '@components/employees/EmployeeDetail.vue'
import EmployeeTabBar from '@components/employees/EmployeeTabBar.vue'
import EmployeeBenefitsView from '@/views/employees/EmployeeBenefitsView.vue'
import EmployeeVariableView from '@/views/employees/EmployeeVariableView.vue'
import EmployeeSalaryView from '@/views/employees/EmployeeSalaryView.vue'
import EmployeeSummary from '@/components/employees/EmployeeSummary.vue'
import { benefitsCompanyCostSummary } from '@/utils/benefit'

export default {
  components: {
    EmployeeAccessRightsButton,
    EmployeeDetail,
    EmployeeBenefitsView,
    EmployeeVariableView,
    EmployeeSalaryView,
    EmployeeSummary,
    EmployeeTabBar
  },
  data() {
    return {
      selectedTab: null,
      benefits: null,
      variableWages: null,
      selectedRecurrence: 'yearly'
    }
  },
  computed: {
    employee() {
      if (this.$$isOneselfRoute) {
        return this.$store.getters['employees/getOneself']
      }

      return this.$store.getters['employees/getEmployee'](this.$route.params.id)
    },
    remunerationSummary() {
      if (this.employee) {
        const { salaryValue, contractualVariableValue } = this.employee.currentWage || {}
        const { yearly } = benefitsCompanyCostSummary(this.benefits || []) || {}
        const salary = salaryValue || 0
        const variable = contractualVariableValue || 0
        const benefits = yearly || 0
        return {
          isLoaded: !!this.benefits,
          salary,
          variable,
          benefits,
          total: salary + variable + benefits
        }
      }
    },
    tabs() {
      const { salary, variable, benefits } = this.remunerationSummary || {}
      return [
        {
          id: 'salary',
          name: this.$t('remuneration.fixed.shortTitle'),
          component: 'EmployeeSalaryView',
          value: salary
        },
        {
          id: 'variable',
          name: this.$t('remuneration.variable.shortTitle'),
          component: 'EmployeeVariableView',
          hidden: this.$$isOneselfRoute &&
            !((this.variableWages && (this.variableWages.ongoing || this.variableWages.closed)) ||
              (this.employee.currentWage && this.employee.currentWage.contractualVariableValue)),
          value: variable
        },
        {
          id: 'benefits',
          name: this.$t('remuneration.benefits.shortTitle'),
          component: 'EmployeeBenefitsView',
          hidden: this.$$isOneselfRoute &&
            !(this.benefits && this.benefits.length),
          value: benefits
        }
      ].filter(({ hidden }) => !hidden)
    }
  },
  methods: {
    selectTab(tab) {
      this.selectedTab = this.tabs.find(t => t.id === tab.id)
      history.replaceState(null, null, '#tab-' + this.selectedTab.id)
    },
    selectDefaultTab() {
      const hashTab = this.tabs.find(t => t.id === location.hash.substr(5))
      this.selectedTab = hashTab || this.tabs[0]
    },
    selectRecurrence(recurrence) {
      this.selectedRecurrence = recurrence
    },
    async loadBenefits() {
      if (this.employee) {
        this.benefits = await this.$store.dispatch('employees/getEmployeeBenefits', this.employee.id)
        this.selectDefaultTab()
      }
    },
    async loadVariableWages() {
      if (this.employee) {
        this.variableWages = await this.$store.dispatch('employees/getEmployeeVariableWages', this.employee.id)
        this.selectDefaultTab()
      }
    }
  },
  mounted() {
    this.loadBenefits()
    this.loadVariableWages()
  },
  created() {
    if (this.employee) {
      this.selectDefaultTab()
      this.$store.commit('employees/setCurrentEmployee', this.employee)
    }
    else {
      this.$router.push({ name: 'employees' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/alert.scss";
@import "./src/styles/button.scss";

.tab {
  @include tab-container;
  padding-bottom: 30px;
}
</style>
