<template>
  <div class="container">
    <div v-if="isEnabled">
      <menu
        v-if="!isGridParamsVisible"
        class="float-right grid-menu">
        <div class="button-group">
          <GridExclusionButton />
          <button
            v-if="!$$isManager"
            @click="toggle"
            class="secondary"
            v-t="'grid.onboarding.generate.generate'" />
          <GridPreviewButton :grid="onboardingGrid" />
        </div>
      </menu>
      <h1>{{$t('grid.onboarding.generate.yourGrid', {company: $$companyName })}}</h1>
      <resizable-panel :observer="[isGridParamsVisible, errorMessage, isLoading]">
        <div v-if="isGridParamsVisible">
          <p class="no-margin-top preserve-lines" v-t="'grid.onboarding.generate.intro'"></p>
          <h3 v-t="'grid.editor.formula'"></h3>
          <grid-formula layout="horizontal" :grid="onboardingGrid"></grid-formula>
          <h3 v-t="'grid.editor.parameters'"></h3>
          <grid-config-editor />
          <LoadingView
            v-if="isLoading"
            :messages="[1, 2, 3, 4, 5].map(i => $t(`grid.onboarding.generate.loading.message${i}`))" />
          <div
            class="error-message preserve-lines"
            v-else-if="errorMessage">{{ errorMessage }}</div>
          <p
            class="alert"
            v-else-if="isGridGenerated"
            v-t="'grid.onboarding.generate.modificationWarning'">
          </p>
          <menu class="hero">
            <button
              v-if="isGridGenerated"
              class="secondary"
              @click="toggle"
              v-t="'common.cancel'"></button>
            <loading-button
              class="primary"
              @click="submit"
              :loading="isLoading">
              <span v-t="'grid.onboarding.generate.generate'"></span>
            </loading-button>
            <br><br>
          </menu>
        </div>
      </resizable-panel>
      <div
        v-if="isGridGenerated"
        class="grid"
        :class="{'disabled': isGridParamsVisible}">
        <grid-valuation-editor />
        <p v-html="$t('grid.onboarding.generate.footer')"></p>
        <modal :visible="isPublishVisible" @close="closePublish">
          <grid-publish />
        </modal>
        <menu class="hero" v-if="!$$isManager">
          <p v-t="'grid.onboarding.generate.shallWeProceed'"></p>
          <loading-button
            class="primary"
            @click="openPublish"
            :loading="isLoading">
            <span v-t="'common.continue'"></span>
          </loading-button>
        </menu>
      </div>
    </div>
    <div v-else>
      <h1 v-t="'grid.onboarding.generate.title'"></h1>
      <p v-html="$t('grid.onboarding.generate.placeholder')"></p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import animateScrollTo from 'animated-scroll-to'
import Modal from '@components/commons/Modal'
import GridExclusionButton from '@components/grid/qualification/GridExclusionButton.vue'
import GridValuationEditor from '@components/grid/valuation/GridValuationEditor.vue'
import GridPreviewButton from '@components/grid/viewer/GridPreviewButton.vue'
import GridPublish from '@components/grid/editor/GridPublish.vue'
import GridFormula from '@components/grid/viewer/GridFormula.vue'
import GridConfigEditor from '@components/grid/valuation/GridConfigEditor.vue'
import ResizablePanel from '@components/commons/ResizablePanel.vue'
import LoadingView from '@components/commons/LoadingView.vue'

export default {
  components: {
    GridConfigEditor,
    GridExclusionButton,
    GridValuationEditor,
    GridFormula,
    GridPreviewButton,
    GridPublish,
    LoadingView,
    Modal,
    ResizablePanel
  },
  data() {
    return {
      errorMessage: null,
      isGridConfigVisible: false,
      isLoading: false,
      isPublishVisible: false
    }
  },
  methods: {
    toggle() {
      if (this.$$isManager) {
        return
      }
      this.isGridConfigVisible = !this.isGridConfigVisible
      if (this.isGridConfigVisible) {
        // Reset grid config
        this.$store.dispatch('onboarding/setGridConfig', null)
        animateScrollTo(0)
      }
    },
    submit(event) {
      const shouldReset = event.altKey
      this.errorMessage = null
      this.isLoading = true
      this.$store.dispatch('onboarding/submitGenerate', { shouldReset })
        .then(() => {
          this.isGridConfigVisible = false
          animateScrollTo(0).then(() => {
            this.isLoading = false
          })
        })
        .catch(error => {
          this.errorMessage = error
          this.isLoading = false
        })
    },
    openPublish() {
      this.isPublishVisible = true
    },
    closePublish() {
      this.isPublishVisible = false
    }
  },
  beforeRouteUpdate(to, _from, next) {
    if (to.hash === '#toggle') {
      this.toggle()
      next(false)
    }
    else {
      next()
    }
  },
  computed: {
    ...mapGetters({
      onboardingGrid: 'sandbox/onboardingGrid',
      onboardingProgress: 'onboarding/getProgressIndex',
      isGridGenerated: 'onboarding/isGridGenerated'
    }),
    isGridParamsVisible() {
      return !this.isGridGenerated || this.isGridConfigVisible
    },
    isEnabled() {
      return this.onboardingProgress >= 1
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/alert.scss";
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";
@import "./src/styles/link.scss";

.container {
  @include tab-container;
}

.grid {
  transition: opacity 400ms ease-out;

  &.disabled {
    opacity: 0.5;
  }
}

strong {
  display: inline-block;
  margin-bottom: 0.25em;
}

.formula {
  margin-bottom: 20px;
}

.grid-menu {
  margin: 0;
}

.loading {
  margin-top: 3em;
}
</style>
