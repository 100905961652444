<template>
  <div class="container">
    <h2 v-t="'candidates.jobOfferName'"></h2>
    <input
      type="text"
      v-model="jobOfferName"
      @keyup.enter="submit()"
      ref="nameInput"
      :placeholder="$t('candidates.jobOfferNamePlaceholder')"
      :class="{'input-error' : $v.jobOfferName.$error}">
    <br>

    <div class="error-message" v-if="errorMessage">
      {{ errorMessage }}
    </div>

    <menu>
      <button
        class="secondary"
        @click="$emit('close')"
        :disabled="isLoading"
        v-t="'common.cancel'"></button>
      <loading-button
        class="primary"
        @click="submit()"
        :loading="isLoading">
        <span v-t="'common.update'"></span>
      </loading-button>
    </menu>
  </div>

</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  props: ['jobOffer'],
  data() {
    return {
      jobOfferName: this.jobOffer.name,
      isLoading: false,
      errorMessage: null
    }
  },
  methods: {
    submit() {
      this.$v.$touch()

      if (!this.$v.$error) {
        this.isLoading = true

        this.$store.dispatch('candidates/updateJobOffer', {
          id: this.jobOffer.id,
          name: this.jobOfferName
        })
          .then(response => {
            this.$emit('close', response)
          })
          .catch(e => {
            this.isLoading = false
          })
      }
    }
  },
  mounted() {
    this.$refs.nameInput.focus()
  },
  validations() {
    return { jobOfferName: { required } }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";

.container {
  @include modal-container;
  width: 400px;
}
</style>
