<template>
  <div class="container" v-if="storedVariablePlan">
    <h1 v-t="'variable.periodForm.title'"></h1>
    <p
      class="preserve-lines" v-t="{path: 'variable.periodForm.intro', args: {period: formatPeriod(this.storedVariablePlan, false, '→')}}"></p>
    <VariablePlanPeriod
      :big="true"
      v-model="variablePlanModel" />
    <p class="error-message fade-in" v-if="errorMessage">{{ errorMessage }}</p>
    <menu>
      <button
        type="button"
        class="secondary"
        @click="$router.go(-1)"
        v-t="'common.cancel'">
      </button>
      <loading-button
        class="primary"
        :disabled="isLoading"
        :loading="isLoading"
        @click="submit">
        <span v-t="'variable.periodForm.action'"></span>
      </loading-button>
    </menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { buildNextVariablePlan, formatPeriod } from '@/utils/variable'
import VariablePlanPeriod from '@components/variable/VariablePlanPeriod.vue'

export default {
  components: {
    VariablePlanPeriod
  },
  data() {
    return {
      isLoading: false,
      errorMessage: null,
      variablePlanModel: {}
    }
  },
  computed: {
    ...mapGetters({
      getVariablePlan: 'variablePlans/getVariablePlan',
      kinds: 'variablePlans/getKinds',
      recurrences: 'variablePlans/getRecurrences'
    }),
    storedVariablePlan() {
      return this.getVariablePlan(this.$route.params.id)
    }
  },
  methods: {
    formatPeriod,
    initModel() {
      if (this.storedVariablePlan) {
        this.variablePlanModel = buildNextVariablePlan(this.storedVariablePlan)
      }
    },
    async submit() {
      this.errorMessage = null
      this.isLoading = true
      try {
        const variablePlan = await this.$store.dispatch('variablePlans/createVariablePlan', this.variablePlanModel)
        const employeeIds = this.storedVariablePlan.variableWages.map(w => w.userId)
        await this.$store.dispatch('variablePlans/createVariableWages', { variablePlan, employeeIds })
        this.$router.push({ name: 'variablePlan', params: { id: variablePlan.id, highlightId: variablePlan.id, highlightClass: 'flash' } })
      }
      catch (e) {
        this.errorMessage = e
        this.isLoading = false
      }
    }
  },
  watch: {
    storedVariablePlan: 'initModel'
  },
  created() {
    this.initModel()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";

.container {
  @include container;
}

menu {
  margin: 2em 0 0.5em;
}
</style>
