<template>
  <div
    id="account-menu-button"
    class="dropdown header-link"
    :class="{highlighted: $store.state.accountMenuDisplayed, updated: isChangelogUpdated}">
    <div class="dropdown-button">
      <div>
        <NameThumbnail :employee="user" inline=true />
        <div class="changelog-updated" v-if="isChangelogUpdated && $$isAtLeastManager" v-t="'account.new'"></div>
      </div>
      <img class="down" src="~@/assets/triangle-down-black.svg">
    </div>
    <div
      class="dropdown-content"
      v-if="$store.state.accountMenuDisplayed">
      <ul>
        <li
          class="header-link settings"
          @click="openSettings"
          v-t="'account.settings'"></li>
        <li
          class="header-link changelog"
          @click="openChangelog"
          v-t="'account.changelog'"></li>
        <li
          class="header-link signout"
          @click="signOut()"
          v-t="'account.signout'"></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NameThumbnail from '@components/commons/NameThumbnail.vue'

const CW_PACKAGE_VERSION = 'CW_PACKAGE_VERSION'

export default {
  components: {
    NameThumbnail
  },
  data() {
    return {
      isChangelogUpdated: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'account/getUser'
    })
  },
  methods: {
    initChangelogUpdated() {
      const seenVersion = localStorage.getItem(CW_PACKAGE_VERSION)
      const currentVersion = process.env.PACKAGE_VERSION

      this.isChangelogUpdated = seenVersion && seenVersion !== currentVersion
      if (!seenVersion) {
        localStorage.setItem(CW_PACKAGE_VERSION, process.env.PACKAGE_VERSION)
      }
    },
    openChangelog() {
      this.isChangelogUpdated = false
      localStorage.setItem(CW_PACKAGE_VERSION, process.env.PACKAGE_VERSION)
      this.$$openUrl(this.$t('urls.changelog'))
    },
    openSettings() {
      this.$router.push('/settings')
    },
    signOut() {
      this.$store.dispatch('account/signOut')
    }
  },
  created() {
    this.initChangelogUpdated()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/link.scss";

.dropdown {
  position: relative;
  user-select: none;
  display: flex;
  align-items: center;
  padding: 0 15px 0 20px;
  margin-right: -10px;
  height: 100%;
  @include font-semibold;

  .dropdown-button {
    display: flex;
    align-items: center;
    pointer-events: none;
    text-align: center;

    .thumbnail {
      width: 40px;
      height: 40px;
      line-height: 40px;
    }
  }

  .down {
    width: 10px;
    margin: 2px 0 0 12px;
    pointer-events: none;
  }
}

.highlighted {
  background: $lightteal-color;
}

.updated {
  .changelog-updated {
    @include font-smaller-size;
    color: $clearteal-color;
    text-align: right;
  }

  .changelog::after {
    content: " ";
    display: inline-block;
    width: 9px;
    height: 9px;
    border-radius: 9px;
    margin-left: 10px;
    background: $clearteal-color;
  }
}

.dropdown-content {
  @include font-small-size;
  text-align: center;
  z-index: 1000000;
  top: $header-height;
  right: 0px;
  width: 180px;
  position: absolute;
  background: white;
  border: $border;
  border-bottom: 3px solid $border-color;
  border-top: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  @include font-regular;

  ul {
    margin: 0;
    padding: 0;
    text-align: left;
    list-style-type: none;
  }

  li.header-link {
    line-height: 3.4em;
    padding: 0 0 0 40px;
    background: transparent none no-repeat 12px center;

    &.settings {
      background-image: url(~@/assets/menu-settings.svg);
    }

    &.changelog {
      background-image: url(~@/assets/menu-changelog.svg);
    }

    &.signout {
      background-image: url(~@/assets/menu-signout.svg);
    }
  }
}
</style>
