<template>
  <div class="wage-viewer" ref="container">
    <menu class="float-right edit-menu">
      <loading-button
        v-if="$$isAdmin && hasDeletePermission"
        class="destructive"
        @click="deleteWage"
        :loading="isLoading">
        <span v-t="'employees.employee.wage.delete'" />
      </loading-button>
      <router-link
        v-if="$$isAdmin && $$hasUpdatePermission(employee)"
        tag="button"
        class="secondary"
        :to="{name:'updateEmployeeWage', params:{wageId: wage.id} }"
        v-t="'common.update'" />
    </menu>
    <h1 v-t="`grid.wage.viewer.${isCurrentPeriod ? 'current' : 'previous'}Title${isOneself ? 'Oneself' : ''}`"></h1>
    <loading-view v-if="isLoading" />
    <div v-else>
      <p v-if="periodLabel" class="light-text period" v-t="periodLabel"></p>
      <div class="wage-formula-viewer">
        <p v-t="`grid.wage.viewer.${salaryOnGridIntro ? 'on' : 'off'}GridSalaryIntro`"></p>
        <WageGridFormula
          :wageDetails="wageDetails"
          :showVariable="showVariable"
          :showFormula="salaryOnGridIntro"
          @select="selectComponent" />
        <div v-if="wage.explanation">
          <div class="input-label" v-t="'wageCalculator.reason'"></div>
          <MarkdownText :value="wage.explanation" />
        </div>
      </div>

      <div class="wage-grid-viewer" v-if="grid">
        <h2 :data-id="grid.id">
          {{getGridTitle(grid, $$companyName)}}
          <GridVersionPicker
            class="grid-version-picker"
            :disabled="true"
            :selectedGrid="grid" />
        </h2>
        <MarkdownText v-model="grid.description" />
        <div>
          <GridComponentDetail
            v-for="component in gridComponents"
            :key="component.id"
            :wageDetails="wageDetails"
            :grid="grid"
            :component="component"
            :activeComponentSlug="activeComponentSlug"
            :showDescriptions="true"
            :showSkills="true"
            :showEmployees="false"
            :showValues="true"
            @select="selectComponent" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computePeriod } from '@/utils/newsfeed'
import { slugify } from '@/utils/string'
import animateScrollTo from 'animated-scroll-to'
import { componentsForRemunerationType, getWageDetails, getGridTitle, getParentComponent } from '@/utils/grid'
import LoadingView from '@components/commons/LoadingView.vue'
import MarkdownText from '@components/commons/MarkdownText.vue'
import GridComponentDetail from '@components/grid/viewer/GridComponentDetail.vue'
import WageGridFormula from '@components/wage/WageGridFormula.vue'
import GridVersionPicker from '@components/grid/viewer/GridVersionPicker.vue'
import { formatDate } from '@/utils/date'

export default {
  components: {
    GridComponentDetail,
    GridVersionPicker,
    MarkdownText,
    WageGridFormula,
    LoadingView
  },
  props: {
    wage: Object,
    newsfeedWages: Array,
    showVariable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeComponentSlug: null,
      isLoading: true,
      grid: null
    }
  },
  computed: {
    wageDetails() {
      return { ...this.wage, ...getWageDetails(this.grid, this.wage, { includeVariable: this.showVariable, includeComponents: true }) }
    },
    employee() {
      if (this.wage) {
        return this.$store.getters['employees/getEmployee'](this.wage.employeeId)
      }
    },
    hasDeletePermission() {
      if (this.employee) {
        const isCurrentWage = this.employee.currentWage && this.employee.currentWage.id === this.wage.id
        const hasGrid = !!this.wage.gridId
        return this.$$hasUpdatePermission(this.employee) && (!isCurrentWage || !hasGrid)
      }
    },
    isOneself() {
      return this.wage.employeeId === this.$store.getters['account/getUser'].id
    },
    salaryOnGridIntro() {
      return this.wage.hasLevels && this.$$hasPermission('employeesWageFormula')
    },
    gridComponents() {
      return componentsForRemunerationType(this.grid.components, 'salary')
    },
    computedPeriod() {
      if (this.newsfeedWages) {
        return computePeriod(this.wage, this.newsfeedWages)
      }
    },
    isCurrentPeriod() {
      return !(this.computedPeriod && this.computedPeriod[1])
    },
    periodLabel() {
      if (this.computedPeriod) {
        const format = 'MMMM YYYY'
        const [startDate, endDate] = this.computedPeriod
        if (startDate && endDate) {
          return {
            path: 'grid.wage.viewer.previousPeriod',
            args: {
              startDate: formatDate(startDate, format),
              endDate: formatDate(endDate, format)
            }
          }
        }
        else if (startDate) {
          return {
            path: 'grid.wage.viewer.currentPeriod',
            args: { startDate: formatDate(startDate, format) }
          }
        }
      }
    }
  },
  methods: {
    getGridTitle,
    getComponentSlug(component) {
      return slugify(component.name)
    },
    selectComponent(component) {
      if (component) {
        const parentComponent = getParentComponent(component, this.grid)
        if (parentComponent) {
          const slug = this.getComponentSlug(parentComponent)
          const anchor = '#' + slug
          animateScrollTo(this.$el.querySelector(anchor), {
            elementToScroll: this.$refs.container,
            minDuration: 0,
            maxDuration: 1000
          }).then(() => {
            setTimeout(() => {
              this.activeComponentSlug = slug
            }, 110)
          })
        }
      }
    },
    async deleteWage() {
      if (confirm(this.$t('employees.employee.wage.deleteConfirmation'))) {
        try {
          this.isLoading = true
          await this.$store.dispatch('employees/deleteWage', this.wage)
          this.$emit('close')
        }
        catch (error) {
          alert(error)
          this.isLoading = false
        }
      }
    }
  },
  mounted() {
    const gridId = this.wage.gridId
    if (gridId) {
      const promise = this.wage.sharingToken
        ? this.$store.dispatch('wageSharing/getGrid', this.wage.sharingToken)
        : this.$store.dispatch('currentGrid/getGrid', gridId)
      promise.then(grid => {
        this.grid = grid
        this.isLoading = false
      })
    }
    else {
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";

.wage-viewer {
  width: $content-max-width;
  padding: 20px 20px 0 20px;
}

h1,
h2 {
  margin-bottom: 0;
}

.grid-version-picker {
  margin-left: 0.25em;
  vertical-align: 1.5px;
}

.period {
  margin: 0.5em 0 -5px;
}

.wage-formula-viewer,
.wage-grid-viewer {
  margin-bottom: 2em;
}

.edit-menu {
  margin: 0 35px 0 0;

  .destructive:not(:last-of-type) {
    margin-right: 0;
  }
}
</style>
