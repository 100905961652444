import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'fr', // Hide english for now, then => navigator.language.substring(0, 2),
  fallbackLocale: 'fr',
  currency: 'EUR',
  messages: {
    fr: require('./fr.json'),
    en: require('./en.json')
  },
  preserveDirectiveContent: true // See https://github.com/kazupon/vue-i18n/issues/408
})

if (module.hot) {
  module.hot.accept(['./fr.json'], () => {
    i18n.setLocaleMessage('fr', require('./fr.json'))
    i18n.setLocaleMessage('en', require('./en.json'))
  })
};

export default i18n
