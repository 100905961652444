import i18n from '@/i18n'
import api from '@/store/api'
import short from 'short-uuid'
import { orderBy, uniq } from 'lodash'
import { slugify } from './string'

export const CUSTOM_FIELD_URN = 'urn:clw:cf:'
export const CUSTOM_FIELD_KINDS = ['string', 'float']
export const CUSTOM_FIELD_SCOPES = ['dashboard', 'variable']

export const FIELDS = [
  // Name fields
  { id: 'firstName', type: 'string', category: 'name' },
  { id: 'lastName', type: 'string', category: 'name' },
  // Personal fields
  { id: 'gender', type: 'gender', category: 'personal', autocomplete: true, fixed: true },
  { id: 'birthdate', type: 'date', category: 'personal' },
  { id: 'employeeNumber', type: 'string', category: 'personal' },
  { id: 'email', type: 'email', category: 'personal' },
  // Company fields
  { id: 'arrivalDate', type: 'date', category: 'company' },
  { id: 'departureDate', type: 'date', category: 'company' },
  { id: 'jobGroup', type: 'string', category: 'company', autocomplete: true, ref: 'role' },
  { id: 'jobType', type: 'string', category: 'company', autocomplete: true, ref: 'role' },
  { id: 'jobTitle', type: 'string', category: 'company', autocomplete: true },
  { id: 'managementLevel', type: 'string', category: 'company', autocomplete: true, ref: 'management' },
  { id: 'city', type: 'string', category: 'company', autocomplete: true, ref: 'city' },
  { id: 'salary', type: 'float', category: 'company' },
  { id: 'contractualVariable', type: 'float', category: 'company' },
  { id: 'contractType', type: 'string', category: 'company', autocomplete: true },
  { id: 'spc', type: 'string', category: 'company', autocomplete: true },
  // Team fields
  { id: 'manager', type: 'string', category: 'team' },
  { id: 'managerId', type: 'string', category: 'team', autocomplete: true, fixed: true, hidden: true },
  { id: 'department', type: 'string', category: 'team', autocomplete: true },
  { id: 'subDepartment', type: 'string', category: 'team', autocomplete: true }
]

function getCustomField(customField) {
  const description = customField.description || i18n.t(`settings.settingsCompanyCustomFields.kinds.${customField.kind}.name`)
  return {
    id: customField.id,
    name: customField.name,
    description: description,
    placeholder: description,
    type: customField.kind,
    category: 'custom',
    autocomplete: false
  }
}

// Get employee fields
export function getFields(category, options) {
  options = options || {}
  if (!options.store && (!category || category === 'custom')) {
    throw new Error('custom categories requires a store')
  }
  return FIELDS
    .map(field => ({
      ...field,
      name: i18n.t(`employees.fields.${field.id}.name`),
      description: i18n.t(`employees.fields.${field.id}.description`),
      placeholder: i18n.t(`employees.fields.${field.id}.placeholder`)
    }))
    .concat((!category || category === 'custom')
      ? options.store.getters['company/getCustomFields'].map(getCustomField)
      : [])
    .filter(field =>
      (!category || field.category === category) &&
      (options.hidden || !field.hidden) &&
      (!options.exclude || !options.exclude.includes(field.id)) &&
      (!options.include || options.include.includes(field.id))
    )
}

// Get employee field
export function getFieldById(id, store) {
  const fields = getFields(null, { store, hidden: true })
  return fields.find(f => f.id === id)
}

// Get employee field value
export function getField(employee, field, options) {
  options = options || {}
  switch (field) {
    case 'fullName':
      return options.inverted
        ? [employee.lastName, employee.firstName].join(' ')
        : [employee.firstName, employee.lastName].join(' ')
    case 'slug':
      return slugify(getField(employee, 'fullName', options))
    case 'currentWageDetails':
      if (options.fieldOnly) {
        return
      }
      else if (!options.store) {
        throw new Error('currentWageDetails requires a store')
      }
      return options.store.getters['employees/getCurrentWageDetails'](employee)
    case 'referenceWageDetails':
      if (options.fieldOnly) {
        return
      }
      else if (!options.store) {
        throw new Error('ReferenceWageDetails requires a store')
      }
      return options.store.getters['employees/getReferenceWageDetails'](employee)
    case 'salary':
      if (options.fieldOnly) {
        return
      }
      else if (!options.store) {
        throw new Error('salary requires a store')
      }
      const currentWageDetails = options.store.getters['employees/getCurrentWageDetails'](employee)
      return currentWageDetails ? currentWageDetails.summary.salary.value : 0
    case 'contractualVariable':
      if (options.fieldOnly) {
        return
      }
      else if (!options.store) {
        throw new Error('contractualVariable requires a store')
      }
      const currentWageDetails2 = options.store.getters['employees/getCurrentWageDetails'](employee)
      return currentWageDetails2 ? currentWageDetails2.summary.salary.variableValue : 0
    case 'jobType':
      if (options.fieldOnly) {
        // Without store, we can extract job type from current wage qualification,
        // but we cannot fallback onboarding wage.
        if (employee.currentWage &&
          employee.currentWage.qualification &&
          employee.currentWage.qualification[0] &&
          employee.currentWage.qualification[0].ref === 'role') {
          return employee.currentWage.qualification[0].name
        }
        else {
          return employee.jobType
        }
      }
      else if (!options.store) {
        throw new Error('jobType requires a store')
      }
      const wageDetails = options.store.getters['employees/getReferenceWageDetails'](employee)
      const roleComponent = wageDetails && wageDetails.selectedComponents.find(c => c.ref === 'role')
      return roleComponent ? roleComponent.selectedLevel.name : employee.jobType
    case 'department':
    case 'team':
      return options.departmentOnly ? employee.department : getField(employee, 'fullDepartment', options)
    case 'fullDepartment':
      return [employee.department, employee.subDepartment].filter(Boolean).join(' › ')
    case 'avatar':
      if (options.fieldOnly) {
        return
      }
      else if (!options.store) {
        throw new Error('avatar requires a store')
      }
      if (employee.hasAvatar) {
        const company = options.store.getters['account/getCompany']
        const cb = Math.round(Number.MAX_SAFE_INTEGER * Math.random())
        return `${api.defaults.baseURL}/avatar/${company.id}/${employee.id}?${cb}`
      }
      break
    case 'manager':
      if (options.fieldOnly) {
        return employee[field] || null
      }
      else if (!options.store) {
        throw new Error('manager requires a store')
      }
      const manager = options.store.getters['employees/getEmployee'](employee.managerId)
      return manager ? getField(manager, 'fullName') : undefined
    case 'initials':
      if (employee.initials) {
        return employee.initials.substr(0, 3).toUpperCase()
      }
      else {
        return (
          (employee.firstName || '').split('-')
            .map(c => c.trim().charAt(0))
            .slice(0, 2)
            .join('') +
          (employee.lastName || '').charAt(0)
        ).toUpperCase()
      }
    default:
      return field.startsWith(CUSTOM_FIELD_URN) ? employee.customFields && employee.customFields[field] : employee[field] || null
  }
}

// Get field autocomplete options
export function getFieldOptions(field, options) {
  let employees
  options = options || {}
  switch (field.id) {
    case 'gender':
      return ['m', 'f'].map(id => ({
        id,
        name: i18n.t(`employees.fields.gender.value.${id}`)
      }))
    case 'managerId':
      if (!options.store) {
        throw new Error(`${field.name} requires a store`)
      }
      employees = options.store.getters['employees/getAllEmployees']
      return orderBy(employees.map(employee => ({
        id: employee.id,
        name: getField(employee, 'fullName', options)
      })), ['name'])
    default:
      if (!options.store) {
        throw new Error(`${field.name} requires a store`)
      }
      employees = options.store.getters['employees/getEmployees']
      const values = uniq(employees
        .map(e => getField(e, field.id, options))
        .concat([options.model && options.model.name])
        .filter(t => t))
        .sort()
      return values.map(id => ({
        id,
        name: id
      }))
  }
}

export function buildCustomField() {
  return {
    id: `${CUSTOM_FIELD_URN}${short.generate()}`,
    name: '',
    description: '',
    kind: 'string',
    scopes: []
  }
}
