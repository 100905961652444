<template>
  <div class="wrapper">
    <div class="svg-container gender-container">
      <header class="gender-header">
        <div class="preserve-lines" v-t="'dashboard.graphs.gender.intro'"></div>
        <div class="gender-logo"></div>
      </header>
      <div class="gender-section">
        <div class="gender-title">
          <h2 v-t="'dashboard.graphs.gender.salaryDiff.title'"></h2>
          <span>
            {{ salaryDiffScore * 40 }}
            /
            {{$tc('dashboard.graphs.gender.points', 40, {count: 40})}}
          </span>
        </div>
        <div class="preserve-lines" v-t="'dashboard.graphs.gender.salaryDiff.intro'"></div>
        <div class="gender-graph">
          <div class="gender-table gender-table-salary-diff ">
            <table class="blue">
              <tr class="row">
                <th v-t="'dashboard.graphs.gender.columns.group'"></th>
                <th v-t="'dashboard.graphs.gender.columns.females'"></th>
                <th v-t="'dashboard.graphs.gender.columns.meanSalary'"></th>
                <th v-t="'dashboard.graphs.gender.columns.males'"></th>
                <th v-t="'dashboard.graphs.gender.columns.meanSalary'"></th>
                <th v-t="'dashboard.graphs.gender.columns.eligible'"></th>
                <th v-t="'dashboard.graphs.gender.columns.meanDiff'"></th>
                <th></th>
              </tr>
              <tr
                class="row"
                :class="{'gender-invalid': !group.valid}"
                v-for="group in salaryDiff"
                :key="group.name">
                <td>{{group.name}}</td>
                <td>{{group.femaleCount}}</td>
                <td>{{group.femaleSalaryMean | formattedCurrency(false) }}</td>
                <td>{{group.maleCount}}</td>
                <td>{{group.maleSalaryMean | formattedCurrency(false) }}</td>
                <td v-t="group.valid ? 'common.yes' : 'common.no'"></td>
                <td>{{group.salaryDiff | formattedNumber(1, 1) }}&nbsp;%</td>
                <td>
                  <graph-score :valid="group.valid" :score="group.salaryDiffScore" />
                </td>
              </tr>
              <tr class="row tfoot">
                <th colspan="7" v-t="'dashboard.graphs.gender.trend'"></th>
                <th>
                  <graph-score :valid="isGroupsValid(salaryDiff)" :score="salaryDiffScore" />
                </th>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="gender-section">
        <div class="gender-title">
          <h2 v-t="'dashboard.graphs.gender.salaryRaiseDiff.title'"></h2>
          <span>
            <template v-if="salaryRaiseDiff">
              {{ salaryRaiseDiffScore * 35 }}
              /
            </template>
            {{$tc('dashboard.graphs.gender.points', 35, {count: 35})}}
          </span>
        </div>
        <div class="preserve-lines" v-t="'dashboard.graphs.gender.salaryRaiseDiff.intro'"></div>
        <div class="gender-graph">
          <div class="gender-table gender-table-salary-raise-diff" v-if="salaryRaiseDiff">
            <table class="blue">
              <tr class="row">
                <th v-t="'dashboard.graphs.gender.columns.group'"></th>
                <th v-t="'dashboard.graphs.gender.columns.females'"></th>
                <th v-t="'dashboard.graphs.gender.columns.raises'"></th>
                <th v-t="'dashboard.graphs.gender.columns.males'"></th>
                <th v-t="'dashboard.graphs.gender.columns.raises'"></th>
                <th v-t="'dashboard.graphs.gender.columns.eligible'"></th>
                <th v-t="'dashboard.graphs.gender.columns.meanDiff'"></th>
                <th></th>
              </tr>
              <tr
                class="row"
                :class="{'gender-invalid': !group.valid}"
                v-for="group in salaryRaiseDiff"
                :key="group.name">
                <td>{{group.name}}</td>
                <td>{{group.femaleCount}}</td>
                <td>{{group.femaleRaiseCount}}</td>
                <td>{{group.maleCount}}</td>
                <td>{{group.maleRaiseCount}}</td>
                <td v-t="group.valid ? 'common.yes' : 'common.no'"></td>
                <td>{{group.salaryRaiseDiff | formattedNumber(1, 1) }}&nbsp;%</td>
                <td>
                  <graph-score :valid="group.valid" :score="salaryRaiseDiffScore" />
                </td>
              </tr>
              <tr class="row tfoot">
                <th colspan="7" v-t="'dashboard.graphs.gender.trend'"></th>
                <th>
                  <graph-score :valid="isGroupsValid(salaryRaiseDiff)" :score="salaryRaiseDiffScore" />
                </th>
              </tr>
            </table>
          </div>
          <button v-else class="secondary" :disabled="isLoadingEmployeesNewsfeeds" @click="loadEmployeesNewsfeed">
            {{$t(isLoadingEmployeesNewsfeeds ? 'dashboard.graphs.gender.loading' : 'dashboard.graphs.gender.loadData')}}
          </button>
        </div>
      </div>
      <div class="gender-section">
        <div class="gender-title">
          <h2 v-t="'dashboard.graphs.gender.maternityLeave.title'"></h2>
          <span>{{$tc('dashboard.graphs.gender.points', 15, {count: 15})}}</span>
        </div>
        <div class="preverse-lines" v-t="'dashboard.graphs.gender.maternityLeave.intro'"></div>
        <br>
        <div class="gender-table">
          <table class="blue">
            <tr class="row">
              <th colspan="2" v-t="'dashboard.graphs.gender.maternityLeave.table.title'"></th>
            </tr>
            <tr class="row">
              <td v-t="'dashboard.graphs.gender.maternityLeave.table.good'"></td>
              <td>{{$tc('dashboard.graphs.gender.points', 15, {count: 15})}}</td>
            </tr>
            <tr class="row">
              <td v-t="'dashboard.graphs.gender.maternityLeave.table.bad'"></td>
              <td>{{$tc('dashboard.graphs.gender.points', 0, {count: 0})}}</td>
            </tr>
          </table>
        </div>
        <p class="light-text" v-t="'dashboard.graphs.gender.maternityLeave.unavailable'"></p>
      </div>
      <div class="gender-section">
        <div class="gender-title">
          <h2 v-t="'dashboard.graphs.gender.topSalaries.title'"></h2>
          <span>
            {{ topSalariesScore * 10 }}
            /
            {{$tc('dashboard.graphs.gender.points', 10, {count: 10})}}
          </span>
        </div>
        <div class="preverse-lines" v-t="'dashboard.graphs.gender.topSalaries.intro'"></div>
        <div class="gender-graph">
          <div class="gender-table gender-table-top-salaries">
            <table class="blue">
              <tr class="row">
                <th colspan="3" v-t="'dashboard.graphs.gender.columns.salariesRank'"></th>
              </tr>
              <tr
                v-for="(employee, i) in topSalaries"
                :key="employee.id"
                class="row">
                <td>{{i + 1}}</td>
                <td class="gender-dot" :class="employee.gender">{{employee.fullName}}</td>
                <td>{{employee.salary | formattedCurrency }}</td>
              </tr>
              <tr class="row tfoot">
                <th colspan="2" v-t="'dashboard.graphs.gender.trend'"></th>
                <th>
                  <graph-score :score="topSalariesScore" />
                </th>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="gender-section">
        <div class="gender-title">
          <h2 v-t="'dashboard.graphs.gender.footer.title'"></h2>
        </div>
        <div v-html="$t('dashboard.graphs.gender.footer.intro')"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mean, sum } from 'simple-statistics'
import GraphScore from '@components/graph/GraphScore.vue'
import { dateDiff } from '@/utils/date'
import i18n from '@/i18n'
import orderBy from 'lodash.orderby'
import groupBy from 'lodash.groupby'
import partition from 'lodash.partition'

// Utils
const getEmployeeAgeGroup = ({ age }) => {
  switch (true) {
    case age < 30:
      return i18n.t('dashboard.graphs.gender.groups.agedUnder30')
    case age >= 30 && age < 40:
      return i18n.t('dashboard.graphs.gender.groups.agedUnder40')
    case age >= 40 && age < 50:
      return i18n.t('dashboard.graphs.gender.groups.agedUnder50')
    case age >= 50:
      return i18n.t('dashboard.graphs.gender.groups.agedOver50')
  }
}

const getEmployeeSocioprofessionalCategoryGroup = employee => {
  return employee.spc || i18n.t('dashboard.graphs.gender.groups.default')
}

const sortEmployeesBeforeGrouping = employee => {
  return orderBy(employee, ['spc', 'age'])
}

const isFemale = employee => employee.gender === 'female'

const groupEmployees = employees => {
  return groupBy(
    sortEmployeesBeforeGrouping(employees),
    e => [getEmployeeSocioprofessionalCategoryGroup(e), getEmployeeAgeGroup(e)].join(' '))
}

// SalaryDiff
const getSalaryDiff = employees => {
  const employeesCount = employees.length
  const groupedEmployees = groupEmployees(employees)
  return Object.entries(groupedEmployees).map(([name, employees]) => {
    const employeesRate = employeesCount ? employees.length / employeesCount : 1
    const [females, males] = partition(employees, isFemale)
    const femaleCount = females.length
    const maleCount = males.length
    const valid = !!(femaleCount >= 3 && maleCount >= 3)
    const femaleSalaryMean = femaleCount ? mean(females.map(e => e.salary)) : 0
    const maleSalaryMean = maleCount ? mean(males.map(e => e.salary)) : 0
    const salaryDiff = maleSalaryMean ? (maleSalaryMean - femaleSalaryMean) / maleSalaryMean * 100 : 0
    const salaryDiffSign = salaryDiff ? Math.abs(salaryDiff) / salaryDiff : 1
    const salaryDiffThreshold = Math.max(Math.abs(salaryDiff) - 5, 0) * salaryDiffSign
    const salaryDiffRate = salaryDiffThreshold * employeesRate
    const salaryDiffScore = getSalaryDiffSubScore(salaryDiffThreshold)

    return {
      name,
      femaleCount,
      maleCount,
      valid,
      femaleSalaryMean,
      maleSalaryMean,
      salaryDiff,
      salaryDiffSign,
      salaryDiffThreshold,
      salaryDiffRate,
      salaryDiffScore
    }
  })
}

const getSalaryDiffSubScore = salaryDiff => {
  salaryDiff = Math.abs(salaryDiff)

  switch (true) {
    case salaryDiff > 20:
      return 0 / 40
    case salaryDiff > 19:
      return 2 / 40
    case salaryDiff > 18:
      return 5 / 40
    case salaryDiff > 17:
      return 8 / 40
    case salaryDiff > 16:
      return 11 / 40
    case salaryDiff > 15:
      return 14 / 40
    case salaryDiff > 14:
      return 17 / 40
    case salaryDiff > 13:
      return 19 / 40
    case salaryDiff > 12:
      return 21 / 40
    case salaryDiff > 11:
      return 23 / 40
    case salaryDiff > 9:
      return 27 / 40
    case salaryDiff > 8:
      return 29 / 40
    case salaryDiff > 7:
      return 31 / 40
    case salaryDiff > 6:
      return 33 / 40
    case salaryDiff > 5:
      return 34 / 40
    case salaryDiff > 4:
      return 35 / 40
    case salaryDiff > 3:
      return 36 / 40
    case salaryDiff > 2:
      return 37 / 40
    case salaryDiff > 1:
      return 38 / 40
    case salaryDiff > 0:
      return 39 / 40
    default:
      return 40 / 40
  }
}

const getSalaryDiffScore = salaryDiff => {
  let validSalaryDiff = salaryDiff.filter(g => g.valid)
  validSalaryDiff = validSalaryDiff.length ? validSalaryDiff : salaryDiff
  const salaryDiffTotal = sum(validSalaryDiff.map(g => g.salaryDiffRate))
  return getSalaryDiffSubScore(salaryDiffTotal)
}

// SalaryRaiseDiff

const isEmployeeRaised = (employee, employeesNewsfeeds) => {
  const employeeNewsfeed = employeesNewsfeeds[employee.id]
  if (employeeNewsfeed) {
    // TODO: Use employee stats
    return !!employeeNewsfeed.find(wage => {
      return dateDiff(wage.startDate, new Date(), 'month') <= 12 &&
        employeeNewsfeed.find(w => w.salaryValue < wage.salaryValue && dateDiff(w.startDate, new Date(), 'month') > 12)
    })
  }
}

const countRaisedEmployees = (employees, employeesNewsfeeds) => {
  return employees.filter(e => isEmployeeRaised(e, employeesNewsfeeds)).length
}

const getSalaryRaiseDiff = (employees, employeesNewsfeeds) => {
  if (!Object.keys(employeesNewsfeeds).length) {
    return
  }
  const name = i18n.t('dashboard.graphs.gender.groups.all')
  const [females, males] = partition(employees, isFemale)
  const femaleCount = females.length
  const maleCount = males.length
  const femaleRaiseCount = countRaisedEmployees(females, employeesNewsfeeds)
  const maleRaiseCount = countRaisedEmployees(males, employeesNewsfeeds)
  const valid = !!(femaleCount >= 5 && maleCount >= 5 && (femaleRaiseCount || maleRaiseCount))
  const femaleRaiseDiff = femaleCount ? femaleRaiseCount / femaleCount : 0
  const maleRaiseDiff = maleCount ? maleRaiseCount / maleCount : 0
  const salaryRaiseDiff = Math.abs(femaleRaiseDiff - maleRaiseDiff) * 100
  const salaryRaiseAmount = salaryRaiseDiff / 100 * Math.min(femaleCount, maleCount)

  return [{
    name,
    femaleCount,
    femaleRaiseCount,
    maleCount,
    maleRaiseCount,
    valid,
    salaryRaiseDiff,
    salaryRaiseAmount
  }]
}

const getSalaryRaiseDiffScore = salaryRaiseDiffs => {
  const { salaryRaiseDiff, salaryRaiseAmount } = salaryRaiseDiffs[0]
  switch (true) {
    case salaryRaiseDiff > 10 || salaryRaiseAmount > 10:
      return 0 / 35
    case salaryRaiseDiff > 5 || salaryRaiseAmount > 5:
      return 15 / 35
    case salaryRaiseDiff > 2 || salaryRaiseAmount > 2:
      return 25 / 35
    default:
      return 35 / 35
  }
}

// TopSalaries
const getTopSalaries = employees => {
  return orderBy(employees, ['salary'], ['desc']).slice(0, 10)
}

const getTopSalariesScore = topSalaries => {
  const [females, males] = partition(topSalaries, isFemale)
  const femaleCount = females.length
  const maleCount = males.length
  const minCount = Math.min(femaleCount, maleCount)
  switch (true) {
    case minCount >= 4:
      return 10 / 10
    case minCount >= 2:
      return 5 / 10
    default:
      return 0 / 10
  }
}

export default {
  components: {
    GraphScore
  },
  name: 'gender-equality-index-graph',
  data() {
    return {
      isLoadingEmployeesNewsfeeds: false
    }
  },
  computed: {
    ...mapGetters({
      employees: 'statistics/getFilteredEmployees',
      employeesNewsfeeds: 'statistics/getEmployeesNewsfeeds'
    }),
    validEmployees() {
      // Reject future employees and job offer profiles
      return this.employees.filter(e => e.seniorityDate >= 0 && !e.jobOffer)
    },
    salaryDiff() {
      return getSalaryDiff(this.validEmployees)
    },
    salaryDiffScore() {
      return getSalaryDiffScore(this.salaryDiff)
    },
    salaryRaiseDiff() {
      return getSalaryRaiseDiff(this.validEmployees, this.employeesNewsfeeds)
    },
    salaryRaiseDiffScore() {
      return getSalaryRaiseDiffScore(this.salaryRaiseDiff)
    },
    topSalaries() {
      return getTopSalaries(this.validEmployees)
    },
    topSalariesScore() {
      return getTopSalariesScore(this.topSalaries)
    }
  },
  created() {
    this.loadEmployeesNewsfeed()
  },
  methods: {
    isGroupsValid(groups) {
      return !!(groups && groups.find(group => group.valid))
    },
    async loadEmployeesNewsfeed() {
      this.isLoadingEmployeesNewsfeeds = true
      await this.$store.dispatch('statistics/getEmployeesNewsfeeds')
      this.isLoadingEmployeesNewsfeeds = false
    }
  }
}

</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
@import "./src/styles/graph.scss";
@import "./src/styles/table.scss";

$padding: 20px;

.gender-header {
  display: grid;
  grid-template-columns: 80% auto;
  grid-gap: 2em;

  .gender-logo {
    opacity: 0.8;
    background: transparent url(~@/assets/index-egalite-femme-homme.png)
      no-repeat center center;
    background-size: contain;
  }
}

.gender-section {
  margin-top: $padding;
  border-top: 1px solid $graph-inner-border-color;
  padding-top: $padding;
  @include line-regular-height;
}

.gender-title {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 1em;

  h2 {
    margin: 0;
  }

  span {
    @include font-large-size;
    @include font-semibold;
    color: lighten($light-text-color, 7);
    text-transform: uppercase;
  }
}

.gender-female-color {
  color: $graph-purple-color;
}

.gender-graph {
  margin-top: 1em;
}

.gender-table {
  display: inline-block;
  border: 1px solid $graph-outer-border-color;
  border-radius: $border-radius;
  box-shadow: 1px 1.5px 0 rgba(0, 0, 0, 0.03);
  margin-bottom: 0.25em;
  margin-right: 1em;

  table {
    @include font-small-size;

    th,
    td {
      @include line-regular-height;
      @include font-tabular-numbers;
      padding: 0.5em;
      border-top: 1px solid $graph-outer-border-color;
    }

    .row.active {
      @include font-semibold;
      background: lighten($graph-purple-color, 50) !important;
      color: $graph-purple-color !important;
    }

    .tfoot th:first-of-type {
      text-align: right;
    }

    .tfoot th {
      background: white;
    }
  }
}

.gender-table-salary-raise-diff {
  th:not(:first-of-type),
  td:not(:first-of-type) {
    text-align: center;
  }
}

.gender-table-salary-diff {
  th:not(:first-of-type),
  td:not(:first-of-type) {
    text-align: right;
  }

  th:nth-of-type(2),
  td:nth-of-type(2),
  th:nth-of-type(4),
  td:nth-of-type(4),
  th:nth-of-type(6),
  td:nth-of-type(6),
  th:nth-of-type(8),
  td:nth-of-type(8) {
    text-align: center;
  }
}

.gender-table-top-salaries {
  td:first-of-type,
  td:last-of-type {
    text-align: right;
  }

  .tfoot th {
    text-align: center;
  }
}

.gender-dot {
  &::before {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    content: " ";
    background: $graph-darkblue-color;
    margin-right: 0.6em;
  }

  &.female::before {
    background: $graph-purple-color;
  }
}

.gender-list {
  li {
    @include line-large-height;
  }
}

.gender-invalid {
  td:nth-last-of-type(2),
  td:nth-last-of-type(3) {
    opacity: 0.8;
  }
}
</style>
