<template>
  <textarea
    ref="textarea"
    :autofocus="autofocus"
    :rows="rows"
    @keydown.enter="handleEnter($event)"
    @keydown.delete="handleDelete($event)"
    @paste="handlePaste($event)"
    v-on="inputListeners"
    v-model="content"></textarea>
</template>

<script>
import autosize from 'autosize'
import { insertContentInPlace } from '@/utils/string'
import { parseSkillNamesFromMultilineString } from '@/utils/skills'

export default {
  props: {
    value: String,
    autofocus: Boolean,
    rows: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      content: this.value || ''
    }
  },
  computed: {
    inputListeners() {
      // https://fr.vuejs.org/v2/guide/components-custom-events.html#Relier-des-evenements-natifs-aux-composants
      return {
        ...this.$listeners,
        ...{
          input: (event) => {
            this.$emit('input', event.target.value)
          }
        }
      }
    },
    hasBatchListener() {
      return this.inputListeners.batch
    }
  },
  watch: {
    value: 'setContent'
  },
  mounted() {
    autosize(this.$el)
    if (this.autofocus) {
      this.$refs.textarea.focus()
    }
  },
  methods: {
    setContent(value) {
      if (this.content !== value) {
        this.content = value || ''
        this.$nextTick(() => {
          autosize.update(this.$refs.textarea)
        })
      }
    },
    handleEnter(event) {
      // Allow newline on shift + enter
      if (!event.shiftKey && !event.altKey) {
        this.$emit('enter', event)
      }
      // Submit form on cmd + enter
      if (event.metaKey || event.ctrlKey) {
        this.$emit('submit', event)
      }
    },
    handleDelete(event) {
      if (!this.content.length) {
        this.$emit('delete', event)
      }
    },
    handlePaste(event) {
      const clipboardData = event.clipboardData || window.clipboardData
      const clipboardText = clipboardData ? clipboardData.getData('text') : null
      const rows = parseSkillNamesFromMultilineString(clipboardText)

      if (this.hasBatchListener && rows.length) {
        let first = rows.shift()

        if (first !== clipboardText) {
          // Prevent default behavior
          event.preventDefault()

          // Update current input
          first = insertContentInPlace(this.value, first, event.target)
          this.setContent(first)
          this.$emit('input', first)

          // Send the rest as batch
          if (rows.length) {
            this.$emit('batch', rows)
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
textarea {
  min-height: 0;
  height: auto;
}
</style>
