var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.wagesLoaded)?_c('div',[(_vm.employees.length > 0)?_c('div',_vm._l((_vm.employees),function(employee){return _c('sandbox-wage-editor',{key:employee.id,staticClass:"sandbox-wage-editor",attrs:{"title":employee.firstName + ' ' + employee.lastName,"sandboxWage":_vm.$store.getters['sandbox/employeeWage'](employee.id),"referenceWage":employee.currentWage},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var containerExpanded = ref.containerExpanded;
return [((employee.arrivalDate || employee.currentWage) && containerExpanded)?_c('div',{staticClass:"show-people-info"},[_vm._v("?")]):_vm._e(),_c('div',{staticClass:"people-info"},[(employee.arrivalDate)?_c('p',[_vm._v(" 💼 "),_c('span',{directives:[{name:"t",rawName:"v-t",value:({
                path: 'employees.employee.detail.status',
                args: {
                  companyName: _vm.company.name,
                  arrivalDate: _vm.$options.filters.fullFormattedDate(employee.arrivalDate),
                  diff: _vm.$options.filters.fullDiffFromNow(employee.arrivalDate)
                }
              }),expression:"{\n                path: 'employees.employee.detail.status',\n                args: {\n                  companyName: company.name,\n                  arrivalDate: $options.filters.fullFormattedDate(employee.arrivalDate),\n                  diff: $options.filters.fullDiffFromNow(employee.arrivalDate)\n                }\n              }"}]})]):_vm._e(),(employee.currentWage)?_c('p',[_vm._v(" 📈 "),_c('span',{directives:[{name:"t",rawName:"v-t",value:({
                path: 'employees.employee.detail.wageEvolution',
                args: {
                  date: _vm.$options.filters.fullFormattedDate(employee.currentWage.startDate),
                  diff: _vm.$options.filters.fullDiffFromNow(employee.currentWage.startDate)
                }
              }),expression:"{\n                path: 'employees.employee.detail.wageEvolution',\n                args: {\n                  date: $options.filters.fullFormattedDate(employee.currentWage.startDate),\n                  diff: $options.filters.fullDiffFromNow(employee.currentWage.startDate)\n                }\n              }"}]})]):_vm._e()])]}}],null,true)})}),1):_vm._e()]):_c('loading-view')}
var staticRenderFns = []

export { render, staticRenderFns }