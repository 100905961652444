<template>
  <div class="container" :class="containerHovered ? 'container-hover' : null">
    <div
      class="top"
      @mouseenter.stop="containerHovered = true"
      @mouseleave.stop="containerHovered = false"
      @click="containerExpanded = !containerExpanded">
      <div class="detail">
        <div class="title">
          <h2>
            <span class="wage-status" v-if="isLoading">⏳</span>
            <span class="wage-status" v-else-if="isSandboxWageValid">✅</span>
            <span class="wage-status" v-else>⚠️</span>
            <span>{{ title }}</span>
          </h2>
          <slot :containerExpanded="containerExpanded"></slot>
        </div>
        <div class="show-status">
          <span v-if="containerExpanded">▲</span>
          <span v-else>▼</span>
        </div>
      </div>
    </div>
    <div class="wage-detail" v-if="sandboxComponents.length > 0 && containerExpanded">
      <div
        v-if="isSandboxWageEmpty"
        class="alert"
        v-t="'sandbox.missingWage'">
      </div>
      <div
        v-else-if="!isSandboxWageValid"
        class="alert warning"
        v-t="'sandbox.invalidWage'">
      </div>
      <wage-calculator
        v-model="sandboxWageModel"
        :grid="sandboxGrid"
        :referenceWage="referenceWage"
        :referenceGrid="currentGrid"
        :showDelta="true"
        :setDefaultValue="false"
        @input="wageChanged">
      </wage-calculator>
    </div>
    <div v-else-if="!sandboxComponents.length" class="wage-detail">
      <div class="alert warning" v-t="'sandbox.incompleteComponents'"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as debounce from 'lodash.debounce'
import { flattenedComponents, isSandboxWageValid, isSandboxWageEmpty } from '@/utils/grid'

import WageCalculator from '@components/wage/WageCalculator.vue'

export default {
  props: ['sandboxWage', 'referenceWage', 'title'],
  data() {
    return {
      sandboxWageModel: {},
      containerExpanded: true,
      containerHovered: false,
      isLoading: false,
      isSandboxWageEmpty: false,
      isSandboxWageValid: false
    }
  },
  computed: {
    ...mapGetters({
      currentGrid: 'currentGrid/getCurrentGrid',
      sandboxGrid: 'sandbox/grid'
    }),
    sandboxComponents() {
      return flattenedComponents(this.sandboxGrid.components)
    }
  },
  methods: {
    wageChanged: debounce(function() {
      this.isSandboxWageEmpty = isSandboxWageEmpty(this.sandboxWageModel)
      this.isSandboxWageValid = isSandboxWageValid(this.sandboxWageModel, this.sandboxGrid)
      this.isLoading = true
      this.$store.dispatch('sandbox/updateSandboxWage', this.sandboxWageModel)
        .then(r => {
          this.isLoading = false
        })
        .catch(e => {
          this.isLoading = false
        })
    }, 1000)
  },
  created() {
    this.sandboxWageModel = Object.assign({}, this.sandboxWage)
    this.isSandboxWageEmpty = isSandboxWageEmpty(this.sandboxWage)
    this.isSandboxWageValid = isSandboxWageValid(this.sandboxWage, this.sandboxGrid)
  },
  mounted() {
    this.containerExpanded = !this.isSandboxWageValid
  },
  components: {
    WageCalculator
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/alert.scss";
@import "./src/styles/form.scss";

.container {
  border: $border;
  border-radius: $border-radius;
}

.container-hover {
  border-color: $clearteal-color;
}

.top {
  cursor: pointer;
  padding: 15px 20px;
  position: relative;

  p {
    margin: 0;
  }

  &:hover > .detail > .show-status {
    color: $clearteal-color;
  }
}

h2 {
  margin: 0;
  color: $clearteal-color;
}

strong {
  display: inline-block;
  @include font-semibold;
  margin: 0 0 0.5em 0;
}

.show-status {
  color: $border-color;
  @include font-big-size;
  cursor: pointer;
  margin-right: 10px;
  user-select: none;
}

.wage-status {
  display: inline-block;
  margin-right: 5px;
  @include font-smaller-size;
}

.title {
  display: flex;
  align-items: center;
}

.detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wage-detail {
  padding: 5px 20px;
}

.level-selector {
  margin-bottom: 20px;

  select {
    width: 100%;
    border: 0;
    background: $lightteal-color;
    color: $clearteal-color;
    @include font-semibold;
  }

  .warning {
    color: $orange-color;
    background: $lightorange-color;

    &:focus {
      box-shadow: inset 0px 0px 0px 2px $orange-color;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.summary-detail {
  padding: 20px;
  border: $border;
  border-radius: $border-radius;
}
</style>
