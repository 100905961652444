<template>
  <div class="resizable-container" :class="{animated: isAnimated}" :style="`height: ${height}`">
    <div class="resizable-content" ref="slot">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ['observer'],
  data() {
    return {
      isAnimated: false,
      height: 'auto'
    }
  },
  methods: {
    init() {
      setTimeout(() => {
        this.isAnimated = true
      }, 500)
    },
    updateHeight() {
      this.$nextTick(() => {
        if (this.$refs.slot) {
          this.height = this.$refs.slot.offsetHeight + 'px'
        }
      })
    }
  },
  watch: {
    observer: 'updateHeight'
  },
  mounted() {
    this.init()
    this.updateHeight()
    this.$on('resize', this.updateHeight)
  }
}
</script>

<style lang="scss" scoped>
.resizable-container {
  overflow: hidden;

  &.animated {
    transition-property: height;
    transition-duration: 0.3s;
  }
}

.resizable-content {
  overflow: hidden;
}
</style>
