<template>
  <div
    v-if="tooltip && employee"
    class="graph-tooltip-container"
    :class="tooltip.position">
    <div class="graph-tooltip">
      <div>
        <name-thumbnail :employee="employee" inline=true />
      </div>
      <div>
        <strong>{{employee.fullName}}</strong><br>
        <small v-if="employee.hiringPlan">
          {{$t('hiringPlans.title')}}
          ·
          {{employee.hiringPlan.name}}
        </small>
        <small v-else-if="employee.wagePlan">
          {{$t('wagePlans.raise')}}
          ·
          {{qualification}}
        </small>
        <small v-else>{{qualification}}</small>
      </div>
      <div>
        <span class="badge border-badge" v-if="!tooltip.isDelta">{{employee.salary | formattedCurrency}}</span>
        <span class="badge border-badge" v-else>{{employee.salaryDelta | formattedCurrency(false, {signDisplay: "always"})}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import NameThumbnail from '@components/commons/NameThumbnail.vue'

export default {
  props: {
    employees: Array,
    tooltip: {
      type: Object
    }
  },
  computed: {
    employee() {
      return this.employees.find(e => e.id === this.tooltip.id)
    },
    qualification() {
      if (this.employee.wage && this.employee.wage.qualification && this.employee.wage.qualification.length) {
        return this.employee.wage.qualification.map(q => q.name).join(' · ')
      }
      else {
        return this.employee.jobType
      }
    }
  },
  components: {
    NameThumbnail
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/badge.scss";

.graph-tooltip-container {
  pointer-events: none;
  position: absolute;
  width: 100%;
}

.graph-tooltip {
  @extend .badge-block;
  opacity: 0.97;
  text-align: left;
  padding: 5px;
  column-gap: 5px;
  line-height: 1.2em;
  background-color: $graph-lightblue-color;
  border-bottom: 1px solid $graph-blue-color;
  border-radius: $border-radius;
  margin-top: 5px;
  display: inline-grid;
  grid-template-columns: auto auto auto;
  align-items: center;

  small {
    @include font-smaller-size;
  }

  .badge {
    margin-left: 0.5em;
  }
}
</style>
