import debounce from 'lodash.debounce'

// Use this helper method when defining actions to add debounce behavior.
// - fn: The original action method (will be debounced)
// - immediateFn: The optional action method to be called immediatly
// - key: Use the key to debounce actions dpending on a specific value in the context object (ex: an id)
// - delay: The delay between 2 debounced calls
const debouncedActionCache = {}
export function debouncedAction(fn, immediateFn, key, delay) {
  return (context, payload) => {
    return new Promise((resolve, reject) => {
      const keyValue = payload[key] + '-' + fn
      let action = debouncedActionCache[keyValue]
      const wrappedFn = function() {
        return fn.apply(null, arguments)
          .then(result => resolve(result))
          .catch(error => reject(error))
      }
      if (!action) {
        action = debounce(wrappedFn, delay || 1000)
        debouncedActionCache[keyValue] = action
      }
      action(context, payload)
      if (immediateFn) {
        immediateFn(context, payload)
      }
    })
  }
}

// Use this method in actions to dispatch several actions sequentially
// The iterator will wait for the previous promise to succeed before starting the next one.
// If one promise fails, will catch error and return it.
// - context: The context from the store
// - actions: Array of [[action, payload], …]
// - memo: Optional, used internally for the recursive logic
// Returns a promise which resolves with the array of promises' responses
export function sequentialDispatch(context, actions) {
  return actions.reduce((memo, [action, payload]) => {
    return memo.then(() => context.dispatch(action, payload)
      .catch(error => context.dispatch('handleAPIError', error, { root: true })))
  }, Promise.resolve())
}
