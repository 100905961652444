<template>
  <div class="visitor-container signin" v-if="userDetails || errorMessage">
    <div class="center">
      <img
        class="visitor-logo"
        src="~@/assets/logo.svg"
        alt="Clearwage"
        @click="$router.push('/')">
    </div>
    <div class="visitor-form">
      <header
        class="visitor-form-header"
        v-html="headerLabel" />
      <div class="visitor-form-content">
        <form @submit.prevent="signUp()" v-if="userDetails">
          <template v-if="isFormVisible">
            <div class="visitor-intro" v-t="completeRegistrationLocale"></div>
            <div class="input-label" v-t="'account.password'"></div>
            <input
              type="password"
              v-model="password"
              :placeholder="$t('account.passwordPlaceholder')"
              :class="{'input-error' : $v.password.$error}">
            <div class="terms">
              <Checkbox
                v-model="termsChecked">
                <span v-html="$t('account.terms', {url: $t('urls.terms')})" :class="{'terms-error' : $v.termsChecked.$error}" />
              </Checkbox>
            </div>
          </template>
          <div v-if="errorMessage">
            <div class="error-message">{{ errorMessage }}</div>
          </div>
          <button class="primary" :disabled="isLoading">{{ requestButtonText }}</button>
        </form>
        <div v-else-if="errorMessage">
          <div class="error-message no-margin-top">{{ errorMessage }}</div>
          <br>
          <button
            type="button"
            class="primary"
            v-t="'common.continue'"
            @click="$router.push('/')"></button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import Checkbox from '@components/commons/Checkbox'

export default {
  components: {
    Checkbox
  },
  data() {
    return {
      password: '',
      isLoading: false,
      errorMessage: null,
      userDetails: null,
      termsChecked: false
    }
  },
  created() {
    this.getUserDetails()
  },
  methods: {
    async getUserDetails() {
      const token = this.token

      try {
        const userDetails = await this.$store.dispatch('account/getInvitation', { token })

        if (!userDetails.error) {
          this.userDetails = userDetails

          // Set locale based on company's locale
          if (this.userDetails.companyLocale) {
            this.$i18n.locale = this.userDetails.companyLocale
          }

          if (!this.isFormVisible) {
            this.signIn()
          }
        }
        else {
          this.$router.push(this.redirectPath)
        }
      }
      catch (error) {
        this.errorMessage = error
      }
    },
    async signIn() {
      this.isLoading = true
      if (!this.$$hasSession || this.$route.query.force) {
        const invitationToken = this.token
        await this.$store.dispatch('account/signIn', { invitationToken })
      }
      this.$router.push(this.redirectPath)
    },
    async signUp() {
      this.$v.$touch()

      if (!this.$v.$error) {
        const token = this.token
        const password = this.password

        this.errorMessage = null
        this.isLoading = true

        try {
          await this.$store.dispatch('account/acceptInvitation', { token, password })
          this.$router.push(this.redirectPath)
        }
        catch (error) {
          this.isLoading = false
          this.errorMessage = error
        }
      }
    }
  },
  computed: {
    token() {
      const token = this.$route.params.token
      const tokenParts = token.split('/')
      return tokenParts[0]
    },
    redirectPath() {
      return this.$route.params.token.replace(this.token, '') || '/'
    },
    isFormVisible() {
      return this.userDetails && !this.userDetails.hasAccount
    },
    requestButtonText() {
      return this.$t(this.isLoading ? 'account.pleaseWait' : 'account.signin.action')
    },
    headerLabel() {
      if (this.errorMessage && !this.userDetails) {
        return this.$t('account.invitation.invalidLink')
      }
      else if (this.userDetails) {
        return this.$t('account.invitation.hello', {
          userName: this.userDetails.firstName
        })
      }
    },
    completeRegistrationLocale() {
      return {
        path: 'account.invitation.completeRegistration',
        args: {
          companyName: this.userDetails.companyName
        }
      }
    }
  },
  validations: {
    password: {
      required
    },
    termsChecked: {
      required: value => value === true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/visitor.scss";

.terms {
  text-align: left;
  @include font-small-size;
  margin-bottom: 20px;
}

.terms-error {
  color: $red-color;
}
</style>
