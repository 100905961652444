<template>
  <div class="graph-score" :class="{invalid: !valid}" :title="title">
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="30" viewBox="0 0 60 30">
      <g fill="none" fill-rule="evenodd">
        <rect width="14" height="14" x="42" y="3" fill="#A8BE78" rx="2" />
        <rect width="14" height="14" x="23" y="3" fill="#F4AD72" rx="2" />
        <rect width="14" height="14" x="4" y="3" fill="#ED8B77" rx="2" />
        <path :transform="transform" :fill="fill" d="M11.7936051,20.3819659 L15.8090291,25.6199147 C16.1450388,26.058225 16.0621078,26.6859354 15.6237975,27.0219451 C15.4492161,27.1557796 15.2353761,27.2283141 15.0153981,27.2283141 L6.98455018,27.2283141 C6.43226543,27.2283141 5.98455018,26.7805989 5.98455018,26.2283141 C5.98455018,26.0083361 6.0570847,25.7944961 6.19091918,25.6199147 L10.2063431,20.3819659 C10.5423528,19.9436556 11.1700632,19.8607246 11.6083735,20.1967343 C11.6779724,20.250089 11.7402505,20.3123671 11.7936051,20.3819659 Z" />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    valid: {
      type: Boolean,
      default: true
    },
    score: {
      type: Number
    }
  },
  computed: {
    transform() {
      const prettyScore = this.score
      const x = prettyScore * 38
      return `translate(${x}  0)`
    },
    fill() {
      return this.valid ? '#2E779E' : '#AEB8C2'
    },
    title() {
      return this.$options.filters.formattedNumber(this.score * 100, 1, 1) + ' %'
    }
  }
}
</script>

<style lang="scss" scoped>
.graph-score {
  background: white;
  border-radius: $border-radius;
  display: inline-block;
  width: 60px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin: -2px 0;

  &.invalid {
    opacity: 0.8;
  }
}

path {
  transition-property: transform;
  transition-duration: 600ms;
}
</style>
