/* global Beacon */

import { trackEvent } from '@/services/analytics'
import i18n from '@/i18n'
import router from '@/router'

const beaconIds = {
  fr: process.env.VUE_APP_HELPSCOUT_FR_BEACON_ID,
  en: process.env.VUE_APP_HELPSCOUT_EN_BEACON_ID
}

let lastBeaconId = null

function currentBeaconId() {
  return beaconIds[i18n.locale]
}

const suggestedArticles = {
  fr: {
    signin: [
      '5c5d64b32c7d3a66e32e2f21', // À propos de Clearwage
      '5c5303072c7d3a66e32ddbb0', // Inscription
      '5c530827042863543ccc6fbc' // Mot de passe oublié
    ],
    dashboard: [
      '5c5d4f732c7d3a66e32e2df3' // Utilisation du dashboard
    ],
    team: [
      '5c641fa5042863543cccee56', // Ajouter un employé
      '5c52f6d8042863543ccc6ebf', // Importer mes employés
      '5c6430cf042863543cccef33', // Inviter mon équipe
      '5c6531f42c7d3a66e32e6f34' // Supprimer un employé
    ],
    onboardingIntro: [
      '5c653cf72c7d3a66e32e6fd0', // Calcul du salaire
      '5c5d841b2c7d3a66e32e30a2' // Obligation de consultation des IRP
    ],
    onboardingSkills: [
      '5c5d62032c7d3a66e32e2eee', // Définir les compétences
      '5c5daef12c7d3a66e32e3349' // Modifier les compétences
    ],
    onboardingQualification: [
      '5c5db3672c7d3a66e32e33ad', // Qualifications des employés
      '5c5daef12c7d3a66e32e3349' // Modifier les compétences
    ],
    onboardingGenerate: [
      '5c61883b042863543cccd37f', // Génération de la grille
      '5c614f8b2c7d3a66e32e4328', // Édition de la grille
      '5c653cf72c7d3a66e32e6fd0', // Calcul du salaire
      '5c6193a8042863543cccd473', // Définir une rémunération
      '5c656e67042863543cccfdd1' // Définir un bon salaire de base
    ]
  },
  en: {
    signin: [
      '5d15c8c504286305cb87d547', // À propos de Clearwage
      '5d19b66104286305cb87e8e2', // Inscription
      '5d19b7102c7d3a6ebd22c27d' // Mot de passe oublié
    ],
    dashboard: [
      '5d15d96004286305cb87d68d' // Utilisation du dashboard
    ],
    team: [
      '5d15d53204286305cb87d63a', // Ajouter un employé
      '5d15cc7804286305cb87d593', // Importer mes employés
      '5d15d08b04286305cb87d5e1', // Inviter mon équipe
      '5d15d76204286305cb87d66a' // Supprimer un employé
    ],
    onboardingIntro: [
      '5d15d2852c7d3a6ebd22af43' // Calcul du salaire
    ],
    onboardingSkills: [
      '5d15dbca2c7d3a6ebd22aff0', // Définir les compétences
      '5d19b3402c7d3a6ebd22c249' // Modifier les compétences
    ],
    onboardingQualification: [
      '5d15e16f2c7d3a6ebd22b03d', // Qualifications des employés
      '5d19b3402c7d3a6ebd22c249' // Modifier les compétences
    ],
    onboardingGenerate: [
      '5d19aea82c7d3a6ebd22c230', // Génération de la grille
      '5d15df5a2c7d3a6ebd22b01c', // Édition de la grille
      '5d15de9504286305cb87d6d9', // Calcul du salaire
      '5d15d2852c7d3a6ebd22af43', // Définir une rémunération
      '5d15db2b2c7d3a6ebd22afe2' // Définir un bon salaire de base
    ]
  }
}

// Prepare Beacon but wait for loadScript() to actually load it
if (currentBeaconId()) {
  /* eslint-disable no-sequences, no-unused-expressions */
  !(function(e, t, n) {
    function a() {
      const e = t.getElementsByTagName('script')[0]
      const n = t.createElement('script')
      n.type = 'text/javascript',
      n.async = true,
      n.src = 'https://beacon-v2.helpscout.net',
      e.parentNode.insertBefore(n, e)
    }
    if (e.Beacon = n = function(t, n, a) {
      e.Beacon.readyQueue.push({ method: t, options: n, data: a })
    }, n.readyQueue = [], t.readyState === 'complete') {
      return a()
    }
    // Load after a delay
    e.Beacon.loadScript = () => setTimeout(a, 700)
  }(window, document, window.Beacon || function() { }))
  /* eslint-enable no-sequences, no-unused-expressions */
}

function initBeacon() {
  if (currentBeaconId()) {
    lastBeaconId = currentBeaconId()

    Beacon('init', currentBeaconId())
    // Beacon settings and translations → https://secure.helpscout.net/settings/beacons

    Beacon('on', 'open', () => trackEvent('OpenBeacon'))
    Beacon('on', 'close', () => trackEvent('CloseBeacon'))
  }
}

const VIEWPORT_OVERLAP_WIDTH = 1180

function adjustBeaconPosition() {
  if (currentBeaconId()) {
    if (window.innerWidth < VIEWPORT_OVERLAP_WIDTH) {
      Beacon('config', { display: { position: 'left' } })
    }
    else {
      Beacon('config', { display: { position: 'right' } })
    }
  }
}

export function loadBeacon() {
  if (currentBeaconId() && Beacon.loadScript) {
    Beacon.loadScript()
    delete Beacon.loadScript
  }
}

export function setupBeacon(helpScoutIdentity = null) {
  // Refresh contextual help after locale change
  if (currentBeaconId() && lastBeaconId !== currentBeaconId()) {
    Beacon('destroy')
    initBeacon()

    if (router.currentRoute.name) {
      refreshContextualHelp(router.currentRoute.name)
    }
  }

  // Identity customer in helpscout
  if (helpScoutIdentity) {
    setHelpscoutIdentity(helpScoutIdentity)
  }
}

export function callBeacon(action, params) {
  if (currentBeaconId()) {
    Beacon(action, params)
  }
}

export function setHelpscoutIdentity({ firstName, lastName, email, userId, companyName, signature }) {
  if (currentBeaconId()) {
    Beacon('identify', {
      name: `${firstName} ${lastName}`,
      email,
      userId,
      companyName,
      signature
    })
  }
}

export function resetHelpscout() {
  if (currentBeaconId()) {
    Beacon('logout')
  }
}

export function hideBeacon() {
  const beacon = document.getElementById('beacon-container')
  if (beacon) {
    beacon.style.display = 'none'
  }
}

export function showBeacon() {
  const beacon = document.getElementById('beacon-container')
  if (beacon) {
    beacon.style.display = 'block'
  }
}

export function refreshContextualHelp(routeName) {
  if (currentBeaconId()) {
    if (suggestedArticles[i18n.locale][routeName]) {
      Beacon('suggest', suggestedArticles[i18n.locale][routeName])
    }
    else {
      Beacon('suggest')
    }

    adjustBeaconPosition()
  }
}
