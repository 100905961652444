import hashSum from 'hash-sum'
const CHECK_DURATION = 60 * 1000 // Check version every minute
const FORCE_RELOAD_DURATION = 24 * 60 * 60 * 1000 // Force reload every day
let latestVersionCheck = +new Date()
let latestVersionHash

export default {
  data() {
    return {
      isVersionLoaded: true
    }
  },
  methods: {
    reloadApp() {
      location.reload()
    },
    async reloadIfObsoleteVersion() {
      try {
        const now = +new Date()
        const shouldCheckVersion = ((now - latestVersionCheck) > CHECK_DURATION)
        const shouldForceReload = ((now - latestVersionCheck) > FORCE_RELOAD_DURATION)
        if (shouldForceReload) {
          console.log('Version check is too old, reloading…')
          this.isVersionLoaded = false
          this.reloadApp()
        }
        else if (shouldCheckVersion) {
          const response = await fetch('/')
          const hash = hashSum(await response.text())
          if (latestVersionHash && hash !== latestVersionHash) {
            console.log('Version check is obsolete, reloading…')
            this.isVersionLoaded = false
            this.reloadApp()
          }
          else {
            console.log('Version check is OK')
          }
          latestVersionHash = hash
          latestVersionCheck = now
        }
      }
      catch (_) {}
    },
    onVisibilityChange() {
      if (document.visibilityState === 'visible') {
        this.reloadIfObsoleteVersion()
      }
    }
  },
  mounted() {
    document.addEventListener('visibilitychange', this.onVisibilityChange)
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.onVisibilityChange)
  }
}
