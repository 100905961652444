<template>
  <div ref="container" @click="closeModalIfMask">
    <div
      class="mask"
      v-if="modalComponent"
      :style="`width: ${viewportWidth}px; height: ${viewportHeight}px`"
      ref="mask">
      <div class="modal" ref="modal">
        <div class="modal-close-button" @click="closeModal">×</div>
        <component
          :is="modalComponent"
          :is-back-available="!!previousModalComponent"
          :payload="modalPayload"
          @simulation="onSimulation"
          @resize="resizeViewport"
          @close="closeModal"
          @select-levels="selectLevels"
          @select-employee="selectEmployee"
          @back="backModal" />
      </div>
    </div>
    <div class="sidebar-container nowrap">
      <aside ref="sidebar">
        <ul class="sticky">
          <li class="header">
            <h3 v-t="'grid.editor.payroll'"></h3>
          </li>
          <li class="payroll-summary">
            <grid-payroll-summary
              :simulation-levels="simulationLevels"
              :simulation-wages="simulationWages"></grid-payroll-summary>
          </li>
          <li class="header">
            <h3 v-t="'grid.editor.formula'"></h3>
          </li>
          <li class="grid-formula">
            <grid-formula
              :grid="grid"
              @select="selectComponent"></grid-formula>
          </li>
        </ul>
      </aside>
      <main :style="`min-height: ${viewportHeight}px`">
        <div ref="viewport">
          <h3 v-t="'grid.editor.componentsTitle'"></h3>
          <scrollable-panel :width="viewportWidth">
            <grid-component
              v-for="component in grid.components"
              :key="component.id"
              :component="component"
              @select="selectLevels" />
          </scrollable-panel>
          <div class="team">
            <div class="team-header">
              <div>
                <h3 v-t="'grid.editor.employeesTitle'"></h3>
                <search-input
                  v-model="searchQuery"
                  :searchCount="searchQueryCount" />
              </div>
              <div>
                <div class="button-group">
                  <button class="secondary" @click="selectWages" v-t="'common.actions'"></button>
                </div>
              </div>
            </div>
            <grid-employees
              :searchQuery="searchQuery"
              @count="count => searchQueryCount = count"
              @select="selectEmployee" />
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import { mapGetters } from 'vuex'
import animateScrollTo from 'animated-scroll-to'
import SearchInput from '@components/commons/SearchInput'
import ScrollablePanel from '@components/commons/ScrollablePanel'
import GridComponent from '@components/grid/valuation/GridComponent'
import GridComponentEditor from '@components/grid/editor/GridComponentEditor'
import GridEmployeeEditor from '@components/grid/valuation/GridEmployeeEditor'
import GridEmployees from '@components/grid/editor/GridEmployees'
import GridFormula from '@components/grid/viewer/GridFormula'
import GridLevelEditor from '@components/grid/editor/GridLevelEditor'
import GridPayrollSummary from '@components/grid/valuation/GridPayrollSummary'
import GridWagesEditor from '@components/grid/valuation/GridWagesEditor'

export default {
  components: {
    GridComponent,
    GridComponentEditor,
    GridEmployeeEditor,
    GridEmployees,
    GridFormula,
    GridLevelEditor,
    GridPayrollSummary,
    GridWagesEditor,
    SearchInput,
    ScrollablePanel
  },
  data() {
    return {
      viewportWidth: 0,
      viewportHeight: 0,
      modalComponent: null,
      modalPayload: null,
      previousModalComponent: null,
      previousModalPayload: null,
      previousModalScroll: 0,
      previousScroll: 0,
      hasInteractedWithModal: false,
      searchQuery: null,
      searchQueryCount: null,
      simulationLevels: [],
      simulationWages: []
    }
  },
  methods: {
    openModal(modal, payload, scroll) {
      const shouldScroll = scroll || this.$refs.container.getBoundingClientRect().top < 0
      if (shouldScroll) {
        animateScrollTo(scroll || this.$refs.container, { offset: -12, minDuration: 0, maxDuration: 0 })
      }
      if (this.modalComponent) {
        this.previousModalComponent = this.modalComponent
        this.previousModalPayload = this.modalPayload
        this.previousModalScroll = window.scrollY
      }
      else {
        this.previousScroll = window.scrollY
      }
      this.modalComponent = modal
      this.modalPayload = this.hydratePayload(payload)
      this.hasInteractedWithModal = false
      this.resizeViewport()
    },
    backModal() {
      if (this.previousModalComponent) {
        this.openModal(this.previousModalComponent, this.previousModalPayload, this.previousModalScroll)
        this.previousModalComponent = null
        this.previousModalPayload = null
      }
    },
    closeModal() {
      this.onSimulation([], [])
      this.modalComponent = null
      this.modalPayload = null
      this.previousModalComponent = null
      this.previousModalPayload = null
      this.resizeViewport()
      if (this.previousScroll) {
        animateScrollTo(this.previousScroll, { offset: -12, minDuration: 0, maxDuration: 0 })
        this.previousScroll = 0
      }
    },
    closeModalIfMask(event) {
      if (this.modalComponent && [this.$refs.mask, this.$refs.sidebar].includes(event.target)) {
        if (this.hasInteractedWithModal) {
          if (window.confirm(this.$t('grid.onboarding.generate.closeWarning'))) {
            this.closeModal()
          }
        }
        else {
          this.closeModal()
        }
      }
    },
    initViewport() {
      this.viewportWidth = this.$refs.viewport.getBoundingClientRect().width
      this.viewportHeight = this.$refs.viewport.getBoundingClientRect().height
    },
    resizeViewport: debounce(function(event) {
      if (this.$refs.viewport) {
        const { viewport, modal } = this.$refs

        // Resize width
        if (event) {
          this.viewportWidth = 0
          this.$nextTick(() => {
            this.viewportWidth = viewport.getBoundingClientRect().width
          })
        }
        else {
          this.viewportWidth = viewport.getBoundingClientRect().width
        }

        // Resize height
        if (modal) {
          const newViewportHeight = Math.max(modal.getBoundingClientRect().height + 50, viewport.getBoundingClientRect().height)
          if (newViewportHeight > this.viewportHeight) {
            this.viewportHeight = newViewportHeight
          }
        }
        else {
          this.viewportHeight = viewport.getBoundingClientRect().height
        }
      }
    }, 100),
    onSimulation: debounce(function(simulationLevels, simulationWages) {
      this.hasInteractedWithModal = true
      if (this.simulationLevels.length || simulationLevels.length || this.simulationWages.length || simulationWages.length) {
        this.simulationLevels = simulationLevels
        this.simulationWages = simulationWages
      }
    }, 1300),
    selectComponent(component) {
      this.openModal('gridComponentEditor', { component })
    },
    selectLevels(component, levels) {
      this.openModal('gridLevelEditor', { component, levels })
    },
    selectEmployee(employee) {
      this.openModal('gridEmployeeEditor', { employee })
    },
    selectWages() {
      this.openModal('gridWagesEditor', {})
    },
    hydratePayload(payload) {
      // Ensure payload levels are up-to-date
      // TODO: Still necessary?
      if (payload.levels) {
        payload.levels = payload.levels.map(({ id }) => this.level(id))
      }
      return payload
    }
  },
  computed: {
    ...mapGetters({
      grid: 'sandbox/grid',
      level: 'sandbox/level'
    })
  },
  mounted() {
    window.addEventListener('resize', this.resizeViewport)
    this.initViewport()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeViewport)
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";
@import "./src/styles/sidebar.scss";

$sidebar-width: 200px;

.sidebar-container {
  grid-gap: 0;

  aside {
    width: $sidebar-width;
  }

  main {
    padding: 0;

    & > div {
      padding: 1.1em 1em 1.5em 1em;
    }
  }

  .payroll-summary,
  .grid-formula {
    padding: 0 0.45em;
    margin-bottom: 1.5em;
    line-height: inherit;
  }
}

.scrollable-panel {
  margin: 0 -1em;
}

.team {
  margin-top: 1.5em;

  .team-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5em;

    h3 {
      display: inline-block;
      margin: 0;
    }

    input[type="search"] {
      @include font-small-size;
      display: inline-block;
      margin-left: 1em;
      width: 200px;
    }
  }
}

.mask {
  position: absolute;
  margin-left: $sidebar-width;
  margin-top: 1px;
  background: rgba($graph-blue-color, 0.8);
  z-index: 2;
  border-radius: 0 3px 3px 0;
  text-align: center;
}

.modal {
  border: 1px solid $graph-outer-border-color;
  border-radius: $border-radius;
  background: white;
  width: 97%;
  margin: 1em auto;
  padding: 1em 1.25em 0.75em 1.25em;
  text-align: left;
  box-shadow: 1px 1.5px 0 rgba(0, 0, 0, 0.03);
}

.modal-close-button {
  position: absolute;
  @include font-big-size;
  @include font-bold;
  cursor: pointer;
  margin-left: 88%;
  width: 30px;
  line-height: 30px;
  text-align: center;

  &:hover {
    background: $graph-lightblue-color;
    border-radius: 15px;
  }

  &:active {
    background: darken($graph-lightblue-color, 5);
  }
}
</style>
