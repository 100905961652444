<template>
  <span class="operation-input" :class="operation">
    <input
      ref="input"
      type="number"
      :min="min"
      :max="max"
      :step="step"
      :disabled="disabled"
      :class="{small: isSmall}"
      v-model="valueModel"
      @input="onInput">
    <span class="symbol" v-if="operation == 'addition'">{{$i18n.currencySymbol}}</span>
    <span class="symbol" v-if="operation == 'percent'">%</span>
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: Number
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 1000000
    },
    step: {
      type: Number,
      step: 1
    },
    operation: {
      type: String,
      default: 'addition'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      valueModel: this.value || 0
    }
  },
  computed: {
    isSmall() {
      return this.max < 1000
    }
  },
  methods: {
    updateValue() {
      this.valueModel = this.value || 0
    },
    onInput() {
      this.$emit('input', +this.valueModel)
      this.$emit('change', +this.valueModel)
    },
    focus() {
      this.$refs.input.focus()
    }
  },
  watch: {
    value: 'updateValue'
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/form.scss";

input[type="number"] {
  display: inline-block;
  width: 115px;
  @include font-tabular-numbers;

  &:focus {
    border-color: lighten($graph-darkblue-color, 20);
  }

  &.small {
    width: 83px;
  }
}

.operation-input.addition,
.operation-input.multiplier,
.operation-input.percent {
  &::before {
    content: '';
    position: absolute;
    line-height: 34px;
    margin-left: 10px;
    @include font-big-size;
    @include font-tabular-numbers;
  }

  .symbol {
    position: relative;
    display: inline-block;
    line-height: 34px;
    width: 26px;
    margin-left: -26px;
    text-align: left;
    @include font-tabular-numbers;
  }
}

.operation-input.multiplier {
  input {
    padding-left: 25px;
  }
}

.operation-input.multiplier::before {
  content: '×'
}

.operation-input.addition,
.operation-input.percent {
  input {
    padding-left: 12px;
    padding-right: 30px;
  }
}

/* Green theme */

.green input:focus {
  border-color: $clearteal-color;
}

/* Error */
.input-error input {
  @extend .input-error;
}
</style>
