<template>
  <div>
    <div v-if="hiringPlans.length > 0">
      <div class="input-label" v-t="'candidates.jobOffer'"></div>
      <select
        v-model="selectedJobOffer"
        @change="selectChange">
        <optgroup
          v-for="hiringPlan in hiringPlans"
          :key="hiringPlan.id"
          :label="hiringPlan.name">
          <option
            v-for="jobOffer in hiringPlan.jobOffers"
            :key="jobOffer.id"
            :value="jobOffer">
            {{ jobOffer.name }}
          </option>
        </optgroup>
        <option v-if="allowCreation" :value="null" v-t="'candidates.newJobOffer'"></option>
      </select>
    </div>
    <div v-if="selectedJobOffer == null && allowCreation" class="job-offer-form">
      <div class="input-label" v-t="'candidates.jobOfferName'"></div>
      <input
        type="text"
        ref="jobOfferNameInput"
        v-model="jobOfferName"
        @input="inputChange"
        :placeholder="$t('candidates.jobOfferNamePlaceholder')">
      <div class="wage-reference"  v-if="currentGrid">
        <label>
          <input
            type="checkbox"
            :value="setReferenceWage"
            @change="setReferenceWageChange($event.target.checked)">
          <span v-t="'candidates.useWageProposalAsReference'"></span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  props: {
    value: Object,
    allowCreation: Boolean
  },
  data() {
    return {
      selectedJobOffer: null,
      jobOfferName: '',
      setReferenceWage: false
    }
  },
  computed: {
    hiringPlans() {
      const hiringPlans = this.$store.getters['candidates/getHiringPlans'].map(hiringPlan => {
        const jobOffers = this.$store.getters['candidates/getJobOffersByHiringPlan'](hiringPlan.id)
        return {
          id: hiringPlan.id,
          name: hiringPlan.name,
          jobOffers: jobOffers
        }
      }).filter(h => h.jobOffers.length)
      return hiringPlans
    },
    currentGrid() {
      return this.$store.getters['currentGrid/getCurrentGrid']
    }
  },
  methods: {
    updateValue() {
      if (this.hiringPlans.length > 0) {
        if (this.value) {
          this.selectedJobOffer = this.value
        }
        else {
          this.selectedJobOffer = this.hiringPlans[0].jobOffers[0]
        }

        this.$emit('input', this.selectedJobOffer)
      }
      else {
        this.selectedJobOffer = null
      }
    },
    selectChange() {
      if (this.selectedJobOffer == null) {
        this.$emit('input', null)

        // The input is not yet in dom because of v-if.
        // Wait for it.
        Vue.nextTick(() => {
          this.$refs.jobOfferNameInput.focus()
        })
      }
      else {
        this.jobOfferName = null
        this.setReferenceWageChange(false)
        this.$emit('input', this.selectedJobOffer)
      }
    },
    inputChange(event) {
      this.$emit('input', { name: this.jobOfferName })
    },
    setReferenceWageChange(value) {
      this.setReferenceWage = value
      this.$emit('set-reference-wage', this.setReferenceWage)
    }
  },
  created() {
    this.updateValue()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/form.scss";

.job-offer-form {
  margin-top: 20px;
}

.wage-reference {
  margin-top: 20px;
}

.input-error {
  .job-offer-form {
    input {
      border: 1px $red-color solid;
    }
  }
}
</style>
