import store from '@/store'
import router from '@/router'
import { normalizeDate } from '@/utils/date'
import * as camelCase from 'lodash.camelcase'
import * as flattenDeep from 'lodash.flattendeep'
import uuidv4 from 'uuid/v4'
import i18n from '@/i18n'
import { toggleItem } from '@/utils/storage'

function rand(array) {
  return array[Math.floor(Math.random() * array.length)]
}

function randInt(min, max) {
  return min + Math.floor(Math.random() * (max - min))
}

function randDate(start, end) {
  const randomDate = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()))
  return normalizeDate(randomDate)
}

function buildGender() {
  return rand(['Homme', 'Femme'])
}

function buildFirstName(gender) {
  switch (gender) {
    case 'Homme': return rand([
      'Arnaud',
      'Cédric',
      'Charles',
      'David',
      'Enzo',
      'Jean-Daniel',
      'Lucas',
      'Michel',
      'Pierre-Alban',
      'Philibert',
      'Quentin',
      'Thomas',
      'Valentin'
    ])
    case 'Femme':
    default:
      return rand([
        'Anna',
        'Charlotte',
        'Elisabeth',
        'Émilie',
        'Gaëlle',
        'Léa',
        'Lou',
        'Lucie',
        'Marie-France',
        'Natacha',
        'Ophélie'
      ])
  }
}

function buildLastName() {
  return rand([
    'Bernard',
    'de Saint-Steban',
    'Dubois',
    'Durand',
    'Laurent',
    'Lefebvre',
    'Leroy',
    'Martin',
    'Michel',
    'Moreau',
    'Petit',
    'Raud',
    'Richard',
    'Robert',
    'Thomas'
  ])
}

function buildInitials(firstName, lastName) {
  return Math.random() > 0.9
    ? firstName[0] + lastName[0] + lastName[lastName.length - 1]
    : firstName[0] + lastName[0]
}

function buildEmail(firstName, lastName) {
  return (
    camelCase(firstName) +
    '.' +
    camelCase(lastName) +
    '@example.org'
  )
}

const jobsHierachy = {
  Tech: {
    CTO: [],
    Développeur: [
      'Développeur Full-Stack',
      'Développeur iOS',
      'Développeur Android',
      'Développeur Web',
      'Développeur Backend',
      'Intégrateur Web'
    ],
    'Data scientist': [
      'Data scientist',
      'Data analyst',
      'Business analyst'
    ]

  },
  Sales: {
    Sales: [
      'Business Developer',
      'Country manager',
      'Account manager',
      'Key Account manager',
      'Technico-commercial',
      'Sales Manager',
      'Head of Sales',
      'Directeur commercial'
    ]
  },
  Marketing: {
    CMO: [],
    Marketing: [
      'Responsable Affiliation',
      'Chef de projet marketing',
      'Rédacteur Web',
      'Traffic manager',
      'Growth hacker',
      'Responsable SEO'
    ],
    Communication: [
      'Responsable communication',
      'Attachée de presse',
      'Responsable RP',
      'Community manager',
      'Social media manager'
    ]
  },
  Produit: {
    CPO: [],
    Manager: [
      'Producer manager',
      'Product owner',
      'Producer',
      'Chef de projet'
    ],
    Designer: [
      'UI Designer',
      'Motion Designer',
      'Graphiste'
    ]
  },
  'Service client': {
    'Service client': [
      'Ingénieur service-client',
      'Responsable service-client',
      'CRM',
      'Agent d’accueil',
      'Community manager'
    ]
  },
  'Ressources Humaines': {
    RH: [
      'Responsable RH',
      'Assistant RH'
    ]
  },
  Légal: {
    Légal: [
      'Avocat',
      'Expert juridique',
      'Juriste d’entreprise'
    ]
  },
  Administration: {
    CEO: [],
    COO: [],
    Comptable: [
      'Expert-comptable',
      'Contrôleur de gestion'
    ]
  }
}

const jobs = flattenDeep(Object.keys(jobsHierachy).map(department => {
  return Object.keys(jobsHierachy[department]).map(jobType => {
    if (jobsHierachy[department][jobType].length) {
      return jobsHierachy[department][jobType].map(jobTitle => (
        { department, jobType, jobTitle }
      ))
    }
    else {
      return { department, jobType, jobTitle: jobType }
    }
  })
}))

function buildJob() {
  return rand(jobs)
}

function buildManagementLevel() {
  return rand([
    'Coéquipier',
    'Lead',
    'Manager',
    'Head',
    'VP'
  ])
}

function buildContractType() {
  return rand([
    'CDI',
    'CDI Temps-partiel',
    'CDD',
    'Alternance',
    'Stage'
  ])
}

function buildCity() {
  return rand([
    'Paris',
    'Lyon',
    'Bordeaux',
    'Lille',
    'Montpellier'
  ])
}

function buildCurrentWage() {
  return {
    hasLevels: false,
    levelIds: [],
    overridenSalaryValue: randInt(18000, 85000)
  }
}

function buildEmployee() {
  const gender = buildGender()
  const firstName = buildFirstName(gender)
  const lastName = buildLastName(gender)
  const managementLevel = buildManagementLevel()
  const { department, jobType, jobTitle } = buildJob()
  const birthdate = randDate(new Date(new Date().getFullYear() + -60, 0), new Date(new Date().getFullYear() - 18, 0))
  const arrivalDate = randDate(new Date(new Date().getFullYear() - 3, 0), new Date(new Date().getFullYear() + 2, 0))

  return {
    id: uuidv4(),
    firstName: firstName,
    lastName: lastName,
    gender: gender,
    birthdate: birthdate,
    email: buildEmail(firstName, lastName),
    arrivalDate: arrivalDate,
    department: department,
    jobType: jobType,
    jobTitle: jobTitle,
    managementLevel: managementLevel,
    contractType: buildContractType(),
    city: buildCity(),
    initials: buildInitials(firstName, lastName),
    currentWage: buildCurrentWage(),
    status: 'employee'
  }
}

function randomEmployee() {
  const employee = buildEmployee()
  console.table([employee])
  return employee
}

function randomEmployees(count) {
  const overriden = true
  const alumnis = []
  const externals = []
  const employees = []
  count = count || Math.floor(3 + Math.random() * 200)

  for (let i = 0; i < count; i++) {
    employees.push(buildEmployee())
  }
  console.info('Generating', count, 'employees', employees)
  store.commit('employees/setEmployees', { employees, alumnis, externals, overriden })
  return employees
}

async function resetCurrentWages() {
  await store.dispatch('employees/resetCurrentWages')
  alert('✅  Current wages have been reset to the last one in newsfeed.')
  location.reload()
}

function toggleChangelog() {
  localStorage.CW_PACKAGE_VERSION = '0' && location.reload()
}

function toggleOnboarding() {
  let promise
  if (store.getters['onboarding/isActive']) {
    promise = store.dispatch('onboarding/disable')
  }
  else {
    promise = store.dispatch('onboarding/init')
  }
  promise.then(() => {
    const name = store.getters['onboarding/isActive'] ? 'grid' : 'sandboxList'
    router.push({ name, props: { rand: Math.random() } })
  })
}

function saveOnboardingState() {
  store.dispatch('onboarding/saveState')
  alert('✅  The state of the onboarding has been saved to localStorage.')
}

function restoreOnboardingState() {
  store.dispatch('onboarding/restoreState')
  router.push('/grid')
}

function unlockOnboarding() {
  store.state.onboarding.progressIndex = (store.state.onboarding.progressIndex + 1) % 4
  store.state.onboarding.isIntro = store.state.onboarding.progressIndex === 0
}

function toggleIsSandboxDebug() {
  store.dispatch('sandbox/toggleIsSandboxDebug')
}

function toggleVariableGoals() {
  const enabled = toggleItem('CW_VARIABLE_GOALS_ENABLED')
  alert(`Variable Goals is ${enabled}`)
}

function uuid() {
  return uuidv4()
}

async function unpublishGrid() {
  if (!window.confirm('Are you sure you want to unpublish the current Grid ?')) {
    return
  }
  try {
    const grid = store.getters['sandbox/grid']
    if (grid) {
      await store.dispatch('sandboxList/remove', grid)
    }
    await store.dispatch('sandbox/delete')
    location.href = '/grid'
  }
  catch (error) {
    alert(`⚠️  There was an error during unpublish:\n${error}`)
  }
}

function setLocale(locale) {
  i18n.locale = locale
}

function go(route) {
  router.push(route)
}

window.cw = {
  go,
  randomEmployee,
  randomEmployees,
  resetCurrentWages,
  toggleChangelog,
  toggleIsSandboxDebug,
  toggleOnboarding,
  toggleVariableGoals,
  saveOnboardingState,
  restoreOnboardingState,
  unlockOnboarding,
  unpublishGrid,
  uuid,
  setLocale
}
