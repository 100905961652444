<template>
  <div>
    <div class="teams-menu-wrapper print-hidden">
      <div class="teams-menu">
        <div class="button-group">
          <PermissionButton permission="teamsDirectory" />
        </div>
        <div class="button-group">
          <button
            v-if="$$isAdmin"
            class="secondary"
            @click="$router.push({name: 'updateTeams'})"
            v-t="'common.update'" />
          <button
            class="secondary"
            v-t="'common.export'"
            @click="download" />
        </div>
      </div>
    </div>
    <div class="app-container">
      <div class="container root-container">
        <h1>{{$$companyName}}</h1>
        <div class="teams"></div>
        <div
          class="team"
          v-for="team in teams"
          :key="team.id">
          <h3 class="team-name print-no-break-after">{{team.team}}</h3>
          <div class="employees">
            <div
              class="employee print-no-break"
              v-for="employee in team.employees"
              :key="employee.id">
              <AvatarEditor :employee="employee" :isDirectory="true" />
              <div class="employee-detail">
                <strong class="employee-name">
                  <router-link
                    class="colored-link"
                    :to="$$hasOpenAccess ? {name: 'employee', params: {id: employee.id}} : {}">
                    {{ employee.fullName }}</router-link>
                </strong>
                <div class="employee-job-title">{{ employee.jobTitle }}</div>
                <div class="employee-email">
                  <a class="colored-link" target="_blank" :href="`mailto:${employee.email}`">{{ employee.email }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getField } from '@/utils/employee'
import { downloadPDF } from '@/utils/pdf'
import PermissionButton from '@components/settings/PermissionButton.vue'
import AvatarEditor from '@components/employees/AvatarEditor.vue'
import orderBy from 'lodash.orderby'
import groupBy from 'lodash.groupby'

export default {
  components: {
    AvatarEditor,
    PermissionButton
  },
  computed: {
    ...mapGetters({
      employees: 'orgChart/getEmployees'
    }),
    teams() {
      const enrichedEmployees = this.employees.map(e => {
        return {
          ...e,
          fullName: getField(e, 'fullName'),
          fullDepartment: getField(e, 'fullDepartment'),
          jobTitle: getField(e, 'jobTitle', { store: this.$store }) || getField(e, 'jobType', { store: this.$store })
        }
      })
      const groupedTeams = groupBy(enrichedEmployees, 'fullDepartment')
      const teams = Object.keys(groupedTeams).sort()
      return orderBy(teams.reduce((memo, team) => {
        team = !['undefined', 'null'].includes(team) ? team : this.$t('employees.group.noTeam')
        memo.push({
          id: team,
          team: team,
          employees: orderBy(groupedTeams[team], ['fullName'])
        })
        return memo
      }, []), [t => t.team])
    }

  },
  methods: {
    download() {
      downloadPDF()
    }
  }

}
</script>

<style lang="scss" scoped>
@import "./src/styles/teams.scss";

.team {
  margin-bottom: 1em;

  .team-name {
    line-height: 2em;
    margin-bottom: 0;
  }
}

.employee {
  display: inline-grid;
  width: 32%;
  margin-top: 10px;
  margin-right: 10px;
  grid-template-columns: auto 1fr;
  gap: 15px;
  align-items: top;
  border: 1px solid $graph-inner-border-color;
  border-radius: $border-radius * 2;
  padding: 5px;
  overflow: hidden;

  .employee-name {
    display: inline-block;
    margin-top: 0.25em;
    margin-bottom: 0.15em;
  }

  .employee-job-title {
    margin-bottom: 0.4em;
  }

  .employee-email {
    @include font-smaller-size;
  }
}
</style>
