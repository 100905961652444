<template>
  <div class="wage-adjusted-slider">
    <label v-t="'wageCalculator.wageAdjustedSlider.title'"></label>
    <svg :height="viewport.height" ref="svgNode"></svg>
  </div>
</template>

<script>
import * as d3 from 'd3'
import debounce from 'lodash.debounce'
import flatten from 'lodash.flatten'
import { getWageDetails } from '@/utils/grid'
import { formatLabel, truncateLabel } from '@/utils/graph'

function initGraph(svg, viewport) {
  const node = {}

  node.svg = d3.select(svg)
  node.viewport = node.svg.append('g')
    .attr('class', 'viewport')
  node.abscissa = node.viewport.append('g')
    .attr('class', 'axis abscissa')
  node.abscissaLine = node.abscissa.append('line')
    .attr('class', 'hTick')
  node.cursorContainer = node.viewport.append('g')
    .attr('class', 'cursor-container')
  node.cursor = node.cursorContainer.append('line')
    .attr('class', 'cursor')
  node.cursorLabel = node.cursorContainer.append('text')
    .attr('class', 'label name')
  node.x = d3.scaleLinear()

  return {
    node: node,
    viewport: viewport,
    data: [],
    isDragging: false,
    animationDuration: 0
  }
}

function generateSliderModel(graph, wage, grid) {
  // Generate data
  const wageDetails = getWageDetails(grid, wage, { includeComponents: true })
  const value = wageDetails.summary.salary.value
  const experienceComponent = wageDetails.components.find(c => c.ref === 'experience')
  graph.data = []
  graph.value = 0
  if (experienceComponent) {
    const simulationWage = { ...wage, overridenSalaryValue: null }
    // Compute data
    graph.data = experienceComponent.levels.map((level, i) => {
      const simulationLevels = [{ ...experienceComponent.selectedLevel, salaryValue: level.salaryValue }]
      const simulatedWageDetails = getWageDetails(grid, simulationWage, { simulationLevels })
      const simulatedValue = simulatedWageDetails.summary.salary.value
      return {
        x: i,
        y: simulatedValue,
        label: level.name
      }
    })
    // Compute value
    graph.data.forEach((d, i) => {
      const current = d.y
      if (i + 1 < graph.data.length) {
        const next = graph.data[i + 1].y
        if ((i === 0 || value >= current) && value < next) {
          graph.value = i + ((value - current) / (next - current))
        }
      }
      else if (value >= current) {
        graph.value = graph.data.length - 1
      }
    })
    graph.value = Math.min(Math.max(graph.value, 0), graph.data.length - 1)
  }
  return graph
}

function renderGraph(graph, onChange) {
  const viewport = graph.viewport
  const node = graph.node

  // Viewport
  viewport.width = graph.node.svg.node().getBoundingClientRect().width
  viewport.innerWidth = viewport.width - viewport.padding.left - viewport.padding.right
  viewport.innerHeight = viewport.height - viewport.padding.top - viewport.padding.bottom

  if (!graph.isDragging) {
    const xDomainPadding = 1.4
    const domainValue = graph.value < graph.data.length - 1 ? graph.value : graph.value - 1
    node.x.domain([Math.floor(domainValue) - xDomainPadding, Math.ceil(domainValue + 0.0001) + xDomainPadding])
      .rangeRound([0, viewport.innerWidth])
  }

  // Viewport background
  node.viewport.attr('transform', `translate(${viewport.padding.left},${viewport.padding.top})`)

  // Axes Line
  const centerY = viewport.innerHeight / 3
  node.abscissaLine
    .attr('x1', node.x(d3.min(graph.data, d => d.x)))
    .attr('y1', centerY)
    .attr('x2', node.x(d3.max(graph.data, d => d.x)))
    .attr('y2', centerY)

  // Axes Ticks
  const abscissaTicksData = flatten(graph.data.map((d, i) => {
    return Array.from(i < graph.data.length - 1 ? [0, 1, 2, 3] : [0], j => ({ x: d.x + j / 4 }))
  }))
  const abscissaTicks = node.abscissa.selectAll('.tick')
    .data(abscissaTicksData, d => d.x)
  abscissaTicks
    .enter().append('line')
    .attr('class', d => 'tick' + ((Math.floor(d.x) === d.x) ? ' major' : ''))
    .merge(abscissaTicks)
    .transition()
    .duration(graph.animationDuration)
    .attr('x1', d => node.x(d.x))
    .attr('y1', d => centerY - 7)
    .attr('x2', d => node.x(d.x))
    .attr('y2', d => centerY + 7)
  abscissaTicks.exit()
    .remove()

  // Axes Labels
  const labelY = centerY + 26
  const abscissaLabels = node.abscissa.selectAll('.label.name')
    .data(graph.data.slice().reverse(), d => d.x)
  abscissaLabels
    .enter().append('text')
    .attr('class', 'label name')
    .merge(abscissaLabels)
    .on('click', (_, d) => {
      onChange(d.y)
    })
    .transition()
    .duration(graph.animationDuration)
    .attr('x', d => node.x(d.x))
    .attr('y', labelY)
    .style('opacity', d => d.x >= node.x.domain()[0] && d.x <= node.x.domain()[1] ? 1 : 0.5)
    .text(d => truncateLabel(d.label, 12))
  abscissaLabels.exit()
    .remove()

  const abscissaValueLabels = node.abscissa.selectAll('.label.value')
    .data(graph.data.slice().reverse(), d => d.x)
  abscissaValueLabels
    .enter().append('text')
    .attr('class', 'label value')
    .merge(abscissaValueLabels)
    .on('click', (_, d) => {
      onChange(d.y)
    })
    .transition()
    .duration(graph.animationDuration)
    .attr('x', d => node.x(d.x))
    .attr('y', d => labelY + 18)
    .style('opacity', d => d.x >= node.x.domain()[0] && d.x <= node.x.domain()[1] ? 1 : 0.5)
    .text(d => formatLabel('$,', d.y))
  abscissaValueLabels.exit()
    .remove()

  // Value Cursor
  node.cursor
    .on('mousedown', function() {
      node.svg.style('cursor', 'col-resize')
      graph.isDragging = true

      node.svg.on('mousemove', function(event) {
        const coords = d3.pointer(event, node.viewport.node())
        let newValue = node.x.invert(coords[0])
        // Round cursor values to 5% while dragging
        newValue = Math.floor(newValue * 20) / 20
        const index = Math.max(Math.floor(newValue), 0)
        if (index + 1 < graph.data.length) {
          const current = graph.data[index]
          const next = graph.data[index + 1]
          const newOverridenSalaryValue = Math.floor(current.y + (newValue - index) * (next.y - current.y))
          onChange(newOverridenSalaryValue)
        }
        else {
          const current = graph.data[graph.data.length - 1]
          const newOverridenSalaryValue = Math.floor(current.y)
          onChange(newOverridenSalaryValue)
        }
      })

      const windowNode = d3.select(window)
      windowNode.on('mouseup', function() {
        graph.isDragging = false
        windowNode.on('mouseup', null)
        node.svg.on('mousemove', null)
        node.svg.style('cursor', null)
        setTimeout(() => {
          renderGraph(graph, onChange)
        }, 500)
      })
    })
    .transition()
    .duration(graph.isDragging ? 0 : graph.animationDuration)
    .attr('x1', node.x(graph.value))
    .attr('y1', centerY - 8)
    .attr('x2', node.x(graph.value))
    .attr('y2', centerY + 8)

  const percentValue = graph.value < graph.data.length - 1 ? graph.value - Math.floor(graph.value) : 1
  node.cursorLabel
    .transition()
    .duration(graph.isDragging ? 0 : graph.animationDuration)
    .attr('x', node.x(graph.value) + 5)
    .attr('y', centerY - 16)
    .text(d3.format('.0%')(percentValue))

  // Enable animation only after first rendering
  graph.animationDuration = 100
}

export default {
  props: {
    wage: Object,
    grid: Object,
    height: {
      type: Number,
      default: 90
    },
    padding: {
      type: Number,
      default: 15
    }
  },
  data() {
    return {
      // Options
      viewport: {
        width: 0, // Computed
        height: this.height,
        innerWidth: 0, // Computed
        innerHeight: 0, // Computed
        padding: {
          left: 0,
          right: 0,
          top: this.padding,
          bottom: this.padding
        }
      },
      // State
      graph: null,
      resizeFn: null
    }
  },
  mounted() {
    this.init()
    this.render()
    this.resizeFn = debounce(this.render, 100)
    window.addEventListener('resize', this.resizeFn)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeFn)
  },
  watch: {
    'wage.overridenSalaryValue': 'render',
    'wage.levelIds': 'render',
    grid: 'render',
    padding: 'render'
  },
  methods: {
    init() {
      this.graph = initGraph(this.$refs.svgNode, this.viewport)
    },
    render() {
      generateSliderModel(this.graph, this.wage, this.grid)
      renderGraph(this.graph, overridenSalaryValue => {
        this.$emit('change', overridenSalaryValue)
      })
    }
  }
}

</script>

<style lang="scss" scoped>
@import "./src/styles/graph.scss";

.wage-adjusted-slider {
  background: $graph-lightblue-color;
  border: 1px solid $graph-inner-border-color;
  border-radius: $border-radius;
  padding: 5px;
  line-height: 0;
}

label {
  @include font-small-size;
  text-align: left;
  display: block;
  @include line-regular-height;
  padding: 0 0 5px 2px;
}

svg {
  background: white;
  border-radius: calc($border-radius / 2);
  user-select: none;
  overflow: hidden;
}

svg::v-deep .cursor {
  stroke: $graph-purple-color;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 5px;
  cursor: col-resize;
  opacity: 0.8;

  &:hover {
    stroke-width: 7px;
  }
}

svg::v-deep .abscissa .hTick  {
  shape-rendering: crispedges;
  stroke: $graph-inner-border-color;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1px;
}

svg::v-deep .abscissa .tick {
  shape-rendering: crispedges;
  stroke: $graph-outer-border-color;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1px;

  &.major {
    stroke: darken($graph-accentblue-color, 10);
    stroke-width: 2px;
  }
}

svg::v-deep .abscissa .label {
  cursor: pointer;
}

svg::v-deep .label.name {
  @include font-semibold;
}

svg::v-deep .cursor-container .label.name {
  fill: $graph-purple-color;
}
</style>
