<template>
  <div class="container">
    <h2 v-t="'candidates.jobOfferDelete.title'"></h2>

    <p v-t="{path: 'candidates.jobOfferDelete.confirmMessage', args: {jobOfferName: jobOffer.name}}"></p>

    <checkbox
      v-if="candidatesCount > 0"
      v-model="deleteCandidates">
      {{ $tc('candidates.jobOfferDelete.removeCandidates', candidatesCount, { count: candidatesCount }) }}
    </checkbox>
    <br>

    <div class="error-message" v-if="errorMessage">
      {{ errorMessage }}
    </div>

    <menu>
      <button
        class="secondary"
        @click="$emit('close')"
        :disabled="isLoading"
        v-t="'candidates.jobOfferDelete.cancel'">
      </button>
      <loading-button
        class="primary"
        @click="submit()"
        :loading="isLoading">
        <span v-t="'common.remove'"></span>
      </loading-button>
    </menu>
  </div>
</template>

<script>
import Checkbox from '@components/commons/Checkbox.vue'

export default {
  props: ['jobOffer'],
  data() {
    return {
      isLoading: false,
      deleteCandidates: false,
      errorMessage: null
    }
  },
  computed: {
    candidatesCount() {
      return this.$store.getters['candidates/getRemovableCandidatesCountByJobOffer'](this.jobOffer.id)
    }
  },
  methods: {
    submit() {
      this.isLoading = true
      this.errorMessage = null

      this.$router.push({ name: 'hiringPlans', params: { highlightJobOffer: this.jobOffer.id, highlightClass: 'zoom-out' } })
      setTimeout(() => {
        this.$store.dispatch('candidates/removeJobOffer', {
          jobOffer: this.jobOffer,
          deleteCandidates: this.deleteCandidates
        }).catch(error => error && alert(error))
      }, 1000)
    }
  },
  components: {
    Checkbox
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";

.container {
  @include modal-container;
  width: 550px;
}
</style>
