<template>
  <resizable-panel class="variable-plan-container" :observer="[groupedVariablePlan.variablePlans, isVisible]">
    <div class="variable-plan">
      <div class="variable-plan-title-container">
        <h2
          class="variable-plan-title"
          @click="toggleVisibility()">
          {{groupedVariablePlan.name}}
          <span class="dark-light-text" v-t="`variable.recurrences.${groupedVariablePlan.recurrence}.title`"></span>
        </h2>
        <router-link
          v-if="isVisible && $$isCreator(groupedVariablePlan)"
          tag="button"
          class="secondary"
          :to="{name: 'addVariablePlanPeriod', params: {id: groupedVariablePlan.id}}">
          <span v-t="'variable.periodForm.action'"></span>
        </router-link>
      </div>
      <MarkdownText
        v-if="isVisible"
        class="variable-plan-description dark-light-text no-margin-top no-margin-bottom"
        v-model="groupedVariablePlan.description" />
      <VariablePlanPeriodsTable v-if="isVisible" :variablePlans="groupedVariablePlan.variablePlans" />
    </div>
  </resizable-panel>
</template>

<script>
import MarkdownText from '@components/commons/MarkdownText'
import VariablePlanPeriodsTable from '@components/variable/VariablePlanPeriodsTable'
import ResizablePanel from '@components/commons/ResizablePanel.vue'

export default {
  components: {
    MarkdownText,
    ResizablePanel,
    VariablePlanPeriodsTable
  },
  props: {
    groupedVariablePlan: {
      type: Object
    }
  },
  data() {
    return {
      isVisible: true
    }
  },
  methods: {
    toggleVisibility() {
      this.isVisible = !this.isVisible
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/variable.scss";

.variable-plan-container {
  margin-bottom: 1.25em;
}

.variable-plan {
  @extend .badge-block;
  display: block;
  padding: $variable-padding;
}

.variable-plan-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 33px;
  margin-top: -2px;

  .variable-plan-title {
    @include font-large-size;
    margin: -1px 0 0;
    cursor: pointer;

    span {
      @include font-regular;
      margin-left: 0.25em;
    }
  }
}

.variable-plan-description {
  padding-right: 170px;
}
</style>
