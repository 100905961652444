<template>
  <div class="simulation-indicators" :class="{'right': mode !== 'simulation'}">
    <IndicatorInput
      class="simulation-indicator"
      v-for="(indicator, i) in indicatorsModel"
      :key="indicator.id"
      :indicator="enrichedConfig.indicators.find(i => i.id === indicator.id)"
      :mode="mode"
      :disabled="disabled"
      v-model="indicatorsModel[i]"
      @input="onIndicatorChange" />
    <div class="simulation-results">
      <div
        class="simulation-indicator simulation-result"
        v-if="bonusValue !== null">
        <div class="indicator-label">
          <span v-t="'variable.common.value'"></span>
          <Tooltip
            class="blue-bg gray-icon"
            :class="{left: mode !== 'simulation'}"
            :force="true"
            @mouseover="isFormulaExplanationVisible = true">
            <FormulaExplanation
              v-if="isFormulaExplanationVisible"
              :enrichedConfig="enrichedConfig"
              :sharedIndicators="sharedIndicators"
              :indicators="indicatorsModel"
              :evaluationOptions="evaluationOptions" />
          </Tooltip>
        </div>
        <div class="badge border-badge large-badge">
          {{bonusValue | formattedCurrency }}
        </div>
        <div class="indicator-description" v-if="isSimulation">
          <template v-if="variablePlan.recurrence !== 'none'">/ {{$t(`variable.recurrences.${variablePlan.recurrence}.noun`).toLowerCase()}}</template>
          / {{$tc('employees.employees.count', 1, {count: ''})}}
        </div>
      </div>
      <div
        class="simulation-indicator simulation-result"
        v-if="totalBonusValue !== null">
        <div class="indicator-label" v-t="'variable.common.total'"></div>
        <div class="badge border-badge large-badge">
          {{totalBonusValue | formattedCurrency }}
        </div>
        <div class="indicator-description" v-if="isSimulation">
          <template v-if="variablePlan.recurrence !== 'none'">/ {{$t(`variable.recurrences.${variablePlan.recurrence}.noun`).toLowerCase()}}</template>
          / {{$tc('employees.employees.count', variableWagesCount, {count: variableWagesCount})}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  evaluateIndicators,
  filterIndicators,
  refreshIndicators
} from '@/utils/variable'
import Tooltip from '@components/commons/Tooltip'
import IndicatorInput from '@components/variable/IndicatorInput'
import FormulaExplanation from '@components/variable/FormulaExplanation'
import { mapGetters } from 'vuex'

export default {
  components: {
    IndicatorInput,
    FormulaExplanation,
    Tooltip
  },
  props: {
    variablePlan: {
      type: Object
    },
    enrichedConfig: {
      type: Object
    },
    // Shared indicators (used in `individual` mode)
    sharedIndicators: {
      type: Array
    },
    // Indicators
    value: {
      type: Array
    },
    // Mode (evaluation option. `simulation`, `individual` or `collective`)
    mode: {
      type: String,
      default: 'simulation'
    },
    // Employee (evaluation option)
    employee: {
      type: Object
    },
    // Disable state
    disabled: {
      type: Boolean
    }
  },
  data() {
    return {
      indicatorsModel: [],
      errorMessage: null,
      bonusValue: null,
      isFormulaExplanationVisible: false,
      totalBonusValue: null
    }
  },
  computed: {
    ...mapGetters({
      getEvaluationOptions: 'variablePlans/getEvaluationOptions'
    }),
    isSimulation() {
      return this.mode === 'simulation'
    },
    variableWagesCount() {
      return this.variablePlan.variableWages.length
    },
    evaluationOptions() {
      return this.getEvaluationOptions(this.variablePlan, this.mode, this.employee)
    }
  },
  methods: {
    initModel() {
      let indicators = this.value || []
      indicators = refreshIndicators(this.enrichedConfig, indicators, this.evaluationOptions)
      indicators = filterIndicators(this.enrichedConfig, indicators, this.evaluationOptions)
      // TODO: Special case for Data Feed reference indicators with:
      // evaluate reference & computeValue
      // editable in simulation
      this.indicatorsModel = indicators
      this.computeBonus()
    },
    computeBonus() {
      // Hide bonus in collective mode
      if (this.mode === 'collective') {
        return
      }
      let bonusValue = null
      let totalBonusValue = null
      try {
        bonusValue = evaluateIndicators(this.enrichedConfig, this.sharedIndicators, this.indicatorsModel, this.evaluationOptions)
        if (this.mode === 'simulation' && this.variableWagesCount > 1) {
          totalBonusValue = bonusValue * this.variableWagesCount
        }
        this.errorMessage = null
      }
      catch (err) {
        this.errorMessage = err
      }
      if (this.bonusValue !== bonusValue) {
        this.$emit('value', bonusValue)
      }
      this.bonusValue = bonusValue
      this.totalBonusValue = totalBonusValue
    },
    onIndicatorChange() {
      this.computeBonus()
      if (!this.disabled) {
        this.$emit('input', this.indicatorsModel)
      }
    }
  },
  watch: {
    'enrichedConfig.formulas': 'initModel',
    'enrichedConfig.indicators': 'initModel',
    'enrichedConfig.dataFeeds': 'computeBonus',
    sharedIndicators: 'computeBonus',
    variableWagesCount: 'computeBonus'
  },
  created() {
    this.initModel()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/badge.scss";

.simulation-indicators {
  display: flex;
  white-space: normal;
  flex-wrap: wrap;
  text-align: left;
  margin-top: -0.15rem;
  margin-bottom: -0.75rem;
  // Avoid height issues when embedded in EmployeesTableContainer
  min-height: 66px;

  &.right {
    justify-content: end;
    max-width: 70vw;

    .simulation-indicator {
      text-align: right;
    }
  }

  .simulation-indicator {
    margin-right: 1em;
    margin-bottom: 0.75em;
  }

  .simulation-results {
    display: flex;
  }

  .indicator-label {
    @include font-small-size;
    @include font-semibold;
    margin-bottom: 5px;
  }

  .indicator-description {
    margin-top: 3px;
    color: $dark-light-text-color;
    @include font-smaller-size;
    @include font-tabular-numbers;
  }

  .tooltip-container::v-deep {
    white-space: initial;
    margin-left: 2px;
    @include font-normal-size;
  }
}
</style>
