<template>
  <button
    v-if="employee"
    class="tooltip-container signin-as-button"
    :class="{loading: isLoading}"
    @click="signIn">
    …
    <div class="tooltip" v-t="'settings.settingsCompanyAccessRights.signinAs'"></div>
  </button>
</template>

<script>
export default {
  props: {
    employee: Object
  },
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    async signIn() {
      this.isLoading = true
      try {
        await this.$store.dispatch('account/signInAsEmployee', this.employee.id)
      }
      catch (_) {
        this.isLoading = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";

.signin-as-button {
  @extend .small-button;
  @include font-bold;
  padding: 5px 0;
  width: 28px;
  margin-left: 5px;
  background: transparent url(~@/assets/icon-signin-as.svg) no-repeat center center;
  color: transparent !important;

  &.loading {
    background-image: none;
    color: $clearteal-color !important;
  }
}

// Similar to Tooltip component without the icon
.tooltip-container {
  position: relative;

  &:hover .tooltip {
    display: block;
  }
}

.tooltip {
  display: none;
  pointer-events: none;
  position: absolute;
  background: $graph-lightblue-color;
  border: $border;
  @include font-regular;
  @include font-small-size;
  color: $text-color;
  border-radius: $border-radius;

  margin-top: 16px;
  margin-left: -163px;
  box-shadow: 1px 2px 0 rgba(0, 0, 0, 0.015), 3px 4px 0 rgba(0, 0, 0, 0.007);
  z-index: 1000;
  padding: 0.5em 0.75em;
  text-transform: none;
  width: 200px;

  &:after,
  &:before {
    top: -21px;
    left: 165px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 10px;
  }

  &:after {
    border-bottom-color: $graph-lightblue-color;
    margin-top: 1px;
  }
  &:before {
    border-bottom-color: $graph-outer-border-color;
  }
}
</style>
