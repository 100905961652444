<template>
  <div class="wrapper">
    <div class="svg-container">
      <GraphTooltip :employees="employees" :tooltip="tooltip" />
      <svg :height="viewport.height" ref="svgNode"></svg>
      <GraphLegend id="seniority" />
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
import i18n from '../../i18n'
import debounce from 'lodash.debounce'
import {
  generateLinearRegressionAbscissaLabels,
  generateOrdinateLabels,
  generateCircles,
  generateLinearRegressionLines,
  renderInteractiveLayer
} from '@/utils/graph'
import GraphLegend from '@components/graph/GraphLegend.vue'
import GraphTooltip from '@components/graph/GraphTooltip.vue'
import { mapGetters } from 'vuex'

function formatAbscissaLabel(months) {
  const years = months / 12
  if (years === Math.floor(years)) {
    return i18n.tc('dashboard.axes.year', years, { count: years })
  }
  else {
    return i18n.tc('dashboard.axes.month', months, { count: months })
  }
}

function initGraph(svg, viewport) {
  const node = {}
  node.svg = d3.select(svg)
  node.viewport = node.svg.append('g')
    .attr('class', 'viewport')
  node.background = node.viewport.append('rect')
    .attr('class', 'background')
  node.abscissa = node.viewport.append('g')
    .attr('class', 'axis abscissa')
  node.ordinate = node.viewport.append('g')
    .attr('class', 'axis ordinate')
  node.lines = node.viewport.append('g')
    .attr('class', 'lines')
  node.circles = node.viewport.append('g')
    .attr('class', 'circles')
  node.x = d3.scaleLinear()
  node.y = d3.scaleLinear()

  return {
    node: node,
    viewport: viewport,
    data: []
  }
}

function generateLinearRegressionModel(graph, employees) {
  // Generate data
  graph.employees = employees
  graph.data = employees.map(e => ({
    id: e.id,
    x: e.seniorityDate,
    y: e.salary,
    class: e.gender,
    route: e.route
  })).filter(d => d.x > 0)
  return graph
}

function renderGraph(graph, component) {
  if (!graph.node.svg.node()) {
    return
  }
  const viewport = graph.viewport
  const node = graph.node

  // Viewport
  viewport.width = graph.node.svg.node().getBoundingClientRect().width
  viewport.innerWidth = viewport.width - viewport.padding.left - viewport.padding.right
  viewport.innerHeight = viewport.height - viewport.padding.top - viewport.padding.bottom

  // Graph Axes
  graph.axes = {
    abscissa: {
      type: 'continuous',
      labels: 'belowAxis',
      axis: node.x
    },
    ordinate: {
      type: 'continuous',
      labels: 'beforeAxis',
      axis: node.y,
      format: '$~s'
    }
  }
  node.x.domain([0, d3.max(graph.data, d => d.x) || 0])
    .rangeRound([35, viewport.innerWidth - 50])
  node.y.domain([d3.max(graph.data, d => d.y) || 0, 0])
    .rangeRound([0, viewport.innerHeight])

  // Viewport background
  node.viewport.attr('transform',
    `translate(${viewport.padding.left},${viewport.padding.top})`)
  node.background.attr('width', viewport.innerWidth)
    .attr('height', viewport.innerHeight)

  // Axes Labels
  const abscissaLabelsData = generateLinearRegressionAbscissaLabels(graph)
  const abscissaLabels = node.abscissa.selectAll('.label')
    .data(abscissaLabelsData, d => d.label)
  abscissaLabels
    .enter().append('text')
    .attr('class', 'label wrap')
    .attr('x', d => d.x)
    .attr('y', d => d.y)
    .merge(abscissaLabels)
    .transition()
    .attr('x', d => d.x)
    .attr('y', d => d.y)
    .text(d => formatAbscissaLabel(d.label))
  abscissaLabels.exit()
    .remove()

  const abscissaTicks = node.abscissa.selectAll('.tick')
    .data(abscissaLabelsData, d => d.label)
  abscissaTicks
    .enter().append('line')
    .attr('class', 'tick')
    .attr('x1', d => d.x)
    .attr('y1', d => viewport.innerHeight)
    .attr('x2', d => d.x)
    .attr('y2', d => viewport.innerHeight)
    .merge(abscissaTicks)
    .transition()
    .attr('x1', d => d.x)
    .attr('y1', d => 0)
    .attr('x2', d => d.x)
    .attr('y2', d => viewport.innerHeight)
  abscissaTicks.exit()
    .remove()

  const ordinateLabelsData = generateOrdinateLabels(graph)
  const ordinateLabels = node.ordinate.selectAll('.label')
    .data(ordinateLabelsData, d => d.id)
  ordinateLabels
    .enter().append('text')
    .attr('class', 'label')
    .attr('x', d => d.x)
    .attr('y', d => d.y)
    .merge(ordinateLabels)
    .text(d => d.label)
    .attr('x', d => d.x)
    .attr('y', d => d.y)
  ordinateLabels.exit()
    .remove()

  const ordinateTicks = node.ordinate.selectAll('.tick')
    .data(ordinateLabelsData, d => d.y)
  ordinateTicks
    .enter().append('line')
    .attr('class', 'tick')
    .merge(ordinateTicks)
    .attr('x1', d => node.x.range()[0])
    .attr('y1', d => d.y)
    .attr('x2', d => node.x.range()[1])
    .attr('y2', d => d.y)
  ordinateTicks.exit()
    .remove()

  // Circles
  const employeesCirclesData = generateCircles(graph)
  const employeesCircles = node.circles.selectAll('.circle')
    .data(employeesCirclesData, d => d.id)
  employeesCircles
    .enter().append('circle')
    .attr('class', d => 'circle ' + d.class)
    .attr('cx', d => d.x)
    .attr('cy', d => d.y)
    .attr('r', 0)
    .style('opacity', 0.7)
    .merge(employeesCircles)
    .transition()
    .delay(d => d.x / node.x.range()[1] * 300 + (node.y.range()[1] - d.y) / node.y.range()[1] * 150)
    .attr('cx', d => d.x)
    .attr('cy', d => d.y)
    .attr('r', d => d.r)
  employeesCircles.exit()
    .transition()
    .attr('r', 0)
    .style('opacity', 0)
    .remove()

  // Linear Regression lines
  const linesData = generateLinearRegressionLines(graph, employeesCirclesData)
  const lines = node.lines.selectAll('.line')
    .data(linesData, d => d.id)
  lines
    .enter().append('line')
    .attr('x1', d => d.x1)
    .attr('y1', d => d.y1)
    .attr('x2', d => d.x1)
    .attr('y2', d => d.y1)
    .merge(lines)
    .transition()
    .duration(500)
    .attr('class', d => 'line ' + d.class)
    .attr('x1', d => d.x1)
    .attr('y1', d => d.y1)
    .attr('x2', d => d.x2)
    .attr('y2', d => d.y2)

  lines.exit()
    .remove()

  // Mouse hover
  renderInteractiveLayer(
    node,
    employeesCirclesData,
    tooltip => {
      component.tooltip = tooltip
    },
    route => {
      component.$router.push(route)
    })
}

export default {
  components: {
    GraphLegend,
    GraphTooltip
  },
  data() {
    return {
      // Options
      viewport: {
        width: 0, // Computed
        height: 350,
        innerWidth: 0, // Computed
        innerHeight: 0, // Computed
        padding: {
          left: 40,
          right: 0,
          top: 15,
          bottom: 50
        }
      },
      // State
      tooltip: null,
      graph: null,
      resizeFn: null
    }
  },
  computed: {
    ...mapGetters({
      employees: 'statistics/getFilteredEmployees'
    })
  },
  mounted() {
    this.init()
    setTimeout(this.render, 400)
    this.resizeFn = debounce(this.render, 100)
    window.addEventListener('resize', this.resizeFn)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeFn)
  },
  watch: {
    employees: 'render'
  },
  methods: {
    init() {
      this.graph = initGraph(this.$refs.svgNode, this.viewport)
    },
    render() {
      if (this.employees) {
        generateLinearRegressionModel(this.graph, this.employees)
        renderGraph(this.graph, this)
      }
    }
  }
}

</script>

<style lang="scss" scoped>
@import "./src/styles/graph.scss";

svg::v-deep .ordinate .label {
  text-anchor: end;
  alignment-baseline: middle;
}

svg::v-deep .abscissa .tick {
  shape-rendering: crispedges;
  stroke: $graph-inner-border-color;
}

svg::v-deep .ordinate .tick {
  shape-rendering: crispedges;
  stroke: $graph-inner-border-color;
  stroke-dasharray: 3 3;
  opacity: 0.8;
}

.wrapper::v-deep .legend {
  li::before {
    opacity: 0.7;
  }
}
</style>
