<template>
  <div class="formula-explanation">
    <strong v-t="'variable.editor.formula.explanation.title'"></strong>
    <div
      v-for="(explainedFormula, i) in explainedFormulas"
      :key="[explainedFormula.formula, i].join()"
      class="formula-line">
      <template v-if="explainedFormula.isError">
        <span class="formula-error" v-t="{path: 'variable.editor.formula.explanation.formulaError', args: {formula: explainedFormula.formula}}"></span>
      </template>
      <template v-else-if="'Condition' === explainedFormula.name">
        {{$t(`variable.editor.formula.explanation.invalidCondition${i === explainedFormulas.length - 1 ? 'Stop' : 'Next'}`, { formula: explainedFormula.formula})}}
      </template>
      <template v-else-if="'Formula' === explainedFormula.name">
        <template v-if="explainedFormula.formula.toString() !== explainedFormula.result.toString()">
          <span class="formula-content">{{explainedFormula.formula}} = </span>
          <span>{{$options.filters.formattedCurrency(explainedFormula.result, true)}}</span>
        </template>
        <template v-else>
          {{$t('variable.editor.formula.explanation.simpleFormulaResult', {result: $options.filters.formattedCurrency(explainedFormula.result, true) })}}
        </template>
      </template>
      <template v-else-if="'Maximum' === explainedFormula.name">
        {{$t('variable.editor.formula.explanation.maximumReached', {result: $options.filters.formattedCurrency(explainedFormula.result, true) })}}
      </template>
    </div>
  </div>
</template>

<script>
import { evaluateIndicators } from '@/utils/variable'

export default {
  props: {
    enrichedConfig: {
      type: Object
    },
    sharedIndicators: {
      type: Array
    },
    indicators: {
      type: Array
    },
    evaluationOptions: {
      type: Object
    }
  },
  computed: {
    explainedFormulas() {
      const evaluationOptions = { ...this.evaluationOptions, annotations: [] }
      evaluateIndicators(this.enrichedConfig, this.sharedIndicators, this.indicators, evaluationOptions)
      return evaluationOptions.annotations
    }
  }
}
</script>

<style lang="scss" scoped>
.formula-line {
  margin-top: 0.5em;

  &:last-of-type {
    color: darken($clearteal-color, 10);
    @include font-medium;
    margin-bottom: 0.25em;

    .formula-content {
      @include font-regular;
      color: $text-color;
    }
  }

  .formula-error {
    color: $red-color;
  }
}
</style>
