<template>
  <div>
    <div class="qualification-editor">
      <EmployeesTableContainer
        :employees="employees.excluded"
        :hideWhenEmpty="true"
        :highlightedEmployeeId="highlightedEmployeeId"
        :infoSlotTitle="$t('employees.employee.form.placementOnGrid')"
        name="grid-qualification-qualifying-employees"
        :progress="excludedProgress"
        :title="$t('grid.onboarding.qualification.editor.employeesToQualify')">
        <template v-slot:info="slotProps">
          <EmployeeInlineWage :employee="getQualificationEmployee(slotProps.employee)" />
        </template>
        <template v-slot:action="slotProps">
          <button
            class="secondary"
            v-t="'grid.onboarding.qualification.editor.qualify'"
            @click="openEmployeeModal($event, slotProps.employee)" />
          <loading-button
            class="exclude-employee destructive"
            :loading="isExcludingEmployeeIds[slotProps.employee.id]"
            @click="excludeEmployee(slotProps.employee)">
            <span>×</span>
          </loading-button>
        </template>
      </EmployeesTableContainer>
      <EmployeesTableContainer
        :employees="employees.included"
        :hideWhenEmpty="true"
        :highlightedEmployeeId="highlightedEmployeeId"
        :infoSlotTitle="$t('employees.employee.form.placementOnGrid')"
        name="grid-qualification-qualified-employees"
        :progress="includedProgress"
        :title="$t('grid.onboarding.qualification.editor.employeesQualified')">
        <template v-slot:info="slotProps">
          <EmployeeInlineWage :employee="getQualificationEmployee(slotProps.employee)" />
        </template>
        <template v-slot:action="slotProps">
          <button
            class="secondary"
            v-t="'common.update'"
            @click="openEmployeeModal($event, slotProps.employee)" />
        </template>
      </EmployeesTableContainer>
    </div>
    <modal :visible="!!modalPayload" @close="closeModal">
      <div class="employee-modal">
        <grid-employee-editor
          :payload="modalPayload"
          :isGridGenerated="false"
          @close="closeModal" />
      </div>
    </modal>
  </div>
</template>

<script>
import GridEmployeeEditor from '@components/grid/valuation/GridEmployeeEditor.vue'
import Modal from '@components/commons/Modal.vue'
import EmployeesTableContainer from '@components/employees/EmployeesTableContainer.vue'
import EmployeeInlineWage from '@components/employees/EmployeeInlineWage.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    GridEmployeeEditor,
    Modal,
    EmployeesTableContainer,
    EmployeeInlineWage
  },
  data() {
    return {
      isExcludingEmployeeIds: {},
      highlightedEmployeeId: null,
      modalPayload: null
    }
  },
  computed: {
    ...mapGetters({
      grid: 'sandbox/grid',
      getQualificationEmployees: 'onboarding/getQualificationEmployees',
      getQualificationEmployee: 'onboarding/getQualificationEmployee'
    }),
    employees() {
      const { qualified, unqualified } = this.getQualificationEmployees
      return { included: qualified, excluded: unqualified }
    },
    excludedProgress() {
      const inversedCount = this.employees.excluded.length
      const count = this.employees.included.length
      const total = this.employees.included.length + this.employees.excluded.length
      return {
        count: inversedCount,
        total: total,
        percent: Math.round(count / total * 100)
      }
    },
    includedProgress() {
      const count = this.employees.included.length
      const total = this.employees.included.length + this.employees.excluded.length
      return {
        count: count,
        total: total,
        percent: Math.round(count / total * 100)
      }
    }
  },
  methods: {
    openEmployeeModal(event, employee) {
      if (!event.altKey) {
        this.modalPayload = { employee }
      }
      else {
        this.removeEmployeeWage(employee)
      }
    },
    closeModal(wage) {
      this.modalPayload = null
      if (wage && wage.employeeId) {
        this.highlightedEmployeeId = wage.employeeId
        this.$emit('change')
      }
    },
    async excludeEmployee(employee) {
      if (!confirm(this.$t('grid.editor.exclusion.excludeEmployeeConfirmation', { employeeName: employee.firstName }))) {
        return
      }
      this.$set(this.isExcludingEmployeeIds, employee.id, true)
      await this.$store.dispatch('employees/updateEmployee', { id: employee.id, isExcluded: true })
      this.$set(this.isExcludingEmployeeIds, employee.id, false)
    },
    async removeEmployeeWage(employee) {
      if (confirm(this.$t('grid.onboarding.qualification.confirmReset', { name: employee.firstName }))) {
        try {
          await this.$store.dispatch('onboarding/resetEmployeeWage', employee)
          this.$emit('change')
        }
        catch (error) {
          error && alert(error)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/button.scss";

.qualification-editor {
  margin-bottom: 1.8em;
}

.employee-modal {
  padding: 20px;
  padding-bottom: 0;
  width: $content-max-width;

  & > div {
    padding-bottom: 10px;
  }
}

.exclude-employee {
  @include font-big-size;
  @include font-bold;
  min-width: 0;
  margin-left: 0.25em;
  padding: 5px 0 7px;
  width: 33px;
  vertical-align: -1px;
}
</style>
