
export const COMPANY_PERMISSION_VALUES = {
  admin: 'admin',
  manager: 'manager',
  employee: 'employee'
}

export const COMPANY_PERMISSIONS = {
  employeesExport: {
    rejectedPermissions: [COMPANY_PERMISSION_VALUES.employee]
  },
  employeesSetCurrentWage: {
    rejectedPermissions: [COMPANY_PERMISSION_VALUES.employee]
  },
  employeesWageFormula: {
    includeNobody: true,
    explanation: true
  },
  gridEditor: {
    rejectedPermissions: [COMPANY_PERMISSION_VALUES.employee]
  },
  gridValuationEditor: {
    rejectedPermissions: [COMPANY_PERMISSION_VALUES.employee],
    explanation: true
  },
  hiringPlans: {
    rejectedPermissions: [COMPANY_PERMISSION_VALUES.employee]
  },
  gridSimulation: {},
  teamsOrgChart: {},
  teamsDirectory: {},
  variablePlans: {
    rejectedPermissions: [COMPANY_PERMISSION_VALUES.employee]
  },
  wagePlans: {
    rejectedPermissions: [COMPANY_PERMISSION_VALUES.employee]
  }
}
