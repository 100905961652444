// This is the client-side implem of OrgChartService

export function computeOrgChart(employees, buildNode) {
  const [trees, nodes] = initTreesAndNodes(employees, buildNode)
  const [updatedTrees] = attachNodes([trees, nodes], 0)
  return updatedTrees
}

function findNode(tree, id) {
  if (tree) {
    if (tree.id === id) {
      return tree
    }
    else if (tree.children) {
      return tree.children.reduce((memo, node) => {
        const result = findNode(node, id)
        return result || memo
      }, null)
    }
  }
}

function addChild(tree, node, parentId) {
  const parentNode = findNode(tree, parentId)
  parentNode.children.push(node)
  return tree
}

function hasParentNode(employee, employees) {
  return employee.managerId &&
    employee.managerId !== employee.id &&
    employees.find(e => e.id === employee.managerId)
}

function initTreesAndNodes(employees, buildNode) {
  return employees.reduce(([trees, nodes], employee) => {
    const node = buildNode(employee)
    if (hasParentNode(employee, employees)) {
      return [trees, [node].concat(nodes)]
    }
    else {
      return [[node].concat(trees), nodes]
    }
  }, [[], []])
}

function attachNodes([trees, nodes], depth) {
  if (depth > 100) {
    console.log('Infinite recursion detected', trees, nodes)
    const errorTree = []
    const usersLoop = nodes
      .reduce((memo, node) => {
        if (nodes.find(({ content }) => content.managerId === node.id)) {
          memo.push(node)
        }
        return memo
      }, [])
      .slice(0, 4)
      .map(n => n.content.firstName)
    errorTree.error = usersLoop
    return [errorTree, []]
  }
  else if (!nodes.length) {
    return [trees, []]
  }
  else {
    return attachNodes(nodes.reduce(([updatedTrees, remainingNodes], node) => {
      const parentId = node.content.managerId
      const tree = updatedTrees.find(t => findNode(t, parentId))
      if (tree) {
        updatedTrees = updatedTrees
          .filter(t => !(t.id === tree.id))
          .concat([addChild(tree, node, parentId)])
        return [updatedTrees, remainingNodes]
      }
      else {
        return [updatedTrees, [node].concat(remainingNodes)]
      }
    }, [trees, []]), depth + 1)
  }
}
