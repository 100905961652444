<template>
  <div>
    <LoadingView v-if="!benefits" />
    <div v-else>
      <h2>{{$tc('benefits.title', benefits.length)}}</h2>
      <div v-if="benefits.length === 0">
        <p>{{placeholderText}}</p>
        <div class="placeholder" v-if="$$isAdmin && !isOneselfView">
          <menu class="hero">
            <p class="no-margin-top" v-html="$t('employees.employee.benefits.adminPlaceholder')" />
            <router-link
              to="/benefits"
              tag="button"
              class="primary"
              v-t="'employees.employee.benefits.goToBenefits'" />
          </menu>
        </div>
      </div>
      <div v-else>
        <p>{{benefitsCountText}}</p>
        <div v-if="valorisation" class="benefits-valorisation">
          <BenefitValorisationSummary
            v-if="companyCost"
            :estimationTitle="$t('benefits.valorisationSummary.estimatedTotalCoverage')"
            :valorisation="companyCost"
            :selectedRecurrence="selectedRecurrence" />
          <BenefitValorisationSummary
            :estimationTitle="$t('benefits.valorisationSummary.estimatedTotalValue')"
            :valorisation="valorisation"
            badgeLayout="green"
            :selectedRecurrence="selectedRecurrence" />
        </div>
        <p class="light-text" v-t="'employees.employee.benefits.moreDetails'"></p>
      </div>
      <div class="benefits-grid">
        <BenefitItem
          v-for="benefit in benefits"
          :key="benefit.id"
          :benefit="benefit"
          @click.native="selectBenefit(benefit)" />
      </div>
      <Modal
        class="benefit-modal"
        :visible="isDetailDisplayed"
        @close="closeDetail">
        <BenefitDetail :benefit="selectedBenefit" />
      </Modal>
    </div>
  </div>
</template>

<script>
import BenefitDetail from '@components/benefits/BenefitDetail.vue'
import BenefitItem from '@components/benefits/BenefitItem.vue'
import BenefitValorisationSummary from '@components/benefits/BenefitValorisationSummary.vue'
import LoadingView from '@components/commons/LoadingView.vue'
import Modal from '@components/commons/Modal.vue'

import { benefitsValorisationSummary, benefitsCompanyCostSummary } from '@/utils/benefit'

export default {
  name: 'EmployeeBenefitsView',
  components: {
    BenefitDetail,
    BenefitItem,
    BenefitValorisationSummary,
    LoadingView,
    Modal
  },
  props: {
    benefits: {
      type: Array,
      default() {
        return []
      }
    },
    selectedRecurrence: {
      type: String,
      default: 'yearly'
    }
  },
  data() {
    return {
      isDetailDisplayed: false,
      selectedBenefit: null
    }
  },
  computed: {
    isOneselfView() {
      return this.$route.name === 'oneself'
    },
    placeholderText() {
      if (this.isOneselfView) {
        return this.$t('employees.employee.benefits.noBenefitsOneself')
      }

      return this.$t('employees.employee.benefits.noBenefits', { firstName: this.employee.firstName })
    },
    benefitsCountText() {
      if (this.isOneselfView) {
        return this.$tc('employees.employee.benefits.countOneself', this.benefits.length)
      }

      return this.$tc('employees.employee.benefits.count', this.benefits.length, { count: this.benefits.length, firstName: this.employee.firstName })
    },
    employee() {
      if (this.isOneselfView) {
        return this.$store.getters['employees/getOneself']
      }

      return this.$store.getters['employees/getEmployee'](this.$route.params.id)
    },
    valorisation() {
      return benefitsValorisationSummary(this.benefits)
    },
    companyCost() {
      return benefitsCompanyCostSummary(this.benefits)
    }
  },
  methods: {
    selectBenefit(benefit) {
      this.selectedBenefit = benefit
      this.isDetailDisplayed = true
    },
    closeDetail() {
      this.isDetailDisplayed = false
      this.selectedBenefit = null
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/badge.scss";
@import "./src/styles/button.scss";

.placeholder {
  @extend .badge-block;
  display: block;
  margin-top: 2em;

  menu.hero {
    margin: 1em 0;
  }
}

.benefits-valorisation {
  display: flex;
  margin: 1em 0 2em;
}

.benefits-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
}

.benefit-modal::v-deep {
  & .close-button-container {
    padding: 26px 17px 0 0;
  }

  & .container {
    min-height: 40px;
  }
}
</style>
