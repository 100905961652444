import Vue from 'vue'
import api from '@/store/api'
import i18n from '@/i18n'
import { trackEvent } from '@/services/analytics'
import { getItem, setItem } from '@/utils/storage'
import {
  getWageDetails,
  gridHasSkills,
  isInterval,
  isGridInterval,
  isSandboxWageValid,
  isSandboxGridGenerated
} from '@/utils/grid'
import {
  getSkillsProgress,
  getSkillsHolders,
  getSkillsHolderLevels,
  findSkills,
  findSkill
} from '@/utils/skills'
import { getField } from '@/utils/employee'
import { generateCurveValues } from '@/utils/graph'
import { debouncedAction, sequentialDispatch } from '@/utils/store'
import { min, max } from 'd3'
import cloneDeepWith from 'lodash.clonedeepwith'
import groupBy from 'lodash.groupby'
import partition from 'lodash.partition'
import sortBy from 'lodash.sortby'
import uuidv4 from 'uuid/v4'

export const CW_ONBOARDING_STATE = 'CW_ONBOARDING_STATE'

const COMPONENT_REFS = {
  role: 'jobType',
  seniority: 'seniority',
  management: 'managementLevel',
  city: 'city'
}

const initialState = () => {
  return {
    // Status
    isActive: false,
    progressIndex: 0,
    // Intro
    isIntro: true,
    // Components
    selectedComponents: null,
    // Skills
    selectedJob: null,
    jobSkills: {},
    // Generate
    isGridGenerated: false,
    gridConfig: null
  }
}

const state = initialState()

function buildGrid(getters) {
  return Object.keys(COMPONENT_REFS).reduce((memo, component) => {
    const enabled = getters.getSelectedComponents.includes(component)
    memo[component] = {
      enabled: enabled,
      levels: enabled ? getters.getEmployeesValues(component) : []
    }
    return memo
  }, {})
}

// This heuristic will only work for log/cubic/linear
function guessGridConfigCurveFromLevels(components, levels) {
  const maxLevelIndex = levels.length - 1
  const maxValue = max(levels, l => getGridConfigComponentValue(components, l)) || 0
  const minValue = min(levels, l => getGridConfigComponentValue(components, l)) || 0
  const meanValue = (maxValue + minValue) / 2
  const levelsMeanValue =
    Math.round((levels[Math.floor(maxLevelIndex / 2)].salaryValue +
      levels[Math.ceil(maxLevelIndex / 2)].salaryValue) / 2 * 10) / 10
  if (levelsMeanValue > meanValue) {
    return 'log'
  }
  else if (levelsMeanValue < meanValue) {
    return 'cubic'
  }
  else {
    return 'linear'
  }
}

// Returns config value for the level of component
// In particular, apply reversed formula on role & experience when interval is enabled,
// so the form can display an amount for role and a coefficient for experience.
function getGridConfigComponentValue(components, level) {
  let { salaryValue } = level
  const component = components.find(c => c.id === level.componentId)
  if (isGridInterval(components)) {
    if (component.parentComponentId) {
      const firstLevel = component.levels.find(l => l.linkedLevelId === level.linkedLevelId)
      return salaryValue / firstLevel.salaryValue
    }
    else if (component.linkedComponents) {
      const linkedComponent = component.linkedComponents && component.linkedComponents[0]
      const firstLinkedLevel = linkedComponent.levels.find(l => l.linkedLevelId === level.id)
      salaryValue = firstLinkedLevel.minimumValue
    }
  }
  return salaryValue || 0
}

const getters = {
  isActive(state) {
    return state.isActive
  },
  isIntro(state) {
    return state.isIntro
  },
  isGridGenerated(state) {
    return state.isGridGenerated
  },
  getProgressIndex(state) {
    return state.progressIndex
  },
  getProgress(state, getters, _, rootGetters) {
    if (state.isActive && !state.isIntro) {
      if (rootGetters['account/isAdmin']) {
        return (getters.getProgressIndex + 1) + '/4'
      }
      else {
        return Math.min(getters.getProgressIndex, 2) + '/2'
      }
    }
  },
  getSelectedComponents(state) {
    return state.selectedComponents
  },
  getEmployeesValues(state, _, __, rootGetters) {
    return (scope) => {
      const employees = rootGetters['employees/getGridEmployees']
      const field = COMPONENT_REFS[scope]
      const shouldPreserveLevelOrder = ['role', 'experience', 'seniority'].includes(scope)
      const groupedEmployees = groupBy(employees, e => e[field])
      let values = Object.keys(groupedEmployees)
        .filter(v => !['null', 'undefined'].includes(v))

      if (!shouldPreserveLevelOrder) {
        values = sortBy(values, value => (
          -groupedEmployees[value].length
        ))
      }
      else {
        values = values.sort()
      }

      // Generate one default level when employees' fields contains no data
      if (!values.length) {
        values = [i18n.t(`templates.clearwage.levels.default${scope === 'role' ? 'Role' : ''}Level`)]
      }
      return values
    }
  },
  getEmployeeWageModel(state, _, __, rootGetters) {
    return (employeeId) => {
      const wage = { ...rootGetters['sandbox/employeeWage'](employeeId) }
      const employee = rootGetters['employees/getEmployee'](employeeId)
      if (employee && !rootGetters['sandbox/hasEmployeeWage'](employeeId)) {
        // Best effort to prefill level ids based on employee fields
        const components = rootGetters['sandbox/allComponents']
        wage.levelIds = Object.keys(COMPONENT_REFS).reduce((memo, ref) => {
          const component = components.find(c => c.ref === ref)
          const field = COMPONENT_REFS[ref]
          const fieldValue = employee[field]
          const level = component && component.levels && component.levels.find(l => l.name === fieldValue)
          if (level) {
            memo.push(level.id)
          }
          return memo
        }, [])
      }
      return wage
    }
  },
  getJobs(_state, getters, _rootState, rootGetters) {
    const components = rootGetters['sandbox/allComponents']
    return getSkillsHolders(components, getters.getJobSkillsProgress)
  },
  getJobSkillsProgress(state, getters) {
    return (job) => getSkillsProgress(getters.getJobSkills(job))
  },
  getSelectedJob(state, getters) {
    return state.selectedJob || (getters.getJobs.length && getters.getJobs[0].id)
  },
  getSelectedJobSkills(state, getters) {
    return getters.getJobSkills(getters.getSelectedJob)
  },
  getJobSkills(state, getters) {
    return (job) => state.jobSkills[job] || getters.getDefaultJobSkills(job)
  },
  getPreviewJobSkills(state, getters) {
    return (job, template) => getters.getDefaultJobSkills(job, template)
  },
  getJobLevels(_, __, ___, rootGetters) {
    return (job) => {
      const components = rootGetters['sandbox/allComponents']
      return getSkillsHolderLevels(components, job)
    }
  },
  getDefaultJobSkills(_state, getters) {
    return (job, template = null) => {
      const levels = getters.getJobLevels(job)
      const hasSkills = !!levels.find(level =>
        level.domains && level.domains.find(domain =>
          !!domain.skills.length))
      if (levels.length) {
        const hasCustomDomains = levels[0].domains && (
          levels[0].domains.length !== 2 ||
          (levels[0].domains.length && levels[0].domains[0].linkedLevelId)
        )

        return {
          id: uuidv4(),
          job: job,
          hasSkills: hasSkills,
          hasCustomDomains,
          disabled: !hasCustomDomains && (template || !hasSkills),
          domains: levels[0].domains,
          levels: levels.map((level, i) => ({
            id: level.id,
            name: level.name,
            description: level.description,
            domains: (level.domains || []).map((domain, j) => {
              let skills = cloneDeepWith(domain.skills)
              if (template) {
                const levelIndex = i.toString()
                const domainIndex = j.toString()
                if (template[levelIndex]) {
                  if (template[levelIndex][domainIndex]) {
                    skills = template[levelIndex][domainIndex].map(skill => (
                      Object.assign(skill, { id: uuidv4() })
                    ))
                  }
                }
                else {
                  skills = []
                }
              }
              return {
                id: domain.id,
                name: domain.name,
                description: domain.description,
                skills: skills
              }
            })
          }))
        }
      }
    }
  },
  getQualificationEmployees(_, __, ___, rootGetters) {
    console.time('getQualificationEmployees')
    const grid = rootGetters['sandbox/grid']
    const employees = rootGetters['employees/getGridEmployees']
    const [qualified, unqualified] = partition(employees, e => isSandboxWageValid(rootGetters['sandbox/employeeWage'](e.id), grid))
    console.timeEnd('getQualificationEmployees')
    return { qualified, unqualified }
  },
  getQualificationEmployee(_, __, ___, rootGetters) {
    return (employee) => {
      const grid = rootGetters['sandbox/grid']
      const wage = rootGetters['sandbox/employeeWage'](employee.id)
      const wageDetails = getWageDetails(grid, wage, { includeComponents: true })
      return { ...employee, wageDetails }
    }
  },
  getGridConfig(state, getters, _rootState, rootGetters) {
    return state.gridConfig || getters.getDefaultGridConfig
  },
  getDefaultGridConfig(state, getters, _rootState, rootGetters) {
    const components = rootGetters['sandbox/allComponents']
    return components.map(component => {
      // Compute Grid Config based on level values
      const { id, ref, name, levels, hasAutoLevels, parentComponentId, salaryOperation } = component
      const config = {
        id,
        ref,
        name,
        hasAutoLevels,
        parentComponentId,
        salaryOperation,
        config: component.config,
        levels: levels.map(({ id, name, rank, linkedLevelId }) => ({ id, name, rank, linkedLevelId }))
      }

      switch (ref) {
        case 'role':
          return {
            ...config,
            type: 'discrete',
            range: [20000, 100000],
            values: levels.map(level => {
              const { id, name } = level
              return {
                id,
                name,
                value: getGridConfigComponentValue(components, level)
              }
            })
          }

        case 'experience':
          // Init hasSplitLevels & forceHasSplitLevels
          const hasIntervals = !!isInterval(component)
          let hasSplitLevels = false
          let forceHasSplitLevels = false
          const groupedLevels = groupBy(levels, l => l.linkedLevelId)
          const hasOnlyOneLinkedLevel = Object.keys(groupedLevels).length === 1
          const hasCustomLevels = !Object.keys(groupedLevels).reduce((memo, linkedLevelId) => {
            return memo && groupedLevels[linkedLevelId].length === Object.values(groupedLevels)[0].length
          }, true)
          if (hasOnlyOneLinkedLevel) {
            forceHasSplitLevels = true
            hasSplitLevels = false
          }
          else if (hasCustomLevels) {
            forceHasSplitLevels = true
            hasSplitLevels = true
          }
          else {
            const hasCustomLevelsValues = Object.values(groupedLevels)[0].reduce((memo, level) => {
              return memo || !!levels.find(l => l.rank === level.rank && l.salaryValue !== level.salaryValue)
            }, false)
            if (hasCustomLevelsValues) {
              hasSplitLevels = true
            }
          }

          // Init curves
          let curves
          if (hasSplitLevels) {
            const parentComponent = components.find(c => c.id === component.parentComponentId)
            curves = Object.keys(groupedLevels).reduce((memo, linkedLevelId) => {
              const linkedLevels = groupedLevels[linkedLevelId]
              const parentLevel = parentComponent.levels.find(l => l.id === linkedLevelId)
              memo.push({
                linkedLevelId,
                name: parentLevel.name,
                range: [
                  min(linkedLevels, l => getGridConfigComponentValue(components, l) || 1),
                  max(linkedLevels, l => getGridConfigComponentValue(components, l) || 1.5)
                ],
                curve: guessGridConfigCurveFromLevels(components, linkedLevels)
              })
              return memo
            }, [])
          }
          else {
            curves = [{
              linkedLevelId: null,
              range: [
                min(levels, l => getGridConfigComponentValue(components, l) || 1),
                max(levels, l => getGridConfigComponentValue(components, l) || 1.5)
              ],
              curve: guessGridConfigCurveFromLevels(components, levels)
            }]
          }

          return {
            ...config,
            type: 'fixed',
            hasIntervals,
            hasSplitLevels,
            forceHasSplitLevels,
            availableCurves: ['cubic', 'linear', 'log'],
            curves
          }

        default:
          switch (component.salaryOperation) {
            case 'addition':
              return {
                ...config,
                type: 'linear',
                range: [
                  0,
                  max(levels, l => l.salaryValue) || 20000
                ]
              }

            case 'multiplier':
              return {
                ...config,
                hasAutoLevels: false, // Not available yet
                type: 'fixed',
                values: levels.map(l => ({
                  id: l.id,
                  name: l.name,
                  value: l.salaryValue || 1
                }))
              }
          }
          break
      }
    })
  }
}

const actions = {
  reset(context) {
    context.commit('reset')
  },
  softReset(context) {
    context.dispatch('setGridConfig', null)
    context.commit('resetJobSkills')
    if (!context.getters.getJobs.find(job => job.id === context.getters.getSelectedJob)) {
      context.dispatch('setSelectedJob', null)
    }
  },
  init(context) {
    if (context.rootGetters['account/isAtLeastManager']) {
      const currentGrid = context.rootGetters['currentGrid/getCurrentGrid']
      let onboardingGrid
      let wages = []
      if (currentGrid) {
        context.commit('init', { currentGrid, onboardingGrid, wages })
      }
      else {
        return this.dispatch('sandbox/getOnboardingGrid').then(() => {
          onboardingGrid = context.rootGetters['sandbox/onboardingGrid']
          wages = context.rootGetters['sandbox/employeeWages']
          context.commit('init', { currentGrid, onboardingGrid, wages })
        })
      }
    }
    else {
      return Promise.resolve()
    }
  },
  disable(context) {
    const currentGrid = true
    context.commit('init', { currentGrid })
    return Promise.resolve()
  },
  saveState(context) {
    context.commit('saveState')
  },
  restoreState(context) {
    context.commit('restoreState')
  },
  submitIntro(context) {
    context.commit('setIsIntro', false)
    return Promise.resolve()
  },
  submitComponents(context, selectedComponents) {
    context.commit('setSelectedComponents', selectedComponents)
    const grid = buildGrid(context.getters)
    return api.post('/grid/build', grid)
      .then(({ data }) => {
        this.dispatch('sandbox/updateGrid', data)
        return this.dispatch('sandbox/getWages').then(_ => {
          return context.dispatch('init')
        })
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  submitSkills(context) {
    return new Promise((resolve, reject) => {
      context.commit('increaseProgressIndex', 2)
      resolve()
    })
  },
  submitQualification(context) {
    return new Promise((resolve, reject) => {
      context.commit('increaseProgressIndex', 3)
      resolve()
    })
  },
  submitGenerate(context, { shouldReset }) {
    const gridConfig = context.getters.getGridConfig
    const gridComponents = context.rootGetters['sandbox/allComponents']

    // Generate components
    const components = gridConfig.map(config => {
      const component = gridComponents.find(c => c.id === config.id)
      config = { ...config }

      // Cast values to number
      if (config.values) {
        config.values = config.values.reduce((memo, item) => {
          memo[item.id] = +item.value
          return memo
        }, {})
      }

      // Cast discrete linear values and generate fixed ones
      switch (config.type) {
        case 'discrete':
        case 'linear':
          // Cast max range to number
          config.range[0] = +config.range[0]
          config.range[1] = +config.range[1]
          break
        case 'fixed':
          // Generate curve values
          if (config.curves) {
            if (!config.hasAutoLevels) {
              config.values = config.curves.reduce((memo, curve) => {
                const levels = component.levels
                  .filter(l =>
                    !curve.linkedLevelId ||
                    l.linkedLevelId === curve.linkedLevelId
                  )
                const count = max(levels, l => l.rank) + 1
                const values = generateCurveValues(count, curve.curve, curve.range[0], curve.range[1])
                levels.forEach(level => {
                  memo[level.id] = values[level.rank]
                })
                return memo
              }, {})
            }
            else {
              delete config.values
            }
          }

          // Set interval toggle in config
          if (config.hasIntervals) {
            config.config.interval = true
          }
          else {
            delete config.config.interval
          }
          break
      }

      // Clean config
      delete config.forceHasSplitLevels
      delete config.curves
      delete config.availableCurves

      return config
    })

    // Generate employees
    const employees = context.rootGetters['sandbox/enrichedEmployees']().reduce((memo, employee) => {
      const levels = gridComponents.reduce((memo, component) => {
        const selectedComponent = employee.postGridWageDetails.selectedComponents.find(c => c.id === component.id)
        if (selectedComponent) {
          memo[component.id] = selectedComponent.selectedLevel.id
        }
        else if (component.levels.length) {
          // Find appropriate default level
          if (component.levels[0].linkedLevelId) {
            const defaultLevel = component.levels.find(l => Object.values(memo).includes(l.linkedLevelId))
            memo[component.id] = defaultLevel ? defaultLevel.id : component.levels[0].id
          }
          else {
            memo[component.id] = component.levels[0].id
          }
        }
        return memo
      }, {})

      memo.push({
        name: getField(employee, 'slug'),
        salary: employee.currentSalary || 0,
        wage: { id: employee.sandboxWage.id },
        ...levels
      })
      return memo
    }, [])

    const solverParams = {
      components: components,
      employees: employees,
      params: {
        reset: shouldReset
      }
    }

    const gridId = context.rootGetters['sandbox/grid'].id
    return api.put(`/grid/${gridId}/solve`, solverParams)
      .then(({ data }) => {
        this.dispatch('sandbox/updateGrid', data.grid)
        this.dispatch('sandbox/updateWages', { employees: data.wages })
        context.commit('setIsGridGenerated', true)
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  submitPublish(context) {
    return this.dispatch('sandbox/publish')
  },
  setSelectedJob(context, job) {
    context.commit('setSelectedJob', job)
  },
  loadSkillsTemplates(context) {
    return api.get('/skill/templates')
      .then(({ data }) => {
        return data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  loadSkillTemplate(context, { job, template }) {
    const component = job[0] === '_' ? 'seniority' : 'experience'
    return api.get(`/skill/template/${component}/${template}`)
      .then(({ data }) => data)
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  setJobSkills(context, { job, template, copy }) {
    let promise
    let componentId
    let linkedLevelId
    const components = context.rootGetters['sandbox/allComponents']
    const roleComponent = components.find(c => c.ref === 'role')
    if (job[0] === '_') {
      componentId = components.find(c => c.id === job.substr(1)).id
    }
    else {
      const experienceComponent = components.find(c => c.ref === 'experience')
      const level = roleComponent.levels.find(level => level.name === job)
      componentId = experienceComponent.id
      linkedLevelId = level.id
    }

    if (template && template !== '_copy') {
      const ref = template
      const body = { ref, linkedLevelId }
      promise = api.put(`/component/${componentId}/skills/template`, body)
        .catch(error => context.dispatch('handleAPIError', error, { root: true }))
    }
    else if (template === '_copy' && copy) {
      const referenceLinkedLevel = roleComponent.levels.find(level => level.name === copy)
      const referenceLinkedLevelId = referenceLinkedLevel.id
      const body = { linkedLevelId, referenceLinkedLevelId }
      promise = api.put(`/component/${componentId}/skills/clone`, body)
        .catch(error => context.dispatch('handleAPIError', error, { root: true }))
    }
    else {
      const params = linkedLevelId ? `?linked_level_id=${linkedLevelId}` : ''
      promise = api.delete(`/component/${componentId}/skills${params}`)
        .catch(error => context.dispatch('handleAPIError', error, { root: true }))
    }
    return promise
      .then(({ data }) => {
        this.dispatch('sandbox/setComponent', data)
        const jobSkills = context.getters.getDefaultJobSkills(job)
        const disabled = false
        context.commit('setJobSkills', { job, jobSkills, disabled })
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  addJobSkill(context, { job, levelId, domainId, name }) {
    const jobSkills = context.getters.getJobSkills(job)
    const skills = findSkills(jobSkills, levelId, domainId)
    const skill = {
      domainId: domainId,
      index: skills.length,
      name: name || ''
    }
    return this.dispatch('sandbox/createSkill', { levelId, skill })
      .then(skill => {
        context.commit('addJobSkill', { job, jobSkills, levelId, domainId, skill })
        return skill
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  addJobSkills(context, { job, levelId, domainId, names }) {
    const actions = names.map(name => {
      return ['addJobSkill', { job, levelId, domainId, name }]
    })
    return sequentialDispatch(context, actions)
  },
  deleteJobSkill(context, { job, levelId, domainId, skillId }) {
    const jobSkills = context.getters.getJobSkills(job)
    const skills = findSkills(jobSkills, levelId, domainId)
    // Do not delete last skill to preserve focus
    if (skills.length > 1) {
      context.commit('deleteJobSkill', { job, jobSkills, levelId, domainId, skillId })
      context.dispatch('reindexJobSkills', { job, jobSkills, levelId, domainId })
      return this.dispatch('sandbox/removeSkill', { levelId, skillId })
    }
    // Empty the last skill
    else if (skills.length && skills[0].name) {
      const name = null
      context.commit('updateJobSkill', { job, jobSkills, levelId, domainId, skillId, name })
      return context.dispatch('updateJobSkill', { job, levelId, domainId, skillId, name })
    }
    else {
      return Promise.resolve()
    }
  },
  updateJobSkill: debouncedAction((context, { job, levelId, domainId, skillId, name }) => {
    const jobSkills = context.getters.getJobSkills(job)
    let skill = findSkill(jobSkills, levelId, domainId, skillId)
    if (skill) {
      context.commit('updateJobSkill', { job, jobSkills, levelId, domainId, skillId, name })
      skill = findSkill(jobSkills, levelId, domainId, skillId)
      return context.dispatch('sandbox/updateSkill', skill, { root: true })
    }
    else {
      // Skill was deleted, nevermind (can happen with debounce)
      return Promise.resolve()
    }
  }, null, 'skillId'),
  reindexJobSkills(context, { job, levelId, domainId }) {
    const jobSkills = context.getters.getJobSkills(job)
    const skills = findSkills(jobSkills, levelId, domainId)
    const updatedSkills = []
    context.commit('reindexJobSkills', { skills, updatedSkills })
    updatedSkills.forEach((skill) => {
      const skillId = skill.id
      const name = skill.name
      context.dispatch('updateJobSkill', { job, levelId, domainId, skillId, name })
    })
  },
  resetEmployeeWage(context, employee) {
    const wage = { ...context.rootGetters['sandbox/employeeWage'](employee.id) }
    const updatedWage = { ...wage, skillIds: [], levelIds: [] }

    employee.wageDetails.selectedComponents.forEach(component => {
      if (!['experience', 'seniority'].includes(component.ref)) {
        updatedWage.levelIds.push(component.selectedLevel.id)
      }
    })

    return this.dispatch('sandbox/updateSandboxWage', updatedWage)
  },
  setGridConfig(context, gridConfig) {
    context.commit('setGridConfig', gridConfig)
  }
}

const mutations = {
  reset(state) {
    Object.assign(state, initialState())
  },
  init(state, { currentGrid, onboardingGrid, wages }) {
    const hasOnboardingGrid = !!onboardingGrid

    if ((!currentGrid || state.isActive) && onboardingGrid) {
      state.isActive = true
      state.isIntro = false
      state.isGridGenerated = false

      // Init Intro
      state.selectedComponents = onboardingGrid.components.map(c => c.ref)
      state.progressIndex = 1

      // Init Skills
      const isSkillsComplete = gridHasSkills(onboardingGrid)
      state.selectedJob = null
      state.jobSkills = {}
      if (isSkillsComplete) {
        state.progressIndex = 2
      }

      // Init Qualifications
      const isQualificationComplete = wages.reduce((memo, wage) => {
        memo = memo && isSandboxWageValid(wage, onboardingGrid)
        return memo
      }, true)

      if (isQualificationComplete) {
        state.progressIndex = 3
      }

      // Init Generate
      const isGridGenerated = isSandboxGridGenerated(onboardingGrid)
      state.gridConfig = null

      if (isGridGenerated) {
        state.progressIndex = 3
        state.isGridGenerated = true
      }

      trackEvent('InitOnboarding', {
        hasOnboardingGrid,
        isSkillsComplete,
        isQualificationComplete,
        isGridGenerated,
        progressIndex: state.progressIndex
      })
    }
    else if (!currentGrid) {
      // Init Intro
      state.isActive = true
      state.isIntro = true
      state.isGridGenerated = false
      state.selectedComponents = ['role']

      state.progressIndex = 0

      trackEvent('InitOnboarding', {
        hasOnboardingGrid,
        progressIndex: state.progressIndex
      })
    }
    else {
      state.isActive = false
    }
  },
  saveState(state) {
    setItem(CW_ONBOARDING_STATE, state)
  },
  restoreState(state) {
    const savedState = getItem(CW_ONBOARDING_STATE, {})
    Object.assign(state, savedState)
  },
  setIsIntro(state, value) {
    state.isIntro = value
  },
  setIsActive(state, value) {
    state.isActive = value
  },
  setIsGridGenerated(state, value) {
    state.isGridGenerated = value
  },
  increaseProgressIndex(state, progressIndex) {
    if (progressIndex > state.progressIndex) {
      state.progressIndex = progressIndex
      trackEvent('SetProgressIndex', { progressIndex })
    }
  },
  setProgressIndex(state, progressIndex) {
    state.progressIndex = progressIndex
    trackEvent('SetProgressIndex', { progressIndex })
  },
  setSelectedComponents(state, components) {
    state.selectedComponents = components
  },
  setSelectedJob(state, job) {
    state.selectedJob = job
  },
  setJobSkills(state, { job, jobSkills, disabled }) {
    jobSkills.disabled = disabled
    Vue.set(state.jobSkills, job, jobSkills)
  },
  resetJobSkills(state) {
    state.jobSkills = {}
  },
  addJobSkill(state, { job, jobSkills, levelId, domainId, skill }) {
    const skills = jobSkills.levels
      .find(l => l.id === levelId)
      .domains.find(d => d.id === domainId)
      .skills
    skills.push(skill)
    Vue.set(state.jobSkills, job, jobSkills)
  },
  deleteJobSkill(state, { job, jobSkills, levelId, domainId, skillId }) {
    const skills = jobSkills.levels
      .find(l => l.id === levelId)
      .domains.find(d => d.id === domainId)
      .skills
    const skillIndex = skills.findIndex(s => s.id === skillId)
    if (skillIndex !== -1) {
      skills.splice(skillIndex, 1)
      Vue.set(state.jobSkills, job, jobSkills)
    }
  },
  updateJobSkill(state, { job, jobSkills, levelId, domainId, skillId, name }) {
    const skill = findSkill(jobSkills, levelId, domainId, skillId)
    skill.name = name
  },
  reindexJobSkills(state, { skills, updatedSkills }) {
    skills.forEach((skill, index) => {
      if (skill.index !== index) {
        skill.index = index
        updatedSkills.push(skill)
      }
    })
  },
  setGridConfig(state, gridConfig) {
    state.gridConfig = cloneDeepWith(gridConfig)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
