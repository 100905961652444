<template>
  <div class="visitor-container password-reset-request">
    <div class="center">
      <img
        class="visitor-logo"
        src="~@/assets/logo.svg"
        alt="Clearwage"
        @click="$router.push('/')">
    </div>
    <div class="visitor-form">
      <header
        class="visitor-form-header"
        v-html="headerLabel" />
      <div class="visitor-form-content">
        <form v-if="!requestSent" @submit.prevent="requestPasswordReset()">
          <div class="input-label" v-t="'account.email'"></div>
          <input
            type="text"
            v-model="email"
            :placeholder="$t('account.emailPlaceholder')"
            :class="{'input-error' : $v.email.$error}">
          <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>
          <button class="primary" :disabled="isLoading">{{ requestButtonText }}</button>
        </form>
        <div v-else class="left">
          <p class="no-margin-top" v-t="'account.passwordReset.firstInstruction'"></p>
        </div>
        <div class="visitor-outro">
          <ol>
            <li v-if="!requestSent" v-t="'account.passwordReset.firstInstruction'"></li>
            <li v-t="'account.passwordReset.secondInstruction'"></li>
            <li v-t="'account.passwordReset.thirdInstruction'"></li>
          </ol>
        </div>
        <p class="visitor-outro no-margin-bottom" v-if="requestSent" v-t="'account.passwordReset.closeTab'"></p>
      </div>
    </div>
  </div>
</template>
<script>
import { required, email } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      email: '',
      isLoading: false,
      errorMessage: null,
      requestSent: false
    }
  },
  mounted() {
    this.email = this.$route.params.email || this.$route.query.email
    if (this.$route.params.apply) {
      this.requestPasswordReset()
    }
  },
  methods: {
    requestPasswordReset() {
      this.$v.$touch()

      if (!this.$v.$error) {
        const email = this.email
        this.errorMessage = null
        this.isLoading = true

        this.$store.dispatch('account/requestPasswordReset', { email })
          .then(response => {
            this.isLoading = false
            this.requestSent = true
          })
          .catch(error => {
            this.isLoading = false
            this.errorMessage = error
          })
      }
    }
  },
  computed: {
    headerLabel() {
      if (this.requestSent) {
        return this.$t('account.passwordReset.allGood')
      }
      else {
        return this.$t('account.passwordReset.pleaseEnterEmail')
      }
    },
    requestButtonText() {
      return this.$t(this.isLoading ? 'account.passwordReset.requesting' : 'account.passwordReset.resetAction')
    }
  },
  validations: {
    email: {
      required,
      email
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/visitor.scss";
</style>
