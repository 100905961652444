<template>
  <div class="component-container">
    <div class="component">
      <table class="blue" :class="{'no-bg': hasLevelGroups}">
        <tr class="row">
          <th
            class="name colored-link"
            :class="arrowClassName('rank')"
            @click="sortBy('rank')">
            {{ component.name }}
          </th>
          <th
            class="colored-link operation-name"
            :class="arrowClassName(isInterval ? 'experienceValue' : 'salaryValue')"
            @click="sortBy(isInterval ? 'experienceValue' : 'salaryValue')">
            {{operationLabel}}
          </th>
          <th
            class="colored-link level-evolution"
            :class="arrowClassName('experienceValue')"
            @click="sortBy('experienceValue')"
            v-if="experienceComponent && !isInterval">
            {{experienceComponent.name}}
          </th>
        </tr>
        <template v-for="(level, i) in enrichedLevels">
          <tr
            v-if="hasLevelGroups && level.group && (i === 0 || enrichedLevels[i - 1].group !== level.group)"
            :key="[level.id, 'group'].join()"
            class="row group">
            <td
              class="level-name"
              :colspan="2 + (experienceComponent && !isInterval ? 1 : 0)">{{level.group}}</td>
          </tr>
          <tr
            :key="level.id"
            @click="select(component, level.similarLevels)"
            class="row"
            :class="{
            group: level.isGroup,
            clickable: isClickable,
            hidden: level.isHidden
          }">
            <td class="level-name">
              <div>{{ level.name }}</div>
            </td>
            <td class="level-value" :style="level.style">
              {{ getLevelValueLabel(level) }}
            </td>
            <td class="level-evolution level-evolution-graph" v-if="experienceComponent && !isInterval">
              <mini-evolution-graph :value="getExperienceLevelValues(level)"></mini-evolution-graph>
              <div class="level-evolution-graph-label">{{ {level: {salaryValue: level.experienceValue}, component: experienceComponent} | formattedRemuneration('salary') }}</div>
            </td>
          </tr>
        </template>
      </table>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-irregular-whitespace */
import { getVisibleLevels, getVisibleLinkedLevels, isInterval, remunerationTypesForComponent } from '@/utils/grid'
import MiniEvolutionGraph from '@components/graph/MiniEvolutionGraph.vue'
import { min, max } from 'd3'
import sortBy from 'lodash.sortby'
import groupBy from 'lodash.groupby'

export default {
  name: 'grid-component',
  props: [
    'component'
  ],
  data() {
    return {
      sortKey: 'rank',
      sortOrder: 1
    }
  },
  computed: {
    experienceComponent() {
      const { linkedComponents } = this.component
      return linkedComponents && linkedComponents.find(c => c.ref === 'experience')
    },
    groupedLevels() {
      const levels = getVisibleLevels(this.component && this.component.levels)
      const groupedLevels = Object.values(groupBy(levels, l => l.rank))
        .map(levels => {
          let experienceValue
          if (this.experienceComponent) {
            const experienceValues = this.getExperienceLevelValues(levels[0])
            experienceValue = experienceValues[experienceValues.length - 1]
          }
          return Object.assign({
            similarLevels: levels,
            experienceValue
          }, levels[0])
        })
      return groupedLevels
    },
    sortedLevels() {
      return this.groupedLevels.slice().sort((a, b) => {
        a = a[this.sortKey]
        b = b[this.sortKey]
        return (a === b ? 0 : (a > b ? 1 : -1)) * this.sortOrder
      })
    },
    enrichedLevels() {
      const isMultiplierOperation = this.component.salaryOperation === 'multiplier'
      const refKey = this.isInterval ? 'experienceValue' : 'salaryValue'
      const minSalaryValue = min(this.sortedLevels, l => l[refKey])
      const maxSalaryValue = max(this.sortedLevels, l => l[refKey])
      return this.sortedLevels.map(level => {
        let percent = 0

        if (maxSalaryValue > 0) {
          if (isMultiplierOperation) {
            percent = Math.floor((level[refKey] - minSalaryValue) / (maxSalaryValue - minSalaryValue) * 95) + 5
          }
          else {
            percent = Math.floor(level[refKey] / maxSalaryValue * 100)
          }
        }

        return Object.assign({
          style: {
            background: `linear-gradient(\
              90deg,\
              #ECF0F3 ${percent}%, \
              rgba(255,255,255,0.5) ${percent}%, \
              rgba(255,255,255,0.5) 100%)`
          }
        }, level)
      })
    },
    operationLabel() {
      return this.component.ref === 'role'
        ? (!this.isInterval ? this.$t('grid.editor.base') : this.$t('grid.editor.interval'))
        : (this.component.salaryOperation === 'multiplier' ? this.$t('grid.editor.coef') : this.$t('grid.editor.amount'))
    },
    remunerationTypes() {
      return remunerationTypesForComponent(this.component)
    },
    arrowClassName() {
      return (key) => this.sortKey === key ? 'arrow-' + this.sortOrder : ''
    },
    isClickable() {
      return !!this.$listeners.select
    },
    isInterval() {
      return isInterval(this.component)
    },
    hasLevelGroups() {
      return !!this.enrichedLevels.find(({ group }) => group) && this.sortKey === 'rank'
    }
  },
  methods: {
    sortBy(key) {
      if (this.sortKey !== key) {
        this.sortOrder = 1
      }
      else {
        this.sortOrder *= -1
      }
      this.sortKey = key
    },
    getExperienceLevelValues(linkedLevel) {
      const levels = getVisibleLinkedLevels(this.experienceComponent && this.experienceComponent.levels, linkedLevel)
      if (levels.length) {
        return sortBy(levels, 'rank').map(l => l.salaryValue || 0)
      }
    },
    getLevelValueLabel(linkedLevel) {
      if (this.experienceComponent && this.isInterval) {
        const levels = getVisibleLinkedLevels(this.experienceComponent && this.experienceComponent.levels, linkedLevel)
        if (levels.length) {
          const minimumValue = Math.round((levels[0].minimumValue || 0) / 1000)
          const maximumValue = Math.round((levels[levels.length - 1].maximumValue || 0) / 1000)
          return `${minimumValue} – ${maximumValue} k${this.$i18n.currencySymbol}`
        }
      }
      else {
        return this.$options.filters.formattedLevelValue(this.component, linkedLevel, null, null, 'grid')
      }
    },
    select(component, levels) {
      this.$emit('select', component, levels)
    }
  },
  components: {
    MiniEvolutionGraph
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/link.scss";
@import "./src/styles/table.scss";

.component-inline {
  display: inline-block;
}

.component {
  display: inline-block;
  vertical-align: top;
  margin: 0 0.5em;
  border: 1px solid $graph-outer-border-color;
  border-radius: $border-radius;
  box-shadow: 1px 1.5px 0 rgba(0, 0, 0, 0.03);
}

table {
  white-space: nowrap;
  @include font-small-size;

  th,
  td {
    line-height: 2.25em;
    padding: 0 0.5em;
    border: 1px solid $graph-outer-border-color;
  }

  th {
    @include font-medium;
    font-weight: 450;
    max-width: 125px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.operation-name {
  text-align: center;
}

.name {
  text-align: left;
}

.row.hidden {
  color: $light-text-color;

  .level-name div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &::after {
      content: " ";
      display: inline-block;
      margin-left: 5px;
      width: 20px;
      height: 20px;
      background: transparent url(~@/assets/icon-hide.svg) no-repeat center
        center;
      opacity: 0.6;
    }
  }
}

.row.group {
  background-color: lighten($graph-lightblue-color, 1) !important;

  .level-name {
    @include font-smaller-size;
    font-weight: 550;
    text-transform: uppercase;
  }
}

.level-name div {
  text-align: left;
  padding-right: 0.25em;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.level-value {
  padding-left: 0.5em;
  text-align: right;
  @include font-tabular-numbers;
}

.level-evolution {
  text-align: center;
  @include font-tabular-numbers;
}

.level-evolution-graph {
  padding: 0;
  background: rgba(#ffffff, 0.5);
  max-width: 90px;
}

.level-evolution-graph-label {
  margin-top: -45px;
}
</style>
