<template>
  <div>
    <h3>
      <router-link
        tag="a"
        class="colored-link"
        :to="{name: 'settingsCompanyIntegrations'}"
        :active-class="'selected'">
        {{$t('settings.settingsCompanyIntegrations.title')}}
      </router-link>
      &nbsp;&nbsp;›&nbsp;&nbsp;
      {{$t('settings.settingsCompanyIntegrations.lucca.title')}}
    </h3>
    <p class="no-margin-top" v-html="$t('settings.settingsCompanyIntegrations.lucca.intro')"></p>
    <div class="form-section">
      <checkbox v-model="settingsModel.active">
        {{$t('settings.settingsCompanyIntegrations.lucca.toggle')}}
      </checkbox>
    </div>
    <div>
      <form @submit.prevent="update">
        <div class="settings-block">
          <header><strong v-t="'settings.settingsCompanyIntegrations.rules.title'"></strong></header>
          <div class="form-section">
            <checkbox v-model="settingsModel.auto">
              {{ $t('settings.settingsCompanyIntegrations.rules.synchronization.auto') }}
            </checkbox>
          </div>
          <div class="form-section">
            <div class="input-label" v-t="'settings.settingsCompanyIntegrations.rules.user.title'"></div>
            <p class="preserve-lines" v-t="'settings.settingsCompanyIntegrations.lucca.rules.user.body'"></p>
          </div>
          <div class="form-section">
            <div class="input-label" v-t="'settings.settingsCompanyIntegrations.rules.wage.title'"></div>
            <p class="preserve-lines" v-t="'settings.settingsCompanyIntegrations.lucca.rules.wage.body'"></p>
          </div>
          <p v-html="$t('settings.settingsCompanyIntegrations.rules.customize')"></p>
        </div>
        <div class="settings-block">
          <header><strong v-t="'settings.settingsCompanyIntegrations.apiAccess'"></strong></header>
          <p class="preserve-lines" v-html="$t('settings.settingsCompanyIntegrations.lucca.apiAccessIntro')"></p>
          <div class="form-section">
            <div class="input-label" v-t="'settings.settingsCompanyIntegrations.lucca.apiKey'"></div>
            <input
              type="password"
              v-model="settingsModel.apiKey"
              placeholder="Ex : ea3a8387-cf2f-45da-a169-26b45db9a1dd">
          </div>
          <div class="form-section">
            <div class="input-label" v-t="'settings.settingsCompanyIntegrations.lucca.domain'"></div>
            <input
              type="text"
              v-model="settingsModel.domain"
              placeholder="Ex : mon-entreprise">
          </div>
          <div class="form-section">
            <div class="input-label">
              <span v-t="'settings.settingsCompanyIntegrations.lucca.legalEntityName'"></span>
              <span class="light-text" v-t="'common.optional'"></span>
            </div>
            <multiselect
              :value="legalEntitiesModel"
              :options="legalEntities"
              trackBy="id"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              :multiple="true"
              :closeOnSelect="false"
              :showNoResults="false"
              :placeholder="$t('employees.import.select.placeholder')"
              :custom-label="legalEntity => legalEntity.name"
              @input="updateLegalEntities" />
          </div>
          <div class="form-section" v-if="!settingsModel.popleeActive">
            <div class="input-label">
              <span v-t="'settings.settingsCompanyIntegrations.lucca.extendedDataSalaryId'"></span>
              <span class="light-text" v-t="'common.optional'"></span>
            </div>
            <multiselect
              v-model="settingsModel.extendedDataSalaryId"
              :options="userProperties"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              :showNoResults="false"
              placeholder="Ex : e_Salaire-Fixe" />
          </div>
        </div>
        <div>
          <resizable-panel :observer="[successMessage, errorMessage]">
            <p v-if="successMessage" class="alert preserve-lines" v-html="successMessage"></p>
            <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
          </resizable-panel>
          <loading-button
            class="primary settings-button"
            :loading="isLoading">
            {{$t('settings.settingsCompanyIntegrations.applyChanges')}}
          </loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Checkbox from '@components/commons/Checkbox'
import ResizablePanel from '@components/commons/ResizablePanel.vue'

export default {
  components: { Checkbox, ResizablePanel },
  data() {
    return {
      settingsModel: {},
      errorMessage: null,
      successMessage: null,
      toggleSuccessMessage: null,
      isLoading: false,
      properties: {}
    }
  },
  computed: {
    ...mapGetters({
      company: 'account/getCompany'
    }),
    legalEntities() {
      return this.properties.legalEntities || []
    },
    legalEntitiesModel() {
      if (this.settingsModel.legalEntityName) {
        return this.settingsModel.legalEntityName.split(',').map(name => ({
          id: name,
          name: name
        }))
      }
    },
    userProperties() {
      return (this.properties.userProperties || [])
        .filter(({ type }) => type === 2)
        .map(({ name }) => name)
    }
  },
  methods: {
    initModel() {
      this.settingsModel = { ...this.company.luccaSettings }
      this.loadProperties()
    },
    async loadProperties() {
      if (this.settingsModel.active) {
        this.properties = await this.$store.dispatch('company/getLuccaProperties')
      }
    },
    updateLegalEntities(value) {
      this.settingsModel.legalEntityName = value.map(({ name }) => name).join(',')
    },
    update() {
      this.errorMessage = null
      this.successMessage = null
      this.toggleSuccessMessage = null
      this.isLoading = true

      this.$store.dispatch('company/updateLuccaSettings', this.settingsModel)
        .then(({ luccaSettings }) => {
          this.isLoading = false
          this.successMessage = this.$t('settings.settingsCompanyIntegrations.lucca.success' + (luccaSettings.active ? 'Active' : 'Inactive'))
        })
        .catch(error => {
          this.isLoading = false
          this.errorMessage = error
        })
    }
  },
  watch: {
    company: 'initModel'
  },
  created() {
    this.initModel()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/settings.scss";
</style>
