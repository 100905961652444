<template>
  <div class="onboarding-variable-wage" :class="{block: hasGoals}">
    <div class="variable-wage-title-container">
      <div class="variable-wage-title">{{variablePlan.name}}</div>
      <div class="variable-wage-subtitle" v-html="formatSmartPeriod(variablePlan)"></div>
      <span class="variable-wage-bonus-value badge border-badge">
        {{ bonusValue | formattedCurrency }}
      </span>
    </div>
    <div class="variable-wage-goal-viewer" v-if="enrichedConfig.goals && enrichedConfig.goals.length">
      <GoalsEditor
        :variablePlan="variablePlan"
        :enrichedConfig="enrichedConfig"
        :indicators='indicators'
        :header="false"
        :employee="employee"
        v-model="enrichedConfig.goals"
        :disabled="true" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import flattenDeep from 'lodash.flattendeep'
import { enrichDataFeeds } from '@/utils/dataFeed'
import { enrichConfig, evaluateIndicators, formatSmartPeriod } from '@/utils/variable'

import cloneDeepWith from 'lodash.clonedeepwith'
import GoalsEditor from '@components/variable/GoalsEditor.vue'

export default {
  components: {
    GoalsEditor
  },
  props: {
    variableWage: {
      type: Object,
      default() {
        return {}
      }
    },
    variableWages: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    ...mapGetters({
      getEmployees: 'variablePlans/getEmployees',
      getEvaluationOptions: 'variablePlans/getEvaluationOptions'
    }),
    hasGoals() {
      return this.variableWages.find(v => v.variablePlan.config && v.variablePlan.config.goals && v.variablePlan.config.goals.length)
    },
    variablePlan() {
      const variableWages = [this.variableWage]
      return { ...this.variableWage.variablePlan, variableWages }
    },
    indicators() {
      const { sharedIndicators } = this.variablePlan
      const { indicators } = this.variableWage
      return flattenDeep([indicators, sharedIndicators])
    },
    employee() {
      const employees = this.getEmployees(this.variablePlan)
      return employees[0]
    },
    enrichedConfig() {
      const employees = this.getEmployees(this.variablePlan)
      const { config, dataFeeds } = this.variablePlan
      const enrichedConfig = cloneDeepWith(config)
      enrichConfig(config, enrichedConfig, {})
      enrichedConfig.dataFeeds = enrichDataFeeds(dataFeeds, this.variablePlan, employees)
      return enrichedConfig
    },
    evaluationOptions() {
      return this.getEvaluationOptions(this.variablePlan, null, this.employee)
    },
    bonusValue() {
      const sharedIndicators = []
      const { enrichedConfig, indicators, evaluationOptions } = this
      const id = this.variableWage.id
      const bonusValue = evaluateIndicators(enrichedConfig, sharedIndicators, indicators, evaluationOptions)
      this.$emit('bonus', { id, bonusValue })
      return bonusValue
    }
  },
  methods: {
    formatSmartPeriod
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/badge.scss";

.onboarding-variable-wage {
  @extend .badge-block, .clickable;
  padding: 0.5em 0.75em;
  margin-right: 1em;

  &.block {
    margin-right: 0;
    display: block;
  }

  &:not(:last-of-type) {
    margin-bottom: 1em;
  }

  &::v-deep .arrow {
    @include font-normal-size;
    color: $light-text-color;
    vertical-align: 1px;
  }

  .variable-wage-title-container {
    display: grid;
    grid-template-columns: 1fr auto auto;
    max-width: 100%;
    gap: 1em;
    align-items: center;

    .variable-wage-title {
      @include font-large-size;
      @include font-semibold;
    }

    .variable-wage-bonus-value {
      margin: -2px 0;
    }
  }

  .variable-wage-goal-viewer {
    margin-top: 0.5em;
  }
}
</style>
