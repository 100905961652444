<template>
  <div class="grid-preview-button">
    <button
      @click="openGridPreviewModal"
      class="secondary"
      v-t="'grid.editor.preview.action'"></button>
    <modal :visible="isGridPreviewVisible" @close="closeGridPreviewModal">
      <grid-preview :grid="grid" :selectedLevelId="selectedLevelId" />
    </modal>
  </div>
</template>

<script>
import Modal from '@components/commons/Modal'
import GridPreview from '@components/grid/viewer/GridPreview'

export default {
  components: {
    Modal,
    GridPreview
  },
  props: {
    grid: Object,
    selectedLevelId: String
  },
  data() {
    return {
      isGridPreviewVisible: false
    }
  },
  methods: {
    openGridPreviewModal(event) {
      if (!event.altKey) {
        this.isGridPreviewVisible = true
      }
      else {
        this.$router.push({
          name: 'sandboxEdit',
          params: { id: this.grid.id }
        })
      }
    },
    closeGridPreviewModal() {
      this.isGridPreviewVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";

.grid-preview-button {
  display: inline-block;
}
</style>
