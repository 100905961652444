<template>
  <div>
    <div class="container" v-if="isVariablePlanView && variablePlanModel">
      <menu class="float-right no-margin-top no-margin-bottom">
        <button
          v-if="isCreator"
          class="destructive"
          v-t="'common.remove'"
          @click="removeVariablePlan">
        </button>
        <button
          class="secondary"
          v-t="'common.back'"
          @click="$router.push({name: 'variablePlans', params: {highlightId: $route.params.highlightId, highlightClass: $route.params.highlightClass}})">
        </button>
        <template v-if="isCreator">
          <button
            v-if="variablePlanModel.status === 'draft'"
            class="primary"
            v-t="'variable.form.publish'"
            @click="setStatus('ongoing')">
          </button>
          <button
            v-if="variablePlanModel.status === 'ongoing'"
            class="primary"
            v-t="'variable.form.close'"
            @click="setStatus('closed')">
          </button>
          <button
            v-if="variablePlanModel.status === 'closed' && isAddNextPeriodAvailable"
            class="primary zoom-in"
            v-t="'variable.form.addNextPeriod'"
            @click="$router.push({name: 'addVariablePlanPeriod', params: {id: variablePlanModel.id}})">
          </button>
        </template>
      </menu>
      <h1>
        <InlineEditor
          v-model="variablePlanModel.name"
          :placeholder="storedVariablePlan.name"
          :disabled="!isCreator"
          @submit="submit()" />
      </h1>
      <MarkdownTextEditor
        class="light-text blue"
        v-model="variablePlanModel.description"
        :placeholder="storedVariablePlan.description"
        :showInlineEditButton="true"
        :showAddButton="true"
        :autoDismiss="true"
        :disabled="!isCreator"
        @submit="submit()" />
      <div class="alert" v-if="!isCreator">
        {{$t('variable.form.creatorNotice', {firstName: storedVariablePlan.creator.firstName, lastName: storedVariablePlan.creator.lastName})}}
      </div>
      <VariablePlanPeriod
        :showStatus="isCreator"
        @status="setStatus"
        v-model="variablePlanModel"
        @input="submit" />
      <p class="preserve-lines" v-html="$t(`variable.statuses.${variablePlanModel.status}.intro`)"></p>
      <VariablePlanEditor
        ref="variablePlanEditor"
        v-model="variablePlanModel"
        @enriched="onEnrichedConfig"
        @templates="openTemplates"
        @input="submit">
        <button
          v-if="variablePlanModel.status === 'draft'"
          class="small-button blue light"
          @click="openTemplates"
          v-t="'variable.templates.examples'" />
      </VariablePlanEditor>
      <VariablePlanEmployees
        ref="variablePlanEmployees"
        :enrichedConfig="enrichedConfig"
        :variablePlan="variablePlanModel"
        @sharedIndicators="onSharedIndicators"
        @computedCents="onComputedCents"
        v-model="variablePlanModel.variableWages" />
      <ResizablePanel :observer="updatedAt">
        <p class="light-text no-margin-bottom" v-if="updatedAt">
          {{$t('variable.form.autoSave')}}
          <span v-if="updatedAt" v-html="$t('grid.onboarding.common.updatedAt', {updatedAt})"></span>
        </p>
      </ResizablePanel>
      <modal :visible="isTemplatesVisible" @close="isTemplatesVisible = false">
        <VariablePlanTemplates :kind="variablePlanModel.kind">
          <template v-slot="slotProps">
            <button class="small-button blue light" @click="applyTemplate(slotProps.variablePlan)" v-t="'variable.templates.action'" />
          </template>
        </VariablePlanTemplates>
      </modal>
    </div>
    <router-view v-else-if="!isVariablePlanView"></router-view>
    <LoadingView v-else />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { setAppTitle } from '@/utils/route'
import InlineEditor from '@components/commons/InlineEditor'
import LoadingView from '@components/commons/LoadingView.vue'
import MarkdownTextEditor from '@components/commons/MarkdownTextEditor'
import Modal from '@components/commons/Modal'
import VariablePlanEditor from '@components/variable/VariablePlanEditor.vue'
import VariablePlanEmployees from '@components/variable/VariablePlanEmployees.vue'
import VariablePlanPeriod from '@components/variable/VariablePlanPeriod.vue'
import VariablePlanTemplates from '@components/variable/VariablePlanTemplates.vue'
import ResizablePanel from '@components/commons/ResizablePanel'

export default {
  components: {
    InlineEditor,
    LoadingView,
    MarkdownTextEditor,
    Modal,
    ResizablePanel,
    VariablePlanEditor,
    VariablePlanEmployees,
    VariablePlanPeriod,
    VariablePlanTemplates
  },
  data() {
    return {
      enrichedConfig: null,
      isLoading: false,
      isTemplatesVisible: false,
      variablePlanModel: null,
      updatedAt: null
    }
  },
  computed: {
    ...mapGetters({
      getVariablePlan: 'variablePlans/getVariablePlan',
      getGroupedVariablePlans: 'variablePlans/getGroupedVariablePlans'
    }),
    isVariablePlanView() {
      return this.$route.name === 'variablePlan'
    },
    storedVariablePlan() {
      return this.getVariablePlan(this.$route.params.id)
    },
    isCreator() {
      return this.$$isCreator(this.storedVariablePlan)
    },
    isAddNextPeriodAvailable() {
      // Test if variable plan is the latest one in group
      return !!this.getGroupedVariablePlans.find(v => v.id === this.variablePlanModel.id)
    }
  },
  methods: {
    initModel() {
      if (this.storedVariablePlan) {
        this.variablePlanModel = { ...this.storedVariablePlan }
        this.$store.commit('variablePlans/setCurrentVariablePlan', this.storedVariablePlan)
      }
    },
    onEnrichedConfig(enrichedConfig) {
      this.enrichedConfig = enrichedConfig
    },
    onSharedIndicators(sharedIndicators) {
      this.variablePlanModel.sharedIndicators = sharedIndicators
      this.submit()
    },
    onComputedCents(computedCents) {
      this.variablePlanModel.computedCents = computedCents
    },
    setStatus(status) {
      if ((this.variablePlanModel.status !== status) && window.confirm(this.$t(`variable.statuses.${status}.confirm`))) {
        this.variablePlanModel.status = status
        this.submit()
        this.$refs.variablePlanEmployees.updateVariableWagesCents()
      }
    },
    async submit() {
      this.$v.$touch()
      if (this.$v.$error || this.isLoading) {
        return
      }
      this.isLoading = true
      try {
        await this.$store.dispatch('variablePlans/updateVariablePlan', this.variablePlanModel)
        this.updatedAt = this.$options.filters.formatCalendar(new Date())
      }
      catch (error) {
        error && alert(error)
      }
      finally {
        this.isLoading = false
      }
    },
    removeVariablePlan() {
      if (window.confirm(this.$t('variable.form.removeConfirmationWarning'))) {
        const variablePlan = this.storedVariablePlan
        this.$router.push({ name: 'variablePlans', params: { highlightId: variablePlan.id, highlightClass: 'red-flash' } })
        setTimeout(() => {
          this.$store.dispatch('variablePlans/removeVariablePlan', variablePlan)
            .catch(error => error && alert(error))
        }, 1000)
      }
    },
    openTemplates() {
      this.isTemplatesVisible = true
    },
    applyTemplate(variablePlan) {
      this.isTemplatesVisible = false
      setTimeout(() => {
        this.$refs.variablePlanEditor.loadConfig(variablePlan)
      }, 500)
    }
  },
  watch: {
    storedVariablePlan: 'initModel'
  },
  beforeRouteUpdate(to, _from, next) {
    if (to.hash === '#templates') {
      this.openTemplates()
      next(false)
    }
    else {
      next()
    }
  },
  async created() {
    try {
      await this.$store.dispatch('variablePlans/getVariablePlan', this.$route.params.id)
      setAppTitle(this.$route)
    }
    catch (e) {
      this.$router.push({ name: 'variablePlans' })
    }
  },
  validations() {
    return {
      variablePlanModel: {
        name: { required }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/alert.scss";
@import "./src/styles/animation.scss";
@import "./src/styles/button.scss";

.container {
  @include container;
}

h1 {
  margin-bottom: 0.4em;
}
</style>
