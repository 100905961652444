<template>
  <div class="container">
    <div class="anchor" :id="componentSlug"></div>
    <div class="top">
      <h2>
        <span v-if="isValid" class="component-status">✅</span>
        <span v-else class="component-status">⚠️</span>
        {{ component.name }}
      </h2>
      <div>
        <button class="small-button" @click="componentFormDisplayed = true" v-t="'sandbox.componentForm.update'"></button>
      </div>
    </div>
    <div class="main">
      <div>
        <div>
          <b v-t="'sandbox.componentDescription'"></b>
        </div>
        <markdown-text v-model="component.description" />
        <button class="small-button update-description" @click="editDescription" v-if="component.description" v-t="'sandbox.updateDescription'"></button>
        <button class="small-button" @click="editDescription" v-else v-t="'sandbox.addDescription'"></button>
      </div>
      <div>
        <table v-if="component.levels && component.levels.length > 0">
          <tr>
            <th colspan="2">#</th>
            <th v-t="'sandbox.name'"></th>
            <th v-if="parentComponent">Parent</th>
            <th v-if="hasRemunerationType('salary')" v-t="'common.salary'"></th>
            <th v-if="hasRemunerationType('equity')" v-t="'common.equity'"></th>
            <th v-if="hasRemunerationType('bonus')" v-t="'common.bonus'"></th>
            <th v-t="'sandbox.levelForm.description'"></th>
            <th></th>
          </tr>
          <draggable
            v-if="component.levels"
            v-model="orderedLevelIds"
            tag="tbody"
            handle=".drag-button"
            :disabled="!isReorderEnabled">
            <level-form
              v-for="level in component.levels"
              :ref="level.id"
              :key="level.id"
              :component="component"
              :parent-component="parentComponent"
              :level="level"
              @addLevel="addLevel">
            </level-form>
          </draggable>
        </table>
        <div class="empty" v-else><b v-t="'sandbox.componentHasNoLevel'"></b></div>
        <loading-button
          @click="addLevel"
          :loading="isLoading"
          class="small-button">
          <span v-t="'sandbox.levelForm.addLevel'"></span>
        </loading-button>
      </div>
    </div>
    <description-modal
      v-if="descriptionEditorDisplayed"
      :title="$t('sandbox.componentForm.descriptionTitle', {componentName: component.name})"
      :description="component.description"
      :loading="isUpdatingDescription"
      @update="updateDescription"
      @close="descriptionEditorDisplayed = false">
    </description-modal>
    <component-form
      v-if="componentFormDisplayed"
      @close="componentFormDisplayed = false"
      :component="component">
    </component-form>
  </div>
</template>

<script>
import { componentHasRemunerationType, isSingleComponentValid } from '@/utils/grid'
import { slugify } from '@/utils/string'
import ComponentForm from '@components/grid/legacy/ComponentForm'
import Draggable from 'vuedraggable'
import DescriptionModal from '@components/grid/legacy/DescriptionModal'
import MarkdownText from '@components/commons/MarkdownText'
import LevelForm from '@components/grid/legacy/LevelForm'

export default {
  components: {
    ComponentForm,
    MarkdownText,
    DescriptionModal,
    Draggable,
    LevelForm
  },
  data() {
    return {
      descriptionEditorDisplayed: false,
      componentFormDisplayed: false,
      isLoading: false,
      isUpdatingDescription: false
    }
  },
  props: {
    component: Object,
    parentComponent: Object
  },
  computed: {
    isReorderEnabled() {
      return this.component.levels[0] && !this.component.levels[0].linkedLevelId
    },
    orderedLevelIds: {
      get() {
        return this.component.levels.map(level => level.id)
      },
      set(orderedLevelIds) {
        this.$store.dispatch('sandbox/reorderLevel', { component: this.component, orderedLevelIds })
      }
    },
    isValid() {
      return isSingleComponentValid(this.component)
    },
    componentSlug() {
      return slugify(this.component.name)
    }
  },
  methods: {
    hasRemunerationType(remunerationType) {
      return componentHasRemunerationType(this.component, remunerationType)
    },
    editDescription() {
      this.descriptionEditorDisplayed = true
    },
    updateDescription(description) {
      this.isUpdatingDescription = true
      this.$store.dispatch('sandbox/updateComponent', {
        id: this.component.id,
        description
      })
        .then(r => {
          this.isUpdatingDescription = false
          this.descriptionEditorDisplayed = false
        })
        .catch(e => {
          this.isUpdatingDescription = false
        })
    },
    async addLevel() {
      const component = this.component
      const level = {
        rank: component.levels ? component.levels.length : 0
      }
      this.isLoading = true
      await this.$store.dispatch('sandbox/createLevel', { component, level })
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
@import "./src/styles/link.scss";

.container {
  @include container;
}

.top {
  display: flex;
  align-items: center;

  button {
    text-transform: uppercase;
    @include font-semibold;
    margin-left: 16px;
    @include font-tiny-size;
    margin-top: 0;
    width: auto;
    vertical-align: sub;
  }
}

.main {
  margin-top: 20px;
  display: grid;
  grid-template-columns: auto 500px;
  grid-gap: 40px;
  align-items: flex-start;
}

.component-status {
  display: inline-block;
  margin-right: 5px;
  @include font-smaller-size;
}

h2 {
  margin: 0;
  color: $clearteal-color;
  @include font-semibold;
}

table {
  width: 100%;
  border-collapse: collapse;
  border: $border;
}

th, td {
  height: 30px;
}

th {
  background-color: $lightteal-color;
  text-align: left;
  padding-left: 5px;
  border-color: $lightteal-color;

  &:first-child {
    text-align: right;
    padding-right: 9px;
  }
}

.small-button {
  margin-top: 15px;
}

.update-description {
  margin-top: 0;
}

.anchor {
  position: relative;
  top: -40px;
  visibility: hidden;
}

.loading {
  display: inline-block;
  margin-left: 7px;
}
</style>
