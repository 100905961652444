<template>
  <div class="columns">
    <div
      class="column-name-thumbnail">
      <NameThumbnail :employee="employee" inline=true />
    </div>
    <div
      class="column-name">
      <h2>{{employee.fullName}}</h2>
      <div v-if="employee.jobType" class="job-type light-text">
        {{employee.jobType}}
      </div>
      <div class="seniority light-text">
        <template v-if="employee.isAlumni">
          <strong>{{ $t('employees.alumnis.name') }}</strong>
        </template>
        <template v-else-if="employee.isExternal">
          <strong>{{ $t('employees.externals.name') }}</strong>
        </template>
        <template v-else-if="employee.seniority > 0">
          {{employee.seniority | formatDiff(2)}}
        </template>
        <template v-else-if="employee.seniority < 0">
          <span v-t="'employees.employee.joinIn'"></span>
          {{ employee.seniority | formatDiff(1) }}
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import NameThumbnail from '@components/commons/NameThumbnail'

export default {
  components: {
    NameThumbnail
  },
  props: {
    employee: {
      type: Object,
      default() {
        return {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.columns {
  display: flex;
  align-items: center;
  border-radius: $border-radius;
  margin-bottom: 20px;
}

.column-name-thumbnail {
  width: 48px;
  cursor: pointer;
}

.column-name {
  width: auto;
  cursor: pointer;

  h2 {
    @include font-normal-size;
    margin-bottom: -2px;
  }

  .job-type,
  .seniority {
    @include font-small-size;
    margin-top: 3px;
  }

  .status-badge {
    @include font-smaller-size;
    color: $text-color;
    background: $graph-lightblue-color;
    padding: 2px 4px;
    border-radius: $border-radius;
    margin-left: 3px;
  }
}
</style>
