<template>
  <div class="form">
    <h3>
      <router-link
        tag="a"
        class="colored-link"
        active-class="noop"
        :to="{name: 'settingsCompanyAccessRights'}"
        v-t="'settings.settingsCompanyAccessRights.title'">
      </router-link>
      <span class="step">›</span>
      <router-link
        tag="a"
        class="colored-link"
        active-class="noop"
        :to="{name: 'settingsCompanyAccessRightsPermissions'}"
        v-t="'settings.settingsCompanyAccessRightsPermissions.title'">
      </router-link>
      <span class="step">›</span>
      <span v-t="'settings.settingsCompanyAccessRightsGroups.title'" @click="toggleDebug"></span>
    </h3>
    <p v-html="$t('settings.settingsCompanyAccessRightsGroups.intro')"></p>
    <TeamsEditor />
  </div>
</template>

<script>
import TeamsEditor from '@components/settings/teams/TeamsEditor.vue'

export default {
  components: {
    TeamsEditor
  },
  methods: {
    toggleDebug(event) {
      if (event.altKey) {
        if (this.$route.query.debug) {
          this.$router.push({ query: {} })
        }
        else {
          this.$router.push({ query: { debug: true } })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/settings.scss";

.step {
  display: inline-block;
  margin: 0 0.5em;
  color: $light-text-color;
}
</style>
