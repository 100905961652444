<template>
  <div v-if="candidate">
    <employee-detail :employee="candidate">
      <template v-if="$$isAdmin">
        <template v-if="!candidate.rejected">
          <button
            class="creative"
            :disabled="isRefusing"
            @click="acceptFormDisplayed = true"
            v-t="'candidates.accept'">
          </button>
          <loading-button
            class="destructive"
            :loading="isRefusing"
            @click="reject">
            <span v-t="'candidates.reject'"></span>
          </loading-button>
        </template>
        <template v-else>
          <loading-button
            class="creative"
            :loading="isAccepting"
            @click="reconsider">
            <span v-t="'candidates.reconsider'"></span>
          </loading-button>
        </template>
        <router-link
          :to="{ name: 'candidateUpdate', params: { id: candidate.id}}"
          tag="button"
          class="secondary"
          v-t="'employees.employee.update'">
        </router-link>
      </template>
    </employee-detail>
    <employee-newsfeed :employee="candidate" :showVariable="true">
      <template v-slot:menu>
        <router-link
          v-if="$$isAdmin"
          :to="{name: 'candidateWageUpdate', params: { id: candidate.id}}"
          tag="button"
          class="secondary"
          v-t="'employees.employee.updateWage'" />
      </template>
    </employee-newsfeed>

    <wage-sharing-panel
      v-if="$$hasGrid && candidate.wageProposal"
      :employee="candidate"
      :wage="candidate.wageProposal"
      template="wageProposal" />

    <modal :visible="acceptFormDisplayed"
      @close="acceptFormDisplayed = false">
      <candidate-accept-form
        :candidate="candidate"
        @close="acceptFormDisplayed = false" />
    </modal>

  </div>
</template>

<script>
import Modal from '@components/commons/Modal.vue'
import CandidateAcceptForm from '@components/candidates/CandidateAcceptForm.vue'
import EmployeeDetail from '@components/employees/EmployeeDetail.vue'
import EmployeeNewsfeed from '@components/employees/EmployeeNewsfeed.vue'
import WageSharingPanel from '@components/wage/WageSharingPanel.vue'

export default {
  data() {
    return {
      errorMessage: null,
      isUpdated: false,
      isUpdating: false,
      isRefusing: false,
      isAccepting: false,
      acceptFormDisplayed: false
    }
  },
  computed: {
    candidate() {
      const candidateId = this.$route.params.id
      return candidateId ? this.$store.getters['candidates/getCandidate'](candidateId) : null
    }
  },
  methods: {
    reject() {
      if (confirm(this.$t('candidates.rejectConfirmation', { candidateName: this.candidate.firstName }))) {
        this.isRefusing = true

        const candidate = { ...this.candidate }
        this.$router.push({ name: 'hiringPlans', params: { highlightCandidate: candidate.id, highlightClass: 'red-flash' } })
        setTimeout(() => {
          this.$store.dispatch('candidates/rejectCandidate', candidate)
            .catch(error => error && alert(error))
        }, 1000)
      }
    },
    reconsider() {
      if (confirm(this.$t('candidates.reconsiderConfirmation', { candidateName: this.candidate.firstName }))) {
        this.isAccepting = true

        this.$store.dispatch('candidates/reconsiderCandidate', this.candidate)
          .then(r => {
            this.isAccepting = false
            this.$router.push({ name: 'hiringPlans', params: { highlightCandidate: this.candidate.id } })
          })
          .catch(e => {
            this.isAccepting = false
          })
      }
    }
  },
  created() {
    if (!this.candidate) {
      this.$router.push({ name: 'hiringPlans' })
    }
  },
  mounted() {
    if (this.candidate) {
      this.$store.commit('candidates/setCurrentCandidate', this.candidate)
    }
  },
  components: {
    CandidateAcceptForm,
    EmployeeDetail,
    EmployeeNewsfeed,
    Modal,
    WageSharingPanel
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
</style>
