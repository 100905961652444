<template>
  <div class="container">
    <div v-if="isEnabled">
      <menu class="float-right no-margin-top no-margin-bottom">
        <grid-export-button :grid="onboardingGrid" />
        <grid-preview-button :grid="onboardingGrid" />
      </menu>
      <h1 v-t="'grid.onboarding.skills.title'"></h1>
      <template v-if="hasComponentWithSkills">
        <p v-t="'grid.onboarding.skills.intro'"></p>
        <onboarding-job-skills-editor @change="onChange" />
        <p>
          {{$t('grid.onboarding.skills.autoSave')}}
          <span v-if="updatedAt" v-html="$t('grid.onboarding.common.updatedAt', {updatedAt})"></span>
        </p>
      </template>
      <p v-else v-t="'grid.onboarding.skills.noComponentWithSkills'" />
      <onboarding-continue-banner
        @submit="submit"
        :loading="isLoading"
        :completed="isJustCompleted"
        :label="hasComponentWithSkills ? $t('grid.onboarding.skills.shallWeProceed') : null"
        :completed-label="$t('grid.onboarding.skills.congratulations')">
      </onboarding-continue-banner>
    </div>
    <div v-else>
      <h1 v-t="'grid.onboarding.skills.title'"></h1>
      <p v-html="$t('grid.onboarding.skills.placeholder')"></p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { gridHasSkills } from '@/utils/grid'
import animateScrollTo from 'animated-scroll-to'
import GridExportButton from '@components/grid/editor/GridExportButton'
import GridPreviewButton from '@components/grid/viewer/GridPreviewButton'
import OnboardingContinueBanner from '@components/grid/onboarding/OnboardingContinueBanner'
import OnboardingJobSkillsEditor from '@components/grid/onboarding/OnboardingJobSkillsEditor'

export default {
  components: {
    GridExportButton,
    GridPreviewButton,
    OnboardingContinueBanner,
    OnboardingJobSkillsEditor
  },
  data() {
    return {
      isLoading: false,
      updatedAt: null
    }
  },
  methods: {
    onChange() {
      this.updatedAt = this.$options.filters.formatCalendar(new Date())
    },
    submit() {
      this.isLoading = true
      this.$store.dispatch('sandbox/getOnboardingGrid').then(() => {
        if (!gridHasSkills(this.onboardingGrid)) {
          animateScrollTo(0).then(() => {
            alert(this.$t('grid.onboarding.skills.continueWarning'))
            this.isLoading = false
          })
        }
        else {
          this.$store.dispatch('onboarding/submitSkills').then(() => {
            this.isLoading = false
            this.$router.push('/grid/onboarding/qualification')
          })
        }
      })
    }

  },
  computed: {
    ...mapGetters({
      onboardingProgress: 'onboarding/getProgressIndex',
      onboardingGrid: 'sandbox/onboardingGrid',
      gridComponents: 'sandbox/allComponents'
    }),
    isEnabled() {
      return this.onboardingProgress >= 1
    },
    hasComponentWithSkills() {
      return !!this.gridComponents.find(c => c.hasSkills)
    },
    isCompleted() {
      return this.onboardingProgress > 1
    },
    isJustCompleted() {
      return !this.isCompleted && gridHasSkills(this.onboardingGrid)
    }
  },
  beforeDestroy() {
    // If the skills were updated, ensure wage skills consistency.
    if (this.updatedAt) {
      this.$store.dispatch('sandbox/ensureSandboxWageSkillsConsistency')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/alert.scss";
@import "./src/styles/button.scss";

.container {
  @include tab-container;
}

h1:not(:first-of-type) {
  margin-top: 1.5em;
}
</style>
