import i18n from '@/i18n'
import { getEmbeddedWageDetails, hashWage } from '@/utils/grid'

export function generateNewsfeed(wages) {
  // Compute wage details
  const newsfeedWages = wages.map(wage =>
    ({ ...wage, ...getEmbeddedWageDetails(wage), hash: hashWage(wage) })
  )

  // Generate transition messages with salary raise
  newsfeedWages.forEach((wage, i) => {
    if (i < newsfeedWages.length - 1) {
      const previousWage = newsfeedWages[i + 1]
      let label
      const explanation = wage.explanation
      const salary = wage.summary.salary.value
      const previousSalary = previousWage.summary.salary.value
      const salaryRaise = salary - previousSalary
      const salaryRaisePercent = previousSalary ? salaryRaise / previousSalary * 100 : 0
      const isSimilar = previousWage && previousWage.hash === wage.hash

      if (!previousWage.grid && wage.grid) {
        label = { path: 'employees.employee.newsfeed.transitionToGrid' }
      }
      else if (previousWage.grid && wage.grid && previousWage.grid.id !== wage.grid.id) {
        const version = i18n.t('grid.current.version', { version: wage.grid.version })
        label = { path: 'employees.employee.newsfeed.gridUpdate', args: { version } }
      }
      else if (salary > previousSalary) {
        label = { path: 'employees.employee.newsfeed.salaryRaise' }
      }
      else {
        label = { path: 'employees.employee.newsfeed.salaryDecrease' }
      }

      wage.transition = {
        explanation,
        isSimilar,
        label,
        salaryRaise,
        salaryRaisePercent
      }
    }
  })

  return newsfeedWages
}

// Compute startDate and endDate of a wage based on newsfeed history
// This skips similar wages to find the last time the salary was actually updated.
export function computePeriod(wage, newsfeedWages) {
  if (newsfeedWages && newsfeedWages.length) {
    newsfeedWages = newsfeedWages.slice().reverse()
    let wageIndex = newsfeedWages.findIndex(w => w.id === wage.id)
    if (wageIndex === -1) {
      wageIndex = newsfeedWages.length - 1
    }
    const salary = wage.summary.salary.value
    const nextWages = newsfeedWages.slice(wageIndex + 1)
    const previousWages = newsfeedWages.slice(0, wageIndex + 1).reverse()
    const firstDiffentNextWage = nextWages.find(w => w.summary.salary.value !== salary)
    const firstDiffentPreviousWageIndex = previousWages.findIndex(w => w.summary.salary.value !== salary)
    const lastSimilarPreviousWage = firstDiffentPreviousWageIndex !== -1 ? previousWages[firstDiffentPreviousWageIndex - 1] : previousWages[previousWages.length - 1]
    return [lastSimilarPreviousWage && lastSimilarPreviousWage.startDate, firstDiffentNextWage && firstDiffentNextWage.startDate]
  }
  return [wage.startDate, null]
}
