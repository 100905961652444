<template>
  <div>
    <GridStructureEditor />
  </div>
</template>

<script>
import GridStructureEditor from '@components/grid/structure/GridStructureEditor.vue'

export default {
  components: {
    GridStructureEditor
  }
}
</script>
