<template>
  <div class="employee-picker-container">
    <div class="input-label">
      {{$tc('settings.settingsCompanyTeams.team' + (editAdmins ? 'Admins' : 'Members'), visibleEmployeesModel.length, {count: visibleEmployeesModel.length} )}}
    </div>
    <resizable-panel :observer="visibleEmployeesModel.length">
      <div class="employees-table" v-if="visibleEmployeesModel.length">
        <table class="blue">
          <tr
            class="row"
            :class="{clickable: !disabled}"
            v-for="employee in visibleEmployeesModel"
            :key="employee.id"
            @click="removeEmployee(employee)">
            <td class="employee-name">
              <name-thumbnail class="blue" :employee="employee" inline=true />
              {{ employee.fullName }}
              <span class="role">{{employee.jobType}}</span>
            </td>
            <td v-if="!disabled" class="employee-action delete">×</td>
          </tr>
        </table>
      </div>
    </resizable-panel>
    <div class="employee-picker">
      <resizable-panel :observer="isPickingEmployee && [availableEmployees.length, filteredEmployees.length]">
        <div v-if="availableEmployees.length">
          <div v-if="isPickingEmployee">
            <input
              type="text"
              ref="search"
              @input="onSearchQueryChange"
              :placeholder="$t('grid.editor.searchEmployee')">
            <button
              class="small-button"
              @click="toggleIsPickingEmployee">
              {{actionLabel}}
            </button>
            <div class="employees-table" v-if="filteredEmployees.length">
              <table class="blue">
                <tr
                  class="row clickable"
                  v-for="employee in filteredEmployees"
                  :key="employee.id"
                  @click="addEmployee(employee)">
                  <td class="employee-name">
                    <name-thumbnail class="blue" :employee="employee" inline=true />
                    {{ employee.fullName }}
                    <span class="role">{{employee.jobType}}</span>
                    <span class="current-team"
                      v-if="!editAdmins && employee.teamName">
                      {{$t('employees.group.team')}}
                      {{employee.teamName}}
                    </span>
                  </td>
                  <td class="employee-action" v-t="'common.add'"></td>
                </tr>
              </table>
            </div>
          </div>
          <button
            v-else
            class="small-button"
            @click="toggleIsPickingEmployee">
            {{actionLabel}}
          </button>
        </div>
      </resizable-panel>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import { mapGetters } from 'vuex'
import NameThumbnail from '@components/commons/NameThumbnail'
import ResizablePanel from '@components/commons/ResizablePanel'
import { buildSearchTerms, filterByQuery, sortByKeys } from '@/utils/string'
import { getField } from '@/utils/employee'
import sortBy from 'lodash.sortby'

export default {
  components: {
    NameThumbnail,
    ResizablePanel
  },
  props: {
    team: Object,
    editAdmins: Boolean,
    disabled: Boolean
  },
  data() {
    return {
      employeesModel: [],
      isPickingEmployee: false,
      searchQuery: null
    }
  },
  computed: {
    ...mapGetters({
      teams: 'teams/getTeams',
      allEmployees: 'employees/getEmployees',
      getEmployeesByIds: 'employees/getEmployeesByIds'
    }),
    employees() {
      const employees = this.getEmployeesByIds(this.team[this.editAdmins ? 'adminIds' : 'memberIds'])
      return this.enrichEmployees(employees)
    },
    visibleEmployeesModel() {
      return this.employeesModel.filter(e => !e.isAlumni)
    },
    availableEmployees() {
      if (this.disabled) {
        return []
      }
      const excludedEmployeeIds = this.employees.map(e => e.id)
      return this.allEmployees.filter(employee => !excludedEmployeeIds.includes(employee.id))
    },
    filteredEmployees() {
      const enrichedAvailableEmployees = this.enrichEmployees(this.availableEmployees, this.editAdmins ? [] : this.teams)
      return filterByQuery(enrichedAvailableEmployees, this.searchQuery, '_searchKey')
    },
    actionLabel() {
      return this.isPickingEmployee
        ? this.$t('common.ok')
        : this.$t('settings.settingsCompanyTeams.add' + (this.editAdmins ? 'Admin' : 'Member'))
    }
  },
  methods: {
    onSearchQueryChange: debounce(function(event) {
      this.searchQuery = event.target.value
    }, 300),
    enrichEmployees(employees, teams = []) {
      return sortBy(employees.map(employee => {
        const jobType = getField(employee, 'jobType', { store: this.$store })
        const fullName = getField(employee, 'fullName')
        let teamName = null
        if (teams.length) {
          const team = teams.find(t => t.memberIds.includes(employee.id))
          teamName = team && team.name
        }
        const hasTeam = !!teamName
        return {
          ...employee,
          jobType,
          fullName,
          hasTeam,
          teamName,
          _searchKey: buildSearchTerms(
            employee.firstName,
            employee.lastName
          )
        }
      }), ['hasTeam', 'fullName'])
    },
    toggleIsPickingEmployee() {
      this.searchQuery = null
      this.isPickingEmployee = !this.isPickingEmployee
      if (this.isPickingEmployee) {
        this.$nextTick(() => {
          if (this.$refs.search) {
            this.$refs.search.focus()
          }
        })
      }
    },
    removeEmployee(employee) {
      if (this.disabled) {
        return
      }
      this.employeesModel = this.employeesModel
        .filter(e => e.id !== employee.id)
      this.onChange()
    },
    addEmployee(employee) {
      this.employeesModel = sortByKeys(this.employeesModel.concat([employee]), 'fullName')
      this.onChange()
      if (!this.filteredEmployees.length) {
        this.toggleIsPickingEmployee()
      }
    },
    onChange() {
      const action = this.editAdmins ? 'teams/updateTeamAdmins' : 'teams/updateTeamMembers'
      const team = this.team
      const employees = this.employeesModel
      this.$store.dispatch(action, { team, employees }).catch(this.initModel)
    },
    initModel() {
      this.employeesModel = [...this.employees]
    }
  },
  watch: {
    employees: 'initModel'
  },
  created() {
    this.initModel()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";
@import "./src/styles/table.scss";

.employees-summary {
  margin: 0.5em 0 0.75rem;
}

.employees-table {
  border: 1px $graph-outer-border-color solid;
  border-radius: $border-radius;
  box-shadow: 1px 1.5px 0 rgba(0, 0, 0, 0.03);
  margin-bottom: 0.6rem;
  // Add scrollbar
  max-height: 36px * 4.2;
  overflow-y: auto;
}

table {
  @include font-small-size;
  user-select: none;

  th,
  td {
    @include line-regular-height;
    padding: 0.25em 0.5em;
  }

  th {
    @include font-medium;
    background: white;
    border: none;
  }

  td {
    border: 1px solid $graph-outer-border-color;
  }

  .role,
  .current-team {
    display: inline-block;
    margin-left: 0.5em;
    color: $light-text-color;

    &.none {
      color: darken($clearteal-color, 10);
    }
  }

  .current-team {
    float: right;
    line-height: 28px;
  }

  .employee-name {
    width: 100%;
  }

  .employee-action {
    color: $light-text-color;
    padding: 0 1.5em;

    &.delete {
      @include font-bold;
      padding: 0 1em;
    }
  }

  .row.clickable:hover {
    .employee-action {
      color: darken($clearteal-color, 10);

      &.delete {
        color: darken($red-color, 12);
      }
    }
  }
}

.employee-picker {
  input[type="text"] {
    display: inline-block;
    max-width: 50%;
    margin-right: 0.75em;
    margin-bottom: 0.75em;
  }
}
</style>
