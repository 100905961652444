<template>
  <div v-if="currentWageDetails">
    <div class="input-label" v-if="showVariable" v-t="'wageCalculator.fixedSalary'"></div>
    <div class="wage-type-selector" v-if="showWageTypeSelector">
      <div
        v-for="wageType in wageTypes"
        :key="wageType.value">
        <input
          type="radio"
          :value="wageType.value"
          :id="[wageType.value, _uid].join('-')"
          :disabled="wageType.disabled || (disabled && wageType.value != selectedWageType)"
          v-model="selectedWageType"
          @change="wageTypeChanged">
        <label
          :for="[wageType.value, _uid].join('-')"
          :class="{disabled: wageType.disabled || (disabled && wageType.value != selectedWageType)}"
          :title="wageType.disabled ? $t('wageCalculator.disabledWageType') : wageType.description">
          <checkbox
            :checked="selectedWageType === wageType.value">{{ wageType.title }}</checkbox>
        </label>
      </div>
    </div>
    <div class="wage-box" ref="viewport">
      <ResizablePanel :observer="[selectedWageType, wageModel]">
        <div class="components" v-if="selectedWageType != 'off-grid'">
          <WageTab
            class="component-part"
            v-for="(component, i) in currentWageDetails.components"
            :key="component.id"
            @click.native="selectComponent(i)"
            :component="component"
            :parentComponent="getParentComponent(currentWageDetails.components[i])"
            :reference-component="getReferenceComponent(component)"
            :wageDetails="currentWageDetails"
            :show-delta="showDelta"
            :show-salary="showSalary"
            :active="component === currentWageDetails.components[selectedComponentIndex]" />
        </div>
      </ResizablePanel>
      <ResizablePanel :observer="[selectedComponentIndex, selectedWageType]">
        <div class="wage-level-selector-container" v-if="currentWageDetails.components[selectedComponentIndex]">
          <WageLevelSelector
            :component="currentWageDetails.components[selectedComponentIndex]"
            :parentComponent="getParentComponent(currentWageDetails.components[selectedComponentIndex])"
            :reference-component="getReferenceComponent(currentWageDetails.components[selectedComponentIndex])"
            :wageDetails="currentWageDetails"
            :show-delta="showDelta"
            :show-salary="showSalary"
            :disabled="disabled"
            @change="levelChanged"
            @reset="levelReset" />
        </div>
      </ResizablePanel>
      <ResizablePanel :observer="selectedWageType">
        <div class="interval-container" v-if="showSalary && isInterval && wageModel.hasLevels">
          <WageIntervalSlider
            :wage="wageModel"
            :grid="grid"
            :showAdjustedCheckbox="!showWageTypeSelector"
            @change="setOverridenSalary"
            @selectLevel="levelChanged" />
        </div>
        <div class="adjusted-container" v-if="selectedWageType === 'adjusted' && !isInterval">
          <h3 v-t="'wageCalculator.wageTypes.adjusted.actionTitle'"></h3>
          <p class="description" v-t="'wageCalculator.wageTypes.adjusted.description'"></p>
          <template v-if="showSalary">
            <p class="wage-part">
              {{$t('wageCalculator.wageTypes.adjusted.alignedSalary')}}
              <span
                class="aligned-salary"
                :title="$t('wageCalculator.restoreAligned')"
                @click="setOverridenSalary(currentWageDetails.summary.salary.value - currentWageDetails.summary.salary.delta)">
                {{ (currentWageDetails.summary.salary.value - currentWageDetails.summary.salary.delta) | formattedCurrency }}
              </span>
            </p>
            <div class="wage-part">
              {{$t('wageCalculator.wageTypes.adjusted.adjustedSalary')}}
              <OperationInput
                class="green"
                ref="overridenSalary"
                :step="100"
                :disabled="disabled"
                v-model="wageModel.overridenSalaryValue"
                @input="changed()" />
            </div>
            <div v-if="!isInterval" class="wage-slider-part">
              <WageAdjustedSlider
                :wage="wageModel"
                :grid="grid"
                @change="setOverridenSalary" />
            </div>
          </template>
        </div>
        <div class="off-grid-container" :class="{'with-reference': !!referenceWage}" v-if="selectedWageType === 'off-grid'">
          <h3 v-t="'wageCalculator.wageTypes.offGrid.actionTitle'"></h3>
          <p v-if="isGridAvailable || !showSalary" class="description" v-t="'wageCalculator.wageTypes.offGrid.description'"></p>
          <template v-if="showSalary">
            <p class="wage-part">
              {{$t('wageCalculator.wageTypes.offGrid.offGridSalary')}}
              <span>
                <OperationInput
                  v-if="referenceWage"
                  class="green"
                  :disabled="true"
                  v-model="referenceWageDetails.summary.salary.fixedValue" />
                <span v-if="referenceWage">&nbsp;→&nbsp;</span>
                <OperationInput
                  class="green"
                  ref="overridenSalary"
                  :step="100"
                  :disabled="disabled"
                  v-model="wageModel.overridenSalaryValue"
                  @input="changed()" />
                <OperationInput
                  v-if="referenceWage"
                  operation="percent"
                  class="percent-input green"
                  :step="0.1"
                  :disabled="disabled"
                  v-model="percentModel.fixedPercent"
                  @input="percentChanged()" />
              </span>
            </p>
          </template>
        </div>
      </ResizablePanel>
      <div class="salary" v-if="showSalary">
        <WageSummary
          :wage-summary="currentWageDetails.summary"
          :reference-wage-summary="referenceWageDetails.summary"
          :show-delta="showDelta"
          :show-wage-type="!!isGridAvailable"
          @reference="resetReference" />
      </div>
    </div>
    <div v-if="showVariable" class="variable-container">
      <div class="input-label" v-t="'wageCalculator.variable.title'"></div>
      <p class="no-margin-top">
        <Checkbox
          v-model="isContractualVariableEnabled"
          @change="resetContractualVariableValue">
          <span>
            {{$t('wageCalculator.variable.checkbox')}}
            <Tooltip
              class="gray-icon"
              :message="$t('wageCalculator.variable.explanation')" />
          </span>
        </Checkbox>
      </p>
      <div v-if="isContractualVariableEnabled">
        <p>
          <span>
            <OperationInput
              v-if="referenceWage && referenceWageDetails.summary.salary.variableValue"
              class="green"
              :disabled="true"
              v-model="referenceWageDetails.summary.salary.variableValue" />
            <span v-if="referenceWage && referenceWageDetails.summary.salary.variableValue">&nbsp;→&nbsp;</span>
            <OperationInput
              class="green"
              :step="100"
              :disabled="disabled"
              v-model="wageModel.contractualVariableValue"
              @input="changed()" />
            <OperationInput
              v-if="referenceWage && referenceWageDetails.summary.salary.variableValue"
              operation="percent"
              class="percent-input green"
              :step="0.1"
              :disabled="disabled"
              v-model="percentModel.variablePercent"
              @input="percentChanged()" />
          </span>
        </p>
      </div>
    </div>
    <div v-if="showComment">
      <MarkdownTextEditor
        v-model="wageModel.comment"
        :action="$t('wageCalculator.commentAction')"
        :label="$t('wageCalculator.comment')"
        :optionalLabel="$t('wageCalculator.private')"
        :placeholder="$t('wageCalculator.commentPlaceholder')"
        :disabled="disabled"
        :showAddButton="true"
        minHeight="25px"
        @change="changed" />
    </div>
    <div v-if="showReason">
      <MarkdownTextEditor
        v-model="wageModel.explanation"
        :label="$t('wageCalculator.reason')"
        :optionalLabel="$t('wageCalculator.public')"
        :placeholder="$t('wageCalculator.reasonPlaceholder')"
        :disabled="disabled"
        :showAddButton="true"
        minHeight="25px"
        @change="changed" />
    </div>
  </div>
</template>

<script>
import cloneDeepWith from 'lodash.clonedeepwith'
import { flattenedComponents, getVisibleLevels, getWageDetails, isGridInterval } from '@/utils/grid'
import { getPercent, applyPercent } from '@/utils/math'

import Checkbox from '@components/commons/Checkbox.vue'
import MarkdownTextEditor from '@components/commons/MarkdownTextEditor.vue'
import OperationInput from '@components/commons/OperationInput.vue'
import ResizablePanel from '@components/commons/ResizablePanel.vue'
import Tooltip from '@components/commons/Tooltip.vue'
import WageAdjustedSlider from '@components/wage/WageAdjustedSlider.vue'
import WageIntervalSlider from '@components/wage/WageIntervalSlider.vue'
import WageLevelSelector from '@components/wage/WageLevelSelector.vue'
import WageSummary from '@components/wage/WageSummary.vue'
import WageTab from '@components/wage/WageTab.vue'
import animateScrollTo from 'animated-scroll-to'

export default {
  components: {
    Checkbox,
    MarkdownTextEditor,
    OperationInput,
    ResizablePanel,
    Tooltip,
    WageAdjustedSlider,
    WageIntervalSlider,
    WageLevelSelector,
    WageSummary,
    WageTab
  },
  props: {
    autoOpen: {
      validate(value) {
        return ['smart', 'default', true, false].includes(value)
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    grid: Object,
    value: Object,
    referenceWage: Object,
    referenceGrid: Object,
    showComment: {
      type: Boolean,
      default: false
    },
    showDelta: {
      type: Boolean,
      default: false
    },
    showReason: {
      type: Boolean,
      default: false
    },
    showVariable: {
      type: Boolean,
      default: false
    },
    showSalary: {
      type: Boolean,
      default: true
    },
    showWageTypeSelector: {
      type: Boolean,
      default: true
    },
    setDefaultValue: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      wageModel: null,
      percentModel: {
        fixedPercent: 0,
        variablePercent: 0
      },
      initialWage: null,
      isContractualVariableEnabled: false,
      referenceWageDetails: null,
      currentWageDetails: null,

      selectedWageType: null,
      selectedComponentIndex: null
    }
  },
  watch: {
    grid: 'reset'
  },
  computed: {
    isGridAvailable() {
      return !!this.grid
    },
    isInterval() {
      if (this.currentWageDetails) {
        return isGridInterval(this.currentWageDetails.components)
      }
    },
    wageTypes() {
      const wageTypes = [
        {
          title: this.$t('wageCalculator.wageTypes.aligned.title'),
          description: this.$t('wageCalculator.wageTypes.aligned.description'),
          disabled: !this.grid,
          value: 'aligned'
        },
        {
          title: this.$t('wageCalculator.wageTypes.adjusted.title'),
          description: this.$t('wageCalculator.wageTypes.adjusted.description'),
          disabled: !this.grid,
          value: 'adjusted'
        },
        {
          title: this.$t('wageCalculator.wageTypes.offGrid.title'),
          description: this.$t('wageCalculator.wageTypes.offGrid.description'),
          value: 'off-grid'
        }
      ]
      if (!this.isGridAvailable) {
        // Show off-grid first
        wageTypes.reverse()
      }

      return wageTypes
    }
  },
  methods: {
    selectComponent(index) {
      return new Promise(resolve => {
        if (this.selectedComponentIndex !== index) {
          this.selectedComponentIndex = index
          setTimeout(() => {
            this.$emit('resize')
            this.fitViewportInScreen()
            resolve()
          }, 310)
        }
        else {
          this.selectedComponentIndex = null
          resolve()
        }
      })
    },
    closeLevelSelector() {
      return this.selectComponent(null)
    },
    fitViewportInScreen() {
      const offset = -12
      const speed = 1700
      const windowHeight = window.innerHeight
      const viewportRect = this.$refs.viewport.getBoundingClientRect()
      const viewportBottom = viewportRect.bottom - offset
      // If bottom of viewport is below fold
      if (viewportBottom > windowHeight) {
        if (viewportRect.height > windowHeight) {
          // If viewport bigger than window, align to top
          animateScrollTo(this.$refs.viewport, { offset, speed })
        }
        else {
          // If viewport smaller than window, align to bottom
          animateScrollTo(window.scrollY + (viewportBottom - windowHeight), { speed })
        }
      }
    },
    getWageDetailsWithSkills(grid, wage) {
      return getWageDetails(grid, wage, { includeComponents: true, includeSelectedSkills: true })
    },
    initWageModel(grid, wage) {
      // If the provided wage is invalid, init with default value
      const defaultLevelIds = this.getDefaultLevelIds(grid)
      const defaultValue = {
        contractualVariableValue: null,
        employeeId: wage && wage.employeeId,
        gridId: grid && grid.id,
        hasLevels: !!grid,
        jobOfferId: wage && wage.jobOfferId,
        levelIds: defaultLevelIds,
        overridenSalaryValue: grid ? null : 0, // 🎩
        skillIds: [],
        skillValues: {},
        salaryValue: null
      }
      const isWageInvalid = !wage ||
        (wage.hasLevels && !wage.levelIds.length) ||
        (wage.gridId && (!grid || wage.gridId !== grid.id))

      let wageModel
      if (isWageInvalid) {
        wageModel = { ...cloneDeepWith(wage), ...defaultValue }
      }
      else {
        wageModel = { ...cloneDeepWith(wage), salaryValue: null }
        // Init potentially unserialized properties
        if (typeof wageModel.overridenSalaryValue === 'undefined') {
          wageModel.overridenSalaryValue = null
        }
        if (typeof wageModel.contractualVariableValue === 'undefined') {
          wageModel.contractualVariableValue = null
        }
      }

      // Set selectedWageType based on wage type
      let selectedWageType = 'aligned'
      if (wageModel.hasLevels && wageModel.overridenSalaryValue) {
        selectedWageType = 'adjusted'
      }
      else if (!wageModel.hasLevels && wageModel.overridenSalaryValue != null) {
        selectedWageType = 'off-grid'
      }

      // Set isContractualVariableEnabled based on targetSalaryValue
      const isContractualVariableEnabled = !!wageModel.contractualVariableValue

      // Set data
      const currentWageDetails = this.getWageDetailsWithSkills(grid, wageModel)
      Object.assign(this.$data, {
        wageModel,
        currentWageDetails,
        selectedWageType,
        isContractualVariableEnabled
      })

      // Notify parent of model change
      if (this.setDefaultValue && isWageInvalid) {
        this.$emit('input', this.wageModel)
      }
    },
    initPercentModel() {
      if (this.referenceWageDetails) {
        this.percentModel.fixedPercent = getPercent(this.wageModel.overridenSalaryValue, this.referenceWageDetails.summary.salary.fixedValue)
        this.percentModel.variablePercent = getPercent(this.wageModel.contractualVariableValue, this.referenceWageDetails.summary.salary.variableValue)
      }
    },
    getDefaultOverridenSalaryValue() {
      // Simulate aligned salary value
      if (this.wageModel.levelIds.length) {
        const wageDetails = getWageDetails(this.grid, {
          hasLevels: true,
          levelIds: this.wageModel.levelIds
        })
        return wageDetails.summary.salary.value
      }
      else {
        return this.currentWageDetails.summary.salary.value
      }
    },
    getDefaultLevelId(component, selectedLevelIds) {
      // Generate a default level id by taking linked levels into account
      if (component.levels && component.levels.length) {
        let levels = getVisibleLevels(component.levels)
        if (levels[0].linkedLevelId) {
          levels = levels.filter(level => selectedLevelIds.includes(level.linkedLevelId))
        }
        return levels.length && levels[0].id
      }
    },
    getDefaultLevelIds(grid) {
      return flattenedComponents(grid && grid.components)
        .reduce((memo, component) => {
          memo.push(this.getDefaultLevelId(component, memo))
          return memo
        }, [])
        .filter(id => !!id)
    },
    getReferenceComponent(component) {
      let referenceComponents = this.referenceWageDetails && this.referenceWageDetails.components
      if (!referenceComponents || !referenceComponents.length) {
        // Can happen when the reference wage is off grid
        // in that case we take the initial wage as the reference
        referenceComponents = getWageDetails(this.grid, this.initialWage, { includeComponents: true }).components
      }
      if (referenceComponents && referenceComponents.length) {
        if (component.ref) {
          return referenceComponents.find(c => c.ref === component.ref)
        }
        else {
          return referenceComponents.find(c => c.id === component.id)
        }
      }
    },
    getParentComponent(component) {
      if (this.currentWageDetails) {
        return this.currentWageDetails.components.find(c => c.id === component.parentComponentId)
      }
    },
    setOverridenSalary(value) {
      this.wageModel.overridenSalaryValue = value
      if (!!value && this.selectedWageType === 'aligned') {
        this.selectedWageType = 'adjusted'
      }
      else if (!value && this.selectedWageType === 'adjusted') {
        this.selectedWageType = 'aligned'
      }
      this.changed()
    },
    reset() {
      this.initWageModel(this.grid, this.initialWage)
      this.changed()
    },
    resetReference() {
      // Allow fast adjustment without losing qualification levels
      let referenceWage = this.referenceWage
      if (referenceWage) {
        const referenceSalary = this.referenceWageDetails.summary.salary.fixedValue
        referenceWage = { ...this.wageModel, overridenSalaryValue: referenceSalary }
        this.initWageModel(this.grid, referenceWage)
        this.changed()
      }
    },
    changed() {
      this.initPercentModel()
      this.currentWageDetails = this.getWageDetailsWithSkills(this.grid, this.wageModel)
      this.$emit('input', this.wageModel)
    },
    percentChanged() {
      if (this.referenceWageDetails) {
        this.wageModel.overridenSalaryValue = applyPercent(this.percentModel.fixedPercent, this.referenceWageDetails.summary.salary.fixedValue)
        this.wageModel.contractualVariableValue = applyPercent(this.percentModel.variablePercent, this.referenceWageDetails.summary.salary.variableValue)
        this.changed()
      }
    },
    resetContractualVariableValue() {
      if (!this.isContractualVariableEnabled) {
        this.wageModel.contractualVariableValue = 0
      }
      this.changed()
    },
    levelReset(resetComponent) {
      this.wageModel.skillIds = this.currentWageDetails.components.reduce((memo, component) => {
        if (component.hasSkills && component.id !== resetComponent.id) {
          memo = memo.concat(component.selectedSkills || [])
        }
        return memo
      }, [])
      this.wageModel.skillValues = this.currentWageDetails.components.reduce((memo, component) => {
        if (component.hasSkillValues && component.id !== resetComponent.id) {
          memo = { ...memo, ...component.selectedSkillValues }
        }
        return memo
      }, {})
      this.wageModel.levelIds = this.currentWageDetails.components.reduce((memo, component) => {
        if (component.selectedLevel && component.id !== resetComponent.id) {
          memo.push(component.selectedLevel.id)
        }
        // Clear obsolete linked selected levels
        if (component.selectedLevel && component.selectedLevel.linkedLevelId && !memo.includes(component.selectedLevel.linkedLevelId)) {
          component.selectedLevel = null
        }
        return memo
      }, [])
      this.changed()
    },
    levelChanged(selectedLevel) {
      const isPartialWage = this.wageModel.levelIds.length !== this.currentWageDetails.components.length

      this.wageModel.skillIds = this.currentWageDetails.components.reduce((memo, component) => {
        return memo.concat(component.selectedSkills || [])
      }, [])

      this.wageModel.skillValues = this.currentWageDetails.components.reduce((memo, component) => {
        if (component.hasSkillValues) {
          memo = { ...memo, ...component.selectedSkillValues }
        }
        return memo
      }, this.wageModel.skillValues)

      this.wageModel.levelIds = this.currentWageDetails.components.reduce((memo, component) => {
        // Set selectedLevel
        if (selectedLevel.componentId === component.id) {
          component.selectedLevel = selectedLevel
        }
        // Clear obsolete linked selected levels
        if (component.selectedLevel && component.selectedLevel.linkedLevelId && !memo.includes(component.selectedLevel.linkedLevelId)) {
          component.selectedLevel = null
        }
        // Retrieve selected levels
        if (component.selectedLevel) {
          memo.push(component.selectedLevel.id)
        }
        else if (!isPartialWage) {
          // If there's no selected level, we can't simply look into component.levels
          // because they have been filtered by wageDetails so we need to generate
          // a default level id based on existing selection
          const originalComponent = flattenedComponents(this.grid.components).find(c => c.id === component.id)
          if (originalComponent) {
            const defaultLevelId = this.getDefaultLevelId(originalComponent, memo)
            if (defaultLevelId) {
              memo.push(defaultLevelId)
            }
          }
        }

        return memo
      }, [])
      this.changed()
    },
    wageTypeChanged() {
      switch (this.selectedWageType) {
        case 'aligned': // Delete overriden salary, restore levels
          this.wageModel.overridenSalaryValue = null
          this.wageModel.hasLevels = true
          if (!this.wageModel.levelIds.length) {
            this.wageModel.levelIds = this.getDefaultLevelIds(this.grid)
          }
          break
        case 'adjusted': // Compute overriden salary, restore levels
          this.wageModel.overridenSalaryValue = this.getDefaultOverridenSalaryValue()
          this.wageModel.hasLevels = true
          if (!this.wageModel.levelIds.length) {
            this.wageModel.levelIds = this.getDefaultLevelIds(this.grid)
          }
          break
        case 'off-grid': // Compute overriden salary, delete levels
          this.wageModel.overridenSalaryValue = this.getDefaultOverridenSalaryValue()
          this.wageModel.hasLevels = false
          this.selectedComponentIndex = null
          break
      }

      this.selectedComponentIndex = null
      this.changed()
      setTimeout(() => {
        this.$emit('resize')
      }, 400)
    }
  },
  mounted() {
    this.initWageModel(this.grid, this.value)
    this.initialWage = cloneDeepWith(this.value)
    if (this.referenceWage) {
      this.referenceWageDetails = getWageDetails(this.referenceGrid, this.referenceWage, { includeComponents: true })
    }
    else {
      this.referenceWageDetails = cloneDeepWith(this.currentWageDetails)
    }
    this.initPercentModel()

    if (this.autoOpen === 'smart') {
      const components = this.currentWageDetails.components || []
      let index = components.findIndex(c => !c.selectedLevel)
      index = index === -1 ? components.findIndex(c => c.ref === 'experience') : index
      index = index === -1 ? 0 : index
      this.selectedComponentIndex = index
    }
    else if (this.autoOpen) {
      this.selectedComponentIndex = 0
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/badge.scss";
@import "./src/styles/form.scss";
@import "./src/styles/link.scss";

$wage-type-selector-padding: 1em;

.wage-box {
  border: 1px solid $graph-outer-border-color;
  border-top: none;
  border-radius: $border-radius;
  background: linear-gradient(
    180deg,
    $graph-background-color 0%,
    darken($graph-background-color, 3) 100%
  );
  margin-bottom: 1em;
}

.components {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.component-part {
  width: 100%;
  display: table-cell;
  vertical-align: middle;
  border-top: 1px solid $graph-outer-border-color;

  &:not(:last-of-type) {
    border-right: 1px solid $graph-outer-border-color;
  }
}

.wage-level-selector-container {
  background: white;
  border-top: 1px solid $graph-outer-border-color;
  padding: 1.5em 5%;
}

.salary {
  position: sticky;
  bottom: 0;
  border-top: 1px solid $graph-outer-border-color;
  background: darken($graph-background-color, 3);
  padding: 5px;
  overflow: hidden;
  text-align: center;

  .wage-summary {
    display: inline-block;
    padding: 5px 8px;
    margin: auto;
    min-width: 300px;
  }
}

.wage-type-selector {
  margin-bottom: 1em;

  div {
    display: inline-block;
  }

  label {
    display: inline-block;
    padding: 0.5em 0.85em 0.45em 0.75em;
    border-radius: 2.5em;
    margin-right: 0.5em;
    @include font-medium;
    @include line-regular-height;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
    }

    &:hover {
      background: $graph-lightblue-color;
    }
  }

  .checkbox {
    pointer-events: none;
  }

  input[type="radio"] {
    display: none;
  }

  input[type="radio"]:checked + label {
    background: $graph-lightblue-color;
  }
}

.interval-container,
.adjusted-container,
.off-grid-container,
.variable-container {
  border-top: 1px solid $graph-outer-border-color;
  padding: 1.25em 2em;
  background: white;
  text-align: center;

  h3 {
    @include font-semibold;
    @include font-large-size;
    margin-bottom: 0.3em;
  }

  .description {
    color: $light-text-color;
    margin-top: 0;
  }

  .wage-part {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 8px;
  }

  .wage-slider-part {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    padding: 15px 0;
  }

  .operation-input,
  .operation-input::v-deep input,
  .aligned-salary {
    display: inline-block;
    width: 120px;
    text-align: right;
    color: $clearteal-color;
    @include font-bold;
    @include font-large-size;
  }

  .operation-input:last-of-type {
    margin-right: -11px;
  }

  .aligned-salary {
    cursor: alias;
    margin-right: 10px;
  }

  &.variable-container {
    border-top: none;
    padding: inherit;
    text-align: left;
  }

  // off-grid-container tweaks
  &.off-grid-container,
  &.variable-container {
    &.with-reference {
      .wage-part {
        width: 510px;
      }
    }

    .operation-input.percent-input {
      margin-left: 0.5em;

      &,
      &::v-deep input {
        width: 110px;
      }
    }
  }
}

.interval-container {
  padding: 0;
}
</style>
