<template>
  <div class="container">
    <LoadingView v-if="isLoading" />
    <div v-else-if="!benefitsPlan">
      <BenefitsPlanForm />
    </div>
    <div v-else>
      <BenefitsPlanEditor />
    </div>
  </div>
</template>

<script>
import BenefitsPlanEditor from '@components/benefits/BenefitsPlanEditor'
import BenefitsPlanForm from '@components/benefits/BenefitsPlanForm'
import LoadingView from '@components/commons/LoadingView'

import { mapGetters } from 'vuex'
import { setAppTitle } from '@/utils/route'

export default {
  components: {
    BenefitsPlanEditor,
    BenefitsPlanForm,
    LoadingView
  },
  computed: {
    ...mapGetters({
      benefitsPlan: 'benefits/getCurrentBenefitsPlan'
    }),
    benefitsPlanId() {
      return this.$route.params.id
    },
    isLoading() {
      return this.benefitsPlanId && !this.benefitsPlan
    }
  },
  async mounted() {
    if (this.benefitsPlanId) {
      try {
        await this.$store.dispatch('benefits/getBenefitsPlan', this.benefitsPlanId)
        setAppTitle(this.$route)
      }
      catch (_error) {
        this.$router.push({ name: 'benefitsPlans' })
      }
    }
  },
  destroyed() {
    this.$store.dispatch('benefits/resetCurrentBenefitsPlan')
  }
}
</script>

<style lang="scss" scoped>
.container {
  @include container;
}
</style>
