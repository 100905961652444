<template>
  <div class="form settings-form">
    <h3 v-t="'settings.settingsCompanyCustomFields.title'"></h3>
    <p v-t="'settings.settingsCompanyCustomFields.intro'"></p>
    <CustomFieldsEditor />
  </div>
</template>

<script>
import CustomFieldsEditor from '@components/settings/CustomFieldsEditor.vue'

export default {
  components: {
    CustomFieldsEditor
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/settings.scss";
</style>
