<template>
  <div class="grid-preview" ref="container">
    <div class="grid-preview-header" v-t="'grid.editor.preview.intro'"></div>

    <h1 :data-id="grid.id">
      {{getGridTitle(grid, $$companyName)}}
      <GridVersionPicker
        class="grid-version-picker"
        :disabled="true"
        :selectedGrid="grid"/>
    </h1>
    <MarkdownText v-model="grid.description" />

    <div>
      <GridComponentDetail
        v-for="component in gridComponents"
        :key="component.id"
        :grid="grid"
        :component="component"
        :active-component-slug="activeComponentSlug"
        :selectedLevelId="selectedLevelId"
        :showDescriptions="true"
        :showSkills="true"
        :showEmployees="false"
        :showValues="$$isAdmin"
        @select="selectComponent" />
    </div>

    <h2 v-t="'grid.formula'"></h2>
    <p v-t="'grid.current.formulaIntro'"></p>
    <p>
      <GridFormula
        layout="horizontal"
        :grid="grid"
        @select="selectComponent" />
    </p>
  </div>
</template>

<script>
import { slugify } from '@/utils/string'
import animateScrollTo from 'animated-scroll-to'
import { componentsForRemunerationType, getParentComponent, getGridTitle } from '@/utils/grid'
import GridComponentDetail from '@components/grid/viewer/GridComponentDetail.vue'
import GridFormula from '@components/grid/viewer/GridFormula.vue'
import GridVersionPicker from '@components/grid/viewer/GridVersionPicker.vue'
import MarkdownText from '@components/commons/MarkdownText'

export default {
  components: {
    GridComponentDetail,
    GridFormula,
    GridVersionPicker,
    MarkdownText
  },
  props: {
    grid: Object,
    selectedLevelId: String
  },
  data() {
    return {
      activeComponentSlug: null
    }
  },
  computed: {
    gridComponents() {
      return componentsForRemunerationType(this.grid.components, 'salary')
    }
  },
  methods: {
    getGridTitle,
    getComponentSlug(component) {
      return slugify(component.name)
    },
    selectComponent(component) {
      if (component) {
        const parentComponent = getParentComponent(component, this.grid)
        if (parentComponent) {
          const slug = this.getComponentSlug(parentComponent)
          const anchor = '#' + slug
          animateScrollTo(this.$el.querySelector(anchor), {
            elementToScroll: this.$refs.container,
            minDuration: 0,
            maxDuration: 1000
          }).then(() => {
            setTimeout(() => {
              this.activeComponentSlug = slug
            }, 110)
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.grid-preview {
  width: $content-max-width;
  padding: 20px;
}

.grid-preview-header {
  @include line-regular-height;
  padding: 20px 20px;
  background-color: $graph-lightblue-color;
  margin: -20px -20px 25px -20px;
}

h1 {
  margin-bottom: 0.45em;
}

h2 {
  margin-bottom: 0.5em;

  &:first-of-type {
    margin-top: 25px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

p {
  margin: 0.5em 0;
}

.grid-version-picker {
  margin-left: 0.25em;
  vertical-align: 1.5px;
}
</style>
