<template>
  <div v-if="wagesLoaded">
    <div v-if="jobOffers.length > 0">
      <sandbox-wage-editor
        class="sandbox-wage-editor"
        v-for="jobOffer in jobOffers"
        :key="jobOffer.id"
        :title="jobOffer.name"
        :sandboxWage="$store.getters['sandbox/jobOfferWage'](jobOffer.id)"
        :referenceWage="jobOffer.referenceWage">
      </sandbox-wage-editor>
    </div>
    <div v-else class="placeholder">
      ✅ <span v-t="'sandbox.noJobOffers'"></span>
    </div>
  </div>
  <loading-view v-else></loading-view>
</template>

<script>
import LoadingView from '@components/commons/LoadingView.vue'
import SandboxWageEditor from '@components/sandbox/SandboxWageEditor.vue'

export default {
  computed: {
    jobOffers() {
      // There shouldn't be any sandbox job offer wage anymore
      return []
    },
    wagesLoaded() {
      return this.$store.getters['sandbox/wagesLoaded']
    }
  },
  components: {
    LoadingView,
    SandboxWageEditor
  }
}
</script>

<style lang="scss" scoped>
.sandbox-wage-editor {
  margin-bottom: 20px;

  &:last-child {
    margin: 0;
  }
}

.placeholder {
  border: $border;
  border-radius: $border-radius;
  padding: 20px;
}
</style>
