import '@/services/debug'
import '@/services/sentry'

import Vue from 'vue'
import Vuelidate from 'vuelidate'
import Multiselect from 'vue-multiselect'
import App from '@/App'
import store from '@/store'
import router from '@/router'
import i18n from '@/i18n'
import markdown from '@/utils/markdown'
import { loadBeacon, callBeacon, refreshContextualHelp, setupBeacon } from '@/services/helpscout'
import { setAppTitle, nextWithHashActions, saveRedirectRoute } from '@/utils/route'

import LoadingButton from '@components/commons/LoadingButton.vue'

import '@/mixins/account'
import '@/mixins/route'
import '@/filters'

Vue.config.productionTip = false

Vue.component('loading-button', LoadingButton)
Vue.component('multiselect', Multiselect)

Vue.use(Vuelidate)

// Can be loaded unconditionnally
const PUBLIC_ROUTES = [
  'contact',
  'demo',
  'helpArticle',
  'invitation',
  'job',
  'jobs',
  'oktaImplicitCallback',
  'wageSharing'
]

// Can be loaded only when signed out
const UNAUTHENTICATED_ROUTES = [
  'signin',
  'signup',
  'passwordRequest',
  'passwordReset'
]

router.beforeEach((to, from, next) => {
  const isPublicRoute = PUBLIC_ROUTES.some(route => route === to.name)
  const isUnauthenticatedRoute = UNAUTHENTICATED_ROUTES.some(route => route === to.name)
  const sessionGuard = () => {
    // Set contextual help
    refreshContextualHelp(to.name)

    // Redirect to relevant route
    if (isPublicRoute) {
      nextWithHashActions(router, callBeacon, to, from, next)
    }
    else {
      if (store.getters.hasSession) {
        isUnauthenticatedRoute ? next('/') : nextWithHashActions(router, callBeacon, to, from, next)
      }
      else {
        isUnauthenticatedRoute ? nextWithHashActions(router, callBeacon, to, from, next) : saveRedirectRoute(to) && next('/signin')
      }
    }
  }

  if (!store.getters.isAppLoaded && store.getters.hasSession) {
    store.dispatch('account/getAccount')
      .then(sessionGuard)
      .catch(sessionGuard)
  }
  else {
    if (!store.getters.isAppLoaded && !isPublicRoute) {
      loadBeacon()
      setupBeacon()
    }

    sessionGuard()
  }
})

router.afterEach((to, _) => {
  Vue.nextTick(() => {
    setAppTitle(to)
  })
})

Vue.filter('markdown', markdown)

// eslint-disable-next-line no-new
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
