<template>
  <div class="publish" :class="{small: !isFirstGrid}">
    <h2 v-t="'grid.publish.title'"></h2>
    <div class="publish-illustration-frame">
      <div
        class="publish-illustration"
        :class="{steady: isLoading || isSteady, go: isPublished}">
      </div>
    </div>
    <p class="preserve-lines" v-html="$t(isFirstGrid ? 'grid.publish.introFirstGrid' : 'grid.publish.intro')"></p>
    <div
      class="error-message preserve-lines"
      v-if="errorMessage">{{ errorMessage }}</div>
    <div
      v-else-if="!isValid"
      key="incomplete"
      class="alert warning preserve-lines"
      v-t="'grid.publish.incompleteWarning'"></div>
    <menu class="hero">
      <loading-button
        class="primary"
        @click="publish"
        :loading="isLoading">
        <span v-t="'grid.publish.publish'"></span>
      </loading-button>
    </menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      errorMessage: null,
      isLoading: false,
      isSteady: false,
      isPublished: false,
      isFirstGrid: false
    }
  },
  computed: {
    ...mapGetters({
      currentGrid: 'currentGrid/getCurrentGrid',
      isValid: 'sandbox/isValid'
    })
  },
  methods: {
    publish() {
      this.isLoading = true
      this.isSteady = true
      setTimeout(() => {
        this.$store.dispatch('onboarding/submitPublish').then(() => {
          this.errorMessage = null
          this.isPublished = true
          setTimeout(() => {
            this.$store.dispatch('sandbox/reset')
            this.$store.dispatch('sandbox/createSandboxGrid')
            this.$store.dispatch('onboarding/init')
            this.$store.dispatch('wagePlans/init')
            this.$store.dispatch('candidates/init')
            this.$router.push({ name: 'grid', params: { isPublishSuccess: true } })
          }, 1400)
        }).catch(error => {
          this.isLoading = false
          this.isSteady = false
          this.errorMessage = error
        })
      }, 500)
    }
  },
  created() {
    this.isFirstGrid = !this.currentGrid
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/alert.scss";
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";

@keyframes steady {
  from {
    transform: translateX(-1px)
  }
  50% {
    transform: translateX(1px)
  }
  to {
    transform: translateX(-1px)
  }
}

@keyframes go {
  from {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(-20px, 20px)
  }
  to {
    transform: translate(300px, -300px)
  }
}

@keyframes fire {
  from {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  13% {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}

.publish {
  @include modal-container;
  background: $graph-lightblue-color;
  text-align: left;
  width: 525px;

  &.small {
    width: 440px;
  }
}

h2 {
  text-align: center;
}

.publish-illustration-frame {
  position: relative;
  margin: auto;
  width: 120px;
  height: 120px;
  border-radius: 84px;
  border: 1px solid $graph-outer-border-color;
  box-shadow: inset 0px 3px 0 rgba($graph-outer-border-color, 0.3);
  background: white;
  overflow: hidden;
}

.publish-illustration {
  position: relative;
  margin: auto;
  width: 64px;
  height: 64px;
  left: -3px;
  margin-top: 28px;
  background: transparent url(~@/assets/launch.svg) no-repeat center center;

  &.steady {
    animation: steady 100ms infinite;
  }

  &.go {
    animation: go 1200ms;
    animation-fill-mode: forwards;

    &::before {
      content: ' ';
      display: block;
      width: 14px;
      height: 150px;
      border-radius: 14px;
      background: lighten($graph-purple-color, 50);
      transform: rotate(45deg) translate(-13px, 44px);
      animation: fire 1200ms;
    }
  }
}
</style>
