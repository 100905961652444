<template>
  <div>
    <template v-if="usersLoaded">
      <user-access-rights-summary v-if="!user" @select="selectFilter" :currentFilter="currentFilter" />
      <template v-if="users.length > 0">
        <div
          class="user-access-rights-row-header">
          <div v-t="'settings.settingsCompanyAccessRights.employee'"></div>
          <div></div>
          <div v-t="'settings.settingsCompanyAccessRights.updatedAt'"></div>
          <div v-t="'settings.settingsCompanyAccessRights.status'"></div>
          <div v-t="'settings.settingsCompanyAccessRights.sync'"></div>
          <div v-t="'settings.settingsCompanyAccessRights.clearwageLogin'"></div>
        </div>
        <div class="user-access-rights-row-container">
          <user-access-rights-row
            v-for="user in limitedUsers"
            :key="user.id"
            :user="user" />
          <div v-if="shouldDisplayLimitToggle" @click="toggleLimit">
            <div class="user-toggle-limit" v-t="limitedUsers.length == this.limit ? 'common.showAll' : 'common.showLess'"></div>
          </div>
        </div>
        <footer class="settings-floating-footer">
          <div>
            {{$t('settings.settingsCompanyAccessRights.usersLoaded', {updatedAt: $options.filters.formatCalendar(usersLoaded)})}}
            <button
              v-if="!user"
              class="small-button"
              v-t="isRefreshing ? 'common.ellipsis' : 'settings.settingsCompanyAccessRights.refresh'"
              :disabled="isRefreshing"
              @click="loadUsers">
            </button>
          </div>
          <div>
            <loading-button
              v-if="!user"
              class="small-button green"
              @click="inviteAll"
              :loading="isLoading">
              <span v-t="'settings.settingsCompanyAccessRights.inviteAll'"></span>
            </loading-button>
          </div>
        </footer>
      </template>
    </template>
    <loading-view v-else />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoadingView from '@components/commons/LoadingView'
import UserAccessRightsRow from '@components/settings/UserAccessRightsRow.vue'
import UserAccessRightsSummary from '@components/settings/UserAccessRightsSummary.vue'

export default {
  props: {
    user: {
      type: Object,
      default() {
        return null
      }
    },
    limit: {
      type: Number,
      default: 25
    }
  },
  data() {
    return {
      isLimited: true,
      isLoading: false,
      isRefreshing: false,
      currentFilter: 'users'
    }
  },
  computed: {
    ...mapGetters({
      isOktaActive: 'account/isOktaActive'
    }),
    ...mapGetters('company', {
      nonInvitedUsers: 'nonInvitedUsers',
      pendingInvitations: 'pendingInvitations',
      users: 'users',
      usersLoaded: 'usersLoaded',
      usersWithAccount: 'usersWithAccount'
    }),
    filteredUsers() {
      if (this.user) {
        const companyUser = this.users.find(u => u.id === this.user.id)
        return companyUser ? [companyUser] : []
      }
      else {
        return this[this.currentFilter]
      }
    },
    limitedUsers() {
      if (this.limit && this.isLimited) {
        return this.filteredUsers.slice(0, this.limit)
      }
      else {
        return this.filteredUsers
      }
    },
    shouldDisplayLimitToggle() {
      return this.limit && this.filteredUsers.length > this.limit
    }

  },
  methods: {
    async loadUsers() {
      this.isRefreshing = true
      await this.$store.dispatch('company/getUsers')
      this.isRefreshing = false
    },
    selectFilter(filter) {
      this.currentFilter = filter
    },
    toggleLimit() {
      this.isLimited = !this.isLimited
    },
    async inviteAll() {
      if (confirm(this.$t('settings.settingsCompanyAccessRights.inviteAllConfirmation'))) {
        this.isLoading = true

        try {
          await this.$store.dispatch('company/inviteAll')
        }
        catch (error) {
          error && alert(error)
        }
        finally {
          this.isLoading = false
        }
      }
    }
  },
  created() {
    this.loadUsers()
  },
  components: {
    LoadingView,
    UserAccessRightsRow,
    UserAccessRightsSummary
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/settings.scss";

$table-padding: 6px;

.user-access-rights-row-header,
.user-access-rights-row-container::v-deep .employee-row {
  display: grid;
  grid-template-columns: 30px 185px 50px 1fr 1fr 114px;
  grid-gap: 7px;
  align-items: center;
  align-content: center;
  padding: $table-padding 0;
  border-bottom: $border;

  & > div:nth-child(3),
  & > div:nth-child(4),
  & > div:nth-child(5),
  & > div:nth-child(6) {
    text-align: center;
  }
}

.user-access-rights-row-container::v-deep .employee-row {
  & > div:last-of-type {
    text-align: right;
  }
}

.user-access-rights-row-header {
  @include font-tiny-size;
  @include font-semibold;
  text-transform: uppercase;
  padding-bottom: $table-padding;

  &.four-columns > div {
    white-space: pre-line;
  }
}

.user-toggle-limit {
  text-align: center;
  text-transform: uppercase;
  @include font-bold;
  @include font-smaller-size;
  color: $clearteal-color;
  line-height: 3em;
  cursor: pointer;

  &:hover {
    background: $lightteal-color;
  }
}
</style>
