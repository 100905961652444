<template>
  <div class="wrapper">
    <div class="svg-container" :class="{loading: isLoading}">
      <LoadingView v-if="isLoading" />
      <div v-else>
      <div class="table-container">
        <table class="blue no-bg">
          <tr class="row">
            <th
              v-for="(field, i) in fieldsColumns"
              class="field clickable"
              :key="i">
              <template v-if="i > 0">
                <dropdown
                  class="blue"
                  :items="getFieldOptions(i)"
                  @change="(_, value) => setField(i, value)">{{field.name}}<span class="down">▾</span>
                </dropdown>
              </template>
              <template v-else>
                {{field.name}}
              </template>
            </th>
            <th
              v-for="(domain, i) in domainsColumns"
              class="domain"
              :class="{current: domain.name === currentDomain}"
              :key="domain.id">
              <button
                v-if="i === 0"
                class="small-button blue arrow arrow-left"
                @click="setOffset(-1)"></button>
              {{formatDomain(domain.name)}}
              <button
                v-if="i === domainsColumns.length - 1"
                class="small-button blue arrow arrow-right"
                @click="setOffset(1)"></button>
            </th>
          </tr>
          <tr class="row clickable"
            v-for="employee in data"
            :key="employee.id">
            <td
              v-for="(field, i) in fieldsColumns"
              :key="i"
              class="field"
              :title="employee[field.id]"
              @click="$router.push({name: 'employee', params: {id: employee.id}})">
              {{field.id === 'arrivalDateDuration' ? $options.filters.formatDate(employee[field.id]) : employee[field.id]}}
            </td>
            <td v-for="(domain, i) in domainsColumns"
              :key="domain.id"
              class="domain"
              :class="{dimmed: employee.domains[i] === null}">
              <template v-if="employee.domains[i] !== null">
                {{employee.domains[i].value | formattedCurrency }}
                <template v-if="employee.domains[i].percent">
                  <span class="percent" :class="{inverted: employee.domains[i].percent < 0}">{{Math.round(employee.domains[i].percent)}}%</span>
                  <div
                    v-if="totals && totals.domains && totals.domains[i] && totals.domains[i].max"
                    class="progress"
                    :style="`width: ${Math.min(Math.round(employee.domains[i].percent / totals.domains[i].max * 100), 100)}%`"></div>
                </template>
              </template>
            </td>
          </tr>
        </table>
      </div>
      <GraphLegend id="table" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoadingView from '@components/commons/LoadingView'
import { formatDate, normalizeDate } from '@/utils/date'
import GraphLegend from '@components/graph/GraphLegend.vue'
import Dropdown from '@components/commons/Dropdown.vue'
import flatten from 'lodash.flatten'

export default {
  components: {
    Dropdown,
    GraphLegend,
    LoadingView
  },
  name: 'table-graph',
  data() {
    return {
      data: null,
      isLoading: true,
      loadingTimeout: null,
      currentDomain: null,
      domains: [],
      totals: null,
      offset: 0
    }
  },
  computed: {
    ...mapGetters({
      availableScopes: 'statistics/getAvailableScopes',
      employees: 'statistics/getFilteredEmployees',
      filters: 'statistics/getUsableFilters',
      options: 'statistics/getCurrentOptions',
      scope: 'statistics/getCurrentScope'
    }),
    fields() {
      const { tableField1, tableField2 } = this.options
      return ['fullName', tableField1, tableField2]
    },
    fieldsColumns() {
      return this.fields.map(f => {
        switch (f) {
          case 'fullName':
            return { id: 'fullName', name: this.$t('dashboard.graphs.table.employee') }
          default:
            const scope = this.availableScopes.find(s => s.value === f)
            return scope ? { id: scope.value, name: scope.title } : { id: f, name: f }
        }
      })
    },
    domainsColumns() {
      return this.domains.map(domain => {
        return {
          id: domain,
          name: domain
        }
      })
    }
  },
  watch: {
    employees: 'render',
    filters: 'render',
    'options.recurrence': 'resetOffsetAndRender',
    scope: 'render',
    $route: 'download'
  },
  methods: {
    setOffset(delta) {
      this.offset += delta
      this.render()
    },
    formatDomain(domain) {
      const [year, month] = domain.split('-')
      const { recurrence } = this.options
      switch (recurrence) {
        case 'yearly':
          return formatDate(domain, 'YYYY')
        case 'biannual':
          const semester = ['06', '12'].indexOf(month) + 1
          return this.$t('dashboard.graphs.evolution.s', { semester: semester }) + ' ' + year
        case 'quarterly':
          const quarter = ['03', '06', '09', '12'].indexOf(month) + 1
          return this.$t('dashboard.graphs.evolution.q', { quarter: quarter }) + ' ' + year
        default:
          return formatDate(domain, 'MM/YYYY')
      }
    },
    resetOffsetAndRender() {
      this.offset = 0
      this.render()
    },
    getFieldOptions(i) {
      const selectedScope = this.fields[i]
      return [{
        name: this.$t('dashboard.filters.show'),
        value: 'scope',
        items: this.availableScopes.map(item => ({
          name: item.title,
          value: item.value,
          selected: selectedScope === item.value
        }))
      }]
    },
    setField(i, value) {
      switch (i) {
        case 1:
          this.$store.dispatch('statistics/setOption', { option: 'tableField1', value })
          break
        case 2:
          this.$store.dispatch('statistics/setOption', { option: 'tableField2', value })
          break
      }
      this.render()
    },
    generateTableRows() {
      const header = [
        ...this.fieldsColumns.map(({ name }) => name),
        ...flatten(this.domainsColumns.map(({ name }) => [
          this.formatDomain(name),
          this.formatDomain(name) + ' (%)']))
      ]
      const rows = this.data.map(employee => [
        ...this.fieldsColumns.map(({ id }) => id === 'arrivalDateDuration' ? new Date(employee[id]) : employee[id]),
        ...flatten(this.domainsColumns.map((_, i) =>
          [employee.domains[i] && employee.domains[i].value,
            ((employee.domains[i] && Math.round(employee.domains[i].percent)) || 0) + '%']))
      ])
      return [header, ...rows]
    },
    download() {
      if (this.$route.hash === '#download') {
        const rows = this.generateTableRows()
        const filename = this.$t('dashboard.graphs.table.export.filename', { date: normalizeDate(new Date()) })
        this.$router.replace({ name: 'export', params: { rows, filename } })
      }
    },
    async render() {
      if (this.employees) {
        await this.generateTableModel(this.graph, this.employees, this.offset)
      }
    },
    async generateTableModel() {
      clearTimeout(this.loadingTimeout)
      this.loadingTimeout = setTimeout(() => {
        this.isLoading = true
      }, 300)
      try {
        const employeesNewsfeeds = await this.$store.dispatch('statistics/getEmployeesNewsfeeds')
        const { employees, filters, offset, options, scope, fields } = this
        const { data, domains, totals, currentDomain } = await this.$store.dispatch('workers/generateTableModel', { employees, employeesNewsfeeds, filters, offset, options, scope, fields })
        this.data = data
        this.domains = domains
        this.totals = totals
        this.currentDomain = currentDomain
      }
      finally {
        clearTimeout(this.loadingTimeout)
        this.isLoading = false
      }
    }
  },
  mounted() {
    setTimeout(this.render, 400)
  }
}

</script>

<style lang="scss" scoped>
@import "./src/styles/alert.scss";
@import "./src/styles/button.scss";
@import "./src/styles/graph.scss";
@import "./src/styles/table.scss";

.wrapper .svg-container {
  overflow: visible;
}

.table-container {
  margin-top: 5px;
  border: 1px solid $graph-outer-border-color;
  border-radius: $border-radius;
  box-shadow: 1px 1.5px 0 rgba(0, 0, 0, 0.03);
  margin-bottom: 20px;
}

table {
  table-layout: fixed;
  @include font-small-size;

  th,
  td {
    @include line-regular-height;
    padding: 0.25em 0.5em;
    border: 1px solid $graph-outer-border-color;
    @include font-tabular-numbers;

    &.field {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &.domain {
      text-align: right;
      width: 112px;
      padding-left: 0;
      cursor: text;
      position: relative;

      &.current {
        color: $graph-darkblue-color;
      }

      .percent {
        float: left;
        color: darken($clearteal-color, 5);
        @include font-smaller-size;
        @include font-semibold;
        width: 40px;
        display: flex;
        align-items: center;

        &::before {
          content: ' ';
          display: inline-block;
          width: 15px;
          height: 8px;
          margin-left: 1px;
          background: transparent url(~@/assets/triangle-up-green.svg) no-repeat center center;
        }

        &.inverted::before {
          transform: scaleY(-1);
        }
      }

      .progress {
        position: absolute;
        height: 2px;
        background: lighten($clearteal-color, 20);
        bottom: -1px;
      }
    }
  }

  th {
    @include font-medium;
    font-weight: 450;
  }

  td.dimmed {
    background-color: lighten($graph-lightblue-color, 1);
  }

  .row:hover td.dimmed {
    background-color: inherit;
  }
}

.arrow {
  padding: 2.5px 3px;
  margin: -2px -2px;

  &.arrow-left {
    float: left;
    margin-left: 5px;
  }

  &.arrow-right {
    float: right;
    margin-left: 5px;
  }

  &::before {
    content: " ";
    display: inline-block;
    width: 1em;
    height: 1em;
    background: transparent url(~@/assets/triangle-down.svg) no-repeat center
      center;
    background-size: contain;
    transform: rotate(90deg);
    vertical-align: middle;
  }

  &.arrow-right::before {
    transform: rotate(-90deg);
  }

  &[disabled]::before {
    opacity: 0.5;
  }
}

.dropdown {
  margin-left: -3px;

  &::v-deep .dropdown-button {
    padding: 3px 7px;
  }
}

.wrapper::v-deep .legend-container .legend {
  display: none;
}
</style>
