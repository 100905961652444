export const REVIEW_STATUS = {
  draft: 'draft',
  ongoing: 'ongoing',
  closed: 'closed'
}
export const REVIEW_STATUSES = [REVIEW_STATUS.draft, REVIEW_STATUS.ongoing, REVIEW_STATUS.closed]

export const REVIEW_STEP = {
  reference: 'reference',
  employee: 'employee',
  manager: 'manager',
  synthesis: 'synthesis'
}

export function isReviewSettingsDisabled(review) {
  return isReviewClosed(review)
}

export function isReviewClosed(review) {
  return review.status === REVIEW_STATUS.closed
}

export const WAGE_REVIEW_STATUS = {
  todo: null,
  ongoing: 'ongoing',
  done: 'done'
}

export function isWageReviewTodo(wageReview) {
  return !wageReview.status
}

export function isWageReviewOngoing(wageReview) {
  return wageReview.status === WAGE_REVIEW_STATUS.ongoing
}

export function isWageReviewDone(wageReview) {
  return wageReview.status === WAGE_REVIEW_STATUS.done
}

export function getVisibleSteps(steps) {
  return steps.filter(s => s.kind !== REVIEW_STEP.reference)
}

export function getReferenceStepsKinds(step) {
  switch (step.kind) {
    case REVIEW_STEP.synthesis:
      return [REVIEW_STEP.reference, REVIEW_STEP.employee, REVIEW_STEP.manager]
    default:
      return [REVIEW_STEP.reference]
  }
}

// Employee can read its own review but not the manager
// Employee can read the synthesis step if done
export function hasStepReadPermission(step, employee, user, wage) {
  switch (step.kind) {
    case REVIEW_STEP.employee:
      return employee.id === user.id || (employee.managerId === user.id && WAGE_REVIEW_STATUS.done === wage.review.status)
    case REVIEW_STEP.manager:
      return employee.managerId === user.id
    case REVIEW_STEP.synthesis:
      return employee.managerId === user.id || WAGE_REVIEW_STATUS.done === wage.review.status || user.status === 'admin'
  }
}

// Employee can update its own review, Manager can update the others
export function hasStepUpdatePermission(step, employee, user, review) {
  if (isReviewClosed(review)) {
    return false
  }
  switch (step.kind) {
    case REVIEW_STEP.employee:
      return employee.id === user.id
    default:
      return employee.managerId === user.id
  }
}
