<template>
  <div class="demo-banner">
    <div class="demo-content">
      <div class="demo-text">
        <div class="demo-emoji">👋</div>
        <div class="preserve-lines" v-html="$t(`demo.banner.${banner}.message`)" />
      </div>
      <div class="demo-action">
        <button
          class="secondary"
          @click="$$openUrl($t(`demo.banner.${banner}.url`))"
          v-t="`demo.banner.${banner}.action`" />
      </div>
    </div>
  </div>
</template>

<script>
import { getSessionItem } from '@/utils/storage'

const CW_DEMO_TOKEN = 'CW_DEMO_TOKEN'

export default {
  data() {
    const demoToken = getSessionItem(CW_DEMO_TOKEN)
    return {
      demoToken
    }
  },
  computed: {
    banner() {
      return this.$te(`demo.banner.${this.demoToken}.message`) ? this.demoToken : 'default'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";

.demo-banner {
  background-color: darken($lightteal-color, 5);
  padding: 0.75rem;
}

.demo-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.demo-text {
  display: flex;
  align-items: center;
}

.demo-emoji {
  @include font-title-size;
  margin-right: 1rem;
}

.demo-action button {
  box-shadow: inset 0px 0px 0px 1px darken($lightteal-color, 25);
}

.demo-content {
  @include font-medium;
  @include font-small-size;
  @include line-regular-height;
  color: darken($clearteal-color, 15);
}

@media screen and (min-width: $content-max-width + 40px) {
  .demo-content {
    margin: 0 auto;
    width: $content-max-width;
  }
}
</style>
