<template>
  <div v-if="$$isAdmin && permissionModel" class="permission-button-container">
    <button
      class="permission-button"
      :class="small ? 'small-button green' : 'secondary'"
      @click="isModalVisible = true">
      {{$t(`settings.company.permissions.values.${getPermission(permission)}.title`)}}
      <span class="down"></span>
    </button>

    <modal :visible="isModalVisible" @close="close">
      <div class="permission-modal">
        <h1 v-t="'settings.company.permissions.modal.title'"></h1>
        <p v-t="'settings.company.permissions.modal.intro'"></p>
        <form @submit.prevent="submit()">
          <radioInputs
            :items="availablePermissionValues"
            localePrefix="settings.company.permissions.values"
            v-model="permissionModel" />
        </form>
        <p v-if="hasPermissionExplanation" class="light-text">
          {{$t(`settings.company.permissions.permissions.${permission}.explanation`)}}
        </p>
        <p class="preserve-lines light-text no-margin-bottom" v-if="$route.name !== 'settingsCompanyAccessRightsPermissions'" v-html="$t('settings.company.permissions.modal.footer')"></p>
        <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>
        <menu class="no-margin-bottom">
          <button
            class="secondary"
            @click="close"
            v-t="'common.cancel'"></button>
          <loading-button
            class="primary"
            :disabled="isLoading"
            @click="submit"
            :loading="isLoading">
            <span v-t="'common.update'"></span>
          </loading-button>
        </menu>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Modal from '@components/commons/Modal.vue'
import RadioInputs from '@components/commons/RadioInputs.vue'
import { COMPANY_PERMISSIONS, COMPANY_PERMISSION_VALUES } from '@/utils/permissions'

export default {
  components: {
    Modal,
    RadioInputs
  },
  props: {
    scope: {
      type: String,
      // In the future, we might have wagePlan, hiringPlan, etc…
      default: 'company'
    },
    permission: {
      type: String
    },
    // Small skin
    small: {
      type: Boolean
    },
    explanation: {
      type: String
    }
  },
  data() {
    return {
      errorMessage: null,
      isModalVisible: false,
      isLoading: false,
      permissionModel: null
    }
  },
  computed: {
    ...mapGetters({
      getCompanyPermission: 'company/getCompanyPermission',
      getPermission: 'company/getPermission'
    }),
    availablePermissionValues() {
      const companyPermission = COMPANY_PERMISSIONS[this.permission] || {}
      const permissionValues = Object.values(COMPANY_PERMISSION_VALUES)
      return (companyPermission.includeNobody ? ['nobody'] : [])
        .concat(permissionValues.filter(permission => {
          return !companyPermission.rejectedPermissions ||
          !companyPermission.rejectedPermissions.includes(permission)
        }))
    },
    hasPermissionExplanation() {
      const companyPermission = COMPANY_PERMISSIONS[this.permission] || {}
      return companyPermission.explanation
    }
  },
  methods: {
    initModel() {
      this.permissionModel = this.getPermission(this.permission)
    },
    close() {
      this.isModalVisible = false
    },
    async submit() {
      this.isLoading = true

      try {
        const permission = this.permission
        const value = this.permissionModel
        await this.$store.dispatch('company/updatePermission', { permission, value })
        this.close()
      }
      catch (error) {
        this.errorMessage = error
      }
      finally {
        this.isLoading = false
      }
    }
  },
  created() {
    this.initModel()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";

.permission-button-container {
  display: inline-block;
}

.permission-button {
  .down {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: transparent url(~@/assets/icon-lock-green.svg) no-repeat center
      center;
    background-size: contain;
    vertical-align: -1px;
    margin-left: 3px;
  }
}

.permission-modal {
  width: 650px;
  padding: 20px;

  h1 {
    background: transparent url(~@/assets/icon-lock.svg) no-repeat left center;
    padding-left: 30px;
  }
}
</style>
