<template>
  <div>
    <div class="editor" v-if="benefits.length">
      <div class="toolbar">
        <h3>
          <span>{{$tc('benefits.title', benefits.length)}}</span>
          <span v-if="benefits.length" class="badge small-badge border-badge">
            {{benefits.length}}
          </span>
        </h3>
        <button
          class="primary"
          v-t="'benefits.add'"
          @click="isBenefitFormDisplayed = true" />
      </div>
      <div class="benefits">
        <BenefitItem
          v-for="benefit in benefits"
          :key="benefit.id"
          :benefit="benefit"
          @click.native="benefitSelected(benefit)" />
      </div>
    </div>
    <menu class="hero placeholder no-margin-bottom" v-else>
      <p class="no-margin-top" v-t="'benefits.group.benefitsExplanation'" />
      <button
        class="primary"
        v-t="'benefits.addBenefit'"
        @click="isBenefitFormDisplayed = true" />
    </menu>
    <Modal :visible="isBenefitFormDisplayed" @close="closeForm">
      <BenefitForm :benefit="selectedBenefit" @close="closeForm" />
    </Modal>
  </div>
</template>

<script>
import BenefitForm from '@components/benefits/BenefitForm'
import BenefitItem from '@components/benefits/BenefitItem'
import Modal from '@components/commons/Modal'

export default {
  components: {
    BenefitForm,
    BenefitItem,
    Modal
  },
  data() {
    return {
      isBenefitFormDisplayed: false,
      selectedBenefit: null
    }
  },
  computed: {
    benefits() {
      return this.$store.getters['benefits/getCurrentBenefitsPlan'].benefits
    }
  },
  methods: {
    benefitSelected(benefit) {
      this.selectedBenefit = benefit
      this.isBenefitFormDisplayed = true
    },
    closeForm() {
      this.isBenefitFormDisplayed = false
      this.selectedBenefit = null
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/badge.scss";
@import "./src/styles/button.scss";

.badge {
  margin-left: 0.5em;
}

.editor {
  @extend .badge-block;
  display: block;
  width: 100%;
  padding: 0.8em 1em 0.8em 1em;
  margin-top: 1.5em;
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.8em;

  h3 {
    margin: 0;
  }
}

.placeholder {
  @extend .badge-block;
  display: block;
  margin-top: 2em;
  padding: 1em 1em;
}

.benefits {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  background: white;
  border: $border;
  border-radius: $border-radius;
  padding: 1em;
}
</style>
