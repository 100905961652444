import Vue from 'vue'
import api from '@/store/api'
import i18n from '@/i18n'
import { formatList } from '@/utils/string'
import { dateDiff } from '@/utils/date'
import { getItem, setItem } from '@/utils/storage'
import {
  normalizedChanges,
  denormalizedChanges
} from '@/store/schema'
import uniq from 'lodash.uniq'
import sortBy from 'lodash.sortby'

const CW_SYNCHRONIZATION_STATE = 'CW_SYNCHRONIZATION_STATE'
const CW_SYNCHRONIZATION_TOOLTIP_SEEN = 'CW_SYNCHRONIZATION_TOOLTIP_SEEN'

const initialState = () => {
  return {
    // Changes
    changeIds: null,
    changes: {},
    changesUpdatedAt: null,
    loadingPromise: null,
    thirdPartyUsers: {},
    // Changes history
    changesHistory: [],
    changesHistoryMetadata: {},
    isChangesHistoryLoading: false
  }
}

const saveState = () => {
  setItem(CW_SYNCHRONIZATION_STATE, state)
}

const restoreState = () => {
  let restoredState = initialState()
  const parsedState = getItem(CW_SYNCHRONIZATION_STATE, null)
  if (parsedState) {
    // Date is serialized in UTC (https://stackoverflow.com/a/1488415)
    parsedState.changesUpdatedAt = new Date(parsedState.changesUpdatedAt)
    // Ensure all keys are defined
    Object.keys(restoredState).forEach(key => {
      parsedState[key] = typeof parsedState[key] === 'undefined' ? restoredState[key] : parsedState[key]
    })
    restoredState = parsedState
  }

  return restoredState
}

const state = restoreState()

const getters = {
  isLoading(state) {
    return state.loadingPromise
  },
  isChangesHistoryLoading(state) {
    return state.isChangesHistoryLoading
  },
  isSynchronizationEnabled(_state, _getters, _rootState, rootGetters) {
    const isBambooHRActive = rootGetters['account/isBambooHRActive']
    const isLuccaActive = rootGetters['account/isLuccaActive']
    return isBambooHRActive || isLuccaActive
  },
  isWageQualificationSynchronizationEnabled(_state, _getters, _rootState, rootGetters) {
    // Only supported in Lucca for now
    const isLuccaActive = rootGetters['account/isLuccaWageQualificationActive']
    return isLuccaActive
  },
  enabledIdentityProviders(_state, _getters, _rootState, rootGetters) {
    const isBambooHRActive = rootGetters['account/isBambooHRActive']
    const isLuccaActive = rootGetters['account/isLuccaActive']
    return [
      isBambooHRActive && 'bamboo_hr',
      isLuccaActive && 'lucca'
    ].filter(s => s)
  },
  enabledIdentityProviderNames(_state, { isSynchronizationEnabled, enabledIdentityProviders }) {
    if (isSynchronizationEnabled) {
      return formatList(enabledIdentityProviders.map(p => i18n.t(`settings.settingsCompanySynchronization.identityProviders.${p}`)))
    }
    else {
      return i18n.t('settings.settingsCompanySynchronization.identityProviders.generic')
    }
  },
  changes(state) {
    return state.changeIds && denormalizedChanges(state)
  },
  availableChanges(_state, { changes }) {
    return (changes || []).filter(change => !change.applied && !change.applying)
  },
  isSynchronized(_state, { isLoading, changes }) {
    return !isLoading && !(changes || []).find(c => !c.applied)
  },
  getSynchronizableThirdPartyUsers(_state, { isSynchronizationEnabled, enabledIdentityProviders }) {
    return user => {
      // Temporariled disabled
      // eslint-disable-next-line no-constant-condition
      if (false && isSynchronizationEnabled && user && user.thirdPartyUsers) {
        return user.thirdPartyUsers.filter(t => enabledIdentityProviders.includes(t.identityProvider))
      }
      else {
        return []
      }
    }
  },
  changesUpdatedAt(state) {
    return state.changesUpdatedAt
  },
  changesHistory(state) {
    return state.changesHistory
  },
  showChangesHistoryLoadMore(state) {
    return !!state.changesHistoryMetadata.after
  },
  changesHistoryTotal(state) {
    return state.changesHistoryMetadata.totalCount || 0
  }
}

const actions = {
  reset(context) {
    context.commit('reset')
  },
  init(context, force) {
    if (context.getters.isAdminSession) {
      context.dispatch('reset')
    }
    if (context.rootGetters['account/isAdmin']) {
      context.dispatch('getChanges', force)
    }
    // Resolve instantly
    return Promise.resolve()
  },
  synchronizeUserWage(context, user) {
    const changes = context.getters.getSynchronizableThirdPartyUsers(user)
      .map(thirdPartyUser => ({ id: `urn:clw:wage_outdated:${thirdPartyUser.identityProvider}:${thirdPartyUser.externalId}` }))
    if (changes.length) {
      return context.dispatch('applyChanges', { changes })
    }
  },
  getChanges(context, params) {
    const { force, externalIds } = params || {}
    if (!context.getters.isSynchronizationEnabled) {
      return Promise.resolve([])
    }
    else if (context.state.loadingPromise) {
      return context.state.loadingPromise
    }
    else if (!force &&
      context.getters.changes &&
      dateDiff(context.getters.changesUpdatedAt, new Date(), 'days') < 1) {
      return Promise.resolve(context.getters.changes)
    }
    else {
      const loadingPromise = api.get('/synchronization/changes?' + (force ? 'force=true' : '') + (externalIds ? '&externalIds=' + externalIds : ''))
        .then(({ data, headers }) => {
          const changesUpdatedAt = new Date(headers['last-modified'])
          context.commit('setChanges', { changes: data, changesUpdatedAt })
          return data
        })
        .catch(error => {
          context.commit('reset')
          return context.dispatch('handleAPIError', error, { root: true })
        })
      context.commit('softReset')
      context.commit('setLoadingPromise', loadingPromise)
      return loadingPromise
    }
  },
  applyChanges(context, { changes }) {
    const changeIds = changes.map(c => c.id)
    context.commit('setApplyingChanges', changeIds)

    return api.put('/synchronization/changes', { changeIds })
      .then(({ data }) => {
        context.commit('setChanges', { changes: data })
        context.dispatch('getChangesHistory')
        context.dispatch('employees/needsRefresh', null, { root: true })
        return data
      })
      .catch(error => {
        context.commit('reset')
        return context.dispatch('handleAPIError', error, { root: true })
      })
  },
  async getChangesHistory({ commit, state }, loadMore = false) {
    commit('setIsChangesHistoryLoading', true)
    const after = loadMore && state.changesHistoryMetadata && state.changesHistoryMetadata.after
    const { data: { changes, metadata } } = await api.get('/synchronization/history' + (after ? '?after=' + after : ''))
    commit('setChangesHistory', { changes, metadata, loadMore: !!after })
    commit('setIsChangesHistoryLoading', false)
  }
}

const mutations = {
  reset(state) {
    Object.assign(state, initialState())
    localStorage.removeItem(CW_SYNCHRONIZATION_STATE)
    sessionStorage.removeItem(CW_SYNCHRONIZATION_TOOLTIP_SEEN)
  },
  softReset(state) {
    const { changeIds, changes, changesUpdatedAt, loadingPromise, thirdPartyUsers } = initialState()
    Object.assign(state, { changeIds, changes, changesUpdatedAt, loadingPromise, thirdPartyUsers })
    localStorage.removeItem(CW_SYNCHRONIZATION_STATE)
    sessionStorage.removeItem(CW_SYNCHRONIZATION_TOOLTIP_SEEN)
  },
  setLoadingPromise(state, loadingPromise) {
    state.loadingPromise = loadingPromise
  },
  setApplyingChanges(state, changeIds) {
    changeIds.forEach(id => {
      Vue.set(state.changes, id, { ...state.changes[id], applying: true })
    })
  },
  setChanges(state, { changes, changesUpdatedAt }) {
    state.loadingPromise = null
    state.applyChangesPromise = null
    state.changesUpdatedAt = changesUpdatedAt || new Date()

    const n = normalizedChanges(changes)
    state.changeIds = state.changeIds || []
    state.changeIds = [...state.changeIds, ...n.result]
    state.changes = { ...state.changes, ...n.entities.changes }
    state.thirdPartyUsers = { ...n.entities.thirdPartyUsers, ...state.thirdPartyUsers }

    state.changeIds = sortBy(uniq(state.changeIds), changeId => {
      let { thirdPartyUser } = state.changes[changeId]
      thirdPartyUser = state.thirdPartyUsers[thirdPartyUser]
      return [thirdPartyUser.identityProvider, thirdPartyUser.givenName, thirdPartyUser.familyName].join()
    })

    saveState()
  },
  setIsChangesHistoryLoading(state, isChangesHistoryLoading) {
    state.isChangesHistoryLoading = isChangesHistoryLoading
  },
  setChangesHistory(state, { changes, metadata, loadMore }) {
    state.changesHistory = loadMore ? [...state.changesHistory, ...changes] : [...changes]
    state.changesHistoryMetadata = metadata
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
