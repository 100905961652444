<template>
  <div>
    <div class="infos">
      <AvatarEditor v-if="isUpdate" :employee="employee" />
      <NameThumbnail v-else :employee="employee" />
      <div class="employee-detail">
        <menu class="toolbar">
          <slot></slot>
        </menu>
        <div>
          <h2 v-if="employee.firstName || employee.lastName" class="employee-name">
            {{ employee.firstName }}&nbsp;{{ employee.lastName }}
          </h2>
          <h3 v-if="jobType || employee.jobTitle" class="job-title">
            <span v-if="jobType">{{jobType}}</span>
            <span v-if="employee.jobTitle && employee.jobTitle !== jobType">{{employee.jobTitle}}</span>
          </h3>
        </div>
        <p v-if="employeeLocale">
          <span class="dot" :style="{backgroundColor}"></span>
          <span v-t="employeeLocale"></span>
        </p>
        <p class="alumni" v-if="alumniLocale">
          <span class="dot" :style="{backgroundColor}"></span>
          <span v-t="alumniLocale"></span>
        </p>
        <p v-if="situationLocale">
          <span class="dot" :style="{backgroundColor}"></span>
          <span>{{situationLocale}}</span>
        </p>
        <p v-if="currentSalaryLocale">
          <span class="dot" :style="{backgroundColor}"></span>
          <span v-html="currentSalaryLocale"></span>
        </p>
        <p v-else-if="employee.birthdate">
          <span class="dot" :style="{backgroundColor}"></span>
          <span v-t="birthdateLocale"></span>
        </p>
        <p v-if="employee.isExternal">
          <span class="dot" :style="{backgroundColor}"></span>
          <span class="light-text" v-t="isExternalLocale"></span>
        </p>
        <!-- Candidates only -->
        <p v-if="jobOfferLocale">
          <span class="dot" :style="{backgroundColor}"></span>
          <span v-t="jobOfferLocale"></span>
        </p>
        <p v-if="createdLocale">
          <span class="dot" :style="{backgroundColor}"></span>
          <span v-t="createdLocale"></span>
        </p>
        <p class="rejected" v-if="rejectedLocale">
          <span class="dot" :style="{backgroundColor}"></span>
          <span v-t="rejectedLocale"></span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { colorize } from '@/utils/color'
import NameThumbnail from '@components/commons/NameThumbnail.vue'
import AvatarEditor from '@components/employees/AvatarEditor.vue'
import { dateInFuture } from '@/utils/date'
import { getField } from '@/utils/employee'

export default {
  components: {
    AvatarEditor,
    NameThumbnail
  },
  props: {
    employee: {
      type: Object
    },
    isUpdate: {
      type: Boolean
    }
  },
  computed: {
    jobType() {
      return getField(this.employee, 'jobType', { store: this.$store })
    },
    birthdateLocale() {
      const age = this.$options.filters.yearsDiffFromNow(this.employee.birthdate)

      return {
        path: 'employees.employee.detail.birthdate',
        args: {
          birthdate: this.$options.filters.fullFormattedDate(this.employee.birthdate),
          diff: this.$tc('employees.employee.detail.years', age, { age })
        }
      }
    },
    isExternalLocale() {
      return {
        path: 'employees.employee.detail.isExternal'
      }
    },
    employeeLocale() {
      if (this.employee.isAlumni) {
        return {
          path: 'employees.employee.detail.alumniStatus',
          args: {
            companyName: this.$$companyName,
            arrivalDate: this.$options.filters.fullFormattedDate(this.employee.arrivalDate),
            diff: this.$options.filters.fullDiff(this.employee.arrivalDate, this.employee.departureDate)
          }
        }
      }
      else if (dateInFuture(this.employee.arrivalDate)) {
        return {
          path: 'employees.employee.detail.futureStatus',
          args: {
            companyName: this.$$companyName,
            arrivalDate: this.$options.filters.fullFormattedDate(this.employee.arrivalDate),
            diff: this.$options.filters.fullDiffFromNow(this.employee.arrivalDate)
          }
        }
      }
      else if (this.employee.arrivalDate) {
        return {
          path: 'employees.employee.detail.status',
          args: {
            companyName: this.$$companyName,
            arrivalDate: this.$options.filters.fullFormattedDate(this.employee.arrivalDate),
            diff: this.$options.filters.fullDiffFromNow(this.employee.arrivalDate)
          }
        }
      }
    },
    situationLocale() {
      const contractType = this.employee.contractType

      const city = this.employee.city
      if (contractType && city) {
        return this.$t('employees.employee.detail.situation', {
          contractType: contractType,
          city: city
        })
      }
      else if (contractType) {
        return contractType
      }
      else if (city) {
        return city
      }
    },
    currentSalaryLocale() {
      // Defined in grid editor
      if (this.employee.currentSalary) {
        return this.$t(
          'employees.employee.detail.currentSalary',
          {
            salary: this.$options.filters.formattedNumber(this.employee.currentSalary)
          }
        )
      }
    },
    alumniLocale() {
      if (this.employee.isAlumni || this.employee.departureDate) {
        return {
          path: 'employees.employee.' + (dateInFuture(this.employee.departureDate) ? 'willBeAlumniAlert' : 'isAlumniAlert'),
          args: {
            employeeName: this.employee.firstName,
            companyName: this.$$companyName,
            date: this.$options.filters.fullFormattedDate(this.employee.departureDate),
            diff: this.$options.filters.fullDiffFromNow(this.employee.departureDate)
          }
        }
      }
    },
    jobOfferLocale() {
      if (this.employee.jobOffer) {
        let jobOffer
        if (this.employee.jobOffer && this.employee.jobOffer.name) {
          jobOffer = this.employee.jobOffer.name
        }
        else {
          jobOffer = this.$store.getters['candidates/getJobOffer'](this.employee.jobOffer).name
        }
        return {
          path: 'candidates.details.jobOffer',
          args: {
            jobOffer
          }
        }
      }
    },
    createdLocale() {
      if (this.employee.wageProposal) {
        return {
          path: 'candidates.details.addedOn',
          args: {
            date: this.$options.filters.fullFormattedDate(this.employee.createdAt),
            diff: this.$options.filters.fullDiffFromNow(this.employee.createdAt)
          }
        }
      }
    },
    rejectedLocale() {
      if (this.employee.rejected) {
        return {
          path: 'candidates.details.rejectionInfo',
          args: {
            candidateName: this.employee.firstName,
            date: this.$options.filters.fullFormattedDate(this.employee.updatedAt)
          }
        }
      }
    },
    backgroundColor() {
      return colorize(this.employee.firstName)
    }
  }
}
</script>

<style lang="scss" scoped>
.infos {
  display: grid;
  grid-template-columns: 125px 1fr;
  align-items: center;
  border: $border;
  border-radius: $border-radius;
  padding: 20px;
  background: white;
}

.employee-detail {
  width: 100%;
  margin-left: 20px;
}

.employee-name {
  margin-bottom: 0.25em;
}

.job-title {
  margin-bottom: 0.7em;
  color: $light-text-color;
  @include font-regular;

  span + span::before {
    content: "/";
    display: inline-block;
    @include font-small-size;
    margin: 0 0.5em;
  }
}

p {
  margin: 0.3em 0;

  &:last-child {
    margin: 0;
  }
}

.toolbar {
  float: right;
  margin: 0 20px 0 0;
}

.placeholder {
  margin: 0;
}

.dot {
  display: inline-block;
  width: 0.8em;
  height: 0.8em;
  border-radius: 0.8em;
  margin-right: 0.5em;
  vertical-align: -1px;
  box-shadow: inset 0 1em rgba(64, 73, 71, 0.05);
}

.alumni,
.rejected {
  @include font-semibold;
}
</style>
