<template>
  <router-link
    tag="button"
    class="secondary"
    :to="{name: 'exportGrid', params: {gridId: this.grid && this.grid.id}}"
    v-t="'common.export'">
  </router-link>
</template>

<script>
export default {
  props: {
    grid: {
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
</style>
