<template>
  <div
    class="formula"
    :class="[
      layout,
      index === 0 ? 'root' : 'group',
      `group-${ layout == 'vertical' ? index : inversedIndex}`]">
    <div
      v-for="(component, i) in computedFormula.components"
      :key="component.id">
      <div
        v-if="i !== 0"
        class="operation">
        {{ operationSymbol(component.operation) }}
      </div>
      <div
        v-if="component.name"
        class="component"
        :class="{clickable: isClickable}"
        @click="select(component)">
        {{ component.name }}
      </div>
      <appGridFormulaDetail
        v-else
        @select="select"
        :layout="layout"
        :formula="component"
        :index="index + 1"
        :total="depth">
      </appGridFormulaDetail>
    </div>
  </div>
</template>
<script>
import { remunerationOperationSymbol, gridFormulaForRemunerationType } from '@/utils/grid'

export default {
  name: 'appGridFormulaDetail',
  props: {
    layout: {
      type: String,
      default: 'vertical'
    },
    formula: {
      type: Object
    },
    grid: {
      type: Object
    },
    index: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
      default: 0
    }
  },
  computed: {
    computedFormula() {
      return this.grid ? gridFormulaForRemunerationType(this.grid, 'salary') : this.formula
    },
    depth() {
      if (this.total) {
        return this.total
      }
      else {
        return this.computeDepth(this.computedFormula)
      }
    },
    inversedIndex() {
      return this.depth - this.index
    },
    isClickable() {
      return (this.index === 0 && !!this.$listeners.select) || this.$parent.isClickable
    }
  },
  methods: {
    computeDepth(formula, index = 0) {
      if (formula.components) {
        return 1 + formula.components.reduce((memo, component) => {
          memo += this.computeDepth(component, index)
          return memo
        }, 0)
      }
      else {
        return 0
      }
    },
    operationSymbol(operation) {
      return remunerationOperationSymbol(operation)
    },
    select(component) {
      this.$emit('select', component)
    }
  }
}
</script>

<style lang="scss" scoped>
.root {
  overflow: hidden;
}

div {
  display: inline-block;
  font-size: 0; // Disable undesired spaces
}

.component {
  @include font-normal-size;
  @include font-semibold;
  line-height: 30px;
  padding: 0 10px;
  color: inherit;
}

.operation {
  display: inline-block;
  @include font-big-size;
  @include font-semibold;
  @include font-tabular-numbers;
  line-height: 30px;
  padding: 0 5px;
  color: $text-color;
  vertical-align: -2px;
}

.clickable {
  cursor: pointer;
}

// Horizontal layout {
.formula.horizontal {
  $line-height: 40px;
  padding-left: 12px;

  &.root {
    border: 1px solid lighten($graph-darkblue-color, 25);
    border-radius: calc($line-height / 2);
    padding-right: 12px;
  }

  .component {
    @include font-big-size;
    color: $graph-darkblue-color;

    &.clickable:hover {
      text-decoration: underline;
    }
  }

  .component, .operation {
    line-height: $line-height;
  }

  &.group {
    padding-right: 12px;
    margin-right: 8px;
    margin-left: -12px;
    border-radius: calc($line-height / 2);
  }

  &.group-1 {
    background: lighten($graph-blue-color, 1);
  }

  &.group-2 {
    background: lighten($graph-blue-color, 7);
  }

  &.group-3 {
    background: white;
  }
}

// Vertical layout
.formula.vertical {
  display: grid;

  .operation {
    margin-left: -4px;
    padding-left: 26px;
  }

  .component {
    margin: 3px;
    background-color: $graph-accentblue-color;
    border-radius: $border-radius;
    color: inherit;
    box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.08);
    max-width: 130px;
    overflow: hidden;
    vertical-align: bottom;
    text-overflow: ellipsis;

    &:hover {
      background: white;
    }
  }

  .group {
    border: 1px solid $graph-accentblue-color;
    box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.015);
    border-radius: $border-radius;
    margin-bottom: 5px;
    padding: 4px;
  }

  .group-1 {
    background: lighten($graph-blue-color, 1);
  }

  .group-2 {
    background: lighten($graph-blue-color, 2);
  }

  .group-1 .operation {
    padding-left: 21px;
  }

  .group-2 .operation {
    padding-left: 16px;
  }
}
</style>
