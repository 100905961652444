<template>
  <div class="wage-plan-simulation">
    <h1 v-t="'wagePlans.simulation.title'"></h1>
    <p v-t="'wagePlans.simulation.intro'"></p>
    <div class="simulation-actions">
      <div>
        <div class="input-label big" v-t="'wagePlans.simulation.grid.title'"></div>
        <p v-t="'wagePlans.simulation.grid.intro'"></p>
        <p>
          <select v-model="selectedComponentId">
            <option
              v-for="component in gridComponents"
              :key="component.id"
              :value="component.id">{{component.name}}</option>
            <option
              :value="null"
              v-if="!gridComponents.length"
              v-t="'wagePlans.simulation.grid.noGrid'"></option>
          </select>
        </p>
        <button
          class="primary"
          v-t="'common.apply'"
          :disabled="!selectedComponentId"
          @click="applyNextLevel"></button>
      </div>
      <div class="hr"></div>
      <div>
        <div class="input-label big" v-t="'common.salary'"></div>
        <p v-t="'wagePlans.simulation.salary.intro'"></p>
        <p>
          <operation-input
            class="green"
            operation="percent"
            :min="-100"
            :step="1"
            v-model="salaryRaisePercent" />
        </p>
        <button
          class="primary"
          v-t="'common.apply'"
          @click="applySalaryRaise"></button>
      </div>
      <div class="hr"></div>
      <div>
        <div class="input-label big" v-t="'common.reset'"></div>
        <p v-t="'wagePlans.simulation.reset.intro'"></p>
        <button
          class="primary reset-button"
          v-t="'common.apply'"
          @click="applyReset"></button>
      </div>
    </div>
  </div>
</template>

<script>
import OperationInput from '@components/commons/OperationInput.vue'

export default {
  components: {
    OperationInput
  },
  data() {
    return {
      selectedComponentId: null,
      salaryRaisePercent: 3
    }
  },
  computed: {
    gridComponents() {
      return this.$store.getters['currentGrid/allComponents']
        .filter(c => !['role', 'city'].includes(c.ref))
    },
    selectedComponentLabel() {
      const component = this.gridComponents.find(c => c.id === this.selectedComponentId)
      return component ? component.name : '…'
    },
    componentsDropdownItems() {
      return [{
        name: 'Composant',
        items: this.gridComponents.map(component => ({
          name: component.name,
          value: component.id,
          selected: this.selectedComponentId === component.id
        }))
      }]
    }
  },
  methods: {
    selectComponent(_, componentId) {
      this.selectedComponentId = componentId
    },
    applyNextLevel() {
      const component = this.selectedComponentId
      this.$emit('simulate', { kind: 'next_level', component })
    },
    applySalaryRaise() {
      const salaryRaisePercent = this.salaryRaisePercent
      this.$emit('simulate', { kind: 'salary_raise', salaryRaisePercent })
    },
    applyReset() {
      this.$emit('simulate', { kind: 'reset' })
    }
  },
  created() {
    this.selectedComponentId = this.gridComponents.length ? this.gridComponents[0].id : null
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
@import "./src/styles/block.scss";
@import "./src/styles/form.scss";

.wage-plan-simulation {
  width: 750px;
  padding: 20px 20px 30px 20px;
  overflow-y: visible !important;
}

.simulation-actions {
  @extend .block;
  padding: 1em;
  display: grid;
  grid-template-columns: 1fr auto 1fr auto 1fr;

  .hr {
    text-align: center;
    width: 30px;

    &::before {
      display: block;
      content: " ";
      width: 1px;
      height: 100%;
      background: $border-color;
      margin: auto;
    }
  }

  select {
    width: auto;
    max-width: 200px;
  }

  .reset-button {
    margin-top: 28px;
  }
}
</style>
