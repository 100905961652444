<template>
  <transition name="modal">
    <div class="mask" v-if="visible">
      <div class="wrapper" @click="clickOutside">
        <div class="container">
          <div class="close-button-container">
            <div class="close-button" @click="close">×</div>
          </div>
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    visible: Boolean
  },
  methods: {
    close() {
      this.$emit('close')
    },
    clickOutside(event) {
      if (event.target.classList.contains('wrapper')) {
        this.close()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mask {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(darken($background-color, 90), 0.3);
  display: table;
}

.wrapper {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.container {
  position: relative;
  display: inline-block;
  min-width: 100px;
  min-height: 100px;
  margin: 0 auto;
  text-align: left;
  background-color: white;
  border-radius: $border-radius;
  overflow: hidden;
  box-shadow: 2px 3px 0 rgba(0, 0, 0, 0.01), 2px 6px 20px rgba(0, 0, 0, 0.15);

  // Force slot to fit in smaller viewports, even if fixed width is set
  &::v-deep > div:last-child {
    max-width: 96vw;
    max-height: 95vh;
    overflow-y: auto;
  }
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity 300ms ease-out;

  .container {
    transition: transform 300ms ease-out;
  }
}

.modal-enter,
.modal-leave-to {
  opacity: 0;

  .container {
    transform: scale(0.93);
  }
}

.close-button-container {
  position: absolute;
  right: 0;
  text-align: right;
  padding: 1em 1em 0 0;
  z-index: 1;
}

.close-button {
  display: inline-block;
  @include font-big-size;
  @include font-bold;
  cursor: pointer;
  width: 30px;
  line-height: 30px;
  text-align: center;

  &:hover {
    background: $graph-lightblue-color;
    border-radius: 15px;
  }

  &:active {
    background: darken($graph-lightblue-color, 5);
  }
}

/* Overflow visible skin (for small modals with dropdown) */
.mask.overflow-visible .container {
  overflow: visible;

  &::v-deep > div:last-child {
    overflow: visible;
  }
}
</style>
