<template>
  <div
    class="variable-plan-editor sidebar-container inverted"
    :class="{disabled: isFormulaDisabled}"
    @drop.prevent="uploadConfig"
    @dragover.prevent>
    <main>
      <div>
        <div class="formula-container form-section">
          <div class="variable-plan-action">
            <slot :variablePlan="value" />
          </div>
          <h3>
            {{$tc('variable.editor.formula.formulas', configModel.formulas.length, {count: configModel.formulas.length})}}
            <button class="small-button blue light add-button" @click="addFormula">+</button>
          </h3>
          <draggable
            v-model="configModel.formulas"
            :disabled="isFormulaDisabled"
            @change="onFormulaChange">
            <div
              class="formula"
              v-for="(formula, i) in configModel.formulas"
              :key="i">
              <div class="remove-container">
                <div>
                  <FormulaInput
                    v-model="formula.formula"
                    :error="enrichedConfig.formulas[i] && enrichedConfig.formulas[i].formulaError"
                    :placeholder="$t('variable.editor.formula.placeholder')"
                    :autofocus="!formula.formula"
                    @input="onFormulaChange" />
                  <div>
                    <div
                      v-if="formula.condition !== null"
                      class="formula-condition">
                      <div></div>
                      <span v-t="'variable.editor.condition.intro'"></span>
                      <FormulaInput
                        class="small"
                        v-model="formula.condition"
                        :autofocus="isStarted && formula.condition === ''"
                        :placeholder="$t('variable.editor.condition.placeholder')"
                        :error="enrichedConfig.formulas[i] && enrichedConfig.formulas[i].conditionError"
                        @input="onFormulaChange"
                        @blur="removeFormulaConditionIfEmpty(i)" />
                    </div>
                    <div
                      v-if="formula.maximum !== null"
                      class="formula-maximum">
                      <div></div>
                      <span v-t="'variable.editor.maximum.intro'"></span>
                      <FormulaInput
                        class="small"
                        v-model="formula.maximum"
                        :autofocus="isStarted && formula.maximum === ''"
                        :placeholder="$t('variable.editor.maximum.placeholder')"
                        :error="enrichedConfig.formulas[i] && enrichedConfig.formulas[i].maximumError"
                        @input="onFormulaChange"
                        @blur="removeFormulaMaximumIfEmpty(i)" />
                    </div>
                    <menu class="no-margin-bottom" v-if="formula.condition === null || formula.maximum === null">
                      <button
                        v-if="formula.condition === null"
                        class="small-button blue light"
                        @click="addFormulaCondition(i)"
                        v-t="'variable.editor.condition.add'"></button>
                      <button
                        v-if="formula.maximum === null"
                        class="small-button blue light"
                        @click="addFormulaMaximum(i)"
                        v-t="'variable.editor.maximum.add'"></button>
                    </menu>
                  </div>
                </div>
                <div>
                  <div v-if="configModel.formulas.length > 1" class="remove-button" @click="removeFormula(i)">×</div>
                </div>
              </div>
            </div>
          </draggable>
          <footer
            v-if="configModel.formulas.length > 1"
            v-t="'variable.editor.formula.multipleFormulasExplanation'"></footer>
        </div>
        <VariablePlanDataFeeds
          :variablePlan="value"
          :enrichedDataFeeds="enrichedConfig.dataFeeds"
          @input="onDataFeedsChange" />
        <div class="simulation-container" v-if="hasFormula">
          <div class="variable-plan-action" v-if="!isFormulaDisabled">
            <button
              v-if="!configModel.goals.length"
              class="small-button blue light"
              @click="addGoal"
              v-t="'variable.goal.addGoal'"></button>
          </div>
          <h3>
            {{$tc('variable.editor.simulation.simulations', simulationsModel.length, {count: simulationsModel.length})}}
          </h3>
          <GoalsEditor
            :variablePlan="value"
            :enrichedConfig="enrichedConfig"
            :indicators='simulationsModel[0].indicators'
            :disabled="isFormulaDisabled"
            v-model="configModel.goals"
            @input="onGoalsChange" />
          <div
            class="simulation"
            v-for="(simulation, i) in simulationsModel"
            :key="simulation.id">
            <div class="remove-container center">
              <div>
                <IndicatorsEditor
                  :mode="'simulation'"
                  :variablePlan="value"
                  :enrichedConfig="enrichedConfig"
                  v-model="simulation.indicators"
                  @input="onSimulationIndicatorChange" />
              </div>
              <div>
                <div v-if="simulationsModel.length > 1" class="remove-button" @click="removeSimulation(i)">×</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <aside>
      <ul>
        <li class="header">
          <h3>
            {{$tc('variable.editor.indicator.indicators', 2, {count: 2})}}
          </h3>
        </li>
        <div class="indicator-config-container">
          <IndicatorConfig
            class="cool-bump"
            :class="{'no-animation': !isStarted}"
            v-for="(indicator, i) in configModel.indicators"
            :key="indicator.id"
            :indicators="configModel.indicators"
            :dataFeeds="value.dataFeeds"
            :disabled="isFormulaDisabled"
            v-model="configModel.indicators[i]"
            @input="onIndicatorConfigChange" />
          <div
            class="data-feed-placeholder"
            v-if="isDataFeedPlaceholderVisible">
            <div class="indicator-title">
              <div class="indicator-label" v-t="'variable.dataFeed.placeholder.title'"></div>
              <button class="small-button blue light modern" v-t="'variable.dataFeed.placeholder.action'" @click="setupIntegration"></button>
            </div>
            <p class="indicator-kind-description" v-t="'variable.dataFeed.placeholder.intro'"></p>
          </div>
        </div>
        <li v-if="!configModel.indicators.length">
          <p
            class="dark-light-text no-margin-bottom"
            v-t="'variable.editor.indicator.intro'"></p>
        </li>
      </ul>
      <a
        v-if="!isFormulaDisabled"
        :title="$t('variable.editor.config.export')"
        class="download-button" @click="downloadConfig">↓</a>
    </aside>
  </div>
</template>

<script>
import { slugify } from '@/utils/string'
import { saveAs } from 'file-saver'
import debounce from 'lodash.debounce'
import cloneDeepWith from 'lodash.clonedeepwith'
import Draggable from 'vuedraggable'
import {
  migrateConfig,
  buildFormula,
  buildGoal,
  buildSimulation,
  enrichConfig
} from '@/utils/variable'
import { normalizeDate } from '@/utils/date'
import FormulaInput from '@components/variable/FormulaInput'
import IndicatorConfig from '@components/variable/IndicatorConfig'
import IndicatorsEditor from '@components/variable/IndicatorsEditor'
import GoalsEditor from '@components/variable/GoalsEditor'
import VariablePlanDataFeeds from '@components/variable/VariablePlanDataFeeds'
import { enrichDataFeeds } from '@/utils/dataFeed'
import { mapGetters } from 'vuex'

export const CW_VARIABLE_GOALS_ENABLED = 'CW_VARIABLE_GOALS_ENABLED'

export default {
  components: {
    Draggable,
    FormulaInput,
    GoalsEditor,
    IndicatorConfig,
    IndicatorsEditor,
    VariablePlanDataFeeds
  },
  props: {
    value: Object
  },
  data() {
    return {
      configModel: {},
      enrichedConfig: {},
      isStarted: false,
      simulationsModel: []
    }
  },
  computed: {
    ...mapGetters({
      getEmployees: 'variablePlans/getEmployees'
    }),
    hasFormula() {
      return !!this.enrichedConfig.formulas.find(f => f.compiledFormula)
    },
    isFormulaDisabled() {
      return this.value.status !== 'draft'
    },
    variableWagesCount() {
      return this.value.variableWages.length
    },
    isDataFeedPlaceholderVisible() {
      return !(this.value.dataFeeds && this.value.dataFeeds.length) && this.configModel.indicators.length && this.value.status === 'draft'
    }
  },
  watch: {
    variableWagesCount: 'onDataFeedsChange'
  },
  methods: {
    initModel(configRaw, simulationsRaw) {
      // Init config & simulations
      const { config, simulations } = migrateConfig(configRaw, simulationsRaw)
      this.configModel = config
      this.simulationsModel = simulations

      // Init enriched config
      this.enrichedConfig = cloneDeepWith(this.configModel)

      this.onFormulaChange()
      this.onDataFeedsChange()
    },
    onFormulaChange() {
      const isCollective = this.value.kind === 'collective_variable'
      enrichConfig(this.configModel, this.enrichedConfig, { isCollective })
      this.$emit('enriched', this.enrichedConfig)
      this.submit()
    },
    onDataFeedsChange() {
      const employees = this.getEmployees(this.value)
      this.$set(this.enrichedConfig, 'dataFeeds', enrichDataFeeds(this.value.dataFeeds, this.value, employees))
      this.$emit('enriched', this.enrichedConfig)
    },
    onIndicatorConfigChange() {
      this.enrichedConfig.indicators = this.configModel.indicators
      this.$emit('enriched', this.enrichedConfig)
      this.submit()
    },
    onSimulationIndicatorChange() {
      this.submit()
    },
    onGoalsChange() {
      this.submit()
    },
    submit: debounce(function() {
      if (this.isStarted && !this.isFormulaDisabled) {
        const config = this.configModel
        const simulations = this.simulationsModel
        const model = { ...this.value, config, simulations }
        this.$emit('input', model)
      }
      this.isStarted = true
    }, 1000),
    downloadConfig() {
      const filename = this.$t('variable.form.export.jsonFilename', { name: slugify(this.value.name), date: normalizeDate(new Date()) })
      const data = {
        config: this.configModel,
        simulations: this.simulationsModel
      }
      saveAs(new Blob([JSON.stringify(data, null, 2)]), filename)
      setTimeout(() => {
        alert(this.$t('variable.editor.config.exportSuccess'))
      }, 500)
    },
    uploadConfig(event) {
      if (!this.isFormulaDisabled && event.dataTransfer.files.length) {
        const file = [...event.dataTransfer.files][0]
        file.text().then(text => {
          const variablePlan = JSON.parse(text)
          this.loadConfig(variablePlan)
        })
      }
    },
    loadConfig(variablePlan) {
      const { config, simulations } = cloneDeepWith(variablePlan)
      const isFormulaEmpty = !this.configModel.formulas[0] || !this.configModel.formulas[0].formula
      if (isFormulaEmpty || window.confirm(this.$t('variable.editor.config.overwriteWarning'))) {
        this.initModel(config, simulations)
      }
    },
    addFormula() {
      this.configModel.formulas.push(buildFormula())
      this.onFormulaChange()
    },
    removeFormula(index) {
      this.configModel.formulas.splice(index, 1)
      this.onFormulaChange()
    },
    addFormulaCondition(i) {
      this.configModel.formulas[i].condition = ''
    },
    removeFormulaConditionIfEmpty(i) {
      if (!this.configModel.formulas[i].condition) {
        this.configModel.formulas[i].condition = null
      }
    },
    addFormulaMaximum(i) {
      this.configModel.formulas[i].maximum = ''
    },
    removeFormulaMaximumIfEmpty(i) {
      if (!this.configModel.formulas[i].maximum) {
        this.configModel.formulas[i].maximum = null
      }
    },
    addSimulation() {
      this.simulationsModel.push(buildSimulation())
      this.onSimulationIndicatorChange()
    },
    removeSimulation(index) {
      this.simulationsModel.splice(index, 1)
      this.onSimulationIndicatorChange()
    },
    addGoal() {
      this.configModel.goals.push(buildGoal())
      this.onGoalsChange()
    },
    setupIntegration() {
      const subject = this.$t('variable.dataFeed.placeholder.contact.subject')
      const text = this.$t('variable.dataFeed.placeholder.contact.text', { name: this.value.name })
      const prefill = { subject, text }
      this.$router.push({ hash: '#contact', params: { prefill } })
    }
  },
  created() {
    this.initModel(this.value.config, this.value.simulations)
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/sidebar.scss";
@import "./src/styles/variable.scss";

.variable-plan-editor {
  margin-top: 20px;

  &.sidebar-container {
    grid-template-columns: 1fr 35%;

    aside {
      position: relative;
      max-width: none;

      ul {
        padding: 0 0 5px;
      }

      .download-button {
        cursor: pointer;
        @include font-bold;
        opacity: 0.2;
        position: absolute;
        right: 20px;
        bottom: 15px;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  // Disabled skin with no JS!
  &.disabled::v-deep {
    .formula-container,
    .simulation-container {
      h3 button,
      .formula menu,
      .remove-button {
        display: none;
      }
    }

    .formula {
      pointer-events: none;

      .formula-input {
        background-color: lighten($background-color, 4);
      }

      .formula-condition,
      .formula-maximum {
        margin-top: 0.5em;
        color: $light-text-color;
        gap: 0.4em;

        &:last-of-type {
          margin-bottom: -3px;
        }

        .formula-input {
          background: transparent;
          border: none;
          box-shadow: none;
          padding: 0;
          line-height: 1em;
        }
      }

    }

    aside {
      .dropdown,
      .mapping-editor {
        pointer-events: none;
      }

      .dropdown-button {
          background: transparent !important;
          box-shadow: none !important;
      }
    }
  }
}

.indicator-config-container {
  padding: 0 13px;
}

.variable-plan-action {
  float: right;
  position: relative;
  z-index: 1;
  margin-top: -5px;
}

.data-feed-placeholder {
  @extend .badge-block;
  margin: 18px 0 10px 0;
  border: none;
  padding: 5px 7px;

  .indicator-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .indicator-label {
      @include font-small-size;
      @include font-semibold;
      @include line-regular-height;
      margin-bottom: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 21px;
      padding-right: 3px;
      background: transparent url(~@/assets/icon-bolt.svg) no-repeat 2px 1px;
    }
  }

  .indicator-kind-description {
    @include font-small-size;
    color: $dark-light-text-color;
    margin: 5px 0 3px;
    letter-spacing: -0.1px;
  }
}

footer {
  @include line-regular-height;
  color: $light-text-color;
  @include font-small-size;
  margin: 0.5rem 0;
}
</style>
