<template>
  <div class="container">
    <div class="toolbar">
      <router-link tag="div" class="back-link" to="/grid/sandbox" v-t="'common.back'"></router-link>
    </div>
    <template v-if="grid">
      <div class="grid-title">
        <div class="edit-action" @click="formDisplayed = true">
          <h1>{{ grid.name }}</h1>
          <img src="~@/assets/edit.svg" alt="edit">
          <div class="underline"></div>
        </div>
      </div>
      <div class="sandbox-tab-bar">
        <div class="sandbox-tab-bar-header">
          <span v-t="'sandbox.formula'"></span>&nbsp;
          <span v-if="isGridValid">✅</span>
          <span v-else>⚠️</span>
        </div>
        <div class="sandbox-tab-bar-header merged">
          <span v-t="'sandbox.wageAlignment'"></span>&nbsp;
          <span v-if="isWagesValid">✅</span>
          <span v-else>⚠️</span>
        </div>
        <div class="sandbox-tab-bar-header">
          <span v-t="'sandbox.publication'"></span>&nbsp;
          <span v-if="isSandboxValid">🚀</span>
          <span v-else>⛔</span>
        </div>
        <div
          class="sandbox-tab-bar-item"
          v-for="tab in tabs"
          :key="tab"
          @click="tabSelected(tab)"
          :class="{active: tab == activeTab}">
          {{ tab }}
        </div>
      </div>
      <div class="sandbox-tab-content">
        <component :is="displayedComponent"></component>
      </div>
      <grid-name-form v-if="formDisplayed" @close="gridFormClosed" :grid="grid"></grid-name-form>
    </template>
    <loading-view v-else-if="isLoading"></loading-view>
    <template v-else>
      <div class="error-message" v-t="'sandbox.notFound'"></div>
    </template>
  </div>
</template>

<script>
import GridNameForm from '@components/grid/legacy/GridNameForm.vue'
import LoadingView from '@components/commons/LoadingView.vue'
import SandboxEmployeeWageView from '@/views/sandbox/SandboxEmployeeWageView.vue'
import SandboxGridView from '@/views/sandbox/SandboxGridView.vue'
import SandboxJobOfferWageView from '@/views/sandbox/SandboxJobOfferWageView.vue'
import SandboxSummaryView from '@/views/sandbox/SandboxSummaryView.vue'
import TabBar from '@components/commons/TabBar.vue'

import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      gridId: this.$route.params.id,
      isLoading: false,
      tabs: [this.$t('sandbox.components'), this.$t('sandbox.employees'), this.$t('sandbox.jobOffers'), this.$t('sandbox.summary.title')],
      tabsComponents: ['sandbox-grid-view', 'sandbox-employee-wage-view', 'sandbox-job-offer-wage-view', 'sandbox-summary-view'],
      activeTab: this.$t('sandbox.components'),
      formDisplayed: false
    }
  },
  computed: {
    ...mapGetters('sandbox', {
      grid: 'grid',
      allComponents: 'allComponents',
      isSandboxValid: 'isValid',
      isGridValid: 'isGridValid',
      isWagesValid: 'isWagesValid'
    }),
    displayedComponent() {
      return this.tabsComponents[this.tabs.indexOf(this.activeTab)]
    }
  },
  methods: {
    tabSelected(tab) {
      this.activeTab = tab
    },
    gridFormClosed(grid) {
      this.formDisplayed = false
      if (grid) {
        this.$store.commit('sandbox/setGrid', grid)
      }
    }
  },
  mounted() {
    if (!this.grid || this.grid !== this.gridId) {
      this.isLoading = true
      this.$store.dispatch('sandbox/get', this.gridId)
        .then(response => {
          this.isLoading = false

          if (this.$route.hash) {
            window.location = this.$route.hash
          }
        })
    }
    else {
      if (this.$route.hash) {
        window.location = this.$route.hash
      }

      this.$store.dispatch('sandbox/getWages', this.gridId)
    }
  },
  components: {
    GridNameForm,
    LoadingView,
    SandboxEmployeeWageView,
    SandboxGridView,
    SandboxJobOfferWageView,
    SandboxSummaryView,
    TabBar
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/alert.scss";
@import "./src/styles/form.scss";
@import "./src/styles/link.scss";

.grid-title {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.edit-action {
  position: relative;
  cursor: pointer;

  .underline {
    display: none;
    position: absolute;
    bottom: 2px;
    width: 100%;
    height: 2px;
    background: $light-text-color;
  }

  &:hover > .underline {
    display: block;
  }

  img {
    height: 16px;
    margin-left: 5px;
  }
}

h1 {
  margin: 0;
  display: inline-block;
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 20px;
  padding-bottom: 0px;
}

.container {
  @include tab-container;
  padding: 0;
  margin-bottom: 50vh;
}

.alert {
  margin: 0;
}

.sandbox-tab-bar {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  background: $lightteal-color;
  border-top: $border;
  margin-top: 20px;
}

.sandbox-tab-bar-header {
  text-align: center;
  text-transform: uppercase;
  @include font-smaller-size;
  padding-top: 10px;
  @include font-bold;
  color: $light-text-color;

  &:first-child {
    border-right: $border;
  }

  &:nth-of-type(3) {
    border-left: $border;
  }
}

.sandbox-tab-bar-item {
  min-width: 150px;
  text-align: center;
  line-height: 2.7em;
  @include font-semibold;
  cursor: pointer;
  border-bottom: $border;

  &:nth-of-type(4) {
    border-right: $border;
  }

  &:last-of-type {
    border-left: $border;
  }

  &:hover {
    border-bottom: 4px solid $clearteal-color;
  }
}

.active {
  border-bottom: 4px solid $clearteal-color;
}

.merged {
  grid-column: span 2;
}

.sandbox-tab-content {
  padding: 20px;
}
</style>
