import api from '@/store/api'
import { REVIEW_STATUS } from '@/utils/reviews'
import { getSequentialName, buildSearchTerms } from '@/utils/string'

const initialState = () => {
  return {
    currentReviewName: null,
    reviews: [],
    reviewModel: {
      name: '',
      status: REVIEW_STATUS.draft
    }
  }
}

const state = initialState()

const getters = {
  getReviews(state) {
    return state.reviews.map(review =>
      ({ ...review, _searchKey: buildSearchTerms(review.name) }))
  },
  getReviewModel(state, { getReviews }) {
    return ignoreName => {
      const existingNames = getReviews.map(w => w.name)
      const name = getSequentialName('Ma campagne', existingNames, ignoreName)
      return {
        ...state.reviewModel,
        name: name
      }
    }
  },
  getCurrentReviewName(state) {
    return state.currentReviewName
  }

}

const actions = {
  reset(context) {
    context.commit('reset')
  },
  init(context) {
    if (context.rootGetters['account/isAdmin']) {
      return context.dispatch('getReviews')
    }
    else {
      return Promise.resolve()
    }
  },
  async getReviews({ commit, dispatch }) {
    try {
      const { data } = await api.get('/reviews')
      commit('setReviews', data)
      return data
    }
    catch (error) {
      throw await dispatch('handleAPIError', error, { root: true })
    }
  },
  async getReview({ dispatch }, id) {
    try {
      const { data } = await api.get('/review/' + id)
      return data
    }
    catch (error) {
      throw await dispatch('handleAPIError', error, { root: true })
    }
  },
  async getReviewWages({ dispatch }, id) {
    try {
      const { data } = await api.get('/review/' + id + '/wages')
      return data
    }
    catch (error) {
      throw await dispatch('handleAPIError', error, { root: true })
    }
  },
  async createReview({ getters, dispatch }) {
    try {
      const review = getters.getReviewModel()
      const { data } = await api.post('/review', review)
      return data
    }
    catch (error) {
      throw await dispatch('handleAPIError', error, { root: true })
    }
  },
  async updateReview({ dispatch }, review) {
    try {
      const { data } = await api.patch('/review/' + review.id, review)
      return data
    }
    catch (error) {
      throw await dispatch('handleAPIError', error, { root: true })
    }
  },
  async removeReview({ dispatch }, review) {
    try {
      await api.delete('/review/' + review.id)
      await dispatch('getReviews')
    }
    catch (error) {
      throw await dispatch('handleAPIError', error, { root: true })
    }
  },
  async setEmployees({ dispatch }, { reviewId, employeeIdsToAdd, employeeIdsToRemove }) {
    try {
      const path = `/review/${reviewId}/employees`
      await api.put(`${path}/assign`, { employeeIds: employeeIdsToAdd })
      await api.put(`${path}/unassign`, { employeeIds: employeeIdsToRemove })
    }
    catch (error) {
      throw await dispatch('handleAPIError', error, { root: true })
    }
  },
  async updateEmployee({ dispatch }, { reviewId, userId, managerId }) {
    try {
      const { data } = await api.put(`/review/${reviewId}/employee/${userId}`, { managerId })
      return data
    }
    catch (error) {
      throw await dispatch('handleAPIError', error, { root: true })
    }
  },
  async updateStep({ dispatch }, { reviewId, step }) {
    try {
      const { data } = await api.patch('/review/' + reviewId + '/step/' + step.id, step)
      return data
    }
    catch (error) {
      throw await dispatch('handleAPIError', error, { root: true })
    }
  },
  async updateWage({ dispatch }, { reviewId, wage }) {
    try {
      const { data } = await api.put('/review/' + reviewId + '/wage/' + wage.id, wage)
      return data
    }
    catch (error) {
      throw await dispatch('handleAPIError', error, { root: true })
    }
  },
  async resetWage({ dispatch }, { reviewId, wage }) {
    try {
      const { data } = await api.delete('/review/' + reviewId + '/wage/' + wage.id)
      return data
    }
    catch (error) {
      throw await dispatch('handleAPIError', error, { root: true })
    }
  },
  async createWagePlan({ dispatch }, review) {
    try {
      const { data } = await api.put('/review/' + review.id + '/wage_plan/')
      await dispatch('wagePlans/setWagePlan', data, { root: true })
      return data
    }
    catch (error) {
      throw await dispatch('handleAPIError', error, { root: true })
    }
  }
}

const mutations = {
  reset(state) {
    Object.assign(state, initialState())
  },
  setCurrentReview(state, { name }) {
    state.currentReviewName = name
  },
  setReviews(state, reviews) {
    state.reviews = reviews
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
