<template>
  <div class="benefit-detail">
    <header>
      <h1 class="no-margin-bottom">{{benefit.name}}</h1>
      <div class="benefit-category">
        <BenefitBadge class="benefit-badge" :benefit="benefit" />
        <div>{{$t(`benefits.categories.${benefit.details.category}`)}}</div>
      </div>
    </header>
    <BenefitValorisationSummary
      v-if="benefit.details.value"
      class="benefit-valorisation"
      :valorisation="valorisation"
      :benefit="benefit"
      :inModal="true" />
    <div v-if="benefit.details.description" class="benefit-description">
      <h2 v-t="'benefits.form.generalInformations'" />
      <MarkdownText :value="benefit.details.description" />
    </div>
    <div class="benefit-description" v-if="benefit.details.links.length">
      <h2 v-t="'benefits.form.externalLinks'" />
      <a
        class="external-link"
        v-for="link in benefit.details.links"
        :key="link.url"
        :href="link.url"
        target="_blank">
        <img src="~@/assets/external-link.svg" :alt="link.url">
        <span>{{link.name}}</span>
      </a>
    </div>
  </div>
</template>

<script>
import BenefitBadge from '@components/benefits/BenefitBadge'
import BenefitValorisationSummary from '@components/benefits/BenefitValorisationSummary'
import MarkdownText from '@components/commons/MarkdownText'
import { benefitValorisationSummary } from '@/utils/benefit'

export default {
  components: {
    BenefitBadge,
    MarkdownText,
    BenefitValorisationSummary
  },
  props: {
    benefit: {
      type: Object,
      required: true
    }
  },
  computed: {
    valorisation() {
      if (!this.benefit.details.value) {
        return null
      }

      return benefitValorisationSummary(this.benefit)
    }
  }
}
</script>

<style lang="scss" scoped>
.benefit-detail {
  width: 850px;
  padding: 1.5em;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.benefit-valorisation {
  margin-top: 1em;
  margin-left: 0;
}

.benefit-category {
  display: flex;
  align-items: center;
  margin-left: 1.5em;
  margin-right: 3em;
  background: $lightteal-color;
  padding: 0 1.5em 0em 0.8em;
  @include font-semibold;
  @include font-smaller-size;
  text-transform: uppercase;
  border-radius: 25px;
}

.benefit-badge {
  background: $lightteal-color;
  margin-right: 0.3em;
}

.benefit-description {
  margin-top: 1em;
  border: 1px solid $graph-outer-border-color;
  border-radius: $border-radius;

  h2 {
    display: block;
    padding: 0.5em 1em;
    margin: 0;
    background: $graph-background-color;
    color: $graph-text-color;
    @include font-large-size;
  }

  .description {
    margin: 1em;
  }

  .external-link {
    display: flex;
    padding: 1em;
    text-decoration: none;
    @include font-large-size;

    img {
      width: 20px;
      height: 20px;
      margin-right: 0.5em;
    }

    &:hover {
      background: $graph-background-color;
    }
  }
}
</style>
