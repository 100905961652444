<template>
  <div class="synchronization-changes" v-if="isSynchronizationEnabled">
    <resizable-panel :observer="[successMessage, errorMessage]">
      <p class="alert fade-in" v-if="successMessage">{{successMessage}}</p>
      <p class="error-message" v-if="errorMessage">{{errorMessage}}</p>
    </resizable-panel>
    <div v-if="changes">
      <div v-if="changes.length" class="changes-table">
        <synchronization-change-row
          v-for="(change, i) in changes"
          :key="change.id"
          :change="change"
          :header="formatHeader(changes, change, i)"
          @submit="applyChange" />
      </div>
      <p v-else class="alert" v-t="'settings.settingsCompanySynchronization.changes.synched'"></p>
    </div>
    <footer class="settings-floating-footer" v-if="!isLoading">
      <div>
        <span v-if="changesUpdatedAt">
          {{$t('settings.settingsCompanyAccessRights.usersLoaded', {updatedAt: $options.filters.formatCalendar(this.changesUpdatedAt)})}}
        </span>
        <button
          class="small-button"
          v-t="'settings.settingsCompanyAccessRights.refresh'"
          :disabled="isLoading"
          @click="loadChanges(true, $event)">
        </button>
      </div>
      <div v-if="availableChanges.length">
        <loading-button
          class="small-button green"
          @click="applyAllChanges">
          <span v-t="'settings.settingsCompanySynchronization.actions.syncAll'"></span>
        </loading-button>
      </div>
    </footer>
    <loading-view
      v-if="isLoading"
      :messages="[1, 2, 3].map(i => $t(`settings.settingsCompanySynchronization.loading.message${i}`))" />
  </div>
</template>

<script>
import animateScrollTo from 'animated-scroll-to'
import { mapGetters } from 'vuex'
import LoadingView from '@components/commons/LoadingView'
import SynchronizationChangeRow from '@components/settings/SynchronizationChangeRow'
import ResizablePanel from '@components/commons/ResizablePanel'

export default {
  components: {
    LoadingView,
    ResizablePanel,
    SynchronizationChangeRow
  },
  data() {
    return {
      errorMessage: null,
      successMessage: null
    }
  },
  computed: {
    ...mapGetters({
      changes: 'synchronization/changes',
      isSynchronizationEnabled: 'synchronization/isSynchronizationEnabled',
      changesUpdatedAt: 'synchronization/changesUpdatedAt',
      isLoading: 'synchronization/isLoading',
      availableChanges: 'synchronization/availableChanges'
    })
  },
  methods: {
    formatHeader(changes, change, i) {
      if (i === 0 || change.thirdPartyUser.identityProvider !== changes[i - 1].thirdPartyUser.identityProvider) {
        const count = changes.filter(c => c.thirdPartyUser.identityProvider === change.thirdPartyUser.identityProvider).length

        return this.$tc('settings.settingsCompanySynchronization.changes.changes', count, { count })
      }
    },
    handleError(error) {
      animateScrollTo(0).then(() => {
        this.errorMessage = error || this.$t('settings.settingsCompanySynchronization.changes.genericError')
      })
    },
    async loadChanges(force, event) {
      try {
        this.errorMessage = null
        let externalIds
        if (event && event.altKey) {
          externalIds = window.prompt('Entrez les identifiants à synchroniser, séparés par des virgules :')
        }
        await this.$store.dispatch('synchronization/getChanges', { force, externalIds })
      }
      catch (error) {
        this.handleError(error)
      }
    },
    async applyChange(change) {
      try {
        const changes = [change]
        this.errorMessage = null
        await this.$store.dispatch('synchronization/applyChanges', { changes })
      }
      catch (error) {
        this.handleError(error)
      }
    },
    async applyAllChanges() {
      try {
        const changes = this.availableChanges
        this.errorMessage = null
        await this.$store.dispatch('synchronization/applyChanges', { changes })
        await this.loadChanges()
      }
      catch (error) {
        this.handleError(error)
      }
    }
  },
  created() {
    this.loadChanges()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/alert.scss";
@import "./src/styles/settings.scss";
</style>
