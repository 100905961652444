<template>
  <div>
    <span v-if="isQualificationMissing" class="light-text small-size">Qualification<br>manquante
      <Tooltip class="left gray-icon" :message="$t('reviews.wage.missingQualificationTooltip')" />
    </span>
    <button
      v-else-if="isWageReviewTodo(wage.review)"
      class="review-wage-button small-button gray bordered todo"
      :class="{blue: hasStepUpdatePermission}"
      :disabled="!hasStepReadPermission"
      @click="openWage">À remplir</button>

    <button
      v-else-if="isWageReviewOngoing(wage.review)"
      class="review-wage-button small-button gray bordered ongoing"
      :class="{blue: hasStepUpdatePermission}"
      :disabled="!hasStepReadPermission"
      @click="openWage">En cours</button>

    <button
      v-else-if="isWageReviewDone(wage.review)"
      class="review-wage-button small-button green bordered done"
      :disabled="!hasStepReadPermission"
      @click="openWage">Terminée</button>
  </div>
</template>

<script>
import Tooltip from '@components/commons/Tooltip.vue'
import { isWageReviewTodo, isWageReviewOngoing, isWageReviewDone, hasStepUpdatePermission, hasStepReadPermission } from '@/utils/reviews'
import { mapGetters } from 'vuex'

export default {
  components: {
    Tooltip
  },
  props: {
    employee: {
      type: Object,
      default() {
        return {}
      }
    },
    step: {
      type: Object,
      default() {
        return {}
      }
    },
    wage: {
      type: Object,
      default() {
        return {}
      }
    },
    review: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods: {
    isWageReviewTodo,
    isWageReviewOngoing,
    isWageReviewDone,
    openWage() {
      this.$emit('open')
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'account/getUser'
    }),
    hasStepReadPermission() {
      return hasStepReadPermission(this.step, this.employee, this.getUser, this.wage)
    },
    hasStepUpdatePermission() {
      return hasStepUpdatePermission(this.step, this.employee, this.getUser, this.review)
    },
    isQualificationMissing() {
      return !(this.wage && this.wage.hasLevels && this.wage.levelIds.length)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";

.review-wage-button {
  &.todo::after,
  &.ongoing::after {
    content: " ";
    display: inline-block;
    width: 0.8em;
    height: 0.8em;
    opacity: 0.8;
    background: transparent url(~@/assets/edit.svg) no-repeat right center;
    background-size: contain;
    vertical-align: baseline;
    margin-left: 0.4em;
  }

  &.blue::after {
    background-image: url(~@/assets/edit-blue.svg);
  }

  &.done::after {
    content: " ✓";
    @include font-extra-bold;
    display: inline-block;
    margin-left: 0.2em;
  }
}
</style>
