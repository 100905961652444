<template>
  <resizable-panel
    :observer="[showAllEmployees, searchQuery, employees.length, $scopedSlots.intro]">
    <template v-if="(employees.length || !hideWhenEmpty)">
      <div class="employees-table-container">
        <h3>
          <div>
            <div
              class="clickable"
              @click="toggleShowAllEmployees">
              <!-- Title slot replaces the original title -->
              <div v-if="$scopedSlots.title">
                <slot name="title" />
              </div>
              <span v-else>{{title}}</span>
              <onboarding-progress-bar v-if="progress" :percent="progress" />
            </div>
            <search-input
              class="large blue"
              v-if="showAllEmployees && employees.length"
              v-model="searchQuery"
              :searchCount="searchQueryCount" />
          </div>
          <div v-if="$scopedSlots.company && !searchQuery" class="company-slot">
            <slot name="company" />
          </div>
        </h3>
        <EmployeesTable
          v-if="showAllEmployees && employees.length"
          :class="{hidden: searchQuery && !searchQueryCount}"
          :employees="employees"
          :fieldOnly="fieldOnly"
          :highlightedEmployeeId="highlightedEmployeeId"
          :infoSlotTitle="infoSlotTitle"
          :limit="limit"
          :name="name"
          :preservedProps="preservedProps"
          :searchQuery="searchQuery"
          :showSeniority="showSeniority"
          :sortKey="sortKey"
          @count="count => searchQueryCount = count">
          <!-- Intro slot is on the top of the list of employees -->
          <template v-if="$scopedSlots.intro" v-slot:intro>
            <slot name="intro" />
          </template>
          <!-- Group slot is on top right inside the header -->
          <template v-if="$scopedSlots.group" v-slot:group="slotProps">
            <slot name="group" :employees="slotProps.employees" />
          </template>
          <!-- Info slot is the left-aligned space for each employee -->
          <template v-if="$scopedSlots.info" v-slot:info="slotProps">
            <slot name="info" :employee="slotProps.employee" />
          </template>
          <!-- Action slot is the right-aligned space for each employee -->
          <template v-if="$scopedSlots.action" v-slot:action="slotProps">
            <slot name="action" :employee="slotProps.employee" />
          </template>
        </EmployeesTable>
        <p
          v-if="placeholder && !employees.length"
          class="placeholder">
          {{placeholder}}
        </p>
      </div>
    </template>
  </resizable-panel>
</template>

<script>
import ResizablePanel from '@components/commons/ResizablePanel.vue'
import SearchInput from '@components/commons/SearchInput.vue'
import EmployeesTable from '@components/employees/EmployeesTable.vue'
import OnboardingProgressBar from '@components/grid/onboarding/OnboardingProgressBar.vue'

export default {
  components: {
    ResizablePanel,
    SearchInput,
    EmployeesTable,
    OnboardingProgressBar
  },
  props: {
    employees: {
      type: Array
    },
    fieldOnly: {
      type: Boolean
    },
    hideWhenEmpty: {
      type: Boolean
    },
    highlightedEmployeeId: {
      type: String
    },
    infoSlotTitle: {
      type: String
    },
    limit: {
      type: Number,
      default: 5
    },
    name: {
      type: String
    },
    placeholder: {
      type: String
    },
    preservedProps: {
      type: Array
    },
    progress: {
      type: Object
    },
    showSeniority: {
      type: Boolean
    },
    sortKey: {
      type: String,
      default: 'jobType'
    },
    title: {
      type: String
    }
  },
  data() {
    return {
      showAllEmployees: true,
      searchQuery: null,
      searchQueryCount: 0
    }
  },
  methods: {
    toggleShowAllEmployees() {
      this.showAllEmployees = !this.showAllEmployees
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
@import "./src/styles/badge.scss";

.employees-table-container {
  @extend .badge-block;
  display: block;
  padding: 0.75em 1.1em;
  margin-top: 1.8em;
}

h3 {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .clickable {
    cursor: pointer;
    display: inline-block;
  }

  .progress-bar {
    margin: 0 0.25em;
  }

  .company-slot {
    font-size: 0;
    line-height: 0;
  }
}

.employees-table {
  margin-top: 0.75em;
  padding: 0.25em 0;
  background: white;
  border-radius: $border-radius;
  border: 1px solid $graph-inner-border-color;

  &.hidden {
    display: none;
  }
}

.placeholder {
  color: $light-text-color;
  margin: 0;
  line-height: 34px;
}
</style>
