import { hcl } from 'd3'
import { slugify } from '@/utils/string'

// Interesting ressources on HCL:
// - https://howlettstudios.com/articles/2017/5/6/the-problem-with-hsv
// - https://www.vis4.net/blog/2011/12/avoid-equidistant-hsv-colors/
// - https://github.com/d3/d3-color#hcl
// Hue = [0, 360]
// Chroma = [0, 100]
// Lightness =  // [0, 150]
export function colorize(text, chroma = 90, lightness = 25) {
  const hue = computeHue(text)
  return hcl(hue, lightness, chroma)
}

function computeHue(text) {
  const hueOffset = 360 * 0.6 // Start from the blues.
  const maxChars = 23 // < 26 to have colors loop a little.
  const minCharCode = 'a'.charCodeAt(0)
  text = slugify(text) || ''
  while (text.length < 3) {
    text += 'a'
  }

  const first = (text.charCodeAt(0) - minCharCode) / maxChars
  const second = (text.charCodeAt(1) - minCharCode) / maxChars
  const third = (text.charCodeAt(1) - minCharCode) / maxChars
  return (first + second / 10 + third / 100) * 360 + hueOffset
}
