<template>
  <div class="options-container" v-if="isOptionsVisible">
    <div class="options">
      <dropdown
        :class="{highlight: isHighlighted}"
        :right="true"
        :items="options"
        @change="setOption">
        <div class="options-icon"></div>
      </dropdown>
      <div v-if="isHighlighted" class="dot"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Dropdown from '@components/commons/Dropdown.vue'
import { getVisibleLevels } from '@/utils/grid'

export default {
  components: {
    Dropdown
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      hasCurrentOptions: 'statistics/hasCurrentOptions',
      currentOptions: 'statistics/getCurrentOptions',
      currentReport: 'statistics/getCurrentReport',
      onboardingGrid: 'sandbox/onboardingGrid',
      currentGrid: 'currentGrid/getCurrentGrid',
      getCurrentWages: 'employees/getCurrentWages'
    }),
    isHighlighted() {
      return this.hasCurrentOptions(this.optionsKeys)
    },
    isOptionsVisible() {
      return this.options.length > 1
    },
    isSalaryVisible() {
      return this.onboardingGrid && !['evolution', 'grid', 'table'].includes(this.currentReport.id)
    },
    isEquivalenceLevelsVisible() {
      return !['payroll', 'evolution', 'grid', 'table'].includes(this.currentReport.id)
    },
    isRecurrenceVisible() {
      return ['table'].includes(this.currentReport.id)
    },
    isDownloadVisible() {
      return ['table'].includes(this.currentReport.id)
    },
    referenceGrid() {
      return this.currentGrid || this.onboardingGrid
    },
    equivalenceComponents() {
      if (this.referenceGrid) {
        return this.referenceGrid.components.filter(c => !c.linkedComponents.length)
      }
      else {
        return []
      }
    },
    options() {
      const options = [{
        name: this.$t('common.options'),
        items: []
      }]

      if (this.isRecurrenceVisible) {
        options.push({
          name: this.$t('variable.recurrences.title'),
          value: 'recurrence',
          items: this.recurrenceOptions.map(({ name, value }) => ({
            name,
            value,
            selected: this.getOption('recurrence') === value
          }))
        })
      }

      if (this.isSalaryVisible) {
        options.push({
          name: this.$t('dashboard.options.salary.title'),
          value: 'salary',
          items: this.salaryOptions.map(({ name, value }) => ({
            name,
            value,
            selected: this.getOption('salary') === value
          }))
        })
      }

      const hasIncludeContractualVariable = !!this.getCurrentWages.find(w => w.contractualVariableValue)
      if (hasIncludeContractualVariable) {
        options.push({
          name: this.$t('dashboard.options.includeContractualVariable.title'),
          value: 'includeContractualVariable',
          items: this.includeContractualVariableOptions.map(({ name, value }) => ({
            name,
            value,
            selected: this.getOption('includeContractualVariable') === value
          }))
        })
      }

      if (this.isEquivalenceLevelsVisible) {
        this.equivalenceComponents.forEach(component => {
          const levels = getVisibleLevels(component.levels)
          const equivalenceLevels = this.getOption('equivalenceLevels')
          options.push({
            name: component.name,
            value: `equivalenceLevels|${component.ref}`,
            items: levels.map(({ name, id }) => ({
              name: this.$t('dashboard.options.alignCity.true', { city: name }),
              value: { ...equivalenceLevels, [component.ref]: id },
              selected: equivalenceLevels[component.ref] === id
            })).concat([{
              name: this.$t('dashboard.options.alignCity.false'),
              value: { ...equivalenceLevels, [component.ref]: undefined },
              selected: !equivalenceLevels[component.ref]
            }])
          })
        })
      }

      if (this.isDownloadVisible) {
        options.push({
          name: this.$t('dashboard.options.download.title'),
          value: 'download',
          items: [{
            name: this.$t('dashboard.options.download.excel'),
            value: 'download'
          }]
        })
      }
      return options
    },
    optionsKeys() {
      return this.options.map(({ value }) => value && value.split('|')[0])
    },
    salaryOptions() {
      const salaries = [{
        name: this.$t('dashboard.options.salary.current'),
        value: 'current'
      }]
      if (this.onboardingGrid) {
        salaries.push({
          name: this.$t('dashboard.options.salary.sandbox'),
          value: 'sandbox'
        })
      }
      return salaries
    },
    includeContractualVariableOptions() {
      return [{
        name: this.$t('dashboard.options.includeContractualVariable.true'),
        value: true
      }, {
        name: this.$t('dashboard.options.includeContractualVariable.false'),
        value: false
      }]
    },
    recurrenceOptions() {
      return ['yearly', 'biannual', 'quarterly', 'monthly'].map(recurrence => ({
        name: this.$t(`variable.recurrences.${recurrence}.title`),
        value: recurrence
      }))
    }
  },
  methods: {
    getOption(key) {
      return this.currentOptions && this.currentOptions[key]
    },
    setOption(option, value) {
      option = option.split('|')[0]
      if (option === 'download') {
        this.$router.push({ hash: '#download' })
      }
      else {
        this.$store.dispatch('statistics/setOption', { option, value })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/link.scss";
@import "./src/styles/button.scss";

$options-padding: 0.3em;

.options-container {
  display: inline-block;
}

.options {
  position: relative;
  display: inline-block;
  padding: $options-padding;
  border: $border;
  border-radius: $border-radius;
}

.options-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: transparent url(~@/assets/graph-options.svg) no-repeat center
    center;
  background-size: 24px;
  margin: -0.5em;
}

.dropdown::v-deep .dropdown-button {
  font: inherit;
  color: inherit;
  border: none;
  padding: 0 0.5em;

  &:hover,
  &:focus {
    color: inherit;
    background: $graph-lightblue-color;
  }

  &:focus {
    background: $graph-blue-color;
  }
}

.dropdown::v-deep {
  .dropdown-button {
    max-width: 570px;
    line-height: 1.75em;
  }

  .dropdown-content {
    border-color: $graph-blue-color !important;
    outline: none !important;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1) !important;

    // Dropdown multi-select skin
    .dropdown-item {
      line-height: 1.5em;

      .right-label {
        @include font-bigger-size;
        width: 40px;
        height: 36px;
        line-height: 36px;
        margin: -5px -28px -5px 5px;
        text-align: center;
        color: lighten($graph-text-color, 10);

        &:hover {
          background: darken($graph-lightblue-color, 5);
          color: $graph-text-color;
        }
      }

      &:hover {
        background: $graph-lightblue-color !important;
      }
    }
  }

  &.highlight .dropdown-content .dropdown-item.dropdown-checked-item {
    color: darken($clearteal-color, 5);
  }
}

.dot {
  width: 8px;
  height: 8px;
  background: $clearteal-color;
  border-radius: 22px;
  position: absolute;
  top: -1px;
  right: -1px;
  outline: 4px solid rgba($clearteal-color, 25%);
}
</style>
