<template>
  <div class="rem-summary" :class="{global: variablePlansSummary.length || benefitsCount}">
    <div class="rem-part salary" v-if="employee.wageDetails">
      <label>{{ salary | formattedCurrency }}</label>
      <strong v-t="'employees.employee.summary.salary'"></strong>
      <em>
        <span v-if="hasLevels && isAdjusted" v-t="'grid.editor.adjusted'"></span>
        <span v-else-if="!hasLevels && $$hasGrid" v-t="'grid.editor.offGrid'"></span>
      </em>
    </div>
    <div class="rem-part variable" v-if="contractualVariable">
      <label>{{ contractualVariable | formattedCurrency }}</label>
      <strong>{{$t('employees.employee.summary.variable')}}</strong>
    </div>
    <div class="rem-part variable" v-else-if="variablePlansSummary.length">
      <label>{{variablePlansSummary.length}}</label>
      <strong>{{$tc('employees.employee.summary.variables', variablePlansSummary.length, {count: variablePlansSummary.length})}}</strong>
      <em>{{formatTruncatedSequence(variablePlansSummary)}}</em>
    </div>
    <div class="rem-part variable" v-if="benefitsCount">
      <label>{{benefitsCount}}</label>
      <strong>{{$tc('employees.employee.summary.benefits', benefitsCount, {count: benefitsCount})}}</strong>
      <em>{{formatTruncatedSequence(benefitsPlansSummary)}}</em>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    employee: {
      type: Object
    }
  },
  methods: {
    formatTruncatedSequence(list) {
      if (list.length > 1) {
        return list[0] + ' +' + (list.length - 1)
      }
      else if (list.length === 1) {
        return list[0]
      }
    }
  },
  computed: {
    hasLevels() {
      return this.employee.wageDetails.selectedComponents && this.employee.wageDetails.selectedComponents.length
    },
    isAdjusted() {
      return this.employee.wageDetails.summary.salary.delta
    },
    salary() {
      return this.employee.wageDetails.summary.salary.value
    },
    contractualVariable() {
      return this.employee.wageDetails.summary.salary.variableValue
    },
    variablePlansSummary() {
      return this.employee.variablePlansSummary || []
    },
    benefitsCount() {
      return this.employee.benefitsCount
    },
    benefitsPlansSummary() {
      return this.employee.benefitsPlansSummary || []
    }

  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
$rem-margin: 7px;
$rem-padding: 5px;
$rem-width: 160px;

.rem-summary {
  display: flex;
  align-items: center;

  &.global {
    align-items: flex-start;
  }
}

.rem-part {
  display: inline-block;
  padding: $rem-padding $rem-padding * 2 $rem-padding 0;
  width: $rem-width;

  label,
  strong,
  em {
    display: block;
    cursor: inherit;
    @include line-regular-height;
  }

  label {
    @include font-big-size;
    @include font-bold;
    @include font-alternate-digits;
    line-height: 1.2em;
    color: $clearteal-color;
  }

  strong {
    @include font-smaller-size;
    font-weight: 550;
    letter-spacing: -0.75px;
    text-transform: uppercase;
  }

  em {
    @include font-small-size;
    font-style: initial;
    color: $light-text-color;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
