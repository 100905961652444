<template>
  <div>
    <h3>
      <router-link
        tag="a"
        class="colored-link"
        :to="{name: 'settingsCompanyIntegrations'}"
        :active-class="'selected'">
        {{$t('settings.settingsCompanyIntegrations.title')}}
      </router-link>
      &nbsp;&nbsp;›&nbsp;&nbsp;
      {{$t('settings.settingsCompanyIntegrations.payfit.title')}}
    </h3>
    <p class="no-margin-top" v-html="$t('settings.settingsCompanyIntegrations.payfit.intro')"></p>
    <div class="settings-block-container">
      <div class="settings-block">
        <header><strong v-t="'settings.settingsCompanyIntegrations.payfit.export.title'"></strong></header>
        <p class="preserve-lines" v-html="$t('settings.settingsCompanyIntegrations.payfit.export.intro')"></p>
        <menu>
          <router-link
            tag="button"
            class="primary"
            :to="{hash: $t('settings.settingsCompanyIntegrations.payfit.export.url')}">
            <span v-t="'settings.settingsCompanyIntegrations.payfit.export.action'"></span>
          </router-link>
        </menu>
      </div>
      <div class="settings-block">
        <header><strong v-t="'settings.settingsCompanyIntegrations.payfit.users.title'"></strong></header>
        <p class="preserve-lines" v-html="$t('settings.settingsCompanyIntegrations.payfit.users.intro')"></p>
        <menu>
          <router-link
            tag="button"
            class="primary"
            :to="{name: 'payfitUserImport'}">
            <span v-t="'settings.settingsCompanyIntegrations.payfit.users.action'"></span>
          </router-link>
        </menu>
      </div>
      <div class="settings-block">
        <header><strong v-t="'settings.settingsCompanyIntegrations.payfit.salaries.title'"></strong></header>
        <p class="preserve-lines" v-html="$t('settings.settingsCompanyIntegrations.payfit.salaries.intro')"></p>
        <menu>
          <router-link
            tag="button"
            class="primary"
            :to="{name: 'payfitSalaryImport'}">
            <span v-t="'settings.settingsCompanyIntegrations.payfit.salaries.action'"></span>
          </router-link>
        </menu>
      </div>
    </div>
    <p class="light-text preserve-lines" v-html="$t('settings.settingsCompanyIntegrations.payfit.footer')"></p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      company: 'account/getCompany'
    })
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/settings.scss";

.settings-block-container {
  counter-reset: steps;

  .settings-block {
    counter-increment: steps;

    header strong {
      display: flex;
      align-items: center;

      &:before {
        content: counter(steps);
        width: 24px;
        line-height: 24px;
        border-radius: 24px;
        background: $clearteal-color;
        color: white;
        text-align: center;
        @include font-bold;
        @include font-alternate-digits;
        margin-right: 0.75em;
        padding-right: 1px;
        box-sizing: border-box;
      }
    }
  }
}
</style>
