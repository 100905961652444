import api from '@/store/api'
import { sequentialDispatch } from '@/utils/store'
import { normalizedEmployees } from '@/store/schema'
import { getWageDetails } from '@/utils/grid'
import { FIELDS, getField } from '@/utils/employee'
import Vue from 'vue'

const initialState = () => {
  const employeeModel = FIELDS.reduce((memo, field) => {
    memo[field.id] = null
    return memo
  }, {})
  return {
    employeeList: [], // [1, 2, …]
    alumniList: [], // [4, 5, …]
    externalList: [], // [6, 7, …]
    employees: {}, // {1: {…}, 2: {…}, …}
    needsRefresh: false,
    oneself: {}, // The employee associated with the user
    overriden: false,
    employeeModel: {
      ...employeeModel,
      customFields: {},
      isExcluded: false,
      isExternal: false,
      initialWage: null,
      teams: [],
      currentWage: {
        hasLevels: true,
        explanation: '',
        levelIds: [],
        overridenSalaryValue: 0
      }
    },
    currentEmployeeName: '' // Breadcrumb helper
  }
}

const state = initialState()

function buildEmployeeRoute(context, id, path) {
  const oneself = context.getters.getOneself || {}
  const base = oneself.id === id ? '/employee' : `/employee/${id}`
  return `${base}/${path}`
}

const getters = {
  getEmployees(state) {
    return state.employeeList.map(id => state.employees[id])
  },
  getAlumnis(state) {
    return state.alumniList.map(userId => state.employees[userId])
  },
  getExternals(state) {
    return state.externalList.map(userId => state.employees[userId])
  },
  getEmployee(state) {
    return (id) => state.employees[id] || (state.oneself && state.oneself.id === id ? state.oneself : null)
  },
  getEmployeesByIds(_, { getEmployee }) {
    return employeeIds => {
      return employeeIds.map(employeeId => {
        return getEmployee(employeeId)
      }).filter(e => !!e)
    }
  },
  getEmployeeByEmailOrSlug(_, { getAllEmployees }) {
    return ({ email, slug }) => getAllEmployees.find(employee =>
      (email && getField(employee, 'email') === email) ||
      (slug && getField(employee, 'slug') === slug)
    )
  },
  getUpdateEmployees(_, { getEmployees }) {
    return getEmployees.filter(({ isReadOnly }) => !isReadOnly)
  },
  getGridEmployees(_, { getEmployees }) {
    return getEmployees.filter(e => !e.isExcluded)
  },
  getCurrentWages(_, getters) {
    return getters.getEmployees.map(member => member.currentWage).filter(w => w != null)
  },
  getCurrentEmployeeName(state) {
    return state.currentEmployeeName
  },
  getOneself(state) {
    return state.oneself
  },
  getEmployeesAndAlumnis(_, { getEmployees, getAlumnis }) {
    return [...getEmployees, ...getAlumnis]
  },
  getAllEmployees(state) {
    return Object.values(state.employees)
  },
  getCurrentWageDetails(_, { getAllEmployees }, _rootState, rootGetters) {
    const currentGrid = rootGetters['currentGrid/getCurrentGrid']
    const currentWageDetails = getAllEmployees.reduce((memo, employee) => {
      memo[employee.id] = getWageDetails(currentGrid, employee.currentWage)
      return memo
    }, {})

    return (employee, options) => !options && currentWageDetails[employee.id] ? currentWageDetails[employee.id] : getWageDetails(currentGrid, employee.currentWage, options)
  },
  getReferenceWageDetails(_, { getAllEmployees, getCurrentWageDetails }, _rootState, rootGetters) {
    // Reference Wage = Current Wage || Onboarding Wage
    // Wage details are pre-computed for performance reasons (except if options is provided).
    const onboardingGrid = rootGetters['sandbox/onboardingGrid']
    const getReferenceWageDetails = (employee, options) => {
      if (onboardingGrid && rootGetters['sandbox/hasEmployeeWage'](employee.id)) {
        const onboardingWage = rootGetters['sandbox/employeeWage'](employee.id)
        return getWageDetails(onboardingGrid, onboardingWage, options)
      }
      else {
        return getCurrentWageDetails(employee, options)
      }
    }
    const referenceWageDetails = getAllEmployees.reduce((memo, employee) => {
      memo[employee.id] = getReferenceWageDetails(employee)
      return memo
    }, {})

    return (employee, options) => !options && referenceWageDetails[employee.id] ? referenceWageDetails[employee.id] : getReferenceWageDetails(employee, options)
  }
}

const actions = {
  reset(context) {
    context.commit('reset')
  },
  getEmployeesIfNeeded({ state, commit, dispatch }) {
    if (state.needsRefresh) {
      commit('setNeedsRefresh', false)
      return dispatch('getEmployees')
    }
  },
  needsRefresh({ commit }) {
    commit('setNeedsRefresh', true)
  },
  getEmployees(context) {
    if (context.state.overriden) {
      return
    }
    return api.get('/employees')
      .then(({ data }) => {
        return context.commit('setEmployees', data)
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  getEmployee(context, id) {
    return api.get('/employee/' + id)
      .then(({ data }) => {
        context.commit('updateEmployee', data)
        return data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  getOneself(context) {
    return api.get('/employee')
      .then(response => {
        context.commit('setOneself', response.data)
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  async getNewsfeeds({ dispatch }, params) {
    try {
      const { data } = await api.get('employees/newsfeeds' + (params && params.preloaded ? '?preloaded=true' : ''))
      return data
    }
    catch (error) {
      return dispatch('handleAPIError', error, { root: true })
    }
  },
  async getNewsfeed(context, id) {
    try {
      const { data } = await api.get(buildEmployeeRoute(context, id, 'newsfeed'))
      return data
    }
    catch (error) {
      return context.dispatch('handleAPIError', error, { root: true })
    }
  },
  async getEmployeeBenefits(context, id) {
    try {
      const { data } = await api.get(buildEmployeeRoute(context, id, 'benefits'))
      return data
    }
    catch (error) {
      return context.dispatch('handleAPIError', error, { root: true })
    }
  },
  async getEmployeeVariableWages(context, id) {
    try {
      const { data } = await api.get(buildEmployeeRoute(context, id, 'variable_wages'))
      return data
    }
    catch (error) {
      return context.dispatch('handleAPIError', error, { root: true })
    }
  },
  addEmployee(context, employee) {
    // Rename currentWage to initialWage
    const employeeModel = { ...employee }
    if (employeeModel.currentWage) {
      employeeModel.initialWage = employeeModel.currentWage
      delete employeeModel.currentWage
    }

    return api.post('/employee', employeeModel)
      .then(response => {
        context.commit('addEmployee', response.data)
        return response.data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  updateEmployee(context, employee) {
    return api.patch('/employee/' + employee.id, employee)
      .then(response => {
        context.commit('updateEmployee', response.data)
        return response.data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  async updateEmployees({ getters, dispatch }, employees) {
    // Use faster import employees endpoint
    try {
      employees = employees.map(employee => {
        // Ensure required fields are present
        const { email, firstName, lastName } = getters.getEmployee(employee.id)
        return { email, firstName, lastName, ...employee }
      })
      await api.put('/employees', { employees })
      return dispatch('getEmployees')
    }
    catch (error) {
      throw await dispatch('handleAPIError', error, { root: true })
    }
  },
  setCurrentWage(context, { employee, wage }) {
    return api.put('/employee/' + employee.id + '/wage/current', wage)
      .then(({ data }) => {
        context.commit('updateEmployee', data)
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  resetCurrentWages(context) {
    return api.put('/employees/wage/current')
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  async getWage(context, wageId) {
    try {
      const { data } = await api.get(`/wage/${wageId}`)
      return data
    }
    catch (error) {
      return context.dispatch('handleAPIError', error, { root: true })
    }
  },
  async createWage(context, { employee, wage }) {
    try {
      const { data } = await api.post(`/employee/${employee.id}/wage`, wage)
      return data
    }
    catch (error) {
      return context.dispatch('handleAPIError', error, { root: true })
    }
  },
  async updateWage({ dispatch }, wage) {
    try {
      const { data } = await api.patch(`/wage/${wage.id}`, wage)
      await dispatch('getEmployee', wage.employeeId)
      return data
    }
    catch (error) {
      return dispatch('handleAPIError', error, { root: true })
    }
  },
  async deleteWage({ dispatch }, wage) {
    try {
      await api.delete(`/wage/${wage.id}`)
      await dispatch('getEmployee', wage.employeeId)
      return true
    }
    catch (error) {
      return dispatch('handleAPIError', error, { root: true })
    }
  },
  async overwriteEmployeeWages(context, { id, wages }) {
    try {
      const { data } = await api.post(`/employee/${id}/wages`, { wages, overwriteWages: true })
      return data
    }
    catch (error) {
      return context.dispatch('handleAPIError', error, { root: true })
    }
  },
  async overwriteEmployeesWages(context, employeesWages) {
    const actions = employeesWages.map(employeeWages => {
      return ['overwriteEmployeeWages', employeeWages]
    })
    return sequentialDispatch(context, actions)
  },
  removeEmployee(context, employee) {
    return api.delete('/employee/' + employee.id)
      .then(_ => {
        context.commit('removeEmployee', employee)
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  updateEmployeeAvatar(context, { employee, file }) {
    const data = new FormData()
    data.append('file', file)

    return api.post(`/employee/${employee.id}/avatar`, data)
      .then(({ data }) => {
        const updatedEmployee = data
        context.commit('updateEmployee', updatedEmployee)
        context.dispatch('orgChart/setEmployee', updatedEmployee, { root: true })
        return data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  }
}

const mutations = {
  reset(state) {
    Object.assign(state, initialState())
  },
  setEmployees(state, employees) {
    const n = normalizedEmployees(employees)
    state.employeeList = n.result.employees
    state.alumniList = n.result.alumnis
    state.externalList = n.result.externals
    state.employees = n.entities.users || {}
    state.overriden = !!employees.overriden
  },
  setOneself(state, oneself) {
    state.oneself = oneself
  },
  addEmployee(state, employee) {
    Vue.set(state.employees, employee.id, employee)
    state.employeeList.push(employee.id)
  },
  updateEmployee(state, employee) {
    Vue.set(state.employees, employee.id, employee)
  },
  setCurrentEmployee(state, employee) {
    state.currentEmployeeName = employee.firstName + ' ' + employee.lastName
  },
  removeEmployee(state, employee) {
    if (state.employeeList.includes(employee.id)) {
      state.employeeList.splice(state.employeeList.indexOf(employee.id), 1)
    }
    if (state.alumniList.includes(employee.id)) {
      state.alumniList.splice(state.alumniList.indexOf(employee.id), 1)
    }
    if (state.externalList.includes(employee.id)) {
      state.externalList.splice(state.externalList.indexOf(employee.id), 1)
    }
  },
  setNeedsRefresh(state, needsRefresh) {
    state.needsRefresh = needsRefresh
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
