<template>
  <div class="wage-grid-formula" :class="{overflow: isRoot}">
    <template v-if="isRoot">
      <div class="salary">
        <div class="salary-name" v-t="'common.salary'"></div>
        <div class="salary-value badge border-badge large-badge">
          {{ salary | formattedCurrency }}
        </div>
      </div>
      <div class="salary-operation" v-if="computedFormula">
        =
      </div>
    </template>
    <div
      v-if="computedFormula"
      class="formula"
      :class="[
      isRoot ? 'root' : 'group',
      `group-${inversedIndex}`]">
      <div
        v-for="(component, i) in computedFormula.components"
        :key="component.id">
        <div
          v-if="i !== 0"
          class="operation">
          {{ operationSymbol(component.operation) }}
        </div>
        <div
          v-if="component.name"
          class="component"
          :class="{clickable: isClickable}"
          @click="select(component)">
          <div class="component-name">
            {{ component.name }}
          </div>
          <div class="component-selected-level-name">
            {{component.selectedLevel && component.selectedLevel.name}}
          </div>
          <div class="component-selected-level-value">
            {{ component | formattedLevelValue(component.selectedLevel, getParentComponent(component), getParentComponent(component)  && getParentComponent(component).selectedLevel, 'formula', {hideOperation: true, wageDetails: simulationWageDetails || wageDetails}) }}
          </div>
        </div>
        <wageGridFormulaDetail
          v-else
          @select="select"
          :formula="component"
          :index="index + 1"
          :total="depth"
          :simulationWageDetails="simulationWageDetails || wageDetails" />
      </div>
    </div>
    <template v-if="isRoot && showFormula && delta">
      <div class="salary-operation">
        {{delta > 0 ? '+' : '-'}}
      </div>
      <div class="adjustement">
        <div class="adjustement-name" v-t="'grid.editor.adjustment'">
        </div>
        <div class="adjustement-value">
          {{ Math.abs(delta) | formattedCurrency }}
        </div>
      </div>
    </template>
    <template v-if="isRoot && showVariable && variable">
      <div class="salary-operation">
        +
      </div>
      <div class="adjustement">
        <div class="adjustement-name" v-t="'grid.editor.variableSalary'">
        </div>
        <div class="adjustement-value">
          {{ variable | formattedCurrency }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  componentsFormulaForRemunerationType,
  isGridInterval,
  remunerationOperationSymbol
} from '@/utils/grid'

export default {
  name: 'wageGridFormulaDetail',
  props: {
    wageDetails: {
      type: Object
    },
    simulationWageDetails: {
      type: Object
    },
    showVariable: {
      type: Boolean,
      default: true
    },
    showFormula: {
      type: Boolean,
      default: true
    },
    // Internal
    formula: {
      type: Object
    },
    index: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
      default: 0
    }
  },
  computed: {
    salary() {
      return this.wageDetails.summary.salary.value
    },
    delta() {
      return this.wageDetails.summary.salary.delta
    },
    variable() {
      return this.wageDetails.summary.salary.variableValue
    },
    computedFormula() {
      if (!this.showFormula) {
        return
      }
      if (this.wageDetails && this.wageDetails.hasLevels) {
        return componentsFormulaForRemunerationType(this.wageDetails.components, 'salary')
      }
      else {
        return this.formula
      }
    },
    depth() {
      if (this.total) {
        return this.total
      }
      else {
        return this.computeDepth(this.computedFormula)
      }
    },
    inversedIndex() {
      return this.depth - this.index
    },
    isRoot() {
      return this.index === 0
    },
    isClickable() {
      return (this.isRoot && !!this.$listeners.select) || this.$parent.isClickable
    },
    isInterval() {
      return isGridInterval(this.wageDetails && this.wageDetails.components)
    }
  },
  methods: {
    computeDepth(formula, index = 0) {
      if (formula && formula.components) {
        return 1 + formula.components.reduce((memo, component) => {
          memo += this.computeDepth(component, index)
          return memo
        }, 0)
      }
      else {
        return 0
      }
    },
    operationSymbol(operation) {
      return remunerationOperationSymbol(operation)
    },
    getParentComponent(component) {
      if (this.computedFormula) {
        return this.computedFormula.components.find(c => c.id === component.parentComponentId)
      }
    },
    select(component) {
      this.$emit('select', component)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/badge.scss";

// Based on GridFormula component
$line-height: 71px;
$border-radius: calc($line-height / 8);

.overflow {
  overflow: hidden;
}

div {
  display: inline-block;
  font-size: 0; // Disable undesired spaces
  @include line-regular-height;
}

.salary {
  border: 1px solid $graph-outer-border-color;
  border-radius: $border-radius;
  vertical-align: top;
  padding: 4px;
  background: lighten($graph-blue-color, 7);
  margin-bottom: 1rem;

  div {
    display: block;
    @include font-normal-size;
  }

  .salary-name {
    @include font-semibold;
    color: $graph-darkblue-color;
    padding-left: 3px;
  }

  .salary-value {
    margin: 3px 3px 4px;
    padding: 4px 7px;
  }
}

.salary-operation {
  content: "=";
  display: inline-block;
  @include font-big-size;
  @include font-semibold;
  @include font-tabular-numbers;
  line-height: $line-height + 2;
  padding: 0 12px;
  vertical-align: top;
  color: $text-color;
}

.adjustement {
  border: 1px solid $graph-outer-border-color;
  border-radius: $border-radius;
  vertical-align: top;
  padding: 3px 10px;
  background: lighten($graph-blue-color, 7);
  text-align: center;
  margin-bottom: 1rem;

  div {
    display: block;
    @include font-normal-size;
  }

  .adjustement-name {
    @include font-semibold;
    color: $graph-darkblue-color;
  }

  .adjustement-value {
    @include font-semibold;
    padding: 10px 0 13px;
  }
}

.formula {
  padding-left: 12px;

  .clickable {
    cursor: pointer;
  }

  &.root {
    overflow: hidden;
    border: 1px solid $graph-outer-border-color;
    border-radius: $border-radius;
    padding-right: 12px;
    margin-bottom: 1rem;
  }

  .component {
    padding: 4px 0;
    vertical-align: top;

    &.clickable:hover .component-name {
      text-decoration: underline;
    }

    div {
      @include font-normal-size;
      display: block;
    }

    .component-name {
      @include font-semibold;
      color: $graph-darkblue-color;
    }

    .component-selected-level-name {
      @include font-semibold;
    }

    .component-selected-level-value {
      @include font-tabular-numbers;
    }
  }

  .operation {
    @include font-big-size;
    @include font-semibold;
    @include font-tabular-numbers;
    line-height: $line-height;
    padding: 0 12px;
    vertical-align: top;
    color: $text-color;
  }

  &.group {
    padding-right: 12px;
    margin-left: -12px;
    border-radius: $border-radius;
  }

  &.group-1 {
    background: lighten($graph-blue-color, 7);
    box-shadow: inset -1px 0 0 rgba($graph-darkblue-color, 0.1);
  }

  &.group-2 {
    background: lighten($graph-blue-color, 9);
    box-shadow: inset -1px 0 0 rgba($graph-darkblue-color, 0.1);
  }

  &.group-3 {
    background: white;
  }
}
</style>
