<template>
  <div class="container">
    <h1 v-t="'benefits.group.title'" />
    <LoadingView v-if="!isLoaded" />
    <div v-else-if="benefitsPlans.length === 0">
      <p v-html="$t('benefits.group.description')" />
      <menu class="hero">
        <router-link
          :to="{name: 'newBenefitsPlan'}"
          tag="button"
          class="primary"
          v-t="'benefits.group.create'" />
      </menu>
    </div>
    <div v-else>
      <div class="benefits-plans">
        <template v-for="benefitsPlan in benefitsPlans">
          <div
            class="benefit-plan"
            :key="benefitsPlan.id"
            @click="$router.push({name: 'benefitsPlan', params: {id: benefitsPlan.id}})">
            <h3>{{benefitsPlan.name}}</h3>
            <p v-if="benefitsPlan.description">
              {{benefitsPlan.description}}
            </p>
            <p class="detail">
              {{$tc('benefits.count', benefitsPlan.benefitsCount)}}
              <span
                v-if="benefitsPlan.benefitsCount && benefitsPlan.employeesCount"
                v-html="$tc('benefits.employeesCount', benefitsPlan.employeesCount)" />
            </p>
          </div>
        </template>
      </div>
      <hr>
      <div class="add-plan">
        <p v-html="$t('benefits.group.description')" />
        <router-link
          :to="{name: 'newBenefitsPlan'}"
          tag="button"
          class="primary"
          v-t="'benefits.group.create'" />
      </div>
    </div>
  </div>
</template>

<script>
import LoadingView from '@components/commons/LoadingView'
import { mapGetters } from 'vuex'

export default {
  components: {
    LoadingView
  },
  computed: {
    ...mapGetters({
      isLoaded: 'benefits/isLoaded',
      benefitsPlans: 'benefits/getBenefitsPlans'
    })
  },
  mounted() {
    this.$store.dispatch('benefits/getBenefitsPlans')
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";

.container {
  @include container;
}

.benefits-plans {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
  align-items: flex-start;

  .benefit-plan {
    padding: 10px;
    background-color: $graph-background-color;
    border: 1px solid $graph-outer-border-color;
    border-radius: $border-radius;
    cursor: pointer;

    &:hover {
      background: darken($graph-lightblue-color, 2);
    }

    h3 {
      margin-bottom: 0.1em;
    }

    p {
      padding: 0;
      margin: 0;
    }

    .detail {
      @include font-small-size;
      margin-top: 0.5em;
      color: $light-text-color;
    }
  }
}

.add-plan {
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    max-width: 800px;
    margin: 0;
  }
}

hr {
  display: block;
  border: 0;
  border-top: $border;
  height: 1px;
  margin: 2em auto 1em auto;
}
</style>
