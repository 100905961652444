<template>
  <div class="container">
    <VariablePlans />
  </div>
</template>

<script>
import VariablePlans from '@components/variable/VariablePlans.vue'

export default {
  components: {
    VariablePlans
  }
}
</script>

<style lang="scss" scoped>
.container {
  @include container;
}
</style>
