<template>
  <span class="grid-version">
    {{ $t('grid.current.version', { version: this.gridVersion }) }}
  </span>
</template>

<script>
import { getGridVersion } from '@/utils/grid'
export default {
  props: {
    grid: Object
  },
  computed: {
    gridVersion() {
      const currentGrid = this.$store.getters['currentGrid/getCurrentGrid']
      return getGridVersion(this.grid, currentGrid)
    }
  }
}
</script>

<style lang="scss" scoped>
.grid-version {
  color: $light-text-color;
  margin-left: 1.25em;
  letter-spacing: -0.04em;
  margin-bottom: -2px;
}
</style>
