import uniq from 'lodash.uniq'
import flattenDeep from 'lodash.flattendeep'
import i18n from '@/i18n'

// Slugify
export function slugify(text) {
  if (text) {
    return text.toString()
      .toLowerCase()
      .trim()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\s+/g, '-')
      .replace(/&/g, '-and-')
      .replace(/[^\w-]+/g, '')
      .replace(/--+/g, '-')
  }
  else {
    return null
  }
}

// Capitalize
export function capitalize(value) {
  if (!value) {
    return ''
  }
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
}

// Titleize
export function titleize(value) {
  if (!value) {
    return ''
  }
  return value.split(' ').map(capitalize).join(' ')
}

// Generate sequential names
export function getSequentialName(name, existingNames, ignoreName, showFirst) {
  const reversedVersions = existingNames
    .filter(existingName => existingName.includes(name) &&
      (!ignoreName || ignoreName !== existingName))
    .map(existingName => {
      const matchingVersion = existingName.match(/#(\d+)$/)
      return matchingVersion ? +matchingVersion[1] : 1
    })
    .sort()
    .reverse()
  if (reversedVersions.length > 0) {
    name += ' #' + ++reversedVersions[0]
  }
  else if (showFirst) {
    name += ' #1'
  }
  return name
}

// Filter arrays with pre-built search terms
export function buildSearchTerms(...args) {
  return slugify(args.join(' '))
}

export function matchSearchTerms(terms, key) {
  return terms.reduce((found, term) => {
    return found && key.indexOf(term) !== -1
  }, true)
}

function filterBySubQuery(array, query, searchTermsKey = '_searchKey') {
  if (query) {
    const searchTerms = query.split(' ')
      .map(term => buildSearchTerms(term))
      .filter(term => !!term)

    return array.filter(employee => (
      employee.id === query ||
      employee.email === query ||
      matchSearchTerms(searchTerms, employee[searchTermsKey] || '')
    ))
  }
  else {
    return array
  }
}

export function filterByQuery(array, query, searchTermsKey = '_searchKey') {
  if (query) {
    const subqueries = query.split(/[,;|]/)
      .map(subquery => subquery.trim())
      .filter(subquery => !!subquery)

    return uniq(flattenDeep(subqueries.map(subquery => filterBySubQuery(array, subquery, searchTermsKey))))
  }
  else {
    return array
  }
}

// Sort array while handling number & diacritics
export function localeCompare(a, b, order) {
  if (a === b) {
    return 0
  }
  else if (a === null || a === undefined) {
    return 1
  }
  else if (b === null || b === undefined) {
    return -1
  }
  else if (typeof a === 'number') {
    return (a > b ? 1 : -1) * order
  }
  else {
    return a.localeCompare(b) * order
  }
}

export function sortByKeys(array, sortKey, sortOrder = 1, groupKey = null) {
  return [].concat(array).sort((aa, bb) => {
    const defaultSortKey = 'fullName'
    const a = aa[sortKey]
    const b = bb[sortKey]

    if (groupKey && groupKey !== 'none') {
      const c = aa[groupKey]
      const d = bb[groupKey]
      const groupResult = localeCompare(c, d, 1)
      if (groupResult !== 0) {
        return groupResult
      }
    }

    if (a === b) {
      return localeCompare(aa[defaultSortKey], bb[defaultSortKey], sortOrder)
    }
    else {
      return localeCompare(a, b, sortOrder)
    }
  })
}

// Extract domain from http(s) host
export function formatDomain(host) {
  return host.trim().replace(/https?:\/\//, '')
}

// Join array with comma and append last occurence with `common.and`
// "Pierre, Paul and Jacques"
export function formatList(list) {
  if (!list || list.length === 0) return ''

  if (list.length > 1) {
    return `${list.slice(0, -1).join(', ')} ${i18n.t('common.and')} ${list.slice(-1)}`
  }

  return list[0]
}

// Insert content in place based on DOM node selection
export function insertContentInPlace(value, addedValue, node) {
  try {
    if (node) {
      value = value || ''
      const { selectionStart, selectionEnd } = node
      return value.slice(0, selectionStart) +
        addedValue +
        value.slice(selectionEnd)
    }
    else {
      return addedValue
    }
  }
  catch (_) {
    return addedValue
  }
}

// Takes a numeric string, returns a float
// Like parseFloat, but with better decimal and percent support
export function parseStringFloat(text) {
  text = (text || '0').toString()
    .trim()
    .replace(/,/g, '.') // Replace french decimal delimiter `,` by `.`
    .replace(/([0-9]) ([0-9])/g, '$1$2') // Replace french thousand delimiter `1 000` by `1000`

  const divider = text.includes('%') ? 100 : 1
  return parseFloat(text) / divider
}
