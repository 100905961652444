<template>
  <div class="variable-plan-templates">
    <h1 v-t="'variable.templates.title'"></h1>
    <p v-html="$t('variable.templates.intro')"></p>
    <div v-for="name in kinds" :key="name">
      <h2 v-t="`variable.kinds.${name}.title`"></h2>
      <VariablePlanDemoSequence :name="name">
        <template v-slot="slotProps">
          <slot :variablePlan="slotProps.variablePlan" />
        </template>
      </VariablePlanDemoSequence>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VariablePlanDemoSequence from '@components/variable/VariablePlanDemoSequence.vue'

export default {
  components: {
    VariablePlanDemoSequence
  },
  props: {
    // Pass a kind to move this template to the top
    kind: {
      type: String
    }
  },
  computed: {
    ...mapGetters({
      getKinds: 'variablePlans/getKinds'
    }),
    kinds() {
      let kinds = this.getKinds
      if (kinds.includes(this.kind)) {
        kinds = [this.kind, ...kinds.filter(k => k !== this.kind)]
      }
      return kinds
    }
  }
}
</script>

<style lang="scss" scoped>
.variable-plan-templates {
  width: $content-max-width;
  padding: 20px;
}

h2 {
  margin: 0 0 0.5em 0;
}
</style>
