<template>
  <div class="indicator-input-container" v-if="!['reference', 'constant'].includes(kind)">
    <div class="indicator-label">{{indicator.name}}</div>
    <div class="indicator-input" :class="[kind, disabled && 'disabled']" >
      <template v-if="['value', 'tiers'].includes(kind)">
        <input
          class="value"
          type="number"
          min="0"
          :step="step"
          v-model="inputValueModel"
          :disabled="disabled"
          @input="onChange">
      </template>
      <template v-else-if="kind === 'reference' && false">
        <!-- Hidden we figure a proper update pattern for references -->
        <input
          class="value"
          type="number"
          min="0"
          :step="step"
          v-model="inputValueModel"
          :disabled="disabled || mode !== 'simulation'"
          @input="onChange">
      </template>
      <template v-else-if="kind === 'percentage'">
        <input
          class="percentage"
          type="number"
          min="0"
          max="1000"
          step="1"
          :disabled="disabled"
          v-model="inputValueModel"
          @input="onChange">
      </template>
      <template v-else-if="kind === 'checkbox' && mapping">
        <checkbox
          v-model="inputValueModel"
          :disabled="disabled"
          @change="onChange">{{mapping[0].name}}</checkbox>
      </template>
      <template v-else-if="kind === 'list' && mapping">
        <select v-model="inputValueModel" @change="onChange" :disabled="disabled">
          <option
            v-for="(option, i) in mapping"
            :key="i"
            :value="option.name">{{option.name}}</option>
        </select>
      </template>
    </div>
  </div>
</template>

<script>
import { getInputValueFromValue, getValueFromInputValue } from '@/utils/variable'
import Checkbox from '@components/commons/Checkbox'

export default {
  components: {
    Checkbox
  },
  props: {
    // Indicator config
    indicator: Object,
    // Simulation indicator
    value: Object,
    // Disabled status
    disabled: Boolean,
    // Evaluation mode
    mode: String
  },
  data() {
    return {
      inputValueModel: null
    }
  },
  computed: {
    kind() {
      return this.indicator.kind
    },
    mapping() {
      return this.indicator.mapping
    },
    step() {
      return +this.inputValueModel <= 100 ? 1 : 100
    }
  },
  methods: {
    initModel() {
      const { value, inputValue } = this.value
      this.inputValueModel = getInputValueFromValue(this.indicator, value, inputValue)
      this.onChange()
    },
    onChange() {
      if (this.value.frozen) {
        return
      }
      const inputValue = this.inputValueModel
      const value = getValueFromInputValue(this.indicator, inputValue)
      if (value !== this.value.value || inputValue !== this.value.inputValue) {
        const model = { ...this.value, value, inputValue }
        this.$emit('input', model)
      }
    }
  },
  watch: {
    'indicator.kind': 'initModel',
    'indicator.mapping': 'initModel',
    value: 'initModel'
  },
  created() {
    this.initModel()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/form.scss";

.indicator-label {
  @include font-small-size;
  @include font-semibold;
  margin-bottom: 5px;
}

.indicator-input {
  display: inline-block;
  position: relative;
  @include font-big-size;

  input[type=number] {
    @include font-big-size;
  }

  &.value,
  &.tiers,
  &.reference {
    input {
      width: 6.3em;
    }
  }

  &.percentage {
    input {
      padding-right: 32px;
      width: 5.5em;
    }

    &::after {
      content: "%";
      @include font-tabular-numbers;
      position: absolute;
      right: 15px;
      top: 6px;
    }
  }

  &.checkbox {
    @include font-normal-size;
    padding: 6px 14px 6px 8px;
    background: white;
    border-radius: 15px;
    border: $border;
    background-color: white;

    &.disabled {
      background-color: lighten($background-color, 2);
    }
  }
}
</style>
