import store from '@/store'
import api from '@/store/api'
import i18n from '@/i18n'
import { required, email, decimal } from 'vuelidate/lib/validators'
import { dateValidator, normalizeDate } from '@/utils/date'
import { slugify } from '@/utils/string'
import { getField, getFields } from '@/utils/employee'
import orderBy from 'lodash.orderby'

const REQUIRED_FIELDS = ['firstName', 'lastName']
const NON_REVIEW_FIELDS = ['firstName', 'lastName', 'gender', 'email']

const initialState = () => {
  return {
    requiredFields: null,
    optionalFields: null,
    columns: [],
    employees: null,
    selectedColumns: [],

    raiseMissingRequiredFields: false
  }
}
const state = initialState()

function isFloat(n) {
  return Number(n) === n && n % 1 !== 0
}

const GENDER_FEMALE = ['femme', 'feminin', 'female', 'fem', 'f']
const GENDER_MALE = ['homme', 'masculin', 'male', 'mle', 'm', 'h']
const GENDERS = [...GENDER_FEMALE, ...GENDER_MALE]

function validateGender(value) {
  return GENDERS.includes(slugify(value))
}

function validateUniqueness(rows) {
  return rows.every(value => rows.filter(row => row === value).length <= 1)
}

function normalizeGender(value) {
  return GENDER_FEMALE.includes(slugify(value)) ? 'f' : 'm'
}

function validateColumnType(field, rows) {
  // Generate errors for each types
  switch (field.type) {
    case 'string':
      if (!rows.every(required)) {
        return 'invalid string'
      }
      break
    case 'gender':
      if (!rows.every(required) || !rows.every(validateGender)) {
        return 'invalid gender'
      }
      break
    case 'email':
      if (!rows.every(required) || !rows.every(email) || !validateUniqueness(rows)) {
        return 'invalid email'
      }
      break
    case 'date':
      if (!rows.every(row => (!row || dateValidator(row)))) {
        return 'invalid date'
      }
      break
    case 'float':
      if (!rows.every(required) || !rows.every(decimal)) {
        return 'invalid float'
      }
      break
  }
  return 'valid'
}

function computeStatus(columns, fields, employees, isRequired = false, raiseMissingRequiredFields = false) {
  if (columns.length && columns.length !== employees[0].length) {
    console.warn('Selected columns', columns, 'does not match employees columns', employees[0])
  }
  return fields.map(field => {
    let status = ''
    const matchingColumns = columns.filter(c => c === field.id)
    let values = []

    if (matchingColumns.length === 1) {
      const columnIndex = columns.indexOf(field.id)

      values = employees.map(e => e[columnIndex])
      status = validateColumnType(field, values)
    }
    else if (matchingColumns.length > 1) {
      status = 'invalid duplicate'
    }
    else if (matchingColumns.length === 0 && isRequired && raiseMissingRequiredFields) {
      status = 'invalid required'
    }
    else if (matchingColumns.length === 0) {
      status = ''
    }

    return Object.assign({}, field, { status: status, values: values })
  })
}

const getters = {
  getFields(state) {
    const requiredFields = getFields(null, { include: REQUIRED_FIELDS, store })
      .map(f => ({ ...f, review: !NON_REVIEW_FIELDS.includes(f) }))
    const optionalFields = getFields(null, { exclude: REQUIRED_FIELDS, store })
      .map(f => ({ ...f, review: !NON_REVIEW_FIELDS.includes(f) }))
    return {
      required: state.requiredFields || requiredFields,
      optional: state.optionalFields || optionalFields
    }
  },
  getAllFields(_, { getFields }) {
    return [...getFields.required, ...getFields.optional]
  },
  getFieldById(_, { getAllFields }) {
    return id => getAllFields.find(f => f.id === id)
  },
  getSelectedColumns(state) {
    return state.selectedColumns
  },
  getEmployees(state) {
    return state.employees
  },
  getParsedEmployees(state, getters) {
    return orderBy(state.employees.map(e => {
      return state.selectedColumns.reduce((memo, column, i) => {
        if (column && column !== 'ignored') {
          const field = getters.getFieldById(column)
          let value = e[i]

          if (!getters.validateCell(i, value)) {
            // Ignore invalid value
            return memo
          }
          if (field && field.type === 'date') {
            value = value ? normalizeDate(value) : null
          }
          if (field && field.type === 'gender') {
            value = normalizeGender(value)
          }
          if (field && field.type === 'string') {
            value = value.toString()
          }
          if (column === 'salary') {
            memo.initialWage = memo.initialWage || { hasLevels: false }
            memo.initialWage.overridenSalaryValue = value
          }
          else if (column === 'contractualVariable') {
            memo.initialWage = memo.initialWage || { hasLevels: false }
            memo.initialWage.contractualVariableValue = value
          }
          else if (field.category === 'custom') {
            memo.customFields = memo.customFields || {}
            memo.customFields[column] = value
          }
          else {
            memo[column] = value
          }
        }
        return memo
      }, {})
    }), [e => getField(e, 'fullName')])
  },
  getColumns(state) {
    return state.columns
  },
  getDefaultSelectedColumns(state, getters) {
    const fieldsWithSlug = getters.getAllFields.map(field => (
      Object.assign({ slug: slugify(field.name) },
        field)
    ))
    return state.columns.map((c, i) => {
      const columnSlug = c && slugify(c)
      const matchingField = fieldsWithSlug.find(f => f.slug === columnSlug)
      const rows = state.employees.map(e => e[i])
      const isEmpty = rows.filter(required).length === 0

      return matchingField && !isEmpty ? matchingField.id : ''
    })
  },
  validateCell(state, getters) {
    return (column, value) => {
      const columnId = state.selectedColumns[column]
      const field = getters.getFieldById(columnId)

      if (field) {
        const status = validateColumnType(field, [value])
        return status.indexOf('invalid') === -1
      }
      else {
        return true
      }
    }
  },
  getEmployeesTemplate(_, __, ___, rootGetters) {
    return () => {
      const store = { getters: rootGetters }
      const fields = getFields(null, { store })
      const employees = [
        {
          firstName: 'Becky',
          lastName: 'Sims',
          gender: 'F',
          birthdate: new Date('1991-03-17'),
          employeeNumber: '0013',
          email: 'becky.sims@example.com',
          arrivalDate: new Date('2018-04-06'),
          departureDate: null,
          jobGroup: i18n.t('employees.import.template.jobGroup.product'),
          jobType: i18n.t('employees.import.template.jobType.manager'),
          jobTitle: i18n.t('employees.import.template.jobTitle.productManager'),
          managementLevel: i18n.t('employees.import.template.managementLevel.director'),
          city: 'Paris',
          salary: 54000,
          contractualVariable: 4000,
          contractType: i18n.t('employees.import.template.contractType.cdi'),
          spc: i18n.t('employees.import.template.spc.cadre'),
          manager: null,
          department: i18n.t('employees.import.template.department.product'),
          subDepartment: null
        },
        {
          firstName: 'Hadrien',
          lastName: 'Mayer',
          gender: 'H',
          birthdate: new Date('1982-05-09'),
          employeeNumber: '0045',
          email: 'hadrien.meyer@example.com',
          arrivalDate: new Date('2020-04-18'),
          departureDate: new Date('2022-03-20'),
          jobGroup: i18n.t('employees.import.template.jobGroup.tech'),
          jobType: i18n.t('employees.import.template.jobType.developer'),
          jobTitle: i18n.t('employees.import.template.jobTitle.iOSDeveloper'),
          managementLevel: i18n.t('employees.import.template.managementLevel.employee'),
          city: 'Lyon',
          salary: 43000,
          contractualVariable: null,
          contractType: i18n.t('employees.import.template.contractType.cdd'),
          spc: i18n.t('employees.import.template.spc.cadre'),
          manager: 'Becky Sims',
          department: i18n.t('employees.import.template.department.tech'),
          subDepartment: i18n.t('employees.import.template.subDepartment.apps')
        }
      ]
      const header = fields.map(({ name }) => name)
      const rows = employees.map(employee => {
        return fields.map(({ id }) => employee[id])
      })
      return [header, ...rows]
    }
  }
}

const actions = {
  reset(context) {
    context.commit('reset')
  },
  rawConvert(context, file) {
    let endpoint
    const data = new FormData()

    switch (file.type) {
      case 'text/csv':
        endpoint = '/utils/convert/csv'
        break
      default:
        endpoint = '/utils/convert/xlsx'
        break
    }
    data.append('file', file)

    return api.post(endpoint, data)
      .then(({ data }) => {
        return Object.values(data).map(Object.values)
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  convert(context, file) {
    return context.dispatch('rawConvert', file)
      .then(rows => {
        return context.dispatch('open', rows)
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  open(context, rows) {
    // Detect header row based on presence of name label
    const fields = context.getters.getAllFields.map(field => field.name)
    const headerIndex = rows.findIndex(c => !!c.find(d => fields.includes(d)))
    const columns = headerIndex !== -1 ? rows[headerIndex] : []
    const employees = headerIndex !== -1 ? rows.slice(headerIndex + 1) : []

    // Round floats to 2 decimals
    employees.forEach(employee => {
      employee.forEach((value, i) => {
        if (isFloat(value)) {
          employee[i] = Math.round(+value * 100) / 100
        }
      })
    })

    // Throw error if empty
    if (employees.length < 1) {
      return Promise.reject()
    }

    context.commit('setEmployees', employees)
    context.commit('setColumns', columns)
    context.commit('setSelectedColumns', context.getters.getDefaultSelectedColumns)
    context.commit('setRaiseMissingRequiredFields', false)
    context.commit('validate', context.getters.getFields)
  },
  validate(context) {
    context.commit('validate', context.getters.getFields)
  },
  submit(context) {
    context.commit('setRaiseMissingRequiredFields', true)
    context.commit('validate', context.getters.getFields)

    // Make sure all fields are ok
    if (context.getters.getFields.required.every(f => f.status === 'valid')) {
      const employees = context.getters.getParsedEmployees

      // Detect duplicates
      const duplicates = []
      employees.reduce((memo, employee) => {
        const employeeSlug = getField(employee, 'slug')
        if (!memo[employeeSlug]) {
          memo[employeeSlug] = true
        }
        else {
          duplicates.push(getField(employee, 'fullName'))
        }
        return memo
      }, {})
      if (duplicates.length) {
        return Promise.reject({
          error: i18n.t('employees.import.errors.duplicate', {
            employees: duplicates.join(', ')
          })
        })
      }

      return api.put('/employees', { employees })
        .then(response => {
          const data = response.data
          if (data.created && data.updated &&
            (data.created.length || data.updated.length)) {
            return this.dispatch('employees/getEmployees').then(() => {
              return data
            })
          }
          else {
            return Promise.reject()
          }
        })
        .catch(_ => {
          return Promise.reject({ error: i18n.t('employees.import.errors.failure') })
        })
    }
  },
  setSelectedColumn(context, selection) {
    context.commit('setSelectedColumn', selection)
    context.commit('validate', context.getters.getFields)
  }
}

const mutations = {
  reset(state) {
    Object.assign(state, initialState())
  },
  setEmployees(state, employees) {
    state.employees = employees
  },
  setColumns(state, columns) {
    state.columns = columns
  },
  setSelectedColumn(state, { column, value }) {
    if (column < state.selectedColumns.length) {
      state.selectedColumns[column] = value
    }
    else {
      console.warn('Selected column index', column, 'is outside bounds of available columns', state.selectedColumns.length)
    }
  },
  setSelectedColumns(state, selectedColumns) {
    state.selectedColumns = selectedColumns
  },
  setRaiseMissingRequiredFields(state, raiseMissingRequiredFields) {
    state.raiseMissingRequiredFields = raiseMissingRequiredFields
  },
  validate(state, fields) {
    state.requiredFields = computeStatus(
      state.selectedColumns,
      fields.required,
      state.employees,
      true,
      state.raiseMissingRequiredFields)
    state.optionalFields = computeStatus(
      state.selectedColumns,
      fields.optional,
      state.employees)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
