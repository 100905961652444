import moment from 'moment'
import formatSetup from 'moment-duration-format'
import Vue from 'vue'
import i18n from '@/i18n'

// Setup moment-duration-format for i18n
formatSetup(moment)
Object.keys(i18n.messages).forEach(locale => {
  moment.updateLocale(locale, {
    durationLabelsStandard: {
      S: i18n.tc('date.duration.millisecond', 1, locale),
      SS: i18n.tc('date.duration.millisecond', 2, locale),
      s: i18n.tc('date.duration.second', 1, locale),
      ss: i18n.tc('date.duration.second', 2, locale),
      m: i18n.tc('date.duration.minute', 1, locale),
      mm: i18n.tc('date.duration.minute', 2, locale),
      h: i18n.tc('date.duration.hour', 1, locale),
      hh: i18n.tc('date.duration.hour', 2, locale),
      d: i18n.tc('date.duration.day', 1, locale),
      dd: i18n.tc('date.duration.day', 2, locale),
      w: i18n.tc('date.duration.week', 1, locale),
      ww: i18n.tc('date.duration.week', 2, locale),
      M: i18n.tc('date.duration.month', 1, locale),
      MM: i18n.tc('date.duration.month', 2, locale),
      y: i18n.tc('date.duration.year', 1, locale),
      yy: i18n.tc('date.duration.year', 2, locale)
    }
  })
})

export function parseDate(string, strict = true) {
  moment.locale(i18n.locale)
  return moment(string, ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD', 'DD/MM/YYYY'], strict)
}

export function parseDateUTC(string, strict = true) {
  moment.locale(i18n.locale)
  return moment.utc(string, ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD', 'DD/MM/YYYY'], strict)
}

export function normalizeDate(string, strict = true) {
  return parseDate(string, strict).format('YYYY-MM-DD')
}

export function formatDiff(diff, precision) {
  return moment
    .duration(Math.abs(diff), 'days')
    .format({ largest: precision })
    .replace(', ', i18n.t('date.and'))
}

export function buildNormalizedDate(addMonth = 0) {
  const today = new Date()
  const date = new Date(today.getFullYear(), today.getMonth() + addMonth)
  return normalizeDate(date)
}

export function dateInFuture(string) {
  return parseDate(string).diff(moment().startOf('day')) >= 0
}

export function dateIsBefore(dateString, referenceDateString) {
  return parseDate(dateString).isBefore(parseDate(referenceDateString))
}

export function dateValidator(string) {
  return parseDate(string).isValid()
}

export function fullFormattedDate(string) {
  if (!string) {
    return ''
  }

  return parseDate(string, false).format('LL')
}

export function formattedDate(string) {
  if (!string) {
    return ''
  }

  const date = parseDate(string, false)
  return date.isValid() ? date.format('DD/MM/YYYY') : '?' + string
}

export function formatDate(string, format = 'DD/MM/YYYY') {
  if (!string) {
    return ''
  }

  const date = parseDate(string, false)
  return date.isValid() ? date.format(format) : '?' + string
}

export function formatCalendar(string) {
  if (!string) {
    return ''
  }

  const date = parseDate(string, false)
  return date.isValid() ? date.calendar(null, { sameElse: i18n.t('date.calendar.sameElse') }).toLowerCase() : '?' + string
}

export function formattedMonthAndYear() {
  const month = parseDate(new Date()).format('MMMM YYYY')
  return month[0].toUpperCase() + month.slice(1)
}

export function yearsDiffFromNow(string) {
  if (!string) {
    return ''
  }

  const date = parseDate(string)
  return moment().diff(date, 'years')
}

export function fullDiffFromNow(string, precision = 2) {
  if (!string) {
    return ''
  }

  const date = parseDate(string, false)
  const diff = moment().startOf('day').diff(date, 'days')

  if (diff === 0) {
    return `(${i18n.t('date.today')})`
  }

  const duration = formatDiff(diff, precision)
  if (diff > 0) {
    return `(${i18n.t('date.xDurationAgo', { duration })})`
  }
  else {
    return `(${i18n.t('date.inXDuration', { duration })})`
  }
}

export function fullDiffRaw(fromString, toString) {
  if (!fromString) {
    return ''
  }

  if (!toString) {
    toString = new Date()
  }

  const fromDate = parseDate(fromString)

  const toDate = parseDate(toString)

  return toDate.diff(fromDate, 'days') + 1
}

export function fullDiff(fromString, toString, precision = 2) {
  const diff = fullDiffRaw(fromString, toString)

  return formatDiff(diff, precision)
}

export function dateDiff(fromString, toString, period) {
  const fromDate = moment.utc(fromString)
  const toDate = moment.utc(toString)

  return toDate.diff(fromDate, period)
}

export function getRecurrenceDelta(recurrence) {
  switch (recurrence) {
    case 'weekly':
      return { period: 'week', value: 1 }
    case 'monthly':
      return { period: 'month', value: 1 }
    case 'quarterly':
      return { period: 'month', value: 3 }
    case 'biannual':
      return { period: 'month', value: 6 }
    case 'yearly':
      return { period: 'month', value: 12 }
    default:
      return { period: 'day', value: 1 }
  }
}

Vue.filter('formattedDate', formattedDate)
Vue.filter('formatDate', formatDate)
Vue.filter('formatCalendar', formatCalendar)
Vue.filter('fullFormattedDate', fullFormattedDate)
Vue.filter('fullDiffFromNow', fullDiffFromNow)
Vue.filter('yearsDiffFromNow', yearsDiffFromNow)
Vue.filter('fullDiff', fullDiff)
Vue.filter('formatDiff', formatDiff)
