<template>
  <div class="review">
    <div
      class="field-box"
      v-for="field in fields"
      :key="field.id">
      <header>
        <h3>
          {{$t(`employees.review.${field.id}.title`)}}
          <span class="emoji">✏️</span>
        </h3>
      </header>
      <main>
        <resizable-panel :observer="[field.values, listObserver]">
          <p class="no-margin-top preserve-lines" v-t="`employees.review.${field.id}.body`"></p>
            <transition-group
              name="list"
              tag="ul"
              @after-enter="onTransitionEnd"
              @after-leave="onTransitionEnd">
              <li
                v-for="value in field.values"
                :key="value.id">
                <checkbox
                  class="green"
                  :value="value.id"
                  v-model="field.selected">
                </checkbox>
                <input
                  type="text"
                  v-model="value.current"
                  @input="onChange">
                <employees-badges
                  :employees="value.employees" />
              </li>
            </transition-group>
          <p v-if="field.values.length > 1">
            <span class="check-arrow">⤵</span>
            <button
              class="small-button"
              :class="{triggered: field.selected.length > 1}"
              @click="mergeFieldValues(field)"
              v-t="'employees.review.merge'"></button>
            <span
              v-if="isMergedField == field.id"
              class="merge-indicator reveal"
              v-t="'employees.review.mergeSuccess'"></span>
          </p>
        </resizable-panel>
      </main>
    </div>
  </div>
</template>

<script>
import Checkbox from '@components/commons/Checkbox.vue'
import ResizablePanel from '@components/commons/ResizablePanel.vue'
import EmployeesBadges from '@components/employees/EmployeesBadges'

export default {
  components: {
    Checkbox,
    EmployeesBadges,
    ResizablePanel
  },
  props: ['value'],
  data() {
    return {
      fields: this.value,
      isMergedField: false,
      listObserver: 0
    }
  },
  methods: {
    onTransitionEnd() {
      this.listObserver++
    },
    mergeFieldValues(field) {
      if (field.selected.length > 1) {
        const values = field.selected.map(s => field.values.find(f => f.id === s))
        const firstValue = values.shift()
        values.forEach(remainingValue => {
          firstValue.merged.push(remainingValue.initial)
          firstValue.employees = [...firstValue.employees, ...remainingValue.employees]
        })
        field.selected = []
        field.values = field.values.filter(f => !firstValue.merged.includes(f.initial))
        this.onChange()
        this.isMergedField = field.id
      }
    },
    updateValue() {
      this.isMergedField = false
      this.fields = this.value
    },
    onChange() {
      this.$emit('input', this.fields)
    }
  },
  watch: {
    value: 'updateValue'
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";

$padding: 5px 12px;
.field-box {
  border: $border;
  border-radius: $border-radius;
  overflow: hidden;
  margin-bottom: 1.5em;
}

header {
  background-color: $lightteal-color;
  padding: $padding;
  border-bottom: $border;

  h3 {
    margin: 0;
    line-height: 2em;
  }

  .emoji {
    display: inline-block;
    margin-left: 0.4em;
    transform: scaleX(-1) scale(0.8);
  }
}

main {
  background: lighten($background-color, 2);
  padding: $padding;
  padding-top: 1em;
}

ul {
  list-style-type: none;
  padding-left: 2px;
}

li {
  height: 34px;
  margin-bottom: 0.5em;
  transform-origin: 50px center;

  &:last-of-type {
    margin-bottom: 0;
  }
}

input[type="text"] {
  display: inline-block;
  width: 300px;
}

.check-arrow {
  @include font-bold;
  display: inline-block;
  transform: rotate(180deg) translateY(8px);
  margin: 0 7px;
  color: $light-text-color;
}

.small-button.triggered {
  color: $clearteal-color;
  border-color: lighten($clearteal-color, 20);
}

.employees-badges {
  display: inline-block;
  margin-left: 1em;
}

.merge-indicator {
  color: $clearteal-color;
  margin-left: 0.75em;
}

.list-enter-active, .list-leave-active {
  transition: transform 300ms, opacity 300ms, height 500ms, margin-bottom 500ms;
}

.list-enter, .list-leave-to {
  transform: scale(0.8);
  opacity: 0;
  height: 0;
  margin-bottom: 0;
}
</style>
