<template>
  <div>
    <EmployeesTableContainer
      :employees="selectedEmployees"
      :hideWhenEmpty="true">
      <template v-slot:title>
        <span v-t="'benefits.beneficiaries'" />
        <span class="badge small-badge border-badge">
          {{benefitsPlan.employeeIds.length}}
        </span>
      </template>
      <template v-slot:company>
        <menu>
          <button
            class="primary"
            @click="isEmployeePickerDisplayed = true"
            v-t="'benefits.group.update'" />
        </menu>
      </template>
    </EmployeesTableContainer>
    <menu v-if="!selectedEmployees.length" class="hero placeholder no-margin-bottom">
      <p class="no-margin-top" v-t="'benefits.group.beneficiariesExplanation'" />
      <button
        class="primary"
        @click="isEmployeePickerDisplayed = true"
        v-t="'benefits.group.addEmployees'" />
    </menu>
    <Modal :visible="isEmployeePickerDisplayed" @close="isEmployeePickerDisplayed = false">
      <div class="employees-picker-modal">
        <EmployeesPicker v-model="selectedEmployees" @select="updateEmployees" />
      </div>
    </Modal>

  </div>
</template>

<script>
import EmployeesPicker from '@components/commons/EmployeesPicker'
import EmployeesTableContainer from '@components/employees/EmployeesTableContainer'
import Modal from '@components/commons/Modal'
import { mapGetters } from 'vuex'

export default {
  components: {
    EmployeesPicker,
    EmployeesTableContainer,
    Modal
  },
  data() {
    return {
      isEmployeePickerDisplayed: false
    }
  },
  computed: {
    ...mapGetters({
      benefitsPlan: 'benefits/getCurrentBenefitsPlan',
      getEmployeesByIds: 'employees/getEmployeesByIds'
    }),
    selectedEmployees() {
      return this.benefitsPlan.employeeIds ? this.getEmployeesByIds(this.benefitsPlan.employeeIds) : []
    }
  },
  methods: {
    async updateEmployees(employees) {
      const employeeIdsToAdd = employees.filter(e => e.isSelected).map(e => e.id)
      const employeeIdsToRemove = employees.filter(e => !e.isSelected).map(e => e.id)

      try {
        await this.$store.dispatch('benefits/setBenefitsPlanEmployees', { employeeIdsToAdd, employeeIdsToRemove })
      }
      catch (errorMessage) {
        errorMessage && alert(errorMessage)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/badge.scss";
@import "./src/styles/button.scss";

.badge {
  margin-left: 0.8em;
}

.placeholder {
  @extend .badge-block;
  display: block;
  margin-top: 2em;
  padding: 1em 1em;
}

.employees-picker-modal {
  width: 650px;
  padding: 2em 1em 1em 1em;
}
</style>
