<template>
  <div v-if="benefitsPlanModel">
    <div class="toolbar">
      <h1>
        <InlineEditor
          v-model="benefitsPlanModel.name"
          :placeholder="benefitsPlanModel.name"
          :error="$v.benefitsPlanModel.name.$error"
          @submit="updateBenefitsPlan" />
      </h1>
      <LoadingButton
        class="destructive"
        :loading="isDeleting"
        @click="deleteBenefitsPlan">
        <span v-t="'benefits.group.delete'"></span>
      </LoadingButton>
    </div>
    <MarkdownTextEditor
      :showAddButton="true"
      :showInlineEditButton="true"
      v-model="benefitsPlanModel.description"
      @submit="updateBenefitsPlan" />
    <template v-if="valorisation">
      <div class="valorisation">
        <BenefitValorisationSummary
          v-if="valorisation"
          :valorisation="valorisation"
          :estimationTitle="$t('benefits.valorisationSummary.companyCostPerEmployee')" />
        <BenefitValorisationSummary
          v-if="totalValorisation"
          :estimationTitle="$t('benefits.valorisationSummary.globalCompanyCost')"
          :valorisation="totalValorisation" />
      </div>
    </template>
    <BenefitsEditor />
    <BenefitsPlanEmployees v-if="benefitsPlan.benefits.length" />
  </div>
</template>

<script>
import BenefitsEditor from '@components/benefits/BenefitsEditor'
import BenefitsPlanEmployees from '@components/benefits/BenefitsPlanEmployees'
import BenefitValorisationSummary from '@components/benefits/BenefitValorisationSummary'
import InlineEditor from '@components/commons/InlineEditor'
import LoadingButton from '@components/commons/LoadingButton'
import MarkdownTextEditor from '@components/commons/MarkdownTextEditor'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { benefitsCompanyCostSummary } from '@/utils/benefit'

export default {
  components: {
    BenefitsEditor,
    BenefitsPlanEmployees,
    BenefitValorisationSummary,
    InlineEditor,
    LoadingButton,
    MarkdownTextEditor
  },
  data() {
    return {
      benefitsPlanModel: null,
      isDeleting: false
    }
  },
  computed: {
    ...mapGetters({
      benefitsPlan: 'benefits/getCurrentBenefitsPlan',
      benefits: 'benefits/getBenefitsFromCurrentPlan'
    }),
    valorisation() {
      return benefitsCompanyCostSummary(this.benefits)
    },
    totalValorisation() {
      const employeeCount = this.benefitsPlan.employeeIds.length
      return employeeCount && benefitsCompanyCostSummary(this.benefits, employeeCount)
    }
  },
  methods: {
    async updateBenefitsPlan() {
      this.$v.$touch()
      if (!this.$v.$error) {
        try {
          await this.$store.dispatch('benefits/updateBenefitsPlan', this.benefitsPlanModel)
        }
        catch (errorMessage) {
          errorMessage && alert(errorMessage)
        }
      }
    },
    async deleteBenefitsPlan() {
      if (confirm(this.$t('benefits.group.deleteConfirm'))) {
        this.isDeleting = true
        try {
          await this.$store.dispatch('benefits/deleteBenefitsPlan', this.benefitsPlan.id)
          this.$router.push({ name: 'benefitsPlans' })
        }
        catch {
          this.isDeleting = false
        }
      }
    }
  },
  mounted() {
    this.benefitsPlanModel = { ...this.benefitsPlan }
  },
  validations: {
    benefitsPlanModel: { name: { required } }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";

h1 {
  margin: 0;
}

.toolbar {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.valorisation {
  margin-top: 2em;
  display: flex;
}
</style>
