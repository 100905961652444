<template>
  <div>
    <onboarding-job-skills-editor v-if="hasComponentWithSkills"  @change="onChange" />
    <p class="alert" v-else v-t="'grid.editor.gridSkillsEditor.noComponentWithSkills'" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OnboardingJobSkillsEditor from '@components/grid/onboarding/OnboardingJobSkillsEditor'

export default {
  components: {
    OnboardingJobSkillsEditor
  },
  data() {
    return {
      isUpdated: null
    }
  },
  computed: {
    ...mapGetters({
      gridComponents: 'sandbox/allComponents'
    }),
    hasComponentWithSkills() {
      return !!this.gridComponents.find(c => c.hasSkills)
    }
  },
  methods: {
    onChange() {
      this.isUpdated = true
    }
  },
  beforeDestroy() {
    // If the skills were updated, ensure wage skills consistency.
    if (this.isUpdated) {
      this.$store.dispatch('sandbox/ensureSandboxWageSkillsConsistency')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/alert.scss";
</style>
