<template>
  <LoadingView />
</template>

<script>
import { setSessionItem } from '@/utils/storage'
import LoadingView from '@components/commons/LoadingView'

const CW_DEMO_TOKEN = 'CW_DEMO_TOKEN'

export default {
  components: {
    LoadingView
  },
  async beforeMount() {
    const code = this.$route.params.code
    const codeParts = code.split('/')
    const signinToken = codeParts[0]
    const path = code.replace(signinToken, '') || '/'
    setSessionItem(CW_DEMO_TOKEN, signinToken)

    try {
      await this.$store.dispatch('account/signIn', { signinToken })
      this.$router.push(path).catch(error => error)
    }
    catch {
      this.$router.push('/signin')
    }
  }
}
</script>
