<template>
  <div class="container">
    <h2 v-t="'candidates.acceptForm.title'"></h2>
    <p>
      <span v-t="'candidates.acceptForm.congratulations'"></span>&nbsp;🎉<br>
      <span v-t="{path: 'candidates.acceptForm.moreInfos', args: {candidateName: candidate.firstName}}"></span>
    </p>

    <div class="form">
      <div class="field">
        <div class="input-label" v-t="'candidates.acceptForm.arrivalDate'"></div>
        <date-input
          ref="arrivalDateInput"
          v-model="acceptParams.arrivalDate"
          :placeholder="$t('common.datePlaceholder')"
          :class="{'input-error' : $v.acceptParams.arrivalDate.$error}">
        </date-input>
      </div>
      <div class="field">
        <div class="input-label" v-t="'candidates.acceptForm.birthdate'"></div>
        <date-input
          v-model="acceptParams.birthdate"
          :placeholder="$t('common.datePlaceholder')"
          :class="{'input-error' : $v.acceptParams.birthdate.$error}">
        </date-input>
      </div>
      <div class="field">
        <div class="input-label">
          <span v-t="'employees.employee.form.gender'"></span>
        </div>
        <gender-input
          v-model="acceptParams.gender"
          :class="{'input-error' : $v.acceptParams.gender.$error}">
        </gender-input>
      </div>

      <div class="field" v-if="otherCandidatesCount > 1">
        <checkbox
          v-model="acceptParams.rejectAll">
          {{ $tc('candidates.acceptForm.rejectOther', otherCandidatesCount - 1, {otherCandidateCount: otherCandidatesCount - 1}) }}
        </checkbox>
      </div>
    </div>

    <div class="error-message" v-if="errorMessage">
      {{ errorMessage }}
    </div>

    <menu>
      <button
        class="secondary"
        @click="$emit('close')"
        :disabled="isLoading"
        v-t="'candidates.cancel'">
      </button>
      <loading-button
        class="primary"
        @click="accept"
        :loading="isLoading">
        <span v-t="'candidates.accept'"></span>
      </loading-button>
    </menu>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DateInput from '@components/commons/DateInput.vue'
import GenderInput from '@components/commons/GenderInput.vue'
import Checkbox from '@components/commons/Checkbox.vue'

export default {
  data() {
    return {
      isLoading: false,
      errorMessage: null,
      acceptParams: {
        arrivalDate: '',
        birthdate: this.candidate.birthdate,
        gender: 'm',
        rejectAll: false
      }
    }
  },
  props: ['candidate'],
  computed: {
    otherCandidatesCount() {
      const jobOffer = this.$store.getters['candidates/getJobOffer'](this.candidate.jobOffer)
      if (!jobOffer || jobOffer.quantity > 1) {
        return 0
      }
      else {
        return this.$store.getters['candidates/getEnrichedCandidatesByJobOffer'](this.candidate.jobOffer).length
      }
    }
  },
  methods: {
    accept() {
      this.$v.$touch()
      this.errorMessage = null

      if (!this.$v.$error) {
        this.isLoading = true
        this.$store.dispatch('candidates/acceptCandidate', {
          candidate: this.candidate,
          acceptParams: this.acceptParams
        })
          .then(employee => {
            this.$router.push({
              name: 'employee',
              params: {
                id: employee.id,
                candidateAccepted: true
              }
            })
          })
          .catch(error => {
            this.isLoading = false
            this.errorMessage = error
          })
      }
    }
  },
  mounted() {
    this.$refs.arrivalDateInput.$el.focus()
  },
  validations() {
    return {
      acceptParams: {
        arrivalDate: { required },
        birthdate: { required },
        gender: { required }
      }
    }
  },
  components: {
    Checkbox,
    DateInput,
    GenderInput
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";

.container {
  @include modal-container;
  width: 410px;
}

h2 {
  margin: 0;
}

.field {
  margin-bottom: 20px;
}
</style>
