<template>
  <div
    class="thumbnail"
    :class="{inline, long, avatar: backgroundImage}"
    :style="{backgroundColor, backgroundImage}"
    :title="[employee.firstName, employee.lastName].join(' ')">
    {{ initials }}
  </div>
</template>

<script>
import { colorize } from '@/utils/color'
import { getField } from '@/utils/employee'

export default {
  props: ['employee', 'inline'],
  computed: {
    backgroundColor() {
      return colorize(this.employee.firstName)
    },
    backgroundImage() {
      const avatar = getField(this.employee, 'avatar', { store: this.$store })
      return avatar ? `url(${avatar})` : ''
    },
    initials() {
      return getField(this.employee, 'initials')
    },
    long() {
      return this.initials.length > 2
    }
  }
}
</script>

<style lang="scss" scoped>
.thumbnail {
  margin: 0 auto;
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 50%;
  background: $background-color none no-repeat center center;
  background-size: cover;
  text-align: center;
  font-size: 45px;
  box-sizing: content-box;
  text-transform: uppercase;
  user-select: none;

  &.avatar {
    color: transparent;
  }

  &.inline {
    display: inline-block;
    width: 36px;
    height: 36px;
    line-height: 36px;
    @include font-normal-size;
    @include font-semibold;
  }

  &.inline.blue {
    background-color: $graph-blue-color;
    width: 28px;
    height: 28px;
    line-height: 28px;
    margin-right: 4px;
  }

  &.long {
    font-size: 40px;

    &.inline {
      @include font-smaller-size;
    }
  }
}
</style>
