<template>
  <div
    v-if="value"
    class="description"
    v-html="$options.filters.markdown(value)">
  </div>
</template>

<script>
export default {
  props: {
    value: String
  }
}
</script>

<style lang="scss" scoped>
.description {
  @include line-regular-height;
  margin: 0.5em 0; // Parent component can override this.

  &.small {
    @include font-small-size;
  }

  &::v-deep {
    h1,
    h2,
    h3,
    h4 {
      & + p {
        margin-top: 0;
      }
    }

    ul {
      margin: 0.25em 0;
    }

    ul {
      padding-left: 20px;
    }

    code {
      background: $graph-lightpurple-color;
      color: $graph-purple-color;
      padding: 0.2em 0.5em;
      border-radius: $border-radius;
    }

    hr {
      display: block;
      border: 0;
      border-top: $border;
    }

    p {
      margin: 0.5em 0;
    }

    p:first-of-type {
      margin-top: 0;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }
}
</style>
