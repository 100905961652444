<template>
  <ul class="grid-editor-menu">
    <router-link
      v-for="gridEditor in gridEditors"
      :key="gridEditor.id"
      tag="li"
      class="header-link"
      active-class="selected"
      :to="{ name: gridEditor.id }">
      <div class="grid-editor-icon" :class="gridEditor.id"></div>{{$t(`grid.editor.${gridEditor.id}.menuTitle`)}}
    </router-link>
  </ul>
</template>

<script>
export default {
  computed: {
    gridEditors() {
      return [{
        id: 'gridValuationEditor',
        hidden: !this.$$isAtLeastManager || !this.$$hasPermission('gridValuationEditor')
      },
      {
        id: 'gridQualificationEditor'
      },
      {
        id: 'gridSkillsEditor'
      },
      {
        id: 'gridStructureEditor',
        hidden: !this.$$isAdmin
      }].filter(tab => !tab.hidden)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/badge.scss";
@import "./src/styles/link.scss";

.grid-editor-menu {
  margin: 0 0 0 0;
  padding: 0;
}

// Similar to DashboardView's .report-list li
.grid-editor-menu li {
  @extend .badge-block;
  white-space: pre-line !important;
  @include font-small-size;
  padding: 0.4em 0.4em 0.4em 0.6em;
  margin-right: 0.6em;
  line-height: 1.2em;
  white-space: normal;
  background: white;
  display: inline-grid;
  grid-template-columns: auto 100px;
  column-gap: 0.6em;
  align-items: center;
  cursor: default;

  &:last-child {
    margin-right: 0;
  }

  &:hover:not(.router-link-exact-active):not(.disabled),
  &.selected {
    background: darken($graph-lightblue-color, 2);

    .grid-editor-icon {
      background-color: white;
      opacity: 1;
    }
  }

  .grid-editor-icon {
    display: inline-block;
    width: 2.5em;
    height: 2.4em;
    background: $graph-blue-color;
    border-radius: $border-radius;
    background: white none no-repeat center center;
    opacity: 0.8;

    &.gridValuationEditor {
      background-image: url(~@/assets/grid-valuation-editor.svg);
    }

    &.gridQualificationEditor {
      background-image: url(~@/assets/grid-qualification-editor.svg);
    }

    &.gridSkillsEditor {
      background-image: url(~@/assets/grid-skills-editor.svg);
    }

    &.gridStructureEditor {
      background-image: url(~@/assets/grid-structure-editor.svg);
    }
  }
}
</style>
