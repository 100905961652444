<template>
  <div class="block" :class="{active: componentSlug == activeComponentSlug}">
    <div class="anchor" :id="componentSlug"></div>
    <div>
      <div>
        <h2 @click="selectComponent(component)">{{ component.name }}</h2>
        <template v-for="component in linkedComponents">
          <span :key="[component.id, 'op'].join()" class="operation">{{ remunerationOperationSymbol(component.salaryOperation) }}</span>
          <h2
            :key="component.id"
            @click="selectComponent(component)">
            {{ component.name }}
          </h2>
        </template>
        <MarkdownText v-model="component.description" />
      </div>
      <div v-if="!linkedComponents.length">
        <GridComponentTable
          :wageDetails="wageDetails"
          :grid="grid"
          :component="component"
          :showDescriptions="showDescriptions"
          :showEmployees="showEmployees"
          :showSkills="showSkills"
          :showSkillsProgress="showSkillsProgress"
          :showValues="showValues" />
      </div>
      <div v-else class="linked-components">
        <div class="linked-components-select" v-if="!wageDetails">
          <GridLevelPicker
            :component="component"
            v-model="selectedLevel"
            @input="selectLevel" />
          <GridLevelPicker
            v-if="intervalBoundsScopeComponent"
            :component="intervalBoundsScopeComponent"
            v-model="selectedIntervalBoundsScopeLevel"
            @input="selectIntervalBoundsScopeLevel" />
        </div>
        <div class="linked-components-detail">
          <transition name="slide-fade" mode="out-in">
            <GridComponentTable
              :key="[selectedLevel && selectedLevel.id, selectedIntervalBoundsScopeLevel && selectedIntervalBoundsScopeLevel.id].join()"
              :wageDetails="wageDetails"
              :simulatedWageDetails="simulatedWageDetails"
              :grid="grid"
              :component="component.linkedComponents[0]"
              :linkedComponent="component"
              :linkedLevel="selectedLevel"
              :showDescriptions="showDescriptions"
              :showEmployees="showEmployees"
              :showSkills="showSkills"
              :showSkillsProgress="showSkillsProgress"
              :showValues="showValues" />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MarkdownText from '@components/commons/MarkdownText'
import GridComponentTable from '@components/grid/viewer/GridComponentTable.vue'
import GridLevelPicker from '@components/grid/viewer/GridLevelPicker.vue'
import { flattenedComponents, getVisibleLevels, getWageDetails, isIntervalBoundsScope, remunerationOperationSymbol } from '@/utils/grid'
import { slugify } from '@/utils/string'

export default {
  components: {
    MarkdownText,
    GridComponentTable,
    GridLevelPicker
  },
  props: {
    wageDetails: Object,
    grid: Object,
    component: Object,
    activeComponentSlug: String,
    selectedLevelId: String,
    showDescriptions: Boolean,
    showEmployees: Boolean,
    showSkills: Boolean,
    showSkillsProgress: Boolean,
    showValues: Boolean
  },
  data() {
    return {
      selectedLevel: null,
      selectedIntervalBoundsScopeLevel: null
    }
  },
  methods: {
    getVisibleLevels,
    initSelectedLevel() {
      // Use first level as selected level
      const visibleLevels = getVisibleLevels(this.component.levels)
      this.selectedLevel = visibleLevels.length ? visibleLevels[0] : null
      this.selectedIntervalBoundsScopeLevel = this.intervalBoundsScopeComponent ? getVisibleLevels(this.intervalBoundsScopeComponent.levels)[0] : null

      // Use wage details from props or oneself
      const simulationWage = this.$store.getters['currentGrid/getSimulationWage']
      const wageDetails = this.wageDetails || getWageDetails(this.grid, simulationWage, { includeComponents: true })

      if (wageDetails) {
        const wageComponent = wageDetails.selectedComponents.find(c => c.id === this.component.id)
        const wageSelectedLevel = wageComponent && wageComponent.selectedLevel && visibleLevels.find(l => l.id === wageComponent.selectedLevel.id)
        if (wageSelectedLevel) {
          this.selectedLevel = wageSelectedLevel
        }
        const wageIntervalBoundsScopeComponent = wageDetails.selectedComponents.find(c => this.intervalBoundsScopeComponent && c.id === this.intervalBoundsScopeComponent.id)
        if (wageIntervalBoundsScopeComponent && wageIntervalBoundsScopeComponent.selectedLevel) {
          this.selectedIntervalBoundsScopeLevel = wageIntervalBoundsScopeComponent.selectedLevel
        }
      }
      // Use selected level from props
      if (this.selectedLevelId && visibleLevels.find(l => l.id === this.selectedLevelId)) {
        this.selectedLevel = visibleLevels.find(l => l.id === this.selectedLevelId)
      }
    },
    remunerationOperationSymbol,
    selectComponent(component) {
      this.$emit('select', component)
    },
    selectLevel(level) {
      this.selectedLevel = level
      this.$store.dispatch('currentGrid/setSimulationWageLevel', level)
    },
    selectIntervalBoundsScopeLevel(intervalBoundsScopeLevel) {
      this.selectedIntervalBoundsScopeLevel = intervalBoundsScopeLevel
      this.$store.dispatch('currentGrid/setSimulationWageLevel', intervalBoundsScopeLevel)
    }
  },
  computed: {
    componentSlug() {
      return slugify(this.component.name)
    },
    linkedComponents() {
      return flattenedComponents(this.component.linkedComponents)
    },
    intervalBoundsScopeComponent() {
      return this.grid && this.grid.components.find(c => isIntervalBoundsScope(c))
    },
    simulatedWageDetails() {
      if (this.intervalBoundsScopeComponent) {
        const selectedLevel = this.selectedIntervalBoundsScopeLevel
        const simulatedWage = { hasLevels: true, levelIds: [selectedLevel.id] }
        return getWageDetails(this.grid, simulatedWage, { includeComponents: true })
      }
    }
  },
  created() {
    this.initSelectedLevel()
  }
}
</script>

<style lang="scss" scoped>
@keyframes flash {
  from {
    background: white;
  }
  33% {
    background: $graph-lightblue-color;
  }
  to {
    background: white;
  }
}

.block {
  margin: 0 -20px;
  padding: 1.4em 20px;
  border-bottom: 1px solid $graph-outer-border-color;

  &.active {
    h2 {
      border-bottom-color: rgba($graph-darkblue-color, 0.8);
    }
  }
}

h2 {
  @include font-bigger-size;
  @include font-semibold;
  cursor: pointer;
  display: inline-block;
  color: $graph-darkblue-color;
  margin-bottom: 0;
  border-bottom: 1px solid transparent;
  transition: 300ms border-bottom-color;
}

.grid-component-table {
  margin-top: 0.5em;
}

.anchor {
  position: relative;
  top: -4em;
  visibility: hidden;
}

.operation {
  display: inline-block;
  @include font-big-size;
  @include font-semibold;
  @include font-tabular-numbers;
  line-height: 30px;
  padding: 0 5px;
  color: $text-color;
}

.linked-components {
  .linked-components-select {
    padding: 0.5rem 0;
  }

  .linked-components-detail {
    .slide-fade-enter-active {
      transition: all 0.2s linear;
    }
    .slide-fade-leave-active {
      transition: all 0.2s linear;
    }
    .slide-fade-enter,
    .slide-fade-leave-to {
      transform: scale(0.96);
      opacity: 0.75;
    }
  }
}
</style>
