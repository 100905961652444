<template>
  <div>
    <div v-if="isWagePlanForm" class="wage-plan-form-container">
      <menu class="float-right no-margin-top">
        <loading-button
          v-if="isCreator"
          class="destructive"
          :disabled="isDeleting"
          :loading="isDeleting"
          @click="removeWagePlan">
          <span v-t="'common.remove'"></span>
        </loading-button>
        <router-link
          to="/dashboard"
          tag="button"
          class="secondary"
          v-t="'common.back'">
        </router-link>
        <button
          class="secondary"
          v-t="'common.export'"
          @click="exportWagePlan" />
        <loading-button
          v-if="isCreator"
          class="primary"
          :disabled="isPublishing"
          :loading="isPublishing"
          @click="publishWagePlan">
          <span v-t="'wagePlans.publish.action'"></span>
        </loading-button>
      </menu>
      <div v-if="isCreator">
        <h1 v-t="'wagePlans.title'"></h1>
        <div class="form">
          <div class="form-section">
            <div class="input-label" v-t="'wagePlans.name'"></div>
            <input
              type="text"
              v-model="wagePlanModel.name"
              @change="onChange">
            <checkbox :checked="isBudgetEnabled" @change="toggleBudget">
              <span v-t="'wagePlans.defineBudget'"></span>
            </checkbox>
            <resizable-panel :observer="isBudgetEnabled">
              <div v-if="isBudgetEnabled">
                <div class="budget-input-container">
                  <div class="input-label with-margin" v-t="'wagePlans.budget'"></div>
                  <operation-input
                    class="green budget-input"
                    operation="addition"
                    :min="0"
                    :step="1000"
                    v-model="wagePlanModel.budgetValue"
                    @change="onChange" />
                </div>
              </div>
            </resizable-panel>
          </div>
          <div class="form-section">
            <div class="input-label" v-t="'employees.employee.wage.startDate'" />
            <DateInput
              v-model="wagePlanModel.startDate"
              @input="onChange" />
          </div>
        </div>
      </div>
      <h1 v-else class="no-margin-bottom">{{wagePlanModel.name}}</h1>
      <div v-if="wagePlanDetail" class="payroll-container" id="payroll">
        <h3 ref="payroll" v-t="'common.payroll'"></h3>
        <div class="payroll">
          <div>
            <div class="input-label" v-t="'grid.editor.currentFemale'"></div>
            <div
              class="badge border-badge">
              {{ wagePlanDetail.payroll | formattedCurrency }}
            </div>
          </div>
          <div class="arrow">
            →
          </div>
          <div>
            <div class="input-label" v-t="'grid.editor.newFemale'"></div>
            <div class="badge border-badge" v-if="payroll.postPayroll !== wagePlanDetail.payroll">
              {{ payroll.postPayroll | formattedCurrency }}
            </div>
            <div
              v-else
              class="badge border-badge delta-badge"
              key="noChange"
              v-t="'common.unchanged'">
            </div>
            <div
              v-if="payroll && payroll.postPayrollRise !== 0"
              class="badge border-badge delta-badge"
              :class="{higher: payroll.postPayrollRise > 0, lower: payroll.postPayrollRise < 0}">
              {{ payroll.postPayrollRise | formattedCurrency(false, {signDisplay: "always"}) }}
              <small>({{ payroll.postPayrollRisePercent | formattedNumber(1, 1) }}&nbsp;%)</small>
            </div>
          </div>
          <div class="budget" v-if="isBudgetEnabled && wagePlanModel.budgetValue">
            <div class="input-label" v-t="'wagePlans.remainingBudget'"></div>
            <div
              class="badge border-badge delta-badge"
              :class="{higher: remainingBudgetValue > 0, lower: remainingBudgetValue < 0}">
              {{ remainingBudgetValue | formattedCurrency }}
              <small v-if="remainingBudgetValue > 0 && remainingBudgetValue !== wagePlanModel.budgetValue">({{$t('wagePlans.onBudget')}} {{wagePlanModel.budgetValue | formattedCurrency }})</small>
              <small v-else-if="remainingBudgetValue < 0" v-t="'wagePlans.offBudget'"></small>
            </div>
          </div>
        </div>
      </div>
      <EmployeesTableContainer
        class="employees-included"
        :employees="partitionedEmployees.included"
        :fieldOnly="true"
        :highlightedEmployeeId="highlightedEmployeeId"
        :infoSlotTitle="$t($$hasGrid ? 'employees.employee.form.newPlacementOnGrid': 'wagePlans.nextSalary')"
        name="wage-plan-included-employees"
        :preservedProps="['currentWage']"
        :progress="includedProgress"
        :title="$t('wagePlans.includedEmployeesTitle')"
        :placeholder="$t('wagePlans.includedEmployeesPlaceholder')">
        <template v-slot:company>
          <menu>
            <GraphFilters
              :showEmployeesCount="false"
              :showIcon="false" />
            <template v-if="!getCurrentFilters.length">
              <button
                class="secondary"
                @click="isSimulationPickerVisible = true"
                v-t="'wagePlans.simulation.title'"></button>
              <button
                class="secondary"
                @click="isEmployeesPickerVisible = true"
                v-t="'wagePlans.includedEmployeesTitle'"></button>
            </template>
          </menu>
        </template>
        <template v-slot:info="slotProps">
          <EmployeeInlineWage :employee="getWagePlanEmployee(slotProps.employee)" :showSalary="true" />
        </template>
        <template v-slot:action="slotProps">
          <router-link
            :to="{name: 'wagePlanEmployee', params: {id: wagePlanId, employeeId: slotProps.employee.id}}"
            tag="button"
            class="secondary"
            v-t="'common.update'">
          </router-link>
          <loading-button
            class="remove-employee-wage destructive"
            :disabled="isDeletingEmployeeWages.includes(slotProps.employee.id)"
            :loading="isDeletingEmployeeWages.includes(slotProps.employee.id)"
            @click="removeEmployeeWage(slotProps.employee)">
            <span>×</span>
          </loading-button>
        </template>
        <template v-slot:group="slotProps">
          <EmployeesWageDiff :employees="getWagePlanEmployees(slotProps.employees)" />
        </template>
      </EmployeesTableContainer>
      <EmployeesTableContainer
        :employees="partitionedEmployees.excluded"
        :fieldOnly="true"
        :highlightedEmployeeId="highlightedEmployeeId"
        :infoSlotTitle="$t($$hasGrid ? 'employees.employee.form.placementOnGrid' : 'wagePlans.currentSalary')"
        name="wage-plan-excluded-employees"
        :preservedProps="['currentWage']"
        :placeholder="$t('wagePlans.excludedEmployeesPlaceholder')"
        :title="$t('wagePlans.excludedEmployeesTitle')">
        <template v-slot:info="slotProps">
          <EmployeeInlineWage :employee="getWagePlanEmployee(slotProps.employee)" :showSalary="true" />
        </template>
        <template v-slot:action="slotProps">
          <router-link
            :to="{name: 'wagePlanEmployee', params: {id: wagePlanId, employeeId: slotProps.employee.id}}"
            tag="button"
            class="secondary"
            v-t="'common.add'">
          </router-link>
        </template>
      </EmployeesTableContainer>
      <modal class="overflow-visible" :visible="isSimulationPickerVisible" @close="isSimulationPickerVisible = false">
        <WagePlanSimulation @simulate="applySimulation" />
      </modal>
      <modal :visible="isEmployeesPickerVisible" @close="isEmployeesPickerVisible = false">
        <div class="employees-picker-modal">
          <h1 v-t="'wagePlans.employees.title'"></h1>
          <div v-t="'wagePlans.employees.intro'"></div>
          <EmployeesPicker
            v-model="partitionedEmployees.included"
            @select="selectEmployees" />
        </div>
      </modal>
    </div>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import anime from 'animejs/lib/anime.es.js'
import Checkbox from '@components/commons/Checkbox.vue'
import DateInput from '@components/commons/DateInput.vue'
import EmployeeInlineWage from '@components/employees/EmployeeInlineWage.vue'
import EmployeesPicker from '@components/commons/EmployeesPicker.vue'
import EmployeesTableContainer from '@components/employees/EmployeesTableContainer.vue'
import EmployeesWageDiff from '@components/employees/EmployeesWageDiff.vue'
import GraphFilters from '@components/graph/GraphFilters.vue'
import Modal from '@components/commons/Modal.vue'
import OperationInput from '@components/commons/OperationInput.vue'
import ResizablePanel from '@components/commons/ResizablePanel.vue'
import WagePlanSimulation from '@components/wagePlans/WagePlanSimulation.vue'

import { mapGetters } from 'vuex'
import partition from 'lodash.partition'
import { getWageDetails } from '@/utils/grid'
import { slugify } from '@/utils/string'
import { normalizeDate } from '@/utils/date'

export default {
  components: {
    Checkbox,
    DateInput,
    EmployeeInlineWage,
    EmployeesPicker,
    EmployeesTableContainer,
    EmployeesWageDiff,
    GraphFilters,
    Modal,
    OperationInput,
    ResizablePanel,
    WagePlanSimulation
  },
  data() {
    return {
      highlightedEmployeeId: null,
      isDeleting: false,
      isDeletingEmployeeWages: [],
      isEmployeesPickerVisible: false,
      isPublishing: false,
      isSimulationPickerVisible: false,
      payroll: null,
      wagePlanModel: {}
    }
  },
  computed: {
    ...mapGetters({
      employees: 'employees/getUpdateEmployees',
      getWagePlan: 'wagePlans/getWagePlan',
      getWagePlanDetail: 'wagePlans/getWagePlanDetail',
      getWagePlanExport: 'wagePlans/getWagePlanExport',
      currentGrid: 'currentGrid/getCurrentGrid',
      getEmployeesByIds: 'employees/getEmployeesByIds',
      getCurrentFilters: 'statistics/getCurrentFilters',
      getFilteredEmployees: 'statistics/getFilteredEmployees'
    }),
    wagePlanId() {
      return this.$route.params.id
    },
    storedWagePlan() {
      return this.getWagePlan(this.wagePlanId)
    },
    indexedWages() {
      if (this.storedWagePlan && this.storedWagePlan.wages) {
        return this.storedWagePlan.wages.reduce((memo, wage) => {
          memo[wage.employeeId] = wage
          return memo
        }, {})
      }
      else {
        return {}
      }
    },
    wagePlanDetail() {
      return this.getWagePlanDetail(this.wagePlanId)
    },
    updatedPayroll() {
      if (this.wagePlanDetail) {
        const { postPayroll, postPayrollRise, postPayrollRisePercent } = this.wagePlanDetail
        return { postPayroll, postPayrollRise, postPayrollRisePercent }
      }
    },
    isWagePlanForm() {
      return this.$route.name === 'wagePlan'
    },
    isBudgetEnabled() {
      return this.wagePlanModel.budgetValue !== null
    },
    remainingBudgetValue() {
      return this.wagePlanModel.budgetValue - Math.max(this.wagePlanDetail.postPayrollRise, 0)
    },
    filteredEmployees() {
      if (this.getCurrentFilters.length) {
        const employeeIds = this.getFilteredEmployees.map(({ id }) => id)
        return this.getEmployeesByIds(employeeIds)
      }
      else {
        return this.employees
      }
    },
    partitionedEmployees() {
      console.time('partitionedEmployees')
      const [included, excluded] = partition(this.filteredEmployees, ({ id }) => (this.indexedWages[id]))
      console.timeEnd('partitionedEmployees')
      return { included, excluded }
    },
    includedProgress() {
      const count = this.partitionedEmployees.included.length
      const total = this.employees.length
      return {
        count: count,
        total: total,
        percent: Math.round(count / total * 100)
      }
    },
    isCreator() {
      return this.$$isCreator(this.storedWagePlan)
    }
  },
  methods: {
    getWagePlanEmployee(employee, salaryOnly) {
      const wage = this.indexedWages[employee.id]
      const wageDetails = getWageDetails(this.currentGrid, employee.currentWage, { includeVariable: true, salaryOnly })
      const simulationWageDetails = wage ? getWageDetails(this.currentGrid, wage, { includeVariable: true, salaryOnly }) : null

      return {
        ...employee,
        wageDetails,
        simulationWageDetails
      }
    },
    getWagePlanEmployees(employees) {
      return employees.map(employee => this.getWagePlanEmployee(employee, true))
    },
    async applySimulation(simulationModel) {
      const wagePlan = this.wagePlanModel
      const addedEmployeeIds = (this.partitionedEmployees.included.length ? this.partitionedEmployees.included : this.filteredEmployees).map(e => e.id)

      this.isSimulationPickerVisible = false
      await this.$store.dispatch('wagePlans/assignWages', { wagePlan, addedEmployeeIds, simulationModel })
    },
    async selectEmployees(selections) {
      const wagePlan = this.wagePlanModel
      const [addedEmployees, removedEmployees] = partition(selections, s => s.isSelected)
      const [addedEmployeeIds, removedEmployeeIds] = [addedEmployees.map(e => e.id), removedEmployees.map(e => e.id)]

      try {
        this.isDeletingEmployeeWages = removedEmployeeIds
        await this.$store.dispatch('wagePlans/assignWages', { wagePlan, addedEmployeeIds, removedEmployeeIds })
        this.isDeletingEmployeeWages = []
      }
      catch (error) {
        error && alert(error)
      }
    },
    onChange() {
      this.$store.dispatch('wagePlans/debouncedUpdateWagePlan', this.wagePlanModel)
        .catch(error => error && alert(error))
    },
    toggleBudget() {
      if (this.isBudgetEnabled) {
        this.wagePlanModel.budgetValue = null
      }
      else {
        const tenPercentPayroll = Math.floor(this.wagePlanDetail.payroll * 0.1 / 500) * 500
        this.wagePlanModel.budgetValue = tenPercentPayroll
        this.$nextTick(() => {
          const budget = this.$el.querySelector('.budget')
          if (budget) {
            budget.classList.add('fade-in')
          }
        })
      }
      this.onChange()
    },
    exportWagePlan() {
      const rows = this.getWagePlanExport(this.wagePlanId)
      const filename = this.$t('wagePlans.export.xlsxFilename', { name: slugify(this.wagePlanModel.name), date: normalizeDate(new Date()) })
      this.$router.push({ name: 'export', params: { rows, filename } })
    },
    publishWagePlan() {
      if (this.storedWagePlan.wages.length) {
        if (window.confirm(this.$t('wagePlans.publish.confirm'))) {
          this.isPublishing = true
          this.$store.dispatch('wagePlans/publishWagePlan', this.wagePlanModel).then(() => {
            this.isPublishing = false
            this.$router.push({ name: 'employees', params: { isWagePlanPublishSuccess: true } })
          }).catch(error => {
            this.isPublishing = false
            error && alert(error)
          })
        }
      }
      else {
        alert(this.$t('wagePlans.publish.empty'))
      }
    },
    removeWagePlan() {
      if (!this.storedWagePlan.wages.length || window.confirm(this.$t('wagePlans.removeConfirm'))) {
        this.isDeleting = true
        this.$store.dispatch('wagePlans/removeWagePlan', this.wagePlanModel).then(() => {
          this.isDeleting = false
          this.$router.push('/dashboard')
        })
          .catch(error => {
            this.isDeleting = false
            error && alert(error)
          })
      }
    },
    removeEmployeeWage(employee) {
      const params = { wagePlanId: this.wagePlanId, employeeId: employee.id }
      this.isDeletingEmployeeWages = [employee.id]
      this.$store.dispatch('wagePlans/removeEmployeeWage', params).then(() => {
        this.isDeletingEmployeeWages = []
      })
        .catch(error => {
          this.isDeletingEmployeeWages = []
          error && alert(error)
        })
    },
    highlightUpdatedEmployee() {
      this.highlightedEmployeeId = this.$route.params.highlightEmployee
    },
    generatePayroll(options) {
      const updatedPayroll = this.updatedPayroll
      if (this.payroll && updatedPayroll) {
        updatedPayroll.postPayrollRisePercent = {
          value: updatedPayroll.postPayrollRisePercent,
          round: 10
        }
        anime({
          targets: this.payroll,
          round: 1,
          delay: (options && options.delay) || 0,
          duration: 400,
          easing: 'linear',
          ...this.updatedPayroll
        })
      }
      else {
        this.payroll = this.updatedPayroll
      }
    },
    generatePayrollWithDelay() {
      this.generatePayroll({ delay: 600 })
    }
  },
  watch: {
    $route: 'highlightUpdatedEmployee',
    updatedPayroll: 'generatePayrollWithDelay'
  },
  beforeDestroy() {
    this.$store.dispatch('wagePlans/removeWagePlanIfUnchanged', this.wagePlanModel)
  },
  created() {
    if (this.storedWagePlan) {
      this.wagePlanModel = {
        id: this.storedWagePlan.id,
        name: this.storedWagePlan.name,
        budgetValue: this.storedWagePlan.budgetValue,
        startDate: this.storedWagePlan.startDate
      }
      this.$store.commit('wagePlans/setCurrentWagePlanName', this.wagePlanModel.name)
      this.showAllEmployees = !this.storedWagePlan.wages.length
      this.generatePayroll()
    }
    else {
      this.$router.push('/dashboard')
    }
  },
  mounted() {
    this.highlightUpdatedEmployee()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/badge.scss";
@import "./src/styles/button.scss";
@import "./src/styles/block.scss";
@import "./src/styles/form.scss";

.wage-plan-form-container {
  @include container;
}

input[type="text"] {
  display: inline-block;
  width: 325px;
  margin-right: 1em;
}

input[type="date"] {
  width: 160px;
}

.checkbox {
  display: inline-block;
  vertical-align: middle;
}

.input-label {
  padding-left: 0;
}

.input-label:not(:first-of-type) {
  margin-top: 2em;
}

.operation-input {
  margin-right: 0.75em;
}

.budget-input-container {
  margin-top: 1em;

  .budget-input::v-deep input[type="number"] {
    width: 150px;
  }
}

.remove-employee-wage {
  min-width: 0;
  margin-left: 5px;
  padding: 5px 0 7px;
  width: 33px;
  height: 33px;
  vertical-align: -1px;

  span {
    @include font-big-size;
    @include font-bold;
  }
}

.payroll-container {
  @extend .badge-block;
  padding: 0.75em 1.1em 0.9em 1.1em;
  margin-top: 1em;

  h3 {
    margin: 0 0 0.4em;
  }

  .payroll {
    display: inline-grid;
    grid-template-columns: auto auto auto auto;

    .arrow {
      padding-top: 24px;
      margin: 0 1em;
    }
  }
}

.budget {
  border-left: 1px solid $graph-outer-border-color;
  padding-left: 1em;
  margin-left: 1em;
}

.employees-picker-modal {
  width: 650px;
  padding: 20px;
}

.employees-included::v-deep {
  .employee-header + .employee-group {
    // Align info slot title with group
    margin-top: -35px;
  }
}

.filters-container {
  @include font-small-size;
  @include font-regular;
  @include line-regular-height;
}
</style>
