<template>
  <div
    v-if="hasContent"
    class="tooltip-container"
    :class="{top: isTopSkin}"
    @mouseover="onMouseOver">
    <span class="tooltip-icon">i</span>
    <div class="tooltip preserve-lines" v-if="message">{{message}}</div>
    <div class="tooltip" v-if="this.$slots.default">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // Use `message` or use <slot/>
    message: {
      type: String
    },
    // Set force to true to always display the icon even without content.
    // This can be used to lazy-load the slot on mouse over.
    force: {
      type: Boolean
    }
  },
  data() {
    return {
      isTopSkin: false
    }
  },
  computed: {
    hasContent() {
      return this.$slots.default || this.message || this.force
    }
  },
  methods: {
    onMouseOver() {
      this.$emit('mouseover')
      this.computeIsToSkin()
    },
    computeIsToSkin() {
      const midScreen = window.innerHeight / 2
      const { y } = this.$el.getBoundingClientRect()
      this.isTopSkin = y > midScreen
    }
  }
}
</script>

<style lang="scss" scoped>
.tooltip-container {
  position: relative;
  display: inline-block;

  &:hover {
    .tooltip {
      display: block;
    }
    .tooltip-icon {
      background: white;
    }
  }
}

// Default skin: bottom right
.tooltip {
  @include font-regular;
  @include line-regular-height;
  pointer-events: none;
  display: none;
  position: absolute;
  border: 1px solid $graph-outer-border-color;
  border-radius: $border-radius;
  color: $text-color;
  z-index: 1000;
  background: white;
  padding: 0.6em;
  margin-left: -18px;
  margin-top: 15px;
  box-shadow: 1px 2px 0 rgba(0, 0, 0, 0.03), 3px 4px 0 rgba(0, 0, 0, 0.015),
    4px 6px 10px rgba(0, 0, 0, 0.1);
  width: 440px;

  &:after,
  &:before {
    top: -21px;
    left: 20px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 10px;
  }

  &:after {
    border-bottom-color: white;
    margin-top: 1px;
  }
  &:before {
    border-bottom-color: $graph-outer-border-color;
  }
}

.tooltip-icon {
  display: inline-block;
  @include font-bold;
  font-family: serif;
  cursor: help;
  width: 18px;
  height: 18px;
  line-height: 18px;
  border-radius: 18px;
  text-align: center;
  margin-left: 0.25em;
  border: 1px solid lighten($graph-darkblue-color, 18);
  color: $graph-darkblue-color;
}

// Blue Background skin
.tooltip-container.blue-bg .tooltip {
  background: $graph-lightblue-color;

  &::after {
    border-bottom-color: $graph-lightblue-color;
  }
}

// Gray Icon skin
.tooltip-container.gray-icon .tooltip-icon {
  color: $light-text-color;
  border-color: darken($border-color, 15);
}

// Green Icon skin
.tooltip-container.green-icon .tooltip-icon {
  color: darken($clearteal-color, 5);
  border-color: $clearteal-color;
}

// Orange Icon skin
.tooltip-container.orange-icon .tooltip-icon {
  color: darken($orange-color, 5);
  border-color: $orange-color;
}

// Left skin
.tooltip-container.left .tooltip {
  background: $graph-lightblue-color;
  margin-left: -300px;
  margin-top: 14px;
  width: 350px;

  &:after,
  &:before {
    left: 300px;
  }

  &::after {
    border-bottom-color: $graph-lightblue-color;
  }
}

// Top left skin
.tooltip-container.top {
  .tooltip {
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 14px;

    &::before,
    &::after {
      top: 100%;
      transform: scaleY(-1);
    }

    &::after {
      margin-top: -1px;
    }
  }
}
</style>
