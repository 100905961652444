<template>
  <form class="level-form" @submit.prevent="submit">
    <h3 class="title" @click.alt="isDebug = !isDebug">
      {{$t('grid.structure.addA')}} {{levelName}} {{isDebug ? '(ajout multiple)' : ''}}
    </h3>
    <div class="form-section" v-if="!isDebug">
      <p>{{$t('grid.structure.addLevelIntro', {levelName})}}</p>
      <GridLevelPicker
        :component="component"
        :value="selectedLevel"
        @input="selectLevel" />
    </div>
    <div class="form-section">
      <div class="input-label" v-t="'common.name'"></div>
      <input
        class="name-input"
        type="text"
        ref="name"
        v-model.trim="levelModel.name"
        :placeholder="$t('grid.structure.levelNamePlaceholder', {levelName})"
        :class="{ 'input-error': $v.levelModel.name.$error}">
    </div>
    <div class="form-section">
      <div class="input-label" v-t="'common.description'"></div>
      <MarkdownTextEditor
        v-model="levelModel.description"
        :placeholder="$t('grid.editor.levelDescription')"
        :showAddButton="true"
        :showInlineEditButton="true"
        :showToolbar="true"
        :autoDismiss="true" />
    </div>
    <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>
    <menu>
      <button
        class="secondary"
        type="button"
        @click="close"
        v-t="'common.cancel'"></button>
      <loading-button
        class="primary"
        :disabled="isLoading"
        :loading="isLoading">
        <span v-t="'common.add'"></span>
      </loading-button>
    </menu>
  </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import { getLevelName, getLinkedLevels, getVisibleLinkedLevels } from '@/utils/grid'
import GridLevelPicker from '@components/grid/viewer/GridLevelPicker.vue'
import MarkdownTextEditor from '@components/commons/MarkdownTextEditor.vue'

function uniqueName(value) {
  return !getLinkedLevels(this.component.levels, this.linkedLevel).find(l => l.name === value)
}

export default {
  components: {
    GridLevelPicker,
    MarkdownTextEditor
  },
  props: {
    component: Object,
    linkedLevel: Object
  },
  data() {
    return {
      selectedLevel: null,
      levelModel: {
        name: null,
        description: null
      },
      errorMessage: null,
      isLoading: false,
      isDebug: false
    }
  },
  computed: {
    ...mapGetters({
      grid: 'sandbox/grid'
    }),
    levelName() {
      return getLevelName(this.component.ref, 1).replace('1 ', '')
    }
  },
  methods: {
    initModel() {
      const levels = getVisibleLinkedLevels(this.component.levels, this.linkedLevel)
      if (levels.length) {
        this.selectLevel(levels[levels.length - 1])
      }
    },
    async submit() {
      this.errorMessage = null
      this.$v.$touch()

      if (this.$v.$error) {
        return
      }

      this.$v.$reset()
      this.isLoading = true

      try {
        const component = this.component
        const templateLevel = this.selectedLevel
        const level = this.levelModel
        if (this.isDebug) {
          // Debug logic to add same level to all parent levels
          const allComponents = this.$store.getters['sandbox/allComponents']
          const parentComponent = allComponents.find(c => c.id === component.parentComponentId)
          const parentParentComponent = parentComponent && allComponents.find(c => c.id === parentComponent.parentComponentId)
          let linkedLevels
          if (parentParentComponent) {
            linkedLevels = parentParentComponent.levels.reduce((memo, l) => {
              memo = [...memo, ...getLinkedLevels(parentComponent.levels, l)]
              return memo
            }, [])
          }
          else {
            linkedLevels = parentComponent.levels
          }
          const levels = linkedLevels.map(l => ({ ...level, linkedLevelId: l.id }))
          if (window.confirm(`${levels.length} paliers seront ajoutés. Continuer ?`)) {
            await this.$store.dispatch('sandbox/createLevels', { component, levels })
            alert(`${levels.length} paliers ajoutés.`)
            this.close()
          }
        }
        else {
          await this.$store.dispatch('sandbox/createTemplateLevel', { component, templateLevel, level })
          this.close()
          this.$emit('add', level)
        }
      }
      catch (error) {
        this.errorMessage = error
        this.isLoading = false
      }
    },
    selectLevel(level) {
      this.selectedLevel = level
      this.$refs.name.focus()
    },
    close() {
      this.$emit('close')
    }
  },
  mounted() {
    this.initModel()
  },
  validations: {
    levelModel: {
      name: { required, uniqueName }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/alert.scss";
@import "./src/styles/animation.scss";
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";
@import "./src/styles/link.scss";

.level-form {
  padding: 20px;
  width: 500px;
}

input[type="text"].name-input {
  width: 20em;
}
</style>
