<template>
  <div class="variable-plan-period">
    <div>
      <div class="input-label" :class="{big}" v-t="'variable.recurrences.title'"></div>
      <Dropdown
        class="large"
        :disabled="isPeriodDisabled"
        :items="recurrencesDropdownItems"
        @change="setRecurrence">
        <span v-t="`variable.recurrences.${variablePlanModel.recurrence}.title`"></span>
        <span class="down">▾</span>
      </Dropdown>
    </div>
    <div>
      <div
        class="input-label"
        :class="{big}"
        v-t="'variable.periodForm.startDate' + (isPunctual ? 'Punctual' : '')">
      </div>
      <DateInput
        v-model="variablePlanModel.startDate"
        :disabled="isPeriodDisabled"
        :placeholder="$t('common.datePlaceholder')"
        @input="onStartDateChange" />
    </div>
    <div v-if="!isPunctual">
      <div
        class="input-label"
        :class="{big}"
        v-t="'variable.periodForm.endDate'"></div>
      <DateInput
        v-model="variablePlanModel.endDate"
        :disabled="isPeriodDisabled"
        :placeholder="$t('common.datePlaceholder')"
        @input="submit" />
    </div>
    <div v-if="showStatus">
      <div class="input-label" :class="{big}" v-t="'variable.statuses.title'"></div>
      <div class="variable-plan-status-container">
        <Dropdown
          class="variable-plan-statuses-dropdown large description"
          :items="statusesDropdownItems"
          @change="setStatus">
          <span class="variable-plan-status" :class="variablePlanModel.status">
            <span v-t="`variable.statuses.${variablePlanModel.status}.title`"></span>
            <span class="down">▾</span>
          </span>
        </Dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { computePeriod } from '@/utils/variable'
import DateInput from '@components/commons/DateInput.vue'
import Dropdown from '@components/commons/Dropdown'

export default {
  components: {
    DateInput,
    Dropdown
  },
  props: {
    big: {
      type: Boolean
    },
    showStatus: {
      type: Boolean
    },
    value: {
      type: Object
    }
  },
  data() {
    return {
      variablePlanModel: null
    }
  },
  computed: {
    ...mapGetters({
      recurrences: 'variablePlans/getRecurrences',
      scopes: 'variablePlans/getScopes',
      statuses: 'variablePlans/getStatuses'
    }),
    isPeriodDisabled() {
      return this.variablePlanModel.status !== 'draft'
    },
    isPunctual() {
      return this.variablePlanModel.recurrence === 'none'
    },
    recurrencesDropdownItems() {
      return [{
        name: this.$t('variable.recurrences.title'),
        items: this.recurrences.map(recurrence => ({
          name: this.$t(`variable.recurrences.${recurrence}.title`),
          value: recurrence,
          selected: this.variablePlanModel.recurrence === recurrence
        }))
      }]
    },
    statusesDropdownItems() {
      return [{
        name: this.$t('variable.statuses.title'),
        items: this.statuses.map(status => ({
          name: this.$t(`variable.statuses.${status}.title`),
          label: this.$t(`variable.statuses.${status}.description`),
          value: status,
          selected: this.variablePlanModel.status === status
        }))
      }]
    }
  },
  methods: {
    initModel() {
      this.variablePlanModel = { ...this.value }
    },
    setRecurrence(_, value) {
      this.variablePlanModel.recurrence = value
      const period = computePeriod(this.variablePlanModel.recurrence, this.variablePlanModel.startDate)
      this.variablePlanModel = { ...this.variablePlanModel, ...period }
      this.submit()
    },
    onStartDateChange() {
      const period = computePeriod(this.variablePlanModel.recurrence, this.variablePlanModel.startDate)
      this.variablePlanModel = { ...this.variablePlanModel, ...period }
      this.submit()
    },
    setStatus(_, value) {
      this.$emit('status', value)
    },
    submit() {
      this.$emit('input', this.variablePlanModel)
    }
  },
  watch: {
    value: 'initModel'
  },
  created() {
    this.initModel()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/variable.scss";

.variable-plan-period {
  margin: 1.25em 0 0;
  display: flex;

  & > * {
    margin-right: 25px;
  }

  input {
    width: 10.5em;
  }
}

.variable-plan-statuses-dropdown::v-deep {
  .dropdown-content {
    width: 500px;
  }

  .dropdown-item:nth-of-type(2) {
    @extend .variable-plan-status, .draft;
  }

  .dropdown-item:nth-of-type(3) {
    @extend .variable-plan-status, .ongoing;
  }

  .dropdown-item:nth-of-type(4) {
    @extend .variable-plan-status, .closed;
  }

  .dropdown-item .right-label {
    color: $text-color;
  }
}

.variable-plan-status-container {
  display: flex;
}
</style>
