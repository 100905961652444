import axios from 'axios'
import defaultsConfig from 'axios/lib/defaults'
import transformKeys from 'transform-keys'
import camelCase from 'lodash.camelcase'
import snakeCase from 'lodash.snakecase'

const CW_URN = 'urn:clw:'

// snakeCase was destroying ids in keys:
// d0c130e0-4356-452f-a9af-2132b86e9f8b
// d_0_c_130_e_0_4356_452_f_a_9_af_2132_b_86_e_9_f_8_b
function safeSnakeCase(text) {
  return text.length !== 36 && !text.startsWith(CW_URN) ? snakeCase(text) : text
}

// camelCase was destroying ids in keys:
// beb91e77-0fd2-468c-8af6-521dfb3abd3a
// beb91E770Fd2468C8Af6521Dfb3Abd3A
function safeCamelCase(text) {
  return text.length !== 36 && !text.startsWith(CW_URN) ? camelCase(text) : text
}

function transformRequest(data) {
  if (data) {
    data = transformKeys(data, safeSnakeCase)
  }
  return data
}

function transformResponse(data) {
  if (data) {
    data = transformKeys(data, safeCamelCase)
  }
  return data
}

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {},
  transformRequest: [transformRequest, defaultsConfig.transformRequest[0]],
  transformResponse: [defaultsConfig.transformResponse[0], transformResponse]
})

export default api
