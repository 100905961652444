<template>
  <div class="benefit">
    <BenefitBadge :benefit="benefit" />
    <div class="benefit-name">{{ benefit.name }}</div>
  </div>
</template>

<script>
import BenefitBadge from '@components/benefits/BenefitBadge'

export default {
  components: {
    BenefitBadge
  },
  props: {
    benefit: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/badge.scss";

.benefit {
  @extend .badge-block, .clickable;
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;
  justify-content: flex-start;
  width: 100%;
  padding: 0.4em 0.7em;
  @include font-semibold;
  @include font-large-size;
}

.benefit-name {
  margin-left: 0.6em;
}
</style>
