<template>
  <button
    @click="$emit('click', $event)"
    :disabled="loading">
    <template v-if="loading">
      <div class="spinner">•••</div>
    </template>
    <template v-else>
      <slot></slot>
    </template>
  </button>
</template>

<script>
export default {
  props: ['loading']
}
</script>

<style lang="scss" scoped>
@keyframes spinner {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.spinner {
  text-indent: 3px;
  letter-spacing: 3px;
  animation: spinner 1s ease-in infinite;
}
</style>
