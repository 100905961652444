<template>
  <transition name="modal">
    <div class="mask">
      <div class="wrapper">
        <div class="container">
          <h2>{{ title }}</h2>
          <div class="component-name">
            <div class="input-label" v-t="'sandbox.componentForm.name'"></div>
            <input
              type="text"
              v-model="componentModel.name"
              ref="nameInput"
              :placeholder="$t('sandbox.componentForm.namePlaceholder')"
              :class="{'input-error' : $v.componentModel.name.$error}"
              @keyup.enter="isLoading ? null : submit()">
          </div>
          <label>
            <input type="checkbox" v-model="componentModel.hasRareEvolution">
            <span v-t="'sandbox.componentForm.frequency'"></span>
          </label>
          <template v-if="!this.component && availableComponents.length">
            <div>
              <label>
                <input type="checkbox" v-model="isLinkedComponent">
                <span v-t="'sandbox.componentForm.linked'"></span>
              </label>
              <div v-if="isLinkedComponent" class="linked-component">
                <div class="input-label" v-t="'sandbox.componentForm.component'"></div>
                <select v-model="selectedLinkedComponent" :class="{'select-error' : $v.selectedLinkedComponent.$error}">
                  <option disabled :value="null" v-t="'sandbox.componentForm.selectComponent'"></option>
                  <option
                    v-for="availableComponent in availableComponents"
                    :key="availableComponent.id"
                    :value="availableComponent">
                    {{ availableComponent.name }}
                  </option>
                </select>
                <div class="input-label" v-t="'sandbox.componentForm.modification'"></div>
                <select v-model="selectedOperation" :class="{'select-error' : $v.selectedOperation.$error}">
                  <option disabled :value="null" v-t="'sandbox.componentForm.selectOperation'"></option>
                  <option
                    v-for="operation in operations"
                    :key="operation.value"
                    :value="operation.value">
                    {{ operation.name }}
                  </option>
                </select>
              </div>
            </div>
          </template>
          <template v-if="component && !component.parentComponentId">
            <div class="remuneration-impact">
              <h3 v-t="'sandbox.componentForm.remunerationImpact'"></h3>
              <div
                class="remuneration-impact-type"
                v-for="remunerationType in availableRemunerationTypes"
                :key="remunerationType">
                <label>
                  <input
                    type="checkbox"
                    v-model="hasRemunerationOperation[remunerationType]"
                    :disabled="hasOnlyOneRemunerationOperation && hasRemunerationOperation[remunerationType]">
                  <span v-t="remunerationType"></span>
                </label>
              </div>
            </div>
          </template>
          <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>
          <menu>
            <loading-button
              v-if="component"
              class="destructive"
              :disabled="isLoading || isDeleting"
              :loading="isDeleting"
              @click="removeComponent">
              <span v-t="'common.remove'"></span>
            </loading-button>
            <button class="secondary" @click="$emit('close', null)" :disabled="isLoading" v-t="'sandbox.componentForm.cancel'"></button>
            <loading-button
              class="primary"
              :disabled="isLoading || isDeleting"
              @click="submit()"
              :loading="isLoading">
              <span>{{ actionName }}</span>
            </loading-button>
          </menu>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { REMUNERATION_TYPES } from '@/utils/grid'

export default {
  data() {
    return {
      componentModel: null,
      isLinkedComponent: false,
      isLoading: false,
      isDeleting: false,
      errorMessage: null,
      selectedOperation: null,
      selectedLinkedComponent: null,
      operations: [
        {
          name: this.$t('sandbox.componentForm.operationMultiplier'),
          value: 'multiplier'
        },
        {
          name: this.$t('sandbox.componentForm.operationDivider'),
          value: 'divider'
        }
      ],
      hasRemunerationOperation: {
        salary: false,
        equity: false,
        bonus: false
      }
    }
  },
  props: [
    'component',
    'gridId',
    'remunerationType'
  ],
  created() {
    if (this.component) {
      this.componentModel = Object.assign({}, this.component)

      this.availableRemunerationTypes.forEach(remunerationType => {
        this.hasRemunerationOperation[remunerationType] = this.component[remunerationType + 'Operation'] != null
      })
    }
    else {
      this.componentModel = Object.assign({}, this.$store.state.sandbox.componentModel)
    }
  },
  computed: {
    title() {
      return this.$t('sandbox.componentForm.' + (this.component == null ? 'addComponent' : 'updateComponent'))
    },
    actionName() {
      return this.$t('sandbox.componentForm.' + (this.component == null ? 'add' : 'update'))
    },
    availableComponents() {
      return this.$store.getters['sandbox/availableComponents'](this.remunerationType)
    },
    hasOnlyOneRemunerationOperation() {
      return Object.values(this.hasRemunerationOperation).filter(has => has === true).length === 1
    },
    availableRemunerationTypes() {
      return REMUNERATION_TYPES
    }
  },
  methods: {
    submit() {
      this.$v.$touch()

      if (!this.$v.$error) {
        this.isLoading = true

        this.createOrUpdate()
          .then(response => {
            this.$emit('close', response)
          })
          .catch(error => {
            this.errorMessage = error
            this.isLoading = false
          })
      }
    },
    createOrUpdate() {
      if (this.component) {
        if (!this.component.parentComponentId) {
          this.availableRemunerationTypes.forEach(remunerationType => {
            if (this.hasRemunerationOperation[remunerationType] && !this.componentModel[remunerationType + 'Operation']) {
              this.componentModel[remunerationType + 'Operation'] = this.$store.getters['sandbox/gridOperations'][remunerationType]
            }
            else if (!this.hasRemunerationOperation[remunerationType] && this.componentModel[remunerationType + 'Operation']) {
              this.componentModel[remunerationType + 'Operation'] = null
              this.$store.dispatch('sandbox/removeRemuneration', {
                component: this.component,
                remunerationType: remunerationType
              })
            }
          })
        }

        return this.$store.dispatch('sandbox/updateComponent', this.componentModel)
      }

      if (!this.isLinkedComponent) {
        this.componentModel[this.remunerationType + 'Operation'] = this.$store.getters['sandbox/gridOperations'][this.remunerationType]
        return this.$store.dispatch('sandbox/createComponent', {
          gridId: this.gridId,
          component: this.componentModel
        })
      }
      else {
        this.componentModel[this.remunerationType + 'Operation'] = this.selectedOperation
        return this.$store.dispatch('sandbox/createLinkedComponent', {
          componentId: this.selectedLinkedComponent.id,
          linkedComponent: this.componentModel
        })
      }
    },
    removeComponent() {
      if (confirm(this.$t('sandbox.componentForm.deleteConfirmation', { componentName: this.component.name }))) {
        this.isDeleting = true
        this.$store.dispatch('sandbox/removeComponent', this.component)
          .then(response => {
            this.isDeleting = false
            scroll(0, 0)
          })
      }
    }
  },
  mounted() {
    this.$refs.nameInput.focus()
  },
  validations() {
    const validations = { componentModel: { name: { required } } }

    if (this.isLinkedComponent) {
      validations.selectedOperation = { required }
      validations.selectedLinkedComponent = { required }
    }

    return validations
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";

.mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: table;
  transition: opacity 0.3s ease;
}

.wrapper {
  display: table-cell;
  vertical-align: middle;
}

.container {
  @include container;
  border: none;
  width: 400px;
  margin: 0 auto;
  transition: all 0.3s ease;
}

.component-name {
  margin-bottom: 20px;
}

.remuneration-impact {
  margin-bottom: 20px;
}

.remuneration-impact-type label {
  margin-bottom: 5px;
}

label {
  display: block;
  margin-bottom: 20px;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

hr {
  display: block;
  border: 0;
  border-top: $border;
  height: 1px;
  margin: 10px 0 30px 0;
}

select {
  display: block;
  margin-bottom: 20px;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
