<template>
  <div>
    <h3 v-t="'settings.settingsCompanyIntegrations.title'"></h3>
    <p v-t="'settings.settingsCompanyIntegrations.intro'"></p>
    <div class="integrations">
      <div id="lucca" class="settings-block integration">
        <div class="integration-name">
          <a href="https://www.lucca.fr" target="_blank" class="hover-link">
            <img src="~@/assets/integration-lucca.svg" alt="LUCCA">
            <p>lucca.fr</p>
          </a>
        </div>
        <div class="integration-description">
          <div v-html="$t('settings.settingsCompanyIntegrations.lucca.summary')"></div>
          <div class="integration-menu">
            <div v-if="isLuccaActive" class="status active" v-t="'settings.settingsCompanyIntegrations.activeIntegration'"></div>
            <div v-else class="status inactive" v-t="'settings.settingsCompanyIntegrations.inactiveIntegration'"></div>
            <router-link
              tag="button"
              class="primary"
              :to="{name: 'settingsCompanyIntegrationLucca'}">
              <span v-t="'settings.settingsCompanyIntegrations.configure'"></span>
            </router-link>
          </div>
        </div>
      </div>
      <div id="bambooHR" class="settings-block integration">
        <div class="integration-name">
          <a href="https://www.bamboohr.com/" target="_blank" class="hover-link">
            <img src="~@/assets/integration-bamboo-hr.svg" alt="BambooHR">
            <p class="small">bamboohr.com</p>
          </a>
        </div>
        <div class="integration-description">
          <div v-html="$t('settings.settingsCompanyIntegrations.bambooHR.summary')"></div>
          <div class="integration-menu">
            <div v-if="isBambooHRActive" class="status active" v-t="'settings.settingsCompanyIntegrations.activeIntegration'"></div>
            <div v-else class="status inactive" v-t="'settings.settingsCompanyIntegrations.inactiveIntegration'"></div>
            <router-link
              tag="button"
              class="primary"
              :to="{name: 'settingsCompanyIntegrationBambooHR'}">
              <span v-t="'settings.settingsCompanyIntegrations.configure'"></span>
            </router-link>
          </div>
        </div>
      </div>
      <div id="okta" class="settings-block integration">
        <div class="integration-name">
          <a href="https://www.okta.com" target="_blank" class="hover-link">
            <img src="~@/assets/integration-okta.svg" alt="OKTA">
            <p>okta.com</p>
          </a>
        </div>
        <div class="integration-description">
          <div v-html="$t('settings.settingsCompanyIntegrations.okta.summary')"></div>
          <div class="integration-menu">
            <div v-if="isOktaActive" class="status active" v-t="'settings.settingsCompanyIntegrations.activeIntegration'"></div>
            <div v-else class="status inactive" v-t="'settings.settingsCompanyIntegrations.inactiveIntegration'"></div>
            <router-link
              tag="button"
              class="primary"
              :to="{name: 'settingsCompanyIntegrationOKTA'}">
              <span v-t="'settings.settingsCompanyIntegrations.configure'"></span>
            </router-link>
          </div>
        </div>
      </div>
      <div id="payfit" class="settings-block integration">
        <div class="integration-name">
          <a href="https://www.payfit.com" target="_blank" class="hover-link">
            <img src="~@/assets/integration-payfit.svg" alt="Payfit">
            <p>payfit.com</p>
          </a>
        </div>
        <div class="integration-description">
          <div v-html="$t('settings.settingsCompanyIntegrations.payfit.summary')"></div>
          <div class="integration-menu">
            <div class="status inactive" v-t="'settings.settingsCompanyIntegrations.manualIntegration'"></div>
            <router-link
              tag="button"
              class="primary"
              :to="{name: 'settingsCompanyIntegrationPayfit'}">
              <span v-t="'settings.settingsCompanyIntegrations.configure'"></span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <p v-html="$t('settings.settingsCompanyIntegrations.footer')"></p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      isBambooHRActive: 'account/isBambooHRActive',
      isLuccaActive: 'account/isLuccaActive',
      isOktaActive: 'account/isOktaActive'
    })
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/settings.scss";

.integration {
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-gap: $settings-padding * 1.2;
  padding-bottom: $settings-padding;
}

.integration-name {
  img {
    border: 1px solid lighten($graph-inner-border-color, 4);
    background: white;
    border-radius: $border-radius;
    width: 102px;
    height: 102px;
  }

  p {
    margin-top: $settings-padding;
    color: $light-text-color;
    text-align: center;
  }

  .small {
    @include font-small-size;
  }
}

.integration-description::v-deep p:first-of-type {
  margin-top: 3px;
}

.integration-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .status.active {
    color: darken($clearteal-color, 5);
  }

  .status.inactive {
    color: $light-text-color;
  }
}
</style>
