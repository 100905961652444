<template>
  <div class="indicator">
    <div class="indicator-title">
      <div class="indicator-label">{{model.name}}</div>
      <div class="indicator-dropdown">
        <Dropdown
          class="blue modern description"
          :items="kindsDropdownItems"
          @change="setKind"
          :disabled="disabled">
          <span v-t="`variable.editor.indicator.kinds.${model.kind}.title`"></span>
          <span class="down">▾</span>
        </Dropdown>
        <Dropdown
          v-if="!['reference', 'constant'].includes(model.kind)"
          class="blue modern description"
          :items="sourcesDropdownItems"
          @change="setSource"
          :disabled="disabled">
          <span v-t="`variable.editor.indicator.sources.${model.source}.title`"></span>
          <span class="down">▾</span>
        </Dropdown>
      </div>
    </div>
    <div class="indicator-kind-description-container" v-if="!disabled || model.kind === 'reference'">
      <p
        class="indicator-kind-description"
        v-t="`variable.editor.indicator.kinds.${model.kind}.description`"></p>
      <div class="indicator-dropdown">
        <Dropdown
          v-if="model.kind === 'reference'"
          class="blue modern description"
          :items="referencesDropdownItems"
          @change="setReference"
          :disabled="disabled">
          <span>{{selectedReferenceTitle}}</span>
          <span class="down">▾</span>
        </Dropdown>
      </div>
    </div>
    <MappingEditor
      v-if="model.mapping"
      v-model="model.mapping"
      :key="model.kind"
      @input="onChange" />
  </div>
</template>

<script>
import cloneDeepWith from 'lodash.clonedeepwith'
import Dropdown from '@components/commons/Dropdown'
import MappingEditor from '@components/variable/MappingEditor'
import {
  INDICATOR_KINDS,
  INDICATOR_SOURCES,
  buildIndicatorMapping,
  buildGroupedAvailableReferences
} from '@/utils/variable'
import flatten from 'lodash.flatten'

export default {
  components: {
    Dropdown,
    MappingEditor
  },
  props: {
    indicators: Array,
    dataFeeds: Array,
    value: Object,
    disabled: Boolean
  },
  data() {
    return {
      model: null
    }
  },
  computed: {
    kinds() {
      return INDICATOR_KINDS
    },
    sources() {
      return INDICATOR_SOURCES
    },
    kindsDropdownItems() {
      return [{
        name: this.$t('variable.editor.indicator.kinds.title'),
        items: this.kinds.map(kind => ({
          name: this.$t(`variable.editor.indicator.kinds.${kind}.title`),
          label: this.$t(`variable.editor.indicator.kinds.${kind}.description`),
          value: kind,
          selected: this.model.kind === kind
        }))
      }]
    },
    sourcesDropdownItems() {
      return [{
        name: this.$t('variable.editor.indicator.sources.title'),
        items: this.sources.map(source => ({
          name: this.$t(`variable.editor.indicator.sources.${source}.title`),
          label: this.$t(`variable.editor.indicator.sources.${source}.description`),
          value: source,
          selected: this.model.source === source
        }))
      }]
    },
    groupedAvailableReferences() {
      const customFields = this.$store.getters['company/getCustomFields']
      return buildGroupedAvailableReferences(this.indicators, this.dataFeeds, this.model, customFields)
    },
    availableReferences() {
      return flatten(this.groupedAvailableReferences)
    },
    selectedReferenceTitle() {
      const selectedReference = this.availableReferences.find(i => i.id === this.model.reference)
      return selectedReference ? selectedReference.title : this.$t('variable.editor.indicator.references.placeholder')
    },
    referencesDropdownItems() {
      return this.groupedAvailableReferences.map((group, i) => ({
        name: this.$t('variable.editor.indicator.references.indicators' + i),
        value: i,
        items: group.map(reference => ({
          name: reference.title,
          label: reference.label,
          value: reference.id,
          selected: this.model.reference === reference.id
        }))
      })).filter(({ items }) => items.length)
    }
  },
  methods: {
    initModel() {
      this.model = cloneDeepWith(this.value)
    },
    validateReference() {
      if (this.model.kind !== 'reference') {
        delete this.model.reference
      }
      else {
        if (!this.availableReferences.map(r => r.id).includes(this.model.reference)) {
          delete this.model.reference
        }
        this.model.source = 'individual'
      }
    },
    setKind(_, value) {
      this.model.kind = value
      this.validateReference()
      this.model.mapping = buildIndicatorMapping(this.model.kind, this.model.reference)
      this.onChange()
    },
    setSource(_, value) {
      this.model.source = value
      this.onChange()
    },
    setReference(_, value) {
      this.model.reference = value
      this.model.mapping = buildIndicatorMapping(this.model.kind, this.model.reference)
      this.onChange()
    },
    onChange() {
      this.$emit('input', this.model)
    }
  },
  watch: {
    value: 'initModel'
  },
  created() {
    this.initModel()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/badge.scss";
@import "./src/styles/form.scss";

.indicator {
  @extend .badge-block;
  border: none;
  width: 100%;
  padding: 5px 7px;
  margin-bottom: 10px;
}

.indicator-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;

  .indicator-label {
    @include font-small-size;
    @include font-semibold;
    @include line-regular-height;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 3px;
  }
}

// Dropdown skin: right aligned with description
.indicator-dropdown::v-deep {
  position: relative;

  .dropdown:not(:first-child) {
    margin-left: 5px;
  }

  .dropdown-button {
    padding: 5px;
    max-width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:not(:hover):not(:focus) {
      color: $light-text-color;
    }
  }

  .dropdown-content {
    width: 304px;
    right: 0;
    margin-top: 5px;
  }
}

.indicator-kind-description-container {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 5px;
  margin: 5px 0 7px;
  align-items: center;
}

.indicator-kind-description {
  @include font-small-size;
  color: $dark-light-text-color;
  margin: 0;
}
</style>
