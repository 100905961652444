<template>
  <div class="welcome">
    <h1 v-html="$t('dashboard.welcome.title')"></h1>
    <p v-if="!$$isDemoSession" v-html="$t('dashboard.welcome.accountCreationSuccess', {company: company.name})"></p>
    <template v-if="!$$isDemoSession">
      <div v-html="$t('dashboard.welcome.body')" />
      <p>
        <menu class="hero">
          <router-link
            tag="button"
            class="primary"
            :to="{name: 'importEmployees'}"
            v-t="'dashboard.welcome.importEmployees'">
          </router-link>
          <router-link
            tag="button"
            class="primary"
            :to="{name: 'settingsCompanyIntegrations'}"
            v-t="'dashboard.welcome.goToIntegrations'">
          </router-link>
        </menu>
      </p>
    </template>
    <template v-else>
      <div v-html="$t('demo.welcome.body')" />
      <p>
        <menu class="hero">
          <router-link
            tag="button"
            class="primary"
            to="/dashboard"
            v-t="'demo.welcome.showDashboard'">
          </router-link>
        </menu>
      </p>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      company: 'account/getCompany'
    })
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
@import "./src/styles/link.scss";

.welcome {
  @include container;
  background: white url(~@/assets/welcome.png) repeat-y center right;
  min-height: 300px;
}

.welcome::v-deep {
  .brand {
    color: $clearteal-color;
  }

  ul {
    list-style-type: none;
    padding-left: 1.5em;
    margin: 0 0;

    li {
      line-height: 1.4em;
      margin: 1.4em 0;
    }

    li:before {
      content: " ";
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      background: lighten($clearteal-color, 15);
      position: relative;
      right: 1em;
      vertical-align: -1px;
    }
  }

  .blue:before {
    background: $graph-text-color;
  }

  .dark:before {
    background: $darkteal-color;
  }

  a {
    @extend .hover-link;
    @include font-large-size;
    @include font-semibold;
    color: $clearteal-color;
  }

  .blue a {
    color: $graph-text-color;
  }

  .dark a {
    color: $darkteal-color;
  }

  hr {
    border: none;
    background: $border-color;
    height: 1px;
    margin: 1.5em 0;
  }

  .helpscout {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background: $clearteal-color url(~@/assets/beacon.svg) no-repeat center
      center;
    background-size: 50%;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
    vertical-align: sub;
    margin: 0 2px;

    &:hover {
      box-shadow: inset 0 0 0 30px rgba(0, 0, 0, 0.06);
    }
  }
}
</style>
