<template>
  <div>
    <LoadingView v-if="!variableWages" />
    <div v-else>
      <div class="variable-wages-container" v-if="hasContractualVariable">
        <h2 v-t="'employees.employee.variable.contractualVariable'"></h2>
        <InlineVariableWage
          class="wage-details"
          :wageDetails="currentWageDetails"
          :selectedRecurrence="selectedRecurrence" />
      </div>
      <div class="variable-wages-container" v-if="ongoingVariableWages.length">
        <h2 class="ongoing-variable-wages-title">
          {{$tc('employees.employee.variable.currentVariablePlans' + (hasContractualVariable ? 'Details' : ''), ongoingVariableWages.length)}}
          <span class="badge border-badge large-badge" v-if="totalOngoingBonusValue">
            {{ totalOngoingBonusValue | formattedCurrency }}
          </span>
        </h2>
        <OnboardingVariableWage
          v-for="variableWage in ongoingVariableWages"
          :key="variableWage.id"
          :variableWage="variableWage"
          :variableWages="ongoingVariableWages"
          @bonus="addOngoingBonusValue"
          @click.native="selectVariableWage(variableWage)" />
        <p class="more-details no-margin-bottom light-text" v-t="'employees.employee.newsfeed.moreDetails'"></p>
      </div>
      <div class="variable-wages-container" v-if="closedVariableWages.length">
        <h2 v-t="'employees.employee.variable.newsfeed.title'"></h2>
        <div class="wages">
          <template v-for="variableWage in closedVariableWages">
            <div class="wage" :key="variableWage.id" @click="selectVariableWage(variableWage)">
              <div class="date">
                <div>
                  {{formatDate(variableWage.variablePlan.endDate)}}
                </div>
              </div>
              <div class="timeline">
                <div class="dot"></div>
              </div>
              <div class="variable-wage variable-wage-closed">
                <div class="variable-wage-title">{{variableWage.variablePlan.name}}</div>
                <span class="badge border-badge large-badge">
                  {{ variableWage.computedCents / 100 | formattedCurrency }}
                </span>
              </div>
            </div>
            <div
              v-if="variableWage !== closedVariableWages[closedVariableWages.length - 1]"
              class="wage wage-transition" :key="[variableWage.id, 'transition'].join()">
              <div></div>
              <div class="timeline"></div>
              <div></div>
            </div>
          </template>
        </div>
      </div>
      <div class="variable-wages-container" v-if="!hasContractualVariable && !ongoingVariableWages.length && !closedVariableWages.length">
        <h2 v-t="'employees.employee.variable.variablePlan'"></h2>
        <p class="no-margin-bottom" v-t="{path: 'employees.employee.variable.noCurrentVariablePlans', args: { firstName: employee.firstName }}"></p>
        <div class="placeholder-container">
          <div class="placeholder" v-if="$$isAdmin && updateContractualVariableRoute && !$$isOneselfRoute">
            <menu class="hero">
              <p class="no-margin-top" v-html="$t('employees.employee.variable.adminContractualVariablePlaceholder')" />
              <router-link
                :to="updateContractualVariableRoute"
                tag="button"
                class="primary"
                v-t="'employees.employee.variable.goToContractualVariable'" />
            </menu>
          </div>
          <div class="placeholder" v-if="$$isAtLeastManager && $$hasPermission('variablePlans') && !$$isOneselfRoute">
            <menu class="hero">
              <p class="no-margin-top" v-html="$t('employees.employee.variable.adminPlaceholder')" />
              <router-link
                :to="{name: 'variablePlans'}"
                tag="button"
                class="primary"
                v-t="'employees.employee.variable.goToVariable'" />
            </menu>
          </div>
        </div>
      </div>
    </div>
    <Modal
      class="variable-wage-viewer-modal"
      :visible="!!selectedVariableWage"
      @close="selectedVariableWage = null">
      <VariableWageViewer :employee="employee" :variableWage="selectedVariableWage" />
    </Modal>
  </div>
</template>

<script>
import { sum } from 'd3'
import LoadingView from '@components/commons/LoadingView.vue'
import Modal from '@components/commons/Modal.vue'
import OnboardingVariableWage from '@/components/variable/OngoingVariableWage.vue'
import VariableWageViewer from '@components/variable/VariableWageViewer.vue'
import InlineVariableWage from '@components/wage/InlineVariableWage.vue'
import { getWageDetails } from '@/utils/grid'
import { titleize } from '@/utils/string'
import { formatDate } from '@/utils/date'

export default {
  name: 'EmployeeVariableView',
  components: {
    LoadingView,
    OnboardingVariableWage,
    InlineVariableWage,
    Modal,
    VariableWageViewer
  },
  props: {
    variableWages: {
      type: Object,
      default() {
        return {}
      }
    },
    selectedRecurrence: {
      type: String,
      default: 'yearly'
    }
  },
  data() {
    return {
      selectedVariableWage: null,
      ongoingBonusValues: {}
    }
  },
  computed: {
    employee() {
      if (this.$$isOneselfRoute) {
        return this.$store.getters['employees/getOneself']
      }

      return this.$store.getters['employees/getEmployee'](this.$route.params.id)
    },
    ongoingVariableWages() {
      return (this.variableWages && this.variableWages.ongoing) || []
    },
    closedVariableWages() {
      return (this.variableWages && this.variableWages.closed) || []
    },
    hasContractualVariable() {
      return this.currentWageDetails && this.currentWageDetails.summary.salary.variableValue
    },
    isCandidate() {
      return !!this.employee.wageProposal
    },
    currentWageDetails() {
      const currentWage = !this.isCandidate ? this.employee.currentWage : this.employee.wageProposal
      if (currentWage) {
        const grid = this.$store.getters['currentGrid/getCurrentGrid']
        const wageDetails = getWageDetails(grid, currentWage)
        return { ...currentWage, ...wageDetails }
      }
    },
    totalOngoingBonusValue() {
      return sum(Object.values(this.ongoingBonusValues))
    },
    updateContractualVariableRoute() {
      if (this.employee && this.employee.currentWage) {
        const { id } = this.employee
        const wageId = this.employee.currentWage.id
        return { name: 'updateEmployeeWage', params: { id, wageId } }
      }
    }
  },
  methods: {
    formatDate(date) {
      return titleize(formatDate(date, 'MMMM YYYY'))
    },
    selectVariableWage(variableWage) {
      this.selectedVariableWage = variableWage
    },
    addOngoingBonusValue({ id, bonusValue }) {
      this.$set(this.ongoingBonusValues, id, bonusValue)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/badge.scss";
@import "./src/styles/button.scss";
@import "./src/styles/newsfeed.scss";

.variable-wages-container:not(:last-of-type) {
  margin-bottom: 3em;
}

.variable-wage {
  @extend .badge-block, .clickable;
  @include line-regular-height;
  padding: 0.5em 0.75em;
  margin-right: 20px;

  &::v-deep .arrow {
    @include font-normal-size;
    color: $light-text-color;
    vertical-align: 1px;
  }

  .variable-wage-title {
    @include font-large-size;
    @include font-semibold;
  }

  &.variable-wage-closed {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 1em;
    align-items: center;
  }
}

.ongoing-variable-wages-title {
  .border-badge {
    margin: -4px 7px;
  }
}

.more-details {
  @include font-small-size;
  margin: 0.75em 0;
}

.wage-transition {
  height: 1.5em;
}

.placeholder-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.placeholder {
  @extend .badge-block;
  padding: 0.5em;
  display: block;
  margin-top: 2em;

  menu.hero {
    margin: 1em 0;

    & > * {
      margin-left: 0;
    }
  }
}

.variable-wage-viewer-modal::v-deep {
  & .close-button-container {
    padding: 20px 1em 0 0;
  }
}
</style>
