<template>
  <div class="employee-picker-container">
    <div class="input-label" v-t="'employees.employee.form.placementOnGrid'"></div>
    <div class="employees-summary">
      {{$tc(`grid.editor.employeesAtThisLevel`, employeesModel.length, {employees: employeesModel.length})}}
    </div>
    <resizable-panel :observer="employeesModel.length">
      <div class="employees-table" v-if="employeesModel.length">
        <table class="blue">
          <tr
            class="row"
            :class="{clickable: employee.$isUpdated}"
            v-for="employee in employeesModel"
            :key="employee.id"
            @click="removeEmployee(employee)">
            <td class="employee-name" :colspan="!employee.$isUpdated && 2">
              <name-thumbnail class="blue" :employee="employee" inline=true />
              {{ employee.fullName }}
              <span class="role">{{employee.roleLabel}}</span>
            </td>
            <td class="employee-action delete" v-if="employee.$isUpdated">×</td>
          </tr>
        </table>
      </div>
    </resizable-panel>
    <div class="employee-picker">
      <resizable-panel :observer="isPickingEmployee && [filteredEmployees.length, availableEmployees.length]">
        <div v-if="isPickingEmployee">
          <input
            type="text"
            @input="onSearchQueryChange"
            ref="search"
            :placeholder="$t('grid.editor.searchEmployee')">
          <button
            class="small-button"
            @click="toggleIsPickingEmployee">
            {{actionLabel}}
          </button>
          <div class="employees-table" v-if="filteredEmployees.length">
            <table class="blue">
              <tr
                class="row clickable"
                v-for="employee in filteredEmployees"
                :key="employee.id"
                @click="addEmployee(employee)">
                <td class="employee-name">
                  <name-thumbnail class="blue" :employee="employee" inline=true />
                  {{ employee.fullName }}
                  <span class="role">{{employee.roleLabel}}</span>
                  <span class="component-value"
                    v-if="employee.currentLevelLabel && employee.currentLevelLabel !== employee.roleLabel">
                    {{$t('grid.onboarding.qualification.editor.currentLevel')}}
                    {{employee.currentLevelLabel}}
                  </span>
                  <span class="component-value no-level" v-if="!employee.currentLevelLabel" v-t="'grid.editor.toBePositioned'"></span>
                </td>
                <td class="employee-action" v-t="'common.add'"></td>
              </tr>
            </table>
          </div>
        </div>
      </resizable-panel>
      <button
        v-if="!isPickingEmployee"
        class="small-button"
        @click="toggleIsPickingEmployee">
        {{actionLabel}}
      </button>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import { mapGetters } from 'vuex'
import NameThumbnail from '@components/commons/NameThumbnail'
import ResizablePanel from '@components/commons/ResizablePanel'
import { filterByQuery, sortByKeys } from '@/utils/string'
import sortBy from 'lodash.sortby'

export default {
  components: {
    NameThumbnail,
    ResizablePanel
  },
  props: {
    component: Object,
    employees: Array
  },
  data() {
    return {
      employeesModel: [],
      isPickingEmployee: false,
      searchQuery: null
    }
  },
  computed: {
    ...mapGetters({
      allEmployees: 'employees/getEmployees',
      getEnrichedEmployee: 'sandbox/enrichedEmployee',
      employeeWage: 'sandbox/employeeWage'
    }),
    availableEmployees() {
      const employeeIds = this.employeesModel.map(e => e.id)
      return sortBy(this.allEmployees.map(employee => {
        employee = this.getEnrichedEmployee(employee)
        const components = employee.postGridWageDetails.selectedComponents
        const roleComponent = components.find(c => c.ref === 'role')
        const roleLabel = roleComponent && roleComponent.selectedLevel && roleComponent.selectedLevel.name
        const component = components.find(c => c.id === this.component.id)
        const currentLevelLabel = component && component.selectedLevel && component.selectedLevel.name
        const hasQualification = !!currentLevelLabel
        return {
          ...employee,
          $isUpdated: true,
          roleLabel,
          currentLevelLabel,
          hasQualification
        }
      })
        .filter(employee => employee.sandboxWage.hasLevels && !employeeIds.includes(employee.id)), ['hasQualification', 'fullName'])
    },
    filteredEmployees() {
      return filterByQuery(this.availableEmployees, this.searchQuery, '_searchKey')
    },
    actionLabel() {
      return this.isPickingEmployee ? this.$t('common.ok') : this.$t('employees.employee.form.addEmployee')
    }
  },
  methods: {
    onSearchQueryChange: debounce(function(event) {
      this.searchQuery = event.target.value
    }, 300),
    toggleIsPickingEmployee() {
      this.isPickingEmployee = !this.isPickingEmployee
      if (this.isPickingEmployee) {
        this.$nextTick(() => {
          if (this.$refs.search) {
            this.$refs.search.focus()
          }
        })
      }
    },
    removeEmployee(employee) {
      if (!employee.$isUpdated) {
        return
      }
      this.employeesModel = this.employeesModel
        .filter(e => e.id !== employee.id)
      this.onChange()
    },
    addEmployee(employee) {
      this.employeesModel = sortByKeys(this.employeesModel.concat([employee]), 'fullName')
      this.onChange()
    },
    onChange() {
      this.$emit('change', this.employeesModel)
    },
    initModel() {
      if (this.employees.length !== this.employeesModel.length) {
        this.isPickingEmployee = false
      }
      this.searchQuery = null
      this.employeesModel = [...this.employees]
    }
  },
  watch: {
    employees: 'initModel'
  },
  created() {
    this.initModel()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";
@import "./src/styles/table.scss";

.employee-picker-container {
  margin-bottom: 1.5rem;
}

.employees-summary {
  margin: 0.5em 0 0.75rem;
}

.employees-table {
  border: 1px $graph-outer-border-color solid;
  border-radius: $border-radius;
  box-shadow: 1px 1.5px 0 rgba(0, 0, 0, 0.03);
  margin-bottom: 0.75rem;
  // Add scrollbar
  max-height: 36px * 4.2;
  overflow-y: auto;
}

table {
  @include font-small-size;

  th,
  td {
    @include line-regular-height;
    padding: 0.25em 0.5em;
  }

  th {
    @include font-medium;
    background: white;
    border: none;
  }

  td {
    border: 1px solid $graph-outer-border-color;
  }

  .role,
  .component-value {
    display: inline-block;
    margin-left: 0.5em;
    color: $light-text-color;

    &.no-level {
      color: darken($clearteal-color, 10);
    }
  }

  .component-value {
    float: right;
    line-height: 28px;
  }

  .employee-name {
    width: 100%;
  }

  .employee-action {
    color: $light-text-color;
    padding: 0 1.5em;

    &.delete {
      @include font-bold;
      padding: 0 1em;
    }
  }

  .row:hover {
    .employee-action {
      color: darken($clearteal-color, 10);

      &.delete {
        color: darken($red-color, 12);
      }
    }
  }
}

.employee-picker {
  input[type="text"] {
    display: inline-block;
    max-width: 50%;
    margin-right: 0.75em;
    margin-bottom: 0.75em;
  }
}
</style>
