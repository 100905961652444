// ---- localStorage ----

// Get a JS object from localStorage
export function getItem(key, defaultValue = null, version = 1) {
  return getStorageItem(localStorage, key, defaultValue, version)
}

// Set a JS object from localStorage
export function setItem(key, data, version = 1) {
  return setStorageItem(localStorage, key, data, version)
}

// Toggle a boolean from localStorage
export function toggleItem(key, version = 1) {
  const item = getStorageItem(localStorage, key, false, version)
  if (item) {
    removeStorageItem(localStorage, key)
  }
  else {
    setItem(key, true, version)
  }
  return !item
}

// ---- sessionStorage ----

// Get a JS object from sessionStorage
export function getSessionItem(key, defaultValue = null, version = 1) {
  return getStorageItem(sessionStorage, key, defaultValue, version)
}

// Set a JS object from sessionStorage
export function setSessionItem(key, data, version = 1) {
  return setStorageItem(sessionStorage, key, data, version)
}

// Set a JS object from sessionStorage and remove it if empty
export function setSessionItemClean(key, data, version = 1) {
  return data
    ? setStorageItem(sessionStorage, key, data, version)
    : removeStorageItem(sessionStorage, key)
}

// ---- private methods ----

function getStorageItem(storage, key, defaultValue, version) {
  try {
    if (storage.getItem(key)) {
      const wrappedItem = JSON.parse(storage.getItem(key))
      if (wrappedItem.v === version) {
        return wrappedItem.d
      }
    }
  }
  catch { }

  return defaultValue
}

function setStorageItem(storage, key, data, version = 1) {
  const wrappedItem = { v: version, d: data }
  storage.setItem(key, JSON.stringify(wrappedItem))
}

function removeStorageItem(storage, key) {
  storage.removeItem(key)
}
