<template>
  <div>
    <template v-if="isEditing">
      <CustomFieldForm
        :field="isEditing"
        @close="close" />
    </template>
    <template v-else>
      <div v-if="customFields.length">
        <ol class="custom-fields">
          <draggable class="views" v-model="orderedCustomFields">
            <li
              v-for="customField in customFields"
              :key="customField.id"
              class="colored-link"
              @click="editField(customField)">
              <span class="name">{{customField.name}}</span>
              <span class="kind">{{$t(`settings.settingsCompanyCustomFields.kinds.${customField.kind}.name`)}}</span>
            </li>
          </draggable>
        </ol>
      </div>
      <CustomFieldForm
        v-if="isAdding"
        @close="close" />
      <button
        v-if="!isAdding"
        class="primary"
        @click="addField"
        v-t="'settings.settingsCompanyCustomFields.form.addCustomField'"></button>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Draggable from 'vuedraggable'
import CustomFieldForm from '@components/settings/CustomFieldForm.vue'

export default {
  components: {
    CustomFieldForm,
    Draggable

  },
  data() {
    return {
      isEditing: false,
      isAdding: false
    }
  },
  computed: {
    ...mapGetters({
      customFields: 'company/getCustomFields'
    }),
    orderedCustomFields: {
      get() {
        return this.customFields
      },
      set(value) {
        this.$store.dispatch('company/updateCustomFields', value)
      }
    }
  },
  methods: {
    addField() {
      this.isAdding = true
    },
    editField(field) {
      this.isEditing = field
    },
    close() {
      this.isAdding = false
      this.isEditing = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
@import "./src/styles/link.scss";
@import "./src/styles/form.scss";

.custom-fields {
  list-style-type: none;
  margin-bottom: 1.5em;
  padding-left: 0;

  li {
    counter-increment: list;
    margin-bottom: 0.75em;

    &:not(:last-of-type) {
      padding-bottom: 0.75em;
      border-bottom: $border;
    }

    .name {
      margin: 0 0.75em 0 0.75em;
    }

    &:before {
      @include font-smaller-size;
      @include font-bold;
      content: counter(list);
      display: inline-block;
      width: 20px;
      line-height: 20px;
      border-radius: 10px;
      background: $lightteal-color;
      color: darken($clearteal-color, 5);
      text-align: center;
    }

    .kind {
      @include font-tiny-size;
      @include font-semibold;
      text-transform: uppercase;
      display: inline-block;
      padding: 0.1em 0.4em;
      border-radius: $border-radius;
      border: 1px solid transparent;
      background: $lightgray-color;
      color: darken($gray-color, 15);
    }
  }
}
</style>
