<template>
  <div @paste="onPaste">
    <h3 class="title" v-if="isGridStructureEditor">
      {{payload.component.name}}
      &nbsp;&nbsp;›
      <InlineEditor
        v-model="name"
        :placeholder="name"
        :error="$v.name.$error"
        @submit="onChange" />
    </h3>
    <div class="title-picker" v-else>
      <GridLevelPicker
        :component="payload.component"
        :value="levelsModel[0]"
        @input="selectLevel" />
    </div>
    <div v-if="isGridStructureEditor || !linkedLevelsModel">
      <div class="input-label" v-t="'common.description'"></div>
      <MarkdownTextEditor
        v-model="description"
        :placeholder="$t('grid.editor.levelDescription')"
        :showAddButton="true"
        :showInlineEditButton="true"
        :showToolbar="true"
        :autoDismiss="true"
        @change="onChange" />
    </div>
    <!-- Layout for Grid Editor (with interval) -->
    <template v-if="!isGridStructureEditor && isInterval && linkedLevelsModel">
      <div v-if="linkedLevelsModel">
        <div class="input-label" v-t="'grid.editor.experienceCurve'"></div>
        <div>
          <div class="evolution-graph">
            <WageIntervalSlider
              :wage="computedWageIntervalSlider.wage"
              :grid="computedWageIntervalSlider.grid"
              :simulationLevels="computedWageIntervalSlider.simulationLevels"
              :showWage="false"
              @updateLevel="onLinkedLevelValueChange" />
          </div>
        </div>
      </div>
      <!-- Linked levels interval values -->
      <div class="interval-box-container">
        <div
          v-for="(linkedLevel, i) in linkedLevelsModel"
          :key="linkedLevel.id"
          :class="{hidden: linkedLevel.isHidden}"
          class="interval-box">
          <div class="interval-box-name">
            <div
              class="input-label">
              {{linkedLevel.name}}
            </div>
          </div>
          <div>
            <div class="interval-header" v-if="!i">
              <div class="input-label" v-t="'grid.editor.level.interval.min'"></div>
              <div class="input-label" v-t="'grid.editor.level.interval.max'"></div>
            </div>
            <div class="interval-content">
              <OperationInput
                class="green"
                :class="{'input-error' : isLevelIntervalInvalid(linkedLevel)}"
                operation="number"
                :min="0"
                :max="300000"
                :step="500"
                v-model="linkedLevel.minimumValue"
                @change="onIntervalMinMaxValueChange(linkedLevel)" />
              <OperationInput
                class="green"
                :class="{'input-error' : isLevelIntervalInvalid(linkedLevel)}"
                operation="number"
                :min="0"
                :max="300000"
                :step="500"
                v-model="linkedLevel.maximumValue"
                @change="onIntervalMinMaxValueChange(linkedLevel)" />
            </div>
          </div>
          <div v-if="linkedLinkedLevelsModel">
            <div class="interval-header" v-if="!i">
              <div
                class="input-label"
                v-for="linkedLinkedLevel in getVisibleLinkedLevels(linkedLinkedLevelsModel, linkedLevel)"
                :key="linkedLinkedLevel.id">{{linkedLinkedLevel.name}}</div>
            </div>
            <div class="interval-content">
              <OperationInput
                v-for="linkedLinkedLevel in getVisibleLinkedLevels(linkedLinkedLevelsModel, linkedLevel)"
                :key="linkedLinkedLevel.id"
                class="green"
                operation="number"
                :min="0"
                :max="300000"
                :step="100"
                v-model="linkedLinkedLevel.salaryValue"
                @change="onLinkedLinkedLevelChange(linkedLinkedLevel)" />
            </div>
          </div>
        </div>
      </div>
      <div>
        <GridEmployees
          class="team"
          :simulation-levels="combinedLevelsModel"
          :simulation-wages="wagesModel"
          :highlighted-ref="linkedLevelsModel && 'experience'"
          :showIntervals="isInterval"
          @count="count => employeesCount = count"
          @employees="setEmployees"
          @select="selectEmployee" />
        <div
          v-if="hasEmployeesOutsideInterval && $$isAdmin"
          class="alert warning cool-bump outside-interval-warning">
          {{$t('grid.editor.level.outsideIntervalWarning')}}
          <button
            class="small-button orange adjust-button"
            v-t="'grid.editor.level.outsideIntervalAction'"
            @click="overrideWagesToFitInsideInterval" />
        </div>
        <p v-if="isRole" class="light-text" v-html="$t('grid.editor.roleSalaryGuide')"></p>
      </div>
    </template>
    <!-- Layout for Grid Editor (without interval) -->
    <template v-else-if="!isGridStructureEditor && !isInterval">
      <div v-if="linkedLevelsModel">
        <div class="input-label" v-t="'grid.editor.experienceCurve'"></div>
        <div>
          <div class="evolution-graph">
            <MiniEvolutionGraph
              class="shadows"
              :value="computedLinkedLevelValues"
              :labels="linkedLevelsModel.map(l => l.name)"
              :height="150"
              :padding="8" />
          </div>
        </div>
      </div>
      <!-- Layout for single level edition -->
      <div v-if="!linkedLevelsModel">
        <div class="input-box">
          <div class="input-label" v-t="`grid.editor.${isRole ? 'salaryBase' : 'levelValue'}`"></div>
          <OperationInput
            class="green"
            :operation="salaryOperation"
            :step="step"
            v-model="salaryValue"
            @change="onChange" />
        </div>
        <div class="input-box disabled-box fade-in" v-if="!linkedLevelsModel && initialSalaryValue !== salaryValue">
          <div class="input-label" v-t="'grid.editor.initialValue'"></div>
          <OperationInput
            :operation="salaryOperation"
            :step="step"
            :disabled="true"
            v-model="initialSalaryValue" />
          <button
            class="small-button"
            @click="initModel"
            v-t="'common.back'"></button>
        </div>
      </div>
      <!-- Layout for linked level edition -->
      <div v-else>
        <div class="linked-level-box">
          <div class="input-label" v-t="`grid.editor.${isRole ? 'salaryBase' : 'levelValue'}`"></div>
          <OperationInput
            class="green"
            :operation="salaryOperation"
            :step="step"
            v-model="salaryValue"
            @change="onLinkedLevelChange" />
        </div>
        <div
          class="linked-level-box"
          v-for="linkedLevel in linkedLevelsModel"
          :key="linkedLevel.id"
          :class="{hidden: linkedLevel.isHidden}">
          <div
            class="input-label colored-link"
            @click="selectLinkedLevel(linkedLevel)">{{linkedLevel.name}}</div>
          <OperationInput
            class="green"
            operation="addition"
            :min="0"
            :max="200000"
            :step="100"
            v-model="linkedLevel.simulatedSalaryValue"
            @change="onLinkedLevelSimulatedSalaryChange(linkedLevel)" />
          <OperationInput
            class="green"
            operation="multiplier"
            :min="0.1"
            :max="100"
            :step="0.01"
            v-model="linkedLevel.salaryValue"
            @change="onLinkedLevelChange" />
        </div>
      </div>
      <div>
        <GridEmployees
          class="team"
          :simulation-levels="combinedLevelsModel"
          :highlighted-ref="linkedLevelsModel && 'experience'"
          @count="count => employeesCount = count"
          @select="selectEmployee" />
        <p v-if="isRole" class="light-text" v-html="$t('grid.editor.roleSalaryGuide')"></p>
      </div>
    </template>
    <!-- Layout for Grid Structure Editor -->
    <template v-if="isGridStructureEditor">
      <div v-if="hasEmployeePicker">
        <GridEmployeePicker
          :component="payload.component"
          :employees="employeesModel"
          @change="onEmployeesChange" />
      </div>
      <div v-else>
        <div class="input-label" v-t="'employees.employee.form.placementOnGrid'"></div>
        <p class="light-text no-margin-top" v-t="'grid.editor.noQualificationYet'"></p>
      </div>
    </template>
    <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>
    <menu v-if="$$isAdmin">
      <button
        v-if="isUpdated"
        class="destructive fade-in"
        @click="reset"
        v-t="'common.reset'"></button>
      <loading-button
        v-if="isGridStructureEditor"
        class="destructive"
        :disabled="isDeleting"
        :loading="isDeleting"
        @click="remove">
        <span v-t="'common.remove'"></span>
      </loading-button>
      <button
        v-if="isBackAvailable"
        class="secondary"
        @click="$emit('back')"
        v-t="'common.back'"></button>
      <button
        v-else
        class="secondary"
        @click="close"
        v-t="'common.cancel'"></button>
      <loading-button
        class="primary"
        :disabled="isLoading"
        @click="submit()"
        :loading="isLoading">
        <span v-t="'common.update'"></span>
      </loading-button>
    </menu>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import GridLevelPicker from '@components/grid/viewer/GridLevelPicker.vue'
import InlineEditor from '@components/commons/InlineEditor'
import MarkdownTextEditor from '@components/commons/MarkdownTextEditor'
import OperationInput from '@components/commons/OperationInput'
import GridEmployees from '@components/grid/editor/GridEmployees'
import GridEmployeePicker from '@components/grid/editor/GridEmployeePicker'
import MiniEvolutionGraph from '@components/graph/MiniEvolutionGraph.vue'
import WageIntervalSlider from '@components/wage/WageIntervalSlider.vue'
import cloneDeepWith from 'lodash.clonedeepwith'
import { mapGetters } from 'vuex'
import { componentHasSkills } from '@/utils/skills'
import {
  isInterval,
  valueFromOperation,
  getLinkedComponent,
  getVisibleLinkedLevels
} from '@/utils/grid'
import { parseStringFloat } from '@/utils/string'
import flatten from 'lodash.flatten'

export default {
  components: {
    InlineEditor,
    MarkdownTextEditor,
    MiniEvolutionGraph,
    GridEmployees,
    GridEmployeePicker,
    GridLevelPicker,
    OperationInput,
    WageIntervalSlider
  },
  name: 'gridLevelEditor',
  props: {
    payload: Object,
    isBackAvailable: Boolean,
    isGridStructureEditor: {
      type: Boolean
    },
    isGridGenerated: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      description: null,
      errorMessage: null,
      initialSalaryValue: null,
      isDeleting: false,
      isLoading: false,
      isUpdated: false,
      employeesModel: [],
      employeesCount: 0,
      employees: [],
      levelsModel: null,
      linkedLevelsModel: null,
      linkedLinkedLevelsModel: null,
      name: null,
      salaryValue: 0,
      hasEmployeesOutsideInterval: false,
      wagesModel: []
    }
  },
  computed: {
    ...mapGetters({
      enrichedEmployees: 'sandbox/enrichedEmployees',
      grid: 'sandbox/grid'
    }),
    salaryOperation() {
      return this.payload.component.salaryOperation
    },
    step() {
      return this.salaryOperation === 'addition' ? 100 : 0.05
    },
    combinedLevelsModel() {
      return [
        ...(this.levelsModel || []),
        ...(this.linkedLevelsModel || []),
        ...(this.linkedLinkedLevelsModel || [])
      ]
    },
    isInterval() {
      return isInterval(this.payload.component)
    },
    isRole() {
      return this.payload.component.ref === 'role'
    },
    hasEmployeePicker() {
      return this.isGridStructureEditor && !componentHasSkills(this.payload.component)
    },
    computedLinkedLevelValues() {
      const linkedComponent = { ...this.payload.component.linkedComponents[0], levels: this.linkedLevelsModel }
      const refLevel = this.levelsModel[0]
      return this.linkedLevelsModel.map(l => (
        !this.isInterval
          ? valueFromOperation(refLevel.salaryValue || 0, l.salaryValue || 0, linkedComponent.salaryOperation)
          : [l.minimumValue, l.maximumValue]
      ))
    },
    computedWageIntervalSlider() {
      if (this.linkedLevelsModel) {
        const wage = { hasLevels: true, levelIds: [this.levelsModel[0].id, this.linkedLevelsModel[0].id] }
        const grid = this.grid
        const simulationLevels = this.combinedLevelsModel
        return { wage, grid, simulationLevels }
      }
    }
  },
  methods: {
    getVisibleLinkedLevels,
    initLevelsModels() {
      this.linkedLevelsModel = null
      this.linkedLinkedLevelsModel = null
      this.levelsModel = cloneDeepWith(this.payload.levels)
      const linkedComponent = getLinkedComponent(this.payload.component)
      const linkedLinkedComponent = getLinkedComponent(linkedComponent)
      if (linkedComponent) {
        this.linkedLevelsModel = cloneDeepWith(getVisibleLinkedLevels(linkedComponent.levels, this.levelsModel[0]))
      }
      if (linkedLinkedComponent) {
        this.linkedLinkedLevelsModel = cloneDeepWith(flatten(this.linkedLevelsModel.map(l => getVisibleLinkedLevels(linkedLinkedComponent.levels, l))))
      }
      this.initLevelsModelDefaultValue()
      this.initSimulatedLinkedLevelsModel()
    },
    initLevelsModelDefaultValue() {
      // Needed for newly created roles, when intervals are enabled
      if (this.isInterval && this.linkedLevelsModel && this.levelsModel.every(l => !l.salaryValue)) {
        this.levelsModel.forEach(level => {
          level.salaryValue = 1
        })
      }
    },
    initSimulatedLinkedLevelsModel() {
      // Needed by onLinkedLevelSimulatedSalaryChange, when intervals are disabled
      if (this.linkedLevelsModel) {
        const linkedLevelValues = this.computedLinkedLevelValues
        this.linkedLevelsModel.forEach((level, i) => {
          level.simulatedSalaryValue = linkedLevelValues[i]
        })
      }
    },
    initModel() {
      this.initLevelsModels()
      this.name = this.levelsModel[0].name
      this.description = this.levelsModel[0].description
      this.salaryValue = this.levelsModel[0].salaryValue
      this.initialSalaryValue = this.salaryValue
      this.isUpdated = false
      this.wagesModel = []
      if (this.hasEmployeePicker) {
        this.employeesModel = this.enrichedEmployees(this.combinedLevelsModel, [], true)
      }
    },
    setEmployees(employees) {
      if (this.isInterval) {
        this.employees = employees
        this.hasEmployeesOutsideInterval = !!this.employees.find(e =>
          e.postGridWageDetails.summary.salary.interval &&
          e.postGridWageDetails.summary.salary.interval.isOutside)
      }
    },
    onIntervalMinMaxValueChange(level) {
      level.salaryValue = Math.round((level.minimumValue + level.maximumValue) / 2)
      this.onChange()
    },
    onLinkedLinkedLevelChange() {
      this.onChange()
    },
    onLinkedLevelChange() {
      // We need to call onChange twice because initSimulatedLinkedLevelsModel requires salaryValue to be set
      this.onChange()
      this.initSimulatedLinkedLevelsModel()
      this.onChange()
    },
    onLinkedLevelSimulatedSalaryChange(level) {
      const refLevel = this.levelsModel[0]
      level.salaryValue = level.simulatedSalaryValue / refLevel.salaryValue
      this.onChange()
    },
    onLinkedLevelValueChange({ levelId, minimumValue, salaryValue, maximumValue }) {
      const level = this.linkedLevelsModel.find(l => l.id === levelId)
      if (level) {
        level.minimumValue = minimumValue
        level.salaryValue = salaryValue
        level.maximumValue = maximumValue
        this.onChange()
      }
    },
    onChange() {
      this.levelsModel = this.levelsModel.map(level => {
        level.name = this.name
        level.description = this.description
        if (typeof this.salaryValue === 'number') {
          level.salaryValue = +this.salaryValue
        }
        return level
      })
      this.isUpdated = true
      this.$emit('simulation', this.combinedLevelsModel, this.wagesModel)
    },
    onEmployeesChange(employees) {
      this.employeesModel = employees
      this.isUpdated = true
    },
    onPaste(event) {
      // Allow pasting simulated salary values from Excel
      const clipboardData = event.clipboardData || window.clipboardData
      const clipboardText = clipboardData ? clipboardData.getData('text') : null
      const pastedLevels = (clipboardText || '').split('\n').map(l => parseStringFloat(l))
      const isLevelsValid = this.linkedLevelsModel && pastedLevels.length === this.linkedLevelsModel.length && !pastedLevels.find(l => isNaN(l))
      if (isLevelsValid) {
        this.linkedLevelsModel.forEach((level, i) => {
          const pastedLevel = pastedLevels[i]
          level.simulatedSalaryValue = pastedLevel
          this.onLinkedLevelSimulatedSalaryChange(level)
        })
      }
    },
    reset() {
      this.initModel()
      this.$emit('simulation', this.combinedLevelsModel, this.wagesModel)
    },
    close() {
      this.$emit('close')
    },
    selectLevel(level) {
      const component = this.payload.component
      this.$emit('select-levels', component, [level])
    },
    selectLinkedLevel(linkedLevel) {
      const linkedComponent = getLinkedComponent(this.payload.component)
      this.$emit('select-levels', linkedComponent, [linkedLevel])
    },
    async selectEmployee(employee) {
      if (this.$$isAdmin && this.isUpdated && window.confirm(this.$t('grid.onboarding.generate.saveWarning'))) {
        await this.submit(false)
      }
      this.$emit('select-employee', employee)
    },
    isLevelIntervalInvalid(level) {
      return !level.minimumValue || !level.salaryValue || !level.maximumValue || level.minimumValue > level.maximumValue || level.salaryValue > level.maximumValue
    },
    overrideWagesToFitInsideInterval() {
      this.employees
        .filter(e => e.postGridWageDetails.summary.salary.interval &&
          e.postGridWageDetails.summary.salary.interval.isOutside)
        .forEach(e => {
          const { interval } = e.postGridWageDetails.summary.salary
          const wage = { ...e.sandboxWage }
          wage.overridenSalaryValue = interval.percent > 1 ? interval.max : interval.min
          this.wagesModel = this.wagesModel.filter(w => w.id !== wage.id).concat([wage])
          this.$emit('simulation', this.combinedLevelsModel, this.wagesModel)
        })
      this.isUpdated = true
    },
    async remove() {
      if (confirm(this.$t('sandbox.levelForm.deleteConfirmation', { levelName: this.payload.levels[0].name }))) {
        this.isDeleting = true
        try {
          await this.$store.dispatch('sandbox/removeLevel', this.payload.levels[0])
          this.close()
        }
        catch (error) {
          this.errorMessage = error
        }
        finally {
          this.isDeleting = false
        }
      }
    },
    async submit(shouldClose = true) {
      this.errorMessage = null
      this.$v.$touch()

      if (this.$v.$error) {
        return
      }
      else if (this.isInterval && !this.isGridStructureEditor && this.linkedLevelsModel && this.linkedLevelsModel.find(l => this.isLevelIntervalInvalid(l))) {
        this.errorMessage = this.$t('grid.editor.level.intervalInvalid')
        return
      }
      this.isLoading = true

      try {
        if (this.hasEmployeePicker) {
          // Associate selected employees with current level
          const wages = this.employeesModel.map(e => e.sandboxWage)
          const level = this.combinedLevelsModel[0]
          await this.$store.dispatch('sandbox/updateSandboxWagesLevel', { wages, level })
        }
        if (this.wagesModel.length) {
          await this.$store.dispatch('sandbox/updateSandboxWages', this.wagesModel)
        }
        await this.$store.dispatch('sandbox/updateLevels', this.combinedLevelsModel)
        if (shouldClose) {
          this.$emit('close')
        }
      }
      catch (error) {
        this.errorMessage = error
        this.isLoading = false
      }
    }
  },
  created() {
    this.initModel()
  },
  watch: {
    'payload.component': 'initModel'
  },
  validations: {
    name: {
      required
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/alert.scss";
@import "./src/styles/animation.scss";
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";
@import "./src/styles/link.scss";

h3.title {
  height: 32px;
  display: flex;
  align-items: center;

  .inline-editor {
    margin-left: 0.4em;
  }
}

.title-picker {
  margin-bottom: 1em;
}

.input-box {
  display: inline-block;
  margin-right: 0.3em;
}

.disabled-box *:not(.small-button) {
  opacity: 0.8;
}

input[type="text"].input-hover {
  display: inline-block;
  width: auto;
  font: inherit;

  &:not(:focus):not(:hover):not(.input-error) {
    border-color: transparent;
  }
}

.operation-input {
  display: inline-block;
  margin-bottom: 1em;
  margin-right: 0.5em;
}

.creative {
  margin-right: 0.5em;
}

.skills {
  float: left;
  margin-left: 0;
}

.evolution-graph {
  border: 1px solid $graph-outer-border-color;
  border-radius: $border-radius;
  margin-bottom: 1em;

  & > div:not(.wage-interval-slider) {
    width: 90%;
    margin: auto;
  }

  & > div.wage-interval-slider {
    padding-top: 5px;
  }
}

.linked-level-box {
  .input-label {
    display: inline-block;
    width: 82px;
    text-align: right;
    margin-bottom: 0;
    vertical-align: middle;
  }

  &.hidden {
    .operation-input {
      opacity: 0.6;
    }

    .input-label {
      color: $light-text-color;

      &::after {
        content: " ";
        display: inline-block;
        margin-left: 4px;
        width: 20px;
        height: 20px;
        background: transparent url(~@/assets/icon-hide.svg) no-repeat center
          center;
        vertical-align: middle;
        opacity: 0.6;
        transform: scale(
          0.9
        ); // For weird reasons background-size isn't working
      }
    }
  }
}

.interval-box-container {
  margin-bottom: 1em;
}

.interval-box {
  display: grid;
  grid-template-columns: 70px auto 1fr;
  align-items: end;
  gap: 0.5em;
  margin-bottom: 0.5em;

  .interval-box-name .input-label {
    margin-bottom: 10px;
    text-align: right;
  }

  .interval-header {
    display: grid;
    grid-template-columns: repeat(auto-fit, 79px);
    margin: 0 0 2px 0;
    text-align: center;

    &:not(:first-of-type) {
      margin-top: 0.5em;
    }

    .input-label {
      color: $light-text-color;
      text-align: center;
      margin-bottom: 0;
    }
  }

  .interval-content {
    white-space: nowrap;

    // Grouped operation input theme
    .operation-input::v-deep {
      margin: 0;

      input {
        border-radius: $border-radius;
        width: 80px;
        padding: 0;
        text-align: right;
      }

      &:not(:first-of-type) {
        margin-left: -1px;
      }

      &:not(:first-of-type) input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &:not(:last-of-type) input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}

.linked-level-box {
  .operation-input {
    margin-left: 1em;
    margin-right: 0;
  }
}

.outside-interval-warning {
  @include font-small-size;
  padding: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.team {
  margin: 1.25em 0;
}
</style>
