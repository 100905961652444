<template>
  <div>
    <EmployeesTableContainer
      class="review-employees-container"
      name="reviewEmployees"
      :employees="selectedEmployees"
      :limit="10"
      :preservedProps="['managerId']"
      :hideWhenEmpty="true">
      <template v-slot:title>
        <span>Participants</span>
        <span class="badge small-badge border-badge">
          {{selectedEmployees.length}}
        </span>
      </template>
      <template v-slot:company>
        <menu>
          <button
            class="primary"
            @click="isEmployeePickerDisplayed = true"
            :disabled="isDisabled"
            v-t="'benefits.group.update'" />
        </menu>
      </template>
      <template v-slot:group>
        <div class="employee-action employee-action-header">
          <div
            class="step"
            v-for="(step, i) in getVisibleSteps(review.steps)"
            :key="step.id">
            <span class="light-text">{{i + 1}}.</span>&nbsp;
            {{step.shortName}}
          </div>
        </div>
      </template>
      <template v-slot:action="slotProps">
        <div class="employee-action">
          <div
            class="step"
            :class="step.kind"
            v-for="step in getVisibleSteps(review.steps)"
            :key="step.id">
            <template v-if="step.kind === 'employee'">
              {{getField(slotProps.employee, 'fullName')}}
            </template>
            <template v-else-if="step.kind === 'manager'">
              <EmployeeField
                v-if="!isDisabled"
                :field="getFieldById('managerId', $store)"
                :showLabel="false"
                :placeholder="$t('common.select.placeholder')"
                v-model="slotProps.employee.managerId"
                @input="updateEmployee(slotProps.employee, 'managerId')" />
                <span v-else>{{getField(slotProps.employee, 'manager', {store: $store})}}</span>
            </template>
            <template v-else-if="step.kind === 'synthesis'">
              <div>{{getField(slotProps.employee, 'fullName')}}</div>
              <div v-if="slotProps.employee.managerId"><span class="light-text">&amp;</span>&nbsp;{{getField(slotProps.employee, 'manager', {store: $store})}}</div>
            </template>
          </div>
        </div>
      </template>
    </EmployeesTableContainer>
    <menu v-if="!selectedEmployees.length" class="hero placeholder no-margin-bottom">
      <p class="no-margin-top">Indiquez quels salariés participent à cette campagne.</p>
      <button
        class="primary"
        @click="isEmployeePickerDisplayed = true"
        v-t="'benefits.group.addEmployees'" />
    </menu>
    <Modal :visible="isEmployeePickerDisplayed" @close="isEmployeePickerDisplayed = false">
      <div class="employees-picker-modal">
        <EmployeesPicker v-model="selectedEmployees" @select="updateEmployees" />
      </div>
    </Modal>

  </div>
</template>

<script>
import { getFieldById, getField } from '@/utils/employee'
import EmployeesPicker from '@components/commons/EmployeesPicker.vue'
import EmployeesTableContainer from '@components/employees/EmployeesTableContainer.vue'
import EmployeeField from '@components/employees/EmployeeField.vue'
import Modal from '@components/commons/Modal'
import { isReviewSettingsDisabled, getVisibleSteps } from '@/utils/reviews'

export default {
  components: {
    EmployeesPicker,
    EmployeesTableContainer,
    EmployeeField,
    Modal
  },
  props: {
    review: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      isEmployeePickerDisplayed: false,
      isSaving: false
    }
  },
  computed: {
    isDisabled() {
      return isReviewSettingsDisabled(this.review) || this.isSaving
    },
    selectedEmployees() {
      return this.review.employees
    }
  },
  methods: {
    getField,
    getFieldById,
    getVisibleSteps,
    async updateEmployees(employees) {
      const reviewId = this.review.id
      const employeeIdsToAdd = employees.filter(e => e.isSelected).map(e => e.id)
      const employeeIdsToRemove = employees.filter(e => !e.isSelected).map(e => e.id)

      try {
        this.isSaving = true
        await this.$store.dispatch('reviews/setEmployees', { reviewId, employeeIdsToAdd, employeeIdsToRemove })
        this.isSaving = false
        this.$emit('change')
      }
      catch (errorMessage) {
        errorMessage && alert(errorMessage)
      }
    },
    async updateEmployee(employee, field) {
      const model = { reviewId: this.review.id, userId: employee.id, [field]: employee[field] }
      await this.$store.dispatch('reviews/updateEmployee', model)
      // TODO: Optimize
      this.$emit('change')
    }

  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/badge.scss";
@import "./src/styles/button.scss";

.badge {
  margin-left: 0.8em;
}

.hero.placeholder {
  @extend .badge-block;
  display: block;
  margin: 20px 0;
  padding: 1em 1em;
}

.employees-picker-modal {
  width: 650px;
  padding: 2em 1em 1em 1em;
}

.review-employees-container::v-deep {
  margin-top: 20px;

  .column-action-slot {
    white-space: normal;
    padding-left: 0;
    text-align: left;
  }

  &.resizable-container,
  .resizable-content {
    overflow: visible;
  }
}

.employee-action {
  width: 700px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2em;
  align-items: center;

  .employee-action-header {
    @include font-tiny-size;
    @include font-bold;
    text-transform: uppercase;
    white-space: nowrap;
  }

  .step {
    &.synthesis {
      @include font-small-size;
      @include line-regular-height;
      margin: -3px 0;
    }
  }
}
</style>
