import store from '@/store'
import i18n from '@/i18n'
import api from '@/store/api'
import { computeOrgChart } from '@/utils/org-chart'
import { getField } from '@/utils/employee'
import { formatDate, formatDiff, fullDiffRaw } from '@/utils/date'
import { formatCurrency } from '@/utils/currency'

function getCardField(employee, key) {
  // If available, replace org chart employee by real employee data
  employee = store.getters['employees/getEmployee'](employee.id) || employee

  const [scope, id] = key.split(':')
  switch (scope) {
    case 'employee':
      switch (id) {
        case 'jobTitle':
          return getField(employee, 'jobTitle', { store }) || getField(employee, 'jobType', { store }) || i18n.t('orgChart.fields.role.default')
        case 'arrivalDate':
          const arrivalDate = getField(employee, id, { store })
          return arrivalDate ? formatDate(arrivalDate) : null
        case 'salary':
        case 'contractualVariable':
          const salary = getField(employee, id, { store })
          return salary ? formatCurrency(salary) : ''
        case 'latestSalaryRaise':
          const currentWage = employee.currentWage
          if (currentWage) {
            // TODO: Fetch actual salary raise date (by loading newsfeed)
            const seniority = fullDiffRaw(currentWage.startDate, new Date())
            return formatDiff(seniority, 1).replace('1 ans', '1 an')
          }
          else {
            return ''
          }
        default:
          return getField(employee, id, { store })
      }
    case 'wage':
      const referenceWageDetails = getField(employee, 'referenceWageDetails', { store })
      const referenceComponent = referenceWageDetails && referenceWageDetails.selectedComponents && referenceWageDetails.selectedComponents.find(c => c.id === id)
      if (referenceComponent && referenceComponent.selectedLevel) {
        return referenceComponent.selectedLevel.name
      }
      else {
        return i18n.t('grid.offGrid')
      }
    case 'avatar':
      return id
    case 'none':
    default:
      return ''
  }
}

function buildNode(options) {
  return employee => {
    return {
      id: employee.id,
      role: getCardField(employee, options.role),
      name: getCardField(employee, options.name),
      info: getCardField(employee, options.info),
      initials: getField(employee, 'initials'),
      avatar: getField(employee, 'avatar', { store }),
      avatarOption: getCardField(employee, options.avatar),
      fullDepartment: getCardField(employee, options.group),
      content: employee,
      children: [],
      meta: {}
    }
  }
}

const initialState = () => ({
  employees: [],
  options: {}
})

const state = initialState()

const getters = {
  getEmployees(state) {
    return state.employees
  },
  getOptions(state) {
    return state.options
  },
  getOrgChart(_state, { getEmployees, getOptions }, _rootState, _rootGetters) {
    // Options are init in OrgChartOptions's created()
    const isOptionsAvailable = !!Object.keys(getOptions).length
    return isOptionsAvailable ? computeOrgChart(getEmployees, buildNode(getOptions)) : []
  },
  orgChartError(_, { getOrgChart, getEmployees }) {
    return getEmployees.length && getOrgChart.error
  }
}

const actions = {
  reset({ commit }) {
    commit('reset')
  },
  getEmployees(context) {
    return api.get('/org_chart/employees')
      .then(({ data }) => {
        return context.commit('setEmployees', data.employees)
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  setOptions(context, options) {
    context.commit('setOptions', options)
  },
  setEmployee(context, employee) {
    context.commit('setEmployee', employee)
  }
}

const mutations = {
  reset(state) {
    Object.assign(state, initialState())
  },
  setEmployees(state, employees) {
    state.employees = employees
  },
  setEmployee(state, employee) {
    state.employees = state.employees.filter(e => e.id !== employee.id).concat([employee])
  },
  setOptions(state, options) {
    state.options = { ...options }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
