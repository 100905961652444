<template>
  <div>
    <div class="container" v-if="!isWelcome && synthesis">
      <DashboardSummary />
      <GraphHeader />
      <div v-if="employees.length" class="reports">
        <div class="report-container">
          <RouterView></RouterView>
        </div>
        <hr>
        <div class="plans-container" v-if="$$isAtLeastManager">
          <WagePlans v-if="$$hasPermission('wagePlans')" />
          <HiringPlans v-if="$$hasPermission('hiringPlans')" />
        </div>
      </div>
    </div>
    <welcome-view v-if="isWelcome" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DashboardSummary from '@components/dashboard/DashboardSummary.vue'
import WagePlans from '@components/wagePlans/WagePlans.vue'
import HiringPlans from '@components/hiringPlans/HiringPlans.vue'
import GraphHeader from '@/components/graph/GraphHeader.vue'
import WelcomeView from '@/views/dashboard/WelcomeView.vue'

export default {
  components: {
    DashboardSummary,
    GraphHeader,
    HiringPlans,
    WagePlans,
    WelcomeView
  },
  computed: {
    ...mapGetters({
      company: 'account/getCompany',
      employees: 'employees/getEmployees',
      synthesis: 'statistics/getSynthesis',
      currentReport: 'statistics/getCurrentReport'
    }),
    isWelcome() {
      return this.currentReport.id === 'welcome'
    },
    title() {
      if (this.$$hasOpenAccess) {
        return this.company.name
      }
      else {
        return this.$t('employees.summary.yourTeam')
      }
    }
  },
  methods: {
    routeUpdated() {
      // Redirect to current report
      if (!this.$route.params.id) {
        const { id } = this.currentReport
        this.$router.replace({ name: 'dashboard', params: { id } })
      }
    }
  },
  watch: {
    $route: 'routeUpdated'
  },
  mounted() {
    this.routeUpdated()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/alert.scss";
@import "./src/styles/button.scss";
@import "./src/styles/link.scss";
@import "./src/styles/sidebar.scss";

.container {
  @include container;
  margin-bottom: 1.5em;
}

.alert::v-deep .emoji {
  float: left;
  font-size: 2.2em;
  line-height: 42px;
  margin: 0 0.5em 1em 0.3em;
}

menu.hero {
  margin: -0.25em 0 0.4em;
}

h1 {
  margin-top: 0;
}

hr {
  display: block;
  border: 0;
  border-top: $border;
  height: 1px;
  margin: 25px 32% 40px;
}

.plans-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.5em;
  margin-top: 2em;
}
</style>
