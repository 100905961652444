<template>
  <div class="progress-bar">
    <div class="progress" v-if="!minimal">
      <div class="bar" :style="`width: ${percentValue}%`">
      </div>
    </div>
    <label v-if="percentValue < 100 || !showCheckmark" :class="{ongoing: percentValue > 0}">
      {{percentLabel}}
    </label>
    <label v-else class="fade-in completed">
    </label>
  </div>
</template>

<script>
export default {
  props: {
    percent: Object,
    minimal: Boolean,
    showCheckmark: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    percentValue() {
      return this.percent.percent
    },
    percentLabel() {
      return `${this.percent.count} / ${this.percent.total}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";

$progress-bar-color: lighten($graph-darkblue-color, 18);

.progress-bar {
  display: inline-block;
  @include font-normal-size;
  @include font-regular;
  margin-left: 0.5em;
  white-space: nowrap;
}

.progress {
  display: inline-block;
  width: 50px;
  height: 16px;
  border: 1px solid $progress-bar-color;
  border-radius: 16px;
  vertical-align: -3px;
  margin-left: 0.5em;
  margin-right: 0.5em;
  padding: 2px;
  background: rgba(255, 255, 255, 0.2);
}

.bar {
  min-width: 20%;
  display: block;
  height: 10px;
  background: $progress-bar-color;
  border-radius: 12px;
  transition: width 400ms ease-out;
}

label {
  @include font-small-size;
  cursor: inherit;
  display: inline-block;
  min-width: 35px;
  text-align: center;
  position: relative;

  &.ongoing {
    @include font-semibold;
    color: $graph-darkblue-color;
  }

  &.completed:after {
    display: inline-block;
    content: '';
    position: absolute;
    left: 11px;
    top: -15px;
    background: transparent;
    width: 6px;
    height: 10px;
    border-bottom: 4px solid $progress-bar-color;
    border-right: 4px solid $progress-bar-color;
    border-radius: 1px;
    transform: rotate(45deg);
  }
}
</style>
