<template>
  <div>
    <InlineWageDiff
      v-if="employee.simulationWageDetails"
      class="inline-wage"
      :wageDetails="employee.simulationWageDetails"
      :referenceWageDetails="employee.wageDetails" />
    <InlineWage
      v-else
      class="inline-wage"
      :wageDetails="employee.wageDetails"
      :showSalary="false"
      :showSalaryBadges="showSalary"
      :showRoleComponent="false"
      :shouldHighlightHiddenLevel="true" />
  </div>
</template>

<script>
import InlineWage from '@components/wage/InlineWage.vue'
import InlineWageDiff from '@components/wage/InlineWageDiff.vue'

export default {
  components: {
    InlineWage,
    InlineWageDiff
  },
  props: {
    // Employee might contain a simulationWageDetails for InlineWageDiff
    employee: {
      type: Object
    },
    showSalary: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
.inline-wage {
  display: block;
  border-image: none;
  border-bottom: none;
  @include font-small-size;
  line-height: 1.3em;
  min-height: 39px;
  padding: 0;
  margin-bottom: -7px;

  &::v-deep {
    label {
      @include font-smaller-size;
    }

    .component {
      background: $graph-lightblue-color;
      margin: 0;
      padding: 0.2em 0.6em;
      border-radius: $border-radius;
      margin-bottom: 7px;
    }

    .component:not(:last-of-type) {
      margin-right: 7px;
    }

    .badge,
    .badge.delta-badge {
      @include font-normal-size;
      border: none;
      float: right;
      margin: 7px 0 0 0;
    }
  }
}
</style>
