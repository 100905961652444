import { render, staticRenderFns } from "./GridFormula.vue?vue&type=template&id=31050fce&scoped=true&"
import script from "./GridFormula.vue?vue&type=script&lang=js&"
export * from "./GridFormula.vue?vue&type=script&lang=js&"
import style0 from "./GridFormula.vue?vue&type=style&index=0&id=31050fce&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31050fce",
  null
  
)

export default component.exports