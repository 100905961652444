<template>
  <div class="review-wage-editor">
    <h3 @click="debugWage">{{step.name}}</h3>
    <EmployeeSmallDetail :employee="employee" />
    <div v-if="!isLoading" class="review-wage">
      <h3 @click="debugWage">{{roleLevel.name}}</h3>
      <div
        class="review-level"
        v-for="level in levels"
        :key="level.id">
        <div class="review-level-header">
          <Checkbox
            class="review-level-name"
            :checked="isLevelReached(level)"
            :disabled="isDisabled"
            @click.native="onLevel($event, level)">
            {{level.name}}
          </Checkbox>
          <div class="review-level-criteria">
            <Tooltip class="gray-icon" :message="levelCriteriaAnnotations[level.id]" />
          </div>
          <div class="review-level-value">{{ getFormattedLevelValue(level) }}</div>
          <div class="review-level-delta-labels">
            <span
              v-if="referenceLevel && selectedLevelModel.name != referenceLevel.name && level.name === referenceLevel.name">
              Ancien palier
            </span>
            <span
              v-if="referenceLevel && selectedLevelModel.name != referenceLevel.name && level.name === selectedLevelModel.name"
              class="new"
              v-t="'wageCalculator.newLevelLong'">
            </span>
          </div>
        </div>
        <div>
          <MarkdownText class="review-level-description small" v-model="level.description" />
          <div
            class="table-container review-domain">
            <table class="blue no-bg">
              <template
                v-for="(domain, i) in level.domains.filter(d => d.skills.filter(s => s.name).length)">
                <tr
                  class="row"
                  :key="domain.id">
                  <th
                    class="review-step"
                    :class="{behind: !!i}"
                    v-for="referenceStep in referenceSteps"
                    :key="referenceStep.id">
                    <template v-if="!i">
                      {{referenceStep.shortName}}
                    </template>
                  </th>
                  <th class="review-step review-current-step" :class="{behind: !!i}">
                    <template v-if="!i">
                      {{step.shortName}}
                    </template>
                  </th>
                  <th>
                    {{domain.name}}
                    <Tooltip class="gray-icon" :message="domain.description" />
                  </th>
                  <th :class="{behind: !!i}">
                    <template v-if="!i">Commentaires</template>
                  </th>
                </tr>
                <tr class="row"
                  v-for="skill in domain.skills"
                  :key="skill.id">
                  <td
                    class="review-step "
                    v-for="referenceWage in referenceWages"
                    :key="referenceWage.id">
                    <CheckableSkill
                      class="review-check"
                      :skill="referenceWagesCheckableSkillModel[referenceWage.id].skills[`${skill.name}-${skill.index}`] || {id: 0, name: ''}"
                      :selectedSkills="referenceWagesCheckableSkillModel[referenceWage.id].selectedSkills"
                      :selectedSkillValues="referenceWagesCheckableSkillModel[referenceWage.id].selectedSkillValues"
                      :disabled="true"
                      :hasSkillValues="true"
                      :showName="false" />
                  </td>
                  <td class="review-step review-current-step">
                    <CheckableSkill
                      class="review-check"
                      :skill="skill"
                      :selectedSkills="selectedSkillsModel"
                      :selectedSkillValues="selectedSkillValuesModel"
                      @selectIsChecked="onSkill"
                      :disabled="isDisabled"
                      :hasSkillValues="hasSkillValues"
                      :showName="false" />
                  </td>
                  <td class="review-skill">
                    {{skill.name}}
                  </td>
                  <td class="review-comment" @click="selectTextarea">
                    <AutosizeTextarea
                      class="review-comment-textarea"
                      data-merciapp-spellcheck="false"
                      type="text"
                      :value="reviewModel.skillComments[skill.id]"
                      :disabled="isDisabled"
                      placeholder="…"
                      @input='value => onComment(skill, value)' />
                  </td>
                </tr>
              </template>
            </table>
          </div>
        </div>
      </div>
      <br>
      <div class="input-label">
        <span>Commentaires</span>
        <span class="light-text" v-t="'common.optional'"></span>
      </div>
      <AutosizeTextarea
        class="review-comment"
        data-merciapp-spellcheck="false"
        v-model="reviewModel.comment"
        :disabled="isDisabled"
        placeholder="…"
        @input='submitDebounced()' />
    </div>
    <LoadingView v-if="isLoading" />
    <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>
    <menu class="review-menu">
      <span v-if="!isDisabled" class="auto-save light-text small-size" v-html="$t(updatedAt ? 'grid.onboarding.common.updatedAt' : 'variable.form.autoSave', {updatedAt})"></span>
      <button
        v-if="reviewModel.status && !isDisabled"
        class="secondary reset-button fade-in"
        @click="reset">Recommencer</button>
      <button
        class="secondary"
        @click="close()">Fermer</button>
      <loading-button
        v-if="!isDisabled"
        class="primary"
        :disabled="isPublishing"
        @click="publish"
        :loading="isPublishing">
        Publier l’évaluation
      </loading-button>
    </menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getVisibleLinkedLevels, getWageDetails } from '@/utils/grid'
import AutosizeTextarea from '@components/commons/AutosizeTextarea.vue'
import EmployeeSmallDetail from '@components/employees/EmployeeSmallDetail.vue'
import CheckableSkill from '@components/commons/CheckableSkill.vue'
import Checkbox from '@components/commons/Checkbox.vue'
import MarkdownText from '@components/commons/MarkdownText.vue'
import LoadingView from '@components/commons/LoadingView'
import Tooltip from '@components/commons/Tooltip.vue'
import { getFlatComponent, getSkillsFromLevels } from '@/utils/skills'
import { hasStepUpdatePermission, REVIEW_STEP, WAGE_REVIEW_STATUS } from '@/utils/reviews'
import debounce from 'lodash.debounce'
import partition from 'lodash.partition'

export default {
  components: {
    AutosizeTextarea,
    CheckableSkill,
    Checkbox,
    EmployeeSmallDetail,
    LoadingView,
    MarkdownText,
    Tooltip
  },
  props: {
    payload: Object
  },
  data() {
    return {
      selectedSkillsModel: [],
      selectedSkillValuesModel: {},
      selectedLevelModel: null,
      reviewModel: {},
      levelCriteriaAnnotations: {},
      errorMessage: null,
      isPublishing: false,
      isDebug: false,
      grid: null,
      updatedAt: null
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'account/getUser'
    }),
    isDisabled() {
      return !hasStepUpdatePermission(this.step, this.employee, this.getUser, this.payload.review)
    },
    employee() {
      return this.payload.employee
    },
    manager() {
      return this.payload.employee.manager
    },
    referenceSteps() {
      return this.payload.referenceSteps
    },
    referenceWages() {
      return this.payload.referenceWages
    },
    referenceWagesDetails() {
      return this.referenceWages.map(wage => getWageDetails(this.grid, wage, { includeComponents: true, includeSelectedSkills: true }))
    },
    referenceWagesCheckableSkillModel() {
      return this.referenceWages.reduce((memo, wage, i) => {
        const wageDetails = this.referenceWagesDetails[i]
        const referenceComponent = wageDetails.selectedComponents.find(c => c.ref === 'experience')
        const referenceComponentSkills = getSkillsFromLevels(referenceComponent.levels)
        const skills = referenceComponentSkills.reduce((memo, skill) => {
          memo[`${skill.name}-${skill.index}`] = skill
          return memo
        }, {})
        memo[wage.id] = {
          skills: skills,
          selectedSkills: referenceComponent.selectedSkills,
          selectedSkillValues: referenceComponent.selectedSkillValues
        }
        return memo
      }, {})
    },
    referenceLevel() {
      const experienceComponent = this.referenceWagesDetails[0].selectedComponents.find(c => c.ref === 'experience')
      return experienceComponent && experienceComponent.selectedLevel
    },
    wage() {
      return this.payload.wage
    },
    wageDetails() {
      return getWageDetails(this.grid, this.wage, { includeComponents: true, includeSelectedSkills: true })
    },
    roleComponent() {
      return this.wageDetails.selectedComponents.find(c => c.ref === 'role')
    },
    component() {
      return this.wageDetails.selectedComponents.find(c => c.ref === 'experience')
    },
    flatComponent() {
      return getFlatComponent(this.component)
    },
    config() {
      return this.component.config
    },
    roleLevel() {
      return this.roleComponent.selectedLevel
    },
    levels() {
      return getVisibleLinkedLevels(this.component.levels, this.roleLevel)
    },
    step() {
      return this.payload.step
    },
    hasSkillValues() {
      return this.component.hasSkillValues
    }
  },
  methods: {
    async initModel() {
      this.isLoading = true
      this.grid = await this.$store.dispatch('currentGrid/getGrid', this.wage.gridId)
      this.reviewModel = { ...this.wage.review }
      this.initSkillComments()
      this.selectedSkillsModel = [...this.component.selectedSkills]
      this.selectedSkillValuesModel = { ...this.component.selectedSkillValues }
      this.selectedLevelModel = this.component.selectedLevel || {}
      this.applyLevelCriteria(this.config.levelCriteria, false)
      this.isLoading = false
      this.submitDebounced = debounce(function() {
        this.submit()
      }, 800)
    },
    initSkillComments() {
      this.reviewModel.skillComments = this.reviewModel.skillComments || {}
      // Merge skill comments from employee and manager into synthesis step
      if (this.step.kind === REVIEW_STEP.synthesis && !this.reviewModel.status) {
        // Merge skill comments
        const employeeStep = this.referenceSteps.find(s => s.kind === REVIEW_STEP.employee)
        const employeeWage = employeeStep && this.referenceWages.find(w => w.reviewStepId === employeeStep.id)
        const employeeSkillComments = (employeeWage && employeeWage.review && employeeWage.review.skillComments) || {}
        const managerStep = this.referenceSteps.find(s => s.kind === REVIEW_STEP.manager)
        const managerWage = managerStep && this.referenceWages.find(w => w.reviewStepId === managerStep.id)
        const managerSkillComments = (managerWage && managerWage.review && managerWage.review.skillComments) || {}
        const skills = getSkillsFromLevels(this.component.levels)
        for (const skill of skills) {
          if (employeeSkillComments[skill.id]) {
            this.reviewModel.skillComments[skill.id] = `${this.employee.firstName} > ${employeeSkillComments[skill.id].trim()}`
          }
          if (managerSkillComments[skill.id]) {
            this.reviewModel.skillComments[skill.id] = this.reviewModel.skillComments[skill.id] ? this.reviewModel.skillComments[skill.id] + '\n' : ''
            this.reviewModel.skillComments[skill.id] += `${this.manager.firstName} > ${managerSkillComments[skill.id].trim()}`
          }
        }

        // Merge review comment
        const employeeComment = (employeeWage && employeeWage.review && employeeWage.review.comment)
        const managerComment = (managerWage && managerWage.review && managerWage.review.comment)
        if (employeeComment) {
          this.reviewModel.comment = `${this.employee.firstName} > ${employeeComment.trim()}`
        }
        if (managerComment) {
          this.reviewModel.comment = this.reviewModel.comment ? this.reviewModel.comment + '\n' : ''
          this.reviewModel.comment += `${this.manager.firstName} > ${managerComment.trim()}`
        }
      }
    },
    isLevelReached(level) {
      return level.rank <= this.selectedLevelModel.rank
    },
    getFormattedLevelValue(level) {
      const { wageDetails } = this
      return this.$options.filters.formattedLevelValue(this.component, level, this.roleComponent, this.roleComponent && this.roleComponent.selectedLevel, 'qualification', { wageDetails })
    },
    selectTextarea(event) {
      if (event.target && event.target.querySelector('.review-comment-textarea')) {
        event.target.querySelector('.review-comment-textarea').focus()
      }
    },
    close(successMessage) {
      this.$emit('close', successMessage)
    },
    getLevelSkillsProgress(level, selectedSkills, defaultValue = 100) {
      if (!level) {
        return defaultValue
      }
      const skillIds = level.skills.map(s => s.id)
      const [selected, unselected] = partition(skillIds, id => selectedSkills.includes(id))
      const count = selected.length
      const total = count + unselected.length
      const percent = Math.round(count / total * 100)
      return percent
    },
    applyLevelCriteria(levelCriteria, applySelectedLevel = false) {
      switch (levelCriteria) {
        case 'contexte':
          const isRedacCriteria = ['Rédaction', 'Direction'].includes(this.roleLevel.group)
          const skills = this.selectedSkillsModel
          const levels = this.flatComponent.levels
          let selectedLevel = this.selectedLevelModel
          levels.forEach(level => {
            const i = level.rank
            let annotation = 'Critères d’éligibilité du palier :\n\n'
            if (i === 0) {
              annotation +=
                `Le palier ${level.name} est atteint automatiquement ✅`
              selectedLevel = level
            }
            else {
              if (isRedacCriteria) {
                // Critère 1
                const previousPreviousLevelPercent = this.getLevelSkillsProgress(levels[i - 2], skills)
                const criterion1 = previousPreviousLevelPercent === 100
                annotation +=
                  `${criterion1 ? '✅' : '❌'} (Palier-2) ${previousPreviousLevelPercent}% = 100% des compétences\n`
                // Critère 2
                const previousLevelPercent = this.getLevelSkillsProgress(levels[i - 1], skills)
                const criterion2 = previousLevelPercent > 80
                annotation +=
                  `${criterion2 ? '✅' : '❌'} (Palier-1) ${previousLevelPercent}% > 80% des compétences\n`
                // Critère 3
                const levelPercent = this.getLevelSkillsProgress(levels[i], skills)
                const nextLevelPercent = this.getLevelSkillsProgress(levels[i + 1], skills, 0)
                const sumPercent = previousLevelPercent + levelPercent + nextLevelPercent
                const criterion3 = sumPercent > 100
                annotation +=
                  `${criterion3 ? '✅' : '❌'} (Palier-1) ${previousLevelPercent}% + Palier ${levelPercent}% + (Palier+1) ${nextLevelPercent}% > 100%\n`
                // Synthèse
                const isReached = criterion1 + criterion2 + criterion3 === 3
                annotation +=
                  isReached ? `\nLe palier ${level.name} est éligible ✅` : `\nLe palier ${level.name} n’est pas encore éligible ❌`
                if (isReached) {
                  selectedLevel = level
                }
              }
              else {
                const previousLevelPercent = this.getLevelSkillsProgress(levels[i - 1], skills)
                const criterion1 = previousLevelPercent === 100
                annotation +=
                  `${criterion1 ? '✅' : '❌'} 100% des compétences du palier précédent ${!criterion1 ? '(' + previousLevelPercent + '%)' : ''}\n`
                // Critère 1
                const isReached = criterion1
                annotation +=
                  isReached ? `\nLe palier ${level.name} est éligible ✅` : `\nLe palier ${level.name} n’est pas encore éligible ❌`
                if (isReached) {
                  selectedLevel = level
                }
              }
            }
            this.levelCriteriaAnnotations[level.id] = annotation
            console.log(level.name, '   |   ', annotation)
          })
          console.log('Eligible:', selectedLevel.name, '\nReference:', this.referenceLevel && this.referenceLevel.name)
          if (applySelectedLevel) {
            if (!this.referenceLevel || (selectedLevel.rank >= this.referenceLevel.rank)) {
              this.selectedLevelModel = selectedLevel
              console.log('Eligible level has been selected applied ✅')
            }
            else {
              console.log('Eligible level has been ignored ❌ because ', selectedLevel.name, '<', this.referenceLevel && this.referenceLevel.name)
            }
          }
          break
      }
    },
    onComment(skill, value) {
      if (value) {
        this.reviewModel.skillComments[skill.id] = value
      }
      else {
        delete this.reviewModel.skillComments[skill.id]
      }
      this.submitDebounced()
    },
    onLevel(event, level) {
      event.preventDefault()
      if (this.isDisabled) {
        return
      }
      // TODO: handle !hasManualLevels
      if (this.config.hasManualLevels) {
        this.selectedLevelModel = this.component.levels.find(l => l.id === level.id)
      }
      this.submitDebounced()
    },
    onSkill({ id, isChecked, skillValue }) {
      if (isChecked && !this.selectedSkillsModel.includes(id)) {
        this.selectedSkillsModel.push(id)
      }
      else if (!isChecked && this.selectedSkillsModel.includes(id)) {
        this.selectedSkillsModel.splice(this.selectedSkillsModel.indexOf(id), 1)
      }
      if (skillValue !== undefined) {
        this.selectedSkillValuesModel[id] = skillValue
      }
      if (this.config.hasManualLevels && this.config.levelCriteria) {
        this.applyLevelCriteria(this.config.levelCriteria, true)
      }
      this.submitDebounced()
    },
    async publish() {
      this.isPublishing = true
      this.reviewModel.status = WAGE_REVIEW_STATUS.done
      await this.submit()
      const successMessage = this.step.kind === REVIEW_STEP.employee ? '✅  C’est tout bon, votre auto-évaluation a bien été publiée !' : `✅ L’évaluation de ${this.employee.fullName} a bien été publiée !`
      this.close(successMessage)
    },
    async submit() {
      this.updatedAt = this.$options.filters.formatCalendar(new Date())
      if (!this.reviewModel.status) {
        this.reviewModel.status = WAGE_REVIEW_STATUS.ongoing
      }
      const reviewId = this.payload.review.id
      const wage = {
        ...this.wage,
        levelIds: [],
        skillIds: [],
        skillValues: {},
        review: this.reviewModel
      }

      this.wageDetails.selectedComponents.forEach(component => {
        if (component.id === this.component.id) {
          wage.levelIds = [...wage.levelIds, this.selectedLevelModel.id]
          wage.skillIds = [...wage.skillIds, ...this.selectedSkillsModel]
          wage.skillValues = { ...wage.skillValues, ...this.selectedSkillValuesModel }
        }
        else {
          wage.levelIds = [...wage.levelIds, component.selectedLevel.id]
          wage.skillIds = [...wage.skillIds, ...(component.selectedSkills || [])]
          wage.skillValues = { ...wage.skillValues, ...(component.selectedSkillValues || {}) }
        }
      })

      const updatedWage = await this.$store.dispatch('reviews/updateWage', { reviewId, wage })
      this.$emit('change', updatedWage)
      return true
    },
    flushSubmitDebounced() {
      this.submitDebounced && this.submitDebounced.flush && this.submitDebounced.flush()
    },
    async reset() {
      if (window.confirm('Les compétences sélectionnées seront remises à zéro, à partir de la précédente évaluation saisie dans Clearwage.\n\nSouhaitez-vous continuer ?')) {
        const reviewId = this.payload.review.id
        const oldWage = this.wage
        const newWage = await this.$store.dispatch('reviews/resetWage', { reviewId, wage: oldWage })
        this.$emit('delete', { oldWage, newWage })
        await this.initModel()
      }
    },
    debugWage(event) {
      if (event.altKey) {
        this.isDebug = !this.isDebug
      }
    }
  },
  created() {
    this.initModel()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/button.scss";
@import "./src/styles/table.scss";

h3 {
  margin-bottom: 12px;
}

.review-wage-editor {
  padding: 15px 20px 0 20px;
  width: 96vw;
  height: 95vh;
}

.wage-viewer {
  width: auto;
  padding: 0;
}

.reset-button {
  margin-right: 2em;
}

.review-level {
  .review-level-header {
    display: grid;
    grid-template-columns: auto auto auto 1fr;
    gap: 1em;
    align-items: end;
    padding: 10px;
    margin: -10px;
    position: sticky;
    top: -15px;
    background: white;
    z-index: 2;

    .review-level-name {
      @include font-semibold;
      @include font-big-size;

      &::v-deep input[type="checkbox"]:checked + label {
        color: $graph-darkblue-color;
      }
    }

    .review-level-criteria {
      margin-left: -7px;
    }

    .review-level-delta-labels {
      @include font-smaller-size;
      @include font-bold;
      text-transform: uppercase;
      color: $light-text-color;
      margin-bottom: 2px;

      .new {
        color: lighten($graph-darkblue-color, 10);
      }
    }

    .review-level-value:not(:empty) {
      @include font-semibold;
      @include font-tabular-numbers;
      color: $text-color;
      margin-bottom: 1px;
    }
  }
}

$hover-color: lighten($graph-lightblue-color, 1);

.review-domain.table-container {
  margin-top: 10px;
  border: 1px solid $graph-outer-border-color;
  border-radius: $border-radius;
  box-shadow: 1px 1.5px 0 rgba(0, 0, 0, 0.03);
  margin-bottom: 25px;

  table {
    @include font-small-size;

    tr.row {
      vertical-align: baseline;

      &:hover,
      &:focus-within {
        background: $hover-color;

        textarea {
          background: $hover-color !important;
        }
      }
    }

    th,
    td {
      @include line-regular-height;
      padding: 0.25em 0.5em;
      border: 1px solid $graph-outer-border-color;
      @include font-tabular-numbers;

      &.review-step {
        width: 130px;
        text-align: center;
        padding: 0;
        vertical-align: middle;
        user-select: none;

        &.review-current-step {
          color: $graph-darkblue-color;
        }

        .review-check {
          justify-content: center;
          padding: 0 3px 0 0;
          gap: 3px;

          &::v-deep select {
            &:not(:disabled) {
              background: white;
            }

            &:disabled {
              border: none;
              appearance: none;
            }
          }
        }
      }

      &.review-comment {
        width: 30%;
        padding: 0;

        textarea {
          margin: 0;
          padding: 3px 10px;
          border: none;
          line-height: 1.4em;

          &:focus {
            background: $hover-color !important;
            box-shadow: inset 3px 0 0 lighten($graph-darkblue-color, 22) !important;
          }
        }
      }
    }

    th {
      @include font-medium;
      font-weight: 450;
      position: sticky;
      top: 28px;
      z-index: 1;
      border: none;

      &.behind {
        z-index: 0;
        border-right: none;

        & + .behind {
          border-left: none;
        }
      }
    }
  }
}

.review-menu {
  position: sticky;
  bottom: 0;
  z-index: 4;
  border-top: 1px solid $graph-outer-border-color;
  background: white;
  padding: 12px 20px;
  margin: 0 -20px;
  overflow: hidden;
  box-shadow: 0 -3px 3px #ccdae431;

  .auto-save {
    margin-right: 1.5rem;
  }
}

.review-comment {
  margin: 0.75em 0 3.5em;
  border: 1px solid $graph-outer-border-color;
  border-radius: $border-radius;
  box-shadow: 1px 1.5px 0 rgba(0, 0, 0, 0.03);
}
</style>
