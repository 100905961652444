<template>
  <div class="top-bar print-hidden">
    <div class="teams-menu-wrapper">
      <div class="navigation-bar">
        <div :style="floorStyle" class="floor" />
        <div class="navigation-section company-section">
          <ul>
            <router-link
              v-if="$$hasPermission('teamsOrgChart')"
              :to="{name: 'teams'}"
              tag="li"
              exact-active-class="selected"
              v-t="'orgChart.title'" />
            <router-link
              v-if="$$hasPermission('teamsDirectory')"
              :to="{name: 'directory'}"
              tag="li"
              active-class="selected"
              v-t="'teams.directory.title'" />
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navigationElements: [],
      floorStyle: {}
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: function(newValue, _oldValue) {
        this.$nextTick(() => this.positionFloor())
      }
    }
  },
  mounted() {
    this.navigationElements = this.$children.map(c => c.$el)
  },
  methods: {
    positionFloor(animated = true) {
      setTimeout(() => {
        const selectedElement = this.navigationElements.find(el => el.classList.contains('selected'))

        if (selectedElement) {
          const navigationSectionElement = selectedElement.parentElement.parentElement
          const transitionType = this.floorStyle.height ? 'all' : 'height'

          this.floorStyle = {
            height: '4px',
            transition: animated ? `${transitionType} 0.25s ease-out` : null,
            transform: `translateX(${selectedElement.offsetLeft + navigationSectionElement.offsetLeft}px)`,
            width: `${selectedElement.clientWidth}px`
          }
        }
        else {
          this.floorStyle = { ...this.floorStyle, height: 0 }
        }
      }, 90)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/teams.scss";

.top-bar {
  border-bottom: $border;
  background-color: white;
}

.navigation-bar {
  position: relative;
  display: flex;
  background: white;
  margin-left: 180px;
  height: $top-bar-height;
}

// Reduce left margin on small screens
@media screen and (max-width: $content-max-width) {
  .navigation-bar {
    margin-left: 53px;
  }
}

.floor {
  position: absolute;
  bottom: 0;
  height: 0;
  background: $graph-text-color;
}

.navigation-section {
  position: relative;
  margin-right: 1em;
  min-width: 150px;
}

ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

li {
  @include font-smaller-size;
  @include font-bold;
  height: $top-bar-height;
  letter-spacing: 1px;
  padding: 0 1em;
  padding-top: 13px;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
  font-feature-settings: "frac" 1;
  transition: background-color 250ms ease;
}

.company-section {
  h4 {
    color: $graph-text-color;
    background: $graph-background-color;
  }

  li:hover:not(.selected) {
    background-color: $graph-background-color;
  }
}
</style>
