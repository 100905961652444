<template>
  <div>
    <EmployeeNewsfeed class="employee-newsfeed" :employee="employee" :selectedRecurrence="selectedRecurrence">
      <template v-slot:menu>
        <router-link
          v-if="$$hasUpdatePermission(employee) && $$hasPermission('employeesSetCurrentWage')"
          :to="{name: 'newEmployeeCurrentWage', params: { id: employee.id}}"
          tag="button"
          class="secondary"
          v-t="`employees.employee.${employee.currentWage ? 'addSalaryRaise' : 'setWage'}`" />
      </template>
      <template v-slot:footer>
        <router-link
          v-if="$$isAdmin && $$hasUpdatePermission(employee)"
          tag="button"
          class="small-button"
          :to="{name: 'newEmployeeWage'}"
          v-t="'employees.employee.newsfeed.addHistory'" />
      </template>
    </EmployeeNewsfeed>
  </div>
</template>

<script>
import EmployeeNewsfeed from '@components/employees/EmployeeNewsfeed.vue'

export default {
  name: 'EmployeeSalaryView',
  components: {
    EmployeeNewsfeed
  },
  props: {
    selectedRecurrence: {
      type: String,
      default: 'yearly'
    }
  },
  computed: {
    employee() {
      if (this.$$isOneselfRoute) {
        return this.$store.getters['employees/getOneself']
      }

      return this.$store.getters['employees/getEmployee'](this.$route.params.id)
    }
  }

}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";

.employee-newsfeed::v-deep {
  h2 {
    margin-top: 0;
  }

  .newsfeed {
    margin: 0 0 3em;
    padding: 0;
    border: none;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
</style>
