<template>
  <table class="payroll-table">
    <tr>
      <th v-t="'grid.editor.currentFemale'"></th>
      <th v-if="isOnboarding" v-t="'grid.editor.postGrid'"></th>
      <th v-else v-t="'grid.editor.newFemale'"></th>
    </tr>
    <tr>
      <td>
        {{ gridSummary.currentSalary | formattedCurrency }}
      </td>
      <td>
        {{ gridSummary.postGridSalaryFixed | formattedCurrency }}
      </td>
    </tr>
    <tr>
      <td colspan="2" class="strong">
          {{ gridSummary.postGridSalaryFixedRise | formattedCurrency(false, {signDisplay: "always"}) }}
          ({{ gridSummary.percent | formattedNumber(2, 2) }}<small>&nbsp;%</small>)
      </td>
    </tr>
  </table>
</template>

<script>
import anime from 'animejs/lib/anime.es.js'
import { mapGetters } from 'vuex'
import { sum } from 'd3'
import debounce from 'lodash.debounce'

export default {
  props: ['simulationLevels', 'simulationWages'],
  data() {
    return {
      gridSummary: {
        currentSalary: 0,
        postGridSalaryFixed: 0,
        postGridSalaryFixedRise: 0,
        percent: 0
      }
    }
  },
  methods: {
    generateGridSummary(options) {
      console.time('generateGridSummary')
      const employees = this.enrichedEmployees(this.simulationLevels, this.simulationWages)
      const currentSalary = sum(employees, e => e.currentSalary)
      const postGridSalaryFixed = sum(employees, e => e.postGridSalaryFixed)
      const postGridSalaryFixedRise = sum(employees, e => e.postGridSalaryFixedRise)
      const percent = currentSalary ? postGridSalaryFixedRise / currentSalary * 100 : 0
      const updatedGridSummary = { currentSalary, postGridSalaryFixed, postGridSalaryFixedRise, percent }
      if (this.gridSummary && this.gridSummary.currentSalary) {
        updatedGridSummary.percent = {
          value: percent,
          round: 100
        }
        anime({
          targets: this.gridSummary,
          round: 1,
          delay: (options && options.delay) || 0,
          duration: 400,
          easing: 'linear',
          ...updatedGridSummary
        })
      }
      else {
        this.gridSummary = updatedGridSummary
      }
      console.timeEnd('generateGridSummary')
    },
    generateGridSummaryDebounced: debounce(function() {
      this.generateGridSummary()
    }, 1000)
  },
  computed: {
    ...mapGetters({
      enrichedEmployees: 'sandbox/enrichedEmployees',
      isOnboarding: 'onboarding/isActive',
      grid: 'sandbox/grid',
      wages: 'sandbox/employeeWages'
    })
  },
  watch: {
    simulationLevels: 'generateGridSummaryDebounced',
    simulationWages: 'generateGridSummaryDebounced',
    grid: 'generateGridSummaryDebounced',
    wages: 'generateGridSummaryDebounced'
  },
  mounted() {
    this.generateGridSummaryDebounced()
  }
}
</script>

<style lang="scss" scoped>
.payroll-table {
  width: 100%;
  border-collapse: collapse;
  border-style: hidden;
  text-align: center;
  line-height: 2em;
  box-shadow: 1px 1.5px 0 rgba(0, 0, 0, 0.03);

  tr:nth-of-type(2) td {
    width: 50%;
  }

  th {
    background: $graph-accentblue-color;
    @include font-medium;
  }

  td {
    background: white;
    border: 1px solid $graph-outer-border-color;
    @include font-tabular-numbers;
  }

  th:first-of-type {
    border-top-left-radius: 4px;
  }

  th:last-of-type {
    border-top-right-radius: 4px;
  }

  tr:last-of-type td:first-of-type {
    border-bottom-left-radius: 4px;
  }

  tr:last-of-type td:last-of-type {
    border-bottom-right-radius: 4px;
  }

  .strong {
    @include font-medium;
    color: $graph-darkblue-color;
  }
}
</style>
