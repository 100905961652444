<template>
  <div class="container">
    <employee-detail :employee="candidate" />
    <div class="wage-calculator">
      <h3 v-if="candidate.wageProposal" v-t="'employees.employee.wage.update'"></h3>
      <h3 v-else v-t="'employees.employee.setWage'"></h3>

      <wage-calculator
        ref="wageProposal"
        v-model="wageProposalModel"
        :grid="currentGrid"
        :referenceWage="candidate.wageProposal"
        :referenceGrid="currentGrid"
        :showDelta="candidate.wageProposal !== null"
        :showVariable="true"
        @input="isUpdated = true">
      </wage-calculator>

      <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>

      <menu>
        <button
          v-if="isUpdated"
          class="secondary"
          @click="resetWageProposal"
          :disabled="isLoading"
          v-t="'common.reset'">
        </button>
        <button
          class="secondary"
          @click="$router.go(-1)"
          v-t="'employees.employee.form.cancel'">
        </button>
        <loading-button
          v-if="$$isAdmin"
          class="primary"
          @click="setWageProposal"
          :loading="isLoading">
          <span v-if="candidate.wageProposal" v-t="'employees.employee.updateWage'"></span>
          <span v-else v-t="'employees.employee.setThisWage'"></span>
        </loading-button>
      </menu>
    </div>
  </div>
</template>

<script>
import EmployeeDetail from '@components/employees/EmployeeDetail.vue'
import WageCalculator from '@components/wage/WageCalculator.vue'

export default {
  data() {
    return {
      wageProposalModel: null,
      isLoading: false,
      isUpdated: false,
      errorMessage: null
    }
  },
  computed: {
    candidate() {
      const candidateId = this.$route.params.id
      return this.$store.getters['candidates/getCandidate'](candidateId)
    },
    currentGrid() {
      return this.$store.getters['currentGrid/getCurrentGrid']
    }
  },
  methods: {
    resetWageProposal() {
      this.$refs.wageProposal.reset()
      this.isUpdated = false
    },
    setWageProposal() {
      const candidate = this.candidate
      const wageProposal = this.wageProposalModel
      this.errorMessage = null
      this.isLoading = true

      this.$store.dispatch('candidates/setWageProposal', { candidate, wageProposal })
        .then(response => {
          this.$router.go(-1)
        })
        .catch(error => {
          this.errorMessage = error
          this.isLoading = false
        })
    }
  },
  created() {
    if (this.candidate) {
      this.$store.commit('employees/setCurrentEmployee', this.candidate)

      if (this.candidate.wageProposal) {
        this.wageProposalModel = { ...this.candidate.wageProposal }
        this.wageProposalModel.explanation = null
      }
      else {
        this.wageProposalModel = { ...this.$store.getters['candidates/getCandidateModel'].wageProposal }
      }
    }
    else {
      this.$router.push({ name: 'employees' })
    }
  },
  components: {
    EmployeeDetail,
    WageCalculator
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/alert.scss";
@import "./src/styles/button.scss";

.container {
  background: $background-color;
}

.wage-calculator {
  @include container;
  margin-top: 1.5em;
}

h2 {
  display: block;
  margin: 0 0 20px 0;
}
</style>
