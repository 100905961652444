<template>
  <div class="container" v-if="employee">
    <employee-detail :employee="employee" />
    <div class="wage-calculator">
      <h3 v-if="employee.currentWage" v-t="'employees.employee.wage.update'"></h3>
      <h3 v-else v-t="'employees.employee.setWage'"></h3>
      <div v-if="isWageQualificationSynchronizationEnabled">
        <p
          class="alert preserve-lines"
          v-html="$t('employees.employee.wage.synchronizedWageQualification', {serviceNames: enabledIdentityProviderNames})"></p>
        <button
          v-for="thirdPartyUser in employee.thirdPartyUsers"
          :key="thirdPartyUser.id"
          class="secondary third-party-button"
          v-t="{
              path: 'employees.employee.thirdPartyUser.openIn',
              args: {serviceName: $t(`settings.settingsCompanySynchronization.identityProviders.${thirdPartyUser.identityProvider}`)}}"
          @click="$$openUrl(thirdPartyUser.profileUrl)"></button>
      </div>
      <p
        v-else-if="isWagePlan"
        class="alert preserve-lines"
        v-t="{path: 'employees.employee.wage.wagePlanReassurance', args: {wagePlanName, firstName: employee.firstName}}">
      </p>
      <div class="top-fields" v-else>
        <div>
          <div class="input-label" v-t="'employees.employee.wage.startDate'" />
          <DateInput
            v-model="currentWageModel.startDate" />
        </div>
      </div>
      <WageCalculator
        ref="currentWage"
        v-model="currentWageModel"
        :grid="currentGrid"
        :referenceWage="employee.currentWage"
        :referenceGrid="currentGrid"
        :showComment="isWagePlan"
        :showDelta="employee.currentWage !== null"
        :showReason="true"
        :showVariable="true"
        @input="isUpdated = true" />
      <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>
      <div v-if="isSynchronizable">
        <div class="input-label" v-t="'employees.synchronization.section'"></div>
        <checkbox v-model="isSynchronizationPlanned"><span v-t="{path: 'employees.synchronization.synchronizeWith', args: {serviceNames: enabledIdentityProviderNames}}"></span></checkbox>
      </div>
      <menu>
        <button
          v-if="isUpdated"
          class="secondary"
          @click="resetCurrentWage"
          :disabled="isLoading"
          v-t="'common.reset'">
        </button>
        <button
          class="secondary"
          @click="$router.go(-1)"
          v-t="'employees.employee.form.cancel'">
        </button>
        <loading-button
          v-if="$$isAtLeastManager"
          class="primary"
          @click="setCurrentWage"
          :loading="isLoading">
          <span v-if="employee.currentWage" v-t="'employees.employee.updateWage'"></span>
          <span v-else v-t="'employees.employee.setThisWage'"></span>
        </loading-button>
      </menu>
    </div>
    <employee-newsfeed
      v-if="employee"
      :employee="employee"
      :showCurrent="false"
      :collapsed="true" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DateInput from '@components/commons/DateInput.vue'
import EmployeeDetail from '@components/employees/EmployeeDetail.vue'
import EmployeeNewsfeed from '@components/employees/EmployeeNewsfeed.vue'
import WageCalculator from '@components/wage/WageCalculator.vue'
import Checkbox from '@components/commons/Checkbox.vue'
import { dateIsBefore, normalizeDate } from '@/utils/date'

export default {
  components: {
    Checkbox,
    DateInput,
    EmployeeDetail,
    EmployeeNewsfeed,
    WageCalculator
  },
  data() {
    return {
      currentWageModel: null,
      isLoading: false,
      isUpdated: false,
      isSynchronizationPlanned: true,
      errorMessage: null
    }
  },
  computed: {
    ...mapGetters({
      isWageQualificationSynchronizationEnabled: 'synchronization/isWageQualificationSynchronizationEnabled',
      enabledIdentityProviderNames: 'synchronization/enabledIdentityProviderNames',
      getSynchronizableThirdPartyUsers: 'synchronization/getSynchronizableThirdPartyUsers'
    }),
    isSynchronizable() {
      return !this.isWagePlan && this.getSynchronizableThirdPartyUsers(this.employee).length
    },
    isWagePlan() {
      return this.$route.name === 'wagePlanEmployee'
    },
    wagePlanName() {
      return this.$store.getters['wagePlans/getCurrentWagePlanName']
    },
    employee() {
      const employeeId = this.isWagePlan ? this.$route.params.employeeId : this.$route.params.id
      return this.$store.getters['employees/getEmployee'](employeeId)
    },
    currentGrid() {
      return this.$store.getters['currentGrid/getCurrentGrid']
    }
  },
  methods: {
    resetCurrentWage() {
      this.$refs.currentWage.reset()
      this.isUpdated = false
    },
    setCurrentWage() {
      const employee = this.employee
      const wage = this.currentWageModel
      const referenceWage = employee.currentWage
      this.errorMessage = null
      this.isLoading = true

      if (!this.isWagePlan) {
        this.$store.dispatch('employees/setCurrentWage', { employee, wage })
          .then(() => {
            // Synchronize wage with identity provider
            if (this.isSynchronizable) {
              if (this.isSynchronizationPlanned) {
                this.$store.dispatch('synchronization/synchronizeUserWage', employee)
              }
              else {
                this.$store.dispatch('synchronization/reset')
              }
            }
            // Update similar wage of sandbox grid
            this.$store.dispatch('sandbox/updateSimilarSandboxWage', { employee, wage, referenceWage }).then(() => {
              this.$router.go(-1)
            })
          })
          .catch(error => {
            this.errorMessage = error
            this.isLoading = false
          })
      }
      else {
        const wagePlanId = this.$route.params.id
        this.$store.dispatch('wagePlans/setEmployeeWage', { employee, wage, wagePlanId })
          .then(() => {
            this.$router.push({ name: 'wagePlan', hash: '#payroll', params: { id: wagePlanId, highlightEmployee: employee.id } })
          })
          .catch(error => {
            this.errorMessage = error
            this.isLoading = false
          })
      }
    }
  },
  created() {
    if (this.employee) {
      this.$store.commit('employees/setCurrentEmployee', this.employee)

      if (this.isWagePlan) {
        const wagePlanId = this.$route.params.id
        const wagePlanWage = this.$store.getters['wagePlans/getEmployeeWage'](wagePlanId, this.employee.id)
        if (wagePlanWage) {
          this.currentWageModel = { ...wagePlanWage }
        }
      }

      if (!this.currentWageModel) {
        if (this.employee.currentWage) {
          this.currentWageModel = { ...this.employee.currentWage }
          this.currentWageModel.explanation = null
        }
        else {
          this.currentWageModel = { ...this.$store.state.employees.employeeModel.currentWage }
        }
        // Set start date
        if (!this.currentWageModel.startDate || dateIsBefore(this.currentWageModel.startDate, new Date())) {
          this.currentWageModel.startDate = normalizeDate(new Date())
        }
      }
    }
    else {
      this.$router.push({ name: 'employees' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/alert.scss";
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";

.container {
  background: $background-color;
}

.wage-calculator {
  @include container;
  margin-top: 1.5em;
}

h2 {
  display: block;
  margin: 0 0 20px 0;
}

.top-fields {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1em;
}

.third-party-button {
  float: right;
  margin-top: -5.25em;
  margin-right: 1em;
}
</style>
