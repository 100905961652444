<template>
  <div class="graph-legend">
    <div>
      <div
        v-for="[option, value] in Object.entries(options)"
        :key="option">
        <Checkbox
          class="option-checkbox preserve-lines fade-in"
          :checked="value"
          @change="value => $store.dispatch('statistics/setOption', {option, value})">{{$t(`dashboard.options.${option}`)}}</Checkbox>
      </div>
    </div>
    <div class="legend-title">
      <h3>
        <span v-t="{path: `dashboard.graphs.${id}.legend`, args: {company: $$companyName, scope: selectedScopeName}}"></span>
        <Tooltip
          class="graph-tooltip blue-bg gray-icon top left">
          <p v-html="$t(`dashboard.graphs.${currentReport.id}.notes`, {scope: selectedScopeName})"></p>
          <p class="no-margin-bottom">
            <span v-html="$t('dashboard.graphs.footer', {time: time})"></span>
            <SelectedPlansSentence />.
          </p>
        </Tooltip>
      </h3>
    </div>
    <div class="legend-container">
      <template v-if="graph && needsTruncation(graph)">
        <button
          class="small-button blue arrow arrow-left"
          :disabled="!canTruncatePrevious(graph, value)"
          @click="truncatePrevious()"></button>
        <button
          class="small-button blue arrow arrow-right"
          :disabled="!canTruncateNext(graph, value)"
          @click="truncateNext()"></button>
      </template>
      <div class="legend">
        <ul>
          <li class="female" v-t="'dashboard.genders.female'"></li>
          <li class="male" v-t="'dashboard.genders.male'"></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  canTruncatePrevious,
  canTruncateNext,
  needsTruncation
} from '@/utils/graph'
import Checkbox from '@components/commons/Checkbox'
import Tooltip from '@components/commons/Tooltip'
import SelectedPlansSentence from '@components/wagePlans/SelectedPlansSentence'

export default {
  components: {
    Checkbox,
    Tooltip,
    SelectedPlansSentence
  },
  props: {
    id: String,
    graph: Object,
    value: Number
  },
  computed: {
    ...mapGetters({
      currentReport: 'statistics/getCurrentReport',
      selectedScopeName: 'statistics/getCurrentScopeNameLowerCase',
      options: 'statistics/getOptions',
      filteredEmployees: 'statistics/getFilteredEmployees'
    }),
    time() {
      // Trick to update the time when the report is regenerated:
      return this.filteredEmployees && this.selectedScopeName && this.$options.filters.formatCalendar(new Date())
    }
  },
  methods: {
    needsTruncation,
    canTruncatePrevious,
    canTruncateNext,
    truncatePrevious() {
      if (canTruncatePrevious(this.graph, this.value)) {
        this.$emit('input', this.value - 1)
      }
    },
    truncateNext() {
      if (canTruncateNext(this.graph, this.value)) {
        this.$emit('input', this.value + 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/button.scss";
@import "./src/styles/link.scss";

$legend-padding: 0.5rem;

.graph-legend {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  position: relative;
  background: rgba(white, 0.75);
  margin: 10px 0 0;
}

.legend-title {
  text-align: center;
  position: relative;

  h3 {
    margin: 0;
    white-space: nowrap;
  }
}

.legend-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > *:not(:first-child) {
    margin-left: $legend-padding;
  }
}

.legend {
  @include font-small-size;
  @include line-regular-height;
  display: inline-block;
  padding: 0.3em 0.6em;
  border: $border;
  border-radius: $border-radius;
  background: white;
  text-align: left;
  white-space: nowrap;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li::before {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    content: " ";
    background: $graph-darkblue-color;
    margin-right: 0.5em;
  }

  li.female::before {
    background: $graph-purple-color;
  }
}

.option-checkbox::v-deep {
  @include font-small-size;

  label {
    align-items: center;

    &::after {
      top: 10px !important;
    }
  }
}

.arrow {
  padding: 5px 6.5px;

  &::before {
    content: " ";
    display: inline-block;
    width: 1em;
    height: 1em;
    background: transparent url(~@/assets/triangle-down.svg) no-repeat center
      center;
    background-size: contain;
    transform: rotate(90deg);
    vertical-align: middle;
  }

  &.arrow-right::before {
    transform: rotate(-90deg);
  }

  &[disabled]::before {
    opacity: 0.5;
  }
}

.graph-tooltip {
  @include font-small-size;
  white-space: normal;
  margin-left: 0.4em;

  &::v-deep {
    a {
      @extend .unstyled-link;
    }

    p {
      margin-top: 0;
    }
  }
}
</style>
