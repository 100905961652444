<template>
  <div class="block grid-description-editor">
    <div class="grid-title">
      <h3>
        <InlineEditor
          class="blue"
          v-model="gridModel.title"
          :placeholder="defaultTitle"
          @submit="submit()" />
      </h3>
      <GridVersion :grid="gridModel" />
    </div>
    <MarkdownTextEditor
      class="blue no-margin-bottom"
      v-model="gridModel.description"
      :placeholder="grid.description"
      :showInlineEditButton="true"
      :showAddButton="true"
      :showToolbar="true"
      :autoDismiss="true"
      @submit="submit()" />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { getGridTitle } from '@/utils/grid'
import InlineEditor from '@components/commons/InlineEditor'
import MarkdownTextEditor from '@components/commons/MarkdownTextEditor'
import GridVersion from '@components/grid/viewer/GridVersion.vue'

export default {
  components: {
    GridVersion,
    InlineEditor,
    MarkdownTextEditor
  },
  props: {
    grid: Object
  },
  data() {
    return {
      gridModel: null
    }
  },
  computed: {
    defaultTitle() {
      return getGridTitle(this.grid, this.$$companyName)
    }
  },
  methods: {
    initModel() {
      const { id, title, description } = this.grid
      this.gridModel = { id, title: title || this.defaultTitle, description: description }
    },
    async submit() {
      this.$v.$touch()
      if (this.$v.$error || this.isLoading) {
        return
      }
      this.isLoading = true
      try {
        await this.$store.dispatch('sandbox/updateGridProperties', this.gridModel)
      }
      finally {
        this.isLoading = false
      }
    }
  },
  watch: {
    grid: 'initModel'
  },
  validations() {
    return {
      gridModel: {
        title: { required }
      }
    }
  },
  created() {
    this.initModel()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
@import "./src/styles/block.scss";

.grid-description-editor {
  margin-bottom: 1em;

  .grid-title {
    display: flex;
    align-items: center;
    margin-bottom: 9px;

    h3 {
      color: $graph-darkblue-color;
      margin: 0;
    }
  }
}
</style>
