<template>
  <div class="container">
    <div v-if="isEnabled">
      <menu class="float-right no-margin-top no-margin-bottom">
        <grid-exclusion-button />
      </menu>
      <h1 v-t="'grid.onboarding.qualification.title'"></h1>
      <p class="no-margin-bottom" v-html="$t('grid.onboarding.qualification.intro')"></p>
      <onboarding-qualification-editor @change="onChange" />
      <p v-if="updatedAt" v-html="$t('grid.onboarding.common.updatedAt', {updatedAt})"></p>
      <onboarding-continue-banner
        v-if="$$isAdmin"
        @submit="submit"
        :completed="isJustCompleted"
        :label="$t('grid.onboarding.qualification.shallWeProceed')"
        :completedLabel="$t('grid.onboarding.qualification.congratulations')" />
      <onboarding-continue-banner
        v-else
        :completed="isJustCompleted"
        :completedLabel="$t('grid.onboarding.qualification.congratulationsManager')"
        :hideButton="true" />
    </div>
    <div v-else>
      <h1 v-t="'grid.onboarding.qualification.title'"></h1>
      <p v-html="$t('grid.onboarding.qualification.placeholder')"></p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import animateScrollTo from 'animated-scroll-to'
import OnboardingContinueBanner from '@components/grid/onboarding/OnboardingContinueBanner'
import OnboardingQualificationEditor from '@components/grid/onboarding/OnboardingQualificationEditor'
import GridExclusionButton from '@components/grid/qualification/GridExclusionButton'

export default {
  components: {
    GridExclusionButton,
    OnboardingContinueBanner,
    OnboardingQualificationEditor
  },
  data() {
    return {
      updatedAt: null
    }
  },
  methods: {
    onChange() {
      this.updatedAt = this.$options.filters.formatCalendar(new Date())
    },
    submit() {
      if (this.employees.unqualified.length) {
        animateScrollTo(0).then(() => {
          alert(this.$t('grid.onboarding.qualification.continueWarning'))
        })
      }
      else {
        this.$store.dispatch('onboarding/submitQualification').then(() => {
          this.$router.push('/grid/onboarding/generate')
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      onboardingProgress: 'onboarding/getProgressIndex',
      employees: 'onboarding/getQualificationEmployees'
    }),
    isEnabled() {
      return this.onboardingProgress >= 1
    },
    isCompleted() {
      return this.onboardingProgress > 2
    },
    isJustCompleted() {
      return !this.isCompleted && !this.employees.unqualified.length
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/alert.scss";

.container {
  @include tab-container;
}
</style>
