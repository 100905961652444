<template>
  <cleave
    v-model="number"
    :options="options"
    @input="$emit('input', number)"
    @keyup.native.enter="$emit('enterKeyUp')">
  </cleave>
</template>

<script>
import Cleave from 'vue-cleave-component'

export default {
  props: ['value'],
  data() {
    return {
      number: this.value,
      options: {
        numeral: true,
        numeralPositiveOnly: true,
        delimiter: ' ',
        numeralDecimalMark: '.',
        numeralDecimalScale: 4
      }
    }
  },
  watch: {
    value: 'updateInput'
  },
  methods: {
    updateInput() {
      this.number = this.value
    }
  },
  components: {
    Cleave
  }
}
</script>
