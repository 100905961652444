<template>
  <div>
    <ResizablePanel :observer="isReportsMenuVisible">
      <div v-if="isReportsMenuVisible">
        <div v-t="'dashboard.reports'" class="input-label big"></div>
        <ul class="report-list">
          <router-link
            v-for="report in reports"
            :key="report"
            tag="li"
            class="header-link"
            active-class="selected"
            :to="{ name: 'dashboard', params: {id: report}}"
            @dblclick.native="toggleReportsMenu">
            <div class="report-icon" :class="report"></div>{{$t(`dashboard.graphs.${report}.title`)}}
          </router-link>
        </ul>
      </div>
    </ResizablePanel>
    <div class="graph-header">
      <div>
        <h2 @click="toggleReportsMenu">
          <span class="report-icon" :class="currentReport.id"></span>
          <span v-t="`dashboard.graphs.${currentReport.id}.title`"></span>
        </h2>
      </div>
      <div class="graph-selectors">
        <ScopeSelector />
        <GraphFilters />
        <GraphOptions />
      </div>
    </div>
  </div>
</template>

<script>
import GraphFilters from '@components/graph/GraphFilters.vue'
import GraphOptions from '@components/graph/GraphOptions.vue'
import ScopeSelector from '@components/graph/ScopeSelector.vue'
import ResizablePanel from '@components/commons/ResizablePanel'
import { mapGetters } from 'vuex'

export default {
  components: {
    GraphFilters,
    GraphOptions,
    ResizablePanel,
    ScopeSelector
  },
  data() {
    return {
      isReportsMenuVisible: true
    }
  },
  computed: {
    ...mapGetters({
      currentReport: 'statistics/getCurrentReport',
      reports: 'statistics/getReports'
    })
  },
  methods: {
    toggleReportsMenu() {
      this.isReportsMenuVisible = !this.isReportsMenuVisible
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/badge.scss";

.graph-header {
  display: flex;
  justify-content: space-between;
  user-select: none;

  h2 {
    margin: 0;
    white-space: nowrap;
    margin-right: 1em;

    .report-icon {
      padding: 0.3em;
      border: $border;
      border-radius: $border-radius;
      vertical-align: -9px;
      margin-right: 15px;
    }

    &:hover {
      cursor: pointer;
      color: $graph-darkblue-color;

      .report-icon {
        background-color: $graph-lightblue-color;
      }
    }
  }
}

.graph-selectors {
  text-align: right;

  & > div {
    vertical-align: top;
    text-align: left;
    margin-bottom: 0.5em;

    &:not(:first-of-type) {
      margin-left: 0.5em;
    }
  }
}

.report-list {
  margin: 0 0 15px 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  gap: 0.5em;
}

.report-list li {
  @extend .badge-block;
  user-select: none;
  white-space: pre-line !important;
  padding: 0.3em 0.3em 0.3em 0.5em;
  line-height: 1.2em;
  white-space: normal;
  background: white;
  display: inline-grid;
  grid-template-columns: 50px 1fr;
  align-items: center;
  cursor: default;

  &:hover:not(.router-link-exact-active):not(.disabled) {
    cursor: pointer;
    background: lighten($graph-lightblue-color, 1);

    .report-icon {
      background-color: white;
      opacity: 1;
    }
  }

  &.selected {
    background: darken($graph-lightblue-color, 2);

    .report-icon {
      background-color: white;
      opacity: 1;
    }
  }
}

.report-icon {
  display: inline-block;
  width: 2.5rem;
  height: 2.4rem;
  background: $graph-blue-color;
  border-radius: $border-radius;
  background: white none no-repeat center center;
  opacity: 0.8;

  &.evolution {
    background-image: url(~@/assets/report-evolution.svg);
  }

  &.payroll {
    background-image: url(~@/assets/report-payroll.svg);
  }

  &.workforce {
    background-image: url(~@/assets/report-workforce.svg);
  }

  &.salaries {
    background-image: url(~@/assets/report-salaries.svg);
  }

  &.table {
    background-image: url(~@/assets/report-table.svg);
  }

  &.grid {
    background-image: url(~@/assets/report-grid.svg);
  }

  &.deviation {
    background-image: url(~@/assets/report-deviation.svg);
  }

  &.ranges {
    background-image: url(~@/assets/report-ranges.svg);
  }

  &.seniority {
    background-image: url(~@/assets/report-seniority.svg);
  }

  &.gender {
    background-image: url(~@/assets/report-gender.svg);
  }
}
</style>
