<template>
  <div class="container">
    <template v-if="gridsLoaded">
      <div class="toolbar">
        <button class="secondary" @click="createGrid" v-t="'sandbox.createNewGrid'"></button>
      </div>
      <div class="border">
        <table v-if="sandboxGrids.length > 0">
          <tr>
            <th colspan="2" v-t="'sandbox.name'"></th>
          </tr>
          <tr
            class="row clickable"
            v-for="grid in sandboxGrids.slice().reverse()"
            :key="grid.id">
            <td
              class="grid"
              @click="selectGrid(grid)">
              {{ grid.name }}
              <span class="description">
                {{$t('grid.current.createdAt', {date: $options.filters.fullFormattedDate(grid.updatedAt)})}}
              </span>
            </td>
            <td class="actions">
              <button style="padding: 0; background: transparent" @click="deleteGrid(grid)">🗑</button>
            </td>
          </tr>
        </table>
        <div class="explanation" v-else v-html="$t('sandbox.sectionExplanation')"></div>
      </div>
    </template>
    <loading-view v-else></loading-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoadingView from '@/components/commons/LoadingView.vue'

export default {
  computed: {
    ...mapGetters({
      onboardingGrid: 'sandbox/onboardingGrid',
      sandboxGrids: 'sandboxList/grids',
      gridsLoaded: 'sandboxList/gridsLoaded'
    })
  },
  methods: {
    createGrid() {
      this.$router.push({ name: 'sandboxForm' })
    },
    deleteGrid(grid) {
      if (confirm(this.$t('sandbox.deleteConfirmation', { gridName: grid.name }))) {
        this.$store.dispatch('sandboxList/remove', grid)
      }
    },
    selectGrid(grid) {
      this.$store.commit('sandbox/setName', grid.name)

      this.$router.push({
        name: 'sandboxEdit',
        params: { id: grid.id }
      })
    }
  },
  mounted() {
    this.$store.dispatch('sandboxList/get')
  },
  components: {
    LoadingView
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
@import "./src/styles/table.scss";

.container {
  @include tab-container;
}

.toolbar {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
}

.border {
  border: $border;
  border-radius: $border-radius;
}

.explanation {
  padding: 20px;
}

.actions {
  text-align: right;
  width: 50px;
}

.description {
  @include font-small-size;
  display: inline-block;
  color: $light-text-color;
  margin-left: 0.5em;
}
</style>
