<template>
  <div class="wage-summary">
    <div class="wage-part">
      <h3>{{ summary.type | formattedRemunerationType }}</h3>
      <div v-if="summary.unit">
        <div v-if="showDelta && summary.diffValue" class="wage-delta">
          <!-- Show Values with Delta -->
          <div class="badge border-badge large-badge clickable" @click="$emit('reference')">{{ (summary.referenceValue) | formattedCurrency }}</div>
          <span class="wage-arrow">→</span>
          <div class="badge border-badge large-badge">{{ summary.value | formattedCurrency }}</div>
          <div
            class="badge delta-badge border-badge large-badge"
            :class="summary.diffValue > 0 ? 'higher' : 'lower'"
            :title="$t(`grid.salary${summary.diffValue > 0 ? 'Raise' : 'Decrease'}`)">
            {{ summary.diffValue | formattedCurrency(false, {signDisplay: "always"}) }}
            <small>({{ summary.diffPercentValue | formattedNumber(1, 1) }}&nbsp;%)</small>
          </div>
        </div>
        <div v-else class="badge border-badge large-badge">
          <!-- Show Value -->
          {{ summary.value | formattedCurrency }}
        </div>
      </div>
      <div v-else class="wage-value not-available" v-t="'common.na'"></div>
    </div>
  </div>
</template>

<script>
import anime from 'animejs/lib/anime.es.js'

export default {
  props: {
    wageSummary: Object,
    referenceWageSummary: Object,
    showDelta: {
      type: Boolean,
      default: false
    },
    showWageType: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      summary: null
    }
  },
  methods: {
    generateSummary() {
      const updatedSummary = {
        type: 'salary',
        referenceValue: this.wageSummary.salary.value,
        diffValue: 0,
        diffPercentValue: 0,
        ...this.wageSummary.salary
      }
      const reference = this.referenceWageSummary && this.referenceWageSummary.salary
      if (reference && updatedSummary.value !== reference.value) {
        updatedSummary.referenceValue = reference.value
        updatedSummary.diffValue = updatedSummary.value - reference.value
        updatedSummary.diffPercentValue = reference.value ? Math.abs(updatedSummary.diffValue / reference.value) * 100 : 100
      }

      if (this.summary) {
        updatedSummary.diffPercentValue = {
          value: updatedSummary.diffPercentValue,
          round: 10
        }
        anime({
          targets: this.summary,
          round: 1,
          duration: 400,
          easing: 'linear',
          value: updatedSummary.value,
          referenceValue: updatedSummary.referenceValue,
          diffValue: updatedSummary.diffValue,
          diffPercentValue: updatedSummary.diffPercentValue
        })
      }
      else {
        this.summary = updatedSummary
      }
    }
  },
  watch: {
    wageSummary: 'generateSummary',
    referenceWageSummary: 'generateSummary'
  },
  created() {
    this.generateSummary()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/badge.scss";

h3 {
  margin: 0 1em 0 0;
}

.wage-part {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.off-grid {
  margin-top: 0.1em;
  color: $light-text-color;
}

.overriden {
  @include font-small-size;
  background: white;
  border: 1px solid $lightteal-color;
}

.strike {
  text-decoration: line-through;

  &:hover {
    text-decoration: none;
  }
}

.not-available {
  background: $lightgray-color;
  color: $gray-color;
  @include font-regular;
}

.wage-delta {
  display: flex;
  white-space: nowrap;
  align-items: center;

  .wage-arrow {
    margin: 0 5px;
  }
}

.margin-top {
  margin-top: -0.3em;
}

hr {
  display: block;
  border: 0;
  border-top: $border;
  height: 1px;
  margin: 20px 0 20px 0;
}
</style>
