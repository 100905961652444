<template>
  <loading-view />
</template>

<script>
import LoadingView from '@components/commons/LoadingView'

export default {
  async beforeMount() {
    try {
      await this.$store.dispatch('okta/parseAccessToken')
      this.$router.replace({ name: 'signin', params: { isOktaAuth: true } })
    }
    catch (error) {
      this.$router.replace({ name: 'signin', params: { errorMessage: error.message } })
    }
  },
  components: {
    LoadingView
  }
}
</script>
