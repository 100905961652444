/* eslint-disable no-eval */

// Use user snippets to add custom css, js, etc.
let isSnippetInjected = false
export function setupSnippet(user) {
  if (user.jsSnippet) {
    eval(user.jsSnippet)
    isSnippetInjected = true
  }
}

export function destroySnippet() {
  if (isSnippetInjected) {
    location.reload()
  }
}
