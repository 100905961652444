<template>
  <div class="wage-level-selector">
    <div v-if="hasSkills && visibleLevels.length" class="levels-with-skills">
      <MarkdownText class="no-margin-top" v-model="(parentComponent || component).description" />
      <OnboardingQualificationSkillsEditor
        class="skills-editor"
        :component="component"
        :parentComponent="parentComponent"
        :referenceLevel="referenceLevel"
        :wageDetails="wageDetails"
        :showDelta="showDelta"
        :showSalary="showSalary"
        :disabled="disabled"
        @selectLevel="selectLevel"
        @resetLevel="resetLevel" />
    </div>
    <div v-else-if="visibleLevels.length" class="levels-without-skills">
      <MarkdownText class="no-margin-top" v-model="(parentComponent || component).description" />
      <p class="no-margin-top">
        {{$t('wageCalculator.chooseLevel', {level: getLevelName(component.ref, 2, true).replace('2 ', '')}) | capitalize}}
      </p>
      <template v-for="(level, i) in visibleLevels">
        <div
          v-if="level.group && (i === 0 || visibleLevels[i - 1].group !== level.group)"
          :key="[level.id, 'group'].join()"
          class="level-group level disabled">
          <span class="input-label big">{{level.group}}</span>
        </div>
        <div
          class="level"
          :class="{
            active:level === component.selectedLevel,
            large: !!level.description,
            disabled
          }"
          :key="[level.id, _uid].join()"
          @click="selectOrResetLevel($event, level)">
          <Checkbox
            :checked="component.selectedLevel && component.selectedLevel.id === level.id" />
          <label
            :for="[level.id, _uid].join('-')"
            class="level-name">
            {{ level.name }}
            <span
              class="level-delta-labels"
              v-if="showDelta && referenceLevel && component.selectedLevel && component.selectedLevel.name != referenceLevel.name && level.name === referenceLevel.name"
              v-t="'wageCalculator.currentLevel'">
            </span>
            <span
              class="level-delta-labels"
              v-if="showDelta && referenceLevel && component.selectedLevel && component.selectedLevel.name != referenceLevel.name && level.name === component.selectedLevel.name"
              v-t="'wageCalculator.newLevel'">
            </span>
            <span
              class="level-delta-labels"
              v-if="level.isHidden"
              v-t="'grid.editor.hiddenLevel'">
            </span>
            <span class="level-value">{{ getFormattedLevelValue(level) }}</span>
          </label>
          <MarkdownText class="description small" v-model="level.description" />
        </div>
      </template>
    </div>
    <div v-else class="alert warning" v-t="'sandbox.noLevels'">
    </div>
    <p
      v-if="showSalary && isIntervalBoundsScope(component)"
      class="small-size light-text no-margin-bottom"
      v-t="'wageCalculator.intervalScopeNotice'" />
  </div>
</template>

<script>
import {
  hasSkills
} from '@/utils/skills'
import Checkbox from '@components/commons/Checkbox.vue'
import MarkdownText from '@components/commons/MarkdownText'
import OnboardingQualificationSkillsEditor from '@components/grid/onboarding/OnboardingQualificationSkillsEditor.vue'
import { getVisibleLevels, isIntervalBoundsScope, getLevelName } from '@/utils/grid'

export default {
  components: {
    Checkbox,
    MarkdownText,
    OnboardingQualificationSkillsEditor
  },
  props: {
    component: Object,
    parentComponent: Object,
    disabled: {
      type: Boolean,
      default: false
    },
    referenceComponent: Object,
    // Used in formattedLevelValue
    wageDetails: Object,
    showDelta: {
      type: Boolean,
      default: false
    },
    showSalary: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    referenceLevel() {
      return this.referenceComponent && this.referenceComponent.selectedLevel
    },
    hasSkills() {
      return hasSkills(this.component)
    },
    visibleLevels() {
      return getVisibleLevels(this.component && this.component.levels, this.component && this.component.selectedLevel)
    }
  },
  methods: {
    getLevelName,
    isIntervalBoundsScope,
    selectLevel(level) {
      if (this.disabled) {
        return
      }
      this.$emit('change', level)
    },
    selectOrResetLevel(event, level) {
      if (event.altKey) {
        return this.resetLevel()
      }
      this.selectLevel(level)
    },
    resetLevel() {
      if (this.disabled) {
        return
      }
      this.$emit('reset', this.component)
    },
    setDefaultSelectedLevel() {
      if (!this.hasSkills && !this.component.selectedLevel && this.visibleLevels && this.visibleLevels.length) {
        this.selectLevel(this.visibleLevels[0])
      }
    },
    getFormattedLevelValue(level) {
      if (this.showSalary) {
        const { wageDetails } = this
        return this.$options.filters.formattedLevelValue(this.component, level, this.parentComponent, this.parentComponent && this.parentComponent.selectedLevel, 'qualification', { wageDetails })
      }
    }
  },
  watch: {
    'component.id': 'setDefaultSelectedLevel'
  },
  created() {
    this.setDefaultSelectedLevel()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/alert.scss";

.levels {
  margin: -0.5em auto 1em;
}

.level {
  padding: 0 1em;
  border: 0.25em solid white;
  border-radius: 2.5em;

  &:not(.disabled) {
    cursor: pointer;
  }

  &.large {
    border-radius: 1em;
  }

  label {
    line-height: 2.5em;
    @include font-medium;
  }

  &.active,
  &:not(.disabled):hover {
    background: $graph-lightblue-color;
  }

  &.active {
    color: $graph-darkblue-color;

    .next-level-label {
      opacity: 0.8;
    }
  }

  .level-value:not(:empty) {
    float: right;
    @include font-tabular-numbers;
  }

  .description {
    margin: 0 0 1em 32px !important;
    color: $text-color;
  }

  .level-delta-labels {
    @include font-smaller-size;
    @include font-bold;
    line-height: 1em;
    text-transform: uppercase;
    opacity: 0.5;
    display: inline-block;
    margin-left: 0.5em;
  }

  .checkbox {
    pointer-events: none;

    &::v-deep label:before {
      vertical-align: sub !important;
    }

    &::v-deep label:after {
      top: 2.5px !important;
    }
  }

  &.level-group:not(:first-of-type) {
    margin-top: 0.5em;
  }
}
</style>
