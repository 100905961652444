<template>
  <div class="wage-interval-slider" v-if="intervalComponent">
    <header v-if="showWage">
      <label v-if="intervalComponent.selectedLevel">{{$t('wageCalculator.wageIntervalSlider.title', {level: intervalComponent.selectedLevel.name})}}</label>
      <div>
        <Checkbox
          class="fade-in"
          v-if="isManualModel && showAdjustedCheckbox"
          v-model="isManualModel"
          @change="onManualChange">
          <span v-t="'wageCalculator.wageIntervalSlider.adjusted'"></span>
        </Checkbox>
        <OperationInput
          :step="100"
          v-model="valueModel"
          @input="onChange" />
        <OperationInput
          v-if="isPercentVisible"
          class="percent-input"
          operation="percent"
          :min="-1000"
          :max="1000"
          :step="1"
          v-model="percentModel"
          @input="onPercentChange" />
      </div>
    </header>
    <svg :height="viewport.height" ref="svgNode"></svg>
  </div>
</template>

<script>
import * as d3 from 'd3'
import debounce from 'lodash.debounce'
import flatten from 'lodash.flatten'
import Checkbox from '@components/commons/Checkbox.vue'
import OperationInput from '@components/commons/OperationInput.vue'
import { getWageDetails, getIntervalComponent, isIntervalStartPositionMedian, getVisibleLevels, getComponentScopeValue, isInterval, isIntervalStepsScope, getVisibleLinkedLevels } from '@/utils/grid'
import { formatLabel, truncateLabel } from '@/utils/graph'
import orderBy from 'lodash.orderby'

function initGraph(svg, viewport) {
  const node = {}

  node.svg = d3.select(svg)
  node.viewport = node.svg.append('g')
    .attr('class', 'viewport')
  node.abscissa = node.viewport.append('g')
    .attr('class', 'axis abscissa')
  node.highlight = node.viewport.append('rect')
    .attr('class', 'highlight')
  node.levels = node.viewport.append('g')
    .attr('class', 'levels')
  node.cursorContainer = node.viewport.append('g')
    .attr('class', 'cursor-container')
  node.cursorPercent = node.cursorContainer.append('text')
    .attr('class', 'label percent')
  node.cursorValue = node.cursorContainer.append('text')
    .attr('class', 'label value')
  node.cursorShadow = node.cursorContainer.append('line')
    .attr('class', 'cursor-shadow')
  node.cursor = node.cursorContainer.append('line')
    .attr('class', 'cursor')
  node.x = d3.scaleLinear()
  node.y = d3.scaleBand()

  return {
    node: node,
    viewport: viewport,
    data: [],
    isDragging: false,
    animationDuration: 0
  }
}

function generateSliderModel(graph, wage, grid, simulationLevels, showWage, isStartPositionMedian) {
  const wageDetails = getWageDetails(grid, wage, { simulationLevels, includeComponents: true })
  const component = wageDetails.components.find(component => isInterval(component, false))
  const stepComponent = component.linkedComponents.find(isIntervalStepsScope)
  const selectedLevel = component.selectedLevel
  const value = wageDetails.summary.salary.value
  const visibleLevels = getVisibleLevels(component && component.levels, showWage ? selectedLevel : null)

  // Generate data
  graph.data = []
  graph.value = value
  graph.data = visibleLevels.map(level => {
    const simulatedLevel = simulationLevels.find(l => l.id === level.id) || level
    const min = getComponentScopeValue(grid.components, wage, simulatedLevel.minimumValue || 0, 'intervalBounds')
    const med = getComponentScopeValue(grid.components, wage, simulatedLevel.salaryValue || 0, 'intervalBounds')
    const max = getComponentScopeValue(grid.components, wage, simulatedLevel.maximumValue || 0, 'intervalBounds')
    let steps

    if (stepComponent && stepComponent.levels && stepComponent.levels.length) {
      const stepLevels = getVisibleLinkedLevels(stepComponent.levels, level)
      steps = stepLevels.length && stepLevels.map(l => {
        const sl = simulationLevels.find(ll => ll.id === l.id) || l
        return {
          id: sl.id,
          label: sl.name,
          x: getComponentScopeValue(grid.components, wage, sl.salaryValue, 'intervalBounds')
        }
      })
    }

    return {
      x: med,
      steps: steps,
      xMin: min,
      xMax: Math.max(max, min),
      id: simulatedLevel.id,
      label: simulatedLevel.name
    }
  })
  graph.active = (showWage && selectedLevel && graph.data.find(d => d.id === selectedLevel.id)) || false
  if (graph.active) {
    if (isStartPositionMedian) {
      graph.percentValue = graph.value >= graph.active.x
        ? (graph.value - graph.active.x) / (graph.active.xMax - graph.active.x)
        : (graph.value - graph.active.x) / (graph.active.x - graph.active.xMin)
    }
    else {
      graph.percentValue = (graph.value - graph.active.xMin) / (graph.active.xMax - graph.active.xMin)
    }
  }
  else {
    graph.percentValue = 0
  }
  if (stepComponent) {
    const selectedStepComponent = wageDetails.components.find(isIntervalStepsScope)
    if (selectedStepComponent && selectedStepComponent.selectedLevel) {
      graph.stepLabel = selectedStepComponent.selectedLevel.name
    }
  }
  return graph
}

function renderGraph(graph, onChange, onLevelChange, onLevelValueChange) {
  const viewport = graph.viewport
  const node = graph.node

  // Viewport
  viewport.width = graph.node.svg.node().getBoundingClientRect().width
  viewport.innerWidth = viewport.width - viewport.padding.left - viewport.padding.right
  viewport.innerHeight = viewport.height - viewport.padding.top - viewport.padding.bottom

  if (!graph.isDragging) {
    const xDomainMin = Math.min(graph.value, d3.min(graph.data, d => d.xMin)) * 0.93
    const xDomainMax = Math.max(graph.value, d3.max(graph.data, d => d.xMax)) * 1.07
    node.x.domain([xDomainMin, xDomainMax])
      .rangeRound([0, viewport.innerWidth])

    const yDomain = graph.data.map(d => d.id).reverse()
    node.y.domain(yDomain)
      .range([0, viewport.innerHeight])
      .padding(yDomain.length > 3 ? [0.35] : [0.60])
  }

  // Viewport background
  node.viewport.attr('transform', `translate(${viewport.padding.left},${viewport.padding.top})`)
  const xTicks = node.x.ticks(10)
  const xTicksData = xTicks.map((t, i) => ({
    id: i,
    x: node.x(t),
    y: node.y.range()[0] - 7,
    label: i === xTicks.length - 1 ? formatLabel('$~s', t) : Math.round(t / 1000)
  }))
  const abscissaLabels = node.abscissa.selectAll('.label')
    .data(xTicksData, d => d.id)
  abscissaLabels
    .enter().append('text')
    .attr('class', 'label')
    .attr('x', viewport.width)
    .attr('y', d => d.y)
    .merge(abscissaLabels)
    .transition()
    .duration(graph.animationDuration)
    .attr('x', d => d.x)
    .attr('y', d => d.y)
    .text(d => d.label)
  abscissaLabels.exit()
    .remove()

  const abscissaTicks = node.abscissa.selectAll('.tick')
    .data(xTicksData, d => d.id)
  abscissaTicks
    .enter().append('line')
    .attr('class', 'tick')
    .attr('x1', viewport.width)
    .attr('y1', node.y.range()[0])
    .attr('x2', viewport.width)
    .attr('y2', node.y.range()[1])
    .merge(abscissaTicks)
    .transition()
    .duration(graph.animationDuration)
    .attr('x1', d => d.x)
    .attr('y1', node.y.range()[0])
    .attr('x2', d => d.x)
    .attr('y2', node.y.range()[1])
  abscissaTicks.exit()
    .remove()

  // Highlight
  const HL_PADDING = 6
  const Y_HIDDEN = -1000
  node.highlight
    .transition()
    .duration(graph.animationDuration)
    .attr('x', -viewport.padding.left)
    .attr('y', graph.active ? node.y(graph.active.id) - HL_PADDING : Y_HIDDEN)
    .attr('width', viewport.width)
    .attr('height', node.y.bandwidth() + 2 * HL_PADDING + 1.5)

  // Levels
  const levelsShadows = node.levels.selectAll('.shadow')
    .data(graph.data, d => d.label)
  levelsShadows
    .enter().append('rect')
    .merge(levelsShadows)
    .transition()
    .duration(graph.animationDuration)
    .attr('class', d => 'shadow' + (graph.active.id === d.id ? ' active' : ''))
    .attr('x', d => node.x(d.xMin))
    .attr('y', d => node.y(d.id))
    .attr('rx', 3)
    .attr('ry', 3)
    .attr('width', d => node.x(d.xMax) - node.x(d.xMin))
    .attr('height', node.y.bandwidth())
  levelsShadows.exit()
    .remove()

  const levelsBars = node.levels.selectAll('.level')
    .data(graph.data, d => d.label)
  levelsBars
    .enter().append('rect')
    .merge(levelsBars)
    .on('click', function(event, d) {
      if (!graph.active) {
        return
      }
      const active = graph.data.find(({ id }) => id === d.id)
      const coords = d3.pointer(event, node.viewport.node())
      const value = node.x.invert(coords[0])

      if (d.steps) {
        // Select closest step level
        const sortedSteps = orderBy(d.steps.map(s => {
          return {
            id: s.id,
            distance: Math.abs(s.x - value)
          }
        }), ['distance'], ['asc'])
        const closestLevelId = sortedSteps[0].id
        onLevelChange(d.id)
        onLevelChange(closestLevelId)
        onChange(null)
      }
      else {
        // Select level and round click position on level to the closest 25%
        onLevelChange(d.id)
        const xMin = value < d.x ? active.xMin : d.x
        const xMax = value < d.x ? d.x : d.xMax
        const percentValue = (value - xMin) / (xMax - xMin)
        const nicePercentValue = Math.round(percentValue * 100 / 25) / 100 * 25
        const niceValue = (xMax - xMin) * nicePercentValue + xMin
        onChange(niceValue)
      }
    })
    .on('mousedown', function(event, d) {
      if (graph.active) {
        return
      }
      node.svg.style('cursor', 'col-resize')
      node.levels.style('pointer-events', 'none')
      graph.isDragging = true
      graph.dragOffset = d3.pointer(event, node.viewport.node())[0] - node.x(d.xMin)

      node.svg.on('mousemove', function(event) {
        const coords = d3.pointer(event, node.viewport.node())
        const { x, xMin, xMax } = d
        const newXMin = Math.round(node.x.invert(coords[0] - graph.dragOffset) / 1000) * 1000
        const offset = newXMin - xMin
        const newX = offset + x
        const newXMax = offset + xMax
        if (offset) {
          onLevelValueChange(d.id, newXMin, newX, newXMax)
        }
      })

      const windowNode = d3.select(window)
      windowNode.on('mouseup', function() {
        graph.isDragging = false
        windowNode.on('mouseup', null)
        node.svg.on('mousemove', null)
        node.svg.style('cursor', null)
        node.levels.style('pointer-events', null)
        setTimeout(() => {
          renderGraph(graph, onChange, onLevelChange, onLevelValueChange)
        }, 500)
      })
    })
    .transition()
    .duration(graph.animationDuration)
    .attr('class', d => 'level' + (graph.active.id === d.id ? ' active' : ''))
    .attr('x', d => node.x(d.xMin))
    .attr('y', d => node.y(d.id))
    .attr('rx', 3)
    .attr('ry', 3)
    .attr('width', d => node.x(d.xMax) - node.x(d.xMin))
    .attr('height', node.y.bandwidth())
  levelsBars.exit()
    .remove()

  const levelsTicksData = flatten(graph.data.map(d => {
    if (d.steps) {
      return flatten(d.steps.map((s, i) => {
        return {
          id: [d.label, i].join(),
          y: d.id,
          x: s.x
        }
      }))
    }
    else {
      return {
        id: [d.label, 'x'].join(),
        y: d.id,
        x: d.x
      }
    }
  }))
  const levelsTicks = node.levels.selectAll('.tick')
    .data(levelsTicksData, d => d.id)
  levelsTicks
    .enter().append('line')
    .merge(levelsTicks)
    .attr('class', d => 'tick' + (graph.active.id === d.y ? ' active' : ''))
    .transition()
    .duration(graph.animationDuration)
    .attr('x1', d => node.x(d.x))
    .attr('x2', d => node.x(d.x))
    .attr('y1', d => node.y(d.y))
    .attr('y2', d => node.y(d.y) + node.y.bandwidth() + 1)
  levelsTicks.exit()
    .remove()

  const levelsBoundsLabels = node.levels.selectAll('.bound')
    .data(flatten(graph.data.map(d => {
      return [{
        id: [d.label, 'min'].join(),
        class: 'bound min ' + (graph.active.id === d.id ? 'active' : ''),
        x: d.xMin,
        y: d.id,
        steps: d.steps,
        isMin: true,
        label: formatLabel('$~s', Math.round(d.xMin / 100) * 100)
      }, {
        id: [d.label, 'max'].join(),
        class: 'bound max ' + (graph.active.id === d.id ? 'active' : ''),
        x: d.xMax,
        y: d.id,
        steps: d.steps,
        label: formatLabel('$~s', Math.round((d.xMax) / 100) * 100)
      }]
    })), d => d.id)
  levelsBoundsLabels
    .enter().append('text')
    .merge(levelsBoundsLabels)
    .attr('class', d => d.class)
    .on('click', function(_, d) {
      onLevelChange(d.y)
      onChange(d.x)
      if (d.steps) {
        onLevelChange(d.isMin ? d.steps[0].id : d.steps[d.steps.length - 1].id)
      }
    })
    .transition()
    .duration(graph.animationDuration)
    .attr('x', d => node.x(d.x))
    .attr('y', d => node.y(d.y) + node.y.bandwidth() / 2)
    .text(d => d.label)
  levelsBoundsLabels.exit()
    .remove()

  const levelsLabels = node.levels.selectAll('.label')
    .data(graph.data.slice().reverse(), d => d.label)
  levelsLabels
    .enter().append('text')
    .merge(levelsLabels)
    .attr('class', d => 'label' + (graph.active.id === d.id ? ' active' : ''))
    .on('click', function(_, d) {
      onLevelChange(d.id)
    })
    .transition()
    .duration(graph.animationDuration)
    .attr('x', 35 - viewport.padding.left)
    .attr('y', d => node.y(d.id) + node.y.bandwidth() / 2)
    .text(d => truncateLabel(d.label, 12))
  levelsLabels.exit()
    .remove()

  // Cursor container
  node.cursorContainer.style('display', graph.active ? '' : 'none')

  if (graph.active) {
    // Cursor shadow
    node.cursorShadow
      .transition()
      .duration(graph.animationDuration)
      .duration(graph.isDragging ? 0 : graph.animationDuration)
      .attr('x1', node.x(graph.value))
      .attr('y1', node.y(graph.active.id))
      .attr('x2', node.x(graph.value))
      .attr('y2', node.y(graph.active.id) + node.y.bandwidth())

    // Cursor
    node.cursor
      .on('mousedown', function() {
        node.svg.style('cursor', 'col-resize')
        node.levels.style('pointer-events', 'none')
        graph.isDragging = true

        node.svg.on('mousemove', function(event) {
          const coords = d3.pointer(event, node.viewport.node())
          const newValue = Math.round(node.x.invert(coords[0]) / 10) * 10
          onChange(newValue)
        })

        const windowNode = d3.select(window)
        windowNode.on('mouseup', function() {
          graph.isDragging = false
          windowNode.on('mouseup', null)
          node.svg.on('mousemove', null)
          node.svg.style('cursor', null)
          node.levels.style('pointer-events', null)
          setTimeout(() => {
            renderGraph(graph, onChange, onLevelChange, onLevelValueChange)
          }, 500)
        })
      })
      .transition()
      .duration(graph.animationDuration)
      .duration(graph.isDragging ? 0 : graph.animationDuration)
      .attr('x1', node.x(graph.value))
      .attr('y1', node.y(graph.active.id))
      .attr('x2', node.x(graph.value))
      .attr('y2', node.y(graph.active.id) + node.y.bandwidth())

    // Cursor percent
    node.cursorPercent
      .transition()
      .duration(graph.animationDuration)
      .duration(graph.isDragging ? 0 : graph.animationDuration)
      .attr('x', node.x(graph.active.xMax))
      .attr('y', node.y(graph.active.id) + node.y.bandwidth() / 2)
      .text('(' + (graph.stepLabel || d3.format('.0%')(graph.percentValue)) + ')')

    // Cursor value
    node.cursorValue
      .transition()
      .duration(graph.animationDuration)
      .duration(graph.isDragging ? 0 : graph.animationDuration)
      .attr('x', node.x(graph.value) + 5)
      .attr('y', node.y(graph.active.id) - 11)
      .text(formatLabel('$,', graph.value))
  }

  // Enable animation only after first rendering
  graph.animationDuration = 150
}

export default {
  components: {
    Checkbox,
    OperationInput
  },
  props: {
    wage: Object,
    grid: Object,
    simulationLevels: {
      type: Array,
      default() {
        return []
      }
    },
    height: {
      type: Number,
      default: 250
    },
    showWage: {
      type: Boolean,
      default: true
    },
    showAdjustedCheckbox: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      // Options
      viewport: {
        width: 0, // Computed
        height: this.height,
        innerWidth: 0, // Computed
        innerHeight: 0, // Computed
        padding: {
          left: 150,
          right: 25,
          top: 22,
          bottom: 5
        }
      },
      // State
      graph: null,
      resizeFn: null,
      valueModel: 0,
      percentModel: 0,
      isManualModel: false
    }
  },
  mounted() {
    this.init()
    this.render()
    this.resizeFn = debounce(this.render, 100)
    window.addEventListener('resize', this.resizeFn)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeFn)
  },
  watch: {
    'wage.overridenSalaryValue': 'render',
    'wage.levelIds': 'render',
    grid: 'render',
    padding: 'render'
  },
  computed: {
    intervalComponent() {
      const simulationLevels = this.simulationLevels
      return getIntervalComponent(getWageDetails(this.grid, this.wage, { simulationLevels, includeComponents: true }))
    },
    stepComponent() {
      return this.intervalComponent.linkedComponents.find(isIntervalStepsScope)
    },
    isStartPositionMedian() {
      return isIntervalStartPositionMedian(this.intervalComponent)
    },
    isPercentVisible() {
      return !this.stepComponent
    }
  },
  methods: {
    init() {
      this.graph = initGraph(this.$refs.svgNode, this.viewport)
    },
    initModel(graph, wage) {
      this.valueModel = graph.value
      this.percentModel = Math.round(graph.percentValue * 100)
      this.isManualModel = !!wage.overridenSalaryValue
    },
    onLevelChange(id) {
      let level = this.intervalComponent.levels.find(l => l.id === id)
      let selectedLevel = this.intervalComponent.selectedLevel

      if (!level && this.stepComponent) {
        level = this.stepComponent.levels.find(l => l.id === id)
        selectedLevel = null
      }

      if (level && (!selectedLevel || selectedLevel.id !== id)) {
        this.$emit('selectLevel', level)
      }
    },
    onPercentChange(percentValue) {
      const active = this.graph.active
      const value = this.isStartPositionMedian
        ? (percentValue >= 0
            ? Math.round(((active.xMax - active.x) * (percentValue / 100) + active.x) / 10) * 10
            : Math.round(((active.x - active.xMin) * (percentValue / 100) + active.x) / 10) * 10)
        : Math.round(((active.xMax - active.xMin) * (percentValue / 100) + active.xMin) / 10) * 10
      this.onChange(value)
    },
    onManualChange() {
      if (!this.isManualModel) {
        this.onChange(null)
      }
    },
    onChange(value) {
      this.$emit('change', value)
    },
    onLevelValueChange(levelId, minimumValue, salaryValue, maximumValue) {
      this.$emit('updateLevel', { levelId, minimumValue, salaryValue, maximumValue })
    },
    render() {
      generateSliderModel(this.graph, this.wage, this.grid, this.simulationLevels, this.showWage, this.isStartPositionMedian)
      this.initModel(this.graph, this.wage)
      renderGraph(this.graph, this.onChange, this.onLevelChange, this.onLevelValueChange)
    }
  }
}

</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/graph.scss";

.wage-interval-slider {
  background: white;
  line-height: 0;
}

header {
  padding: 10px 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  label {
    @include font-normal-size;
    @include line-regular-height;
  }

  .operation-input::v-deep {
    height: 28px;
    line-height: 28px;
    // border-radius: 4px;
  }

  .operation-input.percent-input {
    margin-left: 0.5em;

    &,
    &::v-deep input {
      width: 92px;
    }
  }

  .checkbox {
    line-height: 19px;
    margin-right: 18px;
  }
}

svg {
  background: white;
  border-radius: calc($border-radius / 2);
  user-select: none;
  overflow: hidden;
}

svg::v-deep rect.highlight {
  fill: hsl(293deg 37% 78% / 20%);
}

svg::v-deep .cursor-shadow,
svg::v-deep .cursor {
  stroke-linecap: round;
  stroke-linejoin: round;
  cursor: col-resize;
  transform: translateY(-0.5px);
}

svg::v-deep .cursor-shadow {
  stroke-width: 10px;
  stroke: lighten($graph-purple-color, 30);
  opacity: 0.5;
}

svg::v-deep .cursor {
  stroke-width: 6px;
  stroke: lighten($graph-purple-color, 3);
}

svg::v-deep .cursor-container text.percent {
  @include font-smaller-size;
  @include font-medium;
  fill: lighten($graph-purple-color, 10);
  font-smoothing: antialiased;
  text-anchor: start;
  transform: translate(52px, 5px);
  text-shadow: none;
}

svg::v-deep .cursor-container text.value {
  @include font-normal-size;
  @include font-semibold;
  fill: $graph-purple-color;
}

svg::v-deep .abscissa .tick {
  shape-rendering: crispedges;
  stroke: $graph-inner-border-color;
  stroke-dasharray: 3 3;
  opacity: 0.8;
}

svg::v-deep .abscissa .label {
  fill: $light-text-color;
}

svg::v-deep .levels rect.level {
  cursor: pointer;
  fill: darken($graph-lightblue-color, 0);

  &.active {
    fill: lighten($graph-purple-color, 30);
  }
}

svg::v-deep .levels rect.shadow {
  fill: darken($graph-lightblue-color, 7);
  transform: translate(0, 1.5px);

  &.active {
    fill: lighten($graph-purple-color, 20);
  }
}

svg::v-deep .levels line.tick {
  pointer-events: none;
  stroke: lighten($graph-inner-border-color, 0);
  cursor: pointer;
  stroke-width: 1.5px;

  &.active {
    stroke: lighten($graph-purple-color, 20);
  }
}

svg::v-deep .levels text.label {
  text-anchor: start;
  transform: translateY(5px);
  cursor: pointer;

  &.active {
    @include font-semibold;
    fill: $graph-purple-color;
  }
}

svg::v-deep .levels text.bound {
  @include font-smaller-size;
  fill: darken($graph-blue-color, 35);
  font-smoothing: antialiased;
  cursor: pointer;

  &.active {
    @include font-medium;
    fill: lighten($graph-purple-color, 10);
  }

  &.min {
    text-anchor: end;
    transform: translate(-7px, 5px);
  }
  &.max {
    text-anchor: start;
    transform: translate(7px, 5px);
  }
}
</style>
