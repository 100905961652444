<template>
  <div>
    <menu class="float-right">
      <loading-button
        class="secondary"
        :loading="isLoading"
        @click="createWagePlan()">
        <span v-t="'common.create'"></span>
      </loading-button>
    </menu>
    <h3 v-t="'wagePlans.wagePlans'"></h3>
    <div class="plans">
      <div
        class="plan"
        v-for="wagePlan in enrichedWagePlans"
        :key="wagePlan.id"
        @click="openWage($event, wagePlan)">
        <checkbox :checked="isWagePlanSelected(wagePlan)">
          {{wagePlan.name}}
        </checkbox>
        <div class="employees-badge">
          <div class="employees" :class="{empty: !wagePlan.wages.length}">
            {{$tc('wagePlans.includedEmployees', wagePlan.wages.length, {count: wagePlan.wages.length})}}
          </div>
          <div class="badge-container">
            <div
              v-if="wagePlan.postPayrollRise !== 0"
              class="badge border-badge delta-badge"
              :class="{higher: wagePlan.postPayrollRise > 0, lower: wagePlan.postPayrollRise < 0}">
              {{ wagePlan.postPayrollRise | formattedCurrency(false, {signDisplay: "always"}) }}
            </div>
            <div v-else class="badge border-badge delta-badge" v-t="'common.unchanged'"></div>
          </div>
        </div>
      </div>
      <div
        v-if="!enrichedWagePlans.length"
        class="alert placeholder"
        v-t="'wagePlans.placeholder'">
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Checkbox from '@components/commons/Checkbox.vue'

export default {
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      enrichedWagePlans: 'wagePlans/getEnrichedWagePlans',
      isWagePlanSelected: 'wagePlans/isWagePlanSelected'
    })
  },
  methods: {
    openWage({ target, offsetX }, { id }) {
      switch (true) {
        case target.nodeName === 'LABEL':
          return
        case target.nodeName === 'INPUT':
          this.$store.dispatch('wagePlans/toggleWagePlanSelection', { id })
          return
        default:
          this.$router.push({ name: 'wagePlan', params: { id } })
      }
    },
    createWagePlan() {
      this.isLoading = true
      this.$store.dispatch('wagePlans/createWagePlan').then(({ id }) => {
        this.isLoading = false
        this.$router.push({ name: 'wagePlan', params: { id } })
      })
        .catch(error => {
          this.isLoading = false
          error && alert(error)
        })
    }
  },
  components: {
    Checkbox
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/plans.scss";
</style>
