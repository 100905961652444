import Vue from 'vue'

Vue.mixin({
  computed: {
    $$hasSession() {
      return this.$store.getters.hasSession
    },
    $$isAdmin() {
      return this.$store.getters['account/isAdmin']
    },
    $$isManager() {
      return this.$store.getters['account/isManager']
    },
    $$isAtLeastManager() {
      return this.$store.getters['account/isAtLeastManager']
    },
    $$hasOpenAccess() {
      return this.$store.getters['account/hasOpenAccess']
    },
    $$hasPermission() {
      return permission => this.$store.getters['account/hasPermission'](permission)
    },
    $$hasUpdatePermission() {
      return employee => this.$$isAtLeastManager && !this.$$isOneselfRoute && employee && !employee.isReadOnly
    },
    $$isCreator() {
      return model => this.$$isAdmin || model.creatorId === this.$store.getters['account/getUser'].id
    },
    $$companyName() {
      return this.$store.getters['account/getCompany'].name
    },
    $$isDemoSession() {
      return this.$store.getters.isDemoSession
    },
    $$hasGrid() {
      return this.$store.getters['currentGrid/hasGrid']
    }
  }
})
