import Vue from 'vue'
import api from '@/store/api'
import { normalizedBenefitsPlans } from '@/store/schema'

const initialState = () => ({
  benefitsPlans: null,
  benefitsPlanIds: null,
  currentBenefitsPlan: null,
  currentBenefits: null
})

const state = initialState()

const getters = {
  isLoaded({ benefitsPlans }) {
    return benefitsPlans !== null
  },
  getBenefitsPlans({ benefitsPlanIds, benefitsPlans }) {
    return benefitsPlanIds && benefitsPlanIds.map(id => benefitsPlans[id])
  },
  getCurrentBenefitsPlan({ currentBenefitsPlan, currentBenefits }, getters) {
    return currentBenefitsPlan && {
      ...currentBenefitsPlan,
      benefits: getters.getBenefitsFromCurrentPlan
    }
  },
  getBenefitsFromCurrentPlan({ currentBenefitsPlan, currentBenefits }) {
    if (!currentBenefitsPlan.benefits) {
      return []
    }

    return currentBenefitsPlan.benefits.map(id => currentBenefits[id]).sort((a, b) => a.name.localeCompare(b.name))
  },
  getCurrentBenefitsPlanName({ currentBenefitsPlan }) {
    return currentBenefitsPlan && currentBenefitsPlan.name
  }
}

const actions = {
  reset({ commit }) {
    commit('reset')
  },
  resetCurrentBenefitsPlan({ commit }) {
    commit('setCurrentBenefitsPlan', null)
  },
  async getBenefitsPlans({ dispatch, commit }) {
    try {
      const { data } = await api.get('/benefits_plans')
      commit('setBenefitsPlans', data)
      return data
    }
    catch (error) {
      dispatch('handleAPIError', error, { root: true })
    }
  },
  async getBenefitsPlan({ commit, dispatch, state }, benefitsPlanId) {
    if (state.currentBenefitsPlan && state.currentBenefitsPlan.id === benefitsPlanId) {
      return state.currentBenefitsPlan
    }

    try {
      const { data } = await api.get(`/benefits_plan/${benefitsPlanId}`)
      commit('setCurrentBenefitsPlan', data)
      return data
    }
    catch (error) {
      dispatch('handleAPIError', error, { root: true })
      throw error
    }
  },
  async createBenefitsPlan({ commit, dispatch }, params) {
    try {
      const { data } = await api.post('/benefits_plan', params)
      commit('setCurrentBenefitsPlan', data)
      return data
    }
    catch (error) {
      throw await dispatch('handleAPIError', error, { root: true })
    }
  },
  async updateBenefitsPlan({ commit, dispatch }, benefitsPlan) {
    try {
      const { data } = await api.patch(`/benefits_plan/${benefitsPlan.id}`, benefitsPlan)
      commit('setCurrentBenefitsPlan', data)
      return data
    }
    catch (error) {
      throw await dispatch('handleAPIError', error, { root: true })
    }
  },
  async deleteBenefitsPlan({ commit, dispatch }, benefitsPlanId) {
    try {
      await api.delete(`benefits_plan/${benefitsPlanId}`)
      commit('removeBenefitsPlan', benefitsPlanId)
      dispatch('employees/needsRefresh', null, { root: true })
    }
    catch (error) {
      throw await dispatch('handleAPIError', error, { root: true })
    }
  },
  async setBenefitsPlanEmployees({ commit, dispatch, state }, { employeeIdsToAdd, employeeIdsToRemove }) {
    try {
      const path = `/benefits_plan/${state.currentBenefitsPlan.id}/employees`
      await api.put(`${path}/assign`, { employeeIds: employeeIdsToAdd })
      const { data } = await api.put(`${path}/unassign`, { employeeIds: employeeIdsToRemove })

      dispatch('employees/needsRefresh', null, { root: true })
      commit('setCurrentBenefitsPlan', data)
    }
    catch (error) {
      throw await dispatch('handleAPIError', error, { root: true })
    }
  },
  async createBenefit({ commit, dispatch, state }, params) {
    try {
      const { data } = await api.post(`benefits_plan/${state.currentBenefitsPlan.id}/benefit`, params)
      commit('addBenefitToPlan', data)
      dispatch('employees/needsRefresh', null, { root: true })
      return data
    }
    catch (error) {
      throw await dispatch('handleAPIError', error, { root: true })
    }
  },
  async updateBenefit({ commit, dispatch }, benefit) {
    try {
      const { data } = await api.patch(`/benefit/${benefit.id}`, benefit)
      commit('updateBenefit', data)
      dispatch('employees/needsRefresh', null, { root: true })
      return data
    }
    catch (error) {
      throw await dispatch('handleAPIError', error, { root: true })
    }
  },
  async deleteBenefit({ commit, dispatch }, benefitId) {
    try {
      await api.delete(`/benefit/${benefitId}`)
      commit('removeBenefitFromPlan', benefitId)
      dispatch('employees/needsRefresh', null, { root: true })
    }
    catch (error) {
      throw await dispatch('handleAPIError', error, { root: true })
    }
  }
}

const mutations = {
  reset(state) {
    Object.assign(state, initialState())
  },
  setBenefitsPlans(state, benefitsPlans) {
    const n = normalizedBenefitsPlans(benefitsPlans)
    state.benefitsPlanIds = n.result
    state.benefitsPlans = n.entities.benefitsPlan
  },
  setCurrentBenefitsPlan(state, benefitsPlan) {
    if (!benefitsPlan) {
      state.currentBenefitsPlan = null
      state.currentBenefits = null
    }
    else {
      const n = normalizedBenefitsPlans([benefitsPlan])
      state.currentBenefitsPlan = Object.values(n.entities.benefitsPlan)[0]
      state.currentBenefits = n.entities.benefit || []

      if (!state.currentBenefitsPlan.benefits) {
        state.currentBenefitsPlan.benefits = []
      }
    }
  },
  removeBenefitsPlan({ benefitsPlanIds }, benefitsPlanId) {
    benefitsPlanIds = benefitsPlanIds && benefitsPlanIds.filter(id => id !== benefitsPlanId)
  },
  addBenefitToPlan({ currentBenefitsPlan, currentBenefits }, benefit) {
    Vue.set(currentBenefits, benefit.id, benefit)
    currentBenefitsPlan.benefits.push(benefit.id)
  },
  updateBenefit({ currentBenefits }, benefit) {
    currentBenefits[benefit.id] = benefit
  },
  removeBenefitFromPlan({ currentBenefitsPlan }, benefitId) {
    currentBenefitsPlan.benefits = currentBenefitsPlan.benefits.filter(id => id !== benefitId)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
