<template>
  <div class="scrollable-panel">
    <div
      ref="panel"
      class="scrollable-panel-inner"
      :style="`width: ${width}px; max-width: ${width}px`">
      <slot />
    </div>
    <div v-if="isScrollableLeft" class="scroll-left fade-in" @click="scrollTo(false)"></div>
    <div v-if="isScrollableRight" class="scroll-right fade-in" @click="scrollTo(true)"></div>
  </div>
</template>

<script>
import animateScrollTo from 'animated-scroll-to'
import debounce from 'lodash.debounce'

export default {
  props: {
    width: Number
  },
  data() {
    return {
      isScrollableRight: false,
      isScrollableLeft: false
    }
  },
  methods: {
    scrollTo(right) {
      const { scrollWidth, offsetWidth } = this.$refs.panel
      const scrollLeft = right ? scrollWidth - offsetWidth : 0
      animateScrollTo([scrollLeft, null], {
        elementToScroll: this.$refs.panel,
        minDuration: 500
      })
    },
    computeScrollable: debounce(function() {
      if (!this.$refs.panel) {
        return
      }
      const { scrollWidth, scrollLeft, offsetWidth } = this.$refs.panel
      this.isScrollableLeft = scrollLeft > 0
      this.isScrollableRight = (scrollLeft + offsetWidth) < scrollWidth
    }, 200)
  },
  watch: {
    width: 'computeScrollable'
  },
  beforeDestroy() {
    this.$refs.panel.removeEventListener('scroll', this.computeScrollable)
  },
  mounted() {
    this.$refs.panel.addEventListener('scroll', this.computeScrollable)
    this.computeScrollable()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";

.scrollable-panel {
  position: relative;
}

.scrollable-panel-inner {
  padding: 0 1em 1em 0.5em;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  flex-flow: row nowrap;
  background: linear-gradient(
    0deg,
    rgba(234, 237, 240, 0.3) 0%,
    rgba(255, 255, 255, 1) 30%,
    rgba(255, 255, 255, 1) 100%
  );
}

.scroll-left,
.scroll-right {
  background: transparent url(~@/assets/scroll-right.svg) no-repeat center center;
  position: absolute;
  bottom: 12px;
  right: 0;
  width: 70px;
  height: 70px;
  cursor: pointer;

  &:hover {
    background-image: url(~@/assets/scroll-right-active.svg)
  }
}

.scroll-left {
  left: 0;
  right: auto;
  transform: scaleX(-1);
}
</style>
