<template>
  <div class="valorisation-summary">
    <div v-if="benefit" class="valorisation-section">
      <h2 v-t="'benefits.valorisationSummary.title'" />
      <div>
        <div :class="badgeLayout">
          {{ benefit.details.value | formattedCurrency }}
        </div>
        <div
          class="recurrence"
          v-if="benefit.details.recurrence !== 'none'"
          v-t="`benefits.valorisationSummary.periods.${benefit.details.recurrence}`" />
      </div>
    </div>
    <div v-if="benefit" class="valorisation-section">
      <h2 v-t="'benefits.valorisationSummary.coverage'" />
      <div class="green">
        {{ benefit.details.percentage * 100 | formattedNumber(0,2) }}&nbsp;%
      </div>
    </div>
    <div v-if="valorisation" class="valorisation-section">
      <div class="title">
        <h2 v-if="estimationTitle">{{estimationTitle}}</h2>
        <h2 v-else v-t="'benefits.valorisationSummary.estimation'" />
        <Tooltip
          class="green-icon"
          :class="{left: inModal}"
          :message="$t('benefits.valorisationSummary.estimationInformation')" />
      </div>
      <div class="value-block">
        <div v-if="(!benefit || benefit.details.recurrence !== 'monthly') &&
          (!selectedRecurrence || selectedRecurrence === 'monthly') ">
          <div :class="badgeLayout">
            ~&nbsp;{{ valorisation.monthly | formattedCurrency }}
          </div>
          <div class="recurrence" v-t="'benefits.valorisationSummary.periods.monthly'" />
        </div>
        <div v-if="(!benefit || benefit.details.recurrence !== 'yearly')  &&
        (!selectedRecurrence || selectedRecurrence === 'yearly')">
          <div :class="badgeLayout">
            ~&nbsp;{{ valorisation.yearly | formattedCurrency }}
          </div>
          <div class="recurrence" v-t="'benefits.valorisationSummary.periods.yearly'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tooltip from '@components/commons/Tooltip'

export default {
  components: {
    Tooltip
  },
  props: {
    valorisation: Object,
    benefit: Object,
    estimationTitle: String,
    badgeLayout: {
      type: String,
      default: 'standard'
    },
    inModal: {
      type: Boolean,
      default: false
    },
    selectedRecurrence: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/badge.scss";

.valorisation-summary {
  @extend .badge-block;
  display: flex;
  padding: 0;
  margin-right: 1em;
}

.valorisation-section {
  margin-right: 1em;
  padding: 0.6em 1em;
  align-items: center;

  .title {
    display: flex;
  }

  h2 {
    @include font-normal-size;
    margin: 0;
    margin-right: 0.2em;
    margin-bottom: 0.5em;
  }

  .value-block {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 1.5em;
  }

  .standard {
    @extend .badge, .large-badge;
    border: 1px solid $clearteal-color;
  }

  .green {
    @extend .badge, .large-badge, .delta-badge, .higher;
    border: 1px solid $green-color;
  }

  .recurrence {
    display: inline-block;
    margin-left: 0.55em;
    text-align: right;
    color: $light-text-color;
  }
}
</style>
