<template>
  <div class="container">
    <h2 v-t="'sandbox.form.createNewGrid'"></h2>
    <div class="form">
      <div class="form-section">
        <h3 v-t="'sandbox.form.gridType'"></h3>
        <template
          v-for="gridType in gridTypes"
          v-if="(gridType.value == 'clone' && hasCurrentGrid) || gridType.value != 'clone'">
          <input
            type="radio"
            :value="gridType.value"
            :id="gridType.value"
            :key="gridType.value"
            v-model="selectedGridType"
            @click="$refs.nameInput.focus()">
          <label
            :for="gridType.value"
            :key="[gridType.value, 'label'].join()">
            <strong>{{ gridType.title }}</strong>
            <br>
            {{ gridType.description }}
          </label>
        </template>
      </div>
      <div class="form-section">
        <h3 v-t="'sandbox.form.details'"></h3>
        <div class="grid-name">
          <div class="input-label" v-t="'sandbox.form.name'"></div>
          <input
            type="text"
            v-model="gridModel.name"
            @keyup.enter="submit()"
            ref="nameInput"
            :placeholder="defaultName"
            :class="{'input-error' : $v.gridModel.name.$error}">
        </div>
        <div class="template-grid" v-if="selectedGridType == 'template'">
          <div class="input-label" v-t="'sandbox.form.model'"></div>
          <select v-model="selectedTemplateGrid" :class="{'select-error' : $v.selectedTemplateGrid.$error}">
            <template v-if="templateGrids.length > 0 && selectedTemplateGrid">
              <option
                disabled
                :value="null"
                key="disabled"
                v-t="'sandbox.form.selectModel'"></option>
              <option
                v-for="templateGrid in templateGrids"
                :key="templateGrid.ref"
                :value="templateGrid">
                {{ templateGrid.name }}
              </option>
            </template>
            <option v-else disabled :value="null">•••</option>
          </select>
          <div class="template-description" v-if="selectedTemplateGrid">
            <div class="input-label" v-t="'sandbox.form.description'"></div>
            <markdown-text v-model="selectedTemplateGrid.description" />
          </div>
        </div>
        <menu>
          <button class="secondary" @click="$router.push('/grid/sandbox')" :disabled="isLoading" v-t="'sandbox.form.cancel'"></button>
          <loading-button
            class="primary"
            @click="submit()"
            :loading="isLoading">
            <span v-t="'common.create'"></span>
          </loading-button>
        </menu>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import MarkdownText from '@components/commons/MarkdownText'

export default {
  components: {
    MarkdownText
  },
  data() {
    return {
      gridModel: null,
      gridTypes: [
        {
          title: this.$t('sandbox.form.evolutionModel.title'),
          description: this.$t('sandbox.form.evolutionModel.description'),
          value: 'clone'
        },
        {
          title: this.$t('sandbox.form.inspirationModel.title'),
          description: this.$t('sandbox.form.inspirationModel.description'),
          value: 'template'
        },
        {
          title: this.$t('sandbox.form.fromScratchModel.title'),
          description: this.$t('sandbox.form.fromScratchModel.description'),
          value: 'blank'
        }
      ],
      isLoading: false,
      selectedGridType: 'template',
      selectedTemplateGrid: null
    }
  },
  computed: {
    templateGrids() {
      return this.$store.getters['sandboxList/templateGrids']
    },
    hasCurrentGrid() {
      return this.$store.getters['currentGrid/getCurrentGrid'] != null
    },
    companyName() {
      return this.$store.getters['account/getCompany'].name
    },
    defaultName() {
      return this.$t('sandbox.form.namePlaceholder', { companyName: this.companyName }) +
             ` #${this.$store.getters['sandboxList/grids'].length + 1}`
    }
  },
  methods: {
    submit() {
      this.$v.$touch()

      if (!this.$v.$error) {
        this.isLoading = true

        switch (this.selectedGridType) {
          case 'template':
            this.gridModel.templateRef = this.selectedTemplateGrid.ref
            break
          case 'clone':
            this.gridModel.gridId = this.$store.getters['currentGrid/getCurrentGrid'].id
            break
          default:
        }

        this.$store.dispatch('sandboxList/create', this.gridModel)
          .then(grid => {
            this.$store.commit('sandbox/setGrid', grid)
            this.$store.commit('sandbox/setName', grid.name)

            this.$router.push({
              name: 'sandboxEdit',
              params: { id: grid.id }
            })
          })
          .catch(_ => {
            this.isLoading = false
          })
      }
    }
  },
  created() {
    this.$store.dispatch('sandboxList/getTemplateGrids')
      .then(r => {
        this.selectedTemplateGrid = this.templateGrids.length > 0 ? this.templateGrids[0] : null
      })

    this.gridModel = Object.assign({}, this.$store.state.sandboxList.gridModel)
    this.gridModel.name = this.defaultName
  },
  mounted() {
    this.$refs.nameInput.focus()
    this.selectedGridType = this.hasCurrentGrid ? 'clone' : 'template'
  },
  validations() {
    const validations = { gridModel: { name: { required } } }

    if (this.selectedGridType === 'template') {
      validations.selectedTemplateGrid = { required }
    }

    return validations
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";
@import "./src/styles/link.scss";

.container {
  @include tab-container;
}

.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  grid-gap: 20px;
  margin: 20px 0;
}

.form-section {
  border: $border;
  border-radius: $border-radius;
  padding: 20px;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

menu {
  margin-top: 20px;
}

hr {
  display: block;
  border: 0;
  border-top: $border;
  height: 1px;
  margin: 10px 0 30px 0;
}

label {
  cursor: pointer;
  display: block;
  margin: 0 0 10px 0;
  padding: 20px;
  border-radius: $border-radius;
  @include line-regular-height;

  &:last-child {
    margin: 0;
  }

  &:hover {
    background: $lightteal-color;
  }
}

input {
  &[type="radio"] {
    display: none;
  }
}

input[type="radio"]:checked + label {
  background: $lightteal-color;
}

.template-grid {
  margin-top: 20px;
}

.template-description {
  margin-top: 20px;

  p {
    margin: 0;
    padding: 0 10px;
    border: $border;
    border-radius: $border-radius;
  }
}
</style>
