<template>
  <transition name="slide-fade" appear>
    <div class="admin-banner print-hidden" v-if="!isDimissed">
      <div class="admin-banner-title">
        <div v-html="message"></div>
      </div>
      <menu class="admin-actions">
        <div class="logout" @click="signOut" v-t="'account.signout'"></div>
        <div class="close-button" @click="isDimissed = true">×</div>
      </menu>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      isDimissed: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'account/getUser',
      isSessionReadOnly: 'isSessionReadOnly'
    }),
    message() {
      return this.$t('account.loggedAsBanner' + (this.isSessionReadOnly ? 'ReadOnly' : ''), {
        userName: this.user.firstName + ' ' + this.user.lastName,
        companyName: this.user.company.name
      })
    }
  },
  methods: {
    async signOut() {
      await this.$store.dispatch('account/signOut')
      // Will only work if the tab was opened programmatically (backoffice),
      // which is the expected behavior:
      window.close()
      // Otherwise, go back to settings:
      location.href = '/settings/company/access-rights'
    }
  }
}
</script>

<style lang="scss" scoped>
.admin-banner {
  width: 100%;
  background: $lightred-color;
  color: $red-color;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid darken($lightred-color, 5);
}

.admin-banner-title::v-deep span {
  @include font-semibold;
}

.admin-actions {
  margin: 0;

  .logout {
    text-transform: uppercase;
    cursor: pointer;
    @include font-small-size;
    @include font-bold;
    border-radius: $border-radius;
    display: inline-block;
    padding: 0 10px;
    line-height: 20px;
    border: 1px solid lighten($red-color, 15);

    &:hover {
      background: white;
    }
  }

  .close-button {
    display: inline-block;
    @include font-big-size;
    @include font-bold;
    box-sizing: content-box;
    border: 1px solid lighten($red-color, 10);
    border-radius: 100%;
    cursor: pointer;
    width: 20px;
    line-height: 20px;
    text-align: center;
    margin: 0 1em 0 0.75em;

    &:hover {
      background: white;
      border-radius: 15px;
    }

    &:active {
      background: darken($graph-lightblue-color, 5);
    }
  }
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 300ms ease-out;
}

.slide-fade-enter,
.slide-fade-leave-to {
  margin-top: -45px;
  opacity: 0;
}
</style>
