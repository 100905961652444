import { slugify } from '@/utils/string'
import groupBy from 'lodash.groupby'
import sortBy from 'lodash.sortby'
import cloneDeepWith from 'lodash.clonedeepwith'

function updateEmployees(employees, field, oldValue, newValue) {
  employees.filter(e => e[field] === oldValue).forEach(employee => {
    employee.isUpdated = true
    employee[field] = newValue
  })
}

const AVAILABLE_FIELDS = ['jobType', 'managementLevel', 'city', 'contractType', 'spc', 'department']

const state = {}

const getters = {
  getDefaultReviewFields(_state, _, __, rootGetters) {
    const employees = rootGetters['employees/getEmployees']
    return AVAILABLE_FIELDS.map(field => {
      const groupedEmployees = groupBy(employees, e => slugify(e[field]))
      const slugValues = Object.keys(groupedEmployees)
        .filter(v => v !== 'null')
        .sort()

      if (slugValues.length) {
        return {
          id: field,
          selected: [],
          values: slugValues.map(slug => {
            // Values with the same slug will be merged with the most common value.
            const employeesValues = groupedEmployees[slug].map(e => e[field])
            const employees = groupedEmployees[slug]
            const groupedValues = groupBy(employeesValues)
            const mostCommonValue = sortBy(Object.keys(groupedValues), v => -groupedValues[v].length)[0]
            const merged = employeesValues.filter(v => v !== mostCommonValue)

            return {
              id: slug,
              initial: mostCommonValue,
              current: mostCommonValue,
              merged,
              employees
            }
          })
        }
      }
    }).filter(f => !!f)
  }
}

const actions = {
  updateReviewFields(context, reviewFields) {
    const employees = cloneDeepWith(context.rootGetters['employees/getEmployees'])

    reviewFields.forEach(field => {
      field.values.forEach(value => {
        if (value.initial !== value.current) {
          updateEmployees(employees, field.id, value.initial, value.current)
        }
        value.merged.forEach(mergedValue => {
          updateEmployees(employees, field.id, mergedValue, value.current)
        })
      })
    })

    const updatedEmployees = employees.filter(e => e.isUpdated)
    if (updatedEmployees.length) {
      const promises = updatedEmployees.map(e => context.dispatch('employees/updateEmployee', e, { root: true }))
      return Promise.all(promises)
    }
    else {
      return Promise.resolve()
    }
  }
}

const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
