<template>
  <select
    name="gender"
    v-model="gender"
    @change="update">
      <option value="m" v-t="'employees.fields.gender.value.m'"></option>
      <option value="f" v-t="'employees.fields.gender.value.f'"></option>
  </select>
</template>

<script>
export default {
  props: ['value'],
  data() {
    return {
      gender: this.value
    }
  },
  methods: {
    update() {
      this.$emit('input', this.gender)
    }
  }
}
</script>
