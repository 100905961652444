<template>
  <div class="mapping-editor" :class="{'hidden-name': isNameHidden}">
    <table class="blue">
      <tr>
        <th v-t="'variable.editor.indicator.mapping.name'"></th>
        <th v-t="'variable.editor.indicator.mapping.value'"></th>
      </tr>
      <tr v-for="prop in model"
        :key="prop.id"
        class="row">
        <td
          ref="name"
          contenteditable="true"
          @keydown="onKeyDown(prop, $event, true)"
          @focusout="onNameChange(prop, $event)"
          v-html="prop.name"></td>
        <td
          ref="value"
          contenteditable="true"
          @keydown="onKeyDown(prop, $event)"
          @focusout="onValueChange(prop, $event)"
          v-html="prop.value"></td>
      </tr>
    </table>
  </div>
</template>

<script>
import { buildIndicatorMappingItem } from '@/utils/variable'
import { parseStringFloat } from '@/utils/string'
import { sanitize } from 'dompurify'

export default {
  props: {
    value: Array
  },
  data() {
    return {
      model: []
    }
  },
  computed: {
    isNameHidden() {
      return this.model[0] && this.model[0].nameType === 'hidden'
    }
  },
  methods: {
    initModel() {
      this.model = [...this.value]
    },
    simulateBackTab(node) {
      const tds = [...this.$el.querySelectorAll('td[contenteditable]')]
      const index = tds.indexOf(node)
      if (index - 1 >= 0) {
        tds[index - 1].focus()
        document.execCommand('selectAll', false, null)
        return true
      }
      else {
        return false
      }
    },
    simulateTab(node) {
      const tds = [...this.$el.querySelectorAll('td[contenteditable]')]
      const index = tds.indexOf(node)
      if (index + 1 < tds.length) {
        tds[index + 1].focus()
        document.execCommand('selectAll', false, null)
        return true
      }
      else {
        return false
      }
    },
    onKeyDown(prop, event, isName) {
      if (event.code === 'Backspace') {
        if (!event.target.innerText) {
          if (isName) {
            if (this.model.length > 1 && !prop.single) {
              event.preventDefault()
              const index = this.model.indexOf(prop)
              this.model.splice(index, 1)
              this.simulateBackTab(event.target)
              this.onChange()
            }
          }
          else {
            event.preventDefault()
            this.simulateBackTab(event.target)
          }
        }
      }
      else if (event.code === 'Enter') {
        event.preventDefault()
        if (!this.simulateTab(event.target) && !prop.single) {
          this.model.push(buildIndicatorMappingItem('', ''))
          this.onChange()
          this.$nextTick(() => {
            this.simulateTab(event.target)
          })
        }
      }
    },
    sanitizeText(text) {
      return sanitize(text, { ALLOWED_TAGS: [] })
        .replace(/&nbsp;/g, ' ')
        .replace(/&gt;/g, '>')
        .replace(/&lt;/g, '<')
        .replace(/&.+;/g, '')
        .trim()
    },
    onNameChange(prop, event) {
      prop.name = this.sanitizeText(event.target.innerText)
      if (prop.nameType === 'number' && isNaN(parseStringFloat(prop.name))) {
        prop.name = 0
      }
      this.onChange()
    },
    onValueChange(prop, event) {
      prop.value = this.sanitizeText(event.target.innerText)
      if (isNaN(parseStringFloat(prop.value))) {
        prop.value = 0
      }
      this.onChange()
    },
    onChange() {
      this.$emit('input', this.model)
    }
  },
  watch: {
    value: 'initModel'
  },
  created() {
    this.initModel()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/table.scss";

.mapping-editor {
  border: 1px solid $graph-outer-border-color;
  border-radius: $border-radius;
  margin: 5px 0 3px 0;
  overflow: hidden;

  // Hidden name skin
  &.hidden-name {
    td {
      line-height: 2em;
    }

    tr:first-of-type,
    td:first-of-type {
      display: none;
    }
  }
}

table {
  white-space: nowrap;
  @include font-small-size;
  table-layout: fixed;

  th,
  td {
    @include line-large-height;
    padding: 0 0.5em;
    border: 1px solid $graph-outer-border-color;
    overflow: hidden;
  }

  th {
    @include font-medium;
  }
}
</style>
