<template>
  <div>
    <div class="slow-fade-in" v-if="wage">
      <wage-sharing-viewer :wage="wage" />
    </div>
    <loading-view v-else />
  </div>
</template>

<script>
import LoadingView from '@components/commons/LoadingView'
import WageSharingViewer from '@components/wage/WageSharingViewer'

export default {
  data() {
    return {
      wage: null
    }
  },
  created() {
    this.$store.dispatch('wageSharing/getWage', this.$route.params.token).then(wage => {
      this.$store.commit('account/setCompany', wage.company)
      this.wage = wage
      document.title = wage.sharingConfig.title
    }).catch(e => {
      alert(this.$t('wageSharing.notFoundError'))
      this.$router.push('/')
    })
  },
  components: {
    LoadingView,
    WageSharingViewer
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
</style>
