<template>
  <div class="dashboard-summary">
    <div>
      <h1 class="no-margin-bottom">{{companyName}}</h1>
    </div>
    <div>
      <router-link class="labeled-badge" :to="{name: 'employees'}">
        <label v-t="'dashboard.synthesis.employees'"></label>
        <span class="badge border-badge">
          {{ synthesis.employees.length }}
        </span>
      </router-link>
      <router-link class="labeled-badge" :to="{name: 'employees'}">
        <label v-t="'dashboard.synthesis.payroll'"></label>
        <span class="badge border-badge" v-if="payroll">
          {{ payroll.postPayroll | formattedCurrency }}
        </span>
        <span
          v-if="payroll && payroll.postPayrollRise"
          class="badge border-badge delta-badge"
          :class="{higher: payroll.postPayrollRise > 0, lower: payroll.postPayrollRise < 0}">
          {{ payroll.postPayrollRise | formattedCurrency(false, {signDisplay: "always"}) }}
          <small>({{ payroll.postPayrollRisePercent | formattedNumber(1, 1) }}&nbsp;%)</small>
        </span>
      </router-link>
      <router-link class="labeled-badge" to="/grid">
        <label v-t="'dashboard.synthesis.grid'"></label>
        <span
          v-if="synthesis.grid && synthesis.grid.published"
          class="badge border-badge"
          v-t="{path: 'grid.current.v', args: {version: synthesis.grid.version}}">
        </span>
        <span
          v-else-if="synthesis.grid && synthesis.grid.onboarding"
          class="badge border-badge delta-badge"
          v-t="'grid.editor.workInProgress'">
        </span>
        <span
          v-else
          class="badge border-badge delta-badge"
          v-t="'dashboard.synthesis.toBeDefined'">
        </span>
      </router-link>
    </div>
  </div>
</template>

<script>
import anime from 'animejs/lib/anime.es.js'
import { getSessionItem, setSessionItem } from '@/utils/storage'
import { mapGetters } from 'vuex'

const CW_PREVIOUS_PAYROLL = 'CW_PREVIOUS_PAYROLL'

export default {
  data() {
    return {
      payroll: this.$route.params.highlightPayroll ? getSessionItem(CW_PREVIOUS_PAYROLL) : null
    }
  },
  computed: {
    ...mapGetters({
      synthesis: 'statistics/getSynthesis'
    }),
    updatedPayroll() {
      const { postPayroll, postPayrollRise, postPayrollRisePercent } = this.synthesis
      return { postPayroll, postPayrollRise, postPayrollRisePercent }
    },
    companyName() {
      if (this.$$hasOpenAccess) {
        return this.$$companyName
      }
      else {
        return this.$t('employees.summary.yourTeam')
      }
    }
  },
  methods: {
    savePayroll() {
      setSessionItem(CW_PREVIOUS_PAYROLL, this.payroll)
    },
    generatePayroll(options) {
      if (this.payroll) {
        anime({
          targets: this.payroll,
          round: 1,
          delay: (options && options.delay) || 0,
          duration: 400,
          easing: 'linear',
          ...this.updatedPayroll,
          postPayrollRisePercent: {
            value: this.updatedPayroll.postPayrollRisePercent,
            round: 10
          }
        })
      }
      else {
        this.payroll = this.updatedPayroll
      }
    },
    generatePayrollWithDelay() {
      this.generatePayroll({ delay: 600 })
    }
  },
  watch: {
    updatedPayroll: 'generatePayroll'
  },
  beforeDestroy() {
    this.savePayroll()
  },
  created() {
    this.generatePayrollWithDelay()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/badge.scss";

.dashboard-summary {
  @include line-regular-height;
  padding: 7px 12px 7px 20px;
  background-color: $graph-lightblue-color;
  border: 1px solid $graph-blue-color;
  border-bottom: none;
  margin: -21px -21px 15px -21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: $border-radius $border-radius 0 0;
}

.labeled-badge {
  @extend .badge-block;
  @extend .clickable;
  border: none;
  background: transparent;
  padding: 0.5em;
  position: relative;

  .border-badge {
    border: 1px solid darken($lightteal-color, 30);
  }

  &:not(:first-of-type) {
    margin-left: 20px;

    &::before {
      content: ' ';
      display: inline-block;
      position: absolute;
      width: 1px;
      height: 90%;
      margin-top: -5px;
      margin-left: -18px;
      background: $graph-blue-color;
    }
  }
}
</style>
