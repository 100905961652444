import Vue from 'vue'

Vue.mixin({
  computed: {
    $$isOneselfRoute() {
      return this.$route.name === 'oneself'
    }
  },
  methods: {
    $$openUrl(url) {
      window.open(url)
    }
  }
})
