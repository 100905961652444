<template>
  <input
    v-if="useNativeInput"
    v-model="date"
    type="date"
    @input="updateValue" />
  <cleave
    v-else
    v-model="date"
    :options="options"
    @input="updateValue">
  </cleave>
</template>

<script>
import Cleave from 'vue-cleave-component'
import moment from 'moment'

export default {
  props: ['value'],
  data() {
    return {
      useNativeInput: false,
      date: null,
      options: {
        date: true,
        datePattern: ['d', 'm', 'Y']
      }
    }
  },
  watch: {
    value: 'updateInput'
  },
  methods: {
    isNativeInputAvailable() {
      const input = document.createElement('input')
      input.setAttribute('type', 'date')
      input.setAttribute('value', 'foo')

      return input.value !== 'foo'
    },
    updateValue() {
      const date = moment(this.date, ['DDMMYYYY', 'YYYY-MM-DD'], true)

      if (date.isValid() && date.format('YYYY-MM-DD') !== this.value) {
        this.$emit('input', date.format('YYYY-MM-DD'))
      }
      else if (!this.date) {
        this.$emit('input', this.date)
      }
    },
    updateInput() {
      const rawDate = moment(this.value, 'YYYY-MM-DD', true)

      if (!this.useNativeInput && rawDate.isValid()) {
        this.date = rawDate.format('DD/MM/YYYY')
      }
      else {
        this.date = this.value
      }
    }
  },
  mounted() {
    this.useNativeInput = this.isNativeInputAvailable()

    this.updateInput()
  },
  components: {
    Cleave
  }
}
</script>

<style lang="scss">
@import "./src/styles/form.scss";
</style>
