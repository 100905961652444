<template>
  <div class="form-container">
    <div class="form">
      <div class="section">
        <h3 v-t="'benefits.form.generalInformations'" />
        <p v-t="'benefits.form.generalInformationExplanation'" />
        <div class="form-section">
          <div class="input-label" v-t="'benefits.form.name'" />
          <input
            ref="benefitName"
            type="text"
            v-model="benefitModel.name"
            :class="{'input-error' : $v.benefitModel.name.$error}"
            :disabled="isLoading">
        </div>
        <div class="form-section">
          <div class="input-label" v-t="'benefits.form.category'" />
          <div class="category-section">
            <select
              v-model="benefitModel.details.category"
              :class="{'input-error' : $v.benefitModel.details.category.$error}">
              <option
                v-for="category in categories"
                :key="category"
                :value="category"
                v-t="`benefits.categories.${category}`" />
            </select>
            <BenefitBadge :benefit="benefitModel" />
          </div>
        </div>
        <div class="form-section">
          <h3>
            <span v-t="'benefits.form.valorisation'" />
            <span class="optional" v-t="'benefits.form.optional'" />
          </h3>
          <p v-t="'benefits.form.valorisationExplanation'" />
          <div class="form-section-value">
            <div>
              <div class="input-label">
                <span v-t="'benefits.form.value'" />
              </div>
              <input
                type="number"
                v-model="benefitModel.details.value"
                @input="partChange"
                :disabled="isLoading" />
            </div>
            <div>
              <div class="input-label" v-t="'benefits.form.recurrence'" />
              <select
                type="number"
                v-model="benefitModel.details.recurrence"
                :disabled="isLoading"
                :class="{'input-error' : $v.benefitModel.details.recurrence.$error}">
                <option
                  v-for="recurrence in recurrences"
                  :key="recurrence"
                  :value="recurrence"
                  v-t="`benefits.form.recurrences.${recurrence}`" />
              </select>
            </div>
          </div>
          <div class="form-section-value">
            <div>
              <div class="input-label">
                <span v-t="'benefits.form.percentage'" />
              </div>
              <input
                type="number"
                v-model="percentagePart"
                @input="partChange"
                :disabled="isLoading"
                :class="{'input-error' : $v.percentagePart.$error}" />
            </div>
            <div>
              <div class="input-label">
                <span v-t="'benefits.form.cost'" />
              </div>
              <input
                type="number"
                v-model="cost"
                @input="costChange"
                :disabled="isLoading" />
            </div>
          </div>
        </div>
        <div class="form-section">
          <h3 v-t="'benefits.form.externalLinks'" />
          <p v-t="'benefits.form.externalLinkExplanation'" />
          <BenefitAttachmentsEditor v-model="benefitModel" />
        </div>
      </div>
      <div class="section">
        <h3 v-t="'benefits.form.description'" />
        <p>
          <span v-t="'benefits.form.descriptionExplanation'" /><br>
        </p>
        <MarkdownTextEditor
          :showToolbar="true"
          minHeight="300px"
          v-model="benefitModel.details.description" />
      </div>
    </div>
    <menu>
      <LoadingButton
        class="destructive"
        v-if="benefit"
        :loading="isDeleting"
        @click="remove">
        {{$t('benefits.form.delete')}}
      </LoadingButton>
      <button
        class="secondary"
        v-t="'benefits.form.cancel'"
        @click="$emit('close')" />
      <LoadingButton
        class="primary"
        :loading="isLoading"
        @click="submit">
        {{action}}
      </LoadingButton>
    </menu>
    <div class="error-message" v-if="errorMessage">
      {{errorMessage}}
    </div>
  </div>
</template>

<script>
import BenefitAttachmentsEditor from '@components/benefits/BenefitAttachmentsEditor'
import BenefitBadge from '@components/benefits/BenefitBadge'
import LoadingButton from '@components/commons/LoadingButton'
import MarkdownTextEditor from '@components/commons/MarkdownTextEditor'
import cloneDeepWith from 'lodash.clonedeepwith'
import { required } from 'vuelidate/lib/validators'
import { categories, recurrences } from '@/utils/benefit'

export default {
  components: {
    MarkdownTextEditor,
    BenefitAttachmentsEditor,
    BenefitBadge,
    LoadingButton
  },
  props: {
    benefit: Object
  },
  data() {
    return {
      categories,
      recurrences,
      isLoading: false,
      isDeleting: false,
      errorMessage: null,
      benefitModel: {
        details: {
          category: categories[0],
          links: []
        }
      },
      percentagePart: null,
      cost: null
    }
  },
  computed: {
    action() {
      return this.$t(`benefits.form.${this.benefit ? 'update' : 'add'}`)
    }
  },
  methods: {
    partChange() {
      if (this.benefitModel.details.value && this.percentagePart !== null) {
        this.benefitModel.details.percentage = this.percentagePart / 100
        this.cost = this.benefitModel.details.value * this.benefitModel.details.percentage
      }
    },
    costChange() {
      if (this.benefitModel.details.value) {
        this.percentagePart = this.cost * 100 / this.benefitModel.details.value
        this.benefitModel.details.percentage = this.percentagePart / 100
      }
    },
    async submit() {
      this.$v.$touch()

      if (!this.$v.$error) {
        try {
          this.isLoading = true
          this.error = null

          if (!this.benefitModel.details.value) {
            delete this.benefitModel.details.recurrence
            delete this.benefitModel.details.percentage
          }

          if (this.benefit) {
            await this.$store.dispatch('benefits/updateBenefit', this.benefitModel)
          }
          else {
            await this.$store.dispatch('benefits/createBenefit', this.benefitModel)
          }
          this.$emit('close')
        }
        catch (error) {
          this.isLoading = false
          this.errorMessage = error
        }
      }
    },
    async remove() {
      try {
        if (confirm(this.$t('benefits.form.deleteConfirmation'))) {
          this.isDeleting = true
          this.errorMessage = null
          await this.$store.dispatch('benefits/deleteBenefit', this.benefit.id)
          this.$emit('close')
        }
      }
      catch (error) {
        this.isDeleting = false
        this.errorMessage = error
      }
    }
  },
  mounted() {
    if (this.benefit) {
      this.benefitModel = cloneDeepWith(this.benefit)

      if (this.benefitModel.details.value) {
        this.percentagePart = this.benefitModel.details.percentage !== null ? this.benefitModel.details.percentage * 100 : 100
        this.partChange()
      }
    }
    else {
      this.$refs.benefitName.focus()
    }
  },
  validations() {
    const validations = {
      benefitModel: {
        name: { required },
        details: {
          category: { required },
          recurrence: {}
        }
      },
      percentagePart: {}
    }

    if (this.benefitModel.details.value) {
      validations.benefitModel.details.recurrence = { required }
      validations.percentagePart = { required }
    }

    return validations
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";

.form-container {
  width: 850px;
  padding: 20px;
}

.form {
  display: flex;
}

.section {
  width: 50%;

  &:first-child {
    margin-right: 2em;
  }

  h3 {
    margin: 0;
  }

  p {
    margin: 0.2em 0 1.5em 0;
    color: $light-text-color;
    @include font-small-size;
  }
}

.form-section-value {
  display: flex;
  margin-bottom: 1em;

  div {
    width: 100%;

    &:first-child {
      margin-right: 1em;
    }
  }
}

.category-section {
  display: flex;
  align-items: center;

  select {
    margin-right: 1em;
  }
}

textarea {
  margin: 0;
  border-radius: 15px;
}

menu {
  margin-top: 1em;
}

.optional {
  display: inline-block;
  color: $light-text-color;
  @include font-tiny-size;
  @include font-bold;
  text-transform: uppercase;
  margin-left: 0.5em;
}
</style>
