<template>
  <div class="breadcrumb">
    <ul>
      <li v-for="route in breadcrumbRoutes" :key="route.path">
        <router-link
          :to="{path: route.path}"
          tag="span"
          class="breadcrumb-link"
          :class="{logo: route.isLogo}">
          {{ route.name }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  computed: {
    breadcrumbRoutes() {
      return []
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/link.scss";

.breadcrumb {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  white-space: nowrap;
  margin-left: -10px;
  min-height: 1.5em;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  display: inline;
}

li .breadcrumb-link {
  display: inline-block;
  line-height: 49px;
  padding: 0 10px;
}

li .logo {
  display: none;
}

li + li::before {
  content: "›";
  padding: 2px;
}

.secondary {
  padding: 0.4em 1em;
  min-width: 0;
  margin-right: 0.5em;
}
</style>
