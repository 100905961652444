<template>
  <span>
    <select class="button" v-if="reusableComponents.length" @change="onChange">
      <option>+&nbsp;<span v-t="'sandbox.componentForm.addComponent'"></span></option>
      <option v-t="'sandbox.componentForm.newComponent'"></option>
      <option
        v-for="component in reusableComponents"
        :key="component.id"
        :value="component.id">
        {{ component.name }}
      </option>
    </select>
    <div v-else class="button" @click="$emit('createComponent', remunerationType)">
      +&nbsp;<span v-t="'sandbox.componentForm.addComponent'"></span>
    </div>
  </span>
</template>

<script>
export default {
  props: [
    'remunerationType'
  ],
  computed: {
    reusableComponents() {
      return this.$store.getters['sandbox/reusableComponents'](this.remunerationType)
    }
  },
  methods: {
    onChange(event) {
      const options = event.target.options

      switch (options.selectedIndex) {
        case 0:
          break
        case 1:
          this.$emit('createComponent', this.remunerationType)
          break
        default:
          const component = this.reusableComponents[options.selectedIndex - 2]
          this.$store.dispatch('sandbox/addRemuneration', {
            component,
            remunerationType: this.remunerationType
          })
            .then(component => {
              this.$emit('remunerationAdded', component)
            })
      }

      options.selectedIndex = 0
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  font-family: $font;
  display: inline-block;
  margin-top: 20px;
  box-shadow: 0px 0px 0px 1px $clearteal-color inset;
  color: $clearteal-color;
  @include font-big-size;
  border-radius: $border-radius;
  padding: 2px 10px 2px 10px;
  cursor: pointer;

  &:hover {
    background-color: $lightteal-color;
  }

  &:focus {
    outline: none;
  }
}

select {
  min-width: 200px;
  height: 27px;
  border: none;
  background-color: white;
  text-align: center;
}
</style>
