<template>
  <div class="form settings-form small">
    <h3 v-t="'settings.settingsUserInfo.title'"></h3>
    <form @submit.prevent="update">
      <div class="form-section">
        <div class="input-label" v-t="'employees.employee.form.firstName'"></div>
        <input
          type="text"
          v-model="userModel.firstName"
          :class="{'input-error' : $v.userModel.firstName.$error}">
      </div>
      <div class="form-section">
        <div class="input-label" v-t="'employees.employee.form.lastName'"></div>
        <input
          type="text"
          v-model="userModel.lastName"
          :class="{'input-error' : $v.userModel.lastName.$error}">
      </div>
      <div>
        <p v-if="updateSuccess" class="alert" v-t="'settings.updateSuccess'"></p>
        <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
        <loading-button
          class="primary settings-button"
          :loading="isLoading">
          <span v-t="'settings.update'"></span>
        </loading-button>
      </div>
    </form>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      userModel: {
        firstName: null,
        lastName: null
      },
      errorMessage: null,
      updateSuccess: false,
      isLoading: false
    }
  },
  computed: {
    user() {
      return this.$store.getters['account/getUser']
    }
  },
  methods: {
    update() {
      this.$v.$touch()

      if (!this.$v.$error) {
        this.updateSuccess = false
        this.errorMessage = null
        this.isLoading = true

        this.$store.dispatch('account/updateAccount', this.userModel)
          .then(response => {
            this.isLoading = false
            this.updateSuccess = true
          })
          .catch(error => {
            this.isLoading = false
            this.errorMessage = error
          })
      }
    }
  },
  mounted() {
    this.userModel.firstName = this.user.firstName
    this.userModel.lastName = this.user.lastName
  },
  validations() {
    return {
      userModel: {
        firstName: { required },
        lastName: { required }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/settings.scss";
</style>
