<template>
  <div class="grid-level-picker">
    <div class="input-label">{{component.name}}</div>
    <multiselect
      class="big"
      v-model="levelModel"
      :options="options"
      trackBy="name"
      :close-on-select="true"
      :allow-empty="false"
      :group-values="hasLevelGroups ? 'levels' : undefined"
      :group-label="hasLevelGroups ? 'group' : undefined"
      :group-select="hasLevelGroups ? false : undefined"
      selectLabel=""
      deselectLabel=""
      selectedLabel=""
      :max-height="450"
      :showNoResults="false"
      :placeholder="$t('employees.import.select.placeholder')"
      :custom-label="level => level.name"
      @input="onChange" />
  </div>
</template>

<script>
import { getVisibleLevels } from '@/utils/grid'
import groupBy from 'lodash.groupby'
export default {
  props: {
    component: {
      type: Object,
      default() {
        return {}
      }
    },
    value: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      levelModel: null
    }
  },
  computed: {
    visibleLevels() {
      const levels = getVisibleLevels(this.component && this.component.levels)
      return levels.filter(l => !this.levelModel || !l.linkedLevelId || l.linkedLevelId === this.levelModel.linkedLevelId)
    },
    hasLevelGroups() {
      return !!this.visibleLevels.find(({ group }) => group)
    },
    options() {
      if (this.hasLevelGroups) {
        const groupedLevels = groupBy(this.visibleLevels, 'group')
        return Object.keys(groupedLevels).reduce((memo, group) => {
          memo.push({
            group: group !== 'null' ? group : this.component.name,
            levels: groupedLevels[group]
          })
          return memo
        }, [])
      }
      else {
        return this.visibleLevels
      }
    }
  },
  methods: {
    getVisibleLevels,
    initModel() {
      this.levelModel = this.value
    },
    onChange() {
      this.$emit('input', this.levelModel)
    }
  },
  watch: {
    value: 'initModel'
  },
  created() {
    this.initModel()
  }
}
</script>

<style lang="scss" scoped>
.grid-level-picker {
  display: inline-block;
  min-width: 125px;

  & + .grid-level-picker {
    margin-left: 0.8rem;
  }
}

.multiselect::v-deep {
  display: inline-block;
  width: auto;
  min-width: 100%;
  vertical-align: top;

  .multiselect__single,
  .multiselect__input {
    font-size: 16.5px !important;
    font-weight: 500 !important;
  }

  .multiselect__tags {
    min-width: 100%;
    border: 1px solid $graph-outer-border-color !important;
  }

  // Avoid overflow in dropdown
  .multiselect__content-wrapper {
    min-width: 100%;
    width: auto;
    border-top-right-radius: 5px;
  }

  // Set minimum width for dropdown
  // &.multiselect--active,
  // .multiselect__content-wrapper {
  //   width: 200px;
  // }
}
</style>
