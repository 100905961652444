export const benefitsValorisationSummary = (benefits, employeeCount = 1) => {
  const valorisation = benefits.map(b => benefitValorisationSummary(b, employeeCount))
    .filter(e => e)

  return valorisation.length && valorisation.reduce((acc, val) => ({
    monthly: acc.monthly + val.monthly,
    yearly: acc.yearly + val.yearly
  }))
}

export const benefitsCompanyCostSummary = (benefits, employeeCount = 1) => {
  const valorisation = benefits.map(b => benefitCompanyCostSummary(b, employeeCount))
    .filter(e => e)

  if (valorisation.length) {
    const computedValorisation = valorisation.reduce((acc, val) => ({
      monthly: acc.monthly + val.monthly,
      yearly: acc.yearly + val.yearly,
      percentage: acc.percentage + val.percentage
    }))

    computedValorisation.percentage /= valorisation.length

    return computedValorisation
  }

  return null
}

export const benefitCompanyCostSummary = (benefit, employeeCount = 1) => {
  const valorisation = benefitValorisationSummary(benefit, employeeCount)

  if (valorisation) {
    const percentage = benefit.details.percentage

    valorisation.monthly *= percentage
    valorisation.yearly *= percentage
    valorisation.percentage = percentage
  }

  return valorisation
}

export const benefitValorisationSummary = (benefit, employeeCount = 1) => {
  if (!benefit.details.value) {
    return null
  }

  const value = parseFloat(benefit.details.value)
  const valorisation = {}

  switch (benefit.details.recurrence) {
    case 'daily':
      // Make the asumption daily means 5 work days per week
      valorisation.monthly = value * 5 * 4
      break
    case 'weekly':
      valorisation.monthly = value * 4
      break
    case 'monthly':
      valorisation.monthly = value
      break
    case 'quarterly':
      valorisation.monthly = value / 4
      break
    case 'yearly':
      valorisation.monthly = value / 12
      valorisation.yearly = value
      break
    default:
      return null
  }

  if (!valorisation.yearly) {
    valorisation.yearly = valorisation.monthly * 12
  }

  valorisation.monthly *= employeeCount
  valorisation.yearly *= employeeCount

  return valorisation
}

export const categories = [
  'health',
  'food',
  'housing',
  'publicTransportation',
  'nonMotorisedTransportation',
  'motorisedTransportation',
  'vacation',
  'formation',
  'bonus',
  'retirement',
  'leisure',
  'parenting',
  'supply',
  'misc'
]

export const recurrences = [
  'none',
  'daily',
  'weekly',
  'monthly',
  'quarterly',
  'yearly'
]
