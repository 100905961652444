<template>
  <div>
    <h3 class="title">
      {{$t('grid.editor.component.title')}}
      &nbsp;&nbsp;›
      <InlineEditor
        v-model="componentModel.name"
        :placeholder="componentModel.name"
        :error="$v.componentModel.name.$error"
        @submit="onChange" />
      <template v-if="linkedComponentModel">
        <div class="component-operation">
          {{ remunerationOperationSymbol(linkedComponentModel.salaryOperation) }}
        </div>
        <inline-editor
          class="inline"
          v-model="linkedComponentModel.name"
          :placeholder="linkedComponentModel.name"
          :autoDismiss="true"
          @submit="onChange" />
        <template v-if="linkedLinkedComponentModel">
          <div class="component-operation">
            {{ remunerationOperationSymbol(linkedLinkedComponentModel.salaryOperation) }}
          </div>
          <inline-editor
            class="inline"
            v-model="linkedLinkedComponentModel.name"
            :placeholder="linkedLinkedComponentModel.name"
            :autoDismiss="true"
            @submit="onChange" />
        </template>
      </template>
    </h3>
    <div v-if="!isGridStructureEditor">
      <div class="input-label" v-t="'common.description'"></div>
      <MarkdownTextEditor
        v-model="componentModel.description"
        :placeholder="$t('grid.editor.componentDescription')"
        :showAddButton="true"
        :showInlineEditButton="true"
        :showToolbar="true"
        :autoDismiss="true"
        @change="onChange" />
    </div>
    <div class="component-settings form-section" v-if="isGridStructureEditor">
      <div class="input-label" v-t="'grid.editor.component.settings'"></div>
      <checkbox
        v-if="linkedComponentModel"
        v-model="linkedComponentModel.hasSkills"
        @change="onHasSkillsChange">
        {{$t('grid.editor.component.hasSkillsCheckbox')}}
      </checkbox>
      <checkbox
        v-else
        v-model="componentModel.hasSkills"
        @change="onHasSkillsChange">
        {{$t('grid.editor.component.hasSkillsCheckbox')}}
      </checkbox>
      <resizable-panel :observer="linkedComponentModel && linkedComponentModel.hasSkills">
        <checkbox
          class="other-checkbox"
          v-if="linkedComponentModel && linkedComponentModel.hasSkills"
          v-model="linkedComponentModel.hasSplitDomains"
          @change="onChange">
          {{$t('grid.editor.component.hasSplitDomainsCheckbox')}}
        </checkbox>
        <checkbox
          class="other-checkbox"
          v-if="linkedComponentModel && linkedComponentModel.hasSkills"
          v-model="linkedComponentModel.hasSkillValues"
          @change="onChange">
          {{$t('grid.editor.component.hasSkillValues')}}
        </checkbox>
      </resizable-panel>
      <checkbox
        class="other-checkbox"
        v-if="linkedComponentModel && !linkedLinkedComponentModel"
        v-model="linkedComponentModel.config.interval"
        @change="onChange">
        {{$t('grid.editor.component.hasIntervalsCheckbox')}}
      </checkbox>
    </div>
    <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>
    <menu v-if="$$isAdmin">
      <button
        v-if="isUpdated"
        class="destructive fade-in"
        @click="initModel"
        v-t="'common.reset'"></button>
      <loading-button
        v-if="isGridStructureEditor"
        class="destructive"
        :disabled="isDeleting"
        :loading="isDeleting"
        @click="remove">
        <span v-t="'common.remove'"></span>
      </loading-button>
      <button class="secondary" @click="close" v-t="'common.cancel'"></button>
      <loading-button
        class="primary"
        :disabled="isLoading"
        @click="submit"
        :loading="isLoading">
        <span v-t="'common.update'"></span>
      </loading-button>
    </menu>
  </div>
</template>

<script>
import Checkbox from '@components/commons/Checkbox'
import InlineEditor from '@components/commons/InlineEditor'
import MarkdownTextEditor from '@components/commons/MarkdownTextEditor'
import ResizablePanel from '@components/commons/ResizablePanel'
import { required } from 'vuelidate/lib/validators'
import { remunerationOperationSymbol } from '@/utils/grid'
import cloneDeepWith from 'lodash.clonedeepwith'

export default {
  components: {
    Checkbox,
    InlineEditor,
    MarkdownTextEditor,
    ResizablePanel
  },
  name: 'gridComponentEditor',
  props: {
    payload: {
      type: Object
    },
    isGridStructureEditor: {
      type: Boolean
    }
  },
  data() {
    return {
      componentModel: null,
      linkedComponentModel: null,
      linkedLinkedComponentModel: null,
      errorMessage: null,
      isDeleting: false,
      isUpdated: false,
      isLoading: false,
      isRemovingSkills: false
    }
  },
  methods: {
    remunerationOperationSymbol,
    initModel() {
      const component = this.payload.component
      this.linkedComponentModel = null
      this.linkedLinkedComponentModel = null
      this.componentModel = cloneDeepWith(component)

      if (component.linkedComponents && component.linkedComponents.length) {
        this.linkedComponentModel = cloneDeepWith(component.linkedComponents[0])
        if (this.linkedComponentModel.linkedComponents && this.linkedComponentModel.linkedComponents.length) {
          this.linkedLinkedComponentModel = cloneDeepWith(this.linkedComponentModel.linkedComponents[0])
        }
      }
      this.isRemovingSkills = false
      this.isUpdated = false
    },
    onHasSkillsChange(value) {
      this.isRemovingSkills = !value
      this.onChange()
    },
    onChange() {
      this.isUpdated = true
    },
    close() {
      this.$emit('close')
    },
    async remove() {
      if (confirm(this.$t('sandbox.componentForm.deleteConfirmation', { componentName: this.payload.component.name }))) {
        this.isDeleting = true
        try {
          await this.$store.dispatch('sandbox/removeComponent', this.payload.component)
          this.close()
        }
        catch (error) {
          this.errorMessage = error
        }
        finally {
          this.isDeleting = false
        }
      }
    },
    async submit() {
      this.$v.$touch()

      if (this.$v.$error || this.isLoading) {
        return
      }

      if (this.isRemovingSkills && !window.confirm(this.$t('grid.editor.component.hasSkillsConfirmation'))) {
        return
      }

      this.isLoading = true

      try {
        if (this.linkedLinkedComponentModel) {
          await this.$store.dispatch('sandbox/updateComponent', this.linkedLinkedComponentModel)
        }
        if (this.linkedComponentModel) {
          await this.$store.dispatch('sandbox/updateComponent', this.linkedComponentModel)
        }
        const component = await this.$store.dispatch('sandbox/updateComponent', this.componentModel)
        this.$emit('close', component)
      }
      catch (error) {
        this.errorMessage = error
      }
      finally {
        this.isLoading = false
      }
    }
  },
  watch: {
    'payload.component': 'initModel'
  },
  created() {
    this.initModel()
  },
  validations() {
    const validations = {
      componentModel: {
        name: { required }
      }
    }
    if (this.linkedComponentModel) {
      validations.linkedComponentModel = {
        name: { required }
      }
    }
    return validations
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";

h3.title {
  height: 32px;
  display: flex;
  align-items: center;

  .inline-editor {
    margin-left: 0.4em;
  }
}

.component-settings {
  .input-label {
    margin-bottom: 0.75em;
  }

  .other-checkbox {
    display: block;
    padding: 0.5em 0 0.1em;
  }
}

.component-operation {
  display: inline-block;
  @include font-big-size;
  @include font-tabular-numbers;
  padding: 5px 10px 5px 10px;
  color: $text-color;
}
</style>
