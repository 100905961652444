<template>
  <tr>
    <td class="status" :class="{warning: !isValid}"></td>
    <td class="rank-col">
      <div class="drag-button" :class="{draggable: level != null}" v-if="!isLoading">⸬</div>
      <input
        type="text"
        v-model="levelModel.rank"
        @keyup="updateLevel">
    </td>
    <td class="name-col">
      <input
        type="text"
        v-model="levelModel.name"
        @keyup.enter="addLevel"
        :placeholder="$t('sandbox.levelForm.levelName')"
        @keyup="updateLevel"
        ref="nameInput">
    </td>
    <td
      v-if="linkedLevelName"
      class="linked-level-col">
      {{linkedLevelName}}
    </td>
    <td
      v-for="remunerationType in remunerationTypes"
      :key="remunerationType"
      :class="valueClass(remunerationType + 'Value')"
      class="value-col">
      <span class="operation">{{ operationSymbol(remunerationType) }}</span>
      <number-input
        v-model="levelModel[remunerationType + 'Value']"
        @keyup.native.enter="addLevel"
        @keyup.native="updateLevel">
      </number-input>
      <span class="operation">{{ operationUnit(remunerationType) }}</span>
    </td>
    <td v-if="levelModel.description" class="description-col" @click="descriptionEditorDisplayed = true">
      <span v-t="'sandbox.levelForm.updateDescription'"></span>&nbsp;📝
    </td>
    <td v-else class="description-col light-text" @click="descriptionEditorDisplayed = true">
      <span v-t="'sandbox.levelForm.addDescription'"></span>&nbsp;📝
    </td>
    <td style="text-align:center; width: 40px" class="clickable">
      <span v-if="isLoading">⏳</span>
      <span v-else @click="removeLevel" class="secondary">🗑</span>
    </td>
    <description-modal
      v-if="descriptionEditorDisplayed"
      :title="$t('sandbox.levelForm.descriptionTitle', {levelName: levelModel.name})"
      :description="levelModel.description"
      :loading="isLoading"
      @update="updateDescription"
      @close="descriptionEditorDisplayed = false">
    </description-modal>
  </tr>
</template>

<script>
import { remunerationTypesForComponent, remunerationOperationSymbol, remunerationOperationUnit } from '@/utils/grid'
import NumberInput from '@components/commons/NumberInput.vue'
import DescriptionModal from '@components/grid/legacy/DescriptionModal.vue'
import * as debounce from 'lodash.debounce'

export default {
  data() {
    return {
      levelModel: null,
      isLoading: false,
      descriptionEditorDisplayed: false
    }
  },
  watch: {
    level: 'initModel'
  },
  created() {
    this.initModel()
  },
  props: {
    level: Object,
    component: Object,
    parentComponent: Object
  },
  computed: {
    remunerationTypes() {
      return remunerationTypesForComponent(this.component)
    },
    isValid() {
      return this.levelModel.name && this.remunerationTypes.every(remunerationType => {
        return this.levelModel[remunerationType + 'Value']
      })
    },
    valueClass() {
      return (value) => {
        if ((this.levelModel[value] == null || this.levelModel[value].length === 0)) {
          return 'input-warning'
        }

        return null
      }
    },
    linkedLevelName() {
      if (this.parentComponent && this.level.linkedLevelId) {
        const linkedLevel = this.parentComponent.levels.find(l => l.id === this.level.linkedLevelId)
        return linkedLevel && linkedLevel.name
      }
    }
  },
  methods: {
    initModel() {
      this.levelModel = { ...this.level }
    },
    addLevel() {
      this.$emit('addLevel')
    },
    updateLevel: debounce(function() {
      this.isLoading = true
      this.$store.dispatch('sandbox/updateLevel', this.levelModel)
        .then(response => {
          this.isLoading = false
        })
    }, 500),
    removeLevel() {
      if (confirm(this.$t('sandbox.levelForm.deleteConfirmation', { levelName: this.levelModel.name }))) {
        this.$store.dispatch('sandbox/removeLevel', this.levelModel)
      }
    },
    operationSymbol(remunerationType) {
      return remunerationOperationSymbol(this.component[remunerationType + 'Operation'])
    },
    operationUnit(remunerationType) {
      return remunerationOperationUnit(this.component[remunerationType + 'Operation'])
    },
    updateDescription(description) {
      this.levelModel.description = description
      this.isLoading = true

      this.$store.dispatch('sandbox/updateLevel', this.levelModel)
        .then(r => {
          this.isLoading = false
          this.descriptionEditorDisplayed = false
        })
        .catch(e => {
          this.isLoading = false
        })
    }
  },
  components: {
    NumberInput,
    DescriptionModal
  }
}
</script>

<style lang="scss" scoped>
tr {
  border: $border;

  &:hover .drag-button {
    display: block;
  }
}

.rank-col {
  position: relative;
  border: $border;

  input {
    width: 25px;
    text-align: center;
  }
}

.linked-level-col {
  border: $border;
  padding: 0 5px;
}

.value-col {
  width: 130px;
  padding-left: 5px;
  padding-right: 5px;
  border: $border;

  input {
    text-align: right;
    width: 70px;
  }
}

.operation {
  color: $gray-color;
}

.status {
  width: 8px;
  background: none;
}

.drag-button {
  height: 100%;
  position: absolute;
  text-align: center;
  left: -40px;
  top: 9px;
  width: 40px;
  display: none;
}

.draggable {
  cursor: ns-resize;
}

.sortable-ghost {
  background: $border-color;
  opacity: 0;
}

.sortable-drag {
  background: white;
}

input {
  display: inline;
  padding: 5px;
  margin: 0;
  width: 100%;
  border: none;
  outline: none;
  @include font-normal-size;
  font-family: $font;
  background: none;
}

input::placeholder {
  color: $gray-color;
}

.warning {
  background-color: $lightorange-color;
}

.clickable {
  cursor: pointer;
}

.description-col {
  padding: 0 10px;
  white-space: nowrap;
  cursor: pointer;
  border: $border;
  text-align: center;

  &:hover {
    color: $clearteal-color;
  }
}
</style>
