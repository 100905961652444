<template>
  <div class="employees-wage-diff">
    <span
      v-if="delta !== 0"
      class="badge delta-badge"
      :class="{higher: delta > 0, lower: delta < 0}">
      {{ delta | formattedCurrency(false, {signDisplay: "always"}) }}
      <small>({{ deltaPercent | formattedNumber(1, 1) }}&nbsp;%)</small>
    </span>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import { getPercent } from '@/utils/math'
import { getWagesDetailsSummary } from '@/utils/grid'

export default {
  props: {
    employees: {
      type: Array
    }
  },
  data() {
    return {
      delta: 12,
      deltaPercent: 0,
      debounceFn: null
    }
  },
  methods: {
    computeDelta() {
      const referenceSummary = getWagesDetailsSummary(this.employees.map(e => e.wageDetails.summary)).salary
      const summary = getWagesDetailsSummary(this.employees.map(e => e.simulationWageDetails.summary)).salary
      this.delta = summary.value - referenceSummary.value
      this.deltaPercent = getPercent(summary.value, referenceSummary.value)
    }
  },
  watch: {
    'employees'() {
      this.debounceFn()
    }
  },
  created() {
    this.computeDelta()
    this.debounceFn = debounce(this.computeDelta, 300)
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/badge.scss";

.employees-wage-diff {
  // Hardcode height to align info slot title with group
  height: 26px;
}
</style>
