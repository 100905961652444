<template>
  <div>
    <div
      class="variable-demo"
      v-for="(variablePlan, index) in variablePlans"
      :key="index">
      <MarkdownText v-model="variablePlan.description" />
      <VariablePlanEditor class="variable-plan-demo" :value="variablePlan">
        <template v-slot="slotProps">
          <slot :variablePlan="slotProps.variablePlan" />
        </template>
      </VariablePlanEditor>
    </div>
  </div>
</template>

<script>
import MarkdownText from '@components/commons/MarkdownText'
import VariablePlanEditor from '@components/variable/VariablePlanEditor.vue'
import { getDemoVariablePlan, isDemoVariablePlanAvailable } from '@/utils/variable'

export default {
  components: {
    MarkdownText,
    VariablePlanEditor
  },
  props: {
    // Prefix of the demo files located in @/assets/variable (ex: `individual_variable`)
    name: {
      type: String
    }
  },
  computed: {
    count() {
      let count = 0
      while (isDemoVariablePlanAvailable(this.name, count + 1)) {
        count++
      }
      return count
    },
    variablePlans() {
      return Array.from(Array(this.count), (_, i) => {
        return getDemoVariablePlan(this.name, i + 1)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.variable-plan-demo {
  border-width: 2px !important;
  border-style: dashed;
  border-radius: $border-radius * 2;
  margin-bottom: 2.5em;
}
</style>
