import Vue from 'vue'
import i18n from '@/i18n'
import store from '@/store'
import Router from 'vue-router'
import BenchmarkJobView from '@/views/benchmark/BenchmarkJobView'
import BenchmarkView from '@/views/benchmark/BenchmarkView'
import BenefitsPlansView from '@/views/benefits/BenefitsPlansView'
import BenefitsPlanView from '@/views/benefits/BenefitsPlanView'
import CandidateFormView from '@/views/candidates/CandidateFormView'
import CandidateView from '@/views/candidates/CandidateView'
import CandidateWageView from '@/views/candidates/CandidateWageView'
import DashboardView from '@/views/dashboard/DashboardView'
import DirectoryView from '@/views/teams/DirectoryView'
import DemoView from '@/views/DemoView'
import EmployeeFormView from '@/views/employees/EmployeeFormView'
import EmployeesReviewView from '@/views/employees/EmployeesReviewView'
import EmployeesView from '@/views/employees/EmployeesView'
import EmployeeView from '@/views/employees/EmployeeView'
import EmployeeCurrentWageFormView from '@/views/employees/EmployeeCurrentWageFormView'
import EmployeeWageFormView from '@/views/employees/EmployeeWageFormView'
import GridCurrentView from '@/views/grid/GridCurrentView'
import GridEditorView from '@/views/grid/editor/GridEditorView'
import GridQualificationEditorView from '@/views/grid/editor/GridQualificationEditorView'
import GridSkillsEditorView from '@/views/grid/editor/GridSkillsEditorView'
import GridStructureEditorView from '@/views/grid/editor/GridStructureEditorView'
import GridValuationEditorView from '@/views/grid/editor/GridValuationEditorView'
import GridView from '@/views/grid/GridView'
import GridWageCalculatorView from '@/views/grid/GridWageCalculatorView'
import HiringPlansView from '@/views/hiringPlans/HiringPlansView'
import InvitationView from '@/views/InvitationView'
import JobOfferFormView from '@/views/candidates/JobOfferFormView'
import OktaImplicitCallback from '@/views/visitor/OktaImplicitCallback'
import OnboardingGenerateView from '@/views/grid/onboarding/OnboardingGenerateView'
import OnboardingIntroView from '@/views/grid/onboarding/OnboardingIntroView'
import OnboardingQualificationView from '@/views/grid/onboarding/OnboardingQualificationView'
import OnboardingSkillsView from '@/views/grid/onboarding/OnboardingSkillsView'
import OrgChartView from '@/views/teams/OrgChartView'
import PasswordResetRequestView from '@/views/PasswordResetRequestView'
import PasswordResetView from '@/views/PasswordResetView'
import ReportView from '@/views/dashboard/ReportView'
import ReviewsView from '@/views/reviews/ReviewsView'
import ReviewView from '@/views/reviews/ReviewView'
import SandboxFormView from '@/views/sandbox/SandboxFormView'
import SandboxListView from '@/views/sandbox/SandboxListView'
import SandboxView from '@/views/sandbox/SandboxView'
import SettingsCompanyAccessRightsView from '@/views/settings/SettingsCompanyAccessRightsView'
import SettingsCompanyAccessRightsGroupsView from '@/views/settings/SettingsCompanyAccessRightsGroupsView'
import SettingsCompanyAccessRightsPermissionsView from '@/views/settings/SettingsCompanyAccessRightsPermissionsView'
import SettingsCompanyBillingView from '@/views/settings/SettingsCompanyBillingView'
import SettingsCompanyInfoView from '@/views/settings/SettingsCompanyInfoView'
import SettingsCompanyIntegrationBambooHRView from '@/views/settings/SettingsCompanyIntegrationBambooHRView'
import SettingsCompanyIntegrationLuccaView from '@/views/settings/SettingsCompanyIntegrationLuccaView'
import SettingsCompanyIntegrationOKTAView from '@/views/settings/SettingsCompanyIntegrationOKTAView'
import SettingsCompanyIntegrationPayfitView from '@/views/settings/SettingsCompanyIntegrationPayfitView'
import SettingsCompanyIntegrationsView from '@/views/settings/SettingsCompanyIntegrationsView'
import SettingsCompanySynchronizationView from '@/views/settings/SettingsCompanySynchronizationView'
import SettingsCompanyCustomFieldsView from '@/views/settings/SettingsCompanyCustomFieldsView'
import SettingsUserEmailView from '@/views/settings/SettingsUserEmailView'
import SettingsUserInfoView from '@/views/settings/SettingsUserInfoView'
import SettingsUserPasswordView from '@/views/settings/SettingsUserPasswordView'
import SettingsView from '@/views/settings/SettingsView'
import SigninView from '@/views/SigninView'
import SignupView from '@/views/SignupView'
import TeamsView from '@/views/teams/TeamsView'
import TeamsFormView from '@/views/teams/TeamsFormView'
import VariablePlanFormView from '@/views/variablePlans/VariablePlanFormView'
import VariablePlanPeriodFormView from '@/views/variablePlans/VariablePlanPeriodFormView'
import VariablePlansView from '@/views/variablePlans/VariablePlansView'
import VariablePlanView from '@/views/variablePlans/VariablePlanView'
import WagePlanFormView from '@/views/wagePlans/WagePlanFormView'
import WageSharingView from '@/views/WageSharingView'
import WelcomeView from '@/views/dashboard/WelcomeView'

// Lazy-loaded routes
const EmployeesCompareView = () => import('@/views/employees/EmployeesCompareView' /* webpackChunkName: "chunk-employees-compare" */)
const EmployeesImportView = () => import('@/views/employees/EmployeesImportView' /* webpackChunkName: "chunk-employees-import" */)
const EmployeesExportView = () => import('@/views/employees/EmployeesExportView' /* webpackChunkName: "chunk-employees-export" */)
const GridExportView = () => import('@/views/grid/GridExportView' /* webpackChunkName: "chunk-grid-export" */)
const OCRImportView = () => import('@/views/tools/OCRImportView' /* webpackChunkName: "chunk-ocr-import" */)
const LuccaImportView = () => import('@/views/tools/LuccaImportView' /* webpackChunkName: "chunk-lucca-import" */)
const QualificationImportView = () => import('@/views/tools/QualificationImportView' /* webpackChunkName: "chunk-qualification-import" */)
const PayfitSalaryImportView = () => import('@/views/tools/PayfitSalaryImportView' /* webpackChunkName: "chunk-payfit-salary-import" */)
const PayfitUserImportView = () => import('@/views/tools/PayfitUserImportView' /* webpackChunkName: "chunk-payfit-user-import" */)
const DataFeedImportView = () => import('@/views/tools/DataFeedImportView' /* webpackChunkName: "chunk-data-feed-import" */)
const WageToolView = () => import('@/views/tools/WageToolView' /* webpackChunkName: "chunk-wage-tool" */)
const GenericExportView = () => import('@/views/GenericExportView' /* webpackChunkName: "chunk-generic-export" */)

Vue.use(Router)

const verifyAdminAccess = (to, from, next, fallback = '/') => {
  store.getters['account/isAdmin'] ? next() : next(fallback)
}

const verifyManagerAccess = (to, from, next, fallback = '/') => {
  store.getters['account/isAdmin'] || store.getters['account/isManager'] ? next() : next(fallback)
}

const verifyOpenAccess = (to, from, next) => {
  store.getters['account/hasOpenAccess'] ? next() : next('/')
}

const verifyPermission = (permission, fallback = '/') => {
  return (to, from, next) => {
    return store.getters['account/hasPermission'](permission) ? next() : next(fallback)
  }
}

// Mute NavigationDuplicated exceptions (https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378)
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

export default new Router({
  mode: 'history',
  routes: [
    {
      name: 'root',
      path: '/',
      beforeEnter: (to, from, next) => {
        if (store.getters.isDemoSession && store.getters['account/isAdmin']) {
          next('/demo/welcome')
        }
        else {
          store.getters['account/hasOpenAccess'] ? next('/dashboard') : next('/profile')
        }
      }
    },
    {
      name: 'signin',
      path: '/signin',
      component: SigninView,
      meta: {
        routeName: 'account.signin.title',
        layout: false
      }
    },
    {
      name: 'oktaImplicitCallback',
      path: '/signin/okta/callback',
      component: OktaImplicitCallback,
      meta: {
        routeName: 'account.signin.title',
        layout: false
      }
    },
    {
      name: 'signup',
      path: '/signup',
      component: SignupView,
      meta: {
        routeName: 'account.signup.title',
        layout: false
      }
    },
    {
      name: 'passwordRequest',
      path: '/password',
      component: PasswordResetRequestView,
      meta: {
        routeName: 'account.passwordReset.forgotYourPassword',
        layout: false
      }
    },
    {
      name: 'passwordReset',
      path: '/password/:token',
      component: PasswordResetView,
      meta: {
        routeName: 'account.passwordReset.forgotYourPassword',
        layout: false
      }
    },
    {
      name: 'invitation',
      path: '/invitation/:token(.*)',
      component: InvitationView,
      meta: {
        layout: false,
        routeName: 'account.invitation.title'
      }
    },
    {
      path: '/variable',
      beforeEnter: verifyPermission('variablePlans'),
      component: {
        template: '<router-view></router-view>'
      },
      meta: {
        routeName: 'variable.longTitle'
      },
      children: [
        {
          name: 'variablePlans',
          path: '',
          component: VariablePlansView,
          meta: {
            hideInBreadcrumb: true
          }
        },
        {
          name: 'addVariablePlan',
          path: 'add',
          component: VariablePlanFormView,
          meta: {
            routeName: 'variable.form.shortTitle'
          }
        },
        {
          name: 'variablePlan',
          path: ':id',
          component: VariablePlanView,
          meta: {
            routeName: 'common.ellipsis',
            dynamicRouteGetter: 'variablePlans/getCurrentVariablePlanName'
          },
          children: [
            {
              name: 'addVariablePlanPeriod',
              path: 'add',
              component: VariablePlanPeriodFormView,
              meta: {
                routeName: 'variable.periodForm.title'
              }
            }
          ]
        }
      ]
    },
    {
      path: '/reviews',
      beforeEnter: verifyPermission('reviews'),
      component: {
        template: '<router-view></router-view>'
      },
      meta: {
        routeName: 'variable.longTitle'
      },
      children: [
        {
          name: 'reviews',
          path: '',
          component: ReviewsView,
          meta: {
            hideInBreadcrumb: true
          }
        },
        {
          name: 'review',
          path: ':id',
          component: ReviewView,
          meta: {
            routeName: 'common.ellipsis',
            dynamicRouteGetter: 'reviews/getCurrentReviewName'
          }
        }
      ]
    },
    {
      path: '/grid',
      component: GridView,
      meta: {
        routeName: 'grid.title'
      },
      children: [
        {
          name: 'grid',
          path: '',
          component: GridCurrentView,
          meta: {
            hideInBreadcrumb: true
          }
        },
        {
          name: 'gridCalculator',
          path: 'simulation',
          beforeEnter: verifyPermission('gridSimulation', { name: 'grid' }),
          component: GridWageCalculatorView,
          meta: {
            routeName: 'grid.simulation.title'
          }
        },
        {
          name: 'gridEditor',
          path: 'editor',
          beforeEnter: verifyPermission('gridEditor', { name: 'grid' }),
          component: GridEditorView,
          meta: {
            routeName: 'grid.editor.title'
          },
          children: [
            {
              name: 'gridValuationEditor',
              path: 'valuation',
              beforeEnter: verifyPermission('gridValuationEditor', { name: 'grid' }),
              component: GridValuationEditorView,
              meta: {
                routeName: 'grid.editor.gridValuationEditor.title'
              }
            },
            {
              name: 'gridQualificationEditor',
              path: 'qualification',
              component: GridQualificationEditorView,
              meta: {
                routeName: 'grid.editor.gridQualificationEditor.title'
              }
            },
            {
              name: 'gridSkillsEditor',
              path: 'skills',
              component: GridSkillsEditorView,
              meta: {
                routeName: 'grid.editor.gridSkillsEditor.title'
              }
            },
            {
              name: 'gridStructureEditor',
              path: 'structure',
              beforeEnter: verifyAdminAccess,
              component: GridStructureEditorView,
              meta: {
                routeName: 'grid.editor.gridStructureEditor.title'
              }
            }
          ]
        },
        {
          name: 'exportGrid',
          path: 'export',
          component: GridExportView,
          meta: {
            routeName: 'grid.export.title'
          }
        },
        {
          path: 'sandbox',
          beforeEnter: verifyManagerAccess,
          component: {
            template: '<router-view></router-view>'
          },
          meta: {
            routeName: 'grid.sandbox'
          },
          children: [
            {
              path: '',
              name: 'sandboxList',
              component: SandboxListView,
              meta: {
                hideInBreadcrumb: true
              }
            },
            {
              name: 'sandboxForm',
              path: 'create',
              component: SandboxFormView,
              meta: {
                routeName: 'common.create'
              }
            },
            {
              name: 'sandboxEdit',
              path: ':id',
              component: SandboxView,
              meta: {
                routeName: 'common.ellipsis',
                dynamicRouteGetter: 'sandbox/name'
              }
            }
          ]
        },
        {
          path: 'onboarding',
          beforeEnter: verifyManagerAccess,
          component: {
            template: '<router-view></router-view>'
          },
          meta: {
            hideInBreadcrumb: true
          },
          children: [
            {
              path: '',
              name: 'onboardingIntro',
              component: OnboardingIntroView,
              meta: {
                routeName: 'grid.onboarding.intro.title'
              }
            },
            {
              path: 'skills',
              name: 'onboardingSkills',
              component: OnboardingSkillsView,
              meta: {
                routeName: 'grid.onboarding.skills.title'
              }
            },
            {
              path: 'qualification',
              name: 'onboardingQualification',
              component: OnboardingQualificationView,
              meta: {
                routeName: 'grid.onboarding.qualification.title'
              }
            },
            {
              path: 'generate',
              name: 'onboardingGenerate',
              component: OnboardingGenerateView,
              meta: {
                routeName: 'grid.onboarding.generate.title'
              }
            }
          ]
        }
      ]
    },
    {
      path: '/demo/welcome',
      name: 'demoWelcome',
      component: WelcomeView,
      meta: {
        routeName: 'demo.welcome.title',
        layout: true
      }
    },
    {
      path: '/demo/:code(.*)',
      name: 'demo',
      component: DemoView,
      meta: {
        hideInBreadcrumb: true,
        layout: false
      }
    },
    {
      path: '/dashboard',
      component: DashboardView,
      meta: {
        routeName: 'dashboard.title'
      },
      beforeEnter: (to, from, next) => {
        if (from.name === 'wagePlan') {
          Object.assign(to.params, { highlightPayroll: true })
        }
        verifyOpenAccess(to, from, next)
      },
      children: [
        {
          name: 'dashboard',
          path: ':id',
          component: ReportView,
          meta: {
            routeName: 'common.ellipsis',
            dynamicRouteGetter: 'statistics/getCurrentReportName',
            preserveScroll: true
          }
        }
      ]
    },
    {
      path: '/employees',
      beforeEnter: verifyOpenAccess,
      component: {
        template: '<router-view></router-view>'
      },
      meta: {
        routeName: 'employees.title'
      },
      children: [
        {
          name: 'employees',
          path: '',
          component: EmployeesView,
          meta: {
            hideInBreadcrumb: true
          }
        },
        {
          name: 'addEmployee',
          path: 'add',
          beforeEnter: verifyAdminAccess,
          component: EmployeeFormView,
          meta: {
            routeName: 'employees.employee.form.addEmployee'
          }
        },
        {
          name: 'importEmployees',
          path: 'import',
          component: EmployeesImportView,
          beforeEnter: verifyAdminAccess,
          meta: {
            routeName: 'employees.import.title'
          }
        },
        {
          name: 'exportEmployees',
          path: 'export',
          component: EmployeesExportView,
          beforeEnter: verifyPermission('employeesExport'),
          meta: {
            routeName: 'employees.export.title'
          }
        },
        {
          name: 'compareEmployees',
          path: 'compare',
          component: EmployeesCompareView,
          beforeEnter: verifyAdminAccess,
          meta: {
            routeName: 'employees.compare.title'
          }
        },
        {
          name: 'reviewEmployees',
          path: 'review',
          component: EmployeesReviewView,
          beforeEnter: verifyAdminAccess,
          meta: {
            routeName: 'employees.review.title'
          }
        },
        {
          path: ':id',
          component: {
            template: '<router-view></router-view>'
          },
          meta: {
            routeName: 'common.ellipsis',
            dynamicRouteGetter: 'employees/getCurrentEmployeeName'
          },
          children: [
            {
              path: '',
              name: 'employee',
              component: EmployeeView,
              meta: {
                hideInBreadcrumb: true
              }
            },
            {
              name: 'updateEmployee',
              path: 'update',
              beforeEnter: verifyAdminAccess,
              component: EmployeeFormView,
              meta: {
                routeName: 'employees.employee.form.update'
              }
            },
            {
              name: 'newEmployeeCurrentWage',
              path: 'wage',
              beforeEnter: verifyPermission('employeesSetCurrentWage'),
              component: EmployeeCurrentWageFormView,
              meta: {
                routeName: 'employees.employee.wage.update'
              }
            },
            {
              name: 'newEmployeeWage',
              path: 'wage/new',
              beforeEnter: verifyAdminAccess,
              component: EmployeeWageFormView,
              meta: {
                routeName: 'employees.employee.wage.update'
              }
            },
            {
              name: 'updateEmployeeWage',
              path: 'wage/:wageId',
              beforeEnter: verifyAdminAccess,
              component: EmployeeWageFormView,
              meta: {
                routeName: 'employees.employee.wage.update'
              }
            }
          ]
        }
      ]
    },
    {
      path: '/teams',
      component: TeamsView,
      children: [
        {
          name: 'teams',
          path: '',
          component: OrgChartView,
          beforeEnter: verifyPermission('teamsOrgChart', { name: 'directory' }),
          meta: {
            rootless: true,
            routeName: 'orgChart.title'
          }
        },
        {
          name: 'directory',
          path: 'directory',
          component: DirectoryView,
          beforeEnter: verifyPermission('teamsDirectory'),
          meta: {
            routeName: 'teams.directory.title',
            rootless: true
          }
        },
        {
          name: 'updateTeams',
          path: 'update',
          component: TeamsFormView,
          beforeEnter: verifyAdminAccess,
          meta: {
            routeName: 'teams.form.title',
            rootless: true
          }
        }
      ]
    },
    {
      path: '/plans',
      beforeEnter: verifyPermission('wagePlans'),
      component: {
        template: '<router-view></router-view>'
      },
      meta: {
        routeName: 'wagePlans.title'
      },
      children: [
        {
          path: '',
          redirect: '/dashboard'
        },
        {
          name: 'addWagePlan',
          path: 'add',
          component: WagePlanFormView,
          meta: {
            routeName: 'wagePlans.create.title'
          }
        },
        {
          path: ':id',
          name: 'wagePlan',
          component: WagePlanFormView,
          meta: {
            routeName: 'common.ellipsis',
            dynamicRouteGetter: 'wagePlans/getCurrentWagePlanName'
          },
          children: [
            {
              name: 'wagePlanEmployee',
              path: 'employee/:employeeId',
              component: EmployeeCurrentWageFormView,
              meta: {
                routeName: 'common.ellipsis',
                dynamicRouteGetter: 'employees/getCurrentEmployeeName'
              }
            }
          ]
        }
      ]
    },
    {
      path: '/recruitment',
      beforeEnter: verifyPermission('hiringPlans'),
      component: {
        template: '<router-view></router-view>'
      },
      meta: {
        routeName: 'hiringPlans.title'
      },
      children: [
        {
          name: 'hiringPlans',
          path: '',
          component: HiringPlansView,
          beforeEnter: verifyManagerAccess,
          meta: {
            hideInBreadcrumb: true
          }
        },
        {
          name: 'addHiringPlan',
          path: 'add',
          beforeEnter: verifyManagerAccess,
          component: JobOfferFormView,
          meta: {
            routeName: 'hiringPlans.form.title'
          }
        },
        {
          path: 'job-offer',
          beforeEnter: verifyManagerAccess,
          component: {
            template: '<router-view></router-view>'
          },
          redirect: '/recruitment',
          meta: {
            routeName: 'candidates.jobOffers'
          },
          children: [
            {
              name: 'addJobOffer',
              path: 'add',
              component: JobOfferFormView,
              meta: {
                routeName: 'candidates.addJobOffer'
              }
            },
            {
              name: 'jobOffer',
              path: ':id',
              component: JobOfferFormView,
              meta: {
                routeName: 'common.ellipsis',
                dynamicRouteGetter: 'candidates/getCurrentJobOfferName'
              }
            }
          ]
        },
        {
          path: 'applicant',
          beforeEnter: verifyManagerAccess,
          component: {
            template: '<router-view></router-view>'
          },
          redirect: '/recruitment',
          meta: {
            routeName: 'candidates.candidates'
          },
          children: [
            {
              name: 'addCandidate',
              path: 'add',
              component: CandidateFormView,
              meta: {
                routeName: 'candidates.addCandidate'
              }
            },
            {
              path: ':id',
              component: {
                template: '<router-view></router-view>'
              },
              meta: {
                routeName: 'common.ellipsis',
                dynamicRouteGetter: 'candidates/getCurrentCandidateName'
              },
              children: [
                {
                  name: 'candidate',
                  path: '',
                  component: CandidateView,
                  meta: {
                    hideInBreadcrumb: true
                  }
                },
                {
                  name: 'candidateUpdate',
                  path: 'update',
                  component: CandidateFormView,
                  meta: {
                    routeName: 'common.update'
                  }
                },
                {
                  name: 'candidateWageUpdate',
                  path: 'wage',
                  component: CandidateWageView,
                  meta: {
                    routeName: 'employees.employee.wage.update'
                  }
                }
              ]
            }
          ]
        }
      ]
    },
    {
      name: 'oneself',
      path: '/profile',
      component: EmployeeView,
      meta: {
        routeName: 'employees.profile'
      }
    },
    {
      name: 'tools',
      path: '/tools',
      redirect: '/tools/jobs',
      component: {
        template: '<router-view></router-view>'
      },
      meta: {
        hideInBreadcrumb: true
      },
      children: [
        {
          name: 'jobs',
          path: 'jobs',
          component: BenchmarkView,
          meta: {
            routeName: 'benchmark.jobs.title'
          },
          children: [
            {
              path: ':id',
              name: 'job',
              component: BenchmarkJobView,
              meta: {
                routeName: 'common.ellipsis',
                dynamicRouteGetter: 'jobs/getCurrentJobName'
              }
            }
          ]
        },
        {
          name: 'ocrImport',
          path: 'ocr',
          beforeEnter: verifyAdminAccess,
          component: OCRImportView,
          meta: {
            routeName: 'tools.ocrImport.title'
          }
        },
        {
          name: 'luccaImport',
          path: 'lucca',
          beforeEnter: verifyAdminAccess,
          component: LuccaImportView,
          meta: {
            routeName: 'tools.luccaImport.title'
          }
        },
        {
          name: 'qualificationImport',
          path: 'qualification',
          beforeEnter: verifyAdminAccess,
          component: QualificationImportView,
          meta: {
            routeName: 'tools.qualificationImport.title'
          }
        },
        {
          name: 'dataFeedImport',
          path: 'dataFeed',
          beforeEnter: verifyAdminAccess,
          component: DataFeedImportView,
          meta: {
            routeName: 'tools.dataFeedImport.title'
          }
        },
        {
          name: 'payfitSalaryImport',
          path: 'payfitSalary',
          beforeEnter: verifyAdminAccess,
          component: PayfitSalaryImportView,
          meta: {
            routeName: 'tools.payfitSalaryImport.title'
          }
        },
        {
          name: 'payfitUserImport',
          path: 'payfitUser',
          beforeEnter: verifyAdminAccess,
          component: PayfitUserImportView,
          meta: {
            routeName: 'tools.payfitUserImport.title'
          }
        },
        {
          name: 'wageTool',
          path: 'wageTool',
          beforeEnter: verifyAdminAccess,
          component: WageToolView,
          meta: {
            routeName: 'tools.wageTool.title'
          }
        }
      ]
    },
    {
      path: '/settings',
      component: SettingsView,
      meta: {
        hideInBreadcrumb: true
      },
      children: [
        {
          name: 'settings',
          path: '',
          redirect: { name: 'settingsUser' }
        },
        {
          path: 'user',
          component: {
            template: '<router-view></router-view>'
          },
          meta: {
            routeName: 'common.ellipsis',
            dynamicRouteGetter: 'account/getUserName'
          },
          children: [
            {
              name: 'settingsUser',
              path: '',
              redirect: { name: 'settingsUserInfo' }
            },
            {
              name: 'settingsUserInfo',
              path: 'info',
              component: SettingsUserInfoView,
              meta: {
                routeName: 'settings.settingsUserInfo.title'
              }
            },
            {
              name: 'settingsUserEmail',
              path: 'email',
              component: SettingsUserEmailView,
              meta: {
                routeName: 'settings.settingsUserEmail.title'
              }
            },
            {
              name: 'settingsUserPassword',
              path: 'password',
              component: SettingsUserPasswordView,
              meta: {
                routeName: 'settings.settingsUserPassword.title'
              }
            }
          ]
        },
        {
          path: 'company',
          component: {
            template: '<router-view></router-view>'
          },
          beforeEnter: verifyAdminAccess,
          meta: {
            hideInBreadcrumb: true
          },
          children: [
            {
              name: 'settingsCompany',
              path: '',
              redirect: { name: 'settingsCompanyInfo' }
            },
            {
              name: 'settingsCompanyInfo',
              path: 'info',
              component: SettingsCompanyInfoView,
              meta: {
                routeName: 'settings.settingsCompanyInfo.title'
              }
            },
            {
              name: 'settingsCompanyBilling',
              path: 'billing',
              component: SettingsCompanyBillingView,
              meta: {
                routeName: 'settings.settingsCompanyBilling.title'
              }
            },
            {
              name: 'settingsCompanyAccessRights',
              path: 'access-rights',
              component: SettingsCompanyAccessRightsView,
              meta: {
                routeName: 'settings.settingsCompanyAccessRights.title'
              },
              children: [
                {
                  name: 'settingsCompanyAccessRightsPermissions',
                  path: 'permissions',
                  component: SettingsCompanyAccessRightsPermissionsView,
                  meta: {
                    routeName: 'settings.settingsCompanyAccessRightsPermissions.title'
                  },
                  children: [
                    {
                      name: 'settingsCompanyAccessRightsGroups',
                      path: 'groups',
                      component: SettingsCompanyAccessRightsGroupsView,
                      meta: {
                        routeName: 'settings.settingsCompanyAccessRightsGroups.title'
                      }
                    }
                  ]
                }
              ]
            },
            {
              name: 'settingsCompanyTeams',
              path: 'teams',
              redirect: { name: 'settingsCompanyAccessRightsGroups' }
            },
            {
              name: 'settingsCompanySynchronization',
              path: 'synchronization',
              component: SettingsCompanySynchronizationView,
              meta: {
                routeName: 'settings.settingsCompanySynchronization.title'
              }
            },
            {
              name: 'settingsCompanyCustomFields',
              path: 'customFields',
              component: SettingsCompanyCustomFieldsView,
              meta: {
                routeName: 'settings.settingsCompanyCustomFields.title'
              }
            },
            {
              path: 'integrations',
              component: {
                template: '<router-view></router-view>'
              },
              meta: {
                routeName: 'settings.settingsCompanyIntegrations.title'
              },
              children: [
                {
                  name: 'settingsCompanyIntegrations',
                  path: '',
                  component: SettingsCompanyIntegrationsView,
                  meta: {
                    hideInBreadcrumb: true
                  }
                },
                {
                  name: 'settingsCompanyIntegrationBambooHR',
                  path: 'bambooHR',
                  component: SettingsCompanyIntegrationBambooHRView,
                  meta: {
                    routeName: 'settings.settingsCompanyIntegrations.bambooHR.title'
                  }
                },
                {
                  name: 'settingsCompanyIntegrationLucca',
                  path: 'lucca',
                  component: SettingsCompanyIntegrationLuccaView,
                  meta: {
                    routeName: 'settings.settingsCompanyIntegrations.lucca.title'
                  }
                },
                {
                  name: 'settingsCompanyIntegrationOKTA',
                  path: 'okta',
                  component: SettingsCompanyIntegrationOKTAView,
                  meta: {
                    routeName: 'settings.settingsCompanyIntegrations.okta.title'
                  }
                },
                {
                  name: 'settingsCompanyIntegrationPayfit',
                  path: 'payfit',
                  component: SettingsCompanyIntegrationPayfitView,
                  meta: {
                    routeName: 'settings.settingsCompanyIntegrations.payfit.title'
                  }
                }
              ]
            }
          ]
        }
      ]
    },
    {
      path: '/benefits',
      component: {
        template: '<router-view></router-view>'
      },
      beforeEnter: verifyAdminAccess,
      meta: {
        routeName: 'benefits.group.title'
      },
      children: [
        {
          path: '',
          name: 'benefitsPlans',
          component: BenefitsPlansView,
          meta: {
            hideInBreadcrumb: true
          }
        },
        {
          path: 'packages/add',
          name: 'newBenefitsPlan',
          component: BenefitsPlanView,
          meta: {
            routeName: 'benefits.group.createNewGroup'
          }
        },
        {
          path: 'packages/:id',
          name: 'benefitsPlan',
          component: BenefitsPlanView,
          meta: {
            routeName: 'common.ellipsis',
            dynamicRouteGetter: 'benefits/getCurrentBenefitsPlanName'
          }
        }
      ]
    },
    {
      name: 'wageSharing',
      path: '/w/:token',
      component: WageSharingView,
      meta: {
        layout: false,
        routeName: 'common.ellipsis'
      }
    },
    {
      name: 'contact',
      path: '/contact',
      beforeEnter() {
        location.href = i18n.t('urls.contact')
      }
    },
    {
      name: 'export',
      path: '/export',
      component: GenericExportView
    },
    {
      name: 'helpArticle',
      path: '/article/:id',
      beforeEnter(to) {
        const id = to.params.id
        location.href = i18n.t('urls.helpArticle', { id })
      }
    },
    {
      path: '*',
      redirect: '/'
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }

    if (to.meta.preserveScroll) {
      return false
    }

    if (savedPosition) {
      return savedPosition
    }

    return { x: 0, y: 0 }
  }
})
