<template>
  <div class="checkable-skill" :class="{checked: isCheckedModel, clickable:!disabled, animated: isAnimated}" @mousedown="toggleIsChecked">
    <div class="skill-check" :class="progressColor">
      <template v-if="isCheckedModel">✓</template>
      <template v-else-if="isOngoing"><span class="icon-ongoing"></span></template>
      <template v-else-if="isUnknown"><span class="icon-unknown"></span></template>
      <template v-else>•</template>
    </div>
    <div v-if="hasSkillValues" class="skill-value">
      <select
        class="blue"
        v-model="skillValueModel"
        :disabled="disabled"
        @mousedown.stop
        @change="onSkillValueChange">
          <option
            v-for="skillValue in availableSkillValues"
            :key="skillValue"
            :value="skillValue">
            {{ $t(`grid.onboarding.skills.skillValues.${skillValue}.name`) }}
          </option>
      </select>
    </div>
    <div v-if="hasProgress" class="skill-progress" :class="progressColor" :title="$t('grid.current.skillsProgressExplanation')">
      {{progress * 100 | formattedNumber(0, 0) }}%
    </div>
    <div v-if="showName" class="skill-name">
      {{name}}
    </div>
    <div v-if="showName" class="skill-labels">
      <span class="skill-label" v-for="(label, i) in labels" :key="i">{{label}}</span>
    </div>
  </div>
</template>

<script>
import { getSkillLabels, getSkillValue, isSkillChecked, SKILL_VALUES, SKILL_VALUE_ACQUIRED, SKILL_VALUE_NONE, SKILL_VALUE_ONGOING, SKILL_VALUE_UNKNOWN } from '@/utils/skills'

export default {
  props: {
    skill: Object,
    selectedSkills: Array,
    selectedSkillValues: Object,
    disabled: Boolean,
    progress: Number,
    hasSkillValues: Boolean,
    showName: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      // Boolean
      isCheckedModel: false,
      // Value
      skillValueModel: 0,
      defaultSkillValue: SKILL_VALUE_NONE,
      isAnimated: false
    }
  },
  computed: {
    id() {
      return this.skill.id
    },
    name() {
      return this.skill.name.split('|')[0].trim()
    },
    labels() {
      return getSkillLabels(this.skill)
    },
    hasProgress() {
      return this.progress !== undefined
    },
    progressColor() {
      switch (true) {
        case this.progress >= 0.6: return 'ok'
        case this.progress >= 0.3: return 'mid'
        default: return 'low'
      }
    },
    isOngoing() {
      return this.skillValueModel === SKILL_VALUE_ONGOING
    },
    isUnknown() {
      return this.skillValueModel === SKILL_VALUE_UNKNOWN
    },
    availableSkillValues() {
      return SKILL_VALUES.slice().reverse()
    }
  },
  methods: {
    initModel() {
      this.isCheckedModel = isSkillChecked(this.selectedSkills, this.id)
      this.skillValueModel = getSkillValue(this.selectedSkills, this.selectedSkillValues, this.id)
      // Allows fast switching between previous value
      if (this.skillValueModel !== SKILL_VALUE_ACQUIRED) {
        this.defaultSkillValue = this.skillValueModel
      }
    },
    toggleIsChecked() {
      if (this.disabled) {
        return
      }
      const isChecked = !this.isCheckedModel
      let skillValue
      if (this.hasSkillValues) {
        skillValue = isChecked ? SKILL_VALUE_ACQUIRED : this.defaultSkillValue
      }
      this.$emit('selectIsChecked', {
        id: this.id,
        isChecked,
        skillValue: skillValue
      })
    },
    onSkillValueChange() {
      this.$emit('selectIsChecked', {
        id: this.id,
        isChecked: this.skillValueModel === SKILL_VALUE_ACQUIRED,
        skillValue: this.skillValueModel
      })
    }
  },
  watch: {
    isCheckedModel() {
      this.isAnimated = true
    },
    selectedSkills: 'initModel',
    selectedSkillValues: 'initModel'
  },
  created() {
    this.initModel()
  }
}
</script>

<style lang="scss" scoped>
.checkable-skill {
  display: flex;
  grid-gap: 10px;
  @include font-small-size;
  @include line-regular-height;
  padding: 0.5em 0 0;

  &.clickable:hover {
    color: $graph-darkblue-color;
    cursor: pointer;
    user-select: none;

    .skill-check {
      opacity: 0.9;
    }

    .skill-label {
      background-color: lighten($graph-blue-color, 3);
      color: $graph-darkblue-color;
    }
  }
}

@keyframes check {
  from {
    transform: scale(1.5);
    opacity: 0.9;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.skill-check {
  @include font-big-size;
  @include font-extra-bold;
  opacity: 0.4;
  width: 20px;
  flex-shrink: 0;
  text-align: center;

  .icon-ongoing {
    display: inline-block;
    width: 100%;
    height: 100%;
    background: transparent url(~@/assets/icon-hourglass.svg) no-repeat center 4px;
    background-size: 14px;
  }

  .icon-unknown {
    display: inline-block;
    width: 100%;
    height: 100%;
    background: transparent url(~@/assets/icon-help.svg) no-repeat center top;
    background-size: contain;
  }
}

.checkable-skill.checked {
  .skill-check {
    opacity: 1;
    color: $graph-darkblue-color;
  }

  .skill-value select {
    color: $graph-darkblue-color;
  }
}

.checkable-skill.checked.animated .skill-check {
  animation: check 300ms ease-out;
}

.skill-value {
  width: 95px;
  flex-shrink: 0;

  select {
    font-size: inherit;
    height: 20px;
    width: 100%;
    background: transparent;
    border-radius: $border-radius;
    padding: 0 2px;
  }
}

.skill-progress {
  @include font-smaller-size;
  @include font-semibold;
  min-width: 35px;
  text-align: right;
  cursor: help;
  padding-right: 5px;
}

// Progress colors
.skill-progress,
.checkable-skill.has-progress .skill-check {
  color: darken($clearteal-color, 5);

  &.mid {
    color: lighten($graph-darkblue-color, 10);
  }

  &.low {
    color: $dark-light-text-color;
  }
}

.skill-name {
  flex-grow: 2;
}

.skill-label {
  @include font-smaller-size;
  @include font-medium;
  color: darken($light-text-color, 25);
  display: inline-block;
  text-align: right;
  background: white;
  border-radius: 2em;
  padding: 1px 7px;
  margin: -2px 0 -2px 4px;
}
</style>
