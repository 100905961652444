<template>
  <div>
    <div class="newsfeed" v-if="showCurrent">
      <menu class="float-right no-margin-top">
        <slot name="menu"></slot>
      </menu>
      <h2 v-t="isCandidate ? 'candidates.wageProposal' : 'employees.employee.newsfeed.currentWage'"></h2>
      <div v-if="currentWageDetails">
        <InlineWage
          class="wage-details"
          :wageDetails="currentWageDetails"
          :showSalaryBadges="showVariable"
          :selectedRecurrence="selectedRecurrence"
          @click.native="selectWage(currentWageDetails)" />
        <p
          class="more-details no-margin-bottom light-text"
          v-t="'employees.employee.newsfeed.moreDetails'"
          @click="selectWage(currentWageDetails)" ></p>
      </div>
      <p
        v-else
        class="light-text"
        key="empty"
        v-t="'employees.employee.newsfeed.empty'">
      </p>
    </div>
    <div class="newsfeed" :class="{collapsed: isCollapsed} " v-if="filteredNewsfeedWage.length">
      <h2>
        {{$t('employees.employee.newsfeed.title')}}
        <button
          v-if="collapsed"
          class="small-button"
          @click="isCollapsed = !isCollapsed">
          {{$t(isCollapsed ? 'common.show' : 'common.hide')}}
        </button>
        <dropdown
          v-if="showDisplayDropdown"
          :items="displayDropdownItems"
          @change="setShowSimilarWages">
          <span v-t="'common.options'"></span>
        </dropdown>
      </h2>
      <transition-group v-if="!isLoading" name="wage" tag="div" class="wages">
        <template v-for="wage in isCollapsed ? [] : filteredNewsfeedWage">
          <div class="wage" :key="wage.id">
            <div class="date">
              <div>
                {{formatDate(wage.startDate)}}
              </div>
            </div>
            <div class="timeline">
              <div class="dot" :class="{active: currentWageDetails && currentWageDetails.id === wage.id}"></div>
            </div>
            <InlineWage
              class="wage-details"
              :wageDetails="wage"
              :showSalaryBadges="showVariable"
              :selectedRecurrence="selectedRecurrence"
              @click.native="selectWage(wage)" />
          </div>
          <div
            v-if="wage.transition"
            class="wage wage-transition"
            :key="[wage.id, 'transition'].join()">
            <div></div>
            <div class="timeline"></div>
            <div class="light-text">
              <div class="action">
                <span v-t="wage.transition.label"></span>
                <span
                  v-if="wage.transition.salaryRaise"
                  class="badge small-badge delta-badge"
                  :class="{higher: wage.transition.salaryRaise > 0, lower: wage.transition.salaryRaise < 0}">
                  {{ wage.transition.salaryRaise | formattedCurrency(false, {signDisplay: "always", selectedRecurrence}) }}
                  ({{ wage.transition.salaryRaisePercent | formattedDeltaNumber(1, 1) }}&nbsp;%)
                </span>
                <span
                  v-else
                  class="badge small-badge delta-badge"
                  v-t="'common.unchanged'">
                </span>
              </div>
              <div v-if="wage.transition.explanation" class="reason-container">
                <div class="reason-label">→ </div>
                <markdown-text class="reason" v-model="wage.transition.explanation" />
              </div>
            </div>
          </div>
        </template>
        <div class="error-message" v-if="errorMessage" key="error">{{ errorMessage }}</div>
      </transition-group>
    </div>
    <div
      v-if="this.$slots.footer && currentWageDetails"
      :class="filteredNewsfeedWage.length && 'aligned-footer'">
      <slot name="footer"></slot>
    </div>
    <modal class="wage-viewer-modal" :visible="!!selectedWage" @close="selectedWage = null">
      <wage-viewer
        :wage="selectedWage"
        :newsfeedWages="filteredNewsfeedWage"
        :showVariable="showVariable"
        @close="selectedWage = null" />
    </modal>
  </div>
</template>

<script>
import MarkdownText from '@components/commons/MarkdownText.vue'
import Dropdown from '@components/commons/Dropdown.vue'
import InlineWage from '@components/wage/InlineWage.vue'
import WageViewer from '@components/wage/WageViewer.vue'
import Modal from '@components/commons/Modal.vue'
import { generateNewsfeed } from '@/utils/newsfeed'
import { getWageDetails } from '@/utils/grid'
import { formatDate } from '@/utils/date'
import { titleize } from '@/utils/string'

const CW_NEWSFEED_SHOW_SIMILAR_WAGES = 'CW_NEWSFEED_SHOW_SIMILAR_WAGES'

export default {
  components: {
    MarkdownText,
    Dropdown,
    InlineWage,
    Modal,
    WageViewer
  },
  props: {
    employee: Object,
    showCurrent: {
      type: Boolean,
      default: true
    },
    showVariable: {
      type: Boolean,
      default: false
    },
    collapsed: {
      type: Boolean,
      default: false
    },
    selectedRecurrence: {
      type: String,
      default: 'yearly'
    }
  },
  data() {
    return {
      errorMessage: null,
      isLoading: false,
      isCollapsed: this.collapsed,
      selectedWage: null,
      showSimilarWages: this.$$isAdmin ? true : localStorage[CW_NEWSFEED_SHOW_SIMILAR_WAGES] === 'true',
      newsfeedWages: []
    }
  },
  computed: {
    isCandidate() {
      return !!this.employee.wageProposal
    },
    currentWageDetails() {
      const currentWage = !this.isCandidate ? this.employee.currentWage : this.employee.wageProposal
      if (currentWage) {
        const grid = this.$store.getters['currentGrid/getCurrentGrid']
        const wageDetails = getWageDetails(grid, currentWage)
        return { ...currentWage, ...wageDetails }
      }
    },
    showDisplayDropdown() {
      return !this.isCollapsed && this.newsfeedWages.find(wage => wage.transition && wage.transition.isSimilar)
    },
    displayDropdownItems() {
      return [{
        name: this.$t('employees.employee.newsfeed.history'),
        items: [{
          name: this.$t('employees.employee.newsfeed.showSimilarWages'),
          value: 'similar',
          selected: this.showSimilarWages
        }]
      }]
    },
    filteredNewsfeedWage() {
      return this.showSimilarWages ? this.newsfeedWages : this.newsfeedWages.filter(wage => this.showSimilarWages || !(wage.transition && wage.transition.isSimilar))
    }
  },
  methods: {
    selectWage(wage) {
      this.selectedWage = wage
    },
    setShowSimilarWages() {
      this.showSimilarWages = !this.showSimilarWages
      localStorage[CW_NEWSFEED_SHOW_SIMILAR_WAGES] = this.showSimilarWages
    },
    formatDate(date) {
      return titleize(formatDate(date, 'MMMM YYYY'))
    },
    async loadNewsfeed() {
      if (this.isCandidate) {
        return
      }
      this.isLoading = true
      try {
        const wages = await this.$store.dispatch('employees/getNewsfeed', this.employee.id)
        this.newsfeedWages = generateNewsfeed(wages)
      }
      catch (error) {
        this.errorMessage = error
      }
      finally {
        this.isLoading = false
      }
    }
  },
  watch: {
    employee: 'loadNewsfeed'
  },
  mounted() {
    this.loadNewsfeed()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/badge.scss";
@import "./src/styles/block.scss";
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";
@import "./src/styles/newsfeed.scss";

h2 {
  margin-top: 2px;
  margin-bottom: 1em;

  .small-button,
  .dropdown {
    @include font-regular;
    margin-left: 0.25em;
    vertical-align: 1.5px;
  }
}

.newsfeed {
  @include container;
  margin-top: 1.5em;

  h2 {
    transition: margin-bottom 300ms;
    transition-delay: 200ms;
    transition-timing-function: ease-in-out;
  }

  .wages {
    overflow: hidden;
  }

  &.collapsed {
    h2 {
      margin-bottom: 0;
    }

    .wages {
      padding-bottom: 0;
    }
  }
}

.wage-details {
  @extend .badge-block;
  @extend .clickable;
  padding: 0.25em 0.75em;

  .components {
    padding: 0;
  }

  &:hover {
    background: darken($graph-lightblue-color, 2);
  }

  &::v-deep .component {
    min-width: 75px;
  }
}

.more-details {
  @include font-small-size;
  margin-top: 0.75em;
}

.wage-transition {
  .badge {
    margin-left: 0.75em;
  }

  .action {
    padding: 1.25em 0;
  }

  .reason-container {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 0.5em;
    margin-top: -0.75em;
    padding-bottom: 1em;
  }

  .reason-label {
    @include font-smaller-size;
    @include font-bold;
    margin-top: 3px;
    text-transform: uppercase;
  }

  .reason {
    margin: 0;

    &::v-deep {
      p {
        margin: 0.25em 0;
      }

      p:first-of-type {
        margin-top: 0;
      }
    }
  }
}

.loading {
  padding: 1em 0;
}

.wage-viewer-modal::v-deep {
  & .close-button-container {
    padding: 20px 1em 0 0;
  }
}

.aligned-footer {
  margin-top: -1.5em;
  margin-left: 10.8em;
}
</style>
