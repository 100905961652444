<template>
  <div class="badge-wrapper">
    <div class="badge" :class="benefit.details.category" />
  </div>
</template>

<script>
export default {
  props: {
    benefit: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.badge-wrapper {
  border-radius: 1000px;
  background-color: white;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.badge {
  width: 24px;
  height: 24px;
  border: 0;

  &.health {
    background-image: url(~@/assets/benefits/health.svg);
  }

  &.food {
    background-image: url(~@/assets/benefits/food.svg);
  }

  &.housing {
    background-image: url(~@/assets/benefits/housing.svg);
  }

  &.leisure {
    background-image: url(~@/assets/benefits/leisure.svg);
  }

  &.retirement {
    background-image: url(~@/assets/benefits/retirement.svg);
  }

  &.bonus {
    background-image: url(~@/assets/benefits/bonus.svg);
  }

  &.publicTransportation {
    background-image: url(~@/assets/benefits/public-transportation.svg);
  }

  &.motorisedTransportation {
    background-image: url(~@/assets/benefits/motorised-transportation.svg);
  }

  &.nonMotorisedTransportation {
    background-image: url(~@/assets/benefits/non-motorised-transporation.svg);
  }

  &.formation {
    background-image: url(~@/assets/benefits/formation.svg);
  }

  &.vacation {
    background-image: url(~@/assets/benefits/vacation.svg);
  }

  &.parenting {
    background-image: url(~@/assets/benefits/parenting.svg);
  }

  &.supply {
    background-image: url(~@/assets/benefits/supply.svg);
  }

  &.misc {
    background-image: url(~@/assets/benefits/misc.svg);
  }
}
</style>
