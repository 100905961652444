<template>
  <div class="grid-navigation" :class="{visible: activeComponentSlug}">
    <div class="grid-navigation-border">
      <div class="component"
        :class="{active: getComponentSlug(component) == activeComponentSlug}"
        v-for="component in components"
        :key="component.id"
        @click="$emit('select', component)">
        <h2>
          {{component.name}}
          <span v-for="linkedComponent in component.linkedComponents" :key="linkedComponent.id">
            {{remunerationOperationSymbol(linkedComponent.salaryOperation)}}
            {{linkedComponent.name}}
          </span>
        </h2>
      </div>
      <div class="component scroll-top" @click="$emit('select')">
        <h2 v-t="'common.back'"></h2>
      </div>
    </div>
  </div>
</template>

<script>
import { remunerationOperationSymbol } from '@/utils/grid'
import { slugify } from '@/utils/string'

export default {
  props: {
    components: Array,
    activeComponentSlug: String
  },
  methods: {
    remunerationOperationSymbol,
    getComponentSlug(component) {
      return slugify(component.name)
    }
  }
}
</script>

<style lang="scss" scoped>
.grid-navigation {
  position: sticky;
  top: 0;
  z-index: 1;
  transition: opacity 200ms;
  height: 0;

  &:not(.visible) {
    opacity: 0;
    pointer-events: none;
  }
}

.grid-navigation-border {
  background: white;
  border-bottom: 1px solid $graph-outer-border-color;
  margin: 0 -20px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.03);
}

.component {
  display: inline-block;
  padding: 0.75em 20px;
  cursor: pointer;

  &:hover {
    background-color: lighten($graph-lightblue-color, 1);
  }

  &.active {
    background: darken($graph-lightblue-color, 2);
  }
}

.component h2 {
  @include font-large-size;
  @include font-semibold;
  margin: 0;
  display: inline-block;
  color: $graph-darkblue-color;
  border-bottom: 1px solid transparent;
  transition: 300ms border-bottom-color;
}

.scroll-top {
  float: right;

  h2 {
    color: $light-text-color;
    @include font-medium;
  }

  h2::after {
    content: ' ';
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: 0.25em;
    background: transparent url(~@/assets/arrow-down.svg) no-repeat center right;
    vertical-align: -2px;
    transform: scaleY(-1);
    opacity: 0.7;
  }
}
</style>
