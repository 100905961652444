<template>
  <div
    v-if="candidates.length"
    class="candidates"
    :class="{compact}">
    <table class="blue" :class="{inverted: compact}">
      <template v-for="candidate in candidates">
        <tr
          class="candidate row clickable"
          :key="candidate.id"
          :data-id="candidate.id"
          @click="$emit('select', candidate)">
          <td class="candidate-name">
            <name-thumbnail class="blue" :employee="candidate" inline=true />
            {{ candidate.firstName }} {{ candidate.lastName }}
          </td>
          <td v-if="compact">
            {{ candidate.proposedSalary | formattedCurrency }}
          </td>
          <td v-else>
            <inline-wage
              class="inline-wage"
              :wageDetails="candidate.wageProposalDetails"
              :showSalaryBadges="true" />
          </td>
        </tr>
      </template>
    </table>
  </div>
</template>

<script>
import animateScrollTo from 'animated-scroll-to'
import NameThumbnail from '@components/commons/NameThumbnail.vue'
import InlineWage from '@components/wage/InlineWage.vue'

export default {
  props: {
    candidates: Array,
    compact: Boolean
  },
  methods: {
    highlightCandidate() {
      const candidateId = this.$route.params.highlightCandidate
      if (this.candidates.find(c => c.id === candidateId)) {
        const candidate = this.$el.querySelector(`.candidate[data-id="${candidateId}"]`)
        if (candidate) {
          animateScrollTo(this.$el, {
            verticalOffset: -115,
            minDuration: 0,
            maxDuration: 0
          }).then(() => {
            candidate.classList.add(this.$route.params.highlightClass || 'zoom-in')
          })
        }
      }
    }
  },
  mounted() {
    this.highlightCandidate()
  },
  components: {
    NameThumbnail,
    InlineWage
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/table.scss";

.candidates {
  border: 1px $graph-outer-border-color solid;
  border-radius: $border-radius;
  box-shadow: 1px 1.5px 0 rgba(0, 0, 0, 0.03);
}

.candidates.compact table td {
  line-height: 2.5em;
}

table {
  white-space: nowrap;

  td {
    border: 1px solid $graph-outer-border-color;
  }

  td:last-of-type {
    text-align: right;
    @include font-tabular-numbers;
  }
}

.inline-wage {
  display: block;
  padding: 0 0.5em 0 0;
  border-image: none;
  border-bottom: none;
  @include font-small-size;
  line-height: 1.3em;

  &::v-deep {
    label {
      @include font-smaller-size;
    }

    .component {
      margin: 0;
      padding: 0.2em 0.6em;
    }

    .component:not(:last-of-type) {
      margin-right: 7px;
    }

    .badge, .badge.delta-badge {
      @include font-normal-size;
      float: right;
      margin: 5px 0 0 1em;
    }
  }
}

</style>
