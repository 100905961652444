<template>
  <div class="tabs" :class="activeTab && activeTab.id">
    <div class="header">
      <div
        v-for="tab in tabs"
        :key="tab.id"
        @click="click(tab)"
        :class="['tab', !tab.value ? 'empty': '', tab.id, activeTab && activeTab.id === tab.id ? 'active' : ''].join(' ')">
        <div class="tab-content">
          <label>{{ tab.name }}</label>
          <strong>{{ tab.value | formattedCurrency(false, {selectedRecurrence}) }}</strong>
        </div>
        <div class="fix-bottom"></div>
      </div>
    </div>
    <div class="border"></div>
  </div>
</template>

<script>
export default {
  props: ['tabs', 'activeTab', 'selectedRecurrence'],
  methods: {
    click(tab) {
      this.$emit('tabSelected', tab)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/link.scss";

$salary-color: #b5cedf;
$variable-color: #dbb2dc;
$benefits-color: #94d3ce;

.header {
  z-index: 0;
  padding-left: 167px;
  background: transparent;
  display: flex;
}

.tabs {
  margin-top: -52px;

  .tab {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-top: -3px;
    top: 3px;
    width: 130px;
    height: 55px;
    margin-left: 5px;

    z-index: 0;
    background-color: white;
    border: $border;
    border-bottom: none;
    border-radius: $border-radius;
    background-image: linear-gradient(
      to top,
      lighten($background-color, 1.5),
      white
    );
    cursor: pointer;

    &:first-child {
      margin-left: 0;
    }

    .tab-content {
      margin-left: 10px;

      label {
        display: block;
        @include font-small-size;
        @include font-semibold;
        color: $light-text-color;
        cursor: pointer;
      }

      strong {
        @include font-big-size;
        @include font-bold;
        @include font-alternate-digits;
        line-height: 1.3em;
        color: darken($light-text-color, 7);
      }
    }

    &.empty {
      justify-content: center;

      .tab-content {
        margin-left: 0;
      }

      label {
        @include font-normal-size;
      }

      strong {
        display: none;
      }
    }

    &.active {
      z-index: 1;
      background: white;
      @include font-semibold;
    }

    &.salary {
      label {
        color: darken($salary-color, 23);
      }

      strong {
        color: darken($salary-color, 30);
      }
    }

    &.variable {
      label {
        color: darken($variable-color, 23);
      }
      strong {
        color: darken($variable-color, 25);
      }
    }

    &.benefits {
      label {
        color: darken($benefits-color, 23);
      }
      strong {
        color: darken($benefits-color, 25);
      }
    }
  }

  &.salary .border,
  &.salary .tab.active,
  .tab.salary:hover {
    border-color: $salary-color;
  }

  &.variable .border,
  &.variable .tab.active,
  .tab.variable:hover {
    border-color: desaturate(lighten($variable-color, 5), 10);
  }

  &.benefits .border,
  &.benefits .tab.active,
  .tab.benefits:hover {
    border-color: desaturate(lighten($benefits-color, 10), 10);
  }
}

.border {
  position: relative;
  border-top: $border;
  background-color: white;
}

.fix-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3px;
  background-color: white;
}

.active {
  z-index: 1;
  background: white;
  @include font-semibold;
}
</style>
