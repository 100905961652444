<template>
  <div class="wage-sharing-panel">
    <h2 v-t="'wageSharing.share'"></h2>
    <div>
      <checkbox
        :value="true"
        v-model="isWageSharing"
        @change="onChange">
        {{$t('wageSharing.shareCheckbox')}}
      </checkbox>
    </div>
    <resizable-panel :observer="wage.sharingToken">
      <div v-if="wage.sharingToken">
        <div class="wage-sharing-link">
          <copy-input v-model="url" />
          <button class="secondary" v-t="'common.remove'" @click="removeLink"></button>
        </div>
        <div class="wage-sharing-editor">
          <label v-t="'wageSharing.linkPreview'"></label>
          <wage-sharing-viewer :wage="wage" :isEditable="true" />
        </div>
      </div>
    </resizable-panel>
  </div>
</template>

<script>
import Checkbox from '@components/commons/Checkbox'
import CopyInput from '@components/commons/CopyInput'
import ResizablePanel from '@components/commons/ResizablePanel'
import WageSharingViewer from '@components/wage/WageSharingViewer'

export default {
  props: {
    employee: Object,
    wage: Object,
    template: String
  },
  data() {
    return {
      isCreating: false,
      isWageSharing: !!this.wage.sharingToken
    }
  },
  computed: {
    url() {
      return this.$t('urls.wageSharing', { token: this.wage.sharingToken })
    }
  },
  methods: {
    onChange(isWageSharing) {
      if (isWageSharing) {
        this.createLink()
      }
      else {
        this.removeLink()
      }
    },
    createLink() {
      this.isCreating = true
      this.$store.dispatch('wageSharing/share', {
        wage: this.wage,
        employee: this.employee,
        template: this.template
      })
        .then(() => {
          this.isCreating = false
          this.isWageSharing = true
        })
        .catch(error => {
          error && alert(error)
          this.isCreating = false
          this.isWageSharing = false
        })
    },
    removeLink() {
      if (window.confirm(this.$t('wageSharing.removeConfirmation'))) {
        this.$store.dispatch('wageSharing/remove', this.wage)
          .then(() => {
            this.isWageSharing = false
          })
          .catch(error => {
            error && alert(error)
            this.isWageSharing = true
          })
      }
      else {
        this.$nextTick(() => {
          this.isWageSharing = true
        })
      }
    }
  },
  components: {
    Checkbox,
    CopyInput,
    ResizablePanel,
    WageSharingViewer
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/animation.scss";
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";

.wage-sharing-panel {
  @include container;
  margin-top: 1.5em;
}

.wage-sharing-link {
  margin-top: 1em;

  input[type="text"] {
    display: inline-block;
    width: 320px;
  }

  button {
    margin-left: 0.6rem;
  }
}

.wage-sharing-editor {
  background-color: $background-color;
  border: $border;
  border-radius: $border-radius;
  margin-top: 1.35em;
  overflow: hidden;
  box-shadow: inset 2px 2px 0 rgba($darkteal-color, 0.05);

  label {
    background: $clearteal-color;
    color: white;
    @include font-smaller-size;
    @include font-bold;
    text-transform: uppercase;
    padding: 0.6em 1em;
    float: left;
    border-radius: 0 0 $border-radius 0;
    box-shadow: inset 2px 2px 0 rgba($darkteal-color, 0.1);
  }
}
</style>
