import { captureException } from '@sentry/browser'
import Vue from 'vue'
import Vuex from 'vuex'
import cookies from 'js-cookie'

import api from '@/store/api'
import router from '@/router'
import i18n from '@/i18n'

import account from '@modules/account'
import benefits from '@modules/benefits'
import candidates from '@modules/candidates'
import company from '@modules/company'
import currentGrid from '@modules/current-grid'
import employees from '@/store/modules/employees'
import employeesImporter from '@/store/modules/employees-importer'
import employeesReview from '@/store/modules/employees-review'
import jobs from '@modules/jobs'
import orgChart from '@modules/org-chart'
import okta from '@modules/okta'
import onboarding from '@modules/onboarding'
import reviews from '@modules/reviews'
import sandbox from '@modules/sandbox'
import sandboxList from '@modules/sandbox-list'
import statistics from '@modules/statistics'
import synchronization from '@modules/synchronization'
import teams from '@/store/modules/teams'
import variablePlans from '@modules/variable-plans'
import wagePlans from '@modules/wage-plans'
import wageSharing from '@modules/wage-sharing'
import workers from '@modules/workers'

import { resetAnalyticsFields } from '@/services/analytics'
import { loadBeacon, resetHelpscout } from '@/services/helpscout'
import { resetSentryUserContext } from '@/services/sentry'
import { destroySnippet } from '@/services/snippet'
import { COMPANY_STATUSES } from '@/utils/company'

const CW_ADMIN_SESSION_COOKIE = cookies.get('s')
const CW_SESSION_TOKEN = 'CW-SESSION-TOKEN'
const CW_IS_READONLY_SESSION = 'CW-IS-READONLY-SESSION'

const HTTP_ERROR_BAD_REQUEST = 400
const HTTP_ERROR_FORBIDDEN = 403
const HTTP_ERROR_NOT_FOUND = 404
const HTTP_ERROR_NOT_ALLOWED = 405

Vue.use(Vuex)

const storedSessionToken = sessionStorage.getItem(CW_SESSION_TOKEN) || CW_ADMIN_SESSION_COOKIE || localStorage.getItem(CW_SESSION_TOKEN)
api.defaults.headers.common.Authorization = storedSessionToken

const store = new Vuex.Store({
  modules: {
    account,
    benefits,
    candidates,
    company,
    currentGrid,
    employees,
    employeesImporter,
    employeesReview,
    jobs,
    orgChart,
    okta,
    onboarding,
    reviews,
    sandbox,
    sandboxList,
    statistics,
    synchronization,
    teams,
    variablePlans,
    wagePlans,
    wageSharing,
    workers
  },
  state: {
    sessionToken: storedSessionToken,
    isSessionReadOnly: sessionStorage.getItem(CW_IS_READONLY_SESSION) || false,
    isAppLoaded: false,
    accountMenuDisplayed: false,
    isReadOnlyAlertDisplayed: false
  },
  getters: {
    hasSession(state) {
      return state.sessionToken != null
    },
    isAppLoaded(state) {
      return state.isAppLoaded
    },
    isAdminSession(state) {
      return cookies.get('s') !== undefined
    },
    isAccountMenuDisplayed(state) {
      return state.accountMenuDisplayed
    },
    isReadOnlyAlertDisplayed(state) {
      return state.isReadOnlyAlertDisplayed
    },
    isSessionReadOnly(state) {
      return state.isSessionReadOnly
    },
    isDemoSession(state, _, __, rootGetters) {
      return state.isSessionReadOnly && (rootGetters['account/getCompany'] || {}).status === COMPANY_STATUSES.internal
    },
    isLeadSession(_, __, ___, rootGetters) {
      return (rootGetters['account/getCompany'] || {}).status === COMPANY_STATUSES.lead
    },
    isTrialExpired(_, { isLeadSession }, ___, rootGetters) {
      const company = (rootGetters['account/getCompany'] || {})
      const isTrialExpired = company.trialExpiresAt && new Date(company.trialExpiresAt).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)
      return isLeadSession && isTrialExpired
    },
    isExpiredSession(_, __, ___, rootGetters) {
      return (rootGetters['account/getCompany'] || {}).status === COMPANY_STATUSES.churn
    }
  },
  actions: {
    handleAPIError(context, error) {
      let errorMessage
      if (error && error.response) {
        switch (error.response.status) {
          case HTTP_ERROR_FORBIDDEN:
            // Session seems invalid, destroy it
            context.commit('destroySession')
            break
          case HTTP_ERROR_BAD_REQUEST:
          case HTTP_ERROR_NOT_FOUND:
            // Regular errors, do nothing
            break
          case HTTP_ERROR_NOT_ALLOWED:
            // Read only session, show demo modal
            context.commit('setIsReadOnlyAlertDisplayed', true)
            break
          default:
            // Unusual error, log it
            captureException(error)
        }
        // Extract error message
        try {
          if (error.response.status === HTTP_ERROR_NOT_ALLOWED) {
            errorMessage = null
          }
          else if (error.response.data && error.response.data.error) {
            errorMessage = error.response.data.error
          }
          else if (error.response.data && error.response.data.errors) {
            const message = Object.entries(error.response.data.errors).map(([field, fieldErrors]) => (
              fieldErrors.map(fieldError => [field, fieldError].join(' '))
                .join(', ')
            )).join(', ')
            errorMessage = i18n.t('common.genericServerError', { message })
          }
          else if (error.response.data) {
            errorMessage = i18n.t('common.genericServerError', { message: error.response.data })
          }
        }
        catch { }
      }
      return Promise.reject(errorMessage)
    },
    setIsAppLoaded({ commit, getters }) {
      commit('setIsAppLoaded')
      if (getters.isExpiredSession) {
        commit('setIsReadOnlyAlertDisplayed', true)
      }
    }
  },
  mutations: {
    setIsAppLoaded(state) {
      state.isAppLoaded = true
      loadBeacon()
    },
    setAdminSession(_, { token }) {
      setSessionCookie(token)
      location.reload()
    },
    setSession(state, { token, isReadOnly }) {
      state.sessionToken = token
      state.isSessionReadOnly = isReadOnly

      if (state.isSessionReadOnly) {
        sessionStorage.setItem(CW_IS_READONLY_SESSION, true)
        sessionStorage.setItem(CW_SESSION_TOKEN, state.sessionToken)
      }
      else if (!CW_ADMIN_SESSION_COOKIE) {
        localStorage.setItem(CW_SESSION_TOKEN, state.sessionToken)
      }

      api.defaults.headers.common.Authorization = state.sessionToken
    },
    destroySession(state) {
      state.isAppLoaded = false

      if (CW_ADMIN_SESSION_COOKIE) {
        removeSessionCookie()
      }
      else {
        resetHelpscout()
        resetAnalyticsFields()
        resetSentryUserContext()
        localStorage.clear()
        sessionStorage.clear()
        state.sessionToken = null
        api.defaults.headers.common.Authorization = null
        router.push('/signin')
        destroySnippet()
      }
    },
    setAccountMenuDisplayed(state, displayed) {
      state.accountMenuDisplayed = displayed
    },
    setIsReadOnlyAlertDisplayed(state, isReadOnlyAlertDisplayed) {
      state.isReadOnlyAlertDisplayed = isReadOnlyAlertDisplayed
    }
  },
  strict: process.env.NODE_ENV !== 'production'
})

function setSessionCookie(token) {
  const domain = location.host.includes('clearwage.com') ? '.clearwage.com' : undefined
  const expires = 1 // day
  const params = { domain, expires }
  cookies.set('s', token, params)
  sessionStorage.setItem(CW_IS_READONLY_SESSION, true)
}

function removeSessionCookie() {
  const domain = location.host.includes('clearwage.com') ? '.clearwage.com' : undefined
  const params = { domain }
  cookies.remove('s', params)
  sessionStorage.removeItem(CW_IS_READONLY_SESSION)
}

function readOnlyInterceptor(request) {
  if (store.getters.isSessionReadOnly || store.getters.isExpiredSession || store.getters.isTrialExpired) {
    if (['get', 'options'].includes(request.method)) {
      return request
    }

    // Allow signin or stripe subscription
    if (request.method === 'post' && (request.url === '/account/signin' || request.url === '/company/stripe_subscription')) {
      return request
    }

    const error = { response: { status: 405 } }
    throw error
  }
  else {
    return request
  }
}

api.interceptors.request.use(readOnlyInterceptor)

export default store
