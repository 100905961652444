import Vue from 'vue'
import orderBy from 'lodash.orderby'
import api from '@/store/api'
import groupBy from 'lodash.groupby'
import uniq from 'lodash.uniq'
import { buildSearchTerms } from '@/utils/string'
import { formatSmartPeriod } from '@/utils/variable'
import {
  debouncedAction,
  sequentialDispatch
} from '@/utils/store'
import {
  normalizedVariablePlans,
  normalizedVariablePlan,
  denormalizedVariablePlans,
  denormalizedVariablePlan
} from '@/store/schema'

const RECURRENCES = {
  weekly: 'weekly',
  // bimonthly: 'bimonthly', // Unsupported
  monthly: 'monthly',
  quarterly: 'quarterly',
  biannual: 'biannual',
  yearly: 'yearly',
  none: 'none'
}

const STATUSES = {
  draft: 'draft',
  ongoing: 'ongoing',
  closed: 'closed'
}
const STATUSES_ORDER = [STATUSES.draft, STATUSES.ongoing, STATUSES.closed]

const SCOPES = {
  employee: 'employee',
  team: 'team',
  company: 'company'
}

const KINDS = {
  individual_variable: 'individual_variable',
  collective_variable: 'collective_variable',
  bonus: 'bonus',
  profit_sharing: 'profit_sharing',
  incentive: 'incentive'
}

const initialState = () => {
  return {
    currentVariablePlanName: null,
    variablePlanIds: [],
    variablePlans: {},
    variableWages: {},
    variablePlanModel: {
      name: '',
      version: 1,
      startDate: new Date(),
      endDate: new Date(),
      kind: KINDS.individual_variable,
      scope: SCOPES.employee,
      recurrence: RECURRENCES.monthly,
      status: STATUSES.draft,
      config: {}
    },
    variableWageModel: {
      indicators: []
    }
  }
}

const state = initialState()

const getters = {
  getVariablePlanModel(state) {
    return state.variablePlanModel
  },
  getGroupedVariablePlans(_, { getVariablePlans }) {
    const variablePlansByGroupKey = groupBy(getVariablePlans, 'groupKey')
    return Object.values(variablePlansByGroupKey).map(groupedVariablePlans => {
      const variablePlans = orderBy(groupedVariablePlans, [v => STATUSES_ORDER.indexOf(v.status), 'startDate'], ['asc', 'desc'])
      const refVariablePlan = variablePlans[0]

      return {
        id: refVariablePlan.id,
        name: refVariablePlan.name,
        description: refVariablePlan.description,
        recurrence: refVariablePlan.recurrence,
        variablePlans,
        _searchKey: buildSearchTerms(refVariablePlan.name)
      }
    })
  },
  getVariablePlans(state) {
    return denormalizedVariablePlans(state)
  },
  getVariablePlan({ variablePlans, variableWages }) {
    return variablePlanId => denormalizedVariablePlan({ variablePlanId, variablePlans, variableWages })
  },
  getEvaluationOptions(_, { getEmployees }) {
    return (variablePlan, mode, employee) => {
      const employees = getEmployees(variablePlan)
      const variableWagesCount = employees.length
      const { startDate, endDate, recurrence } = variablePlan
      const period = { startDate, endDate, recurrence }
      return { mode, employee, employees, variableWagesCount, period }
    }
  },
  getEmployees(_state, _getters, _rootState, rootGetters) {
    return variablePlan => {
      const userIds = (variablePlan.variableWages || []).map(v => v.userId)
      return userIds.length ? rootGetters['employees/getEmployeesByIds'](userIds) : rootGetters['employees/getEmployees']
    }
  },
  getCurrentVariablePlanName(state) {
    return state.currentVariablePlanName
  },
  getRecurrences() {
    return Object.values(RECURRENCES)
  },
  getKinds() {
    return Object.values(KINDS)
  },
  getStatuses() {
    return Object.values(STATUSES)
  }
}

const actions = {
  reset(context) {
    context.commit('reset')
  },
  init(context) {
    if (context.rootGetters['account/isAdmin']) {
      return context.dispatch('getVariablePlans')
    }
    else {
      return Promise.resolve()
    }
  },
  getVariablePlans(context) {
    return api.get('/variable_plans')
      .then(({ data }) => {
        context.commit('setVariablePlans', data)
        return data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  getVariablePlan(context, id) {
    return api.get('/variable_plan/' + id)
      .then(({ data }) => {
        context.commit('setVariablePlan', data)
        return data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  async getVariablePlansWithWages(context) {
    const variablePlans = await context.dispatch('getVariablePlans')
    await sequentialDispatch(context, variablePlans.map(variablePlan => ['getVariablePlan', variablePlan.id]))
    return context.getters.getVariablePlans
  },
  async getVariablePayrollElements(context, { startDate, endDate }) {
    // Load all variable plans and wages
    let variablePlans = await context.dispatch('getVariablePlansWithWages')

    // VariablePlans will be filtered by date and only closed ones will be included.
    variablePlans = variablePlans
      .filter(v => v.status === 'closed')
      .filter(v => v.endDate >= startDate && v.endDate <= endDate)
    variablePlans = orderBy(variablePlans, ['name', 'endDate'], ['asc', 'asc'])

    // Format them so they can be used easily in export
    return variablePlans.reduce((memo, variablePlan) => {
      const name = [variablePlan.name, formatSmartPeriod(variablePlan, ' → ')].join(' ')
      memo[name] = variablePlan.variableWages.reduce((memo2, variableWage) => {
        memo2[variableWage.userId] = variableWage.computedCents / 100
        return memo2
      }, {})
      return memo
    }, {})
  },
  createVariablePlan(context, variablePlan) {
    return api.post('/variable_plan', variablePlan)
      .then(({ data }) => {
        context.commit('setVariablePlan', data)
        context.dispatch('employees/needsRefresh', null, { root: true })
        return data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  async createVariablePlans(context, variablePlans) {
    const actions = variablePlans.map(variablePlan => {
      return ['createVariablePlan', variablePlan]
    })
    await sequentialDispatch(context, actions)
  },
  updateVariablePlan(context, variablePlan) {
    return api.patch('/variable_plan/' + variablePlan.id, variablePlan)
      .then(({ data }) => {
        context.commit('setVariablePlan', data)
        context.dispatch('employees/needsRefresh', null, { root: true })
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  removeVariablePlan(context, variablePlan) {
    return api.delete('/variable_plan/' + variablePlan.id)
      .then(() => {
        context.commit('removeVariablePlan', variablePlan)
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  createVariableWage(context, { variablePlan, employeeId, variableWage }) {
    return api.post(`/employee/${employeeId}/variable_wage?variable_plan_id=` + variablePlan.id, variableWage)
      .then(({ data }) => {
        context.commit('setVariableWage', data)
        return data
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  async setVariableWages({ commit, dispatch }, { variablePlan, variableWages }) {
    try {
      const { data } = await api.put(`/variable_plan/${variablePlan.id}/variable_wages`, { variableWages })
      commit('setVariablePlan', data)
    }
    catch (error) {
      throw await dispatch('handleAPIError', error, { root: true })
    }
  },
  createVariableWages(context, { variablePlan, employeeIds }) {
    if (!employeeIds.length) {
      return Promise.resolve()
    }
    return api.put(`/variable_plan/${variablePlan.id}/assign`, { employeeIds })
      .then(({ data }) => {
        context.commit('setVariablePlan', data)
        context.dispatch('employees/needsRefresh', null, { root: true })
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  removeVariableWage(context, { variablePlan, employeeId }) {
    // Not used
    const updatedVariablePlan = context.getters.getVariablePlan(variablePlan.id)
    const variableWage = updatedVariablePlan.variableWages.find(w => w.userId === employeeId)

    return api.delete(`/employee/${employeeId}/variable_wage/${variableWage.id}`)
      .then(_ => {
        context.commit('removeVariableWage', { variablePlanId: variablePlan.id, variableWageId: variableWage.id })
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  removeVariableWages(context, { variablePlan, employeeIds }) {
    if (!employeeIds.length) {
      return Promise.resolve()
    }
    return api.put(`/variable_plan/${variablePlan.id}/unassign`, { employeeIds })
      .then(({ data }) => {
        context.commit('setVariablePlan', data)
        context.dispatch('employees/needsRefresh', null, { root: true })
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  assignVariableWages(context, { variablePlan, addedEmployeeIds, removedEmployeeIds }) {
    const actions = [
      ['createVariableWages', { variablePlan, employeeIds: addedEmployeeIds }],
      ['removeVariableWages', { variablePlan, employeeIds: removedEmployeeIds }]
    ]
    return sequentialDispatch(context, actions).then(() => {
      return context.getters.getVariablePlan(variablePlan.id)
    })
  },
  updateVariableWageDebounced: debouncedAction((context, variableWage) => {
    return api.patch(`/employee/${variableWage.userId}/variable_wage/${variableWage.id}`, variableWage)
      .then(({ data }) => {
        context.commit('setVariableWage', data)
      })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  }, null, 'userId'),
  async getDataFeed({ commit, dispatch }, { variablePlanId, id }) {
    try {
      const { data } = await api.get(`/data_feed/${id}`)
      commit('setDataFeed', { variablePlanId, dataFeed: data })
      return data
    }
    catch (error) {
      throw await dispatch('handleAPIError', error, { root: true })
    }
  },
  async createDataFeedItems(context, { dataFeedId, dataFeedItems }) {
    // Does not update state
    return api.post(`/data_feed/${dataFeedId}/items`, { dataFeedItems })
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  },
  async removeDataFeedItems({ dispatch }, dataFeedId) {
    // Does not update state
    return api.delete(`/data_feed/${dataFeedId}/items`)
      .catch(error => dispatch('handleAPIError', error, { root: true }))
  },
  async removeDataFeedItem(context, { dataFeedId, id }) {
    // Does not update state, should be called along with getDataFeed()
    return api.delete(`/data_feed/${dataFeedId}/item/${id}`)
      .catch(error => context.dispatch('handleAPIError', error, { root: true }))
  }
}

const mutations = {
  reset(state) {
    Object.assign(state, initialState())
  },
  setVariablePlans(state, variablePlans) {
    const n = normalizedVariablePlans(variablePlans)
    state.variablePlanIds = n.result
    // Preserve variableWages since they are not returned when listing the plans
    if (n.entities.variablePlans) {
      Object.values(n.entities.variablePlans).forEach(variablePlan => {
        if (!variablePlan.variableWages && state.variablePlans[variablePlan.id]) {
          variablePlan.variableWages = state.variablePlans[variablePlan.id].variableWages
        }
      })
    }
    state.variablePlans = { ...state.variablePlans, ...n.entities.variablePlans }
    state.variableWages = { ...state.variableWages, ...n.entities.variableWages }
  },
  setVariablePlan(state, variablePlan) {
    const n = normalizedVariablePlan(variablePlan)
    // Preserve dataFeeds & variableWages since they are not returned when updating the plan
    if (!variablePlan.dataFeeds && state.variablePlans[n.result]) {
      n.entities.variablePlans[n.result].dataFeeds = state.variablePlans[n.result].dataFeeds
    }
    if (!variablePlan.variableWages && state.variablePlans[n.result]) {
      n.entities.variablePlans[n.result].variableWages = state.variablePlans[n.result].variableWages
    }
    state.variablePlanIds = uniq([...state.variablePlanIds, n.result])
    state.variablePlans = { ...state.variablePlans, ...n.entities.variablePlans }
    state.variableWages = { ...state.variableWages, ...n.entities.variableWages }
  },
  removeVariablePlan(state, variablePlan) {
    state.variablePlanIds = state.variablePlanIds.filter(id => id !== variablePlan.id)
    Vue.delete(state.variablePlans, variablePlan.id)
  },
  setCurrentVariablePlan(state, { name }) {
    state.currentVariablePlanName = name
  },
  setVariableWage(state, variableWage) {
    const { variablePlanId } = variableWage
    const variablePlan = state.variablePlans[variablePlanId]
    if (!variablePlan.variableWages.includes(variableWage.id)) {
      variablePlan.variableWages.push(variableWage.id)
    }
    Vue.set(state.variableWages, variableWage.id, variableWage)
  },
  removeVariableWage(state, { variablePlanId, variableWageId }) {
    const variablePlan = state.variablePlans[variablePlanId]
    variablePlan.variableWages.splice(variablePlan.variableWages.indexOf(variableWageId), 1)
    Vue.delete(state.variableWages, variableWageId)
  },
  setDataFeed(state, { variablePlanId, dataFeed }) {
    const variablePlan = state.variablePlans[variablePlanId]
    if (variablePlan) {
      variablePlan.dataFeeds = (variablePlan.dataFeeds || [])
        .filter(d => d.id !== dataFeed.id)
        .concat([dataFeed])
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
