<template>
  <div class="container">
    <h1 v-t="'hiringPlans.hiringPlans'"></h1>
    <div class="hiring-plans" v-if="displayHiringPlans">
      <div class="lone-candidates" v-if="loneCandidates.length">
        <h2 class="lone-candidates-title" v-t="'candidates.loneCandidates'"></h2>
        <div class="lone-candidates-container">
          <candidates-table
            :candidates="loneCandidates"
            :compact="true"
            @select="openCandidate" />
        </div>
      </div>
      <hiring-plan
        v-for="hiringPlan in hiringPlans"
        :key="hiringPlan.id"
        :hiringPlan="hiringPlan" />
      <div class="hiring-plans-add">
        {{$t('hiringPlans.incentive')}}
        <router-link
          tag="button"
          class="primary"
          :to="{name: 'addHiringPlan'}"
          v-t="'hiringPlans.addHiringPlan'">
        </router-link>
      </div>
    </div>
    <div v-else>
      <p v-html="$t('hiringPlans.intro')" class="preserve-lines"></p>
      <menu class="hero">
        <router-link
          tag="button"
          class="primary"
          :to="{name: 'addJobOffer'}"
          v-t="'candidates.addJobOffer'">
        </router-link>
      </menu>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CandidatesTable from '@components/candidates/CandidatesTable.vue'
import HiringPlan from '@components/hiringPlans/HiringPlan'

export default {
  computed: {
    ...mapGetters({
      currentGrid: 'currentGrid/getCurrentGrid',
      hiringPlans: 'candidates/getEnrichedHiringPlans',
      getEnrichedCandidatesByJobOffer: 'candidates/getEnrichedCandidatesByJobOffer'
    }),
    loneCandidates() {
      return this.getEnrichedCandidatesByJobOffer(null)
    },
    displayHiringPlans() {
      return this.hiringPlans.length || this.loneCandidates.length
    }
  },
  methods: {
    openCandidate({ id }) {
      this.$router.push({ name: 'candidate', params: { id } })
    }
  },
  components: {
    CandidatesTable,
    HiringPlan
  },
  mounted() {
    this.$store.dispatch('candidates/getCandidates')
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/badge.scss";
@import "./src/styles/button.scss";

.container {
  @include container;
}

.lone-candidates-title {
  @include font-large-size;
  margin: 0;
}

.lone-candidates-container {
  margin: 1em 0 1.5em;
  width: 30%;
}

.lone-candidates:not(:first-of-type),
.hiring-plans-add:not(:first-of-type) {
  border-top: 1px solid $graph-inner-border-color;
  padding-top: 1em;
}

.hiring-plans-add {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}
</style>
