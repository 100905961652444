import api from '@/store/api'
import { slugify } from '@/utils/string'

function buildSearchTerms(...args) {
  return slugify(args.join(' '))
}

function matchSearchTerms(terms, key) {
  return terms.reduce((found, term) => {
    return found && key.indexOf(term) !== -1
  }, true)
}

function buildJobId(jobId) {
  // Handle both PCS & Job Id (extension of PCS)
  return jobId.length === 4 ? `${slugify(jobId)}0` : jobId
}

const initialState = () => {
  return {
    jobs: [],
    indexedJobs: {},
    searchableJobs: [],
    currentJobName: null,
    suggestedJobs: [
      { id: '388a10', job: 'Développeur' },
      { id: '374b3', job: 'Chef de produit' },
      { id: '467a24', job: 'Responsable service client' },
      { id: '465a4', job: 'Designer' },
      { id: '374d2', job: 'Business Developer' },
      { id: '374b5', job: 'Responsable marketing' },
      { id: '388b8', job: 'Administrateur système' },
      { id: '372c13', job: 'Gestionnaire ressources humaines' }
    ],
    regions: [
      'AUVERGNE-RHONE-ALPES',
      'BOURGOGNE-FRANCHE-COMTE',
      'BRETAGNE',
      'CENTRE-VAL DE LOIRE',
      'CORSE',
      'GRAND EST',
      'HAUTS-DE-FRANCE',
      'ILE-DE-FRANCE',
      'NORMANDIE',
      'NOUVELLE-AQUITAINE',
      'OCCITANIE',
      'PAYS DE LA LOIRE',
      'PROVENCE-ALPES-COTE D\'AZUR'
    ],
    defaultRegion: 'ILE-DE-FRANCE'
  }
}

const state = initialState()

const getters = {
  getRegions(state) {
    return state.regions
  },
  getDefaultRegion(state) {
    return state.defaultRegion
  },
  getSuggestedJobs(state) {
    return state.suggestedJobs
  },
  getJobs(state) {
    return state.jobs
  },
  getRandomJobs(state) {
    return (len) => {
      const jobs = []
      while (len-- > 0) {
        const index = Math.floor(Math.random() * state.jobs.length) - 1
        jobs.push(state.jobs[index])
      }
      return jobs
    }
  },
  getJob(state) {
    return (jobId) => state.indexedJobs[jobId]
  },
  getCurrentJobName(state) {
    return state.currentJobName
  },
  getRelatedJobs(state, getters) {
    return (jobId) => {
      const job = getters.getJob(jobId)

      return state.searchableJobs.filter(j => (
        j.professionCode === job.professionCode
      ))
    }
  },
  getJobsCount(state) {
    return state.jobs.length
  },
  searchJobs(state) {
    return (searchQuery) => {
      if (searchQuery) {
        const searchTerms = searchQuery.split(' ').map(term => buildSearchTerms(term))
        const searchPcs = buildSearchTerms(searchQuery)

        return state.searchableJobs.filter(job => (
          searchPcs === job.professionCode ||
            matchSearchTerms(searchTerms, job._searchKey)
        ))
      }
      else {
        return []
      }
    }
  }
}

const actions = {
  reset(context) {
    context.commit('reset')
  },
  getJobs(context) {
    return api.get('/jobs')
      .then(response => {
        context.commit('setJobs', response.data)
      })
  },
  getJob(context, jobId) {
    return api.get('/job/' + buildJobId(jobId))
      .then(response => {
        context.commit('setJob', response.data)
        return response.data
      })
  }
}

const mutations = {
  reset(state) {
    Object.assign(state, initialState())
  },
  setJobs(state, jobs) {
    state.jobs = jobs
    state.searchableJobs = jobs.map(job => ({
      id: job.id,
      group: job.group,
      job: job.job,
      professionCode: job.id.substr(0, 4),
      _searchKey: slugify(job.job)
    }))
  },
  setJob(state, job) {
    state.indexedJobs[job.id] = job
  },
  setCurrentJob(state, job) {
    state.currentJobName = job.job
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
