<template>
  <div class="formula-input-container">
    <div
      v-if="computedPlaceholder"
      class="formula-placeholder">{{computedPlaceholder}}</div>
    <div
      ref="formulaInput"
      class="formula-input"
      :class="{error}"
      contenteditable="true"
      @input="onChange"
      @blur="onBlur"
      @paste="onPaste"></div>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    error: String,
    autofocus: Boolean,
    placeholder: String
  },
  computed: {
    computedPlaceholder() {
      if (this.placeholder && !this.value) {
        return this.placeholder
      }
    }
  },
  methods: {
    formatFormula(formula) {
      // Idea: Sanitize & colorize :)
      return formula
    },
    initModel() {
      if (this.$refs.formulaInput.innerText !== this.value) {
        this.$refs.formulaInput.innerText = this.formatFormula(this.value)
      }
    },
    onPaste(event) {
      // This avoids pasting HTML content
      setTimeout(() => {
        this.$refs.formulaInput.innerHTML = this.formatFormula(this.$refs.formulaInput.innerText)
      }, 100)
    },
    onBlur() {
      this.$emit('blur')
    },
    onChange(event) {
      this.$emit('input', event.target.innerText)
    }
  },
  watch: {
    value: 'initModel'
  },
  mounted() {
    this.initModel()
    if (this.autofocus) {
      this.$refs.formulaInput.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
.formula-input-container {
  @include font-big-size;
  @include font-tabular-numbers;
  @include line-large-height;
}

.formula-input {
  border: 1px solid $graph-outer-border-color;
  border-radius: $border-radius;
  background: white;
  box-shadow: inset 0 1px 0 rgba(black, 0.05);
  padding: 0.3em 0.5em;

  &:focus {
    outline: lighten($graph-darkblue-color, 30) auto 1px;
  }

  &.error {
    outline: lighten($red-color, 20) auto 1px;
  }
}

.formula-placeholder {
  pointer-events: none;
  border: 1px solid transparent;
  color: $light-text-color;
  position: absolute;
  padding: 0.3em 0.5em;
}

// Small skin
.formula-input-container.small {
  @include font-normal-size;
}
</style>
