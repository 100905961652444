<template>
  <LoadingView v-if="!wageModel" />
  <div class="container" v-else-if="employee">
    <employee-detail :employee="employee" />
    <div class="wage-calculator">
      <h3 v-if="updatedWageId" v-t="'employees.employee.wage.update'" />
      <h3 v-else v-t="'employees.employee.wage.create'" />
      <div v-if="isWageQualificationSynchronizationEnabled">
        <p
          class="alert preserve-lines"
          v-html="$t('employees.employee.wage.synchronizedWageQualification', {serviceNames: enabledIdentityProviderNames})"></p>
        <button
          v-for="thirdPartyUser in employee.thirdPartyUsers"
          :key="thirdPartyUser.id"
          class="secondary third-party-button"
          v-t="{
              path: 'employees.employee.thirdPartyUser.openIn',
              args: {serviceName: $t(`settings.settingsCompanySynchronization.identityProviders.${thirdPartyUser.identityProvider}`)}}"
          @click="$$openUrl(thirdPartyUser.profileUrl)"></button>
      </div>
      <div class="top-fields">
        <div>
          <div class="input-label" v-t="'employees.employee.wage.startDate'" />
          <DateInput
            v-model="wageModel.startDate"
            :class="{'input-error' : $v.wageModel.startDate.$error}" />
        </div>
        <div v-if="isGridVersionPickerVisible" class="grid-choice">
          <div class="input-label" v-t="'employees.employee.wage.grid'" />
          <GridVersionPicker
            class="grid-picker large"
            :selectedGrid="grid"
            :canSelectNoGrid="true"
            @change="gridChanged" />
        </div>
      </div>
      <div
        v-if="startDateErrorMessage"
        class="alert warning">{{startDateErrorMessage}}</div>
      <WageCalculator
        v-if="!gridIsLoading"
        ref="wageCalculator"
        :referenceWage="referenceWage"
        :value="wageModel"
        @input="updateWageModel"
        :grid="grid"
        :showDelta="true"
        :showReason="true"
        :showVariable="true">
      </WageCalculator>
      <LoadingView v-else />
      <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>
      <menu>
        <loading-button
          v-if="canDeleteWage"
          class="destructive"
          @click="deleteWage"
          :loading="isLoading">
          <span v-t="'employees.employee.wage.delete'" />
        </loading-button>
        <button
          v-if="isUpdated"
          class="secondary"
          @click="resetCurrentWage"
          :disabled="isLoading"
          v-t="'common.reset'">
        </button>
        <button
          class="secondary"
          @click="$router.go(-1)"
          v-t="'employees.employee.form.cancel'">
        </button>
        <loading-button
          class="primary"
          @click="submit"
          :loading="isLoading">
          <span v-if="updatedWageId" v-t="'employees.employee.updateWage'" />
          <span v-else v-t="'employees.employee.wage.create'" />
        </loading-button>
      </menu>
    </div>
    <employee-newsfeed
      v-if="employee"
      :employee="employee"
      :showCurrent="false"
      :collapsed="true" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DateInput from '@components/commons/DateInput'
import EmployeeDetail from '@components/employees/EmployeeDetail'
import EmployeeNewsfeed from '@components/employees/EmployeeNewsfeed'
import GridVersionPicker from '@components/grid/viewer/GridVersionPicker'
import LoadingView from '@components/commons/LoadingView'
import WageCalculator from '@components/wage/WageCalculator'

import { required } from 'vuelidate/lib/validators'
import { dateIsBefore, formattedDate } from '@/utils/date'

export default {
  components: {
    DateInput,
    EmployeeDetail,
    EmployeeNewsfeed,
    GridVersionPicker,
    LoadingView,
    WageCalculator
  },
  data() {
    return {
      referenceWage: null,
      wageModel: null,
      isLoading: false,
      gridIsLoading: false,
      isUpdated: false,
      errorMessage: null,
      selectedGrid: this.$store.getters['currentGrid/getCurrentGrid']
    }
  },
  computed: {
    ...mapGetters({
      isWageQualificationSynchronizationEnabled: 'synchronization/isWageQualificationSynchronizationEnabled',
      enabledIdentityProviderNames: 'synchronization/enabledIdentityProviderNames'
    }),
    startDateErrorMessage() {
      const currentWage = this.employee.currentWage
      if (this.wageModel.startDate) {
        if (currentWage && currentWage.id !== this.wageModel.id &&
          !dateIsBefore(this.wageModel.startDate, currentWage.startDate)) {
          const date = formattedDate(currentWage.startDate)
          return this.$t('employees.employee.wage.invalidStartDateBeforeCurrentWage', { date })
        }
      }
    },
    isGridVersionPickerVisible() {
      const currentWage = this.employee.currentWage
      const isCurrentWage = currentWage && currentWage.id === this.wageModel.id
      const hasNoGrid = currentWage && !currentWage.hasLevels
      return this.$$hasGrid && (!isCurrentWage || hasNoGrid)
    },
    hasGridHistory() {
      return this.$store.getters['currentGrid/getCompanyGrids'].length > 1
    },
    employee() {
      const employeeId = this.$route.params.id
      return this.$store.getters['employees/getEmployee'](employeeId)
    },
    grid() {
      return this.selectedGrid
    },
    updatedWageId() {
      return this.$route.params.wageId
    },
    canDeleteWage() {
      return this.updatedWageId && this.employee.currentWage &&
        ((this.updatedWageId !== this.employee.currentWage.id) || !this.wageModel.gridId)
    }
  },
  methods: {
    updateWageModel(newWageModel) {
      this.isUpdated = true
      this.wageModel = {
        ...newWageModel,
        startDate: this.wageModel.startDate
      }
    },
    async gridChanged(gridId) {
      this.gridIsLoading = true
      this.selectedGrid = gridId ? await this.$store.dispatch('currentGrid/getGrid', gridId) : null

      if (this.wageModel.gridId !== gridId) {
        this.wageModel.gridId = gridId
        this.wageModel.levelIds = []
      }

      this.gridIsLoading = false
    },
    async resetCurrentWage() {
      if (this.referenceWage) {
        this.wageModel = { ...this.referenceWage }
        await this.gridChanged(this.referenceWage.gridId)
      }

      this.isUpdated = false
    },
    async submit() {
      this.$v.$touch()

      if (!this.$v.$error && !this.startDateErrorMessage) {
        const employee = this.employee
        const wage = this.wageModel
        this.errorMessage = null
        this.isLoading = true

        try {
          if (wage.id) {
            await this.$store.dispatch('employees/updateWage', wage)
          }
          else {
            await this.$store.dispatch('employees/createWage', { employee, wage })
          }
          this.$router.go(-1)
        }
        catch (error) {
          this.errorMessage = error
          this.isLoading = false
        }
      }
    },
    async deleteWage() {
      if (confirm(this.$t('employees.employee.wage.deleteConfirmation'))) {
        try {
          this.isLoading = true
          await this.$store.dispatch('employees/deleteWage', this.referenceWage)
          this.$router.go(-1)
        }
        catch (error) {
          this.errorMessage = error
          this.isLoading = false
        }
      }
    }
  },
  async mounted() {
    if (this.employee) {
      this.$store.commit('employees/setCurrentEmployee', this.employee)

      if (this.updatedWageId) {
        try {
          this.referenceWage = await this.$store.dispatch('employees/getWage', this.updatedWageId)
          this.wageModel = { ...this.referenceWage }
          await this.gridChanged(this.referenceWage.gridId)
        }
        catch {
          this.$router.push({ name: 'employees' })
        }
      }
      else {
        this.wageModel = { ...this.$store.state.employees.employeeModel.currentWage }
      }
    }
    else {
      this.$router.push({ name: 'employees' })
    }
  },
  validations: {
    wageModel: {
      startDate: { required }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/alert.scss";
@import "./src/styles/button.scss";
@import "./src/styles/form.scss";

.container {
  background: $background-color;
}

.wage-calculator {
  @include container;
  margin-top: 1.5em;
}

h2 {
  display: block;
  margin: 0 0 20px 0;
}

.top-fields {
  display: flex;
  margin-bottom: 2em;
  align-items: flex-start;

  .grid-choice {
    margin-left: 1em;
  }
}

.alert {
  margin-bottom: 2em;
}

.third-party-button {
  float: right;
  margin-top: -6.5em;
  margin-right: 1em;
}
</style>
