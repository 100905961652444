import OktaAuth from '@okta/okta-auth-js'
import { formatDomain } from '@/utils/string'

const SCIM_BASE_URL = `${process.env.VUE_APP_API_URL}/scim/v2`

const OPEN_ID_CONNECT_LOGIN_REDIRECT_URI = `${window.location.origin}/signin/okta/callback`
const OPEN_ID_CONNECT_INITIATE_LOGIN_URI = `${window.location.origin}/signin`
const OPEN_ID_CONNECT_REDIRECT_OPTIONS = {
  responseType: ['id_token', 'token'],
  responseMode: 'fragment',
  scopes: ['openid', 'profile', 'email']
}

const CW_OKTA_DOMAIN = 'CW_OKTA_DOMAIN'
const CW_OKTA_CLIENT_ID = 'CW_OKTA_CLIENT_ID'

function formatIssuer(domain) {
  return `https://${domain}`
}

function getOktaAuth(credentials = {}) {
  let { clientId, domain } = credentials
  if (!clientId || !domain) {
    clientId = sessionStorage.getItem(CW_OKTA_CLIENT_ID)
    domain = sessionStorage.getItem(CW_OKTA_DOMAIN)
  }

  if (clientId && domain) {
    const config = {
      redirectUri: OPEN_ID_CONNECT_LOGIN_REDIRECT_URI,
      clientId,
      issuer: formatIssuer(domain)
    }

    sessionStorage.setItem(CW_OKTA_CLIENT_ID, clientId)
    sessionStorage.setItem(CW_OKTA_DOMAIN, domain)

    return new OktaAuth(config)
  }
}

const getters = {
  getSCIMBaseUrl() {
    return SCIM_BASE_URL
  },
  getOpenIDConnectLoginRedirectURI() {
    return OPEN_ID_CONNECT_LOGIN_REDIRECT_URI
  },
  getOpenIDConnectInitiateLoginURI() {
    return OPEN_ID_CONNECT_INITIATE_LOGIN_URI
  }
}

const actions = {
  loginWithRedirect(context, authentication) {
    const oktaAuth = getOktaAuth(authentication)
    if (oktaAuth) {
      return oktaAuth.token.getWithRedirect(OPEN_ID_CONNECT_REDIRECT_OPTIONS)
    }
  },
  loginWithIssuer(context, issuer) {
    const domain = formatDomain(issuer)
    this.dispatch('account/getSigninMethod', { domain }).then(signinMethod => {
      return context.dispatch('loginWithRedirect', signinMethod)
    })
  },
  async parseAccessToken() {
    const oktaAuth = getOktaAuth()
    if (oktaAuth) {
      const tokens = await oktaAuth.token.parseFromUrl()
      tokens.forEach(token => {
        if (token.accessToken) oktaAuth.tokenManager.add('accessToken', token)
        if (token.idToken) oktaAuth.tokenManager.add('idToken', token)
      })
    }
  },
  async getLoginCredentials() {
    const oktaAuth = getOktaAuth()
    if (oktaAuth) {
      const identityProvider = 'okta'
      const idTokenContainer = await oktaAuth.tokenManager.get('idToken')
      const idToken = idTokenContainer && idTokenContainer.idToken
      const clientId = sessionStorage.getItem(CW_OKTA_CLIENT_ID)
      const domain = sessionStorage.getItem(CW_OKTA_DOMAIN)

      return { identityProvider, idToken, clientId, domain }
    }
  }
}

export default {
  namespaced: true,
  getters,
  actions
}
