<template>
  <div class="wrapper">
    <div class="svg-container">
      <GraphTooltip :employees="employees" :tooltip="tooltip" />
      <svg :height="viewport.height" ref="svgNode" />
      <GraphLegend
        id="workforce"
        :graph="graph"
        v-model="truncateOffset"
        @input="render" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import * as d3 from 'd3'
import debounce from 'lodash.debounce'
import { getGroupedEmployeesAndScopeValues } from '@/utils/statistics'
import {
  generateAbscissaLabels,
  generateOrdinateLabels,
  generateBars,
  generateStackedCircles,
  renderInteractiveLayer,
  truncateModel,
  wrapLabels,
  renderSelectionLayer
} from '@/utils/graph'
import GraphLegend from '@components/graph/GraphLegend.vue'
import GraphTooltip from '@components/graph/GraphTooltip.vue'

function formatAbscissaLabel(d) {
  return `${d.count}\n${d.label}`
}

function initGraph(svg, viewport) {
  const node = {}

  node.svg = d3.select(svg)
  node.viewport = node.svg.append('g')
    .attr('class', 'viewport')
  node.background = node.viewport.append('rect')
    .attr('class', 'background')
  node.selection = node.viewport.append('g')
    .attr('class', 'selection')
  node.bars = node.viewport.append('g')
    .attr('class', 'bars')
  node.circles = node.viewport.append('g')
    .attr('class', 'circles')
  node.abscissa = node.viewport.append('g')
    .attr('class', 'axis abscissa')
  node.ordinate = node.viewport.append('g')
    .attr('class', 'axis ordinate')
  node.x = d3.scaleBand()
  node.y = d3.scaleLinear()

  return {
    node: node,
    viewport: viewport,
    data: [],
    truncation: {
      total: 0
    }
  }
}

function generateComboGraphModel(graph, employees, scope) {
  const { groupedEmployees, scopeValues } = getGroupedEmployeesAndScopeValues(employees, scope)

  graph.data = scopeValues.map(x => ({
    x: x,
    y: d3.sum(groupedEmployees[x], e => e.salary),
    values: groupedEmployees[x]
  }))
  graph.truncation.total = graph.data.length

  // Use employees count as fallback when there's no salary
  if (!graph.data.find(d => d.y > 0)) {
    graph.isDataFallback = true
    graph.data.forEach(d => {
      d.y = d.values.length
    })
  }

  return graph
}

function renderGraph(graph, component) {
  if (!graph.node.svg.node()) {
    return
  }
  const domain = graph.data.map(d => d.x)
  const viewport = graph.viewport
  const node = graph.node

  // Viewport
  viewport.width = graph.node.svg.node().getBoundingClientRect().width
  viewport.innerWidth = viewport.width - viewport.padding.left - viewport.padding.right
  viewport.innerHeight = viewport.height - viewport.padding.top - viewport.padding.bottom

  // Graph Axes
  graph.axes = {
    abscissa: {
      type: 'discrete',
      labels: 'belowAxis',
      axis: node.x
    },
    ordinate: {
      type: 'continuous',
      labels: 'aboveMax',
      axis: node.y,
      format: '$,'
    }
  }
  node.x.domain(domain)
    .rangeRound([0, viewport.innerWidth])
    .padding([0.15])
  if (node.x.bandwidth() > 300) {
    node.x.padding([0.50])
  }
  else if (node.x.bandwidth() > 150) {
    node.x.padding([0.40])
  }
  node.y.domain([d3.max(graph.data, d => d.y) * 1.15, 0])
    .rangeRound([0, viewport.innerHeight])

  // Viewport background
  node.viewport.attr('transform',
    `translate(${viewport.padding.left},${viewport.padding.top})`)
  node.background.attr('width', viewport.innerWidth)
    .attr('height', viewport.innerHeight)

  // Axes Labels
  const abscissaLabelsData = generateAbscissaLabels(graph)
  const abscissaLabels = node.abscissa.selectAll('.label')
    .data(abscissaLabelsData, formatAbscissaLabel)
  abscissaLabels
    .enter().append('text')
    .attr('class', 'label wrap')
    .merge(abscissaLabels)
    .attr('x', d => d.x)
    .attr('y', d => d.y)
    .text(formatAbscissaLabel)
  abscissaLabels.exit()
    .remove()

  const ordinateLabelsData = graph.isDataFallback ? [] : generateOrdinateLabels(graph)
  const ordinateLabels = node.ordinate.selectAll('.label')
    .data(ordinateLabelsData, d => d.id)
  ordinateLabels
    .enter().append('text')
    .attr('class', 'label')
    .attr('x', d => d.x)
    .attr('y', d => d.y)
    .merge(ordinateLabels)
    .text(d => d.label)
    .transition()
    .attr('x', d => d.x)
    .attr('y', d => d.y)
  ordinateLabels.exit()
    .remove()

  Vue.nextTick(() => {
    wrapLabels(graph, graph.node.svg.node())
  })

  // Bars
  const salariesBarsData = generateBars(graph)
  const salariesBars = node.bars.selectAll('.bar')
    .data(salariesBarsData, d => d.id)
  salariesBars
    .enter().append('rect')
    .attr('class', 'bar')
    .attr('x', d => d.x)
    .attr('y', d => d.y + d.height)
    .attr('rx', 2)
    .attr('ry', 2)
    .attr('width', d => d.width)
    .attr('height', 0)
    .merge(salariesBars)
    .transition()
    .attr('x', d => d.x)
    .attr('y', d => d.y)
    .attr('width', d => d.width)
    .attr('height', d => d.height)
  salariesBars.exit()
    .remove()

  // Circles
  const employeesCirclesData = generateStackedCircles(graph)
  const employeesCircles = node.circles.selectAll('.circle')
    .data(employeesCirclesData, d => d.id)
  employeesCircles
    .enter().append('circle')
    .attr('class', d => 'circle ' + d.class)
    .attr('cx', d => d.x)
    .attr('cy', d => d.y)
    .attr('r', 0)
    .merge(employeesCircles)
    .transition()
    .delay((_, i) => i * 5)
    .attr('cx', d => d.x)
    .attr('cy', d => d.y)
    .attr('r', d => d.r)
  employeesCircles.exit()
    .transition()
    .attr('r', 0)
    .style('opacity', 0)
    .remove()

  // Mouse hover
  renderInteractiveLayer(
    node,
    employeesCirclesData,
    tooltip => {
      component.tooltip = tooltip
    },
    route => {
      component.$router.push(route)
    })

  renderSelectionLayer(
    graph,
    component.$store.getters['statistics/isSelectScopeValueAvailable'],
    scope => {
      component.$store.dispatch('statistics/selectScopeValue', scope)
    }
  )
}

export default {
  components: {
    GraphLegend,
    GraphTooltip
  },
  name: 'combo-graph',
  data() {
    return {
      // Options
      viewport: {
        width: 0, // Computed
        height: 350,
        innerWidth: 0, // Computed
        innerHeight: 0, // Computed
        padding: {
          left: 0,
          right: 0,
          top: 25,
          bottom: 77
        }
      },
      // State
      tooltip: null,
      truncateOffset: 0,
      graph: null,
      resizeFn: null
    }
  },
  computed: {
    ...mapGetters({
      selectedScope: 'statistics/getCurrentScope',
      employees: 'statistics/getFilteredEmployees'
    })
  },
  mounted() {
    this.init()
    setTimeout(this.render, 400)
    this.resizeFn = debounce(this.render, 50)
    window.addEventListener('resize', this.resizeFn)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeFn)
  },
  watch: {
    employees: 'resetRender',
    selectedScope: 'resetRender'
  },
  methods: {
    init() {
      this.graph = initGraph(this.$refs.svgNode, this.viewport)
    },
    resetRender() {
      this.truncateOffset = 0
      this.resizeFn()
    },
    render() {
      if (this.employees) {
        console.time('renderComboGraph')
        generateComboGraphModel(this.graph, this.employees, this.selectedScope)
        truncateModel(this.graph, this.truncateOffset)
        renderGraph(this.graph, this)
        console.timeEnd('renderComboGraph')
      }
    }
  }
}

</script>

<style lang="scss" scoped>
@import "./src/styles/graph.scss";

svg::v-deep .ordinate .label {
  @include font-semibold;
  fill: $graph-text-color;
}

svg::v-deep .bar {
  fill: $graph-blue-color;
}
</style>
