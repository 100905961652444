<template>
  <div>
    <menu class="float-right no-margin-top">
      <input
        ref="searchQuery"
        type="search"
        spellcheck="false"
        :placeholder="$t('employees.search')"
        @input="onSearchQueryChange"
        @keydown="onSearchKeyDown($event)" />
      <loading-button
        v-if="$$isAdmin"
        class="secondary"
        :loading="isCreating"
        @click="createReview()">
        <span v-t="'reviews.placeholder.action'"></span>
      </loading-button>
    </menu>
    <h1><span v-t="'reviews.longTitle'"></span><span class="beta">Beta</span></h1>
    <LoadingView v-if="isLoading" />
    <div class="reviews" v-else-if="sortedReviews.length">
      <router-link
        class="review"
        v-for="review in sortedReviews"
        :key="review.id"
        :to="{name: 'review', params: {id: review.id}}">
        <h3>{{review.name}}</h3>
        <p class="detail">
          {{ $t(`variable.statuses.${review.status}.title`) }}
          ·
          <span v-html="$tc('employees.employees.count', review.employeesCount)" />
        </p>
      </router-link>
    </div>
    <p v-else v-t="$$isAdmin ?'reviews.placeholder.emptyAdmin' : 'reviews.placeholder.empty'"></p>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import { mapGetters } from 'vuex'
import { filterByQuery, sortByKeys } from '@/utils/string'
import LoadingView from '@components/commons/LoadingView'

const CW_REVIEWS_SORT_KEY = 'CW_REVIEWS_SORT_KEY'
const CW_REVIEWS_SORT_ORDER = 'CW_REVIEWS_SORT_ORDER'
const CW_REVIEWS_SEARCH_QUERY = 'CW_REVIEWS_SEARCH_QUERY'

export default {
  components: {
    LoadingView
  },
  data() {
    const defaults = {
      isLoading: false,
      isCreating: false,
      searchQuery: null,

      sortItems: ['name', 'startDate', 'endDate', 'employeesCount'],
      sortKey: 'name',
      sortOrder: 1
    }

    if (defaults.sortItems.includes(localStorage[CW_REVIEWS_SORT_KEY])) {
      defaults.sortKey = localStorage[CW_REVIEWS_SORT_KEY]
    }
    if (['-1', '1'].includes(localStorage[CW_REVIEWS_SORT_ORDER])) {
      defaults.sortOrder = +localStorage[CW_REVIEWS_SORT_ORDER]
    }
    if (sessionStorage[CW_REVIEWS_SEARCH_QUERY]) {
      defaults.searchQuery = sessionStorage[CW_REVIEWS_SEARCH_QUERY]
    }

    return defaults
  },
  computed: {
    ...mapGetters({
      reviews: 'reviews/getReviews'
    }),
    filteredReviews() {
      return filterByQuery(this.reviews, this.searchQuery, '_searchKey')
    },
    sortedReviews() {
      return sortByKeys(this.filteredReviews, this.sortKey, this.sortOrder)
    },
    sortDropdownItems() {
      return [{
        name: this.$t('employees.sort.property'),
        value: 'sortKey',
        items: this.sortItems.map(item => ({
          name: this.$t(`variable.form.${item}`),
          value: item,
          selected: this.sortKey === item
        }))
      }, {
        name: this.$t('employees.sort.order'),
        value: 'sortOrder',
        items: [{
          name: this.$t('employees.sort.ascending'),
          value: 1,
          selected: this.sortOrder === 1
        },
        {
          name: this.$t('employees.sort.descending'),
          value: -1,
          selected: this.sortOrder === -1
        }]
      }]
    }
  },
  methods: {
    async loadReviews() {
      this.isLoading = true
      try {
        await this.$store.dispatch('reviews/getReviews')
      }
      catch (_) { }
      this.isLoading = false
    },
    onSearchQueryChange: debounce(function(event) {
      this.searchQuery = event.target.value
    }, 300),
    onSearchKeyDown(event) {
      if (event.key === 'Enter' && this.sortedReviews.length) {
        this.$router.push({ name: 'review', params: { id: this.sortedReviews[0].id } })
      }
    },
    setSort(key, value) {
      switch (key) {
        case 'sortKey':
          localStorage[CW_REVIEWS_SORT_KEY] = value
          this.sortKey = value
          break
        case 'sortOrder':
          localStorage[CW_REVIEWS_SORT_ORDER] = value
          this.sortOrder = value
          break
      }
    },
    createReview() {
      this.isCreating = true
      this.$store.dispatch('reviews/createReview').then(({ id }) => {
        this.isCreating = false
        this.$router.push({ name: 'review', params: { id } })
      })
        .catch(error => {
          this.isCreating = false
          error && alert(error)
        })
    }
  },
  beforeDestroy() {
    if (this.searchQuery) {
      sessionStorage[CW_REVIEWS_SEARCH_QUERY] = this.searchQuery
    }
    else {
      delete sessionStorage[CW_REVIEWS_SEARCH_QUERY]
    }
  },
  mounted() {
    this.loadReviews()

    if (this.$route.params.query) {
      this.searchQuery = this.$route.params.query
    }
    if (this.searchQuery) {
      this.$refs.searchQuery.value = this.searchQuery
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/button.scss";
@import "./src/styles/link.scss";

h1 {
  margin-bottom: 20px;

  .beta {
    @include font-smaller-size;
    @include font-bold;
    text-transform: uppercase;
    color: $clearteal-color;
    vertical-align: 9px;
    margin-left: 6px;
  }
}

.reviews {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
  align-items: flex-start;

  .review {
    @extend .unstyled-link;
    display: block;
    padding: 10px;
    background: $graph-background-color url(~@/assets/icon-chat.svg) no-repeat
      center right 10px;
    background-size: 55px;
    border: 1px solid $graph-outer-border-color;
    border-radius: $border-radius;
    cursor: pointer;

    &:hover {
      background-color: darken($graph-lightblue-color, 2);
    }

    h3 {
      margin-bottom: 0.1em;
    }

    p {
      padding: 0;
      margin: 0;
    }

    .detail {
      @include font-small-size;
      margin-top: 0.5em;
      color: $light-text-color;
    }
  }
}

.review-add {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  border-top: 1px solid $graph-inner-border-color;
  padding-top: 1em;
}

input[type="search"] {
  display: inline-block;
  width: 190px;
}

.arrow-1::after,
.arrow--1::after {
  content: " ";
  text-align: left;
  display: inline-block;
  vertical-align: sub;
  width: 16px;
  height: 16px;
  margin-left: 0.25em;
  background: transparent url(~@/assets/arrow-down.svg) no-repeat center center;
  opacity: 0.8;
}

.arrow-1::after {
  transform: scaleY(-1);
}

.dropdown::v-deep .dropdown-button:hover .arrow-1::after,
.dropdown::v-deep .dropdown-button:focus .arrow-1::after,
.dropdown::v-deep .dropdown-button:hover .arrow--1::after,
.dropdown::v-deep .dropdown-button:focus .arrow--1::after {
  background-image: url(~@/assets/arrow-down-active.svg);
}
</style>
